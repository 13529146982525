<div id="obprintContainer" class="  mt-1 row scroll-obsecNotHeading text-black">
    <div class="col-12">
        <div class="col-12 p-0">
            <div>
                <button class="btn btn-info" type="button" (click)="onChangePrintType(false)">
                    <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                </button>
                <button class="btn btn-info" type="button" (click)="onChangePrintType(true)">
                    <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                </button>
            </div>

        </div>
        <div class="float-right row">
            <button [useExistingCss]="true" id="printButton" printSectionId="obprint" ngxPrint class="close d-print-none"> <i class="fe-printer" placement="left" ngbTooltip="PrintEncounter"></i></button>
        </div>
        <div id="obprint" class="border-left border-right border-bottom">
            <div class="report bg-white">
                <div class="mb-0 pt-1 pl-4 pr-4">
                    <div class="clearfix mb-3">
                        <div>
                            <div class="row" *ngIf="page.loading">
                                <div class="col-12">
                                    <div class="d-flex align-items-center">
                                        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                                        <span class="ml-2">Please wait...</span>
                                    </div>
                                </div>
                            </div>



                        </div>

                        <div class="col-12 p-0" *ngIf="isPrintLogo">
                            <div>
                                <banner-setting></banner-setting>
                            </div>
                        </div>
                        <div class="col-12 p-0" *ngIf="!isPrintLogo">
                            <div style="height: 100px"></div>
                        </div>


                        <div class="overflow-auto">
                            <div class="border-top2px d-flex justify-content-between mt-3 pb-1 pt-1 border-bottom-dotted">
                                <h4 class="pl-0">Discharge Summary</h4>
                                <h4 class="white-space-nowrap">Department of Neonatology</h4>
                            </div>
                            <table class="w-100 border-collapse white-space-nowrap">
                                <tr class="col-12">
                                    <td class="w-130 border-bottom-dotted">
                                        <span class="">Baby Of</span>
                                    </td>
                                    <td class="border-bottom-dotted">
                                        <h5 class="">:</h5>
                                    </td>
                                    <td class="border-bottom-dotted text-uppercase w-200p">
                                        <h5 class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="neonatalBaby && neonatalBaby.babyOf !=null" [textContent]="neonatalBaby.babyOf"></h5>
                                    </td>

                                    <td class="text-right border-bottom-dotted">
                                        <div> Baby UMR No  : <h5 class="pl-1  d-inline-block" *ngIf="neonatalBaby && neonatalBaby.babyUmr">{{neonatalBaby.babyUmr}}</h5></div>
                                    </td>
                                    <td class="w-130 border-bottom-dotted">
                                        <span class=""> Mother UMR No.</span>
                                    </td>
                                    <td class="border-bottom-dotted">
                                        <h5 class="">:</h5>
                                    </td>
                                    <td class="w-200p text-uppercase border-bottom-dotted">
                                        <h5 class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="neonatalBaby && neonatalBaby.motherUmr!=null" [textContent]="neonatalBaby.motherUmr"></h5>
                                    </td>

                                </tr>

                                <tr class="col-12">
                                    <td>
                                        <span>Father Name</span>
                                    </td>
                                    <td>
                                        <h5 class="">:</h5>
                                    </td>

                                    <td colspan="2">
                                        <h5 class="d-block  pl-1 text-truncate max-width_25vw text-uppercase" *ngIf="neonatalBaby && neonatalBaby.fatherName!=null" [textContent]="neonatalBaby.fatherName"></h5>
                                    </td>
                                    <td>
                                        <span>Mother IP No</span>
                                    </td>
                                    <td>
                                        <h5 class="">:</h5>
                                    </td>
                                    <td>
                                        <h5 class="font-17" *ngIf="fullTranscript && fullTranscript.admissionNo">{{fullTranscript.admissionNo}}</h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Admission Date/Time</span>
                                    </td>
                                    <td>
                                        <h5 class="">:</h5>
                                    </td>
                                    <td colspan="2">
                                        <h5 class="font-17" *ngIf="fullTranscript && fullTranscript.admissionDate">{{fullTranscript.admissionDate|date:'dd/MM/yyyy HH:mm'}}</h5>
                                    </td>
                                    <td>
                                        <span>ANC No</span>
                                    </td>
                                    <td>
                                        <h5 class="">:</h5>
                                    </td>
                                    <td>
                                        <h5 class="font-17" *ngIf="neonatalBaby && neonatalBaby.ancNo!=null" [textContent]="neonatalBaby.ancNo"></h5>
                                    </td>
                                </tr>
                                <tr class="border-bottom-dotted">
                                    <td>
                                        <span>Discharge Date</span>
                                    </td>
                                    <td class="border-bottom-dotted">
                                        <h5 class="">:</h5>
                                    </td>
                                    <td colspan="2" class="border-bottom-dotted">
                                        <h5 class="font-17" *ngIf="dischargeSummary && dischargeSummary.dischargedDate" [textContent]="dischargeSummary.dischargedDate | date: 'dd/MM/yyyy'"></h5>
                                    </td>


                                </tr>
                                <tr class="col-12">
                                    <td>
                                        <span>Birth Date/Time</span>
                                    </td>
                                    <td>
                                        <h5 class="">:</h5>
                                    </td>

                                    <td colspan="2">
                                        <ng-container *ngIf="neonatalBaby && (neonatalBaby.birthDate || neonatalBaby.birthTime)">
                                            <span class="font-17" *ngIf="neonatalBaby.birthDate ||  neonatalBaby.birthTime ">{{ neonatalBaby.birthDate }} {{ neonatalBaby.birthTime }}</span>

                                        </ng-container>
                                    </td>


                                    <td>
                                        <span>Weight(Kgs)</span>
                                    </td>
                                    <td>
                                        <h5 class="">:</h5>
                                    </td>
                                    <td>

                                        <h5 class="font-17" *ngIf="neonatalBaby && neonatalBaby.weight!=null" [textContent]="neonatalBaby.weight"></h5>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Sex</span>
                                    </td>
                                    <td>
                                        <h5 class="">:</h5>
                                    </td>
                                    <td colspan="2">
                                        <h5 class="font-17" *ngIf="neonatalBaby && neonatalBaby.sex!=null" [textContent]="neonatalBaby.sex"></h5>
                                    </td>
                                    <td>
                                        <span>Length(Cms)</span>
                                    </td>
                                    <td>
                                        <h5 class="">:</h5>
                                    </td>
                                    <td>
                                        <h5 class="font-17" *ngIf="neonatalBaby && neonatalBaby.length!=null" [textContent]="neonatalBaby.length"></h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Apgar</span>
                                    </td>
                                    <td>
                                        <h5 class="">:</h5>
                                    </td>
                                    <td colspan="2">
                                        <span class="font-17" *ngIf="neonatalBaby && neonatalBaby.apgar!=null" [textContent]="neonatalBaby.apgar"></span>/
                                        <span class="font-17" *ngIf="neonatalBaby && neonatalBaby.apgar1!=null" [textContent]="neonatalBaby.apgar1"></span>/
                                        <span class="font-17" *ngIf="neonatalBaby && neonatalBaby.apgar10!=null" [textContent]="neonatalBaby.apgar10"></span>
                                    </td>
                                    <td>
                                        <span>Head Circumference(Cms)</span>
                                    </td>
                                    <td>
                                        <h5 class="">:</h5>
                                    </td>
                                    <td>
                                        <h5 class="font-17" *ngIf="neonatalBaby && neonatalBaby.hc!=null" [textContent]="neonatalBaby.hc"></h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Gestination Age(Weeks)</span>
                                    </td>
                                    <td>
                                        <h5 class="">:</h5>
                                    </td>
                                    <td colspan="2">
                                        <h5 class="font-17" *ngIf="neonatalBaby && neonatalBaby.gestAgeWks!=null" [textContent]="neonatalBaby.gestAgeWks"></h5>
                                    </td>
                                    <td>
                                        <span>Discharge Type</span>
                                    </td>
                                    <td>
                                        <h5 class="">:</h5>
                                    </td>
                                    <td>
                                        <h5 class="font-17" *ngIf="dischargeSummary && dischargeSummary.discharge!=null" [textContent]="dischargeSummary.discharge"></h5>
                                    </td>
                                </tr>
                                <tr class="border-bottom-dotted">
                                    <td>
                                        <span>Baby BloodGroup</span>
                                    </td>
                                    <td class="border-bottom-dotted">
                                        <h5 class="">:</h5>
                                    </td>
                                    <td colspan="2" class="border-bottom-dotted">
                                        <h5 class="font-17" *ngIf="neonatalBaby && neonatalBaby.bloodGroup!=null" [textContent]="neonatalBaby.bloodGroup"></h5>
                                    </td>

                                </tr>
                            </table>
                            <table class="col-12 mt-1 mb-1" style="border-collapse: collapse;">


                                <tr *ngIf="diagnosisRecords && diagnosisRecords.length !== 0">

                                    <td>
                                        <table class="w-100">
                                            <thead>
                                                <tr class="font-weight-bolder">
                                                    <th class="col-6" style="white-space: normal; word-break: break-all;"><h5>Diagnosis/Complications/Problem</h5></th>
                                                    <th class="col-6"><h5>ICD Code</h5></th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr *ngFor="let item of diagnosisRecords; let i = index">
                                                    <td>
                                                        <span class="align-content-lg-center" [textContent]="item.usable"></span><br />
                                                    </td>
                                                    <td>
                                                        <span class="align-content-lg-center" [textContent]="item.code"></span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>


                            </table>

                            <div>
                                <h5 class="dashboard-title" *ngIf="dischargeSummary?.maternalSummary">Maternal History</h5>
                                <span *ngIf="dischargeSummary?.maternalSummary" [innerHTML]="dischargeSummary.maternalSummary"></span>
                            </div>

                            <div>
                                <h5 class="dashboard-title" *ngIf="dischargeSummary?.periSummary">Perinatal History</h5>
                                <span *ngIf="dischargeSummary?.periSummary" [innerHTML]="dischargeSummary.periSummary"></span>
                            </div>


                            <div>
                                <h5 class="dashboard-title">Special Features</h5>
                                <div *ngIf="neonatalBaby && neonatalBaby.impression!=null" [textContent]="neonatalBaby.special"></div>
                            </div>
                            <div>
                                <h5 class="dashboard-title">Congential Anamolies</h5>
                                <span *ngIf="neonatalBaby && neonatalBaby.congo7 && neonatalBaby.congo7.length>0 || neonatalBaby && neonatalBaby.congo6 && neonatalBaby.congo6.length>0 || neonatalBaby && neonatalBaby && neonatalBaby.congo1 && neonatalBaby.congo1.length>0 || neonatalBaby && neonatalBaby.congo2 && neonatalBaby.congo2.length>0 || neonatalBaby && neonatalBaby.congo3 && neonatalBaby.congo3.length>0 || neonatalBaby && neonatalBaby.congo8 && neonatalBaby.congo8.length>0 || neonatalBaby && neonatalBaby.congo4 && neonatalBaby.congo4.length>0">
                                    Baby Has
                                </span>
                                <span *ngIf="neonatalBaby && neonatalBaby.congo7  && neonatalBaby.congo7.length >0">
                                    <span *ngFor="let item of neonatalBaby.congo7" [textContent]="item + ','">
                                    </span>
                                </span>
                                <span *ngIf="neonatalBaby && neonatalBaby.congo6 && neonatalBaby.congo6.length >0">
                                    <span *ngFor="let item of neonatalBaby.congo6">
                                        <span>{{ item }},</span>
                                    </span>
                                </span>
                                <span *ngIf="neonatalBaby && neonatalBaby.congo1 && neonatalBaby.congo1.length >0">
                                    <span *ngFor="let item of neonatalBaby.congo1" [textContent]="item + ','"></span>
                                </span>
                                <span *ngIf="neonatalBaby && neonatalBaby.congo2 && neonatalBaby.congo2.length >0">
                                    <span *ngFor="let item of neonatalBaby.congo2" [textContent]="item + ','"></span>
                                </span>
                                <span *ngIf="neonatalBaby && neonatalBaby.congo3 && neonatalBaby.congo3.length >0">
                                    <span *ngFor="let item of neonatalBaby.congo3" [textContent]="item + ','"></span>
                                </span>
                                <span *ngIf="neonatalBaby && neonatalBaby.congo8 && neonatalBaby.congo8.length >0">
                                    <span *ngFor="let item of neonatalBaby.congo8" [textContent]="item + ','"></span>
                                </span>
                                <span *ngIf="neonatalBaby && neonatalBaby.congo4 && neonatalBaby.congo4.length >0">
                                    <span *ngFor="let item of neonatalBaby.congo4" [textContent]="item + ','"></span>
                                </span>

                                <span *ngIf="neonatalBaby && neonatalBaby.congo3 && neonatalBaby.congo3.length >0">
                                    <span *ngFor="let item of neonatalBaby.congo2" [textContent]="item + ','"></span>
                                </span>
                            </div>

                            <div>
                                <h5 class="dashboard-title">Impression</h5>
                                <span *ngIf="dischargeSummary && dischargeSummary.impression !=null" [textContent]="dischargeSummary.impression"> </span>
                                <span> /</span>
                                <span *ngIf="dischargeSummary &&  dischargeSummary.apnea">Apnea</span>,
                                <span *ngIf="dischargeSummary &&  dischargeSummary.asymptomaticHypoglycemia">AsymptomaticHypoglycemia,</span>
                                <span *ngIf="dischargeSummary &&  dischargeSummary.neonatalJaundice">NeonatalJaundice,</span>
                                <span *ngIf="dischargeSummary &&  dischargeSummary.nutrition">Nutrition,</span>
                                <span *ngIf="dischargeSummary &&  dischargeSummary.pah">PAH,</span>
                                <span *ngIf="dischargeSummary &&  dischargeSummary.perinatalDepression">PerinatalDepression,</span>
                                <span *ngIf="dischargeSummary &&  dischargeSummary.rdhmd">RD-HMD-Insure-Bubble CPAP,</span>
                                <span *ngIf="dischargeSummary &&  dischargeSummary.suspectSepsis">SuspectSepsis,</span>
                                <span *ngIf="dischargeSummary &&  dischargeSummary.ttnb">TTNB,</span>
                                <span *ngIf="dischargeSummary &&  dischargeSummary.rhiso">RH IsoImmunisation,</span>
                                <span *ngIf="dischargeSummary &&  dischargeSummary.hyper">HyperInsulinemic,</span>
                                <span *ngIf="dischargeSummary &&  dischargeSummary.hyper1">Hypoglycemia,</span>
                                <span *ngIf="dischargeSummary &&  dischargeSummary.neurosonogram">Neurosonogram,</span>
                                <span *ngIf="dischargeSummary &&  dischargeSummary.thyroid">Thyroid</span>
                            </div>

                            <div>
                                <h5 class="dashboard-title">Course In Hospital</h5>
                                <div *ngIf="neonatalBaby && neonatalBaby.congo5  && neonatalBaby.congo5.length >0">
                                    <span *ngFor="let item of neonatalBaby.congo5" [textContent]="item + ','"></span> was given on Date:<span [textContent]="neonatalBaby.datehb"></span>
                                </div>
                            </div>
                            <div class="w-100">
                                <table class="col-12 mt-1 mb-1" style="border-collapse: collapse;">
                                    <thead>
                                        <tr>
                                            <th class="vertical-align-top w-16p white-space-nowrap"><h5>Screening Details</h5></th>
                                            <th></th>
                                            <th class="vertical-align-top"><h5>Result</h5></th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>
                                                ENBS
                                            </td>
                                            <td class="w-16">
                                                <h5>:</h5>
                                            </td>
                                            <td>
                                                <span class="font-17" *ngIf="neonatalBaby && neonatalBaby.enbs!=null" [textContent]="neonatalBaby.enbs"></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Sample Sent
                                            </td>
                                            <td>
                                                <h5>:</h5>
                                            </td>
                                            <td>
                                                <span class="font-17" *ngIf="neonatalBaby && neonatalBaby.sample!=null" [textContent]="neonatalBaby.sample"></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Hearing Screening
                                            </td>
                                            <td>
                                                <h5>:</h5>
                                            </td>
                                            <td>
                                                <span class="font-17" *ngIf="neonatalBaby && neonatalBaby.hearingScreen!=null" [textContent]="neonatalBaby.hearingScreen"></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Red Eye Reflex

                                            </td>
                                            <td>
                                                <h5>:</h5>
                                            </td>
                                            <td>
                                                <span class="font-17" *ngIf="neonatalBaby && neonatalBaby.redEye!=null" [textContent]="neonatalBaby.redEye"></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                SPO2 On Room Air

                                            </td>
                                            <td>
                                                <h5>:</h5>
                                            </td>
                                            <td>
                                                <span class="font-17" *ngIf="neonatalBaby && neonatalBaby.sPO2OnRoomAir!=null" [textContent]="neonatalBaby.sPO2OnRoomAir"></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Cord Blood TSH

                                            </td>
                                            <td>
                                                <h5>:</h5>
                                            </td>
                                            <td>
                                                <span class="font-17" *ngIf="neonatalBaby && neonatalBaby.cordBlood!=null" [textContent]="neonatalBaby.cordBlood"></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <h5 class="dashboard-title" *ngIf="dischargeSummary && dischargeSummary.babySummary">Baby Details</h5>
                                <span *ngIf="dischargeSummary && dischargeSummary.babySummary" [innerHTML]="dischargeSummary.babySummary"></span>

                            </div>
                            <div class="mt-2">
                                <div *ngIf="bookingHeader && bookingHeader.length >0">
                                    <div><h4>Investigation Done</h4> </div>
                                    <table class="tableBorder" style="width:100%">
                                        <thead>
                                            <tr class="tableBorder">
                                                <th class="tableBorder"><h5>Date</h5></th>
                                                <th class="tableBorder"><h5>Test Name</h5> </th>
                                                <th class="tableBorder"><h5>Result</h5></th>
                                                <th class="tableBorder"><h5>Normal Value</h5></th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of bookingHeader;let i =index;">
                                                <td class="tableBorder" [textContent]="item.uploadedDate"></td>
                                                <td class="tableBorder" [textContent]="item.testName"></td>
                                                <td class="tableBorder" [textContent]="item.testCode"></td>
                                                <td class="tableBorder" [textContent]="item.status"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="border-top-dotted mt-2">
                            <p class="mt-2">  The Summary has been explained to me in a language which I understand</p>
                        </div>
                        <h4 class="text-secondary mt-2 ml-2">ఈ సారాంశం నాకు అర్థమయ్యే భాషలో వివరించబడింది.</h4>
                        <div class="row mt-2">
                            <p class="col-6">Name of the patient / Attendant:</p>
                            <p class="col-6">Signature:</p>
                        </div>
                        <div class="detailsBlock">
                            <div>
                                <p class="text-center">“For Birth certificate, please contact Circle No.Amberpet 16, Ward No. Himayat Nagar 79,</p>
                                <p class="text-center"> Opp: GHMC building, 6th Floor,Opposite GPO,Abids Hyderabad with reference of baby's case summary</p>
                            </div>
                            <h4>How to apply for the Birth Certificate</h4>
                            <p class="mt-2">1.Birth certificate can be collected after receiving Acknowledgement number from GHMC on registered mobile number</p>
                            <p class="mt-2">
                                2.Visit the nearest Mee-Seva centre
                            </p>
                            <p class="mt-2">
                                3.Please carry your baby's discharge summary and parent's aadhar card xerox copies while applying for a birth certificate and baby
                                name enrolment process
                            </p>
                        </div>
                    </div>
                    <div class="col-12 border-dark border-top">
                        <div *ngIf="isFooter">
                            <banner-setting [reportName]='"Neonatal Didcharge Report Footer"'></banner-setting>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>


