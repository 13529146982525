<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">
                        <ng-container *ngIf="admission">
                            <button *ngIf="!admission?.isDischarged" (click)="openModifyModel(templateAdd,null)" class="btn btn-primary btn-sm">
                                <i class="mdi mdi-note-plus-outline mr-1"></i>Add New Nurse Note
                            </button>

                        </ng-container>
                        <button *ngIf="swap == true" (click)="openModifyModel(templateAdd,null)" class="btn btn-primary btn-sm">
                            <i class="mdi mdi-note-plus-outline mr-1"></i>Add New Nurse Note
                        </button>
                        <button *ngIf="records && records.length" (click)="OnClickPrint()" class="btn btn-primary btn-sm">
                            <i class="mdi mdi-printer mr-1"></i>Print
                        </button>
                        <!--<a *ngIf=" swap != true" href="javascript:;" class="btn btn-secondary btn-sm ml-1" (click)="onBack()">
        <span><i class="fe-arrow-left mr-1"></i> Back</span>
    </a>-->
                        <button *ngIf="swap" type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
                    </div>
                    <h4 class="page-title">Nurse Notes</h4>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 mb-4">
                <div class="overflow-auto">
                    <table class="table table-centered table-sm table-striped table-bordered mb-0">
                        <thead class="table-primary">
                            <tr>
                                <th style="width:50px;">#</th>
                                <th>Note</th>
                                <th width="110">Date</th>
                                <th [ngStyle]="{'width.px': widths.createdBy}">Created By</th>
                                <th>Modified By</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="loading">
                                <tr>
                                    <td colspan="8">
                                        <span class="mr-1 spinner-grow spinner-grow-sm"></span> Please wait while loading nurse notes ...
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="!loading && !records.length">
                                <tr>
                                    <td colspan="8">
                                        <i class="mdi mdi-alert text-warning mr-1"></i> Nurse Notes does not exists.
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="!loading && records.length">
                                <ng-container *ngFor="let item of records; let i = index;">
                                    <tr>
                                        <td [textContent]="(i + 1)"></td>
                                        <td>
                                            <div class="d-flex">
                                                <div class="mr-2"><img alt="lab service" [src]="imgOrigin + 'assets/images/days/note.png'" width="28" /></div>
                                                <div [textContent]="item.note"></div>
                                            </div>
                                        </td>
                                        <td [textContent]="(item.date || item.createdDate) | date:'dd-MM-yyyy'"></td>
                                        <td>
                                            <div>
                                                <div class="media">
                                                    <div class="media-body">
                                                        <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.createdByName"></h5>
                                                        <span class="d-block font-12 mt-1">
                                                            <small [textContent]="item.createdDate | date:'dd-MM-yyyy, hh:mm a'"></small>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <div class="media">
                                                    <div class="media-body">
                                                        <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.modifiedByName"></h5>
                                                        <span class="d-block font-12 mt-1">
                                                            <small *ngIf="item.modifiedByName " [textContent]="item.modifiedDate | date:'dd-MM-yyyy, hh:mm a'"></small>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <th>
                                            <div class="d-flex align-items-center justify-content-end">
                                                <a href="javascript:;" class="action-icon text-primary" placement="left" ngbTooltip="Edit" (click)="openModifyModel(templateAdd,item)"><i class="fe-edit"></i></a>
                                            </div>
                                        </th>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</div>

<ng-template #templateAdd>
    <form [formGroup]="addForm" (submit)="modify()">
        <div class="modal-header">
            <h4 class="modal-title">
                <i class="mdi mdi-note-plus-outline mr-1"></i>
                Add Note
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Note<code>*</code></label>
                        <textarea placeholder="Nurse Notes" rows="6" formControlName="note" class="form-control"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
            <button type="submit" class="btn btn-primary btn-sm">
                <span *ngIf="submitting"><span class="spinner-grow spinner-grow-sm mr-1"></span> please wait ...</span>
                <span *ngIf="!submitting"><i class="mdi mdi-check-bold mr-1" placement="right"></i>Submit</span>
            </button>
        </div>
    </form>
</ng-template>
