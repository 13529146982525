
import { HttpService } from '../services/http.service';
import { NotifyService } from '../services/notify.service';
import { ApiResources } from "../helpers";
import { Setting } from "@shared/entities";
import { Observable, lastValueFrom } from 'rxjs';

export class WhatsAppService {
    isActive: boolean =  null;
    constructor() {

        this.isActive = null;

    }


  

    async getCommonSettings(body: Record<any, any>, httpService: HttpService, notifyService: NotifyService, endPoint: string): Promise<boolean | null> {
      

        try {
            
            const response: Setting[] = await lastValueFrom(
                httpService.get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), { name: "WhatsAppMsgService" })
            );

            this.isActive = response.length > 0 ? response[0].active : false;
            return this.isActive;
        } catch (error) {
            console.error('Error fetching settings:', error);
            return null;
        }
    }

    async sendWhatsAppMessageAsync(body: Record<any, any>, httpService: HttpService, notifyService: NotifyService, endPoint: string = "SendMessage") {   

        if (this.isActive == null) {
            this.isActive = await this.getCommonSettings(body, httpService, notifyService, endPoint);

          
            
        }

        if (this.isActive==false)
        {
            console.log("");
        }
        else if (this.isActive == true) {

            const settings = JSON.parse(localStorage.getItem("settings"));
            var url = settings.whatsAppService.base + endPoint;
            var contactNumber = settings.whatsAppService.contactNumber;
            var foundationName = settings.whatsAppService.practiceName;

            body['FoundationName'] = foundationName;
            body['PracticeName'] = foundationName;
            body["ContactNumber"] = contactNumber;

            httpService.post(url, body).subscribe((response: any) => {
                notifyService.successToast("The whatsapp message has sent !!")
            },
                (error: any) => {
                    notifyService.errorToast("Could not send whatsapp message" + error); console.log(error)
                });
        }


    }


}