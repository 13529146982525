
<div *ngIf="!isPrintLogo" class="col-12 p-0">
    <div>
        <pharmacy-header></pharmacy-header>
    </div>


</div>
<table class="w-100">

    <thead>
        <tr>
            <td>
                <!--place holder for the fixed-position header-->
                <div class="page-header-space"></div>
            </td>
        </tr>
    </thead>

    <tbody>
        <tr>
            <td>
                <!--*** CONTENT GOES HERE ***-->
                <div>
                    <div class="min-height-80 mb-3">
                        <div class=" border-dark py-2 mt-lg-2">
                            <h5 class="text-center">PURCHASE ORDER</h5>
                        </div>
                        <div>
                            <div class="overflow-auto">
                                <table class="w-100 border-dark border">
                                    <tr class="vertical-align-top">
                                        <td class="border-bottom border-right border-dark w-50"><h5 class="mb-0">SUPPLIER DETAILS</h5></td>

                                        <td class="border-bottom border-dark">
                                            <span class="d-inline-block w-150px pl-1">From</span>
                                            <h5 class="d-inline-block text-uppercase" *ngIf="practice">
                                                <span [textContent]="': '+practice.fullName"></span>
                                            </h5>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td class="border-bottom border-right border-dark text-uppercase">
                                            <h5 [textContent]="selectedSupplier.name"></h5>
                                        </td>
                                        <td class="border-bottom border-dark pl-1">
                                            <span class="d-inline-block w-150px">CIN No</span><h5 class="d-inline-block" [textContent]="': '+practice[0].practiceTIN || '-----------'"></h5>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="border-bottom border-right border-dark">
                                            <span>
                                                <span [textContent]="selectedSupplier.address || '----'"></span>
                                            </span>
                                            <span *ngIf="selectedSupplier.city">
                                                &nbsp;,<span [textContent]="selectedSupplier.city"></span>
                                            </span>
                                            <span *ngIf="selectedSupplier.pinCode">
                                                &nbsp;-<span [textContent]="selectedSupplier.pinCode"></span>
                                            </span>
                                        </td>
                                        <td class="border-bottom border-dark pl-1">
                                            <span class="d-inline-block w-150px">GST No</span><h5 class="d-inline-block" [textContent]="': '+ '-----------'"></h5>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="border-bottom border-right border-dark">
                                            <span class="d-inline-block w-130">PAN</span><h5 class="text-capitalize d-inline-block" [textContent]="': '+selectedSupplier.pan|| '----'"></h5>
                                        </td>

                                        <td class="border-bottom border-dark pl-1">
                                            <span class="d-inline-block w-150px">PO Date</span>
                                            <h5 class="d-inline-block" [textContent]="': ' + (date | date:'dd/MM/yyyy') ||  '----'"></h5>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td class="border-bottom border-right border-dark">
                                            <span class="d-inline-block w-130">GST No</span><h5 class="text-capitalize d-inline-block" [textContent]="': '+ selectedSupplier.gstNo"></h5>
                                        </td>
                                        <td class="border-bottom border-dark">
                                            <span class="d-inline-block white-space-nowrap ">Supplier Quotation No</span> <h5 class="d-inline-block" [textContent]="': '+'---'"></h5>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td class="border-bottom border-right border-dark">
                                            <div>
                                                <span class="d-inline-block w-130">Contact Person</span><h5 class="mb-0 text-uppercase d-inline-block" [textContent]="': '+selectedSupplier.contactPersonName"></h5>
                                            </div>
                                        </td>

                                        <td class=" border-dark ">
                                            <span class="w-auto d-inline-block white-space-nowrap ">Supplier Quotation Date</span> <h5 class="d-inline-block" [textContent]="': '+'---'"></h5>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td class="border-dark border-right">
                                            <div *ngIf="selectedSupplier.mobile" class="mb-0">
                                                <span class="d-inline-block w-130">Phone No</span><h5 class="d-inline-block" [textContent]="': '+selectedSupplier.mobile"></h5>
                                            </div>
                                        </td>

                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="mt-3">
                            Sir,<br /> Please supply the following items at the earliest, as per the finalised rate :
                        </div>
                        <div class="mt-3">
                            <div class="overflow-auto">
                                <table class="border-dark  w-100">
                                    <tr class="white-space-nowrap vertical-align-top">
                                        <th class="border text-center border-dark">S.No.</th>
                                        <th class="border-top text-center border-bottom border-right border-dark w-250px">Product Details</th>
                                        <th class="border-top text-center border-bottom border-right border-dark w-250px">Manfacturer </th>
                                        <th class="border-top text-center border-bottom border-right border-dark">Unit</th>
                                        <th class="border-top text-center border-bottom border-right border-dark">Qty </th>
                                        <th class="border-top text-center border-bottom border-right border-dark">Free</th>
                                        <th class="border-top text-center border-bottom border-right border-dark">Rate</th>
                                        <th class="border-top text-center border-bottom border-right border-dark">Dis(%)</th>
                                        <th class="border-top text-center border-bottom border-right border-dark">DisAmt</th>
                                        <th class="border-top text-center border-bottom border-right border-dark">GST(%)</th>
                                        <th class="border-top text-center border-bottom border-right border-dark">Tax</th>
                                        <th class="border-top text-center border-bottom border-right border-dark">Amount</th>
                                    </tr>
                                    <tr *ngFor="let prod of productFormArray.controls;index as j;" class="vertical-align-top">
                                        <td class="border-bottom border-left text-center border-right border-dark">
                                            <span [textContent]="j+1"></span>
                                        </td>
                                        <td class="border-bottom border-right border-dark pl-1">
                                            <div class="text-uppercase" [textContent]="prod.value.productName"></div>
                                            <span class="text-capitalize font-14" [textContent]="prod.value.genericName"></span>
                                        </td>
                                        <td class="border-bottom border-right border-dark pl-1">
                                            <div class="text-uppercase" [textContent]="prod.value.companyName"></div>
                                        </td>

                                        <td class="border-bottom border-right border-dark text-center">
                                            <span [textContent]="prod.value.purchaseUnitQuantity"></span>
                                            <span [textContent]="prod.value.purchaseUnitName"></span>
                                        </td>
                                        <td class="border-bottom border-right border-dark text-center">
                                            <span [textContent]="prod.value.quantity"></span>
                                        </td>
                                        <td class="border-bottom border-right border-dark text-center">
                                            <span [textContent]="prod.value.free"></span>
                                        </td>
                                        <td class="border-bottom border-right border-dark text-center">
                                            <span [textContent]="prod.value.purchaseRate | currency:'INR'"></span>
                                        </td>

                                        <td class="border-bottom border-right border-dark text-center">
                                            <span [textContent]="'Dis%'"></span>
                                        </td>
                                        <td class="border-bottom border-right border-dark text-center">
                                            <span [textContent]="'0'"></span>
                                        </td>
                                        <td class="border-bottom border-right border-dark text-center">
                                            <span [textContent]="(prod.value.tax || 0) +'%'"></span>
                                        </td>
                                        <td class="border-bottom border-right border-dark text-center">
                                            <span [textContent]="(prod.value.taxAmount || 0) | currency:'INR'"></span>
                                        </td>

                                        <td class="border-bottom border-right border-dark text-center">
                                            <span [textContent]="(prod.value.netAmount || 0) | currency:'INR'"></span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between flex-wrap mt-2">
                            <div> <h5 class="d-inline-block">Rupees in Words :&nbsp;</h5> <span class="text-capitalize" [textContent]="amtInWords + ' Only' "></span></div>
                            <div><span class="white-space-nowrap">Total :&nbsp;</span><h5 class="d-inline-block" [textContent]="(totalNet   || 0) | currency:'INR'"></h5></div>
                        </div>
                        <div class="d-flex justify-content-between flex-wrap mt-2">
                            <div><span class="white-space-nowrap">Taxable Amount :&nbsp;</span><h5 class="d-inline-block" [textContent]="((totalNet- taxValue ) || 0) | currency:'INR'"></h5></div>
                            <div><span class="white-space-nowrap">Total Tax :&nbsp;</span><h5 class="d-inline-block" [textContent]="(taxValue  || 0) | currency:'INR'"></h5></div>
                            <div><span class="white-space-nowrap">Discount Amount :&nbsp;</span><h5 class="d-inline-block">0</h5></div>
                        </div>
                        <div class="border-bottom-dotted border-top-dotted mt-2 py-1">
                            <h5>Terms and conditions</h5>
                        </div>
                        <div class="mt-0 preserve-whitespace">
                            <div class="d-flex justify-content-between flex-wrap mt-1" [textContent]="termsAndConditions">

                            </div>
                        </div>
                        <div class="d-flex justify-content-between flex-wrap mt-5">
                            <!--<div><span class="white-space-nowrap">Prepared By :&nbsp;</span><h5 class="d-inline-block border-bottom-dotted border-dark min-width100px" [textContent]="item.createdByName">&nbsp;</h5></div>-->
                            <div><span class="white-space-nowrap">Approved By :&nbsp;</span><h5 class="d-inline-block border-bottom-dotted border-dark min-width100px">&nbsp;</h5></div>
                            <div><span class="white-space-nowrap">Supplier acknowledgement :&nbsp;</span><h5 class="d-inline-block border-bottom-dotted border-dark min-width100px">&nbsp;</h5></div>
                        </div>

                    </div>
                </div>

            </td>
        </tr>
    </tbody>

    <tfoot>
        <tr>
            <td>
                <!--place holder for the fixed-position footer-->
                <div class="page-footer-space"></div>
            </td>
        </tr>
    </tfoot>

</table>