import { Injectable } from "@angular/core";


@Injectable()
export class CimsService {

    getApiPath() {
        let localSettings = localStorage.getItem("settings");
        let setting = JSON.parse(localSettings);
        return setting["cimsAPI"];
    }
        
}

