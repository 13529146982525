import { Component, OnDestroy, OnInit, Input, HostListener } from '@angular/core';
import { OB } from '../../../areas/admin/full-transcript/pages/ob-report/ob.namespace';
import { takeUntil, finalize } from 'rxjs/operators';
import { Page, IUserAccount, PatientProfile, IEncounterResource } from '../../models';
import { HttpService, AppData, EncounterCommunication, TimelineToggleService } from '../../services';
import { ObEncounter } from "@shared/models/ob-encounter.model";
import { Appointment, AppointmentDashboard, PreviousAppointment } from '../../entities';
import { ApiResources } from '../../helpers';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { GYN } from '../../../areas/admin/gyn-encounter/gyn.namespace';
import { json } from 'stream/consumers';
import moment from 'moment';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'ob-header',
    templateUrl: './ob-header.html',
    styleUrls: ['./ob-header.css']
})
export class ObHeaderComponent implements OnDestroy, OnInit {
    @Input() patientId: string;
    @Input() appointmentId: string;
    @Input() encounterType: string;
    ancCards: OB.AncCards;
    page: Page;
    // encryptedPatientId: any;
    isAdmission: any;
    // appointmentId: any;


    obEncounter: ObEncounter;
    appointments: any;
    oldAppointment: any;
    vitals: any;
    allergies: OB.Allergies;
    allergiesdata: IEncounterResource;
    antinantalRisk: OB.AntinantalRisk;
    ancCardGeneration: OB.AncCardGeneration;
    ancVisit: OB.Measure;
    encounterCommunicationSubscription: Subscription;
    encounterAppointmentSubscription: Subscription;
    appointment: AppointmentDashboard;
    ancCardRecord: OB.AncCardGeneration;
    patient: PatientProfile;
    visitNumber: number;
    specialFeature: OB.SpecialFeature;
    ancCardClosed: OB.AncCardClosure;
    visit: number;
    roleId: number;
    commonData: IEncounterResource;
    gplaJson: GYN.NewGynHistory;
    gpla: OB.AncCards;
    sideNav: boolean;
    gacal: any;
    visitRecord: number;
    name: string;
    specialFetures: string;
    previousMeasureValues: OB.Measure;
    generalExamination: any;
    constructor(
        private readonly httpService: HttpService,
        private readonly appData: AppData,
        //private readonly notifyService: NotifyService,
        private readonly encounterCommunication: EncounterCommunication,
        private readonly router: Router,
        public timelineService: TimelineToggleService,
        public readonly datepipe: DatePipe


    ) {
        this.page = new Page();

    }

 
    private findAncCard() {
        const request = {
            patientId: this.appointment.patientId,
            appointmentId: this.appointment.appointmentId,
            isAdmission: this.isAdmission
        }
        this.httpService.get(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchANC), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: OB.AncCardGeneration) => {
                this.ancCardRecord = response;
                this.paddingTop();
            });
    }


    private fetchAppointmentCounts() {
    
        const request = {
            patientId: this.appointment.patientId,
            appointmentId: this.appointment.appointmentId,
            isadmission: this.isAdmission

        };
        this.httpService.post(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.findVisitNumber), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: any) => {
                var records = response;
                if (records != null) {
                    var measures = JSON.parse(records.generalExamination);
                    if (measures) {
                        this.previousMeasureValues = measures;
                        
                    }
                     this.visitRecord = measures != null && measures.visitNumber ? (measures.visitNumber + 1) : 1;
                    var ancCardDetal = JSON.parse(records.ancCaredClosure);
                    if (ancCardDetal && ancCardDetal.ancCardClosed == "Yes") {
                        this.visitNumber = 1;
                    }
                    else {
                        this.visitNumber = this.visitRecord;
                    }
                }
                else {
                    this.visitNumber = 1;
                }
                this.visit = this.generalExamination && this.generalExamination.visitNumber ? this.generalExamination.visitNumber : this.visitNumber;
              
            });
        this.paddingTop();
    }

    private findDashboard() {
        const request = {
            encryptedAppointmentId: this.appointmentId,
            encryptedProviderId: this.page.userAccount && this.page.userAccount.encryptedReferenceId ? this.page.userAccount.encryptedReferenceId : null,
            encryptedPatientId: this.patientId,
            isAdmission: this.isAdmission
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<[ObEncounter, Appointment, PreviousAppointment]>(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.findEncounterData), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: [ObEncounter, Appointment, PreviousAppointment]) => {
                this.obEncounter = response["dashboard"];
                this.appointments = response["appointments"];
                this.oldAppointment = response["oldAppointment"];              
                this.patchingValue();
                this.fetchAppointmentCounts();
                this.paddingTop();
            });
    }

    patchingValue() {
        if (this.obEncounter && this.obEncounter.obEncounterId) {
            this.vitals = this.obEncounter.vitals ? JSON.parse(this.obEncounter.vitals) : null;
            this.antinantalRisk = this.obEncounter.antinantalRisk ? JSON.parse(this.obEncounter.antinantalRisk) : null;
            this.ancCards = this.obEncounter.ancCard ? JSON.parse(this.obEncounter.ancCard) : null;
            this.allergies = this.obEncounter.allergies ? JSON.parse(this.obEncounter.allergies) : null;
            this.ancCardGeneration = this.obEncounter.ancCardGeneration ? JSON.parse(this.obEncounter.ancCardGeneration) : null;
            this.specialFeature = this.obEncounter.specialFeature ? JSON.parse(this.obEncounter.specialFeature) : null;
            this.ancCardClosed = this.obEncounter.ancCardCloseCommonData ? JSON.parse(this.obEncounter.ancCardCloseCommonData) : null;
            this.gpla = this.obEncounter.gpla ? JSON.parse(this.obEncounter.gpla) : null;
            this.generalExamination = this.obEncounter.generalExamination ? JSON.parse(this.obEncounter.generalExamination) : null;
            if (this.ancCards) {
                if (this.ancCards.lmpStatus == "Unknown") {
                    this.onCalculateGA(this.ancCards.ultraSoundEdd ? this.ancCards.ultraSoundEdd : this.ancCards.edd ? this.ancCards.edd : null, this.ancCards.lmpStatus);
                }
                else if (this.ancCards.lmpStatus == "Known") {
                    this.onCalculateGA(this.ancCards.lmp, this.ancCards.lmpStatus);
                } else {
                    this.onCalculateGaForOthers(this.ancCards);
                }

            }
            this.ancVisit = this.obEncounter.generalExamination ? JSON.parse(this.obEncounter.generalExamination) : null;
            this.specialFetures = this.specialFeature && this.specialFeature.specialFeature ? this.specialFeature.specialFeature : this.ancCards && this.ancCards.specialFeature ? this.ancCards.specialFeature : null
        }
    }


    onCalculateGaForOthers(ancCard: OB.AncCards) {
        var lmpStatus = ancCard.lmpStatus;
        var number = lmpStatus == "D5 Embryo transfer" ? 19 : 17;
        var dateOfTransfer = ancCard.dateOfTransfer;
        const result = new Date(dateOfTransfer);
        result.setDate(result.getDate() - number);
        var lmp = this.datepipe.transform(result, 'yyyy-MM-dd');
        const edd = new Date(lmp);
        edd.setDate(edd.getDate() + 280);


        const startDate = moment(lmp);
        const appointmentDate = moment(this.appointment.appointmentDate);
        const daysBetween = appointmentDate.diff(startDate, 'days');
        const weeks = Math.floor(daysBetween / 7)
        var remainingDays = parseFloat((daysBetween % 7 * 0.1).toFixed(1));

        this.ancCards.ga = weeks + remainingDays;
    }

    public onCalculateGA(value: any, lmpStatus?: any) {
        const request = {
            value: value,
            lmpStatus: lmpStatus,
            appointmentDate: this.appointment.appointmentDate
        }
        this.httpService.get(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.calculateGA), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: string) => {
                this.gacal = response;
                this.ancCards.ga = this.gacal;

            });

    }
    toggleIt() {
        this.timelineService.toggle();
        if (this.timelineService.showBox == false) {
            $("body")[0].setAttribute("data-timeline-size", "timelineabsent");
        } else {
            $("body")[0].setAttribute("data-timeline-size", "timeline");
        }
    }
    toggleSideNav() {
        $(".sidebar-main-menu")[0].classList.toggle("removeLeft");
        if ($("body")[0].getAttribute('data-sidebar-size') === "default") {
            $("body")[0].setAttribute("data-sidebar-size", "condensed");
            this.sideNav = true;
        } else if ($("body")[0].getAttribute('data-sidebar-size') === "condensed") {
            $("body")[0].setAttribute("data-sidebar-size", "default");
            this.sideNav = false;
        }
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    this.isAdmission = url.split("/")[4] === "a";
                    this.appointmentId = url.split("/")[url.split("/").length - 3];
                    this.roleId = this.page.userAccount.roleId;
                    this.encounterCommunicationSubscription = this.encounterCommunication.encounter.subscribe((value: any) => {
                        if (this.appointmentId == "b" || this.appointmentId == "a") {
                            var currentUrl = this.router.url;
                            this.appointmentId = currentUrl.split("/")[currentUrl.split("/").length - 4];
                        }
                        this.findCommonData();
                        if (value["value"]) {
                            this.obEncounter = value["value"];
                            this.patchingValue();
                        }
                      
                    });
                    this.name = this.page.userAccount.fullName;
                    this.encounterAppointmentSubscription = this.encounterCommunication.appointmentFecthDetail.subscribe((value: AppointmentDashboard) => {
                        this.appointment = value;
                        this.findAncCard();
                        this.findDashboard();
                        this.paddingTop();

                    });
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }
    @HostListener('window:resize')
    onResize() {
        this.paddingTop();
    }
    @HostListener('window:load')
    onLoad() {
        setTimeout(() => { this.paddingTop(); }, 1500)
    }
    @HostListener('window:hashchange')
    onhashchange() {
        setTimeout(() => { this.paddingTop(); }, 500)
    }
    paddingTop() {
        if (document.getElementById("header") != null && document.getElementsByClassName("content")[0] != null) {
            var paddingTopValue = document.getElementById("header").offsetHeight;
            var content = document.getElementsByClassName("content")[0];
            content.setAttribute('style', `padding-top:${paddingTopValue}px`);
        }
    }
    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.encounterCommunicationSubscription.unsubscribe();
        this.encounterAppointmentSubscription.unsubscribe();
    }



    private findCommonData() {
        const request = {
            encryptedappointmentid: this.appointmentId,
            encryptedpatientid: this.patientId
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<IEncounterResource>(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.findEncounterData), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: IEncounterResource) => {
                this.commonData = response;
                this.gplaJson = this.commonData.jsonString != null ? JSON.parse(this.commonData.jsonString) : null;

            });
    }
    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }
}