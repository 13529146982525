<div class="report bg-white position-relative">
    <div class=" overflow-auto" *ngIf="isPrintLogo">
        <banner-setting [reportName]='"Appointment Invoive"'></banner-setting>
    </div>
    <div class="w-100 overflow-auto border_style">
        <div class="d-inline-block w-100 text-center ">
            <div><h4 class="btp pt-2" [textContent]="'Final Bill of Supply - Diagnostics Bill'"></h4></div>
            <div><h5 class="font-weight-normal pt-1 pb-2" [textContent]="'(Health Care Service - SAC)'"></h5></div>
        </div>
    </div>
    <ng-container *ngIf="report">
        <div>
            <table class="table table-sm table-borderless table-centered bg-white bt_bb m-0">
                <tbody>
                    <tr>
                        <td class="pt-2 w-10px white-space-nowrap">Patient Name :</td>
                        <td class="pt-2" *ngIf="report.patientName">
                            <h5 class="text_uppercase" [textContent]="report.patientName"></h5>

                        </td>
                        <td class="pt-2 w-10px white-space-nowrap">UMR No :</td>
                        <td class="pt-2" *ngIf="report.umrno">
                            <h5 [textContent]="report.umrno"></h5>
                        </td>
                        <td class="pt-2 w-10px white-space-nowrap">Bill Date/Time :</td>
                        <td class="pt-2" *ngIf="report.createdDate">
                            <h5 class="text_uppercase" [textContent]="report.createdDate | date:'dd/MM/yyyy h:mm a'"></h5>
                        </td>
                    </tr>
                    <tr>
                        <td class="w-10px white-space-nowrap">Location :</td>
                        <td *ngIf="report.locationName">
                            <h5 class="text_uppercase" [textContent]="report.locationName || 'N/A'"></h5>
                        </td>
                        <td class="w-10px white-space-nowrap">Bill No :</td>
                        <td *ngIf="report.billNumber">
                            <h5 class="text_uppercase" [textContent]="report.billNumber || 'N/A'"></h5>
                        </td>
                        <td class="w-10px white-space-nowrap">Age/Sex :</td>
                        <td class="d-flex">
                            <h5 class="text_uppercase" *ngIf="report.ageInYMD" [textContent]="report.ageInYMD"></h5>
                            <h5 class="text_uppercase" *ngIf="report.gender" [textContent]="(report.gender === 'F' ? '/Female' : '/Male') "></h5>
                        </td>
                    </tr>
                    <tr>
                        <td class="pb-2 w-10px white-space-nowrap">Doctor Name :</td>
                        <td class="pb-2">
                            <h5 class="text_uppercase" [textContent]="report.providerName"></h5>
                        </td>
                        <td class="pb-2 w-10px white-space-nowrap">
                            Req No :
                        </td>
                        <td class="pb-2">
                            <h5 class="text_uppercase" [textContent]="report.requisitionNumber"></h5>
                        </td>
                        <td class="pb-2 w-10px white-space-nowrap">Mobile No :</td>
                        <td class="pb-2">
                            <h5 class="text_uppercase" [textContent]="report.mobile"></h5>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row bg-white">
            <div class="col-12">
                <div>
                    <table class="border-dark border_font" width="100%" border="0" cellspacing="0" cellpadding="5">
                        <thead>
                            <tr>
                                <th class="border_th pb-2 pt-2">S.No</th>
                                <th class="border_th pb-2 pt-2">Dept Name</th>
                                <th class="border_th pb-2 pt-2">MachineName</th>
                                <th class="border_th pb-2 pt-2">Investigations</th>
                                <th class="border_th pb-2 pt-2">Status</th>
                                <th class="border_th pb-2 pt-2">Dis Amount</th>
                                <th class="border_th pb-2 pt-2">Reg Charges</th>
                                <th class="border_th text-right pb-2 pt-2">Emergency</th>
                                <th class="border_th text-right pb-2 pt-2">Amount(Rs)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="  pt-2 pb-1">
                                    <span class="mb-0 mt-0" [textContent]="1"></span>
                                </td>
                                <td class="  pt-2 pb-1">
                                    <span class="mb-0 mt-0 text-uppercase">Scan</span>
                                </td>
                                <td class="  pt-2 pb-1">
                                    <span class="mb-0 mt-0 text-uppercase" [textContent]="report.machineName"></span>
                                </td>
                                <td class="  pt-2 pb-1">
                                    <span class="mb-0 mt-0 text-uppercase" [textContent]="report.scanTestName"></span>
                                </td>
                                <td class="  pt-2 pb-1">
                                    <ng-container [ngSwitch]="report.status">
                                        <span *ngSwitchCase="'B'">Booked</span>
                                        <span *ngSwitchCase="'R'">Rescheduled</span>
                                        <span *ngSwitchCase="'C'">Canceled</span>
                                        <span *ngSwitchDefault>Unknown</span>
                                    </ng-container>
                                </td>
                                <td class="  pt-2 pb-1">
                                    <span class="mb-0 mt-0 text-uppercase" [textContent]="report.discountAmount"></span>
                                </td>
                                <td class="  pt-2 pb-1">
                                    <span class="mb-0 mt-0 text-uppercase" [textContent]="report.regCharges"></span>
                                </td>
                                <td class="text-right  pt-2 pb-1">
                                    <span class="mb-0 mt-0 "></span>
                                </td>
                                <td class="text-right pt-2 pb-1">
                                    <span class="mb-0 mt-0 " [textContent]="report.netAmount || 0 | currency :'INR'"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="col-12 mtop pad_left pr-0">
                        <tr>
                            <td>
                                <span>Rupees:</span>
                            </td>
                            <td>
                                <h5 class=" first-letter"> {{amountInWord}} </h5>
                            </td>
                            <th colspan="5" class="text-right p-0"><span>Total Amount Rs :</span></th>
                            <td class="text-right w-75p">
                                <h5 class="p-0 "> <span [textContent]="( report.netAmount || 0) | currency:'INR'"></span></h5>
                            </td>
                        </tr>
                        <tr>
                            <td class="p_width">
                                Pay Mode :
                            </td>
                            <td>
                                <ng-container>
                                    <table>
                                        <ng-container *ngIf="report.payTypes?.length > 1; else singlePayType">
                                            <tr *ngFor="let payType of report.payTypes">
                                                <td class="DataFontWeight" [textContent]="payType.payTypeName">
                                                </td>
                                                <td>
                                                    <span class="DataFontWeight">:</span>
                                                    <span>&nbsp; &nbsp;</span>
                                                    <h5 class="d-inline DataFontWeight" [textContent]="payType.amountPaid || 0 | currency :'INR'"></h5>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <ng-template #singlePayType>
                                            <tr>
                                                <td class="d-inline DataFontWeight" [textContent]="report.payTypes[0]?.payTypeName"></td>
                                            </tr>
                                        </ng-template>
                                    </table>
                                    <div>
                                        <div *ngIf="!isDuplicate && report.paymentType === 'P'" class="water_mark">
                                            <div id="backgroundOfwaterMark"> <pre class="text-warning opacity50 d-inline">Partial Paid</pre></div>
                                        </div>
                                        <div *ngIf="isDuplicate == true" class="water_mark">
                                            <div id="backgroundOfwaterMark"> <pre class="text-danger opacity50 d-inline">Duplicate Invoice</pre></div>
                                        </div>
                                    </div>
                                </ng-container>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Diagnosis :
                            </td>
                            <td>
                                <h5>Scan</h5>
                            </td>
                            <td colspan="5" class="text-right p_width p-0">
                                <span>Paid Amount Rs :</span>
                            </td>
                            <td class="text-right w-75p">
                                <h5 class=" p-0 "> <span [textContent]="(report.paidAmount || 0) | currency:'INR'"></span></h5>
                            </td>
                        </tr>
                    </table>
                    <table>
                        <tr>
                            <td *ngIf="practiceLocations[0] && practiceLocations[0].optionalText1">
                                <span> Note:{{practiceLocations[0].optionalText1}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="d-flex">
                                Remarks &nbsp;: <h5 class="ms-2 text_uppercase" [textContent]="report.bookScanRemarks ? report.bookScanRemarks : '---'"></h5>
                            </td>
                        </tr>
                        <tr>
                            <td class="d-flex">
                                Indication &nbsp;: <h5 class="ms-2 text_uppercase" [textContent]="report.indication ? report.indication : '---'"></h5>
                            </td>
                        </tr>
                        <tr>
                            <td *ngIf="practiceLocations[0] && practiceLocations[0].appointmentNo">
                                <span> {{practiceLocations[0].appointmentNo}}</span>
                            </td>
                        </tr>
                    </table>
                    <table>
                        <tr class="d-flex">
                            <td class="d-flex">
                                User Name : <h5 class="ms-2 text_uppercase" [textContent]="page.userAccount.fullName"></h5>
                            </td>
                            <td class=" pl-5 d-flex">
                                Password : <h5 class="ms-2 text_uppercase"></h5>
                            </td>
                        </tr>
                    </table>
                    <table>
                        <tr class="col-12">
                            <td>
                                <div>
                                    <practice-content></practice-content>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <table class="border_width">
                        <tr class="col-12">
                            <td class="col-6"> </td>
                            <td>
                                Print Date/Time:<span class="d-inline" [textContent]="currentDate | date :'dd/MM/yyyy, h:mm a'"></span>. &nbsp;&nbsp;
                                <span class="text_uppercase d-inline" [textContent]="page.userAccount?.fullName || ''"></span>
                            </td>
                        </tr>
                    </table>
                </div>
                <div *ngIf="isFooter">
                    <banner-setting [reportName]='"Appointment Invoice Footer"'></banner-setting>
                </div>
            </div>
        </div>
    </ng-container>
</div>