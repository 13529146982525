import { Component, ViewEncapsulation, OnInit, OnDestroy, Input } from "@angular/core";
import { IUserAccount, Page, IResource } from "@shared/models";
import { HttpService, PrintOptionService, BannerService, ResourceService, AppData } from "@shared/services";
import { PayTypes, PharmacySaleBill, RetailStore, ScanBooking } from "@shared/entities";
import { ApiResources } from "@shared/helpers";
import { finalize, takeUntil } from "rxjs/operators";
import * as numberToWords from 'number-to-words';

class GST {
    gst: number;
    taxableAmount: number;
    cgst: number;
    sgst: number;
    igst: number;
    discount?: number;
}
@Component({
    templateUrl: "./scan-report-new.html",
    styleUrls: ['./scan-report-new.widget.css'],
    selector: "scan-report-new",
    encapsulation: ViewEncapsulation.None
})
export class ScanReportNewReportWidget implements OnInit, OnDestroy {
    @Input() bookScanAppointmentId: number;
    @Input() isDuplicate?: boolean;
    page: Page;
    loadingInvoice: boolean;
    loading: boolean;
    currentDate: Date;
    relationType: string;
    amountInWord: string;
    report: ScanBooking;
    practiceLocations: Array<IResource>;
    formattedText: string;
    practices: Array<string>;
    @Input() isPrintLogo: boolean;
    bannerBasics: boolean;
    searchFailed = false;
    accountId = 0;
    encryptedAppointmentId: string;
    @Input() isFooter: boolean;



    constructor(
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
        private readonly bannerService: BannerService,
        private readonly appData: AppData,
        private readonly resourceService: ResourceService,
    ) {
        this.page = new Page();
        this.currentDate = new Date();
        this.practiceLocations = new Array<IResource>();
    }

    private fetchInvoice(bookScanAppointmentId?: number) {
        this.loadingInvoice = true;
        this.httpService.get<Array<ScanBooking>>(ApiResources.getURI(ApiResources.bookScanAppointment.base, ApiResources.bookScanAppointment.fetchSelectedAppointment), { bookScanAppointmentId: bookScanAppointmentId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingInvoice = false))
            .subscribe(
                (response: Array<ScanBooking>) => {
                    this.report = new ScanBooking();
                    this.report.payTypes = new Array<PayTypes>();
                    this.report = response[0];
                    this.report.payTypes = response[0].payTypes;

                    switch (this.report.relation) {
                        case "Husband":
                            this.relationType = "W/O";
                            break;
                        case "Wife":
                            this.relationType = "H/O";
                            break;
                        case "Father":
                            this.relationType = this.report.gender === "M" ? "S/O" : "D/O";
                            break;
                        case "Sister":
                            this.relationType = this.report.gender === "F" ? "Sis/O" : "B/O";
                            break;
                        case "Brother":
                            this.relationType = this.report.gender === "F" ? "Sis/O" : "B/O";
                            break;
                        case "Son":
                            this.relationType = this.report.gender === "M" ? "F/O" : "M/O";
                            break;
                        case "Daughter":
                            this.relationType = this.report.gender === "M" ? "F/O" : "M/O";
                            break;
                        default:
                            this.relationType = ""; // Default value if no match is found
                            break;
                    }

                    let amountInWords = numberToWords.toWords(this.report.netAmount);
                    this.amountInWord = amountInWords + ' rupees only.';
                });
    }

    private fetchPracticeLocation() {
        this.loading = true;
        this.resourceService.fetchPracticeLocations()
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<IResource>) => {
                this.practiceLocations = response;
                this.practices = this.practiceLocations[0].optionalText2.split(/\n/g);
                this.formattedText = this.practiceLocations[0].optionalText2.replace(/\n/g, '<br/>');
            });
    }

    toTitleCase(str: string): string {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchPracticeLocation();
                    this.fetchInvoice(this.bookScanAppointmentId);
                    this.currentDate = new Date();
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
}
