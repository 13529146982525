<div class="mt-1 row text-black">
    <div class="col-12">
        <div class="col border border-bottom-0 d-flex pl-1 pr-1 pt-1" style="background-color: #edfaf7;">
            <div class="col-10">
                <div>
                    <button class="btn btn-info" type="button" (click)="onChangePrintType(false)">
                        <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangePrintType(true)">
                        <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangeFooter(false)">
                        <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangeFooter(true)">
                        <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
                    </button>
                </div>
            </div>


            <div class="col-2">
                <button [useExistingCss]="true" printSectionId="geneticprint" id="printButton" ngxPrint class="close d-print-none"><i class="fe-printer" placement="left" ngbTooltip="Print"></i></button>
            </div>

        </div>

        <div id="geneticprint" class="border-left bg-white pb-2 setScroll border-right">
            <div class="report pl-4 pr-4 position-relative">
                <div class="row" *ngIf="page.loading">
                    <div class="col-12">
                        <div class="d-flex align-items-center">
                            <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                            <span class="ml-2">Please wait while generating IEncounter Prescription ...</span>
                        </div>
                    </div>
                </div>




                <div class="page-header" style="text-align: center">
                    <div class="col-12 p-0" *ngIf="isPrintLogo">
                        <banner-setting [reportName]='"Genetic Counselling Report Header"'></banner-setting>
                    </div>
                </div>

                <div class="page-footer">
                    <div class="border-dark border-bottom d-flex flex-wrap justify-content-between">
                        <div class="d-flex">
                            <div><span>Saved User :&nbsp;</span></div>
                            <div><span [textContent]="appointment.providerName"></span></div>
                        </div>
                        <div class="d-flex">
                            <div><span>Date :&nbsp;</span></div>
                            <div><span [textContent]="date | date :'d/MM/yyyy'"></span>,<span [textContent]="date | date :' h:mm a'"></span></div>
                        </div>
                        <div class="d-flex">
                            <div><span>Printed by :&nbsp;</span></div>
                            <div><span [textContent]="printedByName"></span></div>
                        </div>
                    </div>
                    <div class="col-12 p-0" *ngIf="isFooter">
                        <banner-setting [reportName]='"Genetic Counselling Report Footer"'></banner-setting>
                    </div>
                </div>


                <table class="w-100">

                    <thead>
                        <tr>
                            <td>
                                <!--place holder for the fixed-position header-->
                                <div class="page-header-space"></div>
                            </td>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>
                                <!--*** CONTENT GOES HERE ***-->
                                <div class="page">
                                    <div>
                                        <h3 class="text-underline">
                                            Genetic Counselling Summary
                                        </h3>
                                    </div>

                                    <div class="d-flex align-items-end mt-2">
                                        <div class="col-12 p-0">
                                            <table class="w-100">
                                                <tr>
                                                    <td>
                                                        <span class="m-0 ">Name</span>
                                                    </td>
                                                    <td>
                                                        <span class="m-0 ">:</span>
                                                    </td>
                                                    <td class=" text-capitalize ">
                                                        <span *ngIf="appointment && appointment.patientName" [textContent]="appointment.patientName"></span>&nbsp;<span *ngIf="appointment && appointment.patientAge">({{appointment.patientAge}}&nbsp;Years)</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span class="m-0 white-space-nowrap "> Partner’s Name</span>
                                                    </td>
                                                    <td>
                                                        <span class="m-0 ">:</span>
                                                    </td>
                                                    <td>
                                                        <span *ngIf="partnerName" [textContent]="partnerName"></span>&nbsp;<span *ngIf="age">({{age}}&nbsp;Years)</span>

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="vertical-align-top w-150px">
                                                        <div class="m-0 ">UMR No.</div>
                                                        <div class="m-0 white-space-nowrap">Date of Counselling</div>
                                                        <div class="m-0 ">Referral Doctor</div>
                                                    </td>
                                                    <td class="vertical-align-top">
                                                        <div class="m-0 ">:</div>
                                                        <div class="m-0 ">:</div>
                                                        <div class="m-0 ">:</div>
                                                    </td>
                                                    <td rowspan="2" class="vertical-align-top">
                                                        <div class="d-flex w-100">
                                                            <div class="min-width180px">
                                                                <div *ngIf="appointment" [textContent]="appointment.umrNo"></div>
                                                                <div class="white-space-nowrap" *ngIf="appointment" [textContent]="appointment.appointmentDate | date: 'dd/MM/yyyy'"></div>
                                                                <div *ngIf="appointment" [textContent]="geneticVisit.referralDoctor"></div>
                                                            </div>
                                                            <div class="border border-dark min-height-45 p-1 flex-grow-1 ml-3 text-justify">
                                                                <h4 class="d-inline-block">Counselling Request : <span *ngIf="geneticVisit && geneticVisit.geneticSummary" [innerHTML]="geneticVisit.geneticSummary"></span></h4>
                                                            </div>
                                                        </div>

                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>


                                    <div class="border-dark border-top mt-2" *ngIf=" geneticVisit && geneticVisit.templates.length >0">
                                        <h5 class="pt-1">
                                            Description Summary
                                        </h5>
                                    </div>
                                    <div class="text-justify" *ngIf=" geneticVisit && geneticVisit.templates.length >0">
                                        <p *ngFor="let item of geneticVisit.templates" class="pl-1" [innerHTML]="item.description"></p>
                                    </div>

                                    <div *ngIf="geneticVisit" class="text-justify">
                                        <p class="col-12" [innerHTML]="geneticVisit.description"></p>
                                    </div>



                                    <div *ngFor="let image of teplateDocuments">
                                        <div class="document-container">
                                            <div class="document-body">
                                                <img Image.value [src]="image.formedUrl['changingThisBreaksApplicationSecurity']" alt="URL" class="img-fluid imgstyle">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-2">
                                        <h5> Adivce</h5>
                                        <h5 class="text-underline"> For Mrs. <span *ngIf="appointment && appointment.patientName" [textContent]="appointment.patientName"></span></h5>
                                    </div>
                                    <div class="text-justify">
                                        <div class="d-flex"><div class="d-inline-block">1. </div> <div class="d-inline-block">TORCH profile</div></div>
                                        <div class="d-flex"><div class="d-inline-block">2. </div> <div class="d-inline-block">Amniocentesis, fetal karyotype/chromosomal microarray and CFTR gene testing  </div></div>
                                        <div class="d-flex"><div class="d-inline-block">3. </div> <div class="d-inline-block">Review with reports</div></div>
                                    </div>


                                    <div class="mt-4">
                                        <span class="text-capitalize" *ngIf="appointment">{{appointment.providerName}}</span>
                                    </div>
                                    <div class="text-capitalize">
                                        <span *ngIf="appointment">
                                            {{appointment.specializationName}}
                                        </span>
                                    </div>
                                    <div class="text-capitalize">
                                        <span [textContent]="practiceName"></span>
                                    </div>
                                    <div class="text-lowercase">
                                        <span class="text-black m-0 " *ngIf="appointment">
                                            Email :{{appointment.providerEmail}}
                                        </span>
                                    </div>
                                </div>

                            </td>
                        </tr>
                    </tbody>

                    <tfoot>
                        <tr>
                            <td>
                                <!--place holder for the fixed-position footer-->
                                <div class="page-footer-space"></div>
                            </td>
                        </tr>
                    </tfoot>

                </table>
            </div>
        </div>
    </div>
</div>
