
<div class="mt-1 text-black">
    <div class="container image_section p-0" style="text-align:initial;">
        <div class="card-box mb-0">
            <div class="clearfix mb-3">
                <div class="float-right row">
                    <h4 class="m-0 d-print-none heading2">Gyn Encounter Full Transcript</h4>
                    <button [useExistingCss]="true" printSectionId="gynprint" ngxPrint class="close d-print-none"><i class="fe-printer" placement="left" ngbTooltip="Print"></i></button>
                </div>
                <div>
                    <button class="btn btn-info" type="button" (click)="onChangePrintType( false)">
                        <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangePrintType(true)">
                        <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangeFooter(false)">
                        <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangeFooter(true)">
                        <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
                    </button>
                </div>
            </div>
            <div class="row" *ngIf="page.loading">
                <div class="col-12">
                    <div class="d-flex align-items-center">
                        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                        <span class="ml-2">Please wait while generating Gyn Full Transcript ...</span>
                    </div>
                </div>
            </div>
            <!--<div class="row" *ngIf=" (!fullTranscript )">
                <div class="col-12 mh-300">
                    <no-data title="Gyn Report Full Transcript"></no-data>
                </div>
            </div>-->
            <div id="gynprint">
                <ng-container *ngIf="fullTranscript">
                    <div class="col-12" *ngIf="isPrintLogo">
                        <banner-setting [reportName]='"Gyn Encounter Full Transcript Header"'></banner-setting>
                    </div><hr />
                    <div class="d-flex justify-content-between heading1 white-space-nowrap">
                        <div class="col-6 p-0">
                            <div class="text-truncate">
                                <label>Patient Name:</label>
                                <span class="pl-1 text-uppercase" [textContent]="fullTranscript.patientName"></span>
                            </div>
                            <div class="text-truncate" *ngIf="fullTranscript.patientMobile">
                                <label>Mobile No:</label>
                                <span class="pl-1" [textContent]="fullTranscript.patientMobile + ' / '"></span>
                                <span class="pl-1" *ngIf="fullTranscript.patientGender" [textContent]="(fullTranscript.patientGender | gender) + ' / '"></span>
                                <span class="pl-1" *ngIf="fullTranscript.patientAge" [textContent]="fullTranscript.patientAge +' yrs'"></span>
                                <span class="pl-1" *ngIf="!fullTranscript.patientAge" [textContent]="'0 yrs' "></span>
                            </div>
                        </div>
                        <div class="d-inline-block" *ngIf="isAdmission==false">
                            <div>
                                <label>Appointment No:</label>
                                <span class="pl-1">{{fullTranscript.appointmentNo}}</span>
                            </div>
                            <div>
                                <label>Appointment Date & Time:</label>
                                <span class="pl-1" [textContent]="(appointmentsList.appointmentDate |  date :'dd/MM/yyyy') + ' ' + appointmentsList.appointmentTimeString"></span>
                            </div>

                        </div>
                        <div class="d-inline-block" *ngIf="isAdmission==true">
                            <div>
                                <label>Admission No:</label>
                                <span class="pl-1">{{fullTranscript.admissionId}}</span>
                            </div>
                            <div>
                                <label>Admission Date:</label>
                                <span class="pl-1" [textContent]="(appointmentsList.appointmentDate |  date :'dd/MM/yyyy') + ' ' + appointmentsList.admTime"></span>
                            </div>

                        </div>
                    </div><hr />
                </ng-container>
                <ng-container *ngIf="!page.loading && fullTranscript">
                    <div class="col-12 textOfEveryVw" *ngIf="!page.loading && fullTranscript">

                        <!--<div *ngIf="documents && patientDocumentId" class="dashboard-box dashboard-table-box">
                        <h4 class="dashboard-title">Documents</h4>
                        <div class="row ml-2 pb-2 pt-1" maxFileSize="10mb">
                            <span *ngIf="documents && documents.length===1">{{documents.length}} document uploaded.</span>
                            <span *ngIf="documents && documents.length>1">{{documents.length}} documents uploaded.</span>-->
                        <!--<span *ngIf="documents && documents.maxsize>=10mb"> documents not uploaded</span>-->
                        <!--</div>
                        </div>-->
                        <div *ngIf="isAdmission==false">
                            <div class="dashboard-box dashboard-table-box" #gynCardGenerationSection *ngIf="gynCardGeneration && gynCardGeneration !== null">
                                <h4 class="dashboard-title">Gynaec card</h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="gynCardGeneration && gynCardGeneration !== null">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black">
                                                <th colspan="2">Gynaec Card</th>
                                            </tr>
                                            <tr *ngIf="gynCardGeneration.gynNo">
                                                <td class="col-8">Gynaec Number </td>
                                                <td class="col-4" [textContent]="gynCardGeneration.gynNo"> </td>
                                            </tr>
                                            <tr *ngIf="gynCardGeneration.consultantDoctor">
                                                <td class="col-8">Consultant Doctor</td>
                                                <td class="col-4" [textContent]="gynCardGeneration.consultantDoctor.toUpperCase()"> </td>
                                            </tr>
                                            <tr *ngIf="gynCardGeneration.registrationDate">
                                                <td class="width-fixed">Registration Date</td>
                                                <td class="col-4" [textContent]="gynCardGeneration.registrationDate | date :'dd/MM/yyyy'"> </td>
                                            </tr>
                                            <tr *ngIf="gynCardGeneration.otherDetails">
                                                <td class="col-8">Any Other Details</td>
                                                <!--<td class="col-4" [textContent]="gynCardGeneration.otherDetails"> </td>-->
                                                <td class="col-4">
                                                    <div style="white-space:normal; word-break:break-all;">
                                                        <span [textContent]="gynCardGeneration.otherDetails"></span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngIf="gynCardGeneration.gynNoExists">
                                                <td class="col-8">Gyn No Exists</td>
                                                <td class="col-4" [textContent]="gynCardGeneration.gynNoExists"> </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dashboard-box dashboard-table-box" #measureSection *ngIf="measure && measure !== null">
                            <h4 class="dashboard-title">Measures</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="measure.lmp || measure.heightInFeet ||  measure.lLmpList ||
                                                    measure.weight || measure.bsa || measure.systolicBP || measure.diastolicBP || measure.pulseRate || measure.respiratoryRate ||
                                                     measure.temparature">
                                            <th colspan="2">Measures</th>
                                        </tr>
                                        <tr *ngIf="measure.heightInFeet">
                                            <td class="col-8">Height In Feet</td>
                                            <td class="col-4" [textContent]="measure.heightInFeet"> </td>
                                        </tr>
                                        <tr *ngIf="measure.heightInMeters">
                                            <td class="col-8">Height In Meter</td>
                                            <td class="col-4" [textContent]="measure.heightInMeters"> </td>
                                        </tr>
                                        <tr *ngIf="measure.weight">
                                            <td class="col-8">Weight</td>
                                            <td class="col-4" [textContent]="measure.weight"> </td>
                                        </tr>
                                        <tr *ngIf="measure.bodyMassIndex">
                                            <td class="col-8">BMI</td>
                                            <td class="col-4" [textContent]="measure.bodyMassIndex"> </td>
                                        </tr>
                                        <tr *ngIf="measure.bsa">
                                            <td class="col-8">BSA</td>
                                            <td class="col-4" [textContent]="measure.bsa"> </td>
                                        </tr>
                                        <tr *ngIf="measure.bmiCategorization">
                                            <td class="col-8">BMI Categorization</td>
                                            <td class="col-4" [textContent]="measure.bmiCategorization"> </td>
                                        </tr>
                                        <tr *ngIf="measure.systolicBP">
                                            <td class="col-8">Systolic BP</td>
                                            <td class="col-4" [textContent]="measure.systolicBP"> </td>
                                        </tr>
                                        <tr *ngIf="measure.diastolicBP">
                                            <td class="col-8">Diastolic BP</td>
                                            <td class="col-4" [textContent]="measure.diastolicBP"> </td>
                                        </tr>
                                        <tr *ngIf="measure.pulseRate">
                                            <td class="col-8">Pulse Rate</td>
                                            <td class="col-4" [textContent]="measure.pulseRate"> </td>
                                        </tr>
                                        <tr *ngIf="measure.respiratoryRate">
                                            <td class="col-8">Respiratory Rate</td>
                                            <td class="col-4" [textContent]="measure.respiratoryRate"> </td>
                                        </tr>
                                        <tr *ngIf="measure.temparature">
                                            <td class="col-8">Temparature</td>
                                            <td class="col-4" [textContent]="measure.temparature"> </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isAdmission==false">
                            <div *ngIf="roleId != 63">
                                <div class="dashboard-box dashboard-table-box" #gynHistorySection *ngIf="gynHistory && gynHistory !== null">
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <h4 class="dashboard-title">Gynaec History</h4>
                                            <div class="dashboard-body">
                                                <div class="overflow-auto">
                                                    <div class="row" *ngIf="gynHistory && gynHistory !== null ">
                                                        <div class="col-12">
                                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                                <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gynHistory && gynHistory !== null ">
                                                                    <th colspan="2">Gynaec History</th>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.historyUpadtedDate">
                                                                    <td class="col-8">History Updated On</td>
                                                                    <td class="col-4" [textContent]="gynHistory.historyUpadtedDate | date :'dd/MM/yyyy'"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.outsideDoctor">
                                                                    <td class="col-8">Referred From (Out Side Doctor)</td>
                                                                    <td class="col-4" [textContent]="gynHistory.outsideDoctor"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.menstrualPattern">
                                                                    <td class="col-8">Menstrual Cycle</td>
                                                                    <td class="col-4" [textContent]="gynHistory.menstrualPattern"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.menstrualCycleIntervals">
                                                                    <td class="col-8">Menstrual Cycle Interval(Days)</td>
                                                                    <td class="col-4" [textContent]="gynHistory.menstrualCycleIntervals"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.durationOfFlow">
                                                                    <td class="col-8">Duration Of Flow</td>
                                                                    <td class="col-4" [textContent]="gynHistory.durationOfFlow"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.amountOfFlow">
                                                                    <td class="col-8">Amount Of Flow</td>
                                                                    <td class="col-4" [textContent]="gynHistory.amountOfFlow"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.dysmenorrhoea">
                                                                    <td class="col-8">Dysmenorrhoea</td>
                                                                    <td class="col-4" [textContent]="gynHistory.dysmenorrhoea"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.ageAtMenarche">
                                                                    <td class="col-8">Age At Menarche</td>
                                                                    <td class="col-4" [textContent]="gynHistory.ageAtMenarche"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.bloodGroup">
                                                                    <td class="col-8">Blood Group</td>
                                                                    <td class="col-4" [textContent]="gynHistory.bloodGroup"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.rhType">
                                                                    <td class="col-8">Rh Type</td>
                                                                    <td class="col-4" [textContent]="gynHistory.rhType"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.martitalStatus">
                                                                    <td class="col-8">Marital Status</td>
                                                                    <td class="col-4" [textContent]="gynHistory.martitalStatus"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.yearOfMarriage">
                                                                    <td class="col-8">Year Of Marriage</td>
                                                                    <td class="col-4" [textContent]="gynHistory.yearOfMarriage"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.gestationAge">
                                                                    <td class="col-8">Cohabitation In Years</td>
                                                                    <td class="col-4" [textContent]="gynHistory.gestationAge"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.contraception">
                                                                    <td class="col-8">Contraception</td>
                                                                    <td class="col-4" [textContent]="gynHistory.contraception"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.contraceptionOthers">
                                                                    <td class="col-8">Contraception Others</td>
                                                                    <td class="col-4" [textContent]="gynHistory.contraceptionOthers"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.consanguinity">
                                                                    <td class="col-8">Consanguinity</td>
                                                                    <td class="col-4" [textContent]="gynHistory.consanguinity"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.degreeOfConsanguinity">
                                                                    <td class="col-8">Degree Of Consanguinity</td>
                                                                    <td class="col-4" [textContent]="gynHistory.degreeOfConsanguinity"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.gravida">
                                                                    <td class="col-8">Gravida</td>
                                                                    <td class="col-4" [textContent]="gynHistory.gravida"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.para">
                                                                    <td class="col-8">Para</td>
                                                                    <td class="col-4" [textContent]="gynHistory.para"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.living">
                                                                    <td class="col-8">Living</td>
                                                                    <td class="col-4" [textContent]="gynHistory.living"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.abortion">
                                                                    <td class="col-8">Abortion</td>
                                                                    <td class="col-4" [textContent]="gynHistory.abortion"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.ectopic">
                                                                    <td class="col-8">Ectopic</td>
                                                                    <td class="col-4" [textContent]="gynHistory.ectopic"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.death">
                                                                    <td class="col-8">Death</td>
                                                                    <td class="col-4" [textContent]="gynHistory.death"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.lmp">
                                                                    <td class="col-8">Lmp</td>
                                                                    <td class="col-4" [textContent]="gynHistory.lmp | date :'dd/MM/yyyy'"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.yr || gynHistory.month || gynHistory.day">
                                                                    <td class="col-8">Calculated Lmp</td>
                                                                    <td class="col-4"><span [textContent]="gynHistory.day +'d'+' '+ gynHistory.month +'m'+' '+ gynHistory.yr+'y'"></span></td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.lLmp">
                                                                    <td class="col-8">LLmp</td>
                                                                    <td class="col-4" [textContent]="gynHistory.lLmp | date :'dd/MM/yyyy'"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.llmpYr || gynHistory.llmpMonth || gynHistory.llDay">
                                                                    <td class="col-8">Calculated LLmp</td>
                                                                    <td class="col-4"><span [textContent]="gynHistory.llDay +'d'+' '+ gynHistory.llmpMonth +'m'+' '+ gynHistory.llmpYr +'y'"></span></td>
                                                                </tr>

                                                                <tr *ngIf="gynHistory.lLmpList!==null && gynHistory.lLmpList.length > 0">
                                                                    <td colspan="2">
                                                                        <div class="dashboard-body">
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <h6 class="font-14 mt-0 mb-2">LLMP</h6>
                                                                                    <ul class="dashboard-ul row">
                                                                                        <li *ngFor="let item of gynHistory.lLmpList" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>


                                                                <!--<tr *ngIf="gynHistory.postmenopausal">
                                                                    <td class="col-8">Post Menopausal</td>
                                                                    <td class="col-4" [textContent]="gynHistory.postmenopausal"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.postabortal">
                                                                    <td class="col-8">Post Abortal</td>
                                                                    <td class="col-4" [textContent]="gynHistory.postabortal"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.lactationalAmenorrhea">
                                                                    <td class="col-8">Lactational Amenorrhea</td>
                                                                    <td class="col-4" [textContent]="gynHistory.lactationalAmenorrhea"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.thyromegaly">
                                                                    <td class="col-8">Thyromegaly</td>
                                                                    <td class="col-4" [textContent]="gynHistory.thyromegaly"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.postHysterectomy">
                                                                    <td class="col-8">Post Hysterectomy</td>
                                                                    <td class="col-4" [textContent]="gynHistory.postHysterectomy"> </td>
                                                                </tr>-->
                                                                <tr *ngIf="gynHistory.menopause">
                                                                    <td class="col-8">Menopause</td>
                                                                    <td class="col-4" [textContent]="gynHistory.menopause"> </td>
                                                                </tr>

                                                                <tr *ngIf="gynHistory.ageAtMenopause">
                                                                    <td class="col-8">Age At Menopause</td>
                                                                    <td class="col-4" [textContent]="gynHistory.ageAtMenopause"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.dyspareunia">
                                                                    <td class="col-8">Dyspareunia</td>
                                                                    <td class="col-4" [textContent]="gynHistory.dyspareunia"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.imb">
                                                                    <td class="col-8">IMB</td>
                                                                    <td class="col-4" [textContent]="gynHistory.imb"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.vaginalDiscahrge">
                                                                    <td class="col-8">Vaginal Discharge</td>
                                                                    <td class="col-4" [textContent]="gynHistory.vaginalDiscahrge"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.pcb">
                                                                    <td class="col-8">PCB</td>
                                                                    <td class="col-4" [textContent]="gynHistory.pcb"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.pms">
                                                                    <td class="col-8">PMS</td>
                                                                    <td class="col-4" [textContent]="gynHistory.pms"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.pmdd">
                                                                    <td class="col-8">PMDD</td>
                                                                    <td class="col-4" [textContent]="gynHistory.pmdd"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.papSmear">
                                                                    <td class="col-8">Pap Smear</td>
                                                                    <td class="col-4" [textContent]="gynHistory.papSmear"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.lastPapSmearDate">
                                                                    <td class="col-8">Last Pap Smear Date</td>
                                                                    <td class="col-4" [textContent]="gynHistory.lastPapSmearDate"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.report">
                                                                    <td class="col-8">Report</td>
                                                                    <td class="col-4">
                                                                        <!--<div class="break-all">
                                                                            <span [textContent]="gynHistory.report"></span>
                                                                        </div>-->
                                                                        <div style="white-space:normal; word-break:break-all;">
                                                                            <span [textContent]="gynHistory.report"></span>
                                                                        </div>
                                                                    </td>
                                                                    <!-- <td class="col-4" [textContent]="gynHistory.report"> </td>-->
                                                                </tr>
                                                                <tr *ngIf="gynHistory.hrt">
                                                                    <td class="col-8">HRT</td>
                                                                    <td class="col-4" [textContent]="gynHistory.hrt"> </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.presentingComplaint">
                                                                    <td class="col-8">Presenting Complaint</td>
                                                                    <td class="col-4" [textContent]="gynHistory.presentingComplaint"> </td>
                                                                </tr>

                                                                <tr *ngIf="gynHistory.treatmentList!==null && gynHistory.treatmentList.length >0">
                                                                    <td colspan="2">
                                                                        <div class="dashboard-body">
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <h6> Treatment History</h6>
                                                                                    <ul class="dashboard-ul row">
                                                                                        <li *ngFor="let item of gynHistory.treatmentList" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.treatmentHistory">
                                                                    <td class="col-8">Treatment History</td>
                                                                    <td class="col-4">
                                                                        <div style="white-space:normal; word-break:break-all;">
                                                                            <span [textContent]="gynHistory.treatmentHistory"></span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.presentingIllness">
                                                                    <td class="col-8">Presenting Illness</td>
                                                                    <!--<td class="col-4" [textContent]="gynHistory.presentingIllness"> </td>-->
                                                                    <td class="col-4">
                                                                        <div style="white-space:normal; word-break:break-all;">
                                                                            <span [textContent]="gynHistory.presentingIllness"></span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <h6 class="font-14 mt-0 mb-2">Other History</h6>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.bowelSymptoms">
                                                                    <td class="col-8">Bowel Symptoms</td>
                                                                    <!--<td class="col-4" [textContent]="gynHistory.bowelSymptoms"> </td>-->
                                                                    <td class="col-4">
                                                                        <div style="white-space:normal; word-break:break-all;">
                                                                            <span [textContent]="gynHistory.bowelSymptoms"></span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.bladderSymptoms">
                                                                    <td class="col-8">Bladder Symptoms</td>
                                                                    <!--<td class="col-4" [textContent]="gynHistory.bladderSymptoms"> </td>-->
                                                                    <td class="col-4">
                                                                        <div style="white-space:normal; word-break:break-all;">
                                                                            <span [textContent]="gynHistory.bladderSymptoms"></span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.otherWeightChange">
                                                                    <td class="col-8">Weight Change</td>
                                                                    <td class="col-4">
                                                                        <div class="styleForFields">
                                                                            <span [textContent]="gynHistory.otherWeightChange"></span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.otherHepatitis">
                                                                    <td class="col-8">Appetite</td>
                                                                    <td class="col-4">
                                                                        <div class="styleForFields">
                                                                            <span [textContent]="gynHistory.otherHepatitis"></span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="gynHistory.addictions">
                                                                    <td class="col-8">Addictions</td>
                                                                    <td class="col-4" [textContent]="gynHistory.addictions"> </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="dashboard-body">
                                                <div class="overflow-auto">
                                                    <table class="table table-centered table-nowrap table-sm  table-striped mb-0">

                                                        <tr *ngIf="gynHistory.gainChange">
                                                            <td class="col-8">Gain Change</td>
                                                            <td class="col-4" [textContent]="gynHistory.gainChange"> </td>
                                                        </tr>
                                                        <tr *ngIf="gynHistory.gainChangeList!==null && gynHistory.gainChangeList.length > 0">
                                                            <td colspan="2">
                                                                <div class="dashboard-body">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <h6> Gain Change</h6>
                                                                            <ul class="dashboard-ul row">
                                                                                <li *ngFor="let item of gynHistory.gainChangeList" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="gynHistory.lossChange">
                                                            <td class="col-8">Loss Change</td>
                                                            <td class="col-4" [textContent]="gynHistory.lossChange"> </td>
                                                        </tr>
                                                        <tr *ngIf="gynHistory.loss">
                                                            <td class="col-8">Loss Change</td>
                                                            <td class="col-4">
                                                                <span *ngIf="gynHistory.loss">Loss</span>&nbsp;
                                                            </td>
                                                        </tr>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isAdmission==false">
                            <div *ngIf="roleId != 63">
                                <div class="dashboard-box dashboard-table-box" #gyneacVisitSection *ngIf="gyneacVisit && gyneacVisit !== null">
                                    <h4 class="dashboard-title">Gynaec Visit</h4>
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gyneacVisit.visitType || gyneacVisit.consultantDoctor || gyneacVisit.details || gyneacVisit.gravida || gyneacVisit.para || gyneacVisit.living ||
                                                     gyneacVisit.abortion || gyneacVisit.ectopic || gyneacVisit.death">
                                                    <th colspan="2">General Breast and Gynaec Examination</th>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.gynNo">
                                                    <td class="col-8">Gyn No.</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.gynNo"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.visitDate">
                                                    <td class="col-8">Visit Date</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.visitDate | date :'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.visitNo">
                                                    <td class="col-8">Visit No.</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.visitNo"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.consultantDoctor">
                                                    <td class="col-8">Consultant Doctor</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.consultantDoctor.toUpperCase()"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.visitType">
                                                    <td class="col-8">Visit Type</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.visitType"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.procedureType">
                                                    <td class="col-8">Procedure Type</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.procedureType"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.communicated">
                                                    <td class="col-8">Communicated By</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.communicated"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.details">
                                                    <td class="col-8">Details</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.details"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.details"></span>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr *ngIf="gyneacVisit.postmenopausal">
                                                    <td class="col-8">Postmenopausal</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.details"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.postmenopausal"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.gravida">
                                                    <td class="col-8">Gravida</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.gravida"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.para">
                                                    <td class="col-8">Para</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.para"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.living">
                                                    <td class="col-8">Living</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.living"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.abortion">
                                                    <td class="col-8">Abortion</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.abortion"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.ectopic">
                                                    <td class="col-8">Ecotopic</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.ectopic"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.death">
                                                    <td class="col-8">Death</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.death"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gyneacVisit.lmp || gyneacVisit.postmenopausal || gyneacVisit.postabortal || gyneacVisit.lactationalAmenorrhea || gyneacVisit.postHysterectomy || gyneacVisit.heightInFeet ||
                                                     gyneacVisit.heightInMeters || gyneacVisit.weight || gyneacVisit.height || gyneacVisit.bsa || gyneacVisit.systolicBP || gyneacVisit.diastolicBP || gyneacVisit.pulseRate || gyneacVisit.respiratoryRate ||
                                                     gyneacVisit.temparature">
                                                    <th colspan="2">Measure</th>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.lmp">
                                                    <td class="col-8">LMP</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.lmp | date :'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.yr || gyneacVisit.month || gyneacVisit.day">
                                                    <td class="col-8">Calculated Lmp</td>
                                                    <td class="col-4"><span [textContent]="gyneacVisit.day +'d'+' '+ gyneacVisit.month +'m'+' '+ gyneacVisit.yr+'y'"></span></td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.postmenopausal||gyneacVisit.postabortal||gyneacVisit.lactationalAmenorrhea ||gyneacVisit.postHysterectomy">
                                                    <td class="col-8">LLMP</td>
                                                    <td class="col-4 dashboard-ul row">
                                                        <span *ngIf="gyneacVisit.postmenopausal">Post Menopausal ,</span>&nbsp;
                                                        <span *ngIf="gyneacVisit.postabortal">Post Abortal,  </span>&nbsp;
                                                        <span *ngIf="gyneacVisit.lactationalAmenorrhea">Lactational Amenorrhea and</span>&nbsp;
                                                        <span *ngIf="gyneacVisit.postHysterectomy">Post Hysterectomy</span>&nbsp;
                                                    </td>
                                                </tr>
                                                <!--<tr *ngIf="gyneacVisit.postMenopausal">
                                                    <td colspan="2">
                                                        <div class="dashboard-body">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <h6 class="font-14 mt-0 mb-2">Post Menopausal</h6>
                                                                    <ul class="dashboard-ul row">
                                                                        <li *ngFor="let item of gyneacVisit.postMenopausal" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>-->
                                                <tr *ngIf="gyneacVisit.lLmpList">
                                                    <td colspan="2">
                                                        <div class="dashboard-body">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <h6 class="font-14 mt-0 mb-2">LMP</h6>
                                                                    <ul class="dashboard-ul row">
                                                                        <li *ngFor="let item of gyneacVisit.lLmpList" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.heightInFeet">
                                                    <td class="col-8">Height In Feet</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.heightInFeet"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.heightInMeters">
                                                    <td class="col-8">Height In Meter</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.heightInMeters"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.weight">
                                                    <td class="col-8">Weight</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.weight"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.bodyMassIndex">
                                                    <td class="col-8">BMI</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.bodyMassIndex"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.bsa">
                                                    <td class="col-8">BSA</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.bsa"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.bmiCategorization">
                                                    <td class="col-8">BMI Categorization</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.bmiCategorization"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.systolicBP">
                                                    <td class="col-8">Systolic BP</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.systolicBP"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.diastolicBP">
                                                    <td class="col-8">Diastolic BP</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.diastolicBP"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.pulseRate">
                                                    <td class="col-8">Pulse Rate</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.pulseRate"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.respiratoryRate">
                                                    <td class="col-8">Respiratory Rate</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.respiratoryRate"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.temparature">
                                                    <td class="col-8">Temparature</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.temparature"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gyneacVisit.pallor || gyneacVisit.icterus || gyneacVisit.lymphNodes || gyneacVisit.thyromegaly || gyneacVisit.pigmentation ||
                                                     gyneacVisit.hirsutism || gyneacVisit.acne || gyneacVisit.axillaryHair || gyneacVisit.pubicHair || gyneacVisit.tannerStagePubicHair">
                                                    <th colspan="2">General Examination</th>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.pallor">
                                                    <td class="col-8">Pallor</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.pallor"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.icterus">
                                                    <td class="col-8">Icterus</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.icterus"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.lymphNodes">
                                                    <td class="col-8">Lymph Nodes</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.lymphNodes"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.thyromegaly">
                                                    <td class="col-8">Thyromegaly</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.thyromegaly"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.pigmentation">
                                                    <td class="col-8">Acanthosis Nigricans</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.pigmentation"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.hirsutism">
                                                    <td class="col-8">Hirsutism</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.hirsutism"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.fgScore">
                                                    <td class="col-8">FG Score</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.fgScore"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.acne">
                                                    <td class="col-8">Acne</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.acne"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.axillaryHair">
                                                    <td class="col-8">Axillary Hair</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.axillaryHair"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.pubicHair">
                                                    <td class="col-8">Pubic Hair</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.pubicHair"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.tannerStagePubicHair">
                                                    <td class="col-8">Tanner Stage Pubic Hair</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.tannerStagePubicHair"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <div *ngIf="gyneacVisit.rightExamination || gyneacVisit.rightExaminationComment || gyneacVisit.leftExamination || gyneacVisit.leftExaminationComment">
                                                <!--<h6 class="dashboard-title">Breast Examination</h6>-->
                                                <div class="overflow-auto">
                                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                        <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gyneacVisit.rightExamination || gyneacVisit.rightExaminationComment">
                                                            <th colspan="2">Breast Examination</th>
                                                        </tr>
                                                        <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gyneacVisit.rightExamination || gyneacVisit.rightExaminationComment">
                                                            <th colspan="2">Right</th>
                                                        </tr>
                                                        <tr *ngIf="gyneacVisit.rightExamination">
                                                            <td class="col-8">Right Examination</td>
                                                            <td class="col-4">
                                                                <div style="white-space:normal; word-break:break-all;">
                                                                    <span [textContent]="gyneacVisit.rightExamination"></span>
                                                                </div>
                                                            </td>
                                                            <!--<td class="col-4" [textContent]="gyneacVisit.rightExamination">
                                                            </td>-->

                                                        </tr>
                                                        <tr *ngIf="gyneacVisit.rightExaminationComment">
                                                            <td class="col-8">Right Examination Comments</td>
                                                            <td class="col-4">
                                                                <div style="white-space:normal; word-break:break-all;">
                                                                    <span [textContent]="gyneacVisit.rightExaminationComment"></span>
                                                                </div>
                                                            </td>
                                                            <!--<td class="col-4" [textContent]="gyneacVisit.rightExaminationComment"> </td>-->
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div class="overflow-auto">
                                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                        <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gyneacVisit.leftExamination || gyneacVisit.leftExaminationComment">
                                                            <th colspan="2">Left</th>
                                                        </tr>
                                                        <tr *ngIf="gyneacVisit.leftExamination">
                                                            <td class="col-8">left Examination</td>
                                                            <td class="col-4" [textContent]="gyneacVisit.leftExamination"> </td>
                                                        </tr>
                                                        <tr *ngIf="gyneacVisit.leftExaminationComment">
                                                            <td class="col-8">Left Examination Comments</td>
                                                            <td class="col-4">
                                                                <div style="white-space:normal; word-break:break-all;">
                                                                    <span [textContent]="gyneacVisit.leftExaminationComment"></span>
                                                                </div>
                                                            </td>
                                                            <!--<td class="col-4" [textContent]="gyneacVisit.leftExaminationComment"> </td>-->
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gyneacVisit.abdominalExamination || gyneacVisit.externalGenitalia|| gyneacVisit.speculumExamination || gyneacVisit.bimanualExamination || gyneacVisit.rectalExamination">
                                                    <th colspan="2">Gyneac Examination</th>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.abdominalExamination">
                                                    <td class="col-8">Abdominal Examination</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.abdominalExamination"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.abdominalExamination"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.externalGenitalia">
                                                    <td class="col-8">External Genitalia</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.externalGenitalia"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.speculumExamination">
                                                    <td class="col-8">Speculum Examination</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.speculumExamination"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.bimanualExamination">
                                                    <td class="col-8">Bimanual Examination</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.bimanualExamination"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.rectalExamination">
                                                    <td class="col-8">Rectal Examination</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.rectalExamination"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gyneacVisit.gyanecExaminationSurgery || gyneacVisit.academicScan || gyneacVisit.specialFeatures || gyneacVisit.provisionalDiagnosis || gyneacVisit.finalDiagnosis ||
                                                     gyneacVisit.planOfManagement || gyneacVisit.seenByDr || gyneacVisit.examinedByDr || gyneacVisit.nextReviewDate">
                                                    <th colspan="2">Summary</th>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.gynaecExaminationSummary">
                                                    <td class="col-8">Gynaec Examination Summary</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.gynaecExaminationSummary"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.gynaecExaminationSummary"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.academicScan">
                                                    <td class="col-8">Academic Scan</td>
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.academicScan"></span>
                                                        </div>
                                                    </td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.academicScan"> </td>-->
                                                </tr>
                                                <tr *ngIf="gyneacVisit.specialFeatures">
                                                    <td class="col-8">Special Features</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.specialFeatures"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.specialFeatures"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.provisionalDiagnosis">
                                                    <div class="dashboard-body" *ngIf="gyneacVisit.provisionalDiagnosis && gyneacVisit.provisionalDiagnosis!=null && gyneacVisit.provisionalDiagnosis.length>0 ">
                                                        <div class="row">
                                                            <div class="col-12" *ngIf="gyneacVisit.provisionalDiagnosis && gyneacVisit.provisionalDiagnosis.length >0">
                                                                <h6 class="font-14 mt-0 mb-2">provisional Diagnosis</h6>
                                                                <ul class="dashboard-ul row">
                                                                    <li *ngFor="let item of gyneacVisit.provisionalDiagnosis" class=" col-sm-12" [textContent]="item"></li>
                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.finalDiagnosis">
                                                    <td class="col-8">Final Diagnosis</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.finalDiagnosis"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.planOfManagement">
                                                    <td class="col-8">Plan Of Management</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.planOfManagement"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.planOfManagement"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.seenByDr">
                                                    <td class="col-8">Seen By Dr</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.seenByDr.toUpperCase()"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.examinedByDr">
                                                    <td class="col-8">Examined By Dr</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.examinedByDr.toUpperCase()"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.nextReviewDate">
                                                    <td class="col-8">Next Review Date</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.nextReviewDate "> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>


                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gyneacVisit.biopsyIndication || gyneacVisit.anaesthesia!=null ||
                                            gyneacVisit.cervix!=null ||   gyneacVisit.uterocervicalLength!=null || gyneacVisit.usgGuidance!=null">

                                                    <th colspan="2">Endometrial Biopsy</th>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.biopsyIndication">
                                                    <td class="col-8">Indication</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.gynaecExaminationSummary"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.biopsyIndication"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.anaesthesia">
                                                    <td class="col-8">Anaesthesia</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.anaesthesia"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.cervix">
                                                    <td class="col-8">Cervix</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.specialFeatures"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.cervix"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.uterocervicalLength">
                                                    <td class="col-8">Uterocervical Length</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.provisionalDiagnosis1"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.uterocervicalLength"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.usgGuidance">
                                                    <td class="col-8">USG Guidance</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.usgGuidance"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gyneacVisit.mirenaIndication || gyneacVisit.cervixAnaesthesia!=null ||
                                            gyneacVisit.uterocervicalLengthCm!=null ||   gyneacVisit.usgConfirmation!=null">

                                                    <th colspan="2"> Mirena Insertion</th>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.mirenaIndication">
                                                    <td class="col-8">Indication</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.gynaecExaminationSummary"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.mirenaIndication"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.cervixAnaesthesia">
                                                    <td class="col-8">Anaesthesia</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.cervixAnaesthesia"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.uterocervicalLengthCm">
                                                    <td class="col-8">Uterocervical Length Cm</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.specialFeatures"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.uterocervicalLengthCm"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.usgConfirmation">
                                                    <td class="col-8">USG Confirmation</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.provisionalDiagnosis1"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.usgConfirmation"></span>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </table>
                                        </div>
                                    </div>

                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gyneacVisit.colposcopyIndication || gyneacVisit.colposcopyAnaesthesia!=null ||
                                            gyneacVisit.colposcopyCervix!=null ||   gyneacVisit.scj!=null
                                            || gyneacVisit.acetowhiteAreas!=null ||   gyneacVisit.lugolSIodine!=null  || gyneacVisit.biopsy!=null
                                            ||   gyneacVisit.hpvTest!=null">

                                                    <th colspan="2">  Colposcopy</th>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.mirenaIndication">
                                                    <td class="col-8">Indication</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.gynaecExaminationSummary"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.colposcopyIndication"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.cervixAnaesthesia">
                                                    <td class="col-8">Anaesthesia</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.colposcopyAnaesthesia"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.colposcopyCervix">
                                                    <td class="col-8">Cervix</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.specialFeatures"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.colposcopyCervix"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.scj">
                                                    <td class="col-8">SCJ</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.provisionalDiagnosis1"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.scj"></span>
                                                        </div>
                                                    </td>
                                                </tr>


                                                <tr *ngIf="gyneacVisit.acetowhiteAreas">
                                                    <td class="col-8">Acetowhite Areas</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.acetowhiteAreas"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.lugolSIodine">
                                                    <td class="col-8">Lugol S Iodine</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.specialFeatures"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.lugolSIodine"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.biopsy">
                                                    <td class="col-8">Biopsy</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.provisionalDiagnosis1"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.biopsy"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.hpvTest">
                                                    <td class="col-8">HPV Test</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.provisionalDiagnosis1"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.hpvTest"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gyneacVisit.cryotheraphyIndication || gyneacVisit.cryotheraphyCervix!=null ">

                                                    <th colspan="2">Cryotherapy</th>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.cryotheraphyIndication">
                                                    <td class="col-8">Indication D</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.gynaecExaminationSummary"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.cryotheraphyIndication"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.cryotheraphyCervix">
                                                    <td class="col-8">Cervix</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.cryotheraphyCervix"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gyneacVisit.cervicalIndication || gyneacVisit.cervicalAnaesthesia!=null ||
                                            gyneacVisit.cervicalUSGGuidance!=null ||   gyneacVisit.dilatedUpto!=null
                                            || gyneacVisit.procedureAbandoned!=null">

                                                    <th colspan="2">CERVICAL DILATATION</th>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.cervicalIndication">
                                                    <td class="col-8">Indication</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.gynaecExaminationSummary"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.cervicalIndication"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.cervicalAnaesthesia">
                                                    <td class="col-8">Anaesthesia</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.cervicalAnaesthesia"> </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.cervicalUSGGuidance">
                                                    <td class="col-8">USG Guidance</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.specialFeatures"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.cervicalUSGGuidance"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.dilatedUpto">
                                                    <td class="col-8">Dilated Upto</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.provisionalDiagnosis1"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.dilatedUpto"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.procedureAbandoned">
                                                    <td class="col-8">Procedure Abandoned</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.procedureAbandoned"> </td>
                                                </tr>

                                            </table>
                                        </div>
                                    </div>

                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gyneacVisit.procedueDetails || gyneacVisit.minorOPDate!=null ">

                                                    <th colspan="2">OTHER PROCEDURES</th>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.procedueDetails">
                                                    <td class="col-8">Details</td>
                                                    <!--<td class="col-4" [textContent]="gyneacVisit.gynaecExaminationSummary"> </td>-->
                                                    <td class="col-4">
                                                        <div style="white-space:normal; word-break:break-all;">
                                                            <span [textContent]="gyneacVisit.procedueDetails"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="gyneacVisit.minorOPDate">
                                                    <td class="col-8">Minor OP Date</td>
                                                    <td class="col-4" [textContent]="gyneacVisit.minorOPDate"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div *ngIf="roleId != 63">
                            <div class="dashboard-box dashboard-table-box" #problemListSection *ngIf="problemList">
                                <h4 class="dashboard-title">Past Medical History</h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <div *ngIf="isPastMedicalHistory">
                                            There is no past medical history
                                        </div>
                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="data && data.length>0">
                                            <thead>
                                                <tr>
                                                    <th>Disease name</th>
                                                    <th>Year Of Diagnosis</th>
                                                    <th>ICD</th>
                                                    <th>Status</th>
                                                    <th style="width: 250px">Treatment history</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of data">
                                                    <td>
                                                        <div class="media">
                                                            <div class="media-body">
                                                                <h5 class="mb-0 mt-0 font-weight-normal text-truncate-line" [textContent]="item.usable"></h5>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p [textContent]="item.date ||'---'"></p>
                                                    </td>
                                                    <td [textContent]="item.code"></td>
                                                    <td [textContent]="item.status||'--'"></td>
                                                    <td>
                                                        <p class="mb-0 text-truncate-line" [textContent]="item.overRideMessage|| '-'"></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="roleId != 63">
                            <div class="dashboard-box dashboard-table-box" #familyHistorySection *ngIf="familyHistory">
                                <h4 class="dashboard-title">Family History</h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <div *ngIf="isFamilyHistory">
                                            There is no Family History
                                        </div>
                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="familyHistory &&  familyHistory.length >0">
                                            <thead>
                                                <tr>
                                                    <th>Relation</th>
                                                    <th>Age/DOB</th>
                                                    <th>Problem</th>
                                                    <th>Status</th>
                                                    <th>Comments</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of familyHistory;">
                                                    <td>
                                                        <div class="media">
                                                            <div class="media-body">
                                                                <h5 class="mb-0 mt-0 font-weight-normal text-truncate-line" [textContent]=" item.relation"></h5>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p *ngIf="item.age" [textContent]="item.age "></p>
                                                        <p style="padding-left: 25px" *ngIf="!item.age" [textContent]="'-'"></p>
                                                    </td>
                                                    <td>
                                                        <p *ngIf="item.problem" [textContent]="item.problem "></p>
                                                        <p style="padding-left: 25px" *ngIf="!item.problem" [textContent]="'-'"></p>
                                                    </td>
                                                    <td>
                                                        <p *ngIf="item.status" [textContent]="item.status "></p>
                                                        <p style="padding-left: 25px" *ngIf="!item.status" [textContent]="'-'"></p>
                                                    </td>
                                                    <td>
                                                        <p *ngIf="item.comments" [textContent]="item.comments "></p>
                                                        <p style="padding-left: 25px" *ngIf="!item.comments" [textContent]="'-'"></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div *ngIf="familyHistory && familyHistory.length>0 && familyHistory[0].mainComment">
                                            <strong>Comments</strong>
                                            <div><span [textContent]="familyHistory[0].mainComment "></span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="roleId != 63">
                            <div class="dashboard-box dashboard-table-box" #surgerySection *ngIf="surgeries">
                                <h4 class="dashboard-title">Past surgical history</h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <div *ngIf="isProcedureSurgery">
                                            There is no Surgical History
                                        </div>
                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="surgeries && surgeries.length >0">
                                            <thead>
                                                <tr>
                                                    <th class="col-4">Year Of Surgery</th>
                                                    <th class="col-4">Surgeries</th>
                                                    <th class="col-4">Comments</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of surgeries;">
                                                    <td>
                                                        <div class="media">
                                                            <div class="media-body">
                                                                <h5 [textContent]=" item.date"></h5>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p *ngIf="item.surgeries" [textContent]="item.surgeries "></p>
                                                        <p *ngIf="!item.surgeries" [textContent]="'-'"></p>
                                                    </td>
                                                    <td>
                                                        <p *ngIf="item.comments" [textContent]="item.comments "></p>
                                                        <p style="padding-left: 25px" *ngIf="!item.comments" [textContent]="'-'"></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="roleId != 63">
                            <div class="dashboard-box dashboard-table-box" #allergiesSection *ngIf="allergies && allergies !== null">
                                <h4 class="dashboard-title">Allergies</h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <div *ngIf="allergies && allergies !== null">
                                            <div>
                                                <div *ngIf="allergies && allergies.noHistory && allergies.noHistory!=null ">
                                                    There is no allergy
                                                </div>

                                                <div class="dashboard-break" *ngIf="!allergies.noHistory && (allergies.drugNames!=null || allergies.drugNames!=null || allergies.drugReactions!=null
                         || allergies.dustNames!=null || allergies.dustReactions!=null || allergies.foodNames!=null || allergies.foodReactions!=null || allergies.otherNames!=null || allergies.otherReactions!=null)">
                                                    <div class="table-responsive horizontal-scroll">
                                                        <table class="table table-sm mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th width="30%">Allergy Type</th>
                                                                    <th width="30%">Allergy</th>
                                                                    <th width="40%">Reaction </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngIf="allergies.drugNames">
                                                                    <td class="col-4">Drug Allergy</td>
                                                                    <td class="col-4" [textContent]="allergies.drugNames"></td>
                                                                    <td class="col-4" [textContent]="allergies.drugReactions"></td>
                                                                </tr>
                                                                <tr *ngIf="allergies.foodNames">
                                                                    <td class="col-4">Food Allergy</td>
                                                                    <td class="col-4" [textContent]="allergies.foodNames"></td>
                                                                    <td class="col-4" [textContent]="allergies.foodReactions"></td>
                                                                </tr>
                                                                <tr *ngIf="allergies.dustNames">
                                                                    <td class="col-4">Dust Allergy</td>
                                                                    <td class="col-4" [textContent]="allergies.dustNames"></td>
                                                                    <td class="col-4" [textContent]="allergies.dustReactions"></td>
                                                                </tr>
                                                                <tr *ngIf="allergies.otherNames">
                                                                    <td class="col-4">Others</td>
                                                                    <td class="col-4" [textContent]="allergies.otherNames"></td>
                                                                    <td class="col-4" [textContent]="allergies.otherReactions"></td>
                                                                </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="roleId != 63">
                            <div class="dashboard-box dashboard-table-box" #birthHistorySection *ngIf="birthHistory && birthHistory.length">
                                <h4 class="dashboard-title">OB Birth History</h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Pregnancy No</th>
                                                    <th>Year Of Birth</th>
                                                    <th>PregnancyStatus </th>
                                                    <th>Mode Of Delivery</th>
                                                    <th>Indication</th>
                                                    <th>Weight</th>
                                                    <th>Sex Of Baby</th>
                                                    <th>StatusOfBaby</th>
                                                    <th>Delivery At</th>
                                                    <th>Others</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of birthHistory;">
                                                    <td [textContent]="item.pregnancyNo || '--'"></td>
                                                    <td [textContent]="item.yearOfBirth || '--'"></td>
                                                    <td [textContent]="item.pregnancyStatus || '--'"></td>
                                                    <td [textContent]="item.modeOfDelivery || '--'"></td>
                                                    <td [textContent]="item.indication || '--'"></td>
                                                    <td [textContent]="item.birthWeight || '--'"></td>
                                                    <td [textContent]="item.sexOfBaby || '--'"></td>
                                                    <td [textContent]="item.statusOfBaby || '--'"></td>
                                                    <td [textContent]="item.deliveryAt || '--'"></td>
                                                    <td style="white-space: normal; word-break: break-all;" [textContent]="item.other || '--'"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="roleId != 63">
                            <div class="dashboard-box dashboard-table-box" #orderPrescriptionSection *ngIf="orderPrescription && orderPrescription !== null">
                                <h4 class="dashboard-title">Order/Prescription</h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <table class="table table-centered table-sm table-striped mb-0">
                                            <tr *ngIf="bookingHeader && bookingHeader.length > 0">
                                                <td>Investigations</td>
                                                <td>
                                                    <table class="table table-centered table-nowrap table-sm table-striped transcipt mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Test Name</th>
                                                                <th>Test Code</th>
                                                                <th>Add By</th>
                                                                <th>Add Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                            <tr *ngFor="let item of bookingHeader;let i =index;">
                                                                <td>
                                                                    <span class=" text-wrap" [textContent]="item.testName"></span><br />
                                                                </td>
                                                                <td>
                                                                    <span class=" text-wrap" [textContent]="item.testCode"></span>
                                                                </td>
                                                                <td>
                                                                    <span class=" text-wrap" [textContent]="item.encounterLabAdd || '--'"></span>
                                                                </td>
                                                                <td>
                                                                    <span class=" text-wrap" [textContent]="item.encounterLabAddDate | date:'dd/MM/yyyy hh:mm a'"></span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr *ngIf="(records && records.length !== 0)">
                                                <td>
                                                    Prescription
                                                </td>
                                                <td *ngFor="let record of records;">
                                                    <table class="table table-centered table-nowrap table-sm table-striped transcipt mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Medicine Name</th>
                                                                <th>Generic Name</th>
                                                                <th>Dosage</th>
                                                                <th *ngIf="isMedicationInstruction">Instruction</th>
                                                                <th>Category</th>
                                                                <th>Qty</th>
                                                                <th>Remarks</th>
                                                                <th>Add By</th>
                                                                <th>Add Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                            <tr *ngFor="let item of record.medicines; let i = index">
                                                                <td>
                                                                    <span class=" text-wrap" [textContent]="item.productName"></span><br />
                                                                </td>
                                                                <td>
                                                                    <span class=" text-wrap" *ngIf="item.genericName" [textContent]="item.genericName"></span>

                                                                </td>
                                                                <td>
                                                                    <span class=" text-wrap">
                                                                        <span *ngIf="item.instruction" [textContent]="item.instruction"></span>
                                                                    </span>
                                                                </td>
                                                                <td *ngIf="isMedicationInstruction">
                                                                    <div>
                                                                        <span class="text-capitalize" *ngIf="item.isMorning" [textContent]="item.morningDosage"></span>
                                                                    </div>
                                                                    <div>
                                                                        <span class="text-capitalize" *ngIf="item.isAfternoon" [textContent]="item.afternoonDosage"></span>
                                                                    </div>
                                                                    <div>
                                                                        <span class="text-capitalize" *ngIf="item.isNight" [textContent]="item.nightDosage"></span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span class=" text-wrap">
                                                                        <span *ngIf="item.instruction" [textContent]="item.categoryName"></span>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span class=" text-wrap">
                                                                        <span *ngIf="item.instruction" [textContent]="item.dosage"></span>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span class=" text-wrap">
                                                                        <span *ngIf="item.instruction" [textContent]="item.remark"></span>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span class=" text-wrap">
                                                                        <span *ngIf="item.instruction" [textContent]="item.addBy || '--'"></span>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span class=" text-wrap">
                                                                        <span *ngIf="item.instruction" [textContent]="item.addDate | date:'dd/MM/yyyy hh:mm a'"></span>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr *ngFor="let item of orderPrescription.orderData">
                                                <td *ngIf="item.comments && item.comments !== null " [textContent]="item.orderName"></td>
                                                <td *ngIf="item.comments && item.comments !== null " [innerHTML]="item.comments"></td>
                                            </tr>
                                            <tr *ngIf="referralOrder && referralOrder.length !== 0">
                                                <td>
                                                    Referrals
                                                </td>
                                                <td>
                                                    <table class="table table-centered table-nowrap table-sm table-striped transcipt mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Doctors</th>
                                                                <th>Comments</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                            <tr *ngFor="let item of referralOrder; let i = index">
                                                                <td>
                                                                    <span class=" text-wrap" [textContent]="item.name"></span><br />
                                                                </td>
                                                                <td>
                                                                    <span class=" text-wrap" [textContent]="item.comments"></span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isAdmission==false">
                            <div *ngIf="roleId != 63">
                                <div class="dashboard-box dashboard-table-box" #gynIUISection *ngIf="IUIData && IUIData.length>0">
                                    <div *ngFor="let gynIUI of IUIData">
                                        <h4 class="dashboard-title">Gynaec IUI</h4>
                                        <div class="dashboard-body">
                                            <div class="overflow-auto">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gynIUI.typeOfInfertility || gynIUI.indicationForIUI || gynIUI.others || gynIUI.cycle || gynIUI.rxRegime || gynIUI.rxDetails || gynIUI.noOfDF || gynIUI.ovulationTriggers ||
gynIUI.timeForHCGToIUI || gynIUI.cycleNoIUI || gynIUI.inSeminations || gynIUI.inSeminations || gynIUI.days">
                                                                <th colspan="2">INTRAUTERINE INSEMINATION</th>
                                                            </tr>
                                                            <tr *ngIf="gynIUI.typeOfInfertility">
                                                                <td class="col-8">Type Of Infertility</td>
                                                                <td class="col-4" [textContent]="gynIUI.typeOfInfertility"> </td>
                                                            </tr>
                                                            <tr *ngIf="gynIUI.indicationForIUI">
                                                                <td class="col-8">Indication For IUI</td>
                                                                <td class="col-4" [textContent]="gynIUI.indicationForIUI"> </td>
                                                            </tr>
                                                            <tr *ngIf="gynIUI.others">
                                                                <td class="col-8">Others</td>
                                                                <td class="col-4" [textContent]="gynIUI.others"> </td>
                                                            </tr>
                                                            <tr *ngIf="gynIUI.cycle">
                                                                <td class="col-8">Cycle</td>
                                                                <td class="col-4" [textContent]="gynIUI.cycle"> </td>
                                                            </tr>
                                                            <tr *ngIf="gynIUI.rxRegime">
                                                                <td class="col-8">Rx Regime</td>
                                                                <td class="col-4" [textContent]="gynIUI.rxRegime"> </td>
                                                            </tr>
                                                            <tr *ngIf="gynIUI.rxDetails">
                                                                <td class="col-8">Rx Details</td>
                                                                <!--<td class="col-4" [textContent]="gynIUI.rxDetails"> </td>-->
                                                                <td class="col-4">
                                                                    <div style="white-space:normal; word-break:break-all;">
                                                                        <span [textContent]="gynIUI.rxDetails"></span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="gynIUI.noOfDF">
                                                                <td class="col-8">No Of DF</td>
                                                                <td class="col-4" [textContent]="gynIUI.noOfDF"> </td>
                                                            </tr>
                                                            <tr *ngIf="gynIUI.ovulationTriggers">
                                                                <td class="col-8">Ovulation Triggers</td>
                                                                <td class="col-4" [textContent]="gynIUI.ovulationTriggers"> </td>
                                                            </tr>
                                                            <tr *ngIf="gynIUI.timeForHCGToIUI">
                                                                <td class="col-8">Time from last trigger to IUI (Hrs)</td>
                                                                <td class="col-4" [textContent]="gynIUI.timeForHCGToIUI"> </td>
                                                            </tr>
                                                            <tr *ngIf="gynIUI.cycleNoIUI">
                                                                <td class="col-8">IUI Cycle No</td>
                                                                <td class="col-4" [textContent]="gynIUI.cycleNoIUI"> </td>
                                                            </tr>
                                                            <tr *ngIf="gynIUI.inSeminations && gynIUI.inSeminations!=null">
                                                                <td class="col-8">Number Of Inseminations Per Cycle</td>
                                                                <td class="col-4" [textContent]="gynIUI.inSeminations"> </td>
                                                            </tr>
                                                            <tr *ngIf="gynIUI.inSeminationsList  &&  gynIUI.inSeminationsList.length>0">
                                                                <td colspan="2">
                                                                    <div class="dashboard-body">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <h6 class="font-14 mt-0 mb-2">Number Of Inseminations Per Cycle</h6>
                                                                                <ul class="dashboard-ul row">
                                                                                    <li *ngFor="let item of gynIUI.inSeminationsList" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="gynIUI.days">
                                                                <td class="col-8">Days Of Abstinence</td>
                                                                <td class="col-4" [textContent]="gynIUI.days"> </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dashboard-body">
                                            <div class="overflow-auto">
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gynIUI.volume || gynIUI.liquification || gynIUI.spermConcentration || gynIUI.progressiveMotility || gynIUI.nonProgressiveMotility || gynIUI.immotility || gynIUI.pusCells ||
        gynIUI.rbc || gynIUI.epithelialCells || gynIUI.comments">
                                                        <th colspan="2">PRE PROCESS REPORT</th>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.volume">
                                                        <td class="col-8">Volume(MI)</td>
                                                        <td class="col-4" [textContent]="gynIUI.volume"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.liquification">
                                                        <td class="col-8">Liquefaction Time In Min</td>
                                                        <td class="col-4" [textContent]="gynIUI.liquification"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.spermConcentration">
                                                        <td class="col-8">Sperm Concentration (Millions Per MI)</td>
                                                        <td class="col-4" [textContent]="gynIUI.spermConcentration"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.progressiveMotility">
                                                        <td class="col-8">Progressive Motility(%)</td>
                                                        <td class="col-4" [textContent]="gynIUI.progressiveMotility"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.nonProgressiveMotility">
                                                        <td class="col-8">Non Progressive Motility(%)</td>
                                                        <td class="col-4" [textContent]="gynIUI.nonProgressiveMotility"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.immotility">
                                                        <td class="col-8">Immotility(%)</td>
                                                        <td class="col-4" [textContent]="gynIUI.immotility"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.pusCells">
                                                        <td class="col-8">Pus Cells / HPF</td>
                                                        <td class="col-4" [textContent]="gynIUI.pusCells"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.rbc">
                                                        <td class="col-8">RBC/HPF</td>
                                                        <td class="col-4" [textContent]="gynIUI.rbc"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.epithelialCells">
                                                        <td class="col-8">Epithelial Cells/HPF</td>
                                                        <td class="col-4" [textContent]="gynIUI.epithelialCells"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.comments">
                                                        <td class="col-8">Comments</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynIUI.comments"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="dashboard-body">
                                            <div class="overflow-auto">
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gynIUI.spermPreparation || gynIUI.cultureMedium || gynIUI.inseminationVolume || gynIUI.motileSperms || gynIUI.progressiveMotility1 || gynIUI.nonProgressiveMotility1
|| gynIUI.immotility1 || gynIUI.iuiCatheterUsed || gynIUI.iuiProcedure || gynIUI.date || gynIUI.andrologist || gynIUI.gynaecologist">
                                                        <th colspan="2">POST PROCESS REPORT</th>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.spermPreparation">
                                                        <td class="col-8">Sperm Preparation</td>
                                                        <td class="col-4" [textContent]="gynIUI.spermPreparation"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.cultureMedium">
                                                        <td class="col-8">Details</td>
                                                        <td class="col-4" [textContent]="gynIUI.cultureMedium"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.inseminationVolume">
                                                        <td class="col-8">Insemination Volume(MI)</td>
                                                        <td class="col-4" [textContent]="gynIUI.inseminationVolume"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.motileSperms">
                                                        <td class="col-8">Motile Sperms (Millions Per MI)</td>
                                                        <td class="col-4" [textContent]="gynIUI.motileSperms"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.progressiveMotility1">
                                                        <td class="col-8">Progressive Motility(%)</td>
                                                        <td class="col-4" [textContent]="gynIUI.progressiveMotility1"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.nonProgressiveMotility1">
                                                        <td class="col-8">Non Progressive Motility(%)</td>
                                                        <td class="col-4" [textContent]="gynIUI.nonProgressiveMotility1"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.immotility1">
                                                        <td class="col-8">Immotility(%)</td>
                                                        <td class="col-4" [textContent]="gynIUI.immotility1"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.iuiCatheterUsed">
                                                        <td class="col-8">IUI Catheter Used</td>
                                                        <td class="col-4" [textContent]="gynIUI.iuiCatheterUsed"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.iuiProcedure">
                                                        <td class="col-8">IUI Procedure</td>
                                                        <td class="col-4" [textContent]="gynIUI.iuiProcedure"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.iuiDate">
                                                        <td class="col-8">Date</td>
                                                        <td class="col-4" [textContent]="gynIUI.iuiDate | date :'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.iuiProcedure">
                                                        <td class="col-8">Details</td>
                                                        <!-- <td class="col-4" [textContent]="gynIUI.details"> </td>-->
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynIUI.details"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.date">
                                                        <td class="col-8">Date</td>
                                                        <td class="col-4" [textContent]="gynIUI.date | date:'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.andrologist">
                                                        <td class="col-8">Andrologist</td>
                                                        <td class="col-4" [textContent]="gynIUI.andrologist"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.gynaecologist">
                                                        <td class="col-8">Gynaecologist</td>
                                                        <td class="col-4" [textContent]="gynIUI.gynaecologist"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynIUI.reviewdate">
                                                        <td class="col-8">Review Date</td>
                                                        <td class="col-4" [textContent]="gynIUI.reviewdate"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="dashboard-body" *ngIf="gynIUI.adviceToPatient && gynIUI.adviceToPatient.length>0">
                                            <div class="row">
                                                <div class="col-12">
                                                    <h6 class="font-14 mt-0 mb-2">Advice To Patient</h6>
                                                    <ul class="dashboard-ul row">
                                                        <li *ngFor="let item of gynIUI.adviceToPatient" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isAdmission==false">
                            <div *ngIf="roleId != 63">
                                <div class="dashboard-box dashboard-table-box" #gyneacPartnerDetailsSection *ngIf="partnerDetails && partnerDetails !== null">
                                    <h4 class="dashboard-title">Gynaec Partner Details</h4>
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <div class="row" *ngIf="partnerDetails && partnerDetails !== null ">
                                                <div class="col-12">
                                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                        <tr class="bg-soft-dark" style="border-bottom:1px solid black">
                                                            <th colspan="2">Gynaec Partner Details</th>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.husbandName">
                                                            <td class="col-8">Husband Name</td>
                                                            <td class="col-4" [textContent]="partnerDetails.husbandName"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.husbandAge">
                                                            <td class="col-8">Husband Age</td>
                                                            <td class="col-4" [textContent]="partnerDetails.husbandAge"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.gynNo">
                                                            <td class="col-8">GYN No</td>
                                                            <td class="col-4" [textContent]="partnerDetails.gynNo"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.visitNo">
                                                            <td class="col-8">Visit No</td>
                                                            <td class="col-4" [textContent]="partnerDetails.visitNo"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.consultantDoctor">
                                                            <td class="col-8">Consultant Dr</td>
                                                            <td class="col-4" [textContent]="partnerDetails.consultantDoctor.toUpperCase()"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.occupation">
                                                            <td class="col-8">Occupation</td>
                                                            <td class="col-4" [textContent]="partnerDetails.occupation"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.bloodGroup">
                                                            <td class="col-8">Blood Group</td>
                                                            <td class="col-4" [textContent]="partnerDetails.bloodGroup"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.rhType">
                                                            <td class="col-8">Rh Type</td>
                                                            <td class="col-4" [textContent]="partnerDetails.rhType"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.tobacco">
                                                            <td class="col-8">Tobacco</td>
                                                            <td class="col-4" [textContent]="partnerDetails.tobacco"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.ifYesDetails1">
                                                            <td class="col-8">If Yes Details</td>
                                                            <td class="col-4" [textContent]="partnerDetails.ifYesDetails1"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.alcohol">
                                                            <td class="col-8">Alcohol</td>
                                                            <td class="col-4" [textContent]="partnerDetails.alcohol"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.ifYesDetails2">
                                                            <td class="col-8">If Yes Details</td>
                                                            <td class="col-4" [textContent]="partnerDetails.ifYesDetails2"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.medicalHistory">
                                                            <td class="col-8">Medical History</td>
                                                            <td class="col-4" [textContent]="partnerDetails.medicalHistory"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.surgicalHistory">
                                                            <td class="col-8">Surgical History</td>
                                                            <td class="col-4" [textContent]="partnerDetails.surgicalHistory"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.treatmentHistory">
                                                            <td class="col-8">Treatment History</td>
                                                            <td class="col-4" [textContent]="partnerDetails.treatmentHistory"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.hivDate">
                                                            <td class="col-8">HIV Date</td>
                                                            <td class="col-4" [textContent]="partnerDetails.hivDate"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.hivResult">
                                                            <td class="col-8">HIV Result</td>
                                                            <td class="col-4" [textContent]="partnerDetails.hivResult"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.hivDate">
                                                            <td class="col-8">HBs Ag Date</td>
                                                            <td class="col-4" [textContent]="partnerDetails.hbsAgDate"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.hbsAgResult">
                                                            <td class="col-8">HBs Ag Result</td>
                                                            <td class="col-4" [textContent]="partnerDetails.hbsAgResult"> </td>
                                                        </tr>

                                                        <tr *ngIf="partnerDetails.antiHCVDate">
                                                            <td class="col-8">Anti HCV Date</td>
                                                            <td class="col-4" [textContent]="partnerDetails.antiHCVDate"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.antiHCVResult">
                                                            <td class="col-8">Anti HCV Result</td>
                                                            <td class="col-4" [textContent]="partnerDetails.antiHCVResult"> </td>
                                                        </tr>
                                                        <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="partnerDetails.date || partnerDetails.volume || partnerDetails.liquificationTime|| partnerDetails.spermConcentration|| partnerDetails.activeMotility
                              || partnerDetails.sluggishMotility|| partnerDetails.normalForms||partnerDetails.pusCells||partnerDetails.impression||partnerDetails.remarks">
                                                            <th colspan="2">Semen Analysis Report</th>

                                                        </tr>
                                                        <tr *ngIf="partnerDetails.date">
                                                            <td class="col-8">Date</td>
                                                            <td class="col-4" [textContent]="partnerDetails.date | date :'dd/MM/yyyy'"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.volume">
                                                            <td class="col-8">Volume</td>
                                                            <td class="col-4" [textContent]="partnerDetails.volume"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.liquificationTime">
                                                            <td class="col-8">Liquefaction Time</td>
                                                            <td class="col-4" [textContent]="partnerDetails.liquificationTime"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.spermConcentration">
                                                            <td class="col-8">Sperm Concentration (Mill/MI)</td>
                                                            <td class="col-4" [textContent]="partnerDetails.spermConcentration"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.activeMotility">
                                                            <td class="col-8">Active Motility %</td>
                                                            <td class="col-4" [textContent]="partnerDetails.activeMotility"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.sluggishMotility">
                                                            <td class="col-8">Sluggish Motility %</td>
                                                            <td class="col-4" [textContent]="partnerDetails.sluggishMotility"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.normalForms">
                                                            <td class="col-8">Normal Forms %</td>
                                                            <td class="col-4" [textContent]="partnerDetails.normalForms"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.pusCells">
                                                            <td class="col-8">Pus Cells Per HPF</td>
                                                            <td class="col-4" [textContent]="partnerDetails.pusCells"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.impression">
                                                            <td class="col-8">Impression</td>
                                                            <td class="col-4" [textContent]="partnerDetails.impression"> </td>
                                                        </tr>
                                                        <tr *ngIf="partnerDetails.remarks">
                                                            <td class="col-8">Remarks</td>
                                                            <!--<td class="col-4" [textContent]="partnerDetails.remarks"> </td>-->
                                                            <td class="col-4">
                                                                <div style="white-space:normal; word-break:break-all;">
                                                                    <span [textContent]="partnerDetails.remarks"></span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isAdmission==false">
                            <div *ngIf="roleId != 63">
                                <div class="dashboard-box dashboard-table-box" #opProcedureFormSection *ngIf="procedureForm && procedureForm.length>0">
                                    <div *ngFor="let procedureForm of procedureFormData">
                                        <h4 class="dashboard-title">OP Procedure Form</h4>
                                        <div class="dashboard-body">
                                            <div class="overflow-auto">
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black">
                                                        <th colspan="2">OP Procedure form</th>
                                                    </tr>
                                                    <tr *ngIf="procedureForm.date">
                                                        <td class="col-8">Date</td>
                                                        <td class="col-4" [textContent]="procedureForm.date| date:'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr *ngIf="procedureForm.partnersName">
                                                        <td class="col-8">Partners Name</td>
                                                        <td class="col-4" [textContent]="procedureForm.partnersName"> </td>
                                                    </tr>
                                                    <tr *ngIf="procedureForm.consultant">
                                                        <td class="col-8">Consultant</td>
                                                        <td class="col-4" [textContent]="procedureForm.consultant.toUpperCase()"> </td>
                                                    </tr>
                                                    <tr *ngIf="procedureForm.specialization">
                                                        <td class="col-8">Specialization</td>
                                                        <td class="col-4" [textContent]="procedureForm.specialization"> </td>
                                                    </tr>
                                                    <!--<tr *ngIf="procedureForm.procedures">
                                                        <td class="col-8">Procedure</td>
                                                        <td class="col-4" [textContent]="procedureForm.procedures"> </td>
                                                    </tr>-->
                                                    <tr *ngIf="procedureForm.procedures && procedureForm.procedures.length > 0">
                                                        <td colspan="2">
                                                            <div class="dashboard-body">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <h6 class="font-14 mt-0 mb-2">Procedure</h6>
                                                                        <ul class="dashboard-ul row">
                                                                            <li *ngFor="let item of procedureForm.procedures" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="procedureForm.team">
                                                        <td class="col-8">Team</td>
                                                        <td class="col-4" [textContent]="procedureForm.team"> </td>
                                                    </tr>
                                                    <tr *ngIf="procedureForm.indication">
                                                        <td class="col-8">Indication</td>
                                                        <td class="col-4" [textContent]="procedureForm.indication"> </td>
                                                    </tr>
                                                    <tr *ngIf="procedureForm.lmp">
                                                        <td class="col-8">LMP</td>
                                                        <td class="col-4" [textContent]="procedureForm.lmp | date:'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr *ngIf="procedureForm.finding">
                                                        <td class="col-8">Findings</td>
                                                        <!--<td class="col-4" [textContent]="procedureForm.finding"> </td>-->
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="procedureForm.finding"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="procedureForm.analgesia">
                                                        <td class="col-8">Analgesia</td>
                                                        <td class="col-4" [textContent]="procedureForm.analgesia"> </td>
                                                    </tr>
                                                    <tr *ngIf="procedureForm.antibiotics">
                                                        <td class="col-8">Antibiotics</td>
                                                        <td class="col-4" [textContent]="procedureForm.antibiotics"> </td>
                                                    </tr>
                                                    <tr *ngIf="procedureForm.labSamples">
                                                        <td class="col-8">Lab Samples</td>
                                                        <td class="col-4" [textContent]="procedureForm.labSamples"> </td>
                                                    </tr>
                                                    <tr *ngIf="procedureForm.abandoned">
                                                        <td class="col-8">Procedure Not Completed Abandoned</td>
                                                        <td class="col-4" [textContent]="procedureForm.abandoned"> </td>
                                                    </tr>
                                                    <tr *ngIf="procedureForm.reason">
                                                        <td class="col-8">Reason For The Same</td>
                                                        <td class="col-4" [textContent]="procedureForm.reason"> </td>
                                                    </tr>
                                                    <tr *ngIf="procedureForm.duration">
                                                        <td class="col-8">Duration (Min)</td>
                                                        <td class="col-4" [textContent]="procedureForm.duration"> </td>
                                                    </tr>
                                                    <tr *ngIf="procedureForm.painScore">
                                                        <td class="col-8">Pain Score</td>
                                                        <td class="col-4" [textContent]="procedureForm.painScore"> </td>
                                                    </tr>
                                                    <tr *ngIf="procedureForm.remarks">
                                                        <td class="col-8">Remarks</td>
                                                        <td class="col-4" [textContent]="procedureForm.remarks"> </td>
                                                    </tr>
                                                    <tr *ngIf="procedureForm.review">
                                                        <td class="col-8">Review</td>
                                                        <td class="col-4" [textContent]="procedureForm.review"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isAdmission==false">
                            <div *ngIf="roleId != 63">
                                <div class="dashboard-box dashboard-table-box" #proformaForOasiSection *ngIf="proformaForOASI && proformaForOASI !== null">
                                    <h4 class="dashboard-title">Proforma for OASI Follow Up</h4>
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <div class="row" *ngIf="proformaForOASI && proformaForOASI !== null ">
                                                <div class="col-12">
                                                    <table class="table table-centered table-sm table-striped mb-0">
                                                        <tr class="bg-soft-dark" style="border-bottom:1px solid black">
                                                            <th colspan="2">Proforma For OASI Follow Up</th>
                                                        </tr>

                                                        <tr *ngIf="proformaForOASI.deliveryDate">
                                                            <td class="col-8">Date Of Delivery</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.deliveryDate | date :'dd/MM/yyyy'"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.ancNo">
                                                            <td class="col-8">GYN No</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.ancNo"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.followupVisit">
                                                            <td class="col-8">Follow Up Visit </td>
                                                            <td class="col-4" [textContent]="proformaForOASI.followupVisit"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.visitDate">
                                                            <td class="col-8">Visit Date</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.visitDate | date :'dd/MM/yyyy'"> </td>
                                                        </tr>
                                                        <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="proformaForOASI.gravida|| proformaForOASI.para||proformaForOASI.living
                                                    || proformaForOASI.abortion||proformaForOASI.bmi||proformaForOASI.weight|| proformaForOASI.gaAtDelivery||proformaForOASI.previousCs||proformaForOASI.inducedLabour||proformaForOASI.anaesthesia||proformaForOASI.durationOfActivePhase
                                                ||proformaForOASI.durationOf2ndStage||proformaForOASI.modeOfDelivery||proformaForOASI.episiotomy||proformaForOASI.placeOfRepair||proformaForOASI.perineum||proformaForOASI.lacerations
                                                ||proformaForOASI.birthWeight|| proformaForOASI.hc||proformaForOASI.liquor||proformaForOASI.shoulderDystocia">
                                                            <th colspan="2">Delivery Details</th>
                                                        </tr>

                                                        <tr *ngIf="proformaForOASI.gravida">
                                                            <td class="col-8">Gravida</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.gravida"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.para">
                                                            <td class="col-8">Para</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.para"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.living">
                                                            <td class="col-8">Living</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.living"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.abortion">
                                                            <td class="col-8">Abortion</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.abortion"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.bmi">
                                                            <td class="col-8">BMI</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.bmi"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.weight">
                                                            <td class="col-8">Weight</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.weight"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.gaAtDelivery">
                                                            <td class="col-8">GA At Delivery</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.gaAtDelivery"> </td>
                                                        </tr>

                                                        <tr *ngIf="proformaForOASI.previousCs">
                                                            <td class="col-8">Previous Cs</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.previousCs"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.inducedLabour">
                                                            <td class="col-8">Induced Labour</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.inducedLabour"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.anaesthesia">
                                                            <td class="col-8">Anaesthesia</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.anaesthesia"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.durationOfActivePhase">
                                                            <td class="col-8">Duration Of Active Phase</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.durationOfActivePhase"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.durationOf2ndStage">
                                                            <td class="col-8">Duration Of 2nd Stage</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.durationOf2ndStage"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.modeOfDelivery">
                                                            <td class="col-8">Mode Of Delivery</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.modeOfDelivery"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.episiotomy">
                                                            <td class="col-8">Episiotomy</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.episiotomy"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.placeOfRepair">
                                                            <td class="col-8">Place Of Repair</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.placeOfRepair"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.perineum">
                                                            <td class="col-8">Perineum</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.perineum"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.lacerations">
                                                            <td class="col-8">Lacerations</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.lacerations"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.perinalTear">
                                                            <td class="col-8">Perinal Tears</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.perinalTear"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.birthWeight">
                                                            <td class="col-8">Birth Weight</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.birthWeight"> </td>
                                                        </tr>
                                                        <!--||postnantalDate||proformaForOASI.durationSinceTear-->
                                                        <tr *ngIf="proformaForOASI.hc">
                                                            <td class="col-8">HC</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.hc"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.suturing">
                                                            <td class="col-8">Suturing</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.suturing"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.liquor">
                                                            <td class="col-8">Liquor</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.liquor"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.shoulderDystocia">
                                                            <td class="col-8">Shoulder Dystocia</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.shoulderDystocia"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.prDone">
                                                            <td class="col-8">PR Done</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.prDone"> </td>
                                                        </tr>

                                                        <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="proformaForOASI.postnatalDate|| proformaForOASI.durationSinceTear||proformaForOASI.perinealPain
                                                    || proformaForOASI.dyspaerunia||proformaForOASI.howFrequentD||proformaForOASI.constipation|| proformaForOASI.painfulDefaecation||proformaForOASI.fecalIncontinence||
                                                    proformaForOASI.flatus||proformaForOASI.fluid||proformaForOASI.faeces">
                                                            <th colspan="2">Postnatal Review</th>
                                                        </tr>

                                                        <tr *ngIf="proformaForOASI.postnatalDate">
                                                            <td class="col-8">Date</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.postnatalDate| date :'dd/MM/yyyy'"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.durationSinceTear">
                                                            <td class="col-8">Duration Since Tear</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.durationSinceTear"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.symptoms">
                                                            <td class="col-8">Symptoms</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.symptoms"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.details">
                                                            <td class="col-8">Details</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.details"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.perinealPain">
                                                            <td class="col-8">Perineal Pain</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.perinealPain"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.howFrequent">
                                                            <td class="col-8">How Frequent</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.howFrequent"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.anagles">
                                                            <td class="col-8">Needing Analgesics</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.anagles"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.dyspaerunia">
                                                            <td class="col-8">Dyspaerunia</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.dyspaerunia"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.howFrequentD">
                                                            <td class="col-8">How Frequent D</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.howFrequentD"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.frequencyList && proformaForOASI.frequencyList.length >0">
                                                            <td colspan="2">
                                                                <div class="dashboard-body">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <h6>How Frequent D</h6>
                                                                            <ul class="dashboard-ul row">
                                                                                <li *ngFor="let item of proformaForOASI.frequencyList" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <!--<tr *ngIf="proformaForOASI.bowelSymptoms||proformaForOASI.bladderSymptoms||proformaForOASI.woundInfection">
                                                            <td class="col-8">How Frequent D</td>
                                                            <td class="col-4">
                                                                <span *ngIf="proformaForOASI.bowelSymptoms">Bowel Symptoms ,</span>&nbsp;
                                                                <span *ngIf="proformaForOASI.bladderSymptoms">Bladder Symptoms and</span>&nbsp;
                                                                <span *ngIf="proformaForOASI.woundInfection">Wound Infection</span>&nbsp;
                                                            </td>
                                                        </tr>-->
                                                        <tr *ngIf="proformaForOASI.fistula">
                                                            <td class="col-8">Fistula</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.fistula"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.constipation">
                                                            <td class="col-8">Constipation</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.constipation"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.painfulDefaecation">
                                                            <td class="col-8">Painful Defaecation</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.painfulDefaecation"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.fecalIncontinence">
                                                            <td class="col-8">Fecal Incontinence</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.fecalIncontinence"> </td>
                                                        </tr>
                                                        <!--<tr *ngIf="proformaForOASI.flatus||proformaForOASI.fluid||proformaForOASI.faeces">
                                                            <td class="col-8">Fecal Incontinence</td>
                                                            <td class="col-4">
                                                                <span *ngIf="proformaForOASI.flatus">flatus</span>&nbsp;
                                                                <span *ngIf="proformaForOASI.fluid">fluid</span>&nbsp;
                                                                <span *ngIf="proformaForOASI.faeces">faeces</span>&nbsp;
                                                            </td>
                                                        </tr>-->
                                                        <tr *ngIf="proformaForOASI.faecesList &&  proformaForOASI.faecesList.length>0">
                                                            <td colspan="2">
                                                                <div class="dashboard-body">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <h6 class="font-14 mt-0 mb-2">Fecal Incontinence</h6>
                                                                            <ul class="dashboard-ul row">
                                                                                <li *ngFor="let item of proformaForOASI.faecesList" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <!--<tr *ngIf="proformaForOASI.flatus">
                                                            <td class="col-8">Flatus</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.flatus"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.fluid">
                                                            <td class="col-8">Fluid</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.fluid"> </td>
                                                        </tr>-->
                                                        <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf=" proformaForOASI.solid||proformaForOASI.liquid
                                                    || proformaForOASI.gas||proformaForOASI.wearPad||proformaForOASI.lifestyleAlteration">
                                                            <th colspan="2"> Type Of Incontinence</th>
                                                        </tr>

                                                        <tr *ngIf="proformaForOASI.solid">
                                                            <td class="col-8">Solid</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.solid"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.liquid">
                                                            <td class="col-8">Liquid</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.liquid"> </td>
                                                        <tr *ngIf="proformaForOASI.gas">
                                                            <td class="col-8">Gas</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.gas"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.wearPad">
                                                            <td class="col-8">Wear Pad</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.wearPad"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.lifestyleAlteration">
                                                            <td class="col-8">Lifestyle Alteration</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.lifestyleAlteration"> </td>
                                                        </tr>

                                                        <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf=" proformaForOASI.difficultyPassinUrine||proformaForOASI.frequencyDysuria
                                                    || proformaForOASI.urinaryIncontinence">
                                                            <th colspan="2">Urinary Symptoms</th>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.difficultyPassinUrine">
                                                            <td class="col-8">Difficulty Pass in Urine</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.difficultyPassinUrine"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.frequencyDysuria">
                                                            <td class="col-8">Frequency Dysuria</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.frequencyDysuria"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.culture">
                                                            <td class="col-8">If Yes CUE And Culture Sent</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.culture"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.urinaryIncontinence">
                                                            <td class="col-8">Urinary Incontinence</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.urinaryIncontinence"> </td>
                                                        </tr>
                                                        <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf=" proformaForOASI.urineLeakage||proformaForOASI.urineLose
                                                    || proformaForOASI.severity|| proformaForOASI.severityScore|| proformaForOASI.endoanalUltrasound|| proformaForOASI.analManometry|| proformaForOASI.analManometry
                                                    || proformaForOASI.anyOtherProblems|| proformaForOASI.managementPlan|| proformaForOASI.reviewDate|| proformaForOASI.seenByDoctor|| proformaForOASI.bowelSymptoms
                                                    || proformaForOASI.bladderSymptoms|| proformaForOASI.woundInfection">
                                                            <th colspan="2">If Urinary Incontinence</th>
                                                        </tr>
                                                        <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf=" proformaForOASI.urineLeakage||proformaForOASI.urineLose
                                                    || proformaForOASI.severity|| proformaForOASI.severityScore|| proformaForOASI.endoanalUltrasound|| proformaForOASI.analManometry|| proformaForOASI.analManometry
                                                    || proformaForOASI.anyOtherProblems|| proformaForOASI.managementPlan|| proformaForOASI.reviewDate|| proformaForOASI.seenByDoctor|| proformaForOASI.bowelSymptoms
                                                    || proformaForOASI.bladderSymptoms|| proformaForOASI.woundInfection">
                                                            <th colspan="2">Severity Index</th>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.urineLeakage">
                                                            <td class="col-8">1.How Often Do You Experience Urine Leakage?</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.urineLeakage"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.urineLose">
                                                            <td class="col-8">2.How Much Urine Do You Lose Each Time?</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.urineLose"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.severity">
                                                            <td class="col-8">The Severity Is Described By The Total Score Which Is The Source For The First Question Multiplied By The Score For The Second Question</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.severity"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.severityScore">
                                                            <td class="col-8">Severity Score</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.severityScore"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.endoanalUltrasound">
                                                            <td class="col-8">Endoanal UltraSound</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.endoanalUltrasound"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.analManometry">
                                                            <td class="col-8">Anal Manometry</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.analManometry"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.anyOtherProblems">
                                                            <td class="col-8">Any Other Problems</td>
                                                            <!--<td class="col-4" [textContent]="proformaForOASI.anyOtherProblems"> </td>-->
                                                            <td class="col-4">
                                                                <div style="white-space:normal; word-break:break-all;">
                                                                    <span [textContent]="proformaForOASI.anyOtherProblems"></span>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr *ngIf="proformaForOASI.managementPlan">
                                                            <td class="col-8">Management Plan</td>
                                                            <!--<td class="col-4" [textContent]="proformaForOASI.managementPlan"> </td>-->
                                                            <td class="col-4">
                                                                <div style="white-space:normal; word-break:break-all;">
                                                                    <span [textContent]="proformaForOASI.managementPlan"></span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.reviewDate">
                                                            <td class="col-8">Review Date</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.reviewDate | date :'dd/MM/yyyy'"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.seenByDoctor">
                                                            <td class="col-8">Seen By Doctor</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.seenByDoctor"> </td>
                                                        </tr>
                                                        <!--<tr *ngIf="proformaForOASI.bowelSymptoms">
                                                            <td class="col-8">Bowel Symptoms</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.bowelSymptoms"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.bladderSymptoms">
                                                            <td class="col-8">Bladder Symptoms</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.bladderSymptoms"> </td>
                                                        </tr>
                                                        <tr *ngIf="proformaForOASI.woundInfection">
                                                            <td class="col-8">Wound Infection</td>
                                                            <td class="col-4" [textContent]="proformaForOASI.woundInfection"> </td>
                                                        </tr>-->

                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div *ngIf="roleId != 63">
                            <div class="dashboard-box dashboard-table-box" *ngIf="ectopic || ectopic!==null && (  uvsounding && uvsounding.length>0  || singleDose && singleDose.length>0 || multiDose && multiDose.length>0 || followup && followup.length>0)">


                                <h4 class="dashboard-title">Medical Management Of Ectopic Pregnancy</h4>
                                <div class="dashboard-body">
                                    <div class="row" *ngIf="ectopic && ectopic !== null ">
                                        <div class="col-12">
                                            <table class="table table-centered  table-sm table-striped mb-0">
                                                <tr class="bg-soft-dark" style="border-bottom:1px solid black">
                                                    <th colspan="2">Medical Management Of Ectopic Pregnancy</th>
                                                </tr>

                                                <tr *ngIf="ectopic.consultantDoctor">
                                                    <td class="col-8">Consultant Doctor</td>
                                                    <td class="col-4" [textContent]="ectopic.consultantDoctor.toUpperCase()"> </td>
                                                </tr>

                                                <tr *ngIf="ectopic.heightInFeet">
                                                    <td class="col-8">Height (Cm)</td>
                                                    <td class="col-4" [textContent]="ectopic.heightInFeet"> </td>
                                                </tr>
                                                <tr *ngIf="ectopic.weight">
                                                    <td class="col-8">Weight (Kg)</td>
                                                    <td class="col-4" [textContent]="ectopic.weight"> </td>
                                                </tr>
                                                <tr *ngIf="ectopic.bsa">
                                                    <td class="col-8">BSA(Kg/M2)</td>
                                                    <td class="col-4" [textContent]="ectopic.bsa"> </td>
                                                </tr>
                                                <tr *ngIf="ectopic.bloodGroup">
                                                    <td class="col-8">Blood Group</td>
                                                    <td class="col-4" [textContent]="ectopic.bloodGroup"> </td>
                                                </tr>
                                                <tr *ngIf="ectopic.rhType">
                                                    <td class="col-8">Rh Type</td>
                                                    <td class="col-4" [textContent]="ectopic.rhType"> </td>
                                                </tr>
                                                <tr *ngIf="ectopic.antiD">
                                                    <td class="col-8">Anti D</td>
                                                    <td class="col-4" [textContent]="ectopic.antiD"> </td>
                                                </tr>
                                                <tr *ngIf="ectopic.ifYesDose">
                                                    <td class="col-8">If Yes Dose</td>
                                                    <td class="col-4" [textContent]="ectopic.ifYesDose"> </td>
                                                </tr>
                                                <tr *ngIf="ectopic.antidDate">
                                                    <td class="col-8">Date Antid</td>
                                                    <td class="col-4" [textContent]="ectopic.antidDate |  date:'dd/MM/yyyy'"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="col-12" *ngIf="uvsounding && uvsounding.length>0">
                                            <label>UltraSound Findings</label>
                                            <table class="table table-centered table-sm table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>SN</th>
                                                        <th>Date</th>
                                                        <th>Ultrasound Findings</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of uvsounding; let i=index">
                                                        <!--<td>
                                                            <p *ngIf="item.sn" [textContent]="item.sn "></p>
                                                            <p *ngIf="!item.sn" [textContent]="'-'"></p>
                                                        </td>-->
                                                        <td>{{i+1}}</td>
                                                        <td>
                                                            <p *ngIf="item.date" [textContent]="item.date | date :'dd/MM/yyyy'"></p>
                                                            <p style="padding-left: 25px" *ngIf="!item.comments" [textContent]="'-'"></p>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="item.comments" [textContent]="item.comments"></p>
                                                            <p style="padding-left: 25px" *ngIf="!item.comments" [textContent]="'-'"></p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-12" *ngIf="investigations && investigations.length>0">
                                            <label>Investigations</label>
                                            <div class="overflow-auto">
                                                <table class="table table-sm table-striped mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>SN</th>
                                                            <th>Date</th>
                                                            <th>Haemoglobin (Gm/Dl)</th>
                                                            <th>WBC Count (/Cumm)</th>
                                                            <th>Platelet Count (Lakhs/Cumm)</th>
                                                            <th>Bilirubin (Mg/Dl)</th>
                                                            <th>SGOT (U/L)</th>
                                                            <th>SGPT (U/L))</th>
                                                            <th>
                                                                Creatinine (Mg/Dl)
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of investigations;  let i = index">
                                                            <td>
                                                                {{i+1}}
                                                            </td>
                                                            <!--<td>
                                                                <p *ngIf="item.sn" [textContent]="item.sn "></p>
                                                                <p *ngIf="!item.sn" [textContent]="'-'"></p>
                                                            </td>-->
                                                            <td>
                                                                <p *ngIf="item.investigationDate" [textContent]="item.investigationDate  | date :'dd/MM/yyyy'"></p>
                                                                <p style="padding-left: 25px" *ngIf="!item.investigationDate" [textContent]="'-'"></p>
                                                            </td>
                                                            <td>
                                                                <p *ngIf="item.haemoglobin" [textContent]="item.haemoglobin"></p>
                                                                <p style="padding-left: 25px" *ngIf="!item.haemoglobin" [textContent]="'-'"></p>
                                                            </td>
                                                            <td>
                                                                <p *ngIf="item.wbcCount" [textContent]="item.wbcCount"></p>
                                                                <p style="padding-left: 25px" *ngIf="!item.wbcCount" [textContent]="'-'"></p>
                                                            </td>
                                                            <td>
                                                                <p *ngIf="item.plateletCount" [textContent]="item.plateletCount"></p>
                                                                <p style="padding-left: 25px" *ngIf="!item.plateletCount" [textContent]="'-'"></p>
                                                            </td>
                                                            <td>
                                                                <p *ngIf="item.bilirubin" [textContent]="item.bilirubin"></p>
                                                                <p style="padding-left: 25px" *ngIf="!item.bilirubin" [textContent]="'-'"></p>
                                                            </td>
                                                            <td>
                                                                <p *ngIf="item.sgot" [textContent]="item.sgot"></p>
                                                                <p style="padding-left: 25px" *ngIf="!item.sgot" [textContent]="'-'"></p>
                                                            </td>
                                                            <td>
                                                                <p *ngIf="item.sgpt" [textContent]="item.sgpt"></p>
                                                                <p style="padding-left: 25px" *ngIf="!item.sgpt" [textContent]="'-'"></p>
                                                            </td>
                                                            <td>
                                                                <p *ngIf="item.creatinine" [textContent]="item.creatinine"></p>
                                                                <p style="padding-left: 25px" *ngIf="!item.creatinine" [textContent]="'-'"></p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="col-12" *ngIf="singleDose && singleDose.length>0">
                                            <label>Single Dose Regime</label>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>SN</th>
                                                        <th>S Dose</th>
                                                        <th>Date</th>
                                                        <th>Beta HCG (MIU/Ml)</th>
                                                        <th>Methotrexate (50 Mg/M2 )</th>
                                                        <th>Mifepristone (600 Mg)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of singleDose;  let i = index">
                                                        <td>{{i+1}}</td>
                                                        <!--<td>
                                                            <p *ngIf="item.sn" [textContent]="item.sn "></p>
                                                            <p *ngIf="!item.sn" [textContent]="'-'"></p>
                                                        </td>-->
                                                        <td>
                                                            <p *ngIf="item.sDose" [textContent]="item.sDose"></p>
                                                            <p style="padding-left: 25px" *ngIf="!item.sDose" [textContent]="'-'"></p>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="item.date" [textContent]="item.date  | date :'dd/MM/yyyy'"></p>
                                                            <p style="padding-left: 25px" *ngIf="!item.date" [textContent]="'-'"></p>
                                                        </td>

                                                        <td>
                                                            <p *ngIf="item.betaHCG" [textContent]="item.betaHCG"></p>
                                                            <p style="padding-left: 25px" *ngIf="!item.betaHCG" [textContent]="'-'"></p>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="item.methotrexate" [textContent]="item.methotrexate"></p>
                                                            <p style="padding-left: 25px" *ngIf="!item.methotrexate" [textContent]="'-'"></p>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="item.mifepristone" [textContent]="item.mifepristone"></p>
                                                            <p style="padding-left: 25px" *ngIf="!item.mifepristone" [textContent]="'-'"></p>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-12" *ngIf="multiDose && multiDose.length>0">
                                            <label>Multiple Dose Regime</label>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>SN</th>
                                                        <th>Rx Day</th>
                                                        <th>Date</th>
                                                        <th>Beta HCG (MIU/Ml)</th>
                                                        <th>Methotrexate (1 Mg/Kg)</th>
                                                        <th>Folinic Acid (0.1mg/Kg)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of multiDose;   let i = index">
                                                        <td>{{i+1}}</td>
                                                        <!--<td>
                                                            <p *ngIf="item.sn" [textContent]="item.sn "></p>
                                                            <p *ngIf="!item.sn" [textContent]="'-'"></p>
                                                        </td>-->
                                                        <td>
                                                            <p *ngIf="item.rxDay" [textContent]="item.rxDay"></p>
                                                            <p style="padding-left: 25px" *ngIf="!item.rxDay" [textContent]="'-'"></p>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="item.date" [textContent]="item.date  | date :'dd/MM/yyyy'"></p>
                                                            <p style="padding-left: 25px" *ngIf="!item.date" [textContent]="'-'"></p>
                                                        </td>

                                                        <td>
                                                            <p *ngIf="item.betaHCG" [textContent]="item.betaHCG"></p>
                                                            <p style="padding-left: 25px" *ngIf="!item.betaHCG" [textContent]="'-'"></p>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="item.methotrexate" [textContent]="item.methotrexate"></p>
                                                            <p style="padding-left: 25px" *ngIf="!item.methotrexate" [textContent]="'-'"></p>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="item.folinicAcid" [textContent]="item.folinicAcid"></p>
                                                            <p style="padding-left: 25px" *ngIf="!item.folinicAcid" [textContent]="'-'"></p>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-12" *ngIf="followup && followup.length>0">
                                            <label>Follow Up</label>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>SN</th>
                                                        <th>Date</th>
                                                        <th>Weekly Beta HCG (MIU/Ml)</th>
                                                        <th>Reviewing Doctor</th>
                                                        <th>Further Plan</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of followup; let i=index">

                                                        <td>
                                                            {{i+1}}
                                                        </td>

                                                        <td>
                                                            <p *ngIf="item.date" [textContent]="item.date  | date :'dd/MM/yyyy'"></p>
                                                            <p style="padding-left: 25px" *ngIf="!item.date" [textContent]="'-'"></p>
                                                        </td>

                                                        <td>
                                                            <p *ngIf="item.weeklyBetaHCG" [textContent]="item.weeklyBetaHCG"></p>
                                                            <p style="padding-left: 25px" *ngIf="!item.weeklyBetaHCG" [textContent]="'-'"></p>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="item.reviewingDoctor" [textContent]="item.reviewingDoctor"></p>
                                                            <p style="padding-left: 25px" *ngIf="!item.reviewingDoctor" [textContent]="'-'"></p>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="item.furtherPlan" [textContent]="item.furtherPlan"></p>
                                                            <p style="padding-left: 25px" *ngIf="!item.furtherPlan" [textContent]="'-'"></p>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isAdmission == true">
                            <div *ngIf="roleId != 63">
                                <!--#specialFeatureSection-->
                                <div class="dashboard-box dashboard-table-box" *ngIf="specialFeature && specialFeature !== null">
                                    <h4 class="dashboard-title">Special Features</h4>
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="specialFeature.specialFeature">
                                                    <td class="col-8">Special Features</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="specialFeature.specialFeature"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="roleId != 63">
                            <div class="dashboard-box dashboard-table-box" *ngIf="outSideTests && outSideTests !== null" #outsideTestsSection>
                                <h4 class="dashboard-title">Outside Tests</h4>
                                <div class="dashboard-body" *ngFor="let items of outSideTests">
                                    <div class="overflow-auto">
                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="items">
                                            <thead>
                                                <tr>
                                                    <th>Done Date</th>
                                                    <th>Test Name</th>
                                                    <th>Other Tests</th>
                                                    <th>Other Test Results</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div class="media">
                                                            <div class="media-body">
                                                                <h5 class="mb-0 mt-0 font-weight-normal text-truncate-line" [textContent]=" items.doneDate | date:'dd/MM/yyyy'"></h5>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p *ngIf="items.testname" [textContent]="items.testname"></p>
                                                    </td>
                                                    <td>
                                                        <p *ngIf="items.otherTests" [textContent]="items.otherTests "></p>
                                                    </td>
                                                    <td>
                                                        <p *ngIf="items.otherTestResults" [textContent]="items.otherTestResults "></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table *ngIf="items.parameters && items.parameters.length>0" class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <thead>
                                                <tr>
                                                    <th>SN</th>
                                                    <th>Component</th>
                                                    <th>Parameter</th>
                                                    <th>Result Value</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr *ngFor="let item of items.parameters; let i=index">
                                                    <td>
                                                        <p *ngIf="item.parameterName" [textContent]="i+1"></p>
                                                    </td>
                                                    <td>
                                                        <p *ngIf="item.componentName" [textContent]="item.componentName"></p>
                                                    </td>
                                                    <td>
                                                        <p *ngIf="item.parameterName" [textContent]="item.parameterName"></p>
                                                    </td>
                                                    <td>
                                                        <p *ngIf="item.resultValues" [textContent]="item.resultValues"></p>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="roleId != 63">
                            <div class="dashboard-box dashboard-table-box" *ngIf="reminders && reminders !== null">
                                <h4 class="dashboard-title">Reminders</h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <div class="row col-12" *ngIf="reminders && reminders !== null">
                                            <div class="row col-12">
                                                <div *ngIf="reminders && reminders==null">
                                                    There is no reminders
                                                </div>
                                            </div>
                                            <div class="col-12" *ngIf="reminders && reminders !== null">
                                                <div class="table-responsive horizontal-scroll">
                                                    <table class="table table-sm mb-0">
                                                        <!--<thead>
                                                            <tr>
                                                                <th width="30%">Reminders</th>
                                                            </tr>
                                                        </thead>-->
                                                        <!--<tbody>-->
                                                        <tr *ngIf="reminders">
                                                            <td class="col-8">Reminders</td>
                                                            <td class="col-4">
                                                                <span class=" text-wrap" [textContent]="reminders"></span><br />
                                                            </td>
                                                        </tr>
                                                        <!--</tbody>-->
                                                    </table>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isAdmission==true">
                            <div class="dashboard-box dashboard-table-box" *ngIf="admissionSheet && admissionSheet !== null">
                                <h4 class="dashboard-title">Gynaec Admission Sheet</h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <div class="row" *ngIf="admissionSheet && admissionSheet !== null ">
                                            <div class="col-12">
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black">
                                                        <th colspan="2">Gynaec Admission Sheet</th>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.relativeDetails">
                                                        <td class="col-8">Relative Details</td>
                                                        <td class="col-4" [textContent]="admissionSheet.relativeDetails"> </td>
                                                    </tr>

                                                    <tr *ngIf="admissionSheet.relativeName">
                                                        <td class="col-8">Relative Name</td>
                                                        <td class="col-4" [textContent]="admissionSheet.relativeName"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.consultantDoctor">
                                                        <td class="col-8">Consultant Doctor</td>
                                                        <td class="col-4" [textContent]="admissionSheet.consultantDoctor.toUpperCase()"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.gynNo">
                                                        <td class="col-8">Gyn No</td>
                                                        <td class="col-4" [textContent]="admissionSheet.gynNo"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.ipNo">
                                                        <td class="col-8">IP No</td>
                                                        <td class="col-4" [textContent]="admissionSheet.ipNo"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.roomNo">
                                                        <td class="col-8">Room No</td>
                                                        <td class="col-4" [textContent]="admissionSheet.roomNo"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.admissionDate">
                                                        <td class="col-8">Admission Date and Time</td>
                                                        <td class="col-4" [textContent]="admissionSheet.admissionDate  | date:'dd/MM/yyyy, hh:mm a'"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.reasonForAdmission">
                                                        <td class="col-8">Complaint/Reason for Admission</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="admissionSheet.reasonForAdmission"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.specialFeature">
                                                        <td class="col-8">Special Features and Risk Categorization</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="admissionSheet.specialFeature"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.lmp">
                                                        <td class="col-8">LMP</td>
                                                        <td class="col-4" [textContent]="admissionSheet.lmp"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.menstrualCycles">
                                                        <td class="col-8">Menstrual Cycles</td>
                                                        <td class="col-4" [textContent]="admissionSheet.menstrualCycles"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.contraception">
                                                        <td class="col-8">Contraception</td>
                                                        <td class="col-4" [textContent]="admissionSheet.contraception"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.obstetricFormula">
                                                        <td class="col-8">Obstetric Formula</td>
                                                        <td class="col-4" [textContent]="admissionSheet.obstetricFormula"> </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="admissionSheet.medicalHistory || admissionSheet.surgicalHistory
                             || admissionSheet.familyHistory ">
                                                        <th colspan="2">History</th>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.medicalHistory">
                                                        <td class="col-8">Medical History</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="admissionSheet.medicalHistory"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.surgicalHistory">
                                                        <td class="col-8">Surgical History</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="admissionSheet.surgicalHistory"></span>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr *ngIf="admissionSheet.familyHistory">
                                                        <td class="col-8">Family History</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="admissionSheet.familyHistory"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.drugAlleries">
                                                        <td class="col-8">Drug Allergies</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="admissionSheet.drugAlleries"></span>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr *ngIf="admissionSheet.medication">
                                                        <td class="col-8">Medication</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="admissionSheet.medication"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="admissionSheet.heightInFeet || admissionSheet.weight
                            || admissionSheet.bodyMassIndex || admissionSheet.bsa || admissionSheet.pulseRate || admissionSheet.bp || admissionSheet.shock || admissionSheet.pallor">
                                                        <th colspan="2">General Condition</th>
                                                    </tr>

                                                    <tr *ngIf="admissionSheet.heightInFeet">
                                                        <td class="col-8">Height (Feet)</td>
                                                        <td class="col-4" [textContent]="admissionSheet.heightInFeet"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.weight">
                                                        <td class="col-8">Weight (Kg)</td>
                                                        <td class="col-4" [textContent]="admissionSheet.weight"> </td>
                                                    </tr>

                                                    <tr *ngIf="admissionSheet.bodyMassIndex">
                                                        <td class="col-8">BMI</td>
                                                        <td class="col-4" [textContent]="admissionSheet.bodyMassIndex"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.bsa">
                                                        <td class="col-8">BSA</td>
                                                        <td class="col-4" [textContent]="admissionSheet.bsa"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.pulseRate">
                                                        <td class="col-8">PR (Min)</td>
                                                        <td class="col-4" [textContent]="admissionSheet.pulseRate"> </td>
                                                    </tr>

                                                    <tr *ngIf="admissionSheet.bp">
                                                        <td class="col-8">BP (mm Hg)</td>
                                                        <td class="col-4" [textContent]="admissionSheet.bp"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.respiratoryRate">
                                                        <td class="col-8">RR (Min)</td>
                                                        <td class="col-4" [textContent]="admissionSheet.respiratoryRate"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.temparature">
                                                        <td class="col-8">Temparature(Fh)</td>
                                                        <td class="col-4" [textContent]="admissionSheet.temparature"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.conscious">
                                                        <td class="col-8">Conscious</td>
                                                        <td class="col-4" [textContent]="admissionSheet.conscious"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.shock">
                                                        <td class="col-8">Shock</td>
                                                        <td class="col-4" [textContent]="admissionSheet.shock"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.pallor">
                                                        <td class="col-8">Pallor</td>
                                                        <td class="col-4" [textContent]="admissionSheet.pallor"> </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="admissionSheet.abdomen ||admissionSheet.scars
                            || admissionSheet.scarDetails ">
                                                        <th colspan="2">Per Abdomen</th>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.abdomen">
                                                        <td class="col-8">Abdomen</td>
                                                        <td class="col-4" [textContent]="admissionSheet.abdomen"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.scars">
                                                        <td class="col-8">Scars</td>
                                                        <td class="col-4" [textContent]="admissionSheet.scars"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.scarDetails">
                                                        <td class="col-8">Scar Details</td>
                                                        <td class="col-4" [textContent]="admissionSheet.scarDetails"> </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="admissionSheet.externalGentilia ||admissionSheet.pelExOthers
                            || admissionSheet.gynaecExaminationSummary || admissionSheet.pelExOthers">
                                                        <th colspan="2">Pelvic Examination </th>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.externalGentilia">
                                                        <td class="col-8">External Gentilia</td>
                                                        <td class="col-4" [textContent]="admissionSheet.externalGentilia"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.pelExOthers">
                                                        <td class="col-8">Pel Ex Others</td>
                                                        <td class="col-4" [textContent]="admissionSheet.pelExOthers"> </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="admissionSheet.perSpeculam ||admissionSheet.psOtherdtl
                            || admissionSheet.gynaecExaminationSummary ">
                                                        <th colspan="2">Per Speculam</th>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.perSpeculam">
                                                        <td class="col-8">Cervix</td>
                                                        <td class="col-4" [textContent]="admissionSheet.perSpeculam"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.psOtherdtl">
                                                        <td class="col-8">PS Other Dtls</td>
                                                        <td class="col-4" [textContent]="admissionSheet.psOtherdtl"> </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="admissionSheet.uterus ||admissionSheet.utOtherDtls
                            || admissionSheet.size || admissionSheet.adnexaRight || admissionSheet.adnexaLeft || admissionSheet.pod|| admissionSheet.pelvicTenderness">
                                                        <th colspan="2">Bimanual Examination</th>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.uterus">
                                                        <td class="col-8">Uterus</td>
                                                        <td class="col-4" [textContent]="admissionSheet.uterus"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.utOtherDtls">
                                                        <td class="col-8">Ut Other Dtls</td>
                                                        <td class="col-4" [textContent]="admissionSheet.utOtherDtls"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.size">
                                                        <td class="col-8">Size</td>
                                                        <td class="col-4" [textContent]="admissionSheet.size"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.adnexaRight">
                                                        <td class="col-8">AdNexa Right</td>
                                                        <td class="col-4" [textContent]="admissionSheet.adnexaRight"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.adnexaLeft">
                                                        <td class="col-8">AdNexa Left</td>
                                                        <td class="col-4" [textContent]="admissionSheet.adnexaLeft"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.pod">
                                                        <td class="col-8">POD</td>
                                                        <td class="col-4" [textContent]="admissionSheet.pod"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.pelvicTenderness">
                                                        <td class="col-8">Pelvic Tenderness</td>
                                                        <td class="col-4" [textContent]="admissionSheet.pelvicTenderness"> </td>
                                                    </tr>

                                                    <tr *ngIf="admissionSheet.gynaecExaminationSummary">
                                                        <td class="col-8">Gynaec Examination Summary</td>
                                                        <td class="col-4" [textContent]="admissionSheet.gynaecExaminationSummary"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.academicScan">
                                                        <td class="col-8">Academic Scan</td>
                                                        <td class="col-4" [textContent]="admissionSheet.academicScan"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.specialFeatures">
                                                        <td class="col-8">Special Features</td>
                                                        <td class="col-4" [textContent]="admissionSheet.specialFeatures"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.finalDiagnosis">
                                                        <td class="col-8">Final Diagnosis</td>
                                                        <td class="col-4" [textContent]="admissionSheet.finalDiagnosis"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.planOfManagement">
                                                        <td class="col-8">Plan Of Management</td>
                                                        <td class="col-4" [textContent]="admissionSheet.planOfManagement"> </td>
                                                    </tr>


                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="admissionSheet.provisionalDiagnosis">
                                                        <th colspan="2">Diagnosis</th>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.provisionalDiagnosis">
                                                        <td class="col-8">Provisional Diagnosis</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="admissionSheet.provisionalDiagnosis"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="admissionSheet.cbpChecked || admissionSheet.cbp || admissionSheet.cbpDate ||
                            admissionSheet.bloodGroupChecked || admissionSheet.bloodGroup ||admissionSheet.bloodGroupDate ||
                            admissionSheet.drugAlleries || admissionSheet.cbp || admissionSheet.cbpDate ||
                             admissionSheet.hivChecked || admissionSheet.hiv || admissionSheet.hivDate || admissionSheet.tshChecked || admissionSheet.bsa ||
                         admissionSheet.tsh || admissionSheet.tshDate || admissionSheet.rbs || admissionSheet.rbsDate || admissionSheet.hbsAghecked ||
                             admissionSheet.hbsAG || admissionSheet.hbsDate || admissionSheet.fbsChecked || admissionSheet.fbs || admissionSheet.fbsDate ||
                        admissionSheet.creatinineChecked || admissionSheet.creatinine || admissionSheet.creatinineDate || admissionSheet.cueChecked ||
                        admissionSheet.cue || admissionSheet.cueDate || admissionSheet.ecgChecked || admissionSheet.ecgDate || admissionSheet.chestXRayChecked  ||
                        admissionSheet.chestXRay || admissionSheet.otherChecked || admissionSheet.other ||
                             admissionSheet.scan || admissionSheet.mri || admissionSheet.ctu || admissionSheet.hsg || admissionSheet.hpe || admissionSheet.papSmear ||
                        admissionSheet.bloodMatched || admissionSheet.noOfMatched || admissionSheet.consultantInformed || admissionSheet.planManagement || admissionSheet.instruction">
                                                        <th colspan="2">Investigations With Date Checked</th>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.cbpChecked">
                                                        <td class="col-8">CBP</td>
                                                        <td class="col-4" [textContent]="admissionSheet.cbpChecked"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.cbp">
                                                        <td class="col-8">CBP</td>
                                                        <td class="col-4" [textContent]="admissionSheet.cbp"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.cbpDate">
                                                        <td class="col-8"> Date</td>
                                                        <td class="col-4" [textContent]="admissionSheet.cbpDate  | date:'dd/MM/yyyy, hh:mm a'"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.bloodGroupChecked">
                                                        <td class="col-8">Blood Group </td>
                                                        <td class="col-4" [textContent]="admissionSheet.bloodGroupChecked"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.bloodGroup">
                                                        <td class="col-8">Blood Group</td>
                                                        <td class="col-4" [textContent]="admissionSheet.bloodGroup"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.bloodGroupDate">
                                                        <td class="col-8"> Date</td>
                                                        <td class="col-4" [textContent]="admissionSheet.bloodGroupDate | date :'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.hivChecked">
                                                        <td class="col-8">HIV HVSIG</td>
                                                        <td class="col-4" [textContent]="admissionSheet.hivChecked"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.hiv">
                                                        <td class="col-8">HIV HVSIG</td>
                                                        <td class="col-4" [textContent]="admissionSheet.hiv"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.hivDate">
                                                        <td class="col-8"> Date</td>
                                                        <td class="col-4" [textContent]="admissionSheet.hivDate  | date :'dd/MM/yyyy'"> </td>
                                                    </tr>

                                                    <tr *ngIf="admissionSheet.tshChecked">
                                                        <td class="col-8">TSH </td>
                                                        <td class="col-4" [textContent]="admissionSheet.tshChecked"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.tsh">
                                                        <td class="col-8">TSH</td>
                                                        <td class="col-4" [textContent]="admissionSheet.tsh"> </td>
                                                    </tr>

                                                    <tr *ngIf="admissionSheet.tshDate">
                                                        <td class="col-8"> Date</td>
                                                        <td class="col-4" [textContent]="admissionSheet.tshDate  | date :'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.rbsChecked">
                                                        <td class="col-8">RBS </td>
                                                        <td class="col-4" [textContent]="admissionSheet.rbsChecked"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.rbs">
                                                        <td class="col-8">RBS </td>
                                                        <td class="col-4" [textContent]="admissionSheet.rbs"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.rbsDate">
                                                        <td class="col-8"> Date</td>
                                                        <td class="col-4" [textContent]="admissionSheet.rbsDate  | date :'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.hbsAghecked">
                                                        <td class="col-8">HBA1C</td>
                                                        <td class="col-4" [textContent]="admissionSheet.hbsAghecked"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.hbsAG">
                                                        <td class="col-8">HBA1C</td>
                                                        <td class="col-4" [textContent]="admissionSheet.hbsAG"> </td>
                                                    </tr>

                                                    <tr *ngIf="admissionSheet.hbsDate">
                                                        <td class="col-8">Date</td>
                                                        <td class="col-4" [textContent]="admissionSheet.hbsDate  | date :'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.antihcvChecked">
                                                        <td class="col-8">Anti HCV</td>
                                                        <td class="col-4" [textContent]="admissionSheet.antihcvChecked"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.antihcv">
                                                        <td class="col-8">Anti HCV</td>
                                                        <td class="col-4" [textContent]="admissionSheet.antihcv"> </td>
                                                    </tr>

                                                    <tr *ngIf="admissionSheet.antihcvDate">
                                                        <td class="col-8">Date</td>
                                                        <td class="col-4" [textContent]="admissionSheet.antihcvDate  | date :'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.fbsChecked">
                                                        <td class="col-8">FBS/PLBS </td>
                                                        <td class="col-4" [textContent]="admissionSheet.fbsChecked"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.fbs">
                                                        <td class="col-8">FBS/PLBS</td>
                                                        <td class="col-4" [textContent]="admissionSheet.fbs"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.fbsDate">
                                                        <td class="col-8"> Date</td>
                                                        <td class="col-4" [textContent]="admissionSheet.fbsDate  | date :'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.bsa">
                                                        <td class="col-8">BSA</td>
                                                        <td class="col-4" [textContent]="admissionSheet.bsa"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.creatinineChecked">
                                                        <td class="col-8">Creatinine </td>
                                                        <td class="col-4" [textContent]="admissionSheet.creatinineChecked"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.creatinine">
                                                        <td class="col-8">Creatinine</td>
                                                        <td class="col-4" [textContent]="admissionSheet.creatinine"> </td>
                                                    </tr>

                                                    <tr *ngIf="admissionSheet.creatinineDate">
                                                        <td class="col-8"> Date</td>
                                                        <td class="col-4" [textContent]="admissionSheet.creatinineDate | date :'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.cueChecked">
                                                        <td class="col-8">Cue </td>
                                                        <td class="col-4" [textContent]="admissionSheet.cueChecked"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.cue">
                                                        <td class="col-8">Cue</td>
                                                        <td class="col-4" [textContent]="admissionSheet.cue"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.cueDate">
                                                        <td class="col-8"> Date</td>
                                                        <td class="col-4" [textContent]="admissionSheet.cueDate | date :'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.ecgChecked">
                                                        <td class="col-8">Ecg </td>
                                                        <td class="col-4" [textContent]="admissionSheet.ecgChecked"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.ecg">
                                                        <td class="col-8">Ecg </td>
                                                        <td class="col-4" [textContent]="admissionSheet.ecg"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.ecgDate">
                                                        <td class="col-8">Date</td>
                                                        <td class="col-4" [textContent]="admissionSheet.ecgDate | date :'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.chestXRayChecked">
                                                        <td class="col-8">Chest XRay </td>
                                                        <td class="col-4" [textContent]="admissionSheet.chestXRayChecked"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.chestXRay">
                                                        <td class="col-8">Chest XRay</td>
                                                        <td class="col-4" [textContent]="admissionSheet.chestXRay"> </td>
                                                    </tr>

                                                    <tr *ngIf="admissionSheet.otherChecked">
                                                        <td class="col-8">Other </td>
                                                        <td class="col-4" [textContent]="admissionSheet.otherChecked"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.other">
                                                        <td class="col-8">Other</td>
                                                        <td class="col-4" [textContent]="admissionSheet.other"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.scan">
                                                        <td class="col-8">Scan</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="admissionSheet.scan"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.mri">
                                                        <td class="col-8">MRI</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="admissionSheet.mri"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.ctu">
                                                        <td class="col-8">CT</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="admissionSheet.ctu"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.hsg">
                                                        <td class="col-8">HSG</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="admissionSheet.hsg"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.hpe">
                                                        <td class="col-8">HPE</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="admissionSheet.hpe"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.papSmear">
                                                        <td class="col-8">Pap Smear</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="admissionSheet.papSmear"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.bloodMatched">
                                                        <td class="col-8">Blood To Be Cross Matched</td>
                                                        <td class="col-4" [textContent]="admissionSheet.bloodMatched"> </td>
                                                    </tr>

                                                    <tr *ngIf="admissionSheet.noOfMatched">
                                                        <td class="col-8">No Of Units</td>
                                                        <td class="col-4" [textContent]="admissionSheet.noOfMatched"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.consultantInformed">
                                                        <td class="col-8">Consultant Informed</td>
                                                        <td class="col-4" [textContent]="admissionSheet.consultantInformed"> </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.planManagement">
                                                        <td class="col-8">Proposed Plan of Management</td>
                                                        <td class="col-4" [textContent]="admissionSheet.planManagement">
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.instruction">
                                                        <td class="col-8">Instruction</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="admissionSheet.instruction"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="admissionSheet.details">
                                                        <td class="col-8">Details</td>
                                                        <td class="col-4" [textContent]="admissionSheet.details">
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isAdmission==true">
                            <div class="dashboard-box dashboard-table-box" #gynSurgerySection *ngIf="gynSurgeries && gynSurgeries !== null">
                                <h4 class="dashboard-title">Gynaec Surgeries</h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <div class="row" *ngIf="gynSurgeries && gynSurgeries !== null ">
                                            <div class="col-12">
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black">
                                                        <th colspan="2">Gynaec Surgeries</th>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.gynNo">
                                                        <td class="col-8">Gyn  No</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.gynNo"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.ipNo">
                                                        <td class="col-8">Ip No</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.ipNo"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.surgeryDate">
                                                        <td class="col-8">Surgery Date and Time</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.surgeryDate |   date:'dd/MM/yyyy, hh:mm a'"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.surgeryType">
                                                        <td class="col-8">Surgery Type</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.surgeryType"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.surgeon">
                                                        <td class="col-8">Surgeon</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.surgeon"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.asstSurgeon">
                                                        <td class="col-8">Asst Surgeon</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.asstSurgeon"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.standByDr">
                                                        <td class="col-8">Stand By Dr</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.standByDr"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.scrubNurse">
                                                        <td class="col-8">Scrub Nurse</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.scrubNurse"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.anaesthetis">
                                                        <td class="col-8">anaesthetis</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.anaesthetis"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.technician">
                                                        <td class="col-8">Technician</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.technician"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.lmp">
                                                        <td class="col-8">LMP</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.lmp"> </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gynSurgeries.laproscopy|| gynSurgeries.hysteroscopy || gynSurgeries.colposcopy ||
                                                gynSurgeries.laparotomy || gynSurgeries.hysterectomy || gynSurgeries.otherSurgeries ||
                                                 gynSurgeries.indication || gynSurgeries.anaesthesia || gynSurgeries.otherAnaesthesia ||
                                                 gynSurgeries.nameOfTheProcedure || gynSurgeries.findings ||  gynSurgeries.procedureDone || gynSurgeries.complications ||
                                                 gynSurgeries.complicationsDetails">
                                                        <th colspan="2">Surgery Details</th>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.laproscopy">
                                                        <td class="col-8">Laproscopy</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.laproscopy"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.hysteroscopy">
                                                        <td class="col-8">Hysteroscopy</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.hysteroscopy"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.colposcopy">
                                                        <td class="col-8">Colposcopy</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.colposcopy"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.laparotomy">
                                                        <td class="col-8">Laparotomy</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.laparotomy"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.hysterectomy">
                                                        <td class="col-8">Hysterectomy</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.hysterectomy"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.otherSurgeries">
                                                        <td class="col-8">Other Surgeries</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.otherSurgeries"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.otherSurgeriestext">
                                                        <td class="col-8">Other Surgeries</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.otherSurgeriestext"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.scrubNurseone">
                                                        <td class="col-8">Scrub Nurse 1</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.scrubNurseone"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.scrubNursetwo">
                                                        <td class="col-8">Scrub Nurse 2 </td>
                                                        <td class="col-4" [textContent]="gynSurgeries.scrubNursetwo"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.assistSurgeonone">
                                                        <td class="col-8">Assist Surgeon 1</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.assistSurgeonone"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.assistSurgeontwo">
                                                        <td class="col-8">Assist Surgeon 2 </td>
                                                        <td class="col-4" [textContent]="gynSurgeries.assistSurgeontwo"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.indication">
                                                        <td class="col-8">Indication</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.indication"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.anaesthesia">
                                                        <td class="col-8">Anaesthesia</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.anaesthesia"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.otherAnaesthesia">
                                                        <td class="col-8">Other Anaesthesia</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.otherAnaesthesia"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.nameOfTheProcedure">
                                                        <td class="col-8">Name Of The Procedure</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.nameOfTheProcedure"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.procedureDone">
                                                        <td class="col-8">Procedure Done</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.procedureDone"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.findings">
                                                        <td class="col-8">Findings</td>
                                                        <!--<td class="col-4" [textContent]="gynSurgeries.findings"> </td>-->
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.findings"></span>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.complications">
                                                        <td class="col-8">Complications</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.complications"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.complicationsDetails">
                                                        <td class="col-8">Complications</td>
                                                        <!--<td class="col-4" [textContent]="gynSurgeries.complicationsDetails"> </td>-->
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.complicationsDetails"></span>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gynSurgeries.angleOfScope || gynSurgeries.distendingMedium || gynSurgeries.inflowRate
                                                || gynSurgeries.inflowPressure || gynSurgeries.input || gynSurgeries.output ||
                                                 gynSurgeries.findings || gynSurgeries.procedureDone ||
                                                gynSurgeries.externalOSAbn ||gynSurgeries. externalOS || gynSurgeries.toWhichSize || gynSurgeries.cxDilatation || gynSurgeries.fluidDeficit ||
                                                  gynSurgeries.impressionHysteroscopy ||  gynSurgeries.versapoint ||  gynSurgeries.others ||  gynSurgeries.uterineWallsAbn ||  gynSurgeries.uterineWalls ||
                                                 gynSurgeries.cavity ||  gynSurgeries.leftCornuaDetail
                                            ||  gynSurgeries.leftCornua ||  gynSurgeries.rightCornuaDetail ||  gynSurgeries.rightCornua ||  gynSurgeries.fundus ||  gynSurgeries.cervicalCanal ||  gynSurgeries.ifAbnormalDtls ||  gynSurgeries.internalOs ">
                                                        <th colspan="2"> Hysteroscopy  Details</th>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.angleOfScope">
                                                        <td class="col-8">Size And Angle of Scope</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.angleOfScope"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.distendingMedium">
                                                        <td class="col-8">Distending Medium</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.distendingMedium"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.inflowRate">
                                                        <td class="col-8">InFlow Rate</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.inflowRate"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.inflowPressure">
                                                        <td class="col-8">InFlow Pressure</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.inflowPressure"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.input">
                                                        <td class="col-8">Input</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.input"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.output">
                                                        <td class="col-8">Output</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.output"> </td>
                                                    </tr>
                                                    <!--<tr *ngIf="gynSurgeries.findings">
                                                        <td class="col-8">Findings</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.findings"> </td>
                                                    </tr>-->
                                                    <!--<tr *ngIf="gynSurgeries.procedureDone">
                                                        <td class="col-8">Procedure Done</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.procedureDone"> </td>
                                                    </tr>-->

                                                    <tr *ngIf="gynSurgeries.fluidDeficit">
                                                        <td class="col-8">Fluid Deficit</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.fluidDeficit"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.uteroCervicalLength">
                                                        <td class="col-8">Utero Cervical Length</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.uteroCervicalLength"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.cxDilatation">
                                                        <td class="col-8">Cx Dilatation</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.cxDilatation"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.toWhichSize">
                                                        <td class="col-8">To Which Size</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.toWhichSize"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.externalOS">
                                                        <td class="col-8">External OS</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.externalOS"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.externalOSAbn">
                                                        <td class="col-8">External OS Abn</td>
                                                        <!--<td class="col-4" [textContent]="gynSurgeries.externalOSAbn"> </td>-->
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.externalOSAbn"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.cervicalCanal">
                                                        <td class="col-8">Cervical Canal</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.cervicalCanal"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.ifAbnormalDtls">
                                                        <td class="col-8">If Abnormal Dtls</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.ifAbnormalDtls"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.internalOs">
                                                        <td class="col-8">Internal Os</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.internalOs"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.internalOsAbn">
                                                        <td class="col-8">Internal Os Abn</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.internalOsAbn"></span>
                                                            </div>
                                                        </td>
                                                        <!--<td class="col-4" [textContent]="gynSurgeries.internalOsAbn"> </td>-->
                                                    </tr>


                                                    <tr *ngIf="gynSurgeries.fundus">
                                                        <td class="col-8">Fundus</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.fundus"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.rightCornua">
                                                        <td class="col-8">Right Cornua</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.rightCornua"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.rightCornuaDetail">
                                                        <td class="col-8">Right Cornua Abn</td>
                                                        <!--<td class="col-4" [textContent]="gynSurgeries.rightCornuaDetail"> </td>-->
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.rightCornuaDetail"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.leftCornua">
                                                        <td class="col-8">Left Cornua</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.leftCornua"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.leftCornuaDetail">
                                                        <td class="col-8">Left Cornua Abn</td>
                                                        <!--<td class="col-4" [textContent]="gynSurgeries.leftCornuaDetail"> </td>-->
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.leftCornuaDetail"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.rightOstium">
                                                        <td class="col-8">Right Ostium</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.rightOstium"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.rightOstiumNotSee">
                                                        <td class="col-8"> Right Ostium Not See</td>
                                                        <!--<td class="col-4" [textContent]="gynSurgeries.leftCornuaDetail"> </td>-->
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.rightOstiumNotSee"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.leftOstium">
                                                        <td class="col-8">Left Ostium</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.leftOstium"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.leftOstiumNotSee">
                                                        <td class="col-8"> Left Ostium Not See</td>
                                                        <!--<td class="col-4" [textContent]="gynSurgeries.leftCornuaDetail"> </td>-->
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.leftOstiumNotSee"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.cavity">
                                                        <td class="col-8">Cavity</td>
                                                        <!--<td class="col-4" [textContent]="gynSurgeries.leftCornuaDetail"> </td>-->
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.cavity"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.uterineWalls">
                                                        <td class="col-8">Uterine Walls</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.uterineWalls"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.uterineWallsAbn">
                                                        <td class="col-8">Uterine Walls Abn</td>
                                                        <!--<td class="col-4" [textContent]="gynSurgeries.leftCornuaDetail"> </td>-->
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.uterineWallsAbn"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.others">
                                                        <td class="col-8">Others</td>
                                                        <!--<td class="col-4" [textContent]="gynSurgeries.leftCornuaDetail"> </td>-->
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.others"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.versapoint">
                                                        <td class="col-8">Versapoint</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.versapoint"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.impressionHysteroscopy">
                                                        <td class="col-8">Impression Hysteroscopy</td>
                                                        <!--<td class="col-4" [textContent]="gynSurgeries.impressionHysteroscopy"> </td>-->
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.impressionHysteroscopy"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gynSurgeries.techniqueOfPneumoperitoneum|| gynSurgeries.ifVeress || gynSurgeries.bladderCatheterization
                                                ||gynSurgeries.initialPressure || gynSurgeries.maintenancePressure || gynSurgeries.fiveMmSite ||
                                                 gynSurgeries.morcellator || gynSurgeries.tenMMPort ">

                                                        <th colspan="2"> Laproscopy  Details</th>

                                                    <tr *ngIf="gynSurgeries.techniqueOfPneumoperitoneum">
                                                        <td class="col-8">Technique Of Pneumoperitoneum</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.techniqueOfPneumoperitoneum"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.ifVeress">
                                                        <td class="col-8">If Veress</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.ifVeress"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.bladderCatheterization">
                                                        <td class="col-8">Bladder Catheterization</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.bladderCatheterization"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.initialPressure">
                                                        <td class="col-8">Initial Pressure</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.initialPressure"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.maintenancePressure">
                                                        <td class="col-8">Maintenance Pressure</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.maintenancePressure"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.tenMMPort">
                                                        <td class="col-8">10 Mm Port</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.tenMMPort"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.fiveMmSite">
                                                        <td class="col-8">5 Mm Site</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.fiveMmSite"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.morcellator">
                                                        <td class="col-8">Morcellator</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.morcellator"> </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gynSurgeries.uterusSize || gynSurgeries.type ||
                                             gynSurgeries.uterosacrals || gynSurgeries.uterosacralsDetails ||  gynSurgeries.pod || gynSurgeries.podAbn ||
                                                 gynSurgeries.rightTube || gynSurgeries.rightTubeAbn ||
                                                 gynSurgeries.leftTube || gynSurgeries.leftTubeAbn || gynSurgeries.rightOvary || gynSurgeries.rightOvaryAbnormal || gynSurgeries.leftOvary ||
                                            gynSurgeries.leftOvaryAbnormal || gynSurgeries.tuboOvariaRelation || gynSurgeries.tuboOvariAbn || gynSurgeries.pelvicPeritoneum ||
                                            gynSurgeries.pelvicPeriAbn || gynSurgeries.dyeTest || gynSurgeries.ectopicGestation || gynSurgeries.site || gynSurgeries.rupture ||
                                            gynSurgeries.endometriosis || gynSurgeries.siteOfEndometriosis || gynSurgeries.upperAbdomen || gynSurgeries.abnDtls || gynSurgeries.haemoperitoneum ||
                                            gynSurgeries.amount || gynSurgeries.findingOthers">
                                                        <th colspan="2">Findings</th>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.uterusSize">
                                                        <td class="col-8">Uterus Size</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.uterusSize"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.type">
                                                        <td class="col-8">Type</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.type"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.uterosacrals">
                                                        <td class="col-8">Uterosacrals</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.uterosacrals"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.uterosacralsDetails">
                                                        <td class="col-8">Uterosacrals Abn</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.uterosacralsDetails"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.pod">
                                                        <td class="col-8">POD</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.pod"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.podAbn">
                                                        <td class="col-8">POD Abn</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.podAbn"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.rightTube">
                                                        <td class="col-8">Right Tube</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.rightTube"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.rightTubeAbn">
                                                        <td class="col-8">Right Tube Abn</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.rightTubeAbn"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.leftTube">
                                                        <td class="col-8">Left Tube</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.leftTube"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.leftTubeAbn">
                                                        <td class="col-8">Left Tube Abn</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.leftTubeAbn"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.rightOvary">
                                                        <td class="col-8">Right Ovary</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.rightOvary"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.rightOvaryAbnormal">
                                                        <td class="col-8">Right Ovary Abnormal</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.rightOvaryAbnormal"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.leftOvary">
                                                        <td class="col-8">Left Ovary</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.leftOvary"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.leftOvaryAbnormal">
                                                        <td class="col-8">Left Ovary Abnormal</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.leftOvaryAbnormal"></span>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.tuboOvariaRelation">
                                                        <td class="col-8">Tubo Ovaria Relation</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.tuboOvariaRelation"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.tuboOvariAbn">
                                                        <td class="col-8">Tubo Ovari Abn</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.tuboOvariAbn"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.tuboOvariaRelation">
                                                        <td class="col-8">Tubo Ovaria Relation</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.tuboOvariaRelation"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.tuboOvariAbn">
                                                        <td class="col-8">Tubo Ovari Abn</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.tuboOvariAbn"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.ovarianFossaRight">
                                                        <td class="col-8">Ovarian Fossa Right</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.ovarianFossaRight"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.ovarianFossaRightAbn">
                                                        <td class="col-8"> Ovarian Fossa Right  Abn</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.ovarianFossaRightAbn"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.ovarianFossaLeft">
                                                        <td class="col-8">Ovarian Fossa Left</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.ovarianFossaLeft"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.ovarianFossaLeftAbn">
                                                        <td class="col-8"> Ovarian Fossa Left  Abn</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.ovarianFossaLeftAbn"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.pelvicPeritoneum">
                                                        <td class="col-8">Pelvic Peritoneum</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.pelvicPeritoneum"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.pelvicPeriAbn">
                                                        <td class="col-8">PelvicPeri Abn</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.pelvicPeriAbn"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <!--<tr *ngIf="gynSurgeries.pelvicPeritoneumAbn">
                                                        <td class="col-8">Pelvic Peritoneum Abn</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.pelvicPeritoneumAbn"></span>
                                                            </div>
                                                        </td>
                                                    </tr>-->
                                                    <tr *ngIf="gynSurgeries.dyeTest">
                                                        <td class="col-8"> Dye Test</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.dyeTest"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.ectopicGestation">
                                                        <td class="col-8">Ectopic Gestation</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.ectopicGestation"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.site">
                                                        <td class="col-8">Site</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.site"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.rupture">
                                                        <td class="col-8">Rupture</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.rupture"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.endometriosis">
                                                        <td class="col-8">Endometriosis</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.endometriosis"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.siteOfEndometriosis">
                                                        <td class="col-8">Site of Endometriosis</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.siteOfEndometriosis"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.upperAbdomen">
                                                        <td class="col-8">Upper Abdomen</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.upperAbdomen"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.abnDtls">
                                                        <td class="col-8">Abn Dtls</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.abnDtls"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.haemoperitoneum">
                                                        <td class="col-8">Haemoperitoneum</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.haemoperitoneum"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.amount">
                                                        <td class="col-8">Amount</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.amount"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.findingOthers">
                                                        <td class="col-8"> Others</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.findingOthers"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gynSurgeries.ovarianDrilling || gynSurgeries.endobag ||
                                             gynSurgeries.endosuturing || gynSurgeries.endosuturing ||  gynSurgeries.specimenWt || gynSurgeries.specialInstrumentList
                                                || gynSurgeries.impressionLaproscopy || gynSurgeries.woundSuturing  ">
                                                        <th colspan="2"> Details of Surgery</th>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.ovarianDrilling">
                                                        <td class="col-8">Ovarian Drilling</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.ovarianDrilling"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.currentWattage">
                                                        <td class="col-8">Current Wattage</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.currentWattage"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.avgDuration">
                                                        <td class="col-8">Avg Duration of Each Puncture</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.avgDuration"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.rtOvary">
                                                        <td class="col-8">Rt Ovary</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.rtOvary"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.ltOvary">
                                                        <td class="col-8">Lt Ovary</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.ltOvary"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.endobag">
                                                        <td class="col-8">Endo bag</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.endobag"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.vasopressin">
                                                        <td class="col-8">Vasopressin</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.vasopressin"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.endosuturing">
                                                        <td class="col-8">Endo Suturing</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.endosuturing"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.specimenWt">
                                                        <td class="col-8">Specimen Wt</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.specimenWt"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.specialInstrumentList && gynSurgeries.specialInstrumentList.length>0">
                                                        <td colspan="2">
                                                            <div class="dashboard-body">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <h6 class="font-14 mt-0 mb-2">Special Instrument</h6>
                                                                        <ul class="dashboard-ul row">
                                                                            <li *ngFor="let item of gynSurgeries.specialInstrumentList" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.woundSuturing">
                                                        <td class="col-8">Wound Suturing</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.woundSuturing"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.impressionLaproscopy">
                                                        <td class="col-8">Impression Laproscopy</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.impressionLaproscopy"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gynSurgeries.tah || gynSurgeries.vh ||
                                             gynSurgeries.tlh || gynSurgeries.anteversionOfUterus ||  gynSurgeries.complaintsOthers || gynSurgeries.specialInstrumentList
                                                || gynSurgeries.vaultClosure || gynSurgeries.specimenRemoval  ">
                                                        <th colspan="2"> Hysterectomy Surgical Procedure</th>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.tah">
                                                        <td class="col-8">TAH</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.tah"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.vh">
                                                        <td class="col-8">VH</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.vh"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.tlh">
                                                        <td class="col-8">TLH</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.tlh"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.specialEquipment">
                                                        <td colspan="2">
                                                            <div class="dashboard-body">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <h6 class="font-14 mt-0 mb-2">Special Equipment Used</h6>
                                                                        <ul class="dashboard-ul row">
                                                                            <li *ngFor="let item of gynSurgeries.specialEquipment" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.anteversionOfUterus">
                                                        <td class="col-8">Anteversion Of Uterus By</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.anteversionOfUterus"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.specimenRemoval">
                                                        <td class="col-8">Specimen Removal</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.specimenRemoval"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.vaultClosure">
                                                        <td class="col-8">Vault Closure</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.vaultClosure"> </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="gynSurgeries.colpoComplaints || gynSurgeries.complaintsOthers ||
                                             gynSurgeries.menstrualCycle || gynSurgeries.yearsSexuallyActive ||  gynSurgeries.contraception ||
                                                gynSurgeries.contraceptionOthers
                                                || gynSurgeries.pastHistory || gynSurgeries.obstetricHistory|| gynSurgeries.dateOfLastPAP || gynSurgeries.lastPapSmearImpression || gynSurgeries.familyHOIfYes
                                            || gynSurgeries.familyCancer || gynSurgeries.obstetricHistory  ">
                                                        <th colspan="2"> Colposcopy</th>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.colpoComplaints">
                                                        <td class="col-8">Complaints</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.colpoComplaints"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.complaintsOthers">
                                                        <td class="col-8">Complaints Others</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.complaintsOthers"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.menstrualCycle">
                                                        <td class="col-8">Menstrual Cycle</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.menstrualCycle"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.yearsSexuallyActive">
                                                        <td class="col-8">Years Sexually Active</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.yearsSexuallyActive"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.contraception">
                                                        <td class="col-8">Contraception</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.contraception"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.contraceptionOthers">
                                                        <td class="col-8">Contraception Others</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.contraceptionOthers"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.pastHistory">
                                                        <td class="col-8">Past History Of</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.pastHistory"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.obstetricHistory">
                                                        <td class="col-8">Obstetric History</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.obstetricHistory"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.familyCancer">
                                                        <td class="col-8">Family H/O Cancer</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.familyCancer"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.familyHOIfYes">
                                                        <td class="col-8">Family H/O If Yes</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.familyHOIfYes"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.lastPapSmearImpression">
                                                        <td class="col-8">Last PAP Smear Impression</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.lastPapSmearImpression"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.dateOfLastPAP">
                                                        <td class="col-8">Date Of Last PAP</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.dateOfLastPAP | date:'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf=" gynSurgeries.furtherPalnOfManagement || gynSurgeries.qbl || gynSurgeries.abdominalCerclage || gynSurgeries.shirodkarCerclage ||
    gynSurgeries.coloposcopy || gynSurgeries.inadequate || gynSurgeries.colposcopyDiagnosis || gynSurgeries.followUpReviewDate ||gynSurgeries.pAndS ||
    gynSurgeries.pAndV || gynSurgeries.scjSeenClearly || gynSurgeries.leukoplakia || gynSurgeries.acetowhiteAreas ||
     gynSurgeries.abnormalVasculature || gynSurgeries.ifYes || gynSurgeries.vulvalChanges || gynSurgeries.ifYesDescribe || gynSurgeries.findingsOthers || gynSurgeries.coloposcopy||
gynSurgeries.colposcopyDiagnosis ">

                                                        <th colspan="2">Findings</th>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.pAndS">
                                                        <td class="col-8">P/S</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.pAndS"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.pAndV">
                                                        <td class="col-8">P/V</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.pAndV"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.scjSeenClearly">
                                                        <td class="col-8">SCJ Seen Clearly</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.scjSeenClearly"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.leukoplakia">
                                                        <td class="col-8">Leukoplakia</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.leukoplakia"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.acetowhiteAreas">
                                                        <td class="col-8">Acetowhite Areas</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.acetowhiteAreas"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.gradeI">
                                                        <td class="col-8">Grade I</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.gradeI"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.gradeII">
                                                        <td class="col-8">Grade II</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.gradeII"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.gradeIII">
                                                        <td class="col-8">Grade III</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.gradeIII"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.abnormalVasculature">
                                                        <td class="col-8">Abnormal Vasculature</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.abnormalVasculature"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.ifYes">
                                                        <td class="col-8">If Yes</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.ifYes"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.vulvalChanges">
                                                        <td class="col-8">Any Vaginal/Vulval Changes</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.vulvalChanges"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.ifYesDescribe">
                                                        <td class="col-8">If Yes, Describe</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.ifYesDescribe"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.findingsOthers">
                                                        <td class="col-8"> Others</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.findingsOthers"></span>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.coloposcopy">
                                                        <td class="col-8">Coloposcopy</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.coloposcopy"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.inadequate">
                                                        <td class="col-8">InAdequate</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.inadequate"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.colposcopyDiagnosis">
                                                        <td class="col-8">Colposcopy Diagnosis</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.colposcopyDiagnosis"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.followUpReviewDate">
                                                        <td class="col-8">FollowUp Review Date</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.followUpReviewDate | date :'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.shirodkarCerclage">
                                                        <td class="col-8">Shirodkar Cerclage</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.shirodkarCerclage"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.abdominalCerclage">
                                                        <td class="col-8">Abdominal Cerclage</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.abdominalCerclage"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.remarks">
                                                        <td class="col-8">Remarks</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.remarks"></span>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.qbl">
                                                        <td class="col-8">QBL (Ml)</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.qbl"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.furtherPalnOfManagement">
                                                        <td class="col-8">Further Paln Of Management</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="gynSurgeries.furtherPalnOfManagement"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf=" gynSurgeries.shiftingIntoOT || gynSurgeries.startOfAnaesthesia ||
                                                gynSurgeries.antibioticsTotalDoseTime || gynSurgeries.startOfSurgery ||
                                                gynSurgeries.shiftingOutOfOT || gynSurgeries.durationOfSurgery
                                               ">
                                                        <th colspan="2">Time Tracking</th>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.shiftingIntoOT">
                                                        <td class="col-8">Shifting Into OT</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.shiftingIntoOT"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.startOfAnaesthesia">
                                                        <td class="col-8">Start Of Anaesthesia</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.startOfAnaesthesia"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.antibioticsTotalDoseTime">
                                                        <td class="col-8">Antibiotics Total Dose Time</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.antibioticsTotalDoseTime"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.startOfSurgery">
                                                        <td class="col-8">Start Of Surgery</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.startOfSurgery"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.endOfProcedure">
                                                        <td class="col-8">End Of Procedure</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.endOfProcedure"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.shiftingOutOfOT">
                                                        <td class="col-8">Shifting Out Of OT</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.shiftingOutOfOT"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.durationOfSurgery">
                                                        <td class="col-8">Duration Of Surgery</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.durationOfSurgery"> </td>
                                                    </tr>
                                                    <!--<tr *ngIf="gynSurgeries.specialEquipment">
                                                        <td class="col-8">Special Equipment</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.specialEquipment"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.anteversionOfUterus">
                                                        <td class="col-8">Anteversion Of Uterus</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.anteversionOfUterus"> </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.specimenRemoval">
                                                        <td class="col-8">Specimen Removal</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.specimenRemoval"> </td>
                                                    </tr>

                                                    <tr *ngIf="gynSurgeries.vaultClosure">
                                                        <td class="col-8">Vault Closure</td>
                                                        <td class="col-4" [textContent]="gynSurgeries.vaultClosure"> </td>
                                                    </tr>-->
                                                    <!--<tr *ngIf="gynSurgeries.vhSummary">
                                                        <td class="col-8">VH Summary</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [innerHTML]="gynSurgeries.vhSummary"></span>
                                                            </div>
                                                        </td>

                                                    </tr>-->
                                                    <!--<tr *ngIf="gynSurgeries.otherSurgery">
                                                    <td class="col-8">Other Surgery</td>
                                                    <td class="col-4" [textContent]="gynSurgeries.otherSurgery"> </td>    </tr>-->

                                                    <tr *ngIf="gynSurgeries.tahSummary">
                                                        <td class="col-8">TAH </td>
                                                        <!--<td class="col-4" [innerHTML]="gynSurgeries.tahSummary"> </td>-->
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [innerHTML]="gynSurgeries.tahSummary"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.vhSummary">
                                                        <td class="col-8">VH </td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [innerHTML]="gynSurgeries.vhSummary"></span>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.tlhSummary">
                                                        <td class="col-8">TLH </td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [innerHTML]="gynSurgeries.tlhSummary"></span>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.laparotomySummary && gynSurgeries.laparotomySummary">
                                                        <td class="col-6">Laparotomy </td>
                                                        <td class="col-6">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [innerHTML]="gynSurgeries.laparotomySummary"></span>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                    <tr *ngIf="gynSurgeries.otherSurgery">
                                                        <td class="col-8"> Other Surgery</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [innerHTML]="gynSurgeries.otherSurgery"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <!--<tr *ngIf="gynSurgeries.abnDtls">
                                                    <td class="col-8">Abn Dtls</td>
                                                    <td class="col-4" [textContent]="gynSurgeries.abnDtls"> </td>    </tr>-->
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isAdmission==true">
                            <div class="dashboard-box dashboard-table-box" *ngIf="dischargeSummary && dischargeSummary !== null">
                                <h4 class="dashboard-title">Gynaec Discharge Summary</h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <div class="row" *ngIf="dischargeSummary && dischargeSummary !== null ">
                                            <div class="col-12">
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <!--<tr class="bg-soft-dark" style="border-bottom:1px solid black">
                                                        <th colspan="2">Gynaec Discharge Summary</th>
                                                    </tr>-->
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="dischargeSummary.gynNo ||dischargeSummary.relativeDetails
                            || dischargeSummary.relativeName || dischargeSummary.bloodGroup || dischargeSummary.rhType || dischargeSummary.admissionDate|| dischargeSummary.bedNo ">
                                                        <th colspan="2">Patient Details</th>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.gynNo">
                                                        <td class="col-8">Gyn No</td>
                                                        <td class="col-4" [textContent]="dischargeSummary.gynNo"> </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.relativeDetails">
                                                        <td class="col-8">Relative Details</td>
                                                        <td class="col-4" [textContent]="dischargeSummary.relativeDetails"> </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.relativeName">
                                                        <td class="col-8">Relative Name</td>
                                                        <td class="col-4" [textContent]="dischargeSummary.relativeName"> </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.bloodGroup">
                                                        <td class="col-8">Blood Group</td>
                                                        <td class="col-4" [textContent]="dischargeSummary.bloodGroup"> </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.rhType">
                                                        <td class="col-8">Rh Type</td>
                                                        <td class="col-4" [textContent]="dischargeSummary.rhType"> </td>
                                                    </tr>

                                                    <tr *ngIf="dischargeSummary.admissionDate">
                                                        <td class="col-8">  Admission Date And Time</td>
                                                        <td class="col-4" [textContent]="dischargeSummary.admissionDate |date:'dd/MM/yyyy, hh:mm a'"> </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.bedNo">
                                                        <td class="col-8">Bed No</td>
                                                        <td class="col-4" [textContent]="dischargeSummary.bedNo"> </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf=" dischargeSummary.summaryType || dischargeSummary.dischargeType || dischargeSummary.dischargeDate || dischargeSummary.transferDate || dischargeSummary.deathDate ||
                             dischargeSummary.dischargeTime || dischargeSummary.hospitalName || dischargeSummary.reasonForTranfer || dischargeSummary.reasonForLAMA || dischargeSummary.causeOfDeath ||
                            dischargeSummary.consultantDoctor || dischargeSummary.diagnosis || dischargeSummary.chiefComplaints || dischargeSummary.complaintAndHistory ||
                        dischargeSummary.medicalHistory || dischargeSummary.surgicalHistory || dischargeSummary.allergyHistory || dischargeSummary.familyHistory ||
                        dischargeSummary.admissionDetails || dischargeSummary.operativeFindings || dischargeSummary.courseInTheHospital || dischargeSummary.conditionAtDischarge ||
                        dischargeSummary.furtherPlanOfManagement || dischargeSummary.reviewDates  ">

                                                        <th colspan="2">Discharge Details</th>
                                                    </tr>

                                                    <tr *ngIf="dischargeSummary.summaryType">
                                                        <td class="col-8">Summary Type</td>
                                                        <td class="col-4" [textContent]="dischargeSummary.summaryType"> </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.dischargeType">
                                                        <td class="col-8">Discharge Type</td>
                                                        <td class="col-4" [textContent]="dischargeSummary.dischargeType"> </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.dischargeDate">
                                                        <td class="col-8">Discharge Date  and Time</td>
                                                        <td class="col-4" [textContent]="dischargeSummary.dischargeDate | date:'dd/MM/yyyy, hh:mm a'"> </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.transferDate">
                                                        <td class="col-8">Transfer Date and Time</td>
                                                        <td class="col-4" [textContent]="dischargeSummary.transferDate |date:'dd/MM/yyyy, hh:mm a'"> </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.deathDate">
                                                        <td class="col-8">Death Date and Time</td>
                                                        <td class="col-4" [textContent]="dischargeSummary.deathDate | date:'dd/MM/yyyy, hh:mm a'"> </td>
                                                    </tr>


                                                    <tr *ngIf="dischargeSummary.dischargeTime">
                                                        <td class="col-8"> Time Of Discharge</td>
                                                        <td class="col-4" [textContent]="dischargeSummary.dischargeTime"> </td>
                                                    </tr>

                                                    <tr *ngIf="dischargeSummary.hospitalName">
                                                        <td class="col-8">Hospital Name</td>
                                                        <td class="col-4" [textContent]="dischargeSummary.hospitalName"> </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.reasonForTranfer">
                                                        <td class="col-8">Reason For Tranfer</td>
                                                        <td class="col-4" [textContent]="dischargeSummary.reasonForTranfer"> </td>
                                                    </tr>

                                                    <tr *ngIf="dischargeSummary.reasonForLAMA">
                                                        <td class="col-8">Reason For LAMA</td>
                                                        <td class="col-4" [textContent]="dischargeSummary.reasonForLAMA"> </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.causeOfDeath">
                                                        <td class="col-8">Cause Of Death</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="dischargeSummary.causeOfDeath"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.consultantDoctor">
                                                        <td class="col-8">Consultant Doctor</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="dischargeSummary.consultantDoctor.toUpperCase()"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.diagnosis">
                                                        <td class="col-8">Diagnosis</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [innerHTML]="dischargeSummary.diagnosis"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.chiefComplaints">
                                                        <td class="col-8">Chief Complaints</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [innerHTML]="dischargeSummary.chiefComplaints"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.complaintAndHistory">
                                                        <td class="col-8">Complaint And History</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [innerHTML]="dischargeSummary.complaintAndHistory"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.medicalHistory">
                                                        <td class="col-8">Medical History</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [innerHTML]="dischargeSummary.medicalHistory"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.surgicalHistory">
                                                        <td class="col-8">Surgical History</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [innerHTML]="dischargeSummary.surgicalHistory"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.allergyHistory">
                                                        <td class="col-8">Allergy History</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [innerHTML]="dischargeSummary.allergyHistory"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.familyHistory">
                                                        <td class="col-8">Family History</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [innerHTML]="dischargeSummary.familyHistory"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.admissionDetails">
                                                        <td class="col-8">Admission Details</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [innerHTML]="dischargeSummary.admissionDetails"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.operativeFindings">
                                                        <td class="col-8">Operative Findings</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [innerHTML]="dischargeSummary.operativeFindings"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.courseInTheHospital">
                                                        <td class="col-8">Course In The Hospital</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [innerHTML]="dischargeSummary.courseInTheHospital"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.conditionAtDischarge">
                                                        <td class="col-8">Condition At Discharge</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [innerHTML]="dischargeSummary.conditionAtDischarge"></span>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr *ngIf="dischargeSummary.furtherPlanOfManagement">
                                                        <td class="col-8">Further Plan Of Management</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [innerHTML]="dischargeSummary.furtherPlanOfManagement"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="dischargeSummary.reviewDates">
                                                        <td class="col-8">Followup/Review Dates</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [innerHTML]="dischargeSummary.reviewDates"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="isAdmission==true">
                            <div class="dashboard-box dashboard-table-box" *ngIf="colposcopy && colposcopy !== null">
                                <h4 class="dashboard-title">Colposcopy</h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <div class="row" *ngIf="colposcopy && colposcopy !== null ">
                                            <div class="col-12">
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black">
                                                        <th colspan="2">Colposcopy</th>
                                                    </tr>

                                                    <tr *ngIf="colposcopy.surgeryDate">
                                                        <td class="col-8">Surgery Date and Time</td>
                                                        <td class="col-4" [textContent]="colposcopy.surgeryDate | date:'dd/MM/yyyy, hh:mm a'"> </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.surgeon">
                                                        <td class="col-8">Surgeon</td>
                                                        <td class="col-4" [textContent]="colposcopy.surgeon"> </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black"
                                                        *ngIf="colposcopy.indicationForColposcopy ||colposcopy.historyOfScreeningAndTreatment
                            || colposcopy.parity || colposcopy.contraception
                            || colposcopy.pregnancyStatus || colposcopy.menopausalStatus
                            || colposcopy.hysterectomyStatus
                            || colposcopy.smokingHistory || colposcopy.hivStatus||colposcopy.hpvVaccinationStatus
                            || colposcopy.externalGenitalia || colposcopy.perSpeculum||colposcopy.cervixVisibility
                             || colposcopy.scjVisibility || colposcopy.hivStatus||colposcopy.hpvVaccinationStatus
                            || colposcopy.externalGenitalia || colposcopy.perSpeculum||colposcopy.cervixVisibility
                            || colposcopy.scjVisibility || colposcopy.manipulationToVisualizeSCJ
                            || colposcopy.acetowhiteningPresent">
                                                        <th colspan="2">Precolposcopy Evaluation</th>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.indicationForColposcopy">
                                                        <td class="col-8">Indication For Colposcopy</td>
                                                        <td class="col-4" [textContent]="colposcopy.indicationForColposcopy"> </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.historyOfScreeningAndTreatment">
                                                        <td class="col-8">History Of Screening and Treatment</td>
                                                        <td class="col-4" [textContent]="colposcopy.historyOfScreeningAndTreatment"> </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.parity">
                                                        <td class="col-8">Parity</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="colposcopy.parity"></span>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr *ngIf="colposcopy.contraception">
                                                        <td class="col-8"> Contraception</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="colposcopy.contraception"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.pregnancyStatus">
                                                        <td class="col-8">Pregnancy Status</td>
                                                        <td class="col-4" [textContent]="colposcopy.pregnancyStatus"> </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.menopausalStatus">
                                                        <td class="col-8">Menopausal Status</td>
                                                        <td class="col-4" [textContent]="colposcopy.menopausalStatus"> </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.hysterectomyStatus">
                                                        <td class="col-8"> Hysterectomy Status</td>
                                                        <td class="col-4" [textContent]="colposcopy.hysterectomyStatus "> </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.smokingHistory">
                                                        <td class="col-8">Smoking History</td>
                                                        <td class="col-4" [textContent]="colposcopy.smokingHistory"> </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.hivStatus">
                                                        <td class="col-8"> HIV Status</td>
                                                        <td class="col-4" [textContent]="colposcopy.hivStatus"> </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.hpvVaccinationStatus">
                                                        <td class="col-8"> HPV Vaccination Status</td>
                                                        <td class="col-4" [textContent]="colposcopy.hpvVaccinationStatus"> </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.hysterectomyStatus">
                                                        <td class="col-8">External Genitalia</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="colposcopy.externalGenitalia"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.perSpeculum">
                                                        <td class="col-8">Per Speculum</td>
                                                        <td class="col-4">
                                                            <div style="white-space:normal; word-break:break-all;">
                                                                <span [textContent]="colposcopy.perSpeculum"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.cervixVisibility">
                                                        <td class="col-8">Cervix Visibility</td>
                                                        <td class="col-4" [textContent]="colposcopy.cervixVisibility"> </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.scjVisibility">
                                                        <td class="col-8">SCJ Visibility</td>
                                                        <td class="col-4" [textContent]="colposcopy.scjVisibility"> </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.manipulationToVisualizeSCJ">
                                                        <td class="col-8">Cervix Manipulation To Visualize SCJ</td>
                                                        <td class="col-4" [textContent]="colposcopy.manipulationToVisualizeSCJ"> </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.acetowhiteningPresent">
                                                        <td class="col-8"> Acetowhitening Present</td>
                                                        <td class="col-4" [textContent]="colposcopy.acetowhiteningPresent"> </td>
                                                    </tr>


                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf=" colposcopy.normalColoposcopicList">
                                                        <th colspan="2">Normal Colposcopic Findings</th>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.normalColoposcopicList">
                                                        <td colspan="2">
                                                            <div class="dashboard-body">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <!--<h6 class="font-14 mt-0 mb-2">Special Instrument</h6>-->
                                                                        <ul class="dashboard-ul row">
                                                                            <li *ngFor="let item of colposcopy.normalColoposcopicList" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf=" colposcopy.lesionsPresent">


                                                        <th colspan="2">Abnormal Colposcopic Findings</th>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.lesionsPresent">
                                                        <td class="col-8">Lesions Present</td>
                                                        <td class="col-4" [textContent]="colposcopy.lesionsPresent"> </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf=" colposcopy.lowGradeFeatureList">


                                                        <th colspan="2">Low Grade Features</th>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.lowGradeFeatureList">
                                                        <td colspan="2">
                                                            <div class="dashboard-body">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <!--<h6 class="font-14 mt-0 mb-2">Special Instrument</h6>-->
                                                                        <ul class="dashboard-ul row">
                                                                            <li *ngFor="let item of colposcopy.lowGradeFeatureList" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf=" colposcopy.highGradeFeatureList">


                                                        <th colspan="2">High-Grade Features</th>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.highGradeFeatureList">
                                                        <td colspan="2">
                                                            <div class="dashboard-body">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <!--<h6 class="font-14 mt-0 mb-2">Special Instrument</h6>-->
                                                                        <ul class="dashboard-ul row">
                                                                            <li *ngFor="let item of colposcopy.highGradeFeatureList" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf=" colposcopy.suspiciousCancerList
                            || colposcopy.otherFindingList || colposcopy.lugolsStaining
                            || colposcopy.miscellaneousList || colposcopy.miscellaneousList
                            || colposcopy.remarks
                            || colposcopy.endocervicalSampling || colposcopy.colposcopicImpression||colposcopy.reviewDate">


                                                        <th colspan="2">Suspicious For Invasive Cancer</th>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.suspiciousCancerList">
                                                        <td colspan="2">
                                                            <div class="dashboard-body">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <!--<h6 class="font-14 mt-0 mb-2">Special Instrument</h6>-->
                                                                        <ul class="dashboard-ul row">
                                                                            <li *ngFor="let item of colposcopy.suspiciousCancerList" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.otherFindingList">
                                                        <td class="col-8">Other Findings</td>
                                                        <td class="col-4" [textContent]="colposcopy.otherFindingList"> </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.lugolsStaining">
                                                        <td class="col-8">Lugols Staining</td>
                                                        <td class="col-4" [textContent]="colposcopy.lugolsStaining"> </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.miscellaneousList">
                                                        <td class="col-8">Miscellaneous Findings</td>
                                                        <td class="col-4" [textContent]="colposcopy.miscellaneousList"> </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.remarks">
                                                        <td class="col-8">Remarks</td>
                                                        <td class="col-4" [textContent]="colposcopy.remarks"> </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.locationOfBiopsies">
                                                        <td class="col-8">Location Of Biopsies</td>
                                                        <td class="col-4" [textContent]="colposcopy.locationOfBiopsies"> </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.endocervicalSampling">
                                                        <td class="col-8">Endocervical Sampling</td>
                                                        <td class="col-4" [textContent]="colposcopy.endocervicalSampling "> </td>
                                                    </tr>

                                                    <tr *ngIf="colposcopy.colposcopicImpression">
                                                        <td class="col-8">Colposcopic Impression</td>
                                                        <td class="col-4" [textContent]="colposcopy.colposcopicImpression "> </td>
                                                    </tr>
                                                    <tr *ngIf="colposcopy.reviewDate">
                                                        <td class="col-8">Review Date</td>
                                                        <td class="col-4" [textContent]="colposcopy.reviewDate | date:'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="dashboard-box dashboard-table-box" *ngIf="addendumForm && addendumForm !== null" #addendumFormSection>
                                <h4 class="dashboard-title">Addendum Form</h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr *ngIf="addendumForm.date">
                                                <td class="col-8">Date</td>
                                                <td class="col-4" [textContent]="addendumForm.date  | date: 'dd/MM/yyyy'"> </td>
                                            </tr>
                                            <tr *ngIf="addendumForm.time">
                                                <td class="col-8">Time</td>
                                                <td class="col-4" [textContent]="addendumForm.time"> </td>
                                            </tr>
                                            <tr *ngIf="addendumForm.note">
                                                <td class="col-8">Note</td>
                                                <td class="col-4" [textContent]="addendumForm.note"> </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="dashboard-box dashboard-table-box" *ngIf="surgerySlip && (surgerySlip.surgicalProcedure||surgerySlip.remarks
                                 ||surgerySlip.admissionDate  ||surgerySlip.admissionDate||surgerySlip.surgeon||surgerySlip.locationId  ||surgerySlip.otherProcedure || surgerySlip.surgeryDate ||
                                 surgerySlip.mrno || surgerySlip.name || surgerySlip.age || surgerySlip.husbandName)">
                                <h4 class="dashboard-title">Surgery Slip</h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr *ngIf="surgerySlip.mrno">
                                                <td class="col-8">MR No</td>
                                                <td class="col-4" [textContent]="surgerySlip.mrno"> </td>
                                            </tr>
                                            <tr *ngIf="surgerySlip.name">
                                                <td class="col-8">Name</td>
                                                <td class="col-4" [textContent]="surgerySlip.name"> </td>
                                            </tr>
                                            <tr *ngIf="surgerySlip.age">
                                                <td class="col-8">Age</td>
                                                <td class="col-4" [textContent]="surgerySlip.age"> </td>
                                            </tr>
                                            <tr *ngIf="surgerySlip.husbandName">
                                                <td class="col-8">W/O</td>
                                                <td class="col-4" [textContent]="surgerySlip.husbandName"> </td>
                                            </tr>
                                            <tr *ngIf="surgerySlip.consultant">
                                                <td class="col-8">Consultant</td>
                                                <td class="col-4" [textContent]="surgerySlip.consultant"> </td>
                                            </tr>
                                            <tr *ngIf="surgerySlip.surgicalProcedure">
                                                <td class="col-8">Surgical Procedure</td>
                                                <td class="col-4" [textContent]="surgerySlip.surgicalProcedure"> </td>
                                            </tr>
                                            <tr *ngIf="surgerySlip.otherProcedure">
                                                <td class="col-8">Other Procedure</td>
                                                <td class="col-4" [textContent]="surgerySlip.otherProcedure"> </td>
                                            </tr>
                                            <tr *ngIf="surgerySlip.surgeryDate">
                                                <td class="col-8">Surgery Date</td>
                                                <td class="col-4" [textContent]="surgerySlip.surgeryDate"> </td>
                                            </tr>
                                            <tr *ngIf="surgerySlip.admissionDate">
                                                <td class="col-8">Admission Date</td>
                                                <td class="col-4" [textContent]="surgerySlip.admissionDate  | date: 'dd/MM/yyyy'"> </td>
                                            </tr>
                                            <tr *ngIf="surgerySlip.surgeon">
                                                <td class="col-8">Surgeon</td>
                                                <td class="col-4" [textContent]="surgerySlip.surgeon"> </td>
                                            </tr>
                                            <tr *ngIf="surgerySlip.surgeryLocation">
                                                <td class="col-8">Surgery Location</td>
                                                <td class="col-4" [textContent]="surgerySlip.locationId"> </td>
                                            </tr>
                                            <tr *ngIf="surgerySlip.remarks">
                                                <td class="col-8">Remarks</td>
                                                <td class="col-4" [textContent]="surgerySlip.remarks"> </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="roleId != 63">
                            <div class="dashboard-box dashboard-table-box" #admssionFormSection *ngIf="admissionSlip && admissionSlip !== null">
                                <h4 class="dashboard-title">Admission Slip</h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr *ngIf="admissionSlip.mrno">
                                                <td class="col-8">Mr No</td>
                                                <td class="col-4" [textContent]="admissionSlip.mrno"> </td>
                                            </tr>
                                            <tr *ngIf="admissionSlip.name">
                                                <td class="col-8">Name</td>
                                                <td class="col-4" [textContent]="admissionSlip.name"> </td>
                                            </tr>
                                            <tr *ngIf="admissionSlip.age">
                                                <td class="col-8">Age</td>
                                                <td class="col-4" [textContent]="admissionSlip.age"> </td>
                                            </tr>
                                            <tr *ngIf="admissionSlip.wifeOf">
                                                <td class="col-8">Wife Of</td>
                                                <td class="col-4" [textContent]="admissionSlip.wifeOf"> </td>
                                            </tr>
                                            <tr *ngIf="admissionSlip.department">
                                                <td class="col-8">Department</td>
                                                <td class="col-4" [textContent]="admissionSlip.department"> </td>
                                            </tr>
                                            <tr *ngIf="admissionSlip.admissionDate">
                                                <td class="col-8">Admssion Date</td>
                                                <td class="col-4" [textContent]="admissionSlip.admissionDate | date: 'dd/MM/yyyy'"> </td>
                                            </tr>
                                            <tr *ngIf="admissionSlip.time">
                                                <td class="col-8">Time</td>
                                                <td class="col-4" [textContent]="admissionSlip.time"> </td>
                                            </tr>
                                            <tr *ngIf="admissionSlip.admssionDoctor">
                                                <td class="col-8">Admission Doctor</td>
                                                <td class="col-4" [textContent]="admissionSlip.admssionDoctor.toUpperCase()"> </td>
                                            </tr>
                                            <tr *ngIf="admissionSlip.fhConsultant">
                                                <td class="col-8">Consultant</td>
                                                <td class="col-4" [textContent]="admissionSlip.fhConsultant"> </td>
                                            </tr>
                                            <tr *ngIf="admissionSlip.surgeryDate">
                                                <td class="col-8">Surgery Date</td>
                                                <td class="col-4" [textContent]="admissionSlip.surgeryDate | date: 'dd/MM/yyyy'"> </td>
                                            </tr>
                                            <tr *ngIf="admissionSlip.surgeon">
                                                <td class="col-8">Surgeon</td>
                                                <td class="col-4" [textContent]="admissionSlip.surgeon.toUpperCase()"> </td>
                                            </tr>
                                            <tr *ngIf="admissionSlip.surgicalPrcedure">
                                                <td class="col-8">Surgical Procedure</td>
                                                <td class="col-4" [textContent]="admissionSlip.surgicalPrcedure"> </td>
                                            </tr>
                                            <tr *ngIf="admissionSlip.gestationalAge">
                                                <td class="col-8">Gestational Age</td>
                                                <td class="col-4" [textContent]="admissionSlip.gestationalAge"> </td>
                                            </tr>
                                            <tr *ngIf="admissionSlip.phoneNo">
                                                <td class="col-8">Phone No</td>
                                                <td class="col-4" [textContent]="admissionSlip.phoneNo"> </td>
                                            </tr>
                                            <tr *ngIf="admissionSlip.resonForAdmission">
                                                <td class="col-8">Reason For Admission</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="admissionSlip.resonForAdmission"> </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="roleId != 63">
                            <div class="dashboard-box dashboard-table-box" #referralFormSection *ngIf="referralForm && referralForm.length>0 ">
                                <h4 class="dashboard-title">Referral Form</h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Referral To Doctor</th>
                                                    <th>Reason For Referring</th>
                                                    <th>Req Date</th>
                                                    <th>Time</th>
                                                    <th>Consultant Doctor</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of referralForm">
                                                    <td><span [textContent]="item.referralDoctor"></span></td>
                                                    <td><span [textContent]="item.reason"></span></td>
                                                    <td><span [textContent]="item.requiredDate | date: 'dd/MM/yyyy'"></span></td>
                                                    <td><span [textContent]="item.time"></span></td>
                                                    <td><span [textContent]="item.consultantDoctor"></span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="dashboard-box dashboard-table-box" #doctorReviewSection *ngIf="doctorsReviewForm && doctorsReviewForm !== null">
                            <h4 class="dashboard-title">Doctors Review</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto" *ngFor="let doctorsReviewForm of doctorsReviewForm">
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <tr *ngIf="doctorsReviewForm.timeOfAssessment">
                                            <td class="col-8">Time Of Assessment</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.timeOfAssessment"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.mrNo">
                                            <td class="col-8">MR No</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.mrNo"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.name">
                                            <td class="col-8">Name</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.name"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.husbandName">
                                            <td class="col-8">Husband Name</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.husbandName"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.ipNo">
                                            <td class="col-8">IP No</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.ipNo"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.dateOfAdmission">
                                            <td class="col-8">Date Of Admission</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.dateOfAdmission | date:'dd/MM/yyyy'"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.time">
                                            <td class="col-8">Time(hrs)</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.time"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.roomNo">
                                            <td class="col-8">Room No</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.roomNo"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.complaints && doctorsReviewForm.complaints.length >0">
                                            <div class="col-12" *ngIf="doctorsReviewForm.complaints && doctorsReviewForm.complaints.length > 0">
                                                <h6 class="font-14 mt-0 mb-1">Complaints</h6>
                                                <ul class="dashboard-ul row">
                                                    <li *ngFor="let item of  doctorsReviewForm.complaints" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                </ul>
                                            </div>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.otherComplaints">
                                            <td class="col-8">Other Complaints</td>
                                            <td class="col-4">
                                                <div style="white-space:normal; word-break:break-all;">
                                                    <span [textContent]="doctorsReviewForm.otherComplaints"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm && (doctorsReviewForm.heightInFeet || doctorsReviewForm.weightInKg || doctorsReviewForm.bmi || doctorsReviewForm.systolicBp || doctorsReviewForm.diastolicBp || doctorsReviewForm.pulseRate ||
                                                doctorsReviewForm.respiratoryRate || doctorsReviewForm.spO2 || doctorsReviewForm.temperature || doctorsReviewForm.conscious || doctorsReviewForm.shock || doctorsReviewForm.pallor || doctorsReviewForm.icterus ||
                                                doctorsReviewForm.oedema ||  doctorsReviewForm.spleen || doctorsReviewForm.liver || doctorsReviewForm.reflex || doctorsReviewForm.cardiovasc || doctorsReviewForm.respSystem || doctorsReviewForm.urineOutput || doctorsReviewForm.urineAlbumin)">
                                            <h6 class="font-14 mt-0 mb-2">General Examination</h6>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.heightInFeet">
                                            <td class="col-8">Height In Feet</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.heightInFeet"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.weightInKg">
                                            <td class="col-8">Weight In Kg</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.weightInKg"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.bmi">
                                            <td class="col-8">BMI</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.bmi"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.systolicBp">
                                            <td class="col-8">Systolic BP</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.systolicBp"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.diastolicBp">
                                            <td class="col-8">Diastolic BP</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.diastolicBp"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.pulseRate">
                                            <td class="col-8">Pulse Rate</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.pulseRate"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.respiratoryRate">
                                            <td class="col-8">Respiratory Rate</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.respiratoryRate"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.spO2">
                                            <td class="col-8">SpO2</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.spO2"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.temperature">
                                            <td class="col-8">Temperature</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.temperature"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.conscious">
                                            <td class="col-8">Conscious</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.conscious"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.shock">
                                            <td class="col-8">Shock</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.shock"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.pallor">
                                            <td class="col-8">Pallor</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.pallor"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.icterus">
                                            <td class="col-8">Icterus</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.icterus"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.oedema">
                                            <td class="col-8">Oedema</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.oedema"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.spleen">
                                            <td class="col-8">Spleen</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.spleen"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.liver">
                                            <td class="col-8">Liver</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.liver"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.reflex">
                                            <td class="col-8">Reflex</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.reflex"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.cardiovasc">
                                            <td class="col-8">Cardiovasc</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.cardiovasc"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.respSystem">
                                            <td class="col-8">Resp. System</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.respSystem"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.urineOutput">
                                            <td class="col-8">Urine Output</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.urineOutput"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.urineAlbumin">
                                            <td class="col-8">Urine Albumin</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.urineAlbumin"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.type">
                                            <h6 class="font-14 mt-0 mb-2">
                                                Specific Examination
                                            </h6>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.type">
                                            <td class="col-8">Type</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.type"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.others1">
                                            <td class="col-8">Others</td>
                                            <td class="col-4">
                                                <div style="white-space:normal; word-break:break-all;">
                                                    <span [textContent]="doctorsReviewForm.others1"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.uterusPalpable || doctorsReviewForm.fundalHt || doctorsReviewForm.utContractions || doctorsReviewForm.pp1 || doctorsReviewForm.fifthPalpable || doctorsReviewForm.amnioticFluid || doctorsReviewForm.fhs || doctorsReviewForm.fhr || doctorsReviewForm.others2 || doctorsReviewForm.others4 || doctorsReviewForm.others5">
                                            <h6 class="font-14 mt-0 mb-2">
                                                Obstetric Examination
                                            </h6>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.uterusPalpable">
                                            <td class="col-8">Uterus Palpable</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.uterusPalpable"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.fundalHt">
                                            <td class="col-8">Fundal Ht	</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.fundalHt"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.utContractions">
                                            <td class="col-8">Ut Contractions</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.utContractions"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.pp1">
                                            <td class="col-8">P P</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.pp1"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.fifthPalpable">
                                            <td class="col-8">Fifth Palpable</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.fifthPalpable"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.amnioticFluid">
                                            <td class="col-8">Amniotic Fluid</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.amnioticFluid"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.fhs">
                                            <td class="col-8">FHS</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.fhs"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.fhr">
                                            <td class="col-8">FHR</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.fhr"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.others2">
                                            <td class="col-8">Others</td>
                                            <td class="col-4">
                                                <div style="white-space:normal; word-break:break-all;">
                                                    <span [textContent]="doctorsReviewForm.others2"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.others4">
                                            <td class="col-8">Others</td>
                                            <td class="col-4">
                                                <div style="white-space:normal; word-break:break-all;">
                                                    <span [textContent]="doctorsReviewForm.others4"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.others5">
                                            <td class="col-8">Others</td>
                                            <td class="col-4">
                                                <div style="white-space:normal; word-break:break-all;">
                                                    <span [textContent]="doctorsReviewForm.others5"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.psEaxm || doctorsReviewForm.psOthers || doctorsReviewForm.cxLength || doctorsReviewForm.cxDilation || doctorsReviewForm.membranes || doctorsReviewForm.presentingPart || doctorsReviewForm.station || doctorsReviewForm.pelvis || doctorsReviewForm.others5">
                                            <h6 class="font-14 mt-0 mb-2">
                                                Obstetric Vaginal Examination
                                            </h6>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.psEaxm">
                                            <td class="col-8">P/S Exam</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.psEaxm"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.psEaxm">
                                            <td class="col-8">P/S Others</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.psOthers"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.cxLength">
                                            <td class="col-8">Cx Length	</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.cxLength"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.cxDilation">
                                            <td class="col-8">Cx Dilation</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.cxDilation"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.membranes">
                                            <td class="col-8">Membranes</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.membranes"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.presentingPart">
                                            <td class="col-8">Presenting Part</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.presentingPart"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.station">
                                            <td class="col-8">Station</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.station"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.pelvis">
                                            <td class="col-8">Pelvis</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.pelvis"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.others3">
                                            <td class="col-8">Others</td>
                                            <td class="col-4">
                                                <div style="white-space:normal; word-break:break-all;">
                                                    <span [textContent]="doctorsReviewForm.others3"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.postnatalComplaints || doctorsReviewForm.otherComplaints2 || doctorsReviewForm.lactating || doctorsReviewForm.perineum || doctorsReviewForm.perinealWound || doctorsReviewForm.abdominalIncision || doctorsReviewForm.perSpeculam || doctorsReviewForm.perVaginum ||
                                                   doctorsReviewForm.perRectal || doctorsReviewForm.bleedingPv || doctorsReviewForm.details || doctorsReviewForm.others6 || doctorsReviewForm.psOthers">
                                            <h6 class="font-14 mt-0 mb-2">
                                                Postnatal Visit
                                            </h6>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.postnatalComplaints && doctorsReviewForm.postnatalComplaints.length >0">
                                            <td class="col-8">
                                                Postnatal Complaints
                                            </td>
                                            <td class="col-4">
                                                <ul class="dashboard-ul row">
                                                    <li *ngFor="let item of  doctorsReviewForm.postnatalComplaints" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.otherComplaints2">
                                            <td class="col-8">Other Complaints</td>
                                            <td class="col-4">
                                                <div style="white-space:normal; word-break:break-all;">
                                                    <span [textContent]="doctorsReviewForm.otherComplaints2"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.lactating">
                                            <td class="col-8">Lactating</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.lactating"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.perineum">
                                            <td class="col-8">Perineum</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.perineum"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.perinealWound">
                                            <td class="col-8">Perineal Wound</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.perinealWound"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.abdominalIncision">
                                            <td class="col-8">Abdominal Incision</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.abdominalIncision"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.perSpeculam">
                                            <td class="col-8">Per Speculam</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.perSpeculam"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.perVaginum">
                                            <td class="col-8">Per Vaginum</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.perVaginum"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.perRectal">
                                            <td class="col-8">Per Rectal</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.perRectal"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.bleedingPv">
                                            <td class="col-8">Bleeding PV</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.bleedingPv"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.details">
                                            <td class="col-8">Details</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.details"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.others6">
                                            <td class="col-8">Others</td>
                                            <td class="col-4">
                                                <div style="white-space:normal; word-break:break-all;">
                                                    <span [textContent]="doctorsReviewForm.others6"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.psEaxm">
                                            <td class="col-8">P/S Others</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.psOthers"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.drugAllergies">
                                            <td class="col-8">Drug Allergies</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.drugAllergies"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.consultantOpinion">
                                            <td class="col-8">Consultant Opinion</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.consultantOpinion"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.counselling">
                                            <td class="col-8">Counselling</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.counselling"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.consultantInformed">
                                            <td class="col-8">Consultant Informed</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.consultantInformed"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.doctorsReview">
                                            <td class="col-8">Doctors Review</td>
                                            <td class="col-4">
                                                <div style="white-space:normal; word-break:break-all;">
                                                    <span [textContent]="doctorsReviewForm.doctorsReview"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.seenByDr">
                                            <td class="col-8">Seen By Dr</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.seenByDr"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.otherConsultant">
                                            <td class="col-8">Other Consultant</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.otherConsultant"> </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div #telephoneTriageAssessmentSection *ngIf="telephoneTriageAssessmentdata && telephoneTriageAssessmentdata.length>0">
                            <div class="dashboard-box dashboard-table-box" *ngFor="let telephoneTriageAssessment of telephoneTriageAssessmentdata">
                                <h4 class="dashboard-title">TelePhoneTriageAssessment </h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">

                                            <tr>
                                                <td class="col-8">Call Date</td>
                                                <td class="col-4" [textContent]="telephoneTriageAssessment.callDate | date:'dd/MM/yyyy'"> </td>
                                            </tr>
                                            <tr>
                                                <td class="col-8">Call Time</td>
                                                <td class="col-4" [textContent]="telephoneTriageAssessment.callTime"> </td>
                                            </tr>
                                            <tr>
                                                <td class="col-8">Type of Consultation</td>
                                                <td class="col-4" [textContent]="telephoneTriageAssessment.typeofConsultation"> </td>
                                            </tr>
                                            <tr *ngIf="telephoneTriageAssessment && telephoneTriageAssessment.reasonForCall">
                                                <td class="col-4">Reason For Call</td>
                                                <td class="col-8">
                                                    <ul class="dashboard-ul row">
                                                        <li *ngFor="let item of  telephoneTriageAssessment.reasonForCall" [textContent]="item"></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="col-8">Other</td>
                                                <td class="col-4" [textContent]="telephoneTriageAssessment.reasonothers"> </td>
                                            </tr>
                                            <tr *ngIf="telephoneTriageAssessment && telephoneTriageAssessment.adviceGiventoPatient">
                                                <td class="col-4">Advice Given To Patient</td>
                                                <td class="col-8">
                                                    <ul class="dashboard-ul row">
                                                        <li *ngFor="let item of  telephoneTriageAssessment.adviceGiventoPatient" [textContent]="item"></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="col-8"> Others</td>
                                                <td class="col-4" [textContent]="telephoneTriageAssessment.adviceother"> </td>
                                            </tr>
                                            <tr *ngIf="telephoneTriageAssessment && telephoneTriageAssessment.actionTakenIfAdvisedToVisit">
                                                <td class="col-4">ActionTakenIfAdvisedToVisit</td>
                                                <td class="col-8">
                                                    <ul class="dashboard-ul row">
                                                        <li *ngFor="let item of  telephoneTriageAssessment.actionTakenIfAdvisedToVisit" [textContent]="item"></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="col-8">Others</td>
                                                <td class="col-4" [textContent]="telephoneTriageAssessment.actionother"> </td>
                                            </tr>
                                            <tr *ngIf="telephoneTriageAssessment && telephoneTriageAssessment.doctorName">
                                                <td class="col-8">Doctor Name</td>
                                                <td class="col-4 text-uppercase" [textContent]="telephoneTriageAssessment.doctorName"> </td>
                                            </tr>
                                            <tr *ngIf="telephoneTriageAssessment && telephoneTriageAssessment.callCompletedDate">
                                                <td class="col-8">Call Completed Date</td>
                                                <td class="col-4" [textContent]="telephoneTriageAssessment.callCompletedDate | date:'dd/MM/yyyy'"> </td>
                                            </tr>
                                            <tr *ngIf="telephoneTriageAssessment && telephoneTriageAssessment.callcompletedTime">
                                                <td class="col-8">Call Completed Time</td>
                                                <td class="col-4" [textContent]="telephoneTriageAssessment.callcompletedTime"> </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dashboard-box" #antinantalRiskSection *ngIf="antinantalRisk && antinantalRisk !== null ">
                            <h4 class="dashboard-title">Risk Factor</h4>
                            <div class="dashboard-body">
                                <div *ngIf="antinantalRisk && antinantalRisk.noRisk">
                                    There is No Risk
                                </div>
                                <table border="collapse" class="border dashboard-break table table-sm" *ngIf="antinantalRisk && !antinantalRisk.noRisk">
                                    <tr class="bg-soft-dark" *ngIf="antinantalRisk.characteristics && antinantalRisk.characteristics.length >0" style="border-bottom:1px solid black">
                                        <th colspan="2">Antenantal Risk</th>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.characteristics && antinantalRisk.characteristics.length >0">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Material characteristics</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.characteristics; let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i != antinantalRisk.characteristics.length-1) && (item && item !='')">,</span>
                                            </span>
                                        </td>
                                    </tr>

                                    <tr *ngIf="antinantalRisk.covidInfection && antinantalRisk.covidInfection != null" style="border-bottom:1px solid black">
                                        <td>
                                            <div class="font-14 font-weight-bold mt-0 mb-1">Covid-19 infection </div>
                                        </td>
                                        <td>
                                            <div><span [textContent]="antinantalRisk.covidInfection"></span> </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.details && antinantalRisk.details.length >0">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Previous Pregnancy Details</span>
                                        </td>

                                        <td>
                                            <span *ngFor="let item of antinantalRisk.details ;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="( i!= antinantalRisk.details.length-1) && (item && item !='')">,</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.maternalmedical && antinantalRisk.maternalmedical.length > 0">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Maternal Medical History</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.maternalmedical ;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf=" (i!= antinantalRisk.maternalmedical.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.uterineRiskFactor && antinantalRisk.uterineRiskFactor.length > 0">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Uterine Risk Factor</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.uterineRiskFactor ;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="( i!= antinantalRisk.uterineRiskFactor.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.pregnancyComplications && antinantalRisk.pregnancyComplications.length > 0" style="border-bottom:1px solid black">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Current Pregnancy Complications</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.pregnancyComplications ;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="( i!= antinantalRisk.pregnancyComplications.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.fetusComplications && antinantalRisk.fetusComplications.length > 0 || antinantalRisk.otherComplaints !==null">
                                        <td>
                                            <span class="font-14 font-weight-bold mt-0 mb-1">Fetus Complications</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.fetusComplications ;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="( i!= antinantalRisk.fetusComplications.length-1) && (item && item !='')">,</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk && antinantalRisk.otherComplaints !==null" style="border-bottom:1px solid black">
                                        <td>
                                            <span class="font-14 font-weight-bold mt-0 mb-1">Other</span>
                                        </td>

                                        <td>
                                            <span [textContent]="antinantalRisk.otherComplaints"></span>
                                        </td>
                                    </tr>
                                    <tr class="bg-soft-dark" *ngIf=" antinantalRisk.meternall.length > 0|| antinantalRisk.latePragnancy.length > 0 || antinantalRisk.currentPragnancy.length > 0 || antinantalRisk.funtalFetaus.length > 0 || antinantalRisk.maternalMedicalHistory.length > 0 || antinantalRisk.previousPregency.length > 0" style="border-bottom:1px solid black">
                                        <th colspan="2">SGA Screening</th>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.meternall && antinantalRisk.meternall.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Maternal Risk Factor</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.meternall ;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf=" (i!=  antinantalRisk.meternall.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.previousPregency && antinantalRisk.previousPregency.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Previous Pregnancy History</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.previousPregency ;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf=" (i!=  antinantalRisk.previousPregency.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.maternalMedicalHistory && antinantalRisk.maternalMedicalHistory.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Maternal Medical History</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.maternalMedicalHistory ;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf=" (i!=  antinantalRisk.maternalMedicalHistory.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>

                                    <tr *ngIf="antinantalRisk.funtalFetaus && antinantalRisk.funtalFetaus.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Unsuitable for monitoring Fundal Fetaus</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.funtalFetaus ;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="( i!=  antinantalRisk.funtalFetaus.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.currentPragnancy && antinantalRisk.currentPragnancy.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Current Pregnancy Complications - Early Pregnancy</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.currentPragnancy;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="( i!=   antinantalRisk.currentPragnancy.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.latePragnancy && antinantalRisk.latePragnancy.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Late Pregnancy</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.latePragnancy;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="( i!=  antinantalRisk.latePragnancy.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr class="bg-soft-dark" *ngIf="antinantalRisk.highRisk.length > 0||antinantalRisk.intermediateRisk.length > 0|| antinantalRisk.lowRisk.length > 0 || antinantalRisk.comments !=null " style="border-bottom:1px solid black">
                                        <th colspan="2">VTE Risk</th>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.highRisk && antinantalRisk.highRisk.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">High Risk</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.highRisk;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="( i!= antinantalRisk.highRisk.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.intermediateRisk && antinantalRisk.intermediateRisk.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Intermediate Risk</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.intermediateRisk;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf=" (i!= antinantalRisk.intermediateRisk.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.lowRisk && antinantalRisk.lowRisk.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Low Risk</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.lowRisk;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="( i!= antinantalRisk.lowRisk.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.comments && antinantalRisk.comments != null" style="border-bottom:1px solid black">
                                        <td>
                                            <div class="font-14 font-weight-bold mt-0 mb-1">Other </div>
                                        </td>
                                        <td>
                                            <div><span [textContent]="antinantalRisk.comments"></span> </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div>
                            <div class="dashboard-box dashboard-table-box" #anaesthesiarecordPostopSection *ngIf="anaesthesiaRecord && anaesthesiaRecord !== null">
                                <h4 class="dashboard-title">Anaesthesia Record (Post OP) </h4>
                                <div class="dashboard-body">
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <tr *ngIf="anaesthesiaRecord.date">
                                            <td class="col-8">Date</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.date |date:'dd/MM/yyyy'"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.ot">
                                            <td class="col-8">OT</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.ot"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.consent">
                                            <td class="col-8">Consent</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.consent"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.npoStatus">
                                            <td class="col-8">NPO Status (Hrs)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.npoStatus"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.anaesthesiologist">
                                            <td class="col-8">Anaesthesiologist</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.anaesthesiologist"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.surgeon">
                                            <td class="col-8">Surgeon</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.surgeon"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.surgeryDone">
                                            <td class="col-8">Surgery Done</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.surgeryDone"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.anaesthesiaTechnique">
                                            <td class="col-8">Anaesthesia. Technique</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.anaesthesiaTechnique"> </td>
                                        </tr>
                                        <tr>
                                            <th>Immediate Pre-Op Vitals</th>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.pulseRate">
                                            <td class="col-8">Pulse Rate (Min)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.pulseRate"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.systolicBP">
                                            <td class="col-8">Systolic BP (Mm Hg)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.systolicBP"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.diastoticBP">
                                            <td class="col-8">Diastolic BP Mm Hg</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.diastoticBP"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.respiratoryRate">
                                            <td class="col-8">Respiratory Rate (Min)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.respiratoryRate"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.spo2">
                                            <td class="col-8">SPO2 (%)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.spo2"> </td>
                                        </tr>
                                        <tr>
                                            <th>Time Tracking</th>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.anaesthesiaStartedTime">
                                            <td class="col-8">Anaesthesia Started Time</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.anaesthesiaStartedTime"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.completedTime">
                                            <td class="col-8">Completed Time</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.completedTime"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.surgeryStartedTime">
                                            <td class="col-8">Surgery Started Time</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.surgeryStartedTime"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.scompletedTime">
                                            <td class="col-8">S Completed Time</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.scompletedTime"> </td>
                                        </tr>
                                        <tr>
                                            <th>GA Details</th>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.lma">
                                            <td class="col-8">LMA</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.lma"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.size">
                                            <td class="col-8">Size</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.size"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.cufDtls">
                                            <td class="col-8">Cuf Dtls</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.cufDtls"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.ettSize">
                                            <td class="col-8">ETT Size</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.ettSize"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.noofAttempts">
                                            <td class="col-8">No Of Attempts</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.noofAttempts"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.difficultIntubation">
                                            <td class="col-8">Difficult Intubation</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.difficultIntubation"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.clGroup">
                                            <td class="col-8">CL Group</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.clGroup"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.vls">
                                            <td class="col-8">VLS</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.vls"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.sellicksManoeuvre">
                                            <td class="col-8">Sellicks Manoeuvre</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.sellicksManoeuvre"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.oxygen">
                                            <td class="col-8">Oxygen (L/Min)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.oxygen"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.n2o">
                                            <td class="col-8">N2O (L/Min)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.n2o"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.air">
                                            <td class="col-8">Air (L/Min)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.air"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.inhalationalAgent">
                                            <td class="col-8">Inhalational Agent</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.inhalationalAgent"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.ventilation">
                                            <td class="col-8">Ventilation</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.ventilation"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.vt">
                                            <td class="col-8">VT</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.vt"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.rr">
                                            <td class="col-8">RR</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.rr"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.awp">
                                            <td class="col-8">AWP</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.awp"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.peep">
                                            <td class="col-8">PEEP</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.peep"> </td>
                                        </tr>
                                        <tr><th>Regional Anaesthesia DTL</th></tr>
                                        <tr *ngIf="anaesthesiaRecord.regionalAnesthesia">
                                            <td class="col-8">Regional Anesthesia</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.regionalAnesthesia"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.tipg">
                                            <td class="col-8">Tip / G</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.tipg"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.positionInsert">
                                            <td class="col-8">Position Insert / Inj.</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.positionInsert"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.spacelorsa">
                                            <td class="col-8">Space LORS / A</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.spacelorsa"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.time">
                                            <td class="col-8">Time</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.time"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.drug">
                                            <td class="col-8">Drug</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.drug"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.vdose">
                                            <td class="col-8">V / Dose</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.vdose"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.onset">
                                            <td class="col-8">Onset</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.onset"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.dermLevel">
                                            <td class="col-8">Derm. Level</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.dermLevel"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.motorBlock">
                                            <td class="col-8">Motor Block</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.motorBlock"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.paraesthesiaInsert">
                                            <td class="col-8">Paraesthesia Insert / Inj.</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.paraesthesiaInsert"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.btoodTap">
                                            <td class="col-8">Btood Tap</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.btoodTap"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.dp">
                                            <td class="col-8">DP</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.dp"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.comments">
                                            <td class="col-8">Comments</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.comments"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.topups">
                                            <td class="col-8">Topups</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.topups"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.patientPosition">
                                            <td class="col-8">Patient Position</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.patientPosition"> </td>
                                        </tr>
                                        <tr><th>Monitoring</th></tr>
                                        <tr>
                                            <td>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <h6 class="font-18 mt-0 mb-2"></h6>
                                                        <ul class="dashboard-ul row">
                                                            <li *ngFor="let item of anaesthesiaRecord.monitoring" class="col-xl-3" [textContent]="item"></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr><th>Intake Output Details </th></tr>
                                        <tr><th>Intake</th></tr>
                                        <tr *ngIf="anaesthesiaRecord.crystal">
                                            <td class="col-8">Crystal (Ml)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.crystal"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.colloids">
                                            <td class="col-8">Colloids (Ml)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.colloids"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.bllod">
                                            <td class="col-8">Bllod (Ml)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.bllod"> </td>
                                        </tr>
                                        <tr><th>Output</th></tr>
                                        <tr *ngIf="anaesthesiaRecord.estimatedBloodLoss">
                                            <td class="col-8">Estimated Blood Loss (Ml)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.estimatedBloodLoss"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.urine">
                                            <td class="col-8">Urine (Ml)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.urine"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.ascitis">
                                            <td class="col-8">Ascitis (Ml)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.ascitis"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.rylesTubeAspiration">
                                            <td class="col-8">Ryles Tube Aspiration (Ml)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.rylesTubeAspiration"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.recovery">
                                            <td class="col-8">Recovery</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.recovery"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.status">
                                            <td class="col-8">Status</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.status"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.reflwxesMuscleTone">
                                            <td class="col-8">Reflwxes / Muscle Tone</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.reflwxesMuscleTone"> </td>
                                        </tr>
                                        <tr><th>Vitals Before Shifting</th></tr>
                                        <tr *ngIf="anaesthesiaRecord.pr">
                                            <td class="col-8">PR (Min)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.pr"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.sysbp">
                                            <td class="col-8">Sys BP (Mm Hg)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.sysbp"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.dbp">
                                            <td class="col-8">D BP (Mm Hg)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.dbp"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.spo">
                                            <td class="col-8">SpO2</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.spo"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.vas">
                                            <td class="col-8">VAS</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.vas"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.shiftedTo">
                                            <td class="col-8">Shifted To</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.shiftedTo"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.electiveVentilation">
                                            <td class="col-8">Elective Ventilation</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.electiveVentilation"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.anyAdverseEvents">
                                            <td class="col-8">Any Adverse Events</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.anyAdverseEvents"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.totalIntakeOutput">
                                            <td class="col-8">Total Intake Output (Ml)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.totalIntakeOutput"> </td>
                                        </tr>
                                        <tr><th>Continue Vitals Monitoring During Procedure</th></tr>
                                        <tr *ngIf="anaesthesiaRecord.continuevitalsdate">
                                            <td class="col-8">Date</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.continuevitalsdate|date:'dd/MM/yyyy'"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.continuevitalstime">
                                            <td class="col-8">Time</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.continuevitalstime"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.continuevitalsspo2">
                                            <td class="col-8">SpO2</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.continuevitalsspo2"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.etCo2">
                                            <td class="col-8">EtCo2</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.etCo2"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.fio2">
                                            <td class="col-8">FiO2</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.fio2"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.inhalionalagent">
                                            <td class="col-8">Inhalional Agent</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.inhalionalagent"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.temperature">
                                            <td class="col-8">Temperature (Fh)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.temperature"> </td>
                                        </tr>

                                    </table>
                                    <div>

                                        <table class="table" *ngIf="anaesthesiaRecord.vitalshistory && anaesthesiaRecord.vitalshistory.length>0">
                                            <th> Vitals</th>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>SPO2</th>
                                                    <th>ETCO2</th>
                                                    <th>FIO2</th>
                                                    <th>Inhalional Agent</th>
                                                    <th>Temperature</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of anaesthesiaRecord.vitalshistory">
                                                    <td>
                                                        <span>{{item.vitalsdate |date:'dd/MM/yyyy'}}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{item.vitalstime }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{item.vitalsSPO2 }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{item.vitalsETCO2 }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{item.vitalsFIO2 }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{item.vitalsInhalionalagent }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{item.vitalsTemperature}}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="dashboard-box dashboard-table-box" #anaesthesiaSection *ngIf="anaesthesia && anaesthesia !== null">
                                <h4 class="dashboard-title">Anaesthesia Record</h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia && anaesthesia.weight || anaesthesia &&  anaesthesia.height|| anaesthesia &&  anaesthesia.bmi|| anaesthesia && anaesthesia.pacDoneBy||anaesthesia &&  anaesthesia.consultantDr|| anaesthesia && anaesthesia.bloodGroup||anaesthesia && anaesthesia.rhType||
                                                                 anaesthesia && anaesthesia.allergy|| anaesthesia &&  anaesthesia.diagnosis||anaesthesia &&  anaesthesia.surgery|| anaesthesia &&  anaesthesia.problems)">
                                            <div class="col-12">
                                                <h6 class="font-18 mt-0 mb-2">Anaesthesia Record</h6>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr *ngIf="anaesthesia &&  anaesthesia.weight">
                                                        <td class="col-8">Weight</td>
                                                        <td class="col-4" [textContent]="anaesthesia.weight"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.height">
                                                        <td class="col-8">Height</td>
                                                        <td class="col-4" [textContent]="anaesthesia.height"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia &&  anaesthesia.bmi">
                                                        <td class="col-8">BMI</td>
                                                        <td class="col-4" [textContent]="anaesthesia.bmi"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.pacDoneBy">
                                                        <td class="col-8">PAC Done By Dr</td>
                                                        <td class="col-4" [textContent]="anaesthesia.pacDoneBy"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.consultantDr">
                                                        <td class="col-8">Consultant Surgeon</td>
                                                        <td class="col-4" [textContent]="anaesthesia.consultantDr"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.bloodGroup">
                                                        <td class="col-8">Blood Group</td>
                                                        <td class="col-4" [textContent]="anaesthesia.bloodGroup"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.rhType">
                                                        <td class="col-8">Rh Type</td>
                                                        <td class="col-4" [textContent]="anaesthesia.rhType"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia &&  anaesthesia.allergy">
                                                        <td class="col-8">Allergy</td>
                                                        <td class="col-4">
                                                            <span class="wordBreak" [textContent]="anaesthesia.allergy">  </span>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.diagnosis">
                                                        <td class="col-8">Diagnosis</td>
                                                        <td class="col-4">
                                                            <span class="wordBreak" [textContent]="anaesthesia.diagnosis">  </span>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.surgery">
                                                        <td class="col-8">Surgery</td>
                                                        <td class="col-4" [textContent]="anaesthesia.surgery"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.problems">
                                                        <td class="col-8">Problems</td>
                                                        <td class="col-4">
                                                            <span class="wordBreak" [textContent]="anaesthesia.problems">  </span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>

                                        <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia && anaesthesia.reasonForConsutation || anaesthesia &&  anaesthesia.temp|| anaesthesia &&  anaesthesia.sp|| anaesthesia &&  anaesthesia.pr||anaesthesia && anaesthesia.respRate||anaesthesia && anaesthesia.sysBp|| anaesthesia &&  anaesthesia.diasBp)">
                                            <div class="col-12">
                                                <h6 class="font-18 mt-0 mb-2">General Examination</h6>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr *ngIf="anaesthesia &&  anaesthesia.reasonForConsutation">
                                                        <td class="col-8">Reason For Consultation</td>
                                                        <td class="col-4" [textContent]="anaesthesia.reasonForConsutation"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.temp">
                                                        <td class="col-8">
                                                            Temp(Â°C / Fh)
                                                        </td>
                                                        <td class="col-4" [textContent]="anaesthesia.temp"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.pr">
                                                        <td class="col-8">PR(/Min)</td>
                                                        <td class="col-4" [textContent]="anaesthesia.pr"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.respRate">
                                                        <td class="col-8">Resp Rate(/Min)</td>
                                                        <td class="col-4" [textContent]="anaesthesia.respRate"> </td>
                                                    </tr>

                                                    <tr *ngIf="anaesthesia && anaesthesia.sysBp">
                                                        <td class="col-8">Sys BP(Mm Of Hg)</td>
                                                        <td class="col-4" [textContent]="anaesthesia.sysBp"> </td>
                                                    </tr>

                                                    <tr *ngIf="anaesthesia && anaesthesia.diasBp">
                                                        <td class="col-8">Dias BP(Mm Of Hg)</td>
                                                        <td class="col-4" [textContent]="anaesthesia.diasBp"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                        <td class="col-8">SpO2</td>
                                                        <td class="col-4" [textContent]="anaesthesia.sp"> </td>
                                                    </tr>


                                                    <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                        <td class="col-8">Heart</td>
                                                        <td class="col-4" [textContent]="anaesthesia.heart"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                        <td class="col-8">Lungs</td>
                                                        <td class="col-4" [textContent]="anaesthesia.lungs"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                        <td class="col-8">Lungs-Adv Sounds</td>
                                                        <td class="col-4" [textContent]="anaesthesia.lungsSound"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                        <td class="col-8">Built</td>
                                                        <td class="col-4" [textContent]="anaesthesia.built"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                        <td class="col-8">Hydration</td>
                                                        <td class="col-4" [textContent]="anaesthesia.hydration"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                        <td class="col-8">Pallor</td>
                                                        <td class="col-4" [textContent]="anaesthesia.pallor"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                        <td class="col-8">Icterus</td>
                                                        <td class="col-4" [textContent]="anaesthesia.icterus"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                        <td class="col-8">Cyanosis</td>
                                                        <td class="col-4" [textContent]="anaesthesia.cyanosis"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                        <td class="col-8">Oedema</td>
                                                        <td class="col-4" [textContent]="anaesthesia.oedema"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                        <td class="col-8">PP</td>
                                                        <td class="col-4" [textContent]="anaesthesia.pp"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>

                                        <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia && anaesthesia.mouthOpening || anaesthesia.detention && anaesthesia.detention.length >0|| anaesthesia &&  anaesthesia.tmj|| anaesthesia && anaesthesia.neckExtention||anaesthesia && anaesthesia.grade|| anaesthesia && anaesthesia.spineGrade|| anaesthesia && anaesthesia.asaGrade
              ||anaesthesia && anaesthesia.difficultIntubation||anaesthesia && anaesthesia.difficultRegional)">
                                            <div class="col-12">
                                                <h6 class="font-18 mt-0 mb-2">Airway Assessment</h6>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr *ngIf="anaesthesia && anaesthesia.mouthOpening">
                                                        <td class="col-8">Mouth Opening</td>
                                                        <td class="col-4" [textContent]="anaesthesia.mouthOpening"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.detention.length >0">
                                                        <td class="col-8">Dentition </td>
                                                        <td class="col-4" [textContent]="anaesthesia.detention"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.tmj">
                                                        <td class="col-8">TMJ</td>
                                                        <td class="col-4" [textContent]="anaesthesia.tmj"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.neckExtention">
                                                        <td class="col-8">
                                                            Neck Extention
                                                        </td>
                                                        <td class="col-4" [textContent]="anaesthesia.neckExtention"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.grade">
                                                        <td class="col-8"> Mallampati Grade</td>
                                                        <td class="col-4" [textContent]="anaesthesia.grade"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.spineGrade">
                                                        <td class="col-8">Spine Grade</td>
                                                        <td class="col-4" [textContent]="anaesthesia.spineGrade"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.asaGrade">
                                                        <td class="col-8">ASA Grade</td>
                                                        <td class="col-4" [textContent]="anaesthesia.asaGrade"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.difficultIntubation">
                                                        <td class="col-8">Difficult Intubation</td>
                                                        <td class="col-4" [textContent]="anaesthesia.difficultIntubation"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.difficultRegional">
                                                        <td class="col-8">Difficult Regional</td>
                                                        <td class="col-4" [textContent]="anaesthesia.difficultRegional"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>

                                        <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia && anaesthesia.cvs || anaesthesia &&  anaesthesia.endocrine|| anaesthesia && anaesthesia.cns|| anaesthesia && anaesthesia.rs||anaesthesia.renal||anaesthesia && anaesthesia.git|| anaesthesia.currentMed && anaesthesia.currentMed.length >0
              ||anaesthesia && anaesthesia.others||anaesthesia && anaesthesia.pastAnaesthesia)">
                                            <div class="col-12">
                                                <h6 class="font-18 mt-0 mb-2">System Based Assessment</h6>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr *ngIf="anaesthesia && anaesthesia.cvs">
                                                        <td class="col-8">CVS</td>
                                                        <td class="col-4" [textContent]="anaesthesia.cvs"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.endocrine">
                                                        <td class="col-8">Endocrine </td>
                                                        <td class="col-4" [textContent]="anaesthesia.endocrine"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.cns">
                                                        <td class="col-8">CNS /Musculo Skeletal</td>
                                                        <td class="col-4" [textContent]="anaesthesia.cns"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.rs">
                                                        <td class="col-8">
                                                            RS
                                                        </td>
                                                        <td class="col-4" [textContent]="anaesthesia.rs"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.renal">
                                                        <td class="col-8"> Renal</td>
                                                        <td class="col-4" [textContent]="anaesthesia.renal"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.git">
                                                        <td class="col-8">GIT /Hematology</td>
                                                        <td class="col-4" [textContent]="anaesthesia.git"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia.currentMed && anaesthesia.currentMed.length >0">
                                                        <td class="col-8">Current Med</td>
                                                        <td class="col-4" [textContent]="anaesthesia.currentMed"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.others">
                                                        <td class="col-8">Others</td>
                                                        <td class="col-4">
                                                            <span class="wordBreak" [textContent]="anaesthesia.others"></span>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.pastAnaesthesia">
                                                        <td class="col-8">Past Anaesthesia Exposure</td>
                                                        <td class="col-4">
                                                            <span class="wordBreak" [textContent]="anaesthesia.pastAnaesthesia"></span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>

                                        <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia && anaesthesia.prbsAvailability || anaesthesia && anaesthesia.prbsReserve|| anaesthesia && anaesthesia.prbsIssue)">
                                            <div class="col-12">
                                                <h6 class="font-18 mt-0 mb-2">PRBC</h6>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr *ngIf="anaesthesia && anaesthesia.prbsAvailability">
                                                        <td class="col-8">Availability</td>
                                                        <td class="col-4" [textContent]="anaesthesia.prbsAvailability"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.prbsReserve">
                                                        <td class="col-8">Reserve </td>
                                                        <td class="col-4" [textContent]="anaesthesia.prbsReserve"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.prbsIssue">
                                                        <td class="col-8">Issue</td>
                                                        <td class="col-4" [textContent]="anaesthesia.prbsIssue"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia && anaesthesia.ffpAvailability || anaesthesia && anaesthesia.ffpReserve|| anaesthesia && anaesthesia.ffpIssue)">
                                            <div class="col-12">
                                                <h6 class="font-18 mt-0 mb-2">FFP</h6>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr *ngIf="anaesthesia && anaesthesia.ffpAvailability">
                                                        <td class="col-8">Availability</td>
                                                        <td class="col-4" [textContent]="anaesthesia.ffpAvailability"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.ffpReserve">
                                                        <td class="col-8">Reserve </td>
                                                        <td class="col-4" [textContent]="anaesthesia.ffpReserve"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.ffpIssue">
                                                        <td class="col-8">Issue</td>
                                                        <td class="col-4" [textContent]="anaesthesia.ffpIssue"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia && anaesthesia.rdpAvailability || anaesthesia && anaesthesia.rdpReserve|| anaesthesia && anaesthesia.rdpIssue)">
                                            <div class="col-12">
                                                <h6 class="font-18 mt-0 mb-2">RDP</h6>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr *ngIf="anaesthesia && anaesthesia.rdpAvailability">
                                                        <td class="col-8">Availability</td>
                                                        <td class="col-4" [textContent]="anaesthesia.rdpAvailability"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.rdpReserve">
                                                        <td class="col-8">Reserve </td>
                                                        <td class="col-4" [textContent]="anaesthesia.rdpReserve"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.rdpIssue">
                                                        <td class="col-8">Issue</td>
                                                        <td class="col-4" [textContent]="anaesthesia.rdpIssue"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia && anaesthesia.donorScreen || anaesthesia && anaesthesia.onAgitator|| anaesthesia && anaesthesia.issue||anaesthesia && anaesthesia.mayBePosted||anaesthesia && anaesthesia.reviewPac|| (anaesthesia && anaesthesia.instructions && anaesthesia.instructions.length >0) ||anaesthesia && anaesthesia.anaesthesiaPlan
              ||anaesthesia.specificPost||anaesthesia.date||anaesthesia.time)">
                                            <div class="col-12">
                                                <h6 class="font-18 mt-0 mb-2">SDP</h6>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr *ngIf="anaesthesia && anaesthesia.donorScreen">
                                                        <td class="col-8">Donor Screen</td>
                                                        <td class="col-4" [textContent]="anaesthesia.donorScreen"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.onAgitator">
                                                        <td class="col-8">On Agitator </td>
                                                        <td class="col-4" [textContent]="anaesthesia.onAgitator"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.issue">
                                                        <td class="col-8"> Issue </td>
                                                        <td class="col-4" [textContent]="anaesthesia.issue"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.mayBePosted">
                                                        <td class="col-8"> May Be Posted</td>
                                                        <td class="col-4" [textContent]="anaesthesia.mayBePosted"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.reviewPac">
                                                        <td class="col-8">Review PAC</td>
                                                        <td class="col-4" [textContent]="anaesthesia.reviewPac"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.instructions.length >0">
                                                        <td class="col-8">Pre OP Instructions</td>
                                                        <td class="col-4" style=" white-space: normal; word-break: break-all;">
                                                            <ul class="dashboard-ul row">
                                                                <li *ngFor="let item of anaesthesia.instructions" [textContent]="item"></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.anaesthesiaPlan">
                                                        <td class="col-8">Anaesthesia Plan</td>
                                                        <td class="col-4" [textContent]="anaesthesia.anaesthesiaPlan"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.specificPost">
                                                        <td class="col-8">Specific Post OP Instructions</td>
                                                        <td class="col-4" [textContent]="anaesthesia.specificPost"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.date">
                                                        <td class="col-8">Date</td>
                                                        <td class="col-4" [textContent]="anaesthesia.date | date:'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.time">
                                                        <td class="col-8">Time</td>
                                                        <td class="col-4" [textContent]="anaesthesia.time"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia && anaesthesia.hbPcv || anaesthesia && anaesthesia.hiv|| anaesthesia && anaesthesia.fbs||anaesthesia && anaesthesia.scr||anaesthesia && anaesthesia.na||anaesthesia && anaesthesia.sbill|| anaesthesia && anaesthesia.pt
              || anaesthesia && anaesthesia.wbc||anaesthesia && anaesthesia.hbsag|| anaesthesia && anaesthesia.plbs||anaesthesia && anaesthesia.bun||anaesthesia && anaesthesia.kplus||anaesthesia && anaesthesia.sgpt|| anaesthesia && anaesthesia.aptt||anaesthesia && anaesthesia.plat|| anaesthesia && anaesthesia.hcv|| anaesthesia && anaesthesia.rbs|| anaesthesia && anaesthesia.bdUrea
              || anaesthesia && anaesthesia.clMinus|| anaesthesia && anaesthesia.ldh|| anaesthesia && anaesthesia.inr|| anaesthesia && anaesthesia.lastothers)">
                                            <div class="col-12">
                                                <h6 class="font-18 mt-0 mb-2">Investigations</h6>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr *ngIf="anaesthesia.hbPcv">
                                                        <td class="col-8">Hb/PCV</td>
                                                        <td class="col-4" [textContent]="anaesthesia.hbPcv"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.hiv">
                                                        <td class="col-8">HIV </td>
                                                        <td class="col-4" [textContent]="anaesthesia.hiv"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.fbs">
                                                        <td class="col-8"> FBS </td>
                                                        <td class="col-4" [textContent]="anaesthesia.fbs"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.scr">
                                                        <td class="col-8"> S Cr</td>
                                                        <td class="col-4" [textContent]="anaesthesia.scr"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.na">
                                                        <td class="col-8">Na+</td>
                                                        <td class="col-4" [textContent]="anaesthesia.na"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.sbill">
                                                        <td class="col-8">S Bill</td>
                                                        <td class="col-4" [textContent]="anaesthesia.sbill"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.pt">
                                                        <td class="col-8">PT</td>
                                                        <td class="col-4" [textContent]="anaesthesia.pt"> </td>
                                                    </tr>
                                                    <tr *ngIf=" anaesthesia && anaesthesia.wbc">
                                                        <td class="col-8">WBC</td>
                                                        <td class="col-4" [textContent]="anaesthesia.wbc"> </td>
                                                    </tr>
                                                    <tr *ngIf=" anaesthesia && anaesthesia.hbsag">
                                                        <td class="col-8">HbSAg</td>
                                                        <td class="col-4" [textContent]="anaesthesia.hbsag"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.plbs">
                                                        <td class="col-8">PLBS</td>
                                                        <td class="col-4" [textContent]="anaesthesia.plbs"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.bun">
                                                        <td class="col-8">BUN</td>
                                                        <td class="col-4" [textContent]="anaesthesia.bun"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.kplus">
                                                        <td class="col-8">K+</td>
                                                        <td class="col-4" [textContent]="anaesthesia.kplus"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.sgpt">
                                                        <td class="col-8">SGPT</td>
                                                        <td class="col-4" [textContent]="anaesthesia.sgpt"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.aptt">
                                                        <td class="col-8">APTT</td>
                                                        <td class="col-4" [textContent]="anaesthesia.aptt"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.plat">
                                                        <td class="col-8">Plat</td>
                                                        <td class="col-4" [textContent]="anaesthesia.plat"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.hcv">
                                                        <td class="col-8">HCV</td>
                                                        <td class="col-4" [textContent]="anaesthesia.hcv"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.rbs">
                                                        <td class="col-8">RBS</td>
                                                        <td class="col-4" [textContent]="anaesthesia.rbs"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.bdUrea">
                                                        <td class="col-8">Bd Urea</td>
                                                        <td class="col-4" [textContent]="anaesthesia.bdUrea"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.clMinus">
                                                        <td class="col-8">Cl-</td>
                                                        <td class="col-4" [textContent]="anaesthesia.clMinus"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.ldh">
                                                        <td class="col-8">LDH</td>
                                                        <td class="col-4" [textContent]="anaesthesia.ldh"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.inr">
                                                        <td class="col-8">INR</td>
                                                        <td class="col-4" [textContent]="anaesthesia.inr"> </td>
                                                    </tr>
                                                    <tr *ngIf="anaesthesia && anaesthesia.lastothers">
                                                        <td class="col-8">Others</td>
                                                        <td class="col-4" [textContent]="anaesthesia.lastothers"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dashboard-box dashboard-table-box" #aldretescoreSection *ngIf="aldreteScore && aldreteScore !== null">
                            <h4 class="dashboard-title">Aldrete Score </h4>
                            <div class="dashboard-body">
                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                    <tr>
                                        <th class="white-space-break">
                                            Post Anaesthesia Score: Criteria For Discharge From ICU/POW
                                            A Total Discharge Score Of 8-10 Is Necessary For Discharge To Be Filled By Doctors Definition
                                        </th>
                                    </tr>
                                    <tr *ngIf="aldreteScore.activity">
                                        <td class="col-8">Activity</td>
                                        <td class="col-4" [textContent]="aldreteScore.activity"> </td>
                                    </tr>
                                    <tr *ngIf="aldreteScore.respiration">
                                        <td class="col-8">Respiration</td>
                                        <td class="col-4" [textContent]="aldreteScore.respiration"> </td>
                                    </tr>
                                    <tr *ngIf="aldreteScore.circulation">
                                        <td class="col-8">Circulation</td>
                                        <td class="col-4" [textContent]="aldreteScore.circulation"> </td>
                                    </tr>
                                    <tr *ngIf="aldreteScore.consciousness">
                                        <td class="col-8">Consciousness</td>
                                        <td class="col-4" [textContent]="aldreteScore.consciousness"> </td>
                                    </tr>
                                    <tr *ngIf="aldreteScore.oxygensaturation">
                                        <td class="col-8">Oxygen Saturation</td>
                                        <td class="col-4" [textContent]="aldreteScore.oxygensaturation"> </td>
                                    </tr>
                                    <tr *ngIf="aldreteScore.totalscore">
                                        <td class="col-8">Total Score</td>
                                        <td class="col-4" [textContent]="aldreteScore.totalscore"> </td>
                                    </tr>
                                    <tr *ngIf="aldreteScore.scoreDoneBy">
                                        <td class="col-8">Score Done By</td>
                                        <td class="col-4" [textContent]="aldreteScore.scoreDoneBy"> </td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>


                </ng-container>

                <ng-container *ngIf="!page.loading && fullTranscript && fullTranscript.appointmentId > 0">

                    <div class="row topHeading">
                        <div class="col-12 pl-3">
                            <div class="row mb-0">
                                <label class="mb-0 text-mute">Prescribed By</label>
                            </div>
                            <div class="row" *ngIf="fullTranscript.signature">
                                <img [src]="fullTranscript.signature" alt="clinic logo" class="img-fluid" style="height: 100px !important; width: 100px !important;" />
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <address>
                                        <h5 class="font-family-primary font-weight-bold" [textContent]="fullTranscript.providerName"></h5>
                                        <h6 class="font-weight-light font-14" [textContent]="fullTranscript.specializationName"></h6>
                                        <h6 class="font-weight-light font-14" [textContent]="fullTranscript.practiceName"></h6>
                                        <!--<h6 *ngIf="fullTranscript.providerNo" class="font-weight-light font-16" [textContent]="'License No: '+ fullTranscript.providerNo"></h6>-->
                                        <h6 class="font-weight-normal font-14" [textContent]="fullTranscript.practiceLocation"></h6>
                                    </address>
                                </div><div class="col-md-4"></div>
                                <div class="col-md-4">
                                    <div class="row">
                                        <div *ngIf="iconBasics != null">
                                            <!-- <img [src]="iconBasics.imagePath" [hidden]="loading" alt="icon" style="width: 100%" />-->
                                            <img [src]="safe(appointment.signature)" class="img-fluid" style="height: 100px !important; width: 100px !important;" />
                                        </div>
                                        <!--<img src="assets/images/practice-logo.jpg" alt="clinic logo" class="img-fluid" style="height: 100px !important; width: 200px !important;" />-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><hr />
                </ng-container>
                <div *ngIf="isFooter">
                    <banner-setting [reportName]='"Gyn Encounter Full Transcript Footer"'></banner-setting>
                </div>
            </div>
        </div>
    </div>
</div>