export class LabReceiveModel {
    labTransferHeaderId: number;
    transferNumber: string;
    transferedBy: number;
    transferedDate: Date;
    transferedLocationId: number;
    receivedBy: number;
    receivedDate: Date;
    transferedByName: string;
    receivedByName: string;
    transferedToLocation: string;
    totalItems: number;
    labs: Array<LabReceiveDetailModel>;
    isShow: boolean;
    isReceiveing: boolean;
    isSelected: boolean;
    transferredTemperature?: number;
    transferredTemperatureComments: string;
    disabled: boolean;
    constructor() {
        this.labs = new Array<LabReceiveDetailModel>();
    }
}

export class LabReceiveDetailModel {
    labTransferDetailId: number;
    labTransferHeaderId: number;
    newLabBookingDetailId: number;
    locationId: number;
    testName: string;
    testCode: string;
    requisitionNumber: string;
    createdLocationName: string;
    bookedDate: Date;
    collectionDate: Date;
    rowColor: string;
    newLabBookingHeaderId: number;
    subCollectionDate?: Date;
    sampleName: string;
    isSelected: boolean;
    departmentName: string;
    labVacutainerName: string;
    sampleUsage: string;
    PatientName: string;
    umrNo: string;
    mobile: string;
    messageCount: number;
    labSampleCollectionDetailId: number;
    sampleID: string;
    emergency: boolean;
    quickReport: boolean;
    disabled: boolean;
    age?: number;
}