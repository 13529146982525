<div class="content" style="background-color:white">
    <div class="container-fluid">
        <div class="d-flex align-content-between">
            <div class="col-6">
                <h1 style="color: #fbde06; margin-left: 30px">Labour Ward Handover</h1>
            </div>
            <div class="col-2" style="margin-top:20px">
                Date : 2024-11-05
            </div>
            <div class="col-4">
                <banner-setting [reportName]='"Lab Invoice Header"'></banner-setting>
            </div>
        </div>
        <!--<div class="row">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading...</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mh-300">
                <div class="no-data">
                    <img src="assets/images/no-data.png" alt="No data" />
                    <h4 class="title">Oops! No Data found.</h4>
                    <p class="sub-title">There is no data to show you right now.</p>
                </div>
            </div>
        </div>-->
        <div class="d-flex">
            <div class="col-8">
                <div class="d-flex justify-content-md-between">
                    <div class="table-responsive queue-parent mx-2">
                        <table class="table table-bordered table-striped mb-0">
                            <thead>
                                <tr style="background-color: #fbde06 ">
                                    <th class="text-center"></th>
                                    <th class="text-center">Patient / Consultant Name</th>
                                    <th class="text-center">Diagnosis / Risk Factors</th>
                                    <th class="text-center">Last VE</th>
                                    <th class="text-center">Plan Of Management</th>
                                    <th class="text-center">Pain Relief</th>
                                    <th class="text-center">Blood Group</th>
                                    <th class="text-center">Care by</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>LDR1</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>LDR2</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>LDR3</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr style="background-color: #fbde06;">
                                    <th colspan="8" style="text-align:center">Midwifery Led Unit</th>
                                </tr>
                                <tr>
                                    <td>HDU1</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>HDU2</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>HDU3</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <p class="break-all" style="font-size:18px;margin-left:10px">
                    The handover Should take place using the mnemonic "SHARING" - Staffing ,
                    High-risk(LW/HDU),Awaiting OT list,Recovery Room(ICU/POW),Inductions,Neonatal and Gynaec Handover.
                    Time of HandOver is 8.30 am and 4.30 pm.
                </p>
            </div>
            <div class="col-4" style="margin-top:45px">
                <div>
                    <table class="col-12 table table-striped">
                        <tbody>
                            <tr class="col-12">
                                <td class="col-5">
                                    <span>Staffing</span>
                                </td>
                                <td class="col-1">
                                    <span>:</span>
                                </td>
                                <td class="col-6">
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr class="col-12">
                                <td class="col-5">
                                    <span>Consultant </span>
                                </td>
                                <td class="col-1">
                                    <span>:</span>
                                </td>
                                <td class="col-6">
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr class="col-12">
                                <td class="col-5">
                                    <span>LR </span>
                                </td>
                                <td class="col-1">
                                    <span>:</span>
                                </td>
                                <td class="col-6">
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr class="col-12">
                                <td class="col-5">
                                    <span>HDU </span>
                                </td>
                                <td class="col-1">
                                    <span>:</span>
                                </td>
                                <td class="col-6">
                                    <span>data</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style="background-color: #fbde06;margin-top:-30px">
                    <div class="d-flex align-content-lg-around">
                        <div class="col-11">
                            <h4>Awaiting OT List</h4>
                        </div>
                        <div class="col-1">
                            <span style="color:white;"> &nbsp;</span>
                        </div>
                    </div>
                </div>
                <div>
                    <table class="col-12 table table-striped table-black-border">
                        <tbody>
                            <tr>
                                <td>
                                    <span>1</span>
                                </td>
                                <td>
                                    <span>2</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>3</span>
                                </td>
                                <td>
                                    <span>4</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table class="col-12 table table-striped table-black-border">
                        <thead>
                            <tr style="background-color: #fbde06;margin-top:-20px">
                                <th>Induction Of Labour</th>
                                <th>P</th>
                                <th>C</th>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span>1</span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>2</span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>3</span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>4</span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>5</span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>



                <div style="background-color: #fbde06;margin-top:-20px">
                    <div class="d-flex align-content-lg-around">
                        <div class="col-11">
                            <h4>Neonatal Follow-Up</h4>
                        </div>
                        <div class="col-1">
                            <span style="color:white;"> &nbsp;</span>
                        </div>
                    </div>
                </div>
                <div>
                    <table class="col-12 table table-striped table-black-border">
                        <tbody>
                            <tr>
                                <td>
                                    <span>1</span>
                                </td>
                                <td>
                                    <span>2</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div style="background-color: #fbde06;margin-top:-20px">
                    <div class="d-flex align-content-lg-around">
                        <div class="col-11">
                            <h4>ICU/POW</h4>
                        </div>
                        <div class="col-1">
                            <span style="color:white;"> &nbsp;</span>
                        </div>
                    </div>
                </div>
                <div>
                    <table class="col-12 table table-striped table-black-border">
                        <tbody>
                            <tr>
                                <td>
                                    <span>1</span>
                                </td>
                                <td>
                                    <span>2</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div style="background-color: #fbde06;margin-top:-20px">
                    <div class="d-flex align-content-lg-around">
                        <div class="col-11">
                            <h4>Ward</h4>
                        </div>
                        <div class="col-1">
                            <span style="color:white;"> &nbsp;</span>
                        </div>
                    </div>
                </div>
                <div>
                    <table class="col-12 table table-striped table-black-border">
                        <tbody>
                            <tr>
                                <td>
                                    <span>1</span>
                                </td>
                                <td>
                                    <span>2</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>1</span>
                                </td>
                                <td>
                                    <span>2</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>1</span>
                                </td>
                                <td>
                                    <span>2</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-flex" style="font-size:18px">
                    <div class="col-5">
                        Total No.Of Admissions
                    </div>
                    <div class="col-1">
                        :
                    </div>
                    <div class="col-6">
                        data
                    </div>
                </div>
                <div style="font-size:18px">
                    <table class="col-12 table table-striped table-black-border">
                        <tbody>
                            <tr>
                                <td>
                                    <span>Total No.Of Birth </span>
                                </td>
                                <td>
                                    <span>SVB </span>
                                </td>
                                <td>
                                    <span>AVB</span>
                                </td>
                                <td>
                                    <span>CB</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Obstetric Unit</span>
                                </td>
                                <td>
                                    <span>2</span>
                                </td>
                                <td>
                                    <span>1</span>
                                </td>
                                <td>
                                    <span>2</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Midwifery Led Unit</span>
                                </td>
                                <td>
                                    <span>2</span>
                                </td>
                                <td>
                                    <span>1</span>
                                </td>
                                <td>
                                    <span>2</span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    Transfer From MLU to OU
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
