
import { HttpService } from '../services/http.service';
import { NotifyService } from '../services/notify.service';
import { ApiResources } from "../helpers";
import { Setting } from "@shared/entities";

export class SmsService {

    smsServiceActive: boolean = null;
    emailServiceActive: boolean = null;
    constructor() {

    }

    getCommonSettings(httpService: HttpService,serviceName:string) {

        httpService.get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), { name: serviceName })
            .subscribe(async (response: Array<Setting>) => {
                if (response.length > 0) {

                    if (serviceName == "SmsService")
                        this.smsServiceActive = await response[0].active;
                    else
                        this.emailServiceActive = await response[0].active;

                }
            }, (error: any) => { console.log(error) });

        if (serviceName == 'SmsService') {return this.smsServiceActive;}

        return this.emailServiceActive;

    }

    sendMessageAsync(body: Record<any, any>, httpService: HttpService, notifyService: NotifyService) {

        if (this.emailServiceActive ==null)
        {
            this.getCommonSettings(httpService, "SmsService")

        }

        else if (this.smsServiceActive == true) {

            const settings = JSON.parse(localStorage.getItem("settings"));
            var url = settings.whatsAppService.base + "sendSms";
            var contactNumber = settings.whatsAppService.contactNumber;
            var foundationName = settings.whatsAppService.practiceName;

            body['FoundationName'] = foundationName;
            body['PracticeName'] = foundationName;
            body["ContactNumber"] = contactNumber;

            httpService.post(url, body).subscribe((response: any) => {
                notifyService.successToast("The  message has sent !!")
            },
                (error: any) => {
                    notifyService.errorToast("Could not send  message" + error); console.log(error)
                });

        }
    }


    sendEmailAsync(body: Record<any, any>, httpService: HttpService, notifyService: NotifyService) {


        if (this.smsServiceActive == null) {
            this.getCommonSettings(httpService, "EmailService")

        }
        else if (this.emailServiceActive == true) {
            const settings = JSON.parse(localStorage.getItem("settings"));
            var url = settings.whatsAppService.base + "SendEmail";
            var contactNumber = settings.whatsAppService.contactNumber;
            var foundationName = settings.whatsAppService.practiceName;
            var Source = settings.EmailService.AppointmentEmail;
            var Passcode = settings.EmailService.AppointmentCode;


            body['FoundationName'] = foundationName;
            body['PracticeName'] = foundationName;
            body["ContactNumber"] = contactNumber;
            body["Source"] = Source;
            body["Passcode"] = Passcode;

            httpService.post(url, body).subscribe((response: any) => {
                notifyService.successToast("The  message has sent !!")
            },
                (error: any) => {
                    notifyService.errorToast("Could not send  message" + error); console.log(error)
                });
        }
    }
}