<div class="  mt-1 scroll-obsecNotHeading text-black">
    <div class="container image_section">
        <button class="btn btn-info" type="button" (click)="onChangePrintType( false)">
            <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
        </button>
        <button class="btn btn-info" type="button" (click)="onChangePrintType(true)">
            <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
        </button>
        <button class="btn btn-info" type="button" (click)="onChangeFooter( false)">
            <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
        </button>
        <button class="btn btn-info" type="button" (click)="onChangeFooter(true)">
            <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
        </button>
    </div>
    <div class="container image_section">
        <div class="card-box mb-0">
            <div class="clearfix mb-3">

                <div class="float-right row">
                    <h4 class="m-0 d-print-none heading2">Diet Plan Encounter Full Transcript</h4>
                    <button [useExistingCss]="true" printSectionId="gynprint" ngxPrint class="close d-print-none"><i class="fe-printer" placement="left" ngbTooltip="Print"></i></button>
                </div>
            </div>
            <div class="row" *ngIf="page.loading">
                <div class="col-12">
                    <div class="d-flex align-items-center">
                        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                        <span class="ml-2">Please wait while generating Diet Full Transcript ...</span>
                    </div>
                </div>
            </div>
            <div id="gynprint">
                <ng-container *ngIf="fullTranscript">
                    <div class="col-12" *ngIf="isPrintLogo">
                        <banner-setting [reportName]='"Diet Full Transcript Report header"'></banner-setting>
                    </div><hr />
                    <div class="d-flex justify-content-between heading1 white-space-nowrap">
                        <div class="col-6 p-0">
                            <div class="text-truncate">
                                <label>Patient Name:</label>
                                <span class="pl-1 text-uppercase" [textContent]="fullTranscript.patientName"></span>
                            </div>
                            <div class="text-truncate" *ngIf="fullTranscript.patientMobile">
                                <label>Mobile No:</label>
                                <span class="pl-1" [textContent]="fullTranscript.patientMobile + ' / '"></span>
                                <span class="pl-1" *ngIf="fullTranscript.patientGender" [textContent]="(fullTranscript.patientGender | gender) + ' / '"></span>
                                <span class="pl-1" *ngIf="fullTranscript.patientAge" [textContent]="fullTranscript.patientAge +' yrs'"></span>
                                <span class="pl-1" *ngIf="!fullTranscript.patientAge" [textContent]="'0 yrs' "></span>
                            </div>
                        </div>
                        <div class="d-inline-block" *ngIf="isAdmission==false">
                            <div>
                                <label>Appointment No:</label>
                                <span class="pl-1">{{fullTranscript.appointmentNo}}</span>
                            </div>
                            <div>
                                <label>Appointment Date:</label>
                                <span class="pl-1" [textContent]="(fullTranscript.appointmentDate |  date :'dd/MM/yyyy') + ' ' + fullTranscript.appointmentTimeString"></span>
                            </div>

                        </div>
                        <div class="d-inline-block" *ngIf="isAdmission==true">
                            <div>
                                <label>Admission No:</label>
                                <span class="pl-1">{{fullTranscript.admissionId}}</span>
                            </div>
                            <div>
                                <label>Admission Date:</label>
                                <span class="pl-1" [textContent]="(fullTranscript.admissionDate |  date :'dd/MM/yyyy') + ' ' + fullTranscript.appointmentTimeString"></span>
                            </div>

                        </div>
                    </div><hr />
                </ng-container>
                <ng-container *ngIf="!page.loading && fullTranscript">
                    <div class="col-12 textOfEveryVw" *ngIf="!page.loading && fullTranscript">

                        <div>
                            <div class="dashboard-box dashboard-table-box" #meausreSection *ngIf="measure && measure !== null">
                                <h4 class="dashboard-title">Measures</h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <div *ngIf="measure && measure !== null">
                                            <div class="row">
                                                <div class="col-12">
                                                    <h6 class="font-14 mt-0 mb-2">Measures</h6>
                                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                        <tr *ngIf="measure.visitNo">
                                                            <td class="col-8">Visit Number</td>
                                                            <td class="col-4" [textContent]="measure.visitNo"> </td>
                                                        </tr>
                                                        <tr *ngIf="measure.department">
                                                            <td class="col-8">Department</td>
                                                            <td class="col-4" [textContent]="measure.department"> </td>
                                                        </tr>
                                                        <tr *ngIf="measure.heightInFeet">
                                                            <td class="col-8">Height In Feet</td>
                                                            <td class="col-4" [textContent]="measure.heightInFeet"> </td>
                                                        </tr>
                                                        <tr *ngIf="measure.heightInMeters">
                                                            <td class="col-8">Height In Meters</td>
                                                            <td class="col-4" [textContent]="measure.heightInMeters"> </td>
                                                        </tr>
                                                        <tr *ngIf="measure.weight">
                                                            <td class="col-8">Weight (Kg)</td>
                                                            <td class="col-4" [textContent]="measure.weight"> </td>
                                                        </tr>
                                                        <tr *ngIf="measure.bodyMassIndex">
                                                            <td class="col-8">Body Mass Index (BMI)</td>
                                                            <td class="col-4" [textContent]="measure.bodyMassIndex"> </td>
                                                        </tr>
                                                        <tr *ngIf="measure.bmiCategorization">
                                                            <td class="col-8">BMI Categorization</td>
                                                            <td class="col-4" [textContent]="measure.bmiCategorization"> </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="dashboard-box" #dietPlanPrescribedSection *ngIf="dietPlanPrescribed && dietPlanPrescribed!== null">
                            <h4 class="dashboard-title">Diet Plan Prescribed</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div>
                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr><h5>Diet Plan Prescribed</h5></tr>
                                            <tr *ngIf="dietPlanPrescribed.titles">
                                                <td class="col-8">Titles</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.titles"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.date">
                                                <td class="col-8">Date</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.date | amDateFormat:'DD/MM/YYYY'"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.birthWeight && dietPlanPrescribed.dietType == 'PEDIATRIC'">
                                                <td class="col-8">Birth Weight</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.birthWeight"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.height">
                                                <td class="col-8">Height</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.height"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.termPreterm && dietPlanPrescribed.dietType == 'PEDIATRIC'">
                                                <td class="col-8">Term / Preterm</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.termPreterm"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.lgaSga && dietPlanPrescribed.dietType == 'PEDIATRIC'">
                                                <td class="col-8">LGA / SGA</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.lgaSga"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.headCircumferenc && dietPlanPrescribed.dietType == 'PEDIATRIC'">
                                                <td class="col-8">Head Circumference</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.headCircumferenc"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.percentileWeight && dietPlanPrescribed.dietType == 'PEDIATRIC'">
                                                <td class="col-8">Percentile (Weight)</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.percentileWeight"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.percentileHeightLenght && dietPlanPrescribed.dietType == 'PEDIATRIC'">
                                                <td class="col-8">Percentile (Height / Lenght)</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.percentileHeightLenght"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.conditionDisease && dietPlanPrescribed.dietType == 'PEDIATRIC'">
                                                <td class="col-8">Condition /Disease</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.conditionDisease"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.heightCentile && dietPlanPrescribed.dietType == 'PEDIATRIC'">
                                                <td class="col-8">Height Centile</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.heightCentile"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.heightInMeter && dietPlanPrescribed.dietType !== 'PEDIATRIC' ">
                                                <td class="col-8">Height In Meter</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.heightInMeter"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.dietType">
                                                <td class="col-8">Diet Type</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.dietType"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.patientType">
                                                <td class="col-8">Patient Type</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.patientType"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.initialWeight && dietPlanPrescribed.dietType == 'GYN'">
                                                <td class="col-8">Initial Weight</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.initialWeight"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.prePregnancy1 && dietPlanPrescribed.dietType == 'OBS'">
                                                <td class="col-8">Pre-Pregnancy Wt</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.prePregnancy1"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.prePregnancy && dietPlanPrescribed.dietType == 'OBS'">
                                                <td class="col-8">Pre-Pregnancy BMI</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.prePregnancy"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.presentWeight">
                                                <td class="col-8">Present Weight</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.presentWeight"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.weightCentile && dietPlanPrescribed.dietType == 'PEDIATRIC'">
                                                <td class="col-8">Weight Centile</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.weightCentile"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.bmi && dietPlanPrescribed.dietType !== 'PEDIATRIC'">
                                                <td class="col-8">BMI</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.bmi"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.obstetricStatus && dietPlanPrescribed.dietType == 'OBS'">
                                                <td class="col-8">Obstetric Status</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.obstetricStatus"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.weightLoss">
                                                <td class="col-8">Weight Loss (kgs)</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.weightLoss"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.weightGain">
                                                <td class="col-8">Weight Gain (kgs)</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.weightGain"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.conditionPaeda && dietPlanPrescribed.dietType == 'PEDIATRIC'">
                                                <td class="col-8">Condition Paeda</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.conditionPaeda"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.conditionGyn && dietPlanPrescribed.dietType == 'GYN'">
                                                <td class="col-8">Condition Gyn</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.conditionGyn"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.conditionGen && dietPlanPrescribed.dietType == 'GENERAL'">
                                                <td class="col-8">Condition General</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.conditionGen"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.conditionObs && dietPlanPrescribed.dietType == 'OBS'">
                                                <td class="col-8">Condition OBS</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.conditionObs"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.condition2 && dietPlanPrescribed.dietType == 'OBS'">
                                                <td class="col-8">Condition -2</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.condition2"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.otherCondition">
                                                <td class="col-8">Other Condition</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.otherCondition"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.riskfactor">
                                                <td class="col-8">Risk factors (Others)</td>
                                                <td class="col-4 " [textContent]="dietPlanPrescribed.riskfactor"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.dietPlanPrescribe">
                                                <td class="col-8">Diet Plan Prescribed</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.dietPlanPrescribe"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.counsellingNot">
                                                <td class="col-8">Counselling Notes</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.counsellingNot"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.visitNo">
                                                <td class="col-8">Visit No</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.visitNo"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.haemoglobin">
                                                <td class="col-8">Haemoglobin</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.haemoglobin"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.date1">
                                                <td class="col-8">Date</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.date1 | amDateFormat:'DD/MM/YYYY'"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.oGTTDate && dietPlanPrescribed.dietType == 'OBS'">
                                                <td class="col-8">OGTT Date</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.oGTTDate | amDateFormat:'DD/MM/YYYY' "> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.pgFasting && dietPlanPrescribed.dietType == 'OBS'">
                                                <td class="col-8">P.G.Fasting</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.pgFasting"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.firstHour && dietPlanPrescribed.dietType !== 'PEDIATRIC'">
                                                <td class="col-8">1st Hour</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.firstHour"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.secondHour && dietPlanPrescribed.dietType !== 'PEDIATRIC'">
                                                <td class="col-8">2nd Hour</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.secondHour"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.fbs && dietPlanPrescribed.dietType !== 'PEDIATRIC'">
                                                <td class="col-8">FBS</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.fbs"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.plbs && dietPlanPrescribed.dietType !== 'PEDIATRIC'">
                                                <td class="col-8">PLBS</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.plbs"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.hbA1C && dietPlanPrescribed.dietType !== 'PEDIATRIC'">
                                                <td class="col-8">HbA1C</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.hbA1C"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.anyOther">
                                                <td class="col-8">Any Other</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.anyOther"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.outSideTests">
                                                <td class="col-8">OutSide Tests</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.outSideTests"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.specialFeatures">
                                                <td class="col-8">Special Features</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.specialFeatures"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.hb && (dietPlanPrescribed.dietType == 'PEDIATRIC' || dietPlanPrescribed.dietType == 'GYN')">
                                                <td class="col-8">HB</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.hb"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.vitB && dietPlanPrescribed.dietType == 'PEDIATRIC'">
                                                <td class="col-8">Vit - B</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.vitB"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.pcv && dietPlanPrescribed.dietType == 'PEDIATRIC'">
                                                <td class="col-8">PCV</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.pcv"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.ferritin && dietPlanPrescribed.dietType == 'PEDIATRIC'">
                                                <td class="col-8">Ferritin</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.ferritin"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.lft && dietPlanPrescribed.dietType == 'PEDIATRIC'">
                                                <td class="col-8">LFT</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.lft"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.mch && dietPlanPrescribed.dietType == 'PEDIATRIC'">
                                                <td class="col-8">MCH</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.mch"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.oilPerDay">
                                                <td class="col-8">Oil Per Day</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.oilPerDay"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.milkProducts">
                                                <td class="col-8">Milk Products</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.milkProducts"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.counselledBy">
                                                <td class="col-8">Counselled By</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.counselledBy"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.minutesPerDay">
                                                <td class="col-8">Minutes Per Day</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.minutesPerDay"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.minutesPerDay">
                                                <td class="col-8">Water Per Day</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.waterPerDay"> </td>
                                            </tr>
                                            <tr *ngIf="dietPlanPrescribed.reviewDate">
                                                <td class="col-8">Review Date</td>
                                                <td class="col-4" [textContent]="dietPlanPrescribed.reviewDate |date:'dd/MM/yyyy'"> </td>
                                            </tr>

                                        </table>

                                    </div>
                                    <div>
                                        <table *ngIf="dietPlanPrescribed.dietArray.length>0">
                                            <thead>
                                                <tr>
                                                    <th class="col-1">S.No</th>
                                                    <th class="col-5">Diet Shift Name</th>
                                                    <th class="col-6">Diet Items Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of dietPlanPrescribed.dietArray; let i=index;">
                                                    <td class="col-1"><span>{{i+1}}</span></td>
                                                    <td class="col-5"><span>{{item.dietShiftName}}</span></td>
                                                    <td class="col-6"><span class="word-break">{{item.dietItemsName}}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class=" dashboard-box dashboard-table-box" #pediatricCDCScreeingSection *ngIf="pediatricCDCScreeningForm && pediatricCDCScreeningForm!== null">
                            <h4 class="dashboard-title">Pediatric CDC Screening Form</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div>
                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr *ngIf="pediatricCDCScreeningForm.titles || pediatricCDCScreeningForm.birthWeight || pediatricCDCScreeningForm.sex || pediatricCDCScreeningForm.height || pediatricCDCScreeningForm.weight ||pediatricCDCScreeningForm.termPreterm || pediatricCDCScreeningForm.lgaSga || pediatricCDCScreeningForm.headCircumference || pediatricCDCScreeningForm.percentileWeight || pediatricCDCScreeningForm.percentileHeight || pediatricCDCScreeningForm.conditionDisease ">
                                                <h5>Pediatric CDC Screening</h5>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.titles">
                                                <td class="col-8">Titles</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.titles"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.birthWeight">
                                                <td class="col-8">Birth Weight</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.birthWeight"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.sex">
                                                <td class="col-8">Sex</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.sex"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.height">
                                                <td class="col-8">Height</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.height"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.weight">
                                                <td class="col-8">Weight</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.weight"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.termPreterm">
                                                <td class="col-8">Term/ Preterm</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.termPreterm"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.lgaSga">
                                                <td class="col-8">LGA / SGA</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.lgaSga"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.headCircumference">
                                                <td class="width-fixed">Head Circumference</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.headCircumference"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.percentileWeight">
                                                <td class="col-8">Percentile (Weight)</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.percentileWeight"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.percentileHeight">
                                                <td class="col-8">Percentile(Height/Lenght)</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.percentileHeight"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.conditionDisease">
                                                <td class="col-8">Condition/ Disease</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.conditionDisease"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.screeningDoneDate">
                                                <td class="col-8">Screening Done Date</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.screeningDoneDate  | amDateFormat:'DD/MM/YYYY'"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.hb || pediatricCDCScreeningForm.vitD ||pediatricCDCScreeningForm.pcv || pediatricCDCScreeningForm.others ||pediatricCDCScreeningForm.ferritin || pediatricCDCScreeningForm.lft ||  pediatricCDCScreeningForm.mch ||  pediatricCDCScreeningForm.foodHabit || pediatricCDCScreeningForm.ifOther ||  pediatricCDCScreeningForm.foodAllergy || pediatricCDCScreeningForm.allergyticTo || pediatricCDCScreeningForm.typeOfMilkFeed || pediatricCDCScreeningForm.animalMilk ||  pediatricCDCScreeningForm.onAnyMedication || pediatricCDCScreeningForm.anySupplements ">
                                                <h5>Bio-Chamical Parameters</h5>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.hb">
                                                <td class="col-8">Hb</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.hb"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.vitD">
                                                <td class="col-8">Vit D</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.vitD"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.pcv">
                                                <td class="col-8">PCV</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.pcv"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.others">
                                                <td class="col-8">Others</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.others"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.ferritin">
                                                <td class="col-8">Ferritin</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.ferritin"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.lft">
                                                <td class="col-8">LFT</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.lft"> </td>
                                            </tr>

                                            <tr *ngIf="pediatricCDCScreeningForm.mch">
                                                <td class="col-8">MCH</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.mch"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.foodHabit">
                                                <td class="col-8">Food Habit</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.foodHabit"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.ifOther">
                                                <td class="col-8">If Other</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.ifOther"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.foodAllergy">
                                                <td class="col-8">Food Allergy</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.foodAllergy"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.allergyticTo">
                                                <td class="col-8">If Yes / Allergic To</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.allergyticTo"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.typeOfMilkFeed">
                                                <td class="col-8">Type Of Milk Feed</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.typeOfMilkFeed"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.animalMilk">
                                                <td class="col-8">If Animal Milk</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.animalMilk"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.onAnyMedication">
                                                <td class="col-8">on Any Medication?</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.onAnyMedication"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.anySupplements">
                                                <td class="col-8">Any Supplements</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.anySupplements"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.consistancyOfFood"><h5>Basic Diet Assessment</h5></tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.consistancyOfFood">
                                                <td class="col-8">Consistency Of Food</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.consistancyOfFood"> </td>
                                            </tr>

                                            <tr *ngIf="pediatricCDCScreeningForm.ifOnBreastfeeds || pediatricCDCScreeningForm.ifOnFormulaFeeds || pediatricCDCScreeningForm.volume || pediatricCDCScreeningForm.earlyMorning || pediatricCDCScreeningForm.breakfast || pediatricCDCScreeningForm.midMorning ||
                            pediatricCDCScreeningForm.lunch || pediatricCDCScreeningForm.eveningSnack1 || pediatricCDCScreeningForm.eveningSnack2 || pediatricCDCScreeningForm.dinner || pediatricCDCScreeningForm.bedTime || pediatricCDCScreeningForm.ghee || pediatricCDCScreeningForm.screeningDoneBy || pediatricCDCScreeningForm.screeningDoneDate">
                                                <h5>24 Hours Recall</h5>
                                            </tr>

                                            <tr *ngIf="pediatricCDCScreeningForm.ifOnBreastfeeds">
                                                <td class="col-8">If On Breastfeeds(Feeds/Day)</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.ifOnBreastfeeds"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.ifOnFormulaFeeds">
                                                <td class="col-8">If On Formula Feeds(Feeds/Day)</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.ifOnFormulaFeeds"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.volume">
                                                <td class="col-8">Volume (Ml) Of Formula Feeds/Feed(Ml)</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.volume"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.earlyMorning">
                                                <td class="col-8">Early Morning</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.earlyMorning"> </td>
                                            </tr>

                                            <tr *ngIf="pediatricCDCScreeningForm.breakfast">
                                                <td class="col-8">Breakfast</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.breakfast"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.midMorning">
                                                <td class="col-8">Mid-Morning</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.midMorning"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.lunch">
                                                <td class="col-8">Lunch</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.lunch"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.eveningSnack1">
                                                <td class="col-8">Evening Snack(1)</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.eveningSnack1"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.eveningSnack2">
                                                <td class="col-8">Evening Snack(2)</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.eveningSnack2"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.dinner">
                                                <td class="col-8">Dinner</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.dinner"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.bedTime">
                                                <td class="col-8">Bedtime</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.bedTime"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.ghee">
                                                <td class="col-8">Ghee(Teaspoons)</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.ghee"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.screeningDoneBy">
                                                <td class="col-8">Screening Done By</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.screeningDoneBy"> </td>
                                            </tr>
                                            <tr *ngIf="pediatricCDCScreeningForm.screeningDoneDate">
                                                <td class="col-8">Screening Done Date</td>
                                                <td class="col-4" [textContent]="pediatricCDCScreeningForm.screeningDoneDate  | amDateFormat:'DD/MM/YYYY'"> </td>
                                            </tr>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="dashboard-box  dashboard-table-box" #opNutritionScreeningSection *ngIf="opNutritionScreeningForm && opNutritionScreeningForm!== null">
                            <h4 class="dashboard-title">OP Nutrition Screening Form</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div class="col-12 ">
                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr *ngIf="opNutritionScreeningForm.ancNo || opNutritionScreeningForm.height || opNutritionScreeningForm.heightInMts || opNutritionScreeningForm.prePregnancyWt || opNutritionScreeningForm.prePregnancyBMI || opNutritionScreeningForm.preBMICategtory || opNutritionScreeningForm.presentWeight || opNutritionScreeningForm.presentBMI || opNutritionScreeningForm.bMICategorization || opNutritionScreeningForm.gpla || opNutritionScreeningForm.gestationAge || opNutritionScreeningForm.familyHistory || opNutritionScreeningForm.medicalHistory || opNutritionScreeningForm.pastSurgicalHistory || opNutritionScreeningForm.specialFeature">
                                                <h5>OP Nutrition Screening Form</h5>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.ancNo">
                                                <td class="col-8">ANC No</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.ancNo"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.height">
                                                <td class="col-8">Height</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.height"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.heightInMts">
                                                <td class="col-8">Height In Mts</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.heightInMts"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.prePregnancyWt">
                                                <td class="col-8">Pre Pregnancy Wt</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.prePregnancyWt"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.prePregnancyBMI">
                                                <td class="col-8">Pre Preg BMI</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.prePregnancyBMI"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.preBMICategtory">
                                                <td class="col-8">Pre BMI Category</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.preBMICategtory"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.presentWeight">
                                                <td class="col-8">Present Weight</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.presentWeight"> </td>
                                            </tr>

                                            <tr *ngIf="opNutritionScreeningForm.presentBMI">
                                                <td class="col-8">Present BMI</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.presentBMI"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.bMICategorization">
                                                <td class="col-8">BMI Categorization</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.bMICategorization"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.gpla">
                                                <td class="col-8">G P L A</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.gpla"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.gestationAge">
                                                <td class="col-8">Gestation Age(Wks/days)</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.gestationAge"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.familyHistory">
                                                <td class="col-8">Family History</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.familyHistory"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.medicalHistory">
                                                <td class="col-8">Medical History</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.medicalHistory"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.pastSurgicalHistory">
                                                <td class="col-8">Past Surgical History</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.pastSurgicalHistory"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.specialFeature">
                                                <td class="col-8">Special Features</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.specialFeature"> </td>
                                            </tr>

                                        </table>
                                        <h5>Nutritional Screening Ref Modified Must Screening Tool</h5>
                                        <div class="col-12" *ngIf="opNutritionScreeningForm.opscreeningopts && opNutritionScreeningForm.opscreeningopts.length > 0">
                                            <h6 class="font-14 mt-0 mb-1">Nutritional Screening Ref Modified Must Screening Tool</h6>
                                            <ul *ngFor="let item of opNutritionScreeningForm.opscreeningopts">
                                                <li *ngIf="item !=''" [textContent]="item"></li>
                                            </ul>
                                        </div>

                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr *ngIf="opNutritionScreeningForm.patientFollowing">
                                                <td class="col-8">4.Does The Patient Has Any Of The Following?</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.patientFollowing"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.patientFollowingList && opNutritionScreeningForm.patientFollowingList.length>0">
                                                <td colspan="2">
                                                    <div class="dashboard-body">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <h6 class="font-14 mt-0 mb-2">Does The Patient Has Any Of The Following?</h6>
                                                                <ul>
                                                                    <li *ngFor="let item of opNutritionScreeningForm.patientFollowingList" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr *ngIf="opNutritionScreeningForm.others">
                                                <td class="col-8">
                                                    (For Total Scores Equal To Or Greater than 2 further Nutritional Assesment need to be carried out by dietician)
                                                </td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.others"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.otherFactorList && opNutritionScreeningForm.otherFactorList.length>0">
                                                <td colspan="2">
                                                    <div class="dashboard-body">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <h6 class="font-14 mt-0 mb-2">For Total Scores Equal To Or Greater Than 2 Further Nutritional Assesment</h6>
                                                                <ul>
                                                                    <li *ngFor="let item of opNutritionScreeningForm.otherFactorList" [textContent]="item"></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.otherFactors">
                                                <td class="col-8">Other Factors</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.otherFactors"> </td>
                                            </tr>
                                        </table>

                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr *ngIf="opNutritionScreeningForm.type || opNutritionScreeningForm.allergicIntolerant || opNutritionScreeningForm.allergic || opNutritionScreeningForm.allergicOthers || opNutritionScreeningForm.eatingOut || opNutritionScreeningForm.eating || opNutritionScreeningForm.typeOfHFSS || opNutritionScreeningForm.physicalActivity || opNutritionScreeningForm.physicalActivityOther || opNutritionScreeningForm.nutritionalScreeningDone || opNutritionScreeningForm.referredForNutritional || opNutritionScreeningForm.nutritionalScreeningDate || opNutritionScreeningForm.signature || opNutritionScreeningForm.leafletGiven || opNutritionScreeningForm.presentRiskFactorsother || opNutritionScreeningForm.presentNutritionRisk">
                                                <h5>Dietary intake</h5>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.type">
                                                <td class="col-8">Type</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.type"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.allergicIntolerant">
                                                <td class="col-8">Allergic/ Intolerant To</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.allergicIntolerant"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.allergic">
                                                <td class="col-8">Allergic</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.allergic"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.allergicOthers">
                                                <td class="col-8">Allergic Others</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.allergicOthers"> </td>
                                            </tr>

                                            <tr *ngIf="opNutritionScreeningForm.eatingOut">
                                                <td class="col-8">Eating Out</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.eatingOut"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.eating">
                                                <td class="col-8">Eating</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.eating"> </td>
                                            </tr>

                                            <tr *ngIf="opNutritionScreeningForm.typeOfHFSS">
                                                <td class="col-8">Type Of HFSS Foods(Type & Frequency)</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.typeOfHFSS"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.physicalActivity">
                                                <td class="col-8">Physical Activity</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.physicalActivity"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.physicalActivityOther">
                                                <td class="col-8">Other Exercise</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.physicalActivityOther"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.nutritionalScreeningDone">
                                                <td class="col-8">Nutritional Screening Done</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.nutritionalScreeningDone"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.referredForNutritional">
                                                <td class="col-8">Referred For Nutritional Counselling And Customised Diet Prescription.</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.referredForNutritional"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.presentNutritionRisk">
                                                <td class="col-8">Present Nutrition Risk</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.presentNutritionRisk"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.presentRiskFactorsother">
                                                <td class="col-8">Present Risk Factors</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.presentRiskFactorsother"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.leafletGiven">
                                                <td class="col-8">Leaflet Given</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.leafletGiven"> </td>
                                            </tr>

                                            <tr *ngIf="opNutritionScreeningForm.signature">
                                                <td class="col-8">Signature</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.signature"> </td>
                                            </tr>
                                            <tr *ngIf="opNutritionScreeningForm.nutritionalScreeningDate">
                                                <td class="col-8">Nutritional Screening Date</td>
                                                <td class="col-4" [textContent]="opNutritionScreeningForm.nutritionalScreeningDate | amDateFormat:'DD/MM/YYYY'"> </td>
                                            </tr>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="dashboard-box dashboard-table-box" #opNutritionalAssessmentSection *ngIf="opNutritionalAssessmentForm && opNutritionalAssessmentForm!== null">
                            <h4 class="dashboard-title">OP Nutritional Assessment Form</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div class="col-12 ">
                                        <div class="row">
                                            <div class="col-12">
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr *ngIf="opNutritionalAssessmentForm.titles || opNutritionalAssessmentForm.deptType || opNutritionalAssessmentForm.visitType || opNutritionalAssessmentForm.gpla || opNutritionalAssessmentForm.gestationage || opNutritionalAssessmentForm.specialFeatures || opNutritionalAssessmentForm.occupation || opNutritionalAssessmentForm.heightInFeet || opNutritionalAssessmentForm.heightInMeter || opNutritionalAssessmentForm.prePregnancyWt || opNutritionalAssessmentForm.prePregnancybmi || opNutritionalAssessmentForm.prePregBmiCategory || opNutritionalAssessmentForm.wtLossIn1stTrimester || opNutritionalAssessmentForm.weighttLossIn1stTrimester || opNutritionalAssessmentForm.presentWeight || opNutritionalAssessmentForm.presentBmi || opNutritionalAssessmentForm.bmiCategorization || opNutritionalAssessmentForm.wtLoss || opNutritionalAssessmentForm.wtGain">
                                                        <h5>OP Nutritional Assessment</h5>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.titles">
                                                        <td class="col-8">Titles</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.titles"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.deptType">
                                                        <td class="col-8">Dept Type</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.deptType"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.visitType">
                                                        <td class="col-8">Visit Type</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.visitType"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.gpla">
                                                        <td class="col-8">G P L A</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.gpla"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.gestationage">
                                                        <td class="col-8">Gestation Age(Weeks/days)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.gestationage"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.specialFeatures">
                                                        <td class="col-8">Special Features</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.specialFeatures"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.specialFeatures">
                                                        <td class="col-8">Occupation</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.occupation"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.heightInFeet">
                                                        <td class="col-8">Height in feet</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.heightInFeet"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.heightInMeter">
                                                        <td class="col-8">Height In Meter</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.heightInMeter"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.prePregnancyWt && opNutritionalAssessmentForm.deptType == 'OBS'">
                                                        <td class="col-8">Pre-Pregnancy Wt (Kgs)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.prePregnancyWt"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.prePregnancybmi && opNutritionalAssessmentForm.deptType == 'OBS'">
                                                        <td class="col-8">Pre-Pregnancy BMI</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.prePregnancybmi"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.prePregBmiCategory && opNutritionalAssessmentForm.deptType == 'OBS'">
                                                        <td class="col-8">Pre-Preg BMI Category</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.prePregBmiCategory"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.wtLossIn1stTrimester && opNutritionalAssessmentForm.deptType == 'OBS'">
                                                        <td class="col-8">Wt Loss In 1st Trimester</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.wtLossIn1stTrimester"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.weighttLossIn1stTrimester && opNutritionalAssessmentForm.deptType == 'OBS' && opNutritionalAssessmentForm.wtLossIn1stTrimester == 'Yes'">
                                                        <td class="col-8">Weight </td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.weighttLossIn1stTrimester"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.presentWeight">
                                                        <td class="col-8">Present Weight (kg)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.presentWeight"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.presentBmi">
                                                        <td class="col-8">Present BMI</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.presentBmi"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.bmiCategorization">
                                                        <td class="col-8">BMI Categorization</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.bmiCategorization"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.wtLoss && opNutritionalAssessmentForm.deptType == 'OBS'">
                                                        <td class="col-8">Wt Loss (Kgs)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.wtLoss"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.wtGain && opNutritionalAssessmentForm.deptType == 'OBS'">
                                                        <td class="col-8">Wt Gain (Kgs)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.wtGain"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr *ngIf="opNutritionalAssessmentForm.pastMedicalHistory || opNutritionalAssessmentForm.pastSurgicalHistory || opNutritionalAssessmentForm.familyHistory">
                                                        <h5>Patient History</h5>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.pastMedicalHistory">
                                                        <td class="col-8">Past Medical History</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.pastMedicalHistory"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.pastSurgicalHistory">
                                                        <td class="col-8">Past Surgical History</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.pastSurgicalHistory"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.familyHistory">
                                                        <td class="col-8">Family History</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.familyHistory"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.presentRiskFactors && opNutritionalAssessmentForm.presentRiskFactors.length > 0">
                                                        <h5>Present Condition</h5>
                                                    </tr>

                                                    <tr class="dashboard-break" *ngIf="opNutritionalAssessmentForm.presentRiskFactors && opNutritionalAssessmentForm.presentRiskFactors.length > 0">
                                                        <h6 class="font-14 mt-0 mb-1">Present Risk Factors</h6>
                                                        <ul class="dashboard-ul row">
                                                            <li *ngFor="let item of opNutritionalAssessmentForm.presentRiskFactors" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                        </ul>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.otherRiskFactors">
                                                        <td class="col-8">Other Risk Factors</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.otherRiskFactors"> </td>
                                                    </tr>

                                                    <tr><h5>Laboratory Findings</h5></tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.haemoglobin && opNutritionalAssessmentForm.deptType == 'OBS'">
                                                        <td class="col-8">Haemoglobin</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.haemoglobin"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.hMGBNDate">
                                                        <td class="col-8">HMGBN Date</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.hMGBNDate | amDateFormat:'DD/MM/YYYY'"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.oGTT">
                                                        <td class="col-8">OGTT</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.oGTT"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.oGTTDate">
                                                        <td class="col-8">OGTT Date</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.oGTTDate | amDateFormat:'DD/MM/YYYY'"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.outsideTest">
                                                        <td class="col-8">Outside Test</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.outsideTest"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.dietPreference || (opNutritionalAssessmentForm.dietPreferenceWith && opNutritionalAssessmentForm.dietPreferenceWith.length > 0)">
                                                        <h5>Diet History</h5>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.dietPreference">
                                                        <td class="col-8">Diet Preference</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dietPreference"> </td>
                                                    </tr>
                                                    <tr class="dashboard-break" *ngIf="opNutritionalAssessmentForm.dietPreferenceWith && opNutritionalAssessmentForm.dietPreferenceWith.length > 0">
                                                        <ul class="dashboard-ul row">
                                                            <li *ngFor="let item of opNutritionalAssessmentForm.dietPreferenceWith" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                        </ul>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.giHealth || opNutritionalAssessmentForm.giHealthYes || opNutritionalAssessmentForm.dental || opNutritionalAssessmentForm.anyOtherConerns">
                                                        <h5>Concerns</h5>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.giHealth">
                                                        <td class="col-8">GI Health</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.giHealth"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.giHealthYes && opNutritionalAssessmentForm.giHealth == 'Yes'">
                                                        <td class="col-8">Gi Health</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.giHealthYes"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dental">
                                                        <td class="col-8">Dental</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dental"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.anyOtherConerns">
                                                        <td class="col-8">Any Other Conerns</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.anyOtherConerns"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.milk || opNutritionalAssessmentForm.nuts || opNutritionalAssessmentForm.likes || opNutritionalAssessmentForm.dislikes || opNutritionalAssessmentForm.other1">
                                                        <h5>Food Allergies Or Intolerance</h5>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.milk">
                                                        <td class="col-8">Milk</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.milk"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.nuts">
                                                        <td class="col-8">Nuts</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.nuts"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.likes">
                                                        <td class="col-8">Likes</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.likes"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dislikes">
                                                        <td class="col-8">Dislikes</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dislikes"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.other1">
                                                        <td class="col-8">Other 1</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.other1"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.wakeUpTime || opNutritionalAssessmentForm.sleepingTime">
                                                        <h5>Diet Recall (Avg Of 4 Days)</h5>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.wakeUpTime">
                                                        <td class="col-8">WakeUp Time</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.wakeUpTime"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.sleepingTime">
                                                        <td class="col-8">Sleeping Time</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.sleepingTime"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.morningTime || opNutritionalAssessmentForm.morningMilk || opNutritionalAssessmentForm.morningMilkSugar || opNutritionalAssessmentForm.morningMilkBoost || opNutritionalAssessmentForm.dryfruitsNuts || opNutritionalAssessmentForm.anyOther">
                                                        <h5>Early Morning</h5>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.morningTime">
                                                        <td class="col-8">Time</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.morningTime"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.morningMilk">
                                                        <td class="col-8">Milk</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.morningMilk"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.morningMilkOpt">
                                                        <td class="col-8">Milk</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.morningMilkOpt"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.morningMilkSugar && opNutritionalAssessmentForm.morningMilk == 'Yes'">
                                                        <td class="col-8">Sugar (Tsp)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.morningMilkSugar"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.morningMilkBoost && opNutritionalAssessmentForm.morningMilk == 'Yes'">
                                                        <td class="col-8">Horlicks/ Boost/ Others (Tsp)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.morningMilkBoost"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dryfruitsNuts">
                                                        <td class="col-8">Dryfruits/ Nuts</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dryfruitsNuts"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.anyOther">
                                                        <td class="col-8">Any Others</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.anyOther"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.breakfasttime || opNutritionalAssessmentForm.breakfast  || opNutritionalAssessmentForm.breakfastIdli ||  opNutritionalAssessmentForm.breakfastDosa || opNutritionalAssessmentForm.breakfastRoti || opNutritionalAssessmentForm.breakfastBread ||  opNutritionalAssessmentForm.breakfastSliceOther || opNutritionalAssessmentForm.breakfastOats || opNutritionalAssessmentForm.breakfastOatsOther ||  opNutritionalAssessmentForm.chutney || opNutritionalAssessmentForm.vegCurry || opNutritionalAssessmentForm.cooked || opNutritionalAssessmentForm.dal || opNutritionalAssessmentForm.breakfastOther ">
                                                        <h5>Break Fast</h5>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.breakfasttime">
                                                        <td class="col-8">Time</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.breakfasttime"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.breakfast">
                                                        <td class="col-8">Break Fast</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.breakfast"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.breakfastIdli && opNutritionalAssessmentForm.breakfast == 'Idli'">
                                                        <td class="col-8">Idli (50 Grms) Medium Size</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.breakfastIdli"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.breakfastDosa && opNutritionalAssessmentForm.breakfast == 'Dosa'">
                                                        <td class="col-8">Dosa (30 Grms)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.breakfastDosa"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.breakfastRoti && opNutritionalAssessmentForm.breakfast == 'Roti'">
                                                        <td class="col-8">Roti (30g) Each</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.breakfastRoti"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.breakfastSlice">
                                                        <td class="col-8">Slice</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.breakfastSlice"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.breakfastSliceOther">
                                                        <td class="col-8">Slice Other</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.breakfastSliceOther"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.breakfastOats">
                                                        <td class="col-8">Oats</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.breakfastOats"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.breakfastOatsOther && opNutritionalAssessmentForm.breakfastOats == 'Others'">
                                                        <td class="col-8">Oats</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.breakfastOatsOther"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.chutney">
                                                        <td class="col-8">Chutney</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.chutney"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.vegCurry">
                                                        <td class="col-8">Veg Curry</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.vegCurry"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.cooked && opNutritionalAssessmentForm.vegCurry == 'Yes'">
                                                        <td class="col-8">Cooked(200ml)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.cooked"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dal">
                                                        <td class="col-8">Dal</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dal"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.breakfastOther">
                                                        <td class="col-8">Any Others</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.breakfastOther"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.midMorningtime || opNutritionalAssessmentForm.fruits || opNutritionalAssessmentForm.fruitType || opNutritionalAssessmentForm.fruitsJuice || opNutritionalAssessmentForm.fruitJuiceQua || opNutritionalAssessmentForm.fruitJuiceOther || opNutritionalAssessmentForm.tea || opNutritionalAssessmentForm.teaQua || opNutritionalAssessmentForm.midMorningOther">
                                                        <h5>Mid Morning</h5>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.midMorningtime">
                                                        <td class="col-8">Time</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.midMorningtime"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.fruits">
                                                        <td class="col-8">Fruits</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.fruits"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.fruitType && opNutritionalAssessmentForm.fruits == 'Yes'">
                                                        <td class="col-8">Fruit Type</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.fruitType"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.fruitsJuice">
                                                        <td class="col-8">Fruit Juice</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.fruitsJuice"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.fruitJuiceQua && opNutritionalAssessmentForm.fruitsJuice == 'Yes'">
                                                        <td class="col-8">Fruit Juice</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.fruitJuiceQua"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.fruitJuiceQua == 'Others' && opNutritionalAssessmentForm.fruitsJuice == 'Yes'">
                                                        <td class="col-8">Others</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.fruitJuiceQua"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.tea">
                                                        <td class="col-8">Tea</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.tea"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.teaQua &&  opNutritionalAssessmentForm.tea == 'Yes'">
                                                        <td class="col-8">Tea</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.teaQua"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.midMorningOther">
                                                        <td class="col-8">Any Others</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.midMorningOther"> </td>
                                                    </tr>


                                                    <tr *ngIf="opNutritionalAssessmentForm.lunchtime || opNutritionalAssessmentForm.carealsMilletS || opNutritionalAssessmentForm.lunceRice || opNutritionalAssessmentForm.lunceBrownRice || opNutritionalAssessmentForm.lunceroti || opNutritionalAssessmentForm.lunceWheatrawa || opNutritionalAssessmentForm.lunceMillets || opNutritionalAssessmentForm.lunceChapathi || opNutritionalAssessmentForm.luncePulka || opNutritionalAssessmentForm.lunchvegCurry || opNutritionalAssessmentForm.lunchvegCooked || opNutritionalAssessmentForm.greenLeafy || opNutritionalAssessmentForm.greenLeafyCooked || opNutritionalAssessmentForm.lunchdal || opNutritionalAssessmentForm.lunchdalCooked || opNutritionalAssessmentForm.panner || opNutritionalAssessmentForm.pannerPcs || opNutritionalAssessmentForm.pannerOther || opNutritionalAssessmentForm.lunchNonVeg || opNutritionalAssessmentForm.lunchNonVegPcs || opNutritionalAssessmentForm.lunchNonVegOther || opNutritionalAssessmentForm.lunchcurd || opNutritionalAssessmentForm.lunchOther ">
                                                        <h5>Lunch</h5>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.lunchtime">
                                                        <td class="col-8">Time</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.lunchtime"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.carealsMilletS">
                                                        <td class="col-8">Cereals / Millet'S</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.carealsMilletS"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.lunceRice && opNutritionalAssessmentForm.carealsMilletS == 'Rice'">
                                                        <td class="col-8">Rice</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.lunceRice"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.lunceBrownRice && opNutritionalAssessmentForm.carealsMilletS == 'Brown Rice (cooked 200 ml)'">
                                                        <td class="col-8">Brown Rice (Cooked 200 Ml)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.lunceBrownRice"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.lunceroti && opNutritionalAssessmentForm.carealsMilletS == 'Jowar Roti'">
                                                        <td class="col-8">Roti (30g each)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.lunceroti"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.lunceWheatrawa && opNutritionalAssessmentForm.carealsMilletS == 'Wheat rava (cooked 200 ml)'">
                                                        <td class="col-8">Wheat rava (cooked 200 ml)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.lunceWheatrawa"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.lunceMillets && opNutritionalAssessmentForm.carealsMilletS == 'Millets (cooked 200 ml)'">
                                                        <td class="col-8">Millets (cooked 200 ml)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.lunceMillets"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.lunceChapathi && opNutritionalAssessmentForm.carealsMilletS == 'Chapathi'">
                                                        <td class="col-8">Chapathi</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.lunceChapathi"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.luncePulka && opNutritionalAssessmentForm.carealsMilletS == 'Phulkas'">
                                                        <td class="col-8">Phulkas</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.luncePulka"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.lunchvegCurry">
                                                        <td class="col-8">Veg Curry</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.lunchvegCurry"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.lunchvegCooked && opNutritionalAssessmentForm.lunchvegCurry == 'Yes'">
                                                        <td class="col-8">Veg Curry (Cooked 200ml)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.lunchvegCooked"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.greenLeafy">
                                                        <td class="col-8">Green Leafy</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.greenLeafy"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.greenLeafyCooked && opNutritionalAssessmentForm.greenLeafy == 'Yes'">
                                                        <td class="col-8">Green Leafy (Cooked 200ml)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.greenLeafyCooked"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.lunchdal">
                                                        <td class="col-8">Dal</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.lunchdal"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.lunchdalCooked && opNutritionalAssessmentForm.lunchdal == 'Yes'">
                                                        <td class="col-8">Dal (Cooked 200ml)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.lunchdalCooked"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.panner">
                                                        <td class="col-8">Panner</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.panner"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.pannerPcs && opNutritionalAssessmentForm.panner == 'Yes'">
                                                        <td class="col-8">Panner</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.pannerPcs"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.pannerOther && opNutritionalAssessmentForm.panner == 'Yes' && opNutritionalAssessmentForm.pannerPcs == 'Other' ">
                                                        <td class="col-8">Others</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.pannerOther"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.lunchNonVeg">
                                                        <td class="col-8">Non-Veg</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.lunchNonVeg"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.lunchNonVegPcs && opNutritionalAssessmentForm.lunchNonVeg =='Yes'">
                                                        <td class="col-8">Non-Veg</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.lunchNonVegPcs"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.lunchNonVegOther && opNutritionalAssessmentForm.lunchNonVeg =='Yes' && opNutritionalAssessmentForm.lunchNonVegPcs =='Others'">
                                                        <td class="col-8">Others</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.lunchNonVegOther"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.lunchcurd">
                                                        <td class="col-8">Curd</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.lunchcurd"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.curd">
                                                        <td class="col-8">Curd</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.curd"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.lunchOther">
                                                        <td class="col-8">Any Others</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.lunchOther"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.eveningtime || opNutritionalAssessmentForm.eveningfruits  || opNutritionalAssessmentForm.eveningfruitsOther || opNutritionalAssessmentForm.eveningfruitJuice || opNutritionalAssessmentForm.eveningfruitsJuice || opNutritionalAssessmentForm.eveningfruitsJuiceOther || opNutritionalAssessmentForm.eveningSamosa || opNutritionalAssessmentForm.eveningSamosaName || opNutritionalAssessmentForm.eveningBakery || opNutritionalAssessmentForm.eveningBakeryName || opNutritionalAssessmentForm.eveningOther">
                                                        <h5>Mid Evening</h5>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.eveningtime">
                                                        <td class="col-8">Time</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.eveningtime"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.eveningfruits">
                                                        <td class="col-8">Fruits</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.eveningfruits"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.eveningfruitsOther && opNutritionalAssessmentForm.eveningfruits == 'Yes'">
                                                        <td class="col-8">Other Fruits </td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.eveningfruitsOther"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.eveningfruitJuice">
                                                        <td class="col-8">Fruit Juice</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.eveningfruitJuice"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.eveningfruitsJuice && opNutritionalAssessmentForm.eveningfruitJuice == 'Yes'">
                                                        <td class="col-8">Fruit Juice</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.eveningfruitsJuice"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.eveningfruitsJuiceOther">
                                                        <td class="col-8">Others</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.eveningfruitsJuiceOther"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.eveningSamosa">
                                                        <td class="col-8">Samosa / Fried Item</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.eveningSamosa"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.eveningSamosaName && opNutritionalAssessmentForm.eveningSamosa == 'Yes'">
                                                        <td class="col-8">Type Item Name</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.eveningSamosaName"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.eveningBakery">
                                                        <td class="col-8">Bakery Item</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.eveningBakery"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.eveningBakeryName && opNutritionalAssessmentForm.eveningBakery == 'Yes'">
                                                        <td class="col-8">Type Item Name</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.eveningBakeryName"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.eveningOther">
                                                        <td class="col-8">Any Other</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.eveningOther"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnertime || opNutritionalAssessmentForm.dinnercarealsMilletS || opNutritionalAssessmentForm.dinnerRice ||  opNutritionalAssessmentForm.dinnerBrownRice || opNutritionalAssessmentForm.dinnerRoti || opNutritionalAssessmentForm.dinnerWheatrawa ||  opNutritionalAssessmentForm.dinnerMillets || opNutritionalAssessmentForm.dinnerChapathi || opNutritionalAssessmentForm.dinnerPulka ||  opNutritionalAssessmentForm.dinnervegCurry || opNutritionalAssessmentForm.dinnervegCooked || opNutritionalAssessmentForm.dinnergreenLeafy ||  opNutritionalAssessmentForm.dinnergreenLeafOpts || opNutritionalAssessmentForm.dinnerdal || opNutritionalAssessmentForm.dinnerdalopts ||  opNutritionalAssessmentForm.dinnerpanner || opNutritionalAssessmentForm.dinnerpannerPCs || opNutritionalAssessmentForm.dinnerpannerOthers ||  opNutritionalAssessmentForm.ldinnerNonVeg || opNutritionalAssessmentForm.ldinnerNonVegPcs || opNutritionalAssessmentForm.ldinnerNonVegOther || opNutritionalAssessmentForm.dinnercurd || opNutritionalAssessmentForm.dinnerOther">
                                                        <h5>Dinner</h5>
                                                    </tr>


                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnertime">
                                                        <td class="col-8">Time</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dinnertime"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnercarealsMilletS">
                                                        <td class="col-8">Cereals / Millet'S</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dinnercarealsMilletS"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnerRice && opNutritionalAssessmentForm.dinnercarealsMilletS == 'Rice'">
                                                        <td class="col-8">Rice</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dinnerRice"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnerBrownRice && opNutritionalAssessmentForm.dinnercarealsMilletS == 'Brown Rice (cooked 200 ml)'">
                                                        <td class="col-8">Brown Rice (cooked 200ml)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dinnerBrownRice"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnerRoti && opNutritionalAssessmentForm.dinnercarealsMilletS == 'Jowar Roti'">
                                                        <td class="col-8">Roti (30g each)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dinnerRoti"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnerWheatrawa && opNutritionalAssessmentForm.dinnercarealsMilletS == 'Wheat rava (cooked 200 ml)'">
                                                        <td class="col-8">Wheat Rava (cooked 200ml)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dinnerWheatrawa"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnerMillets && opNutritionalAssessmentForm.dinnercarealsMilletS == 'Millets (cooked 200 ml)'">
                                                        <td class="col-8">Millets (cooked 200ml)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dinnerMillets"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnerChapathi && opNutritionalAssessmentForm.dinnercarealsMilletS == 'Chapthi'">
                                                        <td class="col-8">Chapathi</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dinnerChapathi"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnerPulka && opNutritionalAssessmentForm.dinnercarealsMilletS == 'Pulka'">
                                                        <td class="col-8">Phulkas</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dinnerPulka"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnervegCurry">
                                                        <td class="col-8">Veg Curry</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dinnervegCurry"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnervegCooked && opNutritionalAssessmentForm.dinnervegCurry =='Yes'">
                                                        <td class="col-8">Veg Curry (Cooked 200ml)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dinnervegCooked"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnergreenLeafy">
                                                        <td class="col-8">Green Leafy</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dinnergreenLeafy"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnergreenLeafOpts && opNutritionalAssessmentForm.dinnergreenLeafy == 'Yes'">
                                                        <td class="col-8">Green Leafy (Cooked 200ml)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dinnergreenLeafOpts"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnerdal">
                                                        <td class="col-8">Dal</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dinnerdal"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnerdalopts && opNutritionalAssessmentForm.dinnerdal =='Yes'">
                                                        <td class="col-8">Dal (Cooked 200ml)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dinnerdalopts"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnerpanner">
                                                        <td class="col-8">Paneer</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dinnerpanner"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnerpannerPCs && opNutritionalAssessmentForm.dinnerpanner =='Yes'">
                                                        <td class="col-8">Paneer (Pcs)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dinnerpannerPCs"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnerpannerOthers && opNutritionalAssessmentForm.dinnerpannerPCs == 'Others' && opNutritionalAssessmentForm.dinnerpanner =='Yes'">
                                                        <td class="col-8">Others </td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dinnerpannerOthers"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.ldinnerNonVeg">
                                                        <td class="col-8">Non-Veg</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.ldinnerNonVeg"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.ldinnerNonVegPcs">
                                                        <td class="col-8">Non Veg (Pcs)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.ldinnerNonVegPcs"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.ldinnerNonVegOther && opNutritionalAssessmentForm.ldinnerNonVegPcs == 'Others' && opNutritionalAssessmentForm.ldinnerNonVeg == 'yes'">
                                                        <td class="col-8">Others</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.ldinnerNonVegOther"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnercurd">
                                                        <td class="col-8">Curd</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dinnercurd"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dinnerOther">
                                                        <td class="col-8">Any Others</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dinnerOther"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.bedtime || opNutritionalAssessmentForm.bedTimeMilk || opNutritionalAssessmentForm.bedTimeMilkOpts || opNutritionalAssessmentForm.bedTimeMilkSugar || opNutritionalAssessmentForm.bedTimeMilkBoost || opNutritionalAssessmentForm.bedtimeDryFruits">
                                                        <h5>Bed Time</h5>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.bedtime">
                                                        <td class="col-8">Time</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.bedtime"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.bedTimeMilk">
                                                        <td class="col-8">Milk</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.bedTimeMilk"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.bedTimeMilkOpts && opNutritionalAssessmentForm.bedTimeMilk == 'Yes'">
                                                        <td class="col-8">Milk</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.bedTimeMilkOpts"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.bedTimeMilkSugar && opNutritionalAssessmentForm.bedTimeMilk == 'Yes'">
                                                        <td class="col-8">Sugar (Tsp)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.bedTimeMilkSugar"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.bedTimeMilkBoost && opNutritionalAssessmentForm.bedTimeMilk == 'Yes'">
                                                        <td class="col-8">Horlicks/ Boost/ Others (Tsp)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.bedTimeMilkBoost"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.bedtimeDryFruits">
                                                        <td class="col-8">Dry Fruits</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.bedtimeDryFruits"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.bedTimeOther">
                                                        <td class="col-8">Any Others</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.bedTimeOther"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.oftenyouEatSamosa || opNutritionalAssessmentForm.typeOfFood || opNutritionalAssessmentForm.inclusionOfFruitJuice || opNutritionalAssessmentForm.qualityFruitJuice || opNutritionalAssessmentForm.qualityFruitJuiceMl || opNutritionalAssessmentForm.carbonatedDrink || opNutritionalAssessmentForm.highSugarDrinkMore || opNutritionalAssessmentForm.highSugarDrink || opNutritionalAssessmentForm.inclusionOfProcessedJunk || opNutritionalAssessmentForm.intakeOfWholeGrains || opNutritionalAssessmentForm.moreThanOfDiet || opNutritionalAssessmentForm.lowVegetableIntake ||  opNutritionalAssessmentForm.intakeOfFruits || opNutritionalAssessmentForm.intakeFruits || opNutritionalAssessmentForm.typeOfMilkCurd || opNutritionalAssessmentForm.waterIntake || opNutritionalAssessmentForm.waterIntakeOther || opNutritionalAssessmentForm.totalOilUsed || opNutritionalAssessmentForm.typeOfOil || opNutritionalAssessmentForm.otherOil || opNutritionalAssessmentForm.gheeButter || opNutritionalAssessmentForm.gheeButterYes || opNutritionalAssessmentForm.gheeButterOthers || opNutritionalAssessmentForm.beansGrams || opNutritionalAssessmentForm.beansGramsYes || opNutritionalAssessmentForm.qualitydal || opNutritionalAssessmentForm.qualitydalYes || opNutritionalAssessmentForm.qualityPaneerYes || opNutritionalAssessmentForm.qualityPaneer || opNutritionalAssessmentForm.qualityGlv ||  opNutritionalAssessmentForm.qualityMutton || opNutritionalAssessmentForm.qualityMuttontake || opNutritionalAssessmentForm.qualityMuttonOther ||  opNutritionalAssessmentForm.qualityChicken || opNutritionalAssessmentForm.qualityChickentake || opNutritionalAssessmentForm.qualityFish ||  opNutritionalAssessmentForm.qualityFishtake || opNutritionalAssessmentForm.qualityEgg || opNutritionalAssessmentForm.eggWhite ||  opNutritionalAssessmentForm.eggWhole">
                                                        <h5>Quality Of Diet</h5>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.oftenyouEatSamosa">
                                                        <td class="col-8">How Often You Eat Samosa/Puff/Fried Snacks /Fried Chicken / Fried Mutton / Cakes</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.oftenyouEatSamosa"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.typeOfFood">
                                                        <td class="col-8">Type Of Food</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.typeOfFood"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.inclusionOfFruitJuice">
                                                        <td class="col-8">Inclusion Of Fruit Juice 200ml</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.inclusionOfFruitJuice"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.qualityFruitJuice">
                                                        <td class="col-8">Fruit Juice</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.qualityFruitJuice"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.qualityFruitJuiceMl && opNutritionalAssessmentForm.qualityFruitJuice == 'Yes'">
                                                        <td class="col-8">Fruit Juice Ml</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.qualityFruitJuiceMl"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.carbonatedDrink">
                                                        <td class="col-8">Carbonated Drink</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.carbonatedDrink"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.carbonated && opNutritionalAssessmentForm.carbonatedDrink == 'Yes'">
                                                        <td class="col-8">Carbonated</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.carbonated"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.highSugarDrinkMore">
                                                        <td class="col-8">High Sugar Drink(More Than 3tsp)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.highSugarDrinkMore"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.highSugarDrink && opNutritionalAssessmentForm.highSugarDrinkMore == 'Yes'">
                                                        <td class="col-8">High Sugar Drink</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.highSugarDrink"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.inclusionOfProcessedJunk">
                                                        <td class="col-8">Inclusion Of Processed Junk foods >100g (Twice/ Week)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.inclusionOfProcessedJunk"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.intakeOfWholeGrains ">
                                                        <td class="col-8">Intake Of Whole Grains</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.intakeOfWholeGrains"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.moreThanOfDiet">
                                                        <td class="col-8">More Than 50% Of Diet</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.moreThanOfDiet"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.moreThanOfDiet">
                                                        <td class="col-8">Low Vegetable Intake (< 3 and a half  serving )/ day</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.moreThanOfDiet"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.intakeOfFruits ">
                                                        <td class="col-8">Intake Of Fruits</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.intakeOfFruits"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.intakeFruits && opNutritionalAssessmentForm.intakeOfFruits == 'Yes'">
                                                        <td class="col-8">Intake Fruits</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.intakeFruits"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.typeOfMilkCurd">
                                                        <td class="col-8">Type Of Milk / Curd</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.typeOfMilkCurd"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.waterIntake">
                                                        <td class="col-8">Water Intake</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.waterIntake"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.waterIntakeOther && opNutritionalAssessmentForm.waterIntake == 'others'">
                                                        <td class="col-8">Water Intake Others</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.waterIntakeOther"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.totalOilUsed">
                                                        <td class="col-8">Total Oil Used/ Month</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.totalOilUsed"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.totalOilUsedOthers && opNutritionalAssessmentForm.totalOilUsed == 'others'">
                                                        <td class="col-8">Oil Others</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.totalOilUsedOthers"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.typeOfOil">
                                                        <td class="col-8">Type Of Oil</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.typeOfOil"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.otherOil">
                                                        <td class="col-8">Other Oil</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.otherOil"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.gheeButter ">
                                                        <td class="col-8">Ghee / Butter</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.gheeButter"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.gheeButterYes && opNutritionalAssessmentForm.totalOilUsed == 'Yes'">
                                                        <td class="col-8">Ghee / Butter(Tsp)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.gheeButterYes"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.gheeButterOthers && opNutritionalAssessmentForm.gheeButterYes == 'others' && opNutritionalAssessmentForm.totalOilUsed == 'Yes'">
                                                        <td class="col-8">Others</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.gheeButterOthers"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.beansGrams">
                                                        <td class="col-8">Beans/Grams</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.beansGrams"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.beansGramsYes && opNutritionalAssessmentForm.beansGrams == 'Yes'">
                                                        <td class="col-8">Taken / Day / Week</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.beansGramsYes"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.qualitydal">
                                                        <td class="col-8">Dal (200 Ml)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.qualitydal"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.qualitydalYes && opNutritionalAssessmentForm.qualitydal == 'Yes'">
                                                        <td class="col-8">Taken / Day / Week</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.qualitydalYes"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.qualityPaneerYes">
                                                        <td class="col-8">Paneer</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.qualityPaneerYes"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.qualityPaneer && opNutritionalAssessmentForm.qualityPaneerYes == 'Yes'">
                                                        <td class="col-8">Taken / Day / Week</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.qualityPaneer"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.qualityGlv">
                                                        <td class="col-8">GLV</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.qualityGlv"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.qualityMutton">
                                                        <td class="col-8">Mutton / Red Meat</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.qualityMutton"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.qualityMuttontake && opNutritionalAssessmentForm.qualityMutton == 'Yes'">
                                                        <td class="col-8">Taken / Day / Week</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.qualityMuttontake"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.qualityMuttonOther && opNutritionalAssessmentForm.qualityMuttontake == 'Others' && opNutritionalAssessmentForm.qualityMutton == 'Yes'">
                                                        <td class="col-8">Others</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.qualityMuttonOther"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.qualityChicken">
                                                        <td class="col-8">Chicken</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.qualityChicken"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.qualityChickentake && opNutritionalAssessmentForm.qualityChicken == 'Yes'">
                                                        <td class="col-8">Taken / Day / Week</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.qualityChickentake"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.qualityFish">
                                                        <td class="col-8">Fish</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.qualityFish"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.qualityFishtake && opNutritionalAssessmentForm.qualityFish == 'Yes'">
                                                        <td class="col-8">Taken / Day / Week</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.qualityFishtake"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.qualityEgg">
                                                        <td class="col-8">Egg</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.qualityEgg"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.eggWhite && opNutritionalAssessmentForm.qualityEgg == 'Yes'">
                                                        <td class="col-8">Egg White</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.eggWhite"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.eggWhole && opNutritionalAssessmentForm.qualityEgg == 'Yes'">
                                                        <td class="col-8">Egg Whole</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.eggWhole"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.activityPattern || opNutritionalAssessmentForm.physicalActivity  || opNutritionalAssessmentForm.walking || opNutritionalAssessmentForm.cookingHouseholdWork || opNutritionalAssessmentForm.cookingHouseholdWorkOthers || opNutritionalAssessmentForm.sittingOrReclining || opNutritionalAssessmentForm.sittingOthers || opNutritionalAssessmentForm.exercise">
                                                        <h5>Life Style</h5>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.activityPattern">
                                                        <td class="col-8">Activity Pattern</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.activityPattern"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.physicalActivity">
                                                        <td class="col-8">Physical Activity</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.physicalActivity"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.walking && opNutritionalAssessmentForm.physicalActivity == 'Yes'">
                                                        <td class="col-8">Walking</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.walking"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.cookingHouseholdWork">
                                                        <td class="col-8">Cooking / Household Work</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.cookingHouseholdWork"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.cookingHouseholdWorkOthers">
                                                        <td class="col-8">Others</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.cookingHouseholdWorkOthers"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.sittingOrReclining">
                                                        <td class="col-8">Time Spend On Sitting Or Reclining in a day</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.sittingOrReclining"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.sittingOthers && opNutritionalAssessmentForm.sittingOrReclining == 'Others'">
                                                        <td class="col-8">Sitting Others</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.sittingOthers"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.exercise">
                                                        <td class="col-8">Exercise</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.exercise"> </td>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.nutritionalScreening || opNutritionalAssessmentForm.pamphaletOfHealthyFood || opNutritionalAssessmentForm.nutritionalRisk || opNutritionalAssessmentForm.counselledByNutritionist || opNutritionalAssessmentForm.dietRecommened || opNutritionalAssessmentForm.leafletGivenDietPlan || opNutritionalAssessmentForm.prescribedCalories || opNutritionalAssessmentForm.prescribedProtien || opNutritionalAssessmentForm.signature || opNutritionalAssessmentForm.date  || opNutritionalAssessmentForm.dietRecommenedOthers  || opNutritionalAssessmentForm.specialFeaturesForCare ">
                                                        <h5>Plan Of Nutrition Care</h5>
                                                    </tr>

                                                    <tr *ngIf="opNutritionalAssessmentForm.nutritionalScreening">
                                                        <td class="col-8">Nutritional Screening</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.nutritionalScreening"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.pamphaletOfHealthyFood">
                                                        <td class="col-8">Pamphlet Of Healthy Food Habit </td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.pamphaletOfHealthyFood"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.nutritionalRisk">
                                                        <td class="col-8">Nutritional Risk</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.nutritionalRisk"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.counselledByNutritionist">
                                                        <td class="col-8">Counselled By Nutritionist/Diet Plan</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.counselledByNutritionist"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dietRecommened">
                                                        <td class="col-8">Diet Recommened</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dietRecommened"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.leafletGivenDietPlan">
                                                        <td class="col-8">Leaflet Given / Diet Plan</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.leafletGivenDietPlan"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.prescribedCalories">
                                                        <td class="col-8">Prescribed Calories(Kcals/Day)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.prescribedCalories"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.prescribedProtien">
                                                        <td class="col-8">Prescribed Protien(Gms/Day)</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.prescribedProtien"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.signature">
                                                        <td class="col-8">Signature </td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.signature"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.date">
                                                        <td class="col-8">Date</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.date | amDateFormat:'DD/MM/YYYY'"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.dietRecommenedOthers && opNutritionalAssessmentForm.dietRecommened == 'Others'">
                                                        <td class="col-8">Others</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.dietRecommenedOthers"> </td>
                                                    </tr>
                                                    <tr *ngIf="opNutritionalAssessmentForm.specialFeaturesForCare">
                                                        <td class="col-8">Special Features</td>
                                                        <td class="col-4" [textContent]="opNutritionalAssessmentForm.specialFeaturesForCare"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="dashboard-box dashboard-table-box" #syndromesFormPSection *ngIf="syndromesFormP && syndromesFormP!== null">
                            <h4 class="dashboard-title">Syndromes & Diseases</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div>
                                        <div *ngIf="syndromesFormP.consultantDoctor">
                                            <table class="table table-centered table-nowrap table-sm table-striped table-borderless mb-0">
                                                <tr>
                                                    <td class="col-8">Consultant Doctor</td>
                                                    <td class="col-4 text-uppercase" [textContent]="syndromesFormP.consultantDoctor"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="col-12" *ngIf="syndromesFormP.syndromes && syndromesFormP.syndromes.length > 0">
                                            <h6 class="font-14 mt-0 mb-1">Syndromes</h6>
                                            <ul class="dashboard-ul row">
                                                <li *ngFor="let item of syndromesFormP.syndromes" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                            </ul>
                                        </div>
                                        <div class="col-12" *ngIf="syndromesFormP.diseases && syndromesFormP.diseases.length > 0">
                                            <h6 class="font-14 mt-0 mb-1">Diseases</h6>
                                            <ul class="dashboard-ul row">
                                                <li *ngFor="let item of syndromesFormP.diseases" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                            </ul>
                                        </div>
                                        <table class="table table-centered table-nowrap table-sm table-striped table-borderless mb-0">
                                            <tr *ngIf="syndromesFormP.others">
                                                <td class="col-8">Others</td>
                                                <td class="col-4" [textContent]="syndromesFormP.others"></td>
                                            </tr>
                                            <tr *ngIf="syndromesFormP.provisionalDiagnosis">
                                                <td class="col-8">Provisional Diagnosis</td>
                                                <td class="col-4" [textContent]="syndromesFormP.provisionalDiagnosis"></td>
                                            </tr>
                                            <tr *ngIf="syndromesFormP.testOrdered">
                                                <td class="col-8">Test Ordered</td>
                                                <td class="col-4" [textContent]="syndromesFormP.testOrdered"></td>
                                            </tr>
                                            <tr *ngIf="syndromesFormP.ifThePatientIsWillingToGive">
                                                <td class="col-8">If the Patient Is Willing To Give Sample</td>
                                                <td class="col-4" [textContent]="syndromesFormP.ifThePatientIsWillingToGive"></td>
                                            </tr>
                                            <tr *ngIf="syndromesFormP.dateOfOnsetOfTheSymptom">
                                                <td class="col-8">Date Of Onset Of the Symptoms</td>
                                                <td class="col-4" [textContent]="syndromesFormP.dateOfOnsetOfTheSymptom"></td>
                                            </tr>
                                            <tr *ngIf="syndromesFormP.transactionId">
                                                <td class="col-8">Transaction Id</td>
                                                <td class="col-4" [textContent]="syndromesFormP.transactionId"></td>
                                            </tr>
                                            <tr *ngIf="syndromesFormP.typeOfSample">
                                                <td class="col-8"> Type Of Sample</td>
                                                <td class="col-4" [textContent]="syndromesFormP.typeOfSample"></td>
                                            </tr>
                                            <tr *ngIf="syndromesFormP.dateOfSampleCollection">
                                                <td class="col-8"> Date Of Sample Collection</td>
                                                <td class="col-4" [textContent]="syndromesFormP.dateOfSampleCollection | amDateFormat:'DD/MM/YYYY' "></td>
                                            </tr>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="dashboard-box dashboard-table-box" #ipNutritionScreeingSection *ngIf="ipNutritionalScreeningForm && ipNutritionalScreeningForm!== null">
                            <h4 class="dashboard-title">IP Nutritional Screening Assessment</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">

                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <tr><h5>IP Nutritional Screening Assessment</h5></tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.height">
                                            <td class="col-8">Height (Ft/Cms)</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.height"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.heightInMts">
                                            <td class="col-8">Height In Mts</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.heightInMts"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.prePregnancyWt && ipNutritionalScreeningForm.dietType == 'OBS'">
                                            <td class="col-8">PrePreg Weight</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.prePregnancyWt"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.prePregnancyBmi && ipNutritionalScreeningForm.dietType == 'OBS'">
                                            <td class="col-8">PrePreg BMI</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.prePregnancyBmi"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.presentWeight">
                                            <td class="col-8">Present Weight</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.presentWeight"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.presentBMI">
                                            <td class="col-8">Present BMI</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.presentBMI"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.dietType">
                                            <td class="col-8">Diet Type</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.dietType"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.foodPrefenrnces">
                                            <td class="col-8">Food Prefenrnce</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.foodPrefenrnces"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.diagnosis">
                                            <td class="col-8">Diagnosis</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.diagnosis"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.foodAllergies">
                                            <td class="col-8">Food Allergies</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.foodAllergies"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.dietOrder">
                                            <td class="col-8">Diet Order</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.dietOrder"> </td>
                                        </tr>

                                        <tr><h5>Nutritional Screening (Ref Modified Must Screening Tool)</h5></tr>
                                        <tr>
                                            <ul *ngFor="let item of ipNutritionalScreeningForm.screeningOpts">
                                                <li *ngIf="item !=''" [textContent]="item"></li>
                                            </ul>
                                        </tr>
                                        <tr *ngIf="(ipNutritionalScreeningForm.patientFollowing && ipNutritionalScreeningForm.patientFollowing.length > 0) || (ipNutritionalScreeningForm.patientFollowingCalc && ipNutritionalScreeningForm.patientFollowingCalc.length > 0)">
                                            <h5>Does The Patient Have Any Following?</h5>
                                        </tr>
                                        <tr>
                                            <ul *ngFor="let item of ipNutritionalScreeningForm.patientFollowing">
                                                <li *ngIf="item !=''" [textContent]="item"></li>
                                            </ul>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.calc">
                                            <td class="col-8">Calc</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.calc"> </td>
                                        </tr>
                                        <tr>
                                            <ul *ngFor="let item of ipNutritionalScreeningForm.patientFollowingCalc">
                                                <li *ngIf="item !=''" [textContent]="item"></li>
                                            </ul>
                                        </tr>

                                        <tr *ngIf="ipNutritionalScreeningForm.inadequatePerOralIntake || ipNutritionalScreeningForm.multipleGestation || ipNutritionalScreeningForm.pIHChronicHTN || ipNutritionalScreeningForm.dMTypePGDM || ipNutritionalScreeningForm.anemia || ipNutritionalScreeningForm.oedema ||  ipNutritionalScreeningForm.hOSignificant || ipNutritionalScreeningForm.chewingSwllowingProblem ||  ipNutritionalScreeningForm.nauseaVominting || ipNutritionalScreeningForm.diarrhea ||  ipNutritionalScreeningForm.postSurgical || ipNutritionalScreeningForm.cirrhosis ||  ipNutritionalScreeningForm.nephoroticSyndrome || ipNutritionalScreeningForm.iUGR ||  ipNutritionalScreeningForm.mouthUlcers || ipNutritionalScreeningForm.geriatricPatient ||  ipNutritionalScreeningForm.jaundice || ipNutritionalScreeningForm.pancretitis ||  ipNutritionalScreeningForm.nephritis || ipNutritionalScreeningForm.hypoThyroid ||  ipNutritionalScreeningForm.other || ipNutritionalScreeningForm.otherother ||  ipNutritionalScreeningForm.weightGainLossInPregnacy || ipNutritionalScreeningForm.counsellingDoneBy ||  ipNutritionalScreeningForm.signature || ipNutritionalScreeningForm.assessmentUpdated ||  ipNutritionalScreeningForm.assessmentDone">
                                            <h5>Detailed Nurtitional Risk Assessment</h5>
                                        </tr>

                                        <tr *ngIf="ipNutritionalScreeningForm.inadequatePerOralIntake">
                                            <td class="col-8">Inadequate Per Oral Intake</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.inadequatePerOralIntake"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.multipleGestation">
                                            <td class="col-8">Multiple Gestation</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.multipleGestation"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.pIHChronicHTN">
                                            <td class="col-8">PIH / Chronic HTN</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.pIHChronicHTN"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.dMTypePGDM">
                                            <td class="col-8">DM Type: PGDM/GDM/Type I/II</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.dMTypePGDM"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.anemia">
                                            <td class="col-8">Anemia</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.anemia"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.oedema">
                                            <td class="col-8">Oedema</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.oedema"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.inappropriateWeight">
                                            <td class="col-8">Inappropriate Weight Gain</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.inappropriateWeight"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.hOSignificant">
                                            <td class="col-8">H/O Significant Weight loss(>3Kgs)</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.hOSignificant"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.chewingSwllowingProblem">
                                            <td class="col-8">Chewing/ Swallowing Problem</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.chewingSwllowingProblem"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.nauseaVominting">
                                            <td class="col-8">Nausea / Vomiting / Hyperemesis</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.nauseaVominting"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.diarrhea">
                                            <td class="col-8">Diarrhea</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.diarrhea"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.postSurgical">
                                            <td class="col-8">Post Surgical</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.postSurgical"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.cirrhosis">
                                            <td class="col-8">Cirrhosis</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.cirrhosis"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.nephoroticSyndrome">
                                            <td class="col-8">Nephorotic Syndrome</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.nephoroticSyndrome"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.iUGR">
                                            <td class="col-8">IUGR</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.iUGR"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.mouthUlcers">
                                            <td class="col-8">Mouth Ulcers</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.mouthUlcers"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.geriatricPatient">
                                            <td class="col-8">Geriatric Patients</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.geriatricPatient"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.jaundice">
                                            <td class="col-8">Jaundice</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.jaundice"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.pancretitis">
                                            <td class="col-8">Pancreatitis</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.pancretitis"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.nephritis">
                                            <td class="col-8">Nephritis</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.nephritis"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.hypoThyroid">
                                            <td class="col-8">Hypo Thyroid</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.hypoThyroid"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.other">
                                            <td class="col-8">Other</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.other"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.otherother">
                                            <td class="col-8">Others</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.otherother"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.weightGainLossInPregnacy">
                                            <td class="col-8">Total Weight Gain / Loss In Pregnancy till date</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.weightGainLossInPregnacy"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.counsellingDoneBy">
                                            <td class="col-8">Screening / Counselling Done By</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.counsellingDoneBy"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.signature">
                                            <td class="col-8">Signature</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.signature"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.assessmentUpdated">
                                            <td class="col-8">Assessment Updated</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.assessmentUpdated | amDateFormat:'DD/MM/YYYY'"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.assessmentDone">
                                            <td class="col-8">Assessment Done</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.assessmentDone | amDateFormat:'DD/MM/YYYY'"> </td>
                                        </tr>

                                        <tr *ngIf="ipNutritionalScreeningForm.hb || ipNutritionalScreeningForm.oGTT  || ipNutritionalScreeningForm.rbs  || ipNutritionalScreeningForm.fBS  ||  ipNutritionalScreeningForm.albumin  || ipNutritionalScreeningForm.creatinine  || ipNutritionalScreeningForm.sodium  || ipNutritionalScreeningForm.Potassium  ||  ipNutritionalScreeningForm.chloride  || ipNutritionalScreeningForm.ketoneBodies  || ipNutritionalScreeningForm.sGOSGPT  || ipNutritionalScreeningForm.investOthers  ||  ipNutritionalScreeningForm.investTime  || ipNutritionalScreeningForm.recommendedOnTheBasis || ipNutritionalScreeningForm.dietOther ||  ipNutritionalScreeningForm.nutrientsRecommendetion || ipNutritionalScreeningForm.nutrientsRecommendProtein || ipNutritionalScreeningForm.formOfDiet ||  ipNutritionalScreeningForm.dietNotesOne || ipNutritionalScreeningForm.dietitianNotes || ipNutritionalScreeningForm.specialFestures ">
                                            <h5>Investigations</h5>
                                        </tr>

                                        <tr *ngIf="ipNutritionalScreeningForm.hb">
                                            <td class="col-8">HB</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.hb"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.oGTT">
                                            <td class="col-8">OGTT</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.oGTT"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.rbs">
                                            <td class="col-8">RBS</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.rbs"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.fBS">
                                            <td class="col-8">FBS</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.fBS"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.albumin">
                                            <td class="col-8">Albumin</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.albumin"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.creatinine">
                                            <td class="col-8">Creatinine</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.creatinine"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.sodium">
                                            <td class="col-8">Sodium</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.sodium"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.Potassium">
                                            <td class="col-8">Potassium</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.Potassium"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.chloride">
                                            <td class="col-8">Chloride</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.chloride"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.ketoneBodies">
                                            <td class="col-8">Ketone Bodies</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.ketoneBodies"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.sGOSGPT">
                                            <td class="col-8">SGO / SGPT</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.sGOSGPT"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.investOthers">
                                            <td class="col-8">Others</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.investOthers"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.investTime">
                                            <td class="col-8">Time</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.investTime"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.recommendedOnTheBasis">
                                            <td class="col-8">Diet Recommended On The Basis Of nutritional assessment</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.recommendedOnTheBasis"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.dietOther">
                                            <td class="col-8">Diet Others</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.dietOther"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.nutrientsRecommendetion">
                                            <td class="col-8">Nutrients Recommendetion (Kals/day)</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.nutrientsRecommendetion"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.nutrientsRecommendProtein">
                                            <td class="col-8">Nutrients Recommendetion Protein day</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.nutrientsRecommendProtein"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.formOfDiet">
                                            <td class="col-8">Form Of Diet Or Supplentation: Regular / Soft / Liquid /</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.formOfDiet"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.dietNotesOne">
                                            <td class="col-8">Diet Notes One</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.dietNotesOne"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.dietitianNotes">
                                            <td class="col-8">Dietitian Notes</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.dietitianNotes"> </td>
                                        </tr>
                                        <tr *ngIf="ipNutritionalScreeningForm.specialFeatures">
                                            <td class="col-8">Special Festures</td>
                                            <td class="col-4" [textContent]="ipNutritionalScreeningForm.specialFeatures"> </td>
                                        </tr>

                                    </table>


                                </div>
                            </div>

                        </div>

                        <div class="dashboard-box dashboard-table-box" #orderPrescriptionSection *ngIf="orderPrescription && orderPrescription !== null">
                            <h4 class="dashboard-title">Order/Prescription</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <table class="table table-centered table-sm table-striped mb-0">
                                        <tr>
                                            <td>Labs</td>
                                            <td>
                                                <div class="col-12 p-0 mb-1" *ngIf="!page.loading">
                                                    <provider-lab-widget [appointmentId]="appointment.appointmentId" [flag]="flag"></provider-lab-widget>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Medications
                                            </td>
                                            <td *ngFor="let record of records;">
                                                <table class="table table-centered table-nowrap table-sm table-striped transcipt mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Medicine Name</th>
                                                            <th>Generic Name</th>
                                                            <th>Frequency</th>
                                                            <th>Route	</th>
                                                            <th>Duration</th>
                                                            <th>Qty</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                        <tr *ngFor="let item of record.medicines; let i = index">
                                                            <td>
                                                                <span class=" text-wrap" [textContent]="item.productName"></span><br />
                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap" *ngIf="item.genericName" [textContent]="item.genericName"></span>

                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap" *ngIf="item.instruction" [textContent]="item.instruction"></span>


                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap" *ngIf="item.route" [textContent]="item.route"></span>

                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap" *ngIf="item.duration" [textContent]="item.duration"></span>

                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap" *ngIf="item.dosage" [textContent]="item.dosage"></span>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr *ngIf="bookingHeader && bookingHeader.length > 0">
                                            <td>Investigations</td>
                                            <td>
                                                <table class="table table-centered table-nowrap table-sm table-striped transcipt mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Test Name</th>
                                                            <th>Test Code</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                        <tr *ngFor="let item of bookingHeader;let i =index;">
                                                            <td>
                                                                <span class=" text-wrap" [textContent]="item.testName"></span><br />
                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap" [textContent]="item.testCode"></span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr *ngIf="records && records.length !== 0">
                                            <td>
                                                Prescription
                                            </td>
                                            <td *ngFor="let record of records;">
                                                <table class="table table-centered table-nowrap table-sm table-striped transcipt mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Medicine Name</th>
                                                            <th>Generic Name</th>
                                                            <th>Frequency</th>
                                                            <th>Route	</th>
                                                            <th>Duration</th>
                                                            <th>Qty</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                        <tr *ngFor="let item of record.medicines; let i = index">
                                                            <td>
                                                                <span class=" text-wrap" [textContent]="item.productName"></span><br />
                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap" *ngIf="item.genericName" [textContent]="item.genericName"></span>

                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap" *ngIf="item.instruction" [textContent]="item.instruction"></span>


                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap" *ngIf="item.route" [textContent]="item.route"></span>

                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap" *ngIf="item.duration" [textContent]="item.duration"></span>

                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap" *ngIf="item.dosage" [textContent]="item.dosage"></span>

                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap" *ngIf="item.remark" [textContent]="item.remark"></span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let item of orderPrescription.orderData">
                                            <td *ngIf="item.comments && item.comments !== null " [textContent]="item.orderName"></td>
                                            <td *ngIf="item.comments && item.comments !== null " [innerHTML]="item.comments"></td>
                                        </tr>
                                    </table>
                                    <div *ngIf="referralOrder && referralOrder.length > 0">
                                        <h5>Referrals</h5>
                                    </div>
                                    <div>
                                        <table class="table table-centered table-nowrap table-sm table-striped transcipt mb-0">
                                            <thead>
                                                <tr class="font-weight-bolder">
                                                    <th>Doctors</th>
                                                    <th>Comments</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr *ngFor="let item of referralOrder; let i = index">
                                                    <td>
                                                        <span class=" text-wrap" [textContent]="item.name"></span><br />
                                                    </td>
                                                    <td>
                                                        <span class=" text-wrap" [textContent]="item.comments"></span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dashboard-box dashboard-table-box" #referralFormSection *ngIf="referralForm && referralForm.length>0 ">
                            <h4 class="dashboard-title">Referral Form</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Referral To Doctor</th>
                                                <th>Reason For Referring</th>
                                                <th>Req Date</th>
                                                <th>Time</th>
                                                <th>Consultant Doctor</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of referralForm">
                                                <td><span [textContent]="item.referralDoctor"></span></td>
                                                <td><span [textContent]="item.reason"></span></td>
                                                <td><span [textContent]="item.requiredDate | date: 'dd/MM/yyyy'"></span></td>
                                                <td><span [textContent]="item.time"></span></td>
                                                <td><span [textContent]="item.consultantDoctor"></span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <div class="dashboard-box dashboard-table-box" #addendumSection *ngIf="addendumForm && addendumForm !== null">
                            <h4 class="dashboard-title">Addendum Form</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <tr *ngIf="addendumForm.date">
                                            <td class="col-8">Date</td>
                                            <td class="col-4" [textContent]="addendumForm.date"> </td>
                                        </tr>
                                        <tr *ngIf="addendumForm.time">
                                            <td class="col-8">Time</td>
                                            <td class="col-4" [textContent]="addendumForm.time"> </td>
                                        </tr>
                                        <tr *ngIf="addendumForm.note">
                                            <td class="col-8">Note</td>
                                            <td class="col-4" [textContent]="addendumForm.note"> </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>


                        <div class="dashboard-box dashboard-table-box" #specialFeatureSection *ngIf="specialFeature && specialFeature.specialFeature !== null">
                            <h4 class="dashboard-title">Special Features</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <h6 class="font-14 mt-0 mb-2">Special Features</h6>
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <tr *ngIf="specialFeature.specialFeature">
                                            <td class="col-8">Special Features</td>
                                            <td class="col-4" [textContent]="specialFeature.specialFeature"> </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="dashboard-box dashboard-table-box" #dietGuideLinesSection *ngIf="dietGuideLines">
                            <h4 class="dashboard-title">Diet Guidelines</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div *ngIf="isDietGuideHistory">
                                        There is no diet guidelines
                                    </div>
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="dietGuideLines && dietGuideLines.length >0">
                                        <thead>
                                            <tr>
                                                <th>Disease name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of dietGuideLines;">
                                                <td>
                                                    <div class="media">
                                                        <div class="media-body">
                                                            <h5 class="mb-0 mt-0 font-weight-normal text-truncate-line" [textContent]="item.name"></h5>
                                                        </div>
                                                    </div>
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="dashboard-box dashboard-table-box" *ngIf="(outSideTests && outSideTests !== null) || isView==true" #outsideTestsSection>
                            <h4 class="dashboard-title">Outside Tests</h4>
                            <div class="dashboard-body" *ngFor="let item of outSideTests">
                                <div class="overflow-auto">
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="item">
                                        <thead>
                                            <tr>
                                                <th>Done Date</th>
                                                <th>Test Name</th>
                                                <th>Other Tests</th>
                                                <th>Other Test Results</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="media">
                                                        <div class="media-body">
                                                            <h5 class="mb-0 mt-0 font-weight-normal text-truncate-line" [textContent]=" item.doneDate | date:'dd/MM/yyyy'"></h5>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p *ngIf="item.testname" [textContent]="item.testname"></p>
                                                </td>
                                                <td>
                                                    <p *ngIf="item.otherTests" [textContent]="item.otherTests "></p>
                                                </td>
                                                <td>
                                                    <p *ngIf="item.otherTestResults" [textContent]="item.otherTestResults "></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table *ngIf="item.parameters && item.parameters.length>0" class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th>SN</th>
                                                <th>Component</th>
                                                <th>Parameter</th>
                                                <th>Result Value</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr *ngFor="let items of item.parameters; let i=index">
                                                <td>
                                                    <p *ngIf="items.parameterName" [textContent]="i+1"></p>
                                                </td>
                                                <td>
                                                    <p *ngIf="items.componentName" [textContent]="items.componentName"></p>
                                                </td>
                                                <td>
                                                    <p *ngIf="items.parameterName" [textContent]="items.parameterName"></p>
                                                </td>
                                                <td>
                                                    <p *ngIf="items.resultValues" [textContent]="items.resultValues"></p>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="dashboard-box dashboard-table-box" #allergiesSection *ngIf="reminders && reminders !== null">
                            <h4 class="dashboard-title">Reminders</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div class="row col-12" *ngIf="reminders && reminders !== null">
                                        <div class="row col-12">
                                            <div *ngIf="reminders && reminders==null">
                                                There is no reminders
                                            </div>
                                        </div>
                                        <div class="col-12" *ngIf="reminders && reminders !== null">
                                            <div class="table-responsive horizontal-scroll">
                                                <table class="table table-sm mb-0">
                                                    <tr *ngIf="reminders">
                                                        <td class="col-8">Reminders</td>
                                                        <td class="col-4">
                                                            <span class=" text-wrap" [textContent]="reminders"></span><br />
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="dashboard-box dashboard-table-box" #allergiesSection *ngIf="allergies && allergies !== null">
                                <h4 class="dashboard-title">Allergies</h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <div *ngIf="allergies && allergies !== null">
                                            <div>
                                                <div *ngIf="allergies && allergies.noHistory && allergies.noHistory!=null ">
                                                    There is no allergy
                                                </div>

                                                <div class="dashboard-break" *ngIf="!allergies.noHistory && (allergies.drugNames!=null || allergies.drugNames!=null || allergies.drugReactions!=null
                             || allergies.dustNames!=null || allergies.dustReactions!=null || allergies.foodNames!=null || allergies.foodReactions!=null || allergies.otherNames!=null || allergies.otherReactions!=null)">
                                                    <div class="table-responsive horizontal-scroll">
                                                        <table class="table table-sm mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Allergy Type</th>
                                                                    <th>Allergy</th>
                                                                    <th>Reaction </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngIf="allergies.drugNames">
                                                                    <td class="col-4">Drug Allergy</td>
                                                                    <td class="col-4" [textContent]="allergies.drugNames"></td>
                                                                    <td class="col-4" [textContent]="allergies.drugReactions"></td>
                                                                </tr>
                                                                <tr *ngIf="allergies.foodNames">
                                                                    <td class="col-4">Food Allergy</td>
                                                                    <td class="col-4" [textContent]="allergies.foodNames"></td>
                                                                    <td class="col-4" [textContent]="allergies.foodReactions"></td>
                                                                </tr>
                                                                <tr *ngIf="allergies.dustNames">
                                                                    <td class="col-4">Dust Allergy</td>
                                                                    <td class="col-4" [textContent]="allergies.dustNames"></td>
                                                                    <td class="col-4" [textContent]="allergies.dustReactions"></td>
                                                                </tr>
                                                                <tr *ngIf="allergies.otherNames">
                                                                    <td class="col-4">Others</td>
                                                                    <td class="col-4" [textContent]="allergies.otherNames"></td>
                                                                    <td class="col-4" [textContent]="allergies.otherReactions"></td>
                                                                </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="dashboard-box dashboard-table-box" #problemListSection *ngIf="problemList">
                            <h4 class="dashboard-title">Past Medical History</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div *ngIf="isPastMedicalHistory">
                                        There is no past medical history
                                    </div>
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="!isPastMedicalHistory">
                                        <thead>
                                            <tr>
                                                <th>Disease name</th>
                                                <th>Year Of Diagnosis</th>
                                                <th>ICD</th>
                                                <th>Status</th>
                                                <th style="width: 250px">Treatment history</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of data">
                                                <td>
                                                    <div class="media">
                                                        <div class="media-body">
                                                            <h5 class="mb-0 mt-0 font-weight-normal text-truncate-line" [textContent]="item.usable"></h5>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p [textContent]="item.date ||'---'"></p>
                                                </td>
                                                <td [textContent]="item.code"></td>
                                                <td [textContent]="item.status||'--'"></td>
                                                <td>
                                                    <p class="mb-0 text-truncate-line" [textContent]="item.overRideMessage|| '-'"></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="dashboard-box dashboard-table-box" #surgerySection *ngIf="procedureSurgery">
                            <h4 class="dashboard-title">Past surgical history</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div *ngIf="isProcedureSurgery">
                                        There is no Surgical History
                                    </div>
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="procedureSurgery && procedureSurgery.length >0">
                                        <thead>
                                            <tr>
                                                <th class="col-4">Year Of Surgery</th>
                                                <th class="col-4">Surgeries</th>
                                                <th class="col-4">Comments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of procedureSurgery;">
                                                <td>
                                                    <div class="media">
                                                        <div class="media-body">
                                                            <h5 class="mb-0 mt-0 font-weight-normal text-truncate-line" [textContent]=" item.date"></h5>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="align-content-center">
                                                    <p *ngIf="item.surgeries" [textContent]="item.surgeries "></p>
                                                    <p class="pl-3" *ngIf="!item.surgeries" [textContent]="'-'"></p>
                                                </td>
                                                <td class="align-content-center">
                                                    <p *ngIf="item.comments" [textContent]="item.comments "></p>
                                                    <p class="pl-3" *ngIf="!item.comments" [textContent]="'-'"></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="dashboard-box dashboard-table-box" #familyHistorySection *ngIf="familyHistory">
                            <h4 class="dashboard-title">Family History</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div *ngIf="isFamilyHistory">
                                        There is no Family History
                                    </div>
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="familyHistory &&  familyHistory.length >0">
                                        <thead>
                                            <tr>
                                                <th>Relation</th>
                                                <th>Age/DOB</th>
                                                <th>Problem</th>
                                                <th>Status</th>
                                                <th>Comments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of familyHistory;">
                                                <td style="align-content:center">
                                                    <div class="media">
                                                        <div class="media-body">
                                                            <h5 class="mb-0 mt-0 font-weight-normal text-truncate-line" [textContent]=" item.relation"></h5>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style="align-content:center">
                                                    <p *ngIf="item.age" [textContent]="item.age  "></p>
                                                    <p style="padding-left: 25px" *ngIf="!item.age" [textContent]="'-'"></p>
                                                </td>
                                                <td style="align-content:center">
                                                    <p *ngIf="item.problem" [textContent]="item.problem "></p>
                                                    <p style="padding-left: 25px" *ngIf="!item.problem" [textContent]="'-'"></p>
                                                </td>
                                                <td style="align-content:center">
                                                    <p *ngIf="item.status" [textContent]="item.status "></p>
                                                    <p style="padding-left: 25px" *ngIf="!item.status" [textContent]="'-'"></p>
                                                </td>
                                                <td style="align-content:center">
                                                    <p *ngIf="item.comments" [textContent]="item.comments "></p>
                                                    <p style="padding-left: 25px" *ngIf="!item.comments" [textContent]="'-'"></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div *ngIf="familyHistory && familyHistory.length>0 && familyHistory[0].mainComment">
                                        <strong>Comments</strong>
                                        <div><span [textContent]="familyHistory[0].mainComment "></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="dashboard-box" #antinantalRiskSection *ngIf="antinantalRisk && antinantalRisk !== null ">
                            <h4 class="dashboard-title">Risk Factor</h4>
                            <div class="dashboard-body">
                                <div *ngIf="antinantalRisk && antinantalRisk.noRisk">
                                    There is No Risk
                                </div>
                                <table border="collapse" class="border dashboard-break table table-sm" *ngIf="antinantalRisk && !antinantalRisk.noRisk">
                                    <tr class="bg-soft-dark" *ngIf="antinantalRisk.characteristics && antinantalRisk.characteristics.length >0" style="border-bottom:1px solid black">
                                        <th colspan="2">Antenantal Risk</th>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.characteristics && antinantalRisk.characteristics.length >0">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Material characteristics</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.characteristics; let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i != antinantalRisk.characteristics.length-1) && (item && item !='')">,</span>
                                            </span>
                                        </td>
                                    </tr>

                                    <tr *ngIf="antinantalRisk.covidInfection && antinantalRisk.covidInfection != null" style="border-bottom:1px solid black">
                                        <td>
                                            <div class="font-14 font-weight-bold mt-0 mb-1">Covid-19 infection </div>
                                        </td>
                                        <td>
                                            <div><span [textContent]="antinantalRisk.covidInfection"></span> </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.details && antinantalRisk.details.length >0">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Previous Pregnancy Details</span>
                                        </td>

                                        <td>
                                            <span *ngFor="let item of antinantalRisk.details ;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf=" (i!= antinantalRisk.details.length-1) && (item && item !='')">,</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.maternalmedical && antinantalRisk.maternalmedical.length > 0">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Maternal Medical History</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.maternalmedical ;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="( i!= antinantalRisk.maternalmedical.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.uterineRiskFactor && antinantalRisk.uterineRiskFactor.length > 0">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Uterine Risk Factor</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.uterineRiskFactor ;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="( i!= antinantalRisk.uterineRiskFactor.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.pregnancyComplications && antinantalRisk.pregnancyComplications.length > 0" style="border-bottom:1px solid black">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Current Pregnancy Complications</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.pregnancyComplications ;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="( i!= antinantalRisk.pregnancyComplications.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.fetusComplications && antinantalRisk.fetusComplications.length > 0 || antinantalRisk.otherComplaints !==null">
                                        <td>
                                            <span class="font-14 font-weight-bold mt-0 mb-1">Fetus Complications</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.fetusComplications ;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf=" (i!= antinantalRisk.fetusComplications.length-1) && (item && item !='')">,</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk && antinantalRisk.otherComplaints !==null" style="border-bottom:1px solid black">
                                        <td>
                                            <span class="font-14 font-weight-bold mt-0 mb-1">Other</span>
                                        </td>

                                        <td>
                                            <span [textContent]="antinantalRisk.otherComplaints"></span>
                                        </td>
                                    </tr>
                                    <tr class="bg-soft-dark" *ngIf=" antinantalRisk.meternall.length > 0|| antinantalRisk.latePragnancy.length > 0 || antinantalRisk.currentPragnancy.length > 0 || antinantalRisk.funtalFetaus.length > 0 || antinantalRisk.maternalMedicalHistory.length > 0 || antinantalRisk.previousPregency.length > 0" style="border-bottom:1px solid black">
                                        <th colspan="2">SGA Screening</th>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.meternall && antinantalRisk.meternall.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Maternal Risk Factor</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.meternall ;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf=" (i!=  antinantalRisk.meternall.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.previousPregency && antinantalRisk.previousPregency.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Previous Pregnancy History</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.previousPregency ;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf=" (i!=  antinantalRisk.previousPregency.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.maternalMedicalHistory && antinantalRisk.maternalMedicalHistory.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Maternal Medical History</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.maternalMedicalHistory ;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf=" (i!=  antinantalRisk.maternalMedicalHistory.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>

                                    <tr *ngIf="antinantalRisk.funtalFetaus && antinantalRisk.funtalFetaus.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Unsuitable for monitoring Fundal Fetaus</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.funtalFetaus ;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf=" (i!=  antinantalRisk.funtalFetaus.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.currentPragnancy && antinantalRisk.currentPragnancy.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Current Pregnancy Complications - Early Pregnancy</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.currentPragnancy;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="( i!=   antinantalRisk.currentPragnancy.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.latePragnancy && antinantalRisk.latePragnancy.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Late Pregnancy</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.latePragnancy;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf=" (i!=  antinantalRisk.latePragnancy.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr class="bg-soft-dark" *ngIf="antinantalRisk.highRisk.length > 0||antinantalRisk.intermediateRisk.length > 0|| antinantalRisk.lowRisk.length > 0 || antinantalRisk.comments !=null " style="border-bottom:1px solid black">
                                        <th colspan="2">VTE Risk</th>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.highRisk && antinantalRisk.highRisk.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">High Risk</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.highRisk;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="( i!= antinantalRisk.highRisk.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.intermediateRisk && antinantalRisk.intermediateRisk.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Intermediate Risk</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.intermediateRisk;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="( i!= antinantalRisk.intermediateRisk.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.lowRisk && antinantalRisk.lowRisk.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Low Risk</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.lowRisk;let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf=" (i!= antinantalRisk.lowRisk.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.comments && antinantalRisk.comments != null" style="border-bottom:1px solid black">
                                        <td>
                                            <div class="font-14 font-weight-bold mt-0 mb-1">Other </div>
                                        </td>
                                        <td>
                                            <div><span [textContent]="antinantalRisk.comments"></span> </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>


                        <div #telephoneTriageAssessmentSection *ngIf="telephoneTriageAssessmentdata && telephoneTriageAssessmentdata !== null">
                            <div class="dashboard-box dashboard-table-box" *ngFor="let telephoneTriageAssessment of telephoneTriageAssessmentdata">
                                <h4 class="dashboard-title">TelePhone Triage Assessment </h4>
                                <div class="dashboard-body">
                                    <div class="overflow-auto">
                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">

                                            <tr>
                                                <td class="col-8">Call Date</td>
                                                <td class="col-4" [textContent]="telephoneTriageAssessment.callDate | date:'dd/MM/yyyy'"> </td>
                                            </tr>
                                            <tr>
                                                <td class="col-8">Call Time</td>
                                                <td class="col-4" [textContent]="telephoneTriageAssessment.callTime"> </td>
                                            </tr>
                                            <tr>
                                                <td class="col-8">Type of Consultation</td>
                                                <td class="col-4" [textContent]="telephoneTriageAssessment.typeofConsultation"> </td>
                                            </tr>
                                            <tr *ngIf="telephoneTriageAssessment && telephoneTriageAssessment.reasonForCall">
                                                <td class="col-4">Reason For Call</td>
                                                <td class="col-8">
                                                    <ul class="dashboard-ul row">
                                                        <li *ngFor="let item of  telephoneTriageAssessment.reasonForCall" [textContent]="item"></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="col-8">Other</td>
                                                <td class="col-4" [textContent]="telephoneTriageAssessment.reasonothers"> </td>
                                            </tr>
                                            <tr *ngIf="telephoneTriageAssessment && telephoneTriageAssessment.adviceGiventoPatient">
                                                <td class="col-4">Advice Given To Patient</td>
                                                <td class="col-8">
                                                    <ul class="dashboard-ul row">
                                                        <li *ngFor="let item of  telephoneTriageAssessment.adviceGiventoPatient" [textContent]="item"></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="col-8"> Others</td>
                                                <td class="col-4" [textContent]="telephoneTriageAssessment.adviceother"> </td>
                                            </tr>
                                            <tr *ngIf="telephoneTriageAssessment && telephoneTriageAssessment.actionTakenIfAdvisedToVisit">
                                                <td class="col-4">ActionTakenIfAdvisedToVisit</td>
                                                <td class="col-8">
                                                    <ul class="dashboard-ul row">
                                                        <li *ngFor="let item of  telephoneTriageAssessment.actionTakenIfAdvisedToVisit" [textContent]="item"></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="col-8">Others</td>
                                                <td class="col-4" [textContent]="telephoneTriageAssessment.actionother"> </td>
                                            </tr>
                                            <tr *ngIf="telephoneTriageAssessment && telephoneTriageAssessment.doctorName">
                                                <td class="col-8">Doctor Name</td>
                                                <td class="col-4 text-uppercase" [textContent]="telephoneTriageAssessment.doctorName"> </td>
                                            </tr>
                                            <tr *ngIf="telephoneTriageAssessment && telephoneTriageAssessment.callCompletedDate">
                                                <td class="col-8">Call Completed Date</td>
                                                <td class="col-4" [textContent]="telephoneTriageAssessment.callCompletedDate | date:'dd/MM/yyyy'"> </td>
                                            </tr>
                                            <tr *ngIf="telephoneTriageAssessment && telephoneTriageAssessment.callcompletedTime">
                                                <td class="col-8">Call Completed Time</td>
                                                <td class="col-4" [textContent]="telephoneTriageAssessment.callcompletedTime"> </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </ng-container>
                <div *ngIf="isFooter">
                    <banner-setting [reportName]='"Diet Plan Prescription Report Footer"'></banner-setting>
                </div>
            </div>
        </div>
    </div>
</div>