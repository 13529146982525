
<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">Charges Configuration</h4>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card-box p-1">
                    <table class="table table-borderless table-sm mb-0 ">
                        <tr class="d-flex align-items-center">
                            <td class="col-6 col-sm-4 col-lg-2">
                                <div class="form-group mb-0">
                                    <label class="mb-1">Charge Name</label>
                                    <input placeholder="Enter Charge Name" [(ngModel)]="filters.options.chargeName" type="text" class="form-control" />
                                </div>
                            </td>
                            <td class="col-6 col-sm-4 col-lg-2">
                                <div class="form-group mb-0">
                                    <label class="mb-1">Charge Group Name</label>
                                    <input placeholder="Enter Charge Group Name" [(ngModel)]="filters.options.chargeGroupName" type="text" class="form-control" />
                                </div>
                            </td>
                            <td class="col-6 col-sm-4 col-lg-2">
                                <div class="white-space-nowrap d-flex justify-content-center mtop">
                                    <button type="button"
                                            (click)="onApplyFilters()"
                                            class="btn btn-sm btn-primary rounded-pill">
                                        <i class="mdi mdi-magnify mr-1"></i>Search
                                    </button>
                                    <button type="button"
                                            (click)="onResetFilters()"
                                            class="btn btn-sm reset-btn rounded-pill ml-1">
                                        <i class="mdi mdi-backup-restore mr-1"></i>Reset
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="loading">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading  charges configuration...</span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!loading && (!records || !records.length)">
            <div class="col-12 mh-300">
                <div class="no-data">
                    <img src="assets/images/no-data.png" alt="No data" />
                    <p class="sub-title">There is no data to show you right now.</p>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="records && records.length >0">
            <div class="col-12">
                <div class="card mb-0">
                    <div class="card-body p-0">
                        <div class="overflow-auto">
                            <table class="table table-centered table-sm table-striped table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th>Charge Name</th>
                                        <th>Charge Group Name</th>
                                        <th>Created By</th>
                                        <th>Created Date</th>
                                        <th>Modified By</th>
                                        <th>Modified Date</th>
                                        <th>Is Automatic Add</th>
                                        <th class="text-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of records;">
                                        <td [textContent]="item.chargeName"></td>
                                        <td [textContent]="item.chargeGroupName"></td>
                                        <td>
                                            <span class="mb-0" [textContent]="item.createdByName || '---'"></span><br />
                                            <span class="badge badge-outline-success" [textContent]="item.createdByRole"></span>
                                        </td>
                                        <td [textContent]="item.createdDate | date:'dd-MM-yyyy, hh:mm a'"></td>
                                        <td>
                                            <span class="mb-0" [textContent]="item.modifiedByName || '---'"></span><br />
                                            <span class="badge badge-outline-blue" [textContent]="item.modifiedByRole"></span>
                                        </td>
                                        <td [textContent]="item.modifiedDate | date:'dd-MM-yyyy, hh:mm a'"></td>
                                        <td>
                                            <div class="media">
                                                <span class="badge badge-success" *ngIf="item.isAutomaticAdd">True</span>
                                                <span class="badge badge-soft-danger" *ngIf="!item.isAutomaticAdd">False</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex align-items-center justify-content-end">
                                                <div>
                                                    <a href="javascript:;" class="text-danger" *ngIf="item.isAutomaticAdd" placement="left" ngbTooltip="Inactive" (click)="onModifyStatus(item,false)">
                                                        <i class="mdi mdi-close-thick"></i>
                                                    </a>
                                                    <a href="javascript:;" class="text-success" *ngIf="!item.isAutomaticAdd" placement="left" ngbTooltip="Active" (click)="onModifyStatus(item,true)">
                                                        <i class="mdi mdi-check-bold"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="10">
                                            <nav class="d-flex align-items-center justify-content-between p-0">
                                                <p class="mb-0 font-13">
                                                    <span class="text-dark">
                                                        Page
                                                        <span [textContent]="pagination.currentPage"></span>
                                                        of
                                                        <span [textContent]="pagination.totalPages"></span>
                                                    </span>
                                                    <span class="ml-1">
                                                        <span>
                                                            (Showing
                                                            <span [textContent]="pagination.currentItems - records.length + 1"></span>
                                                            -
                                                            <span [textContent]="pagination.currentItems"></span>
                                                            of
                                                            <span [textContent]="pagination.totalItems"></span>
                                                            Charge Configuration )
                                                        </span>
                                                    </span>
                                                </p>
                                                <ngb-pagination class="pagination justify-content-end"
                                                                [maxSize]="5"
                                                                [rotate]="true"
                                                                [ellipses]="true"
                                                                [(page)]="pagination.pageIndex"
                                                                [pageSize]="pagination.pageSize"
                                                                (pageChange)="onNextPage()"
                                                                [collectionSize]="pagination.totalItems">
                                                    <ng-template ngbPaginationPrevious>
                                                        <i class="fe-arrow-left"></i>
                                                    </ng-template>
                                                    <ng-template ngbPaginationNext>
                                                        <i class="fe-arrow-right"></i>
                                                    </ng-template>
                                                </ngb-pagination>
                                            </nav>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

