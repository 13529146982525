import { OnInit, OnDestroy, Component, ViewEncapsulation, AfterViewInit, HostListener, Input } from "@angular/core";

import { takeUntil, finalize } from "rxjs/operators";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Appointment, PatientMedicationHeader, PreviousAppointment } from "@shared/entities";
import { LabBillHeaderModel } from "@admin/labs/pages/models";
import { AppConfig, HttpService, AppointmentToggleService, TimelineToggleService, IconService, PrintOptionService, appUrls, MenuService, AppData, ResourceService } from "@shared/services";
import { Page } from "../../models/page.model";
import { IEncounterResource } from "../../models/encounter-resource.model";
import { IResource } from "../../models/resource.model";
import { ObEncounter } from "../../models/ob-encounter.model";
import { OB } from "../../../areas/admin/full-transcript/pages/ob-report/ob.namespace";
import { PatientProfile } from "../../models/patient-profile.model";
import { OBEncounterType } from "../../enums/ob-encounter.enum";
import { ApiResources } from "../../helpers/api.helper";
import { IUserAccount } from "../../models/user-account.model";


@Component({
    templateUrl: "./postnatalvisit-report.html",
    selector: "postnatalvisit-report",
    encapsulation: ViewEncapsulation.None
})

export class PostnantalVisitReportPage implements OnInit, OnDestroy, AfterViewInit {
    @Input() printId: string;
    @Input() printadmission: boolean;
    page: Page;
    submitting: boolean;
    submitted: boolean;
    redirecting: boolean;
    routingValue: string;
    encryptedAppointmentId: string;
    section: string;
    nextRoute: string;
    postnantalVisitEncounter: IEncounterResource;
    isAdmission: boolean;
    encryptedPatientId: string;
    providers: Array<IResource>;
    isANCCardGeneration = false;
    appointmentId: string;
    obEncounter: ObEncounter;
    measure: OB.Measure;
    providerId: number;
    complains = ["Anal incontinence", "Excess Bleeding", "Fever", "Inadequate lactation", "Incontinence of urine", "None", "Others", "Pain Abdominal", "Pain Vaginal", "Wound discharge"];
    medicationAndAdvice = ["Iron for three months", "Calcium till lactation"];
    postnantalExercise = ["ABDOMINAL", "KEGELS", "CONTRACEPTIONS"];
    postnantalModel: OB.PostnantalVisit;
    date: Date;
    appointment: Appointment;
    patient: PatientProfile;
    records: Array<PatientMedicationHeader>;
    bookingHeader: Array<LabBillHeaderModel>;
    ordersPrescription: OB.OrdersPrescription;
    currentLocation: string;
    locations: Array<IResource>;
    locationId: number;
    signature: string;
    printName: string;
    referralOrder: Array<OB.OrderReferral>;
    scanTest: OB.ScanTest;
    isPrintLogo: boolean;
    isFooter: boolean;
    constructor(
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly menuservice: MenuService,
        private readonly httpService: HttpService,
        private readonly formBuilder: FormBuilder,
        private readonly resourceService: ResourceService,) {

        this.page = new Page();
        this.date = new Date();
        this.records = new Array<PatientMedicationHeader>();
        this.bookingHeader = new Array<LabBillHeaderModel>();
    }
 


    onReset() {
        this.page.loaded = true;
     
        this.find(this.encryptedAppointmentId);
    }

    find(appointmentId: string) {
        const request = {
            encryptedAppointmentId: appointmentId,
            type: OBEncounterType.PostnanatalVisit,
            isAdmission: this.isAdmission
        };
        this.httpService.post<IEncounterResource>(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.findDashboard), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: IEncounterResource) => {
                this.postnantalVisitEncounter = response;
                if (this.postnantalVisitEncounter.jsonString) {
                    this.postnantalModel = JSON.parse(this.postnantalVisitEncounter.jsonString);
                }
            });
    }


 
    private findAppointment(appointmentId: string) {
        const request = {
            encryptedAppointmentId: appointmentId
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointment = response
                this.findPatientDetails();
                this.fetchLabs();
                this.findDashboard();
            });
    }

    private findPatientDetails() {
        this.page.loading = true;
        this.httpService.post<PatientProfile>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.profile), { encryptedPatientId: this.appointment.encryptedPatientId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: PatientProfile) => {
                if (response) {
                    this.patient = response;

                }
            });
    }
    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    this.locationId = this.page.userAccount.locationId;
                    this.printName = this.page.userAccount.fullName;
                    this.isAdmission = url.split("/")[4] === "a";
                    const appointmentId = decodeURIComponent(url.split("/")[url.split("/").length - 3]);
                    this.appointmentId = appointmentId;
                    this.routingValue = url.split("/")[url.split("/").length - 4];
                    this.encryptedAppointmentId = appointmentId;

                    this.encryptedAppointmentId = this.printId
                    this.isAdmission = this.printadmission
                    this.findAppointment(this.encryptedAppointmentId);
                    this.find(this.appointmentId);
                    this.fetchMedication();
                    this.fetchLocations();

                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe(async (params: Params) => {
                            const section = params["section"];
                            this.section = section ? section : undefined;
                            this.nextRoute = await this.menuservice.getNextRoute(url, params, this.route);
                        });
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }
    ngAfterViewInit() {
        this.paddingTop();
    }
    @HostListener('window:load')
    onLoad() {
        setTimeout(() => { this.paddingTop(); }, 1500)
    }
    @HostListener('window:hashchange')
    onhashchange() {
        setTimeout(() => { this.paddingTop(); }, 500)
    }
    @HostListener('window:resize')
    onResize() {
        this.paddingTop();
    }
    paddingTop() {
        if (document.getElementById("header") != null && document.getElementById("postnantalReportContainer") != null) {
            var paddingTopValue = document.getElementById("header").offsetHeight;
            var content = document.getElementById("postnantalReportContainer");
            content.setAttribute('style', `padding-top:${paddingTopValue}px`);
        }
    }


    private fetchMedication() {
        const request = {
            encryptedAppointmentId: this.appointmentId,
            isAdmission: this.isAdmission
        }

        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                this.records = response;
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });

    }
    private fetchLabs() {
        const request = {
            appointmentId: this.appointment.appointmentId,
            isAdmission: this.isAdmission
        }
        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetchLabs), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                if (response && response.length > 0) {
                    this.bookingHeader = response[0].labs;
                }
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });
    }


    private findDashboard() {
        const request = {
            encryptedAppointmentId: this.appointmentId,
            //encryptedProviderId: this.page.userAccount && this.page.userAccount.encryptedReferenceId ? this.page.userAccount.encryptedReferenceId : null,
            //encryptedPatientId: this.appointment.patientId,
            isAdmission: this.isAdmission
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<[ObEncounter, Appointment, PreviousAppointment]>(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.findEncounterData), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: [ObEncounter, Appointment, PreviousAppointment]) => {
                this.obEncounter = response["dashboard"];
                if (this.obEncounter && this.obEncounter.obEncounterId) {
                    this.ordersPrescription = this.obEncounter.orderPrescription ? JSON.parse(this.obEncounter.orderPrescription) : null;
                    this.referralOrder = this.obEncounter.refferalOrder ? JSON.parse(this.obEncounter.refferalOrder) : null;
                    this.scanTest = this.obEncounter.scanTests ? JSON.parse(this.obEncounter.scanTests) : null;
                }
            });
    }

    private fetchLocations() {
        this.resourceService.locations()
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.locations = response;
                var currentlocation = this.locations.find(s => s.id == this.locationId);
                this.currentLocation = currentlocation.name;
            });
    }
    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }
    onChangePrintType(islogo: boolean) {
        this.isPrintLogo = islogo;
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
}
