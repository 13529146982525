/* eslint-disable @typescript-eslint/no-namespace */
import { SrvRecord } from "dns";
import { Time } from "@angular/common";

export namespace OB {



    export class Vitals {
        height: string;
        heightUnits: string;
        weight: string;
        weightUnits: string;
        respiratory: string;
        pulseOximetry: string;
        temperature: string;
        heartRhythm: string;
        systolicBP: string;
        diastolicBP: string;
        bmi: string;
        randomGlucose: string;
        hba1c: string;
        fastingGlucose: string;
        t4: string;
        t3: string;
        tsh: string;
    }

    export class ImgModel {
        base64String: any;
        type: string;
        edited: boolean;
    }

    export class SkinEncounter {
        inspection: string;
        comments: string;
    }

    export class Skin {
        images: Array<ImgModel>;
        gynEncounterId: number;
        type: number;
        encryptedAppointmentId: string;
        modifiedBy: number;
        createdBy: number;
        jsonString: string;
        isAdmission: any;
        constructor() {
            this.images = new Array<ImgModel>();
        }
    }

    export class Notes {
        notes: string;
    }

    export class GeneticScreening {
        patientAge: string;
        isThalassemia: string;
        isDownSyndrome: string;
        isNeuralTubeDefect: string;
        isJewishBackground: string;
        isSickleCell: string;
        isHemophilia: string;
        isMuscularDystrophy: string;
        isCysticFibrosis: string;
        isHuntington: string;
        isMentalRetardation: string;
        isChromosomal: string;
        isBirthDefects: string;
        isFirstTrimester: string;
        isMedication: string;
        geneticConselling: string;
        isSMA: string;
        otherComments: string;
        isGeneticConselling: string;
    }

    export class InfectionHistory {
        aids: string;
        isHepatities: string;
        isLivewithSomeone: string;
        isGenitalHerpes: string;
        isIllness: string;
        isHistory: string;
        isBloodTransfusion: string;
        isVaricella: string;
        isToxoplasmosis: string;
        otherComments: string;
    }
    export class MusculoskeletalExamOfGaint {
        normal: string;
        abnormal: string;
        gaintComment: string;
        examOfGaintValue: string;
    }
    export class MusculoskeletalExam {
        joints: boolean;
        bones: boolean;
        muscles: boolean;
        examComment: string
    }
    export class MusculoskeletalInspection {
        clubbing: string;
        cyanosis: string;
        petechiea: string;
        inflammatoryCondition: string;
        nodes: string;
        ischemicInfection: string;
        inspectionComment: string;
    }
    export class Musculoskeletal {
        images: string;
        examOfGaint: MusculoskeletalExamOfGaint;
        exam: MusculoskeletalExam;
        inspection: MusculoskeletalInspection;
        comments: string;
        jsonString: string;

        constructor() {
            this.examOfGaint = new MusculoskeletalExamOfGaint();
            this.inspection = new MusculoskeletalInspection();
            this.exam = new MusculoskeletalExam();
        }
    }
    export class MusculoSkeletalEncounter {
        images: string;
        examOfGaint: MusculoskeletalExamOfGaint;
        exam: MusculoskeletalExam;
        inspection: MusculoskeletalInspection;
        comments: string;
        jsonString: string;
    }

    export class LymphaticNeck {
        normal = false;
        consistency = false;
        enlarged = false;
        tender = false;
        neckValue: string;
        comments: string;
    }
    export class LymphaticAxillary {
        normal = false;
        consistency = false;
        enlarged = false;
        tender = false;
        axillaryValue: string;
        comments: string;
    }
    export class LymphaticGroin {
        normal = false;
        consistency = false;
        enlarged = false;
        tender = false;
        groinValue: string;
        comments: string;
    }
    export class LymphaticOther {
        normal = false;
        consistency = false;
        enlarged = false;
        tender = false;
        otherValue: string;
        comments: string;
    }
    export class Lymphatic {
        images: string;
        neck: LymphaticNeck;
        axillary: LymphaticAxillary;
        groin: LymphaticGroin;
        other: LymphaticOther;
        jsonString: string;

        constructor() {
            this.neck = new LymphaticNeck();
            this.axillary = new LymphaticAxillary();
            this.groin = new LymphaticGroin();
            this.other = new LymphaticOther();
        }
    }
    export class LymphaticEncounter {
        images: string;
        neck: LymphaticNeck;
        axillary: LymphaticAxillary;
        groin: LymphaticGroin;
        other: LymphaticOther;
        jsonString: string;
    }

    export class RespiratoryEffort {
        normal = false;
        retractions = false;
        accessoryMuscles = false;
        effortValue: string;
    }
    export class RespiratoryAxillary {
        normal = false;
        egophony = false;
        inspiratoryWheezes = false;
        expiratoryWheezes = false;
        rales = false;
        bronchi = false;
        breadthsSounds = false;
        L = false;
        R = false;
    }
    export class RespiratoryGroin {
        normal = false;
        dullness = false;
        hyperresonance = false;
        L = false;
        R = false;
    }
    export class Respiratory {
        effort: RespiratoryEffort;
        axillary: RespiratoryAxillary;
        groin: RespiratoryGroin;

        constructor() {
            this.effort = new RespiratoryEffort();
            this.axillary = new RespiratoryAxillary();
            this.groin = new RespiratoryGroin();
        }
    }

    export class CvAusculation {
        normal = false;
        mumur = false;
        clicks = false;
        rubs = false;
        ausculationValue: string;
        comments: string;
    }
    export class CvRhythm {
        regular: string;
        irregular: string;
        rhythmValue: string;
        comments: string;
    }
    export class CvExtremities {
        cyanosis: string;
        calfTenderness: string;
        varicosities: string;
        edema: string;
        temperature: string;
        comments: string;
    }
    export class CvPulses {
        palpated: string;
        notPalpated: string;
        pulsesValue: string;
    }
    export class CV {
        ausculation: CvAusculation;
        rhythm: CvRhythm;
        extremities: CvExtremities;
        pulses: CvPulses;

        constructor() {
            this.ausculation = new CvAusculation();
            this.rhythm = new CvRhythm();
            this.extremities = new CvExtremities();
            this.pulses = new CvPulses();
        }
    }

    export class BirthControlMain {
        none = false;
        comments: string;
    }
    export class BirthControlSub {
        spermicides = false;
        orthoEvraPatch = false;
        oc = false;
        transdermal = false;
        vagRing = false;
        condoms = false;
        diaphragm = false;
        rhythm = false;
        withdrawal = false;
        tubalLigation = false;
        vasectomy = false;
        hysterectomy = false;
        navaring = false;
        otherBarrier = false;
        depoProvera = false;
        IUD = false;
        nexplanon = false;
        otherBarriercomments: string;
        depoProveracomments: string;
        IUDcomments: string;
        nexplanoncomments: string;
    }
    export class BirthControl {
        main: BirthControlMain;
        sub: BirthControlSub;
        jsonString: string;
        constructor() {
            this.main = new BirthControlMain();
            this.sub = new BirthControlSub();
        }
    }
    export class BirthControlEncounter {
        main: BirthControlMain;
        sub: BirthControlSub;
        jsonString: string;
    }

    export class AbdomenPalpation {
        normal: boolean;
        softNonTender: boolean;
        masses: boolean;
        tenderness: boolean;
        obese: boolean;
        rebound: boolean;
        hemia: boolean;
        distended: boolean;
        scars: boolean;
        CVtenderness: boolean;
        comments: string;
    }
    export class AbdomenHepatomegaly {
        none: boolean;
        comments: string;
    }
    export class AbdomenBowlSounds {
        normal: boolean;
        active: boolean;
        highPitched: boolean;
        absent: boolean;
        comments: string;
        bowlSoundValue: string;
    }
    export class AbdomenIncision {
        wellHealed: boolean;
        erythema: boolean;
        tender: boolean;
        discharged: boolean;
        dehiscence: boolean;
        incisionValue: string;
    }
    export class AbdomenStoolGuiac {
        negative: boolean;
        positive: boolean;
        stoolGuiacValue: string;
    }
    export class Abdomen {
        palpation: AbdomenPalpation;
        hepatomegaly: AbdomenHepatomegaly;
        bowlSounds: AbdomenBowlSounds;
        incision: AbdomenIncision;
        stoolGuiac: AbdomenStoolGuiac;
        images: string;
        jsonString: string;

        constructor() {
            this.palpation = new AbdomenPalpation();
            this.hepatomegaly = new AbdomenHepatomegaly();
            this.bowlSounds = new AbdomenBowlSounds();
            this.incision = new AbdomenIncision();
            this.stoolGuiac = new AbdomenStoolGuiac();
        }
    }
    export class AbdomenEncounter {
        palpation: AbdomenPalpation;
        hepatomegaly: AbdomenHepatomegaly;
        bowlSounds: AbdomenBowlSounds;
        incision: AbdomenIncision;
        stoolGuiac: AbdomenStoolGuiac;
        images: string;
        jsonString: string;
    }

    export class SkinRashes {
        normal: string;
        abnormal: string;
        rashValue: string;
        comments: string;
    }
    export class SkinInspection {
        papular: string;
        erythema: string;
        scaly: string;
        ulcers: string;
        brownBlack: string;
        pustular: string;
        vascular: string;
        demarcated: string;
        rash: string;
        macular: string;
        irregularMargin: string;
        vesicular: string;
        ulcerated: string;
        umbilicated: string;
        warty: string;
        flat: string;
        raised: string;
        dry: string;
        weeping: string;
        comments: string;
    }
    export class SkinPalpation {
        normal: string;
        induration: string;
        nodules: string;
        tender: string;
        palpationValue: string;
        comments: string;
    }
    export class GynSkin {
        rashes: SkinRashes;
        inspection: SkinInspection;
        palpation: SkinPalpation;
        images: string;
        jsonString: string;
        constructor() {
            this.rashes = new SkinRashes();
            this.inspection = new SkinInspection();
            this.palpation = new SkinPalpation();
        }
    }

    export class MenopausalHistory {
        none: string;
        motherAge: string;
        vaginalDryness: string;
        libidoChanges: string;
        hotFlashes: string;
        urinary: string;
        painfulIntercourse: string;
        mooodChanges: string;
        lowSexDrive: string;
        hysterectomy: string;
        hRTHistory: string;
        comments: string;
        otherComments: string;
    }

    export class LastPelvicUltraSound {
        date: string;
        lastPelvicSound: string;
        comments: string;
    }

    export class LastDexaScan {
        date: string;
        boneDensity: string;
        comments: string;
    }

    export class LastMammogram {
        date: string;
        result: string;
        radiologist: string;
        comments: string;
    }

    export class CancerHistory {
        date: string;
        ailments: string;
        relation: string;
        age: number;
        origin: string;
        comments: string;
    }

    export class AdditionalLabs {
        labs: string;
        date: string;
        result: string;
        comments: string;
    }

    export class GynLab {
        date: string;
        gynLab: string;
        comments: string;
    }

    export class GynHistory {
        lastPap: string;
        HPVStatus: string;
        colposcopyStatus: string;
        leep: string;
        cryoSurgery: string;
        coneBiopsy: string;
        endometriosis: string;
        fibroids: string;
        lastPelvicUltrasound: string;
        dysmenorrhea: string;
        tumors: string;
        PID: string;
        infertility: string;
        desExposure: string;
        abnormalReproductiveOrgans: string;
    }
    export class SexualHistory {
        sexuallyActive: string;
        firstSexual: string;
        monogamous: string;
        sexualPartners: string;
        noOfTimesWithCurrentPartner: string;
    }
    export class SexualTransmittedDiseases {
        herpes: string;
        gonorrhea: string;
        chlamydia: string;
        syphillis: string;
        PID: string;
        HPV: string;
        condyloma: string;
        HIV: string;
        scabies: string;
        trichomoniasis: string;
        warts: string;
        comments: string;
        yes: boolean;
        no: boolean;
    }
    export class GynGynHistory {
        history: GynHistory;
        sexualHistory: SexualHistory;
        sexualTransmittedDiseases: SexualTransmittedDiseases;
        constructor() {
            this.history = new GynHistory();
            this.sexualHistory = new SexualHistory();
            this.sexualTransmittedDiseases = new SexualTransmittedDiseases();

        }
    }

    export class MensturalHistory {
        flow: string;
        duration: string;
        ageofMenopause: string;
        menarche: string;
        cycleLength: string;
        cycleLengthComments: string;
        LMP: string;
        LMPComment: string;
        qualityofLMP: string;
        qualityofLMPValue: string;
    }

    export class BreastScreening {
        fibrocysticBreastDisease: string;
        monthlySelfBreastExam: string;
        lastBreastUltrasound: string;
        lastBreastUltrasoundComment: string;
        lastMammogram: string;
        lastMammogramComment: string;
    }

    export class Breast {
        inspection: string;
        inspectionValue: string;
        inspectionComment: string;
        normal: string;
        abnormal: string;
        palpationValue: string
        massRight: string;
        massLeft: string;
        tendernessRight: string;
        tendernessLeft: string;
        skinChangeRight: string;
        skinChangeLeft: string;
        nippleDischargeRight: string;
        nippleDischargeLeft: string;
        palpableRight: string;
        palpableLeft: string;
        mastectomyRight: string;
        mastectomyLeft: string;
        moreComments: string;
        implantRight: string;
        implantLeft: string;
        selfBreastExam: string;
        images: string;
        jsonString: string;
    }

    export class Heent {
        normal = false;
        abnormal = false;
        comments: string;
    }
    export class HeentThyroid {
        normal = false;
        abnormal = false;
        notEnlarged = false;
        enlarged = false;
        notenlarged = false;

        comments: string;
    }

    export class HeentOther {
        normal = false;
        consistency = false;
        enlarged = false;
        tender = false;
        comments: string;
    }

    export class AncCardClosure {
        ancCardClosed: string;
        reason: string;
        comment: string;

    }

    export class AncCardGeneration {
        mrNo: string;
        name: string;
        age: string;
        sex: string;
        ancNo: string;
        ancNoExists: string;
        husbandName: string;
        husbandAge: number;
        remarks: string;
        consultantDoctor: string;
        registrationDate: Date;
        department: string;
        providerId: string;
        patientId: string;
        ancCardGenerationId: number;
        gynCardGenerationId: number;
        active: boolean;
        rchidNo: string;
    }

    export class Complaints {
        complaintName: string;
        highRisk: string
    }
    export class ObstetricExamination {
        uterusPalpable: string;
        fundusHeight: string;
        sFHeight: string;
        presentation: string;
        fsh: string;
        amountOfLiquor: string;
        oedema: string;
    }



    export class VaginalExamination {
        pelvis: string;
        others: string;
        tetanus: string;
        stationOfPP: string;
        liquor: string;
        presentationPart: string;
        cervix: string;
        externalGenitalia: string;
        psDischarge: string;
        psVagina: string;
        psCervix: string;
    }

    export class BreastExamination {
        inference: string;
        information: string;
        summary: string;

    }

    export class PlanOfManagement {
        usgDate: string;
        typeOfUltraSoundScan: string;
        efw: string;
        usgReport: string;
        doctor: string;
        otherDoctor: string;
        reviewDate: string;
    }

    export class ObBirthHistory {
        pregnancyNo: string;
        deliveryAt: string;
        pregnancyStatus: string;
        yearOfBirth: string;
        gaWeeks: string;
        noOfFetus: number;
        birthOrder: string;
        modeOfDelivery: string;
        indication: string;
        sexOfBaby: string;
        statusOfBaby: string;
        birthWeight: string;
        missCarriage: string;
        reasonForErpc: string;
        conception: string;
        antiDGiven: string;
        booldTranfaction: string;
        breastFeeding: string;
        hypertension: string;
        other: string;


        pogAtDiagnosis: string;
        status: string;
        side: string;
        site: string;
        ectopictTreatement: string;
        surgicalTreatement: string;
        medicalTreatement: string;
        procedure: string;
        heterotropicPregnancy: string;
        heteroPregDt: string;
        gaAtDiagnosis: string;
        typeOfMole: string;
        hpe: string;
        treatement: string;
        adress: string;

        birthOrder2: string;
        modeOfDelivery2: string;
        indication2: string;
        sexOfBaby2: string;
        statusOfBaby2: string;
        birthWeight2: string;

        birthOrder3: string;
        modeOfDelivery3: string;
        indication3: string;
        sexOfBaby3: string;
        statusOfBaby3: string;
        birthWeight3: string;


        birthOrder4: string;
        modeOfDelivery4: string;
        indication4: string;
        sexOfBaby4: string;
        statusOfBaby4: string;
        birthWeight4: string;
        ifNndAge: string;
        causeOfDeath: string;
        ifNndAge2: string;
        causeOfDeath2: string;
        ifNndAge3: string;
        causeOfDeath3: string;
        ifNndAge4: string;
        causeOfDeath4: string;
        pastHistory: Array<string>;
        deliveryDetail: Array<string>;
        ga: string;
        chemotherapyRegime:number

    }
    export class ProcedureSurgery {
        date: string;
        surgeries: Array<string>;
        status: string;
        comments: string;
        isProcedureSurgery: boolean;

    }

    export class MenstrualHistory {
        yearOfMarriage: string;
        gravida: string;
        para: string;
        abortion: string;
        living: string;
        menstrualPattern: string;
        conception: string;
        contraceptives: string;
        consanguinity: string;
        deliveryStatus: string;
        presentPregnencyDetails: string;
        days: string;
        bloodGroup: string;
        rhType: string;
        lMPStatus: string;
        gA: string;
        pregnancyPlan: string;
        lmp: string;
        eddType: string;
        edd: string;
        ultrasoundEdd: string;

    }
    export class MenstrualEDD {


    }
    export class BloodGroupDetail {

    }
    export class LMPANDEDD {

    }
    export class PregnancyPlan {

    }
    export class OtherDetail {
        husbandBloodGroup: string;
        rhType: string;
        hiv: string;
        hbsAg: string;
        smoking: string;
        alcohol: string;
        otherSubstances: string;
        covidVaccine: string;
        cordBloodBanking: string;
        prenatalScreeningOffer: string;
        diet: string;
        lifeStyle: string;
        toiletPreference: string;
        financialCouncelling: string;
        timeTakenToReachHospital: string;
        distanceInKilometers: string;
        prePreWeight: string;

    }
    export class PartnerDetail {

    }
    export class RiskBehavior {

    }
    export class OtherOtherDetails {

    }
    export class DrugHistory {

        fetalLungMaturity: string;
        day1: string;
        day2: string;
        givenBy: string;
        date1: string;
        indication1: string;
        dose1: string;
        byDr1: string;
        date2: string;
        indication2: string;
        dose2: string;
        byDr2: string;
        neonatalCounseling: string;
    }
    export class SteroidAdministration {


    }
    export class AntiDAdministration {

    }
    export class OrdersPrescription {
        importantAdvice: string;
        maternalPlan: string;
        fetlaPlan: string;
        prescription: string;
        invetigationTemplate: string;
        regerrals: string;
        proceduresTemplates: string;
        diagnosisRemarks: string;
        pragnancyPlan: string;
        prePregancyCouncellingDetails: string;
        orderName: [];
        orderData: string;
    }


    export class AntinantalRisk {
        maternalmedical: Array<string>;
        uterineRiskFactor: Array<string>;
        pregnancyComplications: Array<string>;
        fetusComplications: Array<string>;
        characteristics: Array<string>;
        details: Array<string>;
        otherComplaints: string;
        covidInfection: string;

        highRisk: Array<string>;
        intermediateRisk: Array<string>;
        lowRisk: Array<string>;
        comments: string;


        meternall: Array<string>;
        latePragnancy: Array<string>;
        currentPragnancy: Array<string>;
        funtalFetaus: Array<string>;
        maternalMedicalHistory: Array<string>;
        previousPregency: Array<string>;
        vteOtherComplaints: Array<string>;
        noRisk: boolean;
    }
    export class MaternalRiskFactor {
        meternall: string;
        ongoingSmoker: string;
        drugMisuse: string;
    }
    export class PreviousPregnancyHistory {
        sgaBaby: string;
        stillbirth: string;
    }
    export class MaternalMedicalHistory {
        chronicHypertension: string;
        diabeticsMellitus: string;
        rentalImpairment: string;
        antiphosphoilipid: string;
    }
    export class FuntalFetaus {
        largeFibroids: string;
        bmi: string;
        polyhydramnios: string;

    }
    export class CurrentPragnancy {
        papp: string;
        echogenic: string;
    }
    export class LatePragnancy {
        hypertension: string;
        antepartum: string;
        none: string;
    }
    export class SGAScanning {
        maternal: MaternalRiskFactor;
        previousPregency: PreviousPregnancyHistory;
        maternalMedicalHistory: MaternalMedicalHistory;
        funtalFetaus: FuntalFetaus;
        currentPragnancy: CurrentPragnancy;
        latePragnancy: LatePragnancy;
        constructor() {
            this.maternal = new MaternalRiskFactor();
            this.previousPregency = new PreviousPregnancyHistory();
            this.maternalMedicalHistory = new MaternalMedicalHistory();
            this.funtalFetaus = new FuntalFetaus();
            this.currentPragnancy = new CurrentPragnancy();
            this.latePragnancy = new LatePragnancy();

        }
    }
    export class VTERisk {
        highRisk: Array<string>;
        intermediateRisk: Array<string>;
        lowRisk: Array<string>;
        comments: string;
    }
    export class OPProcedure {
        date: string;
        partnersName: string;
        consultant: string;
        specialization: string;
        procedure: string;
        team: string;
        indication: string;
        lmp: string;
        finding: string;
        analgesia: string;
        antibiotics: string;
        labSamples: string;
        abandoned: string;
        reason: string;
        duration: string;
        painScore: string;
        remarks: string;
        review: string;
    }
    export class Vaccine {
        tetanus1stDose: string;
        tetanus2ndDose: string;
        boosterix: string;
        fluVaccine: string;
        covidVaccine: string;
    }
    export class PostnantalVisit {
        mrNo: string;
        name: string;
        aNCNo: string;
        visitNo: string;
        visitDate: Date;
        pulseRate: string;
        systolic: string;
        diastolic: string;
        temperature: string;
        weight: string;
        bMI: string;
        complaints: string;
        otherComplaints: string;
        riskFactors: string;
        medicationAndAdvice: Array<string>;
        contraception: string;
        referred: string;
        reason: string;
        seen: string;
        nextReview: Date;
        dateOfDelivery: Date;
        modeOfDelivery: string;
        sexOfTheBaby: string;
        birthWeight: string;
        babyStatus: string;
        lactating: string;
        modeOfDeliverytwo: string;
        sexOfTheBabytwo: string;
        birthWeighttwo: string;
        babyStatustwo: string;
        lactatingtwo: string;
        modeOfDeliverythree: string;
        sexOfTheBabythree: string;
        birthWeightthree: string;
        babyStatusthree: string;
        lactatingthree: string;
        modeOfDeliveryfour: string;
        sexOfTheBabyfour: string;
        birthWeightfour: string;
        babyStatusfour: string;
        lactatingfour: string;
        perineum: string;
        antenatal: string;
        intrapartum: string;
        postpartum: string;
        pallor: string;
        abdominal: string;
        perineal: string;
        perSpeculam: string;
        perVaginum: string;
        perRectal: string;
        importantAdvice: string;
        medicationAndAdviceComment: string;
        consultedByDr: string;
        antenatalRiskFactor: string;
        complains: Array<string>;
        papSmear: string;
        date: Date;
        nextReviewDate: Date;
        seenByDr: string;
        medicationAndReason: string;
        postnantalExercise: Array<string>;
        sutureRemoval: string;
        dischargediagnosis: string;
        noOfFetus: string;


    }
    export class Measure {
        visitNumber: number;
        department: string;
        heightInFeet: any;
        heightInMeters: string;
        weight: string;
        bodyMassIndex: string;
        bmiCategorization: string;
        pulseRate: string;
        systolic: string;
        diastotic: string;
        respiratoryRate: string;
        tempFahrenhit: string;
        gestationAge: string;
        uterusPulpable: string;
        fundusHeight: string;
        sfHeight: string;
        presentation: string;
        fhs: string;
        amountofliquor: string;
        oedema: string;
        urineAlbumin: string;
        lastUSGDate: Date;
        typeOfUltraSoundScan: string;
        usgReport: string;
        consultedByDr: string;
        otherConsultedDr: string;
        otherReport: string;
        nextReviewDate: Date;
        externalGenitalia: string;
        psVagina: string;
        psCervix: string;
        psDischarge: string;
        cervix: string;
        presentingPart: string;
        stationPP: string;
        liquor: string;
        pelvis: string;
        other: string;
        complains: Array<string>;
        otherComplaints: string;
        highRisk: string;
        psCervixComment: string;
        psVaginaComment: string;
        externalGenitaliaComment: string;

        conscious: string;
        shock: string;
        pallor: string;
        icterus: string;
        spleen: string;
        liver: string;
        reflex: string;
        cardiioVascular: string;
        respiratorySystem: string;
        cns: string;
        examinationSummary: string;
        breastExamination: string;
        cardiioVascularComment: string;
        respiratorySystemComment: string;
        cnsComment: string;
        examinationSummaryComment: string;
        breastExaminationComment: string;

        discussedBirth: string;
        offeredSpinning: string;
        ecvOffered: string;
        ecvDoneBy: string;
        modeOfBirth: string;
        vaginalBreech: string;
        vbacCounseling: string;
        vbacCounselingComment: string;
        ifYes: string;
        ifYesecvOffered: string;
        ecvOfferedComment: string;
        offeredSpinningComment: string;
        ifYesofferedSpinning: string;
        vbac: string;
        ifNotAcceptedReason: string;
        optionOfVaginal: string;
        cervicalOSCm: string;
        membranes: string;
        ifAcceptedEcv: string;
        ifElectiveCsReason: string;
        modeofBirthPlanned: string;
        modeofBirthComment: string;
        modeofBirthPlannedComment: string;
        prasentation: string;
        heightInCentiMeter: string;

    }
    export class GeneralExamination {
        conscious: string;
        shock: string;
        pallor: string;
        icterus: string;
        spleen: string;
        liver: string;
        reflex: string;
        cardiioVascular: string;
        respiratorySystem: string;
        cns: string;
        examinationSummary: string;
        breastExamination: string;
        cardiioVascularComment: string;
        respiratorySystemComment: string;
        cnsComment: string;
        examinationSummaryComment: string;
        breastExaminationComment: string;
    }
    export class Breech {
        discussedBirth: string;
        offeredSpinning: string;
        ecvOffered: string;
        ecvDoneBy: string;
        modeOfBirth: string;
        vaginalBreech: string;
        vbacCounseling: string;
        vbacCounselingComment: string;
        ifYes: string;
        ifYesecvOffered: string;
        ecvOfferedComment: string;
        offeredSpinningComment: string;
        ifYesofferedSpinning: string;
        vbac: string;
    }
    export class AncCards {
        yearOfMarriage: string;
        gravida: number;
        para: string;
        abortion: string;
        living: string;
        menstrualPattern: string;
        days: string;
        conception: string;
        contraceptives: string;
        consanguinity: string;
        deliveryStatus: string;
        presentPregnancyDetails: string;
        bloodGroup: string;
        lmpStatus: string;
        lmp: Date;
        eddType: Date;
        ultraSoundEdd: Date;
        ga: number;
        rhType: string;
        bookingStatus: string;
        willSheDeliver: string;
        referralDoctor: string;
        specialFeature: string;
        husbandBloodGroup: string;
        husbandRhType: string;
        smoking: string;
        alcohol: string;
        otherSubstances: string;
        cordBloodBanking: string;
        prenatalScreeningOffered: string;
        diet: string;
        lifeStyle: string;
        toiletPreference: string;
        financialCouncelling: string;
        timeTakenToReachHospital: string;
        distanceInKilometers: string;
        prePregWeight: string;
        hbsAg: string;
        hiv: string;
        vdrl: string;
        hcv: string;
        edd: Date;
        providerName: string;

        covidVaccine: string;
        vaccineName: string;
        firstDose: string;
        date1: Date;
        secondDose: string;
        date2: Date;
        booster: string;
        date3: Date;
        fetalLungMaturity: string;
        day1: Date;
        day2: Date;
        givenBy: string;
        fluVaccine: string;
        date4: Date;
        tetanus1stDose: string;
        date5: Date;
        tetanus2ndDose: string;
        date6: Date;
        boostrix: string;
        anticoagulant: string;
        medication: string;
        dosage: string;
        frequency: string;
        date7: Date;
        indication1: string;
        dose1: string;
        byDr: string;
        date8: Date;
        indication2: string;
        dose2: string;
        byDr2: string;
        neonatalCounseling: string;
        otherSubstancesComment: string;
        consanguinityComment: string;
        lmpUnKnow: Date;
        consulatantDoctor: string;
        consultanDoctorUserName: string;
        dateOfTransfer: Date;

    }
    export class Covid {
        covidVaccine: string;
        vaccineName: string;
        firstDose: string;
        date1: string;
        secondDose: string;
        date2: string;
        booster: string;
        date3: string;
        fetalLungMaturity: string;
        day1: string;
        day2: string;
        givenBy: string;
        fluVaccine: string;
        date4: string;
        tetanus1stDose: string;
        date5: string;
        tetanus2ndDose: string;
        date6: string;
        boostrix: string;
        anticoagulant: string;
        medication: string;
        dosage: string;
        frequency: string;
        date7: string;
        indication1: string;
        dose1: string;
        byDr: string;
        date8: string;
        indication2: string;
        dose2: string;
        byDr2: string;
        neonatalCounseling: string;
    }

    export class Anaesthesia {
        weight: string;
        height: string;
        bmi: string;
        pacDoneBy: string;
        consultantDr: string;
        bloodGroup: string;
        rhType: string;
        allergy: string;
        diagnosis: string;
        surgery: string;
        problems: string;
        reasonForConsutation: string;
        temp: string;
        sp: string;
        pr: string;
        respRate: string;
        sysBp: string;
        diasBp: string;
        heart: string;
        lungs: string;
        lungsSound: string;
        built: string;
        hydration: string;
        pallor: string;
        icterus: string;
        cyanosis: string;
        oedema: string;
        pp: string;


        mouthOpening: string;
        tmj: string;
        neckExtention: string;
        grade: string;
        spineGrade: string;
        asaGrade: string;
        difficultIntubation: string;
        difficultRegional: string;
        cvs: string;
        endocrine: string;
        cns: string;
        rs: string;
        renal: string;
        git: string;
        currentMed: Array<string>;
        others: string;
        pastAnaesthesia: string;
        prbsAvailability: string;
        prbsReserve: string;
        prbsIssue: string;
        ffpAvailability: string;
        ffpReserve: string;
        ffpIssue: string;
        rdpAvailability: string;
        rdpReserve: string;
        rdpIssue: string;



        donorScreen: string;
        onAgitator: string;
        issue: string;
        mayBePosted: string;
        reviewPac: string;
        anaesthesiaPlan: string;
        specificPost: string;
        date: Date;
        time: string;
        hbPcv: string;
        hiv: string;
        fbs: string;
        scr: string;
        na: string;
        sbill: string;
        pt: string;
        wbc: string;
        hbsag: string;
        plbs: string;
        bun: string;
        kplus: string;
        sgpt: string;
        aptt: string;
        plat: string;
        hcv: string;
        rbs: string;
        bdUrea: string;
        clMinus: string;
        ldh: string;
        inr: string;
        lastothers: string;
        detention: Array<string>;
        instructions: Array<string>;
    }

    export class PrePregnancyCounseling {
        height: string;
        weight: string;
        bmi: string;
        pulse: string;
        temperature: string;
        systolicBp: string;
        dbp: string;
        respRate: string;
        marriedLif: string;
        para: string;
        living: string;
        abort: string;
        menstrualCycle: string;
        firstBloodGroup: string;
        firstRhType: string;
        lmp: Date;
        bloodGroup: string;
        rhType: string;
        reasonForCounseling: string;
        indicationForCounselling: string;
        counsellingSession: string;
        counsellingDetail: string;
        preConception: string;
        lowDoseAspirin: string;
        rubellaVaccine: string;
        assessThyroid: string;
        planAPregaancyAfter: string;
        pedigreeChar: string;
        remark: string;
        reviewDate: Date;
        psVagina: string;
        psDischarge: string;
        cervix: string;
        cervical: string;
        membranes: string;
        presentingPart: string;
        stationOfPP: string;
        pelvis: string;
        perAbdomen: string;

    }

    export class Allergies {
        drugNames: string;
        drugReactions: string;
        foodNames: string;
        foodReactions: string;
        dustNames: string;
        dustReactions: string;
        otherReactions: string;
        otherNames: string;
        noHistory: boolean;
        drugAllergy: string;
        dustAllergy: string;
        foodAllergy: string;
        otherAllergy: string;
        isHistory: boolean;
    }

    export class ObFamilyHistory {
        name: string;
        relation: string;
        diseases: string;
        status: string;
        age: Date;
        comments: string;
        problem: Array<string>;
        isFamilyHistory: boolean;
        mainComment: string;

    }

    export class OutPatientManagement {
        date: Date;
        diagnosis: string;
        lmp: Date;
        ga: string;
        usgFindings: string;
        conservativeManagements: string;
        mifepristone: string;
        dateOfUsingMifepristone: Date;
        timeMifepristone: string;
        antibiotic: string;
        dateOfDoxycline: Date;
        timeDoxycycline: string;
        microgramVaginal: string;
        dateOfMisoprosto: Date;
        timeOfMisoprostol: string;
        reviewOfUltrasound: string;
        dateOfUltrasound: Date;
        timeOfUltrasound: string;
        reportToTheHospital: string;
        otherDetail: string;
    }

    export class SpecialFeature {
        specialFeature: string;
    }

    export class PrePregnancyPlan {
        pregnancyPlan: string;
        year: string;
        ancNo: string;
        calenderYear: string;
        createdByName: string;
        security: string;
        isSaved: boolean;
    }

    export class ReferralForm {
        referralDoctor: string;
        reason: string;
        requiredDate: Date;
        time: any;
        consultantDoctor: string;
        opinion: string;
        providerId: number;
        referralDoctorId: number;
        patientId: number;
        patientName: string;
        encryptedAppointmentId: string;
        referralFormId: number;
        appointmentId: number;
        admissionId: number;
        encounterType?: string;
        encounterName?: string;
        encryptedAdmissionId: string;
  
    }

    export class OrderReferral {
        comments: string;
        name: string;
        id: number;
        value: string;
        fullName: string;
    }

    export class AdmssionSlip {
        mrno: string;
        name: string;
        age: string;
        wifeOf: string;
        department: string;
        admissionDate: Date;
        time: string;
        admssionDoctor: string;
        fhConsultant: string;
        surgeryDate: Date;
        surgeon: string;
        surgicalPrcedure: string;
        gestationalAge: string;
        phoneNo: string;
        resonForAdmission: string;
        doctorUnitMasterId: number;
        appointmentId: number;
        patientId: number;
        encryptedPatientId: string;
        fullName: string;
        appointmentNo: string;
        encounterName: string;
        totalItems: number;
        doctorUnitName: string;
        isAdmitted: boolean;
        formattedTime: string;
    }

    export class MotherWithEpilepsy {
        ga: string;
        date: Date;
        typeOfEpilepsy: string;
        cause: string;
        medication1: string;
        dose1: string;
        mdeication2: string;
        dose2: string;
        medication3: string;
        dose3: string;
        frequencyOfSeizures: string;
        lastEpisodeOfSeizures: string;
        counselingNeedstobeDone: string;
        note: string;
        complains: Array<string>;
    }

    export class ExternalCephalic {
        consultedByDr: string;
        ipNo: string;
        visitDate: Date;
        timeV: string;
        parity: string;
        gestation: string;
        bloodGroup: string;
        previousSection: string;
        labour: string;
        presentation: string;
        fhrMin: string;
        afi: string;
        ebw: string;
        hyperextension: string;
        sideOfBack: string;
        placenta: string;
        comments: string;
        tocolysis: string;
        ifOtherdetails: string;
        noOfAttempts: string;
        ecv: string;
        postProcedure: string;
        antiD: string;
        postnantalExercise: Array<string>;
        outcome: string;
        typeOfEpilepsy: string;
        birthDate: Date;
        indication: string;
        birthWeight: string;
        commentsIfAny: string;

    }

    export class DischargeSummary {
        reltiveDetails: string;
        reltiveName: string;
        bloodGroup: string;
        dateOfAdmission: string;
        timeOfAdmission: string;
        birthDate: string;
        birthTime: string;
        bedNo: string;
        sumaryType: string;
        dischargeType: string;
        dischargeDate: Date;
        transferDate: Date;
        transferTime: string;
        deathDate: Date;
        deathTime: string;
        timeOfDischarge: string;
        hospitalName: string;
        reasonForTransfer: string;
        reasonForLama: string;
        causeOfDeath: string;
        consultanatDotcor: string;
        growCentile: string;
        noOfFetus: string;
        baby1Mrno: string;
        babytwoMrno: string;
        babythreeMrno: string;
        arteral: string;
        venous: string;
        pendingForApproval: string;
        consultedByDr: string;
        diagnosis: string;
        chiefComplaints: string;
        HistoryOfPresentillness: string;
        medicalHistory: string;
        surgicalHistory: string;
        allergyHistory: string;
        familyHistory: string;
        presentPregnancyDetail: string;
        admssionDetails: string;
        otherProceduresDetails: string;
        courseHospital: string;
        consitionDischarge: string;
        followUp: string;
        proposedProcedure: string;
        proposeddone: string;
        otheradvice: string;
        remarks: string;
        venousCheck: Array<string>;
        problems: Array<any>;
        labs: Array<any>;
        birthhistory: Array<any>;
        prescriptions: Array<any>;
        scans: Array<any>;
        labourType: string;
        indicationOfIol: string;
        augmentation: string;
        membRuptureType: string;
        vdamnioticFluid: string;
        delivery: string;
        shoulderDystocia: string;
        perineum: string;
        anaesthesia: string;
        maternal: string;
        pph: string;
        vddateOfbirth: string;
        vdtime: string;
        vdsex: string;
        vdWeight: string;
        vdApgar: string;
        vdStatus: string;
        vdcordAbg: string;
        primaryIndication: string;
        contributtingFactor: string;
        lscsType: string;
        abdomialIncision: string;
        lusCondition: string;
        previousScarIntegrity: string;
        uterineIncision: string;
        plac: string;
        verticalUterineTear: string;
        uterinevesselInvolved: string;
        rtOvary: string;
        rttube: string;
        ltovery: string;
        lttube: string;
        uterineAnomaly: string;
        tubalLigation: string;
        placetaWeight: string;
        fibroids: string;
        adesions: string;
        presentation: string;
        PPPosition: string;
        amnioticFluid: string;
        sex: string;
        weight: string;
        dateOfBirth: string;
        timeOfBirth: string;
        status: string;
        Apgar: string;
        cordAbg: string;
        reasonforTransfer: string;
    }

    export class Syndromes {
        syndromes: any;
        diseases: any;
        others: string;
        provisionalDiagnosis: string;
        testOrdered: string;
        patientWilling: string;
        transactionId: string;
        typeOfSample: string;
        dateSample: string;
        onsetDate: string;
        consultantDoctor: string;
        patientId: number;
    }

    export class OPManagement {
        maternalMonitoring: any;
        nextTesting: string;
        nextAppointment: string;
        fetalMonitoring: any;
        ultrasoundEvaluation: any;
        nextNST: string;
        bpData: string;
        systolicBP: string;
        diasystolicBP: string;
        urineProtein: string;
        diagnosis: string;
    }

    export class NursingAssessment {
        patientName: string;
        age: string;
        ward: string;
        date: string;
        wardInTime: string;
        assessmentTm: string;
        diagnosis: string;
        drugAllergy: string;
        apperance: string;
        levelOfConcious: string;
        build: string;
        nutrition: string;
        menstrualPattern: string;
        edd: string;
        lmp: string;
        bp: string;
        dbp: string;
        temp: string;
        pulse: string;
        resp: string;
        weight: string;
        height: string;
        bmi: string;
        pedalOedema: string;
        pallor: string;
        uterineContraction: string;
        uterineAlbumine: string;
        fhs: string;
        draining: string;
        pelvis: string;
        abdomen: string;
        breast: string;
        ifAnyAbnormal: string;
        physicalDisability: string;
        details: string;
        mentalStatus: string;
        comorbidities: string;
        bmiOptions: string;
        hightRiskCase: string;
        vulnerable: string;
        anaemia: string;
        badObestricHistory: string;
        inducedLabour: string;
        multiplePregnancies: string;
        bloodTranfusion: string;
        postDatedPregnancy: string;
        rhNegative: string;
        iugr: string;
        previousLscs: string;
        cerclage: string;
        cardiacDisease: string;
        hypertension: string;
        renalDisease: string;
        diabetesMelitus: string;
        hepatitis: string;
        respiratoryDisease: string;
        fibroidUterus: string;
        previousUterine: string;
        otherOperations: string;
        nursingPlanOfCare: Array<string>;
        planAPregaancyAfter: string;
        frequenceOf: string;
        painScalingRate: string;
        specialNeeds: Array<string>;
        anyOther: string;
        heightInMeters: string;
        painscalereting: string;
    }

    export class OutsideTest {
        doneDate: Date;
        testname: string;
        resultValue: string;
        otherTests: string;
        otherTestResults: string;
        componentName: string;
        parameterName: string;
        resultValues: string;
        parameters: Array<LabParameters>;

        bloodGP: string;
        baselineScan: string;
        comments: string;
        fshDate1: string;
        fshDate2: string;
        fshDate3: string;
        fshDate4: string;
        lhDate1: string;
        lhDate2: string;
        lhDate3: string;
        lhDate4: string;

        e2Date1: string;
        e2Date2: string;
        e2Date3: string;
        e2Date4: string;
        p4Date1: string;
        p4Date2: string;
        p4Date3: string;
        p4Date4: string;
        amhDate1: string;
        amhDate2: string;
        amhDate3: string;
        amhDate4: string;
        tshDate1: string;
        tshDate2: string;
        tshDate3: string;
        tshDate4: string;
        prlDate1: string;
        prlDate2: string;
        prlDate3: string;
        prlDate4: string;
        hivDate1: string;
        hivDate2: string;
        hivDate3: string;
        hivDate4: string;
        hbsAgDate1: string;
        hbsAgDate3: string;
        hbsAgDate4: string;
        hcvDate1: string;
        hcvDate2: string;
        hcvDate3: string;
        hcvDate4: string;
        cbcDate1: string;
        cbcDate2: string;
        cbcDate3: string;
        cbcDate4: string;
        creatinineDate1: string;
        creatinineDate2: string;
        creatinineDate3: string;
        creatinineDate4: string;
        fbsDate1: string;
        fbsDate2: string;
        fbsDate3: string;
        fbsDate4: string;
        othersDate1: string;
        chromosal: string;
        caryogamy: string;
        husbnadBloodGP: string;
        scrotaleScan: string;
        countDate1: string;
        countDate2: string;
        countDate3: string;
        countDate4: string;
        motilityDate1: string;
        motilityDate2: string;
        motilityDate3: string;
        motilityDate4: string;
        progressiveDate1: string;
        progressiveDate2: string;
        progressiveDate3: string;
        progressiveDate4: string;
        morphologyDate1: string;
        morphologyDate2: string;
        morphologyDate3: string;
        morphologyDate4: string;
        vitalityDate1: string;
        vitalityDate2: string;
        vitalityDate3: string;
        vitalityDate4: string;
        pusCellsDate1: string;
        pusCellsDate2: string;
        pusCellsDate3: string;
        pusCellsDate4: string;
        dfiDate1: string;
        dfiDate2: string;
        dfiDate3: string;
        dfiDate4: string;
        impressionDate1: string;
        impressionDate2: string;
        impressionDate3: string;
        impressionDate4: string;
        husbandHIVDate1: string;
        husbandHIVDate2: string;
        husbandHIVDate3: string;
        husbandHIVDate4: string;
        husbandHbsAgDate1: string;
        husbandHbsAgDate2: string;
        husbandHbsAgDate3: string;
        husbandHbsAgDate4: string;
        husbandHcvDate1: string;
        husbandHcvDate2: string;
        husbandHcvDate3: string;
        husbandHDate4: string;
        biopsy: string;
        Husbandothers: string;
        semenAnalysis: string;
    }

    export class LabParameters {
        componentName: string;
        parameterName: string;
        resultValues: string;
    }
    export class DiagnosisOrder {
        usable: string;
        date: string;
        code: string;
        status: string;
        comments: string;
    }

    export class VisitForm {
        visitDate: Date;
        specialAdvice: string;
    }

    export class NeonatalInitialAsessment {
        babyMno: string;
        name: string;
        ipNo: string;
        sex: string;
        age: string;
        admissionDate: string;
        admissionTime: string;
        roomNo: string;
        motherMrno: string;
        motherName: string;
        fatherName: string;
        modeOfDelivery: string;
        gestationAge: string;
        intrauterineGrowthStatus: string;
        birthWeight: string;
        apgar: string;
        legnth: string;
        length: string;
        hc: string;
        motherBloodGroup: string;
        babyBloodGroup: string;
        congenitalAnomalies: string;
        antenatalHistory: string;
        eventsInEarly: string;
        presentIllnesss: string;
        duration: string;
        feedingType: string;
        feedingMode: string;
        timeSinceLastFeed: string;
        temperature: string;
        hr: string;
        rr: string;
        rs: string;
        cft: string;
        spo2: string;
        fio2: string;
        grbs: string;
        generalExamination: Array<string>;
        size: string;
        shape: string;
        sutureOverriding: string;
        separationAF: string;
        cephalohematoma: string;
        dysmorphism: string;
        eyes: string;
        ears: string;
        mouth: string;
        umbilicalCord: string;
        skin: Array<string>;
        genitalia: string;
        limbs: string;
        spine: string;
        newBallardsScore: string;
        anthropometry: string;
        presentWeight: string;

        oFC: string;
        ponderalIndex: string;
        systemicExamintion: string;
        cVS: string;
        cNS: string;
        respiratory: string;
        abdominal: string;
        impression: string;
        otherDetails: string;
        planOfManagement: Array<SrvRecord>;
    }


    export class ScanDetails {
        scanMachineMaster: string;
        location: string;
        appointmentDate: Date;
        appointmentTime: string;
    }
    export class InductionForm {
        consultantName: string;
        lMP: string;
        edd: Date;
        gestAge: number;
        iol: string;
        baselineNST: string;
        dilatation: string;
        effacement: string;
        stationOfPP: string;
        consistency: string;
        position: string;
        score: string;

        insertionDateP: Date;
        insertionTimeP: string;
        bishopscore: number;
        ctg: string;
        nameofthedoctor: string;
        removalExpulsionDate: Date;
        removalExpulsionTime: string;
        rctg: string;
        bishopScoreR: number;
        otherifyes: string;
        inductionToDeliveryInterval: string;
        otherDetails: string;
        ifYesComplication: string;
        pge1fDate: Date;
        pge1fTime: string;
        pge1fBishopsScore: number;
        pge1fDocterName: string;
        pge1sDate: Date;
        pge1sTime: string;
        pge1sBishopsScore: number;
        pge1sDocterName: string;
        pge1tDate: Date;
        pge1tTime: string;
        pge1tBishopsScore: number;
        pge1tDocterName: string;
        pge14Date: Date;
        pge14Time: string;
        pge14BishopsScore: number;
        pge14DocterName: string;
        pge15Date: Date;
        pge15Time: string;
        pge15BishopsScore: number;
        pge15DocterName: string;
        pge16Date: Date;
        pge16Time: string;
        pge16BishopsScore: number;
        pge16DocterName: string;

        pge2fDate: Date;
        pge2fTime: string;
        pge2fBishopsScore: number;
        pge2fDocterName: string;
        pge2fctg: number;
        pge2sDate: Date;
        pge2sTime: string;
        pge2sBishopsScore: number;
        pge2sDocterName: string;
        pge2sctg: number;
        pge2tDate: Date;
        pge2tTime: string;
        pge2tBishopsScore: number;
        pge2tDocterName: string;
        pge2tctg: number;
        indication: Array<string>;
        armifdone: string;

        stratedDate: Date;
        startedTime: string;
        doctorName: string;
        bishopScoreO: number;
        stopedOn: Date;
        stoppedTime: string;
        bishopScoreO1: number;
        doctorName1: string;

        insertionDateC: Date;
        insertionTimeC: string;
        bishopscoreC: number;
        ctgC: number;
        nameofthedoctorC: string;
        removalExpulsionDateC: Date;
        removalExpulsionTimeC: string;
        rctgC: number;
        bishopScoreC: number;
        doctorNameC: string;
        fstartdate: Date;
        fstarttime: string;
        fBishopScore: number;
        fDoctorName: string;
        fEndDate: Date;
        fEndTime: string;
        fbishopScore: number;
        pge11: string;
        pge12: string;
        pge13: string;
        pge14: string;
        pge15: string;
        pge16: string;
        pge21: string;
        pge22: string;
        pge23: string;
        methodofchoice: Array<string>;

    }
    export class LSCS {

        roomNO: number;
        deliveryDate: Date;
        deliveryTime: Time;
        surgeonName: string;
        asstSurgeon: string;
        nurse: string;
        anaesthetist: string;
        paediatrician: string;
        type: string;
        anaesthesia: string;
        otherComment: string;
        primaryIndication: Array<string>;

        contributingFactors: string;
        categoryName: string;
        decisionToDeliveryTime: Time;
        fetus: string;
        presentation: string;
        termtype: string;
        cephalictype: string;
        nulliparitytype: string;
        robsonValue: string;
        labour: string;
        iOLAgent: string;

        rupture: string;
        abdominalIncision: string;
        adhesions: string;
        ifYesAdhesions: string;
        segmentCondition: string;
        scarIntegrity: string;
        uterineIncision: string;
        placentaSite: string;
        placentaPosition: string;
        verticalUterineTear: string;
        ifYesUterineTear: string;
        uterineVesselInvolved: string;
        ifUterineVesselInvolved: string;
        additionalProcedures: string;
        otherprocedures: string;
        pph: string;
        ifpphyes: string;
        estBloodLoss: string;
        uterineClosure: string;
        drain: string;
        intraperitonel: string;
        subcutaneous: string;
        sMUuterineClosure: string;
        tubalLigation: string;
        rectusSheath: string;
        skinClosure: string;
        rTOvary: string;
        cystTumorSize: string;
        rTTube: string;
        lTOvary: string;
        cystTumSize: string;
        lTTube: string;
        fibroids: string;
        typeOfFibroid: string;
        noOfFibroid: number;
        sizeOfFibroid: string;
        uterineAnomaly: string;
        oFtubalLigation: string;
        tubalLigationMethod: string;
        sentForHPE: string;
        bladderCatheterized: string;
        swabs: string;
        instruments: string;
        placenta: string;
        placentaWeight: string;
        placentaHPE: string;
        shiftingIntoOT: Time;
        startOfAnaesthesia: Time;
        antibioticTotalDoseGivenTime: string;
        startOfSurgery: Time;
        endOfProcedure: Time;
        prophylacticOxytocin: string;
        shiftingOutOfOT: Time;
        othernotes: string;
        noOfBabies: number


        birthOrder1: string;
        babyPresentation1: string;
        presentingPartPosition1: string;
        amnioticFluidColour1: string;
        quantity1: string;
        forceps1: string;
        fetalBloodSampling1: string;
        scalpLactateValue1: string;
        skinToSkinBabyCare1: string;
        skinToSkinBabyCareDetails1: string;
        cordClamping1: string;
        ifImmediateDtl1: string;
        clampingTime1: Time;
        date1: Date;
        timeOfTesting1: Time;
        deliveryAndAdvice: Array<string>;
        deliveryAndAdvice2: Array<string>;
        deliveryAndAdvice3: Array<string>;
        indicationsForIOLs: Array<string>;
        pHArterial1: string;
        pHVenous1: string;
        eCFArterial1: string;
        eCFVenous1: string;
        lactateArterial1: string;
        lactateVenous1: string;
        pO2Arterial1: string;
        pO2Venous1: string;
        pCO2Arterial1: string;
        pCO2Venous1: string;
        modeOfDelivery1: string;
        sexOfBaby1: string;
        statusOfBaby1: string;
        babyBirthDate1: Date;
        babyBirthTime1: Time;
        babyBirthWt1: string;
        apgar1: string;
        cordABG1: string;

        birthOrder2: string;
        babyPresentation2: string;
        presentingPartPosition2: string;
        amnioticFluidColour2: string;
        quantity2: string;
        forceps2: string;
        fetalBloodSampling2: string;
        scalpLactateValue2: string;
        skinToSkinBabyCare2: string;
        skinToSkinBabyCareDetails2: string;
        cordClamping2: string;
        ifImmediateDtl2: string;
        clampingTime2: Time;
        date2: Date;
        timeOfTesting2: Time;
        pHArterial2: string;
        pHVenous2: string;
        eCFArterial2: string;
        eCFVenous2: string;
        lactateArterial2: string;
        lactateVenous2: string;
        pO2Arterial2: string;
        pO2Venous2: string;
        pCO2Arterial2: string;
        pCO2Venous2: string;
        modeOfDelivery2: string;
        sexOfBaby2: string;
        statusOfBaby2: string;
        babyBirthDate2: Date;
        babyBirthTime2: Time;
        babyBirthWt2: string;
        apgar2: string;
        cordABG2: string;



        birthOrder3: string;
        babyPresentation3: string;
        presentingPartPosition3: string;
        amnioticFluidColour3: string;
        quantity3: string;
        forceps3: string;
        fetalBloodSampling3: string;
        scalpLactateValue3: string;
        skinToSkinBabyCare3: string;
        skinToSkinBabyCareDetails3: string;
        cordClamping3: string;
        ifImmediateDtl3: string;
        clampingTime3: Time;
        date3: Date;
        timeOfTesting3: Time;
        pHArterial3: string;
        pHVenous3: string;
        eCFArterial3: string;
        eCFVenous3: string;
        lactateArterial3: string;
        lactateVenous3: string;
        pO2Arterial3: string;
        pO2Venous3: string;
        pCO2Arterial3: string;
        pCO2Venous3: string;
        modeOfDelivery3: string;
        sexOfBaby3: string;
        statusOfBaby3: string;
        babyBirthDate3: Date;
        babyBirthTime3: Time;
        babyBirthWt3: string;
        apgar3: string;
        cordABG3: string;
        skinClosureType: string;



        qbMRNo: string;
        qbName: string;
        qbIPNo: string;
        qbDate: Date;
        qbProcedure: string;
        amnioticFluidMl: string;
        bloodCollection: string;
        vaginalUnderButtocksDrapes: string;
        spillage: string;
        lscsDrapes250gm: string;
        lscsNumber: number;
        lscsTotalDryWeight: number;
        lscsWetWeight: number;
        stickingDrapes80gm: string;
        stickingNumber: number;
        stickingTotalDryWeight: number;
        stickingWetWeight: number;
        cozyDrapes80gm: string;
        cozyNumber: number;
        cozyTotalDryWeight: number;
        cozyWetWeight: number;
        holeTowelClothDrapes160gm: string;
        holeTowelClothNumber: number;
        holeTowelClothTotalDryWeight: number;
        holeTowelClothWetWeight: number;
        smallBlueSheetDrapes30gm: string;
        smallBlueSheetNumber: number;
        smallBlueSheetTotalDryWeight: number;
        smallBlueSheetWetWeight: number;
        surgicalDrapes20gm: string;
        surgicalNumber: number;
        surgicalTotalDryWeight: number;
        surgicalWetWeight: number;
        greenSheetBigDrapes675gm: string;
        greenSheetBigNumber: number;
        greenSheetBigTotalDryWeight: number;
        greenSheetBigWetWeight: number;
        greenSheetMediumDrapes300gm: string;
        greenSheetMediumNumber: number;
        greenSheetMediumTotalDryWeight: number;
        greenSheetMediumWetWeight: number;
        vaginalSanitaryPadDrapes40gm: string;
        vaginalSanitaryPadNumber: number;
        vaginalSanitaryPadTotalDryWeight: number;
        vaginalSanitaryPadWetWeight: number;
        totalDrapes: string;
        diffwetWeightDryWeight: number;
        suctionTotalOnly1: number;
        clots1: number;
        diffWetDryWeight1: number;
        spillage2: number;
        totalQBL1: string;
        suctionTotalOnly2: number;
        clots2: number;
        diffWetDryWeight2: number;
        spillage3: number;
        totalQBL2: string;
        qblDoneByDoctor: string;
        qblDoneByNurse: string;
        amniotic: number;
        ifBreech: string;
        ifYesoFtubalLigation: string;
        labourAnalgesia: string;

    }


    export class NormalDeliveryForm {
        labourType: string;
        iolAgent: string;
        indicationOfIOL: Array<string>;
        iolOther: string;
        augmentation: string;
        membRupture: string;
        indicationForOxytocin: string;
        presentation: string;
        presentationOther: string;
        anaesthesia: string;
        anaesthesiaOther: string;
        nonEpidural: string;
        deliveryType: string;
        trialOfForceps: string;
        doubleSetup: string;
        catheterized: string;
        catheterType: string;
        perineum: string;
        perineal: string;
        cassificationOfAVD: string;
        sequential: string;
        placeOfInstrument: string;
        indicationforavd: Array<string>;
        SutureMaterialUsed: Array<string>;
        applLockAndTraction: string;
        durOfInstrument: string;
        noofPulls: string;
        rotation45: string;
        ifyes: string;
        episiotomy: string;
        indicationepisiotomy: string;
        episiotomydoneby: string;
        decisionforepisiotomdoneby: string;
        placeOfDelivery: string;
        analgesiaProcedure: string;
        consent: string;
        PerAbdomen: string;
        dilatation: string;
        station: string;
        moulding: string;
        caput: string;
        timeOfApplication: string;
        application: string;
        traction: string;
        noofpopoffs: string;
        maternaleffort: string;
        multipleinstrumentUse: string;
        examinationbefore2ndinstrument: string;
        per15thAbdomen: string;
        position: string;
        stations: string;
        mouldings: string;
        caputs: string;
        reasonsforsecondinstrument: string;
        timeof2ndinstrument: string;
        vaginal: string;
        vaginalyes: string;
        paraurethral: string;
        paraurethralyes: string;
        cervical: string;
        cervicalyes: string;
        indicationForRequesting: Array<string>;


        Iststage: string;
        IIndstage: string;
        IIIrdstage: string;
        noofVEs: string;
        noVEAfterARMSROM: string;
        durationOfActivePushing: string;
        maternalComplication: string;
        maternalComplicationOther: string;
        amnioticFluidVolume: string;
        amnioticFluidColour: string;
        shoulderDystocia: string;
        ifYesManouvers: string;
        shoulderDystociaFormFilled: string;
        placenta: string;
        ifAbnormalDts: string;
        placentadtls: string;
        placentalDelivery: string;
        membraneDtls: string;
        PlacentaWt: string;
        cord: string;
        abnCordDtls: string;
        cordLength: string;
        pph: string;
        pphprophylaxis: Array<string>;
        bloodLoss: string;
        stageother: string;
        bloodTransfusion: string;
        bloodTransfusionYes: string;
        preSuturingPR: string;
        postSuturingPR: string;
        analSpincterCondition: string;
        otherDetails: string;
        birthedBy: string;
        birthedByName: string;
        suturingBy: string;
        name: string;
        standByDoctor: string;
        fetalBloodSampling: string;
        scalpLactateValue: string;
        perineumWarmCompression: string;
        perinealMassageDoneInANPeriod: string;
        waterBirth: string;
        hydrotherapy: string;
        birthInTheUnit: string;
        birthCompanion: string;
        occipito: string;
        birthingPosition: string;
        midwiferyToObstetricTransfers: string;
        ifYes: string;
        oasi: string;
        oasiyes: string;
        noofFetus: string;
        presentations: string;
        ifCephalic: string;
        ifterm: string;
        ifNulliparity: string;
        ifBreech: string;
        robsonValue: number;
        noofFetuses: string;

        birthorder: string;
        modeOfDelivery: string;
        indication: string;
        babybirthdate: Date;
        babybirthtime: string;
        sexOfBaby: string;
        statusOfBaby: string;
        babyBirthWeight: string;
        apgar: string;
        cordClamping: string;
        ifimmediateReason: string;
        cordClampingTime: string;
        skinToSkinBabyCare: string;
        ifnoreason: string;
        date: Date;
        timeoftesting: string;
        babyindication: string;




        phArterial: string;
        phVenous: string;
        beecfarterial: string;
        beecVenous: string;
        lactatearterial: string;
        lactatevenous: string;
        po2arterial: string;
        po2venous: string;
        pco2arterial: string;
        pco2venous: string;
        cordabg: string;
        debriefingSession: Array<string>;
        birthorder2: string;
        modeOfDelivery2: string;
        indication2: string;
        babybirthdate2: Date;
        babybirthtime2: string;
        sexOfBaby2: string;
        statusOfBaby2: string;
        babyBirthWeight2: string;
        apgar2: string;
        cordClamping2: string;
        ifimmediateReason2: string;
        cordClampingTime2: string;
        skinToSkinBabyCare2: string;
        ifnoreason2: string;
        date2: Date;
        timeoftesting2: string;
        babyindication2: string;
        cesareandeliveryavedforfetalcompromise2: string;
        low5minuteapgarscore2: string;
        growthrestrictiondopplercompromise2: string;
        pathologicalctg2: string;
        mslwithabnormal2: string;
        intrapartumfever2: string;
        difficultdelivery2: string;
        phArterial2: string;
        phVenous2: string;
        beecfarterial2: string;
        beecVenous2: string;
        lactatearterial2: string;
        lactatevenous2: string;
        po2arterial2: string;
        po2venous2: string;
        pco2arterial2: string;
        pco2venous2: string;
        cordabg2: string;

        birthorder3: string;
        modeOfDelivery3: string;
        indication3: string;
        babybirthdate3: Date;
        babybirthtime3: string;
        sexOfBaby3: string;
        statusOfBaby3: string;
        babyBirthWeight3: string;
        apgar3: string;
        cordClamping3: string;
        ifimmediateReason3: string;
        cordClampingTime3: string;
        skinToSkinBabyCare3: string;
        ifnoreason3: string;
        date3: Date;
        timeoftesting3: string;
        babyindication3: string;
        cesareandeliveryavedforfetalcompromise3: string;
        low5minuteapgarscore3: string;
        growthrestrictiondopplercompromise3: string;
        pathologicalctg3: string;
        mslwithabnormal3: string;
        intrapartumfever3: string;
        difficultdelivery3: string;
        phArterial3: string;
        phVenous3: string;
        beecfarterial3: string;
        beecVenous3: string;
        lactatearterial3: string;
        lactatevenous3: string;
        po2arterial3: string;
        po2venous3: string;
        pco2arterial3: string;
        pco2venous3: string;
        cordabg3: string;
        postOperativePeriod: string;
        postOperativeDays: string;
        stoolSofteners: string;

        //estimation blood loss
        roomNo: string;
        ipNo: string;
        procedure: string;
        estimationDate: Date;
        estimationTime: string;
        preOperativeHb: string;
        bloodGroupRhType: string;
        surgicalSwabs: string;
        surgicalSwabsNos: string;
        amountOfEBL: string;
        soakedWithNos: string;
        soakedWithEBL: string;
        partiallySoaked: string;
        partiallySoakedNos: string;
        partiallySoakedEBL: string;
        vaginalSanitary: string;
        fullySoakedNos: string;
        fullySoakedEBL: string;
        soakedWithFewDryAreas: string;
        soakedWithFewDryAreasNos: string;
        soakedWithFewDryAreasEBL: string;
        tampsonFullySoaked: string;
        tampsonEBL: string;
        clotsInGrams: string;
        clotsInGramsNos: string;
        clotsInGramsEBL: string;
        suctionDrain: string;
        suctionDrainEBL: string;
        drapes: string;
        drapesNos: string;
        drapesEBL: string;
        bedSheet: string;
        bedSheetNos: string;
        bedSheetEBL: string;
        floorSpillage: string;
        floorSpillageNos: string;
        floorSpillageEBL: string;
        totalEstimatedBloodLoss: string;
        calculatingWight: string;
        estimatedBloodVolume: string;
        hct: string;
        hctFinalAllowed: string;
        maximumABL: string;
        initalFinalAllowed: string;
        initialHematocrit: string;
        allowedBloddLoss: string;
        tampsonNos: string;


        //Operative Vaginal Delivery Record
        indicationForDelivery: string;
        classificationOfAvd: string;
        rotationGreater: string;
        ctg: string;
        catheterised: string;
        prerequisitedConsent: string;
        prerequisitesAnalgesiaProcedure: string;
        prerequisitedPlaceOfDelivery: string;
        liquor: string;
        perAbdomen: string;
        examinationDilatation: string;
        examinationPosition: string;
        examinationStation: string;
        examinationMoulding: string;
        examinationCaput: string;
        instrumentUsed: string;
        procedureTimeOfApplication: string;
        numberOfPulls: string;
        applicationTypes: string;
        applicationTraction: string;
        noOfPopOffs: string;
        maternalEffort: string;
        procedurePlacenta: string;
        procedureEpisiotomy: string;
        perRectaleExamination: string;
        perinealTear: string;
        procedureOtherDetails: string;
        multipleInstrumentUse: string;
        examinationPerAbdomen: string;
        examinationBeforePosition: string;
        examinationBeforeStation: string;
        examinationBeforeMoulding: string;
        examinationBeforeCaput: string;
        reasonForSecondInstrument: string;
        timeOfInstrument: string;
        levelOfCare: string;
        syntocinonInfusion: string;
        catheter: string;
        remove: string;
        vaginalPack: string;
        vaginalPackRemove: string;
        diclofenac: string;
        analgesiaPrescribed: string;
        thromboembolicRisk: string;
        thromboprophylaxis: string;
        doctorName: string;
        thisProcedure: string;
        topCopyAccepted: string;


        //Checklist For Post Primary Anal Sphincter Repair
        dateOfDelivery: Date;
        timeOfDelivery: Time;
        deliveryType1: string;
        episiotomy1: string;
        placeOfRepair: string;
        thirdDegreePackUsed: string;
        consultantDoctor: string;
        repairDoneByDr: string;
        assistedBy: string;
        anaesthesia1: string;
        typeOfAnalSphincterRepair: string;
        analMucosa: string;
        iAS: string;
        eAS: string;
        knots: string;
        perinealMuscleAndVaginalMucosa: string;
        rectalExamAtTheEndOfTheProcedure: string;
        vaginalExamAtTheEndOfTheProcedure: string;
        eBL: string;
        swabsNeedlesChecked: string;
        cRMFormFilled: string;
        analgesia: string;
        followUpDate: Date;

        //Shoulder Dystocia Form
        date4: Date;
        time: Time;
        deliveryOfHead: string;
        dutyDoctorCalled: string;
        timeCalled1: string;
        arrived1: string;
        seniorConsultantCalled: string;
        timeCalled2: string;
        arrived2: string;
        paediatricianCalled: string;
        timeCalled3: string;
        arrived3: string;
        mcRobertsManoeuvre: string;
        orderTime1: Time;
        performedBy1: string;
        suprapubicPressureAndRoutineTraction: string;
        orderTime2: Time;
        performedBy2: string;
        evaluationForEpisiotomyReasonIfNotPerformed: string;
        orderTime3: Time;
        performedBy3: string;
        deliveryOfPosteriorArm: string;
        orderTime4: Time;
        performedBy4: string;
        woodScrewManoeuvre: string;
        orderTime5: Time;
        performedBy5: string;
        motherOnAllFours: string;
        orderTime6: Time;
        performedBy6: string;
        others: string;
        otherDetails1: string;
        orderTime7: Time;
        performedBy7: string;
        TimeOfDeliveryOfHead: string;
        timeOfDeliveryOfBody: string;
        atDeliveryHeadFacing: string;
        weight: string;
        apgarAt1Minute: string;
        fMinutes: string;
        tMinutes: string
        baseDeficit: string;
        cordPHArterial: string;
        paediatricAssessmentAtDelivery: string;
        degreeOfPerinealTear: Array<string>;
        labourAnalgesia: string;
    }
    export class AdmissionDetails {
        casetype: string;
        mrno: number;
        name: string;
        husbandName: string;
        ancno: number;
        ipNo: number;
        dateOfAdmission: Date;
        admnTime: string;
        roomNo: string;
        examinationTime: string;
        otherComplaints: string;
        historyOfPresentIllness: string;
        complaints: Array<string>;
        yearOfMarriage: string;
        para: string;
        living: string;
        abortion: string;
        lmp: string;
        edd: string;
        ga: string;
        scanedd: Date;
        heightInFeet: number;
        weightInKg: number;
        bmi: string;
        systolicbp: string;
        diastolicbp: string;
        pulserate: string;
        respiratoryrate: string;
        temperature: string;
        spo2: string;
        conscious: string;
        shock: string;
        icterus: string;
        oedema: string;
        spleen: string;
        liver: string;
        reflex: string;
        cardiovasc: string;
        respsystem: string;
        urineoutput: string;
        urineAlbumin: string;
        utcontr: string;
        pp: string;
        fundalht: string;
        ppabd: string;
        liqr: string;
        fhs: string;
        fhr: string;
        psexam: string;
        draining: string;
        cervix: string;
        ppvag: string;
        station: string;
        cervicaloscm: string;
        pelvis: string;
        drugallergies: string;
        diagnosis: string;
        issheapreviousonecs: string;
        vbacformgiven: string;
        counselling: string;
        consultantopinion: string;
        consultantinformed: string;
        date: Date;
        time: string;
        consultant: string;
        acceptance: string;
        plan: string;
        ifiol: string;
        membranes: string;
        pallor: string;
        diagnosisDetails: string;
    }

    export class IUFDEntryForm {
        mrNo: string;
        name: string;
        fatherName: string;
        ancNo: string;
        ipno: string;
        modeOfDelivery: string;
        date: Date;
        time: string;
        gestAge: string;
        birthOrder: string;
        weight: string;
        sex: string;
        status: string;
        apgar1min: string;
        apgar5min: string;
        apgar10min: string;
        pediatrician: string;
        congenitalAnomalies: string;
        congenitalAnomalyDetails: string;
        otherDetails: string;
    }
    export class DoctorReviewForm {
        timeOfAssessment: string;
        mrNo: number;
        name: string;
        husbandName: string;
        ipNo: number;
        dateOfAdmission: Date;
        time: string;
        roomNo: string;
        complaints: string;
        otherComplaints: string;
        heightInFeet: number;
        weightInKg: number;
        bmi: number;
        systolicBp: number;
        diastolicBp: number;
        pulseRate: number;
        respiratoryRate: number;
        spO2: string;
        temperature: string;
        conscious: string;
        shock: string;
        pallor: string;
        icterus: string;
        oedema: string;
        spleen: string;
        liver: string;
        reflex: string;
        cardiovasc: string;
        respSystem: string;
        urineOutput: string;

        type: string;
        pp1: string;
        uterusPalpable: string;
        fundalHt: string;
        utContractions: string;

        fifthPalpable: string;
        amnioticFluid: string;
        fhs: string;
        fhr: string;
        urineAlbumin: string;
        others1: string;
        psEaxm: string;
        psOthers: string;
        cxLength: string;
        cxDilation: string;
        membranes: string;
        presentingPart: string;
        station: string;
        pelvis: string;
        others2: string;
        others3: string;
        others4: string;
        others5: string;
        others6: string;
        postnatalComplaints: string;
        otherComplaints2: string;
        lactating: string;
        perineum: string;
        perinealWound: string;
        abdominalIncision: string;
        perSpeculam: string;
        perVaginum: string;
        perRectal: string;
        bleedingPv: string;
        details: string;
        drugAllergies: string;
        consultantOpinion: string;
        counselling: string;
        consultantInformed: string;
        doctorsReview: string;
        seenByDr: string;
        otherConsultant: string;

    }

    export class RiskCategoryAtAdm {
        date: Date;
        midwifeLedSupportEligible: string;
        maternalCharacteristics: Array<string>;
        pastObstetricHistory: Array<string>;
        currentMedicalProblems: Array<string>;
        pastSurgicalHistory: Array<string>;
        currentAntenatalProblems: Array<string>;
        problemDetectedInLabour: Array<string>;
        totalNumberOfRiskFactors: number;
        others: string;
    }
    export class AddendumForm {
        note: string;
        date: Date;
        time: string;
    }
    export class Confidential {
        confidential: string;
    }

    export class TelephoneTriageAssessment {
        callDate: Date
        callTime: string;
        typeofConsultation: string;
        reasonForCall: Array<string>;
        reasonothers: string;
        adviceGiventoPatient: Array<string>;
        adviceother: string;
        actionTakenIfAdvisedToVisit: Array<string>;
        actionother: string;
        doctorName: string;
        callCompletedDate: string;
        callcompletedTime: string;
        telePhoneTriageAssessmentId:any
    }

    export class ScanTest {
        id: number;
        name: string;
        comments: string;
    }
    export class AldreteScore {
        activity: string;
        respiration: string;
        circulation: string;
        consciousness: string;
        oxygensaturation: string;
        totalscore: number;
        scoreDoneBy: string;
    }
    export class AnaesthesiaRecordPostOP {
        date: Date
        ot: string;
        consent: string;
        npoStatus: string;
        anaesthesiologist: string;
        surgeon: string;
        surgeryDone: string;
        anaesthesiaTechnique: string;
        pulseRate: string;
        systolicBP: string;
        diastoticBP: string;
        respiratoryRate: string;
        spo2: string;
        anaesthesiaStartedTime: string;
        completedTime: string;
        surgeryStartedTime: string;
        scompletedTime: string;
        lma: string;
        size: string;
        cufDtls: string;
        ettSize: string;
        noofAttempts: string;
        difficultIntubation: string;
        clGroup: string;
        vls: string;
        sellicksManoeuvre: string;
        oxygen: string;
        n2o: string;
        air: string;
        inhalationalAgent: string;
        ventilation: string;
        vt: string;
        rr: string;
        awp: string;
        peep: string;
        regionalAnesthesia: string;
        tipg: string;
        positionInsert: string;
        spacelorsa: string;
        time: string;
        drug: string;
        vdose: string;
        onset: string;
        dermLevel: string;
        motorBlock: string;
        paraesthesiaInsert: string;
        btoodTap: string;
        dp: string;
        comments: string;
        topups: string;
        patientPosition: string;
        monitoring: Array<string>
        crystal: string;
        colloids: string;
        bllod: string;
        estimatedBloodLoss: string;
        urine: string;
        ascitis: string;
        rylesTubeAspiration: string;
        recovery: string;
        status: string;
        reflwxesMuscleTone: string;
        pr: string;
        sysbp: string;
        dbp: string;
        spo: string;
        vas: string;
        shiftedTo: string;
        electiveVentilation: string;
        anyAdverseEvents: string;
        totalIntakeOutput: string;
        continuevitalsdate: string;
        continuevitalstime: string;
        continuevitalsspo2: string;
        etCo2: string;
        fio2: string;
        inhalionalagent: string;
        temperature: string;
        vitalshistory: Array<vitalsHistory>

    }
    export class vitalsHistory {
        vitalsdate: Date;
        vitalstime: string;
        vitalsSPO2: string;
        vitalsETCO2: string;
        vitalsFIO2: string;
        vitalsInhalionalagent: string;
        vitalsTemperature: string;
    }
}