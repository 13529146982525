import { Component, OnDestroy, OnInit, ViewEncapsulation, Input, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ApiResources } from "@shared/helpers";
import { Appointment, PreviousAppointment, PatientMedicationHeader, ImageReports, ScanBooking, Setting } from "@shared/entities";
import { Page, Pagination, IResource, IUserAccount, PatientProfile, IEncounterResource } from "@shared/models";
import { AppData, HttpService, EncounterCommunication, IconService, PrintOptionService, NotifyService, EmergencyContact } from "@shared/services";
import { finalize, takeUntil } from "rxjs/operators";
import { DatePipe } from "@angular/common";
import { InternalMedicine } from "@admin/internal-medicine/internal-medicine.namespace";
import { OrderPrescription } from "@shared/entities";

import { LabBillHeaderModel } from "@admin/labs/pages/models";

import { ResourceService } from "@shared/services";
import { OB } from "../../../areas/admin/full-transcript/pages/ob-report/ob.namespace";
import { GynEncounter } from "../../models/gyn-encounter.model";
import { GYN } from "../../../areas/admin/gyn-encounter/gyn.namespace";

class OrderPrescriptionModel {
    appoitmentId: number;
    orderPrescription: string;
}

@Component({
    templateUrl: "./gyn-print-prescription.html",
   
    selector: "gyn-printprescription",
    encapsulation: ViewEncapsulation.None
})
export class GYNPrescriptionPage implements OnInit, OnDestroy, AfterViewInit {
    @Input() encryptedPatientId: string;
    @Input() encryptedAppointmentId: string;
    @Input() printId: string;
    @Input() printadmission: boolean;
    loadingVitals: boolean;
    routingValue: string;
    page: Page;
    loadingCategories: boolean;
    categories: Array<IResource>;
    section: string;
    pagination: Pagination;
    loading: boolean;
    appointmentId: string;
    isAdmission: boolean;
    providerId: number;
    patientId: number;
    appointments: Array<Appointment>;
    oldAppointment: PreviousAppointment;
    gynCard: IEncounterResource;
    fullTranscript: GynEncounter;
    result: number;
    patient: PatientProfile;
    appointment: Appointment;
    submitting: boolean;
    husbandName: string;
    gynNo: string;
    referralOrder: Array<OB.OrderReferral>;
    scanAppointmentDetails: OB.ScanDetails;
    isAppointmentClosed: boolean;
    a1_Block = true;
    a_Block = true;
    b_Block = true;
    c_Block = true;
    d_Block = true;
    in_Block = true;
    o_Block = true;
    om_Block = true;
    e_Block = true;
    f_Block = true;
    g_Block = true;
    h_Block = true;
    i_Block = true;
    j_Block = true;
    k_Block = true;
    l_Block = true;
    m_Block = true;
    vt_block = true;
    t_Block = true;
    p_Block = true;
    infuion_Block = true;
    sc_Block = true;
    records: Array<PatientMedicationHeader>;
    iconBasics: ImageReports;
    flag = true;
    @Input() isPrintLogo: boolean;
    ordersPrescription: OB.OrdersPrescription;

    gynCardGeneration: GYN.GynCardGeneration;
    medicationComment: InternalMedicine.MedicationComment;
    gynHistory: GYN.NewGynHistory;
    orders: Array<OrderPrescription>;
    gynaecComplaints: any;
    medicationPrescription: any;
    investigationtemplate: any;
    otherInvestigation: any;
    proceduretemplate: any;
    gyneacNotes: any;
    referrals: any;
    importantAdvice: any;
    otherMedication: any;
    templates: any;
    pregnancy: any;
    partnerDetails: GYN.GyneacPartner;
    visitNo: number;
    currentDate: Date;
    bookingHeader: Array<LabBillHeaderModel>;
    gyneacVisit: GYN.GyneacVisit;
    measures: GYN.Measures;
    scanTests: any;
    scanTime: string;
    isTeleconsulatantReport: boolean;
    addendumRecords: GYN.AddendumForm;
    signature: string;
    currentLocation: string;
    scanAppointments: Array<ScanBooking>
    futureAppointments: Array<Appointment>;
    visitNumber: number;
    printBy: string;
    loadingRepotName: boolean;
    reportName: string;
    reportNameHeader: string;
    reportNameFooter: string;
    vitalsdata: any
    infusionrecords: any
    infusions: any
    emergencyContacts: any;
    isFooter: boolean;
    orderdata: Array<any>;
    encounterTypes: IResource[];
    selectedEncounter: any;
    isMedicationInstruction: boolean
    isCheckPrintPrsecription: boolean
    loadingLocation: boolean;
    emergencyContactsLocationBased: Array<IResource>;
    locationId: number = null;
    constructor(
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
        private readonly iconService: IconService,
        private readonly notifyService: NotifyService,
        private readonly encounterCommunication: EncounterCommunication,
        private readonly datePipe: DatePipe,
        private readonly resourceService: ResourceService,
        public readonly emergencyContact: EmergencyContact,

    ) {
        this.page = new Page();
        this.pagination = new Pagination();
        this.appointments = [];
        this.records = new Array<PatientMedicationHeader>();
        //this.currentDate = this.datePipe.transform((new Date), 'MM/dd/yyyy h:mm:ss a');
        this.bookingHeader = new Array<LabBillHeaderModel>();
        this.currentDate = new Date();
        this.emergencyContact.getContact((is) => { this.emergencyContacts = is; });
        this.orderdata = new Array<any>()
        this.emergencyContactsLocationBased = new Array<IResource>();

    }
    ngAfterViewInit() {
        this.paddingTop();
    }
    paddingTop() {
        if (document.getElementById("header") != null && document.getElementById("obprintContainer") != null) {
            var paddingTopValue = document.getElementById("header").offsetHeight;
            var content = document.getElementById("obprintContainer");
            content.setAttribute('style', `padding-top:${paddingTopValue}px`);
        }
    }
    private fetchLabs() {
        this.loading = true;
        const request = {
            appointmentId: this.appointment.appointmentId,
            isAdmission: this.isAdmission
        }
        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetchLabs), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                if (response && response.length > 0) {
                    this.bookingHeader = response[0].labs;
                }
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });
    }
    private findPatient() {
        let intervalHit = setInterval(() => {
            let setting = localStorage.getItem("settings");
            if (setting) {
                clearInterval(intervalHit);
                this.page.loading = true;
                const request = { encryptedAppointmentId: this.appointmentId };
                request["isAdmission"] = this.isAdmission;
                this.httpService.post<GynEncounter>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
                    .pipe(takeUntil(this.page.unSubscribe))
                    .subscribe((response: GynEncounter) => {
                        this.encryptedPatientId = response.encryptedPatientId;

                        this.findPatientDetails(response.encryptedPatientId);
                    });
            }
        }, 10);
    }
    private getSettings() {
        this.loading = true;
        this.httpService.get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: (response: Array<Setting>) => {
                    if (response && response.length > 0) {
                        var medciationINstruction = response.find(s => s.name == "OrderPrescriptionMedicationInstruction");
                        var isOrderPrescription = response.find(s => s.name == "ObOrderPrescriptionDesign");
                        this.isMedicationInstruction = medciationINstruction && medciationINstruction.active ? medciationINstruction.active : false;
                        this.isCheckPrintPrsecription = isOrderPrescription && isOrderPrescription.active ? isOrderPrescription.active : false;

                    }
                }, error: () => { }
            });
    }
    private findDashboard() {
        let intervalHit = setInterval(() => {
            let setting = localStorage.getItem("settings");
            if (setting) {
                clearInterval(intervalHit);
                if (this.routingValue == 'gyn-ip-encounter') {
                    this.isAdmission = true
                }

                const request = { encryptedAppointmentId: this.appointmentId, encryptedProviderId: this.page.userAccount.encryptedReferenceId, encryptedPatientId: this.encryptedPatientId };
                request["isAdmission"] = this.isAdmission;

                this.httpService.post<GynEncounter>(ApiResources.getURI(ApiResources.gynEncounter.base, ApiResources.gynEncounter.findFullTranscript), request)
                    .pipe(takeUntil(this.page.unSubscribe))
                    .pipe(finalize(() => this.page.loading = false))
                    .subscribe((response: GynEncounter) => {
                        this.fullTranscript = response;
                        if (this.fullTranscript && this.fullTranscript.gynEncounterId) {
                            this.ordersPrescription = this.fullTranscript.orderPrescription ? JSON.parse(this.fullTranscript.orderPrescription) : null;
                            this.medicationComment = this.fullTranscript.orderPrescription ? JSON.parse(this.fullTranscript.orderPrescription) : null;
                            this.partnerDetails = this.fullTranscript.gyneacPartner ? JSON.parse(this.fullTranscript.gyneacPartner) : null;
                            this.gynHistory = this.fullTranscript.gynHistory ? JSON.parse(this.fullTranscript.gynHistory) : null;
                            this.referralOrder = this.fullTranscript.refferalOrder ? JSON.parse(this.fullTranscript.refferalOrder) : null;
                            this.scanAppointmentDetails = this.fullTranscript.scanAppointmentDetails ? JSON.parse(this.fullTranscript.scanAppointmentDetails) : null;
                            this.addendumRecords = this.fullTranscript.addendumForm ? JSON.parse(this.fullTranscript.addendumForm) : null;
                            this.gyneacVisit = this.fullTranscript.gynaecVisit ? JSON.parse(this.fullTranscript.gynaecVisit) : null;
                            this.measures = this.fullTranscript.measure ? JSON.parse(this.fullTranscript.measure) : null;
                            this.scanTests = this.fullTranscript.scanTests ? JSON.parse(this.fullTranscript.scanTests) : null;
                            if (this.scanAppointmentDetails && this.scanAppointmentDetails.appointmentDate) {
                                var today = new Date(this.scanAppointmentDetails.appointmentDate + 'T' + this.scanAppointmentDetails.appointmentTime);
                                var currentHrs = today.getHours();
                                //var currentTime = today.getMinutes();
                                var ampm = currentHrs >= 12 ? 'PM' : 'AM';
                                this.scanTime = this.scanAppointmentDetails.appointmentTime + ' ' + ampm;
                            }
                            if (this.measures || this.visitNumber) {
                                this.visitNo = this.measures && this.measures.visitNo ? parseInt(this.measures.visitNo) : this.visitNumber;
                            }
                            else if (this.partnerDetails) {
                                this.visitNo = this.partnerDetails && this.partnerDetails.visitNo;
                            }
                            this.visitNo = this.measures && this.measures.visitNo ? parseInt(this.measures.visitNo) : this.partnerDetails && this.partnerDetails.visitNo ? this.partnerDetails.visitNo : this.visitNumber
                        }

                        if (this.printId == null) {
                            if (!this.isTeleconsulatantReport) {
                                if (response.orderPrescription != null) {
                                    this.notifyService.confirm(" you want to complete the transaction for this visit", () => {
                                        this.encounterCommunication.modifyGynEncounterStatus(this.appointment.appointmentId);
                                    }, () => { $("#printButton").click(); })
                                }
                                else {
                                    this.notifyService.confirm("Order details are not available for this patient visit, still you would like to continue", () => {
                                        this.notifyService.confirm("do you want to complete the transaction for this visit", () => {
                                            this.encounterCommunication.modifyGynEncounterStatus(this.appointment.appointmentId);
                                        }, () => { $("#printButton").click(); })
                                    })
                                }
                            }
                        }
                    });
            }
        }, 10);

    }

    private findOrderPrescription() {
        let PatientId: number = this.patientId;
        this.httpService.get(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchOrderPrescription), { patientId: PatientId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Array<OrderPrescriptionModel>) => {
                if (response.length > 0) {
                    response.forEach((item: OrderPrescriptionModel) => {
                        var jsonParsed = JSON.parse(item.orderPrescription);
                        this.orders.push(jsonParsed)
                    })
                }
            });
    }
    private findPatientDetails(id: string) {
        this.page.loading = true;
        this.httpService.post<PatientProfile>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.profile), { encryptedPatientId: id })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: PatientProfile) => {
                this.patient = response;
                this.husbandName = this.patient.relativeDetails[0] ? this.patient.relativeDetails[0].fullName : null;

            });
    }
    private findAncCard(id: number) {

        this.httpService.get(ApiResources.getURI(ApiResources.gynEncounter.base, ApiResources.gynEncounter.fetchGynCard), { patientId: id })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: GYN.GynCardGeneration) => {
                if (response) {
                    this.gynCardGeneration = response;
                    this.findOrderPrescription();
                    this.fetchMedication();
                    //this.findDashboard();
                    this.fetchLabs();
                }
            });
    }

    private fetchMedication() {
        this.loading = true;
        const request = {
            encryptedAppointmentId: this.appointmentId,
            isAdmission: this.isAdmission
        }

        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                this.records = response;
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });

    }

    private findAppointment(appointmentId: string) {
        const request = {
            encryptedAppointmentId: appointmentId,
            isAdmission: this.isAdmission
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointment = response;
                this.findAncCard(this.appointment.patientId);
                setTimeout(() => {
                    this.findVisitNo(this.appointment.patientId);
                }, 500)
                setTimeout(() => {
                    this.findAncCard(this.appointment.patientId);
                }, 500)
                this.findDashboard();
                this.fetchFutureAppointments();
            });

    }
    private findVisitNo(id: any) {
        const request = { appointmentId: this.appointment.appointmentId, isAdmission: this.isAdmission, patientId: this.appointment.patientId }
        this.httpService.post(ApiResources.getURI(ApiResources.gynEncounter.base, ApiResources.gynEncounter.findvisitNumber), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: any) => {
                var records = response;
                if (records != null) {
                    var measures = JSON.parse(records.measure);

                    this.visitNumber = measures != null && measures.visitNo ? (measures.visitNo + 1) : 1;

                }
                else {
                    this.visitNumber = 1;
                }
            });
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.currentLocation = this.page.userAccount.locationName;
                    this.printBy = this.page.userAccount.fullName;
                    if (this.printId) {
                        this.appointmentId = this.printId;
                        this.isAdmission = this.printadmission;
                        this.findAppointment(this.printId);
                    }
                    else {
                        const url = this.router.url;
                        this.isAdmission = url.split("/")[4] === "a";
                        this.appointmentId = decodeURIComponent(url.split("/")[3]);
                        this.encryptedAppointmentId = decodeURIComponent(this.appointmentId);
                        this.routingValue = url.split("/")[2];
                        this.isTeleconsulatantReport = url.split("/")[6] === "T";
                        this.findAppointment(this.appointmentId);
                    }
                    this.findPatient();
                    this.fetch();
                    this.getSettings();
                    this.fetchEncounterType();
                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((params: Params) => {
                            const section = params["section"];
                            this.section = section ? section : undefined;
                        });
                    this.locationId = userAccount.locationId;
                    this.fetchLocationsEmergencyContacts();


                } else {
                    this.page.userAccount = undefined;
                }
                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                this.iconService.getIconImage((is) => { this.iconBasics = is; });
            });
    }


    onChangeStatus = (type: string, e: MouseEvent) => {
        switch (type) {
            case "a1":
                this.a1_Block = !this.a1_Block;
                break;
            case "a":
                this.a_Block = !this.a_Block;
                break;
            case "b":
                this.b_Block = !this.b_Block;
                break;
            case "c":
                this.l_Block = !this.l_Block;
                break;
            case "j":
                this.j_Block = !this.j_Block;
                break;
            case "k":
                this.k_Block = !this.k_Block;
                break;
            case "VT":
                this.vt_block = !this.vt_block;
                break;
            case "infusion":
                this.infuion_Block = !this.infuion_Block;
                break;
            case "sc":
                this.sc_Block = !this.sc_Block;
                break;
        }
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }
    fetchInfusions() {
        this.loading = true;
        const request = {
            encryptedAppointmentId: this.appointmentId,
            isAdmission: this.isAdmission
        }

        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetchInfusion), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<any>) => {
                this.infusionrecords = response;
                this.infusionrecords.forEach((product) => {
                    product.infusions.forEach((x) => {
                        if (x != null) {
                            this.infusions.push(x);
                        }

                    })

                })

            });

    }
    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }
    private fetchFutureAppointments() {
        let request = {
            encryptedPatientId: this.appointment.encryptedPatientId,
        }
        this.httpService
            .post<[Appointment, ScanBooking]>(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.findFutureAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: [Appointment, ScanBooking]) => {
                    this.futureAppointments = response["appointments"];
                    this.scanAppointments = response["scanAppointments"];
                },
                () => {
                    this.futureAppointments = [];
                    this.scanAppointments = [];
                }
            );
    }
    onChangePrintType(islogo: boolean) {
        this.isPrintLogo = islogo;
    }
    fetch() {

        this.loading = true;
        this.httpService
            .post<any>(ApiResources.getURI(ApiResources.progressReportVitals.base, ApiResources.progressReportVitals.fetchemr), { encryptedAdmissionId: this.appointmentId })
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: any) => {
                    if (response) {
                        if (response) {
                            this.vitalsdata = response.data
                        }
                    }


                },
                () => {
                    this.notifyService.defaultError();
                }
            );
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
    private fetchEncounterType() {
        this.resourceService.encounterType()
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.encounterTypes = response;
                var name = this.routingValue === 'ob-encounter' ? "OB Encounter" :
                    this.routingValue === 'gyn-encounter' || this.routingValue === 'gyn-ip-encounter' ? "Gyne Encounter" :
                        this.routingValue === 'pediatric-encounter' ? "Pediatric Encounter" :
                            this.routingValue === 'diet-plan-encounter' ? "Diet Encounter" :
                                this.routingValue === 'internal-medicine' ? "Internal Medicine" :
                                    this.routingValue === 'genetic-speciality' ? "Genetic Specialty Encounter" : this.routingValue === 'ob-ip-encounter' ? "OB IP Encounter" : this.routingValue === 'physiotherapy-encounter' ?
                                        "Physiotherapy Encounter" : this.routingValue === 'neonatal-ip-encounter' ? "NeonatalIP Encounter" : this.routingValue === 'ivf-encounter' ? "IVF Encounter" : 0;
                var selectedEncounter = this.encounterTypes.find(s => s.name == name);
                this.selectedEncounter = selectedEncounter;
                this.onFetchMasterForOper();

            });
    }
    onFetchMasterForOper() {
        let encounterTypeId;
        let providerId;

        encounterTypeId = this.selectedEncounter?.id;
        if (this.page.userAccount.roleId == 3) {
            providerId = this.page.userAccount.referenceId;
        }
        const req = { encounterTypeId: encounterTypeId, providerId: providerId };
        this.httpService.post<Array<OrderPrescription>>(ApiResources.getURI(ApiResources.orders.base, ApiResources.orders.fetchOrders), req)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: any) => {
                this.orderdata = response;

            });
    }

    private fetchLocationsEmergencyContacts() {
        this.loadingLocation = true;
        this.resourceService.locations()
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingLocation = false))
            .subscribe((response: Array<IResource>) => {
                this.emergencyContactsLocationBased = response.filter(location => location.id === this.locationId);
            });
    }
}