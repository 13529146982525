<div class="modal-body" *ngIf="loading">
    <div class="d-flex align-items-center">
        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
        <span class="ml-2">Please wait while loading bill details....</span>
    </div>
</div>
<div class="modal-body" *ngIf="!loading && bill.length === 0">
    <div class="d-flex justify-content-center">
        <div class="no-data-container">
            <img class="mt-1" width="350" src="assets/images/no-data-2.png" alt="No Data" />
            <h4 class="mt-1">No Bill Data found.Nothing to show you.</h4>
        </div>
    </div>
</div>

<div class="modal-body bg-white" Id="invoiceId" *ngIf="!loading && bill.length > 0">
    <div class="report bg-white" *ngFor="let bill of bill;index as i;">
        <div class=" overflow-auto pt-2" *ngIf="isPrintLogo">
            <banner-setting [reportName]='"Appointment Invoive"'></banner-setting>
        </div>
        <div class="w-100 overflow-auto">
            <div class="d-inline-block w-100 text-center">
                <div><h3 class="btp pt-2" [textContent]="'Final Bill of Supply - Diagnostics Bill'"></h3></div>
                <div><h4 class="pb-2 pt-2 font-weight-normal" [textContent]="'(Health Care Service - SAC)'"></h4></div>
            </div>
        </div>
        <div>
            <table class="table table-sm table-borderless table-centered bg-white bt_bb  m-0">
                <tbody>
                    <tr>
                        <td class="pt-3 white-space-nowrap">Patient Name :</td>
                        <td class="pt-3">

                            <h5 class="text_uppercase" [textContent]="bill.patientName "></h5>
                        </td>
                        <td class="pt-3 white-space-nowrap">UMR No :</td>
                        <td class="pt-3">

                            <h5 class="text_uppercase" [textContent]="bill.umrNo"></h5>
                        </td>
                        <td class="pt-3 white-space-nowrap">Bill Date/Time :</td>
                        <td class="pt-3">

                            <h5 class="text_uppercase" [textContent]="bill.createdDate | date:'dd/MM/yyyy h:mm a'"></h5>
                        </td>
                    </tr>
                    <tr>
                        <td> &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;   &nbsp;</td>
                        <td>
                            <h5 class="ms-2 text_uppercase">   {{relationType ? relationType : "" }} {{bill.relativeName?bill.relativeName:""}}</h5>
                        </td>
                        <td class="white-space-nowrap">Bill No :</td>
                        <td>

                            <h5 class="text_uppercase" [textContent]="bill.billNumber || 'N/A'"></h5>
                        </td>
                        <td class="white-space-nowrap">Age/Sex :</td>
                        <td>
                            
                            <h5 class="text_uppercase d-inline">{{bill.patientAge}}/</h5>
                            <h5 class=" d-inline" [textContent]="(bill.gender === 'F' ? 'Female' : 'Male') "></h5>
                        </td>
                    </tr>
                    <tr>
                        <td class="pb-3 white-space-nowrap">Doctor Name :</td>
                        <td class="pb-3">

                            <h5 class="text_uppercase" [textContent]="bill.doctorName"></h5>
                        </td>
                        <td class="pb-3 white-space-nowrap">
                            Req No :
                        </td>

                        <td class="pb-3">

                            <h5 class="text_uppercase" [textContent]="bill.requisitionNumber"></h5>
                        </td>
                        <td class="pb-3 white-space-nowrap">Mobile No :</td>
                        <td class="pb-3">

                            <h5 class="text_uppercase" [textContent]="bill.mobile"></h5>
                        </td>
                    </tr>
                    <tr>
                        <td class="pb-3">Billed Location :</td>
                        <td class="pb-3">
                            
                            <h5 class="text_uppercase" [textContent]="bill.locationName"></h5>
                        </td>
                       
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="row  bg-white">
            <div class="col-12">
                <div>
                    <table class="border-dark border_font" width="100%" border="0" cellspacing="0" cellpadding="5">
                        <thead>
                            <tr>
                                <th class="border_th pt-1 pb-1">S.No</th>
                                <th class="border_th  pt-1 pb-1">Dept Name</th>
                                <th class="border_th  pt-1 pb-1">Investigations</th>
                                <th class="border_th  pt-1 pb-1">Status</th>
                                <th class="border_th  pt-1 pb-1  text-right">Emergency</th>
                                <th class="border_th  pt-1 pb-1  text-right">Rate</th>
                                <th class="border_th  pt-1 pb-1  text-right">DISC(RS)</th>
                                <th class="border_th  pt-1 pb-1 text-right">Amount(Rs)</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lab of bill.labs;index as j;">
                                <td class=" pt-1 pb-1 text-uppercase">
                                    <span class="mb-0 mt-0" [textContent]="j+1"></span>
                                </td>
                                <td class=" pt-1 pb-1 text-uppercase">
                                    <span class="mb-0 mt-0 " [textContent]="lab.departmentName"></span>
                                </td>
                                <td class=" pt-1 pb-1 text-uppercase">
                                    <span class="mb-0 mt-0 " [textContent]="lab.testName"></span>
                                </td>
                                <td class=" pt-1 pb-1">
                                    <span class="mb-0 mt-0 " [textContent]="lab.status"></span>
                                </td>
                                <td class=" pt-1 pb-1 text-right">
                                    <span class="mb-0 mt-0 "></span>
                                </td>
                                <td class=" pt-1 pb-1 text-right">
                                    <span class="mb-0 mt-0 " [textContent]="lab.totalAmount | currency:'INR'"></span>
                                </td>
                                <td class=" pt-1 pb-1 text-right">
                                    <span class="mb-0 mt-0 " [textContent]="lab.discountAmount | currency:'INR'"></span>
                                </td>
                                <td class=" pt-1 pb-1 text-right" *ngIf="showFinanceandPrintByDetails">
                                    <span class="mb-0 mt-0  " [textContent]="lab.isFromPackage ? '----' :lab.netAmount | currency:'INR' "></span>
                                </td>
                            </tr>
                            <tr class="border-bottom">
                                <td *ngIf="isNewlabinvoiceId" class="justify-content-center d-flex">
                                    <div id="backgroundOfwaterMark" style="-webkit-transform: rotate(-30deg);margin-top:-55px">
                                        <span class=" text-danger font-bold"><b>Duplicate</b></span>
                                    </div>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class=" mtop pad_left w-100">
                        <tr>
                            <td>
                                <span>Rupees:</span>
                            </td>
                            <td>
                                <h5 class=" first-letter"> {{amountInWord}} </h5>
                            </td>
                            <th colspan="5" class="text-right p-0"><span class="pr-2">Total Amount Rs:</span></th>
                            <td class="text-right p-0 w-75p">
                                <h5 [textContent]="( total | currency:'INR' )"></h5>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Diagnosis :
                            </td>
                            <td>
                                <h5>Lab</h5>
                            </td>
                            <td colspan="5" class="text-right p_width p-0">
                                <span class="pr-2">Paid Amount Rs :</span>
                            </td>
                            <td class="text-right p-0 w-75p">
                                <h5> <span [textContent]="paidAmount | currency:'INR'"></span></h5>
                            </td>
                        </tr>
                        <tr *ngIf="payModes && payModes.length > 0">
                            <td class="w120">
                                Pay Mode:
                            </td>
                        </tr>
                        <tr *ngFor="let item of payModes;">
                            <td>
                                <h5 class="ml-3 text-capitalize"><span [textContent]="item.payTypeName"></span></h5>
                            </td>
                            <td>
                                <h5 class="ml-2 text-capitalize"><span [textContent]="item.amountPaid | currency:'INR'"></span></h5>
                            </td>
                            <td>
                                <h5 class="ml-2 text-capitalize"><span [textContent]="item.createdDate | date : 'dd-MM-yyyy'"></span></h5>
                            </td>
                        </tr>
                    </table>
                    <table>
                        <tr>
                            <td *ngIf="practiceLocations[0] && practiceLocations[0].optionalText1">
                                <span> Note: {{practiceLocations[0].optionalText1}}</span>
                            </td>
                        </tr>
                    </table>
                    <table>
                        <tr class="d-flex">
                            <td class="d-flex">
                                User Name : <h5 class="ms-2 text_uppercase pr-2" [textContent]="page.userAccount.fullName"></h5>
                            </td>
                            <td class="d-flex pl-5">
                                Password : <h5 class="ms-2 text_uppercase pr-2" [textContent]="page.userAccount.password"></h5>
                            </td>
                        </tr>
                    </table>
                    <table>
                        <tr class="col-12">

                            <td>
                                <div>
                                    <practice-content></practice-content>
                                </div>
                            </td>
                        </tr>

                    </table>
                    <table class="border_width">
                        <tr class="col-12">
                            <td class="col-5">
                            </td>
                            <td>
                                Print Date/Time:<span [textContent]="currentDate | date :'dd/MM/yyyy, h:mm a'"></span>. &nbsp;
                            </td>
                            <td>
                                {{page.userAccount.fullName}}
                            </td>
                        </tr>
                    </table>
                </div>
                <table class="border_width">
                    <tr class="col-12">
                        <td>
                            <ngx-barcode6 [bc-value]="bill.requisitionNumber" [bc-width]="2" [bc-height]="40"
                                          [bc-display-value]="false">
                            </ngx-barcode6>
                        </td>
                        <td>
                            <qrcode [qrdata]="QrCode" [width]="200" [errorCorrectionLevel]="'M'" [elementType]="'img'"></qrcode>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div *ngIf="isFooter">
            <banner-setting [reportName]='"Appointment Invoice Footer"'></banner-setting>
        </div>
    </div>

</div>

            
           
