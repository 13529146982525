/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnInit, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from "@angular/core";
import { Page, IUserAccount, ProgressData } from "../../models";
import { AppData, HttpService, PrintOptionService } from "../../services";
import { takeUntil, finalize } from "rxjs/operators";
import { ApiResources, UtilHelper, LinqHelper } from "../../helpers";
import { forkJoin } from "rxjs";
import { LabTransferModel, LabParameterInputModel, LabTemplateHeaderForInput, LabParameterInputHeaderModel, LabComponentHeaderForInput, LabBillDetailModel, LabBillHeaderModel, LabParameterGroup, LabTemplateDetail } from "../../../areas/admin/labs/pages/models";
import { LabReportSignature } from "../../../areas/admin/labs/pages/models/lab-report-signature";
import moment from "moment";
import { DomSanitizer } from "@angular/platform-browser";
import { Appointment, Patient, Setting } from "../../entities";
import { LabDocumentsDetails } from "../../entities/laboratory/lab-document-details.entity";
import { Pagination } from "@shared/models";
import { HostListener } from '@angular/core';
import html2canvas from 'html2canvas';
import { pow } from "mathjs";
class Age {
    year: number;
    month: number;
    day: number;
}

@Component({
    templateUrl: "./migrated-lab-report.html",
    selector: "migrated-lab-report",
    styleUrls: ["./migrated-lab-report.css"],
    encapsulation: ViewEncapsulation.None
})

export class MigratedLabReportWidget implements OnInit, OnDestroy {
    @Input() newLabBookingDetailId: number;
    @Input() encryptedNewLabBookingDetailId: string;
    @Input() encryptedPatientId: string;
    @Output() onClose = new EventEmitter<any>();
    @Input() isPrintLogo: boolean = true;
    @Input() isFooter: boolean = true;
    isPrintTermsAndConditions: boolean;
    page: Page;
    loading: boolean;
    demographData: any;
    resultdata: Array<any>;
    age: Age;
    parameters: LabParameterInputModel;
    reportSignatures: Array<LabReportSignature>;
    reports: LabParameterInputModel;
    checkExternal: LabBillDetailModel;
    isExternalLoading: boolean;
    isExternalReport: boolean;
    reportData: any;
    reportDataWithoutAuth: any;
    QrCode: string = null;
    printIframe: any;
    blobUrls: any;
    headerUrl: string;
    footerUrl: string;
    nablUrl: string;
    careAxesUrl: string = "assets/images/careaxesLogo.png";
    documents: Array<LabDocumentsDetails>;
   // encryptedPatientId: string;
    pagination: Pagination;
    allDocumentUrl: string[];
    allDocimentId: number[];
    reportDataArray: any;
    reportDataWithoutAuthArray: any
    document: LabDocumentsDetails;
    loadingDocument: boolean;
    isFirstCall: boolean;
    isAdmission: boolean;
    appointment: Appointment;
    relationType: string;
    billedDate: Date;
    bill: LabBillHeaderModel;
    logoDocument: any;
    attachmentUrl: any = [];
    attachmentImageUrl: any = [];
    attachmentVideoUrl: any = [];
    pdfLoading: boolean;
    patient: Patient;
    @Output() isExternal = new EventEmitter<boolean>();
    @Output() uplodpfd = new EventEmitter<any>();
    templateDetail: LabTemplateDetail[];
    templateDetailsOrder: Array<any>;
    isShowMicrobiology: boolean;
    isShowMicrobiologyFromCommnSettings: boolean;
  
    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly sanitizer: DomSanitizer,
        private readonly printOptionService: PrintOptionService
    ) {
        this.page = new Page();
        this.age = new Age();
        this.checkExternal = new LabBillDetailModel();
        this.demographData = new Array<LabTransferModel>();
        this.initPagination();
        this.isFirstCall = true;
        this.templateDetailsOrder = new Array<any>();
        this.resultdata = new Array<any>();
        this.isPrintLogo = true;
        this.isFooter = true;
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        if (event.ctrlKey && event.key === 'p') {
            event.preventDefault();
            this.generatePDF();
        }
    }

    generatePdfFromButton() {
        this.isFirstCall = false;
        this.generatePDF();
    }
    async generatePDF() {
        let elementData = [];
        const headerElement = document.getElementById('header-content');
        const headerCanvas = await html2canvas(headerElement, {
            scale: 1,
            useCORS: true
        });
        const headerImageDataUrl = headerCanvas.toDataURL('image/png');
        const headerImageBytes = await fetch(headerImageDataUrl).then(res => res.arrayBuffer());

        try {

            const elementsToDisable = Array.from(document.querySelectorAll('.disable-on-pdf'));
            elementsToDisable.forEach((el) => {
                elementData.push({
                    element: el,
                    parent: el.parentElement,
                    nextSibling: el.nextSibling
                });
                el.remove();
            });

            const htmlContent = document.getElementById('invoiceId')?.outerHTML;
            if (!htmlContent) {
                throw new Error('HTML content is missing');
            }

            const html2pdf = await import('html2pdf.js');
            const { PDFDocument } = await import('pdf-lib');


            const pdfDoc = await html2pdf.default().from(htmlContent).set({
                filename: 'merged-content.pdf',
                margin: [200, 0, 10, 0],
                image: { type: 'png', quality: 1 },
                pagebreak: { mode: ['avoid-all'] },
                html2canvas: { scale: 1, useCORS: true },
                jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait', fontSize: 1 }
            }).output('blob').then(async (pdfBlob) => {
                const pdfArrayBuffer = await pdfBlob.arrayBuffer();
                return PDFDocument.load(pdfArrayBuffer);
            });


            const headerImage = await pdfDoc.embedPng(headerImageBytes);


            const pages = pdfDoc.getPages();
            for (let i = 0; i < pages.length; i++) {
                const page = pages[i];
                const { width, height } = page.getSize();
                const headerScale = width / headerCanvas.width;
                const headerWidth = width;
                const headerHeight = headerCanvas.height * headerScale;

                const yPosition = height - headerHeight;


                page.drawImage(headerImage, {
                    x: 0,
                    y: yPosition,
                    width: headerWidth,
                    height: headerHeight
                });
            }


            if (this.reportDataWithoutAuthArray && this.reportDataWithoutAuthArray.length > 0) {
                for (const item of this.reportDataWithoutAuthArray) {
                    const base64String = item.url.split(',')[1];
                    const binaryString = atob(base64String);
                    const uint8Array = new Uint8Array(binaryString.length);
                    for (let j = 0; j < binaryString.length; j++) {
                        uint8Array[j] = binaryString.charCodeAt(j);
                    }
                    const arrayBuffer = uint8Array.buffer;

                    if (item.filetype === 'pdf') {
                        const pdfDocToMerge = await PDFDocument.load(arrayBuffer);
                        const pagesToMerge = await pdfDoc.copyPages(pdfDocToMerge, pdfDocToMerge.getPageIndices());
                        pagesToMerge.forEach(page => pdfDoc.addPage(page));
                    } else if (['png', 'jpg', 'jpeg'].includes(item.filetype)) {
                        const imagePdfDoc = await PDFDocument.create();
                        const image = await imagePdfDoc.embedJpg(arrayBuffer);
                        const page = imagePdfDoc.addPage([image.width, image.height]);
                        page.drawImage(image, { x: 0, y: 0, width: image.width, height: image.height });
                        const pagesToMerge = await pdfDoc.copyPages(imagePdfDoc, imagePdfDoc.getPageIndices());
                        pagesToMerge.forEach(page => pdfDoc.addPage(page));
                    } else {
                        console.error(`Unsupported file type: ${item.filetype}`);
                    }
                }
            }

            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            elementData.forEach(({ element, parent, nextSibling }) => {
                if (nextSibling) {
                    parent.insertBefore(element, nextSibling);
                } else {
                    parent.appendChild(element);
                }
            });

            if (!this.isFirstCall) {
                this.printPDF(blob);
            }
        } catch (error) {
            console.error(error);
            elementData.forEach(({ element, parent, nextSibling }) => {
                if (nextSibling) {
                    parent.insertBefore(element, nextSibling);
                } else {
                    parent.appendChild(element);
                }
            });
        }
    }

    private printPDF(blob: Blob) {
        const url = URL.createObjectURL(blob);
        const newTab = window.open(url, '_blank', 'fullscreen=yes');

        newTab.onload = () => {
            newTab.print();
        };
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.printOptionService.get((is) => { this.isPrintLogo = is; });
                   
                    this.fetchCommonSettings();
                    this.fetchLabResult();
                    this.fetchPatient(this.encryptedPatientId);
                    
                } else {
                    this.fetchPatient(this.encryptedPatientId);
                    this.fetchLabResult();
                 

                }
            });
    }
    private initPagination() {
        this.pagination = new Pagination();
        this.pagination.pageIndex = 1;
        this.pagination.pageSize = 12;
    }
    ngOnDestroy() {
        function cleanUp() {
            try {
                let doc = document.getElementById("myDOC");
                doc.remove();
            } catch (e) {
                console.log(e);
                console.clear();
            }
        }
        cleanUp();
        this.page.unsubscribeAll();
    }

    onCloseModal() {
        this.onClose.emit();
    }

    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }

    onProgress(progressData: ProgressData) {
        this.isExternalLoading = true;
        if (progressData.loaded === progressData.total) {
            this.isExternalLoading = false;
        }
    }



    onPrintPdf() {
        let printIframe;
        let blobUrls = [];
        let data = this.reportDataWithoutAuth;

        async function printMethod() {
            const resp = await fetch(data);
            const blob = await resp.blob();
            const url = URL.createObjectURL(blob);
            blobUrls.push(url);
            if (!printIframe) {
                printIframe = document.createElement('iframe');
                printIframe.setAttribute("id", "myDOC");
                document.body.appendChild(printIframe);

                printIframe.style.position = 'absolute';
                printIframe.style.top = '0';
                printIframe.style.left = '-1000px';

                printIframe.onload = () => {
                    setTimeout(() => {
                        if (printIframe) {
                            printIframe.focus();
                            printIframe.contentWindow.print();
                        }
                    }, 100)
                }
            }
            printIframe.src = url;
        }

        printMethod();
    }

    fetchLabResult() {
            const request = {
                id: this.encryptedNewLabBookingDetailId
        }
        this.httpService.get(ApiResources.getURI(ApiResources.timeline.base, ApiResources.timeline.fetchlabResultinformation), request)
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => { }))
                .subscribe((response: Array<any>) => {
                    if (response) {
                        this.resultdata = response
                     
                    }


                });
        
    }


    private fetchPatient(id: string) {
        const request = {
            encryptedPatientId: id
        }
        this.httpService
            .post<Patient>(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.find), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { }))
            .subscribe(
                (response: Patient) => {
                    this.patient = response;
                    if (this.patient.relation == "Husband") {
                        this.relationType = "W/O";
                    } else if (this.patient.relation == "Wife") {
                        this.relationType = "H/O";
                    } else if (this.patient.relation == "Father" && this.patient.gender == "M") {
                        this.relationType = "S/O";
                    } else if (this.patient.relation == "Father" && this.patient.gender == "F") {
                        this.relationType = "D/O";
                    } else if (this.patient.relation == "Sister" && this.patient.gender == "F") {
                        this.relationType = "Sis /O";
                    } else if (this.patient.relation == "Sister" && this.patient.gender == "M") {
                        this.relationType = "B /O";
                    } else if (this.patient.relation == "Brother" && this.patient.gender == "F") {
                        this.relationType = "Sis /O";
                    } else if (this.patient.relation == "Brother" && this.patient.gender == "M") {
                        this.relationType = "B /O";
                    } else if (this.patient.relation == "Son" && this.patient.gender == "M") {
                        this.relationType = "F /O";
                    } else if (this.patient.relation == "Son" && this.patient.gender == "F") {
                        this.relationType = "M /O";
                    } else if (this.patient.relation == "Daughter" && this.patient.gender == "M") {
                        this.relationType = "F /O";
                    } else if (this.patient.relation == "Daughter" && this.patient.gender == "F") {
                        this.relationType = "M /O";
                    }
                });

    }
    private prepareDocument(document: any) {
        this.logoDocument = document;
        this.logoDocument.headerFormUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${document.headerFormUrl}`);
        this.logoDocument.bottomFormUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${document.bottomFormUrl}`);
    }

    private fetchCommonSettings() {
        this.loading = true;
        const request = {
        }
        request["name"] = "Purpose Of Print Terms And Conditions";
        request["type"] = "Lab";

        this.httpService.get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<Setting>) => {
                this.isPrintTermsAndConditions = response[0]?.active ? response[0].active : false;
            });
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }

}