export class ECSData {
    eCSDataId: number;
    eCSDate: Date;
    locationId: number;
    insuranceCompanyId: number;
    receivedAmount: number;
    taxAmount: number;
    netAmount: number;
    active: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
    createdByName: string;
    modifiedByName: string;
    locationName: string;
    utrNo: string;
    insuranceCompany: string;
    createdByRole: string;
    modifiedByRole: string;
    totalItems: number;
    tpaName: string;
    usedAmount: number;
}