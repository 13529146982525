
<div id="gynprintContainer" class="mt-1 row scroll-obsecNotHeading text-black">
    <div class="col-12">
        <div class="mt-1">
            <div class="col-12 p-0">
                <div class="align-items-start border border-bottom-0 d-flex pl-1 pr-1 pt-1">
                    <div class="pl-0 col-11 d-flex">
                        <div class="right5">
                            <button class="btn btn-info" type="button" (click)="onChangePrintType(false)">
                                <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                            </button>
                            <button class="btn btn-info" type="button" (click)="onChangePrintType(true)">
                                <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                            </button>
                            <button class="btn btn-info" type="button" (click)="onChangeFooter(false)">
                                <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
                            </button>
                            <button class="btn btn-info" type="button" (click)="onChangeFooter(true)">
                                <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
                            </button>
                        </div>
                    </div>
                    <div class="col-1">
                        <button [useExistingCss]="true" id="printButton" printSectionId="gynprint" ngxPrint class="close d-print-none"> <i class="fe-printer" placement="left" ngbTooltip="Print"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div id="gynprint" class="border-left border-right border-bottom">
            <div class="report bg-white">
                <div class="mb-0 pt-1 pl-4 pr-4">
                    <div class="clearfix pb-3">
                        <div>
                            <div class="row" *ngIf="page.loading">
                                <div class="col-12">
                                    <div class="d-flex align-items-center">
                                        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                                        <span class="ml-2">Please wait while generating IEncounter Prescription ...</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!page.loading && fullTranscript">
                                <div class="col-12 p-0" *ngIf="isPrintLogo">
                                    <banner-setting [reportName]='"Gny Discharge Summary Report Header"'></banner-setting>
                                </div>
                                <div *ngIf="!isPrintLogo" class="col-12 p-0 mtp100">
                                </div>
                                <ng-container>
                                    <div class="overflow-auto">
                                        <div class="border-top2px d-flex justify-content-between pb-1 pt-1 border-bottom-dotted">
                                            <h4 class="pl-0">Discharge Summary</h4>
                                            <h4 class="white-space-nowrap">
                                                Department of {{title}}
                                            </h4>
                                        </div>
                                        <div class="col-12 p-0 pb-1 pt-1 white-space-nowrap">
                                            <table class="w-100 white-space-nowrap">
                                                <tr class="col-12">
                                                    <td class="w-130 border-bottom-dotted">
                                                        <span>Patient Name</span>
                                                    </td>
                                                    <td class="border-bottom-dotted">
                                                        <h5>:</h5>
                                                    </td>
                                                    <td class="border-bottom-dotted text-uppercase">
                                                        <h5 class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="fullTranscript.patientName" [textContent]="fullTranscript.patientName"></h5>
                                                    </td>
                                                    <td class="w-130 border-bottom-dotted">
                                                        <span> W/o</span>
                                                    </td>
                                                    <td class="border-bottom-dotted">
                                                        <h5>:</h5>
                                                    </td>
                                                    <td class="w-130 text-uppercase border-bottom-dotted">
                                                        <h5 class="pl-1 " *ngIf="husbandName" [textContent]="husbandName"></h5>
                                                    </td>
                                                </tr>
                                                <tr class="col-12">
                                                    <td>
                                                        <span>Age</span>
                                                    </td>
                                                    <td>
                                                        <h5>:</h5>
                                                    </td>

                                                    <td>
                                                        <h5 class="pl-1" *ngIf="appointment.patientAge">{{appointment.patientAge}} &nbsp;years &nbsp;&nbsp;</h5>
                                                    </td>
                                                    <td>
                                                        <span>Admission Date/Time</span>
                                                    </td>
                                                    <td>
                                                        <h5>:</h5>
                                                    </td>
                                                    <td><h5 class=" pl-1 text-uppercase" [textContent]="fullTranscript.admissionDate | date: 'dd/MM/yyyy hh:mm a'"></h5> </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span>UMR.No</span>
                                                    </td>
                                                    <td>
                                                        <h5>:</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="pl-1 text-uppercase " [textContent]="appointment.umrNo"></h5>
                                                    </td>
                                                    <td>
                                                        <span>Discharge Date & Time</span>
                                                    </td>
                                                    <td>
                                                        <h5>:</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="pl-1 text-uppercase">{{dischargeSummary.dischargeDate| date: 'dd/MM/yyyy'}},{{dischargeSummary.dischargeTime |date:'hh:mm a'}}</h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span>IP.No</span>
                                                    </td>
                                                    <td>
                                                        <h5>:</h5>
                                                    </td>
                                                    <td *ngIf="appointment.appointmentNo">
                                                        <h5 class="pl-1 text-uppercase" [textContent]="appointment.appointmentNo"></h5>
                                                    </td>
                                                    <td>
                                                        <span>Procedure Date/Time</span>
                                                    </td>
                                                    <td>
                                                        <h5>:</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="pl-1 text-uppercase" *ngIf="opProcedureForm" [textContent]="opProcedureForm.date | date: 'dd/MM/yyyy'"></h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span>Consultant Doctor</span>
                                                    </td>
                                                    <td>
                                                        <h5>:</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="pl-1 text-uppercase" [textContent]="appointment.providerName"></h5>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="mt-2" *ngIf="dischargeSummary">
                                    <div>
                                        <h5 class="text-uppercase">
                                            Complaint And History
                                        </h5>
                                        <div>
                                            <span [innerHTML]="dischargeSummary.complaintAndHistory"></span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="obHistory" class="mt-2">
                                    <div class="dashboard-body">
                                        <div *ngIf="obHistory.length >0">
                                            <div><h5 class="font-weight-bold text-black">OBSTETRIC HISTORY</h5></div>
                                            <div class="overflow-auto mt-1 mb-1">
                                                <table class="w-75 border-collapse">
                                                    <thead>
                                                        <tr>
                                                            <th class="w-10px"><h5>Preg No</h5></th>
                                                            <th><h5>Year</h5></th>
                                                            <th><h5>Birth Details</h5></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of obHistory;">
                                                            <td class="min-width80px">
                                                                <div>
                                                                    <div class="text-wrap text-uppercase" [textContent]="item.pregnancyNo"></div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <span class="text-capitalize" [textContent]="item.yearOfBirth"></span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <span>FT {{item.modeOfDelivery}} at {{item.deliveryAt}}hospital {{item.gaWeeks}}weeks of gestation.{{item.sexOfBaby}},{{item.statusOfBaby}}& healty,birth Weight:{{item.birthWeight}}Kg at {{item.deliveryAt}} hospital</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="problemList" class="mt-2">
                                    <div><h5 class="text-uppercase">Medical History</h5> </div>
                                    <div *ngIf="isPastMedicalHistory">
                                        None
                                    </div>
                                    <table style="width:100%">
                                        <tbody>
                                            <tr *ngFor="let item of problemList; let i = index">
                                                <td class="vertical-align-top w-50px">
                                                    <span [textContent]="i +1"></span>
                                                </td>
                                                <td>
                                                    <div class="d-flex">
                                                        Disease:<span class="pl-1" [textContent]="item.usable"></span>
                                                    </div>
                                                    <div class="d-flex">
                                                        ICD Code:<span class="pl-1" [textContent]="item.code"></span>
                                                    </div>
                                                    <div class="d-flex" *ngIf="item.date">
                                                        Year of diagnosis &nbsp;:<span class="pl-1" *ngIf="item.date" [textContent]="item.date"></span>
                                                    </div>
                                                    <div class="d-flex" *ngIf="item.comments">
                                                        Treatment History:<span class="pl-1" [textContent]="item.comments"></span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div *ngIf="procedureSurgery" class="mt-2">
                                    <div><h5 class="text-uppercase">Surgical History</h5> </div>
                                    <div *ngIf="isProcedureSurgery">
                                        There is no Surgical History
                                    </div>
                                    <table style="width:100%" *ngIf="procedureSurgery.length>0">
                                        <tbody>
                                            <tr *ngFor="let item of procedureSurgery; let i = index">
                                                <td>
                                                    <div class="d-flex">
                                                        Year Of Surgery:<span class="pl-1" [textContent]="item.date"></span>
                                                    </div>
                                                    <div class="d-flex">
                                                        Surgeries:<span class="pl-1" [textContent]="item.surgeries"></span>
                                                    </div>
                                                    <div class="d-flex" *ngIf="item.date">
                                                        Comments &nbsp;:<span class="pl-1" *ngIf="item.date" [textContent]="item.comments"></span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-12 p-0 mt-2" *ngIf="allergies && allergies !== null">
                                    <div><h5 class="text-uppercase">Allergy History</h5> </div>
                                    <div *ngIf="allergies">
                                        <div class="d-flex" *ngIf="allergies && allergies.noHistory && allergies.noHistory!=null">
                                            <div>
                                                <div>
                                                    Allergies  <span class="pl-1" *ngIf="allergies && allergies.noHistory && allergies.noHistory!=null "> &nbsp; : &nbsp; There is no allergy</span>
                                                </div>
                                            </div>

                                        </div>
                                        <table class="w-100 vertical-align-top" *ngIf="allergies && !allergies.noHistory">
                                            <tr class="col-12">
                                                <td class="w-150px white-space-nowrap">
                                                    <span> Drug Allergies</span>
                                                </td>
                                                <td>
                                                    <h5 class="">:</h5>
                                                </td>
                                                <td>
                                                    <span class="d-block pl-1" [textContent]="allergies.drugNames"></span>
                                                </td>
                                                <td class="w-150px white-space-nowrap">
                                                    <span *ngIf="allergies.drugReactions"> Adverse Drug Reaction</span>
                                                </td>
                                                <td>
                                                    <span *ngIf="allergies.drugReactions" class="">:</span>
                                                </td>
                                                <td>
                                                    <span class="pl-1" [textContent]="allergies.drugReactions"></span>
                                                </td>
                                            </tr>
                                            <tr class="col-12">
                                                <td class="w-150px white-space-nowrap">
                                                    <span> Food Allergy</span>
                                                </td>
                                                <td>
                                                    <h5 class="">:</h5>
                                                </td>
                                                <td>
                                                    <span class="d-block pl-1" [textContent]="allergies.foodNames"></span>
                                                </td>
                                                <td class="w-150px white-space-nowrap">
                                                    <span *ngIf="allergies.foodReactions"> Adverse Drug Reaction</span>
                                                </td>
                                                <td>
                                                    <span *ngIf="allergies.foodReactions" class="">:</span>
                                                </td>
                                                <td>
                                                    <span class="pl-1" [textContent]="allergies.foodReactions"></span>
                                                </td>
                                            </tr>
                                            <tr class="col-12">
                                                <td class="w-150px white-space-nowrap">
                                                    <span> Dust Allergy</span>
                                                </td>
                                                <td>
                                                    <h5 class="">:</h5>
                                                </td>
                                                <td>
                                                    <span class="d-block pl-1" [textContent]="allergies.dustNames"></span>
                                                </td>
                                                <td class="w-150px white-space-nowrap">
                                                    <span *ngIf="allergies.dustReactions"> Adverse Drug Reaction</span>
                                                </td>
                                                <td>
                                                    <span *ngIf="allergies.dustReactions" class="">:</span>
                                                </td>
                                                <td>
                                                    <span class="pl-1" [textContent]="allergies.dustReactions"></span>
                                                </td>
                                            </tr>
                                            <tr class="col-12">
                                                <td class="w-150px white-space-nowrap">
                                                    <span> Others</span>
                                                </td>
                                                <td>
                                                    <h5 class="">:</h5>
                                                </td>
                                                <td>
                                                    <span class="d-block pl-1" [textContent]="allergies.otherNames"></span>
                                                </td>
                                                <td class="w-150px white-space-nowrap">
                                                    <span *ngIf="allergies.otherReactions"> Adverse Drug Reaction</span>
                                                </td>
                                                <td>
                                                    <span *ngIf="allergies.otherReactions" class="">:</span>
                                                </td>
                                                <td>
                                                    <span class="pl-1" [textContent]="allergies.otherReactions"></span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div *ngIf="familyHistory" class="mt-2">
                                    <div><h5 class="text-uppercase">Family History</h5> </div>
                                    <div *ngIf="isFamilyHistory">
                                        There is no Family History
                                    </div>
                                    <table style="width:100%" *ngIf="familyHistory.length >0">
                                        <tbody>
                                            <tr *ngFor="let item of familyHistory; let i = index">
                                                <td>
                                                    <div class="d-flex">
                                                        Relation:<span class="pl-1" [textContent]="item.relation"></span>
                                                    </div>
                                                    <div class="d-flex">
                                                        Problem:<span class="pl-1" [textContent]="item.problem"></span>
                                                    </div>
                                                    <div class="d-flex" *ngIf="item.comments">
                                                        Comments &nbsp;:<span class="pl-1" *ngIf="item.comments" [textContent]="item.comments"></span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="mt-2" *ngIf="dischargeSummary">
                                    <div>
                                        <h5 class="text-uppercase">
                                            Chief Complaints
                                        </h5>
                                        <div>
                                            <span [innerHTML]="dischargeSummary.chiefComplaints"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-2">
                                    <div>
                                        <h5 class="text-uppercase">Admission Details :</h5>
                                    </div>
                                    <div>
                                        <span [innerHTML]="dischargeSummary.admissionDetails">
                                        </span>
                                    </div>
                                    <table>
                                        <tr>
                                            <td><h5 class="m-0">Indication</h5></td>
                                            <td>
                                                <h5 class="m-0">:</h5>
                                            </td>
                                            <td *ngIf="opProcedureForm && opProcedureForm.indication">  <span class="pl-1">{{opProcedureForm.indication}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5 class="m-0">Anaesthesia</h5></td>
                                            <td>
                                                <h5 class="m-0">:</h5>
                                            </td>
                                            <td *ngIf="opProcedureForm && opProcedureForm.analgesia">  <span class="pl-1">{{opProcedureForm.analgesia}}</span></td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="mt-2" *ngIf="dischargeSummary">
                                    <h5 class="text-uppercase">Operative Findings:</h5>
                                    <div [innerHTML]="dischargeSummary.operativeFindings"></div>
                                </div>
                                <div class="mt-2" *ngIf="dischargeSummary">
                                    <h5 class="text-uppercase">Course  In  The  Hospital</h5>
                                    <div [innerHTML]="dischargeSummary.courseInTheHospital"></div>
                                </div>

                                <div class="mt-2">
                                    <div>
                                        <h5 class="text-uppercase">Condition At Discharge</h5>
                                    </div>
                                    <div *ngIf="dischargeSummary">
                                        <span [innerHTML]="dischargeSummary.conditionAtDischarge"></span>
                                    </div>
                                </div>
                                <div class="mt-2" *ngIf="dischargeSummary">
                                    <div>
                                        <h5 class="text-uppercase">
                                            Follow Up / Review Date
                                        </h5>
                                        <div>
                                            <span [innerHTML]="dischargeSummary.reviewDates"></span>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-2">
                                    <div *ngIf="problemList">
                                        <div><h5 class="text-uppercase">Final Diagnosis</h5> </div>
                                        <div *ngIf="isPastMedicalHistory">
                                            None
                                        </div>
                                        <table class="tableBorder tableWidth " *ngIf="problemList && problemList.length>0">
                                            <thead>
                                                <tr class="tableBorder">
                                                    <th class="tableBorder"><h5>ICD Code</h5></th>
                                                    <th class="tableBorder"><h5> DESCRIPTION</h5></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of problemList">
                                                    <td class="tableBorder" [textContent]="item.code"></td>
                                                    <td class="tableBorder" [textContent]="item.usable"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div *ngIf="records.length > 0">
                                    <div class="dashboard-body">
                                        <div *ngFor="let record of records;index as j;">
                                            <div class="overflow-auto mt-1 mb-1">
                                                <table class="w-100 border-collapse">
                                                    <thead>
                                                        <tr>
                                                            <th><h5>Medications</h5> </th>
                                                            <th><h5>Generic Name</h5></th>
                                                            <th><h5>Frequency</h5></th>
                                                            <th><h5>Days</h5></th>
                                                            <th><h5>Remarks</h5></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                        <tr *ngFor="let item of record.medicines; let i = index">
                                                            <td class="min-width200px">
                                                                <div>
                                                                    <div class=" text-wrap text-uppercase" [textContent]="item.productName"></div>
                                                                </div>
                                                            </td>
                                                            <td class="min-width200px">
                                                                <div>
                                                                    <span class=" text-wrap text-uppercase" *ngIf="item.genericName" [textContent]="'( ' + item.genericName + ' )'"></span>
                                                                    <span class=" text-wrap text-uppercase" *ngIf="!item.genericName" [textContent]="'--'"></span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <span class="text-capitalize" *ngIf="item.instruction" [textContent]="item.instruction"></span>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <span class="text-capitalize" *ngIf="item.instruction" [textContent]="item.duration"></span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <span class="text-capitalize" *ngIf="item.instruction" [textContent]="item.remark"></span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-2">
                                    <div *ngIf="recordsResults && recordsResults.length >0">
                                        <div><h5 class="text-uppercase">Investigation Done</h5> </div>
                                        <table class="tableBorder tableWidth">
                                            <thead>
                                                <tr class="tableBorder">
                                                    <th class="tableBorder"><h5>Date</h5></th>
                                                    <th class="tableBorder"><h5>Investigation</h5> </th>
                                                    <th class="tableBorder"><h5>Result</h5></th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of recordsResults;let i =index;">
                                                    <td class="tableBorder" [textContent]="item.createdDate |date:'dd/MM/yyyy'"></td>
                                                    <td class="tableBorder" [textContent]="item.testName"></td>
                                                    <td class="tableBorder">{{item.result}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="mt-2">
                                    <div class="d-flex justify-content-between mt-3">
                                        <table>
                                            <tr>
                                                <td>
                                                    <h5 class="m-0 text-uppercase">In Case Of Emergency Contact</h5>
                                                </td>
                                                <td>
                                                    <h5 class="m-0">:</h5>
                                                </td>
                                                <td>
                                                    <h5>040-40632444 / 8008403149</h5>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div class=" mt-1 align-items-end col-12 d-flex flex-wrap justify-content-between p-0  column-gap15px">
                                    <div class="d-flex   flex-grow-1" *ngIf="appointment.providerName">
                                        <div><span>Doctor Name :&nbsp;</span></div>
                                        <div class="text-truncate"><h5 class="text-uppercase" [textContent]="appointment.providerName"></h5></div>
                                    </div>
                                    <div class="d-flex align-items-end">
                                        <div><span>Signature :&nbsp;</span></div>
                                        <div><h5 class="border-bottom border-dark"><img *ngIf="appointment && appointment.signature!=null" [src]="safe(appointment.signature)" cssClass="img-fluid rounded-circle" style="height:80px" /></h5></div>
                                    </div>
                                </div>
                                <div class="mt-2 border-top-dotted border-dark">
                                    <h5>The discharge advice and instructions have been explained to me in a language which I understand.</h5>
                                    <br />
                                    <h5 *ngIf="regionalLanguage==='TELUGU'">డిశ్చార్జ్ సలహామరియు సూచనలను నాకు అర్ధమయ్యే భాషలో తెలియచేసినారు.</h5>
                                    <h5 *ngIf="regionalLanguage==='TAMIL'">வெளியேற்றம் குறித்த ஆலோசனைகள் மற்றும் குறிப்புகளை எனக்கு புரிந்துகொள்ளும் மொழியில் தெரிவிக்கவும்.</h5>
                                </div>
                                <div class=" mt-1 align-items-end col-12 d-flex flex-wrap justify-content-between p-0  column-gap15px">
                                    <div class="d-flex   flex-grow-1">
                                        <div><span>Name of the patient / Attendant :&nbsp;</span></div>
                                        <div class="text-truncate" *ngIf="admissionDetails"><h5 class="text-uppercase" [textContent]="admissionDetails.name"></h5></div>
                                    </div>
                                    <div class="d-flex flex-wrap column-gap15px  flex-grow-1">
                                        <div class="d-flex align-items-end">
                                            <div><span>Signature :&nbsp;</span></div>
                                            <div><h5 class="border-bottom border-dark"></h5></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between border-dark border-top mt-2 font-weight-semibold">
                                    <h5><span>Saved User:</span> <span class="text-uppercase" [textContent]="appointment.providerName"></span></h5>
                                    <h5><span>Print Date/Time :</span> <span [textContent]="date | date :'d/MM/yyyy'"></span><span [textContent]="date | date :' h:mm a'"></span></h5>
                                    <h5><span>Printed by :</span> <span class="text-uppercase" [textContent]="printName"></span></h5>
                                </div>
                                <div class="border-dark border-top mt-2">
                                    <banner-setting [reportName]='"Gny Discharge Summary Report Footer"'></banner-setting>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
















