export enum InternalMedicineType {
 
    ProblemList = 1,
    CheifComplaints,
    FollowUp,
    Medications,
    Notes,
    Vitals,
    SocialHistory,
    FamilyMedicalHistory,
    Diagnosis,
    GeneralAdvice,
    Heent,
    Skin,    
    Abdomen,    
    Musculoskeletal,    
    Neurology,
    Extremities,
    Respiratory,
    Ekg,
    CardioVascular,
    Allergies,
    Hospitalization,
    PreventiveCare,
    Immunizations,
    MedicationProblems,
    CurrentMedications,
    ReviewOfSystems,
    MedicationComments,
    ScanAppointmentDetails,
    OtAppointmentDetails,
    ordersPrescription,
    dietGuidlines,
   
}