import { Component, OnDestroy, OnInit, ViewEncapsulation, Input, AfterViewInit, HostListener } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ApiResources } from "@shared/helpers";
import { Appointment, PatientDocument, PreviousAppointment, ObEncounterFullTranscript, PatientMedicationHeader, ImageReports } from "@shared/entities";
import { Page, Pagination, IResource, IUserAccount, PatientProfile } from "@shared/models";
import { AppConfig, AppData, HttpService, AppointmentToggleService, TimelineToggleService, IconService, PrintOptionService } from "@shared/services";
import { finalize, takeUntil } from "rxjs/operators";

import { InternalMedicine } from "@admin/internal-medicine/internal-medicine.namespace";
import { OrderPrescription } from "@shared/entities";
import { OB } from "@admin/full-transcript/pages/ob-report/ob.namespace";
import { ObEncounter } from "@shared/models/ob-encounter.model";
import { LabBillHeaderModel } from "@admin/labs/pages/models";



class OrderPrescriptionModel {
    appoitmentId: number;
    orderPrescription: string;

}

@Component({
    templateUrl: "./obadmissiondetail-report.html",
    encapsulation: ViewEncapsulation.None,
    selector: "admissiondetail-report"
})
export class OBAdmissionDetailReportPrintPage implements OnInit, OnDestroy, AfterViewInit {

    @Input() printId: string;
    @Input() printadmission: boolean;
    routingValue: string;
    page: Page;
    modalRef: NgbModalRef;
    modalViewRef: NgbModalRef;
    loadingCategories: boolean;
    categories: Array<IResource>;
    section: string;
    pagination: Pagination;
    loading: boolean;
    documents: Array<PatientDocument>;
    patientDocumentId: number;
    document: PatientDocument;
    appointmentId: string;
    isAdmission: boolean;
    providerId: number;
    patientId: number;
    appointments: Array<Appointment>;
    oldAppointment: PreviousAppointment;
    fullTranscript: ObEncounter;
    loadingDocument: boolean;
    documentError: boolean;
    showPrevious: boolean;
    showNext: boolean;
    result: number;
    patient: PatientProfile;
    appointment: ObEncounterFullTranscript;
    obEncounter: ObEncounter;
    submitting: boolean;
    locationId: number;
    locations: Array<IResource>;
    currentLocation: string;


    records: Array<PatientMedicationHeader>;
    iconBasics: ImageReports;
    flag = true;
    @Input() isPrintLogo: boolean;
    labs: Array<PatientMedicationHeader>;
    bookingHeader: Array<LabBillHeaderModel>;
    husbandName: string;
    referralOrder: Array<OB.OrderReferral>;
    antinantalRisk: OB.AntinantalRisk;
    ordersPrescription: OB.OrdersPrescription;
    measure: OB.Measure;
    postNatalVisit: OB.PostnantalVisit;
    ancCard: OB.AncCards;
    ancCardGeneration: OB.AncCardGeneration;
    allergies: OB.Allergies;
    medicationComment: InternalMedicine.MedicationComment;
    scanAppointmentDetails: OB.ScanDetails;

    orders: Array<OrderPrescription>;
    isTeleconsulatantReport: boolean;
    date: Date;
    encryptedPatientId: string;
    admissionDetail: OB.AdmissionDetails;
    obHistory: Array<OB.ObBirthHistory>;
    isPastMedicalHistory = false;
    problemList: Array<InternalMedicine.Problem>;
    isProcedureSurgery = false;
    procedureSurgery: Array<OB.ProcedureSurgery>;
    isFamilyHistory = false;
    familyHistory: Array<OB.ObFamilyHistory>;
    specialFeature: OB.SpecialFeature;
    signature: string;
    name: any;
    diseaseName: any
    yearOfDiagnosis: any
    icd: any
    status: any
    treatmentHistory: any;
    bp: string;
    isFooter: boolean;
    constructor(
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly httpService: HttpService,
        private readonly toggleService: AppointmentToggleService,
        public timelineService: TimelineToggleService,
        private readonly printOptionService: PrintOptionService,
        private readonly iconService: IconService,


    ) {
        this.page = new Page();
        this.pagination = new Pagination();
        this.appointments = [];
        this.records = new Array<PatientMedicationHeader>();
        this.labs = new Array<PatientMedicationHeader>();
        this.bookingHeader = new Array<LabBillHeaderModel>();
        this.date = new Date();
    }
    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }






    private findDashboard() {
        const request = { encryptedAppointmentId: this.appointmentId, encryptedProviderId: this.page.userAccount.encryptedReferenceId, encryptedPatientId: this.encryptedPatientId };
        request["isAdmission"] = this.isAdmission;

        this.httpService.post<ObEncounter>(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.findEncounterData), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: ObEncounter) => {
                this.fullTranscript = response["dashboard"];
                if (this.fullTranscript && this.fullTranscript.obEncounterId) {
                    this.ancCard = this.fullTranscript.ancCard ? JSON.parse(this.fullTranscript.ancCard) : null;
                    this.admissionDetail = this.fullTranscript.admissionDetails ? JSON.parse(this.fullTranscript.admissionDetails) : null;
                    if (this.admissionDetail.systolicbp && this.admissionDetail.diastolicbp != null) {
                        this.bp = (this.admissionDetail.systolicbp + "/" + this.admissionDetail.diastolicbp)
                    }
                    this.obHistory = this.fullTranscript.birthHistory ? JSON.parse(this.fullTranscript.birthHistory) : null;
                    this.problemList = this.fullTranscript.problemList ? JSON.parse(this.fullTranscript.problemList) : null
                    if (this.fullTranscript.problemList && this.problemList["problems"].length == 0) {
                        this.isPastMedicalHistory = true;
                    }
                    if (this.problemList) {
                        this.problemList = this.problemList["problems"]

                    }
                    this.procedureSurgery = this.fullTranscript.surgeries ? JSON.parse(this.fullTranscript.surgeries) : null;
                    if (this.fullTranscript.surgeries && this.procedureSurgery.length == 0) {
                        this.isProcedureSurgery = true;
                    }
                    this.familyHistory = this.fullTranscript.familyHistory ? JSON.parse(this.fullTranscript.familyHistory) : null;
                    if (this.fullTranscript.familyHistory && this.familyHistory.length == 0) {
                        this.isFamilyHistory = true;
                    }
                    this.specialFeature = this.fullTranscript.specialFeature ? JSON.parse(this.fullTranscript.specialFeature) : null;
                    this.allergies = this.fullTranscript.allergies ? JSON.parse(this.fullTranscript.allergies) : null;
                    this.allergies = this.fullTranscript.allergies ? JSON.parse(this.fullTranscript.allergies) : null;
                    this.ordersPrescription = this.fullTranscript.orderPrescription ? JSON.parse(this.fullTranscript.orderPrescription) : null;
                    this.measure = this.fullTranscript.measure ? JSON.parse(this.fullTranscript.measure) : null;
                }

            });

    }

    private findOrderPrescription() {
        let PatientId: number = this.patientId;
        this.httpService.get(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchOrderPrescription), { patientId: PatientId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Array<OrderPrescriptionModel>) => {
                if (response.length > 0) {
                    response.forEach((item: OrderPrescriptionModel) => {
                        var jsonParsed = JSON.parse(item.orderPrescription);
                        this.orders.push(jsonParsed)
                    })
                }
            });
    }
    private findPatientDetails(id: string) {
        this.page.loading = true;
        this.httpService.post<PatientProfile>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.profile), { encryptedPatientId: id })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: PatientProfile) => {
                this.patient = response;
                var detaills = this.patient.relativeDetails[0];
                if (detaills && detaills.relation == "Husband") {
                    this.husbandName = this.patient.relativeDetails[0].fullName
                }


            });
    }
    private findAncCard(id: number) {
        const request = {
            patientId: id,
            appointmentId: this.appointment.appointmentId
        }
        this.httpService.get(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchANC), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: OB.AncCardGeneration) => {
                if (response) {
                    this.ancCardGeneration = response;
                    this.husbandName = this.ancCardGeneration.husbandName;
                }
            });

    }

    private fetchMedication() {
        this.loading = true;
        const request = {
            encryptedAppointmentId: this.appointmentId,
            isAdmission: this.isAdmission
        }

        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                this.records = response;
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });
    }

    gotoPrescription(appointmentId: string) {
        this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/prescription/${appointmentId}`, "_blank"); });
    }

    private findAppointment(appointmentId: string) {
        const request = {
            encryptedAppointmentId: appointmentId
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<ObEncounterFullTranscript>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: ObEncounterFullTranscript) => {
                this.appointment = response;
                this.encryptedPatientId = response.encryptedPatientId;
                this.findAncCard(this.appointment.patientId);
                this.fetchMedication();
                this.findDashboard();
                this.fetchLabs();
                this.findPatientDetails(response.encryptedPatientId);
                this.findOrderPrescription();
            });

    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    this.currentLocation = this.page.userAccount.locationName;
                    this.isAdmission = url.split("/")[4] === "a";
                    this.appointmentId = decodeURIComponent(url.split("/")[3]);
                    this.isTeleconsulatantReport = url.split("/")[6] === "T";
                    this.locationId = this.page.userAccount.locationId;
                    this.routingValue = url.split("/")[2];
                    this.appointmentId = this.printId;
                    this.isAdmission = this.printadmission;
                    this.findAppointment(this.appointmentId);
                    this.name = this.page.userAccount.fullName;
                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((params: Params) => {
                            const section = params["section"];
                            this.section = section ? section : undefined;
                        });
                } else {
                    this.page.userAccount = undefined;
                }
                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                this.iconService.getIconImage((is) => { this.iconBasics = is; });

            });
    }
    ngAfterViewInit() {
        this.paddingTop();
    }
    paddingTop() {
        if (document.getElementById("header") != null && document.getElementById("obprintContainer") != null) {
            var paddingTopValue = document.getElementById("header").offsetHeight;
            var content = document.getElementById("obprintContainer");
            content.setAttribute('style', `padding-top:${paddingTopValue}px`);
        }
    }
    private fetchLabs() {
        this.loading = true;
        const request = {
            appointmentId: this.appointment.appointmentId,
            isAdmission: this.isAdmission
        }
        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetchLabs), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                if (response && response.length > 0) {
                    this.bookingHeader = response[0].labs;
                }
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });
    }
    @HostListener('window:load')
    onLoad() {
        setTimeout(() => { this.paddingTop(); }, 1500)
    }
    @HostListener('window:hashchange')
    onhashchange() {
        setTimeout(() => { this.paddingTop(); }, 500)
    }
    @HostListener('window:resize')
    onResize() {
        this.paddingTop();
    }



    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }

    appointmentToggle() {
        this.toggleService.toggle(true);
    }

    timelineToggle() {
        this.timelineService.toggle();
    }
    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }

}