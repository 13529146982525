import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'time'
})
export class TimespanToTimePipe implements PipeTransform {

    transform(value: string): string {
        if (!value) return '';
        const [hours, minutes] = value.split(':').map(Number);
        const period = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = (hours % 12) || 12;  
        const formattedMinutes = minutes.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes} ${period}`;
    }
}
