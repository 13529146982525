/* eslint-disable @typescript-eslint/no-inferrable-types */
import { OnInit, Component, Output, EventEmitter, Input } from "@angular/core";
import { ApiResources } from "../../../../../shared/helpers";
import { takeUntil, finalize } from "rxjs/operators";
import { HttpService, NotifyService } from "../../../../../shared/services";
import { Page } from "../../../../../shared/models";
@Component({
    selector: "lookup-design",
    templateUrl: "./lookupdesign.html",
    styleUrls: ['./lookupdesign.css']

})

export class LookupDesignPage implements OnInit {
    @Output() sendlookupselctedValue = new EventEmitter<{ response: any, lookupId: number }>();
    @Output() sendSelectedLookupName = new EventEmitter<any>();
    @Input() dataFormcreation: any;
    @Input() checkFormcreation: boolean;
    lookupData: any[] = [];
    selectedLookup: any[] = [];
    selectedRadioValue: any;
    lookupID: number;
    lookupName: string;
    isRadioSelected: boolean = false;
    filteredData: any[] = [];
    dataList: any[] = [];
    lookupValueList: any[] = [];
    filterLookupValues: any[] = [];
    searchQuery: string = '';
    searchLookupValue: string = '';
    filterLookupData: any[] = [];
    searchTerm: string = '';
    page: Page;
    newFieldAdded = false;
    newLookupValue = false;
    showDeleteButton = true;
    clearLookup: boolean = false;
    showlookupValue: boolean = false;
    constructor(private httpService: HttpService, private readonly notifyService: NotifyService) { this.page = new Page(); }

    ngOnInit() {
        this.fetchLookup();
        this.loadLookupValues();
    }

    fetchLookup() {
        this.httpService.get(ApiResources.getURI(ApiResources.lookups.base, ApiResources.lookups.fetchlookup), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: any) => {
                this.lookupData = response;
                this.dataList = response;
                this.filteredData = this.dataList;

                if (this.dataFormcreation && this.dataFormcreation?.lookupId) {
                    this.loadLookupValue(this.dataFormcreation)
                }

            })

    }

    isRadioChecked(item: any): boolean {
        if (!this.dataFormcreation) {
            false;
        } else {
            return !!(this.dataFormcreation && this.dataFormcreation.lookupId === item.lookupId);
        }

    }

    loadLookupValues() {
        this.httpService.post(ApiResources.getURI(ApiResources.lookups.base, ApiResources.lookups.fetchlookupvalue), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: any) => {
                // this.selectedLookup = response;
                this.lookupValueList = response;
                this.filterLookupValues = this.lookupValueList;
            })
        this.showDeleteButton = true;
    }



    loadLookupValue(item) {
        this.isRadioSelected = true;
        this.showDeleteButton = true;

        if (typeof item === 'object') {
            var request = { LookupId: item.lookupId };
            this.lookupID = item.lookupId;

            const foundItem = this.lookupData.find(items => items.lookupId === item.lookupId);
            this.lookupName = item.name ? item.name : (foundItem ? foundItem.name : '');
        } else if (typeof item === 'number') {
            request = { LookupId: item };
            this.lookupID = item;
            const foundItem = this.lookupData.find(items => items.lookupId === item);
            this.lookupName = foundItem.name;
        }

        this.httpService.post(ApiResources.getURI(ApiResources.lookups.base, ApiResources.lookups.fetchlookupvalue), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: any) => {
                this.selectedLookup = response;
                const responseLKP = response;
                const lookupId = this.lookupID;
                this.emitLookupData(responseLKP, lookupId)
                const toLtrDsn = {}
                toLtrDsn['lookupId'] = lookupId;
                toLtrDsn['name'] = this.lookupName;
                this.sendSelectedLookupName.emit(toLtrDsn);
            })
        this.showDeleteButton = true;
        this.newLookupValue = false;
    }

    emitLookupData(response: any, lookupId: number): void {
        this.sendlookupselctedValue.emit({ response, lookupId });
    }

    addNewLookUpValue() {
        if (!this.newLookupValue) {
            this.showDeleteButton = true;
            const newRow = { name: '', edit: true };
            this.selectedLookup.unshift(newRow);
            this.newLookupValue = true;
        }
        this.showDeleteButton = false;
    }

    saveNewLookupValue(index: number) {
        const editvalue = this.selectedLookup[index];
        const request = { Name: editvalue.name, LookupId: this.lookupID }
        console.log(request);
        if (editvalue.name.trim() != "") {
            this.httpService.post(ApiResources.getURI(ApiResources.lookups.base, ApiResources.lookups.addlookupvalue), request)
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => this.page.loading = false))
                .subscribe((response: any) => {
                    if (response == "lookupvalue has been added successfully.") {
                        this.notifyService.successToast(response);
                        this.selectedLookup[index].edit = false;
                        if (this.lookupID) {
                            this.loadLookupValue(this.lookupID);
                        }
                        this.newLookupValue = false;
                        this.showDeleteButton = true;
                    }
                    else if (response == "Given lookupvalue already been exists with us.") {
                        this.notifyService.warning(response);
                        this.newLookupValue = false;
                        return;
                    }
                })
        }
        else {
            this.notifyService.warning("Please enter Lookup Value")
        }
    }



    addRow() {
        debugger
        this.dataFormcreation = {};
        if (!this.newFieldAdded) {
            this.dataFormcreation = { lookupId: null };
            const newRow = { name: '', editing: true };
            this.lookupData.unshift(newRow);
            this.newFieldAdded = true;

            this.filterTable('');

        }
    }

    saveNewLookUp(index: number) {
        const editedRow = this.lookupData[index];
        const request = { Name: editedRow.name };
        if (editedRow.name.trim() != "") {
            this.httpService.post(ApiResources.getURI(ApiResources.lookups.base, ApiResources.lookups.addLookup), request)
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => this.page.loading = false))
                .subscribe((response: any) => {
                    if (response == "lookup has been added successfully.") {
                        this.notifyService.success(response);
                        this.lookupData[index].editing = false;
                        this.newFieldAdded = false;
                        this.fetchLookup();
                    }
                    else if (response == "Given lookup already exists with us.") {
                        this.notifyService.warning(response);
                    }
                })
        }
        else {
            this.notifyService.warning("Please enter Lookup Name");
        }

    }

    filterTable(searchQuery: string) {
        if (searchQuery) {
            this.filteredData = this.dataList.filter(item => {
                if (item.name && typeof item.name === 'string') {
                    return item.name.toLowerCase().includes(searchQuery.toLowerCase());
                }
                return false;
            });
            this.dataFormcreation = { lookupId: null };
            this.selectedLookup = [];
            this.lookupName = ''
        }
        else {

            this.dataFormcreation = null;
            this.filteredData = this.dataList;


            this.dataFormcreation = { lookupId: null };
            this.selectedLookup = [];
            this.lookupName = ''
            this.isRadioSelected = false;
            this.sendlookupselctedValue.emit({ response: [], lookupId: null });
        }
    }


    filterLookupValue(searchTerm: string) {
        if (searchTerm == "") {
            this.fetchLookup();
            this.selectedLookup = [];
        }
    }

    search(searchTerm: string) {
        if (searchTerm.trim() == "") {
            this.notifyService.warning("Please enter some value to search")
            return;
        } else {
            const request = { Name: searchTerm };
            this.httpService.post(ApiResources.getURI(ApiResources.lookups.base, ApiResources.lookups.fetchlookups), request)
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => this.page.loading = false))
                .subscribe((response: any) => {
                    this.lookupData = response;
                    this.dataList = response;
                    this.filteredData = this.dataList;
                    this.selectedLookup = [];
                    this.dataFormcreation = { lookupId: null };
                    this.lookupName = "";
                    this.sendlookupselctedValue.emit({ response: [], lookupId: null });
                })
        }
    }


    deleteRow(index: number) {
        const deleteRow = this.selectedLookup[index];
        const request = { LookupValueId: deleteRow.lookupValueId };
        console.log(deleteRow);
        this.notifyService.delete(() => {
            this.selectedLookup.splice(index, 1);
            this.httpService.post(ApiResources.getURI(ApiResources.lookups.base, ApiResources.lookups.deletelookupvalue), request)
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => this.page.loading = false))
                .subscribe((response: any) => {
                    this.loadLookupValue(this.lookupID);
                    this.notifyService.success(response);
                })
        });

    }

    clearSearch() {
        this.searchQuery = '';
        this.clearLookup = true;
        this.filterTable('');
        this.selectedLookup = [];
        this.isRadioSelected = false;
        this.fetchLookup();
        this.newLookupValue = false;
        this.newFieldAdded = false;
        this.searchTerm = '';
    }
    clearSearchValues() {
        this.searchTerm = '';
        this.selectedLookup = [];
        this.lookupName = ''
        this.isRadioSelected = false;
        this.dataFormcreation = { lookupId: null };
        this.fetchLookup();
        this.newLookupValue = false;
        this.newFieldAdded = false;
        this.searchQuery = '';
        this.sendlookupselctedValue.emit({ response: [], lookupId: null });
    }

    removeLookupValue(id: number) {
        const indexToRemove = this.selectedLookup.findIndex(item => item.id === id);
        if (indexToRemove !== -1) {
            this.selectedLookup.splice(indexToRemove, 1);
            this.newLookupValue = false;
            this.showDeleteButton = true;
        }
    }
    removeLookup(id: number) {
        const indexToRemove = this.filteredData.findIndex(item => item.id === id);
        if (indexToRemove !== -1) {
            this.filteredData.splice(indexToRemove, 1);
            this.newFieldAdded = false;
        }
    }

}

