﻿<div *ngIf="currentPageView === pageView.AddView" class="content top1">
    <form [formGroup]="bookAdmissionForm" (submit)="onBookAdmission()">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box">
                        <div class="page-title-right">
                            <div class="page-title-center d-flex align-items-center" *ngIf="flag">
                                <div class="alert alert-info m-0 p-1 mr-2"
                                     *ngIf="patientCounselling.length && !selectedCounsellingDetails">
                                    Counselling
                                    <ng-container *ngFor="let item of patientCounselling; let i = index;">
                                        <span (click)="showCounsellingData(item)"
                                              class="font-weight-bold text-primary mr-1 cursor-pointer">
                                            <span [textContent]="item.counsellingNo"></span>
                                        </span>
                                    </ng-container>
                                    is available.
                                </div>
                            </div>
                            <div class="d-flex justify-content-end align-items-right">
                                <ng-select class="cubible-search" [items]="counsellingCodeWithNames | async" [loading]="loadingCounsellings" bindLabel="name" bindValue="value" [typeahead]="counsellingInput" typeToSearchText="Search patient"
                                           placeholder="Enter Name/Mobile/UmrNo/CounsellingNo of Patient" [minTermLength]="2" [virtualScroll]="true" formControlName="counsellingCode">

                                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                        <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                            No patient found for '{{searchTerm}}'
                                        </div>
                                        <div class="ng-option disabled" *ngIf="(!searchTerm || searchTerm.length < 2)">
                                            Search patient
                                        </div>
                                    </ng-template>


                                    <ng-template ng-option-tmp let-item="item" let-i="index">
                                        <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                        <span class="text-muted d-block font-13" [textContent]="item.optionalText3"></span>
                                        <span class="text-muted d-block font-13" [textContent]="item.optionalText"></span>
                                        <span class="text-muted d-block font-13" [textContent]="item.optionalText4"></span>
                                    </ng-template>
                                    <ng-template ng-label-tmp let-item="item">
                                        <div class="media">
                                            <div class="media-body">
                                                <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-select>
                                <button type="button" (click)="onCloseModal()" class="btn btn-sm btn-secondary rounded-pill ml-2">
                                    <i class="mdi mdi-keyboard-backspace mr-1"></i>
                                    Back
                                </button>
                            </div>
                        </div>

                        <h4 class="page-title">
                            <i class="mdi mdi-badge-account mr-1"></i>{{
                            bookAdmissionForm.value['admissionId']
                            === 0 ? 'Add':'Update'
                            }} Admission
                            <span *ngIf="bookAdmissionForm.value['admissionId'] !== 0">({{bookAdmissionForm.value['admissionNo']}})</span>
                        </h4>
                    </div>
                </div>
            </div>
            <div>
                <div class="d-flex">
                    <div class="flex-1 mr-1">
                        <div class="card-box pb-2">
                            <h4 class="box-title mb-2">
                                <i class="mdi mdi-information-outline mr-1"></i>Admission Information <code>*</code>
                            </h4>
                            <div class="row">
                                <div class="col-6 col-lg-4">
                                    <div class="form-group position-relative">
                                        <label class="mb-1">Admission Date & time <code>*</code></label>
                                        <input type="datetime-local" [min]="minAdmissionDate" [max]="maxAdmissionDate" [ngClass]="{ 'is-invalid': (submitted && forms.bookAdmission.admissionDate.errors) }" class="form-control" formControlName="admissionDate" />
                                        <div *ngIf="submitted && forms.bookAdmission.admissionDate" class="invalid-feedback show-must">
                                            <div *ngIf="forms.bookAdmission.admissionDate.errors?.required">
                                                Admission date is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4">
                                    <div class="form-group">
                                        <label class="mb-1">Patient <code>*</code></label>
                                        <input class="form-control" *ngIf="fromOp =='fromAppointments' ||  fromOp =='fromScheduledAdmission'" formControlName="patientName" disabled />
                                        <ng-select *ngIf="fromOp !=='fromAppointments' &&  fromOp !=='fromScheduledAdmission'" class="text-uppercase"
                                                   [items]="patients" [loading]="loadingPatients"
                                                   [clearable]="true" bindLabel="name"
                                                   bindValue="id"
                                                   [typeahead]="patientsInput" [minTermLength]="2"
                                                   typeToSearchText="Search patient" (search)="fetchPatientHelper($event)" placeholder="Enter Name/Mobile of Patient"
                                                   [ngClass]="{ 'is-invalid': (submitted && forms.bookAdmission.patientId.errors) }" formControlName="patientId" (change)="onClickPatient($event)">
                                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                                    No patient found for '{{searchTerm}}'
                                                </div>
                                                <div class="ng-option disabled" *ngIf="(!searchTerm || searchTerm.length < 2)">
                                                    Search patient
                                                </div>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" let-i="index">
                                                <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                                <span class="text-muted d-block font-13" [textContent]="item.optionalText2"></span>
                                            </ng-template>
                                            <ng-template ng-label-tmp let-item="item" let-i="index">
                                                <div class="media">
                                                    <div class="avatar-xs mr-1">
                                                        <img *ngIf="item.optionalText" [src]="item.optionalText" [alt]="item.value" class="img-fluid rounded-circle" />
                                                        <span *ngIf="!item.optionalText" class="avatar-title rounded-circle font-11 font-weight-bold text-white" avatar-bg [index]="i" [textContent]="item.value | initials"></span>
                                                    </div>
                                                    <div class="media-body">
                                                        <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4">
                                    <div class="form-group">
                                        <label class="mb-1">Case Type <code>*</code></label>
                                        <ng-select class="ng-select-sm text-uppercase" [items]="caseTypes"
                                                   bindLabel="value"
                                                   bindValue="id"
                                                   placeholder="Select"
                                                   autocomplete="nope"
                                                   [ngClass]="{ 'is-invalid': (submitted && forms.bookAdmission.surgeryId.errors) }"
                                                   [multiple]="false"
                                                   formControlName="surgeryId">
                                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                <div class="ng-option disabled">
                                                    No item found for '{{searchTerm}}'
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4">
                                    <div class="form-group">
                                        <label class="mb-1">Admission Visit Type </label>
                                        <select class="form-control custom-select form-control" formControlName="admissionTypeId" [ngClass]="{ 'is-invalid': (submitted && forms.bookAdmission.admissionTypeId.errors) }">
                                            <option [ngValue]="null" disabled *ngIf="!loadingAdmissionTypes">Select</option>
                                            <option *ngIf="loadingAdmissionTypes">Loading...</option>
                                            <option *ngFor="let item of admissionTypes;" [textContent]="item.value" [ngValue]="item.id"></option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4">
                                    <div class="form-group">
                                        <label class="mb-1">Admission Type </label>
                                        <select class="form-control custom-select form-control" formControlName="admissionVisitTypeId" [ngClass]="{ 'is-invalid': (submitted && forms.bookAdmission.admissionVisitTypeId.errors) }">
                                            <option [ngValue]="null" disabled *ngIf="!loadingAdmissionVisitTypes">Select</option>
                                            <option *ngIf="loadingAdmissionTypes">Loading...</option>
                                            <option *ngFor="let item of admissionVisitTypes;" [textContent]="item.value" [ngValue]="item.id"></option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4">
                                    <div class="form-group mb-0">
                                        <label class="mb-1">Doctor Units <code>*</code></label>
                                        <ng-select class="ng-select-sm" [items]="DoctorUnits" [loading]="loadingDoctorUnits" [ngClass]="{ 'is-invalid': (submitted && forms.bookAdmission.doctorUnitMasterId.errors) }" [trackByFn]="trackBy.doctorUnits" bindLabel="unitName" bindValue="doctorUnitMasterId" [typeahead]="patientsInput" typeToSearchText="Search doctorUnits" placeholder="Enter Doctor Unit Name" formControlName="doctorUnitMasterId">
                                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                No Doctor Units found<span *ngIf="searchTerm"> for '{{searchTerm}}' </span>.
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" let-i="index">
                                                <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.unitName"></h5>
                                            </ng-template>
                                            <ng-template ng-label-tmp let-item="item" let-i="index">
                                                <div class="media">
                                                    <div class="avatar-xs mr-1">
                                                        <span class="avatar-title rounded-circle font-11 font-weight-bold bg-soft-warning text-primary">
                                                            <i class="fe-map-pin"></i>
                                                        </span>
                                                    </div>
                                                    <div class="media-body">
                                                        <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.unitName"></h5>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4">
                                    <div class="form-group mb-0">
                                        <label class="mb-1">Department <code>*</code></label>
                                        <ng-select [items]="departments" [loading]="loadingDepartments" [trackByFn]="trackBy.resource" [clearable]="false" bindLabel="value" bindValue="id" [typeahead]="departmentsInput" placeholder="Select Department" [ngClass]="{ 'is-invalid': (submitted && forms.bookAdmission.departmentId.errors) }" formControlName="departmentId">
                                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                No departments found<span *ngIf="searchTerm"> for '{{searchTerm}}' </span>.
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" let-i="index">
                                                <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                                <span class="text-muted d-block font-13" [textContent]="item.optionalText"></span>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>

                                <div class="col-6 col-lg-4">
                                    <div class="form-group mb-0">
                                        <label class="mb-1">Referral Doctor </label>
                                        <ng-select class="ng-select-sm text-uppercase"
                                                   [items]="referrals | async"
                                                   [loading]="loadingReferraldoctor"
                                                   [trackByFn]="trackBy.resource"
                                                   [typeahead]="referralDoctorInput"
                                                   typeToSearchText="Search Referral Doctor"
                                                   bindLabel="name"
                                                   bindValue="name"
                                                   placeholder="Please select Referral Doctor"
                                                   [minTermLength]="2"
                                                   (change)="onChangeReferralDoctor($event)"
                                                   [virtualScroll]="true"
                                                   formControlName="referralDoctorName">
                                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 1">
                                                    No Referral Doctor found for '{{searchTerm}}'
                                                </div>
                                                <div class="ng-option disabled" *ngIf="(!searchTerm || searchTerm.length < 1)">
                                                    Search Referral Doctor
                                                </div>
                                            </ng-template>

                                            <ng-template ng-option-tmp let-item="item" let-i="index">
                                                <div class="media">
                                                    <div class="media-body">
                                                        <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.name"></h5>
                                                        <span class="text-muted d-block font-13" *ngIf="item.value== 'P'" [textContent]="'Internal Doctor'"></span>
                                                        <span class="text-muted d-block font-13" *ngIf="item.value== 'R'" [textContent]="'External Doctor'"></span>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-12 mt-2" *ngIf="bookAdmissionForm.value['admissionId'] === 0 && patient && patient.admissionId !==null && patient.isDischarged === false">
                                    <div class="alert alert-danger w-100-per">
                                        <span class="blink"><i class="mdi mdi-close-octagon mr-1"></i> This Patient already has admission in {{patient.location}} & not discharged!</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="bookAdmissionForm.value['doctorUnitMasterId'] != null && selectedDoctorUnit != null && selectedDoctorUnit!= undefined">
                                <div class="col-12">
                                    <div class="overflow-auto">
                                        <table class="table mt-2 mb-0 table-bordered table-sm">
                                            <thead>
                                                <tr>
                                                    <th>S.no</th>
                                                    <th>Doctor Name</th>
                                                    <th>Designation</th>
                                                    <th>Specialization<code>*</code></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let doctor of selectedDoctorUnit.tagDoctor;index as j;">
                                                    <td>
                                                        <h6 class="mb-0 mt-0" [textContent]="j+1"></h6>
                                                    </td>
                                                    <td>
                                                        <h6 class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="doctor.providerName || '--'"></h6>
                                                    </td>
                                                    <td>
                                                        <h6 class="mb-0 mt-0" [textContent]="doctor.designation || '--'"></h6>
                                                    </td>
                                                    <td *ngIf="j === 0">
                                                        <select class="form-control custom-select form-control" formControlName="specializationId" [ngClass]="{ 'is-invalid': (submitted && forms.bookAdmission.specializationId.errors) }" placeholder="Select Specialization">
                                                            <option [ngValue]="null" disabled *ngIf="!loadSeniorSpecialization">Select</option>
                                                            <option *ngIf="loadSeniorSpecialization">Loading...</option>
                                                            <option *ngFor="let item of seniorDoctorSpecializations;" [textContent]="item.value" [ngValue]="item.id"></option>
                                                        </select>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-box pb-2" formGroupName="surrogacyData" *ngIf="showSurrogacy">
                            <h4 class="box-title mb-2">
                                <i class="mdi mdi-account-box-outline mr-1"></i>Surrogacy
                            </h4>
                            <div class="row">
                                <div class="col-6 col-lg-4">
                                    <div class="form-group">
                                        <label class="mb-1">Genetic Mother Name</label>
                                        <input type="text" class="form-control" formControlName="geneticMotherName" titleOnly block autocomplete="nope" placeholder="Enter Genetic Mother Name" />
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4">
                                    <div class="form-group">
                                        <label class="mb-1">Age</label>
                                        <input type="text" numbersOnly class="form-control" formControlName="age" maxlength="2" block autocomplete="nope" placeholder="Enter age" />
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4">
                                    <div class="form-group">
                                        <label class="mb-1">Genetic Father Name</label>
                                        <input type="text" class="form-control" formControlName="geneticFatherName" block autocomplete="nope" placeholder="Enter Genetic Father Name" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-lg-4">
                                    <div class="form-group">
                                        <label class="mb-1">Address</label>
                                        <textarea class="form-control" formControlName="address" block autocomplete="nope" placeholder="Enter Address"></textarea>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4">
                                    <div class="form-group">
                                        <label class="mb-1">Phone No</label>
                                        <input type="text" numbersOnly class="form-control" formControlName="phoneNo" minlength="10" maxlength="10" block autocomplete="nope" placeholder="Enter Phone No" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group d-flex">
                                    <div class="form-group col-6">
                                        <label class="mb-1">Blood Group</label>
                                    </div>
                                    <div class="form-group col-12 col-lg-8 mb-1">
                                        <select class="form-control custom-select" formControlName="bloodGroup" autocomplete="nope">
                                            <option value="null">Select</option>
                                            <option value="O">O</option>
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="AB">AB</option>
                                        </select>
                                    </div>
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div class="form-group d-flex">
                                    <div class="form-group col-6">
                                        <label class="mb-1">Rh Type</label>
                                    </div>
                                    <div class="form-group col-12 col-lg-8 mb-1">
                                        <select class="form-control custom-select" formControlName="rhType" autocomplete="nope">
                                            <option value="null">Select</option>
                                            <option value="+ve">+VE</option>
                                            <option value="-ve">-VE</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-lg-4">
                                    <div class="form-group">
                                        <label class="mb-1">From</label>
                                        <input type="date" class="form-control" formControlName="fromDate" block autocomplete="nope" placeholder="Enter From Date" />
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4">
                                    <div class="form-group">
                                        <label class="mb-1">To</label>
                                        <input type="date" class="form-control" formControlName="toDate" block autocomplete="nope" placeholder="Enter To Date" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-box pb-2" formGroupName="emergencyInfo" *ngIf="showEmergency">
                            <h4 class="box-title mb-2">
                                <i class="mdi mdi-account-box-outline mr-1"></i>Emergency
                            </h4>
                            <div class="row">
                                <div class="col-6 col-lg-4">
                                    <div class="form-group">
                                        <label class="mb-1">Emergency no.</label>
                                        <input type="text" numbersOnly class="form-control" formControlName="emergencyContactNo" minlength="10" maxlength="10" block autocomplete="nope" placeholder="Enter mobile number" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-box pb-2">
                            <h4 class="box-title mb-2">
                                <i class="mdi mdi-credit-card-outline mr-1"></i>Payment Details <code>*</code>
                            </h4>
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Pay Type <code>*</code></label>
                                        <ng-select class="font-13" placeholder="Select type" [clearable]="false"
                                                   formControlName="payType"
                                                   [items]="payTypes"
                                                   bindLabel="name"
                                                   bindValue="value"
                                                   (change)="onPayTypeChange($event)"
                                                   [ngClass]="{ 'is-invalid': submitted && forms.bookAdmission.payType?.errors }">
                                            <ng-template ng-option-tmp let-item="item">
                                                <span class="font-13" [textContent]="item.name"></span>
                                            </ng-template>
                                            <ng-template ng-label-tmp let-item="item">
                                                <span class="font-13" [textContent]="item.name"></span>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>
                                <ng-container *ngIf="forms?.bookAdmission?.payType?.value === 'I'">
                                    <div class="col-4">

                                        <div class="form-group ">
                                            <label class="mb-1">Insurance Company </label>
                                            <select (ngModelChange)="onChange($event)" class="form-control custom-select" formControlName="insuranceCompanyId" [ngClass]="{ 'is-invalid': submitted && forms.bookAdmission.insuranceCompanyId?.errors }">
                                                <option [ngValue]="null" *ngIf="!loadingInsurances">
                                                    Select insurance company
                                                </option>
                                                <option selected *ngIf="loadingInsurances">Loading...</option>
                                                <option *ngFor="let item of insurances" [textContent]="item.value" [ngValue]="item.id"></option>
                                            </select>
                                            <span style="color: red" *ngIf="message && formArrayIndex==i">{{message}}</span>
                                        </div>

                                    </div>
                                </ng-container>
                                <ng-container *ngIf="forms?.bookAdmission?.payType?.value === 'I'">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label class="mb-1">Tpa Name</label>
                                            <select class="form-control custom-select" placeholder="Please Select TPA" formControlName="tpaId">
                                                <option [ngValue]="null" *ngIf="!loadingTpas">Select TPA</option>
                                                <option selected *ngIf="loadingTpas">Loading...</option>
                                                <option *ngFor="let item of tpa" [textContent]="item.value" [ngValue]="item.id"></option>
                                            </select>
                                        </div>
                                    </div>
                                </ng-container>




                                <div class="col-4">
                                    <div class="form-group mb-0">
                                        <label class="mb-1">Patient Organization </label>
                                        <input type="text" class="form-control" formControlName="patientOrganization" maxlength="50" titleOnly block autocomplete="nope" placeholder="Enter Patient Working Organization" />
                                        <div class="invalid-feedback show-must" *ngIf="submitted && forms.bookAdmission.patientOrganization.errors">
                                            <span *ngIf="forms.bookAdmission.patientOrganization.errors.required">This field is required.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-box pb-2" *ngIf="forms?.bookAdmission?.payType?.value === 'I'">
                            <h4 class="box-title mb-2">
                                <i class="mdi mdi-account-credit-card mr-1"></i>Patient Insurance
                            </h4>
                            <div class="row">
                                <ng-container>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label class="mb-1">Insurance Type <code>*</code></label>
                                            <select class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && forms.bookAdmission.insuranceTypeId.errors }" formControlName="insuranceTypeId">
                                                <option [ngValue]="1">Primary</option>
                                                <option [ngValue]="2">Secondary</option>
                                                <option [ngValue]="3">Teritory</option>
                                            </select>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Charge Template <code>*</code></label>
                                            <ng-select [items]="locationChargeTemplates" [ngClass]="{ 'is-invalid': submitted && forms.bookAdmission.chargeModuleTemplateId.errors }" [loading]="loadingLocationChargeTemplates" [clearable]="false" bindLabel="name" bindValue="id" [notFoundText]="'No Charge Template found'" placeholder="Select charge template" formControlName="chargeModuleTemplateId">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <span class="font-weight-normal font-13" [textContent]="item.name"></span>
                                                </ng-template>
                                                <ng-template ng-label-tmp let-item="item">
                                                    <span class="font-weight-normal font-13" [textContent]="item.name"></span>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label class="mb-1">Card Number </label>
                                            <input type="text" class="form-control" formControlName="cardNo" maxlength="20" block autocomplete="nope" placeholder="Enter card number" />
                                        </div>

                                    </div>
                                </ng-container>
                                <ng-container>
                                    <div class="col-4">
                                        <div class="form-group position-relative mb-0">
                                            <label class="mb-1">Validity </label>
                                            <input type="date" formControlName="validity" [min]="toDay" class="form-control" (ngModelChange)="onOpeninsurance()" />
                                        </div>

                                    </div>
                                </ng-container>
                            </div>

                        </div>
                        <div class="card-box pb-2">
                            <h4 class="box-title mb-2">
                                <i class="mdi mdi-account-credit-card mr-1"></i>Person Responsible For Payment
                            </h4>
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input class="form-control" type="text" textOnly placeholder="Enter Person Name" autocomplete="nope" formControlName="paymentResponsibleName" [ngClass]="{ 'is-invalid': (submitted && forms.bookAdmission.paymentResponsibleName.errors) }" />
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Address</label>
                                        <input class="form-control" type="text" placeholder="Enter Address" autocomplete="nope" formControlName="paymentRPAddress" [ngClass]="{ 'is-invalid': (submitted && forms.bookAdmission.paymentRPAddress.errors) }" />
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group mb-0">
                                        <label class="mb-1">Relation</label>
                                        <select class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && forms.bookAdmission.rpRelationId.errors }" formControlName="rpRelationId">
                                            <option [ngValue]="null" *ngIf="!loadingRelations">Select relation</option>
                                            <option selected *ngIf="loadingRelations">Loading...</option>
                                            <option *ngFor="let item of relationships" [textContent]="item.value" [ngValue]="item.id"></option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group mb-0">
                                        <label class="mb-1">Phone No</label>
                                        <input type="text" numbersOnly class="form-control" placeholder="Enter Phone No" minlength="10" maxlength="10" block autocomplete="nope" formControlName="paymentRPMobile" [ngClass]="{ 'is-invalid': (submitted && forms.bookAdmission.paymentRPMobile.errors) }" />
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group mb-0">
                                        <label class="mb-1">Email</label>
                                        <input type="text" class="form-control" formControlName="paymentRPEmail" maxlength="150" block autocomplete="nope" [ngClass]="{ 'is-invalid': submitted && forms.bookAdmission.paymentRPEmail.errors }" placeholder="Enter email address" />
                                        <div *ngIf="forms.bookAdmission.paymentRPEmail.errors" class="invalid-feedback show-must">
                                            <div *ngIf="forms.bookAdmission.paymentRPEmail.errors.emailExists">
                                                Email address already exist
                                            </div>
                                            <div *ngIf="forms.bookAdmission.paymentRPEmail.errors.invalidEmail">
                                                Please enter valid Email Address
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group mb-0">
                                        <label class="mb-1">Occupation</label>
                                        <ng-select class="ng-select-sm text-uppercase" [items]="occupation" [loading]="loadingOccupation" bindLabel="value" bindValue="id" autocomplete="nope" placeholder="-Select-" formControlName="rpOccupationId" [ngClass]="{ 'is-invalid': submitted && forms.bookAdmission.rpOccupationId.errors }">
                                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                <div class="ng-option disabled">
                                                    Nothing found for '{{searchTerm}}'
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-box pb-2">
                            <h4 class="box-title mb-2 d-flex align-items-center justify-content-between">
                                <span><i class="mdi mdi-package-variant mr-1"></i>Package Details</span>
                                <span class="d-flex align-items-center" aria-hidden="true" (click)="showPackageBlock = !showPackageBlock">
                                    <span *ngIf="!showPackageBlock">
                                        <span class="small">Show</span><i class="mdi mdi-chevron-right ml-1"></i>
                                    </span>
                                    <span *ngIf="showPackageBlock">
                                        <span class="small">Hide</span><i class="mdi mdi-chevron-down ml-1"></i>
                                    </span>
                                </span>
                            </h4>
                            <div class="row" *ngIf="showPackageBlock">
                                <div class="col-6">
                                    <div class="form-group mb-0">
                                        <label>Package</label>
                                        <ng-select [items]="packages" [loading]="loadingPackages" [multiple]="true" [clearable]="true" bindLabel="name" bindValue="id" autocomplete="nope" placeholder="Select package" formControlName="packageModuleId">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-box pb-2">
                            <h4 class="box-title mb-2 d-flex justify-content-between align-items-center">
                                <span><i class="mdi mdi-human-female-girl mr-1"></i>New Born and MLC Details</span>

                            </h4>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <div class="d-flex align-items-center">
                                            <input id="new_born_baby" type="checkbox" formControlName="isNewBornBaby" />
                                            <label for="new_born_baby" class="ml-1 mb-0">New Born Baby</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <div class="d-flex align-items-center">
                                            <input id="mlc" type="checkbox" formControlName="isMLC" />
                                            <label for="mlc" class="ml-1 mb-0">MLC</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>New Born Type</label>
                                        <select class="form-control custom-select form-control mb-0" (change)="onChangeCharge(chargeCategory)" formControlName="babyTypeId">
                                            <option [ngValue]="null" *ngIf="!loadingBabyTypes">All</option>
                                            <option selected *ngIf="loadingBabyTypes">Loading...</option>
                                            <option *ngFor="let item of babyTypes;" [textContent]="item.value" [ngValue]="item.id"></option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-4" *ngIf="bookAdmissionForm.value['isNewBornBaby']===true">
                                    <div class="form-group">
                                        <label>Mother IP No</label>
                                        <ng-select class="ng-select-sm" [items]="admissionPatients" [loading]="loadingPatients" [trackByFn]="trackBy.resource" bindLabel="optionalText" bindValue="id" [typeahead]="patientsInput" typeToSearchText="Search patient" placeholder="Enter Admission No" (change)="onSelectMother($event)" formControlName="motherIpNo">
                                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                No patients found<span *ngIf="searchTerm"> for '{{searchTerm}}' </span>.
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" let-i="index">
                                                <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.name"></h5>
                                                <p class="text-muted d-block font-13" [textContent]="item.optionalText"></p>
                                                <span class="text-muted d-block font-13" [textContent]="item.optionalText2"></span>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-4" *ngIf="bookAdmissionForm.value['isNewBornBaby']==true">
                                    <div class="form-group">
                                        <label class="mb-1">Mother Name</label>
                                        <input class="form-control" type="text" placeholder="Enter Mother Name" autocomplete="nope" formControlName="motherName" [ngClass]="{ 'is-invalid': (submitted && forms.bookAdmission.motherName.errors) }" />
                                    </div>
                                </div>
                                <div class="col-4" *ngIf="bookAdmissionForm.value['isMLC']==true">
                                    <div class="form-group">
                                        <label class="mb-1">MLC No</label>
                                        <input type="text" class="form-control" placeholder="Enter MLC No" block autocomplete="nope" formControlName="mlcNo" [ngClass]="{ 'is-invalid': (submitted && forms.bookAdmission.mlcNo.errors) }" />
                                    </div>
                                </div>
                                <div class="col-4" *ngIf="bookAdmissionForm.value['isMLC']==true">
                                    <div class="form-group">
                                        <label class="mb-1">MLC Type</label>
                                        <input class="form-control" type="text" placeholder="Enter MLC Type" autocomplete="nope" formControlName="mlcType" [ngClass]="{ 'is-invalid': (submitted && forms.bookAdmission.mlcType.errors) }" />
                                    </div>
                                </div>
                                <div class="col-4" *ngIf="bookAdmissionForm.value['isMLC']==true">
                                    <div class="form-group">
                                        <label class="mb-1">MLC Nm</label>
                                        <input class="form-control" type="text" placeholder="Enter MLC Nm" autocomplete="nope" formControlName="mlcNm" [ngClass]="{ 'is-invalid': (submitted && forms.bookAdmission.mlcNm.errors) }" />

                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label class="mb-1">Clinical Notes</label>
                                        <input class="form-control" type="text" placeholder="Enter Clinical Notes" autocomplete="nope" formControlName="clinicalNotes" [ngClass]="{ 'is-invalid': (submitted && forms.bookAdmission.clinicalNotes.errors) }" />
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label class="mb-1">Current Bed</label>
                                        <input class="form-control" type="text" placeholder="Enter Current Bed" autocomplete="nope" formControlName="currentBed" [ngClass]="{ 'is-invalid': (submitted && forms.bookAdmission.currentBed.errors) }" />
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label class="mb-1">Current Unit</label>
                                        <input class="form-control" type="text" placeholder="Enter Current Unit" autocomplete="nope" formControlName="currentUnit" [ngClass]="{ 'is-invalid': (submitted && forms.bookAdmission.currentUnit.errors) }" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-box pb-2">
                            <h4 class="box-title mb-2 d-flex align-items-center justify-content-between">
                                <span> <i class="mdi mdi-attachment mr-1"></i>Attachments </span>
                                <span class="d-flex align-items-center" aria-hidden="true" (click)="showAttachmentBlock = !showAttachmentBlock">
                                    <span *ngIf="!showAttachmentBlock">
                                        <span class="small">Show</span><i class="mdi mdi-chevron-right ml-1"></i>
                                    </span>
                                    <span *ngIf="showAttachmentBlock">
                                        <span class="small">Hide</span><i class="mdi mdi-chevron-down ml-1"></i>
                                    </span>
                                </span>
                            </h4>
                            <div class="row" *ngIf="showAttachmentBlock">
                                <div class="col-6 col-md-3">
                                    <div class="form-group mb-0">
                                        <label class="mb-1">Document Name</label>
                                        <input type="text" class="form-control" formControlName="documentName" placeholder="Enter Document Name" />
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="form-group mb-0">
                                        <label class="mb-1">Document Type</label>
                                        <select class="form-control custom-select text-uppercase" formControlName="documentType">
                                            <option [ngValue]="null" *ngIf="!loadingCategories">Select</option>
                                            <option selected *ngIf="loadingCategories">Loading...</option>
                                            <option *ngFor="let item of categories" [textContent]="item.value" [ngValue]="item.value"></option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="form-group mb-0">
                                        <label class="mb-1">Description</label>
                                        <input type="text" class="form-control" formControlName="description" placeholder="Enter Description" />
                                    </div>
                                </div>
                                <div class="col-6 col-md-3 d-flex align-items-end">
                                    <div class="form-group mb-0">
                                        <button type="button" class="btn btn-primary btn-sm" (click)="onOpenDocumentModal(templateUploadDocument)">
                                            <i class="mdi mdi-paperclip mr-1"></i>Choose File
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-box pb-2">
                            <h4 class="box-title mb-2 d-flex justify-content-between align-items-center">
                                <span><i class="mdi mdi-account-box-outline mr-1"></i>Attendant Information</span>
                                <span class="d-flex align-items-center" aria-hidden="true" (click)="showAttendantBlock = !showAttendantBlock">
                                    <span *ngIf="!showAttendantBlock">
                                        <span class="small">Show</span><i class="mdi mdi-chevron-right ml-1"></i>
                                    </span>
                                    <span *ngIf="showAttendantBlock">
                                        <span class="small">Hide</span><i class="mdi mdi-chevron-down ml-1"></i>
                                    </span>
                                </span>
                            </h4>
                            <div class="row" *ngIf="showAttendantBlock">
                                <div class="col-6 col-lg-4">
                                    <div class="form-group mb-0">
                                        <label class="mb-1">Attendant Name</label>
                                        <input type="text" class="form-control" formControlName="attendantName" maxlength="50" titleOnly block autocomplete="nope" placeholder="Enter attendant name" />
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4">
                                    <div class="form-group mb-0">
                                        <label class="mb-1">Contact No.</label>
                                        <input type="text" numbersOnly class="form-control" formControlName="attendantContactNo" minlength="10" maxlength="10" block autocomplete="nope" placeholder="Enter mobile number" [ngClass]="{ 'is-invalid': (submitted && forms.bookAdmission.attendantContactNo.errors) }" />
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4">
                                    <div class="form-group mb-0">
                                        <label class="mb-1">Relation with Patient</label>

                                        <select class="form-control custom-select" formControlName="attendantRelationWithPatient">
                                            <option [ngValue]="null" *ngIf="!loadingRelationShips">
                                                Select relation with patient
                                            </option>
                                            <option selected *ngIf="loadingRelationShips">Loading...</option>
                                            <option *ngFor="let item of relationships" [textContent]="item.value" [ngValue]="item.value"></option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-box pb-2">
                            <h4 class="box-title mb-2 d-flex justify-content-between align-items-center">
                                <span><i class="fe-edit-1 mr-1"></i>Admission notes</span>
                                <span class="d-flex align-items-center" aria-hidden="true" (click)="showNotesBlock = !showNotesBlock">
                                    <span *ngIf="!showNotesBlock">
                                        <span class="small">Show</span><i class="mdi mdi-chevron-right ml-1"></i>
                                    </span>
                                    <span *ngIf="showNotesBlock">
                                        <span class="small">Hide</span><i class="mdi mdi-chevron-down ml-1"></i>
                                    </span>
                                </span>
                            </h4>
                            <div class="row" *ngIf="showNotesBlock">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <textarea class="form-control" formControlName="admissionNotes" maxlength="250" block autocomplete="nope" placeholder="Enter admission notes"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-box pb-2">
                            <h4 class="box-title mb-2 d-flex justify-content-between align-items-center">
                                <span><i class="mdi mdi-clock-time-twelve-outline mr-1"></i>Patient Timings</span>
                                <span class="d-flex align-items-center" aria-hidden="true" (click)="showTimingBlock = !showTimingBlock">
                                    <span *ngIf="!showTimingBlock">
                                        <span class="small">Show</span><i class="mdi mdi-chevron-right ml-1"></i>
                                    </span>
                                    <span *ngIf="showTimingBlock">
                                        <span class="small">Hide</span><i class="mdi mdi-chevron-down ml-1"></i>
                                    </span>
                                </span>
                            </h4>
                            <div *ngIf="showTimingBlock" class="d-flex align-items-center justify-content-between">
                                <div class="form-group mr-2 w-100-per mb-0">
                                    <label>Breakfast</label>
                                    <input type="time" formControlName="breakfast" class="form-control" />
                                </div>
                                <div class="form-group mr-2 w-100-per mb-0">
                                    <label>Lunch</label>
                                    <input type="time" formControlName="lunch" class="form-control" />
                                </div>
                                <div class="form-group w-100-per mb-0">
                                    <label>Dinner</label>
                                    <input type="time" formControlName="dinner" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="card-box pb-2">
                            <h4 class="box-title mb-2 d-flex justify-content-between align-items-center">
                                <span><i class="mdi mdi-chevron-triple-up mr-1"></i>Patient Position</span>
                                <span class="d-flex align-items-center" aria-hidden="true" (click)="showPatientBlock = !showPatientBlock">
                                    <span *ngIf="!showPatientBlock">
                                        <span class="small">Show</span><i class="mdi mdi-chevron-right ml-1"></i>
                                    </span>
                                    <span *ngIf="showPatientBlock">
                                        <span class="small">Hide</span><i class="mdi mdi-chevron-down ml-1"></i>
                                    </span>
                                </span>
                            </h4>
                            <div class="d-flex" *ngIf="showPatientBlock">
                                <ng-container *ngFor="let subItem of priorities">
                                    <div class="form-group mb-0 mr-3">
                                        <label class="d-flex align-items-center">
                                            <input type="radio" formControlName="patientPriorityId" [value]="subItem.id" class="form-control w-20-px mr-1" />
                                            <i [class]="'mdi mr-1 mdi-' + subItem.icon + ' ' + subItem.color"></i><span [textContent]="subItem.name"></span>
                                        </label>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                    </div>
                    <div class="flex-1 ml-1">
                        <div class="card">
                            <div class="card-body d-flex justify-content-center">
                                <div class="d-flex">
                                    <div>
                                        <div class="media">
                                            <div class="avatar-sm mr-1">
                                                <span class="avatar-title rounded-circle font-11 font-weight-bold text-white" avatar-bg [index]="0" [textContent]="(patient && patient.fullName || 'XX') | initials"></span>
                                            </div>
                                            <div class="media-body">
                                                <h5 class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="(patient && patient.fullName || 'Patient Name')"></h5>
                                                <span>
                                                    <span *ngIf="patient && patient.dateOfBirth" [textContent]="(patient && patient.dateOfBirth) | age:'days'"></span>

                                                    <span *ngIf="!patient || !patient.dateOfBirth" [textContent]="(patient && patient.age || 'XX') + ' yrs'"></span>

                                                    <span class="mx-1"><i class="mdi mdi-slash-forward"></i></span>
                                                    <span [textContent]="patient && patient.gender ? (patient.gender === 'M' ? 'Male' : 'Female') : 'Gender'"></span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="border-middle mx-2"></div>
                                    <div>
                                        <div class="media">
                                            <div class="media-body">

                                                <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="(patient && patient.umrNo || 'UMRXXXXX')"></h5>
                                                <span>
                                                    <span class="mx-1"><i class="mdi mdi-phone"></i></span>
                                                    <span [textContent]="(patient && patient.mobile || 'XXXXXXXXXX')"></span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="patient && patient.relation==='Husband'">
                                        <div class="border-middle mx-2"></div>
                                        <div>
                                            <div class="media">
                                                <div class="media-body">

                                                    <h5 class="mb-0 mt-0 font-weight-normal">Husband Name</h5>
                                                    <span>
                                                        <span class="mx-1"><i class="mdi mdi-account"></i></span>
                                                        <span [textContent]="(patient && patient.fatherOrHusband || 'Husband Name') + ' (' + ((patient && patient.relativeAge || 'XX') + ' yrs') + ')'"></span>
                                                        <span class="ml-1" [textContent]="patient && patient.relativeDOB | date:'dd-MM-yyyy' ||'XX-XX-XXXX'"></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3" *ngIf="selectedCounsellingDetails && selectedCounsellingDetails.counselling.counsellingId && selectedCounsellingPackagesIds && selectedCounsellingPackagesIds.length">
                            <view-counselling [counsellingId]="selectedCounsellingDetails.counselling.counsellingId" [counsellingPackageIds]="selectedCounsellingPackagesIds" [counsellingViewType]="counsellingViewType.ReadOnlyMode"></view-counselling>
                        </div>
                        <div class="card-box pb-2" *ngIf="editBedStatus">
                            <h4 class="box-title mb-2 d-flex justify-content-between">
                                <span> <i class="mdi mdi-bed-outline mr-1"></i>Bed Management <code>*</code> </span>
                                <span *ngIf="submitted && forms.bookAdmission.bedId.errors && beds &&  beds.length>0">
                                    <span class="blink" style="font-weight: bold">
                                        <span>Please Select Bed</span>
                                    </span>
                                </span>
                            </h4>
                            <div class="row">
                                <div class="col-lg-6">
                                    <label>Charge Category</label>
                                    <select class="form-control custom-select form-control-sm mb-0" (change)="onChangeCharge(chargeCategory,$event)" formControlName="chargeCategoryId">
                                        <option [ngValue]="null" *ngIf="!loadingChargeCategoriesWithBeds">All</option>
                                        <option selected *ngIf="loadingChargeCategoriesWithBeds">Loading...</option>
                                        <option *ngFor="let item of chargeCategoriesWithBeds;" [textContent]="item.name" [ngValue]="item.id"></option>
                                    </select>
                                </div>
                                <div class="col-lg-6">
                                    <label>Billing Category</label>
                                    <select class="form-control custom-select form-control-sm mb-0" formControlName="chargeBillingCategoryId">
                                        <option [ngValue]="null" *ngIf="!loadingchargeCategory">All</option>
                                        <option selected *ngIf="loadingchargeCategory">Loading...</option>
                                        <option *ngFor="let item of chargeCategory;" [textContent]="item.name" [ngValue]="item.id"></option>
                                    </select>
                                </div>
                            </div>
                            <div class="row" *ngIf="!forms.bookAdmission.chargeModuleTemplateId.value">
                                <div class="col-sm-12">
                                    <div class="alert alert-info mt-2 text-center">
                                        <span>
                                            <i class="mdi mdi-information mr-1"></i>Please select
                                            <span class="font-weight-bold">Payment Details</span> to see available
                                            beds
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="showBeds">
                                <div class="row mt-2" *ngIf="loadingBeds">
                                    <div class="col-md-12">
                                        <span class="align-items-center">Please wait while loading beds..</span>
                                    </div>
                                </div>
                                <div class="row mt-2" *ngIf="!loadingBeds && beds.length <=0">
                                    <div class="col-12 ">
                                        <div class="alert alert-danger w-100-per">
                                            <span class="blink"><i class="mdi mdi-close-octagon mr-1"></i> There are no beds to select</span>
                                        </div>
                                    </div>
                                </div>
                                <div class=" justify-content-center d-flex ">
                                    <div *ngIf="bedselect">
                                        <div class="card p-1 m-1 bed border  ">
                                            <h4 class="justify-content-center d-flex">Old Bed</h4>
                                            <h5>BedNumber:{{bedselect.bedNumber}}</h5>
                                            <h5>RoomName:{{bedselect.currentRoomName}}</h5>
                                            <h5>CategoryName:{{bedselect.chargeCategoryName}}</h5>
                                        </div>
                                    </div>
                                </div>

                                <div class="bed-holder" *ngIf="!loadingBeds">
                                    <ng-container *ngFor="let item of beds">
                                        <div class="card m-1 bed-main" [ngClass]="{'selected' : selectedBedManagement.bedId === item.bedId}">
                                            <div class="card-body p-2 d-flex justify-content-center align-items-center flex-column">
                                                <div class="d-flex justify-content-center align-items-center flex-column">
                                                    <h4 class="mb-0" [textContent]="item.bedNumber"></h4>
                                                    <h6 [textContent]="item.chargeCategoryName"></h6>
                                                </div>
                                                <div>
                                                    <button type="button" (click)="selectBed(item)" class="btn btn-sm btn-primary rounded-pill">
                                                        <i class="mdi mdi-check-bold mr-1"></i>
                                                        <span *ngIf="selectedBedManagement.bedId !== item.bedId">Select</span>
                                                        <span *ngIf="selectedBedManagement.bedId === item.bedId">Selected</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div
                                          class="btn mr-1"
                                          [ngClass]="{
                                            'btn-soft-pink': item.gender !== 'M' && item.bedStatusName !== 'Available',
                                            'btn-soft-blue': item.gender === 'M' && item.bedStatusName !=='Available',
                                            'btn-success' : selectedBedManagement.bedId === item.bedId ,
                                            'btn-light' : selectedBedManagement.bedId !== item.bedId}"
                                          [ngStyle]="{'background-color': (selectedBedManagement.bedId === item.bedId) ? 'btn-success':'btn-light'}"
                                          (click)="selectBed(item);"
                                          style="box-shadow: 5px 5px 37px #c3c3c3"
                                        >
                                          {{item.bedNumber}}<br />
                                          <span
                                            class="text-white"
                                            *ngIf="selectedBedManagement.bedId === item.bedId"
                                            style="font-size: 16px"
                                          >
                                            <i class="fe-check"></i>
                                          </span>
                                          <span class="text-primary mr-1" style="font-size: 11px"
                                            >{{item.chargeCategoryName}}</span
                                          ><br />
                                          <span class="text-primary mr-1" style="font-size: 11px">
                                            {{item.floorName}} -> {{item.wardName}} -> {{item.roomName}} </span
                                          ><br />
                                          <span
                                            class="text-primary"
                                            *ngIf="selectedBedManagement.bedId !== item.bedId"
                                            [ngClass]="(item.bedStatusName === 'Available')?'text-success':'text-danger'"
                                            style="font-size: 11px"
                                          >
                                            <b>{{item.bedStatusName}}</b>
                                          </span>
                                        </div> -->


                                    </ng-container>






                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="d-flex flex-wrap align-items-center justify-content-end mt-2 fixed-div">
                    <div class="d-flex">
                        <button class="btn btn-sm btn-soft-secondary rounded-pill" type="button" (click)="onCloseModal();"><i class="mdi mdi-close"></i>Cancel</button>
                        <button type="submit" [disabled]="submitting" class="btn btn-sm btn-primary rounded-pill ml-1">
                            <span *ngIf="submitting">
                                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                Please wait..
                            </span>
                            <span id="submit" *ngIf="!submitting">
                                <i class="fe-info mr-1" *ngIf="!bookAdmissionForm.valid" placement="right" ngbTooltip="Enter all (*) required fields to submit"></i>Submit
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </form>
</div>
<div *ngIf="currentPageView === pageView.MainView" class="content top1">

    <div class="container-fluid">
        <div>
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">
                        <ng-container *menuButton="'adm_view_transfer_request'">

                            <button type="button" class="btn btn-primary btn-sm" (click)="hideTimeline()" [routerLink]="['/app/admissions/transfer-request']">
                                <i class="mdi mdi-transfer-right mr-1"></i>View Transfer Request
                            </button>
                        </ng-container>
                        <ng-container *menuButton="'adm_add_new_patient'">
                            <button type="button" class="btn btn-primary btn-sm" *ngIf="isPatientFilters" (click)="onOpenBookAdmissionModel()">
                                <i class="mdi mdi-plus-thick mr-1"></i> Add New Admission
                            </button>
                        </ng-container>
                        <!-- [useExistingCss]="true" printSectionId="admission_print" ngxPrint  -->
                        <button *ngIf="!loading && admissions.length &&  !isPatientFilters" class="btn btn-primary btn-sm mr-1 d-print-none">
                            <i class="fe-printer mr-1"></i>Print
                        </button>
                        <ng-container>
                            <button type="button" class="btn btn-secondary btn-sm ml-1" *ngIf="isPatientFilters" (click)="onShowFilters()">
                                <i class="fe-filter mr-1"></i>More Filters
                            </button>
                        </ng-container>
                    </div>
                    <h4 class="page-title">Admissions</h4>
                </div>
            </div>
        </div>
        <div *ngIf="isPatientFilters">
            <!--in admission page-->
            <div>
                <div class="card-box pb-2 mb-1 p-1">
                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-6 col-md-4 col-lg-4 col-xl-2">
                                <div class="form-group mb-0">
                                    <label class="mb-1">From date</label>
                                    <input class="form-control date-picker" type="text" block placeholder="Select from date" [(ngModel)]="filters.options.fromDate" readonly autocomplete="nope" (dateSelect)="getMinDate()" [maxDate]="setMaxDate" ngbDatepicker #fromDate="ngbDatepicker" (click)="fromDate.toggle(); $event.stopPropagation();" [value]="filters.options.fromDate | date: 'dd/MM/yyyy'" />
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 col-xl-2">
                                <div class="form-group mb-0">
                                    <label class="mb-1">To date</label>
                                    <input class="form-control date-picker" type="text" block placeholder="Select to date" [(ngModel)]="filters.options.toDate" readonly autocomplete="nope" (dateSelect)="getMaxDate()" [minDate]="setMinDate" ngbDatepicker #toDate="ngbDatepicker" (click)="toDate.toggle(); $event.stopPropagation();" [value]="filters.options.toDate | date: 'dd/MM/yyyy'" />
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 col-xl-2">
                                <div class="form-group mb-0">
                                    <label class="mb-1">Patient</label>
                                    <ng-select class="ng-select-sm text-uppercase"
                                               [items]="filterPatient"
                                               [loading]="loading"
                                               [clearable]="true"
                                               bindLabel="value"
                                               bindValue="id"
                                               [searchFn]="customPatientSearchPatient"
                                               (search)="fetchPatientNameMobileUmrHelper($event)"
                                               placeholder="Enter patient name/umr/mobile"
                                               (keydown.enter)="onApplyFilters()"
                                               (change)="onChange($event)"
                                               [(ngModel)]="filters.options.patientId">
                                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                            <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                                No patient found for '{{searchTerm}}'
                                            </div>
                                            <div class="ng-option disabled" *ngIf="(!searchTerm || searchTerm.length < 2)">
                                                Search patient
                                            </div>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-i="index">
                                            <div class="media">
                                                <div class="media-body">
                                                    <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                                    <span class="text-muted d-block font-13" [textContent]="item.optionalText"></span>
                                                    <span class="text-muted d-block font-13" [textContent]="item.optionalText1"></span>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 col-xl-2">
                                <div class="form-group mb-0">
                                    <label class="mb-1">Admission No</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">IP</span>
                                        </div>
                                        <input type="text" class="form-control" (keyup.enter)="onApplyFilters()" [(ngModel)]="filters.options.admissionNo" numbersOnly autocomplete="nope" maxlength="9" placeholder="admission no" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 col-xl-2">
                                <div class="form-group mb-0">
                                    <label class="mb-1">Doctor</label>
                                    <ng-select class="ng-select-sm text-uppercase" [items]="provider | async" [loading]="loadingProviders" [trackByFn]="trackBy.resource" bindLabel="value" bindValue="id" [typeahead]="providerInput" [minTermLength]="2" typeToSearchText="Search doctor" [virtualScroll]="true" placeholder="Enter 2 or more characters" [(ngModel)]="filters.options.providerId">
                                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                            <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                                No doctor found for '{{searchTerm}}'
                                            </div>
                                            <div class="ng-option disabled" *ngIf="(!searchTerm || searchTerm.length < 2)">
                                                Search doctor
                                            </div>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-i="index">
                                            <div class="media">
                                                <div class="avatar-sm mr-2">
                                                    <avatar-img *ngIf="item.optionalText" [src]="item.optionalText" [alt]="item.value" cssClass="img-fluid rounded-circle"></avatar-img>
                                                    <span *ngIf="!item.optionalText" class="avatar-title rounded-circle font-11 font-weight-bold text-white" avatar-bg [index]="i" [textContent]="item.value | initials"></span>
                                                </div>
                                                <div class="media-body">
                                                    <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.value"></h5>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template ng-label-tmp let-item="item">
                                            <div class="media">
                                                <div class="media-body">
                                                    <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.value"></h5>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 col-xl-2">
                                <div style="margin-top: 25px" class="d-flex justify-content-center">
                                    <button type="button" (click)="onApplyFilters()" class="btn btn-sm btn-primary rounded-pill">
                                        <i class="mdi mdi-magnify mr-1"></i>
                                        Search
                                    </button>
                                    <button type="button" (click)="onResetFilters()" class="btn btn-sm reset-btn rounded-pill ml-1">
                                        <i class="mdi mdi-backup-restore mr-1"></i>
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isPatientFilters">
            <!--in patient profile/ admission  page-->
            <div>
                <div class="card-box pb-2 mb-1 p-1">
                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-6 col-md-4 col-lg-4 col-xl-2">
                                <div class="form-group mb-0">
                                    <label class="mb-1">From date</label>
                                    <input class="form-control date-picker" type="text" block placeholder="Select from date" [(ngModel)]="filters.options.fromDate" readonly autocomplete="nope" ngbDatepicker #fromDate="ngbDatepicker" (click)="fromDate.toggle(); $event.stopPropagation();" />
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 col-xl-2">
                                <div class="form-group mb-0">
                                    <label class="mb-1">To date</label>
                                    <input class="form-control date-picker" type="text" block placeholder="Select to date" [(ngModel)]="filters.options.toDate" readonly autocomplete="nope" ngbDatepicker #toDate="ngbDatepicker" (click)="toDate.toggle(); $event.stopPropagation();" />
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 col-xl-2">
                                <div class="form-group mb-0">
                                    <label class="mb-1">Admission No</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">IP</span>
                                        </div>
                                        <input type="text" class="form-control" (keyup.enter)="onApplyFilters()" [(ngModel)]="filters.options.admissionNo" numbersOnly autocomplete="nope" maxlength="9" placeholder="admission no" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 col-xl-2 form-group">
                                <label class="mb-1 font-13">Doctor</label>
                                <ng-select class="ng-select-sm" [items]="providers" [loading]="loadingProviders" [trackByFn]="trackBy.resource" bindLabel="value" bindValue="id" [typeahead]="providerInput" [minTermLength]="2" typeToSearchText="Search doctor" [virtualScroll]="true" placeholder="Enter 2 or more characters" [(ngModel)]="filters.options.providerId">
                                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                        <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                            No doctor found for '{{searchTerm}}'
                                        </div>
                                        <div class="ng-option disabled" *ngIf="(!searchTerm || searchTerm.length < 2)">
                                            Search doctor
                                        </div>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-i="index">
                                        <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                        <span class="text-muted d-block font-13" [textContent]="item.optionalText"></span>
                                    </ng-template>
                                    <ng-template ng-label-tmp let-item="item">
                                        <div class="media">
                                            <div class="avatar-xs mr-1">
                                                <span class="avatar-title rounded-circle font-11 font-weight-bold bg-soft-primary text-primary">
                                                    <i class="fe-map-pin"></i>
                                                </span>
                                            </div>
                                            <div class="media-body">
                                                <h5 class="mb-0 mt-0 font-weight-normal">
                                                    <span>{{item.value}} ({{item.optionalText}})</span>
                                                </h5>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 col-xl-2 form-group">
                                <label class="mb-1 font-13">Active Status</label>
                                <select class="form-control custom-select form-control-sm" (keyup.enter)="onApplyFilters()" [(ngModel)]="filters.options.active">
                                    <option [ngValue]="true">Active</option>
                                    <option [ngValue]="false">Cancelled</option>
                                </select>
                            </div>
                        </div>
                        <div style="margin-top:-20px" class="d-flex justify-content-lg-end xsMarTop">
                            <div class="d-flex form-group mb-0 justify-content-center lessThan1468">
                                <button type="button" (click)="onApplyFilters()" class="btn btn-sm btn-primary rounded-pill">
                                    <i class="mdi mdi-magnify mr-1"></i>Search
                                </button>
                                <button type="button" (click)="onResetFilters()" class="btn btn-sm reset-btn rounded-pill ml-1">
                                    <i class="mdi mdi-backup-restore mr-1"></i>Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="" *ngIf="loadingAdmissions">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading Admissions ...</span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!loadingAdmissions && (!admissions || !admissions.length)">
            <div class="col-12 mh-300">
                <no-data [applied]="filters.applied" [title]="'Admissions'"></no-data>
            </div>
        </div>
        <div>
            <div class="col-12 p-0" id="admission_print">
                <div class="card">
                    <div class="card-body p-0">
                        <div class="appointmentscroll  pb-5" *ngIf="!loadingAdmissions && admissions.length">
                            <table class="table table-centered table-sm mb-0">
                                <thead class="stickOntop">
                                    <tr>
                                        <th *ngIf="isPatientFilters">Patient</th>
                                        <th *ngIf="isPatientFilters">Details</th>
                                        <th>Admission No</th>
                                        <th>Admission Date</th>
                                        <th>Doctor Unit</th>
                                        <th>Doctor</th>
                                        <th>Pay Type</th>
                                        <th>Attendant</th>
                                        <th placement="left" ngbTooltip="{{filters.options.isDischarged === false?'Not discharged Patients':filters.options.isDischarged === true?'Discharged Patients':'All InPatients'}}">
                                            Discharge
                                        </th>
                                        <th>Payment</th>
                                        <th class="noprint-column text-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let item of admissions; let i = index">
                                        <tr [ngClass]="{'table-white' :  i % 2 === 0, 'table-active' : i % 2 !== 0}">
                                            <td *ngIf="isPatientFilters">
                                                <div class="media rowHover" style="cursor: pointer" (click)="onViewPatientProfile(item.encryptedPatientId)">
                                                    <div class="avatar-sm mr-2 patient-details-block">
                                                        <span *ngIf="!item.patientThumbnailUrl" class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="i">
                                                            <span class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="item.patientName | initials"></span>
                                                            <span style="margin-left: 25px" *ngIf="item.isOnline" class="badge-active"></span>
                                                        </span>
                                                        <img *ngIf="item.patientThumbnailUrl" [src]="item.patientThumbnailUrl" [alt]="item.patientName" class="img-fluid rounded-circle">
                                                        <span style="margin-left: -10px" *ngIf="item.patientThumbnailUrl && item.isOnline" class="badge-active"></span>
                                                        <span *ngIf="item.hwcName" class="vip-badge">
                                                            <i class="mdi mdi-crown mdi-rotate-315 mdi-24px" placement="right" [ngbTooltip]="item.hwcName"></i>
                                                        </span>
                                                    </div>
                                                    <div class="media-body">
                                                        <h5 [ngClass]="{'text-line-through' : !item.active}" class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="item.patientName"></h5>
                                                        <span class="d-block font-13">
                                                            <span [textContent]="item.patientGender | gender"></span>
                                                            <span class="content-breaker" [textContent]="item.patientAge + ' yrs'"></span>
                                                            <span class="fe-flag" *ngIf="item.isConvertedFromOPtoIp"></span>
                                                        </span>
                                                        <span *ngIf="item.patientPriorityId">
                                                            <i [class]="'mdi mr-1 mdi-' + item.priorityIcon + ' ' + item.priorityColor"></i><span [textContent]="item.patientPriorityName"></span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td *ngIf="isPatientFilters">
                                                <div class="media">
                                                    <div class="media-body">
                                                        <h5 [ngClass]="{'text-line-through' : !item.active}" class="mb-0 mt-0 font-weight-normal" [textContent]="item.patientMobile || '--'"></h5>
                                                        <span class="d-block font-12">
                                                            <span [textContent]="item.umrNo || '--'"></span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="media">
                                                    <div class="media-body">
                                                        <h5 [ngClass]="{'text-line-through' : !item.active}" class="mb-0 mt-0 font-weight-normal" [textContent]="'#' + item.admissionNo"></h5>
                                                        <span class="d-block font-13">
                                                            <!--<span [textContent]="item.patientType ==='I'?'In Patient ':'Casuality '"></span>-->
                                                            <span *ngIf="item.admissionNotes">
                                                                <i class="mdi mdi-information text-primary mr-1" placement="right" [ngbTooltip]="item.admissionNotes"></i>
                                                            </span>
                                                            <span *ngIf="item.locationName">
                                                                <i class="fe-map-pin mr-1"></i>
                                                                <span [textContent]="item.locationName"></span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td [ngClass]="{'text-line-through' : !item.active}">
                                                <span class="small" [textContent]="item.admissionDate | date:'dd-MM-yyyy, hh:mm a'"></span>
                                            </td>
                                            <td [ngClass]="{'text-line-through' : !item.active}" [textContent]="item.unitName">
                                                <!--<span class="small"
                                                [textContent]="item.unitName"></span>-->
                                            </td>
                                            <td>
                                                <div class="media">
                                                    <div class="avatar-sm mr-2">
                                                        <!--<avatar-img *ngIf="item.providerThumbnailUrl" [src]="item.providerThumbnailUrl" [alt]="item.providerName" cssClass="img-fluid rounded-circle"></avatar-img>-->
                                                        <!--<avatar-img *ngIf="item.providerThumbnailUrl" [src]="item.newproviderThumbnailUrl" [alt]="item.providerName" cssClass="img-fluid rounded-circle"></avatar-img>
                                                                        <span *ngIf="!item.providerThumbnailUrl" class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="i" [textContent]="item.providerName | initials"></span>-->
                                                        <span class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="i" [textContent]="item.providerName | initials"></span>
                                                    </div>
                                                    <div class="media-body">
                                                        <h5 [ngClass]="{'text-line-through' : !item.active}" class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="item.providerName | titlecase"></h5>
                                                        <span class="d-block font-13">
                                                            <span [textContent]="item.departmentName | titlecase"></span>
                                                        </span>
                                                        <span class="small">
                                                            <span [textContent]="item.specializationName || '---' | titlecase"></span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td [textContent]="item.payType"></td>
                                            <td>
                                                <div class="media" *ngIf="item.attendantName">
                                                    <div class="avatar-sm mr-2">
                                                        <span class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="i" [textContent]="item.attendantName | initials"></span>
                                                    </div>
                                                    <div class="media-body">
                                                        <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.attendantName | titlecase"></h5>
                                                        <span class="d-block font-12">
                                                            <span [textContent]="item.attendantContactNo || '--'"></span><br />
                                                            <span class="badge badge-soft-secondary" [textContent]="item.attendantRelationWithPatient"></span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <span *ngIf="!item.attendantName">---</span>
                                            </td>
                                            <td [ngClass]="{'text-line-through' : !item.active}">
                                                <span *ngIf="item.isDischarged">
                                                    <i class="mdi mdi-information text-primary mr-1" placement="left" [ngbTooltip]="item.dischargeStatus"></i>Yes
                                                </span>
                                                <div *ngIf="!item.isDischarged">
                                                    <ng-template #expectedDischargeContent>
                                                        <div class="d-flex align-items-center justify-content-between flex-column" *ngIf="item.expectedDischargeDate && !item.editExpectedDischargeDate">
                                                            <span class="d-flex align-items-center justify-content-between">
                                                                <span class="font-weight-bold" [textContent]="item.expectedDischargeDate | date: 'MMMM d, y'"></span>
                                                                <i (click)="editExpectedDischargeDate(item)" class="mdi mdi-pencil mdi-18px text-primary ml-2"></i>
                                                            </span>

                                                            <span class="d-block align-items-center">
                                                                <a href="javascript:;" class="text-dark">
                                                                    <span [textContent]="item.dischargedByName | titlecase"></span>
                                                                    <span [textContent]="'(' + item.dischargedByRole + ')' | titlecase"></span>
                                                                </a>
                                                            </span>
                                                        </div>
                                                        <div class="d-flex align-items-center column-direction" *ngIf="!item.expectedDischargeDate || item.editExpectedDischargeDate">
                                                            <div class="form-group">
                                                                <input class="form-control" [value]="item.expectedDischargeDateView" (change)="onExpectedDischargeDateChange($event, item)" type="date" [min]="minDatetoday" />
                                                            </div>
                                                            <div>
                                                                <button (click)="updateExpectedDischargeDate(item)" class="btn btn-sm btn-primary">
                                                                    <ng-container *ngIf="item.isUpdatingDischargeDate">
                                                                        <span class="spinner-grow spinner-grow-sm"></span>
                                                                        Please wait ...
                                                                    </ng-container>
                                                                    <ng-container *ngIf="!item.isUpdatingDischargeDate">
                                                                        <i class="mdi mdi-check mr-1"></i> Update
                                                                    </ng-container>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <span>
                                                        No
                                                        <span class="ml-1 cursor-pointer noprint-column" [ngbPopover]="expectedDischargeContent" placement="left" [autoClose]="'outside'" triggers="manual" #p="ngbPopover" (click)="openPopover(p, item)" popoverTitle="Expected Discharge Date" *ngIf="isPatientFilters">
                                                            <i *ngIf="!item.expectedDischargeDate && item.active" class="mdi mdi-pencil text-primary"></i>
                                                            <i *ngIf="item.expectedDischargeDate " class="mdi mdi-pencil-plus text-success"></i>
                                                        </span>
                                                    </span>
                                                </div>
                                                <span class="d-block font-13" *ngIf="item.isDischarged">
                                                    <span class="small" *ngIf="item.dischargeDate" [textContent]="item.dischargeDate | date: 'dd-MM-yyyy, hh:mm a'"></span>
                                                </span>
                                            </td>
                                            <td>
                                                <span *ngIf="item.isFinalBill">
                                                    <span class="badge badge-success fs-16" *ngIf="'Payment Cleared' === item.paymentStatus" [textContent]="item.paymentStatus"></span>
                                                    <span class="badge badge-danger fs-16" *ngIf="'Payment Due' === item.paymentStatus" [textContent]="item.paymentStatus"></span>
                                                    <span class="badge badge-warning fs-16" *ngIf="'Pending Refund' === item.paymentStatus" [textContent]="item.paymentStatus"></span>
                                                </span>
                                                <span class="badge badge-soft-secondary" *ngIf="item.isFinalBill === false">Bill Not Generated</span>
                                            </td>
                                            <td class="noprint-column" >
                                                <div class="d-flex align-items-center justify-content-end" *ngIf="!isPatientFilters">
                                                    <a href="javascript:;" class="action-icon text-primary ml-1" *ngIf="item.encounterName !== null" (click)="onClickAddedEncounter(item)" placement="left" [ngbTooltip]="item.encounterName"><i class="mdi mdi-file-table-outline mr-1"></i></a>
                                                    <a href="javascript:;" class="action-icon text-primary ml-1" *ngIf="item.encounterName !== null" (click)="onEncounterPrescription(item)" placement="left" [ngbTooltip]="item.encounterType+'Report'"><i class="mdi mdi-file-table-outline mr-1"></i></a>

                                                </div>
                                                <div class="d-flex align-items-center justify-content-end" *ngIf="isPatientFilters">
                                                    <span *ngIf="!item.active" class="badge badge-danger fs-16">Cancelled</span><br />
                                                    <span *ngIf="!item.active" class="badge badge-danger fs-16" [textContent]="item.cancelledDate | date:'dd-MM-yyyy, hh:mm a'"></span>
                                                    <div>
                                                        <a class="mx-1" href="javascript:;" (click)="onViewTimeLine(item)" ngbTooltip="View Timeline"><i class="mdi mdi-order-bool-ascending-variant"></i></a>
                                                    </div>
                                                    <div>
                                                        <a class="position-relative" href="javascript:;" (click)="onOpenWidget(chatBox, item)" ngbTooltip="Chat Box">
                                                            <i class="mdi mdi-message-text"></i>
                                                            <span style="left: 8px; top: -8px" class="position-absolute badge badge-danger" [textContent]="item.messageCount" *ngIf="item.messageCount"></span>
                                                        </a>
                                                    </div>
                                                    <ng-container>
                                                        <ng-container *menuButton="'adm_progress_report'">
                                                            <a *ngIf="showProgressReport" href="javascript:;" class="action-icon text-primary mr-1" [routerLink]="['/app/progress-report', item.admissionId, 'a']" placement="left" ngbTooltip="Progress Report">
                                                                <i class="mdi mdi-clipboard-text-outline"></i>
                                                            </a>
                                                        </ng-container>
                                                        <ng-container *menuButton="'adm_service_payments'">
                                                            <a *ngIf="showSeriveOrder" href="javascript:;" class="action-icon text-primary mr-1" [routerLink]="['/app/services', item.encryptedAdmissionId, 'a']" placement="left" ngbTooltip="Services & Payments">
                                                                <i class="mdi mdi-cash mdi-rotate-315"></i>
                                                            </a>
                                                        </ng-container>
                                                        <a href="javascript:;" class="action-icon text-primary mr-1" placement="left" *ngIf="item.chargeCategoryNames !=null" ngbTooltip="Requested for {{item.chargeCategoryNames}}">
                                                            <i class="mdi mdi-information"></i>
                                                        </a>
                                                        <a *menuButton="'adm_generate_bar_code'" href="javascript:;" (click)="onGenerateBarcodeView(templateGenerateBarcode, item.umrNo,item.nameLoc)" class="action-icon text-primary mr-1" placement="left" ngbTooltip="Generate Barcode">
                                                            <i class="mdi mdi-barcode"></i>
                                                        </a>
                                                    </ng-container>
                                                    <div *ngIf="!item.isCheckOut" class="dropdown show position-relative">
                                                        <a href="javascript:;" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" aria-haspopup="true" (click)="onclick(item)">
                                                            <i class="mdi mdi-dots-horizontal"></i>
                                                        </a>
                                                        <div class="dropdown-menu actions-dropdown dropdown-menu-right" x-placement="bottom-end">
                                                            <ng-container *menuButton="'adm_edit_admission'">
                                                                <a class="dropdown-item" *ngIf="isPatientFilters && !item.isDischarged" (click)="onOpenBookAdmissionModel(item)" href="javascript:;">
                                                                    <i class="mdi mdi-pencil-outline mr-1"></i>
                                                                    Edit Admission
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_cancel_admission'">
                                                                <a class="dropdown-item" *ngIf="item.active && !item.isDischarged && item.serviceOrder <= 2" (click)="onCancel(item)" href="javascript:;">
                                                                    <i *ngIf="!item.cancelling" class="mdi mdi-cancel mr-1"></i><i *ngIf="item.cancelling" class="spinner-border spinner-border-sm mr-1"></i>
                                                                    Cancel Admission
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_raise_bed_request'">
                                                                <a class="dropdown-item" *ngIf="'Payment Cleared' !== item.paymentStatus" [attr.disabled]="item.chargeCategoryNames != null" (click)="onOpenRequestBedModel(templateRaiseBedChangeRequest, item)" href="javascript:;">
                                                                    <i class="mdi mdi-bed-clock mr-1"></i>
                                                                    Raise Bed Request
                                                                </a>
                                                            </ng-container>

                                                            <a class="dropdown-item" *menuButton="'adm_pharmact_indent'" (click)="onOpenIndentModel(templateIndent, item)" href="javascript:;">
                                                                <i class="mdi mdi-pill mr-1"></i> Pharmacy Indent
                                                            </a>
                                                            <!--<a class="dropdown-item" *menuButton="'tClM2U'" (click)="onOpenInsuranceModel(insuranceAdmission, item)" href="javascript:;"><i class="mdi mdi-cash mr-1"></i> Insurance</a>-->

                                                            <a *menuButton="'adm_register'" class="dropdown-item" href="javascript:;" [routerLink]="['/app/registers', item.admissionId,item.encryptedPatientId,'a']">
                                                                <i class="mdi mdi-clipboard-text-outline mr-1"></i>Register
                                                            </a>
                                                            <ng-container *menuButton="'adm_internal_medicne'">
                                                                <a *ngIf="isShowInternalMedicine" class="dropdown-item" href="javascript:;" (click)="onInternalMedicine(item.encryptedAdmissionId)">
                                                                    <i class="mdi mdi-checkbook mr-1"></i>
                                                                    Internal Medicine
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_ob_encounter'">
                                                                <a class="dropdown-item" *ngIf="(! item.encounterName  || item.encounterName =='OB IP Encounter') || obbutton " href="javascript:;" (click)="onObEncounter(item)">
                                                                    <i class="mdi mdi-checkbook mr-1"></i>OB Encounter
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_gyn_encounter'">
                                                                <a class="dropdown-item" *ngIf="(! item.encounterName  || item.encounterName =='Gyne IP Encounter') || gynbutton " href="javascript:;" (click)="onGynEncounter(item.encryptedAdmissionId)">
                                                                    <i class="mdi mdi-checkbook mr-1"></i>Gyn Encounter
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_diet_encounter'">
                                                                <a class="dropdown-item" href="javascript:;" (click)="onDietEncounter(item.encryptedAdmissionId)">
                                                                    <i class="mdi mdi-checkbook mr-1"></i>Diet Encounter
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_baby_registration'">
                                                                <a class="dropdown-item" (click)="onRedirectBabyRegistration(item,'fromAdmission')" href="javascript:;">
                                                                    <i class="mdi mdi-baby-buggy mr-1"></i>
                                                                    Baby Registration
                                                                </a>
                                                            </ng-container>
                                                            <ng-container>
                                                                <a class="dropdown-item" *ngIf="(! item.encounterName  || item.encounterName =='IVF IP Encounter') || ivfbutton" href="javascript:;" (click)="onIVFIPEncounter(item.encryptedAdmissionId)">
                                                                    <i class="mdi mdi-checkbook mr-1"></i>IVF IP Encounter
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_neonatal_dashboard'">
                                                                <a class="dropdown-item" href="javascript:;" *ngIf="(! item.encounterName  || item.encounterName =='NeonatalIP Encounter') || neonatalbutton" (click)="onNeonatalDashboardEncounter(item.encryptedAdmissionId)">
                                                                    <i class="mdi mdi-view-dashboard mr-1"></i>Neonatal Dashboard
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_view_document'">
                                                                <a class="dropdown-item" href="javascript:;" (click)="onViewDocument(item.encryptedPatientId)">
                                                                    <i class="mdi mdi-file-document-outline mr-1"></i>View Document
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_full_transcript'">
                                                                <a class="dropdown-item" *ngIf="item.internalMedicineId" href="javascript:;">
                                                                    <i class="mdi mdi-script-text-outline mr-1"></i>
                                                                    Full Transcript
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_prescription'">
                                                                <a class="dropdown-item" *ngIf="item.internalMedicineId" href="javascript:;">
                                                                    <i class="mdi mdi-file-table-outline mr-1"></i>
                                                                    Prescription
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_raise_doctor_unit'">
                                                                <a class="dropdown-item" *ngIf="'Payment Cleared' !== item.paymentStatus" (click)="onOpenDoctorUnitRequest(templateDoctorUnitChangeRequest, item)" href="javascript:;">
                                                                    <i class="mdi mdi-doctor mr-1"></i>
                                                                    Raise Doctor Unit Request
                                                                </a>
                                                            </ng-container>


                                                            <ng-container *menuButton="'adm_birth_certificate'">
                                                                <a class="dropdown-item" (click)="onRedirectBirthCertificate(item,'fromAdmission')" href="javascript:;">
                                                                    <i class="mdi mdi-certificate-outline mr-1"></i>
                                                                    Birth Certificate
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_death_certificate'">
                                                                <a class="dropdown-item" (click)="onRedirectDeathCertificate(item,'fromAdmission')" href="javascript:;">
                                                                    <i class="mdi mdi-certificate-outline mr-1"></i>
                                                                    Death Certificate
                                                                </a>
                                                            </ng-container>

                                                            <ng-container *menuButton="'adm_attendent_view'">
                                                                <a class="dropdown-item" (click)="onOpenAttendentView(templateAttendentview,item)" href="javascript:;">
                                                                    <i class="mdi mdi-account-child mr-1"></i>Attendant View
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_consent_form'">
                                                                <a class="dropdown-item" (click)="onOpenConsentReport(templateConsentReport,item)" href="javascript:;">
                                                                    <i class="mdi mdi-note-multiple-outline mr-1"></i>Consent Form
                                                                </a>
                                                            </ng-container>
                                                            <ng-container>
                                                                <a *menuButton="'adm_stickers_view'" class="dropdown-item" (click)="onOpenSticker(templateViewStickers ,item)" href="javascript:;">
                                                                    <i class="mdi  mdi-apple-safari mr-1"></i>Sticker
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_research_consent_form'">
                                                                <a class="dropdown-item" (click)="onOpenResearchConsentReport(reasearchConsentForm,item)" href="javascript:;">
                                                                    <i class="mdi mdi-note-multiple-outline mr-1"></i>Research Consent Form
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_ip_patient_details_form'">
                                                                <a class="dropdown-item" (click)="onOpenPatientDetailForm(templateViewPatientDetailsForm,item)" href="javascript:;">
                                                                    <i class="mdi mdi-note-multiple-outline mr-1"></i> IP - Patient Details Form
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_change_case_type'">
                                                                <a class="dropdown-item" (click)="onChangeCaseType(templateChangeCaseTypeForm,item)" href="javascript:;">
                                                                    <i class="mdi mdi-note-multiple-outline mr-1"></i>Change Case Type
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_edit_insurance'">
                                                                <a class="dropdown-item" *ngIf="item.payType == 'I'" (click)="onEditInsurance(templateEditInsuranceForm,item)" href="javascript:;">
                                                                    <i class="mdi mdi-pencil-outline mr-1"></i>Edit Insurance
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_gate_pass'">
                                                                <a class="dropdown-item" *ngIf="item.isDischargeSummary" (click)="onGatepass(item.encryptedAdmissionId)" href="javascript:;">

                                                                    <i class="mdi mdi-checkbook mr-1"></i>Gate Pass
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_convert_to_cash'">
                                                                <a class="dropdown-item" *ngIf="item.payType == 'I' && !item.isFinalBill" (click)="onConvertToCash(item)" href="javascript:;">
                                                                    <i class="mdi mdi-pencil-outline mr-1"></i>Convert to Cash
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_payment_consent_form'">
                                                                <a class="dropdown-item" *ngIf="item.payType == 'I'" (click)="onPaymentConsentForm(templatePayaTypeConsentFrom)" href="javascript:;">
                                                                    <i class="mdi mdi-pencil-outline mr-1"></i>Payment Consent Form
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *menuButton="'adm_cashless_form'">
                                                                <a class="dropdown-item" (click)="CashLessUndertakingForm(templateCashLessForm)" href="javascript:;">
                                                                    <i class="mdi mdi-pencil-outline mr-1"></i>Cash Less Form
                                                                </a>
                                                            </ng-container>
                                                            <ng-container>
                                                                <a *menuButton="'admission_consent_from'" class="dropdown-item" href="javascript:;" [routerLink]="['/app/consent-forms', item.admissionId,item.encryptedPatientId,'a']">
                                                                    <i class="mdi mdi-clipboard-text-outline mr-1"></i>Consent Forms
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *ngIf="item.isGatePassGenerated">
                                                                <a *menuButton="'adm_check_out'" class="dropdown-item" (click)="onCheckOut(item)" href="javascript:;">
                                                                    <i class="mdi mdi-run"></i>Check Out
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *ngIf="item">
                                                                <a *menuButton="'adult_admission_from'" class="dropdown-item" (click)="onOpenPatientDetails(templateAdmissionForm, item?.encryptedPatientId)" href="javascript:;">
                                                                    <i class="mdi mdi-pencil-outline mr-1"></i>
                                                                    <span *ngIf="item.patientName.includes('Baby of'); else regularAdmission">Neonatal Admission Form</span>
                                                                    <ng-template #regularAdmission>Admission Form</ng-template>
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *ngIf="item">
                                                                <a *menuButton="'admission_receipt'" class="dropdown-item" (click)="onOpenReceiptModel(item)" href="javascript:;">
                                                                    <i class="mdi mdi-book-outline mr-1"></i>Receipt
                                                                </a>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="item.active" [ngClass]="{'table-white' :  i % 2 === 0, 'table-active' : i % 2 !== 0}" class="noprint-column">
                                            <td colspan="10" class="p-0 border-top-none">
                                                <div class="report-banner">
                                                    <div *ngIf="item.currentRoomId && item.bedNumber!==item.currentBedNumber && !item.isDischarged && item.active" class="d-flex justify-content-center align-items-center mr-2 border alert alert-danger">
                                                        <span class="text-primary font-weight-bold text-small mr-1" [textContent]="item.currentBedNumber"></span>
                                                        <span class="text-small mr-2">
                                                            <i class="mdi mdi-chevron-double-right mr-1"></i><span [textContent]="item.currentRoomName" class="mr-1"></span>
                                                            <i class="mdi mdi-chevron-double-right mr-1"></i><span [textContent]="item.currentWardName"></span>
                                                            <i class="mdi mdi-chevron-double-right mr-1"></i><span [textContent]="item.currentFloorName"></span>
                                                        </span>
                                                        <!--keep this comment once testing will say new functionality is then i will remove other wise undo this-->
                                                        <!--<span class="text-small mr-2">
                                                            <i class="mdi mdi-chevron-double-right mr-1"></i>Current Room:
                                                            <span [textContent]="(item.currentRoomName | titlecase) || '---'"></span>
                                                        </span>-->
                                                    </div>
                                                    <div class="d-flex justify-content-center align-items-center mr-2 border alert alert-info" *ngIf="item.bedNumber">
                                                        <span class="text-primary font-weight-bold text-small mr-1" [textContent]="item.bedNumber"></span>
                                                        <span class="text-small mr-2">
                                                            <i class="mdi mdi-chevron-double-right mr-1"></i><span [textContent]="item.roomName" class="mr-1"></span>
                                                            <i class="mdi mdi-chevron-double-right mr-1"></i><span [textContent]="item.wardName" class="mr-1"></span>
                                                            <i class="mdi mdi-chevron-double-right mr-1"></i><span [textContent]="item.floorName" class="mr-1"></span>
                                                            <i class="mdi mdi-chevron-double-right mr-1"></i><span [textContent]="item.chargeCategoryName"></span>
                                                        </span>
                                                    </div>
                                                    <div class="d-flex align-items-center">
                                                        <ng-container *ngIf="item.medicationInfo && item.medicationInfo.medications">
                                                            <ng-container *ngIf="item.medicationInfo.medications.missed">
                                                                <div class="alert alert-danger flex-center mr-2">
                                                                    <span class="badge badge-danger">Missed</span>
                                                                    <span class="ml-1 mr-1" [textContent]="item.medicationInfo.medications.missed.count"></span>
                                                                    <span>
                                                                        Medicine<span *ngIf="item.medicationInfo.medications.missed.count > 1">s</span>
                                                                    </span>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="item.medicationInfo.medications.pending">
                                                                <div class="alert alert-warning flex-center mr-2">
                                                                    <span class="badge badge-warning">Pending</span>
                                                                    <span class="ml-1 mr-1" [textContent]="item.medicationInfo.medications.pending.count"></span>
                                                                    <span>
                                                                        Medicine<span *ngIf="item.medicationInfo.medications.pending.count > 1">s</span>
                                                                    </span>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="item.medicationInfo.medications.next">
                                                                <div class="alert alert-info flex-center mr-2">
                                                                    <span class="badge badge-info">Next</span>
                                                                    <span class="ml-1 mr-1" [textContent]="item.medicationInfo.medications.next.count"></span>
                                                                    <span>
                                                                        Medicine<span *ngIf="item.medicationInfo.medications.next.count > 1">s</span>
                                                                    </span>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container *ngIf="item.medicationInfo && item.medicationInfo.labs">
                                                            <div class="alert alert-primary mr-2">
                                                                <i class="mdi mdi-flask-outline"></i>
                                                                <span class="ml-1 mr-1" [textContent]="item.medicationInfo.labs.count"></span>
                                                                <span>
                                                                    Lab<span *ngIf="item.medicationInfo.labs.count > 1">s</span>
                                                                </span>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="item.medicationInfo && item.medicationInfo.notes">
                                                            <div class="alert alert-blue mr-2">
                                                                <i class="mdi mdi-calendar-text-outline"></i>
                                                                <span class="ml-1 mr-1" [textContent]="item.medicationInfo.notes.count"></span>
                                                                <span>
                                                                    Note<span *ngIf="item.medicationInfo.notes.count > 1">s</span>
                                                                </span>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                                <tbody *ngIf="pagination.totalPages > 1">
                                    <tr>
                                        <td colspan="10">
                                            <nav class="d-flex align-items-center justify-content-between p-0">
                                                <p class="mb-0 font-13">
                                                    <span class="text-dark">
                                                        Page
                                                        <span [textContent]="pagination.currentPage"></span>
                                                        of
                                                        <span [textContent]="pagination.totalPages"></span>
                                                    </span>
                                                    <span class="ml-1">
                                                        <span>
                                                            (Showing
                                                            <span [textContent]="pagination.currentItems - admissions.length + 1"></span>
                                                            -
                                                            <span [textContent]="pagination.currentItems"></span>
                                                            of
                                                            <span [textContent]="pagination.totalItems"></span>
                                                            admissions)
                                                        </span>
                                                    </span>
                                                </p>
                                                <ngb-pagination class="pagination justify-content-end" [maxSize]="5" [rotate]="true" [ellipses]="true" [(page)]="pagination.pageIndex" [pageSize]="pagination.pageSize" (pageChange)="onNextPage()" [collectionSize]="pagination.totalItems">
                                                    <ng-template ngbPaginationPrevious>
                                                        <i class="fe-arrow-left"></i>
                                                    </ng-template>
                                                    <ng-template ngbPaginationNext>
                                                        <i class="fe-arrow-right"></i>
                                                    </ng-template>
                                                </ngb-pagination>
                                            </nav>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="right-bar" *ngIf="isPatientFilters">
    <div data-simplebar class="h-100">
        <div class="filters-header">
            <h4>Filter Options</h4>
            <a href="javascript:;" (click)="onCloseFilters()"><i class="fe-x"></i></a>
        </div>
        <div class="filters-body">
            <div class="form-group" *ngIf="isPatientFilters">
                <label class="mb-1 font-13">Discharge Type</label>
                <select class="form-control custom-select form-control-sm" (keyup.enter)="onApplyFilters()" [(ngModel)]="filters.options.isCheckOut">
                    <option [ngValue]="null" selected>All</option>
                    <option [ngValue]="true">Discharged</option>
                    <option [ngValue]="false">Not Discharged</option>
                </select>
            </div>
            <div class="form-group position-relative" *ngIf="filters.options.isDischarged">
                <label class="mb-1 font-13">Discharge Date</label>
                <input class="form-control date-picker form-control-sm" type="text" block placeholder="Select discharge date" [(ngModel)]="filters.options.dischargeDate" readonly autocomplete="nope" ngbDatepicker #dischargeDate="ngbDatepicker" (click)="dischargeDate.toggle(); $event.stopPropagation();" />
            </div>
            <div class="form-group">
                <label class="mb-1 font-13">Active Status</label>
                <select class="form-control custom-select form-control-sm" (keyup.enter)="onApplyFilters()" [(ngModel)]="filters.options.active">
                    <option [ngValue]="true">Active</option>
                    <option [ngValue]="false">Cancelled</option>
                </select>
            </div>
            <div class="form-group">
                <label class="mb-1">Charge Category</label>
                <ng-select class="ng-select-sm" [items]="chargeCategory" [loading]="loadingChargeCategory" [trackByFn]="trackBy.resource" bindLabel="name" bindValue="id" [typeahead]="patientsInput" typeToSearchText="Search Charge Category" placeholder="Enter Charge Category" [(ngModel)]="filters.options.chargeCategoryId" (change)="fetchRoomsBasedOnLocation()">
                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                        No Charge Category found<span *ngIf="searchTerm"> for '{{searchTerm}}' </span>.
                    </ng-template>
                </ng-select>
            </div>
            <div class="form-group mb-0">
                <label class="mb-1">Room</label>
                <ng-select class="ng-select-sm" [items]="rooms" [loading]="loadingRooms" [trackByFn]="trackBy.resource" bindLabel="roomName" bindValue="roomId" [typeahead]="patientsInput" typeToSearchText="Search Room" placeholder="Enter Room name" [(ngModel)]="filters.options.roomId">
                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                        No Rooms found<span *ngIf="searchTerm"> for '{{searchTerm}}' </span>.
                    </ng-template>
                </ng-select>
            </div>
            <div class="form-group mb-0 mt-1">
                <label class="mb-1">Admission Visit Type </label>
                <select class="form-control custom-select form-control" [(ngModel)]="filters.options.admissionVisitTypeId">
                    <option [ngValue]="null" disabled>Select</option>
                    <option *ngIf="loadingAdmissionTypes">Loading...</option>
                    <option *ngFor="let item of admissionTypes;" [textContent]="item.value" [ngValue]="item.id"></option>
                </select>
            </div>
            <div class="form-group mb-0 mt-1">
                <label class="mb-1">Admission Type </label>
                <select class="form-control custom-select form-control" [(ngModel)]="filters.options.admissionTypeId">
                    <option [ngValue]="null" disabled *ngIf="!loadingAdmissionVisitTypes">Select</option>
                    <option *ngIf="loadingAdmissionTypes">Loading...</option>
                    <option *ngFor="let item of admissionVisitTypes;" [textContent]="item.value" [ngValue]="item.id"></option>
                </select>
            </div>
            <div class="form-group mb-0 mt-1">
                <label class="mb-1">Department</label>
                <ng-select [items]="departments" [loading]="loadingDepartments" [trackByFn]="trackBy.resource" [clearable]="false" bindLabel="value" bindValue="id" [(ngModel)]="filters.options.departmentId" [typeahead]="departmentsInput" placeholder="Select Department">
                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                        No departments found<span *ngIf="searchTerm"> for '{{searchTerm}}' </span>.
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-i="index">
                        <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                        <span class="text-muted d-block font-13" [textContent]="item.optionalText"></span>
                    </ng-template>
                </ng-select>
            </div>
            <div class="form-group mb-0 mt-1">
                <label class="mb-1">Pay Type </label>
                <select class="form-control custom-select form-control" [(ngModel)]="filters.options.payType">
                    <option [ngValue]="null" disabled>Select</option>
                    <option *ngIf="loadingAdmissionTypes">Loading...</option>
                    <option value=C>C</option>
                    <option value=I>I</option>
                </select>
            </div>



        </div>
        <div class="filters-footer">
            <button type="button" (click)="onResetFilters()" class="btn btn-sm btn-light">Reset</button>
            <button type="button" (click)="onApplyFilters()" class="btn btn-sm btn-primary ml-1">
                Search
            </button>
        </div>
    </div>
</div>

<ng-template #templatePatientAppointment>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-receipt mr-1"></i>View Future Appointment</h4>
        <div>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">
                <i class="fe-x"></i>
            </button>
            <!--<button [useExistingCss]="true" printSectionId="receipt-section" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none"><i class="fe-printer mr-1"></i>Print</button>-->
        </div>
    </div>
    <div class="card-body p-0">
        <div class="overflow-auto">
            <table class="table table-centered table-sm table-striped table-bordered mb-0">
                <tr style="color: #000">
                    <th></th>
                    <th>Appointment No</th>
                    <th>Appointment Date</th>
                    <th>Patient</th>
                    <th>Doctor Name</th>
                    <th class="text-right">Actions</th>
                </tr>
                <tbody *ngFor="let item of patientFutureAppointments; index as i;">
                    <tr>
                        <td>#{{i+1}}</td>
                        <td [textContent]="item.appointmentNo"></td>
                        <td>
                            <span class="mb-0" [textContent]="item.appointmentDate |  date:'dd-MM-yyyy'"></span>
                            <span class="mb-0" [textContent]="' ' + item.appointmentTimeString"></span>
                        </td>
                        <td><span class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="item.patientName"></span></td>
                        <td>
                            <span class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="item.providerName"></span>
                        </td>
                        <td>
                            <a href="javascript:;" class="action-icon text-primary ml-1" (click)="navigateToReschedule(item.appointmentId,'fromAdmissionReschedule')" placement="left" ngbTooltip="Reschedule Appointment">
                                <i class="mdi mdi-calendar-refresh"></i>
                            </a>
                            <a href="javascript:;" class="action-icon text-danger ml-1" (click)="navigateToAdmissions(item.appointmentId,'fromAdmissionCancel')" placement="left" ngbTooltip="Cancel Appointment">
                                <i class="mdi mdi-cancel"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>

<ng-template #templateUploadDocument>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-file-multiple mr-1"></i>Upload Documents</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseDocumentModel();">
            ×
        </button>
    </div>
    <div class="modal-body">
        <file-dropper [expandable]="true" [maxFiles]="maxFiles" [maxFileSize]="10000" [accept]="'application/pdf, image/jpeg, image/pjpeg, image/png, image/gif, video/mp4'" (onSelectEmitter)="onFileSelect($event)">
        </file-dropper>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-sm" (click)="onCloseDocumentModel();">
            Cancel
        </button>
        <button type="button" (click)="onCloseDocumentModel()" class="btn btn-primary btn-sm">
            Upload
        </button>
    </div>
</ng-template>

<ng-template #templateIndent>
    <pharmacy-indent [admission]="selectedAdmission" (closeModal)="onCloseModal();"></pharmacy-indent>
</ng-template>

<ng-template #insuranceAdmission>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-cash-lock-open mr-1"></i>Insurance Details</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">
            ×
        </button>
    </div>
    <div class="modal-body">
        <admission-insurance [admissionId]="selectedAdmission.admissionId" [isFinalBillExist]="selectedAdmission.isFinalBill" (onClose)="onCloseModal()"></admission-insurance>
    </div>
</ng-template>

<ng-template #patientInsurances>
    <form [formGroup]="patientForm">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-cash-lock-open mr-1"></i>Add Insurances</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" href="javascript:;" (click)="onCloseDocumentModel();">
                ×
            </button>
        </div>
        <div class="modal-body">
            <div class="text-right">
                <a href="javascript:;" *ngIf="patientInsuranceArray.length " class="font-14" (click)="onAddPatientInsurance()"><i class="fe-plus mr-1"></i>Add</a>
            </div>
            <div class="row" *ngFor="let insurance of patientInsuranceArray.controls; let i = index" [formGroup]="insurance">
                <div class="d-flex">
                    <div class="d-flex col-11 flex-wrap">
                        <div class="col-lg-3 col-sm-6 col-11">
                            <div class="form-group mb-0">
                                <label class="mb-1">Insurance Type <code>*</code></label>
                                <select disabled class="form-control custom-select" formControlName="insuranceTypeId" [ngClass]="{ 'is-invalid': submitted && insurance.controls['insuranceTypeId'].errors }">
                                    <option [ngValue]="1">Primary</option>
                                    <option [ngValue]="2">Secondary</option>
                                    <option [ngValue]="3">Teritory</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-11">
                            <div class="form-group mb-0">
                                <label class="mb-1">Insurance Company <code>*</code></label>
                                <select (ngModelChange)="onChange($event,i)" class="form-control custom-select" formControlName="insuranceCompanyId" [ngClass]="{ 'is-invalid': submitted && insurance.controls['insuranceCompanyId'].errors }">
                                    <option [ngValue]="null" *ngIf="!loadingInsurances">
                                        Select insurance company
                                    </option>
                                    <option selected *ngIf="loadingInsurances">Loading...</option>
                                    <option *ngFor="let item of insurances" [textContent]="item.value" [ngValue]="item.id"></option>
                                </select>
                                <span style="color: red" *ngIf="message && formArrayIndex==i">{{message}}</span>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-11">
                            <div class="form-group mb-0">
                                <label class="mb-1">Card Number <code>*</code></label>
                                <input type="text" class="form-control" formControlName="cardNo" maxlength="20" numbersOnly block autocomplete="nope" placeholder="Enter card number" [ngClass]="{ 'is-invalid': submitted && insurance.controls['cardNo'].errors }" />
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-11">
                            <div class="form-group position-relative mb-0">
                                <label class="mb-1">Validity <code>*</code></label>
                                <input class="form-control date-picker" placement="top" type="text" [ngClass]="{ 'is-invalid': submitted && insurance.controls['validity'].errors }" placeholder="Select" formControlName="validity" readonly autocomplete="nope" ngbDatepicker #validity="ngbDatepicker" [minDate]="today" (click)="validity.toggle(); $event.stopPropagation();" (ngModelChange)="onOpeninsurance()" />
                            </div>
                        </div>
                    </div>
                    <div style="padding-top: 20px">
                        <div class="form-group mb-0">
                            <a href="javaScript:;" class="text-danger" (click)="onRemovepatientInsuranceArray(i)" placement="left" ngbTooltip="Remove">
                                <i class="fe-x"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="d-flex align-items-center justify-content-end">
                <button type="submit" [disabled]="submitting" class="btn btn-primary ml-1">
                    <span *ngIf="submitting">
                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                        Please wait..
                    </span>
                    <span *ngIf="!submitting">Submit</span>
                </button>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #templateRaiseBedChangeRequest>
    <form [formGroup]="raiseBedChangeRequestForm" (ngSubmit)="onSubmitDoctorUnitChangeRequest(false)">
        <div class="modal-header d-flex justify-content-between">
            <div>
                <h4 class="modal-title">Raise Bed Change Request</h4>
            </div>
            <div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">
                    ×
                </button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <span class="badge badge-outline-primary" *ngIf="selectedAdmission && selectedAdmission.chargeCategoryName != null">
                        <i class="mdi mdi-information mr-1 text-primary"></i> Patient Already in
                        <b> {{selectedAdmission.chargeCategoryName}} </b>
                    </span>
                    <div class="form-group">
                        <label class="mb-1">Charge Category <code>*</code></label>
                        <ng-select class="ng-select-sm text-uppercase" [items]="chargeCategories" bindLabel="name" bindValue="id" autocomplete="nope" placeholder="Select charge categories" [ngClass]="{ 'is-invalid': submitted && forms.bedRequestChange.chargeCategories.errors }" [multiple]="true" formControlName="chargeCategories">
                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                <div class="ng-option disabled">
                                    No charge Categories found for '{{searchTerm}}'
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="form-group">
                        <label class="mb-1">Comments <code>*</code></label>
                        <div class="form-group">
                            <textarea class="form-control" formControlName="requestComments" maxlength="250" block autocomplete="nope" placeholder="Enter Comments" [ngClass]="{ 'is-invalid': submitted && forms.bedRequestChange.requestComments.errors }"></textarea>
                        </div>
                        <div class="invalid-feedback show-must" *ngIf="submitted && forms.bedRequestChange.requestComments.errors">
                            <span *ngIf="forms.bedRequestChange.requestComments.errors.required">This field is required.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-light" (click)="onCloseModal()">
                <i class="mdi mdi-close mr-1"></i>Close
            </button>
            <button type="submit" class="btn btn-sm btn-primary" [disabled]="submitting">
                <span *ngIf="!submitting"> <i class="mdi mdi-check mr-1"></i>Submit </span>
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #templateDoctorUnitChangeRequest>
    <form [formGroup]="raiseDoctorUnitRequestForm" (ngSubmit)="onSubmitDoctorUnitChangeRequest(true)">
        <div class="modal-header d-flex justify-content-between">
            <div>
                <h4 class="modal-title">Raise Doctor Unit Change Request</h4>
            </div>
            <div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">
                    ×
                </button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <span class="badge badge-outline-primary mb-2" *ngIf="selectedAdmission && selectedAdmission.unitName != null">
                        <i class="mdi mdi-information mr-1 text-primary"></i> Having Doctor Unit of
                        <b> {{selectedAdmission.unitName}} </b>
                    </span>
                    <div class="form-group mt-2">
                        <label class="mb-1">Doctor Units <code>*</code></label>
                        <ng-select class="ng-select-sm" [items]="DoctorUnits" [loading]="loadingDoctorUnits" [trackByFn]="trackBy.doctorUnits" bindLabel="unitName" bindValue="doctorUnitMasterId" [typeahead]="patientsInput" typeToSearchText="Search doctorUnits" placeholder="Enter Doctor Unit Name" formControlName="doctorUnitMasterId" (change)="onChangeDoctorUnit($event)" [ngClass]="{ 'is-invalid': submitted && forms.doctorUnitChange.doctorUnitMasterId.errors }">
                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                No Doctor Units found<span *ngIf="searchTerm"> for '{{searchTerm}}' </span>.
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-i="index">
                                <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.unitName"></h5>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item" let-i="index">
                                <div class="media">
                                    <div class="media-body">
                                        <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.unitName"></h5>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="row" *ngIf="raiseDoctorUnitRequestForm.value['doctorUnitMasterId'] != null && selectedDoctorUnit != null && selectedDoctorUnit!= undefined">
                        <div class="col-12">
                            <div class="overflow-auto">
                                <table class="table mt-2 mb-0 table-bordered table-sm">
                                    <thead>
                                        <tr>
                                            <th>S.no</th>
                                            <th>Doctor Name</th>
                                            <th>Designation</th>
                                            <th>Specialization</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let doctor of selectedDoctorUnit.tagDoctor;index as j;">
                                            <td>
                                                <h6 class="mb-0 mt-0" [textContent]="j+1"></h6>
                                            </td>
                                            <td>
                                                <h6 class="mb-0 mt-0 text-uppercase" [textContent]="doctor.providerName || '--'"></h6>
                                            </td>
                                            <td>
                                                <h6 class="mb-0 mt-0" [textContent]="doctor.designation || '--'"></h6>
                                            </td>
                                            <td *ngIf="j === 0">
                                                <select class="form-control custom-select form-control" formControlName="specializationIds" [ngClass]="{ 'is-invalid': (submitted && forms.doctorUnitChange.specializationIds.errors) }" placeholder="Select Specialization">
                                                    <option [ngValue]="null" disabled *ngIf="!loadSeniorSpecialization">Select</option>
                                                    <option *ngIf="loadSeniorSpecialization">Loading...</option>
                                                    <option *ngFor="let item of seniorDoctorSpecializations;" [textContent]="item.value" [ngValue]="item.id"></option>
                                                </select>

                                            </td>
                                            <td *ngIf="j !== 0">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="invalid-feedback show-must" *ngIf="submitted && forms.doctorUnitChange.doctorUnitMasterId.errors">
                        <span *ngIf="forms.doctorUnitChange.doctorUnitMasterId.errors.required">This field is required.</span>
                    </div>
                    <div class="form-group mt-2">
                        <label class="mb-1">Comments<code>*</code></label>
                        <div class="form-group">
                            <textarea class="form-control" formControlName="requestComments" maxlength="250" block autocomplete="nope" placeholder="Enter Comments" [ngClass]="{ 'is-invalid': submitted && forms.doctorUnitChange.requestComments.errors }"></textarea>
                        </div>
                        <div class="invalid-feedback show-must" *ngIf="submitted && forms.doctorUnitChange.requestComments.errors">
                            <span *ngIf="forms.doctorUnitChange.requestComments.errors.required">This field is required.</span>
                            <span *ngIf="forms.doctorUnitChange.requestComments.errors.whiteSpaces">Please remove whitespaces.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-light" (click)="onCloseModal()">
                <i class="mdi mdi-close mr-1"></i>Close
            </button>
            <button type="submit" class="btn btn-sm btn-primary" [disabled]="submitting">
                <span *ngIf="!submitting"> <i class="mdi mdi-check mr-1"></i>Submit </span>
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #templateGenerateBarcode>
    <div class="modal-header d-flex justify-content-between">
        <div>
            <h4 class="modal-title">Barcode</h4>
        </div>
        <div>
            <button [useExistingCss]="true" printSectionId="invoiceId" ngxPrint class="btn btn-xs d-print-none font-16 text-white">
                <i class="fe-printer"></i>
            </button>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)=" removePrintCss();">
                ×
            </button>
        </div>
    </div>
    <div class="justify-content-center" id="invoiceId" >
        <table class="tstyle">
            <tbody>
                <tr class="p-0 m-0">
                    <td class="rowstyle">
                        <div class="text-truncate maxwidth150">
                            <strong>{{umrNo}}</strong>
                        </div>
                    </td>
                    <td align="right" class="text-right rowstyle">
                        <div class="text-truncate maxwidth150">
                            <strong>{{nameLoc}}</strong>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="bacrcode">
                        <ngx-barcode6 [bc-value]="umrNo" [bc-width]="2" [bc-height]="40" [bc-display-value]="false">
                        </ngx-barcode6>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    
    <div class="modal-footer">
        <button (click)="onCloseModal();" class="btn btn-danger btn-sm" type="button">Close</button>
    </div>
</ng-template>

<ng-template #templateAttendentview>
    <div class="modal-header d-flex justify-content-between">
        <div class="row justify-content-between">
            <h4 class="modal-title">Attendant View</h4>
        </div>
        <div>
            <button [useExistingCss]="true" printSectionId="AttendentId" ngxPrint class="btn btn-xs d-print-none font-16">
                <i class="fe-printer"></i>
            </button>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">
                ×
            </button>
        </div>
    </div>
    <div id="AttendentId">
        <div style="
        width: 280px;
        padding: 5px;
        border-radius: 10px;
        border: solid blue 5px;
        margin: auto;
        position: relative;
        background-color: aliceblue;
      ">
            <div style="text-align: center">
                <h3><u>Attendant Pass </u></h3>
                <span class="logo-lg">
                    <img [src]="logoBasics && logoBasics.imageUrl ? logoBasics.imageUrl : 'assets/images/fernandez.png'" [hidden]="loading" alt="Careaxes" width="150" height="150" />
                </span>
            </div>
            <br />
            <ngx-barcode6 *ngIf="selectedAdmission" [bc-value]="selectedAdmission.umrNo"
                          [bc-height]="50"
                          [bc-display-value]="false">
            </ngx-barcode6>
            <div style="
          text-align: initial;
          border: solid black 2px;
          color: black;
          background-color: white;
          border-radius: 5px;
        ">
                <span *ngIf="selectedAdmission">
                    PatientName:<span class="text-uppercase">{{selectedAdmission.patientName}}</span>
                    <br />
                    WardName:{{selectedAdmission.wardName}}<br />
                    Room Name :{{selectedAdmission.roomName}}<br />
                    AdmissionDate:{{ selectedAdmission.admissionDate |date:'dd-MM-yyyy, hh:mm a' }}<br />
                    Admission No:{{selectedAdmission.admissionNo}}
                </span>
            </div>
            <br />
            <div style="text-align: right">SIGNATURE OF THE AUTHORITY</div>
        </div>
    </div>
</ng-template>

<ng-template #templateUrgentAdmission>
    <form [formGroup]="bookAdmissionForm" (submit)="onBookAdmission()">
        <div class="modal-header d-flex justify-content-between">
            <div>
                <h4 class="modal-title"><i class="mdi mdi-exclamation-thick mr-1"></i>Urgent Admission</h4>
            </div>
            <div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseUrgentModal();">
                    ×
                </button>
            </div>
        </div>
        <div class="modal-body">
            <div *ngIf="currentUrgentStep === urgentSteps.Step1" class="d-flex justify-content-center align-items-center">
                <div class="form-group text-center">
                    <h4 class="mb-1">Select Patient <code>*</code></h4>
                    <ng-select [items]="patients" [loading]="loadingPatients" [clearable]="true" bindLabel="name" bindValue="id" [typeahead]="patientsInput" [minTermLength]="2" typeToSearchText="Search patient" (search)="fetchPatientHelper($event)" placeholder="Enter Name/Mobile of Patient" class="cubible-search" [ngClass]="{ 'is-invalid': (submitted && forms.bookAdmission.patientId.errors) }" formControlName="patientId">
                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                            <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                No patient found for '{{searchTerm}}'
                            </div>
                            <div class="ng-option disabled" *ngIf="(!searchTerm || searchTerm.length < 2)">
                                Search patient
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-i="index">
                            <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                            <span class="text-muted d-block font-13" [textContent]="item.optionalText2"></span>
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item" let-i="index">
                            <div class="media">
                                <div class="avatar-xs mr-1">
                                    <img *ngIf="item.optionalText" [src]="item.optionalText" [alt]="item.value" class="img-fluid rounded-circle" />
                                    <span *ngIf="!item.optionalText" class="avatar-title rounded-circle font-11 font-weight-bold text-white" avatar-bg [index]="i" [textContent]="item.value | initials"></span>
                                </div>
                                <div class="media-body">
                                    <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                </div>
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div *ngIf="currentUrgentStep === urgentSteps.Step2">
                <h3>Select Doctor</h3>
            </div>
            <div *ngIf="currentUrgentStep === urgentSteps.Step3">
                <h3>Select Bed</h3>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-secondary" (click)="onCloseUrgentModal()">
                <i class="mdi mdi-close mr-1"></i>Cancel
            </button>
            <button *ngIf="currentUrgentStep === urgentSteps.Step3" type="submit" class="btn btn-sm btn-primary" [disabled]="submitting">
                <span *ngIf="!submitting"> <i class="mdi mdi-check mr-1"></i>Submit </span>
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
            </button>
        </div>
    </form>
</ng-template>
<ng-template #templateConsentReport>

    <div class="modal-header">
        <h4 class="modal-title"><i class="fe-calendar mr-1"></i>Consent For Admission </h4>
        <div>
            <div class="btn-group d-print-none">
                <button class="btn btn-sm" [class.btn-light]="!isPrintLogo" [class.btn-outline-light]="isPrintLogo" type="button" (click)="onChangePrintType(false)">
                    <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                </button>
                <button class="btn btn-sm" [class.btn-light]="isPrintLogo" [class.btn-outline-light]="!isPrintLogo" type="button" (click)="onChangePrintType(true)">
                    <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                </button>
                <div class="btn-group d-print-none">
                    <button class="btn btn-sm" [class.btn-light]="!isFooter" [class.btn-outline-light]="isFooter" type="button" (click)="onChangeFooter(false)">
                        <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
                    </button>
                    <button class="btn btn-sm" [class.btn-light]="isFooter" [class.btn-outline-light]="!isFooter" type="button" (click)="onChangeFooter(true)">
                        <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
                    </button>
                </div>
            </div>
            <button [useExistingCss]="true" printSectionId="ConsentFormId" ngxPrint class="btn btn-xs d-print-none font-16">
                <i class="fe-printer"></i>
            </button>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">
                ×
            </button>
        </div>
    </div>
    <div id="ConsentFormId">
        <div class="justify-content-center d-flex" *ngIf="isPrintLogo">
            <banner-setting [reportName]='"Consent For Admission In The Hospital Header"'></banner-setting>
        </div>
        <div class="justify-content-center d-flex">
            <h3 class="font-weight-bold text-black">Consent For Admission In The Hospital </h3>
        </div>

        <div>
            <h4 class="font-weight-bold text-black justify-content-center d-flex">
                Please read the information below carefully and clarify your doubts before you sign the document.
            </h4>
            <h4 class="font-weight-bold text-black justify-content-center d-flex">Please ensure that only a relative of yours signs as a witness.</h4>
        </div>
        <div style="overflow:auto;height:1000px">
            <div *ngIf="selectedAdmission">
                <p class="font-weight-bold text-black pl-2 p-3 font-18">

                    I <b class="text-uppercase">{{selectedAdmission.patientName}}</b> aged <b>
                        {{patientDOB ? patientDOB : selectedAdmission.patientAge + ' years'}}
                    </b> ,<b>{{selectedAdmission.patientGender === 'M'?'Male':selectedAdmission.patientGender === 'F'?'Female':'--'}}</b>
                    residing at
                    <span *ngIf="selectedAdmission &&(selectedAdmission.city || selectedAdmission.state || selectedAdmission.streetAddress)"><b>{{selectedAdmission.streetAddress }} &nbsp;{{selectedAdmission.city }} &nbsp;{{selectedAdmission.state }}</b> </span>
                    <span *ngIf="!(selectedAdmission &&( selectedAdmission.streetAddress))">-------------</span>
                    give my free and valid consent for admission of myself/my
                    <b> {{selectedAdmission.attendantRelationWithPatient}}</b> name of patient <b class="text-uppercase">{{selectedAdmission.patientName}}</b> aged <b>{{selectedAdmission.patientAge}}</b>&nbsp;years
                    residing at
                </p>
            </div>
            <div class="col-12 d-flex">
                <div class="col-6">
                    <h5 class="font-weight-bold text-black">I understand the various administrative rules and regulations of the hospital, which include but are not limited to following</h5>
                    <h5 class="font-weight-bold text-black">&#x2022;I am aware of the medical infrastructure and amenities in the various types of wards and rooms.</h5>
                    <h5 class="font-weight-bold text-black">&#x2022;I understand that throughout the stay in the hospital, a responsible accompanying person will have to be available/contactable.</h5>
                    <h5 class="font-weight-bold text-black">&#x2022;I have been informed about the visiting hours for the relatives.</h5>
                    <h5 class="font-weight-bold text-black">&#x2022;I have been informed that valuables should not be carried along in the hospital premises.</h5>
                    <h5 class="font-weight-bold text-black">&#x2022;Iunderstand that there may be a need to submit photo id and residential proof of the patient within a reasonable time of admission.</h5>
                    <h5 class="font-weight-bold text-black">&#x2022;I understand that hospital is not ponsible for accidents due to natural calamities or unforeseen events beyond the scope of the hospital.</h5>
                    <h5 class="font-weight-bold text-black">&#x2022;I authorise the healthcare facility to dispose specimens such as blood, tissues, secretions and organs etc obtained during the stay</h5>
                    <h5 class="font-weight-bold text-black">&#x2022;I agree to take up the responsibility for the custody and safety of the new born that has been roomed in. (Applicable for post-delivery patient)</h5>
                    <h5 class="font-weight-bold text-black">
                        &#x2022;I confirm that I have given all the relevant details Including past medical history, previous ailments, surgeries and allergies to the doctor.
                    </h5>
                    <h5 class="font-weight-bold text-black">
                        &#x2022;I have been given information about the rules and regulations of the billing department of the hospital as mentioned below. The rules include but are not limited to following
                    </h5>
                    <h5 class="font-weight-bold text-black">
                        &#x2022;I have been made informed about the estimate of bill under various headings.
                    </h5>
                    <h5 class="font-weight-bold text-black">
                        &#x2022;I have been made to understand that the final bill may vary from the estimate provided, as the services provided under the different headings may differ in different health related situations, which unfold on daily basis during the hospital stay.
                    </h5>
                    <h5 class="font-weight-bold text-black">
                        &#x2022;I understand that after the treating doctor declares that patient is to be discharged a reasonable time is needed for finalisation of the bill.
                    </h5>
                    <h5 class="font-weight-bold text-black">
                        &#x2022;I have been made to understand about the advance payment policy of the hospital.
                    </h5>

                </div>
                <div class="col-6">
                    <h5 class="font-weight-bold text-black">&#x2022;I authorise the healthcare facility, its medical staff and paramedical staff to provide such diagnostic procedures, therapeutic procedures and treatment as in their judgement is deemed necessary in my care. Commonest procedures carried out are IV canulation/ infusion and urinary catheterisation. The procedures mentioned are indicative and not exhaustive.</h5>
                    <h5 class="font-weight-bold text-black">&#x2022;I authorise another qualified doctor to look after the continuity of treatment in case of absence of the treating doctor.</h5>
                    <h5 class="font-weight-bold text-black">&#x2022;I authorise the medical and paramedical team to reveal the healthcare record relevant to the treatment to other health care provider if deemed necessary.</h5>
                    <h5 class="font-weight-bold text-black">&#x2022;I authorise the hospital authority to release the relevant healthcare record to Government agencies as and when needed.</h5>
                    <h5 class="font-weight-bold text-black">&#x2022;I authorise the hospital to release the medical record for the purpose of medical insurance and reimbursement.</h5>
                    <h5 class="font-weight-bold text-black">&#x2022;I agree to cooperate fully with the doctors and to follow instructions and recommendations and dietary advises about the care and overall treatment.</h5>
                    <h5 class="font-weight-bold text-black">
                        &#x2022;I understand that in case of medical insurance the responsibility of ensuring that claim is accepted, is with me. All relevant documents and authorisation letters will be needed to be submitted beforehand for speedy sanction of cashless claims.
                    </h5>
                    <h5 class="font-weight-bold text-black">
                        &#x2022;I understand that in the event that sanction letter is not received from the third party assurance till discharge, the bill will have to be settled in full.
                    </h5>
                    <h5 class="font-weight-bold text-black">
                        &#x2022;I accept that medical science is not perfect and has its limitations. No guarantee has been given about result or outcome
                    </h5>
                    <h5 class="font-weight-bold text-black">
                        &#x2022;I am aware of the grievance redressal mechanism of the hospital. I state that in case of litigation in consumer forum, an appropriate forum in whose territorial jurisdiction the hosp falls into, will be approached
                    </h5>
                    <h5 class="font-weight-bold text-black">
                        &#x2022;I hereby give a free consent willingly with sound mind, without any undue influence, coercion, fraud, misrepresentation or mistake of facts
                    </h5>
                    <h5 class="font-weight-bold text-black">
                        &#x2022;I state that I have understood the above mentioned information fully
                    </h5>
                </div>
            </div>
            <div class="pl-3 p-0" style="margin-top:10px">
                <table class="w-100">
                    <tr *ngIf="selectedAdmission">
                        <td>
                            <h5 class="font-weight-bold text-black">PatientName :<b class="font-weight-bold text-uppercase">{{selectedAdmission.patientName}}</b></h5>
                        </td>
                        <td>
                            <h5 class="font-weight-bold text-black">Signature:-----------</h5>
                        </td>
                        <td>
                            <h5 class="font-weight-bold text-black">Date:{{date| date:'dd/MM/yyyy'}}</h5>
                        </td>
                        <td>
                            <h5 class="font-weight-bold text-black">Time:{{date| date:'hh:mm a'}}</h5>
                        </td>
                    </tr>
                    <tr *ngIf="selectedAdmission">
                        <td>
                            <h5 class="font-weight-bold text-black">AttendantName :<b>{{selectedAdmission.attendantName}}</b></h5>
                        </td>
                        <td>
                            <h5 class="font-weight-bold text-black">Signature:-----------</h5>
                        </td>
                        <td>
                            <h5 class="font-weight-bold text-black">Date:{{date| date:'dd/MM/yyyy'}}</h5>
                        </td>
                        <td>
                            <h5 class="font-weight-bold text-black">Time:{{date| date:'hh:mm a'}}</h5>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h5 class="font-weight-bold text-black">WitnessName :</h5>
                        </td>
                        <td>
                            <h5 class="font-weight-bold text-black">Signature:----------</h5>
                        </td>
                        <td>
                            <h5 class="font-weight-bold text-black">Date:{{date| date:'dd/MM/yyyy'}}</h5>
                        </td>
                        <td>
                            <h5 class="font-weight-bold text-black">Time:{{date| date:'hh:mm a'}}</h5>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h5 class="font-weight-bold text-black">StaffName :</h5>
                        </td>
                        <td>
                            <h5 class="font-weight-bold text-black">Signature:-----------</h5>
                        </td>
                        <td>
                            <h5 class="font-weight-bold text-black">Date:{{date| date:'dd/MM/yyyy'}}</h5>
                        </td>
                        <td>
                            <h5 class="font-weight-bold text-black">Time:{{date| date:'hh:mm a'}}</h5>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div *ngIf="isFooter">
            <banner-setting [reportName]='"Consent For Admission In The Hospital Footer"'></banner-setting>
        </div>
    </div>
</ng-template>

<ng-template #templateViewCounselling>
    <div class="modal-header d-block">
        <div class="d-flex align-items-center justify-content-between">
            <h4 class="modal-title"><i class="mdi mdi-page-next-outline mr-1"></i>Add Counselling Packages</h4>
        </div>
    </div>
    <div class="modal-body p-0" style="min-height: 400px">
        <view-counselling [counsellingId]="selectedCounsellingId" (close)="onCloseUrgentModal();" [counsellingViewType]="counsellingViewType.AddMode" (addedPackages)="applyCounselling($event)"></view-counselling>
    </div>
</ng-template>

<ng-template #reasearchConsentForm>
    <div class="modal-header">
        <div>
            <h4 class="modal-title">Research Consent Form</h4>
        </div>
        <div class="d-flex justify-content-end">
            <button class="btn btn-small" [class.btn-light]="!isPrintLogo" [class.btn-outline-light]="isPrintLogo" type="button" (click)="onChangePrintType(false)">
                <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
            </button>
            <button class="btn btn-small" [class.btn-light]="isPrintLogo" [class.btn-outline-light]="!isPrintLogo" type="button" (click)="onChangePrintType(true)">
                <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
            </button>
            <button class="btn btn-small" [class.btn-light]="!isFooter" [class.btn-outline-light]="isFooter" type="button" (click)="onChangeFooter(false)">
                <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
            </button>
            <button class="btn btn-small" [class.btn-light]="isFooter" [class.btn-outline-light]="!isFooter" type="button" (click)="onChangeFooter(true)">
                <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
            </button>
            <button [useExistingCss]="true" printSectionId="researchConsentFormId" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none">
                <i class="fe-printer mr-1"></i>Print
            </button>
            <button type="button" class="close d-print-none" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">
                ×
            </button>
        </div>
    </div>

        <div id="researchConsentFormId" class="p-3 m-1">
            <div>
                <div class="row">
                    <div class="col-6" *ngIf="isPrintLogo">
                        <banner-setting [reportName]='"Research Consent Form"'></banner-setting>
                    </div>
                    <div class="text-right col-3">
                        <h4> {{ admissions[0]?.locationName }}</h4>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <h3 class="font-weight-bold text-white justify-content-center d-flex bg-dark">Research Consent Form </h3>
            </div>
            <div>
                <div *ngIf="selectedAdmission">
                    <p class="font-weight-bold text-black font-18">
                        Patient Name <b class="text-uppercase">{{selectedAdmission.patientName ? selectedAdmission.patientName :'__________________________' }}</b>,
                        Age <b>{{ patientDOB ? patientDOB : selectedAdmission.patientAge + ' years'}}</b>
                        Sex <b>{{selectedAdmission.patientGender === 'M'?'Male':selectedAdmission.patientGender === 'F'?'Female':'--' }}</b>
                        MR No.<b>{{selectedAdmission.umrNo ? selectedAdmission.umrNo :'__________________________'}}</b>
                        IP No.<b>{{selectedAdmission.admissionNo ? selectedAdmission.admissionNo :'__________________________' }}</b>
                    </p>

                </div>
                <p class="font-weight-bold text-black font-18">
                    <span class="text-capitalize">{{practiceName  | titlecase}}</span> is an academic teaching hospital that engages in research to improve various aspects of health care for women and newborn babies. As part of its commitment to teaching, research and improving health care delivery, the Hospital consultants may use and share data of your clinical exams and investigations, including images and biological material, as well as the placenta, with other clinicians, scientists, researchers and/or institutes within or outside India and is subjected to applicable regulations.
                </p>
                <p class="font-weight-bold text-black font-18">
                    Sharing of data or samples will only be within the framework of specific studies with clear aims and objectives, or for clinical diagnostic purposes including confirmation of diagnosis. While sharing your data, we will ensure that your privacy and confidentiality is maintained. The data will thus be shared without revealing your personal details like name, address, contact details, or medical record numbers. However, you are free to choose to allow, or not to allow, such use of your clinical information, images or biological samples without any aspect of the clinical care you receive being affected by your choice.
                </p>

            </div>
            <div>
                <h4 class="font-weight-bold text-black"> <input type="checkbox" />&nbsp; I hereby consent &nbsp;<input type="checkbox" />&nbsp; I do not Consent for the above</h4>
                <h4 class="font-weight-bold text-black">Name:____________________________________________________________________</h4>
                <h4 class="font-weight-bold text-black"><input type="checkbox" />&nbsp; Patient&nbsp; <input type="checkbox" />&nbsp; Attendant &nbsp;&nbsp; &nbsp; If attendant,state relationship to Patient ___________________</h4>
                <h4 class="font-weight-bold text-black">Signature:_______________________ &nbsp;Date:{{date|date:'dd/MM/yyyy'}}&nbsp; Time:{{date | date:'hh:mm a'}}</h4>
            </div>
            <div *ngIf="regionalLanguage==='TELUGU'">
                <div class="col-12 st_mrg">
                    <h3 class="font-weight-bold text-white justify-content-center d-flex bg-dark">పరిశోధన అనుమతి పత్రం</h3>
                </div>
                <div>
                    <p class="font-weight-bold text-black font-17">
                        ఫెర్నాండేజ్ హాస్పిటల్ మహిళ మరియు నవజాతశిశువుల వివిధ ఆరోగ్యపరిరక్షణలో నిమగ్నమైయున్నది. ఈ కార్యక్రమములో
                        భాగముగా వైద్యశాల బోధన, పరిశోధన, ఆరోగ్యరక్షణ సిబ్బంది, సలహాదారులు మీ యొక్క వివిధ రకముల పరీక్షల వివరములు,
                        నమూనాలు, చిత్రములు, శరీర భాగ పదార్ధములు, వ్యర్థ పదార్థములు (మావి మొదలైనవి) ఉపయోగించదలచనున్నారు.
                        ఇండియాలోకాని, ఇతర ప్రదేశములలోకాని ఉన్న శాస్త్రవేత్తలు, సంస్థలు, పరిశోధకులు, పరీక్షకులు దీనికి సంబంధించి
                        అమలులో ఉన్న నిబంధనలకు లోబడగలరు. మీ పరీక్షల భోగట్టా (డేటా), నమూనాలు విజ్ఞానశోధనకు, వ్యాధి నిర్ధారణకు,
                        పరీక్ష విధానాలకు ఉపయోగింపబడుటలో క్రమబద్ధముగా ఉంచబడును. మీ వ్యక్తిగత వివరములు, పేరు, చిరునామా,
                        మిమ్ము సంప్రదించు విధము, వైద్య పరీక్షల పద్ధతులు, ఇతర వైద్య పత్రములు బహిరంగ పరచకుండా, గోప్యముగా ఉంచబడతాయి.
                        ఏది ఏమైనా, మీ వైద్య పరీక్షల వివరములు, చిత్రములు, నమూనాలు, శరీర భాగ పదార్థములు, వ్యర్థ పదార్థములు, పరిశోధనకు అంగీకరించడము
                        లేదా అనంగీకారము తెలుపడము మీ విజ్ఞతకు (ఇష్టతకు) వదలివేయడమైనది. మీ వైద్య రక్షణపై మీ అభిప్రాయము ఏ ప్రభావము చూపించబడదు
                    </p>
                    <p class="font-weight-bold text-black font-17">
                        నేను పై అంశములను సమ్మతించుచున్నాను/సమ్మతించుటలేదు.
                    </p>
                </div>
                <div>
                    <p class="font-weight-bold text-black font-17">
                        పేరు_________________________________________________________________________
                    </p>
                    <p class="font-weight-bold text-black font-17">సంతకం__________________________&nbsp;తేది.{{date|date:'dd/MM/yyyy'}} &nbsp;&nbsp; సమయము {{date|date:'hh:mm a'}}</p>
                    <p class="font-weight-bold text-black font-17">నేను/రోగితోగల బంధుత్వము __________________________________________________________________</p>
                </div>
            </div>
            <div *ngIf="regionalLanguage==='TAMIL'">
                <div class="col-12 st_mrg">
                    <h3 class="font-weight-bold text-white justify-content-center d-flex bg-dark">ஆராய்ச்சி அனுமதி ஆவணம்</h3>
                </div>
                <div>
                    <p class="font-weight-bold text-black font-17">
                        A4 மருத்துவமனை பெண்கள் மற்றும் பிறபுதிய பிறந்த குழந்தைகளின் உடல்நல பராமரிப்பில் நிபுணமாக செயல்பட்டு வருகிறது. இந்தப் பணி பகுதியாக, மருத்துவப் பள்ளி கல்வி, ஆராய்ச்சி, நலன்களுக்கான பணியாளர்கள், ஆலோசகர்கள் உங்கள் பலவகையான சோதனைகளின் விவரங்களை, மாதிரிகள், படங்கள், உடல் பகுதி பொருட்கள், கழிவுப் பொருட்கள் (மாவி போன்றவை) பயன்படுத்த விரும்புகிறார்கள்.

                        இந்தத் தகவலுக்கான விதிமுறைகள், இந்தியா மற்றும் பிற இடங்களில் உள்ள விஞ்ஞானிகள், நிறுவனங்கள், ஆராய்ச்சியாளர்கள், சோதனையாளர்கள் பின்பற்றுவார்கள். உங்கள் சோதனை தரவுகள், மாதிரிகள் அறிவியல் ஆராய்ச்சி, நோய் கண்டறிதல் மற்றும் சோதனை முறைமைகள் ஆகியவற்றிற்காக பாதுகாப்பாக கையாளப்படும். உங்கள் தனிப்பட்ட தகவல்கள், பெயர், முகவரி, தொடர்பு முறைமைகள், மருத்துவ சோதனை முறைமைகள் மற்றும் பிற மருத்துவ ஆவணங்கள் வெளிப்படையாக வெளியிடப்படாமல், ரகசியமாகக் காப்பாற்றப்படும்.

                        என்றாலும், உங்கள் மருத்துவ சோதனையின் விவரங்கள், படங்கள், மாதிரிகள், உடல் பகுதி பொருட்கள், கழிவுப் பொருட்கள் ஆகியவற்றுக்கான ஆராய்ச்சிக்கு நீங்கள் ஒப்புதல் அளிக்கலாம் அல்லது ஒப்புதல் மறுக்கலாம். இது உங்கள் மருத்துவ பராமரிப்புக்கு எந்தவொரு விளைவையும் ஏற்படுத்தாது.
                    </p>
                    <p class="font-weight-bold text-black font-17">
                        நான் மேலே கூறிய அம்சங்களுக்கு ஒப்புதலளிக்கிறேன்/ஒப்புதலளிக்கவில்லை.
                    </p>
                </div>
                <div>
                    <p class="font-weight-bold text-black font-17">
                        பெயர்_________________________________________________________________________
                    </p>
                    <p class="font-weight-bold text-black font-17">கையொப்பம்__________________________&nbsp;தேதி.{{date|date:'dd/MM/yyyy'}} &nbsp;&nbsp; நேரம் {{date|date:'hh:mm a'}}</p>
                    <p class="font-weight-bold text-black font-17">நான்/போதுக்களாகிய உறவினர் __________________________________________________________________</p>
                </div>
            </div>
            <div *ngIf="isFooter">
                <banner-setting [reportName]='"Research Consent Form Footer"'></banner-setting>
            </div>
        </div>


</ng-template>


<ng-template #templateViewPatientDetailsForm>
    <div class="p-2">
        <div class="btn-group d-print-none justify-content-md-end d-flex modal-header">
            <div>
                <div>
                    <button class="btn btn-info" type="button" (click)="onChangePrintType( false)">
                        <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangePrintType(true)">
                        <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangeFooter(false)">
                        <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangeFooter(true)">
                        <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
                    </button>
                    <button [useExistingCss]="true" printSectionId="PatientDetailsForm" ngxPrint class="btn btn-xs d-print-none font-16">
                        <i class="fe-printer"></i>
                    </button>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">
                        ×
                    </button>
                </div>
            </div>
        </div>
        <div id="PatientDetailsForm">
            <div>
                <div class="row ">
                    <div class="col-12 d-flex">
                        <div class="col-6" *ngIf="isPrintLogo">
                            <banner-setting [reportName]='"IP - PATIENT DETAILS"'></banner-setting>
                        </div>
                        <div class="text-right col-3">
                            <h4> {{ admissions[0]?.locationName }}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <h3 class="font-weight-bold text-white justify-content-center d-flex bg-dark">IP - PATIENT DETAILS </h3>
            </div>
            <div style="margin-left:20px">
                <h4 class="font-weight-bold text-black d-flex">
                    Patient's Name (<i>in block letters with surname</i>) :
                </h4>
            </div>
            <div style="overflow:auto;height:1300px">
                <div *ngIf="selectedAdmission">
                    <div>
                        <div class="form-group mb-1 d-flex">
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Full Name:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="selectedAdmission.patientName ? (selectedAdmission.patientName | uppercase ) :'__________________'"> </h5>
                                </div>
                            </div>
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">DOB:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 *ngIf="selectedAdmission && selectedAdmission.dateOfBirth">{{selectedAdmission.dateOfBirth | date:'dd/MM/yyyy'}} </h5>
                                    <h5 *ngIf="!(selectedAdmission && selectedAdmission.dateOfBirth)" [textContent]="'__________________'"></h5>
                                </div>
                            </div>
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Age:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="patientAge ? patientAge : selectedAdmission.patientAge"></h5>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-1 d-flex">
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Occupation:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.occupation ? selectedAdmission.occupation :'__________________'"> </h5>
                                </div>
                            </div>
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Qualification:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.education ? selectedAdmission.education :'__________________'"> </h5>
                                </div>
                            </div>
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-6 ">
                                    <h5 class="mb-1">Age at marriage:</h5>
                                </div>
                                <div class="form-group mb-1 col-">
                                    <h5 [textContent]="'__________________'"> </h5>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-1 d-flex">
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Religion:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.religion ? selectedAdmission.religion :'__________________'"> </h5>
                                </div>
                            </div>
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Nationality:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.nationality ? selectedAdmission.nationality :'__________________'"> </h5>
                                </div>
                            </div>
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Mobile No:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.patientMobile ? selectedAdmission.patientMobile :'__________________'"> </h5>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-1 d-flex">
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Email ID:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.email ? selectedAdmission.email :'__________________'"> </h5>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex" style="margin-left:20Px">
                            <h5>Type Of Pregnancy :</h5> &nbsp; &nbsp;
                            <div class="d-flex align-items-center">
                                <h5 class="ml-1 mb-0"><input type="checkbox" />&nbsp;Normal</h5>
                            </div>
                            &nbsp; &nbsp;
                            <div class="d-flex align-items-center">
                                <h5 class="ml-1 mb-0"><input type="checkbox" />&nbsp;IVF</h5>
                            </div>
                            &nbsp; &nbsp;
                            <div class="d-flex align-items-center">
                                <h5 class="ml-1 mb-0"><input type="checkbox" />&nbsp;Surrogate</h5>
                            </div>
                        </div>
                    </div>
                    <div style="margin-left:20px">
                        <h4 class="font-weight-bold text-black d-flex">
                            Husband's Name (<i>in block letters with surname</i>) :
                        </h4>
                    </div>
                    <div>
                        <div class="form-group mb-1 d-flex">
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Full Name:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.relativeName ? (selectedAdmission.relativeName| uppercase ) :'__________________'"> </h5>
                                </div>
                            </div>
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">DOB:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 *ngIf="selectedAdmission && selectedAdmission.dob">{{selectedAdmission.dob | date:'dd/MM/yyyy'}} </h5>
                                    <h5 *ngIf="!(selectedAdmission && selectedAdmission.dob)" [textContent]="'__________________'"></h5>
                                </div>
                            </div>
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Age:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.relativeAge ? selectedAdmission.relativeAge :'__________________'"> </h5>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-1 d-flex">
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Occupation:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.relativeOccupation ? selectedAdmission.relativeOccupation :'__________________'"> </h5>
                                </div>
                            </div>
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Qualification:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.relativeEducation ? selectedAdmission.relativeEducation :'__________________'"> </h5>
                                </div>
                            </div>
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Religion:</h5>

                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="'__________________'"> </h5>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-1 d-flex">
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Nationality:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.nationality ? selectedAdmission.nationality :'__________________'"> </h5>
                                </div>
                            </div>
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Mobile :</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.relativeMobileNo ? selectedAdmission.relativeMobileNo :'__________________'"> </h5>
                                </div>
                            </div>
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Email ID:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.email ? selectedAdmission.email :'__________________'"> </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="margin-left:20px">
                        <h4 class="font-weight-bold text-black d-flex">
                            Full Address :
                        </h4>
                    </div>
                    <div>
                        <div class="form-group mb-1 d-flex">
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">House No:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="'__________________'"> </h5>
                                </div>
                            </div>
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Street No:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.addressLine2 ? selectedAdmission.addressLine2 :'__________________'"> </h5>
                                </div>
                            </div>
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Locality:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.streetAddress ? selectedAdmission.streetAddress :'__________________'"> </h5>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-1 d-flex">
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">City:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.city ? selectedAdmission.city :'__________________'"> </h5>
                                </div>
                            </div>
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Pin Code:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.zipcode ? selectedAdmission.zipcode :'__________________'"> </h5>
                                </div>
                            </div>
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4 ">
                                    <h5 class="mb-1">District:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.patietName ? selectedAdmission.patienName :'__________________'"> </h5>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-1 d-flex">
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">State:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.state ? selectedAdmission.state :'__________________'"> </h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="justify-content-center d-flex">
                        <h4 class="font-weight-bold text-black p-1 border border-dark">DETAILS TO BE FILLED AFTER DELIVERY</h4>
                    </div>
                    <div>
                        <div class="form-group mb-1 d-flex">
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-5">
                                    <h5 class="mb-1">Date Of Birth:</h5>
                                </div>
                                <div class="form-group mb-1 col-7">
                                    <h5 [textContent]="selectedAdmission.patentName ? selectedAdmission.patientName :'__________________'"> </h5>
                                </div>
                            </div>
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-3">
                                    <h5 class="mb-1">Time:</h5>
                                </div>
                                <div class="form-group mb-1 col-6">
                                    <h5 [textContent]="selectedAdmission.fgh ? selectedAdmission.patientName :'__________________'"> </h5>
                                </div>
                                (AM/PM)
                            </div>
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Weight:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.patieName ? selectedAdmission.patientName :'__________________'"> </h5>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-1 d-flex">
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Gravida:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.patienName ? selectedAdmission.patientName :'__________________'"> </h5>
                                </div>
                            </div>
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Room No:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.fgh ? selectedAdmission.patientName :'__________________'"> </h5>
                                </div>
                            </div>
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">MR No:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.patietName ? selectedAdmission.patientName :'__________________'"> </h5>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-1 d-flex">
                            <div class="form-group mb-1 d-flex col-4">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">IP No:</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.fgh ? selectedAdmission.patientName :'__________________'"> </h5>
                                </div>
                            </div>
                            <div class="form-group mb-1 d-flex col-8">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Baby Name (Optional) :</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.patientNme ? selectedAdmission.patientName :'__________________'"> </h5>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="form-group mb-1 d-flex col-8">
                                <div class="form-group mb-1 col-4">
                                    <h5 class="mb-1">Age at Birth (No.of Weeks):</h5>
                                </div>
                                <div class="form-group mb-1 col-8">
                                    <h5 [textContent]="selectedAdmission.patientNe ? selectedAdmission.patientName :'__________________'"> </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex" style="margin-left:20Px">
                        <div class="d-flex align-items-center col-3">
                            <h5>Sex</h5>&nbsp;:
                        </div>
                        <div class="d-flex align-items-center">
                            <h5 class="ml-1 mb-0"><input type="checkbox" />&nbsp;Male</h5>
                        </div>
                        &nbsp; &nbsp;
                        <div class="d-flex align-items-center">
                            <h5 class="ml-1 mb-0"><input type="checkbox" />&nbsp;Female</h5>
                        </div>
                    </div>
                    <div class="d-flex" style="margin-left:20Px">
                        <div class="d-flex align-items-center col-3">
                            <h5> Delivery</h5>&nbsp;:
                        </div>
                        <div class="d-flex align-items-center">
                            <h5 class="ml-1 mb-0"><input type="checkbox" />&nbsp;Normal</h5>
                        </div>
                        &nbsp; &nbsp;
                        <div class="d-flex align-items-center font-weight-bold">
                            <h5 class="ml-1 mb-0"><input type="checkbox" />&nbsp;LSCS</h5>
                        </div>
                        &nbsp; &nbsp;
                        <div class="d-flex align-items-center">
                            <h5 class="ml-1 mb-0"><input type="checkbox" />&nbsp;Forceps/Vacuum</h5>
                        </div>
                    </div>
                    <div class="d-flex" style="margin-left:20Px">
                        <div class="d-flex align-items-center col-3">
                            <h5> No.Of Child</h5>&nbsp;:
                        </div>
                        <div class="d-flex align-items-center">
                            <h5 class="ml-1 mb-0"><input type="checkbox" />&nbsp;Single</h5>
                        </div>
                        &nbsp; &nbsp;
                        <div class="d-flex align-items-center">
                            <h5 class="ml-1 mb-0"><input type="checkbox" />&nbsp;Twins</h5>
                        </div>
                        &nbsp; &nbsp;
                        <div class="d-flex align-items-center">
                            <h5 class="ml-1 mb-0"><input type="checkbox" />&nbsp;Triplets</h5>
                        </div>
                        &nbsp; &nbsp;
                        <div class="d-flex align-items-center">
                            <h5 class="ml-1 mb-0"><input type="checkbox" />&nbsp;Quadruplets</h5>
                        </div>
                    </div>
                    <div class="d-flex" style="margin-left:20Px">
                        <div class="d-flex align-items-center col-3">
                            <h5> Birth details</h5>&nbsp;:
                        </div>
                        <div class="d-flex align-items-center">
                            <h5 class="ml-1 mb-0"><input type="checkbox" />&nbsp;Alive</h5>
                        </div>
                        &nbsp; &nbsp;
                        <div class="d-flex align-items-center">
                            <h5 class="ml-1 mb-0"> <input type="checkbox" />&nbsp; Dead</h5>
                        </div>
                        &nbsp; &nbsp;
                        <div class="d-flex align-items-center">
                            <h5 class="ml-1 mb-0"> <input type="checkbox" />&nbsp;Stillborn</h5>
                        </div>
                    </div>
                    <div class="d-flex" style="margin-left:20Px;margin-top:40px">
                        <div class="d-flex col-6">
                            <div>
                                <h5>Nurse's Name&nbsp;:&nbsp;</h5>
                            </div>
                            <div class="col-8">
                                <h5>____________________</h5>
                            </div>
                        </div>
                        <div class="d-flex col-6">
                            <div>
                                <h5>Signature&nbsp;:&nbsp;</h5>
                            </div>
                            <div class="col-8">
                                <h5>____________________</h5>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div style="overflow:auto;height:1000px">
                <div style="margin-top:20px">
                    <div class="justify-content-center d-flex">
                        <h4 class="font-weight-bold text-black ">DETAILS TO BE FILLED AFTER DELIVERY</h4>
                    </div>
                    <div style="margin-left:20px">
                        <p class="break-all text-black font-weight-bold font-15 overflow-auto">
                            I, the undersigned Mr./Mrs./Ms._____________________________________of W/o, D/o, H/o, Guardian
                            of_______________________________hereby declare that the above details are accurate and can
                            be given to the {{city}} for issue of Birth Certificate for the newborn baby after delivery and for insurance purposes
                            also. I hereby acknowledge and understand that the hospital will not be responsible for any wrong details given
                            by me or my attendant to the Hospital. Correction letters will not be issued thereafter. The importance of the
                            above details has been explained to me in the language I understand, and having understood, I have signed this
                            declaration in the presence of a witness
                        </p>
                    </div>
                    <table class="table mt-2 mb-0 table-bordered table-sm">
                        <thead class="table-info">
                            <tr>
                                <th>
                                    <h5>Patient / Husband / Attendant</h5>
                                </th>
                                <th>
                                    <h5>Declaration taken by</h5>
                                </th>
                                <th>
                                    <h5>Witness</h5>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <h5>Name:</h5>
                                </td>
                                <td>
                                    <h5>Name:</h5>
                                </td>
                                <td>
                                    <h5>Name:</h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex">
                                        <div class="d-flex col-6">
                                            <h5> Date:</h5>
                                        </div>
                                        <div class="d-flex col-6">
                                            <h5> Time:</h5>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex">
                                        <div class="d-flex col-6">
                                            <h5> Date:</h5>
                                        </div>
                                        <div class="d-flex col-6">
                                            <h5> Time:</h5>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex">
                                        <div class="d-flex col-6">
                                            <h5> Date:</h5>
                                        </div>
                                        <div class="d-flex col-6">
                                            <h5> Time:</h5>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr style="row-gap:20px">
                                <td>
                                    <h5>Relationship with the patient:</h5>
                                </td>
                                <td>
                                    <h5> Designation:</h5>
                                </td>
                                <td>
                                    <h5> Designation:</h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5>Signature:</h5>
                                </td>
                                <td>
                                    <h5>Signature:</h5>
                                </td>
                                <td>
                                    <h5>Signature:</h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="isFooter">
                        <banner-setting [reportName]='"IP-PATIENT DETAILS FOOTER"'></banner-setting>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-template>
<ng-template #templateChangeCaseTypeForm>
    <div>
        <div class="modal-header d-flex justify-content-between">
            <div>
                <h4 class="modal-title">Change CaseType</h4>
            </div>
            <div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">
                    ×
                </button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="mb-1">Case Type <code>*</code></label>
                        <select class="form-control custom-select form-control-sm" [(ngModel)]="surgeryId">
                            <option [ngValue]="null" disabled *ngIf="!loadingCaseTypes">Select</option>
                            <option *ngIf="loadingCaseTypes">Loading...</option>
                            <option *ngFor="let item of changecaseTypes;" [textContent]="item.value" [ngValue]="item.id"></option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-light" (click)="onCloseModal()">
                <i class="mdi mdi-close mr-1"></i>Close
            </button>
            <button type="button" (click)="onSubmitChangeCaseTypeForm()" class="btn btn-sm btn-primary" [disabled]="submitting">
                <span *ngIf="!submitting"> <i class="mdi mdi-check mr-1"></i>Submit </span>
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #chatBox>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-cash-lock-open mr-1"></i>Admission Chat</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">
            ×
        </button>
    </div>
    <div>
        <chat-box (isClose)="onCloseModal()" (countChanged)="countchangedHandler($event)" [appointmentId]="admissionId" [isAdmission]="true"></chat-box>
    </div>
</ng-template>

<ng-template #templateEditInsuranceForm>
    <form [formGroup]="editInsuranceForm" (ngSubmit)="onSubmiteditInsurance()">
        <div class="modal-header d-flex justify-content-between">
            <div>
                <h4 class="modal-title">Update Insurance</h4>
            </div>
            <div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">
                    ×
                </button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="mb-1">Insurance Company<code>*</code> </label>
                        <select (ngModelChange)="onChange($event)" class="form-control custom-select" formControlName="insuranceCompanyId" [ngClass]="{ 'is-invalid': submitted && insuranceform.insuranceCompanyId.errors }">
                            <option [ngValue]="null" *ngIf="!loadingInsurances">
                                Select insurance company
                            </option>
                            <option selected *ngIf="loadingInsurances">Loading...</option>
                            <option *ngFor="let item of insurances" [textContent]="item.value" [ngValue]="item.id"></option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label class="mb-1">Card Number <code>*</code></label>
                        <input type="text" class="form-control" formControlName="cardNo" maxlength="20" block autocomplete="nope" placeholder="Enter card number" [ngClass]="{ 'is-invalid': (submitted && insuranceform.cardNo.errors) }" />
                    </div>
                    <div class="form-group position-relative mb-0">
                        <label class="mb-1">Validity <code>*</code></label>
                        <input type="date" formControlName="validity" [min]="toDay" class="form-control" (ngModelChange)="onOpeninsurance()" [ngClass]="{ 'is-invalid': (submitted && insuranceform.validity.errors) }" />
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-light" (click)="onCloseModal()">
                <i class="mdi mdi-close mr-1"></i>Close
            </button>
            <button type="submit" class="btn btn-sm btn-primary" [disabled]="submitting">
                <span *ngIf="!submitting"> <i class="mdi mdi-check mr-1"></i>Submit </span>
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
            </button>
        </div>
    </form>
</ng-template>
<ng-template #templatePayaTypeConsentFrom>
    <div class="modal-header">
        <h4 class="modal-title"><i class="fe-calendar mr-1"></i>Payment Consent Form</h4>
        <div>

            <div class="btn-group d-print-none">
                <button class="btn btn-sm" [class.btn-light]="!isPrintLogo" [class.btn-outline-light]="isPrintLogo" type="button" (click)="onChangePrintType(false)">
                    <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                </button>
                <button class="btn btn-sm" [class.btn-light]="isPrintLogo" [class.btn-outline-light]="!isPrintLogo" type="button" (click)="onChangePrintType(true)">
                    <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                </button>
            </div>
            <div class="btn-group d-print-none">
                <button class="btn btn-sm" [class.btn-light]="!isFooter" [class.btn-outline-light]="isFooter" type="button" (click)="onChangeFooter(false)">
                    <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
                </button>
                <button class="btn btn-sm" [class.btn-light]="isFooter" [class.btn-outline-light]="!isFooter" type="button" (click)="onChangeFooter(true)">
                    <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
                </button>
            </div>
            <button [useExistingCss]="true" printSectionId="invoiceId" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none">
                <i class="fe-printer mr-1"></i>Print
            </button>
            <button type="button" class="close d-print-none" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">
                ×
            </button>
        </div>
    </div>
    <div id="invoiceId">
        <div class="p-lg-4">
            <div class="row">
                <div class="col-6 d-flex">
                    <div *ngIf="isPrintLogo">
                        <banner-setting [reportName]='"PAYMENT CONSENT FORM"'></banner-setting>
                    </div>
                </div>
                <div class="text-right col-3">
                    <h4> {{ admissions[0]?.locationName }}</h4>
                </div>
            </div>
        </div>

        <div class="col-12">
            <h3 class="font-weight-bold text-white justify-content-center d-flex bg-dark"> PAYMENT CONSENT FORM </h3>
        </div>
        <div class="a4_size">
            <div class="p-3">
                <div class="form-group mb-1">
                    <div class="form-group mb-1 d-flex">
                        <div class="form-group mb-1">
                            <div class="mb-1">
                                <h5 class="mb-1"> This is to declare that, I ________________________________________________________  (self / husband / father / mother)   </h5>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mb-1 d-flex">
                        <div class="form-group mb-1">
                            <h5 class="mb-1">________________________________________________________ (name of patient),________________________ </h5>
                        </div>
                    </div>
                    <div class="form-group mb-1 d-flex">
                        <div class="form-group mb-1">
                            <h5 class="mb-1">admitted at ________________________ ward is unable to pay the entire advance amount of________________________  </h5>
                        </div>
                    </div>
                </div>
                <div class="form-group mb-1 d-flex">
                    <div class="form-group mb-1">
                        <h5 class="mb-1">____________________________ immediately,and is paying only ________________________________________. </h5>
                    </div>
                </div>
                <div class="form-group mb-1 d-flex">
                    <div class="form-group mb-1">
                        <h5 class="mb-1">However, I agree to pay the remaining amount ( _______________ ) on ____________________ without fail  </h5>
                    </div>
                </div>
                <div class="form-group mb-1 d-flex">
                    <div class="form-group mb-1">
                        <h5 class="mb-1"> (Online / Cash / Credit card / Debit card). </h5>
                    </div>
                </div>

                <br>
                <br>
                <br>
                <div class="form-group mb-1 d-flex">
                    <div class="form-group mb-1">
                        <h5 class="mb-1"> Name: ___________________________________________      Executive Name: ___________________________________ </h5>
                    </div>
                </div>
                <div class="form-group mb-1 d-flex">
                    <div class="form-group mb-1">
                        <h5 class="mb-1">Signature: ________________________________________     Signature: ________________________________________</h5>
                    </div>
                </div>

                <div class="form-group mb-1 d-flex">
                    <div class="form-group mb-1">
                        <h5 class="mb-1"> Date: ____________________________________________      Date: ____________________________________________ </h5>
                    </div>
                </div>
                <div class="form-group mb-1 d-flex">
                    <div class="form-group mb-1">
                        <h5 class="mb-1">  Place: ___________________________________________      Place: ___________________________________________    </h5>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <br>
        <br>
        <div *ngIf="isFooter">
            <banner-setting [reportName]='"PAYMENT CONSENT FORM FOOTER"'></banner-setting>
        </div>
    </div>
</ng-template>

<ng-template #templateCashLessForm>
    <div class="modal-header">
        <h4 class="modal-title"><i class="fe-calendar mr-1"></i>Cashless — Undertaking Form</h4>
        <div>
            <div class="btn-group d-print-none">
                <button class="btn btn-sm" [class.btn-light]="!isPrintLogo" [class.btn-outline-light]="isPrintLogo" type="button" (click)="onChangePrintType(false)">
                    <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                </button>
                <button class="btn btn-sm" [class.btn-light]="isPrintLogo" [class.btn-outline-light]="!isPrintLogo" type="button" (click)="onChangePrintType(true)">
                    <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                </button>
                <div class="btn-group d-print-none">
                    <button class="btn btn-sm" [class.btn-light]="!isFooter" [class.btn-outline-light]="isFooter" type="button" (click)="onChangeFooter(false)">
                        <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
                    </button>
                    <button class="btn btn-sm" [class.btn-light]="isFooter" [class.btn-outline-light]="!isFooter" type="button" (click)="onChangeFooter(true)">
                        <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
                    </button>
                </div>
            </div>
            <button [useExistingCss]="true" printSectionId="cashLessFormId" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none">
                <i class="fe-printer mr-1"></i>Print
            </button>
            <button type="button" class="close d-print-none" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">
                ×
            </button>
        </div>
    </div>
    <div id="cashLessFormId">
        <div class="p-3 m-1">
            <div>
                <div class="row ">
                    <div class="col-6" *ngIf="isPrintLogo">
                        <banner-setting [reportName]='"Cashless—Undertaking Form"'></banner-setting>
                    </div>
                    <div class="text-right col-3">
                        <h4> {{ admissions[0]?.locationName }}</h4>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <h2 class="font-weight-bold text-white justify-content-center d-flex bg-dark">CASHLESS — UNDERTAKING FORM</h2>
            </div>
            <div class="col-12">
                <h3 class="font-weight-bold justify-content-center d-flex">To be filled by the Patient/Attendant at the time of Admission</h3>
            </div>
            <div>
                <div class="font-15 white-space-nowrap font-weight-bold">
                    <h4>I, Mr/Mrs/Ms _______________________________________________, the patient/attendant of</h4>
                    <br />
                    <h4>Mrs/Ms ________________________ have, today, the _____________ day of ________, 20_____,</h4>
                    <h4>been advised/been informed for admission of the patient into {{practiceName  | titlecase}}, {{city | titlecase}}, under the</h4>
                    <h4>the care of Dr _____________________and do hereby state and undertake the following:</h4>
                    <br />
                    <h4>a) I fully understand the rules governing the medical benefits, including the entitlements for treatment as </h4>
                    <h4 class="m-3">explained to me by insurance company ____________________, and prior to my patient’s admission </h4>
                    <h4 class="m-3">in your empanelled Hospital. </h4>
                    <h4>b) I also fully understand that if the cost of additional procedures exceed my entitlements, I should </h4>
                    <h4 class="m-3">immediately,inform the Hospital Insurance Desk to seek further approval from the TPA/Insurance</h4>
                    <h4 class="m-3"> Company. If I am not eligible for such benefits, I/my husband/parent/or patient attendant will </h4>
                    <h4 class="m-3">undertake to pay in advance before the procedure commences and if any due amount, it will be paid </h4>
                    <h4 class="m-3">at the earliest before discharge.</h4>
                    <br />
                    <h4 class="text-black font-18">I do undertake to settle the final bills as per tariff in the following events </h4>
                    <h4 class="m-3">a) In case of denial of cashless facility or disputed by the TPA/Insurance Company or any difference in</h4>
                    <h4 class="m-4">approval amount and actual amount for any reason whatsoever. </h4>
                    <h4 class="m-3">b) I understand that Insurance/TPA’s approvals depend on eligibilities and terms and conditions of my </h4>
                    <h4 class="m-4">policy; Hospital is not responsible for denial of cashless/delays in approvals.</h4>
                    <h4 class="m-3">c) In case I want to get discharged from the Hospital before receipt of confirmation/authorization of the</h4>
                    <h4 class="m-4">cashless by the TPA/Insurance Company, I/my husband/my parent/my attendant will pay the Hospital</h4>
                    <h4 class="m-4"> tariff.</h4>
                    <br />
                    <h4 class="text-black font-18">I also state the following </h4>
                    <h4 class="m-3">a) My policy is an Individual Policy/Corporate Policy (tick the appropriate).</h4>
                    <h4 class=" m-4">(Please note Maternity is not covered in individual policies).</h4>
                    <h4 class="m-3">b) Total sum insured _________________________________________</h4>
                    <br />
                    <h4 class="text-black font-18">Details of policy holder </h4>
                    <h4 class="m-3">a) Signature of Patient/Attendant _________ (In case of attendant, state relationship</h4>
                    <h4 class="m-4">with patient _________________________)</h4>
                    <h4 class="m-3">b) Place _______________ Date _____________ Time ____________ Contact No. __________</h4>
                    <h4 class="m-3">c) Occupation __________________ Designation _________________ Mail ID _____________</h4>
                    <h4 class="m-3">d) Name of the Organization ___________________ Office No. ______________</h4>
                </div>
            </div>
            <div *ngIf="isFooter">
                <banner-setting [reportName]='"Cashless Undertaking Form Footer"'></banner-setting>
            </div>
        </div>

    </div>
</ng-template>
<ng-template #templateViewStickers>

    <div class="modal-header">
        <h4 class="modal-title"><i class="fe-calendar mr-1"></i>Stickers</h4>
        <div>
            <button [useExistingCss]="true" printSectionId="printableData" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none">
                <i class="fe-printer mr-1"></i>Print
            </button>
            <button type="button" class="close d-print-none" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">
                ×
            </button>
        </div>
    </div>
    <div class="modal-body" id="printableData">

        <table>
            <tbody>
                <tr *ngFor="let i of [].constructor(12); let ix = index">
                    <td *ngFor="let j of [].constructor(4); let jy = index">
                        <div class="d-flex flex-wrap pr-4">
                            <div class="text-uppercase">
                                <div class="column-gap15px d-flex justify-content-between">
                                    <h4 [textContent]="'UMR:' "></h4>
                                    <h4 [textContent]=" selectedAdmission.umrNo"></h4>
                                    <h4 class="pl-2" [textContent]="'IP:' "></h4>
                                    <h4 [textContent]=" selectedAdmission.admissionNo"></h4>
                                </div>
                                <div class="d-flex-center">
                                    <h3 [textContent]="selectedAdmission.patientName"></h3>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="ix === 11">
                            <ngx-barcode6 [bc-value]="selectedAdmission.umrNo" [bc-width]="2" [bc-height]="40" [bc-display-value]="false">
                            </ngx-barcode6>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>


    </div>


</ng-template>


<ng-template #templateAdmissionForm>
    <div class="modal-header">
        <h4 class="modal-title"><i class="fe-calendar mr-1"></i>ADMISSION FORM</h4>
        <div>
            <div class="btn-group d-print-none">
                <button class="btn btn-sm" [class.btn-light]="!isPrintLogo" [class.btn-outline-light]="isPrintLogo" type="button" (click)="onChangePrintType(false)">
                    <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                </button>
                <button class="btn btn-sm" [class.btn-light]="isPrintLogo" [class.btn-outline-light]="!isPrintLogo" type="button" (click)="onChangePrintType(true)">
                    <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                </button>
            </div>
            <div class="btn-group d-print-none">
                <button class="btn btn-sm" [class.btn-light]="!isFooter" [class.btn-outline-light]="isFooter" type="button" (click)="onChangeFooter(false)">
                    <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
                </button>
                <button class="btn btn-sm" [class.btn-light]="isFooter" [class.btn-outline-light]="!isFooter" type="button" (click)="onChangeFooter(true)">
                    <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
                </button>
            </div>
            <button [useExistingCss]="true" printSectionId="admissionForm" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none">
                <i class="fe-printer mr-1"></i>Print
            </button>
            <button type="button" class="close d-print-none" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">
                ×
            </button>
        </div>
    </div>
    <div id="admissionForm">
        <div class="report m-1 ">
            <div class="row">
                <div class="col" *ngIf="isPrintLogo">
                    <banner-setting [reportName]='"ADMISSION FORM"'></banner-setting>
                </div>
                <div class="col">
                </div>
                <div class="col  d-flex  mb-2 justify-content-center align-items-center">
                    <div>
                        <h4 *ngIf="patient.patientName.includes('Baby of')" class="text-center">NEONATAL <br /> ADMISSION FORM</h4>
                        <h4 *ngIf="!patient.patientName.includes('Baby of')">ADMISSION FORM</h4>
                    </div>
                </div>
                <div class="col d-flex flex-column ">
                    <div class="col d-flex d-flex justify-content-between  ">
                        <p class="fs-5">COUNSELLING &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="options1" id="radioYes1" value="yes">
                            <label class="form-check-label" for="radioYes1">
                                Yes
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="options1" id="radioNo1" value="no">
                            <label class="form-check-label" for="radioNo1">
                                No
                            </label>
                        </div>
                    </div>
                    <div class="col d-flex d-flex justify-content-between ">
                        <p class="fs-5">REIMBURSEMENT</p>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="options2" id="radioYes2" value="yes">
                            <label class="form-check-label" for="radioYes2">
                                Yes
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="options2" id="radioNo2" value="no">
                            <label class="form-check-label" for="radioNo2">
                                No
                            </label>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row mb-0 pb-0 border-top border-secondary border-bottom">
                <div class="col">
                    <table class=" m-0 tablerow">
                        <tr>
                            <td class="font-weight-bold">M.R. NO</td>
                            <td> :&nbsp;&nbsp; &nbsp;&nbsp;<span [textContent]="patient.umrNo || '---'"></span></td>
                        </tr>
                    </table>
                </div>
                <div class="col mb-0">
                    <table class=" m-0 tablerow">
                        <tr>
                            <td class="font-weight-bold">I.P NO</td>
                            <td> :&nbsp;&nbsp; &nbsp;&nbsp;{{patient.admissionNo}}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <!--  first  tables -->
            <div class="row">
                <div class="col-6">
                    <table class=" text-black table1  mb-0 ">
                        <tr>
                            <td>Patient Name</td>
                            <td> :&nbsp;&nbsp; &nbsp;&nbsp; {{ patient.patientName }}</td>
                        </tr>
                        <tr>
                            <td>Relation</td>
                            <td> :&nbsp;&nbsp; &nbsp;&nbsp; {{ patient.fatherOrHusband }}
                            <td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td>:&nbsp;&nbsp; &nbsp;&nbsp; {{patient.area}}</td>
                        </tr>
                        <tr>
                            <td>TelPh(R)/Tel Ph(O)</td>
                            <td>:&nbsp;&nbsp; &nbsp;&nbsp;</td>
                        </tr>
                        <tr>
                            <td>Mobile No</td>
                            <td>:&nbsp;&nbsp; &nbsp;&nbsp; {{ patient.mobile }}</td>
                        </tr>
                        <tr>
                            <td>Attendant</td>
                            <td>:&nbsp;&nbsp; &nbsp;&nbsp;{{patient.attendantName}}</td>
                        </tr>
                        <tr>
                            <td>Type</td>
                            <td>:&nbsp;&nbsp; &nbsp;&nbsp; {{ patient.appointmentType }}</td>
                        </tr>
                        <tr>
                            <td>Refer By</td>
                            <td>:&nbsp;&nbsp; &nbsp;&nbsp;  {{ patient.referrealDoctorName }}</td>
                        </tr>
                    </table>

                </div>
                <div class="col-6">
                    <table class="  text-black   table1 mb-0 ">
                        <tr>
                            <td>Age</td>
                            <td>
                                :&nbsp;&nbsp; &nbsp;&nbsp;
                                <span>
                                    <span *ngIf="patient && patient.dateOfBirth" [textContent]="(patient && patient.dateOfBirth) | age:'days'"></span>

                                    <span *ngIf="!patient || !patient.dateOfBirth" [textContent]="(patient && patient.age || 'XX') + ' yrs'"></span>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>Sex</td>
                            <td>
                                :&nbsp;&nbsp;&nbsp;&nbsp;
                                {{ patient.gender ? (patient.gender === 'M' ? 'Male' : (patient.gender === 'F' ? 'Female' : patient.gender)) : '' }}
                            </td>
                        </tr>
                        <tr>
                            <td>Relative Age</td>
                            <td>:&nbsp;&nbsp;&nbsp;&nbsp;{{ patient.relativeAge }}{{ patient.relativeAge !== null && patient.relativeAge !== undefined ? ' (years)' : '' }}</td>
                        </tr>
                        <tr>
                            <td>Patient Category</td>
                            <td>
                                :&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>
                                    <span *ngIf="patient.payType === 'I'">{{patient.insuranceCompany}},{{ patient.tpaName}}</span>
                                </span>
                                <span *ngIf="patient.payType ==='C'">Cash</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Entitlement</td>
                            <td>:&nbsp;&nbsp; &nbsp;&nbsp;{{patient.bedRequestName}}</td>
                        </tr>
                        <tr>
                            <td>Area</td>
                            <td>:&nbsp;&nbsp; &nbsp;&nbsp;{{ patient.streetAddress }}</td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td>:&nbsp;&nbsp; &nbsp;&nbsp;    {{ patient.city }}</td>
                        </tr>
                        <tr>

                            <td>Pin Code</td>
                            <td>:&nbsp;&nbsp; &nbsp;&nbsp;    {{ patient.zipcode }}</td>
                        </tr>
                        <tr>

                            <td>Religion</td>
                            <td> :&nbsp;&nbsp; &nbsp;&nbsp;   {{ patient.religion }}</td>
                        </tr>
                        <tr>
                            <td>Nationality</td>
                            <td> :&nbsp;&nbsp; &nbsp;&nbsp;   {{ patient.nationality }}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="row border-top border-secondary border-bottom">
                <div class="col d-flex flex-row">
                    <div class="p-1">Admission Date & Time &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;    <b>:</b> </div>
                    <div class="p-1">{{ patient.createdDate | date:'dd-MM-yyyy, hh:mm a' }} </div>
                </div>
            </div>


            <!--   2nd table -->
            <div class="row">
                <div class="col-6">
                    <table class="  text-black  table1">
                        <tr>
                            <td>Unit Doctor </td>
                            <td><b>:</b>&nbsp;&nbsp; &nbsp;&nbsp; {{ patient.unitDoctor}}  </td>
                        </tr>
                        <tr>
                            <td>Unit </td>
                            <td><b>:</b>&nbsp;&nbsp; &nbsp;&nbsp; {{patient.unitName}} </td>
                        </tr>
                        <tr>
                            <td>Bed No </td>
                            <td><b>:</b>&nbsp;&nbsp; &nbsp;&nbsp;  {{ patient.bedNumber}}  </td>
                        </tr>
                        <tr>
                            <td>Billing Category </td>
                            <td><b>:</b>&nbsp;&nbsp; &nbsp;&nbsp; {{patient.chargeCategoryName}}  </td>
                        </tr>

                        <tr>
                            <td>Room Rent </td>
                            <td><b>:</b>&nbsp;&nbsp; &nbsp;&nbsp;{{patient.roomRent}}   </td>
                        </tr>

                        <tr>
                            <td>Amount Paid </td>
                            <td><b>:</b>&nbsp;&nbsp; &nbsp;&nbsp; {{patient.amountPaid}}  </td>
                        </tr>

                        <tr>
                            <td>Remarks</td>
                            <td><b>:</b>&nbsp;&nbsp; &nbsp;&nbsp;  </td>
                        </tr>
                    </table>
                </div>
                <div class="col-6">
                    <table class="  text-black table1 ">
                        <tr>
                            <td>Case type </td>
                            <td><b>:</b>&nbsp;&nbsp; &nbsp;&nbsp; {{patient.surgeryName}}   </td>
                        </tr>
                        <tr>
                            <td>Floor </td>
                            <td><b>:</b>&nbsp;&nbsp; &nbsp;&nbsp; {{patient.floorName}} </td>
                        </tr>

                        <tr>
                            <td>Room </td>
                            <td><b>:</b>&nbsp;&nbsp; &nbsp;&nbsp; {{patient.roomName}}  </td>
                        </tr>

                        <tr>
                            <td>Shifting Required </td>
                            <td><b>:</b>&nbsp;&nbsp; &nbsp;&nbsp;   </td>
                        </tr>

                        <tr>
                            <td>Date </td>
                            <td><b>:</b>&nbsp;&nbsp; &nbsp;&nbsp; {{patient.createdDate | date:'dd-MM-yyyy' }} </td>
                        </tr>


                        <tr>
                            <td>Time </td>
                            <td><b>:</b>&nbsp;&nbsp; &nbsp;&nbsp; {{ patient.createdDate | date:'hh:mm a' }}   </td>
                        </tr>

                    </table>
                </div>
            </div>
            <!-- empty table -->
            <div class="row">
                <div class="col d-flex justify-content-between font-weight-bold ">
                    <p class="mb-1">SIGNATURE OF THE ATTENDANT</p>
                    <p class="mb-1">NAME & SIGNATURE OF ADMITTING STAFF</p>
                </div>
            </div>

            <div class="row  border-top border-secondary border-bottom  pt-1 pb-1">

                <div class="col">
                    <table class=" w-100 emptytable">
                        <thead>
                            <tr>
                                <th class="border-right-0 text-center">Date</th>
                                <th class="border-right-0">Time</th>
                                <th class="border-right-0">Room</th>
                                <th>Date of Discharge</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-4">
                    <table class=" w-100 emptytable">
                        <thead>
                            <tr>
                                <th class="border-right-0 text-center">Date</th>
                                <th>Extra Attendants</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>

                            </tr>
                            <tr>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>

                            </tr>
                            <tr>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>

                            </tr>
                            <tr>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>

                            </tr>
                            <tr>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>

                            </tr>
                            <tr>
                                <td>&nbsp;&nbsp;</td>
                                <td>&nbsp;&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <p>ANAESTHETIST: DR _________________________</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <p> NEONATOLOGIST: DR _______________________</p>

                        </div>
                        <div class="col">
                            <p>
                                NEONATAL NURSE:
                                _______________________
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col d-inline-flex justify-content-between">
                            <p class="font-weight-bold">TYPE OF CASE:</p>
                            <p>OBSERVATION </p>
                            <p>DELIVERY</p>
                            <p>ELECTIVE SURGERY </p>
                            <p>EMERGENCY SURGERY</p>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <p>DATE OF SURGERY</p>
                        </div>
                        <div class="col">
                            <p>SURGEON:</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <p class="pt-3 pb-2">OPERATION PROCEDURE</p>

                        </div>
                        <div class="col d-inline-flex justify-content-end">

                            <table class=" emptytable2 mb-1">
                                <tr>
                                    <td class="border-right-0 text-center">
                                        APGAR
                                        SCORE
                                    </td>
                                    <td class="border-right-0"> &nbsp;&nbsp; &nbsp;&nbsp;</td>
                                    <td class="border-right-0">&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;</td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col mt-1 d-inline-flex justify-content-between ">
                            <p class="font-weight-bold">VAGINAL DELIVERY:</p>
                            <p>SPONT </p>
                            <p>FORCEPS</p>
                            <p> VENTOUSE</p>
                            <p>ASST.BREECH</p>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col d-inline-flex justify-content-between">
                            <p>LSCS</p>
                            <p>(TUBECTOMY YES/NO)  </p>
                            <p class="font-weight-bold">EPIDURAL</p>

                        </div>
                        <div class="col">
                            <p class="mb-1">YES/NO</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col d-inline-flex justify-content-between">
                            <p>DATE OF DEL: ___________</p>
                            <p>TIME OF DEL: __________ HRS </p>
                        </div>
                        <div class="col d-inline-flex justify-content-between">
                            <p>BOY / GIRL</p>
                            <p>WEIGHT __________ KGS </p>
                            <p>ALIVE/DEAD </p>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col d-inline-flex justify-content-between">
                    <p class="font-weight-bold">Summary Given </p>
                    <p>YES/NO </p>
                    <p>Post Natal Card Given </p>
                    <p>YES/NO</p>
                </div>
            </div>

            <div class="row border-top border-secondary border-bottom">
                <div class="col d-inline-flex justify-content-between">
                    <p>Labour Ward:  </p>
                    <p>Date </p>
                    <p>Time Admitted to LW </p>
                    <p>Time Shifted to OT/Room</p>
                </div>
            </div>
            <hr class="pt-0 mt-0">
            <div class="row d-flex nextpagebreaker">
                <div class="col" *ngIf="isPrintLogo">
                    <banner-setting [reportName]='"ADMISSION FORM"'></banner-setting>
                </div>

                <div class="col d-block justify-content-center text-center " *ngIf="!isPrintLogo">

                </div>


                <div class="col font-weight-bold   d-flex align-items-end mb-2  justify-content-center">
                    <div>
                        <h4 *ngIf="patient.patientName.includes('Baby of')" class="text-center">NEONATAL <br /> ADMISSION FORM</h4>
                        <h4 *ngIf="!patient.patientName.includes('Baby of')">ADMISSION FORM</h4>
                    </div>
                </div>
                <div class="col font-weight-bold">
                    <h4 class="text-center "></h4>
                </div>
            </div>

            <div class="row mb-1">
                <div class="col font-weight-bold border-top border-secondary border-bottom">

                    <h4 class="text-center ">CONSENT FOR MEDICAL TREATMENT</h4>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p>
                        I, the undersigned, Ms. /Mr.  <span class="bold-underlined" *ngIf="patient.relativeName">{{patient.relativeName}}</span>  <span *ngIf="!patient.relativeName">____________________________________</span>   D/o, W/o, F/o, H/o, Guardian  <span *ngIf="patient?.patientName?.includes('Baby of')">,S/o,Baby of </span>
                    </p>
                    <p>
                        of <span class="bold-underlined" *ngIf="patient.fullName">{{patient.fullName}}</span>  <span *ngIf="!patient.fullName">____________________________________</span>  (patient) Residing at <span class="bold-underlined" *ngIf="patient.streetAddress">{{patient.streetAddress}}</span> <span *ngIf="!patient.streetAddress">_______________________________________________________</span>
                    </p>
                    <p>
                        Hereby consent to the provision of diagnosis, care and/or treatment by the doctors and healthcare
                        professionals of {{practiceName  | titlecase}} across its branches, and I hereby acknowledge that such consent
                        will remain in effect unless and until I cancel such consent in writing.
                    </p>
                    <p>
                        I hereby acknowledge and confirm that I am mentally capable of giving informed consent to the
                        provision of the diagnosis, care and/or treatment and am not subject to duress or undue influence.
                    </p>
                    <p>
                        I hereby acknowledge and understand that, by signing this voluntary care patient consent form, I am
                        giving informed consent to the provision of diagnosis, care, and/or treatment by the doctors and
                        healthcare professionals of {{practiceName  | titlecase}} across its branches and cannot bring a legal or other
                        similar action, including an action on a medical, or other health-related claim, against the doctors
                        and healthcare professionals of {{practiceName  | titlecase}} across its branches unless the action or omission of
                        the doctors and healthcare professionals of {{practiceName  | titlecase}} across its branches constitutes willful
                        or wanton misconduct.
                    </p>
                    <p>
                        I consent to the Hospital using a quote / statement made by me in its website and brochures or other
                        public display with either my full name; or one of my names; or by my initials thereto, or alternatively
                        totally anonymously. I understand that this will be for the sole purpose of informing others about the
                        nature and quality of care provided by the Hospital.
                    </p>
                    <p>
                        The line of treatment to be given has been explained to me in the language I understand, and having
                        understood, I have signed this Consent in the presence of a witness.
                    </p>
                    <p>
                        I give consent / I do not give consent for using the case details and treatment of me / my wife / my
                        daughter for research, scientific paper publications and medical education. This may include medical
                        records, investigations, photographs and videos.
                    </p>
                </div>
            </div>
            <div class="row" *ngIf="patient.patientName.includes('Baby of')">
                <div class="col">
                    <table class="border-dark border-collapse w-100 emptytable padfortable">
                        <thead>
                            <tr>
                                <th class="custom-padding"> &nbsp; Patient / Guardian</th>
                                <th>Consent Taken by</th>
                                <th>Witness 1</th>
                                <th>Witness 2</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> &nbsp;Name : &nbsp;&nbsp;{{patient.attendantName}}</td>
                                <td>Name:&nbsp;&nbsp; {{patient.createdByNm}}</td>
                                <td>Name:</td>
                                <td>Name:</td>
                            </tr>
                            <tr>
                                <td> &nbsp;Sign:</td>
                                <td>Sign:</td>
                                <td>Sign:</td>
                                <td>Sign:</td>
                            </tr>
                            <tr>
                                <td> &nbsp;Date: {{patient.createdDate | date:'dd-MM-yyyy' }}</td>
                                <td>Date:{{patient.createdDate | date:'dd-MM-yyyy' }}</td>
                                <td>Date:</td>
                                <td>Date:</td>
                            </tr>
                            <tr>
                                <td> &nbsp;Time: {{patient.createdDate | date:'hh:mm a' }}</td>
                                <td>Time: {{patient.createdDate | date:'hh:mm a' }}</td>
                                <td>Time:</td>
                                <td>Time:</td>
                            </tr>
                            <tr>
                                <td> &nbsp;Relation to Patient : &nbsp;&nbsp;{{patient.attendantRelationWithPatient}}</td>
                                <td>
                                    Organization
                                    Representative
                                </td>
                                <td>Relation to Patient:</td>
                                <td>
                                    Organization
                                    Representative
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            <div class="row">
                <div class="col font-weight-bold">
                    <h4 class="text-center  pt-2 pb-2">CONSENT FOR HOSPITAL ADMISSION & RESPONSIBILITY FOR BILL PAYMENT</h4>
                </div>
            </div>
            <div class="row ">
                <div class="col ">
                    <p>
                        I, the undersigned, Ms. /Mr.  <span class="bold-underlined" *ngIf="patient.paymentResponsibleName">{{patient.paymentResponsibleName}}</span> <span *ngIf="!patient.paymentResponsibleName">______________________</span>  aged _____, unreservedly state that I am fully
                        responsible for payment of hospital bills / expenses incurred by Ms._____________________________ D/o,
                        W/o, Guardian of _______________________during her stay at {{practiceName  | titlecase}}. I also agree to pay
                        further advances towards daily billing incurred. I understand that the estimated charges are based on
                        the category of admission and for the number of days of stay in the hospital and do not include
                        neonatal charges / drugs and disposables / laboratory charges.
                        <br> <br>
                        I agree that in the event of my defaulting on these terms, which have been explained to me in detail in
                        the language that I understand, I am liable for legal action and proceedings.

                    </p>
                </div>
            </div>
            <div class="row" *ngIf="!patient.patientName.includes('Baby of')">
                <div class="col mb-1">
                    <P class="fs-4 text-center">جو کچھ بھی مجھے اپنی زبان اردو میں سمجھایا گیا ہے وہ سب سمجھکر می اپنی رضامندی سے دستقاتھ کرتا / کرتی ہوں</P>
                </div>
            </div>
            <div class="row" *ngIf="!patient.patientName.includes('Baby of') && regionalLanguage==='TELUGU'">
                <div class="col "> <p class="fs-4">పైన ఉదహరించిన విషయములన్నియు నాకు అర్ధమైయ్యే భాషలో (తెలుగులో) విశదీకరించి, నేను అర్ధము చేసుకున్న తరువాతనే నా సమ్మతిని తెలియజేయుచున్నాను </p></div>
            </div>
            <div class="row" *ngIf="!patient.patientName.includes('Baby of') && regionalLanguage==='TAMIL'">
                <div class="col "> <p class="fs-4">மேலே கூறிய அனைத்து விவரங்களும் எனக்கு புரிந்து கொள்ளும்படி (தெலுங்கில்) விளக்கப்பட்டு, நான் புரிந்த பிறகு என் ஒப்புதலை அளிக்கிறேன் </p></div>
            </div>
            <div class="row ">
                <div class="col">
                    <table class="border-dark border-collapse w-100 emptytable padfortable">
                        <thead>
                            <tr>
                                <th class="custom-padding"> &nbsp; Patient / Guardian</th>
                                <th>Consent Taken by</th>
                                <th>Witness 1</th>
                                <th>Witness 2</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> &nbsp;Name : &nbsp;&nbsp;{{patient.attendantName}}</td>
                                <td>Name:&nbsp;&nbsp; {{patient.createdByNm}}</td>
                                <td>Name:</td>
                                <td>Name:</td>
                            </tr>
                            <tr>
                                <td> &nbsp;Sign:</td>
                                <td>Sign:</td>
                                <td>Sign:</td>
                                <td>Sign:</td>
                            </tr>
                            <tr>
                                <td> &nbsp;Date: {{patient.createdDate | date:'dd-MM-yyyy' }}</td>
                                <td>Date:{{patient.createdDate | date:'dd-MM-yyyy' }}</td>
                                <td>Date:</td>
                                <td>Date:</td>
                            </tr>
                            <tr>
                                <td> &nbsp;Time: {{patient.createdDate | date:'hh:mm a' }}</td>
                                <td>Time: {{patient.createdDate | date:'hh:mm a' }}</td>
                                <td>Time:</td>
                                <td>Time:</td>
                            </tr>
                            <tr>
                                <td> &nbsp;Relation to Patient : &nbsp;&nbsp;{{patient.attendantRelationWithPatient}}</td>
                                <td>
                                    Organization
                                    Representative
                                </td>
                                <td>Relation to Patient:</td>
                                <td>
                                    Organization
                                    Representative
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
        <div *ngIf="isFooter">
            <banner-setting [reportName]='"Admission Form Footer"'></banner-setting>
        </div>
    </div>
</ng-template>

<div class="right-bar" *ngIf="showTimeLine">
    <admission-timeline [encryptedAdmissionId]="encryptedAdmissionId" (onClose)="onCloseTimeLine()"></admission-timeline>
</div>



























