import { Component, OnInit, OnDestroy, ViewEncapsulation, TemplateRef, Input } from "@angular/core";
import { Location } from '@angular/common';
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { finalize, takeUntil } from "rxjs/operators";
import { GenericResponse, GenericStatus, Page, IUserAccount } from "../../../../../shared/models";
import { HttpService, AppData, NotifyService } from "../../../../../shared/services";
import { ApiResources, DateHelper, UtilHelper } from "../../../../../shared/helpers";
import { ActivatedRoute, Params } from "@angular/router";
import { IAdmissionModel } from "../../../services/models/admission.model";
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from "@angular/forms";
import { AdmissionFetchHelper, TimelineCommunicationService, PIXEL_PER_LETTER, BUFFER_WIDTH } from "../../shared/helper";
import { IVitals } from "./helpers/helper";
import { LookUpModel } from "../../../services/models/look-up.model";
import { VitalType } from "@shared/entities/vital-type.entity";
import * as Highcharts from 'highcharts';




//class FilterOptions {
//    //fromDate: string = null;
//    //toDate: string = null;
//    //mealTypeId: number;
//    //locationId: number;
//    //floorId: number;
//    vitalTypeId: number = null;
//}
//class Filters {
//    hidden: boolean;
//    options: FilterOptions;
//    applied: boolean;

//    constructor() {
//        this.init();
//        this.hidden = true;
//    }

//    init() {
//        this.options = new FilterOptions();
//        this.applied = false;
//    }
//}


@Component({
    selector: 'progress-report-vitals',
    templateUrl: "./vitals.html",
    styleUrls: ["./vitals.css"],
    encapsulation: ViewEncapsulation.None
})
export class ProgressReportVitalsPage implements OnInit {


    @Input() inputAdmissionId?: number;
    admissionId: number;
    isAdmission: boolean;
    page: Page;
    modalRef: NgbModalRef;
    loading: boolean;
    vitalTypeValue: FormControl;


    dateControl: FormControl;
    records: Array<IVitals>;
    admission: IAdmissionModel;
    imgOrigin: string;

    //selectedVital: FormControl;

    selected: IVitals;
    submitted: boolean;
    submitting: boolean;
    isVitalTypeloading: boolean;
    isUnitTypeloading: boolean;
    widths: { createdBy: number };
    vitalTypes: Array<VitalType>;
    vitalTypesFilter: Array<VitalType>;

    unitTypes: Array<LookUpModel>;
    todayDate: Date;
    minDate = DateHelper.ngbToday;
    Vitalselect = true;
    vitalFrequencyRecordId: number
    vitalTypeId: number;
    vitalarray: VitalType[] = [];
    SelectedVital: number;
    displayGraphRecord: Array<any>;
    graphVitals: Array<any>;
    selectedVitalValue: string;
    storedadata: IVitals[];
    constructor(
        private readonly httpService: HttpService,
        private readonly modalService: NgbModal,
        private readonly notifyService: NotifyService,
        private readonly appData: AppData,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly locationService: Location,
        private readonly admissionFetchHelper: AdmissionFetchHelper,
        private readonly timelineCommunicationService: TimelineCommunicationService,
    ) {
        this.page = new Page();
        
        //this.filters = new Filters();
        this.widths = {
            createdBy: 130
        }
        this.imgOrigin = location.origin + location.pathname;
        this.records = new Array<any>();
        this.vitalTypes = new Array<VitalType>();

        this.todayDate = new Date();
    }


    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchVitalType();

                    this.route.parent.paramMap
                        .subscribe((params: Params) => {
                            var id = params["params"]["id"];
                            this.inputAdmissionId = parseInt(window.atob(id));
                            this.admissionId = this.inputAdmissionId || +params["params"]["id"];
                            this.isAdmission = this.inputAdmissionId ? true : params["params"]["type"] === "a";
                            this.loading = true;
                            this.admissionFetchHelper.admissionFetch(this.admissionId, this.isAdmission, (data: IAdmissionModel) => {
                                this.admission = data;
                            });
                            this.fetch();
                        });
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {
        console.log("");
    }

    // FORM    
    addVitalsToArray(mainItem?: VitalType, givenValue?: number) {
        return this.formBuilder.group({
            name: [mainItem.name],
            givenValue: [givenValue ? givenValue : null],
            selectedUnit: [mainItem.selectedUnit ? mainItem.selectedUnit : null],
            min: [mainItem.min],
            max: [mainItem.min]
        });
    }
    // MODEL
    openModifyModel = (content: TemplateRef<any>, record?: IVitals) => {

        if (record) {
            this.Vitalselect = false
            this.selected = record;
            //this.todayDate = record.date;
            //this.vitalTypes = record.vitals;
            this.vitalTypeId = record.vitalTypeId;
            this.vitalFrequencyRecordId = record.vitalFrequencyRecordId;
            // Assuming VitalType is the type of items in this.vitalTypes

            this.vitalarray=[]
            for (var i = 0; i <= this.vitalTypes.length - 1; i++) {

                if (this.vitalTypes[i].vitalTypeId == this.vitalTypeId) {
                    this.vitalTypes[i].givenValue=null
                    this.vitalarray.push(this.vitalTypes[i])
                    break;
                }
            }



        } else {
            this.fetchVitalType();
            /*this.todayDate = this.todayDate;*/
        }





        this.openModelHelper(content);
    }

    openModelHelper = (content: TemplateRef<any>) => {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: "lg",
            windowClass: "custom-modal effect-scale"
        });
    }

    onCloseModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
            this.selected = null;           
            this.submitted = false;

        } catch (e) {
            // ignored;
        }
    }
    
    takkenVitals() {
        const uniqueNamesSet = new Set();

        this.records.forEach((record) => {
            uniqueNamesSet.add(record.name);
        });

        this.graphVitals = Array.from(uniqueNamesSet);
    }
    fetch = () => {
        this.loading = true;
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReportVitals.base, ApiResources.progressReportVitals.fetch),
                { admissionId: this.admissionId })
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        var data = response.data as Array<IVitals>;
                        const filteredData = data.filter(x => x.vitalFrequencyRecordId);
                        var createdByWidth = Math.max(...data.map(x => x.createdByName.length)) * PIXEL_PER_LETTER;
                        this.widths.createdBy = Math.max(createdByWidth, this.widths.createdBy) + BUFFER_WIDTH - 10;
                        this.records = filteredData;
                        this.takkenVitals();                       
                        this.storedadata = data;


                    } else {
                        this.notifyService.warning(response.message);
                    }


                },
                () => {
                    this.notifyService.defaultError();
                }
            );
    }

    onResetFilters() {
        //this.loading = true;
        //this.filters.init();
        //this.fetch();
        this.records = this.storedadata;

    }

    onApplyFilters() {
        // this.filters.options.vitalTypeId
        //this.filters.options.vitalTypeId =  this.SelectedVital;
        //console.log(this.filters.options)

        //this.loading = true;
        //this.filters.applied =  UtilHelper.isFiltersApplied(this.filters.options);
        this.records = this.SelectedVital !== undefined ? this.storedadata.filter(x => x.vitalTypeId === this.SelectedVital) : this.storedadata;
    }

    //public ViatlsSearch() {
    //    this.loading = true;
    //    const request = { "vitalTypeId": null };
    //    request.vitalTypeId = this.SelectedVital;
    //    this.httpService
    //        .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReportVitals.base, ApiResources.progressReportVitals.fetch), { request })
    //        .pipe(takeUntil(this.page.unSubscribe))
    //        .pipe(finalize(() => { this.loading = false }))
    //        .subscribe(
    //            (response: GenericResponse) => {
    //                if (response.status === GenericStatus[GenericStatus.Success]) {
    //                    var data = response.data as Array<IVitals>;

    //                    var createdByWidth = Math.max(...data.map(x => x.createdByName.length)) * PIXEL_PER_LETTER;
    //                    this.widths.createdBy = Math.max(createdByWidth, this.widths.createdBy) + BUFFER_WIDTH - 10;
    //                    this.records = data;


    //                } else {
    //                    this.notifyService.warning(response.message);
    //                }


    //            },
    //            () => {
    //                this.notifyService.defaultError();
    //            }
    //        );
    //}


    fetchVitalType = () => {
        this.isVitalTypeloading = true;
        this.httpService
            .post<Array<VitalType>>(ApiResources.getURI(ApiResources.vitalType.base, ApiResources.vitalType.fetch), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.isVitalTypeloading = false))
            .subscribe(
                (response: Array<VitalType>) => {

                    this.vitalTypes = response;
                    //if (this.records.length > 0) {

                    //    this.vitalTypes=[]

                    //        if (this.records[0].vitals[0].name) {
                    //            for (let z = 0; z <= this.records[0].vitals.length-1; z++)
                    //            {
                    //                for (let j = 0; j <= response.length - 1; j++) {
                    //                    if (this.records[0].vitals[z].name == response[j].name) {
                    //                    this.vitalTypes.push(response[j])
                    //                }   

                    //                }
                    //            }
                    //        }

                    //}
                    //else {
                    //    this.vitalTypes = response;
                    //}


                    this.vitalTypes.forEach((item) => {

                        item.unitArray = new Array<any>();
                        item.unitArray = item.unitType.split(",");
                    });
                },
                () => {
                    this.vitalTypes = new Array<VitalType>();
                }
            );
    }

    fetchUnitType = () => {
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.vitalType.base, ApiResources.vitalType.fetchUnitType), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.isUnitTypeloading = false))
            .subscribe(
                (response: GenericResponse) => {

                    this.unitTypes = response.data as Array<LookUpModel>;

                },
                () => {
                    this.unitTypes = new Array<LookUpModel>();
                }
            );
    }

    modify = () => {
        this.submitted = true;       
        this.submitting = true;
        const request = this.vitalTypes.filter((item) => { return UtilHelper.isEmpty(item.givenValue) });

        var url = this.Vitalselect
            ? ApiResources.progressReportVitals.insertVitalFreqRecord

            : ApiResources.progressReportVitals.update;


        var data = this.Vitalselect
            ? {
                progressReportVitalsId: this.records[0].progressReportVitalsId,
                admissionId: +this.admissionId,
                modifiedBy: this.page.userAccount.accountId,
                modifiedByName: this.page.userAccount.fullName,
                createdBy: this.page.userAccount.accountId,
                date: this.todayDate,
                vitals: JSON.stringify(request)

            }
            : {
                admissionId: +this.admissionId,
                progressReportVitalsId: this.records[0].progressReportVitalsId,
                date: this.todayDate,
                createdBy: this.page.userAccount.accountId,
                modifiedBy: this.page.userAccount.accountId,
                modifiedByName: this.page.userAccount.fullName,
                vitalFrequencyRecordId: this.vitalFrequencyRecordId,
                takenValue: Number(request[0]?.givenValue)



            }
        this.submitting = true;
        this.Vitalselect = true
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReportVitals.base, url), data)
            .pipe(finalize(() => this.submitting = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        this.fetch();

                        this.timelineCommunicationService.set(true);
                        this.onCloseModal();
                    } else {
                        this.notifyService.warning(response.message);
                    }
                },
                () => {
                    this.notifyService.defaultError();
                }

            );

    }

    delete = (item: IVitals) => {
        item.deleting = true;
        this.notifyService.confirm('Are you sure you want to delete this Vital?', () => {
            this.httpService
                .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReportVitals.base, ApiResources.progressReportVitals.delete), {
                    ProgressReportVitalsId: item.progressReportVitalsId,
                    admissionId: +this.admissionId,
                    modifiedBy: this.page.userAccount.accountId,
                    modifiedByName: this.page.userAccount.fullName,
                    vitalFrequencyRecordId: item.vitalFrequencyRecordId
                })
                .pipe(finalize(() => item.deleting = false))
                .subscribe(
                    (response: GenericResponse) => {
                        if (response.status === GenericStatus[GenericStatus.Success]) {
                            this.fetch();
                            this.timelineCommunicationService.set(true);
                        } else {
                            this.notifyService.warning(response.message);
                        }
                    },
                    () => {
                        this.notifyService.defaultError();
                    }
                );
        }, () => {
            item.deleting = false;
        });
    }
    onBack = () => {
        this.locationService.back();
    }

    
    onSubmitGraphTemplate() {

        this.submitted = true;        
        this.displayGraphRecord = this.records.filter(x => x.name === this.selectedVitalValue);
         this.displayGraphRecord.reverse();
        this.generateGraph();    
    }



    openGraphModel(content: TemplateRef<any>) {
        this.fetchVitalType();
        this.openModelHelper(content);
    }
    
    formatDate(date, takenHour) {
        if (!date) return 'dd/mm/yyyy';
        const d = new Date(date);
        const day = ('0' + d.getDate()).slice(-2);
        const month = ('0' + (d.getMonth() + 1)).slice(-2);
        const year = d.getFullYear();
        return `${day}/${month} ${takenHour}`;

    }
    generateGraph() {
        
        const options: Highcharts.Options = {
            chart: {
                type: 'line',
                backgroundColor: '#f9f9f9', 
                borderRadius: 5, 
                animation: {
                    duration: 1000 
                }
            },
            title: {
                text: this.selectedVitalValue,
                style: {
                    color: '#333', 
                    fontSize: '18px', 
                    fontWeight: 'bold' 
                }
            },
            xAxis: {
                categories: this.displayGraphRecord.map(x => this.formatDate(x.createdDate, x.takenHour)),
                labels: {
                    style: {
                        color: '#666', 
                        fontSize: '12px' 
                    },
                    rotation: -45
                },
                lineColor: '#ddd',
                lineWidth: 1 
            },
            yAxis: {
                title: {
                    text: `${this.displayGraphRecord[0].name} (${this.displayGraphRecord[0].unitType})`,
                    style: {
                        color: '#333', 
                        fontSize: '14px', 
                        fontWeight: 'bold' 
                    }
                },
                labels: {
                    style: {
                        color: '#666', 
                        fontSize: '12px'
                    }
                },
                gridLineColor: '#f0f0f0' 
            },
            series: [{
                name: 'Taken Time',
                data: this.displayGraphRecord.map(x => x.takenValue),
                color: '#007bff' 
            }] as any,
            tooltip: {
                backgroundColor: '#fff', 
                borderColor: '#ccc',
                style: {
                    color: '#333', 
                    fontSize: '12px' 
                },
                formatter: function () {
                    return `<b>${this.series.name}</b><br/>${this.x}: ${this.y}`;
                }
            },
            legend: {
                enabled: true,
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                itemStyle: {
                    color: '#333', 
                    fontSize: '12px' 
                }
            },
            credits: {
                enabled: false 
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 600 
                    },
                    chartOptions: {
                        chart: {
                            height: '60%' 
                        },
                        xAxis: {
                            labels: {
                                style: {
                                    fontSize: '10px' 
                                },
                                rotation: 0 
                            }
                        },
                        yAxis: {
                            labels: {
                                style: {
                                    fontSize: '10px' 
                                }
                            }
                        }
                    }
                }]
            }
        };

        Highcharts.chart('container', options);
    }

}