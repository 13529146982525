import { Component, OnDestroy, OnInit, ChangeDetectorRef, NgZone } from "@angular/core";
@Component({
    templateUrl: "./labour-ward-cubicle.html",
    styleUrls: ["./labour-ward-cubicle.css"]
})

export class LabourWardCubiclePage implements OnInit, OnDestroy {
    constructor() {

    }
    ngOnInit() {
    }

    ngOnDestroy() {
    }
}
