import { Component, ViewEncapsulation, OnInit, OnDestroy, Input } from "@angular/core";
import { IUserAccount, Page } from "@shared/models";
import { HttpService, PrintOptionService, BannerService, ResourceService, AppData } from "@shared/services";
import { ImageReports, Appointment, PayTypes } from "@shared/entities";
import { ApiResources } from "@shared/helpers";
import { finalize, takeUntil } from "rxjs/operators";
import * as numberToWords from 'number-to-words';
import * as converter from "number-to-words";

@Component({
    templateUrl: "./visiting-prescription.html",
    styleUrls: ['./visiting-prescription.widget.css'],
    selector: "visiting-prescription",
    encapsulation: ViewEncapsulation.None
})
export class VisitingPrescriptionWidget implements OnInit, OnDestroy {
    // @Input() labBookingHeaderId: number;
    @Input() appointmentId: number;
    //@Output() onClose = new EventEmitter<any>();
    @Input() duplicateInvoiceId: boolean;
    @Input() isCancelInvoice: boolean;
    @Input() isPrintLogo: boolean;
    @Input() isFooter: boolean;
    @Input() isSubVisit: boolean;
    page: Page;
    loading: boolean;
    bannerBasics: ImageReports;
    myImgUrl = "assets/images/careaxesLogo.png";
    appointments: Array<Appointment>;
    selectedAppointment: Appointment;
    noReceipt = false;
    currentDate: Date;
    loadingRepotName: boolean;
    netTotal: number;
    amtInWords: string;
    QrCode: string;
    relationType: string;
    constructor(
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
        private readonly bannerService: BannerService,
        private readonly appData: AppData,
        private readonly resourceService: ResourceService,
    ) {
        this.page = new Page();
        this.appointments = new Array<Appointment>();
        this.selectedAppointment = new Appointment();
        this.currentDate = new Date();
       
    }

    private fetch() {
        this.loading = true;
        const request = {
            appointmentId: this.appointmentId
        };
        if (this.isCancelInvoice) {
            request["status"] = 'Cancel';
        }

        this.httpService
            .post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.fetchForInvoice), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                (response: Appointment) => {
                    this.selectedAppointment = new Appointment();
                    this.selectedAppointment.payTypes = new Array<PayTypes>();
                    this.selectedAppointment = response;
                    this.selectedAppointment.payTypes = response.payTypes;
                    let amountInWords = numberToWords.toWords(this.selectedAppointment.amount);
                    this.selectedAppointment["amountInWord"] = ' (Received Rupees ' + amountInWords + ' rupees only.)';
                    if (this.selectedAppointment.relation == "Husband") {
                        this.relationType = "W/O";
                    } 
                    if (response == null) {
                        this.noReceipt = true;
                    }
                    var cloneAppointment = JSON.parse(JSON.stringify(this.selectedAppointment));
                    this.selectedAppointment.fullAddress = [cloneAppointment.addressLine2, cloneAppointment.streetAddress, cloneAppointment.city, cloneAppointment.state, cloneAppointment.zipcode].filter(Boolean).join(", ");
                    this.selectedAppointment.aadharNo = [cloneAppointment.aadharNo].filter(Boolean).join(",")

                    if (response.receiptId) {
                        response.receiptNo = String(response.receiptId).padStart(6, '0');

                    }

                    if (this.selectedAppointment.referenceId != null) {
                        this.netTotal = (this.selectedAppointment.aptAmount + this.selectedAppointment.patAmount) - (this.selectedAppointment.aptDiscount + this.selectedAppointment.patDiscount)
                    }
                    else {
                        this.netTotal = (this.selectedAppointment.aptAmount) - (this.selectedAppointment.aptDiscount)
                    }

                    if (this.netTotal) {
                        this.amtInWords =this.toTitleCase(converter.toWords(this.netTotal) )+ ' Rupees Only.';
                    }
                    if (this.selectedAppointment.relation == "Husband") {
                        this.relationType = "W/O";
                    } else if (this.selectedAppointment.relation == "Wife") {
                        this.relationType = "H/O";
                    } else if (this.selectedAppointment.relation == "Father" && this.selectedAppointment.patientGender == "M") {
                        this.relationType = "S/O";
                    } else if (this.selectedAppointment.relation == "Father" && this.selectedAppointment.patientGender == "F") {
                        this.relationType = "D/O";
                    
                    } else if (this.selectedAppointment.relation == "Sister" && this.selectedAppointment.patientGender == "F") {
                        this.relationType = "Sis/O";
                    } else if (this.selectedAppointment.relation == "Sister" && this.selectedAppointment.patientGender == "M") {
                        this.relationType = "B/O";
                    } else if (this.selectedAppointment.relation == "Brother" && this.selectedAppointment.patientGender == "F") {
                        this.relationType = "Sis/O";
                    } else if (this.selectedAppointment.relation == "Brother" && this.selectedAppointment.patientGender == "M") {
                        this.relationType = "B/O";
                    } else if (this.selectedAppointment.relation == "Son" && this.selectedAppointment.patientGender == "M") {
                        this.relationType = "F/O";
                    } else if (this.selectedAppointment.relation == "Son" && this.selectedAppointment.patientGender == "F") {
                        this.relationType = "M/O";
                    } else if (this.selectedAppointment.relation == "Daughter" && this.selectedAppointment.patientGender == "M") {
                        this.relationType = "F/O";
                    } else if (this.selectedAppointment.relation == "Daughter" && this.selectedAppointment.patientGender == "F") {
                        this.relationType = "M/O";
                    }
                    this.QrCode = `${location.origin}${location.pathname}#/new-lab-webreports/${this.selectedAppointment}`;
                },
                () => {
                    this.selectedAppointment = new Appointment();
                }
            );
    }
    toTitleCase(str: string): string {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetch();                             
                        this.printOptionService.get((is) => { this.isPrintLogo = is; });
                        this.bannerService.getBannerImage((is) => { this.bannerBasics = is; });                  
                    this.currentDate = new Date();
                } else {
                    this.page.userAccount = undefined;
                }
            });

    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
}
