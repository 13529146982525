
<div class="row">
    <div class="col-6">
        <div class="row mb-1">
            <div class="col-auto"><label class="mr-2">Search LookUp</label></div>
            <div class="col">
                <div class="input-group">
                    <input class="form-control" type="text" [(ngModel)]="searchQuery" (input)="filterTable($event.target.value)" placeholder="Enter LookUp">
                    <button class="btn btn-danger btn-sm" type="button" (click)="clearSearch()">
                        <i class="mdi mdi-close"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="table-responsive lookup-names-table" >
            <table class="table table-centered table-sm table-striped table-bordered mb-0">
                <thead class="sticky-top visibility" >
                    <tr>
                        <th class="bg-white"></th>
                        <th class=" bg-white combined-header" colspan="3">
                            <div class="d-flex justify-content-between">
                                <label>Lookup Names</label>
                                <button class="end-word btn btn-sm btn-primary" (click)="addRow()">Add NewLookUp <i class="mdi mdi-plus-circle-outline"></i> </button>

                            </div>
                        </th>
                    </tr>

                </thead>
                <tbody>
                    <tr *ngFor="let item of filteredData; let i = index">
                        <ng-template #editCell>
                            <td colspan="2">
                                <div class="input-group">
                                    <input [(ngModel)]="item.name" type="text" class="form-control">
                                    <button class="btn btn-sm btn-primary" (click)="saveNewLookUp(i)">
                                        <i class="mdi mdi-content-save"></i> save
                                    </button>
                                    <button class=" btn-danger" (click)="removeLookup(item.id)">
                                        Remove
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </td>
                        </ng-template>

                        <td>
                            <!--this loads when click in fromcration page-->
                            <div *ngIf="checkFormcreation">
                                <input type="radio"
                                       name="optionGroup"
                                       *ngIf="item.lookupId !== undefined"
                                       [(ngModel)]="dataFormcreation.lookupId"
                                       [value]="item.lookupId"
                                       [checked]="dataFormcreation?.lookupId === item.lookupId"
                                       (change)="loadLookupValue(item)">
                            </div>
                            <!--this load from lookup designpage-->
                            <div *ngIf="!checkFormcreation">
                                <input type="radio"
                                       name="optionGroup"
                                       *ngIf="item.lookupId !== undefined"
                                       [checked]="dataFormcreation?.lookupId === item.lookupId"
                                       (change)="loadLookupValue(item)">
                            </div>

                        </td>
                        <td *ngIf="!item.editing; else editCell">
                            {{
 item.name || 'Enter NewLookUp'
                            }}
                        </td>


                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="col-6">
        <div class="row mb-1">
            <div class="col-auto">
                <label>Search LookUp by using lookupvalue</label>
            </div>
            <div class="col">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchTerm" (input)="filterLookupValue($event.target.value)">
                    <button class="btn btn-danger btn-sm" type="button" (click)="clearSearchValues()">
                        <i class="mdi mdi-close"></i>
                    </button>
                    <button class="fa fa-search btn btn-sm btn-primary" type="button" (click)="search(searchTerm)">
                        <i class="mdi mdi-magnify"></i> search
                    </button>
                </div>
            </div>

        </div>
        <div class="table-responsive lookup-names-table" *ngIf="isRadioSelected">
            <table class="table table-centered table-sm table-striped table-bordered mb-0 right-align-table">
                <thead class="sticky-top visibility">
                    <tr>
                        <th colspan="2" class="combined-header sticky-top bg-white">
                            <div class="d-flex justify-content-between">
                                <label> Lookup Values</label>
                                <button class="end-word btn btn-sm btn-primary" (click)="addNewLookUpValue()">Add NewLookUp Value <i class="mdi mdi-plus-circle-outline"></i></button>
                            </div>
                            <div class="header-text">
                                <label>LookUp values of : {{lookupName}} </label>
                            </div>

                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of selectedLookup let i = index">
                        <ng-template #editCells>
                            <td class="2">
                                <div class="input-group">
                                    <input [(ngModel)]="item.name" type="text" class="form-control">
                                    <button class="btn btn-sm btn-success" (click)="saveNewLookupValue(i)">
                                        <i class="mdi mdi-content-save"></i> save
                                    </button>
                                    <button class=" btn-danger" (click)="removeLookupValue(item.id)">
                                        Remove
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </td>
                        </ng-template>
                        <td *ngIf="!item.edit; else editCells">
                            {{ item.name || 'Enter NewLookUp' }}
                        </td>

                        <td *ngIf="showDeleteButton">
                            <button class="btn btn-sm btn-danger" (click)="deleteRow(i)">
                                <i class="mdi mdi-trash-can-outline"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>

            </table>
        </div>
    </div>
</div>