import { ChangeDetectorRef, Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { SectionRenderPage } from "../../../areas/admin/masters/pages/section-rendering/sectionrender.page";
import { AppData, HttpService, NotifyService } from "../../services";
import { ApiResources } from "../../helpers";
import { gridviewcomponent } from "../../../areas/admin/masters/pages/gridview/gridview.page";
import { finalize, takeUntil } from "rxjs";
import { IUserAccount, Page } from "../../models";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AppointmentsPage } from "../../../areas/admin/appointments/appointments.page";


@Component({
    selector: 'Outside-Encounter',
    templateUrl: 'dynamic-outside-encounter.html',
    styleUrls: ['./dynamic-outside-encounter.css']
})
export class DynamicOutsideEncounter implements OnInit {
    @Input() sectionId: number;
    @Input() sectionDataContent: any;
    @Input() sectionType: number;
    @Input() appointmentId: any;
    @Input() patientId: any;
    @Input() parentComponent: any; 
    @Input() isOutsideEncounter: boolean;
    Sections: any = [];
    sectionName: any;
   // sectionId: any;
    htmlString1: any;
    scriptContent: any;
    importedFormGroup: any;
    importedFormElements: any;
    appointment: any;
    outsideEncounter: boolean = true;
    isShow: boolean = false
    showViewScript:boolean = true
    page: Page;
    sectionList: string[] = [];
    @ViewChild('dynamicComponentContainer', { read: ViewContainerRef, static: true }) dynamicComponentContainer: ViewContainerRef;

    ngOnInit() {
        this.fetchSections();
        (window as any).angularComponent = this; 
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((account: IUserAccount) => {
                if (account) {
                    this.page.userAccount = account;
                } else {
                    this.page.userAccount = undefined;
                }
            });
        this.getAllSections();

       
       

    }
    constructor(private httpService: HttpService, private sanitizer: DomSanitizer, private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef, private readonly route: ActivatedRoute, private readonly router: Router,
        private changeDetectorRef: ChangeDetectorRef, private readonly notifyService: NotifyService, private readonly appData: AppData) { this.page = new Page(); }

    GetSectionrenderComponent(sectionId?, sectionType?) {
        if (this.dynamicComponentContainer) {
          
            this.dynamicComponentContainer.clear();
        }

        this.scriptContent = {};
        // Create a component factory for the specified component type
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SectionRenderPage);
        // Create and attach the component to the view container
        const componentRef = componentFactory.create(this.dynamicComponentContainer.parentInjector);
        componentRef.instance.outsideEncounter = this.outsideEncounter;
        componentRef.instance.selsectionname = this.sectionDataContent.name;
        componentRef.instance.sectionType = this.sectionType;
        componentRef.instance.patientId = this.patientId;
        componentRef.instance.sectionId = this.sectionId;
        componentRef.instance.appointmentId = this.appointmentId;
        componentRef.instance.encounterModeType = this.sectionDataContent.encounterMode;
        componentRef.instance.encounterType = this.sectionDataContent.encounterType;
        componentRef.instance.closeModalAppointment.subscribe(() => {
            this.closeModal(); // Call the method to close the modal
        });
        //componentRef.instance.templateid = IsTemplateId;
        //componentRef.instance.TemplateIDs = TemplateIDs;
        //componentRef.instance.appointmentDate = appointmentDate;
        //componentRef.instance.appointmentId = this.appointment.appointmentId;
        //componentRef.instance.Sections = Sections;

        // setTimeout(() => {
        //this.changeDetectorRef.detectChanges();
        ////componentRef.instance.dataReady.subscribe(() => {
        //    var instance = componentRef.instance;

        //    this.importedFormGroup = instance.myformArray?.controls[0];
        //    this.importedFormElements = instance.clonedArray;
        //    this.scriptContent = instance.scriptContent;
        //    if (this.scriptContent) {
        //        if (this.scriptContent.hasOwnProperty("Section")) {
        //            this.scriptContent["Section"].forEach((sectionObject) => {

        //                const func = (window as any)[sectionObject.logicName] as unknown as Function;

        //                if (typeof func === 'function') {
        //                    func(this.importedFormElements, this.importedFormGroup, this.scriptContent, this.notifyService);
        //                } else {
        //                    console.error(`Function  is not defined.`);
        //                }
        //            })
        //        }
        //    }

        ////});

        ////this.dynamicComponentContainer.insert(componentRef.hostView);
        //}, 500)
        this.changeDetectorRef.detectChanges();

        this.dynamicComponentContainer.insert(componentRef.hostView);
        
    }

    closeModal() {
        this.showViewScript = false;
        if (this.parentComponent) {
            this.parentComponent.onCloseRegContentModal(); // Call the method in PatientAppointmentComponent
        }
        this.dynamicComponentContainer.clear(); // Clear the dynamic component
        // Additional logic to close the modal can be added here if needed
    }
    fetchSections() {
        this.httpService.post(ApiResources.getURI(ApiResources.Adddynamictempsec.base, ApiResources.Adddynamictempsec.fetchbyencountertype), { EncounterMode:1 })
            .subscribe((response: any) => {
                this.Sections = response

            });

    }
    fetchSectionById(id) {
        const request = { IsActive: true, SectionID: id };
        this.httpService.post(ApiResources.getURI(ApiResources.Adddynamictempsec.base, ApiResources.Adddynamictempsec.fetchbyId), request)
            .subscribe((response: any) => {
                this.htmlString1 = this.sanitizer.bypassSecurityTrustHtml(response.view)

            });

    }
    GetGridComponent(sectionid: any) {
        this.dynamicComponentContainer.clear();
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(gridviewcomponent);
        const componentRef = componentFactory.create(this.dynamicComponentContainer.parentInjector);
       /* componentRef.instance.patientId = patientid;*/
        componentRef.instance.sectionId = sectionid;
        //componentRef.instance.typeid = typeid;
        //componentRef.instance.IsDashBoardTemplateId = this.isDashBoardTemplateId
        //componentRef.instance.TemplateId = TemplateId;
        //componentRef.instance.EncounterId = EncounterId;
        //componentRef.instance.Sections = Sections;
        this.dynamicComponentContainer.insert(componentRef.hostView);
    }

    onChange(event) {
        let id = event.sectionID;
        this.sectionName = event.name;
        this.sectionId = id;
        if (event.type == 5 ) {
          //  this.isShow = true
            this.showViewScript = false
            const sharedComponentRef = this.GetSectionrenderComponent(this.sectionId, event.type);

            console.log(sharedComponentRef)
        } else if (event.type == 11) {
           // this.isShow = true;
            this.showViewScript= false
            const sharedComponentRef = this.GetGridComponent(this.sectionId);

        } else {
            this.showViewScript = true;
            this.dynamicComponentContainer.clear();
            this.fetchSectionById(id);
        }
         


    }

    loadSectionsContent() {
        if (this.sectionType == 5) {
            this.showViewScript = false;
            const sharedComponentRef = this.GetSectionrenderComponent(this.sectionId, this.sectionType);
        }
    }
    commmonQueryExecute(request: string): Promise<any> {
        const query = { query: request };

        return new Promise((resolve, reject) => {
            this.httpService
                .get<Array<any>>(
                    ApiResources.getURI(ApiResources.tagformlist.base, ApiResources.tagformlist.commonQueryData), query
                )
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => this.page.loading = false))
                .subscribe(
                    (response: any) => {
                        resolve(response); // Resolve the promise with the response
                    },
                    (error: any) => {
                        console.error("Error: ", error);
                        reject(error); // Reject the promise if there is an error
                    }
                );
        });
    }
    getAllSections(record?) {
       const request = { sectionID: this.sectionId }
        this.httpService
            .post<Array<any>>(ApiResources.getURI(ApiResources.tagformlist.base, ApiResources.tagformlist.getAllSections), request).subscribe((response: any) => {
                this.sectionList = response;
                this.sectionDataContent = response[0];
                this.loadSectionsContent();

            });
    }
   
}