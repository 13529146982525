import { ModuleChargeModel, ModuleModel, PackageModuleDetailModel, PackageModuleModel, PackageModuleViewModel, PackageType } from "@admin/masters/pages/package-modules/models";
import { Component, OnDestroy, OnInit, TemplateRef, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ApiResources, UtilHelper } from "@shared/helpers";
import { IResource, IUserAccount, Page, Pagination } from "@shared/models";
import { AppData, HttpService, NotifyService, ResourceService } from "@shared/services";
import { finalize, takeUntil } from "rxjs/operators";
import Swal from "sweetalert2";

class TrackBy {
    moduleCharge(_: number, item: ModuleChargeModel): number {
        return item.referenceId;
    }

    module(_: number, item: ModuleModel): number {
        return item.modulesMasterId;
    }
}
class FilterOptions {
    chargeName: string = null;
}

class Filters {
    options: FilterOptions;
    applied: boolean;

    constructor() {
        this.init();
    }

    init() {
        this.options = new FilterOptions();
        this.applied = false;
    }
}

interface ICharge {
    module: ModuleModel;
    moduleCharges: Array<ModuleChargeModel>;
}

@Component({
    templateUrl: "./modify-package-module.html",
    selector: "modify-package-module",
    encapsulation: ViewEncapsulation.None,
    styleUrls: ["./modify-package-module.css"]
})
export class ModifyPackageModulePage implements OnInit, OnDestroy {
    packageType = PackageType;
    packageTypes = [{ value: "O", name: "Out-patient" }, { value: "I", name: "In-patient" }, { value: "OI", name: "OP & IP" }] as Array<IResource>;
    payTypes = [{ value: "C", name: "Cash" }, { value: "I", name: "Insurance" }] as Array<IResource>;

    filters: Filters;
    page: Page;
    trackBy: TrackBy;

    loadingPackageInfo: boolean;
    packageMouduleId: string;
    packageInfo: PackageModuleViewModel;
    loadingLocations: boolean;
    locations: Array<IResource>;
    loadingChargeTemplates: boolean;
    chargeTemplates: Array<IResource>;
    loadingProviders: boolean;
    providers: Array<IResource>;
    loadingChargeCategories: boolean;
    chargeCategories: Array<IResource>;
    loadingModules: boolean;
    modules: Array<ModuleModel>;
    showRepeatType: boolean;
    loadingModuleCharges: boolean;
    moduleCharges: Array<ModuleChargeModel>;
    selectedModule: ModuleModel;
    charges: Array<ICharge>;
    packageForm: FormGroup;
    isSubmitted: boolean;
    isSubmitting: boolean;
    pagination: Pagination;
    isCopyPackage: boolean;

    private modalRef: NgbModalRef;
    private moduleId: string;
    private packageModule: PackageModuleModel;
    private packageModuleDetails: Array<PackageModuleDetailModel>;
    private selectedModuleCharges: Array<ModuleChargeModel>;
    private packageModuleLimit: Array<object>;
    constructor(
        private readonly router: Router,
        private readonly appData: AppData,
        private readonly route: ActivatedRoute,
        private readonly modalService: NgbModal,
        private readonly httpService: HttpService,
        private readonly formBuilder: FormBuilder,
        private readonly notifyService: NotifyService,
        private readonly resourceService: ResourceService
    ) {
        this.page = new Page();
        this.trackBy = new TrackBy();
        this.filters = new Filters();
        this.initPagination();
        this.charges = [];
        this.selectedModuleCharges = new Array<ModuleChargeModel>();
    }

    ngOnInit(): void {
        this.setUserAccount();
        this.fetchLocations();
        this.buildForm();
        this.findPackageIdFromRoute();
    }

    onBack(): void {
        this.router.navigateByUrl("/app/masters/package-modules");
    }

    get form() {
        return this.packageForm.controls;
    }

    onAddModule(module: ModuleModel): void {
        let isInValid = false;
        if (module && module.modulesMasterId > 0)
            if (module.packageType)
                if (module.isChargeCategoryApplicable)
                    if ([PackageType.SingleSelect, PackageType.MultiSelect].includes(module.packageType as PackageType))
                        this.assignCharges(module);
                    else {
                        this.notifyService.warningToast("This module cannot be chosen because it should have a package type of 'S' or 'M'.");
                        isInValid = true;
                    }
                else
                    if ([PackageType.Limit, PackageType.Quantity].includes(module.packageType as PackageType))
                        this.assignCharges(module);
                    else {
                        this.notifyService.warningToast("This module cannot be chosen because it should have a package type of 'L' or 'Q'");
                        isInValid = true;
                    }
            else {
                this.notifyService.warningToast("A module without 'Package Type' cannot be added.");
                isInValid = true;
            }

        if (isInValid) {
            const modules = this.packageForm.get("modules").value as Array<ModuleChargeModel>;
            modules.splice(modules.findIndex(m => m.modulesMasterId === module.modulesMasterId), 1);
            this.packageForm.get("modules").setValue(modules);
        }
    }

    onRemoveModule(module: ModuleModel): void {
        const index = this.charges.findIndex(m => m.module.modulesMasterId === module.modulesMasterId);
        if (index !== -1) {
            this.charges.splice(index, 1);
            this.calcTotal();
        }
    }

    initializeSelectedModuleCharges(content: TemplateRef<any>): void {       
        this.modalRef = this.modalService.open(content, { size: "xl", windowClass: "custom-modal modal-dialog-scrollable", centered: true, backdrop: "static", keyboard: false });
    }
    onOpenModal(content: TemplateRef<any>, module: ModuleModel): void {
        this.selectedModule = module;
        this.selectedModuleCharges = [];
        this.fetchModuleCharges(module.modulesMasterId.toString(), content); 
        this.initializeSelectedModuleCharges(content);
    }

    onSelectCharge(moduleCharge: ModuleChargeModel, event: Event): void {
        const isSelected = (event.currentTarget as HTMLInputElement).checked;
        if (isSelected) {
            if (this.selectedModule.packageType === this.packageType.SingleSelect) {
                this.resetSelectedModuleCharges();
                moduleCharge.quantity = 1;
            }
            moduleCharge.quantity = 1;
            moduleCharge.isSelected = true;
            this.selectedModuleCharges.push(moduleCharge);
        } else {
            moduleCharge.quantity = null;
            moduleCharge.isSelected = false;
            moduleCharge.isFree = false;
            this.removeSelectedModuleCharge(moduleCharge);
        }
    }

    onSelectFree(moduleCharge: ModuleChargeModel, event: Event): void {
        const isSelected = (event.currentTarget as HTMLInputElement).checked;
        if (isSelected) {
            if (this.selectedModule.packageType === this.packageType.SingleSelect)
                this.resetSelectedModuleCharges();

            moduleCharge.quantity = 1;
            moduleCharge.isSelected = true;
            moduleCharge.isFree = true;
            this.selectedModuleCharges.push(moduleCharge);
        } else {
            moduleCharge.quantity = null;
            moduleCharge.isSelected = false;
            moduleCharge.isFree = false;
            this.removeSelectedModuleCharge(moduleCharge);
        }
    }

    onCloseModal(): void {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = undefined;
        }
        this.selectedModuleCharges = [];
        this.filters.init();
        this.loadingModuleCharges = undefined;
        this.showRepeatType = undefined;
    }

    onSubmitCharges(modulesMasterId: number): void {
        if (modulesMasterId > 0 && this.filterModuleCharges(modulesMasterId).length <= 0) {

            this.selectedModule.isAllInclude = true;

        }
        const selectedCharges = this.selectedModuleCharges.filter(m => m.isSelected && m.modulesMasterId===modulesMasterId);
        if (!selectedCharges || !selectedCharges.length) {
            alert("Please select charges");
            return;
        }

        const charge = this.charges.find(m => m.module.modulesMasterId === this.selectedModule.modulesMasterId);

        if (charge) {
            charge.moduleCharges.push(...selectedCharges);
        } else {
            this.charges.push({
                module: this.selectedModule,
                moduleCharges: selectedCharges
            } as ICharge);
        }

        this.calcTotal();
        this.onCloseModal();
    }
    resetModelValue(modules: ModuleModel[], module: ModuleModel): void {
        for (const item of modules) {
            if (item.modulesMasterId === module.modulesMasterId) {
                item.modelValue = 0;
                //item.modelValue1 = "";
                break;
            }
        }
    }
    onChangeModelValue(module: ModuleModel): void {
        const modelValue = module.modelValue;
        
        if (modelValue === null || isNaN(modelValue)) {
            this.resetModelValue(this.form.modules.value, module);  
            return;
        } 
        if (modelValue !== null && modelValue < 0) {
            this.resetModelValue(this.form.modules.value, module);  
            this.notifyService.warningToast("Negative values are not allowed");
            return;
        }    

        let charge: ICharge;
        if (this.charges && this.charges.length)
            charge = this.charges.find(m => m.module.modulesMasterId === module.modulesMasterId);

        const amount = module.packageType === this.packageType.Limit ? Number(modelValue) : 0;
        if (charge) {
            if (charge.moduleCharges && charge.moduleCharges.length) {
                charge.moduleCharges[0].quantity = module.packageType === this.packageType.Quantity ? Number(modelValue) : 1;
                charge.moduleCharges[0].isFree = false;
                charge.moduleCharges[0].amount = amount;
            } else {
                charge.moduleCharges = [{
                    modulesMasterId: module.modulesMasterId,
                    quantity: module.packageType === this.packageType.Quantity ? modelValue : 1,
                    isFree: false,
                    amount: amount
                } as ModuleChargeModel];
            }
        }
        else {
            this.charges.push({
                module: module,
                moduleCharges: [{
                    modulesMasterId: module.modulesMasterId,
                    quantity: module.packageType === this.packageType.Quantity ? modelValue : 1,
                    amount: amount,
                    isFree: false
                }]
            } as ICharge);
        }

        this.calcTotal();
    }

    filterModuleCharges(modulesMasterId: number): Array<ModuleChargeModel> {
        const module = this.charges.find(m => m.module.modulesMasterId === modulesMasterId);
        return module && module.moduleCharges || [];
    }

    onRemoveCharge(modulesMasterId: number, index: number): void {
        const moduleCharges = this.filterModuleCharges(modulesMasterId);
        moduleCharges.splice(index, 1);
        this.calcTotal();
    }

    onFilterCharges(): void {
        this.initPagination();
        this.filters.applied = !!this.filters.options.chargeName;
        this.fetchModuleCharges(this.moduleId);
    }

    onResetFilterCharges(): void {
        this.filters.init();
        this.initPagination();
        this.fetchModuleCharges(this.moduleId);
    }

    onNextPage(module: ModuleModel) {
        this.fetchModuleCharges(module.modulesMasterId.toString());
    }

    onSubmit(): void {
        this.isSubmitted = true;
        if (this.packageForm.invalid) return;

        // When No Charges
        if (!this.charges || !this.charges.length || !this.charges.map(m => m.moduleCharges).length) {
            this.notifyService.warning("To save package details, please add some charges.");
            return;
        }
        if (this.isCopyPackage) {
            this.packageForm.patchValue({
                packageMouduleId: 0
            })
        }
       
        const formData = this.packageForm.getRawValue();

        // When No Charges Assigned to a selected module
        let errorModules = [];
        (formData.modules as Array<ModuleModel>).forEach((m: ModuleModel) => {
            let charges = this.filterModuleCharges(m.modulesMasterId);
            if (!charges.length) errorModules.push(m.moduleName);
        });

        if (errorModules.length) {
            this.notifyService.warning(`To save package details, please add some charges for the chosen '${errorModules.join(", ")}' module${errorModules.length > 1 ? 's' : ''}.`);
            return;
        }

        const requestBody = {
            packageModule: {
                packageModuleId: formData.packageModuleId,
                locationId: formData.location.id,
                providerId: formData.provider && formData.provider.id ? formData.provider.id : null,
                packageName: formData.packageName,
                packageType: formData.packageType,
                payType: formData.payType,
                chargeModuleTemplateId: formData.chargeTemplate.id,
                modulesMasterIds: formData.modules.map((m: ModuleModel) => m.modulesMasterId).join(","),
                notes: formData.notes,
                exclusions: formData.exclusions,
                freeQuantity: formData.freeQuantity,
                quantity: formData.quantity,
                expiresIn: formData.expiresIn,
                userAccountId: this.page.userAccount.accountId,
                discountType: formData.discountType,
                discountPercentage: formData.discountPercentage,
                discountAmount: formData.discountAmount,
                createdBy: this.page.userAccount.accountId,
                createdByName: this.page.userAccount.fullName,
                modifiedBy: this.page.userAccount.accountId,
                modifiedByName: this.page.userAccount.fullName,
                loginRoleId: this.page.userAccount.roleId,
                comments: formData.comments,
                isAllInclude: formData.isAllInclude
            },
            packageDetails: [],
            packageModuleLimit: []
        }

        const modulesMasterIds = [], errors = [];
        this.charges.forEach((m: ICharge) => {
            if (!m.module.isChargeCategoryApplicable && !m.module.isPackageApplicable) {
                if (m.module.modelValue && m.moduleCharges && m.moduleCharges.length) {
                    modulesMasterIds.push(m.module.modulesMasterId);
                    const item = m.moduleCharges[0];
                    requestBody.packageDetails.push({
                        packageModuleId: formData.packageModuleId,
                        modulesMasterId: m.module.modulesMasterId,
                        packageModuleDetailId: item.packageModuleDetailId,
                        userAccountId: this.page.userAccount.accountId,
                        quantity: item.quantity,
                        amount: item.amount,
                        isFree: item.isFree
                    });
                }
                else
                    errors.push(`Please add value for ${m.module.moduleName}.`);
            } else {
                if (m.moduleCharges && m.moduleCharges.length) {
                    modulesMasterIds.push(m.module.modulesMasterId);
                    m.moduleCharges.forEach((cd: ModuleChargeModel) => {
                        requestBody.packageDetails.push({
                            packageModuleId: formData.packageModuleId,
                            referenceId: cd.referenceId,
                            modulesMasterId: cd.modulesMasterId,
                            packageModuleDetailId: cd.packageModuleDetailId,
                            userAccountId: this.page.userAccount.accountId,
                            quantity: cd.quantity,
                            amount: cd.amount,
                            isFree: cd.isFree
                        });
                    });
                }
                else
                    errors.push(`Please add some charges for ${m.module.moduleName} or remove the module from charge modules.`);
            }
        });
        formData.modules.forEach(x => {
            if (x.isAllInclude)
                requestBody.packageModuleLimit.push(x);
            else {
               if (x.includedLimit > 0)
                  requestBody.packageModuleLimit.push(x);
            }

        })

        if (errors && errors.length) {
            this.notifyService.warning(errors.join("<br/>"));
            return;
        }

        requestBody.packageModule.modulesMasterIds = modulesMasterIds.join(",");

        this.isSubmitting = true;
        this.httpService
            .post(ApiResources.getURI(ApiResources.packageModule.base, ApiResources.packageModule.modify), requestBody)
            .pipe(finalize(() => { this.isSubmitting = false; this.isSubmitted = false }), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (response: number) => {
                    if (response > 0) {
                        this.notifyService.successToast(`Package module has been ${requestBody.packageModule.packageModuleId > 0 ? 'updated' : 'added'} successfully.`);
                        this.onBack();
                        return;
                    }

                    if (response === -1) {
                        this.notifyService.warningToast("Error: Package Name Already Exists. The specified package name is already in use. Please choose a unique name for your package.");
                        return;
                    }

                    this.notifyService.defaultError();
                },
                error: (err: any) => {
                    this.notifyService.defaultError();
                }
            });
    }

    onChangePackageType(packageType: string): void {
        const payTypeControl = this.packageForm.get("payType");

        let payType: string;
        if (packageType === "O") {
            payType = "C";
            payTypeControl.disable();
        } else {
            payType = null;
            payTypeControl.enable();
        }

        payTypeControl.setValue(payType);
        this.onChangePayType(payType);
    }

    onChangePayType(payType: string): void {
        this.chargeTemplates = [];
        this.packageForm.get("provider").setValue(null);
        this.packageForm.get("chargeTemplate").setValue(null);
        this.resetCharges();

        this.packageForm.get("chargeTemplate").enable();
        const location = this.packageForm.get("location").value as IResource;
        payType && location && this.fetchChargeTemplates(location, payType);
    }

    onChangeLocation(location?: IResource): void {
        this.providers = [];
        this.chargeTemplates = [];
        this.packageForm.get("provider").setValue(null);
        this.packageForm.get("chargeTemplate").setValue(null);
        this.resetCharges();

        if (location?.id) {
            this.fetchProviders(location.id);
            const payType = this.packageForm.get("payType").value;
            payType && this.fetchChargeTemplates(location, payType);
        }
    }

    onChangeChargeTemplate(chargeTemplate?: IResource): void {
        this.resetCharges();
        if (chargeTemplate && chargeTemplate.id) {
            this.fetchModules(chargeTemplate.id);
            this.fetchChargeCategories(chargeTemplate.id);
        }
    }

    onChangeDiscountType(discountType: string): void {
        this.setDiscountControls(discountType, true)
    }

    ngOnDestroy(): void {
        this.page.unsubscribeAll();
        Swal.close()
    }

    private initPagination() {
        this.pagination = new Pagination();
        this.pagination.pageIndex = 1;
        this.pagination.pageSize = 15;
    }

    private findPackageIdFromRoute(): void {
        this.loadingPackageInfo = true;
        this.route.params
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(params => {
                if (params["copy"] && params["copy"] === "true") {
                    this.isCopyPackage = true;
                }
                const packageMouduleId = params["id"];               
                if (packageMouduleId) {
                    this.packageMouduleId = packageMouduleId;
                    this.findPackageInfo();
                }
                else this.loadingPackageInfo = false;               
            });
    }

    private findPackageInfo(): void {
        this.httpService
            .get(ApiResources.getURI(ApiResources.packageModule.base, ApiResources.packageModule.find), { id: this.packageMouduleId })
            .pipe(finalize(() => this.loadingPackageInfo = false), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (response: PackageModuleViewModel) => {
                    if (response?.packageModule && !!response?.packageModuleDetails?.length) {
                        this.packageInfo = response;
                        this.packageModule = response.packageModule;
                        this.packageModuleDetails = response.packageModuleDetails;
                        this.updateForm();
                    }
                },
                error: () => this.packageInfo = undefined
            });
    }

    private setUserAccount(): void {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                this.page.userAccount = userAccount || undefined;
                this.fetchLocations();
                if (this.page.userAccount?.locationId) {
                    this.fetchProviders(this.page.userAccount.locationId);
                }
            });
    }

    private fetchLocations(): void {
        this.loadingLocations = true;
        this.resourceService.locations(true)
            .pipe(finalize(() => this.loadingLocations = false), takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => this.locations = response);
    }

    private fetchChargeTemplates(location: IResource, payType: string): void {
        this.loadingChargeTemplates = true;
        this.resourceService.chargeModuleTemplates(location.id, payType)
            .pipe(finalize(() => { this.loadingChargeTemplates = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((chargeTemplates: Array<IResource>) => {
                this.chargeTemplates = chargeTemplates;

                const chargeTemplateControl = this.packageForm.get("chargeTemplate");
                if (payType === "C" && chargeTemplates?.length) {
                    const chargeTemplate = chargeTemplates.find(m => m.status)
                    if (chargeTemplate) {
                        chargeTemplateControl.setValue(chargeTemplate);
                        chargeTemplateControl.disable();
                        this.onChangeChargeTemplate(chargeTemplate);
                        return;
                    }
                }

                chargeTemplateControl.enable();
            });
    }

    private fetchProviders(locationId: number): void {
        this.loadingProviders = true;
        this.resourceService.providerAccountLocationMap(locationId)
            .pipe(finalize(() => this.loadingProviders = false), takeUntil(this.page.unSubscribe))
            .subscribe((providers: Array<IResource>) => {
                this.providers = providers;
                if (this.packageModule?.providerId) {
                    const provider = this.providers.find(m => m.id === this.packageModule.providerId);
                    this.packageForm.get("provider").setValue(provider);
                }
            });
    }

    private fetchChargeCategories(chargeModuleTemplateId: number): void {
        this.loadingChargeCategories = true;
        this.httpService
            .get(ApiResources.getURI(ApiResources.packageModule.base, ApiResources.packageModule.fetchChargeCategories), { chargeModuleTemplateId })
            .pipe(finalize(() => this.loadingChargeCategories = false), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (response: Array<IResource>) => this.chargeCategories = response,
                error: () => this.chargeCategories = []
            });
    }

    private fetchModules(chargeModuleTemplateId: number): void {
        this.loadingModules = true;
        this.httpService
            .get(ApiResources.getURI(ApiResources.packageModule.base, ApiResources.packageModule.fetchModules), { chargeModuleTemplateId })
            .pipe(finalize(() => this.loadingModules = false), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (response: Array<ModuleModel>) => {
                    this.modules = response;
                    if (this.packageModule?.modulesMasterIds) {
                        const modulesMasterIds = this.getIds(this.packageModule.modulesMasterIds);
                        const modules = this.modules.filter(m => modulesMasterIds.includes(m.modulesMasterId));
                        this.packageForm.get("modules").setValue(modules);

                        modules.forEach((module: ModuleModel) => {
                            this.assignCharges(module);
                        });

                        this.calcTotal();
                    } else {
                        this.packageForm.get("modules").setValue(null);
                    }
                },
                error: () => this.modules = []
            });
    }
    private fetchModuleCharges(modulesMasterId: string, content?: TemplateRef<any>): void {
        this.loadingModuleCharges = true;
        this.moduleId = modulesMasterId;
         
        const requestBody = Object.assign(UtilHelper.clone(this.filters.options), this.pagination);
        requestBody.locationId = this.form.location.value.id;
        requestBody.modulesMasterIds = modulesMasterId;
        requestBody.chargeModuleTemplateId = this.packageForm.get("chargeTemplate").value.id;

        this.httpService
            .post(ApiResources.getURI(ApiResources.packageModule.base, ApiResources.packageModule.fetchModuleCharges), requestBody)
            .pipe(finalize(() => this.loadingModuleCharges = false), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (charges: Array<ModuleChargeModel>) => {
                    if (!charges?.length) {
                        charges.forEach((item) => {
                            const selectedModuleCharge = this.selectedModuleCharges.find(m => m.modulesMasterId === item.modulesMasterId && m.referenceId === item.referenceId);
                            if (selectedModuleCharge) {
                                item.isSelected = true;
                                item.quantity = selectedModuleCharge.quantity;
                                item.isFree = selectedModuleCharge.isFree;
                            }
                        });
                        const selectedCharges = this.filterModuleCharges(charges[0]?.modulesMasterId);
                        if (selectedCharges?.length) {
                            charges.forEach(function (item) {
                                const selectedCharge = selectedCharges.find(m => m.modulesMasterId === item.modulesMasterId && m.referenceId === item.referenceId);
                                if (selectedCharge) {
                                    item.isSelected = true;
                                    item.quantity = selectedCharge.quantity;
                                    item.isFree = selectedCharge.isFree;
                                }
                            });
                        }
                    }

                    this.moduleCharges = charges;
                    UtilHelper.applyPagination(this.moduleCharges, this.pagination);
                    if (!charges?.length)
                        this.showRepeatType = charges[0]?.repeatTypeId > 0;
                    this.moduleCharges.forEach(moduleCharge => {
                        const selectedCharge = this.selectedModuleCharges.find(selected => selected.chargeName === moduleCharge.chargeName);
                        if (selectedCharge) {
                            moduleCharge.isSelected = true;
                            moduleCharge.quantity = selectedCharge.quantity;
                        }
                    });
                },
                error: () => {
                    this.moduleCharges = [];
                    this.notifyService.warning("Error while fetching charges");
                }
            });
    }

    private assignCharges(module: ModuleModel): void {
        let charges: Array<ModuleChargeModel>;
        if (this.packageModuleDetails && this.packageModuleDetails.length) {
            charges = this.packageModuleDetails
                .filter(m => m.modulesMasterId === module.modulesMasterId)
                .map(m => {
                    const item = new ModuleChargeModel();
                    item.packageModuleDetailId = m.packageModuleDetailId;
                    item.chargeGroupId = m.chargeGroupId;
                    item.chargeGroupName = m.chargeGroupName;
                    item.modulesMasterId = m.modulesMasterId;
                    item.chargeName = m.chargeName;
                    item.departmentId = m.departmentId;
                    item.departmentName = m.departmentName;
                    item.isFree = m.isFree;
                    item.amount = m.amount;
                    item.isSelected = true;
                    item.locationId = m.locationId;
                    item.moduleIcon = m.moduleIcon;
                    item.moduleName = m.moduleName;
                    item.modulesMasterId = m.modulesMasterId;
                    item.quantity = m.quantity;
                    item.referenceId = m.referenceId;
                    item.repeatTypeId = m.repeatTypeId;
                    item.repeatTypeName = m.repeatTypeName;
                    return item;
                });

            if (module.packageType === this.packageType?.Limit)
                module.modelValue = this.packageModuleDetails.filter(m => m.modulesMasterId === module.modulesMasterId)[0].amount;

            if (module.packageType === this.packageType?.Quantity)
                module.modelValue = this.packageModuleDetails.filter(m => m.modulesMasterId === module.modulesMasterId)[0].quantity;

            const existingModule = this.charges.find(m => m.module.modulesMasterId === module.modulesMasterId);
            if (existingModule && existingModule.module) {
                existingModule.module = module;
                existingModule.moduleCharges = charges;
            } else {
                this.charges.push({
                    module: module,
                    moduleCharges: charges
                } as ICharge);
            }

            this.selectedModuleCharges = charges;
        }
    }

    private getIds(value: string): Array<number> {
        if (value.indexOf(",") > -1)
            return value.split(",").map(m => parseInt(m));
        else
            return [parseInt(value)];
    }

    private resetSelectedModuleCharges(): void {
        this.selectedModuleCharges.forEach((item: ModuleChargeModel) => {
            item.isSelected = false;
            item.isFree = false;
            item.quantity = null;
        });
    }

    private setDiscountControls(discountType: string, updateValue: boolean): void {
        const discountPercentage = this.packageForm.get("discountPercentage");
        const discountAmount = this.packageForm.get("discountAmount");

        if (updateValue) {
            discountPercentage.setValue(null);
            discountAmount.setValue(null);
        }

        if (discountType === "P") {
            discountPercentage.setValidators([Validators.required]);
            discountAmount.clearValidators();

        } else if (discountType === "A") {
            discountAmount.setValidators([Validators.required]);
            discountPercentage.clearValidators();
        } else {
            discountPercentage.clearValidators();
            discountAmount.clearValidators();
        }

        discountPercentage.updateValueAndValidity();
        discountAmount.updateValueAndValidity();
    }

    private buildForm(): void {
        this.packageForm = this.formBuilder.group({
            packageModuleId: 0,
            packageName: [null, [Validators.required]],
            packageType: [null, [Validators.required]],
            payType: [null, [Validators.required]],
            location: [{ id: this.page.userAccount.locationId, name: this.page.userAccount.locationName } as IResource, [Validators.required]],
            provider: null,
            chargeTemplate: [null, [Validators.required]],
            modules: [[], [Validators.required]],
            notes: null,
            exclusions: null,
            freeQuantity: null,
            quantity: null,
            expiresIn: null,
            discountType: null,
            discountPercentage: null,
            discountAmount: null,
            comments: null,
            isAllInclude: null
        });
    }

    private updateForm(): void {
        const location = { id: this.packageModule.locationId, name: this.packageModule.locationName } as IResource;
        const chargeTemplate = { id: this.packageModule.chargeModuleTemplateId, name: this.packageModule.templateName, status: this.packageModule.payType === "C" } as IResource;
        const provider = this.packageModule.providerId ? { id: this.packageModule.providerId, value: this.packageModule.providerName } as IResource : null;

        this.packageForm.setValue({
            packageModuleId: this.isCopyPackage? 0 : this.packageModule.packageModuleId,
            packageName: this.isCopyPackage ? this.packageModule.packageName + " " + "copy" : this.packageModule.packageName,
            packageType: this.packageModule.packageType,
            payType: this.packageModule.payType,
            location: location,
            provider: provider,
            chargeTemplate: chargeTemplate,
            modules: [],
            notes: this.packageModule.notes,
            exclusions: this.packageModule.exclusions,
            freeQuantity: this.packageModule.freeQuantity,
            quantity: this.packageModule.quantity,
            expiresIn: this.packageModule.expiresIn,
            discountType: this.packageModule.discountType,
            discountPercentage: this.packageModule.discountPercentage,
            discountAmount: this.packageModule.discountAmount,
            comments: this.packageModule.comments,
            isAllInclude: this.packageModule.isAllInclude
        });

        this.packageModule.packageType !== "O" && this.packageForm.get("payType").enable();
        this.fetchChargeTemplates(location, this.packageModule.payType);
        this.fetchProviders(location.id);
        this.fetchModules(chargeTemplate.id);
        this.packageModule?.discountType && this.setDiscountControls(this.packageModule.discountType, false);
    }

    private resetCharges(): void {
        this.chargeCategories = [];
        this.modules = [];
        this.charges = [];
        this.packageForm.get("modules").setValue([]);
    }

    private removeSelectedModuleCharge(moduleCharge: ModuleChargeModel): void {
        const index = this.selectedModuleCharges.findIndex(m => m.modulesMasterId === moduleCharge.modulesMasterId && m.referenceId === moduleCharge.referenceId);
        index >= 0 && this.selectedModuleCharges.splice(index, 1);
    }

    private calcTotal(): void {
        let freeQuantity = 0, quantity = 0;
        if (this.charges && this.charges.length > 0) {
            this.charges.forEach((item: ICharge) => {
                item.moduleCharges.forEach(charge => {
                    if (!charge.isFree)
                        quantity += charge.quantity;
                    else {
                        freeQuantity += charge.quantity;
                        quantity += charge.quantity;
                    }
                });
            });
        }

        this.packageForm.get("quantity").setValue(quantity);
        this.packageForm.get("freeQuantity").setValue(freeQuantity ? freeQuantity : null);
    }
    onChangePackageLImitValue(module: ModuleModel) {
        if (module?.isAllInclude) {
            module.includedLimit = 0;
        }
        else {
            if (module?.includedLimit < 0) {
                this.notifyService.warningToast("Negative values are not allowed");
                return;
            }
            else
                module.isAllInclude = false;
        }
    }
}