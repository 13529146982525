import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnDestroy, OnInit, ViewEncapsulation, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Account, Setting } from "@shared/entities";
import { ApiResources, UtilHelper } from "@shared/helpers";
import { IResource, IUserAccount, Page } from "@shared/models";
import { AppConfig, AppData, HttpService, IdentityService, MenuService, NotifyService, ResourceService } from "@shared/services";
import { EmailValidator, MobileValidator } from "@shared/validators";
import { Subscription } from "rxjs";
import { debounceTime, finalize, takeUntil } from "rxjs/operators";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
class LoginRequest {
    type: string;
    username: string;
    countryId: number;
    password: string;
    accountTypes: Array<number>;
    deviceId: string;
    deviceToken: string;
    deviceType: string;
    locationId: number;
    code: string;
}

@Component({
    templateUrl: "./login.html",
    styleUrls: ['./login.css'],
    encapsulation: ViewEncapsulation.None
})
export class LoginPage implements OnInit, OnDestroy  {
    @ViewChild("templateOTP", { static: true }) templeteOTP: TemplateRef<any>;
    page: Page;

    loadingCountries: boolean;
    countries: Array<IResource>;
    locations: Array<IResource>;
    locationMap: Array<IResource>;
    loadingLocationMap: boolean;
    locationDropDown: boolean;

    env: string;
    envTitle: string;
    loginForm: FormGroup;
    submitting: boolean;
    submitted: boolean;
    logoBasics: Setting;
    logoSubscription: Subscription;
    loadingSettings: boolean;
    isTheme: boolean;
    themeColor: string;
    loading: boolean;
    settings: Array<Setting>;
    ipConfig: boolean;
    loadingPublicIp: boolean;
    publicIp: string;
    DropDown = true;  // Set this to control the visibility of the form group
    twoFA: boolean;
    accountsAutantation: LoginRequest;
    combinedCode: string;
    code = Array.from({ length: 6 }, () => ({ value: '' }));
    validOtp = false;

    modalRef: NgbModalRef;
    OtpValidation: boolean;
    constructor(
        private readonly httpService: HttpService,
        private readonly router: Router,
        private readonly formBuilder: FormBuilder,
        private readonly identityService: IdentityService,
        private readonly menuService: MenuService,
        private readonly resourceService: ResourceService,
        private readonly notifyService: NotifyService,
        private readonly appData: AppData,
        private readonly modalService: NgbModal,
    ) {
        this.locations = new Array<IResource>();
        this.countries = new Array<IResource>();
        this.buildForm();
        try {
            this.env = AppConfig.settings.env.toLowerCase();
            this.envTitle = AppConfig.settings.envTitle;
        }
        catch {
            //ignored;
        }
        this.page = new Page();
        this.locationDropDown = false;
        this.logoBasics = new Setting();

    }

    getLogoImage = () => {
        this.loading = true;

        this.httpService
            .get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), { type: "Logo", active: true })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loading = false }))
            .subscribe({
                next: (response: Array<Setting>) => {
                    if (response && response.length > 0) {
                        this.logoBasics = response[0];
                        if (UtilHelper.isEmpty(response[0].imageUrl)) {
                            response[0].imageUrl = `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${response[0].imageUrl}`;
                        }
                    }
                },
                error: () => {
                    this.logoBasics = new Setting();
                }
            });
    }

    private fetchCountries() {
        this.loadingCountries = true;
        this.resourceService.countries()
            .pipe(finalize(() => { this.loadingCountries = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.countries = response;
                this.loginForm.get("countryId").patchValue(response[0].id);
            });
    }

    private fetchLocationMap(type: string, value: string, ipConfig: boolean) {
        this.loadingLocationMap = true;
        this.resourceService.locationAccountDynamic(type, value, ipConfig)
            .pipe(finalize(() => { this.loadingLocationMap = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.setAccountLocation(response);
                this.twoFA = response[0]?.twoFA;

            });
    }

    private buildForm() {
        this.loginForm = this.formBuilder.group({
            type: [null],
            countryId: [null],
            locationId: [null, [Validators.required]],
            username: ["", [Validators.required]],
            password: ["", [Validators.required]],
            code: [""]
        });
        this.loginForm.get("countryId").valueChanges.subscribe(() => {
            this.loginForm.get("username").updateValueAndValidity();
        });

        this.loginForm.get("username").valueChanges.pipe(debounceTime(300)).subscribe((username: string) => {
            const typeControl = this.loginForm.get("type");
            const usernameControl = this.loginForm.get("username");

            if (username) {
                let type = "E";
                if (type === "M") {
                    usernameControl.setValidators([Validators.required, MobileValidator.isValid]);
                } else {
                    if (username.includes("@")) {
                        usernameControl.setValidators([Validators.required, EmailValidator.isValid]);
                    }
                    else {
                        usernameControl.setValidators([Validators.nullValidator])
                    }
                }
                typeControl.patchValue(type);
                if (type === "E") {
                    type = username.includes("@") ? "E" : "U";
                }
                this.fetchLocationMap(type, username, this.ipConfig);
                if (this.locations.length > 0) {
                    // send 2-factor authentication code to user email
                }
                if (this.twoFA === true) {
                    this.loginForm.get("code").setValidators([Validators.required]);
                    this.notifyService.warningToast("Please enter the OTP code.");
                }
            } else {
                this.locationDropDown = false;
                typeControl.patchValue(null);
                usernameControl.setValidators([Validators.required]);
            }
        });
    }

    get form() { return this.loginForm.controls; }

    onSubmit() {

        this.submitted = true;
        const combinedCode = this.code.map(item => item.value).join('');
        this.combinedCode = combinedCode;
        if (!this.loginForm.valid) {
            this.notifyService.warningToast("PLEASE ENTER VALID CREDENTIALS")
            return;
        }
        if (this.twoFA === true) {
            this.onOpenModel(this.templeteOTP);
            return;
        }

        const model = UtilHelper.clone(this.loginForm.getRawValue()) as LoginRequest;
        this.accountsAutantation = model;
        model.username = model.type && model.type === "M" ? model.countryId + ":" + model.username : model.username;
        model.deviceType = "Web";
        model.deviceId = Math.random().toString(36).substring(2) + Date.now().toString(36);
        model.deviceToken = Math.random().toString(36).substring(2) + Date.now().toString(36);
        delete model.type;
        delete model.countryId;
        this.submitting = true;
        this.httpService
            .post<IUserAccount>(ApiResources.getURI(ApiResources.account.base, ApiResources.account.authenticate), model, false)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                (account: IUserAccount) => {

                    account.deviceId = model.deviceId;
                    this.identityService.signIn(account)
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe(() => {
                            this.menuService.fetch(account.accountId, account.roleId, () => {
                                this.appData.setAccount(account);
                                this.router.navigate([this.menuService.getDefaultRoute]);
                            });

                        }, () => {
                            this.submitting = false;
                            this.notifyService.defaultError();
                        });
                },

                (error: HttpErrorResponse) => {
                    this.submitting = false;
                    const errorMessage = UtilHelper.handleError(error);
                    if (errorMessage) {
                        this.notifyService.warningToast(errorMessage);
                    } else {
                        this.notifyService.defaultError();
                    }
                }
            );

    }
    setAccountLocation = (found: Array<IResource>) => {
        if (found && found.length) {
            this.loginForm.patchValue({
                locationId: found[0].id
            });
            this.locations = found;
            this.locationDropDown = found.length > 1;
        } else {
            this.locationDropDown = false;
        }
    }

    private getSettingsLayoutCommon() {
        this.loadingSettings = true;
        this.httpService.get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), { name: "Theme Color" })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingSettings = false))
            .subscribe((response: Array<Setting>) => {
                if (response.length > 0) {
                    this.isTheme = response[0].active;
                    this.themeColor = response[0].value;
                }
                this.onToggleTheme(this.themeColor);
            }), () => { this.onToggleTheme(this.themeColor); };
    }
    onToggleTheme(color: string) {
        switch (color) {
            case "#827d77 #fec32b #f5f6f9 #f86262":
                this.yellowTheme();
                break;
            default:
                this.blueTheme();
        }
    }

    yellowTheme() {
        const head = document.getElementsByTagName('head')[0];
        const fileref = document.createElement("link")
        fileref.setAttribute("rel", "stylesheet")
        fileref.setAttribute("type", "text/css")
        const fileName = location.origin + location.pathname + "assets/css/yellow-theme.css";
        fileref.setAttribute("href", fileName);
        fileref.media = 'all';
        head.appendChild(fileref);
    }

    blueTheme() {
        const head = document.getElementsByTagName('head')[0];
        const fileref = document.createElement("link")
        fileref.setAttribute("rel", "stylesheet")
        fileref.setAttribute("type", "text/css")
        const fileName = location.origin + location.pathname + "assets/css/blue-theme.css";
        fileref.setAttribute("href", fileName);
        fileref.media = 'all';
        head.appendChild(fileref);
    }

    ngOnInit() {
        //this.getSettingsLayoutCommon();
        //this.getLogoImage();
        //this.getSettingsData();
        //this.fetchPublicIp();

        $("body").addClass("auth-fluid-pages pb-0");
        this.locationDropDown = false;
        //this.fetchCountries();
    }

    ngOnDestroy() {
        $("body").removeClass("auth-fluid-pages pb-0");
        this.page.unsubscribeAll();
    }

    private getSettingsData() {
        this.loadingSettings = true;
        this.httpService.get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch))
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingSettings = false))
            .subscribe((response: Array<Setting>) => {
                if (response.length > 0) {
                    this.settings = response;
                    let ipConfig = this.settings.find(s => s.name == "IpConfig");
                    this.ipConfig = ipConfig ? ipConfig.active : false;
                }

            });
    }

    fetchPublicIp() {
        this.loadingPublicIp = true;
        this.httpService.get(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchPublicIp))
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingPublicIp = false))
            .subscribe((response: string) => {
                this.publicIp = response;
            });
    }

    onInput(event: Event, index: number) {
        const input = event.target as HTMLInputElement;
        const value = input.value;
        if (value.length > 1) {
            input.value = value.slice(0, 1);
        }
        this.code[index].value = input.value;
        if (input.value.length === 1 && index < this.code.length - 1) {
            (document.querySelectorAll('input')[index + 1] as HTMLInputElement)?.focus();
        }
    }


    onKeyDown(event: KeyboardEvent, index: number) {
        const { key } = event;

        event.key
        if (key === 'Backspace' && index > 0 && !this.code[index].value) {
            setTimeout(() => {
                const prevInput = document.querySelectorAll('.input-field')[index - 1] as HTMLInputElement;
                if (prevInput) {
                    prevInput.focus();
                    prevInput.select();
                }
            });
        }
    }
    onInputChange(index: number, value: string) {
        this.code[index].value = value;
        if (value.length === 1 && index < this.code.length - 1) {
            setTimeout(() => {
                const nextInput = document.querySelectorAll('.input-field')[index + 1] as HTMLInputElement;
                if (nextInput) {
                    nextInput.focus();
                }
            });
        }
    }

    onOpenModel(content: TemplateRef<any>) {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            windowClass: "custom-modal effect-scale"
        });
    }

    onClose() {
        this.modalRef.close();
        this.modalRef = undefined;
    }

    onSubmitOtp() {
        this.OtpValidation = true;
        const combinedCode = this.code.map(item => item.value).join('');
        if (this.OtpValidation === true) {
            if (combinedCode === "") {
                this.loginForm.get("code").setValidators([Validators.required]);
                this.notifyService.warningToast("Please enter the OTP code.");
                return;
            }
            else {
                const requrest = {
                    username: this.loginForm.get("username").value.trim(),
                    code: combinedCode
                }
                this.httpService
                    .post<any>(ApiResources.getURI(ApiResources.account.base, ApiResources.account.verifyOTP), requrest)
                    .pipe(takeUntil(this.page.unSubscribe))
                    .subscribe((response: any) => {
                        this.validOtp = true
                    },
                        () => {
                            this.validOtp = false;
                            this.notifyService.warningToast("Please enter the valid OTP code.");
                            return;
                        }

                    );
                if (this.validOtp == false) {
                    return;
                }
            }
        }
        const model = UtilHelper.clone(this.loginForm.getRawValue()) as LoginRequest;
        this.accountsAutantation = model;
        model.username = model.type && model.type === "M" ? model.countryId + ":" + model.username : model.username;
        model.deviceType = "Web";
        model.deviceId = Math.random().toString(36).substring(2) + Date.now().toString(36);
        model.deviceToken = Math.random().toString(36).substring(2) + Date.now().toString(36);
        delete model.type;
        delete model.countryId;
        this.submitting = true;
        this.httpService
            .post<IUserAccount>(ApiResources.getURI(ApiResources.account.base, ApiResources.account.authenticate), model, false)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                (account: IUserAccount) => {

                    account.deviceId = model.deviceId;
                    this.identityService.signIn(account)
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe(() => {
                            this.menuService.fetch(account.accountId, account.roleId, () => {
                                this.appData.setAccount(account);
                                this.router.navigate([this.menuService.getDefaultRoute]);
                                this.modalRef.close();
                                this.modalRef = undefined;
                            });

                        }, () => {
                            this.submitting = false;
                            this.notifyService.defaultError();
                        });
                },

                (error: HttpErrorResponse) => {
                    this.submitting = false;
                    const errorMessage = UtilHelper.handleError(error);
                    if (errorMessage) {
                        this.notifyService.warningToast(errorMessage);
                    } else {
                        this.notifyService.defaultError();
                    }
                }
            );

    }


}