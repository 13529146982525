
<div class="d-flex align-items-center p-2" *ngIf="loading">
    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
    <span class="ml-2">Please wait while loading Package Details ...</span>
</div>
<div class="package-charges-loading" *ngIf="loadingCharges">
    <div class="d-flex align-items-center bg-white p-2">
        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
        <span class="ml-2">Please wait while loading Charges ...</span>
    </div>
</div>
<div class="mh-355 p-2 position-relative" *ngIf="!loading && !packageInfo">
    <no-data [title]="'Package Details'"></no-data>
</div>
<div class="row" *ngIf="!loading && packageInfo && !isShow && !showReport">
    <div class="col-12">
        <h5 class="card-header p-2 bg-light heading2 d-flex justify-content-between align-items-center" [class.border-radius-none]="!isCounselling" [class.bgr]="isCounselling">
            <ng-container>
                <ng-container class="font-16 mb-1">
                    <span *ngIf="!packageInfo.packageModule?.packageName" [textContent]="'Package Preview'"></span>
                    <ng-container *ngIf="packageInfo.packageModule?.packageName">
                        <span [class.text-primary]="!isCounselling" class="text-capitalize" [textContent]="packageInfo.packageModule?.packageName"></span>
                        <ng-container *ngIf="packageInfo.packageModule?.payType && packageInfo.packageModule?.packageType">
                            <span class="ml-1 font-13" [class.text-mute]="!isCounselling" [textContent]="'(' + (packageInfo.packageModule.payType | payType) + ' / '"></span>
                            <span class="font-13" [class.text-mute]="!isCounselling" [textContent]="(packageInfo.packageModule.packageType | packageType) + ')'"></span>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-container class="font-13" *ngIf="isFromCouselling">
                    <span [textContent]="locationName"></span>
                    <small class="text-mute ml-1" [textContent]="templateName"></small>
                </ng-container>
                <ng-container class="font-13" *ngIf="!isFromCouselling">

                    <ng-select [items]="locations" [loading]="loadingLocations" (change)="onChangeLocation($event)" bindLabel="name" placeholder="Select location">
                        <ng-template ng-option-tmp let-item="item">
                            <span class="font-13" [textContent]="item.name"></span>
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item">
                            <span class="font-13" [textContent]="item.name"></span>
                        </ng-template>
                    </ng-select>
                </ng-container>
                <ng-container *ngIf="!isFromCouselling">

                    <ng-select (change)="onChangeChargeTemplate($event)" [items]="chargeTemplates" [loading]="loadingChargeTemplates" bindValue="id" bindLabel="name" [notFoundText]="'No Charge Template found'" placeholder="Select charge template" [(ngModel)]="chargeModuleTemplateId">
                        <ng-template ng-option-tmp let-item="item">
                            <span class="font-weight-normal font-13" [textContent]="item.name"></span>
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item">
                            <span class="font-weight-normal font-13" [textContent]="item.name"></span>
                        </ng-template>
                    </ng-select>
                </ng-container>
            </ng-container>
            <div class="text-mute font-weight-normal font-13 d-flex flex-column">
                <div class="mb-1 d-flex align-items-center" *ngIf="packageInfo.packageModule?.providerName">
                    <span class="text-dark" style="width: 70px; display: inline-block;">Doctor</span>
                    <div class="text-capitalize">
                        <span [textContent]="packageInfo.packageModule.providerName"></span>
                        <small class="ml-1" [textContent]="'(' + packageInfo.packageModule.departmentName + ')'"></small>
                    </div>
                </div>
                <div class=" d-flex align-items-center" *ngIf="packageInfo.packageModule?.expiresIn">
                    <span class="text-dark" style="width: 70px; display: inline-block;">Expires in</span>
                    <div>
                        <span [textContent]="packageInfo.packageModule.expiresIn"></span>
                        <span class="ml-1" [textContent]="'days'"></span>
                    </div>
                </div>
            </div>
        </h5>
        <div class="pt-2" [class.px-2]="!isCounselling" [class.px-0]="!isCounselling">
            <div *ngIf="!chargeCategory" class="mb-2">
                <div class="d-flex align-items-center" *ngIf="loadingChargeCategories">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading Package Cost Details ...</span>
                </div>
                <ng-container *ngIf="!loadingChargeCategories && chargeCategories?.length">
                    <label class="mb-1 font-13 font-weight-bold text-primary font-13">Charge Categories</label>
                    <div class="package-details-container">
                        <table class="table table-centered table-sm mb-0">
                            <thead class="thead-no-bdr font-13">
                                <tr>
                                    <th>Charge Category</th>
                                    <th style="width: 180px" class="text-right">Sub Total</th>
                                    <th style="width: 180px" class="text-right">Discount</th>
                                    <th style="width: 180px" class="text-right">Total</th>
                                    <th class="text-right"></th>
                                </tr>
                            </thead>
                            <tbody class="font-13">
                                <tr *ngFor="let item of chargeCategories; let i = index;">
                                    <td style="width: 40%" class="pl-0 font-weight-semibold" [class.border-0]="i === 0">
                                        <span [textContent]="item.name"></span>
                                    </td>
                                    <td class="text-right" [class.border-0]="i === 0">
                                        <span [textContent]="item.subTotal | currency :'₹ ':'symbol':'1.2-2'"></span>
                                    </td>
                                    <td class="text-right" [class.border-0]="i === 0">
                                        <span [textContent]="item.discount | currency :'₹ ':'symbol':'1.2-2'"></span>
                                    </td>
                                    <td class="text-right" [class.border-0]="i === 0">
                                        <span [textContent]="item.total | currency :'₹ ':'symbol':'1.2-2'"></span>
                                    </td>
                                    <td [class.border-0]="i === 0" style="width: 50px" class="text-right pr-0">
                                        <a class="text-primary cursor-pointer" (click)="onSelectChargeCategory(item.id)"><i class="fe-eye"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
            </div>
            <div class="mb-1 w-100 font-13 d-flex align-items-end justify-content-between" *ngIf="showChargeCategories">
                <label class="font-weight-bold mb-0 text-primary">Package Details</label>
                <div class="d-flex align-items-center" *ngIf="chargeCategory">
                    <span class="text-muted mr-1">Charge Category : </span>
                    <ng-select class="ng-select-28px" [items]="chargeCategories" [loading]="loadingChargeCategories" [clearable]="true" bindLabel="name" placeholder="Select Charge Category" [(ngModel)]="chargeCategory" (change)="onSelectChargeCategory($event.id)" (clear)="onClearChargeCategory();">
                        <ng-template ng-option-tmp let-item="item">
                            <span class="font-weight-semibold font-11" [textContent]="item.name"></span>
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item">
                            <span class="font-weight-semibold font-11" [textContent]="item.name"></span>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="mb-1 w-100 font-13 d-flex align-items-end justify-content-between" *ngIf="!showChargeCategories && chargeCategory">
                <label class="font-weight-bold mb-0 font-12" [textContent]="chargeCategory.name"></label>
                <div *ngIf="!hidePrice && isCounselling" class="form-check form-check-primary">
                    <input class="form-check-input rounded-circle" [(ngModel)]="hidePrices" type="checkbox" id="chkHidePrices">
                    <label class="form-check-label" for="chkHidePrices">Hide Prices</label>
                </div>
            </div>
            <div class="package-details-container" [class.mb-2]="!isCounselling" *ngIf="packageInfo.modules?.length">
                <div class="package-details-section" *ngFor="let md of packageInfo.modules;">
                    <table class="table table-centered table-light-border mb-0">
                        <thead>
                            <tr>
                                <th style="width: 90px" colspan="2" [textContent]="md.moduleName"></th>
                                <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr [class.strike-item]="item.isNA && !hidePrices" *ngFor="let item of fetchPackageDetails(md.modulesMasterId);">
                                <td style="width: 30px" [textContent]="item.index"></td>
                                <td *ngIf="md.isChargeCategoryApplicable" [textContent]="item.chargeName || '-'"></td>
                                <td *ngIf="!md.isChargeCategoryApplicable && md.packageType === packageType.Limit" [textContent]="md.moduleName + ' Amount'"></td>
                                <td *ngIf="!md.isChargeCategoryApplicable && md.packageType === packageType.Quantity" [textContent]="'No.of ' + md.moduleName"></td>
                                <td style="width: 30px" [textContent]="item.quantity"></td>
                                <td style="width: 90px">
                                    <small *ngIf="item.repeatTypeName && md.isChargeCategoryApplicable" [textContent]="item.repeatTypeName" class="text-muted"></small>
                                </td>
                                <td class="text-right" style="width: 135px" *ngIf="!hidePrices">
                                    <span *ngIf="chargeCategory" [textContent]="((item.amount ?? 0) * (item.quantity ?? 0))  | currency :'₹ ':'symbol':'1.2-2'"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <table class="table table-centered table-responsive mb-0 mt-2 table-borderless font-13">
                    <tbody>
                        <tr *ngIf="packageInfo.packageModule?.exclusions" class="h-32 col-12">
                            <td style="width: 135px" class="pl-0 font-weight-bold">Exclusions</td>
                            <td>
                                <span class="mb-0 break-all" [textContent]="packageInfo.packageModule.exclusions"></span>
                            </td>
                        </tr>
                        <tr *ngIf="packageInfo.packageModule?.notes" class="h-32 col-12">
                            <td class="pl-0 pt-0 font-weight-bold">Package Notes</td>
                            <td class="pt-0">
                                <span class="mb-0 break-all" [textContent]="packageInfo.packageModule.notes"></span>
                            </td>
                        </tr>
                        <tr *ngIf="packageInfo.packageModule?.comments" class="h-32 col-12">
                            <td class="pl-0 pt-0 font-weight-bold">Comments</td>
                            <td class="pt-0">
                                <p class="mb-0 pre-line break-all" [textContent]="packageInfo.packageModule.comments"></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-centered package-amounts" *ngIf="!isCounselling">
                    <tbody>
                        <ng-container *ngIf="!chargeCategory">
                            <tr>
                                <td>Total Quantity</td>
                                <td [textContent]="packageInfo.packageModule.quantity"></td>
                            </tr>
                            <tr>
                                <td>Discount</td>
                                <td [textContent]="packageInfo.packageModule.discountPercentage ? packageInfo.packageModule.discountPercentage + '%' : ((packageInfo.packageModule.discountAmount || 0) | currency :'₹ ':'symbol':'1.2-2')"></td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="chargeCategory">
                            <tr>
                                <td>Total Quantity</td>
                                <td *ngIf="packageInfo.packageModule.quantity !== packageInfo.totalQuantity">
                                    <span [textContent]="packageInfo.totalQuantity"></span>
                                    <small class="font-11" [textContent]="' / ' + packageInfo.packageModule.quantity"></small>
                                </td>
                                <td *ngIf="packageInfo.packageModule.quantity === packageInfo.totalQuantity" [textContent]="packageInfo.totalQuantity"></td>
                            </tr>
                            <tr>
                                <td>Sub Total</td>
                                <td [textContent]="packageInfo.subTotal | currency :'₹ ':'symbol':'1.2-2'"></td>
                            </tr>
                            <tr class="text-success">
                                <td>Discount</td>
                                <td [textContent]="packageInfo.discount | currency :'₹ ':'symbol':'1.2-2'"></td>
                            </tr>
                            <tr class="font-weight-bold font-15">
                                <td>Total</td>
                                <td [textContent]="packageInfo.total | currency :'₹ ':'symbol':'1.2-2'"></td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="!loading && packageInfoAll && isShow && !showReport">
    <div class="col-12">
        <div *ngIf="packageInfoAll.length">
            <div *ngFor="let pack of packageInfoAll">
                <span class="package-module-title" [textContent]="pack.packageModule.packageName"></span>
                <div class="package-details-container" [class.mb-2]="!isCounselling" *ngIf="pack.modules?.length">
                    <div class="package-details-section" *ngFor="let md of pack.modules;">
                        <span class="package-module-title" [textContent]="md.moduleName"></span>
                        <div *ngIf="md.moduleName==='OT'">
                            <div *ngFor="let item of fetchPackageDetailsAll(md.modulesMasterId,pack)">
                                <table class="table table-centered table-light-border mb-0">
                                    <thead>
                                        <tr>
                                            <th style="width: 30px">Service Date</th>
                                            <th style="width: 30px">Service Name</th>
                                            <th style="width: 30px">Cost</th>
                                            <th style="width: 30px">Count</th>
                                            <th class="text-right" style="width: 135px">Amount</th>
                                            <th class="text-right" style="width: 35px">Sub Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style="width: 30px" [textContent]="pack.admissionPackageDate |  date :'dd/MM/yyyy'"></td>
                                            <td style="width: 30px" [textContent]="item.chargeName || '-'"></td>
                                            <td [textContent]="item.serviceTotal"></td>
                                            <td style="width: 30px">1</td>
                                            <td class="text-right" style="width: 135px" [textContent]="item.serviceTotal"></td>
                                            <td class="text-right" style="width: 35px" [textContent]="item.serviceTotal"></td>
                                        </tr>
                                        <tr *ngFor="let itemService of fetchSurgeries(item.referenceId)">
                                            <td style="width: 30px" [textContent]="pack.admissionPackageDate |  date :'dd/MM/yyyy'"></td>
                                            <td style="width: 30px" [textContent]="itemService.service"></td>
                                            <td [textContent]="'0'"></td>
                                            <td style="width: 30px">1</td>
                                            <td class="text-right" style="width: 135px" [textContent]="'0'"></td>
                                            <td class="text-right" style="width: 35px" [textContent]="'0'"></td>
                                        </tr>
                                </table>
                            </div>
                        </div>
                        <div *ngIf="md.moduleName!='OT'">
                            <table class="table table-centered table-light-border mb-0">
                                <thead>
                                    <tr>
                                        <th style="width: 30px">Service Date</th>
                                        <th style="width: 30px">Service Name</th>
                                        <th style="width: 30px">Cost</th>
                                        <th style="width: 30px">Count</th>
                                        <th class="text-right" style="width: 135px">Amount</th>
                                        <th class="text-right" style="width: 35px">Sub Total</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr [class.strike-item]="item.isNA" *ngFor="let item of fetchPackageDetailsAll(md.modulesMasterId,pack)">

                                        <td style="width: 30px" [textContent]="pack.admissionPackageDate |  date :'dd/MM/yyyy'"></td>
                                        <td style="width: 30px" *ngIf="md.isChargeCategoryApplicable" [textContent]="item.chargeName || '-'"> </td>
                                        <td style="width: 35px" *ngIf="!hidePrices">
                                            <span *ngIf="!item.isFree && chargeCategory" [textContent]="(item.amount || 0) | currency :'₹ ':'symbol':'1.2-2'"></span>
                                            <small *ngIf="item.isFree">Free <span class="font-10 text-muted" *ngIf="chargeCategory" [textContent]="'(' + ((item.amount || 0) | currency :'₹ ':'symbol':'1.2-2') + ')'"></span></small>
                                        </td>
                                        <td style="width: 30px" *ngIf="!md.isChargeCategoryApplicable && md.packageType === packageType.Quantity" [textContent]="'No.of ' + md.moduleName"></td>
                                        <td *ngIf="!md.isChargeCategoryApplicable && md.packageType === packageType.Limit" [textContent]="md.moduleName + ' Amount'"></td>
                                        <td style="width: 30px" [textContent]="item.quantity"></td>
                                        <td class="text-right" style="width: 135px" *ngIf="!hidePrices" [textContent]=(item.amount*item.quantity)></td>
                                        <td class="text-right" style="width: 35px" [textContent]="(item.amount*item.quantity)"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <table class="table table-centered table-responsive mb-0 mt-2 table-borderless font-13">
                        <tbody>
                            <tr *ngIf="pack.packageModule?.exclusions" class="h-32 col-12">
                                <td style="width: 135px" class="pl-0 font-weight-bold">Exclusions</td>
                                <td>
                                    <span class="mb-0 break-all" [textContent]="pack.packageModule.exclusions"></span>
                                </td>
                            </tr>
                            <tr *ngIf="pack.packageModule?.notes" class="h-32 col-12">
                                <td class="pl-0 pt-0 font-weight-bold">Package Notes</td>
                                <td class="pt-0">
                                    <span class="mb-0 break-all" [textContent]="pack.packageModule.notes"></span>
                                </td>
                            </tr>
                            <tr *ngIf="pack.packageModule?.comments" class="h-32 col-12">
                                <td class="pl-0 pt-0 font-weight-bold">Comments</td>
                                <td class="pt-0">
                                    <p class="mb-0 pre-line break-all" [textContent]="pack.packageModule.comments"></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-centered package-amounts" *ngIf="!isCounselling">
                        <tbody>
                            <ng-container *ngIf="!chargeCategory">
                                <tr>
                                    <td>Total Quantity</td>
                                    <td [textContent]="pack.packageModule.quantity"></td>
                                </tr>
                                <tr>
                                    <td>Discount</td>
                                    <td [textContent]="pack.packageModule.discountPercentage ? pack.packageModule.discountPercentage + '%' : ((pack.packageModule.discountAmount || 0) | currency :'₹ ':'symbol':'1.2-2')"></td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="chargeCategory">
                                <tr>
                                    <td>Total Quantity</td>
                                    <td *ngIf="pack.packageModule.quantity !== pack.totalQuantity">
                                        <span [textContent]="pack.totalQuantity"></span>
                                        <small class="font-11" [textContent]="' / ' + pack.packageModule.quantity"></small>
                                    </td>
                                    <td *ngIf="pack.packageModule.quantity === pack.totalQuantity" [textContent]="pack.totalQuantity"></td>
                                </tr>
                                <tr>
                                    <td>Sub Total</td>
                                    <td [textContent]="pack.subTotal | currency :'₹ ':'symbol':'1.2-2'"></td>
                                </tr>
                                <tr class="text-success">
                                    <td>Discount</td>
                                    <td [textContent]="pack.discount | currency :'₹ ':'symbol':'1.2-2'"></td>
                                </tr>
                                <tr class="font-weight-bold font-15">
                                    <td>Total</td>
                                    <td [textContent]="pack.total | currency :'₹ ':'symbol':'1.2-2'"></td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<!--<div *ngIf="!showReport">
    <div class="package-details-container">
        <div class="package-details-section" *ngFor="let md of packageInfo.packageModules;">
            <div class="d-flex"><h4>Package Name : &nbsp;</h4><span class="package-module-title" [textContent]="md.packageName"></span></div>
            <table class="table table-centered table-light-border mb-0">
                <tbody>
                    <ng-container [class.strike-item]="item.isNA" *ngFor="let item of fetchPackageModuleDetails(md.packageModuleId);">
                        <tr class="td-width" [textContent]="item.moduleName"></tr>

                        <tr *ngFor="let i of fetchPackageDetails(item.modulesMasterId);">
                            <td class="td-width-third" [textContent]="i.quantity"></td>
                            <td class="td-width-forth" [textContent]="i.chargeName || '-'"></td>
                            <td *ngIf="i.repeatTypeName && md.isChargeCategoryApplicable">
                                <small [textContent]="i.repeatTypeName" class="text-muted"></small>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
    <table class="table table-centered table-responsive mb-0 mt-2 table-borderless font-13">
        <tbody>
            <tr *ngIf="packageInfo.packageModule?.exclusions" class="h-32 col-12">
                <td class="pl-0 font-weight-bold third-width">Exclusions</td>
                <td>
                    <span class="mb-0 break-all" [textContent]="packageInfo.packageModule.exclusions"></span>
                </td>
            </tr>
            <tr *ngIf="packageInfo.packageModule?.notes" class="h-32 col-12">
                <td class="pl-0 pt-0 font-weight-bold">Package Notes</td>
                <td class="pt-0">
                    <span class="mb-0 break-all" [textContent]="packageInfo.packageModule.notes"></span>
                </td>
            </tr>
            <tr *ngIf="packageInfo.packageModule?.comments" class="h-32 col-12">
                <td class="pl-0 pt-0 font-weight-bold">Comments</td>
                <td class="pt-0">
                    <p class="mb-0 pre-line break-all" [textContent]="packageInfo.packageModule.comments"></p>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-centered package-amounts" *ngIf="!isCounselling">
        <tbody>
            <ng-container *ngIf="chargeCategory">
                <tr>
                    <td>Total Quantity</td>
                    <td *ngIf="packageInfo.packageModule.quantity !== packageInfo.totalQuantity">
                        <span [textContent]="packageInfo.totalQuantity"></span>
                        <small class="font-11" [textContent]="' / ' + packageInfo.packageModule.quantity"></small>
                    </td>
                    <td *ngIf="packageInfo.packageModule.quantity === packageInfo.totalQuantity" [textContent]="packageInfo.totalQuantity"></td>
                </tr>
                <tr>
                    <td>Sub Total</td>
                    <td [textContent]="packageInfo.subTotal | currency :'₹ ':'symbol':'1.2-2'"></td>
                </tr>
                <tr class="text-success">
                    <td>Discount</td>
                    <td [textContent]="packageInfo.discount | currency :'₹ ':'symbol':'1.2-2'"></td>
                </tr>
                <tr class="font-weight-bold font-15">
                    <td>Total</td>
                    <td [textContent]="packageInfo.total | currency :'₹ ':'symbol':'1.2-2'"></td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>-->


<div *ngIf="!loading && showReport && packageInfo">
    <div>
        <div class="d-flex align-items-center" *ngIf="loadingChargeCategories">
            <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
            <span class="ml-2">Please wait while loading Package Cost Details ...</span>
        </div>
        <ng-container *ngIf="!loadingChargeCategories && chargeCategories?.length">
            <label class="mb-1 font-13 font-weight-bold text-primary font-13">Charge Categories</label>
            <div class="package-details-container">
                <table class="table table-centered table-sm mb-0">
                    <thead class="thead-no-bdr font-13">
                        <tr>
                            <th>Charge Category</th>
                            <th style="width: 180px" class="text-right">Sub Total</th>
                            <th style="width: 180px" class="text-right">Discount</th>
                            <th style="width: 180px" class="text-right">Total</th>
                            <th class="text-right"></th>
                        </tr>
                    </thead>
                    <tbody class="font-13">
                        <tr *ngFor="let item of chargeCategories; let i = index;">
                            <td style="width: 40%" class="pl-0 font-weight-semibold" [class.border-0]="i === 0">
                                <span [textContent]="item.name"></span>
                            </td>
                            <td class="text-right" [class.border-0]="i === 0">
                                <span [textContent]="item.subTotal | currency :'₹ ':'symbol':'1.2-2'"></span>
                            </td>
                            <td class="text-right" [class.border-0]="i === 0">
                                <span [textContent]="item.discount | currency :'₹ ':'symbol':'1.2-2'"></span>
                            </td>
                            <td class="text-right" [class.border-0]="i === 0">
                                <span [textContent]="item.total | currency :'₹ ':'symbol':'1.2-2'"></span>
                            </td>
                            <td [class.border-0]="i === 0" style="width: 50px" class="text-right pr-0">
                                <a class="text-primary cursor-pointer" (click)="onSelectChargeCategory(item.id)"><i class="fe-eye"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </div>
    <div class="border">
        <div class="row_border column-gap10px">
            <div class="flex-grow-1 flex-basis48" *ngFor="let md of packageInfo.packageModules;">
                <div class="package-details-container" [class.mb-2]="!isCounselling" *ngIf="packageInfo?.modules?.length">
                    <div class="d-flex">
                        <h5 class="package-module-title" [textContent]="md.packageName"></h5>
                        (<span id="dynamic-value" [textContent]="md.expiresIn"></span>)
                    </div>
                    <table class="table table-centered table-light-border mb-0 report td_border">
                        <tbody>
                            <tr [class.strike-item]="item.isNA" *ngFor="let item of md.subRecords;">
                                <td [textContent]="item.chargeName || '---'"></td>
                            </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <fieldset class="form-fieldset">
        <legend class="text-center"><strong>Exclusions Notes</strong></legend>
        <div>
            <div class="notes-container">
                <ul>
                    <li>Any complications - Medical / Surgical</li>
                    <li>ICU/HDU/Isolation/NICU</li>
                    <li>Kiwi Pump Rs.8000/-, Donopa</li>
                    <li>Lab+Blood+Cross consultations</li>
                    <li>Any procedure performed in addition to the delivery</li>
                    <li>Additional stay, Additional Services</li>
                    <li>Expanded newborn screening</li>
                    <li>Water Birth / Hydrotherapy Rs 8,000/-</li>
                    <li>Additional pharmacy (May exceed Rs 20000/- to Rs 30000/- approx)</li>
                </ul>
                <p *ngIf="extraExclusionsNotes"><strong>Exclusions:</strong> </p>
                <span [innerHTML]="extraExclusionsNotes"></span>
            </div>
            <div class="border-top">
                <strong>  Advance Payable: As per the case type, full amount to be paid at the time of admission</strong>
            </div>
        </div>
    </fieldset>
    <div class="row p-2">
        <div>
            <span>Note:</span>
            <p>
                Above tariff are subject to change and Rooms are subject to availability at the time of admission.
            </p>
            <p>  Outside food is not allowed.</p>
            <p>
                I/We have understood all that was explained to me/us and assure to abide by the Hospital policy and the schedule of charges
                as per the medical requirement or consumption, and take full responsibility for settling the
            </p>

        </div>
    </div>

    <div class="row row-sm mt-3">
        <div class="col-4 col">
            <div class="form-group mb-0 d-flex flex-column">
                <label class="font-13 font-weight-bold mb-0 text-primary">Counselling Given To</label>
                <div class="d-flex font-13 align-items-center mb-1">
                    <p class="mb-0 font-weight-semibold text-capitalize">
                        <span class="text-uppercase" [textContent]="counselling.patientName"></span>
                    </p>
                </div>
                <div class="d-flex font-13 align-items-center mb-1">
                    <p class="mb-0 font-weight-semibold text-capitalize">
                        <span class="text-uppercase" [textContent]="counselling.umrNo"></span>
                    </p>
                </div>
                <div class="signature-container has-image no-hover" *ngIf="counselling.patientSignature">
                    <img class="img-fluid" alt="Patient Signature"
                         [src]="page.getImage(counselling.patientSignatureRootPath + counselling.patientSignature)" />
                </div>
            </div>
        </div>


        <div class="col-4 col">
            <div class="form-group mb-0 d-flex flex-column">
                <label class="font-13 font-weight-bold mb-0 text-primary">Attendent Given To</label>
                <div class="font-13 font-weight-semibold text-capitalize">
                    <div class="row mb-1">
                        <div><label>Attendent: &nbsp;</label></div>
                        <div><span class="text-uppercase" [textContent]="attendentName"></span></div>
                    </div>
                    <div class="row mb-1">
                        <div><label>Relation: &nbsp;</label></div>
                        <div><span *ngIf="attendentRelation" [textContent]="attendentRelation"></span></div>
                    </div>
                    <div class="row">
                        <div><label>Contact No: &nbsp;</label></div>
                        <div><span *ngIf="attendentContactNo" class="text-muted" [textContent]="attendentContactNo"></span></div>
                    </div>
                </div>
                <div class="signature-container has-image no-hover" *ngIf="counselling.attendentSignature">
                    <img class="img-fluid" alt="Attendent Signature"
                         [src]="page.getImage(counselling.attendentSignatureRootPath + counselling.attendentSignature)" />
                </div>
            </div>
        </div>
        <div class="col-4 col">
            <div class="form-group mb-0 d-flex flex-column align-items-end">
                <label class="font-13 font-weight-bold mb-0 text-primary">
                    Counsellor<small class="ml-1">(Counselling Given By)</small>
                </label>

                <div class="d-flex font-13 align-items-center mb-1">
                    <p class="mb-0 font-weight-semibold text-capitalize">
                        <span class="text-uppercase" [textContent]="counselling.createdByName"></span>
                    </p>
                </div>
                <div class="d-flex font-13 align-items-center mb-1">
                    <p class="mb-0 font-weight-semibold text-capitalize"
                       [textContent]="(counselling.createdDate | date : 'dd-MM-yyyy h:mm a')">
                    </p>
                </div>
                <div class="signature-container has-image no-hover" *ngIf="counselling.counsellorSignature">
                    <img class="img-fluid" alt="Counsellor Signature"
                         [src]="page.getImage(counselling.counsellorSignatureRootPath + counselling.counsellorSignature)" />
                </div>
            </div>
        </div>
    </div>
    <div class="mt-2 d-flex justify-content-between font-weight-bold">
        <div>
            <span>Printed by:</span>
            <span *ngIf="counselling && counselling.createdByName" [textContent]="counselling.createdByName"></span>
        </div>
        <div>
            <span>Print Date/Time:</span>
            <span [textContent]="date | date : 'd/MM/yyyy'"></span> /
            <span [textContent]="date | date : 'h:mm a'"></span>
        </div>
    </div>
</div>

<div *ngIf="isFooter">
    <banner-setting [reportName]='"Counselling Detail Report Footer"'></banner-setting>
</div>



