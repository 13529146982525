
<ng-template #TagMaster>
    <a class="btn btn-primary btn-sm " title="tagmaster" [routerLink]="['/app/masters/tag-master']"><i class="fe-plus mr-1"></i> TagMaster</a>

</ng-template>

<!--<div class="d-flex bd-highlight">-->
    <!--<div class="d-flex justify-content-start" *ngIf="!optionchange || !outsideEncounter">
        <div>
            <label>Forms</label>
            <select (change)="onOptionSelected($event.target.value)" [(ngModel)]="dropdownselVal">
                <option *ngFor="let option of  container" [value]="option.name">{{ option.name }}</option>
            </select>
        </div>
        <div>
            <button type="button" class="btn btn-light btn-sm mr-1" (click)="onAddForm(addFormNamePop)">  <i class="mdi mdi-plus-circle-outline"></i> Add Form</button>

        </div>
    </div>-->


    <!--<div class="d-flex justify-content-end">-->
        <!--edit controls-->
        <!--<ng-template #templateControlEdit>
            <div class="modal-header">
                <h4 class="modal-title">Controls Edit</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
            </div>
            <div class="modal-body overflow-auto edit-mdl-hgt">
                <form [formGroup]="editControlsForm" (ngSubmit)="onEditConytolsData()">

                    <div class="modal-body p-0 ">

                        <div class="row">
                            <div class="col-12">
                                <div class="form-group mb-3">
                                    <label class="mb-1">Lable <code>*</code></label>&nbsp;&nbsp;
                                    <input type="text" id="input1" formControlName="label" placeholder="Enter Lable">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" [disabled]="submitting" class="btn btn-primary btn-sm">
                            <span *ngIf="submitting">
                                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                Please wait..
                            </span>
                            <span *ngIf="!submitting">Submit</span>

                        </button>
                    </div>
                </form>

            </div>
        </ng-template>

    </div>
</div>-->



<!--create form-->
<ng-template #addFormNamePop>
    <div class="modal-header">
        <h4>welcome</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <form [formGroup]="addTemplate" (ngSubmit)="saveTemplate()">

        <div class="modal-body p-0 ">

            <div class="row">
                <div class="col-12">
                    <div class="form-group mb-3">
                        <label class="mb-1">form Name <code>*</code></label>&nbsp;&nbsp;
                        <input type="text" id="input1" formControlName="Name" placeholder="Enter formName">
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" [disabled]="submitting" class="btn btn-primary btn-sm">
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting">Submit</span>

            </button>
        </div>
    </form>
</ng-template>

<div class="row" *ngIf="0">
    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <div class="card-box p-0">
                    <div class="table-responsive">
                        <table class="table table-centered table-sm table-striped table-borderless mb-0">
                            <thead>
                                <tr>
                                    <th>Line Code</th>
                                    <th>Form Control Items </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of flowSheetControls">

                                    <td>
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="data.id"></h5>

                                        </div>
                                    </td>
                                    <td>
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="data.label"></h5>
                                        </div>
                                    </td>

                                    <td>
                                        <a href="javascript:;" (click)="onOpenControlEditModal(templateControlEdit, data)" class="action-icon text-primary font-20" placement="left" ngbTooltip="Edit"><i class="fe-edit"></i></a>
                                        <a href="javascript:;" class="action-icon text-danger ml-1" (click)="deleteFields(data)" placement="right" ngbTooltip="Delete"><i class="mdi mdi-trash-can"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #formmiddleware>

    <div class="modal-header">
        <h5>form render</h5>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="modal-body overflow-auto">
        <ng-container *ngIf="questions else loading">
            <form-middleware [questions]="questions" (onClose)="onCloseModal()"></form-middleware>
        </ng-container>
        <ng-template #loading>
            <h6>no data found...</h6>
        </ng-template>
    </div>


</ng-template>


<ng-template #controlsArrangment>
    <div class="modal-header">
        <h4 class="modal-title">Manage Controls</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="modal-body overflow-auto">
        <div cdkDropListGroup>

            <cdk-drop-list class="example-list" [cdkDropListData]="flowSheetControls" (cdkDropListDropped)="dropItem($event)">
                <div class="example-box" *ngFor="let item of flowSheetControls" cdkDrag [cdkDragData]="item">
                    {{item.label}}
                </div>
                <button class="btn btn-success btn-sm" (click)=onArrangments(flowSheetControls) type="button"><span class="mdi mdi-content-save-all"></span> Save</button>
                <button class="btn btn-danger btn-sm" type="button" (click)="onCloseModal();"> cancel</button>
            </cdk-drop-list>
        </div>

    </div>
    <div class="modal-footer">

    </div>

</ng-template>

<div class="modal-header" *ngIf="encounterModeType == 1 && encounterType == 2">
    <h5 class="modal-title">{{selsectionname}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="handleClose();">×</button>
</div>
<div class="dropdownselVal2 card-body custm-mdl-hgt" *ngIf="dropdownselVal2">

    <div [ngClass]="cssObjectTotal?.formClass || '' ">
        <div class="row">
            <ng-container *ngFor="let question of clonedArray; let j = index ">

                <ng-container *ngFor="let row of myformArray.controls; let i = index" [formGroup]="row">
                    <ng-container [ngSwitch]="question.controlType">
                        <ng-container *ngSwitchCase="'container'">
                            <div [ngClass]="question?.styleProperties?.containerCss?.join(' ') || ''" class="container-control mt-3" *ngIf="!question.styleProperties?.hideFeild">
                                <ng-container *ngTemplateOutlet="containerControl; context: { question, childGroupTags: question.childGroupTags,  formGroup: row.get(question.label), mainFormGroup : row }"></ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'grid'">
                            <div [ngClass]="question?.styleProperties?.containerCss?.join(' ') || ''" class="container-control mt-3" *ngIf="!question.styleProperties?.hideFeild" >
                                <ng-container *ngTemplateOutlet="gridControl; context: { question, childGroupTags: question.childGroupTags,  formGroup: row.get(question.label) ,mainFormGroup : row }"></ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <ng-container *ngTemplateOutlet="dynamicControl; context: { question: question, formGroup: row,mainFormGroup : row }"></ng-container>
                        </ng-container>
                    </ng-container>


                    <!--<ng-container [ngSwitch]="question.controlType">
                        <ng-container *ngSwitchCase="'heading'">
                            <div [ngClass]="question?.styleProperties?.containerCss || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" mt-3>
                                <div class="sec-heading mt-3">

                                    <span [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                                          [ngClass]="question?.styleProperties?.labelCss || ''"
                                          class="label-fnt"
                                          *ngIf="!question.styleProperties?.hideLable"> {{ question?.displayName }}</span>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'submitbutton'">

                            <div [ngClass]="question?.styleProperties?.containerCss || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-3">-->
                    <!--<button class="btn btn-info" [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                            [ngClass]="question?.styleProperties?.labelCss || ''" (click)="handleEvent($event, question.styleProperties?.script, question, row,clonedArray)">
                        {{ question?.label }}
                    </button>-->
                    <!--n
                            <button [disabled]="submitting" class="btn btn-success btn-sm" [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                                    [ngClass]="question?.styleProperties?.labelCss || ''"
                                    (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) :null"
                                    (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">
                                <span *ngIf="submitting">
                                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                    Please wait..
                                </span>
                                <span *ngIf="!submitting"><span class="mdi mdi-content-save-all"></span> {{ question?.displayName }}</span>
                            </button>

                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'eventbutton'">

                        <div [ngClass]="question?.styleProperties?.containerCss || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-3">
                            <button class="btn btn-danger btn-sm" [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                                    [ngClass]="question?.styleProperties?.labelCss || ''"
                                    (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script, row, row, clonedArray) :null"
                                    (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script, row, row, clonedArray) : null">
                                {{ question?.displayName }}
                            </button>
                        </div>

                    </ng-container>-->
                    <!--(click)="handleEvent($event, question.styleProperties?.script, question, row,clonedArray)"
                    (change)="handleEvent($event, question.styleProperties?.script,question, row,clonedArray)"
                    (blur)="handleEvent($event, question.styleProperties?.script, question, row,clonedArray)"-->
                    <!--<ng-container *ngSwitchCase="'text'">-->
                    <!--<input class="textbox" [ngClass]="question.cssClasses" [style]="question.Style" type="text" />-->
                    <!--<div [ngClass]="question?.styleProperties?.containerCss || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-3">

                                                        <div class="row">

                                                            <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                                                                   [ngClass]="question?.styleProperties?.labelCss || ''"
                                                                   class="label-fnt"
                                                                   *ngIf="!question.styleProperties?.hideLable"> {{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                                                            <div class="input-group" [ngClass]="question?.styleProperties?.inputDivCss || ''"
                                                                 [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')">
                                                                <div class="input-group-text" *ngIf="question.styleProperties?.preFix && question.styleProperties?.preFix !== ''">
                                                                    {{question.styleProperties?.preFix}}
                                                                </div>
                                                                <input [type]="question.controlType"
                                                                       [formControlName]="question.label"
                                                                       [value]="question.htmlData"
                                                                       [placeholder]="question.styleProperties.placeHolder ? question.styleProperties.placeHolder : '' "
                                                                       class="form-control"
                                                                       [minlength]="question.styleProperties?.minLength"
                                                                       [maxlength]="question.styleProperties?.maxLength"
                                                                       [id]="question.id"
                                                                       [class.disabled-element]="question.styleProperties?.isDisable"
                                                                       [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')"
                                                                       [ngClass]="[question?.styleProperties?.controlCss || '',   (question.styleProperties?.requiredField && row.get(question.label).invalid && submitted) ? 'is-invalid' : ''].join(' ')"
                                                                       (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">






                                                                <div class="input-group-text" *ngIf="question.styleProperties?.suFfix && question.styleProperties?.suFfix !== ''">
                                                                    {{question.styleProperties?.suFfix}}
                                                                </div>
                                                                <div *ngIf="question.styleProperties?.requiredField && row.get(question.label).invalid && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ?question.styleProperties?.customErrorMessage : 'required Feild' }}</div>
                                                                <div *ngIf=" row.get(question.label).hasError('whiteSpaces') && submitted" class="invalid-feedback show-must">     This field cannot contain only white spaces.</div>
                                                                <div *ngIf=" row.get(question.label).hasError('invalidEmail') && submitted" class="invalid-feedback show-must">Give proper email.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngSwitchCase="'number'">
                                                    <div [ngClass]="question?.styleProperties?.containerCss || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-3">

                                                        <div class="row">

                                                            <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                                                                   [ngClass]="question?.styleProperties?.labelCss || ''"
                                                                   class="label-fnt"
                                                                   *ngIf="!question.styleProperties?.hideLable"> {{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                                                            <div class="input-group" [ngClass]="question?.styleProperties?.inputDivCss || ''"
                                                                 [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')">
                                                                <div class="input-group-text" *ngIf="question.styleProperties?.preFix && question.styleProperties?.preFix !== ''">
                                                                    {{question.styleProperties?.preFix}}
                                                                </div>
                                                                <input [type]="question.controlType"
                                                                       [formControlName]="question.label"
                                                                       [value]="question.htmlData"
                                                                       class="form-control"
                                                                       [min]="question.styleProperties?.minLength"
                                                                       [max]="question.styleProperties?.maxLength"
                                                                       [placeholder]="question.styleProperties.placeHolder ? question.styleProperties.placeHolder : '' "
                                                                       [id]="question.id"
                                                                       [class.disabled-element]="question.styleProperties?.isDisable"
                                                                       [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')"
                                                                       [ngClass]="[question?.styleProperties?.controlCss || '',   question.styleProperties?.requiredField && row.get(question.label).invalid && submitted || (question.styleProperties?.customValidatorNames && row.get(question.label).invalid) ? 'is-invalid' : ''].join(' ')" (click)="handleEvent($event, question.styleProperties?.script, question, row)"
                                                                       (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">

                                                                <div class="input-group-text" *ngIf="question.styleProperties?.suFfix && question.styleProperties?.suFfix !== ''">
                                                                    {{question.styleProperties?.suFfix}}
                                                                </div>

                                                                <div *ngIf="question.styleProperties?.requiredField && row.get(question.label).invalid && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ?question.styleProperties?.customErrorMessage : 'required Feild' }}</div>
                                                                <div *ngIf="question.styleProperties?.customValidatorNames && row.get(question.label).hasError('whiteSpaces') && submitted" class="invalid-feedback show-must">This field cannot contain only white spaces.</div>
                                                                <div *ngIf="question.styleProperties?.customValidatorNames && row.get(question.label).hasError('invalidMobile') && submitted" class="invalid-feedback show-must"> Only 10 digits.</div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngSwitchCase="'textarea'">
                                                    <div [ngClass]="question?.styleProperties?.containerCss || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-3">

                                                        <div class="row">

                                                            <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                                                                   [ngClass]="question?.styleProperties?.labelCss || ''"
                                                                   class="label-fnt"
                                                                   *ngIf="!question.styleProperties?.hideLable"> {{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                                                            <div [ngClass]="question?.styleProperties?.inputDivCss || ''"
                                                                 [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')">

                                                                <textarea class="form-control"
                                                                          [placeholder]="question.styleProperties.placeHolder ? question.styleProperties.placeHolder : '' "
                                                                          [formControlName]="question.label"
                                                                          [id]="question.id"
                                                                          [class.disabled-element]="question.styleProperties?.isDisable"
                                                                          [minlength]="question.styleProperties?.minLength"
                                                                          [maxlength]="question.styleProperties?.maxLength"
                                                                          [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')"
                                                                          [ngClass]="[question?.styleProperties?.controlCss || '',   question.styleProperties?.requiredField && row.get(question.label).invalid && submitted? 'is-invalid' : '']" (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                          (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                          (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                          (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                          (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                          (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                          (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                          (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                          (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                          (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                          (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                          (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">
                    {{ question.htmlData }}</textarea>
                                                                <div *ngIf="question.styleProperties?.requiredField && row.get(question.label).invalid && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ?question.styleProperties?.customErrorMessage : 'required Feild' }}</div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </ng-container>

                                                <ng-container *ngSwitchCase="'date'">

                                                    <div [ngClass]="question?.styleProperties?.containerCss || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-3">

                                                        <div class="row">

                                                            <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                                                                   [ngClass]="question?.styleProperties?.labelCss || ''"
                                                                   class="label-fnt"
                                                                   *ngIf="!question.styleProperties?.hideLable"> {{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                                                            <div class="input-group" [ngClass]="question?.styleProperties?.inputDivCss || ''"
                                                                 [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')">
                                                                <div class="input-group-text" *ngIf="question.styleProperties?.preFix && question.styleProperties?.preFix !== ''">
                                                                    {{question.styleProperties?.preFix}}
                                                                </div>
                                                                <input [type]="question.controlType"
                                                                       [formControlName]="question.label"
                                                                       [value]="question.htmlData"
                                                                       class="form-control"
                                                                       [class.disabled-element]="question.styleProperties?.isDisable"
                                                                       [placeholder]="question.styleProperties.placeHolder ? question.styleProperties.placeHolder : '' "
                                                                       [id]="question.id"
                                                                       [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')"
                                                                       [ngClass]="[question?.styleProperties?.controlCss || '',   question.styleProperties?.requiredField && row.get(question.label).invalid && submitted ? 'is-invalid' : ''].join(' ')" (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                       (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">

                                                                <div class="input-group-text" *ngIf="question.styleProperties?.suFfix && question.styleProperties?.suFfix !== ''">
                                                                    {{question.styleProperties?.suFfix}}
                                                                </div>
                                                                <div *ngIf="question.styleProperties?.requiredField && row.get(question.label).invalid && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ?question.styleProperties?.customErrorMessage : 'required Feild' }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngSwitchCase="'noneYesNoSwitch'" class="no-padding">

                                                    <div class="radio-slider mt-3" [ngClass]="question?.styleProperties?.controlCss || ''">
                                                        <input id="none_{{ question.label }}" [name]="question.label" [formControlName]="question.label" type="radio" [value]="'None'" checked="checked">
                                                        <label for="none_{{ question.label }}">None</label>
                                                        <input id="yes_{{ question.label }}" [name]="question.label" [formControlName]="question.label" type="radio" [value]="'Yes'">
                                                        <label for="yes_{{ question.label }}">Yes</label>
                                                        <input id="no_{{ question.label }}" [name]="question.label" [formControlName]="question.label" type="radio" [value]="'No'">
                                                        <label for="no_{{ question.label }}">No</label>
                                                        <span class="selected"></span>
                                                    </div>
                                                </ng-container>




                                                <ng-container *ngSwitchCase="'dropdown'">

                                                    <div [ngClass]="question?.styleProperties?.containerCss || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-3">


                                                        <ng-container *ngIf="!question?.isEdit">
                                                            <div class="row">

                                                                <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                                                                       [ngClass]="question?.styleProperties?.labelCss || ''"
                                                                       class="label-fnt"
                                                                       *ngIf="!question.styleProperties?.hideLable"> {{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                                                                <div [ngClass]="question?.styleProperties?.inputDivCss || ''"
                                                                     [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')">
                                                                    <ng-container *ngIf="question?.lookupId">
                                                                        <select [formControlName]="question.label" class="form-control"
                                                                                [id]="question.id"
                                                                                [class.disabled-element]="question.styleProperties?.isDisable"
                                                                                [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')"
                                                                                [ngClass]="[question?.styleProperties?.controlCss || '',  question.styleProperties?.requiredField && row.get(question.label).invalid && submitted? 'is-invalid' : ''].join(' ')" (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">


                                                                            <option value="null" selected>--choose--</option>


                                                                            <ng-container *ngFor="let lookup of groupOfLookupValues">
                                                                                <option *ngIf="question?.lookupId === lookup.lookupId" [value]="lookup?.name">{{lookup.name}}</option>
                                                                            </ng-container>

                                                                        </select>

                                                                    </ng-container>
                                                                    <ng-container *ngIf="!question?.lookupId">
                                                                        <ng-select class="ng-select-sm" [items]="question.htmlData"
                                                                                   autocomplete="nope"
                                                                                   placeholder="Select options"
                                                                                   [formControlName]="question.label"
                                                                                   [closeOnSelect]="false"
                                                                                   [id]="question.id"
                                                                                   [class.disabled-element]="question.styleProperties?.isDisable"
                                                                                   [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')"
                                                                                   [ngClass]="[question?.styleProperties?.controlCss || '',  question.styleProperties?.requiredField && row.get(question.label).invalid && submitted ? 'is-invalid' : ''].join(' ')"
                                                                                   (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                   (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                   (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                   (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                   (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                   (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                   (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                   (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                   (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                   (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                   (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                                   (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">

                                                                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                                                <div class="ng-option disabled">
                                                                                    No Sections found for '{{searchTerm}}'
                                                                                </div>
                                                                            </ng-template>
                                                                        </ng-select>
                                                                    </ng-container>

                                                                    <div *ngIf="question.styleProperties?.requiredField && row.get(question.label).invalid && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ?question.styleProperties?.customErrorMessage : 'required Feild' }}</div>
                                                                </div>
                                                            </div>

                                                        </ng-container>

                                                        <div class="input-group input-group-icon align-items-center" *ngIf="question?.isEdit">
                                                            <div class="row">

                                                                <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                                                                       [ngClass]="question?.styleProperties?.labelCss || ''"
                                                                       class="label-fnt"
                                                                       *ngIf="!question.styleProperties?.hideLable"> {{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                                                                <div [ngClass]="question?.styleProperties?.inputDivCss || ''"
                                                                     [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')" class="dsply-flex">

                                                                    <select [formControlName]="question.label" class="form-control in-dsply-flex"
                                                                            [id]="question.id"
                                                                            [disabled]="question.styleProperties?.isDisable"
                                                                            [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')"
                                                                            [ngClass]="[question?.styleProperties?.controlCss || '',  question.styleProperties?.requiredField && row.get(question.label).invalid && submitted? 'is-invalid' : ''].join(' ')" (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                            (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                            (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                            (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                            (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                            (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                            (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                            (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                            (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                            (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                            (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                            (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">

                                                                        <option value="null" selected>--choose--</option>
                                                                        <ng-container *ngFor="let lookup of groupOfLookupValues">

                                                                            <option *ngIf="question?.lookupId === lookup.lookupId" [value]="lookup?.name">{{lookup.name}}</option>
                                                                        </ng-container>
                                                                    </select>
                                                                    <a href="javascript:;" (click)="onOpenlookUp(lookupcontainer,question)"
                                                                       class="action-icon text-primary font-20" placement="left" ngbTooltip="Edit">
                                                                        <i id="editIconLookUp" class="fe-edit"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngSwitchCase="'multiselect'">
                                                    <div [ngClass]="question?.styleProperties?.containerCss || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-3">

                                                        <div class="row">

                                                            <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                                                                   [ngClass]="question?.styleProperties?.labelCss || ''"
                                                                   class="label-fnt"
                                                                   *ngIf="!question.styleProperties?.hideLable"> {{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                                                            <div [ngClass]="question?.styleProperties?.inputDivCss || ''"
                                                                 [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')">

                                                                <ng-select [formControlName]="question.label"
                                                                           [items]="question.htmlData"
                                                                           [multiple]="true"
                                                                           bindLabel="name"
                                                                           placeholder="Choose"
                                                                           [closeOnSelect]="false"
                                                                           [id]="question.id"
                                                                           [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')"
                                                                           [ngClass]="[question?.styleProperties?.controlCss || '',  question.styleProperties?.requiredField && row.get(question.label).invalid && submitted? 'is-invalid' : ''].join(' ')" (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                           (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                           (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                           (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                           (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                           (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                           (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                           (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                           (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                           (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                           (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                                                           (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">

                                                                </ng-select>
                                                                <div *ngIf="question.styleProperties?.requiredField && row.get(question.label).invalid && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ?question.styleProperties?.customErrorMessage : 'required Feild' }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngSwitchCase="'checkbox'">
                                                    <div [ngClass]="question?.styleProperties?.containerCss || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-3">

                                                        <div class="row">

                                                            <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                                                                   [ngClass]="question?.styleProperties?.labelCss || ''"
                                                                   class="label-fnt"
                                                                   *ngIf="!question.styleProperties?.hideLable"> {{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                                                            <div [ngClass]="question?.styleProperties?.inputDivCss || ''"
                                                                 [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')">

                                                                <label *ngFor="let optionCtrl of row.get(question.label).controls; let k = index" class="mr-2">
                                                                    <input class="form-check-input"
                                                                           type="checkbox"
                                                                           [checked]="optionCtrl.value.value"
                                                                           (click)="onCheckboxChange($event, question.htmlData[k], question.label,$event, question.styleProperties?.script, question, row,clonedArray)" />-->
                    <!--(click)="handleEvent($event, question.styleProperties?.script, question, row)"-->
                    <!--<span class="pl-1">{{ question.htmlData[k] }}</span>
                                    </label>
                                </div>
                                <div *ngIf="question.styleProperties?.requiredField && row.get(question.label).invalid && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ?question.styleProperties?.customErrorMessage : 'required Feild' }}</div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'radio'">
                        <div [ngClass]="question?.styleProperties?.containerCss || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-3">
                            <div class="row">
                                <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                                       [ngClass]="question?.styleProperties?.labelCss || ''"
                                       class="label-fnt"
                                       *ngIf="!question.styleProperties?.hideLable"> {{ question?.label }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                                <div [ngClass]="question?.styleProperties?.inputDivCss || ''"
                                     [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')">
                                    <label *ngFor="let option of question.htmlData" class="mr-2">
                                        <input class="form-check-input"
                                               type="radio"
                                               [formControlName]="question.label"
                                               [checked]="option === row.get(question.label).value"
                                               [value]="option"
                                               [name]="question.label"
                                               (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                               (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                               (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                               (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                               (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                               (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                               (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                               (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                               (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                               (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                               (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                               (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">


                                        <span class="pl-1">{{ option }}</span>
                                    </label>
                                </div>
                                <div *ngIf="question.styleProperties?.requiredField && row.get(question.label).invalid && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ?question.styleProperties?.customErrorMessage : 'required Feild' }}</div>
                            </div>

                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'switch'">
                        <div [ngClass]="question?.styleProperties?.containerCss || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-3">
                            <div class="row">
                                <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                                       [ngClass]="question?.styleProperties?.labelCss || ''" class="label-fnt" *ngIf="!question.styleProperties?.hideLable">{{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>

                                <div [ngClass]="question?.styleProperties?.inputDivCss || ''" [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')">-->
                    <!-- Replace radio buttons with custom switch -->
                    <!--<div class="custom-control custom-switch" *ngFor="let option of question.htmlData">
                                        <input type="radio" class="custom-control-input" [id]="option" [formControlName]="question.label" [value]="option" [name]="question.label" [checked]="option === row.get(question.label).value">
                                        <label class="custom-control-label" [for]="option">{{ option }}</label>
                                    </div>
                                </div>
                                <div *ngIf="question.styleProperties?.requiredField && row.get(question.label).invalid && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ? question.styleProperties?.customErrorMessage : 'required Field' }}</div>
                            </div>

                        </div>
                    </ng-container>


                    <ng-container *ngSwitchCase="'ckEditor'">
                        <div [ngClass]="question?.styleProperties?.containerCss || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-3">

                            <div class="row">

                                <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                                       [ngClass]="question?.styleProperties?.labelCss || ''"
                                       class="label-fnt"
                                       *ngIf="!question.styleProperties?.hideLable"> {{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                                <div [ngClass]="question?.styleProperties?.inputDivCss || ''"
                                     [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')">

                                    <ckeditor [editor]="notesEditor"
                                              [config]="config"
                                              [formControlName]="question.label"
                                              [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')"
                                              [ngClass]="[question?.styleProperties?.controlCss || '',  question.styleProperties?.requiredField && row.get(question.label).invalid ? 'is-invalid' : ''].join(' ')"
                                              (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                              (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                              (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                              (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                              (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                              (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                              (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                              (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                              (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                              (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                              (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                              (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">
                                    </ckeditor>
                                    <div *ngIf="question.styleProperties?.requiredField && row.get(question.label).invalid && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ?question.styleProperties?.customErrorMessage : 'required Feild' }}</div>
                                </div>

                            </div>
                        </div>
                    </ng-container>-->
                    <!-- Comments textbox -->
                    <!--<ng-container *ngIf="question?.styleProperties?.requireComments == true ">
                            <div [ngClass]="question?.styleProperties?.controlCss || ''">
                                <input type="text"
                                       class="form-control"
                                       placeholder="comments"
                                       [formControlName]="question.label + '_comments'"
                                       [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')">
                            </div>
                        </ng-container>

                    </ng-container>-->

                </ng-container>

            </ng-container>

        </div>
    </div>


</div>


<div class="col text-right">
    <button type=button class="btn btn-sm btn-danger" (click)="handleClose()" *ngIf="encounterModeType == 1 && encounterType == 2">
        <span class="mdi mdi-close pr-1"></span> Close
    </button>
    <button (click)="handleSave()" [disabled]="submitting" class="btn btn-success btn-sm">
        <span *ngIf="submitting">
            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
            Please wait..
        </span>
        <span *ngIf="!submitting"><span class="mdi mdi-content-save-all pr-1"></span>Save</span>
    </button>


</div>






<ng-template #lookupcontainer>
    <div class="modal-header">
        <h4 class="modal-title">Add Values</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onOptionClose();">×</button>
    </div>
    <div class="modal-body custm-mdl-hgt">


        <lookup-design (sendlookupselctedValue)="handleLookupData($event)" [dataFormcreation]="previousLookup" (onClose)="onCloseModal()"></lookup-design>



    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success btn-xs" (click)="changeLookupOptions(selectedQuestionLabel)">
            <span class="mdi mdi-file-plus"></span>  Add Lookup
        </button>
        <button type="button" class="btn btn-danger btn-xs" data-dismiss="modal" aria-hidden="true" (click)="onOptionClose();"> <i class="mdi mdi-close"></i> Close</button>
    </div>
</ng-template>

<ng-template #dynamicControl let-question="question" let-row="formGroup" let-source="source" let-containerConfig="containerConfig" let-rowIndex = "rowIndex" let-gridType="gridType" let-mainFormGroup ="mainFormGroup" let-gridRowIndex="gridRowIndex" let-parentCntrlInfo="parentCntrlInfo">

    <ng-container [ngSwitch]="question.controlType">

        <ng-container *ngSwitchCase="'heading'">
            <!--<div [formGroup]="row" [ngClass]="question?.styleProperties?.containerCss || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" mt-3>-->
            <div [formGroup]="formGroup" [ngClass]="question?.styleProperties?.containerCss || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-2">
                <div class="sec-heading mt-3" [ngStyle]="getStyleObject(question?.styleProperties?.headingStyles || '')"
                     [ngClass]="question?.styleProperties?.headingCss || ''">

                    <span class="label-fnt"
                          *ngIf="!question.styleProperties?.hideLable"> {{ question?.displayName }}</span>
                </div>
            </div>
            <!--</div>-->
        </ng-container>

        <ng-container *ngSwitchCase="'submitbutton'">

            <div [formGroup]="row" [id]=" 'CntnrDv_' +question?.label " [ngClass]="question?.styleProperties?.containerCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-2">
                <button class="btn btn-info" [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                    [ngClass]="question?.styleProperties?.labelCss || ''" (click)="handleEvent($event, question.styleProperties?.script, question, row,clonedArray)">
                {{ question?.label }}
            </button>
                <!--<button [disabled]="submitting" class="btn btn-success btn-sm" [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                        [ngClass]="question?.styleProperties?.labelCss?.join(' ') || ''"
                        (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) :null"
                        (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">
                    <span *ngIf="submitting">
                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                        Please wait..
                    </span>
                 
                    <span *ngIf="!submitting"><span class="mdi mdi-content-save-all"></span> {{ question?.displayName }}</span>
                </button>-->

            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'eventbutton'">

            <div [formGroup]="row"  [id]=" 'CntnrDv_' +question?.label " [ngClass]="question?.styleProperties?.containerCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-2">
                <button class="btn btn-danger btn-sm" [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                        [ngClass]="question?.styleProperties?.labelCss?.join(' ') || ''"
                        (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script, row, row, clonedArray,mainFormGroup) :null"
                        (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script, row, row, clonedArray) : null">
                    {{ question?.displayName }}
                </button>
            </div>

        </ng-container>
        <!--(click)="handleEvent($event, question.styleProperties?.script, question, row,clonedArray)"
    (change)="handleEvent($event, question.styleProperties?.script,question, row,clonedArray)"
    (blur)="handleEvent($event, question.styleProperties?.script, question, row,clonedArray)"-->
        <ng-container *ngSwitchCase="'text'">
            <!--<input class="textbox" [ngClass]="question.cssClasses" [style]="question.Style" type="text" />-->

            <div [formGroup]="row"  [id]=" 'CntnrDv_' +question?.label " [ngClass]="question?.styleProperties?.containerCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-2" *ngIf="!question.styleProperties?.hideFeild">

                <div class="row">

                    <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                           [ngClass]="question?.styleProperties?.labelCss?.join(' ') || ''"
                           class="label-fnt"
                           *ngIf="gridType !== 'gridType' && !question.styleProperties?.hideLable"> {{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                    <div class="input-group" [ngClass]="question?.styleProperties?.inputDivCss?.join(' ') || ''"
                         [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')">
                        <div class="input-group-text" *ngIf="question.styleProperties?.preFix && question.styleProperties?.preFix !== ''">
                            {{question.styleProperties?.preFix}}
                        </div>
                        <input [type]="question.controlType"
                               [formControlName]="question.label"
                               [value]="question.htmlData"
                               [placeholder]="question.styleProperties.placeHolder ? question.styleProperties.placeHolder : '' "
                               class="form-control"
                               [minlength]="question.styleProperties?.minLength"
                               [maxlength]="question.styleProperties?.maxLength"
                               [id]="parentCntrlInfo?.controlType === 'grid' && gridRowIndex !== undefined ? question.label + '_' + gridRowIndex : question.id"
                               [readonly]="question.styleProperties?.isDisable"
                               [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')"
                               [ngClass]="[question?.styleProperties?.controlCss?.join(' ') || '',   (question.styleProperties?.requiredField && row.get(question.label).invalid && submitted) ? 'is-invalid' : ''].join(' ')"
                               (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup,parentCntrlInfo,gridRowIndex) : null"
                               (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup,parentCntrlInfo,gridRowIndex) : null"
                               (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">
                        <!--(click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"-->

                        <div class="input-group-text" *ngIf="question.styleProperties?.suFfix && question.styleProperties?.suFfix !== ''">
                            {{question.styleProperties?.suFfix}}
                        </div>
                        <ng-container *ngIf="question.styleProperties?.suFfixText && question.styleProperties?.suFfixText !== ''">
                            <div class="ml-2 mt-1">
                                {{ question?.styleProperties?.suFfixText }}
                            </div>
                        </ng-container>
                        <div *ngIf="showSuggestionsFor == (parentCntrlInfo?.controlType === 'grid' ? question.label + '_' + gridRowIndex : question.id)"  class="sug_list" id="SuggestionPoplayout">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <ng-container *ngFor="let key of objectKeys(suggestionsList[0])">
                                            <!-- Exclude 'key_field' by using *ngIf inside ng-container -->
                                            <th *ngIf="key != 'key_feild'">{{ key }}</th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let suggestion of suggestionsList"
                                        (click)="handleEvent($event, question.styleProperties?.script, row, questions, question, mainFormGroup, suggestion,parentCntrlInfo,gridRowIndex)">
                                        <ng-container *ngFor="let key of objectKeys(suggestion)">
                                            <!-- Exclude 'key_field' by using *ngIf inside ng-container -->
                                            <td *ngIf="key != 'key_field'">{{ suggestion[key] }}</td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <ng-container *ngIf="question?.styleProperties?.requireComments == true ">
                            <div class="input-group" [ngClass]="question?.styleProperties?.innerDivCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.innerDivStyles || '')">

                                <input type="text"
                                       [ngStyle]="getStyleObject(question?.styleProperties?.innerControlStyles || '')"
                                       [ngClass]="question?.styleProperties?.innerControlCss?.join(' ') || ''"
                                       class="form-control"
                                       placeholder="comments"
                                       [formControlName]="question.label + '_comments'">

                            </div>
                        </ng-container>
                        <div *ngIf="question.styleProperties?.requiredField && row.get(question.label).invalid && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ?question.styleProperties?.customErrorMessage : 'required Feild' }}</div>
                        <div *ngIf=" row.get(question.label).hasError('whiteSpaces') && submitted" class="invalid-feedback show-must">     This field cannot contain only white spaces.</div>
                        <div *ngIf=" row.get(question.label).hasError('invalidEmail') && submitted" class="invalid-feedback show-must">Give proper email.</div>
                    </div>

                </div>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="'number'">
            <div [formGroup]="row"  [id]=" 'CntnrDv_' +question?.label " [ngClass]="question?.styleProperties?.containerCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-2" *ngIf="!question.styleProperties?.hideFeild">

                <div class="row">

                    <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                           [ngClass]="question?.styleProperties?.labelCss?.join(' ') || ''"
                           class="label-fnt"
                           *ngIf="gridType !== 'gridType' && !question.styleProperties?.hideLable"> {{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                    <div class="input-group" [ngClass]="question?.styleProperties?.inputDivCss?.join(' ') || ''"
                         [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')">
                        <div class="input-group-text" *ngIf="question.styleProperties?.preFix && question.styleProperties?.preFix !== ''">
                            {{question.styleProperties?.preFix}}
                        </div>
                        <input [type]="question.controlType"
                               [formControlName]="question.label"
                               [value]="question.htmlData"
                               class="form-control"
                               [min]="question.styleProperties?.minLength"
                               [max]="question.styleProperties?.maxLength"
                               [placeholder]="question.styleProperties.placeHolder ? question.styleProperties.placeHolder : '' "
                               [id]="question.id"
                               [readonly]="question.styleProperties?.isDisable"
                               [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')"
                               [ngClass]="[question?.styleProperties?.controlCss?.join(' ') || '',   question.styleProperties?.requiredField && row.get(question.label).invalid && submitted || (question.styleProperties?.customValidatorNames && row.get(question.label).invalid) ? 'is-invalid' : ''].join(' ')"
                               (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">

                        <div class="input-group-text" *ngIf="question.styleProperties?.suFfix && question.styleProperties?.suFfix !== ''">
                            {{question.styleProperties?.suFfix}}
                        </div>
                        <div class="input-group-text" *ngIf="question.styleProperties?.suFfix && question.styleProperties?.suFfix !== ''">
                            {{question.styleProperties?.suFfix}}
                        </div>
                        <ng-container *ngIf="question.styleProperties?.suFfixText && question.styleProperties?.suFfixText !== ''">
                            <div class="ml-2 mt-1">
                                {{ question?.styleProperties?.suFfixText }}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="question?.styleProperties?.requireComments == true ">
                            <div class="input-group" [ngClass]="question?.styleProperties?.innerDivCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.innerDivStyles || '')">

                                <input type="text"
                                       [ngStyle]="getStyleObject(question?.styleProperties?.innerControlStyles || '')"
                                       [ngClass]="question?.styleProperties?.innerControlCss?.join(' ') || ''"
                                       class="form-control"
                                       placeholder="comments"
                                       [formControlName]="question.label + '_comments'">

                            </div>
                        </ng-container>
                        <div *ngIf="question.styleProperties?.requiredField && row.get(question.label).invalid && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ?question.styleProperties?.customErrorMessage : 'required Feild' }}</div>
                        <div *ngIf="question.styleProperties?.customValidatorNames && row.get(question.label).hasError('whiteSpaces') && submitted" class="invalid-feedback show-must">This field cannot contain only white spaces.</div>
                        <div *ngIf="question.styleProperties?.customValidatorNames && row.get(question.label).hasError('invalidMobile') && submitted" class="invalid-feedback show-must"> Only 10 digits.</div>

                    </div>

                </div>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="'textarea'">
            <div [formGroup]="row"  [id]=" 'CntnrDv_' +question?.label " [ngClass]="question?.styleProperties?.containerCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-2" *ngIf="!question.styleProperties?.hideFeild">

                <div class="row">

                    <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                           [ngClass]="question?.styleProperties?.labelCss?.join(' ') || ''"
                           class="label-fnt"
                           *ngIf="gridType !== 'gridType' && !question.styleProperties?.hideLable"> {{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                    <div [ngClass]="question?.styleProperties?.inputDivCss?.join(' ') || ''"
                         [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')">

                        <textarea class="form-control"
                                  [placeholder]="question.styleProperties.placeHolder ? question.styleProperties.placeHolder : '' "
                                  [formControlName]="question.label"
                                  [id]="question.id"
                                      [readonly]="question.styleProperties?.isDisable"
                                  [minlength]="question.styleProperties?.minLength"
                                  [maxlength]="question.styleProperties?.maxLength"
                                  [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')"
                                  [ngClass]="[question?.styleProperties?.controlCss?.join(' ') || '',   question.styleProperties?.requiredField && row.get(question.label).invalid && submitted? 'is-invalid' : '']" (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">
{{ question.htmlData }}</textarea>
                        <div class="input-group-text" *ngIf="question.styleProperties?.suFfix && question.styleProperties?.suFfix !== ''">
                            {{question.styleProperties?.suFfix}}
                        </div>
                        <ng-container *ngIf="question.styleProperties?.suFfixText && question.styleProperties?.suFfixText !== ''">
                            <div class="ml-2 mt-1">
                                {{ question?.styleProperties?.suFfixText }}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="question?.styleProperties?.requireComments == true ">
                            <div class="input-group" [ngClass]="question?.styleProperties?.innerDivCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.innerDivStyles || '')">

                                <input type="text"
                                       [ngStyle]="getStyleObject(question?.styleProperties?.innerControlStyles || '')"
                                       [ngClass]="question?.styleProperties?.innerControlCss?.join(' ') || ''"
                                       class="form-control"
                                       placeholder="comments"
                                       [formControlName]="question.label + '_comments'">

                            </div>
                        </ng-container>

                        <div *ngIf="question.styleProperties?.requiredField && row.get(question.label).invalid && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ?question.styleProperties?.customErrorMessage : 'required Feild' }}</div>
                    </div>

                </div>
            </div>


        </ng-container>

        <ng-container *ngSwitchCase="'date'">

            <div [formGroup]="row"   [id]=" 'CntnrDv_' +question?.label "  [ngClass]="question?.styleProperties?.containerCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-2" *ngIf="!question.styleProperties?.hideFeild">

                <div class="row">

                    <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                           [ngClass]="question?.styleProperties?.labelCss?.join(' ') || ''"
                           class="label-fnt"
                           *ngIf="gridType !== 'gridType' && !question.styleProperties?.hideLable"> {{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                    <div class="input-group" [ngClass]="question?.styleProperties?.inputDivCss?.join(' ') || ''"
                         [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')">
                        <div class="input-group-text" *ngIf="question.styleProperties?.preFix && question.styleProperties?.preFix !== ''">
                            {{question.styleProperties?.preFix}}
                        </div>
                        <input [type]="question.controlType"
                               [formControlName]="question.label"
                               [value]="question.htmlData"
                               class="form-control"
                                  [readonly]="question.styleProperties?.isDisable"
                               [placeholder]="question.styleProperties.placeHolder ? question.styleProperties.placeHolder : '' "
                               [id]="question.id"
                               [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')"
                               [ngClass]="[question?.styleProperties?.controlCss?.join(' ') || '',   question.styleProperties?.requiredField && row.get(question.label).invalid && submitted ? 'is-invalid' : ''].join(' ')" (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">

                        <div class="input-group-text" *ngIf="question.styleProperties?.suFfix && question.styleProperties?.suFfix !== ''">
                            {{question.styleProperties?.suFfix}}
                        </div>
                        <ng-container *ngIf="question.styleProperties?.suFfixText && question.styleProperties?.suFfixText !== ''">
                            <div class="ml-2 mt-1">
                                {{ question?.styleProperties?.suFfixText }}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="question?.styleProperties?.requireComments == true ">
                            <div class="input-group" [ngClass]="question?.styleProperties?.innerDivCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.innerDivStyles || '')">

                                <input type="text"
                                       [ngStyle]="getStyleObject(question?.styleProperties?.innerControlStyles || '')"
                                       [ngClass]="question?.styleProperties?.innerControlCss?.join(' ') || ''"
                                       class="form-control"
                                       placeholder="comments"
                                       [formControlName]="question.label + '_comments'">

                            </div>
                        </ng-container>

                        <div *ngIf="question.styleProperties?.requiredField && row.get(question.label).invalid && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ?question.styleProperties?.customErrorMessage : 'required Feild' }}</div>
                    </div>

                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'time'">

            <div [formGroup]="row"  [id]=" 'CntnrDv_' +question?.label " [ngClass]="question?.styleProperties?.containerCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-2" *ngIf="!question.styleProperties?.hideFeild">

                <div class="row">

                    <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                           [ngClass]="question?.styleProperties?.labelCss?.join(' ') || ''"
                           class="label-fnt"
                           *ngIf="gridType !== 'gridType' && !question.styleProperties?.hideLable"> {{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                    <div class="input-group" [ngClass]="question?.styleProperties?.inputDivCss?.join(' ') || ''"
                         [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')">
                        <div class="input-group-text" *ngIf="question.styleProperties?.preFix && question.styleProperties?.preFix !== ''">
                            {{question.styleProperties?.preFix}}
                        </div>
                        <input [type]="question.controlType"
                               [formControlName]="question.label"
                               [value]="question.htmlData"
                               class="form-control"
                               [readonly]="question.styleProperties?.isDisable"
                               [placeholder]="question.styleProperties.placeHolder ? question.styleProperties.placeHolder : '' "
                               [id]="question.id"
                               [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')"
                               [ngClass]="[question?.styleProperties?.controlCss?.join(' ') || '',   question.styleProperties?.requiredField && row.get(question.label).invalid && submitted ? 'is-invalid' : ''].join(' ')" (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                               (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">

                        <div class="input-group-text" *ngIf="question.styleProperties?.suFfix && question.styleProperties?.suFfix !== ''">
                            {{question.styleProperties?.suFfix}}
                        </div>
                        <ng-container *ngIf="question.styleProperties?.suFfixText && question.styleProperties?.suFfixText !== ''">
                            <div class="ml-2 mt-1">
                                {{ question?.styleProperties?.suFfixText }}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="question?.styleProperties?.requireComments == true ">
                            <div class="input-group" [ngClass]="question?.styleProperties?.innerDivCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.innerDivStyles || '')">

                                <input type="text"
                                       [ngStyle]="getStyleObject(question?.styleProperties?.innerControlStyles || '')"
                                       [ngClass]="question?.styleProperties?.innerControlCss?.join(' ') || ''"
                                       class="form-control"
                                       placeholder="comments"
                                       [formControlName]="question.label + '_comments'">

                            </div>
                        </ng-container>

                        <div *ngIf="question.styleProperties?.requiredField && row.get(question.label).invalid && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ?question.styleProperties?.customErrorMessage : 'required Feild' }}</div>
                    </div>

                </div>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="'noneYesNoSwitch'" class="no-padding">

            <div class="radio-slider mt-3" [ngClass]="question?.styleProperties?.controlCss?.join(' ') || ''"  [id]=" 'CntnrDv_' +question?.label ">
                <input id="none_{{ question.label }}" [name]="question.label" [formControlName]="question.label" type="radio" [value]="'None'" checked="checked">
                <label for="none_{{ question.label }}">None</label>
                <input id="yes_{{ question.label }}" [name]="question.label" [formControlName]="question.label" type="radio" [value]="'Yes'">
                <label for="yes_{{ question.label }}">Yes</label>
                <input id="no_{{ question.label }}" [name]="question.label" [formControlName]="question.label" type="radio" [value]="'No'">
                <label for="no_{{ question.label }}">No</label>
                <span class="selected"></span>
            </div>
        </ng-container>




        <ng-container *ngSwitchCase="'dropdown'">

            <div [formGroup]="row"  [id]=" 'CntnrDv_' +question?.label " [ngClass]="question?.styleProperties?.containerCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-2" *ngIf="!question.styleProperties?.hideFeild">


                <ng-container *ngIf="!question?.isEdit">
                    <div class="row">

                        <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                               [ngClass]="question?.styleProperties?.labelCss?.join(' ') || ''"
                               class="label-fnt"
                               *ngIf="gridType !== 'gridType' && !question.styleProperties?.hideLable"> {{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                        <div [ngClass]="question?.styleProperties?.inputDivCss?.join(' ') || ''"
                             [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')" class="input-group">
                            <ng-container *ngIf="question?.lookupId">
                                <div class="input-group-text" *ngIf="question.styleProperties?.preFix && question.styleProperties?.preFix !== ''">
                                    {{question.styleProperties?.preFix}}
                                </div>
                                <select [formControlName]="question.label" class="form-control in-dsply-flex"
                                        [id]="question.id"
                                            [readonly]="question.styleProperties?.isDisable"
                                        [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')"
                                        [ngClass]="[question?.styleProperties?.controlCss?.join(' ') || '',  question.styleProperties?.requiredField && row.get(question.label).invalid && submitted? 'is-invalid' : ''].join(' ')" (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">


                                    <option value="" selected>--choose--</option>


                                    <ng-container *ngFor="let lookup of groupOfLookupValues">
                                        <option *ngIf="question?.lookupId === lookup.lookupId" [value]="lookup?.name">{{lookup.name}}</option>
                                    </ng-container>

                                </select>
                                <div class="input-group-text" *ngIf="question.styleProperties?.suFfix && question.styleProperties?.suFfix !== ''">
                                    {{question.styleProperties?.suFfix}}
                                </div>
                                <ng-container *ngIf="question.styleProperties?.suFfixText && question.styleProperties?.suFfixText !== ''">
                                    <div class="ml-2 mt-1">
                                        {{ question?.styleProperties?.suFfixText }}
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="question?.styleProperties?.requireComments == true ">
                                    <div class="input-group" [ngClass]="question?.styleProperties?.innerDivCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.innerDivStyles || '')">

                                        <input type="text"
                                               [ngStyle]="getStyleObject(question?.styleProperties?.innerControlStyles || '')"
                                               [ngClass]="question?.styleProperties?.innerControlCss?.join(' ') || ''"
                                               class="form-control"
                                               placeholder="comments"
                                               [formControlName]="question.label + '_comments'">

                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!question?.lookupId">
                                <div class="input-group-text" *ngIf="question.styleProperties?.preFix && question.styleProperties?.preFix !== ''">
                                    {{question.styleProperties?.preFix}}
                                </div>
                                <!--<ng-select class="ng-select-sm w-100" [items]="question.htmlData"
                                           autocomplete="nope"
                                           placeholder="Select options"
                                           [formControlName]="question.label"
                                           [closeOnSelect]="false"
                                           [id]="question.id"
                                           [readonly]="question.styleProperties?.isDisable"
                                           [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')"
                                           [ngClass]="[question?.styleProperties?.controlCss?.join(' ') || '',  question.styleProperties?.requiredField && row.get(question.label).invalid && submitted ? 'is-invalid' : ''].join(' ')"
                                           (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                           (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                           (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                           (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                           (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                           (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                           (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                           (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                           (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                           (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                           (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                           (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">

                                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                        <div class="ng-option disabled">
                                            No Sections found for '{{searchTerm}}'
                                        </div>
                                    </ng-template>
                                </ng-select>-->
                                <select [formControlName]="question.label" class="form-control in-dsply-flex"
                                        [id]="question.id"
                                        [readonly]="question.styleProperties?.isDisable"
                                        [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')"
                                        [ngClass]="[question?.styleProperties?.controlCss?.join(' ') || '',  question.styleProperties?.requiredField && row.get(question.label).invalid && submitted? 'is-invalid' : ''].join(' ')" (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                        (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">

                                    <option value="" selected>--select--</option>

                                    <ng-container *ngIf="!question?.lookupId">
                                        <option *ngFor="let option of question.htmlData" [value]="option" [selected]="option === question.value">{{ option }}</option>
                                    </ng-container>

                                </select>


                                <div class="input-group-text" *ngIf="question.styleProperties?.suFfix && question.styleProperties?.suFfix !== ''">
                                    {{question.styleProperties?.suFfix}}
                                </div>
                                <ng-container *ngIf="question.styleProperties?.suFfixText && question.styleProperties?.suFfixText !== ''">
                                    <div class="ml-2 mt-1">
                                        {{ question?.styleProperties?.suFfixText }}
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="question?.styleProperties?.requireComments == true ">
                                    <div class="input-group" [ngClass]="question?.styleProperties?.innerDivCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.innerDivStyles || '')">

                                        <input type="text"
                                               [ngStyle]="getStyleObject(question?.styleProperties?.innerControlStyles || '')"
                                               [ngClass]="[question?.styleProperties?.innerControlCss?.join(' ') || '',   (question.styleProperties?.requiredField && row.get(question.label).invalid && submitted) ? 'is-invalid' : ''].join(' ')"
                                               class="form-control"
                                               placeholder="comments"
                                               [formControlName]="question.label + '_comments'">

                                    </div>
                                </ng-container>
                            </ng-container>

                            <div *ngIf="question.styleProperties?.requiredField  &&  row.get(question.label).invalid && row.get(question.label)?.errors?.required  && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ?question.styleProperties?.customErrorMessage : 'required Feild' }}</div>
                        </div>

                    </div>

                </ng-container>

                <div class="input-group input-group-icon align-items-center" *ngIf="question?.isEdit">
                    <div class="row">

                        <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                               [ngClass]="question?.styleProperties?.labelCss?.join(' ') || ''"
                               class="label-fnt"
                              *ngIf="gridType !== 'gridType' && !question.styleProperties?.hideLable"> {{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                        <div [ngClass]="question?.styleProperties?.inputDivCss?.join(' ') || ''"
                             [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')" class="dsply-flex">

                            <select [formControlName]="question.label" class="form-control in-dsply-flex"
                                    [id]="question.id"
                                   [readonly]="question.styleProperties?.isDisable"
                                    [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')"
                                    [ngClass]="[question?.styleProperties?.controlCss?.join(' ') || '',  question.styleProperties?.requiredField && row.get(question.label).invalid && submitted? 'is-invalid' : ''].join(' ')" (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                    (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                    (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                    (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                    (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                    (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                    (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                    (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                    (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                    (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                    (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                    (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">

                                <option value="" selected>--choose--</option>
                                <ng-container *ngFor="let lookup of groupOfLookupValues">

                                    <option *ngIf="question?.lookupId === lookup.lookupId" [value]="lookup?.name">{{lookup.name}}</option>
                                </ng-container>
                            </select>
                            <div class="input-group-text" *ngIf="question.styleProperties?.suFfix && question.styleProperties?.suFfix !== ''">
                                {{question.styleProperties?.suFfix}}
                            </div>
                            <ng-container *ngIf="question.styleProperties?.suFfixText && question.styleProperties?.suFfixText !== ''">
                                <div class="ml-2 mt-1">
                                    {{ question?.styleProperties?.suFfixText }}
                                </div>
                            </ng-container>
                            <ng-container *ngIf="question?.styleProperties?.requireComments == true ">
                                <div class="input-group" [ngClass]="question?.styleProperties?.innerDivCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.innerDivStyles || '')">

                                    <input type="text"
                                           [ngStyle]="getStyleObject(question?.styleProperties?.innerControlStyles || '')"
                                           [ngClass]="question?.styleProperties?.innerControlCss?.join(' ') || ''"
                                           class="form-control"
                                           placeholder="comments"
                                           [formControlName]="question.label + '_comments'">

                                </div>
                            </ng-container>
                            <div *ngIf="question.styleProperties?.requiredField  &&  row.get(question.label).invalid && row.get(question.label)?.errors?.required  && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ?question.styleProperties?.customErrorMessage : 'required Feild' }}</div>
                        </div>

                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="'multiselect'">
            <div [formGroup]="row"  [id]=" 'CntnrDv_' +question?.label " [ngClass]="question?.styleProperties?.containerCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-2" *ngIf="!question.styleProperties?.hideFeild">

                <div class="row">

                    <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                           [ngClass]="question?.styleProperties?.labelCss?.join(' ') || ''"
                           class="label-fnt"
                           *ngIf="gridType !== 'gridType' && !question.styleProperties?.hideLable"> {{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                    <div [ngClass]="question?.styleProperties?.inputDivCss?.join(' ') || ''"
                         [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')">
                        <div class="input-group-text" *ngIf="question.styleProperties?.preFix && question.styleProperties?.preFix !== ''">
                            {{question.styleProperties?.preFix}}
                        </div>
                        <ng-select [formControlName]="question.label"
                                   [items]="question.htmlData"
                                   [multiple]="true"
                                   bindLabel="name"
                                   placeholder="Choose"
                                   [closeOnSelect]="false"
                                   [id]="question.id"
                                    [readonly]="question.styleProperties?.isDisable"
                                   [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')"
                                   [ngClass]="[question?.styleProperties?.controlCss?.join(' ') || '',  question.styleProperties?.requiredField && row.get(question.label).invalid && submitted? 'is-invalid' : ''].join(' ')" (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">

                        </ng-select>
                        <div class="input-group-text" *ngIf="question.styleProperties?.suFfix && question.styleProperties?.suFfix !== ''">
                            {{question.styleProperties?.suFfix}}
                        </div>
                        <ng-container *ngIf="question.styleProperties?.suFfixText && question.styleProperties?.suFfixText !== ''">
                            <div class="ml-2 mt-1">
                                {{ question?.styleProperties?.suFfixText }}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="question?.styleProperties?.requireComments == true ">
                            <div class="input-group" [ngClass]="question?.styleProperties?.innerDivCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.innerDivStyles || '')">

                                <input type="text"
                                       [ngStyle]="getStyleObject(question?.styleProperties?.innerControlStyles || '')"
                                       [ngClass]="question?.styleProperties?.innerControlCss?.join(' ') || ''"
                                       class="form-control"
                                       placeholder="comments"
                                       [formControlName]="question.label + '_comments'">

                            </div>
                        </ng-container>
                        <div *ngIf="question.styleProperties?.requiredField && row.get(question.label).invalid && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ?question.styleProperties?.customErrorMessage : 'required Feild' }}</div>
                    </div>

                </div>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="'checkbox'">
            <div [formGroup]="row"  [id]=" 'CntnrDv_' +question?.label " [ngClass]="question?.styleProperties?.containerCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-3" *ngIf="!question.styleProperties?.hideFeild">

                <div class="row">

                    <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                           [ngClass]="question?.styleProperties?.labelCss?.join(' ') || ''"
                           class="label-fnt"
                            *ngIf="gridType !== 'gridType' && !question.styleProperties?.hideLable"> {{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                    <div [ngClass]="question?.styleProperties?.inputDivCss?.join(' ') || ''"
                         [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')">

                        <label *ngFor="let optionCtrl of row.get(question.label).controls; let k = index" class="mr-2">
                            <input class="form-check-input"
                                   type="checkbox"
                                      [class.disabled-element]="question.styleProperties.isDisable"
                                   [checked]="optionCtrl.value.value"
                                   (click)="onCheckboxChange($event, question.htmlData[k], question.label,$event, question.styleProperties?.script, question, row,clonedArray,rowIndex,mainFormGroup)" />
                            <!--(click)="handleEvent($event, question.styleProperties?.script, question, row)"-->
                            <span class="pl-1">{{ question.htmlData[k] }}</span>
                        </label>
                    </div>
                    <div *ngIf="question.styleProperties?.requiredField && row.get(question.label).invalid && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ?question.styleProperties?.customErrorMessage : 'required Feild' }}</div>
                    <ng-container *ngIf="question?.styleProperties?.requireComments == true ">
                        <div class="input-group" [ngClass]="question?.styleProperties?.innerDivCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.innerDivStyles || '')">


                            <input type="text"
                                   [ngStyle]="getStyleObject(question?.styleProperties?.innerControlStyles || '')"
                                   [ngClass]="question?.styleProperties?.innerControlCss?.join(' ') || ''"
                                   class="form-control"
                                   placeholder="comments"
                                   [formControlName]="question.label + '_comments'">

                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'radio'">
            <div [formGroup]="row"  [id]=" 'CntnrDv_' +question?.label " [ngClass]="question?.styleProperties?.containerCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-2" *ngIf="!question.styleProperties?.hideFeild">
                <div class="row">
                    <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                           [ngClass]="question?.styleProperties?.labelCss?.join(' ') || ''"
                           class="label-fnt"
                           *ngIf="gridType !== 'gridType' && !question.styleProperties?.hideLable"> {{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                    <div [ngClass]="question?.styleProperties?.inputDivCss?.join(' ') || ''"
                         [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')">
                        <label *ngFor="let option of question.htmlData" class="mr-2">
                            <input class="form-check-input"
                                   type="radio"
                                   [formControlName]="question.label"
                                      [class.disabled-element]="question.styleProperties.isDisable"
                                   [checked]="option === row.get(question.label).value"
                                   [value]="option"
                                   [name]="question.label"
                                   (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                   (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">


                            <span class="pl-1">{{ option }}</span>
                        </label>
                    </div>
                    <div *ngIf="question.styleProperties?.requiredField && row.get(question.label).invalid && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ?question.styleProperties?.customErrorMessage : 'required Feild' }}</div>
                    <ng-container *ngIf="question?.styleProperties?.requireComments == true ">
                        <div class="input-group" [ngClass]="question?.styleProperties?.innerDivCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.innerDivStyles || '')">

                            <input type="text"
                                   [ngStyle]="getStyleObject(question?.styleProperties?.innerControlStyles || '')"
                                   [ngClass]="question?.styleProperties?.innerControlCss?.join(' ') || ''"
                                   class="form-control"
                                   placeholder="comments"
                                   [formControlName]="question.label + '_comments'">

                        </div>
                    </ng-container>
                </div>

            </div>
        </ng-container>

        <ng-container *ngSwitchCase="'switch'">
            <div [ngClass]="question?.styleProperties?.containerCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-2"  [id]=" 'CntnrDv_' +question?.label ">
                <div class="row">
                    <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                           [ngClass]="question?.styleProperties?.labelCss?.join(' ') || ''" class="label-fnt"  *ngIf="gridType !== 'gridType' && !question.styleProperties?.hideLable">{{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>

                    <div [ngClass]="question?.styleProperties?.inputDivCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')">
                        <!-- Replace radio buttons with custom switch -->
                        <div class="custom-control custom-switch" *ngFor="let option of question.htmlData">
                            <input type="radio" class="custom-control-input" [id]="option" [formControlName]="question.label" [value]="option" [name]="question.label" [checked]="option === row.get(question.label).value">
                            <label class="custom-control-label" [for]="option">{{ option }}</label>
                        </div>
                    </div>
                    <div *ngIf="question.styleProperties?.requiredField && row.get(question.label).invalid && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ? question.styleProperties?.customErrorMessage : 'required Field' }}</div>
                </div>
            </div>
        </ng-container>


        <ng-container *ngSwitchCase="'ckEditor'">
            <div [formGroup]="row"  [id]=" 'CntnrDv_' +question?.label " [ngClass]="question?.styleProperties?.containerCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" class="mt-2" *ngIf="!question.styleProperties?.hideFeild">

                <div class="row">

                    <label [ngStyle]="getStyleObject(question?.styleProperties?.labelStyles || '')"
                           [ngClass]="question?.styleProperties?.labelCss?.join(' ') || ''"
                           class="label-fnt"
                           *ngIf="gridType !== 'gridType' && !question.styleProperties?.hideLable"> {{ question?.displayName }}<code *ngIf="question.styleProperties?.requiredField">*</code></label>
                    <div [ngClass]="question?.styleProperties?.inputDivCss?.join(' ') || ''"
                         [ngStyle]="getStyleObject(question?.styleProperties?.inputDivStyles || '')">

                        <ckeditor [editor]="notesEditor"
                                  [config]="config"
                                  [formControlName]="question.label"
                                     [class.disabled-element]="question.styleProperties.isDisable"
                                  [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')"
                                  [ngClass]="[question?.styleProperties?.controlCss?.join(' ') || '',  question.styleProperties?.requiredField && row.get(question.label).invalid ? 'is-invalid' : ''].join(' ')"
                                  (click)="isEventBound('click', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (change)="isEventBound('change', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (mouseenter)="isEventBound('mouseenter', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (mouseleave)="isEventBound('mouseleave', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (blur)="isEventBound('blur', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (focus)="isEventBound('focus', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (keydown)="isEventBound('keydown', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (keyup)="isEventBound('keyup', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (keypress)="isEventBound('keypress', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (input)="isEventBound('input', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (dblclick)="isEventBound('dblclick', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null"
                                  (contextmenu)="isEventBound('contextmenu', question.styleProperties?.script) ? handleEvent($event, question.styleProperties?.script,row, clonedArray,question,mainFormGroup) : null">
                        </ckeditor>
                        <ng-container *ngIf="question?.styleProperties?.requireComments == true ">
                            <div class="input-group" [ngClass]="question?.styleProperties?.innerDivCss?.join(' ') || ''" [ngStyle]="getStyleObject(question?.styleProperties?.innerDivStyles || '')">

                                <input type="text"
                                       [ngStyle]="getStyleObject(question?.styleProperties?.innerControlStyles || '')"
                                       [ngClass]="question?.styleProperties?.innerControlCss?.join(' ') || ''"
                                       class="form-control"
                                       placeholder="comments"
                                       [formControlName]="question.label + '_comments'">

                            </div>
                        </ng-container>
                        <div *ngIf="question.styleProperties?.requiredField && row.get(question.label).invalid && submitted" class="invalid-feedback show-must">{{ question.styleProperties?.customErrorMessage ?question.styleProperties?.customErrorMessage : 'required Feild' }}</div>
                    </div>


                </div>
            </div>
        </ng-container>

        <!-- Comments textbox -->
        <!--<ng-container *ngIf="question?.styleProperties?.requireComments == true ">
        <div [ngClass]="question?.styleProperties?.controlCss || ''">
            <input type="text"
                   class="form-control"
                   placeholder="comments"
                   [formControlName]="question.label + '_comments'"
                   [ngStyle]="getStyleObject(question?.styleProperties?.controlStyles || '')">
        </div>
    </ng-container>-->

    </ng-container>

</ng-template>
<ng-template #containerControl let-question="question" let-childGroupTags="childGroupTags" let-row="formGroup" let-source="source" let-containerConfig="containerConfig" let-mainFormGroup ="mainFormGroup">
    <div class="row" [formGroup]="row">
        <div class="col-12" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')" >
            <!--<a href="javascript:;"
               class="action-icon text-primary font-20" placement="left" ngbTooltip="modify">
                <span class="mdi mdi-grease-pencil" (click)="onOpenControlEditModal(question,source,containerConfig)"></span>
            </a>-->
            <!--<label>{{ question.displayName }}</label>-->
            <div class="sec-heading" [ngStyle]="getStyleObject(question?.styleProperties?.headingStyles || '')"
                 [ngClass]="question?.styleProperties?.headingCss || ''" *ngIf="!question.styleProperties?.hideLable" >

                <span > {{ question?.displayName }}</span>
            </div>
            <div class="row">
                <ng-container *ngFor="let child of childGroupTags">
                    <ng-container *ngTemplateOutlet="dynamicControl; context: { question: child, formGroup: row ,source:'container',containerConfig:question.id,mainFormGroup:mainFormGroup}"></ng-container>
                </ng-container>

            </div>
        </div>
    </div>

</ng-template>
<ng-template #gridControl let-question="question" let-childGroupTags="childGroupTags" let-row="formGroup" let-source="source" let-containerConfig="containerConfig" let-gridType="gridType" let-mainFormGroup = "mainFormGroup">
    <div class="row" [formGroup]="row">
        <div class="col-12 tb-nowrap tb-fd200" [ngStyle]="getStyleObject(question?.styleProperties?.containerStyles || '')">
            <div [class.disabled-element]="question.styleProperties?.hideLable">
                <h4> {{ question?.displayName }}</h4>
            </div>
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <!-- Column Headers -->
                            <th *ngFor="let child of childGroupTags">
                                {{ child?.displayName }}
                            </th>
                            <th *ngIf="!question?.styleProperties?.gridActions">
                                <a href="javascript:;" class="action-icon text-danger ml-1  font-20" (click)="addRow(question,formGroup)" placement="right" 
                                   ngbTooltip="Add"><i class="fe-plus mr-1"></i></a>
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let rowGroup of row.controls; let rowIndex = index">

                            <td *ngFor="let child of childGroupTags">

                                <ng-container *ngTemplateOutlet="dynamicControl; context: {  question: child,  formGroup: rowGroup, source: 'container',  containerConfig: question.id ,rowIndex:rowIndex, gridType:'gridType',mainFormGroup:mainFormGroup,gridRowIndex : rowIndex ,parentCntrlInfo : question }"></ng-container>
                            </td>
                            <td *ngIf="!question?.styleProperties?.gridActions">
                                <a *ngIf="row.controls.length != 1" href="javascript:;" class="action-icon text-danger ml-1" (click)="deleteRow(question.label,rowIndex)" placement="right"
                                   ngbTooltip="delete"><i class="mdi mdi-trash-can"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>

</ng-template>