import { Injectable } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MobileValidator, WhiteSpaceValidator, EmailValidator } from '../validators';
import { NotifyService } from './notify.service';


@Injectable()
export class QuestionControlService {
    validatorClasses: any = {
        WhiteSpaceValidator: WhiteSpaceValidator,
        MobileValidator: MobileValidator,
        EmailValidator: EmailValidator// Add other validators here if needed
    };

    handleCheckboxClick: any;
    constructor(private readonly notifyService: NotifyService) { }
    //toFormGroup(questions: any) {
    //    debugger

    //    const group: any = {};

    //    questions.forEach(question => {
    //        const labelName = question.label;
    //        const controlId = question.id;
    //        const controlType = question.controlType;

    //        if (controlType === 'container') {
    //            // Recursively create a form group for child controls within the container
    //            const childGroupTags = JSON.parse(question.childGroupTags || '[]');
    //            const childGroup = this.toFormGroup(childGroupTags); // Create the child FormGroup
    //            const containerGroup = new FormGroup({
    //                [labelName]: childGroup // Assign the child group under the container name
    //            });

    //            group[labelName] = containerGroup;

    //        } else if (question.controlType === 'checkbox') {

    //            const checkboxArray = new FormArray([]);

    //            question.htmlData.forEach(option => {
    //                const controlName = option; // Use the option as the control name
    //                const control = new FormControl({ value: "", name: controlName,isChecked:false });
    //                if (question.childTagId) {
    //                    if (JSON.parse(question.childTagId).length > 0) {
    //                        control['childTagIds'] = JSON.parse(question.childTagId);
    //                    }
    //                }
    //                if (question.styleProperties?.requiredField) {
    //                    // Custom validator to check if the control has a non-empty value
    //                    control.setValidators(control => {
    //                        const value = control.value;
    //                        if (!value || value.value === "") {
    //                            return { required: true };
    //                        }
    //                        return null;
    //                    });
    //                }
    //                checkboxArray.push(control);
    //            });

    //            group[question.label] = checkboxArray;


    //        } else if (question.controlType === 'multiselect') {

    //            group[question.label] = new FormControl([]);

    //        }
    //        else {

    //            group[question.label] =

    //                question.hasOwnProperty('selectedOption')
    //                    ? new FormControl(question.selectedOption ? "" : question.htmlData)
    //                    : new FormControl(question.value || "");

    //        }

    //        if (question.styleProperties?.requireComments === true) {
    //            group[question.label + '_comments'] = new FormControl(question.value || "");
    //            group[question.label + '_comments'].id = controlId;
    //            group[question.label + '_comments'].controlType = 'text';
    //        }
    //        if (question.styleProperties?.requiredField === true) {
    //            group[question.label].setValidators(Validators.required);
    //        }
    //        if (question.styleProperties?.events) {
    //            const clickFunction = question.styleProperties.events;

    //            group[question.label].valueChanges.subscribe((value: any) => {
    //                clickFunction(value); // Execute the click function with the new value when the value changes
    //            });

    //        }
    //        if (question.styleProperties?.customValidatorNames) {
    //            const validatorNames = question.styleProperties.customValidatorNames.split(';');

    //            // Iterate over each validator name
    //            validatorNames.forEach(validatorName => {
    //                // Split the validator name to get the class name and method name
    //                const [className, methodName] = validatorName.trim().split('.');

    //                if (className && methodName) {
    //                    const validatorClass = this.validatorClasses[className];

    //                    if (validatorClass && typeof validatorClass[methodName] === 'function') {
    //                        const validatorFunction = validatorClass[methodName];

    //                        // Apply the validator function to the form control
    //                        const existingValidators = group[question.label].validator;
    //                        const newValidators = existingValidators
    //                            ? [existingValidators, validatorFunction]
    //                            : validatorFunction;

    //                        group[question.label].setValidators(newValidators);
    //                    } else {
    //                        console.error(`Custom validator '${className}.${methodName}' not found or not a function.`);
    //                    }
    //                }
    //            });
    //        }

    //        if (group[question.label] instanceof FormControl || group[question.label] instanceof FormArray) {
    //            group[question.label].id = controlId;
    //            group[question.label].controlType = controlType;
    //            group[question.label].labelName = labelName;
    //        }
    //        console.log(question)


    //    });

    //    return new FormGroup(group);
    //}

    private createCheckboxGroup(question: any): FormArray {
        const checkboxArray = new FormArray([]);

        question.htmlData.forEach(option => {
            const control = new FormControl({ value: "", name: option, isChecked: false });

            if (question.childTagId) {
                if (JSON.parse(question.childTagId).length > 0) {
                    control['childTagIds'] = JSON.parse(question.childTagId);
                }
            }

            if (question.styleProperties?.requiredField) {
                // Custom validator to check if the control has a non-empty value
                control.setValidators(control => {
                    const value = control.value;
                    return !value || value.value === "" ? { required: true } : null;
                });
            }

            checkboxArray.push(control);
        });

        return checkboxArray;
    }
    toFormGroup(questions: any): FormGroup {
        const group: any = {};

        questions.forEach(question => {
            const labelName = question.label;
            const controlId = question.id;
            const controlType = question.controlType;
            const displayName = question.displayName

            if (controlType === 'checkbox') {
                group[labelName] = this.createCheckboxGroup(question);
            } else if (controlType === 'multiselect') {

                group[labelName] = this.createMultiSelectControl(question);
            } else if (controlType === 'container') {
                //const containerLabel = `${labelName}_heading`;
                //group[containerLabel] = this.createHeadingControl(question);


                //group[labelName] = this.createHeadingControl(question);
                if (question?.childGroupTags) {
                    this.createContainerControl(question, group);   // For nested FormGroup
                }

               
            } else if (controlType === 'grid') {
                // Handle grid control type by creating a FormArray
                group[labelName] = this.createGridControl(question);  // Add FormArray for grid
            } else {
                group[labelName] = this.createDefaultControl(question);
            }
            //const controlKey = controlType === 'container' ? `${labelName}_container` : labelName;


            if (question.styleProperties?.requireComments) {
                const commentControlLabel = `${labelName}_comments`;
                group[commentControlLabel] = new FormControl(''); // Create a required comments control
                this.setControlProperties(group[commentControlLabel], question, `${controlId}_comments`, question.id,true);
            }


            const controlKey = labelName;
            if (group[controlKey]) {
                this.setControlProperties(group[controlKey], question, controlId);
            }
           
            //this.setControlProperties(group[labelName], question, controlId);
        });

        return new FormGroup(group);
    }
    createGridControl(question: any): FormArray {
        const gridArray = new FormArray([]);

        if (question?.childGroupTags) {
            // Loop through each row and create a FormGroup for the row
          
                const rowGroup = this.createRowGroup(question);
                gridArray.push(rowGroup);  // Add the row (FormGroup) to the FormArray
          
        }

        return gridArray;
    }
    createRowGroup(question: any): FormGroup {
        const rowGroup: any = {};

        question.childGroupTags.forEach((column) => {
            const columnLabel = column.label;

            // Generate form controls for the columns based on their type (checkbox, multiselect, default)
            if (column.controlType === 'checkbox') {
                rowGroup[columnLabel] = this.createCheckboxGroup(column);
            } else if (column.controlType === 'multiselect') {
                rowGroup[columnLabel] = this.createMultiSelectControl(column);
            } else {
                rowGroup[columnLabel] = this.createDefaultControl(column);
            }

            // Set the control properties for the form controls
            this.setControlProperties(rowGroup[columnLabel], column, column.id, question.id);

            // Handle comments control if necessary
            if (column.styleProperties?.requireComments) {
                const commentControlLabel = `${columnLabel}_comments`;
                rowGroup[commentControlLabel] = new FormControl(''); // Create a comments control
                this.setControlProperties(rowGroup[commentControlLabel], column, `${column.id}_comments`, question.id, true);
            }
        });

        return new FormGroup(rowGroup);
    }

    createContainerControl(question: any, mainGroup: any): void {
        const childGroupTags = question.childGroupTags;

        // Create a new FormGroup for the container
        const containerGroup = {}
        
       
        childGroupTags.forEach(child => {
            const childLabel = child.label;

            // Create controls based on child control types
            if (child.controlType === 'checkbox') {
                containerGroup[childLabel] = this.createCheckboxGroup(child);
            } else if (child.controlType === 'multiselect') {
                containerGroup[childLabel] = this.createMultiSelectControl(child);
            } else {
                containerGroup[childLabel] = this.createDefaultControl(child);
            }

            this.setControlProperties(containerGroup[childLabel], child, child.id, question.id);
            //this.setControlProperties(containerGroup[childLabel], child, child.id, question.id);

            if (child.styleProperties?.requireComments) {
                const commentControlLabel = `${childLabel}_comments`;
                containerGroup[commentControlLabel] = new FormControl(''); // Create a required comments control
                this.setControlProperties(containerGroup[commentControlLabel], child, `${child.id}_comments`, question.id,true);
            }
        });

        // Add the container FormGroup to the main group with a suitable key
        //const containerLabel = `${question.label}_container`;
        mainGroup[question.label] = new FormGroup( containerGroup);
          // Nest the container group within the main group
    }


    private createContainerControl1(question: any, mainGroup: any): void {

        const childGroupTags = question.childGroupTags

        childGroupTags.forEach(child => {
            const childLabel = child.label;
            if (child.controlType === 'checkbox') {
                mainGroup[childLabel] = this.createCheckboxGroup(child);
            } else if (child.controlType === 'multiselect') {
                mainGroup[childLabel] = this.createMultiSelectControl(child);
            } else {
                mainGroup[childLabel] = this.createDefaultControl(child);
            }

            this.setControlProperties(mainGroup[childLabel], child, child.id, question.id);
        });
    }
    private createMultiSelectControl(question: any): FormControl {
        return new FormControl([]);
    }

    private createDefaultControl(question: any): FormControl {
        return new FormControl(question.hasOwnProperty('selectedOption')
            ? question.selectedOption ? "" : question.htmlData
            : question.value || "");
    }

    private createHeadingControl(question: any): FormControl {

        const controlLabel = `${question.displayName}_heading`;

        // Create the FormControl with an initial value based on question properties
        const control = new FormControl(
            question.hasOwnProperty('selectedOption')
                ? (question.selectedOption ? "" : question.htmlData)
                : question.value || ""
        );

        // Check if childTagId exists and is a valid string
        if (question.childTagId) {
            try {
                
                // Parse the childTagId into an array
                const associatedControlsArray = JSON.parse(question.childTagId);

                // Add the array to the FormControl as an associatedControls property
                control['associatedControls'] = associatedControlsArray;
            } catch (error) {

                console.error('Failed to parse childTagId:', error);
                control['associatedControls'] = []; // Fallback to an empty array if parsing fails
            }
        }

        control['label'] = controlLabel;

        return control;
    }

    private setControlProperties(control, question: any, controlId: any, parentcontrolId?: number, commnets?:boolean) {
        if (question.styleProperties?.requiredField === true && !question.styleProperties?.hideFeild) {
            control.setValidators(Validators.required);
            control.updateValueAndValidity();
        } else if (question.styleProperties?.requiredField === true && question.styleProperties?.hideFeild) {
            control?.clearValidators();
            control.updateValueAndValidity();
        }

        if (question.styleProperties?.isDisable === true) {
            control?.disable();
            control.updateValueAndValidity();
        } else {
            control?.enable();
            control.updateValueAndValidity();
        }
        
        if (question.styleProperties?.events) {
            const clickFunction = question.styleProperties.events;
            control.valueChanges.subscribe((value: any) => {
                clickFunction(value); // Execute the click function with the new value when the value changes
            });
        }
        if (question.styleProperties?.customValidatorNames) {
            const validatorNames = question.styleProperties.customValidatorNames.split(';');

            validatorNames.forEach(validatorName => {
                const [className, methodName] = validatorName.trim().split('.');
                if (className && methodName) {
                    const validatorClass = this.validatorClasses[className];
                    if (validatorClass && typeof validatorClass[methodName] === 'function') {
                        const validatorFunction = validatorClass[methodName];
                        const existingValidators = control.validator;
                        const newValidators = existingValidators
                            ? [existingValidators, validatorFunction]
                            : validatorFunction;
                        control.setValidators(newValidators);
                    } else {
                        console.error(`Custom validator '${className}.${methodName}' not found or not a function.`);
                    }
                }
            });
        }

        control.id = controlId;
        control.controlType = question.controlType;
        if (question.styleProperties?.requireComments && commnets) {
            const commentControlLabel = `${question.label}_comments`;
            control.labelName = commentControlLabel;
        } else {
            control.labelName = question.label;
        }
       
        control.displayName = question.displayName;
        control.parentcontrolId = parentcontrolId ? parentcontrolId : null

       
    }

}
