<div class="bg-white margin-left-14px margin-right-14px">
    <div class="border-soft-secondary border-left-0 border-right-0 border  justify-content-between bg-white">
        <div class="align-items-center d-flex gap5px headerHeight textColorEncounter white-space-nowrap">
            <div class="d-flex headerHeight">
                <button class="align-items-center d-flex m-1 p-0 rounded-circle encounterBtn width30px height30px" (click)="toggleSideNav()">
                    <i [ngClass]="{'d-none':sideNav}" class="mdi mdi-chevron-left font-26"></i><i [ngClass]="{'d-none':!sideNav}" class="mdi d-none mdi-chevron-right font-26"></i>
                </button>
            </div>
            <div class="gap5px align-items-center d-flex">
                <div class="align-self-start avatar-sm">
                    <img *ngIf="appointment && appointment.patientThumbnailUrl" [src]="safe(appointment.patientThumbnailUrl)" class="rounded-circle avatar-sm" [alt]="appointment.patientName">
                    <span *ngIf="appointment && !appointment.patientThumbnailUrl" class="avatar-title rounded-circle bg-primary text-white" [textContent]="appointment.patientName | initials"></span>
                </div>
                <h4 class="font-16 white-space-nowrap"><a href="javascript:;" class="mb-0 mt-0 font-weight-normal text-uppercase" *ngIf="appointment && appointment.patientName">{{appointment.patientName}}</a></h4>
                <h4 class="font-16 white-space-nowrap">UmrNo:<a href="javascript:;" class="mb-0 mt-0 font-weight-normal text-uppercase" *ngIf="appointment && appointment.umrNo">{{appointment.umrNo}}</a></h4>
            </div>
            <div class="d-flex gap5px h-100 p-1 align-items-center" *ngIf="appointment && appointment.providerName!==null">
                <div class="width30px">
                    <img class="w-100" src="assets/images/timelineSvg/encounter.svg" />
                </div>
                <h4 class="font-16 white-space-nowrap">Encounter by <a href="javascript:;" class="mb-0 mt-0 font-weight-normal text-uppercase">{{appointment.providerName}}</a> on &nbsp;<span class="textColorEncounter fontAt1366px" [textContent]="appointment.appointmentDate|date:'dd/MM/yyyy'"></span><span class="fontAt1366px textColorEncounter">&nbsp;{{appointment.appointmentTimeString}}</span></h4>
                <div>
                    <h4 class="font-16 white-space-nowrap">Added By <a href="javascript:;" class="mb-0 mt-0 font-weight-normal text-uppercase">{{name }}</a></h4>
                </div>
            </div>
            <div class="d-flex heading-color1 font-18 font-weight-bold gap10px" placement="left" ngbTooltip="title-mc">
                <div>
                    <span *ngIf="ancCards && ancCards.ga">GA</span>
                    <sub class="font-15 line-height-normal" style="margin-right: 3px;" *ngIf="ancCards && ancCards.ga" [textContent]="ancCards.ga"></sub>
                </div>
                <!--<div class="no-padding">
                    <span *ngIf="femaleHistoryDetails && femaleHistoryDetails.Gravida">G</span><sub class="font-15" style="margin-right: 3px;" [textContent]="femaleHistoryDetails.Gravida"></sub>
                    <span *ngIf="femaleHistoryDetails && femaleHistoryDetails.Para" >P</span><sub class="font-15" style="margin-right: 3px;" [textContent]="femaleHistoryDetails.Para"></sub>
                    <span *ngIf="femaleHistoryDetails && femaleHistoryDetails.Living">L</span><sub class="font-15" style="margin-right: 3px;" [textContent]="femaleHistoryDetails.Living"></sub>
                    <span *ngIf="femaleHistoryDetails && femaleHistoryDetails.Abortion">A</span><sub class="font-15" style="margin-right: 3px;" [textContent]="femaleHistoryDetails.Abortion"></sub>
                </div>-->
            </div>
            <div class="heading-color1 font-weight-bold" *ngIf="ancCards && ancCards.edd &&  roleId != 63">
                <span class="font-17">EDD:{{ancCards.edd}}</span>
            </div>
            <div class="heading-color1 font-weight-bold" *ngIf="visit">
                <span class="font-17">Visit No:{{visit}}</span>
            </div>
            <div *ngIf="encounterType =='ob-ip-encounter'">
                <span class="heading-color1 font-weight-bold" *ngIf="patient">
                    <span class="font-17">Ip No:{{patient.patient.admissionId}}</span>&nbsp;
                </span>
                <span class="heading-color1 font-weight-bold" *ngIf="appointment">
                    <span class="font-17">Room Name:{{appointment.roomName}}</span>
                </span>
            </div>
            <div class="heading-color1 font-weight-bold">
                <span style="color:red;" *ngIf="ancCardClosed && ancCardClosed.ancCardClosed == 'Yes'">(C)</span>
            </div>

        </div>

        <div class="d-flex pt-1 pb-1">
            <div class="border d-flex encounterWedgets flex-grow-1 mx-1" >
                <div class="align-items-center justify-content-center d-flex w-100-px w-75p" >
                    <img class="w-75" src="../../../../assets/images/timelineSvg/riskfactor-c1.svg" />
                </div>
                <div class="flex-grow-1 m-1">
                    <h5 class="heading-color1 mb-0">Risk Factor:</h5>
                    <div class="p-0 d-flex font-weight-medium w-100" > 
                        <div >
                            <span class="line-height-20px white-space-nowrap heading-color1" style="font-size: medium;"><i class="rotatingExclamation mdi mdi-exclamation text-danger mr-1"></i>Section is not reviewed yet</span>
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1 overflow-auto" *ngIf="antinantalRisk && antinantalRisk !== null &&  roleId != 63 && roleId != 82 ">
                    <div class="d-flex justify-content-between align-items-center p-2px pl-0">
                        <h5 class="d-inline font-14 heading-color2 line-height-1 m-0 mx-1 white-space-nowrap">Risk Factor</h5>
                        <!--<button class="btn text-primary d-block line-height-1px font-12 px-1 text-center"><i class=" mdi mdi-arrow-expand-vertical"></i> See more</button>-->
                    </div>
                    <div class="encounterWedgetsContent pr-1">
                        <div>
                            <div class="d-flex align-items-baseline font-weight-medium w-100">
                                <h6 class="white-space-nowrap heading-color2 m-0 mb-1 d-inline line-height-1 mx-1">Antinantal Risk :</h6>
                                <div class="w-100 mb-0 dashboard-break font-14" *ngIf="antinantalRisk &&( antinantalRisk.maternalmedical.length > 0|| antinantalRisk.uterineRiskFactor.length > 0 || antinantalRisk.pregnancyComplications.length > 0 || antinantalRisk.fetusComplications.length > 0 || antinantalRisk.characteristics.length > 0 || antinantalRisk.details.length > 0)">
                                    <div style="column-gap: 35px;" class="mb-0 line-height-1 text-black">
                                        <span class="pr-1" *ngFor="let item of antinantalRisk.maternalmedical" [textContent]="item+','"></span>

                                        <span class="pr-1" *ngFor="let item of antinantalRisk.uterineRiskFactor" [textContent]="item+','"></span>

                                        <span class="pr-1" *ngFor="let item of antinantalRisk.pregnancyComplications" [textContent]="item+','"></span>

                                        <span class="pr-1" *ngFor="let item of antinantalRisk.fetusComplications" [textContent]="item+','"></span>

                                        <span class="pr-1" *ngFor="let item of antinantalRisk.characteristics" [textContent]="item+','"></span>

                                        <span class="pr-1" *ngFor="let item of antinantalRisk.details" [textContent]="item"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="d-flex align-items-baseline font-weight-medium w-100">
                                <h6 class="white-space-nowrap heading-color2 m-0 mb-1 d-inline line-height-1 mx-1">SGA Screening   : </h6>
                                <div class="w-100 mb-0 dashboard-break font-14" *ngIf="antinantalRisk &&( antinantalRisk.meternall.length > 0|| antinantalRisk.latePragnancy.length > 0 || antinantalRisk.currentPragnancy.length > 0 || antinantalRisk.funtalFetaus.length > 0 || antinantalRisk.maternalMedicalHistory.length > 0 || antinantalRisk.previousPregency.length > 0)">
                                    <div style="column-gap: 35px;" class="mb-0 line-height-1 text-black">
                                        <span class="pr-1" *ngFor="let item of antinantalRisk.meternall" [textContent]="item+','"></span>

                                        <span class="pr-1" *ngFor="let item of antinantalRisk.previousPregency" [textContent]="item+','"></span>

                                        <span class="pr-1" *ngFor="let item of antinantalRisk.maternalMedicalHistory" [textContent]="item+','"></span>

                                        <span class="pr-1" *ngFor="let item of antinantalRisk.funtalFetaus" [textContent]="item+','"></span>

                                        <span class="pr-1" *ngFor="let item of antinantalRisk.currentPragnancy" [textContent]="item+','"></span>

                                        <span class="pr-1" *ngFor="let item of antinantalRisk.latePragnancy" [textContent]="item+','"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="d-flex align-items-baseline font-weight-medium w-100">
                                <h6 class="white-space-nowrap heading-color3 m-0 mb-1 d-inline line-height-1 mx-1">VTE Risk         : </h6>
                                <div class="w-100 mb-0 dashboard-break font-14" *ngIf="antinantalRisk && (antinantalRisk.highRisk.length > 0||antinantalRisk.intermediateRisk.length > 0|| antinantalRisk.lowRisk.length > 0  )">
                                    <div style="column-gap: 35px;" class="line-height-1 mb-0 text-black">
                                        <span class="pr-1" *ngFor="let item of antinantalRisk.highRisk" [textContent]="item+','"></span>

                                        <span class="pr-1" *ngFor="let item of antinantalRisk.intermediateRisk" [textContent]="item+','"></span>

                                        <span class="pr-1" *ngFor="let item of antinantalRisk.lowRisk" [textContent]="item+','"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4 min-fit-width" *ngIf="!allergies && roleId != 63 && roleId != 82">
                <div class="d-flex border encounterWedgets">
                    <div class="align-items-center justify-content-center d-flex w-100-px w-75p">
                        <img class="w-75" src="../../../../assets/images/timelineSvg/allergies-c2.svg" />
                    </div>
                    <div class="flex-grow-1 m-1">
                        <h5 class="heading-color1 mb-0" *ngIf="!allergies">Allergies Sections:</h5>
                        <div class="p-0 d-flex font-weight-medium w-100" *ngIf="roleId != 63 && roleId != 82">
                            <div *ngIf="!allergies">
                                <span class="line-height-20px heading-color1" style="font-size: medium;"><i class="rotatingExclamation mdi mdi-exclamation text-danger mr-1"></i>Section is not reviewed yet</span><br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4" *ngIf="specialFeature && specialFeature.specialFeature && roleId != 63 && roleId != 82">
                <div class="d-flex border encounterWedgets pr-1">
                    <div class="align-items-center justify-content-center d-flex w-100-px w-75p">
                        <img class="w-75" src="../../../../assets/images/timelineSvg/special-features-c1.svg" />
                    </div>
                    <div class="flex-grow-1" *ngIf="!specialFeature.specialFeature">
                        <h5 class="heading-color2 mb-0">Special Features:</h5>
                    </div>
                    <div class="flex-grow-1" *ngIf="specialFeature.specialFeature">
                        <h5 class="d-inline font-14 heading-color2 line-height-1 m-0 white-space-nowrap">Special Features:</h5>
                        <div class="encounterWedgetsContent pr-1 text-black font-14" [textContent]="specialFeature.specialFeature"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

