<style>
    .dropdown-menu {
        padding: 0.3rem;
        box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
        animation-name: DropDownSlide;
        animation-duration: .3s;
        animation-fill-mode: both;
        margin: 0;
        position: absolute;
        z-index: 1000;
        max-height: 400px !important;
        overflow: auto;
    }
</style>
<div>
    <form [formGroup]="productForm" (submit)="onSubmit()">
        <div class="modal-body" style="max-height:650px;overflow-y:auto;">
            <div class="row">
                <div class="col-12">
                    <div class="grid-container bg-light p-2 mt-0 mb-1">
                        <i class="mdi mdi-medical-bag mr-1"></i>Product Details
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label class="mb-1">Item Type <code>*</code></label>
                        <div class="row">
                            <div class="col-md-3 mb-1" *ngIf="!productForm.value.inventoryItem">
                                <div class="custom-control custom-switch mb-0">
                                    <input type="radio" id="pharma" class="custom-control-input" formControlName="isGeneralItem" [value]="false" name="isGeneralItem" (change)="onChangeProductType('P')">
                                    <label class="custom-control-label" for="pharma" [textContent]="'Pharmacy'"></label>
                                </div>
                            </div>
                            <div class="col-md-5 mb-1" *ngIf="!productForm.value.inventoryItem">
                                <div class="custom-control custom-switch mb-0">
                                    <input type="radio" id="generalItem" class="custom-control-input" formControlName="isGeneralItem" [value]="true" name="isGeneralItem" (change)="onChangeProductType('G')">
                                    <label class="custom-control-label" for="generalItem" [textContent]="'Pharmacy General Item (Salable)'"></label>
                                </div>
                            </div>
                            <div class="col-md-4 mb-1" *menuButton="'pharmacy_productMaster_generalItem'">
                                <div class="custom-control custom-switch mb-0">
                                    <input type="checkbox" id="generalItem4" class="custom-control-input" formControlName="inventoryItem" (change)="onChangeProductType('I', productForm.value.inventoryItem)">
                                    <label class="custom-control-label" for="generalItem4" [textContent]="'General Item'"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="productForm.value.inventoryItem">
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <label class="mb-0">Item Name <code>*</code></label>
                            <input type="text" class="form-control" block autocomplete="off"
                                   autofocus
                                   placeholder="Search or enter general item name"
                                   formControlName="productName"
                                   [ngClass]="{ 'is-invalid': (submitted && form.productName.errors) }">

                            <div *ngIf="submitted && form.productName.errors" class="invalid-feedback show-must">
                                <div *ngIf="submitted && form.productName.errors.productExists">Product already exists</div>
                            </div>

                            <div *ngIf="submitted && form.productName.errors" class="invalid-feedback show-must">
                                <div *ngIf="form.productName.errors.whiteSpaces">Please remove white spaces.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <div class="d-flex justify-content-between">
                                <label class="mb-0">Category <code>*</code></label>
                                <a href="javaScript:;" (click)="onOpenOtherFormModel(templateLookUp,'C')"><i class="mdi mdi-plus mr-1"></i>Add</a>
                            </div>
                            <ng-select class="ng-select-sm" [items]="category"
                                       [loading]="loadingCategory"
                                       bindLabel="value"
                                       bindValue="id"
                                       typeToSearchText="Search Category"
                                       [virtualScroll]="true"
                                       placeholder="Enter category name"
                                       formControlName="categoryId"
                                       [ngClass]="{ 'is-invalid': submitted && form.categoryId.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                        No category found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.value"></h5>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <label class="mb-0">Sub Category</label>
                            <input type="text" class="form-control" block autocomplete="off" placeholder="Enter Sub Category name" formControlName="subCategoryName" [ngClass]="{ 'is-invalid': submitted && form.subCategoryName.errors }" />
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <div class="d-flex justify-content-between">
                                <label class="mb-0">Manufacturer <code>*</code></label>
                                <a href="javaScript:;" (click)="onOpenOtherFormModel(templateCompany,'M')"><i class="mdi mdi-plus mr-1"></i>Add</a>
                            </div>
                            <ng-select class="ng-select-sm" [items]="companies"
                                       [loading]="loadingCompany"
                                       bindLabel="name"
                                       bindValue="companyId"
                                       typeToSearchText="Search manufacturer"
                                       [virtualScroll]="true"
                                       placeholder="Enter manufacturer"
                                       formControlName="companyId"
                                       [ngClass]="{ 'is-invalid': submitted && form.companyId.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                        No manufacturer found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.name"></h5>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <label class="mb-0">GST<code>*</code></label>
                            <ng-select class="ng-select-sm" [items]="tax"
                                       [loading]="loadingTax"
                                       bindLabel="value"
                                       bindValue="id"
                                       typeToSearchText="Search GST"
                                       [virtualScroll]="true"
                                       placeholder="Enter GST"
                                       formControlName="taxId"
                                       [ngClass]="{ 'is-invalid': submitted && form.taxId.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                        No GST found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.value + '%'"></h5>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" formControlName="asset" id="flexCheckDefaultAsset">
                                <label class="form-check-label" for="flexCheckDefaultAsset">
                                    Asset
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" formControlName="isProductExpire" id="flexCheckDefaultExpired">
                                <label class="form-check-label" for="flexCheckDefaultExpired">
                                    Is Product Expires?
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="settingsData && settingsData.hsnCode">
                        <div class="form-group">
                            <label class="mb-0">HSN Code </label>
                            <input type="text" placeholder="Enter hsncode." numbersOnly maxlength="8" formControlName="hsnCode" class="form-control" />

                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <label class="mb-0">Discount</label>
                            <input type="number" class="form-control" min="0" numbersOnly placeholder="Enter discount " formControlName="discount" />
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <label class="mb-0">Department</label>
                            <input type="text" class="form-control" min="0" placeholder="Enter departmnent " formControlName="department" />
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <label class="mb-0">Group</label>
                            <input type="text" class="form-control" block autocomplete="off" placeholder="Enter Group name" formControlName="groupName" />
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <label class="mb-0">Sub Group</label>
                            <input type="text" class="form-control" block autocomplete="off" placeholder="Enter Sub Group name" formControlName="subGroupName" />
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <label class="mb-0">Request Type</label>
                            <select class="form-control" autocomplete="nope" formControlName="requestType">
                                <option>-Select-</option>
                                <option [Value]="Opex">Opex</option>
                                <option [Value]="Capex">Capex</option>
                            </select>
                        </div>
                    </div>
                  
                  

                    <div class="col-12">
                        <div class="grid-container bg-light p-2 mt-0 mb-1">
                            <i class="mdi mdi-numeric mr-1"></i>Purchase Policy
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <div class="d-flex justify-content-between">
                                <label class="mb-0">Purchase Packet Type<span class="font-11">(e.g Tablet,Strip,Box..etc) </span> <code>*</code></label>
                                <a href="javaScript:;" (click)="onOpenOtherFormModel(templateLookUp,'U','P')"><i class="mdi mdi-plus mr-1"></i>Add</a>
                            </div>
                            <ng-select class="ng-select-sm" [items]="units"
                                       [loading]="loadingUnit"
                                       bindLabel="value"
                                       bindValue="id"
                                       typeToSearchText="Search Unit"
                                       [virtualScroll]="true"
                                       placeholder="Enter unit name"
                                       formControlName="purchaseUnit"
                                       [ngClass]="{ 'is-invalid': submitted && form.purchaseUnit.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                        No unit found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.value"></h5>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <label class="mb-0">Pack Size<span class="font-11">(e.g 15,10,1..etc) </span> <code>*</code></label>
                            <input type="number" class="form-control" min="0" numbersOnly placeholder="Enter unit number" formControlName="purchaseUnitQty" [ngClass]="{ 'is-invalid': (submitted && form.purchaseUnitQty.errors) }" />
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!productForm.value.inventoryItem">
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group" *ngIf="productForm.value.isGeneralItem">
                            <label class="mb-0">Item Name <code>*</code></label>
                            <input type="text" class="form-control" block autocomplete="off"
                                   autofocus
                                   placeholder="Search or enter general item name"
                                   formControlName="productName"
                                   [ngClass]="{ 'is-invalid': (submitted && form.productName.errors) }">
                            <div *ngIf="form.productName.errors" class="invalid-feedback show-must">
                                <div *ngIf="form.productName.errors.productExists">Product already exists</div>
                            </div>

                            <div *ngIf="submitted && form.productName.errors" class="invalid-feedback show-must">
                                <div *ngIf="form.productName.errors.whiteSpaces">Please remove white spaces.</div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="!productForm.value.isGeneralItem">
                            <label class="mb-0">Item/Service Name <code>*</code></label>
                            <div class="input-group">
                                <input type="text" class="form-control" block autocomplete="off"
                                       [ngbTypeahead]="search"
                                       autofocus
                                       [resultFormatter]="formatMatches"
                                       [resultTemplate]="tempalteSearchResults"
                                       [inputFormatter]="formatMatches"
                                       placeholder="Search or enter {{productForm.value.isGeneralItem ? 'general item' :'medicine'}} name"
                                       formControlName="productName"
                                       (selectItem)="onSelectMedicine($event)"
                                       [ngClass]="{ 'is-invalid': (submitted && form.productName.errors) }">
                                <div class="input-group-append">
                                    <button *ngIf="searching" class="btn bg-primary" type="button" style="cursor: default !important">
                                        <span class="spinner-border text-white spinner-border-sm"></span>
                                    </button>
                                    <button class="p-1 m-0 btn bg-primary" *ngIf="!searching" type="button" style="cursor: default !important">
                                        <span class="mdi mdi-magnify text-white"></span>
                                    </button>
                                </div>
                            </div>
                            <span *ngIf="submitted && form.productName.pending" class="spinner async-control"></span>
                            <div *ngIf=" submitted && form.productName.errors" class="invalid-feedback show-must">
                                <div *ngIf="submitted &&form.productName.errors.productExists">Product already exists</div>
                            </div>

                            <div *ngIf="submitted && form.productName.errors" class="invalid-feedback show-must">
                                <div *ngIf="form.productName.errors.whiteSpaces">Please remove white spaces.</div>
                            </div>
                            <ng-template #tempalteSearchResults let-item="result" let-term="term" let-i="index">
                                <div class="media" style="line-height: normal !important">
                                    <div class="media-body">
                                        <h5 class="mb-0 mt-0 font-weight-normal text-truncate dropdown-item p-0">
                                            <span>
                                                <ngb-highlight [result]="item.drugName" [term]="term"></ngb-highlight>
                                            </span>
                                        </h5>
                                        <div class="text-muted text-truncate notify-details" style="font-size:0.9rem;">
                                            <span>
                                                <span [textContent]="item.composition"></span>
                                            </span>
                                        </div>
                                        <div class="text-truncate">
                                            <span>
                                                <span style="padding:3px;" [textContent]="item.companyName"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="!productForm.value.isGeneralItem && settingsData?.genericName">
                        <div class="form-group">
                            <label class="mb-0">Generic Name </label>
                            <input type="text" placeholder="Enter generic Name." formControlName="genericName" class="form-control" [ngClass]="{ 'is-invalid': (submitted && form.genericName.errors) }" />
                            <div *ngIf="submitted && form.genericName.errors" class="invalid-feedback show-must">
                                <div *ngIf="form.genericName.errors.whiteSpaces">Please remove white spaces.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <label class="mb-0">Type <code>*</code></label>
                            <ng-select class="ng-select-sm" [items]="displayProductTypes"
                                       [loading]="loadingType"
                                       bindLabel="typeName"
                                       bindValue="pharmacyProductTypeId"
                                       typeToSearchText="Search Type"
                                       placeholder="Enter type name"
                                       formControlName="pharmacyProductTypeId"
                                       [ngClass]="{ 'is-invalid': submitted && form.pharmacyProductTypeId.errors }"
                                       (change)="onFetchTypeChange($event)">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    Nothing found<span *ngIf="searchTerm"> for '{{searchTerm}}'</span>.
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.typeName"></h5>
                                            <span class="text-muted d-block font-13">
                                                <span class="badge badge-secondary" *ngIf="item.isGeneralItem">General Item</span>
                                                <span class="badge badge-primary" *ngIf="!item.isGeneralItem">Pharmacy</span>
                                            </span>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item" let-i="index">
                                    <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.typeName"></h5>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="settingsData?.pharmacyProductSubTypeId">
                        <div class="form-group">
                            <label class="mb-0">Sub Type </label>
                            <ng-select class="ng-select-sm" [items]="subType"
                                       [loading]="loadingSubType" Product already exists
                                       bindLabel="subTypeName"
                                       bindValue="pharmacyProductSubTypeId"
                                       typeToSearchText="Search Sub Type"
                                       placeholder="Enter sub type name"
                                       formControlName="pharmacyProductSubTypeId"
                                       [ngClass]="{ 'is-invalid': submitted && form.pharmacyProductSubTypeId.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <span *ngIf="productForm.value.pharmacyProductSubTypeId">Nothing found<span *ngIf="searchTerm"> for '{{searchTerm}}'</span></span>
                                    <span *ngIf="productForm.value.pharmacyProductSubTypeId">Select Type first..</span>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.subTypeName"></h5>
                                            <h6 class="d-flex mb-1 mt-0 font-weight-normal" [textContent]="item.typeName"></h6>
                                            <span class="text-muted d-block font-13">
                                                <span class="badge badge-secondary" *ngIf="item.isGeneralItem">General Item</span>
                                                <span class="badge badge-primary" *ngIf="!item.isGeneralItem">Pharmacy</span>
                                            </span>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item" let-i="index">
                                    <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.subTypeName"></h5>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <label class="mb-0">GST<code>*</code></label>
                            <ng-select class="ng-select-sm" [items]="tax"
                                       [loading]="loadingTax"
                                       bindLabel="value"
                                       bindValue="id"
                                       typeToSearchText="Search GST"
                                       [virtualScroll]="true"
                                       placeholder="Enter GST"
                                       formControlName="taxId"
                                       [ngClass]="{ 'is-invalid': submitted && form.taxId.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                        No GST found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.value + '%'"></h5>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <div class="d-flex justify-content-between">
                                <label class="mb-0">Category <code>*</code></label>
                                <a href="javaScript:;" (click)="onOpenOtherFormModel(templateLookUp,'C')"><i class="mdi mdi-plus mr-1"></i>Add</a>
                            </div>
                            <ng-select class="ng-select-sm" [items]="category"
                                       [loading]="loadingCategory"
                                       bindLabel="value"
                                       bindValue="id"
                                       typeToSearchText="Search Category"
                                       [virtualScroll]="true"
                                       placeholder="Enter category name"
                                       formControlName="categoryId"
                                       [ngClass]="{ 'is-invalid': submitted && form.categoryId.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                        No category found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.value"></h5>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="settingsData?.subCategoryName">
                        <div class="form-group">
                            <div class="d-flex justify-content-between">
                                <label class="mb-0">Sub Category</label>
                                <a href="javaScript:;" (click)="onOpenOtherFormModel(templateLookUp,'SubCategory')"><i class="mdi mdi-plus mr-1"></i>Add</a>
                            </div>
                            <ng-select class="ng-select-sm" [items]="subCategory"
                                       [loading]="loadingSubCategory"
                                       bindLabel="value"
                                       bindValue="value"
                                       typeToSearchText="Search Sub Category"
                                       [virtualScroll]="true"
                                       placeholder="Enter Sub category name"
                                       formControlName="subCategoryName"
                                       [ngClass]="{ 'is-invalid': submitted && form.subCategoryName.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 1">
                                        No category found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.value"></h5>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <div class="d-flex justify-content-between">
                                <label class="mb-0">Manufacturer <code>*</code></label>
                                <a href="javaScript:;" (click)="onOpenOtherFormModel(templateCompany,'M')"><i class="mdi mdi-plus mr-1"></i>Add</a>
                            </div>
                            <ng-select class="ng-select-sm" [items]="companies"
                                       [loading]="loadingCompany"
                                       bindLabel="name"
                                       bindValue="companyId"
                                       typeToSearchText="Search manufacturer"
                                       [virtualScroll]="true"
                                       placeholder="Enter manufacturer"
                                       formControlName="companyId"
                                       [ngClass]="{ 'is-invalid': submitted && form.companyId.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                        No manufacturer found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.name"></h5>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <label class="mb-0">HSN Code </label>
                            <input type="text" placeholder="Enter hsncode." numbersOnly maxlength="8" formControlName="hsnCode" class="form-control" [ngClass]="{ 'is-invalid': (submitted && form.hsnCode.errors) }" />
                            <div *ngIf="submitted && form.hsnCode.errors" class="invalid-feedback show-must">
                                <div *ngIf="form.hsnCode.errors.whiteSpaces">Please remove white spaces.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4">
                        <div class="d-flex justify-content-start align-items-center">
                            <div *ngIf="settingsData?.consumable" class="form-group me-4">
                                <div class="form-check" *ngIf="!productForm.value.inventoryItem">
                                    <input class="form-check-input" type="checkbox" formControlName="consumable" id="flexCheckDefaultConsumable">
                                    <label class="form-check-label mr-2" for="flexCheckDefaultConsumable">
                                        Consumable
                                    </label>
                                </div>
                            </div>

                            <div *ngIf="settingsData?.asset" class="form-group me-4">
                                <div class="form-check" *ngIf="productForm.value.inventoryItem">
                                    <input class="form-check-input" type="checkbox" formControlName="asset" id="flexCheckDefaultAsset">
                                    <label class="form-check-label" for="flexCheckDefaultAsset">
                                        Asset
                                    </label>
                                </div>
                            </div>
                            
                            <div class="form-group me-4" *ngIf="settingsData?.isSurgical">
                                <div class="form-check" *ngIf="!productForm.value.inventoryItem">
                                    <input class="form-check-input" type="checkbox" formControlName="isSurgicalProduct" id="flexCheckDefaultExpired">
                                    <label class="form-check-label" for="flexCheckDefaultExpired">
                                        Surgical
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="settingsData?.discount">
                        <div class="form-group">
                            <label class="mb-0">Discount</label>
                            <input type="number" class="form-control" min="0" numbersOnly placeholder="Enter discount" formControlName="discount" [ngClass]="{ 'is-invalid': submitted && form.discount.errors }" />
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="settingsData?.department">
                        <div class="form-group">
                            <label class="mb-0">Department</label>
                            <input type="text" class="form-control" min="0" placeholder="Enter departmnent " formControlName="department" [ngClass]="{ 'is-invalid': submitted && form.department.errors }" />
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="settingsData?.groupName">
                        <div class="form-group">
                            <label class="mb-0">Group</label>
                            <input type="text" class="form-control" block autocomplete="off" placeholder="Enter Group name" formControlName="groupName" [ngClass]="{ 'is-invalid': submitted && form.groupName.errors }" />
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="settingsData?.subGroupName">
                        <div class="form-group">
                            <label class="mb-0">Sub Group</label>
                            <input type="text" class="form-control" block autocomplete="off" placeholder="Enter Sub Group name" formControlName="subGroupName" [ngClass]="{ 'is-invalid': submitted && form.subGroupName.errors }" />
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="settingsData?.requestType">
                        <div class="form-group">
                            <label class="mb-0">Request Type</label>
                            <select class="form-control" autocomplete="nope" formControlName="requestType" [ngClass]="{ 'is-invalid': submitted && form.requestType.errors }">
                                <option>-Select-</option>
                                <option [Value]="Apex">Apex</option>
                                <option [Value]="Capex">Capex</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="grid-container bg-light p-2 mt-0 mb-1">
                            <i class="mdi mdi-numeric mr-1"></i>Purchase Policy
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <div class="d-flex justify-content-between">
                                <label class="mb-0">Purchase Packet Type<span class="font-11">(e.g Tablet,Strip,Box..etc) </span> <code>*</code></label>
                                <a href="javaScript:;" (click)="onOpenOtherFormModel(templateLookUp,'U','P')"><i class="mdi mdi-plus mr-1"></i>Add</a>
                            </div>
                            <ng-select class="ng-select-sm" [items]="units"
                                       [loading]="loadingUnit"
                                       bindLabel="value"
                                       bindValue="id"
                                       typeToSearchText="Search Unit"
                                       [virtualScroll]="true"
                                       placeholder="Enter unit name"
                                       formControlName="purchaseUnit"
                                       [ngClass]="{ 'is-invalid': submitted && form.purchaseUnit.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                        No unit found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.value"></h5>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <label class="mb-0">Pack Size<span class="font-11">(e.g 15,10,1..etc) </span> <code>*</code></label>
                            <input type="number" class="form-control" min="0" numbersOnly placeholder="Enter unit number" formControlName="purchaseUnitQty" [ngClass]="{ 'is-invalid': (submitted && form.purchaseUnitQty.errors) }" />
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <div class="form-check mt-1">
                                <input class="form-check-input" type="checkbox" formControlName="saleLoose" id="flexChecksaleLoose">
                                <label class="form-check-label" for="flexChecksaleLoose">
                                    Allow Sales Loose?
                                </label>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="productForm.value.saleLoose">
                        <div class="col-12">
                            <div class="grid-container bg-light p-2 mt-0 mb-1">
                                <i class="mdi mdi-numeric mr-1"></i>Sales Policy
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-4 col-md-4">
                            <div class="form-group">
                                <div class="d-flex justify-content-between">
                                    <label class="mb-0">Sales Packet Type<span class="font-11">(e.g Tablet,Strip,Box..etc) </span> <code>*</code></label>
                                    <a href="javaScript:;" (click)="onOpenOtherFormModel(templateLookUp,'U','S')"><i class="mdi mdi-plus mr-1"></i>Add</a>
                                </div>
                                <ng-select class="ng-select-sm" [items]="units"
                                           [loading]="loadingUnit"
                                           bindLabel="value"
                                           bindValue="id"
                                           typeToSearchText="Search Unit"
                                           [virtualScroll]="true"
                                           placeholder="Enter sale unit name"
                                           formControlName="saleUnit"
                                           [ngClass]="{ 'is-invalid': submitted && form.saleUnit.errors }">
                                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                        <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                            No unit found for '{{searchTerm}}'
                                        </div>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-i="index">
                                        <div class="media">
                                            <div class="media-body">
                                                <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.value"></h5>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-4 col-md-4">
                            <div class="form-group">
                                <label class="mb-0">Sale Packed Qty <span class="font-11">(e.g 1,10..etc)</span></label>
                                <input type="number" class="form-control" min="0" numbersOnly placeholder="Enter unit number" formControlName="saleUnitQty" [ngClass]="{ 'is-invalid': (submitted && form.saleUnitQty.errors) }" />
                            </div>
                        </div>
                    </ng-container>

                    <div class="col-12">
                        <div class="grid-container bg-light p-2 mt-0 mb-1">
                            <i class="mdi mdi-folder-information-outline mr-1"></i>Other Details
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="!productForm.value.isGeneralItem  && this.settingsData?.medicationId">
                        <div class="form-group">
                            <label class="mb-0">Medication Code </label>
                            <input type="text" placeholder="Enter medication code." formControlName="medicationId" class="form-control"
                                   [ngClass]="{ 'is-invalid': (submitted && form.medicationId.errors) }" />
                            <div *ngIf="submitted && form.medicationId.errors" class="invalid-feedback show-must">
                                <div *ngIf="form.medicationId.errors.whiteSpaces">Please remove white spaces.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf=" this.settingsData?.potency">
                        <div class="form-group">
                            <label class="mb-0">Potency </label>
                            <textarea rows="1" class="form-control" formControlName="potency" [ngClass]="{ 'is-invalid': (submitted && form.potency.errors) }"></textarea>
                            <div *ngIf="submitted && form.potency.errors" class="invalid-feedback show-must">
                                <div *ngIf="form.potency.errors.whiteSpaces">Please remove white spaces.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf=" this.settingsData?.dosage">
                        <div class="form-group">
                            <label class="mb-0">Dosage </label>
                            <textarea rows="1" class="form-control" formControlName="dosage" [ngClass]="{ 'is-invalid': (submitted && form.dosage.errors) }"></textarea>
                            <div *ngIf="submitted && form.dosage.errors" class="invalid-feedback show-must">
                                <div *ngIf="form.dosage.errors.whiteSpaces">Please remove white spaces.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf=" this.settingsData?.formula">
                        <div class="form-group">
                            <label class="mb-0">Formula </label>
                            <textarea rows="1" class="form-control" formControlName="formula" [ngClass]="{ 'is-invalid': (submitted && form.formula.errors) }"></textarea>
                            <div *ngIf="submitted && form.formula.errors" class="invalid-feedback show-must">
                                <div *ngIf="form.formula.errors.whiteSpaces">Please remove white spaces.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="!productForm.value.isGeneralItem  && this.settingsData?.scheduledDrug">
                        <div class="form-group">
                            <label class="mb-0">Scheduled Drug </label>
                            <ng-select class="ng-select-sm" [items]="schedule"
                                       [loading]="loadingSchedule"
                                       bindLabel="value"
                                       bindValue="value"
                                       typeToSearchText="Search schedule"
                                       [virtualScroll]="true"
                                       placeholder="Enter schedule"
                                       formControlName="scheduledDrug"
                                       [ngClass]="{ 'is-invalid': submitted && form.scheduledDrug.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                        No scheduled drug found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="'Schedule ' + item.value"></h5>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item" let-i="index">
                                    <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="'Schedule ' + item.value"></h5>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="!productForm.value.isGeneralItem && this.settingsData?.drugRiskId">
                        <div class="form-group">
                            <label class="mb-0">Drug Risk</label>
                            <ng-select class="ng-select-sm" [items]="drugRisks"
                                       [loading]="loadingTax"
                                       bindLabel="value"
                                       bindValue="id"
                                       typeToSearchText="Search schedule"
                                       [virtualScroll]="true"
                                       placeholder="Enter risk"
                                       formControlName="drugRiskId"
                                       [ngClass]="{ 'is-invalid': submitted && form.drugRiskId.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                        No drug found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.value"></h5>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item" let-i="index">
                                    <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="!productForm.value.isGeneralItem && this.settingsData?.nabhCategoryId">
                        <div class="form-group">
                            <label class="mb-0">NABH Category</label>
                            <ng-select class="ng-select-sm" [items]="nabhCategory"
                                       [loading]="loadingTax"
                                       bindLabel="value"
                                       bindValue="id"
                                       typeToSearchText="Search schedule"
                                       [virtualScroll]="true"
                                       placeholder="Enter nabh"
                                       formControlName="nabhCategoryId"
                                       [ngClass]="{ 'is-invalid': submitted && form.nabhCategoryId.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                        No category found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.value"></h5>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item" let-i="index">
                                    <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="!productForm.value.isGeneralItem && this.settingsData?.dosageTypeId">
                        <div class="form-group">
                            <label class="mb-0">Dosage Type</label>
                            <ng-select class="ng-select-sm" [items]="dosageTypes"
                                       [loading]="loadingTax"
                                       bindLabel="value"
                                       bindValue="id"
                                       typeToSearchText="Search...."
                                       [virtualScroll]="true"
                                       placeholder="Enter...."
                                       formControlName="dosageTypeId"
                                       [ngClass]="{ 'is-invalid': submitted && form.dosageTypeId.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                        No category found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.value"></h5>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item" let-i="index">
                                    <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="this.settingsData?.strength">
                        <div class="form-group">
                            <label class="mb-0">Strength</label>
                            <input type="text" class="form-control" min="0" placeholder="Enter here.." formControlName="strength" [ngClass]="{ 'is-invalid': (submitted && form.strength.errors) }" />
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="this.settingsData?.fixedDose">
                        <div class="form-group">
                            <label class="mb-0">Fixed Dose</label>
                            <input type="number" class="form-control" min="0" placeholder="Enter here.." formControlName="fixedDose" [ngClass]="{ 'is-invalid': (submitted && form.fixedDose.errors) }" />
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="this.settingsData?.medFrequencyMasterId">
                        <div class="form-group">
                            <label class="mb-0">Frequency</label>
                            <ng-select class="ng-select-sm" [items]="frequencies"
                                       [loading]="loadingTax"
                                       bindLabel="name"
                                       bindValue="id"
                                       placeholder="Search frequencies"
                                       formControlName="medFrequencyMasterId"
                                       [ngClass]="{ 'is-invalid': (submitted && form.medFrequencyMasterId.errors) }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm">
                                        No Frequency found for '{{searchTerm}}'
                                    </div>
                                    <div *ngIf="!searchTerm">
                                        Type to start search
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index" let-searchTerm="searchTerm">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal">
                                                <ngb-highlight [result]="item.name" [term]="searchTerm"></ngb-highlight>
                                            </h5>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="this.settingsData?.noOfTimes">
                        <div class="form-group">
                            <label class="mb-0">No Of Times</label>
                            <input type="number" class="form-control" min="0" placeholder="Enter here.." formControlName="noOfTimes" [ngClass]="{ 'is-invalid': (submitted && form.noOfTimes.errors) }" />
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="this.settingsData?.formulationId">
                        <div class="form-group">
                            <label class="mb-0">Formulation </label>
                            <ng-select class="ng-select-sm" [items]="formulations"
                                       [loading]="loadingTax"
                                       bindLabel="value"
                                       bindValue="id"
                                       typeToSearchText="Search Formulation"
                                       [virtualScroll]="true"
                                       placeholder="Enter storage"
                                       formControlName="formulationId"
                                       [ngClass]="{ 'is-invalid': submitted && form.formulationId.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                        No item found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.value"></h5>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="this.settingsData?.medRouteId">
                        <div class="form-group">
                            <label class="mb-0">Route </label>
                            <ng-select class="ng-select-sm" [items]="medRoutes"
                                       [loading]="loadingTax"
                                       bindLabel="value"
                                       bindValue="id"
                                       typeToSearchText="Search ..."
                                       [virtualScroll]="true"
                                       placeholder="Enter ..."
                                       formControlName="medRouteId"
                                       [ngClass]="{ 'is-invalid': submitted && form.medRouteId.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                        No item found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.value"></h5>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="settingsData && this.settingsData.saleUnitName">
                        <div class="form-group">
                            <label class="mb-0">Storage Type </label>
                            <ng-select class="ng-select-sm" [items]="storage"
                                       [loading]="loadingTax"
                                       bindLabel="value"
                                       bindValue="id"
                                       typeToSearchText="Search Storage Type"
                                       [virtualScroll]="true"
                                       placeholder="Enter storage"
                                       formControlName="storageTypeId"
                                       [ngClass]="{ 'is-invalid': submitted && form.storageTypeId.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                        No item found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.value"></h5>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="grid-container bg-light p-2 mt-0 mb-1">
                            <i class="mdi mdi-cart-check mr-1"></i>Inventory Details
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="settingsData && this.settingsData.inventoryExpiry">
                        <div class="form-group">
                            <label class="mb-0">Grading Expiry</label>
                            <select class="form-control" formControlName="inventoryExpiry" [ngClass]="{ 'is-invalid': (submitted && form.inventoryExpiry.errors) }">
                                <option selected [ngValue]="">Please select</option>
                                <option [ngValue]="1">1 Month</option>
                                <option [ngValue]="2">2 Month</option>
                                <option [ngValue]="3">3 Month</option>
                                <option [ngValue]="4">4 Month</option>
                                <option [ngValue]="5">5 Month</option>
                                <option [ngValue]="6">6 Month</option>
                                <option [ngValue]="7">7 Month</option>
                                <option [ngValue]="8">8 Month</option>
                                <option [ngValue]="9">9 Month</option>
                                <option [ngValue]="10">10 Month</option>
                                <option [ngValue]="11">11 Month</option>
                                <option [ngValue]="12">12 Month</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" formControlName="isProductExpire" id="flexCheckDefaultExpired" [ngClass]="{ 'is-invalid': (submitted && form.isProductExpire.errors) }">
                                <label class="form-check-label" for="flexCheckDefaultExpired">
                                    Is Product Expires?
                                </label>
                            </div>
                            <!--<div class="form-check mt-1">
                <input class="form-check-input" type="checkbox" formControlName="onlyConsumable" id="flexCheckOnlyConsumable">
                <label class="form-check-label" for="flexCheckOnlyConsumable">
                    Is Product Only For Consumption?
                </label>
            </div>-->
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="grid-container bg-light p-2 mt-0 mb-1">
                            <i class="mdi mdi-allergy mr-1"></i>Interactions
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="settingsData && this.settingsData.medicineFaq">
                        <div class="form-group">
                            <label class="mb-0">Medicine FAQ </label>
                            <textarea rows="2" class="form-control" formControlName="medicineFaq" [ngClass]="{ 'is-invalid': (submitted && form.medicineFaq.errors) }"></textarea>
                            <div *ngIf="submitted && form.medicineFaq.errors" class="invalid-feedback show-must">
                                <div *ngIf="form.medicineFaq.errors.whiteSpaces">Please remove white spaces.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="settingsData && this.settingsData.medicineInteraction">
                        <div class="form-group">
                            <label class="mb-0">Medicine Interaction</label>
                            <textarea rows="2" class="form-control" formControlName="medicineInteraction" [ngClass]="{ 'is-invalid': (submitted && form.medicineInteraction.errors) }"></textarea>
                            <div *ngIf="submitted && form.medicineInteraction.errors" class="invalid-feedback show-must">
                                <div *ngIf="form.medicineInteraction.errors.whiteSpaces">Please remove white spaces.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="settingsData && this.settingsData.usage">
                        <div class="form-group">
                            <label class="mb-0">Medicine Usage</label>
                            <textarea rows="2" class="form-control" formControlName="usage" [ngClass]="{ 'is-invalid': (submitted && form.usage.errors) }"></textarea>
                            <div *ngIf="submitted && form.usage.errors" class="invalid-feedback show-must">
                                <div *ngIf="form.usage.errors.whiteSpaces">Please remove white spaces.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="settingsData && this.settingsData.alchoholInteraction">
                        <div class="form-group">
                            <label class="mb-0">Alchohol Interaction</label>
                            <textarea rows="2" class="form-control" formControlName="alchoholInteraction" [ngClass]="{ 'is-invalid': (submitted && form.alchoholInteraction.errors) }"></textarea>
                            <div *ngIf="submitted && form.alchoholInteraction.errors" class="invalid-feedback show-must">
                                <div *ngIf="form.alchoholInteraction.errors.whiteSpaces">Please remove white spaces.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="settingsData && this.settingsData.pregnancyInteraction">
                        <div class="form-group">
                            <label class="mb-0">Pregnancy Interaction</label>
                            <textarea rows="2" class="form-control" formControlName="pregnancyInteraction" [ngClass]="{ 'is-invalid': (submitted && form.pregnancyInteraction.errors) }"></textarea>
                            <div *ngIf="submitted && form.pregnancyInteraction.errors" class="invalid-feedback show-must">
                                <div *ngIf="form.pregnancyInteraction.errors.whiteSpaces">Please remove white spaces.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="settingsData && this.settingsData.expertAdvice">
                        <div class="form-group">
                            <label class="mb-0">Expert Advice</label>
                            <textarea rows="2" class="form-control" formControlName="expertAdvice" [ngClass]="{ 'is-invalid': (submitted && form.expertAdvice.errors) }"></textarea>
                            <div *ngIf="submitted && form.expertAdvice.errors" class="invalid-feedback show-must">
                                <div *ngIf="form.expertAdvice.errors.whiteSpaces">Please remove white spaces.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="settingsData && this.settingsData.commonSideEffects">
                        <div class="form-group">
                            <label class="mb-0">Common Side Effects</label>
                            <textarea rows="2" class="form-control" formControlName="commonSideEffects" [ngClass]="{ 'is-invalid': (submitted && form.commonSideEffects.errors) }"></textarea>
                            <div *ngIf="submitted && form.commonSideEffects.errors" class="invalid-feedback show-must">
                                <div *ngIf="form.commonSideEffects.errors.whiteSpaces">Please remove white spaces.</div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default btn-sm" (click)="onDirectOmit();">Cancel</button>
            <button type="submit" [disabled]="submitting" class="btn btn-primary btn-sm ml-1">
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting">Submit</span>
            </button>
        </div>
    </form>
</div>

<ng-template #templateLookUp>
    <form [formGroup]="lookUpForm" (submit)="onSubmitLookup()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-account-cog-outline mr-1"></i><span>Add</span> {{typeOf}}</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="mb-0">{{typeOf}} Name <code>*</code></label>
                        <input type="text" class="form-control" formControlName="name" placeholder="Enter Unit Name" [ngClass]="{ 'is-invalid': submitted && otherForms.lookup.name.errors }" />
                        <div *ngIf="submitted && otherForms.lookup.name.errors" class="invalid-feedback show-must">
                            <div *ngIf="otherForms.lookup.name.errors.required">Name is required.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default btn-sm" (click)="onCloseModal();">Cancel</button>
            <button type="submit" [disabled]="submitting" class="btn btn-primary btn-sm ml-1">
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting">Submit</span>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #templateCompany>
    <form [formGroup]="companyForm" (submit)="onSubmitCompany()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-account-cog-outline mr-1"></i><span>Add</span> Manufacturer</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="mb-0">Manufacturer Name <code>*</code></label>
                        <input type="text" formControlName="name" placeholder="Enter name" class="form-control" [ngClass]="{'is-invalid': submitted && otherForms.company.name.errors }" />
                        <div class="show-must text-danger" *ngIf="submitted && otherForms.company.name.errors">
                            <span *ngIf="otherForms.company.name.errors.required">This field is required.</span>
                            <span *ngIf="otherForms.company.name.errors.whiteSpaces">Please remove whitespaces.</span>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label class="mb-0">Location <code>*</code></label>
                        <textarea class="form-control" formControlName="location" placeholder="Enter location." [ngClass]="{'is-invalid': submitted && otherForms.company.location.errors }"></textarea>
                        <div class="show-must text-danger" *ngIf="submitted && otherForms.company.location.errors">
                            <span *ngIf="otherForms.company.location.errors.required">This field is required.</span>
                            <span *ngIf="otherForms.company.location.errors.whiteSpaces">Please remove whitespaces.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default btn-sm" (click)="onCloseModal();">Cancel</button>
            <button type="submit" [disabled]="submitting" class="btn btn-primary btn-sm ml-1">
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting">Submit</span>
            </button>
        </div>
    </form>
</ng-template>