import { Component, OnDestroy, OnInit, ChangeDetectorRef, NgZone } from "@angular/core";
import { IUserAccount, Page, GenericResponse, GenericStatus, IResource } from "@shared/models";
import { AppData, HttpService, NotifyService, CommunicationService, ResourceService, QueueService, BasicProvider, RealTimeNodeService } from "@shared/services";
import { takeUntil, finalize, debounceTime, distinctUntilChanged, switchMap, tap, catchError } from "rxjs/operators";
import { ApiResources } from "../../../shared/helpers";
import { IFetchModel, IPatient } from "./models/local";
import { Observable, Subject, of } from "rxjs";
import moment from "moment";

interface ICall {
    Status: CallStatus,
    callTimes: number,
    callText: string,
    tokenNumber: number,
    patientName: string;
}

enum CallStatus {
    NotStarted = 1,
    Completed = 2,
}

@Component({
    templateUrl: "./queue.html",
    styleUrls: ["./queue.css"]
})

export class QueuePage implements OnInit, OnDestroy {
    page: Page;
    loading: boolean;
    records: Array<IFetchModel>;
    assigned: Array<IPatient>;
    unassigned: Array<IPatient>;
    isCallingPossible: boolean;
    callingArr: Array<ICall>;
    isCallingGoingOn: boolean;
    provider: Observable<Array<IResource>>;
    providerInput = new Subject<string>();
    loadingProviders: boolean;
    providerId: any;
    locationId: number;
    selectedProviderId: any;
    selectedVoiceName: string;
    showVoice: boolean;
    voices: Array<SpeechSynthesisVoice>;
    interval: any;
    isMute: boolean;
    isInitialMode: boolean;
    globalInterval: any;
    msg: SpeechSynthesisUtterance;
    synth = window.speechSynthesis;
    QrCode: string = null;
    isNotUserAccount = false;
    loadingLocation: boolean;
    locations: Array<IResource>;
    loadingLocProviders: boolean;
    providers: Array<IResource>;

    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
        public communicationService: CommunicationService,
        private readonly resourceService: ResourceService,
        public queueService: QueueService,
        private readonly cd: ChangeDetectorRef,
        private readonly ngZone: NgZone,
        private readonly realTimeNodeService: RealTimeNodeService,
    ) {
        this.page = new Page();
        this.records = new Array<IFetchModel>();
        this.assigned = new Array<IPatient>();
        this.unassigned = new Array<IPatient>();
        this.callingArr = new Array<ICall>();
        this.setCallingPossible();
        this.msg = new SpeechSynthesisUtterance();
    }

    setVoiceList = () => {
        let voices = this.synth.getVoices();
        if (voices.length) {
            this.voices = voices.filter(x => x.lang.indexOf('en') !== -1);
            if (!this.selectedVoiceName) {
                this.selectedVoiceName = voices.find(x => x.default).name;
            }
            this.showVoice = true;
        }
    }

    removeInitialMode = () => {
        this.isInitialMode = false;
    }

    changeVoice = () => {
        this.showVoice = false;
    }

    toggleVolume = () => {
        this.isMute = !this.isMute;
        if (this.isMute) {
            this.msg.volume = 0;
        } else {
            this.msg.volume = 1;
        }
    }

    private fetchProvidersFilter() {
        this.provider = this.providerInput.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap((term: string) =>
                this.resourceService.provider(term,'OP').pipe(
                    tap(() => this.loadingProviders = true),
                    catchError(() => { return of([]) }),
                    finalize(() => this.loadingProviders = false)
                )
            )
        );
    }

    setCallingPossible = () => {
        this.isCallingPossible = 'speechSynthesis' in window;
    }

    callPatient = () => {
        if (this.isCallingGoingOn) return;
        this.isCallingGoingOn = true;
        const callFn = () => {
            const nextIndex = this.callingArr.findIndex(x => x.Status === CallStatus.NotStarted)
            if (nextIndex !== -1) {
                if (this.callingArr[nextIndex].callTimes < 4) {
                    this.callingArr[nextIndex].callTimes = this.callingArr[nextIndex].callTimes + 1
                    this.msg = new SpeechSynthesisUtterance();
                    this.msg.volume = 1;
                    this.msg.text = this.callingArr[nextIndex].callText;
                    this.msg.rate = 0.7;
                    if (this.selectedVoiceName) {
                        const selectedVoice = this.voices.find(x => x.name === this.selectedVoiceName);
                        if (selectedVoice) {
                            this.msg.voice = selectedVoice;
                            this.msg.lang = selectedVoice.lang;
                        }
                    }
                    if (!this.isMute) {
                        this.synth.speak(this.msg);
                    }
                    this.interval = setInterval(() => {
                        if (!this.synth.speaking) {
                            clearInterval(this.interval);
                            callFn()
                        }
                    }, 5000);
                } else {
                    this.callingArr = this.callingArr.filter(x => x.tokenNumber !== this.callingArr[nextIndex].tokenNumber);
                    callFn()
                }
            } else {
                this.isCallingGoingOn = false;
            }
        }
        callFn();
    }

    subscribeToCommunication(): void {
        this.realTimeNodeService.refreshQueue("queue", (data: any) => {
            if (data.GroupName !== "QueueManagement") return;
            const found = this.records.length && this.records.find(x => !!x.patients.find(y => y.tokenNumber.toString() === data.TokenNumber.toString()))
            if (data.type === 2 && found) {
                clearInterval(this.interval);
                if (this.synth.speaking) {
                    this.synth.cancel();
                }
                if (!this.callingArr.find(x => x.tokenNumber === data.tokenNumber && x.patientName === data.patientName)) {
                    var basics = this.unassigned.length ? this.unassigned.find(x => x.tokenNumber === data.tokenNumber && x.fullName.toLowerCase() === data.patientName.toLowerCase()) : null;
                    var cubicleName = data.cubicleName
                        ? ` Cubicle ${data.cubicleName}`
                        : (basics && basics.cubicleName ? ` Cubicle ${basics.cubicleName}` : '');
                    this.callingArr.push({
                        callText: `Token Number ${data.tokenNumber}${cubicleName}`,
                        Status: CallStatus.NotStarted,
                        tokenNumber: data.tokenNumber,
                        patientName: data.patientName,
                        callTimes: 0,
                    } as ICall);
                }
                this.callPatient();
            }
            this.fetch(this.selectedProviderId, this.locationId);
        });
    }

    fetch = (providerId?: any, locationId?: any) => {
        this.loading = true;
        const request = {
            locationId: locationId,
            providerId: providerId
        }
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.queue.base, ApiResources.queue.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                (response: GenericResponse) => {
                    if (GenericStatus[GenericStatus.Success] === response.status) {
                        var records = response.data as Array<IFetchModel>;
                        var allPatients = new Array<IPatient>();
                        records.forEach((x) => {
                            x.fullName = x.fullName.toLowerCase();
                            x.patients.forEach(z => {
                                z.doctorName = x.fullName;
                                const abbreviated = z.appointmentTimeString.split(" ");
                                const timeTokens = abbreviated[0].split(":");
                                const added = abbreviated[1].toLowerCase() == "pm" ? 12 : 0;
                                const hour = timeTokens[0] + added;
                                z.appointmentTime = new Date(2022, 0, 1, +hour, +timeTokens[1], 0);
                            });
                            allPatients.push(...x.patients);
                        })
                        this.records = records;
                        var assigned = allPatients.filter(x => x.queueStatus === 'In Room' || x.queueStatus === 'Calling');
                        this.sortArray(assigned, 'appointmentTime');
                        this.assigned = assigned;
                        var unassigned = allPatients.filter(x => x.queueStatus !== 'In Room' && x.queueStatus !== 'Calling');
                        this.sortArray(unassigned, 'appointmentTime');
                        [...unassigned].forEach((x, i) => {
                            x.waitingPriority = x.waitingPriority || 0;
                            if (x.waitingPriority > 0 && x.queueStatus !== 'Next') {
                                this.arrayMove(unassigned, i, x.waitingPriority)
                            }
                        });
                        this.unassigned = unassigned;
                        this.loading = false;
                        try {
                            this.cd.detectChanges();
                        } catch (e) {
                            console.error("[detech Changes Error]: ", e);
                        }
                    } else {
                        this.notifyService.warningToast(response.message);
                    }
                },
                () => {
                    this.records = new Array<IFetchModel>();
                }
            );
    }

    sortArray = (array: Array<any>, key: string) => {
        array.sort((a, b) => {
            if (a[key] < b[key]) {
                return -1;
            }
            if (a[key] > b[key]) {
                return 1;
            }
            return 0
        });
    }

    arrayMove = (arr, fromIndex, toIndex) => {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }

    onRefresh = () => {
        this.fetch(this.selectedProviderId, this.locationId);
    }

    onLocationSelect = (model: any) => {
        if (model) {
            this.locationId = model.id;
            setTimeout(() => this.setVoiceList(), 2000);
            this.QrCode = model.id.toString() + "," + moment().format('DD-MM-YYYY').toString();
            this.fetchProvidersFilter();
            this.subscribeToCommunication();
        }
        else {
            this.undoData();
        }
    }

    onDoctorSelect = (model: any) => {
        if (model) {
            this.selectedProviderId = model.id;
            this.queueService.selectedProvider = { id: model.id, name: model.value } as BasicProvider;
            this.fetch(model.id, this.locationId);
        }
        else {
            this.undoData();
            this.fetch(null, this.locationId);
        }
    }

    undoData() {
        this.selectedProviderId = null;
        this.assigned = [];
        this.unassigned = [];
    }

    private fetchLocations() {
        this.loadingLocation = true;
        this.resourceService.locations()
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingLocation = false))
            .subscribe((response: Array<IResource>) => {
                this.locations = response;
            });
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((account: IUserAccount) => {
                if (account) {
                    this.page.userAccount = account;
                    if (!this.selectedProviderId && this.queueService.selectedProvider) {
                        this.selectedProviderId = this.queueService.selectedProvider.id;
                        this.isInitialMode = true;
                    }
                    if (account.roleId == 3) {
                        this.fetch(this.selectedProviderId = account.referenceId);
                    }
                    else {
                        this.fetch(this.selectedProviderId);
                    }
                    setTimeout(() => this.setVoiceList(), 2000);
                    this.QrCode = this.page.userAccount.locationId.toString() + "," + moment().format('DD-MM-YYYY').toString();
                    this.fetchProvidersFilter();
                    this.subscribeToCommunication();
                }
                else {
                    this.isNotUserAccount = true;
                    this.fetchLocations();
                }
            });
    }

    ngOnDestroy() {
        clearInterval(this.interval);
        this.synth.cancel();
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
}