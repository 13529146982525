<div class="modal-header">
    <h4 class="modal-title"><i class="mdi mdi-receipt mr-1"></i>View Receipt</h4>
    <div class="d-inline-block d-flex-end">
        <button class="btn btn-small" [class.btn-light]="!isPrintLogo" [class.btn-outline-light]="isPrintLogo" type="button" (click)="onChangePrintType(false)">
            <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
        </button>
        <button class="btn btn-small" [class.btn-light]="isPrintLogo" [class.btn-outline-light]="!isPrintLogo" type="button" (click)="onChangePrintType(true)">
            <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
        </button>
        <button class="btn btn-small" [class.btn-light]="!isFooter" [class.btn-outline-light]="isFooter" type="button" (click)="onChangeFooter(false)">
            <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
        </button>
        <button class="btn btn-small" [class.btn-light]="isFooter" [class.btn-outline-light]="!isFooter" type="button" (click)="onChangeFooter(true)">
            <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
        </button>
        <button [useExistingCss]="true"
                printSectionId="receipt-section"
                ngxPrint
                class="btn btn-light btn-small mr-1 d-print-none">
            <i class="fe-printer mr-1"></i>Print
        </button>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();"><i class="fe-x"></i></button>
    </div>
</div>
<div class="modal-body" id="receipt-section" *ngIf="!(record.paymentType==='Advance')">
    <div class="card">
        <div class="card-body p-0">
            <div class="card-box m-0 d-flex-center" *ngIf="loading">
                <span class="spinner-grow spinner-border-sm mr-2"></span> Please wait while loading receipt
            </div>
            <div class="card-box m-0" *ngIf="record && !loading">
                <div class="row">
                    <div class="col-lg-12" *ngIf="isPrintLogo">
                        <banner-setting [reportName]='"Sevices View Receipt Header"'> </banner-setting>
                    </div>
                    <div class="col-lg-12">
                        <h4 class="box-title mt-3">RECEIPT</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="table-responsive table-responsive-lg text-black">
                            <table class="table table-sm table-borderless table-centered text-black">
                                <tbody>
                                    <tr>
                                        <th>Receipt No</th>
                                        <td>
                                            <span class="mr-1">:</span>
                                            <span [textContent]="'RN-' + record.receiptNo"></span>
                                        </td>
                                        <th>
                                            <span *ngIf="isAdmission">Admission No</span>
                                            <span *ngIf="!isAdmission">Appointment No</span>
                                        </th>
                                        <td>
                                            <span class="mr-1">:</span>
                                            <span [textContent]="admission.admissionNo"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Receipt Date</th>
                                        <td>
                                            <span class="mr-1">:</span>
                                            <span [textContent]="record.createdDate | date:'dd-MM-yyyy, h:mm:ss a'"></span>
                                        </td>
                                        <th>
                                            <span *ngIf="isAdmission">Admission Date</span>
                                            <span *ngIf="!isAdmission">Appointment Date</span>
                                        </th>
                                        <td>
                                            <span class="mr-1">:</span>
                                            <span *ngIf="isAdmission" [textContent]="admission.admissionDate | date: 'dd-MM-yyyy, h:mm:ss a'"></span>
                                            <span *ngIf="!isAdmission">
                                                <span [textContent]="admission.admissionDate | date: 'dd-MM-yyyy'"></span>
                                                <span [textContent]="' ' + admission.timeString"></span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Patient Name</th>
                                        <td>
                                            <span class="mr-1">:</span>
                                            <span>
                                                <span class="text-uppercase" [textContent]="admission.fullName + ', '"></span>
                                                <span [textContent]="(admission.gender === 'F' ? 'Female' : 'Male') + ', '"></span>
                                                <span [textContent]="admission.age + 'yrs'"></span>
                                            </span>
                                        </td>
                                        <th>Doctor Name</th>
                                        <td>
                                            <span class="mr-1">:</span>
                                            <span class="text-uppercase" [textContent]="admission.providerName"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>UMR No</th>
                                        <td>
                                            <span class="mr-1">:</span>
                                            <span [textContent]="admission.umrNo"></span>
                                        </td>
                                        <th>Department</th>
                                        <td>
                                            <span class="mr-1">:</span>
                                            <span [textContent]="admission.departmentName"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Address</th>
                                        <td>
                                            <span class="mr-1">:</span>
                                            <span [textContent]="admission.fullAddress"></span>
                                        </td>
                                        <th>Payment Type</th>
                                        <td>
                                            <span class="mr-1">:</span>
                                            <span [textContent]="record.payTypeName"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Admitted Ward</th>
                                        <td>
                                            <span class="mr-1">:</span>
                                            <span [textContent]="!admission.bedNumber ? 'N/A' : (admission.wardName + ' / ' + admission.roomName + ' / ' + admission.bedNumber)"></span>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-12">
                        <div class="table-responsive table-responsive-lg">
                            <table class="table table-bordered table-sm text-black border-dark" *ngIf="record">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>DESCRIPTION</th>
                                        <th>
                                            UNIT PRICE
                                            <div *ngIf="waterMarkText != undefined">
                                                <div class="justify-content-center d-flex">
                                                    <p id="bg-bgStyle" class=" backgroundOfwaterMark">Duplicate </p>
                                                </div>
                                            </div>
                                        </th>
                                        <th>AMOUNT</th>
                                        <th>REMARKS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td class="col-2">
                                            <div *ngIf="record.receiptTypeId == receiptType.Refund">
                                                <div *ngIf="record.isRefunded">Refund</div>
                                            </div>
                                            <div *ngIf="record.receiptTypeId == receiptType.Cash">
                                                <div *ngIf="record.isAdvance && !record.paymentType">Advance</div>
                                                <div *ngIf="!record.isAdvance && !record.paymentType">Paid</div>
                                                <span *ngIf="record.paymentType" [textContent]="record.paymentType"></span>
                                            </div>
                                        </td>
                                        <td class="col-2" [textContent]="record.cost | currency:'INR'"></td>
                                        <td class="col-2" [textContent]="record.cost | currency:'INR'"></td>
                                        <td class="col-5 " [textContent]="record.remarks || '---'">
                                        </td>
                                    </tr>
                                </tbody>
                                <thead>
                                    <tr>
                                        <th colspan="4" class="text-right">TOTAL</th>
                                        <th [textContent]="record.cost | currency:'INR'"></th>
                                    </tr>
                                </thead>
                            </table>
                            <table class="table table-bordered table-sm text-black" *ngIf="record.subRecords && record.subRecords.length">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>NAME</th>
                                        <th>UNIT PRICE</th>
                                        <th>UNIT</th>
                                        <th>AMOUNT</th>
                                        <th>GST</th>
                                        <th>DISCOUNT</th>
                                        <th>TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of record.subRecords; let i = index; ">
                                        <td [textContent]="i + 1"></td>
                                        <td class="text-uppercase" [textContent]="item.name"></td>
                                        <td [textContent]="item.cost | currency:'INR'"></td>
                                        <td [textContent]="item.unit"></td>
                                        <td [textContent]="item.totalAmount | currency:'INR'"></td>
                                        <td>
                                            <span class="mr-1" [textContent]="item.gstAmount | currency:'INR'"></span>(<span [textContent]="item.gstPercentage"></span>%)
                                        </td>
                                        <td>
                                            <span class="mr-1" [textContent]="item.discountAmount | currency:'INR'"></span><span *ngIf="item.discountAmount">(<span [textContent]="item.discountPercentage"></span>%)</span>
                                        </td>
                                        <td [textContent]="item.netAmount | currency:'INR'"></td>
                                    </tr>
                                </tbody>
                                <thead>
                                    <tr>
                                        <th colspan="7" class="text-right">GROSS TOTAL</th>
                                        <th [textContent]="record.costModel.total | currency:'INR'"></th>
                                    </tr>
                                    <tr>
                                        <th colspan="7" class="text-right"><span class="small mr-1">(included in GROSS TOTAL)</span>GST</th>
                                        <th [textContent]="record.costModel.gst | currency:'INR'"></th>
                                    </tr>
                                    <tr>
                                        <th colspan="7" class="text-right">DISCOUNT</th>
                                        <th [textContent]="record.costModel.discount | currency:'INR'"></th>
                                    </tr>
                                    <tr>
                                        <th colspan="7" class="text-right">NET AMOUNT</th>
                                        <th [textContent]="record.costModel.netAmount | currency:'INR'"></th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-6 text-black">
                        <div class="mb-1">
                            <span class="font-weight-bold">Rupees in Words :&nbsp;</span>
                            <span class="text-capitalize" [textContent]="amtInWords + ' Only' "></span>
                        </div>
                        <div class="mb-1">
                            <span class="font-weight-bold">Created By: </span>
                            <span [textContent]="record.createdByName"></span>
                        </div>
                        <div>
                            <span class="font-weight-bold">Printed Date: </span>
                            <span [textContent]="printedDate | date:'dd-MM-yyyy, h:mm:ss a'"></span>
                        </div>
                    </div>
                </div>
                <div *ngIf="isFooter">
                    <banner-setting [reportName]='"Sevices View Receipt Footer"'> </banner-setting>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="mb-3" id="receipt-section" *ngIf="record.paymentType==='Advance'">
    <div class="report">
        <div>
            <div class="card-box m-0 d-flex-center" *ngIf="loading">
                <span class="spinner-grow spinner-border-sm mr-2"></span> Please wait while loading receipt
            </div>
            <div class="pt-2" *ngIf="record && !loading">
                <div class="row border-bottom-2px border-dark">
                    <div class="col-lg-12" *ngIf="isPrintLogo">
                        <banner-setting [reportName]='"Sevices View Receipt Header"'> </banner-setting>
                    </div>
                </div>
                <div class="row border-bottom-2px border-dark py-2">
                    <div class="col-lg-12 text-center ">
                        <h3 class=" mb-0 fontbold balck">Advance Receipt</h3>
                    </div>
                    <div class="col-lg-12 text-center mt-1">
                        <h4 class="font-weight-normal">(HEALTH CARE SERVICE - SAC )</h4>
                    </div>
                </div>
                <div class=" mt-2">
                    <div class="d-flex border-bottom-2px border-dark pb-1">
                        <div class="col-5 pr-0 pl-0">
                            <table width="100%" class="table-custom">
                                <tr>
                                    <th class="px-2 w-10px ">
                                        <span class="white-space-nowrap">
                                            Patient Name
                                        </span>
                                    </th>
                                    <th>
                                        <span class="margin-Right">: </span>
                                    </th>
                                    <td>
                                        <h5 class="text-uppercase" [textContent]="admission.fullName"></h5>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="px-2  text-left  paddingLeft  ">
                                        <div class="white-space-nowrap ">
                                            Under Dr
                                        </div>
                                    </th>
                                    <th>
                                        <span class="margin-Right">: </span>
                                    </th>
                                    <td>
                                        <h5 class="text-uppercase" [textContent]="admission.providerName"></h5>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="px-2  text-left  paddingLeft  ">
                                        <div class="white-space-nowrap ">
                                            Org Name:
                                        </div>
                                    </th>
                                    <th>
                                        <span class="margin-Right">: </span>
                                    </th>
                                    <td>
                                        <h5 class="text-uppercase" [textContent]=""></h5>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-7 pl-0">
                            <table class="table-custom">
                                <tr class="mb-2 border-top border-bottom border-dark">
                                    <th>
                                        <div class="white-space-nowrap">
                                            IP No
                                        </div>
                                    </th>
                                    <th>
                                        <span class="margin-Right">: </span>
                                    </th>
                                    <td>
                                        <h5 [textContent]="admission.admissionNo"></h5>
                                    </td>
                                    <th class="pl-5">
                                        <div class="white-space-nowrap">
                                            Date
                                        </div>
                                    </th>
                                    <th>
                                        <span class="margin-Right">: </span>
                                    </th>
                                    <td>
                                        <h5 *ngIf="isAdmission" [textContent]="admission.admissionDate | date: 'dd/MM/yyyy'"></h5>
                                        <h5 class="white-space-nowrap" *ngIf="!isAdmission">
                                            <span [textContent]="admission.admissionDate | date: 'dd/MM/yyyy'"></span>
                                            <!--<span [textContent]="' ' + admission.timeString"></span>-->
                                        </h5>
                                    </td>
                                </tr>
                                <tr pl-2>
                                    <th class="w-10px white-space-nowrap pl-8">
                                        <div>
                                            UMR No
                                        </div>
                                    </th>
                                    <th>
                                        <span class="margin-Right">: </span>
                                    </th>
                                    <td>
                                        <h5 [textContent]="admission.umrNo"></h5>
                                    </td>
                                    <th class="pl-5">
                                        <div class="white-space-nowrap">
                                            Booking Date
                                        </div>
                                    </th>
                                    <th>
                                        <span class="margin-Right">: </span>
                                    </th>
                                    <td>
                                        <h5 [textContent]="record.createdDate | date:'dd/MM/yyyy'"></h5>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="w-10px white-space-nowrap pl-8">
                                        <div>
                                            Receipt No
                                        </div>
                                    </th>
                                    <th>
                                        <span class="margin-Right">: </span>
                                    </th>
                                    <td>
                                        <h5 [textContent]="'RN-' + record.receiptNo"></h5>
                                    </td>
                                    <th class="pl-5">
                                        <div>
                                            Age/Sex
                                        </div>
                                    </th>
                                    <th>
                                        <span class="margin-Right">: </span>
                                    </th>
                                    <td>
                                        <div class="white-space-nowrap">
                                            <span [textContent]="admission.age"></span>/
                                            <span [textContent]="(admission.gender === 'F' ? 'Female' : 'Male')"></span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="w-10px white-space-nowrap pl-8">
                                        <div>
                                            Bed Type
                                        </div>
                                    </th>
                                    <th>
                                        <span class="margin-Right">: </span>
                                    </th>
                                    <td>
                                        <h5 class="white-space-nowrap" [textContent]="admission.chargeCategoryName"></h5>
                                    </td>
                                    <th class="pl-5">
                                        <div>
                                            Bed No
                                        </div>
                                    </th>
                                    <th>
                                        <span class="margin-Right">: </span>
                                    </th>
                                    <td>
                                        <h5 [textContent]="admission.bedNumber"></h5>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="d-flex mt-2">
                        <div class="col pr-0 pl-0">
                            <table width="100%" class="table-custom">
                                <tr class="vertical-align-top">
                                    <th class="px-2 w-10px  ">
                                        <span class="white-space-nowrap">
                                            Pay Mode
                                        </span>
                                    </th>
                                    <th>
                                        <span class="margin-Right">: </span>
                                    </th>
                                    <td>
                                        <h5 class="text-uppercase" [textContent]="record.payTypeName"></h5>
                                    </td>
                                </tr>
                                <tr class="vertical-align-top">
                                    <th class="px-2  text-left  paddingLeft  ">
                                        <div class="white-space-nowrap ">
                                            Rupees
                                        </div>
                                    </th>
                                    <th>
                                        <span class="margin-Right">: </span>
                                    </th>
                                    <td>
                                        <h5 class="text-capitalize" [textContent]="amtInWords + ' Only' "></h5>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col pr-0 pl-0">
                            <table width="100%" class="table-custom">
                                <tr class="vertical-align-top">
                                    <th class="px-2 w-10px ">
                                        <span [textContent]=" "></span>
                                    </th>
                                    <th>
                                        <span class="margin-Right"> &nbsp;</span>
                                    </th>
                                    <td>
                                        <span class="text-uppercase" [textContent]=" "></span>
                                    </td>
                                </tr>
                                <tr class="vertical-align-top">
                                    <th class="px-2  text-left  paddingLeft  ">

                                    </th>
                                    <th>
                                        <span class="margin-Right"></span>
                                    </th>
                                    <td class="text-right">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <span class="override-font-weight pr-3">  Received Amount Rs :</span>
                                            <h5 [textContent]="record.cost | number:'1.2-2'"></h5>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="d-flex">
                        <span [textContent]="'Remarks :'"> </span> &nbsp;&nbsp;
                        <span [textContent]="record.remarks"></span>
                    </div>
                    <div class="d-flex">
                        <span class="fzinprint" *ngIf="practiceLocations[0] && practiceLocations[0].optionalText1">
                            Note: {{practiceLocations[0].optionalText1}}
                        </span>
                    </div>
                    <div class="d-flex mt-5 p-0 overflow-auto font-12 font-weight-bold justify-content-end column-gap15px border-bottom-2px border-dark p-1">
                        <span [textContent]="'Print Date/Time: ' + (printedDate | date:'dd-MM-yyyy, h:mm:ss a')" class="mr-4"></span>
                        <span [textContent]="record.createdByName"></span>
                    </div>
                </div>
                <div *ngIf="isFooter">
                    <banner-setting [reportName]='"Sevices View Receipt Footer"'> </banner-setting>
                </div>
            </div>
        </div>
    </div>
</div>