import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { FormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../../shared/services/formCreator.service';
import { ApiResources } from '../../../../../shared/helpers';
import { HttpService, NotifyService } from '../../../../../shared/services';
import { DomSanitizer } from '@angular/platform-browser';
import { finalize, takeUntil } from 'rxjs';
import { Page } from '../../../../../shared/models';


@Component({
    selector: 'form-middleware',
    templateUrl: './formmiddleware.html',
    providers: [QuestionControlService]
})
export class FormMiddleWarePage implements OnInit {
    @Output() onClose = new EventEmitter<any>();
    @Input() previewCheck: boolean;
    @Input() questions: any[] | null = [];
    @Input() htmlString:any;
    @Input() patientDetails: any;
    form!: FormGroup;
    payLoad = '';
    myEditableSpans: any;
    sectionDataValue: any;
    lookupData: any;
    page: Page;
    constructor(private qcs: QuestionControlService, private sanitizer: DomSanitizer, private httpService: HttpService, private readonly notifyService: NotifyService) { this.page = new Page(); }

    ngOnInit() {
        this.fetchLookup();
        if (this.htmlString) {
            //this.form = this.qcs.toFormGroup(this.questions);

            // Create a DOMParser instance
            const parser = new DOMParser();

            // Parse the HTML content
            const parsedHTML = parser.parseFromString(this.htmlString, 'text/html');


            // Find all spans with the class name "myeditable"
            this.myEditableSpans = parsedHTML.querySelectorAll('span.myeditable');

            if (this.myEditableSpans.length > 0) {
                // Assign onclick function to each span
                this.myEditableSpans.forEach(span => {
                    span.setAttribute('onclick', 'handleMyEditableClick(this)');
                });
            }


            window["sectionDataValue"] = this.sectionDataValue;

            const nonEditableCells = parsedHTML.querySelectorAll('.non-editable');

            nonEditableCells.forEach(cell => {
                cell.setAttribute('contenteditable', 'false');
            });

            const textAreaBasedSpan = parsedHTML.querySelectorAll('span[control-tag-type="textareaType"]');
            const textAreaBasedBasedSpanArray = Array.from(textAreaBasedSpan);

            if (textAreaBasedBasedSpanArray.length > 0) {
                textAreaBasedBasedSpanArray.forEach(span => {

                    if (span.getAttribute('input-type') === 'textarea') {
                        // create a textarea element
                        const textarea = document.createElement('textarea');
                        textarea.classList.add('form-control')

                        // copy attributes from the span to the textarea
                        const attributes = span.attributes;
                        for (let i = 0; i < attributes.length; i++) {
                            const attr = attributes[i];
                            if (attr.name !== 'class') {
                                // exclude the 'class' attribute to preserve custom classes
                                textarea.setAttribute(attr.name, attr.value);
                            }
                        }

                        // append the textarea to the parent of the span
                        span.parentNode.replaceChild(textarea, span);

                    };
                });
            };


            const dottedcontrolBasedSpan = parsedHTML.querySelectorAll('span[control-tag-type="dottedControlTag"]');
            const dottedcontrolBasedSpanArray = Array.from(dottedcontrolBasedSpan);
            if (dottedcontrolBasedSpanArray.length > 0) {
                dottedcontrolBasedSpanArray.forEach(span => {

                    span.setAttribute('onclick', 'dottedControlToggle(this)');
                    span.textContent = " ";
                    span.classList.add('dottedControlPop');

                });

            }


            // Find all span elements with the attribute control-tag-type="lookupbased"
            const lookupBasedSpans = parsedHTML.querySelectorAll('span[control-tag-type="lookUpBased"]');


            // You can convert it to an array for easier manipulation if needed
            const lookupBasedSpansArray = Array.from(lookupBasedSpans);
            if (lookupBasedSpansArray.length > 0) {
                lookupBasedSpansArray.forEach(span => {
                    const customNumber = span.getAttribute('data-custom-number');
                    const data_uid = span.getAttribute('data-uid');

                    const lookupId10Array = this.lookupData.filter(item => item.lookupId === Number(customNumber));

                    var checkboxesContainer = document.createElement('div');
                    checkboxesContainer.classList.add('divOptions');
                    checkboxesContainer.style.display = 'none'; // Hide the checkboxes container initially

                    lookupId10Array.forEach(item => {


                        const span = document.createElement('span');
                        span.textContent = item.name; // Change this to the appropriate property

                        const checkbox = document.createElement('input');
                        checkbox.type = 'checkbox';
                        checkbox.classList.add('mr-1');
                        checkbox.value = item.name; // Change this to the appropriate property
                        checkbox.setAttribute('data-uid', data_uid);
                        // Add a change event listener to the checkbox

                        //checkbox.setAttribute('onclick', 'checkboxchange(this)')

                        //label.appendChild(checkbox);
                        const divLabel = document.createElement('label');
                        divLabel.classList.add('d-block');
                        divLabel.appendChild(checkbox);
                        divLabel.appendChild(span);
                        checkboxesContainer.appendChild(divLabel);
                    });
                    // Create a new wrapper element
                    const spanWrapper = document.createElement('span');

                    // Copy the attributes of the original span to the wrapper
                    Array.from(span.attributes).forEach(attr => {
                        spanWrapper.setAttribute(attr.name, attr.value);
                    });

                    // Append the original content of the span to the wrapper
                    spanWrapper.innerHTML = span.innerHTML;

                    // Append the checkboxesContainer to the wrapper
                    spanWrapper.appendChild(checkboxesContainer);

                    // Replace the original span with the wrapper
                    span.parentNode.replaceChild(spanWrapper, span);
                });
            }



            // Get the modified HTML content from the parsedHTML object
            const modifiedHTML = parsedHTML.documentElement.outerHTML;


            this.htmlString = this.sanitizer.bypassSecurityTrustHtml(modifiedHTML);
        } 
       
    }

    onSubmit() {
        var FormValues = JSON.stringify(this.form.getRawValue());
        const request = { isactive: true, PatientId: this.patientDetails.PatientId, data: FormValues, SectionId: this.patientDetails.SectionID };
        this.httpService.post(ApiResources.getURI(ApiResources.sectiondata.base, ApiResources.sectiondata.insert), request)
            .subscribe((response: any) => {
                this.notifyService.success("Data Saved Succesfully");

            });

    }
    onCloseModal() {
        this.onClose.emit();
    }
    fetchLookup() {
        this.httpService.get(ApiResources.getURI(ApiResources.lookups.base, ApiResources.lookups.fetchlookup), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: any) => {
                this.lookupData = response
            })

    }
}