export class TendersTimeLine {
    pharmacyProductRequestHeaderNo:string
    description:string;
    createdByName: string;
    modifiedByName: string;
    createdDate: Date;
    roleName: string;
}




