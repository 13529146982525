import { Subject } from "rxjs";
import { ApiResources } from "../helpers";
import { IUserAccount } from "./user-account.model";

export class Page {
    loading: boolean;
    error: boolean;
    loaded: boolean;
    unSubscribe: Subject<any>;
    userAccount: IUserAccount;

    constructor() {
        this.loading = true;
        this.error = false;
        this.loaded = false;
        this.unSubscribe = new Subject();
    }

    markError(): void {
        this.error = true;
        this.loading = false;
        this.loaded = true;
    }

    unsubscribeAll(): void {
        this.unSubscribe.next(null);
        this.unSubscribe.complete();
    }

    getImage = (url: string): string => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }

        return null;
    }
    getImages = (url: string): string => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImageFromSftp)}?imagePath=${url}`
        }

        return null;
    }
}