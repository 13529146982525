
<div class="  my-1 row scroll-obsecNotHeading text-black">
    <div class="container" style="text-align:initial;">
        <div class="card-box mb-0">
            <div class="clearfix mb-3">
                <div>
                    <button class="btn btn-info" type="button" (click)="onChangePrintType(false)">
                        <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangePrintType(true)">
                        <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangeFooter(false)">
                        <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangeFooter(true)">
                        <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
                    </button>
                </div>
                <div class="float-right row">
                    <h4 class="m-0 d-print-none heading2">Pediatric Encounter Full Transcript</h4>
                    <button [useExistingCss]="true" printSectionId="gynprint" ngxPrint class="close d-print-none"><i class="fe-printer" placement="left" ngbTooltip="Print"></i></button>
                </div>
            </div>
            <div class="row" *ngIf="page.loading">
                <div class="col-12">
                    <div class="d-flex align-items-center">
                        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                        <span class="ml-2">Please wait while generating Pediatric Full Transcript ...</span>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="!page.loading && (!fullTranscript || (!fullTranscript.pediatricEncounterId))">
                <div class="col-12 mh-300">
                    <no-data title=" Full Transcript for Pediatric Encounter"></no-data>
                </div>
            </div>
            <div id="gynprint">
                <ng-container *ngIf="!page.loading && fullTranscript">
                    <div class="col-12" *ngIf="isPrintLogo">
                        <banner-setting [reportName]='"Pediattric FullTranscript Header"'></banner-setting>
                    </div><hr />
                    <div class="d-flex justify-content-between heading1 white-space-nowrap">
                        <div class="col-6 p-0">
                            <div class="text-truncate">
                                <label>Patient Name:</label>
                                <span class="pl-1 text-uppercase" [textContent]="fullTranscript.patientName"></span>
                            </div>
                            <div *ngIf="fullTranscript.patientMobile">
                                <label>Mobile No:</label>
                                <span class="pl-1" [textContent]="fullTranscript.patientMobile + ' / '"></span>
                                <span class="pl-1" *ngIf="fullTranscript.patientGender" [textContent]="(fullTranscript.patientGender | gender) + ' / '"></span>
                                <span class="pl-1" *ngIf="fullTranscript.patientAge" [textContent]="fullTranscript.patientAge +' yrs'"></span>
                                <span class="pl-1" *ngIf="!fullTranscript.patientAge" [textContent]="'0 yrs' "></span>
                            </div>
                        </div>
                        <div class="d-inline-block">
                            <div>
                                <label>Appointment No:</label>
                                <span class="pl-1">{{fullTranscript.appointmentNo}}</span>
                            </div>
                            <div>
                                <label>Appointment Date:</label>
                                <span class="pl-1" [textContent]="(fullTranscript.appointmentDate | date:'dd/MM/yyyy') + ' '+ fullTranscript.appointmentTimeString "></span>
                            </div>
                        </div>
                    </div><hr />
                </ng-container>

                <div class="col-12 textOfEveryVw">

                    <div class="dashboard-box dashboard-table-box" *ngIf="measurements && measurements !== null">
                        <h4 class="dashboard-title">Measurements</h4>
                        <div class="dashboard-body">
                            <div class="overflow-auto">

                                <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="measurements && measurements !== null">

                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black">
                                        <th colspan="2">Basic Details</th>
                                    </tr>


                                    <tr *ngIf="measurements.babyOf">
                                        <td class="col-8">Baby Of</td>
                                        <td class="col-4" [textContent]="measurements.babyOf"></td>
                                    </tr>

                                    <tr *ngIf="measurements.sex">
                                        <td class="col-8">Sex</td>
                                        <td class="col-4" [textContent]="measurements.sex | gender"></td>
                                    </tr>


                                    <tr *ngIf="measurements.fatherName">
                                        <td class="col-8"> Father Name</td>
                                        <td class="col-4" [textContent]="measurements.fatherName"></td>
                                    </tr>
                                    <tr *ngIf="measurements.visitDate">
                                        <td class="col-8"> Visit Date</td>
                                        <td class="col-4" [textContent]="measurements.visitDate| date: 'dd/MM/yyyy'"></td>
                                    </tr>
                                    <tr *ngIf="measurements.consultantDoctor">
                                        <td class="col-8"> Consultant Doctor</td>
                                        <td class="col-4" style="text-transform: uppercase" [textContent]="measurements.consultantDoctor"></td>
                                    </tr>
                                </table>
                            </div>



                            <div class="row" *ngIf=" measurements && measurements !== null && (measurements.lengthInCms || measurements.weightInKgs ||measurements.bmi||measurements.tcb||measurements.todayVisited ||measurements.anyOtherDetails ||measurements.headCircumference)">
                                <div class="col-12">

                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <tr class="bg-soft-dark" style="border-bottom:1px solid black">
                                            <th colspan="2">Height/Weight/BMI</th>
                                        </tr>
                                        <tr *ngIf="measurements.lengthInCms">
                                            <td class="col-8"> Length In Cms</td>
                                            <td class="col-4" [textContent]="measurements.lengthInCms"></td>
                                        </tr>
                                        <tr *ngIf="measurements.weightInKgs">
                                            <td class="col-8">Weight In Kgs</td>
                                            <td class="col-4" [textContent]="measurements.weightInKgs"></td>
                                        </tr>


                                        <tr *ngIf="measurements.headCircumference">
                                            <td class="col-8"> Head Circumference</td>
                                            <td class="col-4" [textContent]="measurements.headCircumference"></td>
                                        </tr>
                                        <tr *ngIf="measurements.bmi">
                                            <td class="col-8">BMI</td>
                                            <td class="col-4" [textContent]="measurements.bmi"></td>
                                        </tr>

                                        <tr *ngIf="measurements.tcb">
                                            <td class="col-8">TCB</td>
                                            <td class="col-4" [textContent]="measurements.tcb"></td>
                                        </tr>
                                        <tr *ngIf="measurements.todayVisited">
                                            <td class="col-8">Today Visited</td>
                                            <td class="col-4" [textContent]="measurements.todayVisited"></td>
                                        </tr>
                                        <tr *ngIf="measurements.anyOtherDetails">
                                            <td class="col-8">Any Other Details</td>
                                            <td class="col-4" style="white-space: normal; word-break: break-all;" [textContent]="measurements.anyOtherDetails"></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row" *ngIf=" measurements && measurements !== null && (measurements.pulseRate || measurements.temparature ||measurements.respiratoryRate)">
                                <div class="col-12">

                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <tr class="bg-soft-dark" style="border-bottom:1px solid black">
                                            <th colspan="2">Examination Findings</th>
                                        </tr>
                                        <tr *ngIf="measurements.pulseRate">
                                            <td class="col-8">Pulse Rate(/Min)</td>
                                            <td class="col-4" [textContent]="measurements.pulseRate"></td>
                                        </tr>
                                        <tr *ngIf="measurements.temparature">
                                            <td class="col-8">Temparature(F)</td>
                                            <td class="col-4" [textContent]="measurements.temparature"></td>
                                        </tr>
                                        <tr *ngIf="measurements.respiratoryRate">
                                            <td class="col-8"> Respiratory Rate(/Min)</td>
                                            <td class="col-4" [textContent]="measurements.respiratoryRate"></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div class="row" *ngIf=" measurements && measurements !== null && (measurements.edd || measurements.cga ||measurements.significantHistory ||measurements.durgAllergies || measurements.drugName || measurements.adverseDrugReaction ||measurements.anyOtherDetails)">
                                <div class="col-12">

                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <tr class="bg-soft-dark" style="border-bottom:1px solid black">
                                            <th colspan="2">Birth Details</th>
                                        </tr>
                                        <tr *ngIf="measurements.edd">
                                            <td class="col-8">EDD</td>
                                            <td class="col-4" [textContent]="measurements.edd| date: 'dd/MM/yyyy'"></td>
                                        </tr>
                                        <tr *ngIf="measurements.cga">
                                            <td class="col-8">CGA</td>
                                            <td class="col-4" [textContent]="measurements.cga"></td>
                                        </tr>
                                        <tr *ngIf="measurements.significantHistory">
                                            <td class="col-8">Any Significant History</td>
                                            <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="measurements.significantHistory"></td>
                                        </tr>
                                        <tr *ngIf="measurements.durgAllergies">
                                            <td class="col-8">Durg Allergies</td>
                                            <td class="col-4" [textContent]="measurements.durgAllergies"></td>
                                        </tr>
                                        <tr *ngIf="measurements.drugName">
                                            <td class="col-8">Drug Name</td>
                                            <td class="col-4" [textContent]="measurements.drugName"></td>
                                        </tr>
                                        <tr *ngIf="measurements.adverseDrugReaction">
                                            <td class="col-8">Adverse Drug Reaction</td>
                                            <td class="col-4" [textContent]="measurements.adverseDrugReaction"></td>
                                        </tr>

                                    </table>
                                </div>
                            </div>
                        </div>



                    </div>
                    <div class="dashboard-box dashboard-table-box" *ngIf="clinicalExamination && clinicalExamination!== null">
                        <h4 class="dashboard-title">Clinical Examination VF</h4>
                        <div class="dashboard-body">
                            <div class="overflow-auto">
                                <div class="dashboard-break">
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="clinicalExamination && clinicalExamination!== null">
                                            <th colspan="2">Clinical Examination</th>
                                        </tr>

                                        <tr *ngIf="clinicalExamination.fatherName">
                                            <td class="col-8"> Father Name</td>
                                            <td class="col-4" [textContent]="clinicalExamination.fatherName"></td>
                                        </tr>
                                        <tr *ngIf="clinicalExamination.visitDate">
                                            <td class="col-8"> Visit Date</td>
                                            <td class="col-4" [textContent]="clinicalExamination.visitDate| date: 'dd/MM/yyyy'"></td>
                                        </tr>


                                        <tr *ngIf="clinicalExamination.cvs">
                                            <td class="col-8"> CVS</td>
                                            <td class="col-4" [textContent]="clinicalExamination.cvs"></td>
                                        </tr>
                                        <tr *ngIf="clinicalExamination.rs">
                                            <td class="col-8"> RS</td>
                                            <td class="col-4" [textContent]="clinicalExamination.rs"></td>
                                        </tr>
                                        <tr *ngIf="clinicalExamination.pa">
                                            <td class="col-8"> PA</td>
                                            <td class="col-4" [textContent]="clinicalExamination.pa"></td>
                                        </tr>
                                        <tr *ngIf="clinicalExamination.cns">
                                            <td class="col-8">CNS</td>
                                            <td class="col-4" [textContent]="clinicalExamination.cns"></td>
                                        </tr>
                                        <tr *ngIf="clinicalExamination.otherFindings">
                                            <td class="col-8">Other Findings</td>
                                            <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="clinicalExamination.otherFindings"></td>
                                        </tr>

                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf=" clinicalExamination && clinicalExamination !== null && (clinicalExamination.feedingHistory || clinicalExamination.adequate||clinicalExamination.exclusiveBreastFeeding||clinicalExamination.weaningStartedAt||clinicalExamination.pulseRate||clinicalExamination.respiratoryRate ||clinicalExamination.bloodPressure ||clinicalExamination.reAssesment)">

                            <div class="col-12">
                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="clinicalExamination && clinicalExamination!== null">
                                        <th colspan="2">Nutritional History</th>
                                    </tr>
                                    <tr *ngIf="clinicalExamination.feedingHistory && clinicalExamination.feedingHistory.length >0">
                                        <td class="col-8">
                                            Feeding History
                                        <td class="col-4" style="white-space:normal;">
                                            <span *ngFor="let item of clinicalExamination.feedingHistory" [textContent]="item+',  '"></span>
                                        </td>

                                    </tr>


                                    <tr *ngIf="clinicalExamination.adequate">
                                        <td class="col-8">Adequate/InAdequate</td>
                                        <td class="col-4" [textContent]="clinicalExamination.adequate"></td>
                                    </tr>
                                    <tr *ngIf="clinicalExamination.exclusiveBreastFeeding">
                                        <td class="col-8">Exclusive Breast Feeding</td>
                                        <td class="col-4" [textContent]="clinicalExamination.exclusiveBreastFeeding| date: 'dd/MM/yyyy'"></td>
                                    </tr>
                                    <tr *ngIf="clinicalExamination.weaningStartedAt">
                                        <td class="col-8">Weaning Started</td>
                                        <td class="col-4" [textContent]="clinicalExamination.weaningStartedAt| date: 'dd/MM/yyyy'"></td>
                                    </tr>
                                    <tr *ngIf="clinicalExamination.pulseRate">
                                        <td class="col-8">Pulse Rate(/Min)</td>
                                        <td class="col-4" [textContent]="clinicalExamination.pulseRate"></td>
                                    </tr>
                                    <tr *ngIf="clinicalExamination.respiratoryRate">
                                        <td class="col-8">Respiratory Rate(/Min)</td>
                                        <td class="col-4" [textContent]="clinicalExamination.respiratoryRate"></td>
                                    </tr>
                                    <tr *ngIf="clinicalExamination.bloodPressure">
                                        <td class="col-8">Blood Pressure</td>
                                        <td class="col-4" [textContent]="clinicalExamination.bloodPressure"></td>
                                    </tr>
                                    <tr *ngIf="clinicalExamination.reAssesment">
                                        <td class="col-8">Re Assesment</td>
                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="clinicalExamination.reAssesment"></td>
                                    </tr>
                                    <tr *ngIf="clinicalExamination.admission">
                                        <td class="col-8">Admission</td>
                                        <td class="col-4" [textContent]="clinicalExamination.admission"></td>
                                    </tr>



                                </table>
                            </div>
                        </div>

                    </div>
                    <div class="dashboard-box dashboard-table-box" *ngIf="pediatricRisk && pediatricRisk!== null">
                        <h4 class="dashboard-title">Pediatric Risk Factors</h4>
                        <div class="dashboard-body">
                            <div class="overflow-auto">
                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="pediatricRisk.anySignificantAllergyHistory">
                                        <th colspan="2">Pediatric Risk Factors</th>
                                    </tr>


                                    <tr *ngIf="pediatricRisk.patientNames  && pediatricRisk.patientNames.length >0">


                                        <td colspan="2" style=" white-space: normal; word-break: break-all;">
                                            <ul class="dashboard-ul row">

                                                <li *ngFor="let item of pediatricRisk.patientNames" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr *ngIf="pediatricRisk.anySignificantAllergyHistory">
                                        <td class="col-8"><strong>Any Significant Allergy History </strong></td>
                                        <td class="col-4" [textContent]="pediatricRisk.anySignificantAllergyHistory"></td>
                                    </tr>
                                    <tr *ngIf="pediatricRisk.anySignificances  && pediatricRisk.anySignificances.length >0">

                                        <td colspan="2" style=" white-space: normal; word-break: break-all;">
                                            <ul class="dashboard-ul row">

                                                <li *ngFor="let item of pediatricRisk.anySignificances" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                            </ul>

                                        </td>
                                    </tr>

                                    <tr *ngIf="pediatricRisk.majorMalformation">
                                        <td class="col-8"><strong> Major Malformation</strong></td>
                                        <td class="col-4" [textContent]="pediatricRisk.majorMalformation"></td>
                                    </tr>

                                    <tr *ngIf="pediatricRisk.inbornErrorsOfMetabolism">
                                        <td class="col-8"> <strong> Inborn Errors Of Metabolism </strong> </td>
                                        <td class="col-4" [textContent]="pediatricRisk.inbornErrorsOfMetabolism"></td>
                                    </tr>
                                    <tr *ngIf="pediatricRisk.epilepsy">
                                        <td class="col-8"> <strong> Epilepsy </strong> </td>
                                        <td class="col-4" [textContent]="pediatricRisk.epilepsy"></td>
                                    </tr>

                                    <tr *ngIf="pediatricRisk.congenitalHeartDisorder">
                                        <td class="col-8"><strong> Congential Heart Disorder </strong></td>
                                        <td class="col-4" [textContent]="pediatricRisk.congenitalHeartDisorder"></td>
                                    </tr>
                                    <tr *ngIf="pediatricRisk.heartDisorder  && pediatricRisk.heartDisorder .length >0">

                                        <td colspan="2" style=" white-space: normal; word-break: break-all;">
                                            <ul class="dashboard-ul row">

                                                <li *ngFor="let item of pediatricRisk.heartDisorder" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                            </ul>

                                        </td>
                                    </tr>

                                    <tr *ngIf="pediatricRisk.anySurgicalProcedure">
                                        <td class="col-8"><strong> Any Surgical Procedure</strong></td>
                                        <td class="col-4" [textContent]="pediatricRisk.anySurgicalProcedure"></td>
                                    </tr>
                                </table>

                            </div>
                        </div>
                    </div>
                    <div class="dashboard-box dashboard-table-box" *ngIf="neonatalRisk && neonatalRisk!== null">
                        <h4 class="dashboard-title">Neonatal Risk Factors </h4>
                        <div class="dashboard-body">
                            <div class="overflow-auto">
                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="neonatalRisk && neonatalRisk!== null">
                                        <th colspan="2">Neonatal Risk Factors</th>
                                    </tr>


                                    <tr *ngIf="neonatalRisk.growthStatus">
                                        <td class="col-8"><strong>Growth Status </strong></td>
                                        <td class="col-4" [textContent]="neonatalRisk.growthStatus"></td>

                                    </tr>

                                    <tr *ngIf="neonatalRisk.growthStatus1  && neonatalRisk.growthStatus1.length >0">

                                        <td colspan="2" style=" white-space: normal; word-break: break-all;">
                                            <ul class="dashboard-ul row">

                                                <li *ngFor="let item of neonatalRisk.growthStatus1" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                            </ul>

                                        </td>
                                    </tr>

                                    <tr *ngIf="neonatalRisk.anySurgicalProcedure">
                                        <td class="col-8"><strong>Any Surgical Procedure</strong></td>
                                        <td class="col-4" [textContent]="neonatalRisk.anySurgicalProcedure"></td>
                                    </tr>



                                    <tr *ngIf="neonatalRisk.anySurgicalProcedure1   && neonatalRisk.anySurgicalProcedure1 .length >0">

                                        <td colspan="2" style=" white-space: normal; word-break: break-all;">
                                            <ul class="dashboard-ul row">

                                                <li *ngFor="let item of neonatalRisk. anySurgicalProcedure1" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                            </ul>

                                        </td>
                                    </tr>
                                    <tr *ngIf="neonatalRisk.malFormations">
                                        <td class="col-8"><strong> Mal Formations </strong></td>
                                        <td class="col-4" [textContent]="neonatalRisk.malFormations"></td>
                                    </tr>
                                    <tr *ngIf="neonatalRisk.malFormations1 && neonatalRisk.malFormations1!== null">
                                        <td class="col-8"><strong> Mal Formations Property </strong> </td>
                                        <td class="col-4" [textContent]="neonatalRisk.malFormations1"></td>
                                    </tr>
                                </table>

                            </div>
                        </div>
                    </div>
                    <div class="dashboard-box dashboard-table-box" *ngIf="others && others!== null">
                        <h4 class="dashboard-title">Others</h4>
                        <div class="dashboard-body">
                            <div class="overflow-auto">
                                <div class="row" *ngIf=" others && others!== null && ( others.sex || others.fatherName ||others.phone )">
                                    <div class="col-12">

                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="others && others!== null">
                                                <th colspan="2">Basic Details</th>
                                            </tr>
                                            <tr *ngIf="others.sex">

                                                <td class="col-8">Sex</td>
                                                <td class="col-4" [textContent]="others.sex | gender"></td>
                                            </tr>
                                            <tr *ngIf="others.fatherName">
                                                <td class="col-8">Father Name</td>
                                                <td class="col-4" [textContent]="others.fatherName"></td>
                                            </tr>
                                            <tr *ngIf="others.address">
                                                <td class="col-8">Address</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="others.address"></td>
                                            </tr>
                                            <tr *ngIf="others.phone">
                                                <td class="col-8">Mobile</td>
                                                <td class="col-4" [textContent]="others.phone"></td>
                                            </tr>


                                        </table>
                                    </div>
                                </div>
                                <div class="row" *ngIf="others && others !== null && (others.heightInFeet || others.heightInMeters||others.weight||others.headCircumference||others.bmi || others.complaints )">
                                    <div class="col-12">

                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="others && others!== null">
                                                <th colspan="2">Measurement</th>
                                            </tr>
                                            <tr *ngIf="others.heightInFeet">
                                                <td class="col-8">Height (In Feet)</td>
                                                <td class="col-4" [textContent]="others.heightInFeet"></td>
                                            </tr>
                                            <tr *ngIf="others.heightInMeters">
                                                <td class="col-8">Height (In Meters)</td>
                                                <td class="col-4" [textContent]="others.heightInMeters"></td>
                                            </tr>
                                            <tr *ngIf="others.weight">
                                                <td class="col-8">Weight (In Kgs) </td>
                                                <td class="col-4" [textContent]="others.weight"></td>
                                            </tr>
                                            <tr *ngIf="others.bmi">
                                                <td class="col-8">BMI</td>
                                                <td class="col-4" [textContent]="others.bmi"></td>
                                            </tr>
                                            <tr *ngIf="others.headCircumference">
                                                <td class="col-8">HC (cm)</td>
                                                <td class="col-4" [textContent]="others.headCircumference"></td>
                                            </tr>
                                            <tr *ngIf="others.complaints">
                                                <td class="col-8">Complaints</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="others.complaints"></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div class="row" *ngIf="others && others !== null && (others.weight1 || others.height1||others.hc )">
                                    <div class="col-12">

                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="others && others!== null">
                                                <th colspan="2">Growth Assesment</th>
                                            </tr>
                                            <tr *ngIf="others.weight1">
                                                <td class="col-8">Weight</td>
                                                <td class="col-4" [textContent]="others.weight1"></td>
                                            </tr>
                                            <tr *ngIf="others.height">
                                                <td class="col-8">Height</td>
                                                <td class="col-4" [textContent]="others.height"></td>
                                            </tr>
                                            <tr *ngIf="others.hc">
                                                <td class="col-8">HC</td>
                                                <td class="col-4" [textContent]="others.hc"></td>
                                            </tr>


                                        </table>
                                    </div>
                                </div>
                                <div class="row" *ngIf="others && others !== null && (others.cvs || others.rs ||others.pa ||others.cns ||others.otherFindings)">
                                    <div class="col-12">

                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="others && others!== null">
                                                <th colspan="2">Clinical Examination</th>
                                            </tr>


                                            <tr *ngIf="others.cvs">
                                                <td class="col-8">CVS</td>
                                                <td class="col-4" [textContent]="others.cvs"></td>
                                            </tr>
                                            <tr *ngIf="others.rs">
                                                <td class="col-8"> RS</td>
                                                <td class="col-4" [textContent]="others.rs"></td>
                                            </tr>
                                            <tr *ngIf="others.pa">
                                                <td class="col-8"> PA</td>
                                                <td class="col-4" [textContent]="others.pa"></td>
                                            </tr>
                                            <tr *ngIf="others.cns">
                                                <td class="col-8">CNS</td>
                                                <td class="col-4" [textContent]="others.cns"></td>
                                            </tr>
                                            <tr *ngIf="others.otherFindings">
                                                <td class="col-8">Other Findings</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="others.otherFindings"></td>
                                            </tr>


                                        </table>
                                    </div>
                                </div>

                                <div class="row" *ngIf="others && others !== null && (others.tone|| others.dasiiIndicated||others.developmentAssesmentDetails||others.ddst||others.mChat||others.abnormalDetails||others.otherDetails1 )">
                                    <div class="col-12">

                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="others && others!== null">
                                                <th colspan="2">Development Assesment Details</th>
                                            </tr>
                                            <tr *ngIf="others.tone">
                                                <td class="col-8">Tone</td>
                                                <td class="col-4" [textContent]="others.tone"></td>
                                            </tr>
                                            <tr *ngIf="others.abnormalDetails">
                                                <td class="col-8">If Abnormal Details</td>
                                                <td class="col-4" [textContent]="others.abnormalDetails"></td>
                                            </tr>
                                            <tr *ngIf="others.dasiiIndicated">
                                                <td class="col-8">Dasii Indicated</td>
                                                <td class="col-4" [textContent]="others.dasiiIndicated"></td>
                                            </tr>
                                            <tr *ngIf="others.developmentAssesmentDetails">
                                                <td class="col-8">Development Assesment Details </td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="others.developmentAssesmentDetails"></td>
                                            </tr>
                                            <tr *ngIf="others.ddst">
                                                <td class="col-8">DDST</td>
                                                <td class="col-4" [textContent]="others.ddst"></td>
                                            </tr>
                                            <tr *ngIf="others.otherDetails1">
                                                <td class="col-8">If Other Details</td>
                                                <td class="col-4" [textContent]="others.otherDetails1"></td>
                                            </tr>

                                            <tr *ngIf="others.mChat">
                                                <td class="col-8">M-Chat</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="others.mChat"></td>
                                            </tr>


                                        </table>
                                    </div>
                                </div>


                                <div class="row" *ngIf="others && others !== null && (others.pediatrician || others.paediatricianOpinion ||
                                               others.developmentalPaediatrician || others.developmentalPediatricianOpinion ||others.geneticist || others.geneticistOpinion || others.paediatricEndocrinologist ||
                                                others.endocrinologistOpinion ||others.paediatricCardiologist || others.cardiologistOpinion || others.paediatricOrthotist || others.orthoOpinion || others.paediatricOphthalmologist
                                                 || others.ophthalOpinion ||
others.audiologyAndSpeechTherapist ||others.audiologistOpinion ||others.nutritionist|| others.nutritionistOpinion)">
                                    <div class="col-12">

                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="others && others!== null">
                                                <th colspan="3">Assesment Details</th>
                                            </tr>
                                            <tr>
                                                <th>Doctor Specialization</th>
                                                <th>Doctor</th>
                                                <th>Opinion</th>
                                            </tr>

                                            <tr>
                                                <td class="col-4" *ngIf="others.pediatrician ||  others.paediatricianOpinion"> Pediatrician</td>
                                                <td class="col-4" *ngIf="others.pediatrician ||  others.paediatricianOpinion" [textContent]="others.pediatrician || '--'"></td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" *ngIf="others.paediatricianOpinion" [textContent]="others.paediatricianOpinion"></td>
                                            </tr>


                                            <tr>
                                                <td class="col-4" *ngIf="others.developmentalPaediatrician ||others.developmentalPediatricianOpinion ">Developmental Pediatrician </td>
                                                <td class="col-4" *ngIf="others.developmentalPaediatrician ||others.developmentalPediatricianOpinion " [textContent]="others.developmentalPaediatrician || '--'"></td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" *ngIf="others.developmentalPediatricianOpinion" [textContent]="others.developmentalPediatricianOpinion"></td>
                                            </tr>


                                            <tr>
                                                <td class="col-4" *ngIf="others.geneticist ||others.geneticistOpinion">Geneticist</td>
                                                <td class="col-4" *ngIf="others.geneticist ||others.geneticistOpinion" [textContent]="others.geneticist || '--'"></td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" *ngIf="others.geneticistOpinion" [textContent]="others.geneticistOpinion"></td>
                                            </tr>

                                            <tr>
                                                <td class="col-4" *ngIf="others.paediatricCardiologist ||others.cardiologistOpinion"> Pediatric Cardiologist</td>
                                                <td class="col-4" *ngIf="others.paediatricCardiologist ||others.cardiologistOpinion" [textContent]="others.paediatricCardiologist || '--'"></td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" *ngIf="others.cardiologistOpinion" [textContent]="others.cardiologistOpinion"></td>
                                            </tr>

                                            <tr>
                                                <td class="col-4" *ngIf="others.paediatricEndocrinologist ||others.endocrinologistOpinion"> Pediatric Endocrinologist</td>
                                                <td class="col-4" *ngIf="others.paediatricEndocrinologist ||others.endocrinologistOpinion" [textContent]="others.paediatricEndocrinologist || '--'"></td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" *ngIf="others.endocrinologistOpinion" [textContent]="others.endocrinologistOpinion"></td>
                                            </tr>

                                            <tr>
                                                <td class="col-4" *ngIf="others.pediatricOrthopedician||others.orthoOpinion">Pediatric OrthPedician</td>
                                                <td class="col-4" *ngIf="others.pediatricOrthopedician||others.orthoOpinion" [textContent]="others.pediatricOrthopedician || '--'"></td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" *ngIf="others.orthoOpinion" [textContent]="others.orthoOpinion"></td>
                                            </tr>


                                            <tr>
                                                <td class="col-4" *ngIf="others.paediatricOrthotist ||others.orthotistOpinion">Pediatric Orthotist</td>
                                                <td class="col-4" *ngIf="others.paediatricOrthotist ||others.orthotistOpinion" [textContent]="others.paediatricOrthotist || '--'"></td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" *ngIf="others.orthotistOpinion" [textContent]="others.orthotistOpinion"></td>
                                            </tr>


                                            <tr>
                                                <td class="col-4" *ngIf="others.paediatricOphthalmologist ||others.ophthalOpinion">Pediatric Orthometrist</td>
                                                <td class="col-4" *ngIf="others.paediatricOphthalmologist ||others.ophthalOpinion" [textContent]="others.paediatricOphthalmologist || '--'"></td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" *ngIf="others.ophthalOpinion" [textContent]="others.ophthalOpinion"></td>
                                            </tr>

                                            <tr>
                                                <td class="col-4" *ngIf="others.audiologyAndSpeechTherapist || others.audiologistOpinion">Audiology And Speech Therapist</td>
                                                <td class="col-4" *ngIf="others.audiologyAndSpeechTherapist || others.audiologistOpinion" [textContent]="others.audiologyAndSpeechTherapist || '--'"></td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" *ngIf="others.audiologistOpinion" [textContent]="others.audiologistOpinion"></td>
                                            </tr>

                                            <tr>
                                                <td class="col-4" *ngIf="others.nutritionist || others.nutritionistOpinion">Nutritionist</td>
                                                <td class="col-4" *ngIf="others.nutritionist || others.nutritionistOpinion" [textContent]="others.nutritionist || '--'"></td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" *ngIf="others.nutritionistOpinion" [textContent]="others.nutritionistOpinion"></td>
                                            </tr>

                                        </table>

                                    </div>
                                </div>
                                <div class="row" *ngIf="others && others !== null && (others.thyroidProfile || others.date || others.t3||others.t4 ||others.tsh ||others.durationToRepeat|| others.cbc || others.cbcDuration ||others.echoDetails || others.toBeDone || others.karyotyping || others.opthalEvaluationDuration ||others.beraDetails ||others.otherDetails || others.echo ||
                                         others.opthalEvaluation|| others.bera )">
                                    <div class="col-12">

                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="others && others!== null">
                                                <th colspan="2">Investigations</th>
                                            </tr>
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf=" others && others !== null &&  (others.date || others.t3 || others.t4|| others.tsh || others.durationToRepeat)">
                                                <th colspan="2">Thyroid Profile</th>
                                            </tr>


                                            <tr *ngIf="others.date">
                                                <td class="col-8">Date</td>
                                                <td class="col-4" [textContent]="others.date | date: 'dd/MM/yyyy'"></td>
                                            </tr>
                                            <tr *ngIf="others.t3">
                                                <td class="col-8">T3</td>
                                                <td class="col-4" [textContent]="others.t3"></td>
                                            </tr>
                                            <tr *ngIf="others.t4">
                                                <td class="col-8">T4</td>
                                                <td class="col-4" [textContent]="others.t4"></td>
                                            </tr>
                                            <tr *ngIf="others.tsh">
                                                <td class="col-8">TSH</td>
                                                <td class="col-4" [textContent]="others.tsh"></td>
                                            </tr>
                                            <tr *ngIf="others.durationToRepeat">
                                                <td class="col-8">Duration To Repeat</td>
                                                <td class="col-4" [textContent]="others.durationToRepeat"></td>
                                            </tr>
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="others && others !== null &&  (others.cbcDuration)">
                                                <th colspan="2">CBC</th>

                                            </tr>

                                            <tr *ngIf="others.cbcDuration">
                                                <td class="col-8">CBC Duration</td>
                                                <td class="col-4" [textContent]="others.cbcDuration"></td>
                                            </tr>
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="others && others !== null &&  (others.echoDetails)">
                                                <th colspan="2">2D ECHO</th>

                                            </tr>
                                            <tr *ngIf="others.echoDetails">
                                                <td class="col-8">Echo Details</td>
                                                <td class="col-4" style="white-space: normal; word-break: break-all;" [textContent]="others.echoDetails"></td>
                                            </tr>
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="others && others !== null &&  (others.toBeDone)">
                                                <th colspan="2">Hearing Assesment</th>

                                            </tr>

                                            <tr *ngIf="others.toBeDone">
                                                <td class="col-8">To Be Done</td>
                                                <td class="col-4" [textContent]="others.toBeDone"></td>
                                            </tr>

                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="others && others !== null &&  (others.karyotyping)">
                                                <th colspan="2">KARYO TYPING </th>

                                            </tr>
                                            <tr *ngIf="others.karyotyping">
                                                <td class="col-8">Karyotyping</td>
                                                <td class="col-4" [textContent]="others.karyotyping"></td>
                                            </tr>
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="others && others !== null &&  (others.opthalEvaluationDuration)">
                                                <th colspan="2">Opthal Evaluation</th>

                                            </tr>

                                            <tr *ngIf="others.opthalEvaluationDuration">
                                                <td class="col-8">Opthal Evaluation Duration</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="others.opthalEvaluationDuration"></td>
                                            </tr>
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="others && others !== null &&  (others.opthalEvaluationDuration)">
                                                <th colspan="2">BERA</th>
                                            </tr>

                                            <tr *ngIf="others.beraDetails">
                                                <td class="col-8">Bera Details</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="others.beraDetails"></td>
                                            </tr>

                                            <tr *ngIf="others.otherDetails">
                                                <td class="col-8">Other Details</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="others.otherDetails"></td>
                                            </tr>
                                        </table>
                                    </div>


                                </div>

                                <div class="row" *ngIf="others && others !== null && ( others.consultantDoctor ||others.syndromes.length>0)">
                                    <div class="col-12">

                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black;" *ngIf="others.consultantDoctor && others.syndromes.length>0">
                                                <th colspan="2">Syndromes</th>
                                            </tr>
                                            <tr>
                                                <td class="col-8">
                                                    Consultant Doctor
                                                </td>
                                                <td style=" white-space: normal; text-transform: uppercase" [textContent]="others.consultantDoctor"></td>
                                            </tr>
                                            <tr *ngIf="others.syndromes.length >0">
                                                <th colspan="2">Syndromes</th>
                                            </tr>
                                            <tr>
                                                <td class="col-8">
                                                    <span>Syndromes</span>
                                                </td>
                                                <td class="col-4" style="white-space:normal;">
                                                    <span *ngFor="let item of others.syndromes" [textContent]="item+',  '"></span>
                                                </td>
                                            </tr>

                                        </table>
                                    </div>
                                </div>

                                <div class="row" *ngIf="others && others !== null && ( others.others || others.provisionalDiagnosis ||others.testOrdered || others.ifThePatientIsWillingToGiveSample || others.symptoms || others.transactionId || others.typeOfSample ||others.dateOfSampleCollection  )">
                                    <div class="col-12">

                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black;" *ngIf="others.diseases && others.diseases.length >0">
                                                <th colspan="2">Diseases</th>
                                            </tr>
                                            <tr>
                                                <td class="col-8">
                                                    <span>Diseases</span>
                                                </td>
                                                <td class="col-4" style="white-space:normal;">
                                                    <span *ngFor="let item of others.diseases" [textContent]="item+',  '"></span>
                                                </td>
                                            </tr>
                                            <tr *ngIf="others.others">
                                                <td class="col-8">Others</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="others.others"></td>
                                            </tr>
                                            <tr *ngIf="others.provisionalDiagnosis">
                                                <td class="col-8"> Provisional Diagnosis</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="others.provisionalDiagnosis"></td>
                                            </tr>
                                            <tr *ngIf="others.testOrdered">
                                                <td class="col-8">Test Ordered</td>
                                                <td class="col-4" [textContent]="others.testOrdered"></td>
                                            </tr>
                                            <tr *ngIf="others.ifThePatientIsWillingToGiveSample">
                                                <td class="col-8"> If the Patient Is Willing To GiveSample</td>
                                                <td class="col-4" [textContent]="others.ifThePatientIsWillingToGiveSample"></td>
                                            </tr>

                                            <tr *ngIf="others.symptoms">
                                                <td class="col-8"> Date Of Onset Of the Symptoms</td>
                                                <td class="col-4" [textContent]="others.symptoms|  date: 'dd/MM/yyyy'"></td>
                                            </tr>
                                            <tr *ngIf="others.transactionId">
                                                <td class="col-8"> Transaction Id</td>
                                                <td class="col-4" [textContent]="others.transactionId"></td>
                                            </tr>
                                            <tr *ngIf="others.typeOfSample">
                                                <td class="col-8"> Type Of Sample</td>
                                                <td class="col-4" [textContent]="others.typeOfSample"></td>
                                            </tr>
                                            <tr *ngIf="others.dateOfSampleCollection">
                                                <td class="col-8"> Date Of Sample Collection</td>
                                                <td class="col-4" [textContent]="others.dateOfSampleCollection | date: 'dd/MM/yyyy'"></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="dashboard-box dashboard-table-box" *ngIf="highRiskIntial && highRiskIntial!== null">
                        <h4 class="dashboard-title">Pediatric High Risk Initial Assessment</h4>
                        <div class="dashboard-body">
                            <div class="overflow-auto">
                                <div class="row" *ngIf=" highRiskIntial && highRiskIntial!== null && ( highRiskIntial.birthWeight || highRiskIntial.fatherName || highRiskIntial.sex ||highRiskIntial.dateOfAdmission ||highRiskIntial.dateOfDischarge ||highRiskIntial.consultantName )">
                                    <div class="col-12">

                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="highRiskIntial && highRiskIntial!== null">
                                                <th colspan="2">Basic Details</th>
                                            </tr>

                                            <tr *ngIf="highRiskIntial.fatherName">
                                                <td class="col-8">Father Name</td>
                                                <td class="col-4" [textContent]="highRiskIntial.fatherName"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.sex">
                                                <td class="col-8">Sex</td>
                                                <td class="col-4" [textContent]="highRiskIntial.sex|gender"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.dateOfAdmission">
                                                <td class="col-8">Date Of Admission</td>
                                                <td class="col-4" [textContent]="highRiskIntial.dateOfAdmission| date: 'dd/MM/yyyy'"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.dateOfDischarge">
                                                <td class="col-8">Date Of Discharge</td>
                                                <td class="col-4" [textContent]="highRiskIntial.dateOfDischarge| date: 'dd/MM/yyyy'"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.consultantName">
                                                <td class="col-8">Consultant Doctor</td>
                                                <td class="col-4" style="text-transform: uppercase" [textContent]="highRiskIntial.consultantName"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.birthWeight">
                                                <td class="col-8">Birth Weight</td>
                                                <td class="col-4" [textContent]="highRiskIntial.birthWeight"></td>
                                            </tr>

                                        </table>
                                    </div>
                                </div>
                                <div class="row" *ngIf="highRiskIntial && highRiskIntial !== null && ( highRiskIntial.visitDate || highRiskIntial.weightInkgs||highRiskIntial.length1 || highRiskIntial.ofc )">
                                    <div class="col-12">

                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="highRiskIntial && highRiskIntial!== null">
                                                <th colspan="2">Baby Growth</th>
                                            </tr>

                                            <tr *ngIf="highRiskIntial.visitDate">
                                                <td class="col-8">Visit Date</td>
                                                <td class="col-4" [textContent]="highRiskIntial.visitDate| date: 'dd/MM/yyyy'"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.weightInkgs">
                                                <td class="col-8">Weight In Kgs</td>
                                                <td class="col-4" [textContent]="highRiskIntial.weightInkgs"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.length1">
                                                <td class="col-8">Length (In Cms)</td>
                                                <td class="col-4" [textContent]="highRiskIntial.length1"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.ofc">
                                                <td class="col-8">OFC(In Cms)</td>
                                                <td class="col-4" [textContent]="highRiskIntial.ofc"></td>
                                            </tr>

                                        </table>
                                    </div>
                                </div>
                                <div class="row" *ngIf="highRiskIntial && highRiskIntial !== null && (highRiskIntial.breastFeeds|| highRiskIntial.formulaFeeds||highRiskIntial.animalFeeds )">
                                    <div class="col-12">

                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="highRiskIntial && highRiskIntial!== null">
                                                <th colspan="2">Nutrition</th>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.breastFeeds">
                                                <td class="col-8">Breast Feeds</td>
                                                <td class="col-4" [textContent]="highRiskIntial.breastFeeds"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.formulaFeeds">
                                                <td class="col-8">Formula Feeds</td>
                                                <td class="col-4" [textContent]="highRiskIntial.formulaFeeds"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.animalFeeds">
                                                <td class="col-8">Animal Feeds</td>
                                                <td class="col-4" [textContent]="highRiskIntial.animalFeeds"></td>
                                            </tr>


                                        </table>
                                    </div>
                                </div>
                                <div class="row" *ngIf="highRiskIntial && highRiskIntial !== null && (highRiskIntial.startAge||highRiskIntial.type)">
                                    <div class="col-12">

                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="highRiskIntial && highRiskIntial!== null">
                                                <th colspan="2">Complementary Feeding</th>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.startAge">
                                                <td class="col-8">Start Age</td>
                                                <td class="col-4" [textContent]="highRiskIntial.startAge"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.type">
                                                <td class="col-8">Type</td>
                                                <td class="col-4" [textContent]="highRiskIntial.type"></td>
                                            </tr>


                                        </table>
                                    </div>
                                </div>
                                <div class="row" *ngIf="highRiskIntial && highRiskIntial !== null && (highRiskIntial.facialScore || highRiskIntial.facialComment ||highRiskIntial.eyeScore ||
                           highRiskIntial.eyeComment || highRiskIntial.visualScore|| highRiskIntial.visualComment||highRiskIntial.auditScore || highRiskIntial.auditComment
                              ||highRiskIntial.swalloingScore ||highRiskIntial.swalloingComment || highRiskIntial.totalScoreSwalloing || highRiskIntial.swalloingComment1)">

                                    <div class="col-12">

                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="highRiskIntial && highRiskIntial!== null">
                                                <th colspan="2">HammerSmith Infant Neurological Examination</th>
                                            </tr>
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="highRiskIntial && highRiskIntial!== null">
                                                <th colspan="2">Assessment Of Carnial Nerve Function</th>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.facialScore">
                                                <td class="col-8">Facial Appearance Score</td>
                                                <td class="col-4" [textContent]="highRiskIntial.facialScore"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.facialComment">
                                                <td class="col-8">Facial Apperance Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.facialComment"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.eyeScore">
                                                <td class="col-8">Eye Movements Score</td>
                                                <td class="col-4" [textContent]="highRiskIntial.eyeScore"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.eyeComment">
                                                <td class="col-8">Eye Movements Comment </td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.eyeComment"></td>
                                            </tr>

                                            <tr *ngIf="highRiskIntial.visualScore">
                                                <td class="col-8">Visual Response Score </td>
                                                <td class="col-4" [textContent]="highRiskIntial.visualScore"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.visualComment">
                                                <td class="col-8">Visual Response Comment </td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.visualComment"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.auditScore">
                                                <td class="col-8">Auditory Response Score </td>
                                                <td class="col-4" [textContent]="highRiskIntial.auditScore"></td>
                                            </tr>

                                            <tr *ngIf="highRiskIntial.auditComment">
                                                <td class="col-8">Auditory Response Comment </td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.auditComment"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.swalloingScore">
                                                <td class="col-8">Sucking/swallowing Score </td>
                                                <td class="col-4" [textContent]="highRiskIntial.swalloingScore"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.swalloingComment">
                                                <td class="col-8">Sucking/Swalloing Comment </td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.swalloingComment"></td>
                                            </tr>
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="highRiskIntial.totalScoreSwalloing">
                                                <td class="col-8">Total Score For Assessment  Of Cranial Nerve Function  </td>
                                                <td class="col-4" [textContent]="highRiskIntial.totalScoreSwalloing"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.swalloingComment1">
                                                <td class="col-8">Comments For Assesment  Of Cranial Nerve Function </td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.swalloingComment1"></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div class="row" *ngIf="highRiskIntial && highRiskIntial !== null && (highRiskIntial.headScore || highRiskIntial.headComment ||highRiskIntial.sittingScore ||
                           highRiskIntial.sittingComment || highRiskIntial.armScore || highRiskIntial.armComment || highRiskIntial.auditScore || highRiskIntial.handsScore
                              ||highRiskIntial.handsComment ||highRiskIntial.legScore || highRiskIntial.feetScore || highRiskIntial.feetComment || highRiskIntial.totalScorePosture || highRiskIntial.postureComment)">

                                    <div class="col-12">


                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="highRiskIntial && highRiskIntial!== null">
                                                <th colspan="2">Assessment Of Posture</th>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.headScore">
                                                <td class="col-8">Head(InSitting) Score</td>
                                                <td class="col-4" [textContent]="highRiskIntial.headScore"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.headComment">
                                                <td class="col-8">Head(InSitting) Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.headComment"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.sittingScore">
                                                <td class="col-8">Trunk(InSitting) Score</td>
                                                <td class="col-4" [textContent]="highRiskIntial.sittingScore"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.sittingComment">
                                                <td class="col-8">Trunk(InSitting)  Comment </td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.sittingComment"></td>
                                            </tr>

                                            <tr *ngIf="highRiskIntial.armScore">
                                                <td class="col-8">Arms(At Rest) Score </td>
                                                <td class="col-4" [textContent]="highRiskIntial.armScore"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.armComment">
                                                <td class="col-8">Arms(At Rest) Comment </td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.armComment"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.handsScore">
                                                <td class="col-8">Hands Score </td>
                                                <td class="col-4" [textContent]="highRiskIntial.handsScore"></td>
                                            </tr>

                                            <tr *ngIf="highRiskIntial.handsComment">
                                                <td class="col-8">Hands Comment </td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.handsComment"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.legScore">
                                                <td class="col-8">Legs Score </td>
                                                <td class="col-4" [textContent]="highRiskIntial.legScore"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.legComment">
                                                <td class="col-8">Legs Comment </td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.legComment"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.feetScore">
                                                <td class="col-8">Feet Score </td>
                                                <td class="col-4" [textContent]="highRiskIntial.feetScore"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.feetComment">
                                                <td class="col-8">Feet Comment </td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.feetComment"></td>
                                            </tr>
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="highRiskIntial.totalScorePosture">
                                                <td class="col-8">Total Score Of Posture  </td>
                                                <td class="col-4" [textContent]="highRiskIntial.totalScorePosture"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.postureComment">
                                                <td class="col-8">Comments For Posture </td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.postureComment"></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div class="row" *ngIf="highRiskIntial && highRiskIntial !== null && (highRiskIntial.quantityScore || highRiskIntial.quantityComment || highRiskIntial.qualityScore ||
                           highRiskIntial.qualityComment || highRiskIntial.totalScore || highRiskIntial.movementComment )">

                                    <div class="col-12">


                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="highRiskIntial && highRiskIntial!== null">
                                                <th colspan="2">Assessment Of Movements</th>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.quantityScore">
                                                <td class="col-8">Quantity Score</td>
                                                <td class="col-4" [textContent]="highRiskIntial.quantityScore"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.quantityComment">
                                                <td class="col-8">Quantity Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.quantityComment"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.qualityScore">
                                                <td class="col-8">Quality Score</td>
                                                <td class="col-4" [textContent]="highRiskIntial.qualityScore"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.qualityComment">
                                                <td class="col-8">Quality Comment </td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.qualityComment"></td>
                                            </tr>

                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="highRiskIntial.totalScore">
                                                <td class="col-8">Total Score For Movements </td>
                                                <td class="col-4" [textContent]="highRiskIntial.totalScore"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.movementComment">
                                                <td class="col-8">Comments For Assesments OF Movements</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.movementComment"></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>

                                <div class="row" *ngIf="highRiskIntial && highRiskIntial !== null && (highRiskIntial.toneScore1 || highRiskIntial.toneComment1 || highRiskIntial.toneScore2 ||
                           highRiskIntial.toneComment2 ||highRiskIntial.toneScore3 || highRiskIntial.toneComment3 || highRiskIntial.toneScore4 || highRiskIntial.toneComment4 ||highRiskIntial.toneScore5 || highRiskIntial.toneComment5 ||highRiskIntial.toneScore6 || highRiskIntial.toneComment6
                                  || highRiskIntial.toneScore7 || highRiskIntial.toneComment7 || highRiskIntial.toneScore8 || highRiskIntial.toneComment8 || highRiskIntial.totalToneScore || highRiskIntial.toneComment)">

                                    <div class="col-12">

                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="highRiskIntial && highRiskIntial!== null">
                                                <th colspan="2">Assessment Of Tone</th>
                                            </tr>

                                            <tr *ngIf="highRiskIntial.toneScore1">
                                                <td class="col-8">Scarf Sign Score</td>
                                                <td class="col-4" [textContent]="highRiskIntial.toneScore1"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.toneComment1">
                                                <td class="col-8">Scarf Sign Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.toneComment1"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.toneScore2">
                                                <td class="col-8"> Passive Shoulder Score</td>
                                                <td class="col-4" [textContent]="highRiskIntial.toneScore2"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.toneComment2">
                                                <td class="col-8">Passive Shoulder Comment </td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.toneComment2"></td>
                                            </tr>

                                            <tr *ngIf="highRiskIntial.toneScore3">
                                                <td class="col-8">Pronation/Supination Score </td>
                                                <td class="col-4" [textContent]="highRiskIntial.toneScore3"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.toneComment3">
                                                <td class="col-8">Pronation/Supination Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.toneComment3"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.toneScore4">
                                                <td class="col-8">Hip Adductors Score </td>
                                                <td class="col-4" [textContent]="highRiskIntial.toneScore4"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.toneComment4">
                                                <td class="col-8">Hip Adductors Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.toneComment4"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.toneScore5">
                                                <td class="col-8">Popliteal Angle Score </td>
                                                <td class="col-4" [textContent]="highRiskIntial.toneScore5"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.toneComment5">
                                                <td class="col-8">Popliteal Angle Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.toneComment5"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.toneScore6">
                                                <td class="col-8">Ankle Dorsiflexion Score </td>
                                                <td class="col-4" [textContent]="highRiskIntial.toneScore6"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.toneComment6">
                                                <td class="col-8">Ankle Dorsiflexion Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.toneComment6"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.toneScore7">
                                                <td class="col-8">Pull To Sit Score </td>
                                                <td class="col-4" [textContent]="highRiskIntial.toneScore7"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.toneComment7">
                                                <td class="col-8"> Pull To Sit Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.toneComment7"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.toneScore8">
                                                <td class="col-8"> Ventral Suspension Score </td>
                                                <td class="col-4" [textContent]="highRiskIntial.toneScore8"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.toneComment8">
                                                <td class="col-8"> Ventral Suspension Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.toneComment8"></td>
                                            </tr>
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="highRiskIntial.totalToneScore">
                                                <td class="col-8"> Total Score for Tone </td>
                                                <td class="col-4" [textContent]="highRiskIntial.totalToneScore"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.toneComment">
                                                <td class="col-8"> Comments For Assessment Of Tone</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.toneComment"></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>

                                <div class="row" *ngIf="highRiskIntial && highRiskIntial !== null && (highRiskIntial.reflexScore1 || highRiskIntial.reflexComment1 || highRiskIntial.reflexScore2||
                           highRiskIntial.reflexComment2 || highRiskIntial.reflexScore3|| highRiskIntial.reflexComment3 ||highRiskIntial.reflexScore4 || highRiskIntial.reflexComment4 || highRiskIntial.reflexScore5
                          || highRiskIntial.reflexComment5 || highRiskIntial.totalReflexScore || highRiskIntial.reflexComment )">

                                    <div class="col-12">


                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="highRiskIntial && highRiskIntial!== null">
                                                <th colspan="2">REFLEXES AND REACTIONS</th>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.reflexScore1">
                                                <td class="col-8">Arm Protection Score</td>
                                                <td class="col-4" [textContent]="highRiskIntial.reflexScore1"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.reflexComment1">
                                                <td class="col-8">Arm Protection Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.reflexComment1"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.reflexScore2">
                                                <td class="col-8"> Vertical Suspension Score</td>
                                                <td class="col-4" [textContent]="highRiskIntial.reflexScore2"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.reflexComment2">
                                                <td class="col-8">Vertical Suspension  Comment </td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.reflexComment2"></td>
                                            </tr>

                                            <tr *ngIf="highRiskIntial.reflexScore3">
                                                <td class="col-8">Lateral Tilting Score </td>
                                                <td class="col-4" [textContent]="highRiskIntial.reflexScore3"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.reflexComment3">
                                                <td class="col-8">Lateral Tilting  Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.reflexComment3"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.reflexScore4">
                                                <td class="col-8"> Forward Parachute Score </td>
                                                <td class="col-4" [textContent]="highRiskIntial.reflexScore4"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.reflexComment4">
                                                <td class="col-8">Forward Parachute Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.reflexComment4"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.reflexScore5">
                                                <td class="col-8">Tendon Reflexes Score </td>
                                                <td class="col-4" [textContent]="highRiskIntial.reflexScore5"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.reflexComment5">
                                                <td class="col-8">Tendon Reflexes Comment</td>
                                                <td class="col-4" style="white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.reflexComment5"></td>
                                            </tr>
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="highRiskIntial.totalReflexScore">
                                                <td class="col-8">Total Reflexes & Reactions Score </td>
                                                <td class="col-4" [textContent]="highRiskIntial.totalReflexScore"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.reflexComment">
                                                <td class="col-8">Comments For Reflexes & Reactions</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.reflexComment"></td>
                                            </tr>

                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <table *ngIf="highRiskIntial && highRiskIntial.crawling  || highRiskIntial.crawlingComment">
                                        <tr>
                                            <th colspan="2">Crawling - Note If Bottom Shuffling</th>
                                        </tr>
                                        <tr *ngIf="highRiskIntial.crawling">
                                            <td>Crawling - Note If Bottom Shuffling</td>
                                            <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.crawling"></td>
                                        </tr>
                                        <tr *ngIf="highRiskIntial.crawlingComment">
                                            <td>Comments For Crawling - Note If Bottom Shuffling</td>
                                            <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.crawlingComment"></td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="row" *ngIf="highRiskIntial && highRiskIntial !== null && (highRiskIntial.headControlComment || highRiskIntial.headControl1 || highRiskIntial.sitting1 || highRiskIntial.sittingComment1 || highRiskIntial.grasp || highRiskIntial.graspComment || highRiskIntial.kick || highRiskIntial.kickComment
                  || highRiskIntial.rolling || highRiskIntial.rollingComment || highRiskIntial.crawling || highRiskIntial.crawlingComment || highRiskIntial.standing || highRiskIntial.standingComment || highRiskIntial.walking || highRiskIntial.walkingComment || highRiskIntial.motorComment  )">

                                    <div class="col-12">
                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="highRiskIntial && highRiskIntial!== null">
                                                <th colspan="2">MOTOR MILESTONES</th>
                                            </tr>
                                            <tr>
                                                <th colspan="2" *ngIf="highRiskIntial.headControl1 || highRiskIntial.headControlComment">Head Control</th>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.headControl1">
                                                <td class="col-8" style="padding-left:12px;">  Head Control Selected Option</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.headControl1"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.headControlComment">
                                                <td class="col-8" style="padding-left:12px;">  Head Control Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.headControlComment"></td>
                                            </tr>
                                            <tr>
                                                <th colspan="2" *ngIf="highRiskIntial.sitting1 ||highRiskIntial.sittingComment1">
                                                    Sitting
                                                </th>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.sitting1">
                                                <td class="col-8" style="padding-left:12px;">  Sitting Selected Option</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.sitting1"></td>
                                            </tr>

                                            <tr *ngIf="highRiskIntial.sittingComment1">
                                                <td class="col-8" style="padding-left:12px;">  Sitting Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all; " [textContent]="highRiskIntial.sittingComment1"></td>
                                            </tr>
                                            <tr>
                                                <th colspan="2" style="padding-left:12px;" *ngIf="highRiskIntial.grasp ||highRiskIntial.graspComment">
                                                    Voluntary Grasp
                                                </th>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.grasp">
                                                <td class="col-8" style="padding-left:12px;">  Voluntary Grasp  Selected Option</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.grasp"></td>
                                            </tr>

                                            <tr *ngIf="highRiskIntial.graspComment">
                                                <td class="col-8" style="padding-left:12px;">  Voluntary Grasp  Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.graspComment"></td>
                                            </tr>
                                            <tr>
                                                <th colspan="2" style="padding-left:12px;" *ngIf="highRiskIntial.kick || highRiskIntial.kickComment">
                                                    Ability To Kick In Supine
                                                </th>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.grasp">
                                                <td class="col-8" style="padding-left:12px;">  Ability To Kick In Supine Selected Option</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.kick"></td>
                                            </tr>

                                            <tr *ngIf="highRiskIntial.graspComment">
                                                <td class="col-8" style="padding-left:12px;">  Ability To Kick In Supine  Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.kickComment"></td>
                                            </tr>
                                            <tr>
                                                <th colspan="2" style="padding-left:12px;" *ngIf="highRiskIntial. rolling|| highRiskIntial. rollingComment">
                                                    Rolling - Note Through Which Side(S)
                                                </th>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.rolling">
                                                <td class="col-8" style="padding-left:12px;">  Rolling - Note Through Which Side(S) Selected Option</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.rolling"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.rollingComment">
                                                <td class="col-8" style="padding-left:12px;">  Rolling - Note Through Which Side(S)  Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.rollingComment"></td>
                                            </tr>



                                            <tr>
                                                <th colspan="2" style="padding-left:12px;" *ngIf="highRiskIntial.standing|| highRiskIntial.standingComment">
                                                    Standing
                                                </th>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.standing">
                                                <td class="col-8" style="padding-left:12px;"> Standing Selected Option</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.standing"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.standingComment">
                                                <td class="col-8" style="padding-left:12px;"> Standing Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.standingComment"></td>
                                            </tr>
                                            <tr>
                                                <th colspan="2" style="padding-left:12px;" *ngIf="highRiskIntial.walking|| highRiskIntial.walkingComment">
                                                    Walking
                                                </th>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.walking">
                                                <td class="col-8" style="padding-left:12px;"> Walking Selected Option</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.walking"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.standingComment">
                                                <td class="col-8" style="padding-left:12px;"> Walking Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.walkingComment"></td>
                                            </tr>

                                            <tr *ngIf="highRiskIntial.motorComment">
                                                <td class="col-8" style="padding-left:1px;"> COMMENTS FOR MOTOR MILESTONES</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.motorComment"></td>
                                            </tr>

                                        </table>
                                    </div>
                                </div>
                                <div class="row" *ngIf="highRiskIntial && highRiskIntial !== null && (highRiskIntial.conciousComment || highRiskIntial.emotionalComment || highRiskIntial.socialOrientations ||
                           highRiskIntial.behaviourComments )">

                                    <div class="col-12">
                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="highRiskIntial && highRiskIntial!== null">
                                                <th colspan="2">Behaviour</th>
                                            </tr>
                                            <tr>
                                                <th colspan="2" *ngIf="highRiskIntial.concious ||highRiskIntial.conciousComment">
                                                    Concious State
                                                </th>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.concious">
                                                <td class="col-8">Conscious Selected Option</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.concious"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.conciousComment">
                                                <td class="col-8">Conscious State Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.conciousComment"></td>
                                            </tr>
                                            <tr>
                                                <th colspan="2" *ngIf="highRiskIntial.emotional ||highRiskIntial.emotionalComment">
                                                    Emotional State
                                                </th>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.emotional">
                                                <td class="col-8">Emotional State Selected Option</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.emotional"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.emotionalComment">
                                                <td class="col-8">Emotional State Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.emotionalComment"></td>
                                            </tr>
                                            <tr>
                                                <th colspan="2" *ngIf="highRiskIntial.socialOrientation ||highRiskIntial.socialOrientations">
                                                    Social Orientation
                                                </th>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.socialOrientation">
                                                <td class="col-8"> Social Orientation Selected Option</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.socialOrientation"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.socialOrientations">
                                                <td class="col-8"> Social Orientation Comment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.socialOrientations"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.behaviourComments">
                                                <td class="col-8">  Comments For Behaviour </td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.behaviourComments"></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div class="row" *ngIf="highRiskIntial && highRiskIntial !== null && (highRiskIntial.grossMotor|| highRiskIntial.fineMotor || highRiskIntial.language ||
                         highRiskIntial.personalSocial )">

                                    <div class="col-12">

                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="highRiskIntial && highRiskIntial!== null">
                                                <th colspan="2">Development Assessment</th>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.grossMotor">
                                                <td class="col-8">Gross Motor</td>
                                                <td class="col-4" [textContent]="highRiskIntial.grossMotor"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.fineMotor">
                                                <td class="col-8">Fine Motor</td>
                                                <td class="col-4" [textContent]="highRiskIntial.fineMotor"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.language">
                                                <td class="col-8"> Language</td>
                                                <td class="col-4" [textContent]="highRiskIntial.language"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.personalSocial != 'Null'">
                                                <td class="col-8"> Personal Social </td>
                                                <td class="col-4" [textContent]="highRiskIntial.personalSocial"></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>

                                <div class="row" *ngIf="highRiskIntial && highRiskIntial !== null && (highRiskIntial.hearing|| highRiskIntial.vision || highRiskIntial.squint )">

                                    <div class="col-12">


                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="highRiskIntial && highRiskIntial!== null">
                                                <th colspan="2">Hearing and Vision</th>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.hearing">
                                                <td class="col-8">Hearing</td>
                                                <td class="col-4" [textContent]="highRiskIntial.hearing"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.vision">
                                                <td class="col-8">Vision</td>
                                                <td class="col-4" [textContent]="highRiskIntial.vision"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.squint">
                                                <td class="col-8"> Squint</td>
                                                <td class="col-4" [textContent]="highRiskIntial.squint"></td>
                                            </tr>

                                        </table>
                                    </div>
                                </div>
                                <div class="row" *ngIf="highRiskIntial && highRiskIntial !== null && (highRiskIntial.score|| highRiskIntial.submittedBy || highRiskIntial.action || highRiskIntial.comments || highRiskIntial.advice )">

                                    <div class="col-12">


                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="highRiskIntial && highRiskIntial!== null">
                                                <th colspan="2">M-Chart Score</th>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.score">
                                                <td class="col-8">Score</td>
                                                <td class="col-4" [textContent]="highRiskIntial.score"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.submittedBy">
                                                <td class="col-8">Submitted By</td>
                                                <td class="col-4" [textContent]="highRiskIntial.submittedBy"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.action">
                                                <td class="col-8">Action</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.action"></td>
                                            </tr>

                                            <tr *ngIf="highRiskIntial.comments">
                                                <td class="col-8">Comments/Remarks</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.comments"></td>
                                            </tr>
                                            <tr *ngIf="highRiskIntial.advice">
                                                <td class="col-8"> Advice / Treatment</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="highRiskIntial.advice"></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="roleId != 63">
                        <div class="dashboard-box dashboard-table-box" *ngIf="orderPrescription && orderPrescription !== null">
                            <h4 class="dashboard-title">Order/Prescription</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <table class="table table-centered table-sm table-striped mb-0">
                                        <tr *ngIf="bookingHeader && bookingHeader.length > 0">
                                            <td>Investigations</td>
                                            <td>
                                                <table class="table table-centered table-nowrap table-sm table-striped transcipt mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Test Name</th>
                                                            <th>Test Code</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                        <tr *ngFor="let item of bookingHeader;let i =index;">
                                                            <td>
                                                                <span class=" text-wrap" [textContent]="item.testName"></span><br />
                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap" [textContent]="item.testCode"></span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr *ngIf="records && records.length !== 0">
                                            <td>
                                                Prescription
                                            </td>
                                            <td *ngFor="let record of records;">
                                                <table class="table table-centered table-nowrap table-sm table-striped transcipt mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Medicine Name</th>
                                                            <th>Generic Name</th>
                                                            <th>Dosage</th>
                                                            <th>Category</th>
                                                            <th>Qty</th>
                                                            <th>Remarks</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                        <tr *ngFor="let item of record.medicines; let i = index">
                                                            <td>
                                                                <span class=" text-wrap" [textContent]="item.productName"></span><br />
                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap" *ngIf="item.genericName" [textContent]="item.genericName"></span>

                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap">
                                                                    <span *ngIf="item.instruction" [textContent]="item.instruction"></span>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap">
                                                                    <span *ngIf="item.instruction" [textContent]="item.categoryName"></span>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap">
                                                                    <span *ngIf="item.instruction" [textContent]="item.dosage"></span>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap">
                                                                    <span *ngIf="item.instruction" [textContent]="item.remark"></span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let item of orderPrescription.orderData">
                                            <td *ngIf="item.comments && item.comments !== null " [textContent]="item.orderName"></td>
                                            <td *ngIf="item.comments && item.comments !== null " [innerHTML]="item.comments"></td>
                                        </tr>

                                        <tr *ngIf="refferalOrder && refferalOrder.length !== 0">
                                            <td>
                                                Referrals
                                            </td>


                                            <td>
                                                <table border="1px solid black" class="w-100" style="border-collapse: collapse;">
                                                    <thead>
                                                        <tr>
                                                            <th class="col-6"><h5>Doctors</h5></th>
                                                            <th class="col-6"><h5>Comments</h5></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr *ngFor="let item of refferalOrder; let i = index">
                                                            <td>


                                                                <h5 class="align-content-lg-center" [textContent]="item.name"></h5><br />
                                                            </td>
                                                            <td>
                                                                <h5 class="align-content-lg-center" [textContent]="item.comments"></h5>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>

                                        <tr *ngIf="diagnosisRecords && diagnosisRecords.length !== 0">
                                            <td>
                                                Diagnosis
                                            </td>
                                            <td>
                                                <table border="1px solid black" class="w-100" style="border-collapse: collapse;">
                                                    <thead>
                                                        <tr class="font-weight-bolder">
                                                            <th class="col-6" style="white-space: normal; word-break: break-all;"><h5>Name</h5></th>
                                                            <th class="col-6"><h5>ICD Code</h5></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr *ngFor="let item of diagnosisRecords; let i = index">
                                                            <td>
                                                                <h5 class="align-content-lg-center" [textContent]="item.usable"></h5><br />
                                                            </td>
                                                            <td>
                                                                <h5 class="align-content-lg-center" [textContent]="item.code"></h5>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr *ngIf="visitOrders && visitOrders.length !== 0">
                                            <td>
                                                Follow Up
                                            </td>
                                            <td>
                                                <table border="1px solid black" class="w-100" style="border-collapse: collapse;">
                                                    <thead>
                                                        <tr>
                                                            <th class="col-6"><h5>Visit Date</h5></th>
                                                            <th class="col-6"><h5>Special Advice</h5></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr *ngFor="let item of visitOrders; let i = index">
                                                            <td>
                                                                <h5 class="align-content-lg-center" [textContent]="item.visitDate| date:'dd/MM/yyyy'"></h5><br />
                                                            </td>
                                                            <td>
                                                                <h5 class="align-content-lg-center" [textContent]="item.specialAdvice"></h5>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard-box dashboard-table-box" *ngIf="familyHistory">
                        <h4 class="dashboard-title">Family History</h4>
                        <div class="dashboard-body">
                            <div class="overflow-auto">
                                <div *ngIf="isFamilyHistory">
                                    There is no Family History
                                </div>
                                <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="familyHistory &&  familyHistory.length >0">
                                    <thead>
                                        <tr>
                                            <th>Relation</th>
                                            <th>Age/DOB</th>
                                            <th>Problem</th>
                                            <th>Status</th>
                                            <th>Comments</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of familyHistory;">
                                            <td>
                                                <div class="media">
                                                    <div class="media-body">
                                                        <h5 class="mb-0 mt-0 font-weight-normal text-truncate-line" [textContent]=" item.relation"></h5>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <p *ngIf="item.age" [textContent]="item.age  "></p>
                                                <p style="padding-left: 25px" *ngIf="!item.age" [textContent]="'-'"></p>
                                            </td>
                                            <td>
                                                <p *ngIf="item.problem" [textContent]="item.problem "></p>
                                                <p style="padding-left: 25px" *ngIf="!item.problem" [textContent]="'-'"></p>
                                            </td>
                                            <td>
                                                <p *ngIf="item.status" [textContent]="item.status "></p>
                                                <p style="padding-left: 25px" *ngIf="!item.status" [textContent]="'-'"></p>
                                            </td>
                                            <td>
                                                <p *ngIf="item.comments" [textContent]="item.comments "></p>
                                                <p style="padding-left: 25px" *ngIf="!item.comments" [textContent]="'-'"></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="familyHistory && familyHistory.length>0 && familyHistory[0].mainComment">
                                    <strong>Comments</strong>
                                    <div><span [textContent]="familyHistory[0].mainComment "></span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="roleId != 63">
                        <div class="dashboard-box dashboard-table-box" *ngIf="birthHistory && birthHistory.length">
                            <h4 class="dashboard-title">Birth History</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th>Pregnancy No</th>
                                                <th>Year Of Birth</th>
                                                <th>PregnancyStatus </th>
                                                <th>Mode Of Delivery</th>
                                                <th>Indication</th>
                                                <th>Weight</th>
                                                <th>Sex Of Baby</th>
                                                <th>StatusOfBaby</th>
                                                <th>Delivery At</th>
                                                <th>Other</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of birthHistory;">
                                                <td [textContent]="item.pregnancyNo"></td>
                                                <td [textContent]="item.yearOfBirth"></td>
                                                <td [textContent]="item.pregnancyStatus"></td>
                                                <td [textContent]="item.modeOfDelivery"></td>
                                                <td [textContent]="item.indication"></td>
                                                <td [textContent]="item.birthWeight"></td>
                                                <td [textContent]="item.sexOfBaby"></td>
                                                <td [textContent]="item.statusOfBaby"></td>
                                                <td [textContent]="item.deliveryAt"></td>
                                                <td style="white-space: normal; word-break: break-all;" [textContent]="item.other">
                                                </td>


                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard-box dashboard-table-box" *ngIf="specialFeature && specialFeature !== null && specialFeature.specialFeature">
                        <h4 class="dashboard-title">Special Features</h4>
                        <div class="dashboard-body">
                            <div class="overflow-auto">
                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                    <tr *ngIf="specialFeature.specialFeature">
                                        <td class="col-8">Special Features</td>
                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="specialFeature.specialFeature"> </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard-box dashboard-table-box" *ngIf="allergies && allergies !== null">
                        <h4 class="dashboard-title">Allergies</h4>
                        <div class="dashboard-body">
                            <div class="overflow-auto">
                                <div *ngIf="allergies && allergies !== null">
                                    <div>
                                        <div *ngIf="allergies && allergies.noHistory && allergies.noHistory!=null ">
                                            There is no allergy
                                        </div>

                                        <div class="w-100 dashboard-break" *ngIf="!allergies.noHistory && (allergies.drugNames!=null || allergies.drugNames!=null || allergies.drugReactions!=null
                                 || allergies.dustNames!=null || allergies.dustReactions!=null || allergies.foodNames!=null || allergies.foodReactions!=null || allergies.otherNames!=null || allergies.otherReactions!=null)">
                                            <div class="table-responsive horizontal-scroll">
                                                <table class="table table-sm mb-0">
                                                    <thead>

                                                        <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="allergies && allergies !== null">
                                                            <th width="30%">Allergy Type</th>
                                                            <th width="30%">Allergy</th>
                                                            <th width="40%">Reaction </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngIf="allergies.drugNames">
                                                            <td class="col-4">Drug Allergy</td>
                                                            <td class="col-4" [textContent]="allergies.drugNames"></td>
                                                            <td class="col-4" [textContent]="allergies.drugReactions"></td>
                                                        </tr>
                                                        <tr *ngIf="allergies.foodNames">
                                                            <td class="col-4">Food Allergy</td>
                                                            <td class="col-4" [textContent]="allergies.foodNames"></td>
                                                            <td class="col-4" [textContent]="allergies.foodReactions"></td>
                                                        </tr>
                                                        <tr *ngIf="allergies.dustNames">
                                                            <td class="col-4">Dust Allergy</td>
                                                            <td class="col-4" [textContent]="allergies.dustNames"></td>
                                                            <td class="col-4" [textContent]="allergies.dustReactions"></td>
                                                        </tr>
                                                        <tr *ngIf="allergies.otherNames">
                                                            <td class="col-4">Others</td>
                                                            <td class="col-4" [textContent]="allergies.otherNames"></td>
                                                            <td class="col-4" [textContent]="allergies.otherReactions"></td>
                                                        </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard-box dashboard-table-box" *ngIf="mChat">
                        <h4 class="dashboard-title">M-Chat</h4>
                        <div class="dashboard-body">
                            <div class="overflow-auto">
                                <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="mChat && mChat !=null">
                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black">
                                        <th colspan="2">Section A:Ask Parent</th>
                                    </tr>

                                    <tr *ngIf="mChat.toy!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            If you point at something across the room, does your child look at it?(FOR EXAMPLE, if you point at a toy or an animal, does your child look at the toy or animal?)
                                        </td>
                                        <td class="col-4">
                                            <span *ngIf="mChat.toy=='None'">None</span>
                                            <span *ngIf="mChat.toy=='1'">Yes</span>
                                            <span *ngIf="mChat.toy=='0'">No</span>


                                        </td>
                                    </tr>
                                    <tr *ngIf="mChat.deaf!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            Have you ever wondered if your child might be deaf?
                                        </td>
                                        <td class="col-4">
                                            <span *ngIf="mChat.deaf=='None'">None</span>
                                            <span *ngIf="mChat.deaf=='1'">No</span>
                                            <span *ngIf="mChat.deaf=='0'">Yes</span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="mChat.child!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            Does your child play pretend or make-believe?
                                            (FOR EXAMPLE, pretend to drink from an empty cup, pretend to talk on a phone,
                                            or pretend to feed a doll or stuffed animal?)
                                        </td>
                                        <td class="col-4">
                                            <span *ngIf="mChat.child=='None'">None</span>
                                            <span *ngIf="mChat.child=='1'">Yes</span>
                                            <span *ngIf="mChat.child=='0'">No</span>
                                        </td>

                                    </tr>
                                    <tr *ngIf="mChat.climb!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            Does your child like climbing on things?
                                            (FOR EXAMPLE, furniture, playground, equipment, or stairs)
                                        </td>
                                        <td class="col-4">
                                            <span *ngIf="mChat.climb=='None'">None</span>
                                            <span *ngIf="mChat.climb=='1'">Yes</span>
                                            <span *ngIf="mChat.climb=='0'">No</span>
                                        </td>


                                    </tr>
                                    <tr *ngIf="mChat.eyes!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            Does your child make unusual finger movements near his or her eyes?
                                            (FOR EXAMPLE, does your child wiggle his or her fingers close to his or her eyes)
                                        </td>

                                        <td class="col-4">
                                            <span *ngIf="mChat.eyes=='None'">None</span>
                                            <span *ngIf="mChat.eyes=='1'">No</span>
                                            <span *ngIf="mChat.eyes=='0'">Yes</span>
                                        </td>

                                    </tr>
                                    <tr *ngIf="mChat.finger!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            Does your child point with one finger to ask for something or to get help?
                                            (FOR EXAMPLE, pointing to a snack or toy that is out of reach)
                                        </td>

                                        <td class="col-4">
                                            <span *ngIf="mChat.finger=='None'">None</span>
                                            <span *ngIf="mChat.finger=='1'">Yes</span>
                                            <span *ngIf="mChat.finger=='0'">No</span>
                                        </td>

                                    </tr>
                                    <tr *ngIf="mChat.finger1!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            Does your child point with one finger to show you something interesting?
                                            (FOR EXAMPLE, pointing to an airplane in the sky or a big truck in the road)
                                        </td>
                                        <td class="col-4">
                                            <span *ngIf="mChat.finger1=='None'">None</span>
                                            <span *ngIf="mChat.finger1=='1'">Yes</span>
                                            <span *ngIf="mChat.finger1=='0'">No</span>
                                        </td>


                                    </tr>
                                    <tr *ngIf="mChat.child1!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            Is your child interested in other children?
                                            (FOR EXAMPLE, does your child watch other children, smile at them, or go to them?)
                                        </td>

                                        <td class="col-4">
                                            <span *ngIf="mChat.child1=='None'">None</span>
                                            <span *ngIf="mChat.child1=='1'">Yes</span>
                                            <span *ngIf="mChat.child1=='0'">No</span>
                                        </td>


                                    </tr>
                                    <tr *ngIf="mChat.flower!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            Does your child show you things by bringing them to you or holding them up for you to see
                                            -- not to get help, but just to share?(FOR EXAMPLE, showing you a flower, a stuffed animal, or a toy truck)
                                        </td>
                                        <td class="col-4">
                                            <span *ngIf="mChat.flower=='None'">None</span>
                                            <span *ngIf="mChat.flower=='1'">Yes</span>
                                            <span *ngIf="mChat.flower=='0'">No</span>
                                        </td>



                                    </tr>
                                    <tr *ngIf="mChat.respond!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            Does your child respond when you call his or her name?
                                            (FOR EXAMPLE does he or she look up, talk or babble,
                                            or stop what he or she is doing when you call his or her name?)
                                        </td>

                                        <td class="col-4">
                                            <span *ngIf="mChat.respond=='None'">None</span>
                                            <span *ngIf="mChat.respond=='1'">Yes</span>
                                            <span *ngIf="mChat.respond=='0'">No</span>
                                        </td>


                                    </tr>
                                    <tr *ngIf="mChat.smile!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            When you smile at your child, does he or she smile back at you?
                                        </td>

                                        <td class="col-4">
                                            <span *ngIf="mChat.smile=='None'">None</span>
                                            <span *ngIf="mChat.smile=='0'">Yes</span>
                                            <span *ngIf="mChat.smile=='1'">No</span>
                                        </td>


                                    </tr>
                                    <tr *ngIf="mChat.noise!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            Does your child get upset by everyday noises?
                                            (FOR EXAMPLE, does your child scream or cry to noise such as a vacuum cleaner or loud music?)
                                        </td>

                                        <td class="col-4">
                                            <span *ngIf="mChat.noise =='None'">None</span>
                                            <span *ngIf="mChat.noise =='1'">Yes</span>
                                            <span *ngIf="mChat.noise =='0'">No</span>
                                        </td>




                                    </tr>
                                    <tr *ngIf="mChat.walk!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            Does your child walk?
                                        </td>

                                        <td class="col-4">
                                            <span *ngIf="mChat.walk=='None'">None</span>
                                            <span *ngIf="mChat.walk=='1'">Yes</span>
                                            <span *ngIf="mChat.walk=='0'">No</span>
                                        </td>


                                    </tr>
                                    <tr *ngIf="mChat.eyeContact!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            Does your child look you in the eye when you are talking to him or her,
                                            playing with him or her, or dressing him or her?
                                        </td>

                                        <td class="col-4">
                                            <span *ngIf="mChat.eyeContact=='None'">None</span>
                                            <span *ngIf="mChat.eyeContact=='1'">Yes</span>
                                            <span *ngIf="mChat.eyeContact=='0'">No</span>
                                        </td>

                                    </tr>
                                    <tr *ngIf="mChat.copy!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            Does your child try to copy what you do?
                                            (FOR EXAMPLE, wave bye-bye, clap, or make a funny noise when you do)
                                        </td>

                                        <td class="col-4">
                                            <span *ngIf="mChat.copy=='None'">None</span>
                                            <span *ngIf="mChat.copy=='1'">Yes</span>
                                            <span *ngIf="mChat.copy=='0'">No</span>
                                        </td>


                                    </tr>
                                    <tr *ngIf="mChat.look!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            If you turn your head to look something,
                                            does your child look around to see what you are looking at?
                                        </td>
                                        <td class="col-4">
                                            <span *ngIf="mChat.look=='None'">None</span>
                                            <span *ngIf="mChat.look=='1'">Yes</span>
                                            <span *ngIf="mChat.look=='0'">No</span>
                                        </td>


                                    </tr>
                                    <tr *ngIf="mChat.praise!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            Does your child try to get you to watch him or her?
                                            (FOR EXAMPLE, does your child look at you for praise, or say "look" or "watch me"?)
                                        </td>

                                        <td class="col-4">
                                            <span *ngIf="mChat.praise =='None'">None</span>
                                            <span *ngIf="mChat.praise=='1'">Yes</span>
                                            <span *ngIf="mChat.praise=='0'">No</span>
                                        </td>

                                    </tr>
                                    <tr *ngIf="mChat.blanket!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            Does your child understand when you tell him or her to do something?
                                            (FOR EXAMPLE, if you don't point, can your child understand "put the book on the chair" or "bring me the blanket"?)
                                        </td>

                                        <td class="col-4">
                                            <span *ngIf="mChat.blanket=='None'">None</span>
                                            <span *ngIf="mChat.blanket=='1'">Yes</span>
                                            <span *ngIf="mChat.blanket=='0'">No</span>
                                        </td>

                                    </tr>
                                    <tr *ngIf="mChat.newtoy!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            If something new happens, does your child look at your face to see how you feel about it?
                                            (FOR EXAMPLE, if he or she hears a strange or funny noise or sees a new toy will he or she look at your face?)
                                        </td>
                                        <td class="col-4">
                                            <span *ngIf="mChat.newtoy=='None'">None</span>
                                            <span *ngIf="mChat.newtoy=='1'">Yes</span>
                                            <span *ngIf="mChat.newtoy=='0'">No</span>
                                        </td>


                                    </tr>

                                    <tr *ngIf="mChat.action!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            Does your child like movement activities? (FOR EXAMPLE, being swung or bounced on your knee)
                                        </td>
                                        <td class="col-4">
                                            <span *ngIf="mChat.action=='None'">None</span>
                                            <span *ngIf="mChat.action=='1'">Yes</span>
                                            <span *ngIf="mChat.action=='0'">No</span>
                                        </td>

                                    </tr>
                                    <tr *ngIf="mChat.total> 0">
                                        <td class="col-8">
                                            Total Score
                                        </td>
                                        <td class="col-4"
                                            [textContent]="mChat.total">
                                        </td>
                                    </tr>
                                    <tr *ngIf="mChat.total> 0">
                                        <td class="col-8">Comments</td>
                                        <td class="col-4" [textContent]="mChat.comments"></td>
                                    </tr>

                                    <tr *ngIf="mChat.total> 0">
                                        <td class="col-8">Action Required</td>
                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="mChat.actionRequired"></td>
                                    </tr>
                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black">
                                        <th colspan="2">Section B:GeneralPractitioner Or Pediatrician Observation</th>
                                    </tr>
                                    <tr *ngIf="mChat.eye1!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            During the appointment, has the child made eye contact with you?
                                        </td>
                                        <td class="col-4">
                                            <span *ngIf="mChat.eye1=='None'">None</span>
                                            <span *ngIf="mChat.eye1=='1'">Yes</span>
                                            <span *ngIf="mChat.eye1=='0'">No</span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="mChat.eye2!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            Get child's attention, then point across the room at an interesting object and say,
                                            "oh look! There's a (name a toy!)" Watch child's face. Does the child look across
                                            to see what you are pointing at?
                                        </td>
                                        <td class="col-4">
                                            <span *ngIf="mChat.eye2=='None'">None</span>
                                            <span *ngIf="mChat.eye2=='1'">Yes</span>
                                            <span *ngIf="mChat.eye2=='0'">No</span>
                                        </td>

                                    </tr>
                                    <tr *ngIf="mChat.eye3!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            Get the child's attention, then give child a miniature toy cup and teapot and say,
                                            "Can you make a cup of tea?" Does the child pretend to pour out tea, drink it, etc.
                                        </td>

                                        <td class="col-4">
                                            <span *ngIf="mChat.eye3=='None'">None</span>
                                            <span *ngIf="mChat.eye3=='1'">Yes</span>
                                            <span *ngIf="mChat.eye3=='0'">No</span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="mChat.eye4!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            Say to the child "Where's the light", or "Show me the light".
                                            Does the child POINT with his/her index finger at the light?
                                        </td>

                                        <td class="col-4">
                                            <span *ngIf="mChat.eye4=='None'">None</span>
                                            <span *ngIf="mChat.eye4=='1'">Yes</span>
                                            <span *ngIf="mChat.eye4=='0'">No</span>

                                        </td>
                                    </tr>

                                    <tr *ngIf="mChat.eye6!=null">
                                        <td class="col-8" style=" white-space: normal; word-break: break-all;">
                                            Can the child build a tower for bricks? (If so how many?) (Number of bricks:)
                                        </td>

                                        <td class="col-4">
                                            <span *ngIf="mChat.eye6=='None'">None</span>
                                            <span *ngIf="mChat.eye6=='1'">Yes</span>
                                            <span *ngIf="mChat.eye6=='0'">No</span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="mChat.score1!=null">
                                        <td class="col-8">
                                            Score
                                        </td>
                                        <td class="col-4"
                                            [textContent]="mChat.score1">
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard-box dashboard-table-box" *ngIf="pediatricGuideLines">
                        <h4 class="dashboard-title">Guidelines</h4>
                        <div class="dashboard-body">
                            <div class="overflow-auto">
                                <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="pediatricGuideLines && pediatricGuideLines.length >0">
                                    <thead>
                                        <tr>
                                            <th>Disease name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of pediatricGuideLines;">
                                            <td>
                                                <div class="media">
                                                    <div class="media-body">
                                                        <h5 class="mb-0 mt-0 font-weight-normal text-truncate-line" [textContent]="item.name"></h5>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard-box dashboard-table-box" *ngIf="vaccineDetail && vaccineDetail !== null && immunization.length > 0">
                        <h4 class="dashboard-title">Vaccination</h4>
                        <div class="dashboard-body">
                            <div class="overflow-auto">
                                <table class="table table-centered table-sm table-striped mb-0">
                                    <tr>
                                        <th>Vaccine Detail</th>
                                        <th>Drug Name</th>
                                        <th>Orderby</th>
                                        <th>Status</th>
                                        <th>BatchNumber</th>
                                        <th>Expiry</th>
                                        <th>BillNumber</th>
                                        <th>GivenBy</th>
                                    </tr>
                                    <tr *ngFor="let imm of immunization;index as o;">
                                        <td>
                                            <h6 class="mb-0"><span [textContent]="imm.vaccineName"></span></h6>
                                            <span class="mb-0 d-flex text-muted">
                                                <i class="mdi mdi-clock mr-1"></i> <span [textContent]="imm.allowedDays"></span>&nbsp;<span [textContent]="imm.allowedType"></span>
                                            </span>
                                        </td>
                                        <td>
                                            <h6 class="mb-0"><span [textContent]="imm.productName"></span></h6>
                                        </td>
                                        <td>
                                            <h6 class="mb-0"><span [textContent]="imm.orderBy || '----'"></span></h6>
                                            <span class="d-flex mb-0"><span [textContent]="imm.createdDate | date:'dd-MM-yyyy, hh:mm a' "></span></span>
                                        </td>
                                        <td>
                                            <span [ngClass]="imm.status === 'Pending' ? 'badge badge-outline-danger' : 'badge badge-outline-success'">{{imm.status}}</span>

                                        </td>
                                        <td>
                                            <h6 class="mb-0"><span [textContent]="imm.batchNumber || '----'"></span></h6>
                                        </td>
                                        <td>
                                            <h6 *ngIf="imm.expiryDate" class="mb-0"><span [textContent]="imm.expiryDate | date:'MM-yyyy'"></span></h6>
                                            <span *ngIf="!imm.expiryDate">----</span>
                                        </td>
                                        <td>
                                            <h6 class="mb-0"><span [textContent]="imm.billNumber || '----'"></span></h6>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="imm.vaccineGivenByName">
                                                <h6 class="mb-0"><span [textContent]="imm.vaccineGivenByName || '----'"></span></h6>
                                                <span class="d-flex mb-0"><span [textContent]="imm.vaccineGivenDate | date:'dd-MM-yyyy, hh:mm a' "></span></span>
                                            </ng-container>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard-box dashboard-table-box" *ngIf="syndrome && syndrome !== null">
                        <h4 class="dashboard-title">Syndromes & Diseases </h4>
                        <div class="dashboard-body">
                            <div class="overflow-auto">
                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                    <tr *ngIf="syndrome.consultantDoctor">
                                        <td class="col-8">Consultant Doctor</td>
                                        <td class="col-4" style="text-transform: uppercase" [textContent]="syndrome.consultantDoctor"></td>
                                    </tr>
                                    <tr class="bg-soft-dark" style="border-bottom:1px solid black" *ngIf="syndrome.syndromes && syndrome.syndromes.length >0">
                                        <th colspan="2">Syndromes</th>
                                    </tr>
                                    <tr *ngIf="syndrome.syndromes && syndrome.syndromes.length >0">
                                        <td>
                                            <span>Syndromes</span>
                                        </td>
                                        <td style="white-space:normal;">
                                            <span *ngFor="let item of syndrome.syndromes" [textContent]="item+',  '"></span>
                                        </td>
                                    </tr>
                                </table>
                                <div class="row" *ngIf="syndrome && syndrome !== null">

                                    <div class="col-12">


                                        <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                            <tr class="bg-soft-dark" style="border-bottom:1px solid black;" *ngIf="syndrome && syndrome !== null">
                                                <th colspan="2">Diseases</th>
                                            </tr>
                                            <tr *ngIf="syndrome.diseases && syndrome.diseases.length >0">
                                                <td>
                                                    <span>Diseases</span>
                                                </td>
                                                <td style="white-space:normal;">
                                                    <span *ngFor="let item of syndrome.diseases" [textContent]="item+',  '"></span>
                                                </td>
                                            </tr>
                                            <tr *ngIf="syndrome.others">
                                                <td class="col-8">Others</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="syndrome.others"></td>
                                            </tr>
                                            <tr *ngIf="syndrome.provisionalDiagnosis">
                                                <td class="col-8"> Provisional Diagnosis</td>
                                                <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="syndrome.provisionalDiagnosis"></td>
                                            </tr>
                                            <tr *ngIf="syndrome.testOrdered">
                                                <td class="col-8">Test Ordered</td>
                                                <td class="col-4" [textContent]="syndrome.testOrdered"></td>
                                            </tr>
                                            <tr *ngIf="syndrome.ifThePatientIsWillingToGiveSample">
                                                <td class="col-8"> If the Patient Is Willing To GiveSample</td>
                                                <td class="col-4" [textContent]="syndrome.ifThePatientIsWillingToGiveSample"></td>
                                            </tr>

                                            <tr *ngIf="syndrome.symptoms">
                                                <td class="col-8"> Date Of Onset Of the Symptoms</td>
                                                <td class="col-4" [textContent]="syndrome.symptoms|  date: 'dd/MM/yyyy'"></td>
                                            </tr>
                                            <tr *ngIf="syndrome.transactionId">
                                                <td class="col-8"> Transaction Id</td>
                                                <td class="col-4" [textContent]="syndrome.transactionId"></td>
                                            </tr>
                                            <tr *ngIf="syndrome.typeOfSample">
                                                <td class="col-8"> Type Of Sample</td>
                                                <td class="col-4" [textContent]="syndrome.typeOfSample"></td>
                                            </tr>
                                            <tr *ngIf="syndrome.dateOfSampleCollection">
                                                <td class="col-8"> Date Of Sample Collection</td>
                                                <td class="col-4" [textContent]="syndrome.dateOfSampleCollection | date: 'dd/MM/yyyy'"></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="roleId != 63">
                        <div class="dashboard-box dashboard-table-box" *ngIf="reminders && reminders !== null">
                            <h4 class="dashboard-title">Reminders</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div class="row col-12" *ngIf="reminders && reminders !== null">
                                        <div class="row col-12">
                                            <div *ngIf="reminders && reminders==null">
                                                There is no reminders
                                            </div>
                                        </div>
                                        <div class="col-12" *ngIf="reminders && reminders !== null">
                                            <div class="table-responsive horizontal-scroll">
                                                <table class="table table-sm mb-0">

                                                    <tr *ngIf="reminders">
                                                        <td class="col-8">Reminders</td>
                                                        <td class="col-4">
                                                            <span class=" text-wrap" [textContent]="reminders"></span><br />
                                                        </td>
                                                    </tr>

                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard-box dashboard-table-box" *ngIf="addendumForm && addendumForm!== null">
                        <h4 class="dashboard-title">Addendum Form</h4>
                        <div class="dashboard-body">
                            <div class="overflow-auto">
                                <div class="row col-12" *ngIf="addendumForm && addendumForm !== null">
                                    <div class="row col-12">
                                        <div *ngIf="addendum && addendum==null">
                                            There is no addendum
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="addendumForm && addendumForm !== null">
                                        <div class="table-responsive horizontal-scroll">
                                            <table class="table table-sm mb-0">

                                                <tr *ngIf="addendumForm.date">
                                                    <td class="col-8">Date</td>
                                                    <td class="col-4">
                                                        <span [textContent]="addendumForm.date| date: 'dd/MM/yyyy'"></span><br />
                                                    </td>
                                                </tr>
                                                <tr *ngIf="addendumForm.time">
                                                    <td class="col-8">Time</td>
                                                    <td class="col-4">
                                                        <span [textContent]="addendumForm.time"></span><br />
                                                    </td>
                                                </tr>
                                                <tr *ngIf="addendumForm.note">
                                                    <td class="col-8">Note</td>
                                                    <td class="col-4">
                                                        <span [textContent]="addendumForm.note"></span><br />
                                                    </td>
                                                </tr>

                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="roleId != 63">
                        <div class="dashboard-box dashboard-table-box" *ngIf="procedureSurgery">
                            <h4 class="dashboard-title">Past Surgical history</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div *ngIf="isProcedureSurgery">
                                        There is no Surgical History
                                    </div>
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="procedureSurgery && procedureSurgery.length >0">
                                        <thead>
                                            <tr>
                                                <th class="col-4">Year Of Surgery</th>
                                                <th class="col-4">Surgeries</th>
                                                <th class="col-4">Comments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of procedureSurgery;">
                                                <td style="align-content:center">
                                                    <div class="media">
                                                        <div class="media-body">
                                                            <h5 class="mb-0 mt-0 font-weight-normal text-truncate-line" [textContent]=" item.date"></h5>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style="align-content:center">
                                                    <p *ngIf="item.surgeries" [textContent]="item.surgeries "></p>
                                                    <p style="padding-left: 25px" *ngIf="!item.surgeries" [textContent]="'-'"></p>
                                                </td>
                                                <td style="align-content:center">
                                                    <p *ngIf="item.comments" [textContent]="item.comments "></p>
                                                    <p style="padding-left: 25px" *ngIf="!item.comments" [textContent]="'-'"></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="roleId != 63">
                        <div class="dashboard-box dashboard-table-box" *ngIf="problemList">
                            <h4 class="dashboard-title">Past Medical History</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div *ngIf="isPastMedicalHistory">
                                        There is no past medical history
                                    </div>
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="!isPastMedicalHistory">
                                        <thead>
                                            <tr>
                                                <th>Disease name</th>
                                                <th>Year Of Diagnosis</th>
                                                <th>ICD</th>
                                                <th>Status</th>
                                                <th style="width: 250px">Treatment history</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of data">
                                                <td>
                                                    <div class="media">
                                                        <div class="media-body">
                                                            <h5 class="mb-0 mt-0 font-weight-normal text-truncate-line" [textContent]="item.usable"></h5>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p [textContent]="item.date ||'---'"></p>
                                                </td>
                                                <td [textContent]="item.code"></td>
                                                <td [textContent]="item.status||'--'"></td>
                                                <td>
                                                    <p class="mb-0 text-truncate-line" [textContent]="item.overRideMessage|| '-'"></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div #telephoneTriageAssessmentSection *ngIf="telephoneTriageAssessmentdata && telephoneTriageAssessmentdata.length>0">
                        <div class="dashboard-box dashboard-table-box" *ngFor="let telephoneTriageAssessments of telephoneTriageAssessmentdata">
                            <h4 class="dashboard-title">TelePhone Triage Assessment </h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto" *ngIf="telephoneTriageAssessments && telephoneTriageAssessments !== null && (telephoneTriageAssessments.callDate || telephoneTriageAssessments.callTime || telephoneTriageAssessments.typeofConsultation || telephoneTriageAssessments.reasonForCall || telephoneTriageAssessments.reasonothers
                                                 || telephoneTriageAssessments.adviceother || telephoneTriageAssessments.adviceGiventoPatient || telephoneTriageAssessments.actionTakenIfAdvisedToVisit || telephoneTriageAssessments.actionother || telephoneTriageAssessments.doctorName || telephoneTriageAssessments.callCompletedDate || telephoneTriageAssessments.callcompletedTime)">
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <tr>
                                            <td class="col-8">Call Date</td>
                                            <td class="col-4" [textContent]="telephoneTriageAssessments.callDate | date:'dd/MM/yyyy'"> </td>
                                        </tr>
                                        <tr>
                                            <td class="col-8">Call Time</td>
                                            <td class="col-4" [textContent]="telephoneTriageAssessments.callTime"> </td>
                                        </tr>
                                        <tr>
                                            <td class="col-8">Type of Consultation</td>
                                            <td class="col-4" [textContent]="telephoneTriageAssessments.typeofConsultation"> </td>
                                        </tr>
                                        <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.reasonForCall?.length > 0">
                                            <td class="col-4">Reason For Call</td>
                                            <td class="col-8" *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.reasonForCall">
                                                <ul class="dashboard-ul row">
                                                    <li *ngFor="let item of  telephoneTriageAssessments.reasonForCall" [textContent]="item"></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="col-8">Other</td>
                                            <td class="col-4" [textContent]="telephoneTriageAssessments.reasonothers"> </td>
                                        </tr>
                                        <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.adviceGiventoPatient?.length > 0">
                                            <td class="col-4">Advice Given To Patient</td>
                                            <td class="col-8">
                                                <ul class="dashboard-ul row">
                                                    <li *ngFor="let item of  telephoneTriageAssessments.adviceGiventoPatient" [textContent]="item"></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="col-8"> Others</td>
                                            <td class="col-4" [textContent]="telephoneTriageAssessments.adviceother"> </td>
                                        </tr>
                                        <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.actionTakenIfAdvisedToVisit?.length > 0">
                                            <td class="col-4">Action Taken If Advised To Visit</td>
                                            <td class="col-8">
                                                <ul class="dashboard-ul row">
                                                    <li *ngFor="let item of  telephoneTriageAssessments.actionTakenIfAdvisedToVisit" [textContent]="item"></li>

                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="col-8">Others</td>
                                            <td class="col-4" [textContent]="telephoneTriageAssessments.actionother"> </td>
                                        </tr>
                                        <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.doctorName">
                                            <td class="col-8">Doctor Name</td>
                                            <td class="col-4 text-uppercase" [textContent]="telephoneTriageAssessments.doctorName"> </td>
                                        </tr>
                                        <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.callCompletedDate">
                                            <td class="col-8">Call Completed Date</td>
                                            <td class="col-4" [textContent]="telephoneTriageAssessments.callCompletedDate | date:'dd/MM/yyyy'"> </td>
                                        </tr>
                                        <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.callcompletedTime">
                                            <td class="col-8">Call Completed Time</td>
                                            <td class="col-4" [textContent]="telephoneTriageAssessments.callcompletedTime"> </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="dashboard-box dashboard-table-box" #aldretescoreSection *ngIf="aldreteScore && aldreteScore !== null">
                        <h4 class="dashboard-title">Aldrete Score </h4>
                        <div class="dashboard-body">
                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                <tr>
                                    <th class="white-space-break">
                                        Post Anaesthesia Score: Criteria For Discharge From ICU/POW
                                        A Total Discharge Score Of 8-10 Is Necessary For Discharge To Be Filled By Doctors Definition
                                    </th>
                                </tr>
                                <tr *ngIf="aldreteScore.activity">
                                    <td class="col-8">Activity</td>
                                    <td class="col-4" [textContent]="aldreteScore.activity"> </td>
                                </tr>
                                <tr *ngIf="aldreteScore.respiration">
                                    <td class="col-8">Respiration</td>
                                    <td class="col-4" [textContent]="aldreteScore.respiration"> </td>
                                </tr>
                                <tr *ngIf="aldreteScore.circulation">
                                    <td class="col-8">Circulation</td>
                                    <td class="col-4" [textContent]="aldreteScore.circulation"> </td>
                                </tr>
                                <tr *ngIf="aldreteScore.consciousness">
                                    <td class="col-8">Consciousness</td>
                                    <td class="col-4" [textContent]="aldreteScore.consciousness"> </td>
                                </tr>
                                <tr *ngIf="aldreteScore.oxygensaturation">
                                    <td class="col-8">Oxygen Saturation</td>
                                    <td class="col-4" [textContent]="aldreteScore.oxygensaturation"> </td>
                                </tr>
                                <tr *ngIf="aldreteScore.totalscore">
                                    <td class="col-8">Total Score</td>
                                    <td class="col-4" [textContent]="aldreteScore.totalscore"> </td>
                                </tr>
                                <tr *ngIf="aldreteScore.scoreDoneBy">
                                    <td class="col-8">Score Done By</td>
                                    <td class="col-4" [textContent]="aldreteScore.scoreDoneBy"> </td>
                                </tr>

                            </table>
                        </div>
                    </div>

                    <div>
                        <div class="dashboard-box dashboard-table-box" #anaesthesiaSection *ngIf="anaesthesia && anaesthesia !== null">
                            <h4 class="dashboard-title">Anaesthesia Record</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia && anaesthesia.weight || anaesthesia &&  anaesthesia.height|| anaesthesia &&  anaesthesia.bmi|| anaesthesia && anaesthesia.pacDoneBy||anaesthesia &&  anaesthesia.consultantDr|| anaesthesia && anaesthesia.bloodGroup||anaesthesia && anaesthesia.rhType||
                                                   anaesthesia && anaesthesia.allergy|| anaesthesia &&  anaesthesia.diagnosis||anaesthesia &&  anaesthesia.surgery|| anaesthesia &&  anaesthesia.problems)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Anaesthesia Record</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="anaesthesia &&  anaesthesia.weight">
                                                    <td class="col-8">Weight</td>
                                                    <td class="col-4" [textContent]="anaesthesia.weight"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.height">
                                                    <td class="col-8">Height</td>
                                                    <td class="col-4" [textContent]="anaesthesia.height"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia &&  anaesthesia.bmi">
                                                    <td class="col-8">BMI</td>
                                                    <td class="col-4" [textContent]="anaesthesia.bmi"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.pacDoneBy">
                                                    <td class="col-8">PAC Done By Dr</td>
                                                    <td class="col-4" [textContent]="anaesthesia.pacDoneBy"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.consultantDr">
                                                    <td class="col-8">Consultant Surgeon</td>
                                                    <td class="col-4" [textContent]="anaesthesia.consultantDr"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.bloodGroup">
                                                    <td class="col-8">Blood Group</td>
                                                    <td class="col-4" [textContent]="anaesthesia.bloodGroup"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.rhType">
                                                    <td class="col-8">Rh Type</td>
                                                    <td class="col-4" [textContent]="anaesthesia.rhType"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia &&  anaesthesia.allergy">
                                                    <td class="col-8">Allergy</td>
                                                    <td class="col-4">
                                                        <span class="wordBreak" [textContent]="anaesthesia.allergy">  </span>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.diagnosis">
                                                    <td class="col-8">Diagnosis</td>
                                                    <td class="col-4">
                                                        <span class="wordBreak" [textContent]="anaesthesia.diagnosis">  </span>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.surgery">
                                                    <td class="col-8">Surgery</td>
                                                    <td class="col-4" [textContent]="anaesthesia.surgery"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.problems">
                                                    <td class="col-8">Problems</td>
                                                    <td class="col-4">
                                                        <span class="wordBreak" [textContent]="anaesthesia.problems">  </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia && anaesthesia.reasonForConsutation || anaesthesia &&  anaesthesia.temp|| anaesthesia &&  anaesthesia.sp|| anaesthesia &&  anaesthesia.pr||anaesthesia && anaesthesia.respRate||anaesthesia && anaesthesia.sysBp|| anaesthesia &&  anaesthesia.diasBp)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">General Examination</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="anaesthesia &&  anaesthesia.reasonForConsutation">
                                                    <td class="col-8">Reason For Consultation</td>
                                                    <td class="col-4" [textContent]="anaesthesia.reasonForConsutation"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.temp">
                                                    <td class="col-8">
                                                        Temp(Â°C / Fh)
                                                    </td>
                                                    <td class="col-4" [textContent]="anaesthesia.temp"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.pr">
                                                    <td class="col-8">PR(/Min)</td>
                                                    <td class="col-4" [textContent]="anaesthesia.pr"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.respRate">
                                                    <td class="col-8">Resp Rate(/Min)</td>
                                                    <td class="col-4" [textContent]="anaesthesia.respRate"> </td>
                                                </tr>

                                                <tr *ngIf="anaesthesia && anaesthesia.sysBp">
                                                    <td class="col-8">Sys BP(Mm Of Hg)</td>
                                                    <td class="col-4" [textContent]="anaesthesia.sysBp"> </td>
                                                </tr>

                                                <tr *ngIf="anaesthesia && anaesthesia.diasBp">
                                                    <td class="col-8">Dias BP(Mm Of Hg)</td>
                                                    <td class="col-4" [textContent]="anaesthesia.diasBp"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                    <td class="col-8">SpO2</td>
                                                    <td class="col-4" [textContent]="anaesthesia.sp"> </td>
                                                </tr>


                                                <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                    <td class="col-8">Heart</td>
                                                    <td class="col-4" [textContent]="anaesthesia.heart"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                    <td class="col-8">Lungs</td>
                                                    <td class="col-4" [textContent]="anaesthesia.lungs"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                    <td class="col-8">Lungs-Adv Sounds</td>
                                                    <td class="col-4" [textContent]="anaesthesia.lungsSound"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                    <td class="col-8">Built</td>
                                                    <td class="col-4" [textContent]="anaesthesia.built"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                    <td class="col-8">Hydration</td>
                                                    <td class="col-4" [textContent]="anaesthesia.hydration"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                    <td class="col-8">Pallor</td>
                                                    <td class="col-4" [textContent]="anaesthesia.pallor"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                    <td class="col-8">Icterus</td>
                                                    <td class="col-4" [textContent]="anaesthesia.icterus"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                    <td class="col-8">Cyanosis</td>
                                                    <td class="col-4" [textContent]="anaesthesia.cyanosis"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                    <td class="col-8">Oedema</td>
                                                    <td class="col-4" [textContent]="anaesthesia.oedema"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.sp">
                                                    <td class="col-8">PP</td>
                                                    <td class="col-4" [textContent]="anaesthesia.pp"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia && anaesthesia.mouthOpening || anaesthesia.detention && anaesthesia.detention.length >0|| anaesthesia &&  anaesthesia.tmj|| anaesthesia && anaesthesia.neckExtention||anaesthesia && anaesthesia.grade|| anaesthesia && anaesthesia.spineGrade|| anaesthesia && anaesthesia.asaGrade
||anaesthesia && anaesthesia.difficultIntubation||anaesthesia && anaesthesia.difficultRegional)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Airway Assessment</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="anaesthesia && anaesthesia.mouthOpening">
                                                    <td class="col-8">Mouth Opening</td>
                                                    <td class="col-4" [textContent]="anaesthesia.mouthOpening"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.detention.length >0">
                                                    <td class="col-8">Dentition </td>
                                                    <td class="col-4" [textContent]="anaesthesia.detention"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.tmj">
                                                    <td class="col-8">TMJ</td>
                                                    <td class="col-4" [textContent]="anaesthesia.tmj"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.neckExtention">
                                                    <td class="col-8">
                                                        Neck Extention
                                                    </td>
                                                    <td class="col-4" [textContent]="anaesthesia.neckExtention"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.grade">
                                                    <td class="col-8"> Mallampati Grade</td>
                                                    <td class="col-4" [textContent]="anaesthesia.grade"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.spineGrade">
                                                    <td class="col-8">Spine Grade</td>
                                                    <td class="col-4" [textContent]="anaesthesia.spineGrade"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.asaGrade">
                                                    <td class="col-8">ASA Grade</td>
                                                    <td class="col-4" [textContent]="anaesthesia.asaGrade"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.difficultIntubation">
                                                    <td class="col-8">Difficult Intubation</td>
                                                    <td class="col-4" [textContent]="anaesthesia.difficultIntubation"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.difficultRegional">
                                                    <td class="col-8">Difficult Regional</td>
                                                    <td class="col-4" [textContent]="anaesthesia.difficultRegional"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia && anaesthesia.cvs || anaesthesia &&  anaesthesia.endocrine|| anaesthesia && anaesthesia.cns|| anaesthesia && anaesthesia.rs||anaesthesia.renal||anaesthesia && anaesthesia.git|| anaesthesia.currentMed && anaesthesia.currentMed.length >0
||anaesthesia && anaesthesia.others||anaesthesia && anaesthesia.pastAnaesthesia)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">System Based Assessment</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="anaesthesia && anaesthesia.cvs">
                                                    <td class="col-8">CVS</td>
                                                    <td class="col-4" [textContent]="anaesthesia.cvs"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.endocrine">
                                                    <td class="col-8">Endocrine </td>
                                                    <td class="col-4" [textContent]="anaesthesia.endocrine"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.cns">
                                                    <td class="col-8">CNS /Musculo Skeletal</td>
                                                    <td class="col-4" [textContent]="anaesthesia.cns"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.rs">
                                                    <td class="col-8">
                                                        RS
                                                    </td>
                                                    <td class="col-4" [textContent]="anaesthesia.rs"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.renal">
                                                    <td class="col-8"> Renal</td>
                                                    <td class="col-4" [textContent]="anaesthesia.renal"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.git">
                                                    <td class="col-8">GIT /Hematology</td>
                                                    <td class="col-4" [textContent]="anaesthesia.git"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.currentMed && anaesthesia.currentMed.length >0">
                                                    <td class="col-8">Current Med</td>
                                                    <td class="col-4" [textContent]="anaesthesia.currentMed"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.others">
                                                    <td class="col-8">Others</td>
                                                    <td class="col-4">
                                                        <span class="wordBreak" [textContent]="anaesthesia.others"></span>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.pastAnaesthesia">
                                                    <td class="col-8">Past Anaesthesia Exposure</td>
                                                    <td class="col-4">
                                                        <span class="wordBreak" [textContent]="anaesthesia.pastAnaesthesia"></span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia && anaesthesia.prbsAvailability || anaesthesia && anaesthesia.prbsReserve|| anaesthesia && anaesthesia.prbsIssue)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">PRBC</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="anaesthesia && anaesthesia.prbsAvailability">
                                                    <td class="col-8">Availability</td>
                                                    <td class="col-4" [textContent]="anaesthesia.prbsAvailability"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.prbsReserve">
                                                    <td class="col-8">Reserve </td>
                                                    <td class="col-4" [textContent]="anaesthesia.prbsReserve"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.prbsIssue">
                                                    <td class="col-8">Issue</td>
                                                    <td class="col-4" [textContent]="anaesthesia.prbsIssue"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia && anaesthesia.ffpAvailability || anaesthesia && anaesthesia.ffpReserve|| anaesthesia && anaesthesia.ffpIssue)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">FFP</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="anaesthesia && anaesthesia.ffpAvailability">
                                                    <td class="col-8">Availability</td>
                                                    <td class="col-4" [textContent]="anaesthesia.ffpAvailability"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.ffpReserve">
                                                    <td class="col-8">Reserve </td>
                                                    <td class="col-4" [textContent]="anaesthesia.ffpReserve"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.ffpIssue">
                                                    <td class="col-8">Issue</td>
                                                    <td class="col-4" [textContent]="anaesthesia.ffpIssue"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia && anaesthesia.rdpAvailability || anaesthesia && anaesthesia.rdpReserve|| anaesthesia && anaesthesia.rdpIssue)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">RDP</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="anaesthesia && anaesthesia.rdpAvailability">
                                                    <td class="col-8">Availability</td>
                                                    <td class="col-4" [textContent]="anaesthesia.rdpAvailability"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.rdpReserve">
                                                    <td class="col-8">Reserve </td>
                                                    <td class="col-4" [textContent]="anaesthesia.rdpReserve"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.rdpIssue">
                                                    <td class="col-8">Issue</td>
                                                    <td class="col-4" [textContent]="anaesthesia.rdpIssue"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia && anaesthesia.donorScreen || anaesthesia && anaesthesia.onAgitator|| anaesthesia && anaesthesia.issue||anaesthesia && anaesthesia.mayBePosted||anaesthesia && anaesthesia.reviewPac|| (anaesthesia && anaesthesia.instructions && anaesthesia.instructions.length >0) ||anaesthesia && anaesthesia.anaesthesiaPlan
||anaesthesia.specificPost||anaesthesia.date||anaesthesia.time)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">SDP</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="anaesthesia && anaesthesia.donorScreen">
                                                    <td class="col-8">Donor Screen</td>
                                                    <td class="col-4" [textContent]="anaesthesia.donorScreen"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.onAgitator">
                                                    <td class="col-8">On Agitator </td>
                                                    <td class="col-4" [textContent]="anaesthesia.onAgitator"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.issue">
                                                    <td class="col-8"> Issue </td>
                                                    <td class="col-4" [textContent]="anaesthesia.issue"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.mayBePosted">
                                                    <td class="col-8"> May Be Posted</td>
                                                    <td class="col-4" [textContent]="anaesthesia.mayBePosted"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.reviewPac">
                                                    <td class="col-8">Review PAC</td>
                                                    <td class="col-4" [textContent]="anaesthesia.reviewPac"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.instructions.length >0">
                                                    <td class="col-8">Pre OP Instructions</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;">
                                                        <ul class="dashboard-ul row">
                                                            <li *ngFor="let item of anaesthesia.instructions" [textContent]="item"></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.anaesthesiaPlan">
                                                    <td class="col-8">Anaesthesia Plan</td>
                                                    <td class="col-4" [textContent]="anaesthesia.anaesthesiaPlan"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.specificPost">
                                                    <td class="col-8">Specific Post OP Instructions</td>
                                                    <td class="col-4" [textContent]="anaesthesia.specificPost"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.date">
                                                    <td class="col-8">Date</td>
                                                    <td class="col-4" [textContent]="anaesthesia.date | date:'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.time">
                                                    <td class="col-8">Time</td>
                                                    <td class="col-4" [textContent]="anaesthesia.time"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia && anaesthesia.hbPcv || anaesthesia && anaesthesia.hiv|| anaesthesia && anaesthesia.fbs||anaesthesia && anaesthesia.scr||anaesthesia && anaesthesia.na||anaesthesia && anaesthesia.sbill|| anaesthesia && anaesthesia.pt
|| anaesthesia && anaesthesia.wbc||anaesthesia && anaesthesia.hbsag|| anaesthesia && anaesthesia.plbs||anaesthesia && anaesthesia.bun||anaesthesia && anaesthesia.kplus||anaesthesia && anaesthesia.sgpt|| anaesthesia && anaesthesia.aptt||anaesthesia && anaesthesia.plat|| anaesthesia && anaesthesia.hcv|| anaesthesia && anaesthesia.rbs|| anaesthesia && anaesthesia.bdUrea
|| anaesthesia && anaesthesia.clMinus|| anaesthesia && anaesthesia.ldh|| anaesthesia && anaesthesia.inr|| anaesthesia && anaesthesia.lastothers)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Investigations</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="anaesthesia.hbPcv">
                                                    <td class="col-8">Hb/PCV</td>
                                                    <td class="col-4" [textContent]="anaesthesia.hbPcv"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.hiv">
                                                    <td class="col-8">HIV </td>
                                                    <td class="col-4" [textContent]="anaesthesia.hiv"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.fbs">
                                                    <td class="col-8"> FBS </td>
                                                    <td class="col-4" [textContent]="anaesthesia.fbs"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.scr">
                                                    <td class="col-8"> S Cr</td>
                                                    <td class="col-4" [textContent]="anaesthesia.scr"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.na">
                                                    <td class="col-8">Na+</td>
                                                    <td class="col-4" [textContent]="anaesthesia.na"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.sbill">
                                                    <td class="col-8">S Bill</td>
                                                    <td class="col-4" [textContent]="anaesthesia.sbill"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.pt">
                                                    <td class="col-8">PT</td>
                                                    <td class="col-4" [textContent]="anaesthesia.pt"> </td>
                                                </tr>
                                                <tr *ngIf=" anaesthesia && anaesthesia.wbc">
                                                    <td class="col-8">WBC</td>
                                                    <td class="col-4" [textContent]="anaesthesia.wbc"> </td>
                                                </tr>
                                                <tr *ngIf=" anaesthesia && anaesthesia.hbsag">
                                                    <td class="col-8">HbSAg</td>
                                                    <td class="col-4" [textContent]="anaesthesia.hbsag"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.plbs">
                                                    <td class="col-8">PLBS</td>
                                                    <td class="col-4" [textContent]="anaesthesia.plbs"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.bun">
                                                    <td class="col-8">BUN</td>
                                                    <td class="col-4" [textContent]="anaesthesia.bun"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.kplus">
                                                    <td class="col-8">K+</td>
                                                    <td class="col-4" [textContent]="anaesthesia.kplus"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.sgpt">
                                                    <td class="col-8">SGPT</td>
                                                    <td class="col-4" [textContent]="anaesthesia.sgpt"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.aptt">
                                                    <td class="col-8">APTT</td>
                                                    <td class="col-4" [textContent]="anaesthesia.aptt"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.plat">
                                                    <td class="col-8">Plat</td>
                                                    <td class="col-4" [textContent]="anaesthesia.plat"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.hcv">
                                                    <td class="col-8">HCV</td>
                                                    <td class="col-4" [textContent]="anaesthesia.hcv"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.rbs">
                                                    <td class="col-8">RBS</td>
                                                    <td class="col-4" [textContent]="anaesthesia.rbs"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.bdUrea">
                                                    <td class="col-8">Bd Urea</td>
                                                    <td class="col-4" [textContent]="anaesthesia.bdUrea"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.clMinus">
                                                    <td class="col-8">Cl-</td>
                                                    <td class="col-4" [textContent]="anaesthesia.clMinus"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.ldh">
                                                    <td class="col-8">LDH</td>
                                                    <td class="col-4" [textContent]="anaesthesia.ldh"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.inr">
                                                    <td class="col-8">INR</td>
                                                    <td class="col-4" [textContent]="anaesthesia.inr"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia && anaesthesia.lastothers">
                                                    <td class="col-8">Others</td>
                                                    <td class="col-4" [textContent]="anaesthesia.lastothers"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="dashboard-box dashboard-table-box" #anaesthesiarecordPostopSection *ngIf="anaesthesiaRecord && anaesthesiaRecord !== null">
                            <h4 class="dashboard-title">Anaesthesia Record (Post OP) </h4>
                            <div class="dashboard-body">
                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                    <tr *ngIf="anaesthesiaRecord.date">
                                        <td class="col-8">Date</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.date |date:'dd/MM/yyyy'"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.ot">
                                        <td class="col-8">OT</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.ot"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.consent">
                                        <td class="col-8">Consent</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.consent"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.npoStatus">
                                        <td class="col-8">NPO Status (Hrs)</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.npoStatus"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.anaesthesiologist">
                                        <td class="col-8">Anaesthesiologist</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.anaesthesiologist"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.surgeon">
                                        <td class="col-8">Surgeon</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.surgeon"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.surgeryDone">
                                        <td class="col-8">Surgery Done</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.surgeryDone"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.anaesthesiaTechnique">
                                        <td class="col-8">Anaesthesia. Technique</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.anaesthesiaTechnique"> </td>
                                    </tr>
                                    <tr>
                                        <th>Immediate Pre-Op Vitals</th>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.pulseRate">
                                        <td class="col-8">Pulse Rate (Min)</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.pulseRate"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.systolicBP">
                                        <td class="col-8">Systolic BP (Mm Hg)</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.systolicBP"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.diastoticBP">
                                        <td class="col-8">Diastolic BP Mm Hg</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.diastoticBP"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.respiratoryRate">
                                        <td class="col-8">Respiratory Rate (Min)</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.respiratoryRate"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.spo2">
                                        <td class="col-8">SPO2 (%)</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.spo2"> </td>
                                    </tr>
                                    <tr>
                                        <th>Time Tracking</th>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.anaesthesiaStartedTime">
                                        <td class="col-8">Anaesthesia Started Time</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.anaesthesiaStartedTime"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.completedTime">
                                        <td class="col-8">Completed Time</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.completedTime"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.surgeryStartedTime">
                                        <td class="col-8">Surgery Started Time</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.surgeryStartedTime"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.scompletedTime">
                                        <td class="col-8">S Completed Time</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.scompletedTime"> </td>
                                    </tr>
                                    <tr>
                                        <th>GA Details</th>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.lma">
                                        <td class="col-8">LMA</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.lma"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.size">
                                        <td class="col-8">Size</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.size"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.cufDtls">
                                        <td class="col-8">Cuf Dtls</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.cufDtls"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.ettSize">
                                        <td class="col-8">ETT Size</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.ettSize"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.noofAttempts">
                                        <td class="col-8">No Of Attempts</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.noofAttempts"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.difficultIntubation">
                                        <td class="col-8">Difficult Intubation</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.difficultIntubation"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.clGroup">
                                        <td class="col-8">CL Group</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.clGroup"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.vls">
                                        <td class="col-8">VLS</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.vls"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.sellicksManoeuvre">
                                        <td class="col-8">Sellicks Manoeuvre</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.sellicksManoeuvre"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.oxygen">
                                        <td class="col-8">Oxygen (L/Min)</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.oxygen"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.n2o">
                                        <td class="col-8">N2O (L/Min)</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.n2o"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.air">
                                        <td class="col-8">Air (L/Min)</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.air"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.inhalationalAgent">
                                        <td class="col-8">Inhalational Agent</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.inhalationalAgent"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.ventilation">
                                        <td class="col-8">Ventilation</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.ventilation"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.vt">
                                        <td class="col-8">VT</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.vt"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.rr">
                                        <td class="col-8">RR</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.rr"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.awp">
                                        <td class="col-8">AWP</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.awp"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.peep">
                                        <td class="col-8">PEEP</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.peep"> </td>
                                    </tr>
                                    <tr><th>Regional Anaesthesia DTL</th></tr>
                                    <tr *ngIf="anaesthesiaRecord.regionalAnesthesia">
                                        <td class="col-8">Regional Anesthesia</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.regionalAnesthesia"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.tipg">
                                        <td class="col-8">Tip / G</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.tipg"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.positionInsert">
                                        <td class="col-8">Position Insert / Inj.</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.positionInsert"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.spacelorsa">
                                        <td class="col-8">Space LORS / A</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.spacelorsa"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.time">
                                        <td class="col-8">Time</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.time"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.drug">
                                        <td class="col-8">Drug</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.drug"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.vdose">
                                        <td class="col-8">V / Dose</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.vdose"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.onset">
                                        <td class="col-8">Onset</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.onset"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.dermLevel">
                                        <td class="col-8">Derm. Level</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.dermLevel"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.motorBlock">
                                        <td class="col-8">Motor Block</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.motorBlock"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.paraesthesiaInsert">
                                        <td class="col-8">Paraesthesia Insert / Inj.</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.paraesthesiaInsert"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.btoodTap">
                                        <td class="col-8">Btood Tap</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.btoodTap"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.dp">
                                        <td class="col-8">DP</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.dp"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.comments">
                                        <td class="col-8">Comments</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.comments"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.topups">
                                        <td class="col-8">Topups</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.topups"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.patientPosition">
                                        <td class="col-8">Patient Position</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.patientPosition"> </td>
                                    </tr>
                                    <tr><th>Monitoring</th></tr>
                                    <tr>
                                        <td>
                                            <div class="row">
                                                <div class="col-12">
                                                    <h6 class="font-18 mt-0 mb-2"></h6>
                                                    <ul class="dashboard-ul row">
                                                        <li *ngFor="let item of anaesthesiaRecord.monitoring" class="col-xl-3" [textContent]="item"></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr><th>Intake Output Details </th></tr>
                                    <tr><th>Intake </th></tr>
                                    <tr *ngIf="anaesthesiaRecord.crystal">
                                        <td class="col-8">Crystal (Ml)</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.crystal"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.colloids">
                                        <td class="col-8">Colloids (Ml)</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.colloids"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.bllod">
                                        <td class="col-8">Bllod (Ml)</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.bllod"> </td>
                                    </tr>
                                    <tr><th>Output</th></tr>
                                    <tr *ngIf="anaesthesiaRecord.estimatedBloodLoss">
                                        <td class="col-8">Estimated Blood Loss (Ml)</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.estimatedBloodLoss"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.urine">
                                        <td class="col-8">Urine (Ml)</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.urine"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.ascitis">
                                        <td class="col-8">Ascitis (Ml)</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.ascitis"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.rylesTubeAspiration">
                                        <td class="col-8">Ryles Tube Aspiration (Ml)</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.rylesTubeAspiration"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.recovery">
                                        <td class="col-8">Recovery</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.recovery"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.status">
                                        <td class="col-8">Status</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.status"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.reflwxesMuscleTone">
                                        <td class="col-8">Reflwxes / Muscle Tone</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.reflwxesMuscleTone"> </td>
                                    </tr>
                                    <tr><th>Vitals Before Shifting</th></tr>
                                    <tr *ngIf="anaesthesiaRecord.pr">
                                        <td class="col-8">PR (Min)</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.pr"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.sysbp">
                                        <td class="col-8">Sys BP (Mm Hg)</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.sysbp"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.dbp">
                                        <td class="col-8">D BP (Mm Hg)</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.dbp"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.spo">
                                        <td class="col-8">SpO2</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.spo"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.vas">
                                        <td class="col-8">VAS</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.vas"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.shiftedTo">
                                        <td class="col-8">Shifted To</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.shiftedTo"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.electiveVentilation">
                                        <td class="col-8">Elective Ventilation</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.electiveVentilation"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.anyAdverseEvents">
                                        <td class="col-8">Any Adverse Events</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.anyAdverseEvents"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.totalIntakeOutput">
                                        <td class="col-8">Total Intake Output (Ml)</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.totalIntakeOutput"> </td>
                                    </tr>
                                    <tr><th>Continue Vitals Monitoring During Procedure</th></tr>
                                    <tr *ngIf="anaesthesiaRecord.continuevitalsdate">
                                        <td class="col-8">Date</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.continuevitalsdate|date:'dd/MM/yyyy'"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.continuevitalstime">
                                        <td class="col-8">Time</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.continuevitalstime"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.continuevitalsspo2">
                                        <td class="col-8">SpO2</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.continuevitalsspo2"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.etCo2">
                                        <td class="col-8">EtCo2</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.etCo2"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.fio2">
                                        <td class="col-8">FiO2</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.fio2"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.inhalionalagent">
                                        <td class="col-8">Inhalional Agent</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.inhalionalagent"> </td>
                                    </tr>
                                    <tr *ngIf="anaesthesiaRecord.temperature">
                                        <td class="col-8">Temperature (Fh)</td>
                                        <td class="col-4" [textContent]="anaesthesiaRecord.temperature"> </td>
                                    </tr>

                                </table>
                                <div>

                                    <table class="table">
                                        <th> Vitals</th>
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>SPO2</th>
                                                <th>ETCO2</th>
                                                <th>FIO2</th>
                                                <th>Inhalional Agent</th>
                                                <th>Temperature</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of anaesthesiaRecord.vitalshistory">
                                                <td>
                                                    <span>{{item.vitalsdate |date:'dd/MM/yyyy'}}</span>
                                                </td>
                                                <td>
                                                    <span>{{item.vitalstime }}</span>
                                                </td>
                                                <td>
                                                    <span>{{item.vitalsSPO2 }}</span>
                                                </td>
                                                <td>
                                                    <span>{{item.vitalsETCO2 }}</span>
                                                </td>
                                                <td>
                                                    <span>{{item.vitalsFIO2 }}</span>
                                                </td>
                                                <td>
                                                    <span>{{item.vitalsInhalionalagent }}</span>
                                                </td>
                                                <td>
                                                    <span>{{item.vitalsTemperature}}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div *ngIf="isFooter">
                        <banner-setting [reportName]='"Pediattric FullTranscript Footer"'></banner-setting>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
