/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, OnInit, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from "@angular/core";
import { Page, IUserAccount, IResource } from "../../models";
import { takeUntil, finalize } from "rxjs/operators";
import { LabBillHeaderModel, LabMainDetailCharge, PayTypes } from "../../../areas/admin/labs/pages/models";
import { ApiResources } from "../../helpers";
import { AppData, HttpService, PrintOptionService, ResourceService } from "../../services";
import { Setting } from "../../entities";
import { PackageModuleModel } from "../../../areas/admin/masters/pages/package-modules/models";
import moment from "moment";
import * as numberToWords from 'number-to-words';

@Component({
    templateUrl: "./new-lab-invoice.html",
    selector: "new-lab-invoice",
    styleUrls:["./new-lab-invoice.css"],
    encapsulation: ViewEncapsulation.None
})

export class NewLabInvoiceWidget implements OnInit, OnDestroy {
    @Input() newLabBookingHeaderId: number;
    @Input() encryptedNewLabBookingHeaderId: string;
    @Input() showFinanceandPrintByDetails: boolean;
    @Output() onClose = new EventEmitter<any>();
    @Input() isNewlabinvoiceId: boolean;
    @Input() isPrintLogo = true;
    @Input() isFooter: boolean;
    page: Page;
    practiceLocations:Array<IResource>
    loading: boolean;
    QrCode: string = null;
    currentDate: Date;
    bill: Array<LabBillHeaderModel>;
    paidAmount: number;
    payTypeName: string;
    total: number;
    amountInWord: string;
    isShowTestDurationTime: boolean;
    packageModules: PackageModuleModel[];
    selectedPackage: PackageModuleModel;
    totalPackageAmount: number;
    packageAmount: number;
    referenceIds: string;
    yearsfordisplay: number;
    monthsfordisplay: number;
    daysfordisplay: number;
    relationType: string;
    payModes: Array<PayTypes>;
    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
        private readonly resourceService: ResourceService,
    ) {
        this.page = new Page();
        //this.age = new Age();
        this.practiceLocations = new Array<IResource>();
        this.bill = new Array<LabBillHeaderModel>();
        this.currentDate = new Date();
        this.packageModules = new Array<PackageModuleModel>();
        this.isPrintLogo = true;
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchInvoice();
                    this.fetchPracticeLocation();
                    this.fetchCommonSettings()
                    this.printOptionService.get((is) => { this.isPrintLogo = is; });
                } else {
                    this.page.userAccount = undefined;
                    this.fetchInvoice();
                    this.printOptionService.get((is) => { this.isPrintLogo = is; });
                }
            });
    }
    private fetchPracticeLocation() {
        this.resourceService.fetchPracticeLocations()
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<IResource>) => {
                this.practiceLocations = response;
            });
    }
    ngOnDestroy() {
        this.page.unsubscribeAll();
    }

    onCloseModal() {
        this.onClose.emit();
    }

    private fetchInvoice() {
        const request = {
            encryptedNewLabBookingHeaderId: this.encryptedNewLabBookingHeaderId
        };
        if (this.showFinanceandPrintByDetails == null && this.showFinanceandPrintByDetails == undefined) {
            this.showFinanceandPrintByDetails == false;
        }
        this.loading = true;
        this.httpService.post(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchSelectedInvoiceLabData), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<LabBillHeaderModel>) => {
                    this.bill = response;                   
                    this.payModes = response[0].payTypes;
                    this.total=0;
                    this.bill.forEach((item) => {
                        this.total = this.total + item.overallNetAmount;
                        
                        if (item.relation == "Husband") {
                            this.relationType = "W/O";
                        } else if (item.relation == "Wife") {
                            this.relationType = "H/O";
                        } else if (item.relation == "Father" && item.gender == "M") {
                            this.relationType = "S/O";
                        } else if (item.relation == "Father" && item.gender == "F") {
                            this.relationType = "D/O";

                        } else if (item.relation == "Sister" && item.gender == "F") {
                            this.relationType = "Sis/O";
                        } else if (item.relation == "Sister" && item.gender == "M") {
                            this.relationType = "B/O";
                        } else if (item.relation == "Brother" && item.gender == "F") {
                            this.relationType = "Sis/O";
                        } else if (item.relation == "Brother" && item.gender == "M") {
                            this.relationType = "B/O";
                        } else if (item.relation == "Son" && item.gender == "M") {
                            this.relationType = "F/O";
                        } else if (item.relation == "Son" && item.gender == "F") {
                            this.relationType = "M/O";
                        } else if (item.relation == "Daughter" && item.gender == "M") {
                            this.relationType = "F/O";
                        } else if (item.relation == "Daughter" && item.gender == "F") {
                            this.relationType = "M/O";
                        }
                        if (item.dateOfBirth != null) {
                            let mdob = moment(String(item.dateOfBirth), "YYYY-MM-DD");
                            let age = moment().diff(mdob, "months");
                            let yrs = (age / 12).toFixed(1);
                            this.yearsfordisplay = parseInt(yrs, 10);
                            let months = age % 12;
                            let days = moment().diff(mdob.add(yrs, 'years'), 'days', false);
                            this.yearsfordisplay = days;
                            this.yearsfordisplay = parseInt(yrs);
                            this.monthsfordisplay = months;
                            let date1 = moment(String(item.dateOfBirth), "YYYY-MM-DD");
                            let monthsa = moment().diff(date1, 'months');
                            date1.add(monthsa, 'months');
                            let daysa = moment().diff(date1, 'days');
                            monthsa + ' ' + 'Month(s)' + ' ' + daysa + " Day(s)"
                            this.daysfordisplay = daysa;
                            item.patientAge = item.dateOfBirth ? this.yearsfordisplay + 'Y ' + this.monthsfordisplay + 'M ' + this.daysfordisplay + 'D' : null;
                        }
                       this. paidAmount=item.paidAmount
                        this.payTypeName=item.payTypeName
                    })
                    let amountInWords = numberToWords.toWords(this.total);
                    this.amountInWord = amountInWords + ' rupees only.';
                    if (this.bill[0].packageModuleId > 0) {
                        this.fetchLabPackages();
                    } else {
                        this.selectedPackage = null;
                    }
                    this.QrCode = `${location.origin}${location.pathname}#/new-lab-webreports/${this.bill[0].requisitionNumber}`;
                });
        this.currentDate = new Date();
    }
    private fetchCommonSettings() {
        this.loading = true;
        const request = {
        }
        request["name"] = "Is Show Test Time Duration In Invoice";
        request["type"] = "Lab";

        this.httpService.get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<Setting>) => {
                if (response) {
                    this.isShowTestDurationTime = response[0]?.active;
                }
            });
    }
    fetchLabPackages() {
        var request = {}
        this.httpService
            .post(ApiResources.getURI(ApiResources.packageModule.base, ApiResources.packageModule.labpackages), request)
            .pipe(finalize(() => { }))
            .subscribe((response: Array<PackageModuleModel>) => {
                this.packageModules = response;
                this.selectedPackage = this.packageModules.find(x => x.packageModuleId == this.bill[0].packageModuleId);
                this.selectedPackage?.packageModuleDetails?.forEach((item, i) => {
                    var index = this.bill[0]?.labs.findIndex(x => x.labMainDetailId == item.referenceId);
                    if (index > -1) {
                        this.bill[0].labs[index].isFromPackage = true;
                    }
                    if (i == 0) {
                        this.referenceIds = item.referenceId.toString();
                    } else {
                        this.referenceIds = this.referenceIds + ',' + item.referenceId;
                    }
                })
                this.fetchPackageLabCharges()
            });
    }
    private fetchPackageLabCharges() {
        var request = {
            referenceIds: this.referenceIds
        }
        this.httpService.post(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchLabCharges), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { }))
            .subscribe(
                (response: Array<LabMainDetailCharge>) => {
                    this.totalPackageAmount = 0;
                    this.packageAmount = 0;
                    this.selectedPackage.packageModuleDetails?.forEach((lab) => {
                        var data = response?.find(x => x.referenceId == lab.referenceId && x.default == true)
                        if (data) {
                            this.totalPackageAmount = this.totalPackageAmount + data.amount;
                        }
                    })
                    this.selectedPackage.packageModuleDetails?.forEach((lab) => {
                        var data = response?.find(x => x.referenceId == lab.referenceId && x.default == true)
                        if (this.selectedPackage.discountType == 'P') {
                            data.amount = data.amount - Number(((data?.amount / 100) * this.selectedPackage.discountPercentage).toFixed(2))
                        } else if (this.selectedPackage.discountType == 'A') {
                            var discountPercentage = (this.selectedPackage.discountAmount / this.totalPackageAmount) * 100
                            data.amount = data.amount - Number(((data?.amount / 100) * discountPercentage))
                        }
                        this.packageAmount = this.packageAmount + data.amount;
                    })
                }
            );
    }

}