import { Component, OnDestroy, OnInit } from "@angular/core";
import { finalize, takeUntil } from "rxjs";
import moment from "moment";
import { ApiResources, UtilHelper } from "../../../shared/helpers";
import { Page } from "../../../shared/models";
import { AppData, ExportService, HttpService } from "../../../shared/services";
import { ExportType } from "../../../shared/enums";

export class OPFeedbackModel {
    appointmentNo: string;
    patientName: string;
    patientMobile: string;
    providerName: string;
    specializationName: string;
    appointmentDate: Date;
    patientAge: number;
    locationName: string;
    patientEmail: string;
    patientGender: string;
    patientsType: string;
    umrNO: string;
    alternateNumber: string;
    flag: string;
    
}
class FilterOptions {
    dateFrom: string = null;
    dateTo: string = null;
}
class Filters {
    options: FilterOptions;
    applied: boolean;
    constructor() {
        this.init();
    }
    init() {
        this.options = new FilterOptions();
        this.applied = undefined;
    }
}


@Component({
    templateUrl: "./op-feedback.html"
})

export class OPFeedBack implements OnInit, OnDestroy {
    page: Page;
    loading: boolean;
    records: Array<OPFeedbackModel>;
    recordsExcel: Array<OPFeedbackModel>;
    filters: Filters;
    minDate: string;
    maxDate: string;
    constructor(
        private readonly httpService: HttpService,
        private readonly appData: AppData,
        private readonly exportService: ExportService,
    ) {
        this.loading = true;
        this.page = new Page();
        this.records = new Array<OPFeedbackModel>;
        this.filters = new Filters();
    }
    
    private fetchOPFeedback() {
        const request = Object.assign(UtilHelper.clone(this.filters.options));
        this.loading = true;
        this.httpService.post(ApiResources.getURI(ApiResources.account.base, ApiResources.account.fetchOPFeedback), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loading = false; }))
            .subscribe((response: Array<OPFeedbackModel>) => {
                this.records = response;
               
            }, () => {
                this.records = new Array<OPFeedbackModel>();
            });
    }
    onApplyFilters() {
        this.filters.applied = UtilHelper.isFiltersApplied(this.filters.options);
        this.loading = true;
        this.fetchOPFeedback();
    }

    onResetFilters() {
        this.filters.applied = UtilHelper.isFiltersApplied(this.filters.options);
        this.filters.init();
        this.loading = true;
        this.fetchOPFeedback();
    }
    changeMinDate(value: string) {
        this.maxDate = value;
        this.filters.options.dateTo = value;
    }

    changeMaxDate(value: string) {
        this.minDate = value;
        this.filters.options.dateFrom = value;
    }
    ngOnInit() {
        this.fetchOPFeedback();
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }
    exportToExcel() {

        this.loading = true;

        this.httpService.post(ApiResources.getURI(ApiResources.account.base, ApiResources.account.fetchOPFeedback), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<OPFeedbackModel>) => {
                this.recordsExcel = response;                
                    var exportReportData = JSON.parse(JSON.stringify(this.recordsExcel));
                    var finalData = [];
                    exportReportData.forEach((obj) => {
                        obj.appointmentDate = moment(obj.appointmentDate).format('DD-MM-YYYY');
                        finalData.push(obj);
                    });
                    let finalRow = new OPFeedbackModel() as any;
                    finalRow.appointmentNo = "";
                    finalRow.patientName = "";
                    finalRow.umrNo = "";
                    finalRow.patientMobile = "";
                    finalRow.providerName = "";
                    finalRow.specializationName = "";
                    finalRow.appointmentDate = "";
                    finalRow.patientAge = "";
                    finalRow.locationName = "";
                    finalRow.patientEmail = "";
                    finalRow.patientGender = "";
                    finalRow.patientsType = "";
                    finalRow.alternateNumber = "";
                    finalRow.flag = "";
                    finalData.push(finalRow);
                    const Columns = "appointmentNo as [Apt No],patientName as [Patient Name], umrNo as [UMR NO],patientMobile as [Patient Mobile], providerName as [Provider Name], specializationName as [Specialization],appointmentDate as [Apt Date], patientAge as [Patient Age], locationName as [Location],patientEmail as [Email], patientGender as [Gender], patientsType as [Patient Type],alternateNumber as [Alternate Number], flag as [Flag]";
                    this.exportService.export(ExportType.XLSX, "OP_Feedback_Report", Columns, finalData);
                
            });
    }
}