export class RevenueReport {
    locationId: number;
    createdDate: Date;
    dayWiseRevenue: number;
    dayWiseRefund: number;
    dayWiseCollection: number;
    dayWiseDiscount: number;
    dayWiseNetTotal: number;
    locationRevenue: number;
    locationRefund: number;
    locationCollection: number;
    locationDiscount: number;
    locationPendingTotal: number;
    locationName:string
}