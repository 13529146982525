import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, of } from "rxjs";
import { ApiResources } from "@shared/helpers";
import { IUserAccount, IAuthToken } from "@shared/models";
import * as CryptoJS from "crypto-js";
import { AppData } from "@src/app/app.data";
import { CommunicationService } from "./communication.service";
import { Router } from "@angular/router";
import { MenuService } from "./menu.service";

const secretKey = "hims-admin-application-2024-11-29";
const pathName = location.pathname.replaceAll("/", "");
const storageKey = pathName ? pathName + ".HIMS.Admin.Auth" : "HIMS.Admin.Auth";

export const appUrls = {
    login: "/login",
    home: "/app/dashboard",
    notFound: "/not-found",
    serverError: "/server-error",
    forbidden: "/forbidden"
}

@Injectable()
export class IdentityService {
    constructor(
        private readonly router: Router,
        private readonly http: HttpClient,
        private readonly appData: AppData,
        private readonly modalService: NgbModal,
        private readonly menuService: MenuService,
        private readonly communicationService: CommunicationService
    ) {
    }

    signIn(account: IUserAccount): Observable<boolean> {
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(account), secretKey).toString();
        localStorage.setItem(storageKey, encryptedData);
        return of(true);
    }

    logout(isHardReload: boolean = false): void {
        if (this.modalService.hasOpenModals()) {
            this.modalService.dismissAll();
        }

        localStorage.removeItem(storageKey);
        this.appData.setAccount(null);
        this.menuService.removeFromLocal();
        localStorage.removeItem("ProviderVideoCallingSession");

        try {
            this.communicationService.stopConnection();
        } catch (e) {
            // ignored;
        }

        if (isHardReload) {
            this.router.navigateByUrl(appUrls.login).then(() => {
                location.reload();
            });
        } else {
            this.router.navigateByUrl(appUrls.login);
        }
    }

    isAuthenticated(): boolean {
        const userAccount = this.getUserAccount();
        return Boolean(userAccount?.roleId || 0);
    }

    get(): IUserAccount {
        const userAccount = this.getUserAccount();
        return userAccount;
    }

    update(authToken: IAuthToken): boolean {
        let userAccount = this.getUserAccount();
        if (userAccount?.accountId == 0) {
            return false;
        }

        userAccount.token = authToken.token;
        userAccount.referenceToken = authToken.referenceToken;
        userAccount.expires = authToken.expires;
        userAccount.allowVideoCall = authToken.allowVideoCall;
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(userAccount), secretKey).toString();
        localStorage.setItem(storageKey, encryptedData);
        return true;
    }

    refreshToken(reference: string): Observable<IAuthToken> {
        const request = { token: reference };
        const apiEndPoint = ApiResources.getURI(ApiResources.account.base, ApiResources.account.refresh);
        return this.http.put<IAuthToken>(apiEndPoint, request);
    }

    private getUserAccount(): IUserAccount {
        try {
            const encryptedData = localStorage.getItem(storageKey);
            if (encryptedData) {
                const userAccount = JSON.parse(CryptoJS.AES.decrypt(encryptedData, secretKey).toString(CryptoJS.enc.Utf8));
                return userAccount;
            }
        }
        catch {
            // ignored;
        }

        return null;
    }
}