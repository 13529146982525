<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <table width="100%" class="heading1">
                <tr *ngIf="isPrintLogo">
                    <td colspan="4" align="center">
                        <banner-setting [reportName]='"Booking Scan Invoice Header"'></banner-setting>
                        <hr class="border-dark">
                    </td>
                </tr>
                <tr *ngIf="!isPrintLogo">
                    <td colspan="4">
                        <h3 class="text-center">Final Bill of Supply - Diagnostics Bill</h3>
                        <h5 class="text-center">(Health Care Service - SAC: 999316)</h5>
                        <hr class="border-dark">
                    </td>
                </tr>
                <tr>
                    <th class="headingDesign">
                        Patient Name
                    </th>
                    <td class="dataDesign">
                        <span class="colonMargin">: </span>
                        <span class="text-uppercase" [textContent]="selectedAppointment.patientName"></span>
                    </td>
                    <th class="headingDesign">
                        MR No
                    </th>
                    <td class="dataDesign">
                        <span class="colonMargin">: </span>
                        <span [textContent]="selectedAppointment.umrno || '---'"></span>
                    </td>
                </tr>
                <tr>
                    <th class="headingDesign">
                        {{relationType ? relationType : 'Relative Name'}}
                    </th>
                    <td class="dataDesign">
                        <span class="colonMargin">: </span>
                        <span class="text-uppercase" [textContent]="selectedAppointment.relativeName"></span>
                    </td>
                    <th class="headingDesign">
                        <span>Bill Date</span>
                    </th>
                    <td class="dataDesign">
                        <span class="colonMargin">: </span>
                        <span [textContent]="selectedAppointment.createdDate | date:'dd-MM-yyyy, h:mm a'"></span>
                    </td>
                </tr>
                <tr class="heading2">
                    <th class="headingDesign">
                        <span>Bill No</span>
                    </th>
                    <td class="dataDesign">
                        <span class="colonMargin">: </span>
                        <span [textContent]="selectedAppointment.billNumber"></span>
                    </td>
                    <th class="headingDesign">
                        Age/Gender
                    </th>
                    <td class="dataDesign">
                        <span class="colonMargin">: </span>
                        <span *ngIf="selectedAppointment.ageInYMD" [textContent]="selectedAppointment.ageInYMD"></span>
                        <span *ngIf="selectedAppointment.gender" [textContent]="selectedAppointment.ageInYMD ? ' / ' + selectedAppointment.gender : selectedAppointment.gender"></span>
                    </td>
                </tr>
                <tr>
                    <th class="headingDesign">
                        Doctor Name
                    </th>
                    <td class="dataDesign">
                        <span class="colonMargin">: </span>
                        <span class="text-uppercase" [textContent]="selectedAppointment.providerName"></span>
                    </td>
                    <th class="headingDesign">
                        Requisition No
                    </th>
                    <td class="dataDesign">
                        <span class="colonMargin">: </span>
                        <span [textContent]="selectedAppointment.requisitionNumber"></span>
                    </td>
                </tr>
                <tr>
                    <th class="headingDesign">
                        Mobile No
                    </th>
                    <td class="dataDesign">
                        <span class="colonMargin">: </span>
                        <span [textContent]="selectedAppointment.mobile"></span>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <hr class="border-dark">
    <div class="d-flex" style="margin-top:-20px">
        <div class="col-1"> <h5>S.No</h5> </div>
        <div class="col-2"> <h5>Dept Name</h5></div>
        <div class="col-5"> <h5>Investigations</h5></div>
        <div class="col-2"> <h5>Emergency</h5></div>
        <div class="col-2"> <h5>Amount (Rs)</h5></div>
    </div>
    <hr class="border-dark" style="margin-top:-1px">
    <div style="margin-top:-20px">
        <div class="d-flex" style="margin-bottom:10px">
            <div class="col-1" [textContent]="'1'"></div>
            <div class="col-2 break-all" [textContent]=" selectedAppointment.machineName || '--'"></div>
            <div class="col-5 break-all" [textContent]="selectedAppointment.scanTestName"></div>
            <div class="col-2" [textContent]="''"></div>
            <div class="col-2 break-all" [textContent]="selectedAppointment.scanAmount || 0 | currency :'INR'"></div>
        </div>
    </div>
    <hr class="border-dark" style="margin-top:-2px">
    <div>
        <div class="d-flex" style="margin-left:-25px">
            <div class="col-7 d-flex">
                <div class="col-3">
                    Rupees
                </div>
                <span>: </span>
                <div class="col-9" *ngIf="paidAmountInWords">
                    <b>{{paidAmountInWords}} rupees only</b>
                </div>
            </div>
            <div class="col-5 d-flex">
                <div class="col-8">
                    Total Amount Rs
                </div>
                <span>: </span>
                <div class="col-4">
                    {{selectedAppointment.scanAmount || 0 | currency:'INR' }}
                </div>
            </div>
        </div>
        <div class="d-flex" style="margin-left:-25px">
            <div class="col-7 d-flex">
                <div class="col-3">
                    Pay Mode
                </div>
                <span>: </span>
                <div class="col-9">
                    <b>{{selectedAppointment.payTypeName}}</b>
                </div>
            </div>
        </div>
        <div class="d-flex" style="margin-left:-25px">
            <div class="col-7 d-flex">
                <div class="col-3">
                    Diagnosis
                </div>
                <span>: </span>
                <div class="col-9">
                    <b>SCAN</b>
                </div>
            </div>
            <div class="col-5 d-flex">
                <div class="col-8">
                    Paid Amount Rs
                </div>
                <span>: </span>
                <div class="col-4">
                    {{selectedAppointment.paidAmount || 0 | currency:'INR' }}
                </div>
            </div>
        </div>
        <div class="d-flex">
            <div class="col-2">
                <div class="flex-grow-1 text-left">
                    <qrcode qrdata="'www.fernandez.com'" [width]="100" [errorCorrectionLevel]="'M'" [elementType]="'img'"></qrcode>
                </div>
            </div>
            <div class="col-10">
                <div>
                    <span>
                        Note: This is a computer-generated document. No signature is required.
                    </span>
                </div>
                <div class="d-flex">
                    <span>
                        <b>
                            You can check your reports(Only Scan) online at <a href="https://www.fernandezhospital.com/">www.fernandezhospital.com</a> and click the Scan reports tab.
                        </b>
                    </span>
                </div>
                <div class="d-flex col-10">
                    <div class="col-6">
                        User Name: <b>3552604</b>
                    </div>
                    <div class="col-6">
                        Password: <b>YER3578935</b>
                    </div>
                </div>
                <div>
                    <span><b>NOW OPEN! Fernandez's new Outpatient Clinic at Necklace Road, Sec’bad. Call 18004191397 for Appointments</b></span>
                </div>
                <div>
                    <span>
                        <b>Please scan the QR code to leave a feedback about our services.</b>
                    </span>
                </div>
            </div>
        </div>
        <div class="d-flex" style="margin-top:20px">
            <div class="col-8 d-flex">
                <div class="text-left">
                    Printed By : {{page.userAccount?.fullName}}
                </div>
            </div>
            <div class="col-4 d-flex">
                <div class="text-right">
                    Printed Date : {{currentDate | date:'dd/MM/yyyy, h:mm a'}}
                </div>
            </div>
        </div>
    </div>
    <hr class="border-dark" style="margin-top:-2px">
    <div class="col-12" *ngIf="isFooter">
        <banner-setting [reportName]='"Booking Scan Invoice Footer"'> </banner-setting>
    </div>
</div>
