import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AutoResume, DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { AppData, IdentityService, appUrls } from "@shared/services";
import { ProgressBarModalComponent } from "@shared/components/progress-bar-modal.component";

@Component({
    selector: "idle-timeout",
    template: ""
})
export class IdleComponent implements OnDestroy {
    timeoutIn = 60;
    modalRef: NgbModalRef;
    idleTimeout: any;
    idleStart: any;

    constructor(
        private readonly idle: Idle,
        private readonly router: Router,
        private readonly appData: AppData,
        private readonly identityService: IdentityService,
        private readonly modalService: NgbModal
    ) {
        idle.setIdle(1200);
        idle.setTimeout(this.timeoutIn);
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        idle.setAutoResume(AutoResume.notIdle);

        this.idle.onTimeout.subscribe(() => {
            this.modalRef.close("logout");
        });

        this.idle.onIdleStart.subscribe(() => {
            if (this.modalService.hasOpenModals()) {
                this.modalService.dismissAll();
            }
            this.openModal();
        });

        this.idle.onTimeoutWarning.subscribe((countdown: number) => {
            this.modalRef.componentInstance.countdown = countdown;
        });

        this.idle.watch();
    }

    openModal() {
        this.modalRef = this.modalService.open(ProgressBarModalComponent, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            windowClass: "custom-modal effect-scale idle-timeout"
        });

        this.modalRef.componentInstance.countdown = this.timeoutIn;
        this.modalRef.result.then((result: any) => {
            if (result === "logout") {
                this.identityService.logout();
            }

            if (result === "stay") {
                this.idle.watch();
            }
        });
    }

    ngOnDestroy() {
        this.idle.stop();
        this.idle.onTimeout.observers.length = 0;
        this.idle.onIdleStart.observers.length = 0;
        this.idle.onTimeoutWarning.observers.length = 0;

        try {
            this.modalService.dismissAll();
        } catch (e) {
            // ignored;
        }
    }
}