
import { HttpService } from '../services/http.service';
import { NotifyService } from '../services/notify.service';
import { ApiResources } from "../helpers";
import { Setting } from "@shared/entities";

export class WhatsAppService {
    isActive: boolean =  null;
    constructor() {

    }


    getCommonSettings(httpService: HttpService) {

        httpService.get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), { name: "WhatsAppMsgService" })

            .subscribe(async (response: Array<Setting>) => {
                if (response.length > 0) {
                    this.isActive =await response[0].active;
                    
                }
            }, (error: any) => { console.log(error) });

        return this.isActive;

    }

    sendWhatsAppMessageAsync(body: Record<any, any>, httpService: HttpService, notifyService: NotifyService, endPoint: string = "SendMessage") {

        if (this.isActive == null) {
            this.getCommonSettings(httpService);
        }
        else if (this.isActive == true) {

            const settings = JSON.parse(localStorage.getItem("settings"));
            var url = settings.whatsAppService.base + endPoint;
            var contactNumber = settings.whatsAppService.contactNumber;
            var foundationName = settings.whatsAppService.practiceName;

            body['FoundationName'] = foundationName;
            body['PracticeName'] = foundationName;
            body["ContactNumber"] = contactNumber;

            httpService.post(url, body).subscribe((response: any) => {
                notifyService.successToast("The whatsapp message has sent !!")
            },
                (error: any) => {
                    notifyService.errorToast("Could not send whatsapp message" + error); console.log(error)
                });
        }


    }


}