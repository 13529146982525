export class WebAlerts {
    comments: string;
    alertTypeCode: string;
    roleDepartmentId: number;
    roleDepartmentName: string;
    isActionRequired: boolean;
    alertDate: Date;
    webAlertId: number;
    isActionCompleted: boolean;
    loading = false;
    acceptedBy: number;
    acceptedByName: string;
    acceptedDate: Date;
    clearedDate: Date;
    referenceId?: number;
    createdBy?: number;
    createdByName?: string;
    roleId: number;
    roleName?: string;
    poNumber: string;
}