import { OnInit, OnDestroy, Component, ViewEncapsulation, TemplateRef } from "@angular/core";
import { takeUntil, finalize } from "rxjs/operators";
import { IUserAccount, Page,  IResource } from "../../../../../shared/models";
import { AppData } from "../../../../../app.data";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Cubicle } from "../../../../../shared/entities/cubicle";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { ApiResources, UtilHelper } from "../../../../../shared/helpers";
import { HttpService, NotifyService, ResourceService } from "../../../../../shared/services";
import Swal from "sweetalert2";
import { HttpErrorResponse } from "@angular/common/http";
import { DischargeConfiguration } from "../../../../../shared/entities";

@Component({
    templateUrl: "./discharge-configuration.html",
    styleUrls: ["./discharge-configuration.css"],
    encapsulation: ViewEncapsulation.None
})

export class DischargeConfigurationPage implements OnInit, OnDestroy {
    page: Page;
    modalRef: NgbModalRef;
    submitting: boolean;
    submitted: boolean;
    dischargeAlerts: Array<DischargeConfiguration>;
    loading: boolean;
    dischargeConfigurationForm: FormGroup;
    request: any;
    loadingstatus: boolean;
    activeStatus: Array<IResource>;
    locations: Array<IResource>;

    loadingRoleDepartment: boolean;
    roleDepartment: Array<IResource>;
    dischargeCount: number;

    constructor(
        private readonly appData: AppData,
        private readonly formBuilder: FormBuilder,
        private readonly httpService: HttpService,
        private readonly modalService: NgbModal,
        private readonly notifyService: NotifyService,
        private readonly resourceService: ResourceService) {
        this.loading = true;
        this.page = new Page();
        this.loading = true;
        this.dischargeAlerts = new Array<DischargeConfiguration>;
    }

   
    private fetchRoleDepartment() {
        this.loadingRoleDepartment = true;
        this.resourceService.fetchRoleDepartment()
            .pipe(finalize(() => { this.loadingRoleDepartment = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                if (response.length > 0) {
                    this.roleDepartment = response;
                    this.dischargeAlerts.forEach((item: DischargeConfiguration) => {
                        var index = this.roleDepartment.findIndex(s => s.id == item.roleDepartmentId);
                        this.roleDepartment.splice(index,1)
                    })                  
                }

            });
    }

    cancelPriorities() {
        this.fetchDischargeAlerts();
    }
    onChangePriority(isUp: boolean, item: DischargeConfiguration) {
        let newPriority = isUp ? item.priority + 1 : item.priority - 1
        if (newPriority <= 0) {
            this.notifyService.warning("Priority must be greater than or equal to 1");
            return;
        }
        item.priority = newPriority
    }

    onChangePriorities() {
        this.submitting = true;
        this.dischargeAlerts.sort((a, b) => a.priority - b.priority);
        let nextPriority = 0;
        let next = 0;
        for (const item of this.dischargeAlerts) {
            if (item.isSkip) {
                next = item.priority;
                continue;
            }  
            else if (item.priority === next) {
                if (next != nextPriority) {
                    ++nextPriority;
                }
                item.priority = nextPriority;
                continue;
            }
            else if (item.priority !== nextPriority) {
                next = item.priority;
                item.priority = ++nextPriority;
            }        
        }
        this.httpService.post(ApiResources.getURI(ApiResources.dischargeAlert.base, ApiResources.dischargeAlert.modifyPriorities), this.dischargeAlerts)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.submitting = false;
                this.submitted = false;
            }))
            .subscribe({
                next: (response: number) => {
                    if (response > 0) {
                        this.fetchDischargeAlerts();
                        this.notifyService.successToast("Updated Successfully");
                    }
                },
                error:(error: HttpErrorResponse) => {
                    const errorMessage = UtilHelper.handleError(error);
                    if(errorMessage) {
                       this.notifyService.warning(errorMessage);
                    } else {
                       this.notifyService.defaultError();
                    }
                }
            }
        );
    }




    fetchDischargeAlerts() {
        const request = {};
        this.httpService.post<Array<DischargeConfiguration>>(ApiResources.getURI(ApiResources.dischargeAlert.base, ApiResources.dischargeAlert.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<DischargeConfiguration>) => {
                if (response.length > 0) {
                    this.dischargeAlerts = response;
                    this.dischargeCount = response.length + 1;
                }
                else {
                    this.dischargeCount = 1;
                }  
            });
    }




    onSubmit() {

        this.submitted = true;
        if (!this.dischargeConfigurationForm.valid) {
            return;
        }

        this.submitting = true;
        const request = Object.assign(UtilHelper.clone(this.dischargeConfigurationForm.getRawValue()));
        request["createdByName"] = this.page.userAccount.fullName;
        request.createdBy = this.page.userAccount.accountId;
        request.createdByName = this.page.userAccount.fullName;
     
        this.httpService.post(ApiResources.getURI(ApiResources.dischargeAlert.base, ApiResources.dischargeAlert.add), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.submitting = false;
                this.submitted = false;
            }))
            .subscribe(() => {
                this.onCloseModal();
                this.fetchDischargeAlerts();
                this.notifyService.successToast("Discharge Alert Added successfully.");
            }, (error: HttpErrorResponse) => {
                const errorMessage = UtilHelper.handleError(error);
                if (errorMessage) {
                    this.notifyService.warning(errorMessage);
                } else {
                    this.notifyService.defaultError();
                }
            });
    }



    private buildForm() {
        this.dischargeConfigurationForm = this.formBuilder.group({
            readyToDischargeAlertOrderId: [null],
            roleDepartmentId: [null],
            priority: [null],
            isBase: [null],
            isGetNotifiedOnRecords: [null],
            isSkip: [null],
        });
    }
    onOpenModel(content: TemplateRef<any>, record?: Cubicle) {
        this.fetchRoleDepartment();
        this.buildForm();
        if (this.dischargeCount) {
            this.dischargeConfigurationForm.patchValue({
                priority: this.dischargeCount
            })
        }
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            windowClass: "custom-modal effect-scale"
        });
    }
    get form() { return this.dischargeConfigurationForm.controls; }
    onCloseModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
        } catch (e) {
            // ignored;
        }

        this.loading = false;
        this.submitting = undefined;
        this.submitted = undefined;
    }

    ngOnDestroy() {
        this.onCloseModal();
        this.page.unsubscribeAll();
        Swal.close();
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.loading = true;
                    this.fetchDischargeAlerts();
                    
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    changeNotifyStatus(record: DischargeConfiguration, type: string) {
        const request = {
            type: type,
            readyToDischargeAlertOrderId: record.readyToDischargeAlertOrderId,
            isBase: record.isBase,
            isGetNotifiedOnRecords: record.isGetNotifiedOnRecords,
            isSkip: record.isSkip
        }
        this.httpService.post(ApiResources.getURI(ApiResources.dischargeAlert.base, ApiResources.dischargeAlert.modifyStatus), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.submitting = false;
                this.submitted = false;
            }))
            .subscribe((response: number) => {
                if (response > 0) {
                    this.fetchDischargeAlerts();
                }
            }, (error: HttpErrorResponse) => {
                const errorMessage = UtilHelper.handleError(error);
                if (errorMessage) {
                    this.notifyService.warning(errorMessage);
                } else {
                    this.notifyService.defaultError();
                }
            });

    }



    changeStatus(record: DischargeConfiguration, type: string, index: number) {
        const request = {
            type: type,
            readyToDischargeAlertOrderId: record.readyToDischargeAlertOrderId,
            isBase: record.isBase,
            isGetNotifiedOnRecords: record.isGetNotifiedOnRecords,
            isSkip: record.isSkip
        }
        if (!record.isSkip) {
            this.dischargeAlerts[index].isSkip = !record.isSkip;
        }
        this.httpService.post(ApiResources.getURI(ApiResources.dischargeAlert.base, ApiResources.dischargeAlert.modifyStatus), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.submitting = false;
                this.submitted = false;
            }))
            .subscribe({
                next: (response: number) => {
                    if (response > 0) {
                        const priorityExists = this.dischargeAlerts.some(alert => alert.priority === record.priority && !alert.isSkip);
                        (!priorityExists) ? this.onChangePriorities() : this.fetchDischargeAlerts();
                    }
            }, error:(error: HttpErrorResponse) => {
                const errorMessage = UtilHelper.handleError(error);
                if (errorMessage) {
                    this.notifyService.warning(errorMessage);
                } else {
                    this.notifyService.defaultError();
                }
            }
        });
    }
}