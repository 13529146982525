<div id="obprintContainer" class="  mt-1 row scroll-obsecNotHeading text-black">
    <div class="col-12">
        <div>
            <div class="col-12 p-0">
                <div>
                    <button class="btn btn-info" type="button" (click)="onChangePrintType( false)">
                        <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangePrintType(true)">
                        <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangeFooter( false)">
                        <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangeFooter(true)">
                        <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
                    </button>
                </div>
                <div class="align-items-start border border-bottom-0 d-flex pl-1 pr-1 pt-1" style="background-color: #edfaf7;">
                    <div class="pl-0 col-11 d-flex">
                        <div class="d-flex flex-wrap">
                            <div *ngIf="ancCard && ancCard !=null">
                                <input type="checkbox" checked (click)="onChangeStatus('a',$event)">
                                <label class="ml-1 mr-2">Patient History</label>
                            </div>
                            <div *ngIf="!isTeleconsulatantReport && (measure && measure!=null) && (antinantalRisk && antinantalRisk !=null) ">
                                <input type="checkbox" checked (click)="onChangeStatus('b',$event)">
                                <label class="ml-1 mr-2">Examination Findings</label>
                            </div>
                            <div *ngIf="bookingHeader && bookingHeader.length >0">
                                <input type="checkbox" checked (click)="onChangeStatus('c',$event)">
                                <label class="ml-1 mr-2">Investigations Ordered</label>
                            </div>
                            <div *ngIf="records && records.length > 0">
                                <input type="checkbox" checked (click)="onChangeStatus('d',$event)">
                                <label class="ml-1 mr-2">Medications Adviced</label>
                            </div>
                            <div *ngIf="planOfmanagementMother">
                                <input type="checkbox" checked (click)="onChangeStatus('e',$event)">
                                <label class="ml-1 mr-2">Plan Of Management-Mother</label>
                            </div>
                            <div *ngIf="fetalplan">
                                <input type="checkbox" checked (click)="onChangeStatus('f',$event)">
                                <label class="ml-1 mr-2">Plan Of Management-Fetus</label>
                            </div>
                            <div *ngIf="importantadvice">
                                <input type="checkbox" checked (click)="onChangeStatus('g',$event)">
                                <label class="ml-1 mr-2">Advice</label>
                            </div>
                            <div *ngIf="diagnosisremarks">
                                <input type="checkbox" checked (click)="onChangeStatus('i',$event)">
                                <label class="ml-1 mr-2">Diagnosis Remark</label>
                            </div>
                            <div *ngIf="otherMedication">
                                <input type="checkbox" checked (click)="onChangeStatus('other_m',$event)">
                                <label class="ml-1 mr-2">Other Medication</label>
                            </div>
                            <div *ngIf="referralOrder && referralOrder.length > 0">
                                <input type="checkbox" (click)="onChangeStatus('j',$event)">
                                <label class="ml-1 mr-2">Referral Doctors</label>
                            </div>
                            <div *ngIf="nutrionalOrdersIP">
                                <input type="checkbox" checked (click)="onChangeStatus('n',$event)">
                                <label class="ml-1 mr-2">Nutritional Orders</label>
                            </div>
                            <div *ngIf="diagnosisIP">
                                <input type="checkbox" checked (click)="onChangeStatus('z',$event)">
                                <label class="ml-1 mr-2">Diagnosis</label>
                            </div>
                            <div *ngIf="maternalPlanIP">
                                <input type="checkbox" checked (click)="onChangeStatus('y',$event)">
                                <label class="ml-1 mr-2">Diagnosis</label>
                            </div>
                            <div *ngIf="fetalPlanIP">
                                <input type="checkbox" checked (click)="onChangeStatus('x',$event)">
                                <label class="ml-1 mr-2">Fetal Plan </label>
                            </div>
                            <div *ngIf="proceduresTemplates">
                                <input type="checkbox" checked (click)="onChangeStatus('v',$event)">
                                <label class="ml-1 mr-2">Procedure Template  </label>
                            </div>
                            <div *ngIf="doctorwise">
                                <input type="checkbox" checked (click)="onChangeStatus('o',$event)">
                                <label class="ml-1 mr-2">Doctor wise </label>
                            </div>
                            <div *ngIf="scanTests && scanTests.length > 0">
                                <input type="checkbox" checked (click)="onChangeStatus('p',$event)">
                                <label class="ml-1 mr-2">Scan Appointment </label>
                            </div>
                            <div *ngIf="vitalsdata && vitalsdata.length > 0">
                                <input type="checkbox" checked (click)="onChangeStatus('VT',$event)">
                                <label class="ml-1 mr-2">Vitals </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-1">
                        <button [useExistingCss]="true" styleSheetFile="./prescription-reports.css" id="printButton" printSectionId="obprint" ngxPrint class="close d-print-none"> <i class="fe-printer" placement="left" ngbTooltip="PrintEncounter"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div id="obprint" class="border-left border-right border-bottom">
            <div class="report bg-white text-justify">
                <div class="mb-0 pt-1">
                    <div class="clearfix pb-3">
                        <div>
                            <div class="row" *ngIf="page.loading">
                                <div class="col-12">
                                    <div class="d-flex align-items-center">
                                        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                                        <span class="ml-2">Please wait while generating IEncounter Prescription ...</span>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="!page.loading && fullTranscript && isTeleconsulatantReport" class="col-12 p-0">
                                <banner-setting [reportName]='"Ob Print Prescription Teleconsulatant Header"'></banner-setting>
                            </div>
                            <ng-container *ngIf="!page.loading && fullTranscript && !isTeleconsulatantReport">
                                <div class="col-12 p-0" *ngIf="isPrintLogo">
                                    <banner-setting [reportName]='"Ob Print-Prescription Header"'></banner-setting>
                                </div>
                                <div *ngIf="!isPrintLogo" class="col-12 p-0 mtp100">
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!page.loading && fullTranscript">
                                <div class="overflow-auto">
                                    <div class="border-top2px d-flex justify-content-between pb-1 pt-1 border-bottom-dotted">
                                        <h4 *ngIf="isTeleconsulatantReport" class="pl-0">Online Consultation</h4>
                                        <h4 *ngIf="!isTeleconsulatantReport" class="pl-0">Prescription</h4>
                                        <h4 class="white-space-nowrap">Department of Obstetrics</h4>
                                    </div>
                                    <table class="w-100 white-space-nowrap">
                                        <tr class="col-12 ageTr vertical-align-top">
                                            <td class="w-130 border-bottom-dotted">
                                                <span class="">Name</span>
                                            </td>
                                            <td class="border-bottom-dotted">
                                                <h5 class="">:</h5>
                                            </td>
                                            <td class="border-bottom-dotted text-uppercase w-200p">
                                                <h5 class="d-block  pl-1 text-truncate white-space-break min-width17vw max-width_25vw" [textContent]="fullTranscript.patientName"></h5>
                                            </td>
                                            <td class="age-block text-right border-bottom-dotted">
                                                <div><div class="d-inline-block">Age</div>  : <h5 class="px-1  d-inline-block  min-width-50-px" *ngIf="fullTranscript && fullTranscript.patientAge">{{fullTranscript.patientAge}} years &nbsp;&nbsp;</h5></div>
                                            </td>
                                            <td class="w-130 border-bottom-dotted">
                                                <span class=""> UMR No.</span>
                                            </td>
                                            <td class="border-bottom-dotted">
                                                <h5 class="">:</h5>
                                            </td>
                                            <td class="w-200p text-uppercase border-bottom-dotted">
                                                <h5 class="pl-1 " [textContent]="fullTranscript.patientNo"></h5>
                                            </td>

                                        </tr>

                                        <tr class="col-12 vertical-align-top">
                                            <td>
                                                <span> Partner's Name</span>
                                            </td>
                                            <td>
                                                <h5 class="">:</h5>
                                            </td>

                                            <td colspan="2">
                                                <h5 *ngIf="husbandName white-space-break" class="pl-1 text-uppercase ">{{husbandName}}</h5>
                                            </td>
                                            <td>
                                                <span> ANC No</span>
                                            </td>
                                            <td>
                                                <h5 class="">:</h5>
                                            </td>
                                            <td><h5 class=" pl-1 text-uppercase" *ngIf="ancCardGeneration && ancCardGeneration.ancNo" [textContent]="ancCardGeneration.ancNo"></h5> </td>
                                        </tr>
                                        <tr class=" vertical-align-top">
                                            <td>
                                                <span> Doctor Name</span>
                                            </td>
                                            <td>
                                                <h5 class="">:</h5>
                                            </td>
                                            <td colspan="2">
                                                <h5 class="pl-1 text-uppercase white-space-break" [textContent]="fullTranscript.providerName"></h5>
                                            </td>
                                            <td>
                                                <span>Date</span>
                                            </td>
                                            <td>
                                                <h5 class="">:</h5>
                                            </td>
                                            <td>
                                                <h5 class="pl-1 text-uppercase " [textContent]="appointment.appointmentDate | date: 'dd/MM/yyyy'"></h5>
                                            </td>
                                           
                                        </tr>
                                        <tr class=" vertical-align-top">
                                            <td>
                                                <span>Medical Reg No</span>
                                            </td>
                                            <td>
                                                <h5 class="">:</h5>
                                            </td>
                                            <td colspan="2">
                                                <h5 class="pl-1 text-uppercase " *ngIf="fullTranscript && fullTranscript.providerNo" [textContent]="fullTranscript.providerNo"></h5>
                                            </td>
                                            <td>
                                                <span>Location</span>
                                            </td>
                                            <td>
                                                <h5 class="">:</h5>
                                            </td>
                                            <td>
                                                <h5 class="pl-1 text-uppercase white-space-break" *ngIf="location && location.name" [textContent]="location.name"></h5>
                                            </td>
                                        </tr>
                                    </table>

                                </div>
                            </ng-container>
                            <div *ngIf="a_Block" class="border-top2px">
                                <div *ngIf="ancCard" class="mt-2">
                                    <div class="d-flex">
                                        <div class="col-5 pl-0">
                                            <h5 class=" ">Antenatal Visit Details</h5>
                                        </div>
                                        <div class="col-2 pl-0 white-space-nowrap">
                                            <h5 class="d-inline-block  ">Visit No :&nbsp;&nbsp;</h5><span *ngIf="generalExamination && generalExamination.visitNumber " [textContent]="generalExamination.visitNumber"></span>
                                        </div>
                                        <div class="col-2 text-center">
                                            <h5>Blood Group</h5>
                                        </div>
                                        <div class="col-3 text-center">
                                            <h5>Period of Gestation</h5>
                                        </div>
                                    </div>
                                    <div class="d-flex" *ngIf="ancCard && ancCard!=null  && (ancCard.gravida || ancCard.para || ancCard.abortion || ancCard.living || ancCard.husbandBloodGroup || ancCard.husbandRhType || ancCard.ga)">
                                        <div class="pl-0 d-flex col-7">
                                            <div>
                                                <div><h5 class="white-space-nowrap">Obstetric Formula &nbsp;:</h5></div>
                                            </div>
                                            <div *ngIf="ancCard && ancCard!=null" class="pl-3">
                                                <table class="pt-2">
                                                    <tr class=" vertical-align-top">
                                                        <th class="line-height-1px">G</th>
                                                        <th></th>
                                                        <th class="line-height-1px">P</th>
                                                        <th></th>
                                                        <th class="line-height-1px">L</th>
                                                        <th></th>
                                                        <th class="line-height-1px">A</th>
                                                        <th></th>
                                                    </tr>
                                                    <tr class=" vertical-align-top">
                                                        <td></td>
                                                        <td class="p-1" [textContent]="ancCard.gravida"></td>
                                                        <td></td>
                                                        <td class="p-1" [textContent]="ancCard.para"></td>
                                                        <td></td>
                                                        <td class="px-1" [textContent]="ancCard.living"></td>
                                                        <td></td>
                                                        <td class="px-1" [textContent]="ancCard.abortion"></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="col-2 d-flex justify-content-center">
                                            <h5 class="" *ngIf="ancCard && ancCard.bloodGroup" [textContent]="ancCard.bloodGroup"> </h5><h5 class="" *ngIf="ancCard && ancCard.rhType" [textContent]="ancCard.rhType"></h5><h5 class="" *ngIf="ancCard && !ancCard.bloodGroup &&  !ancCard.rhType">N/A</h5>
                                        </div>
                                        <div class="col-3 text-center">
                                            <h5 class="" [textContent]="ancCard.ga"></h5>
                                            <span>Weeks</span>
                                        </div>
                                    </div>
                                    <div class="d-flex" *ngIf="ancCard && ancCard.edd!=null && ancCard.eddType != 'Ultrasound Edd'">
                                        <span>EDD : &nbsp;</span>
                                        <div>
                                            <h5 [textContent]="ancCard.edd"></h5>
                                        </div>
                                    </div>
                                    <div class="d-flex" *ngIf="ancCard && ancCard.ultraSoundEdd!=null&& ancCard.lmpStatus =='Unknown'">
                                        <div>Ultrasound EDD : &nbsp;</div>
                                        <div>
                                            <span [textContent]="ancCard.ultraSoundEdd | date: 'dd/MM/yyyy' "></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="allergies" class="mt-2">
                                <div class="d-flex" *ngIf="allergies && allergies.noHistory && allergies.noHistory!=null">
                                    <div>
                                        <div>
                                            Allergies  <span *ngIf="allergies && allergies.noHistory && allergies.noHistory!=null "> &nbsp; : &nbsp; There is no allergy</span>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-12 p-0 pb-1 pt-1">

                                </div>
                                <table class="w-75 mt-2" *ngIf="allergies && !allergies.noHistory">
                                    <tr class="col-12">
                                        <td class="w-130 white-space-nowrap" *ngIf="allergies.drugNames">
                                            <span>Drug Allergies</span>
                                        </td>
                                        <td *ngIf="allergies.drugNames">
                                            <h5>:</h5>
                                        </td>
                                        <td>
                                            <h5 class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="allergies.drugNames" [textContent]="allergies.drugNames"></h5>
                                        </td>
                                        <td class="w-130 white-space-nowrap" *ngIf="allergies.drugReactions">
                                            <span> Adverse Drug Reaction </span>
                                        </td>
                                        <td *ngIf="allergies.drugReactions">
                                            <h5>:</h5>
                                        </td>
                                        <td class="w-130" *ngIf="allergies.drugReactions">
                                            <h5 class="pl-1" [textContent]="allergies.drugReactions"></h5>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div *ngIf="b_Block && !isTeleconsulatantReport" class="mt-2">
                                <div>
                                    <div class="d-flex">
                                        <div class="pl-0 pr-2 col-9" *ngIf="measure && measure !== null">
                                            <div class="d-flex" *ngIf="measure && measure !== null && ( measure.weight || measure.bodyMassIndex || measure.pulseRate || measure.systolic || measure.diastotic || measure.tempFahrenhit || measure.liquor)">
                                                <div class="pl-0">
                                                    <h5>Examination Findings</h5>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-3" *ngIf="measure && measure.weight">
                                                    <span>Weight (Kg) : <span class="white-space-nowrap" [textContent]="measure.weight"></span></span>
                                                </div>
                                                <div class="col-3 d-flex" *ngIf="measure && measure.bodyMassIndex">
                                                    <span>BMI : <span class="white-space-nowrap" [textContent]="measure.bodyMassIndex +'  Kg/m2'"></span></span>
                                                </div>
                                                <div class="col-3 d-flex" *ngIf="measure && measure.pulseRate">
                                                    <span>PR : <span class="white-space-nowrap" [textContent]="measure.pulseRate + '  /min'"></span></span>
                                                </div>
                                                <div class="col-3 d-flex" *ngIf="measure && measure.systolic">
                                                    <span>BP : <span class="white-space-nowrap" [textContent]="measure.systolic"></span>/ <span class="white-space-nowrap" [textContent]="measure.diastotic + '  mmHg'"></span></span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-3" *ngIf="measure && measure.uterusPulpable">
                                                    <div>
                                                        <span>Uterus : <span class="white-space-nowrap" [textContent]="measure.uterusPulpable =='Yes'?'Palable': 'Not Palable'"></span></span>
                                                    </div>
                                                </div>
                                                <div class="col-3 d-flex" *ngIf="measure && measure.fundusHeight">
                                                    <span>Fundal : <span class="white-space-nowrap" [textContent]="measure.fundusHeight + '  cm'"></span></span>
                                                </div>
                                                <div class="col-3 d-flex" *ngIf="measure && measure.presentation">
                                                    <span>Presentation : <span class="white-space-nowrap" [textContent]="measure.presentation"></span></span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-3" *ngIf="measure && measure.amountofliquor">
                                                    <span>Liquor : <span class="white-space-nowrap" [textContent]="measure.amountofliquor"></span></span>
                                                </div>
                                                <div class="col-3 d-flex" *ngIf="measure && measure.fhs">
                                                    <span>FHR : <span class="white-space-nowrap" [textContent]="measure.fhs +'  /min'"></span></span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div *ngIf="antinantalRisk && antinantalRisk !== null " class="pl-0 col-3 mt-2">
                                <h5>Risk Factor</h5><span *ngIf="antinantalRisk && antinantalRisk.noRisk">&nbsp;:</span><span *ngIf="antinantalRisk && antinantalRisk.noRisk">&nbsp; No Risk</span>
                                <div *ngIf="antinantalRisk &&( antinantalRisk.maternalmedical.length > 0|| antinantalRisk.uterineRiskFactor.length > 0 || antinantalRisk.pregnancyComplications.length > 0 || antinantalRisk.fetusComplications.length > 0 || antinantalRisk.characteristics.length > 0 || antinantalRisk.details.length > 0)">
                                    <div class="w-100 mb-0">
                                        <div>
                                            <span *ngFor="let item of antinantalRisk.maternalmedical; let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>&nbsp;
                                                <span *ngIf="(i !== antinantalRisk.maternalmedical.length-1)&& (item && item !='')">,</span>
                                            </span>

                                            <span *ngFor="let item of antinantalRisk.uterineRiskFactor;  let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>&nbsp;
                                                <span *ngIf="(i != antinantalRisk.uterineRiskFactor.length-1)&& (item && item !='')">,</span>
                                            </span>

                                            <span *ngFor="let item of antinantalRisk.pregnancyComplications; let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>&nbsp;
                                                <span *ngIf="(i != antinantalRisk.pregnancyComplications.length-1)&& (item && item !='')">,</span>
                                            </span>

                                            <span *ngFor="let item of antinantalRisk.fetusComplications; let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>&nbsp;
                                                <span *ngIf="(i != antinantalRisk.fetusComplications.length-1)&& (item && item !='')">,</span>
                                            </span>

                                            <span *ngFor="let item of antinantalRisk.characteristics; let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>&nbsp;
                                                <span *ngIf="(i != antinantalRisk.characteristics.length-1)&& (item && item !='')">,</span>
                                            </span>

                                            <span *ngFor="let item of antinantalRisk.details; let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>&nbsp;
                                                <span *ngIf="(i != antinantalRisk.details.length-1 ) && (item && item !='')">,</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex" *ngIf="antinantalRisk && antinantalRisk.otherComplaints">
                                    <h5>Other &nbsp;:&nbsp;</h5>
                                    <div><span [textContent]="antinantalRisk.otherComplaints"></span></div>
                                </div>
                                <div class="d-flex" *ngIf="antinantalRisk && antinantalRisk.covidInfection">
                                    <h5>Covid-19 infection &nbsp;:&nbsp;</h5>
                                    <div><span [textContent]="antinantalRisk.covidInfection"></span></div>
                                </div>
                            </div>
                            <div *ngIf="c_Block" class="mt-2">
                                <div *ngIf="bookingHeader && bookingHeader.length > 0">
                                    <h5>Investigations Ordered</h5>
                                    <div class="text-lowercase" *ngFor="let item of bookingHeader;let i =index;">
                                        <span class=" text-wrap" [textContent]="item.testName"></span>
                                        (&nbsp;<span class=" text-wrap" [textContent]="item.departmentName"></span>&nbsp;)
                                    </div>
                                </div>
                            </div>


                            <div *ngIf="d_Block" class="mt-2">
                                <div *ngIf="records.length > 0">
                                    <div class="dashboard-body">
                                        <div *ngFor="let record of records;index as j;">
                                            <div class="overflow-auto mt-1 mb-1">
                                                <table class="w-100" style="border-collapse: collapse;">
                                                    <thead>
                                                        <tr class=" vertical-align-top">
                                                            <th><h5>Medications Adviced</h5> </th>
                                                            <th><h5>Generic Name</h5></th>
                                                            <th><h5>Frequency</h5></th>
                                                            <th *ngIf="isMedicationInstruction"><h5>Instructions</h5></th>
                                                            <th><h5>Route</h5></th>
                                                            <th><h5>Duration</h5></th>
                                                            <th><h5>Qty</h5></th>
                                                            <th><h5>Dosage</h5></th>

                                                            <th><h5>Category</h5></th>

                                                            <th><h5>Remarks</h5></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr class=" vertical-align-top">
                                                        <tr class=" vertical-align-top" *ngFor="let item of record.medicines; let i = index">
                                                            <td class="min-width120px">
                                                                <div>
                                                                    <div class=" text-wrap text-uppercase " [textContent]="item.productName "></div>
                                                                </div>
                                                            </td>
                                                            <td class="min-width120px">
                                                                <div>
                                                                    <span class=" text-wrap " *ngIf="item.genericName" [textContent]="item.genericName | titlecase "></span>
                                                                    <span class=" text-wrap text-lowercase" *ngIf="!item.genericName" [textContent]="'--'"></span>
                                                                </div>
                                                            </td>
                                                            <td class="min-width120px">
                                                                <div>
                                                                    <div class=" text-wrap text-lowercase " [textContent]="item.instruction "></div>
                                                                </div>
                                                            </td>
                                                            <td *ngIf="isMedicationInstruction">
                                                                <div>
                                                                    <span class="text-capitalize" *ngIf="item.isMorning" [textContent]="item.morningDosage"></span>
                                                                </div>
                                                                <div>
                                                                    <span class="text-capitalize" *ngIf="item.isAfternoon" [textContent]="item.afternoonDosage"></span>
                                                                </div>
                                                                <div>
                                                                    <span class="text-capitalize" *ngIf="item.isNight" [textContent]="item.nightDosage"></span>
                                                                </div>
                                                            </td>
                                                            <td class="min-width120px">
                                                                <div>
                                                                    <div class=" text-wrap text-lowercase " [textContent]="item.route "></div>
                                                                </div>
                                                            </td>
                                                            <td class="min-width120px">
                                                                <div>
                                                                    <div class=" text-wrap text-lowercase " [textContent]="item.duration "></div>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div>
                                                                    <span class="text-capitalize" *ngIf="item.dosage" [textContent]="item.dosage"></span>
                                                                </div>
                                                            </td>

                                                            <td class="min-width100px">
                                                                <div>
                                                                    <span class="" *ngIf="item.instruction" [textContent]="item.instruction | titlecase"></span>

                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div>
                                                                    <span class="text-capitalize" *ngIf="item.categoryName" [textContent]="item.categoryName">
                                                                    </span>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div>
                                                                    <span class="text-capitalize" *ngIf="item.remark" [textContent]="item.remark"></span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="vt_block" class="mt-2">
                                <div *ngIf="vitalsdata && vitalsdata.length > 0">
                                    <div class="dashboard-body">
                                        <div>
                                            <div class="overflow-auto mt-1 mb-1">
                                                <h5>Vitals</h5>
                                                <table class="w-100">
                                                    <thead>
                                                        <tr class=" vertical-align-top">
                                                            <th><h5 class=" ">vitals Name</h5> </th>
                                                            <th><h5 class=" ">Gtime</h5></th>
                                                            <th><h5 class=" ">Freqvency</h5></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr class=" vertical-align-top">
                                                        <tr class=" vertical-align-top" *ngFor="let item of vitalsdata; let i = index">
                                                            <td class="min-width120px">
                                                                <div>
                                                                    <div class=" text-wrap" [textContent]="item.name"></div>
                                                                </div>
                                                            </td>
                                                            <td class="min-width120px">
                                                                <div>
                                                                    <div class=" text-wrap" [textContent]="item.givenTime"></div>
                                                                </div>
                                                            </td>
                                                            <td class="min-width120px">
                                                                <div>
                                                                    <div class=" text-wrap" [textContent]="item.frequency"></div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="c_Block" class="mt-2">
                                <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                                    <div *ngFor="let item of ordersPrescription.orderData">
                                        <div *ngIf="item.orderName==='Investigation Template'">
                                            <div class="w-100" *ngIf="item.comments && item.comments !== null">

                                                <h5 class=" dashboard-title" *ngIf="item.comments && item.comments !== null" [textContent]="item.orderName"></h5>
                                                <table>
                                                    <tr class="pl-1 vertical-align-top" [innerHTML]="item.comments"> </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="e_Block " class="mt-2">
                                <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                                    <div *ngIf="ordersPrescription!=null">
                                        <div *ngFor="let item of ordersPrescription.orderData">
                                            <div *ngIf="item.orderName==='Maternal Plan'">
                                                <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                    <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Plan of Management - Mother</h5>
                                                    <table>
                                                        <tr class="pl-1 vertical-align-top" [innerHTML]="item.comments"></tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="f_Block " class="mt-2">
                                <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                                    <div *ngIf="ordersPrescription!=null">
                                        <div *ngFor="let item of ordersPrescription.orderData">
                                            <div *ngIf="item.orderName==='Fetal Plan'">
                                                <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                    <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Plan of Management - Fetus</h5>
                                                    <table>

                                                        <tr class="pl-1 vertical-align-top" [innerHTML]="item.comments">
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="g_Block" class="mt-2">
                                <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                                    <div *ngIf="ordersPrescription.orderData!=null">
                                        <div *ngFor="let item of ordersPrescription.orderData">
                                            <div *ngIf="ordersPrescription.orderData">
                                                <div *ngIf="item.orderName==='Important Advice'">
                                                    <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                        <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Advice</h5>
                                                        <table>
                                                            <tr class="pl-1 vertical-align-top" [innerHTML]="item.comments">
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="n_Block" class="mt-2">
                                <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                                    <div *ngIf="ordersPrescription.orderData!=null">
                                        <div *ngFor="let item of ordersPrescription.orderData">
                                            <div *ngIf="ordersPrescription.orderData">
                                                <div *ngIf="item.orderName==='Nutrional Orders IP'">
                                                    <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                        <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Nutrional Order</h5>
                                                        <table>
                                                            <tr class="pl-1 vertical-align-top" [innerHTML]="item.comments">
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="diagnosis_Block" class="mt-2">
                                <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                                    <div *ngIf="ordersPrescription.orderData!=null">
                                        <div *ngFor="let item of ordersPrescription.orderData">
                                            <div *ngIf="ordersPrescription.orderData">
                                                <div *ngIf="item.orderName==='Diagnosis IP'">
                                                    <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                        <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Diagnosis</h5>
                                                        <table>
                                                            <tr class="pl-1 vertical-align-top" [innerHTML]="item.comments">
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div *ngIf="isAdmission" class="mt-2">
                                    <div *ngIf="infusions && infusions.length>0">
                                        <div class="dashboard-body">
                                            <div>
                                                <div class="overflow-auto mt-1 mb-1">
                                                    <table class="w-100">
                                                        <thead>
                                                            <tr class=" vertical-align-top">

                                                                <th><h5 class=" ">Infusion Name</h5> </th>
                                                                <th><h5 class=" ">Medicine Name</h5></th>
                                                                <th><h5 class=" ">Device</h5></th>
                                                                <th><h5 class=" ">Infusion Route</h5></th>
                                                                <th><h5 class=" ">Duration Unit</h5></th>
                                                                <th><h5 class=" ">Duration</h5></th>
                                                                <th><h5 class=" ">Flowrate Units</h5></th>
                                                                <th><h5 class=" ">FlowRate Ml</h5></th>
                                                                <th><h5 class=" ">Preparation</h5></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr class=" vertical-align-top">
                                                            <tr class=" vertical-align-top" *ngFor="let item of infusions;">
                                                                <td class="min-width120px">
                                                                    <div>
                                                                        <div class=" text-wrap text-uppercase" [textContent]="item.pharmacyInfusionName || '--'"></div>
                                                                    </div>
                                                                </td>
                                                                <td class="min-width120px">
                                                                    <div>
                                                                        <span class=" text-wrap text-uppercase" *ngIf="item.pharmacyProductName" [textContent]="item.pharmacyProductName"></span>
                                                                        <span class=" text-wrap text-uppercase" *ngIf="!item.pharmacyProductName" [textContent]="'--'"></span>
                                                                    </div>
                                                                </td>
                                                                <td class="min-width100px">
                                                                    <div>
                                                                        <span class="text-capitalize" *ngIf="item.deviceName" [textContent]="item.deviceName"></span>

                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <span class="text-capitalize" *ngIf="item.infusionRoute" [textContent]="item.infusionRoute"></span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <span class="text-capitalize" *ngIf="item.durationUnit" [textContent]="item.durationUnit"></span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <span class="text-capitalize" *ngIf="item.duration" [textContent]="item.duration"></span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <span class="text-capitalize" *ngIf="item.flowrateUnits" [textContent]="item.flowrateUnits"></span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <span class="text-capitalize" *ngIf="item.flowrateMl" [textContent]="item.flowrateMl"></span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <span class="text-capitalize" *ngIf="item.prepration" [textContent]="item.prepration"></span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="maternal_Block" class="mt-2">
                                <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                                    <div *ngIf="ordersPrescription.orderData!=null">
                                        <div *ngFor="let item of ordersPrescription.orderData">
                                            <div *ngIf="ordersPrescription.orderData">
                                                <div *ngIf="item.orderName==='Maternal Plan IP'">
                                                    <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                        <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Maternal Plan</h5>
                                                        <table>
                                                            <tr class="pl-1 vertical-align-top" [innerHTML]="item.comments">
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="fetal_Block" class="mt-2">
                                <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                                    <div *ngIf="ordersPrescription.orderData!=null">
                                        <div *ngFor="let item of ordersPrescription.orderData">
                                            <div *ngIf="ordersPrescription.orderData">
                                                <div *ngIf="item.orderName==='Fetal Plan IP'">
                                                    <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                        <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Fetal Plan</h5>
                                                        <table>
                                                            <tr class="pl-1 vertical-align-top" [innerHTML]="item.comments">
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="procedure_Block" class="mt-2">
                                <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                                    <div *ngIf="ordersPrescription.orderData!=null">
                                        <div *ngFor="let item of ordersPrescription.orderData">
                                            <div *ngIf="ordersPrescription.orderData">
                                                <div *ngIf="item.orderName==='Procedures Templates'">
                                                    <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                        <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Procedure Template</h5>
                                                        <table>
                                                            <tr class="pl-1 vertical-align-top" [innerHTML]="item.comments">
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="o_Block" class="mt-2">
                                <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                                    <div *ngIf="ordersPrescription.orderData!=null">
                                        <div *ngFor="let item of ordersPrescription.orderData">
                                            <div *ngIf="ordersPrescription.orderData">
                                                <div *ngIf="item.orderName==='Doctor wise'">
                                                    <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                        <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Doctor wise</h5>
                                                        <table>
                                                            <tr class="pl-1 vertical-align-top" [innerHTML]="item.comments">
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="i_Block">
                                <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                                    <div *ngIf="ordersPrescription.orderData!=null">
                                        <div *ngFor="let item of ordersPrescription.orderData">
                                            <div *ngIf="ordersPrescription.orderData">
                                                <div *ngIf="item.orderName==='Diagnosis Remarks'">
                                                    <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                        <h5 class="dashboard-title  text-uppercase  " *ngIf="item.comments && item.comments !== null" [textContent]="item.orderName"></h5>
                                                        <table>
                                                            <tr class="pl-1 vertical-align-top" [innerHTML]="item.comments">
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="m_Block" class="mt-2">
                                <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                                    <div *ngIf="ordersPrescription.orderData!=null">
                                        <div *ngFor="let item of ordersPrescription.orderData">
                                            <div *ngIf="ordersPrescription.orderData">
                                                <div *ngIf="item.orderName==='Other Medication'">
                                                    <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                        <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null" [textContent]="item.orderName"></h5>
                                                        <table>
                                                            <tr class="pl-1 vertical-align-top" [innerHTML]="item.comments">
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="j_Block" class="mt-2">
                                <div *ngIf="referralOrder && referralOrder.length > 0">
                                    <h5>Referrals</h5>
                                    <table class="col-4 mt-1 mb-1" style="border-collapse: collapse;">
                                        <thead>
                                            <tr class=" vertical-align-top">
                                                <td class="w-200p vertical-align-top "><h5>Doctors</h5> </td>
                                                <td class="w-200p vertical-align-top "><h5>Comments</h5> </td>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr *ngFor="let item of referralOrder; let i = index">
                                                <td>
                                                    <div class=" text-wrap" [textContent]="item.name"></div>
                                                </td>
                                                <td>
                                                    <div class=" text-wrap" [textContent]="item.comments"></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div *ngIf="p_Block" class="mt-2">
                                <div *ngIf="scanTests && scanTests.length > 0">
                                    <h5>Scan Appointments</h5>
                                    <table class=" mt-1 mb-1">
                                        <thead>
                                            <tr class=" vertical-align-top">
                                                <td class="w-200p vertical-align-top "><h5>Sacn Test Name</h5> </td>
                                                <td class="w-200p vertical-align-top "><h5>Comments</h5> </td>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr *ngFor="let item of scanTests; let i = index">
                                                <td>
                                                    <div class=" text-wrap" [textContent]="item.name"></div>
                                                </td>
                                                <td>
                                                    <div class=" text-wrap" [textContent]="item.comments"></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div *ngIf="scanAppointments && scanAppointments.length>0" class="mt-2">
                                <div>
                                    <h5 class="dashboard-title ">Scan Appointment</h5>
                                    <div *ngFor="let item of scanAppointments; index as i ">
                                        <span>Scan Scheduled for {{item.machineName}} at {{item.locationName}} on {{item.appointmentDate | date :'dd/MM/yyyy'}} at {{item.appointmentTimeString}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2" *ngIf="futureAppointments && futureAppointments.length>0">
                                <div>
                                    <h5 class="dashboard-title ">Next Visit Date</h5>
                                    <div *ngFor="let item of futureAppointments; index as i ">
                                        <span>Next visit with<span class="text-uppercase"> {{item.providerName}} </span>at {{item.locationName}} on {{item.appointmentDate | date :'dd/MM/yyyy'}} at {{item.appointmentTimeString}}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-2" *ngIf="isTeleconsulatantReport">
                                <h5 class="dashboard-title ">Disclaimer</h5>
                                <div>
                                    Limitations of TELE-CONSULT exist and it can never be equated to a clinical consultation. The doctor’s advice is only based on the
                                    accuracy of verbal details provided by you as the mandatory physical examination is not possible. This consultation is only to
                                    help you access health care and is not permitted for any medico-legal purposes. We disclaim any liability or responsibility, of
                                    harm or injury happening due to misinformation, misrepresentation, misinterpretation and impersonation. If you are
                                    experiencing an urgent medical problem, please visit the nearest emergency care facility. Your request / acceptance of tele consult will be taken as your consent with its ingrained limitations and your session will be recorded. Your information and
                                    privacy will be guarded.
                                </div>
                            </div>

                            <div class=" mt-2 align-items-end col-12 d-flex   p-0 pt-1 column-gap15px" *ngIf="emailAndMobile && !emailAndMobile.active">

                                <div class=" flex-grow-1 col-6  d-flex">
                                    <div><span>User Name :&nbsp;</span></div>
                                    <div><h5 class="text-uppercase" *ngIf="fullTranscript && fullTranscript.providerName" [textContent]="fullTranscript.providerName"></h5></div>
                                </div>
                                <div class=" align-items-end col-6 d-flex">
                                    <div><span>Signature :&nbsp;</span></div>
                                    <div><h5 class="border-bottom border-dark w-75p"><img *ngIf="appointment && appointment.signature!=null" [src]="safe(appointment.signature)" class="img-fluid rounded-circle" style="height:80px" /></h5></div>
                                </div>
                            </div>
                            <div class=" mt-2 align-items-end col-12 d-flex flex-wrap justify-content-between p-0 pt-1 column-gap15px">
                                <div class="d-flex flex-grow-1" *ngIf="emailAndMobile && emailAndMobile.active">
                                    <div><span>Email :&nbsp;</span></div>
                                    <div *ngIf="fullTranscript && fullTranscript.email" class="text-truncate"><h5 class="text-lowercase" [textContent]="fullTranscript.email"></h5></div>
                                </div>
                                <div class="d-flex flex-wrap column-gap15px align-items-end justify-content-end flex-grow-1">
                                    <div class="d-flex" *ngIf="emailAndMobile && emailAndMobile.active">
                                        <div><span>Mob :&nbsp;</span></div>
                                        <div *ngIf="fullTranscript && fullTranscript.providerMobileNo"><h5 [textContent]="fullTranscript.providerMobileNo"></h5></div>
                                    </div>
                                    <div class="d-flex flex-grow-1 justify-content-center">
                                        <div><span>User Name :&nbsp;</span></div>
                                        <div><h5 class="text-uppercase" *ngIf="fullTranscript && fullTranscript.providerName" [textContent]="fullTranscript.providerName"></h5></div>
                                    </div>
                                    <div class="d-flex align-items-end">
                                        <div><span>Signature :&nbsp;</span></div>
                                        <div><h5 class="border-bottom border-dark w-75p"><img *ngIf="appointment && appointment.signature!=null" [src]="safe(appointment.signature)" class="img-fluid rounded-circle" style="height:80px" /></h5></div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2">
                                <h5>
                                    EMERGENCY CONTACT : <span *ngIf="location && location.name"> {{location.name}}</span>  Contact No:
                                    <span *ngIf="location && location.optionalText" [textContent]="location.optionalText"></span>

                                </h5>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="border-dark border-top column-gap10px d-flex flex-wrap justify-content-between p-0 pt-1">
                    <div class="d-flex">
                        <div><span>Saved User :&nbsp;</span></div>
                        <div class="text-uppercase" *ngIf="fullTranscript && fullTranscript.providerName"><span [textContent]="fullTranscript.providerName"></span></div>
                    </div>
                    <div class="d-flex">
                        <div><span>Printed By :&nbsp;</span></div>
                        <div><span class="text-uppercase" [textContent]="page.userAccount?.fullName"></span></div>
                    </div>
                    <div class="d-flex">
                        <div><span>Print Date & Time :&nbsp;</span></div>
                        <div><span [textContent]="date | date :'dd/MM/yyyy'"></span>, &nbsp;<span [textContent]="date | date :' h:mm a'"></span></div>
                    </div>
                </div>
                <div *ngIf="isFooter">
                    <banner-setting [reportName]='"Ob Print-Prescription Footer"'></banner-setting>
                </div>
            </div>
        </div>
    </div>
</div>



