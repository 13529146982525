<!--Search -->
<div class="row white-space-nowrap justify-content-between search-filter">
    <div>
        <div class="input-group pr-5 cust-search">
            <input type="text" class="form-control border-right-0 bg-white h-auto" id="searchInput"
                   (keyup.enter)="moveToNext()" (change)="highlightText()" placeholder="Search..." [(ngModel)]="searchInput" />
            <div class="input-group-append">
                <div class="input-group-text bg-white border-left-0 pr-0 h-auto pt-1 pb-1">
                    <span *ngIf="TotalOccurances > 0" [textContent]="activeIndex + ' of ' + TotalOccurances"></span>
                    <span class="mdi mdi-close" [class.disabled]="TotalOccurances <= 0" *ngIf="TotalOccurances > 0" (click)="cancelSearch()"></span>
                    <div class="border-left border-black d-inline">
                        <span (click)="moveToBack()" class="mdi mdi-chevron-up mb-0 "
                              [class.disabled]="TotalOccurances <= 0"
                              [class.text-secondary]="TotalOccurances > 0"></span>
                        <span (click)="moveToNext()" class="mdi mdi-chevron-down mb-0 "
                              [class.disabled]="TotalOccurances <= 0"
                              [class.text-secondary]="TotalOccurances > 0"></span>
                    </div>
                </div>
                <button (click)="highlightText()" class="btn btn-primary btn-sm mb-0">Go</button>
            </div>
        </div>
    </div>
    <div class="col-4">
        <div class="d-flex align-items-center">
            <label>Go To :</label>
            <div class="pl-2 flex-fill">
                <ng-select class="w-100 h-auto"
                           [items]="dropdownOptions"
                           [loading]="isLoadingDropdownOptions"
                           autocomplete="nope"
                           (change)="onDropdownSelect($event)"
                           placeholder="-Select-">
                </ng-select>
            </div>
        </div>

    </div>
</div>



<!--End of Search -->
<div class="scroll-obsecWhenNavigation">
    <div id="obContainer" class="font-weight-semibold text-dark" *ngIf="!loading">
        <div class="mt-1">
            <div class="col-12 p-0">
                <div>
                    <button class="btn btn-info" type="button" (click)="onChangePrintType( false)">
                        <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangePrintType(true)">
                        <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangeFooter(false)">
                        <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangeFooter(true)">
                        <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
                    </button>
                </div>
                <div class="align-items-start border border-bottom-0 d-flex justify-content-end pl-1 pr-1 pt-1" style="background-color: #edfaf7;">
                    <div class="col-1">
                        <button [useExistingCss]="true" id="printButton" printSectionId="obprint" ngxPrint class="close d-print-none"> <i class="fe-printer" placement="left" ngbTooltip="PrintEncounter"></i> </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="loading">
        <div class="col-12">
            <div class="d-flex align-items-center">
                <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                <span class="ml-2">Please wait fetching requested data...</span>
            </div>
        </div>
    </div>

    <div class="bg-white content" id="obprint" #textContainer>
        <div class="clearfix mb-3 p-3 report" *ngIf="!loading">

            <div *ngIf="isPrintLogo">
                <banner-setting [reportName]='"OB All Information Report Header"'></banner-setting>
            </div>
            <div *ngIf="isAdimissionCount > 0">
                <div *ngFor="let item of admissionReport">
                    <ng-container *ngIf="!page.loading && obFullReport">
                        <h5 class="font-weight-bold my-2 text-black" *ngIf="item.ancCard && item.ancCard.consulatantDoctor">Consultant Doctor : {{item.ancCard.consulatantDoctor}}</h5>
                        <h5 class="font-weight-bold white-space-nowrap text-black">Unit :UNIT 2</h5>
                    </ng-container>
                    <h3 class="font-weight-bold w-100 text-center text-dark mb-0 border-dark border-bottom">ADMISSION FORM</h3>

                    <ng-container *ngIf="!page.loading && obFullReport">
                        <div class="overflow-auto">
                            <table class="w-100 table-striped">
                                <tr class="col-12">
                                    <td>
                                        <h5 class="font-weight-bold m-0  d-inline"> Name  :</h5>
                                        <h5 class="font-weight-bold m-0 text-uppercase d-inline" [textContent]="appointment.patientName"></h5>
                                    </td>
                                    <td class="w-150px white-space-nowrap text-right" colspan="3"><span class="m-0"> Admission Date /Ward in Time</span>: <span class="pl-1" [textContent]="appointment.appointmentDate | date: 'dd/MM/yyyy'"></span><span *ngIf="appointment.admTime" class="pl-1">/{{appointment.admTime}}</span></td>
                                </tr>
                                <tr class="col-12">
                                    <td><span class="m-0">MR No :</span><span class="pl-1" *ngIf="appointment && appointment.umrNo">{{appointment.umrNo}}</span></td>
                                    <td><span class="m-0"> IP No: </span><span class="pl-1" [textContent]="appointment.appointmentNo"></span></td>
                                    <td><span class="m-0">Wards :</span><span class="pl-1" [textContent]="appointment.wardName"></span></td>
                                    <td class="text-right"><span class="m-0">Examination Time :</span><span class="pl-1" [textContent]="appointment.roomName"></span></td>
                                    <td style="display:none;"><span class="m-0">Room No :</span><span class="pl-1" [textContent]="appointment.roomName"></span></td>
                                </tr>
                            </table>
                        </div>
                    </ng-container>
                    <div>
                        <div class="border-dark border-top d-flex pb-1 pt-1 align-items-center">
                            <h5 class="font-weight-bold m-0">COMPLAINTS <span class="topHeading">:</span></h5>
                            <div *ngIf="item.admissionDetail && item.admissionDetail.complaints.length > 0">
                                <ul class="d-flex flex-wrap m-0" style="gap:25px;">
                                    <li *ngFor="let item of item.admissionDetail.complaints" [textContent]="item"></li>
                                </ul>
                            </div>
                        </div>
                        <div class="align-items-center border-dark border-top d-flex pt-1 mb-2">
                            <h5 class="font-weight-bold m-0">History Of Present Illness <span class="headingText">:</span></h5>
                            <div *ngIf="item.admissionDetail && item.admissionDetail.historyOfPresentIllness"><span [textContent]="item.admissionDetail.historyOfPresentIllness"></span></div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <h5 class="font-weight-bold m-0 text-black">Antenatal Visit Details</h5>
                                <div *ngIf="item.ancCard && item.ancCard!=null" class="pl-1 mb-2"> <span class="font-weight-bold text-black d-inline-block font-26 pr-1">G</span> <sub class="font-17 pr-2" [textContent]="item.ancCard.gravida"></sub> <span class="font-weight-bold text-black d-inline-block font-26 pr-1">P</span><sub class="font-17 pr-2" [textContent]="item.ancCard.para"></sub> <span class="font-weight-bold text-black d-inline-block font-26 pr-1">L</span><sub class="font-17 pr-2" [textContent]="item.ancCard.abortion"></sub><span class="font-weight-bold text-black d-inline-block font-26 pr-1"> A</span><sub class="font-17" [textContent]="item.ancCard.living"></sub> </div>
                                <div class="white-space-nowrap"> <span class="w-150px d-inline-block text-black">LMP </span>:<span class="pl-1" *ngIf="item.ancCard && item.ancCard!=null"><span [textContent]="item.ancCard.lmp |date :'d/MM/yyyy'"></span></span> </div>
                                <div class="white-space-nowrap"> <span class="w-150px d-inline-block text-black">EDD </span>:<span *ngIf="item.ancCard && item.ancCard!=null" class="pl-1"><span [textContent]="item.ancCard.edd"></span> </span> </div>
                            </div>
                            <div class="col-6 text-center">
                                <h5 class="font-weight-bold m-0"> Period of Gestation </h5>
                                <div *ngIf="item.ancCard && item.ancCard!=null">
                                    <h5><span class="font-26" [textContent]="item.ancCard.ga"></span></h5>
                                </div>
                                <span>weeks</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 ng-star-inserted p-0 pt-1 border-dark border-top" *ngIf="item.obHistory">
                        <div>
                            <h5 class="font-weight-bold mt-0 mb-2">OBSTETRIC HISTORY</h5>
                        </div>
                        <table class="w-100 border-black" border="1">
                            <thead *ngIf="item.obHistory.length >0">
                                <tr class="tableBorder">
                                    <th class="tableBorder font-weight-bolder"> Preg No</th>
                                    <th class="tableBorder font-weight-bolder">Year</th>
                                    <th class="tableBorder font-weight-bolder">Birth Details</th>
                                </tr>
                            </thead>
                            <tbody class="vertical-align-baseline">
                                <tr *ngFor="let item of item.obHistory;">
                                    <td class="tableBorder" [textContent]="item.pregnancyNo"></td>
                                    <td class="tableBorder" [textContent]="item.yearOfBirth"></td>
                                    <td class="tableBorder">
                                        <div *ngIf="item.pregnancyStatus">Pregnancy Status &nbsp;: &nbsp;<span [textContent]="item.pregnancyStatus"></span></div>
                                        <div *ngIf="item.gaWeeks">GA Weeks &nbsp;: &nbsp;<span [textContent]="item.gaWeeks"></span></div>
                                        <div *ngIf="item.deliveryAt">Delivered At &nbsp;: &nbsp;<span [textContent]="item.deliveryAt"></span></div>
                                        <div *ngIf="item?.deliveryDetail?.length"><span [textContent]="item?.deliveryDetail"></span></div>
                                        <div *ngIf="item.indication">Indication &nbsp;: &nbsp;<span [textContent]="item.indication"></span></div>
                                        <div *ngIf="item.other">Other Information &nbsp;: &nbsp;<span [textContent]="item.other"></span></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 ng-star-inserted p-0 pt-1" *ngIf="item.problemList && item.problemList.problems">
                        <div>
                            <h5 class="font-weight-bold">PAST MEDICAL HISTORY</h5>
                        </div>
                        <div *ngIf="isPastMedicalHistory"> None </div>
                        <table class="w-100" *ngIf="item.problemList.problems.length >0">
                            <thead>
                                <tr class="tableBorder">
                                    <th class="tableBorder font-weight-bolder"> Disease</th>
                                    <th class="tableBorder font-weight-bolder">Year of diagnosis</th>
                                    <th class="tableBorder font-weight-bolder">ICD Code</th>
                                    <th class="tableBorder font-weight-bolder">Status</th>
                                    <th class="tableBorder font-weight-bolder min-width180px">Treatment History</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let problem of item.problemList.problems">
                                    <td class="tableBorder" [textContent]="problem.usable"></td>
                                    <td class="tableBorder" [textContent]="problem.date"></td>
                                    <td class="tableBorder" [textContent]="problem.code"></td>
                                    <td class="tableBorder" [textContent]="problem.status"></td>
                                    <td class="tableBorder" [textContent]="problem.comments"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 p-0 pt-1" *ngIf="item.procedureSurgery">
                        <div>
                            <h5 class="font-weight-bold">PAST SURGICAL HISTORY</h5>
                        </div>
                        <div *ngIf="!item.procedureSurgery.length"> There is no Surgical History </div>
                        <table class="w-100" border="1" *ngIf="item.procedureSurgery.length>0">
                            <thead>
                                <tr class="tableBorder">
                                    <th class="tableBorder font-weight-bolder"> Year Of Surgery</th>
                                    <th class="tableBorder font-weight-bolder">Surgeries</th>
                                    <th class="tableBorder font-weight-bolder min-width180px">Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of item.procedureSurgery;">
                                    <td class="tableBorder" [textContent]=" item.date"></td>
                                    <td class="tableBorder" [textContent]="item.surgeries"></td>
                                    <td class="tableBorder" [textContent]="item.comments"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="border-dark border-top border-bottom d-flex align-items-center mt-2">
                        <h5 class="font-weight-bold">RISK FACTORS <span class="topHeading">:</span></h5>
                        <div *ngIf="item.admissionDetail && item.admissionDetail.complaints.length > 0">
                            <ul class="m-0 d-flex flex-wrap" style="gap:25px;">
                                <li *ngFor="let item of item.admissionDetail.complaints" [textContent]="item"></li>
                            </ul>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2">
                        <h5 class="font-weight-bold">SPECIAL FEATURES <span class="topHeading">: &nbsp;</span></h5>
                        <div *ngIf="item.specialFeature && item.specialFeature.specialFeature"> <span [textContent]="item.specialFeature.specialFeature"></span> </div>
                    </div>
                    <div class="row my-2">
                        <div class="col-6">
                            <h5 class="font-weight-bold">Risk Categorisation at admission <span class="topHeading">: &nbsp;</span><span class="text-danger font-weight-normal">No Risk</span></h5>
                        </div>
                        <div>
                            <h5 class="font-weight-bold">No of Risk factors <span class="topHeading">: &nbsp;</span><span class="font-weight-normal"></span></h5>
                        </div>
                    </div>
                    <div>
                        <h5 class="font-weight-bold text-black mb-2">General Examination</h5>
                        <div class="row">
                            <div class="col-4"> <span class="w-150px d-inline-block"> Consciousness </span>:<span class="pl-1" *ngIf="item.admissionDetail && item.admissionDetail.conscious" [textContent]="item.admissionDetail.conscious"></span> </div>
                            <div class="col-4"> <span class="w-150px d-inline-block"> Resp System</span>:<span class="pl-1" *ngIf="item.admissionDetail && item.admissionDetail.respsystem" [textContent]="item.admissionDetail.respsystem"></span> </div>
                            <div class="col-4"> <span class="w-150px d-inline-block"> Edema </span>:<span class="pl-1" *ngIf="item.admissionDetail && item.admissionDetail.oedema" [textContent]="item.admissionDetail.oedema"></span> </div>
                            <div class="col-4"> <span class="w-150px d-inline-block"> Shock</span>:<span class="pl-1" *ngIf="item.admissionDetail && item.admissionDetail.shock" [textContent]="item.admissionDetail.shock"></span> </div>
                            <div class="col-4"> <span class="w-150px d-inline-block">CVS</span>:<span class="pl-1" *ngIf="item.admissionDetail && item.admissionDetail.cardiovasc" [textContent]="item.admissionDetail.cardiovasc"></span> </div>
                            <div class="col-4"> <span class="w-150px d-inline-block"> Reflexes</span>:<span class="pl-1" *ngIf="item.admissionDetail && item.admissionDetail.reflex" [textContent]="item.admissionDetail.reflex"></span> </div>
                            <div class="col-4"> <span class="w-150px d-inline-block"> Pallor</span>:<span class="pl-1" *ngIf="item.admissionDetail && item.admissionDetail.pallor" [textContent]="item.admissionDetail.pallor"></span> </div>
                            <div class="col-4"> <span class="w-150px d-inline-block"> Liver</span>:<span class="pl-1" *ngIf="item.admissionDetail && item.admissionDetail.liver" [textContent]="item.admissionDetail.liver"></span> </div>
                            <div class="col-4"> <span class="w-150px d-inline-block">Urine Output </span>:<span class="pl-1" *ngIf="item.admissionDetail && item.admissionDetail.urineoutput" [textContent]="item.admissionDetail.urineoutput"></span> </div>
                            <div class="col-4"> <span class="w-150px d-inline-block"> Icterus</span>:<span class="pl-1" *ngIf="item.admissionDetail && item.admissionDetail.icterus" [textContent]="item.admissionDetail.icterus"></span> </div>
                            <div class="col-4"> <span class="w-150px d-inline-block"> Spleen</span>:<span class="pl-1" *ngIf="item.admissionDetail && item.admissionDetail.spleen" [textContent]="item.admissionDetail.spleen"></span> </div>
                        </div>
                    </div>
                    <div class="my-2">
                        <table class="border-black m-0 w-100" border="1">
                            <thead>
                                <tr>
                                    <th>Temperature</th>
                                    <th> Pulse (/Min) </th>
                                    <th>BP (mm Hg)</th>
                                    <th>Resp rate</th>
                                    <th>Height(Ft)</th>
                                    <th>Weight(kg)</th>
                                    <th>BMI</th>
                                    <th>Spo2</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="item.admissionDetail && item.admissionDetail !=null">
                                    <td [textContent]="item.admissionDetail.temperature"></td>
                                    <td [textContent]="item.admissionDetail.pulserate"></td>
                                    <td [textContent]="item.admissionDetail.systolicbp"></td>
                                    <td [textContent]="item.admissionDetail.respiratoryrate"></td>
                                    <td [textContent]="item.admissionDetail.heightInFeet"></td>
                                    <td [textContent]="item.admissionDetail.weightInKg"></td>
                                    <td [textContent]="item.admissionDetail.bmi"></td>
                                    <td [textContent]="item.admissionDetail.spo2"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <h5 class="font-weight-bold mb-2 text-black">OBSTETRIC EXAMINATION</h5>
                        <div class="row">
                            <div class="col-6"> <span class="w-200p d-inline-block"> Fundal height (cm)</span>:<span class="pl-1" *ngIf="item.measure && item.measure.fundusHeight" [textContent]="item.measure.fundusHeight"></span> </div>
                            <div class="col-6"> <span class="w-200p d-inline-block"> Liquor</span>:<span class="pl-1" *ngIf="item.measure && item.measure.liquor" [textContent]="item.measure.liquor"></span> </div>
                            <div class="col-6"> <span class="w-200p d-inline-block"> Presenting Part </span>:<span class="pl-1" *ngIf="item.measure && item.measure.presentingPart" [textContent]="item.measure.presentingPart"></span> </div>
                            <div class="col-6"> <span class="w-200p d-inline-block"> Draining </span>:<span class="pl-1" *ngIf="item.admissionDetail && item.admissionDetail.draining" [textContent]="item.admissionDetail.draining"></span> </div>
                            <div class="col-6"> <span class="w-200p d-inline-block"> PP Abd </span>:<span class="pl-1" *ngIf="item.admissionDetail && item.admissionDetail.conscious" [textContent]="item.admissionDetail.conscious"></span> </div>
                            <div class="col-6"> <span class="w-200p d-inline-block">Urine albumin</span>:<span class="pl-1" *ngIf="item.admissionDetail && item.admissionDetail.respsystem" [textContent]="item.admissionDetail.respsystem"></span> </div>
                            <div class="col-6"> <span class="w-200p d-inline-block"> FHS</span>:<span class="pl-1" *ngIf="item.admissionDetail && item.admissionDetail.conscious" [textContent]="item.admissionDetail.conscious"></span> </div>
                            <div class="col-6"> <span class="w-200p d-inline-block"> Uterine Contractions</span>:<span class="pl-1" *ngIf="item.admissionDetail && item.admissionDetail.respsystem" [textContent]="item.admissionDetail.respsystem"></span> </div>
                            <div class="col-6"> <span class="w-200p d-inline-block">FHR</span>:<span class="pl-1" *ngIf="item.admissionDetail && item.admissionDetail.conscious" [textContent]="item.admissionDetail.conscious"></span> </div>
                        </div>
                    </div>
                    <div class="border-bottom border-dark">
                        <h5 class="font-weight-bold text-black my-2">VAGINAL EXAMINATION</h5>
                        <div class="row">
                            <div class="col-6"> <span class="w-200p d-inline-block"> Cervix</span>:<span class="pl-1" *ngIf="item.measure && item.measure.cervix" [textContent]="item.measure.cervix"></span> </div>
                            <div class="col-6"> <span class="w-200p d-inline-block"> Station</span>:<span class="pl-1" *ngIf="item.measure && item.measure.stationPP" [textContent]="item.measure.stationPP"></span> </div>
                            <div class="col-6"> <span class="w-200p d-inline-block"> Membranes </span>:<span class="pl-1" *ngIf="item.measure && item.measure.membranes" [textContent]="item.measure && item.measure.membranes"></span> </div>
                            <div class="col-6"> <span class="w-200p d-inline-block"> Cervical Dilation(cm) </span>:<span class="pl-1" *ngIf="item.measure && item.measure.cervicalOSCm" [textContent]="item.measure && item.measure.cervicalOSCm"></span> </div>
                            <div class="col-6"> <span class="w-200p d-inline-block"> PP Vag</span>:<span class="pl-1" *ngIf="item.admissionDetail && item.admissionDetail.ppvag" [textContent]="item.admissionDetail.ppvag"></span> </div>
                            <div class="col-6"> <span class="w-200p d-inline-block"> Pelvis:</span>:<span class="pl-1" *ngIf="item.admissionDetail && item.admissionDetail.pelvis" [textContent]="item.admissionDetail.pelvis"></span> </div>
                        </div>
                    </div>
                    <div class="border-bottom border-dark d-flex align-items-center">
                        <h5 class="font-weight-bold">PROVISIONAL DIAGNOSIS <span class="topHeading">: &nbsp;</span></h5>
                        <div class="font-weight-bold">
                            <h5 *ngIf="item.ancCard && item.ancCard!=null">
                                &nbsp; &nbsp;G <span class="font-17" [textContent]="item.ancCard.gravida"></span> P<span class="font-17" [textContent]="item.ancCard.para"></span> L<span class="font-17" [textContent]="item.ancCard.abortion"></span> A<span class="font-17" [textContent]="item.ancCard.living"></span>,
                                GA<span class="font-17" [textContent]="item.ancCard.ga">weeks of gestation</span>
                            </h5>
                        </div>
                    </div>
                    <div *ngIf="records.length > 0">
                        <div class="dashboard-body">
                            <div *ngFor="let record of fetchMedRecords(item.admissionId);index as j;">
                                <h5 class="font-weight-bold">Medications Adviced</h5>
                                <div class="overflow-auto mt-1 mb-1">
                                    <table border="1" class="w-100" style="border-collapse: collapse;">
                                        <thead>
                                            <tr>
                                                <th>Medications Adviced</th>
                                                <th>Generic Name</th>
                                                <th>Dosage</th>
                                                <th>Category</th>
                                                <th>Qty</th>
                                                <th>Remarks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <tr *ngFor="let item of record.medicines; let i = index">
                                                <td class="min-width200px">
                                                    <span class=" text-wrap" [textContent]="item.productName"></span><br />
                                                    <span class=" text-wrap" *ngIf="item.genericName" [textContent]="'( ' + item.genericName + ' )'"></span>
                                                </td>
                                                <td class="min-width200px"><span class=" text-wrap" *ngIf="item.genericName" [textContent]="'( ' + item.genericName + ' )'"></span></td>
                                                <td><span class=" text-wrap"> <span *ngIf="item.instruction" [textContent]="item.instruction"></span> </span></td>
                                                <td><span class=" text-wrap"> <span *ngIf="item.categoryName" [textContent]="item.categoryName"></span> </span></td>
                                                <td><span class=" text-wrap"> <span *ngIf="item.dosage" [textContent]="item.dosage"></span> </span></td>
                                                <td><span class=" text-wrap"> <span *ngIf="item.remark" [textContent]="item.remark"></span> </span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="fetchOrderPrescriptionRecords(item.admissionId).length > 0">
                        <div *ngFor="let item of fetchOrderPrescriptionRecords(item.admissionId)">
                            <div *ngIf="item.orderName==='Maternal Plan'">
                                <div class="w-100 dashboard-break" *ngIf="item.comments && item.comments !== null">
                                    <h5 class="font-weight-bold" *ngIf="item.comments && item.comments !== null">Plan of Management - Mother</h5>
                                    <table class="table table-centered table-nowrap table-sm table-striped transcipt">
                                        <tr class="pl-1" [innerHTML]="item.comments"></tr>
                                    </table>
                                </div>
                            </div>
                            <div *ngIf="item.orderName==='Fetal Plan'">
                                <div class="w-100 dashboard-break" *ngIf="item.comments && item.comments !== null">
                                    <h5 class="font-weight-bold" *ngIf="item.comments && item.comments !== null">Plan of Management - Fetus</h5>
                                    <table class="table table-centered table-nowrap table-sm table-striped transcipt">
                                        <tr class="pl-1" [innerHTML]="item.comments"> </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-2">
                        <h5 class="font-weight-bold">Nutritional Order</h5>
                        <table class="table table-centered table-nowrap table-sm mb-0 table-striped transcipt">
                            <tr class="pl-1"> </tr>
                        </table>
                    </div>
                    <div class="d-flex align-items-center">
                        <h5 class="font-weight-bold m-0">Counselling Request <span class="topHeading">: &nbsp;</span></h5>
                        <div *ngIf="item.admissionDetail && item.admissionDetail.counselling"> <span [textContent]="item.admissionDetail.counselling"></span> </div>
                    </div>
                    <ng-container *ngIf="!page.loading && obFullReport">
                        <div class="overflow-auto">
                            <table class="w-100 table-striped">
                                <tr class="col-12">
                                    <td class="pl-0 w-10px" *ngIf="item.admissionDetail && item.admissionDetail.consultantinformed"><h5 class="font-weight-bold m-0 text-black"> Information to Consultant :</h5></td>
                                    <td class="w-200p font-weight-bold"><span class="font-weight-bold m-0 text-uppercase text-black" *ngIf="item.admissionDetail && item.admissionDetail.consultantinformed" [textContent]="item.admissionDetail.consultantinformed"></span></td>
                                    <td class="w-10px " *ngIf="item.admissionDetail && item.admissionDetail.date"><h5 class="m-0 font-weight-bold text-black">Date:</h5></td>
                                    <td class="w-10px"><span class="pl-1 text-black" style="" *ngIf="item.admissionDetail && item.admissionDetail.date" [textContent]="item.admissionDetail.date | date: 'dd.MM.yyyy'"></span></td>
                                </tr>
                                <tr class="col-12">
                                    <td class="pl-0 w-10px" *ngIf="item.admissionDetail && item.admissionDetail.consultantopinion"><h5 class="m-0 font-weight-bold text-black">Plan okayed by Consultant:</h5></td>
                                    <td><span class="pl-1 text-black" *ngIf="item.admissionDetail && item.admissionDetail.consultantopinion" [textContent]="item.admissionDetail.consultantopinion"></span></td>
                                    <td class="pl-0 w-10px"><h5 class="m-0 font-weight-bold text-black" *ngIf="item.admissionDetail && item.admissionDetail.time">Time :</h5></td>
                                    <td><span class="pl-1" *ngIf="item.admissionDetail && item.admissionDetail.time" [textContent]="item.admissionDetail.time"></span></td>
                                </tr>
                            </table>
                        </div>
                    </ng-container>
                    <footer class="border-dark border-top font-13 justify-content-between">
                        <div class="row pt-1">
                            <div class="col-9">
                                <div class="row">
                                    <div class="col-6">
                                        <div><span class="pl-0 col-6 font-weight-bold">Saved on Date & Time : 18/09/2023 11:38 AM</span></div>
                                        <div><span class="pl-0 col-6 font-weight-bold">Accessed on Date &Time : 10/10/2023 11.45 AM</span></div>
                                    </div>
                                    <div class="col-6">
                                        <div><span class="pr-0 col-6 font-weight-bold">Saved by : 800296DRAMEYA</span></div>
                                        <div><span class="pr-0 col-6 font-weight-bold">Accessed by : 1015TMANI</span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <span>Signature</span> <img *ngIf="appointment?.signature != null" [src]="safe(appointment?.signature)" cssClass="img-fluid rounded-circle" style="height:80px" />
                            </div>
                        </div>
                    </footer>
                </div>
                <div class="page-break-after-always"></div>
                <div class="mt-3">
                    <div class="printshow">
                        <banner-setting></banner-setting>
                    </div>
                    <!-- Nursing-initial-assesment -->
                    <div *ngFor="let item of admissionReport">
                        <div *ngFor="let neonatalNurseResource of findNurseCarePlanRecords(item.admissionId)">
                            <div border-left border-right mb-0>
                                <div class="row" *ngIf="page.loading">
                                    <div class="col-12">
                                        <div class="d-flex align-items-center"> <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span> <span class="ml-2">Please wait while generating IEncounter Prescription ...</span> </div>
                                    </div>
                                </div>
                                <div>
                                    <!--<h3 >NURSING INITIAL ASSESSMENT RECORD</h3>-->

                                    <h3 class="d-flex justify-content-center w-100 ">
                                        NURSING INITIAL ASSESSMENT RECORD
                                    </h3>
                                    <h4>{{currentLocation}}</h4>
                                    <div class="border-dark border-top">
                                        <table>
                                            <tr>
                                                <td><h5>Patient Name</h5></td>
                                                <td><h5>:</h5></td>
                                                <td><h5 *ngIf="appointment && appointment.patientName" [textContent]="appointment.patientName"></h5></td>
                                                <td class="w-200p"><h5> M.R.No.</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="appointment && appointment.umrNo" [textContent]="appointment.umrNo"></h5></td>
                                                <td class="w-200p"><h5>IP No.</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="appointment && appointment.appointmentNo" [textContent]="appointment.appointmentNo"></h5></td>
                                            </tr>
                                            <tr>
                                                <td class="w-200p"><h5> Ward/Bed No</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="appointment && appointment.bedId" [textContent]="appointment.bedId"></h5></td>
                                                <td class="w-200p"><h5> Date</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="appointment && appointment.appointmentDate" [textContent]="appointment.appointmentDate| date:'dd/MM/yyyy' "></h5></td>
                                                <td class="w-200p"><h5> Age</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="appointment && appointment.patientAge" [textContent]="appointment.patientAge"></h5></td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="border-top border-dark">
                                        <table>
                                            <tr>
                                                <td><h5>Diagnosis</h5></td>
                                                <td><h5>:</h5></td>
                                                <td><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.diagnosis">{{neonatalNurseResource.json.diagnosis}}</h5></td>

                                            </tr>
                                            <tr>
                                                <td class="w-200p"><h5>LMP</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.lmp">{{neonatalNurseResource.json.lmp}}</h5></td>
                                                <td class="w-200p"><h5>EDD</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.edd">{{neonatalNurseResource.json.edd}}</h5></td>
                                                <td class="w-200p"><h5>Drug</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.drugAllergy">{{neonatalNurseResource.json.drugAllergy}}</h5></td>
                                            </tr>
                                            <tr>
                                                <td class="w-250p"><h5>Initial Nursing Assessment by</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.drugAllergy">{{neonatalNurseResource.json.drugAllergy}}</h5></td>
                                                <td class="w-200p"><h5>Ward in Time</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.drugAllergy">{{neonatalNurseResource.json.drugAllergy}}</h5></td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="border-top border-dark">
                                        <div class=" flex-grow-1">
                                            <div class=" d-flex flex-wrap my-2">
                                                <h4 class="pl-0 col-6">INITIAL ASSESSMENT</h4>
                                                <h4 class="pl-0 col-2" style="text-align:center"> Risk factors </h4>
                                            </div>
                                        </div>
                                        <table class="w-100">
                                            <tr>
                                                <td class="w-200p"><h5>1.Appearance</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.apperance">{{neonatalNurseResource.json.apperance}}</h5></td>
                                                <td class="w-200p"><h5>11.Anaemia</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.anaemia">{{neonatalNurseResource.json.anaemia}}</h5></td>
                                                <td class="w-200p"><h5>21.Blood Transfusion</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.bloodTranfusion">{{neonatalNurseResource.json.bloodTranfusion}}</h5></td>
                                            </tr>
                                            <tr>
                                                <td class="w-200p"><h5>2.Level of consciousness</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.levelOfConcious">{{neonatalNurseResource.json.levelOfConcious}}</h5></td>
                                                <td class="w-200p"><h5>12.Bad Obsteric History</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.badObestricHistory">{{neonatalNurseResource.json.badObestricHistory}}</h5></td>
                                                <td class="w-200p"><h5>22.Cardiac Disease</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.cardiacDisease">{{neonatalNurseResource.json.cardiacDisease}}</h5></td>
                                            </tr>
                                            <tr>
                                                <td class="w-200p"><h5>3.Build</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.build">{{neonatalNurseResource.json.build}}</h5></td>
                                                <td class="w-200p"><h5>13.Induced Labour</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.inducedLabour">{{neonatalNurseResource.json.inducedLabour}}</h5></td>
                                                <td class="w-200p"><h5>23.Hypertension</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.hypertension">{{neonatalNurseResource.json.hypertension}}</h5></td>
                                            </tr>
                                            <tr>
                                                <td class="w-200p"><h5>4.Nutrition</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.nutrition">{{neonatalNurseResource.json.nutrition}}</h5></td>
                                                <td class="w-200p"><h5>14.Multiple Pregnancies</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.multiplePregnancies">{{neonatalNurseResource.json.multiplePregnancies}}</h5></td>
                                                <td class="w-200p"><h5>24.Renal Disease</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.renalDisease">{{neonatalNurseResource.json.renalDisease}}</h5></td>
                                            </tr>
                                            <tr>
                                                <td class="w-200p"><h5>5.Pedal Oedema</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.pedalOedema">{{neonatalNurseResource.json.pedalOedema}}</h5></td>
                                                <td class="w-200p"><h5>15.Blood Transfusion</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.bloodTranfusion">{{neonatalNurseResource.json.bloodTranfusion}}</h5></td>
                                                <td class="w-200p"><h5>25.Diabetes Mellitus</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.diabetesMelitus">{{neonatalNurseResource.json.diabetesMelitus}}</h5></td>
                                            </tr>
                                            <tr>
                                                <td class="w-200p"><h5>6.Uterine Contractions</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.uterineContraction">{{neonatalNurseResource.json.uterineContraction}}</h5></td>
                                                <td class="w-200p"><h5>16.Post dated Pregnancy</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.postDatedPregnancy">{{neonatalNurseResource.json.postDatedPregnancy}}</h5></td>
                                                <td class="w-200p"><h5>26.Hepatitis</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.hepatitis">{{neonatalNurseResource.json.hepatitis}}</h5></td>
                                            </tr>
                                            <tr>
                                                <td class="w-200p"><h5>7.FHS per min</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.fhs">{{neonatalNurseResource.json.fhs}}</h5></td>
                                                <td class="w-200p"><h5>17.Rh Negative</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.rhNegative">{{neonatalNurseResource.json.rhNegative}}</h5></td>
                                                <td class="w-200p"><h5> 27.Respiratory Disease</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.respiratoryDisease">{{neonatalNurseResource.json.respiratoryDisease}}</h5></td>
                                            </tr>
                                            <tr>
                                                <td class="w-200p"><h5>8.Draining</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.draining">{{neonatalNurseResource.json.draining}}</h5></td>
                                                <td class="w-200p"><h5>18.Iugr</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.iugr">{{neonatalNurseResource.json.iugr}}</h5></td>
                                                <td class="w-200p"><h5>28.Fibroid Uterus</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.fibroidUterus">{{neonatalNurseResource.json.fibroidUterus}}</h5></td>
                                            </tr>
                                            <tr>
                                                <td class="w-200p"><h5>9.Urine Albumin</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.uterineAlbumine">{{neonatalNurseResource.json.uterineAlbumine}}</h5></td>
                                                <td class="w-200p"><h5>19.Previous LSCS</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.previousLscs">{{neonatalNurseResource.json.previousLscs}}</h5></td>
                                                <td class="w-200p"><h5>29.Previous Uterine</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.previousUterine">{{neonatalNurseResource.json.previousUterine}}</h5></td>
                                            </tr>
                                            <tr>
                                                <td class="w-200p"><h5>10.Pallor</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.pallor">{{neonatalNurseResource.json.pallor}}</h5></td>
                                                <td class="w-200p"><h5>20.Cerclage</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.cerclage">{{neonatalNurseResource.json.cerclage}}</h5></td>
                                                <td class="w-200p"><h5>30.Other Operations</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.otherOperations">{{neonatalNurseResource.json.otherOperations}}</h5></td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div style="margin-top:10px">
                                        <h4>VITAL PARAMETERS</h4>
                                        <table class="w-100 border-black" border="1">
                                            <tr>
                                                <td><h4>Temp(F)</h4></td>
                                                <td><h4>Pulse/min</h4></td>
                                                <td><h4>BP</h4></td>
                                                <td><h4>Resp rate</h4></td>
                                                <td><h4>Height</h4></td>
                                                <td><h4>Weight (Kg)</h4></td>
                                                <td><h4>BMI</h4></td>
                                            </tr>
                                            <tr>
                                                <td><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.temp">{{neonatalNurseResource.json.temp}}</h5></td>
                                                <td><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.pulse">{{neonatalNurseResource.json.pulse}}</h5></td>
                                                <td><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.bp">{{neonatalNurseResource.json.bp}}</h5></td>
                                                <td><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.resp">{{neonatalNurseResource.json.resp}}</h5></td>
                                                <td><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.height">{{neonatalNurseResource.json.height}}</h5></td>
                                                <td><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.weight">{{neonatalNurseResource.json.weight}}</h5></td>
                                                <td><h5 *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.bmi">{{neonatalNurseResource.json.bmi}}</h5></td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div>
                                        <div class="d-flex justify-content-start mt-2">
                                            <h4>PAIN RATING SCALE :</h4>
                                            <h4 class="ml-2" *ngIf="neonatalNurseResource.json" [textContent]="neonatalNurseResource.json.painScalingRateing"></h4>
                                        </div>
                                        <div>
                                            <div class="d-flex justify-content-around">
                                                <div>
                                                    <label [ngClass]="{'selectedEmogi':(neonatalNurseResource.json && neonatalNurseResource.json.painScalingRate==='No Pain')}" for="No Pain"><img src="assets/images/genetic-images/1.png" alt="3" width="100" height="100" /></label>
                                                </div>
                                                <div>
                                                    <label [ngClass]="{'selectedEmogi':(neonatalNurseResource.json && neonatalNurseResource.json.painScalingRate==='Mild Pain')}" for="Mild Pain"><img src="assets/images/genetic-images/2.png" alt="3" width="100" height="100" /></label>
                                                </div>
                                                <div>
                                                    <label [ngClass]="{'selectedEmogi':(neonatalNurseResource.json && neonatalNurseResource.json.painScalingRate==='Moderate Pain')}" for="Moderate Pain"><img src="assets/images/genetic-images/3.png" alt="3" width="100" height="100" /></label>
                                                </div>
                                                <div>
                                                    <label [ngClass]="{'selectedEmogi':(neonatalNurseResource.json && neonatalNurseResource.json.painScalingRate==='Severe Pain')}" for="Severe Pain"><img src="assets/images/genetic-images/4.png" alt="3" width="100" height="100" /></label>
                                                </div>
                                                <div>
                                                    <label [ngClass]="{'selectedEmogi':(neonatalNurseResource.json && neonatalNurseResource.json.painScalingRate==='Very Severe Pain')}" for="Very Severe Pain"><img src="assets/images/genetic-images/5.png" alt="3" width="100" height="100" /></label>
                                                </div>
                                                <div>
                                                    <label [ngClass]="{'selectedEmogi':(neonatalNurseResource.json && neonatalNurseResource.json.painScalingRate==='Worst Pain Possible')}" for="Worst Pain Possible"><img src="assets/images/genetic-images/6.png" alt="3" width="100" height="100" /></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="border-dark border-bottom">
                                        <table>
                                            <tr>
                                                <td><h4>NURSING PLAN OF CARE</h4></td>
                                            </tr>
                                            <tr *ngFor="let item of  neonatalNurseResource.json.nursingPlanOfCare">
                                                <td><h5>{{item}}</h5></td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="row ">
                                        <div class="col-4"><h5>Frequency To Monitoring : <span *ngIf="neonatalNurseResource.json && neonatalNurseResource.json.frequenceOf">{{neonatalNurseResource.json.frequenceOf}}</span></h5></div>
                                        <div class="col-4"></div>
                                        <div class="col-4"></div>
                                    </div>

                                    <footer class="border-dark border-top font-13 justify-content-between" style="margin-top:10px">
                                        <div class="font-14 flex-grow-1">
                                            <div class=" d-flex flex-wrap">
                                                <h5 class="pl-0 col-6">Print Date/Time :<span [textContent]="date | date :'d/MM/yyyy'"></span></h5>
                                                <h5 class="pl-0 col-6">User Name :<span *ngIf="appointment && appointment.providerName" [textContent]="appointment.providerName"></span></h5>
                                            </div>
                                        </div>
                                        <div class="font-14 flex-grow-1">
                                            <div class="d-flex flex-wrap">
                                                <div><span>Signature :&nbsp;</span></div>
                                                <div><h5 *ngIf="appointment && appointment.signature" class="border-bottom border-dark"><img *ngIf="appointment.signature!=null" [src]="safe(appointment.signature)" cssClass="img-fluid rounded-circle" style="height:80px" /></h5></div>
                                            </div>
                                        </div>
                                    </footer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngFor="let item of admissionReport">
                    <div class="border-dark border-top " *ngIf="fetchNurseNotesRecords(item.admissionId).length > 0">
                        <div style="margin-top:20px;">
                            <div class="d-flex justify-content-between mt-4 col-12 p-0">
                                <div class="flex-grow-1 d-flex col-12">
                                    <h3 class="d-flex position-absolute justify-content-xl-center" style="bottom: 0px; width: 100%; color:black;">
                                        NURSES NOTES
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="border-top border-dark">
                            <div class="col-12 p-0 pb-1 pt-1">
                                <table class="w-100 mt-2">
                                    <tr>
                                        <td class="white-space-nowrap">
                                            <span>Name</span>
                                        </td>
                                        <td>
                                            <h5>:</h5>
                                        </td>
                                        <td>
                                            <h5 class="pl-2" *ngIf="appointment && appointment.patientName" [textContent]="appointment.patientName">
                                            </h5>
                                        </td>
                                        <td>
                                            <span>Mr No</span>
                                        </td>
                                        <td>
                                            <h5>:</h5>
                                        </td>
                                        <td>
                                            <h5 class="pl-2" *ngIf="appointment && appointment.umrNo" [textContent]="appointment.umrNo"></h5>
                                        </td>
                                        <td>
                                            <span>IP No </span>
                                        </td>
                                        <td>
                                            <h5>:</h5>
                                        </td>
                                        <td>
                                            <h5 class="pl-2" *ngIf="appointment && appointment.appointmentNo" [textContent]="appointment.appointmentNo">
                                            </h5>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 mb-4">
                                    <div class="table-responsive">
                                        <table class="table table-centered table-sm table-striped table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>Item</th>
                                                    <th>Bed No</th>
                                                    <th>Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container>
                                                    <ng-container *ngFor="let note of fetchNurseNotesRecords(item.admissionId)">
                                                        <tr>
                                                            <td [textContent]="(note.date || note.createdDate) | date:'dd-MM-yyyy'"></td>
                                                            <td>
                                                                <small [textContent]="note.createdDate | date:'dd-MM-yyyy, hh:mm a'"></small>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex">
                                                                    <div [textContent]="note.note"></div>
                                                                </div>
                                                            </td>
                                                            <td [textContent]="note.bedNumber">
                                                            </td>
                                                            <td *ngIf="appointment && appointment.bedId" [textContent]="note.createdByName"></td>
                                                            <td>
                                                                <div>
                                                                    <div class="media">
                                                                        <div class="media-body">
                                                                            <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="note.createdByName"></h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngFor="let item of admissionReport">
                    <div>
                        <div>
                            <div>
                                <div class="mt-2">
                                    <h4 style="text-align:center">Patient Orders And Action Sheet </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container>
                        <table class="w-100 white-space-nowrap" *ngIf="fetchOrderReports(item.admissionId).length > 0">
                            <ng-container *ngFor="let order of fetchOrderReports(item.admissionId)">
                                <tr>
                                    <td>
                                        <span>Day of Admission</span>
                                    </td>
                                    <td>
                                        <h5>:</h5>
                                    </td>
                                    <td>
                                        <span [textContent]="order.admissionDate | date:'dd-MM-yyyy , hh:mm a'"></span>
                                    </td>
                                    <td>
                                        <span>Doctor Name</span>
                                    </td>
                                    <td>
                                        <h5>:</h5>
                                    </td>
                                    <td>
                                        <span [textContent]="order.providerName"></span>
                                    </td>
                                    <td>
                                        <span>Department</span>
                                    </td>
                                    <td>
                                        <h5>:</h5>
                                    </td>
                                    <td class="text-lowercase">
                                        <span [textContent]="order.specializationName"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Patient Name</span>
                                    </td>
                                    <td>
                                        <h5>:</h5>
                                    </td>
                                    <td>
                                        <span [textContent]="order.patientName"></span>
                                    </td>
                                    <td>
                                        <span>UMR No</span>
                                    </td>
                                    <td>
                                        <h5>:</h5>
                                    </td>
                                    <td>
                                        <span [textContent]="order.patientNo"></span>
                                    </td>
                                    <td>
                                        <span>IPNO</span>
                                    </td>
                                    <td>
                                        <h5>:</h5>
                                    </td>
                                    <td>
                                        <span [textContent]="order.admissionNo"></span>
                                    </td>
                                </tr>
                                <tr class="col-12">
                                    <td>
                                        <span>Date of Ordering</span>
                                    </td>
                                    <td>
                                        <h5>:</h5>
                                    </td>
                                    <td>
                                        <span class="d-block pl-1 text-truncate" [textContent]="order.createdDate | date:'dd-MM-yyyy'"></span>
                                    </td>
                                    <td>
                                        <span>Bed no/ward no</span>
                                    </td>
                                    <td>
                                        <h5>:</h5>
                                    </td>
                                    <td class="w-200p text-uppercase">
                                        <span [textContent]="order.bedNumber"></span>
                                    </td>
                                    <td>
                                        <span class="">Date of printing</span>
                                    </td>
                                    <td>
                                        <h5>:</h5>
                                    </td>
                                    <td>
                                        <span [textContent]="date | date :'d-MM-yyyy'"></span>
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                        <div class="border-top2px">
                            <h5>Runining Medications</h5>
                            <div>
                                <div class="overflow-auto mt-1 mb-1">
                                    <table class="w-100" style="border-collapse: collapse;" *ngIf="fetchmedicinesRecords(item.admissionId).length > 0">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Start Date</th>
                                                <th>Start Date Day</th>
                                                <th>Frequency</th>
                                                <th>Remarks</th>
                                                <th>Route</th>
                                                <th>Order Time</th>
                                                <th>Ordered By</th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div>
                                                        <div [textContent]=" createdDate | date:'dd-mm-yyyy'"></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let records of fetchmedicinesRecords(item.admissionId)">
                                            <tr>
                                                <td>
                                                    <div>
                                                        <div class="text-lowercase" [textContent]="records.productName"></div>
                                                    </div>
                                                <td>
                                                <td>
                                                    <div>
                                                        <div [textContent]="records.createdDate | date:'dd-mm-yyyy'"></div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <span class="text-lowercase" [textContent]="records.instruction"></span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <span class="text-lowercase" [textContent]="records.duration"></span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <span class="text-lowercase" [textContent]="records.remark"></span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <span class="text-lowercase" [textContent]="records.route"></span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <span class="text-lowercase" [textContent]="records.createdDate | date:'hh:mm a'"></span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <span class="text-lowercase" [textContent]="records.createdByName"></span>
                                                    </div>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>

                                    <div></div>
                                    <h5>Lab Test</h5>
                                    <table class="w-100">
                                        <tr>
                                            <th>Test Name</th>
                                            <th></th>
                                            <th>Start Date</th>
                                        </tr>
                                        <tbody>
                                            <tr *ngFor="let item of fetchLabRecords(item.admissionId)">
                                                <td>
                                                    <div>
                                                        <div class="text-lowercase" [textContent]="item.testName"></div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <span class="text-lowercase"></span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <span class="text-lowercase" [textContent]="item.testCode"></span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="border-dark border-top col-12 p-0 pt-1 row justify-content-between">

                        <div class="d-flex">
                            <div><span>Print Date & Time :&nbsp;</span></div>
                            <div><span [textContent]="date | date :'d/MM/yyyy'"></span>, &nbsp;<span [textContent]="date | date :' h:mm a'"></span></div>
                        </div>
                    </div>

                </div>


                <div class="page-break-after-always" style=""></div>
                <div class="mt-3">
                    <!--Start INTRA PARTUM DETAILS  -->

                    <div class="mt-3" *ngFor="let item of admissionReport">
                        <div class="printshow">
                            <banner-setting></banner-setting>
                        </div>
                        <div id="obprintContainer" class="font-weight-semibold text-dark">
                            <div border-left border-right mb-0>
                                <div class="row" *ngIf="page.loading">
                                    <div class="col-12">
                                        <div class="d-flex align-items-center"> <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span> <span class="ml-2">Please wait while generating IEncounter Prescription ...</span> </div>
                                    </div>
                                </div>
                                <div class="text-right">
                                    <h3 class="font-weight-bold">UNIT 2</h3>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <h5 class="font-weight-bold "> FOR PATIENTS IN <br />LABOUR ONLY </h5>
                                    <h3 class="font-weight-bold">INTRA PARTUM DETAILS </h3>
                                    <div class="d-flex justify-content-end">
                                        <div class="border border-dark">
                                            <h6 class="m-1">Robson's Group</h6>
                                            <h6 class="border-dark border-top m-0 p-1 text-center">3</h6>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <table class="w-100 table-striped white-space-nowrap pl-0">
                                            <tr class="col-12">
                                                <td class="w-150px"><h5 class="font-weight-bold m-0">Patient Name </h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p"><span class="d-block pl-1" *ngIf="appointment && appointment.patientName" [textContent]="appointment.patientName"></span></td>
                                                <td class="w-150px"><h5 class="font-weight-bold m-0">MR No</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p "><span class="d-block pl-1" *ngIf="appointment && appointment.umrNo">{{appointment.umrNo}}</span></td>
                                                <td class="w-150px"><h5 class="font-weight-bold  m-0">IP No</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p "><span class="d-block pl-1" *ngIf="appointment && appointment.appointmentNo">{{appointment.appointmentNo}}</span></td>
                                                <td class="w-150px"><h5 class="font-weight-bold m-0">Room No</h5></td>
                                                <td><h5>:</h5></td>
                                                <td class="w-200p "><span class="d-block pl-1" *ngIf="appointment && appointment.roomName">{{appointment.roomName}}</span></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div>
                                            <div class="border border-dark">
                                                <h5 style="color:black;text-align:center" class="font-weight-bold m-1">LABOUR</h5>
                                            </div>
                                            <div class="form-group d-flex mb-1 mt-2">
                                                <table class="w-100 table-striped">
                                                    <tr class="col-12">
                                                        <td class="w-200p"><h5>Labour</h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p" colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.labourType" [textContent]="item.deliverydetails.labourType"></h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> IOL Agent </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.iolAgent">{{item.deliverydetails.iolAgent}}</h5></td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="w-200p"><h5 class="m-0">Indication of IOL</h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p" colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.indicationOfIOL" [textContent]="item.deliverydetails.indicationOfIOL"></h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Augmentation </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.augmentation">{{item.deliverydetails.augmentation}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Indication for Oxytocin </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.indicationForOxytocin">{{item.deliverydetails.indicationForOxytocin}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Memb. Rupture Type </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.membRupture">{{item.deliverydetails.membRupture}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Presentation </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.presentation">{{item.deliverydetails.presentation}}</h5></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="border border-dark">
                                                <h5 class="font-weight-bold m-1 text-center">DELIVERY DETAILS</h5>
                                            </div>
                                            <div class="form-group d-flex mb-1 mt-2">
                                                <table class="w-100 table-striped">
                                                    <tr class="col-12">
                                                        <td class="w-200p"><h5 class="m-0">Anaesthesia</h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p" colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.anaesthesia" [textContent]="item.deliverydetails.anaesthesia"></h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Non-Epidural </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.nonEpidural" [textContent]="item.deliverydetails.nonEpidural"></h5></td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="w-200p"><h5 class="m-0">Del. Type</h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p" colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.deliveryType" [textContent]="item.deliverydetails.deliveryType"></h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Trial of Forceps </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.trialOfForceps">{{item.deliverydetails.trialOfForceps}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Sequential </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.sequential">{{item.deliverydetails.sequential}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Place of Instrument </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.placeOfInstrument">{{item.deliverydetails.placeOfInstrument}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Indication </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.indicationforavd">{{item.deliverydetails.indicationforavd}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Application, Locking & Duration of </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.applLockAndTraction">{{item.deliverydetails.applLockAndTraction}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> No. of Pulls </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.noofPulls">{{item.deliverydetails.noofPulls}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Catheterized Bladder </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.catheterized">{{item.deliverydetails.catheterized}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> If Yes ,Catheterized type </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.catheterType">{{item.deliverydetails.catheterType}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Perineum </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.perineum">{{item.deliverydetails.perineum}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Episiotomy </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.episiotomy">{{item.deliverydetails.episiotomy}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Indication </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.indicationepisiotomy">{{item.deliverydetails.indicationepisiotomy}}</h5></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="border border-dark">
                                                <h5 class="font-weight-bold m-1 text-center">BIRTH LACERATIONS</h5>
                                            </div>
                                            <div class="form-group d-flex mb-1 mt-2">
                                                <table class="w-100 table-striped">
                                                    <tr class="col-12">
                                                        <td class="w-200p"><h5 class="m-0">Vaginal</h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p" colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.vaginal" [textContent]="item.deliverydetails.vaginal"></h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Paraurethral </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.paraurethral">{{item.deliverydetails.paraurethral}}</h5></td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="w-200p"><h5 class="m-0">Cervical</h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p" colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.cervical" [textContent]="item.deliverydetails.cervical"></h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Perineal </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.perineal">{{item.deliverydetails.perineal}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Suture Material Used </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.SutureMaterialUsed">{{item.deliverydetails.SutureMaterialUsed}}</h5></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="border border-dark">
                                                <h5 class="font-weight-bold m-1 text-center">DURATION OF LABOUR</h5>
                                            </div>
                                            <div class="form-group d-flex mb-1 mt-2">
                                                <table class="w-100 table-striped">
                                                    <tr class="col-12">
                                                        <td class="w-200p"><h5 class="m-0">Ist Stage (min)</h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p" colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.Iststage" [textContent]="item.deliverydetails.Iststage"></h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> IInd Stage (min) </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.IIndstage">{{item.deliverydetails.IIndstage}}</h5></td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="w-200p"><h5 class="m-0">IIIrd  Stage (min)</h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p" colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.IIIrdstage" [textContent]="item.deliverydetails.IIIrdstage"></h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> No. of VEs </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.noofVEs">{{item.deliverydetails.noofVEs}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> No. of VEs After ARM </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.noVEAfterARMSROM">{{item.deliverydetails.noVEAfterARMSROM}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Duration of Active </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.durationOfActivePushing">{{item.deliverydetails.durationOfActivePushing}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Others </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.others">{{item.deliverydetails.others}}</h5></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="border border-dark">
                                                <h5 class="font-weight-bold m-1 text-center">BABY DETAILS-1</h5>
                                            </div>
                                            <div class="form-group d-flex mb-1 mt-2">
                                                <table class="w-100 table-striped">
                                                    <tr class="col-12">
                                                        <td class="w-200p"><h5 class="m-0">Sex</h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p" colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.sexOfBaby" [textContent]="item.deliverydetails.sexOfBaby"></h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Weight </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.babyBirthWeight">{{item.deliverydetails.babyBirthWeight}}</h5></td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="w-200p"><h5 class="m-0">Apgar</h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p" colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.apgar" [textContent]="item.deliverydetails.apgar"></h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Date Of Delivery </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.dateOfDelivery">{{item.deliverydetails.dateOfDelivery}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Time </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.timeOfDelivery">{{item.deliverydetails.timeOfDelivery}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Birthed By </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.birthedBy">{{item.deliverydetails.birthedBy}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Name </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.birthedByName">{{item.deliverydetails.birthedByName}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Suturing by </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.suturingBy">{{item.deliverydetails.suturingBy}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Name </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.name">{{item.deliverydetails.name}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Stand By Doctor </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.standByDoctor">{{item.deliverydetails.standByDoctor}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Occipito </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.occipito">{{item.deliverydetails.occipito}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Delayed Cord Clamping </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.cordClampingTime">{{item.deliverydetails.cordClampingTime}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Skin To Skin </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.skinToSkinBabyCare">{{item.deliverydetails.skinToSkinBabyCare}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Cord Abg </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.cordabg">{{item.deliverydetails.cordabg}}</h5></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div>
                                            <h5 class="font-weight-bold mt-3">UMBILICAL CORD BLOOD GASES</h5>
                                            <div class="form-group mb-1 d-flex mt-2">
                                                <table class="w-100 table-striped">
                                                    <tr class="col-12">
                                                        <td class="w-200p"><h5 class="m-0">Date</h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p" colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.date" [textContent]="item.deliverydetails.date"></h5></td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="w-200p"><h5 class="m-0">Time of Testing</h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p" colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.timeoftesting" [textContent]="item.deliverydetails.timeoftesting"></h5></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div>
                                            <h5 class="text-uppercase text-black">Indication for Requesting Cord Blood Gas</h5>
                                            <div class="form-group mb-1 d-flex mt-2">
                                                <table class="w-100 table-striped">
                                                    <tr class="col-12">
                                                        <td class="w-200p"><h5 class="m-0">PH</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Arterial </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.phArterial">{{item.deliverydetails.phArterial}}</h5></td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="w-200p"><h5 class="m-0">Venous </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p" colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.phVenous" [textContent]="item.deliverydetails.phVenous"></h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5>BE ECF</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Arterial </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.beecfarterial">{{item.deliverydetails.beecfarterial}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Venous </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.beecVenous">{{item.deliverydetails.beecVenous}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5>Lactate</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Arterial </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.lactatearterial">{{item.deliverydetails.lactatearterial}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Venous </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.lactatevenous">{{item.deliverydetails.lactatevenous}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5>PO2</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Arterial </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.po2arterial">{{item.deliverydetails.po2arterial}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Venous </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.po2venous">{{item.deliverydetails.po2venous}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5>PCO2</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Arterial </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.pco2arterial"> {{item.deliverydetails.pco2arterial}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Venous </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.pco2venous">{{item.deliverydetails.pco2venous}}</h5></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div>
                                            <div class="border border-dark">
                                                <h5 style="color:black;text-align:center" class="font-weight-bold m-1">INTRAPARTUM COMPLICATIONS</h5>
                                            </div>
                                            <div class="form-group d-flex mb-1 mt-2 ">
                                                <table class="w-100 table-striped">
                                                    <tr class="col-12">
                                                        <td class="w-200p"><h5 class="m-0">Maternal Complication</h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p" colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.maternalComplication" [textContent]="item.deliverydetails.maternalComplication"></h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Amniotic Fluid Volume </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.amnioticFluidVolume">{{item.deliverydetails.amnioticFluidVolume}}</h5></td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="w-200p"><h5 class="m-0">Amniotic Fluid Colour </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p" colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.amnioticFluidColour" [textContent]="item.deliverydetails.amnioticFluidColour"></h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Shoulder Dystocia </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.shoulderDystocia">{{item.deliverydetails.shoulderDystocia}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> If yes, Manouvers </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.ifYesManouvers">{{item.deliverydetails.ifYesManouvers}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Shoulder Dystocia Form </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.shoulderDystociaFormFilled">{{item.deliverydetails.shoulderDystociaFormFilled}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> PPH </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.pph">{{item.deliverydetails.pph}}</h5></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="border border-dark">
                                                <h5 style="color:black;text-align:center" class="font-weight-bold m-1">STAGE III</h5>
                                            </div>
                                            <div class="form-group d-flex mb-1 mt-2 ">
                                                <table class="w-100 table-striped">
                                                    <tr class="col-12">
                                                        <td class="w-200p"><h5 class="m-0">Placenta</h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p" colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.placenta" [textContent]="item.deliverydetails.placenta"></h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Placenta Dtl </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.placentadtls">{{item.deliverydetails.placentadtls}}</h5></td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="w-200p"><h5 class="m-0">Placenta Delivery </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p" colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.placentalDelivery" [textContent]="item.deliverydetails.placentalDelivery"></h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Weight of Placenta(gm) </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.PlacentaWt">{{item.deliverydetails.PlacentaWt}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Cord </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.cord">{{item.deliverydetails.cord}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Cord Length (cm) </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.cordLength">{{item.deliverydetails.cordLength}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Prophylaxis </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.pphprophylaxis">{{item.deliverydetails.pphprophylaxis}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Blood Loss (ml) </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.bloodLoss">{{item.deliverydetails.bloodLoss}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Blood Transfusion </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.bloodTransfusion">{{item.deliverydetails.bloodTransfusion}}</h5></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="border border-dark">
                                                <h5 style="color:black;text-align:center" class="font-weight-bold m-1">Anal Sphincter Details</h5>
                                            </div>
                                            <div class="form-group d-flex mb-1 mt-2 ">
                                                <table class="w-100 table-striped">
                                                    <tr class="col-12">
                                                        <td class="w-200p"><h5 class="m-0">Pre Suturing PR</h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p" colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.preSuturingPR" [textContent]="item.deliverydetails.preSuturingPR"></h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Post Suturing PR </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.postSuturingPR">{{item.deliverydetails.postSuturingPR}}</h5></td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="w-200p"><h5 class="m-0"> Anal Spincter Condition </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p" colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.analSpincterCondition" [textContent]="item.deliverydetails.analSpincterCondition"></h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Other details(If Any) </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.otherDetails">{{item.deliverydetails.otherDetails}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> OASI </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.oasi">{{item.deliverydetails.oasi}}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-200p"><h5 class="m-0"> Others </h5></td>
                                                        <td><h6 class="m-0">:</h6></td>
                                                        <td class="w-200p " colspan="7"><h5 class="d-block w-200p pl-1" *ngIf="item.deliverydetails && item.deliverydetails.others">{{item.deliverydetails.others}}</h5></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div *ngIf="records && records.length > 0">
                                        <div class="dashboard-body">
                                            <div *ngFor="let record of fetchMedRecords(item.admissionId);index as j;">
                                                <h5 class="text-uppercase text-black my-2">Post Operative Orders</h5>
                                                <div class="overflow-auto mt-1 mb-1">
                                                    <table border="1" class="w-100" style="border-collapse: collapse;">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <h4>Medications Adviced </h4>
                                                                </th>
                                                                <th><h4>Generic Name</h4></th>
                                                                <th><h4>Dosage</h4></th>
                                                                <th><h4>Category</h4></th>
                                                                <th><h4>Qty</h4></th>
                                                                <th><h4>Remarks</h4></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                            <tr *ngFor="let item of record.medicines; let i = index">
                                                                <td class="min-width200px">
                                                                    <h5 class=" text-wrap" [textContent]="item.productName"></h5>
                                                                    <br />
                                                                    <h5 class=" text-wrap" *ngIf="item.genericName" [textContent]="'( ' + item.genericName + ' )'"></h5>
                                                                </td>
                                                                <td class="min-width200px"><h5 class=" text-wrap" *ngIf="item.genericName" [textContent]="'( ' + item.genericName + ' )'"></h5></td>
                                                                <td><h5 class=" text-wrap"> <span *ngIf="item.instruction" [textContent]="item.instruction"></span> </h5></td>
                                                                <td><h5 class=" text-wrap"> <span *ngIf="item.categoryName" [textContent]="item.categoryName"></span> </h5></td>
                                                                <td><h5 class=" text-wrap"> <span *ngIf="item.dosage" [textContent]="item.dosage"></span> </h5></td>
                                                                <td><h5 class=" text-wrap"> <span *ngIf="item.remark" [textContent]="item.remark"></span> </h5></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="fetchOrderPrescriptionRecords(item.admissionId).length > 0">
                                        <div *ngFor="let item of fetchOrderPrescriptionRecords(item.admissionId)">
                                            <div *ngIf="item.orderName==='Fetal Plan'">
                                                <div class="w-100 dashboard-break" *ngIf="item.comments && item.comments !== null">
                                                    <h5 class="dashboard-title  m-0" *ngIf="item.comments && item.comments !== null"><u>Plan of Management - Fetus</u></h5>
                                                    <table class="table table-centered table-nowrap table-sm table-striped transcipt">
                                                        <tr class="pl-1" [innerHTML]="item.comments"> </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div *ngIf="item.orderName==='Maternal Plan'">
                                                <div class="w-100 dashboard-break" *ngIf="item.comments && item.comments !== null">
                                                    <h5 class="dashboard-title font-17 m-0" *ngIf="item.comments && item.comments !== null"><u>Plan of Management - Mother</u></h5>
                                                    <table class="table table-centered table-nowrap table-sm table-striped transcipt">
                                                        <tr class="pl-1" [innerHTML]="item.comments"></tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <footer class="border-dark border-top justify-content-between">
                                    <div class="d-flex flex-md-nowrap flex-wrap font-weight-semibold justify-content-between mt-1">
                                        <div class="font-14 flex-grow-1">
                                            <div class="font-weight-bold d-flex flex-wrap"> <span class="pl-0 col-6">Saved on Date & Time : </span> <span class="pr-0 col-6">Saved by :</span> <span class="pl-0 col-6">Accessed on Date &Time : </span> <span class="pr-0 col-6">Accessed by :</span> </div>
                                        </div>
                                        <div class="align-self-end justify-content-center d-flex flex-wrap w-200p">
                                            <div class="w-100 text-center">
                                                <img *ngIf="appointment?.signature !=null" [src]="safe(appointment?.signature)" cssClass="img-fluid rounded-circle" style="height:80px" />
                                            </div>
                                            <div class="w-100 text-center"> Signature of Surgeon </div>
                                        </div>
                                    </div>
                                </footer>
                                <div class="border-dark border-top mt-1">
                                    <div><span>Print Date/Time :</span></div>
                                    <div><span [textContent]="date | date :'d/MM/yyyy'"></span><span [textContent]="date | date :' h:mm a'"></span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Start Just trying to help  -->
            <div class="page-break-after-always" style=""></div>
            <div class="mt-3">
                <div class="printshow">
                    <banner-setting></banner-setting>
                </div>
                <div class="text-right">
                    <span class="text-left mr-5 d-inline-block font-weight-bold pr-4">
                        EMR helping you pickup<br _ngcontent-ng-c880207092="">
                        inferences from the data<br _ngcontent-ng-c880207092="">
                        entered
                        <p></p>
                    </span>
                </div>

                <div>
                    <p>Name:<span class="ml-1" [textContent]="obReportData.PatinetName"></span></p>
                    <span>MR No.: <span class="ml-1" [textContent]="obReportData.UMRNo"></span>,</span>
                    <span>ANC No.: <span class="ml-1" [textContent]="obReportData.ANCNo"></span></span>
                    <p> Date: <span class="ml-1" *ngIf="AntenatalVisitDetails && AntenatalVisitDetails.length > 0"> {{ AntenatalVisitDetails[AntenatalVisitDetails.length - 1].AppointmentDate | date:'dd/MM/yyyy' }} </span> </p>
                    <p *ngIf="generalExamination && generalExamination.visitNumber"> <strong>{{ generalExamination.visitNumber }}{{ getOrdinalSuffix(generalExamination.visitNumber) }} ANC today</strong> </p>
                    <p>
                        Total weight gain (current visit weight - prepregnancy weight):
                        <span class="ml-1" [textContent]="totalWeightGain >= 0 ? 'Gain ' + totalWeightGain + ' Kg' : 'Loss ' + getAbsoluteValue(totalWeightGain) + ' Kg'"></span>
                    </p>
                    <p>
                        Weight change since last visit: <span class="ml-1" *ngIf="weightChangeSinceLastVisit !== null"
                                                              [textContent]="weightChangeSinceLastVisit >= 0 ? 'Gain ' + weightChangeSinceLastVisit + ' Kg' : 'Loss ' + getAbsoluteValue(weightChangeSinceLastVisit) + ' Kg'"></span>
                    </p>
                    <div class="border-dark border-top d-flex align-items-center mt-1">
                        <h5 class="font-weight-bold">Past History<span class="topHeading">:</span></h5>
                        <div *ngIf="obHistory && obHistory.length > 0">
                            <ul class="m-0 d-flex flex-wrap" style="gap:25px;">
                                <ng-container *ngFor="let history of obHistory">
                                    <li *ngIf="history.pastHistory && history.pastHistory.length > 0"> <span *ngFor="let item of history.pastHistory">{{ item }}</span> </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                    <div class="border-dark border-top align-items-center mt-1">
                        <h5 class="font-weight-bold my-2">RISK FACTORS</h5>

                        <div class="row">
                            <div class="col" *ngIf="antinantalRisk">
                                <div class="d-flex align-items-baseline font-weight-medium w-100">
                                    <div *ngIf="antinantalRisk &&( antinantalRisk.maternalmedical.length > 0|| antinantalRisk.uterineRiskFactor.length > 0 || antinantalRisk.pregnancyComplications.length > 0 || antinantalRisk.fetusComplications.length > 0 || antinantalRisk.characteristics.length > 0 || antinantalRisk.details.length > 0)">
                                        <div class="d-flex align-items-baseline font-weight-medium w-100">
                                            <span class="white-space-nowrap  m-0 mb-1 d-inline  line-height-1">Antenatal Risk :</span>
                                            <div class="w-100 mb-0 dashboard-break font-14">
                                                <div style="column-gap: 35px;" class="mb-0 line-height-1 text-black">
                                                    <span *ngFor="let item of antinantalRisk.characteristics; let i=index;">
                                                        <span class="pr-1 " *ngIf="item && item !=''" [textContent]="item"></span>
                                                        <span *ngIf="(i != antinantalRisk.characteristics.length-1) && (item && item !='')">,</span>
                                                    </span>
                                                    <span *ngFor="let item of antinantalRisk.details; let i=index;">
                                                        <span class="pr-1 " *ngIf="item && item !=''" [textContent]="item"></span>
                                                        <span *ngIf="(i != antinantalRisk.details.length-1) && (item && item !='')">,</span>
                                                    </span>
                                                    <span *ngFor="let item of antinantalRisk.maternalmedical; let i=index;">
                                                        <span class="pr-1 " *ngIf="item && item !=''" [textContent]="item"></span>
                                                        <span *ngIf="(i !=antinantalRisk.maternalmedical.length-1) && (item && item !='')">,</span>
                                                    </span>
                                                    <span *ngFor="let item of antinantalRisk.uterineRiskFactor; let i=index;">
                                                        <span class="pr-1 " *ngIf="item && item !=''" [textContent]="item"></span>
                                                        <span *ngIf="(i !=  antinantalRisk.uterineRiskFactor.length-1) && (item && item !='')">,</span>
                                                    </span>
                                                    <span *ngFor="let item of antinantalRisk.pregnancyComplications; let i=index;">
                                                        <span class="pr-1 " *ngIf="item && item !=''" [textContent]="item"></span>
                                                        <span *ngIf="(i !=  antinantalRisk.pregnancyComplications.length-1) && (item && item !='')">,</span>
                                                    </span>
                                                    <span *ngFor="let item of antinantalRisk.fetusComplications; let i=index;">
                                                        <span class="pr-1 " *ngIf="item && item !=''" [textContent]="item"></span>
                                                        <span *ngIf="(i != antinantalRisk.fetusComplications.length-1) && (item && item !='')">,</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h6 *ngIf="antinantalRisk && !( antinantalRisk.maternalmedical.length > 0|| antinantalRisk.uterineRiskFactor.length > 0 || antinantalRisk.pregnancyComplications.length > 0 || antinantalRisk.fetusComplications.length > 0 || antinantalRisk.characteristics.length > 0 || antinantalRisk.details.length > 0)" class="white-space-nowrap heading-color3 m-0 mb-1 d-inline line-height-1 mx-1">Antenatal Risk :</h6>

                                    <div class="d-flex align-items-baseline font-weight-medium w-100" *ngIf="antinantalRisk && (antinantalRisk.covidInfection !=null && antinantalRisk.covidInfection !='')">
                                        <span class="white-space-nowrap   m-0 mb-1 d-inline line-height-1 "> Covid-19 Infection :</span>
                                        <span [textContent]="antinantalRisk.covidInfection"></span>
                                    </div>
                                    <div class="d-flex align-items-baseline font-weight-medium w-100" *ngIf="antinantalRisk && (antinantalRisk.otherComplaints !=null && antinantalRisk.otherComplaints !='')">
                                        <span class="white-space-nowrap  m-0 mb-1 d-inline line-height-1 "> Other :</span>
                                        <span [textContent]="antinantalRisk.otherComplaints"></span>
                                    </div>
                                </div>
                                <div class="d-flex align-items-baseline font-weight-medium w-100">
                                    <div *ngIf="antinantalRisk &&( antinantalRisk.meternall.length > 0|| antinantalRisk.latePragnancy.length > 0 || antinantalRisk.currentPragnancy.length > 0 || antinantalRisk.funtalFetaus.length > 0 || antinantalRisk.maternalMedicalHistory.length > 0 || antinantalRisk.previousPregency.length > 0)">
                                        <div class="d-flex align-items-baseline font-weight-medium w-100">
                                            <span class="white-space-nowrap   m-0 mb-1 d-inline line-height-1">SGA Screening   : </span>
                                            <div class="w-100 mb-0 dashboard-break font-14">
                                                <div style="column-gap: 35px;" class="mb-0 line-height-1 text-black">
                                                    <span *ngFor="let item of antinantalRisk.meternall; let i=index;">
                                                        <span class="pr-1 " *ngIf="item && item !=''" [textContent]="item"></span>
                                                        <span *ngIf="(i != antinantalRisk.meternall.length-1) && (item && item !='')">,</span>
                                                    </span>
                                                    <span *ngFor="let item of  antinantalRisk.previousPregency; let i=index;">
                                                        <span class="pr-1 " *ngIf="item && item !=''" [textContent]="item"></span>
                                                        <span *ngIf="(i !=  antinantalRisk.previousPregency.length-1) && (item && item !='')">,</span>
                                                    </span>

                                                    <span *ngFor="let item of antinantalRisk.maternalMedicalHistory; let i=index;">
                                                        <span class="pr-1 " *ngIf="item && item !=''" [textContent]="item"></span>
                                                        <span *ngIf="(i !=  antinantalRisk.maternalMedicalHistory.length-1) && (item && item !='')">,</span>
                                                    </span>
                                                    <span *ngFor="let item of antinantalRisk.funtalFetaus; let i=index;">
                                                        <span class="pr-1 " *ngIf="item && item !=''" [textContent]="item"></span>
                                                        <span *ngIf="(i != antinantalRisk.funtalFetaus.length-1) && (item && item !='')">,</span>
                                                    </span>
                                                    <span *ngFor="let item of antinantalRisk.currentPragnancy; let i=index;">
                                                        <span class="pr-1 " *ngIf="item && item !=''" [textContent]="item"></span>
                                                        <span *ngIf="(i != antinantalRisk.currentPragnancy.length-1) && (item && item !='')">,</span>
                                                    </span>
                                                    <span *ngFor="let item of antinantalRisk.latePragnancy; let i=index;">
                                                        <span class="pr-1 " *ngIf="item && item !=''" [textContent]="item"></span>
                                                        <span *ngIf="(i != antinantalRisk.latePragnancy.length-1) && (item && item !='')">,</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-baseline font-weight-medium w-100">
                                    <div *ngIf="antinantalRisk && (antinantalRisk.highRisk.length > 0||antinantalRisk.intermediateRisk.length > 0|| antinantalRisk.lowRisk.length > 0  )">
                                        <div class="d-flex align-items-baseline font-weight-medium w-100">
                                            <span class="white-space-nowrap   m-0 mb-1 d-inline line-height-1">VTE Risk         : </span>
                                            <div class="w-100 mb-0 dashboard-break font-14">
                                                <div style="column-gap: 35px;" class="line-height-1 mb-0 text-black">
                                                    <span *ngFor="let item of antinantalRisk.highRisk; let i=index;">
                                                        <span class="pr-1 " *ngIf="item && item !=''" [textContent]="item"></span>
                                                        <span *ngIf="(i != antinantalRisk.highRisk.length-1) && (item && item !='')">,</span>
                                                    </span>
                                                    <span *ngFor="let item of antinantalRisk.intermediateRisk; let i=index;">
                                                        <span class="pr-1 " *ngIf="item && item !=''" [textContent]="item"></span>
                                                        <span *ngIf="(i !=  antinantalRisk.intermediateRisk.length-1) && (item && item !='')">,</span>
                                                    </span>
                                                    <span *ngFor="let item of antinantalRisk.lowRisk; let i=index;">
                                                        <span class="pr-1 " *ngIf="item && item !=''" [textContent]="item"></span>
                                                        <span *ngIf="(i != antinantalRisk.lowRisk.length-1) && (item && item !='')">,</span>
                                                    </span>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="d-flex align-items-baseline font-weight-medium w-100" *ngIf="antinantalRisk && (antinantalRisk.comments && antinantalRisk.comments !='')">
                                    <span class="white-space-nowrap  m-0 mb-1 d-inline line-height-1"> VTE Risk Other       : </span>
                                    <span [textContent]="antinantalRisk.comments"></span>
                                </div>

                            </div>
                            <div class="col">
                                <div class="d-flex align-items-baseline font-weight-medium w-100 font-14">
                                    <span class="white-space-nowrap   m-0 mb-1 d-inline line-height-1 mr-1 mb-2">SPECIAL FEATURES: </span>
                                    <div *ngIf="specialFeature">
                                        <div class="line-height-1 mb-0 text-black"> <span class="pr-1 " [textContent]="specialFeature"></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <h4>Abnormal test results</h4>
                <table *ngIf="abnormalTestResults && abnormalTestResults.length > 0" class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Parameter</th>
                            <th>Result</th>
                            <th>Normal Value</th>
                            <th>Units</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of abnormalTestResults; let i = index">
                            <ng-container *ngIf="i === 0 || item.TestName !== abnormalTestResults[i - 1].TestName">
                                <tr>
                                    <td class="testname font-weight-bolder" *ngIf="item && item.TestName" colspan="4" [textContent]="item.TestName"></td>
                                </tr>
                            </ng-container>
                            <tr>
                                <td *ngIf="item && item.ParameterName" [textContent]="item.ParameterName"></td>
                                <td><span class="text-danger" *ngIf="item && item.Result" [textContent]="item.Result"></span></td>
                                <td [textContent]="item.NormalValue"></td>
                                <td *ngIf="item && item.Units" [textContent]="item.Units"> </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <p *ngIf="!abnormalTestResults || abnormalTestResults.length === 0">No abnormal test results available.</p>
            </div>
            <!-- Start ANTENATAL SUMMARY-->
            <div class="page-break-after-always" style=""></div>
            <div class="printshow">
                <banner-setting></banner-setting>
            </div>
            <div>
                <h3 class="text-xl-center" style="bottom: 0px; width: 100%; color:black;"> ANTENATAL SUMMARY </h3>
                <table class="w-100 table-striped white-space-nowrap mt-2">
                    <tr class="col-12" *ngIf="obReportData">
                        <td class="w-200p"><h5> ANC No</h5></td>
                        <td><h5>:</h5></td>
                        <td class="w-200p"><h5 *ngIf="obReportData && obReportData.ANCNo" [textContent]="obReportData.ANCNo"></h5></td>
                        <td class="w-200p"><h5> M.R.No.</h5></td>
                        <td><h5>:</h5></td>
                        <td class="w-200p"><h5 *ngIf="obReportData && obReportData.UMRNo" [textContent]="obReportData.UMRNo"></h5></td>
                        <td class="w-200p"><h5> Consultant</h5></td>
                        <td><h5>:</h5></td>
                        <td class="w-200p"><h5 *ngIf="obReportData && obReportData.ConsultantDoctor" [textContent]="obReportData.ConsultantDoctor"></h5></td>
                        <td class="w-200p"><h5> Booked</h5></td>
                        <td><h5>:</h5></td>
                        <td class="w-200p"><h5 *ngIf="obReportData && obReportData.booked" [textContent]="obReportData.booked"></h5></td>
                    </tr>
                </table>
                <div *ngIf="obReportData" class="border-dark border-top mt-2">
                    <table class="w-100 white-space-nowrap border-top2px border-bottom2px">
                        <tr class="col-12">
                            <td class="w-130"><span class="">Name</span></td>
                            <td><span class="">:</span></td>
                            <td colspan="2"><span class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="obReportData && obReportData.PatinetName" [textContent]="obReportData.PatinetName"></span></td>
                            <td class="w-130"><span class="">Age</span></td>
                            <td><span>:</span></td>
                            <td colspan="2"><span class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="obReportData && obReportData.Age" [textContent]="obReportData.Age"></span></td>
                            <td class="w-130"><span class="">Date of Birth</span></td>
                            <td><span>:</span></td>
                            <td colspan="2"><span class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="obReportData && obReportData.DateOfBirth" [textContent]="obReportData.DateOfBirth | date: 'dd-MM-yyyy'"></span></td>
                        </tr>
                        <tr class="col-12">
                            <td class="w-130"><span class="">Education</span></td>
                            <td><span>:</span></td>
                            <td colspan="2"><span class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="obReportData && obReportData.Education" [textContent]="obReportData.Education"></span></td>
                            <td class="w-130"><span class="">Occupation</span></td>
                            <td><span>:</span></td>
                            <td colspan="2"><span class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="obReportData && obReportData.Occupation" [textContent]="obReportData.Occupation"></span></td>
                        </tr>
                    </table>
                </div>
                <div *ngIf="obReportData" class="border-dark border-top mt-2 ">
                    <table class="w-100 white-space-nowrap border-top2px border-bottom2px">
                        <tr class="col-12">
                            <td class="w-130"><span class=""> Partner's Name</span></td>
                            <td><span class="">:</span></td>
                            <td colspan="2"><span class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="obReportData && obReportData.RelationName" [textContent]="obReportData.RelationName"></span></td>
                            <td class="w-130"><span class=""> Age</span></td>
                            <td><span class="">:</span></td>
                            <td colspan="2"><span class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="obReportData && obReportData.RelationAge" [textContent]="obReportData.RelationAge"></span></td>
                        </tr>
                        <tr class="col-12">
                            <td class="w-130"><span class="">Education </span></td>
                            <td><span class="">:</span></td>
                            <td colspan="2"><span class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="obReportData && obReportData.RelationEducation" [textContent]="obReportData.RelationEducation"></span></td>
                            <td class="w-130"><span class=""> Occupation </span></td>
                            <td><span class="">:</span></td>
                            <td colspan="2"><span class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="obReportData && obReportData.Relationoccupation" [textContent]="obReportData.Relationoccupation"></span></td>
                        </tr>
                    </table>
                </div>
                <div class="border-dark border-top  mt-2">
                    <table class="w-100 border-top2px border-bottom2px">
                        <tr>
                            <td class="align-top"><span class=""> Address </span></td>
                            <td class="align-top">:<span class="pl-1 tb-cnt-align" *ngIf="obReportData && obReportData.address" [textContent]="obReportData.address"></span></td>
                            <td class="align-top"><span class=""> District </span></td>
                            <td class="align-top">:<span class="pl-1 tb-cnt-align" *ngIf="obReportData && obReportData.City" [textContent]="obReportData.City"></span></td>
                            <td class="align-top"><span class=""> State</span></td>
                            <td class="align-top">:<span class="pl-1 tb-cnt-align" *ngIf="obReportData && obReportData.State" [textContent]="obReportData.State"></span></td>
                        </tr>
                        <tr>
                            <td class="align-top"><span class=""> Contact No </span></td>
                            <td class="align-top">:<span class="pl-1 tb-cnt-align" *ngIf="obReportData && obReportData.Mobile" [textContent]="obReportData.Mobile"></span></td>
                            <td class="align-top"><span class=""> Email</span></td>
                            <td class="align-top" colspan="2">:<span class="pl-1 tb-cnt-align" *ngIf="obReportData && obReportData.Email" [textContent]="obReportData.Email"></span></td>
                        </tr>
                        <tr *ngIf="ancCard">
                            <td class="align-top"><span class=""> Time taken to reach hospital </span></td>
                            <td class="align-top">:<span class="pl-1 tb-cnt-align" *ngIf="ancCard && ancCard.timeTakenToReachHospital" [textContent]="ancCard.timeTakenToReachHospital"></span></td>
                            <td class="align-top"><span class=""> Distance in kilometers </span></td>
                            <td class="align-top" colspan="2">:<span class="pl-1 tb-cnt-align" *ngIf="ancCard && ancCard.distanceInKilometers" [textContent]="ancCard.distanceInKilometers"></span></td>
                        </tr>
                    </table>
                </div>
                <div class="border-dark border-top border-bottom  mt-2">
                    <div class="row mt-2" *ngIf="ancCard">
                        <div class="col ">
                            <h3> CURRENT PREGNANCY DETAILS </h3>
                            <table class="w-100 white-space-nowrap">
                                <tr class="col-12">
                                    <td class="w-130"><span class=""> Year of Marriage: </span></td>
                                    <td><span class="">:</span></td>
                                    <td colspan="2"><span class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="ancCard && ancCard.yearOfMarriage" [textContent]="ancCard.yearOfMarriage"></span></td>
                                    <td class="w-130"><span class=""> Living Children: </span></td>
                                    <td><span class="">:</span></td>
                                    <td colspan="2"><span class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="ancCard && ancCard.living" [textContent]="ancCard.living"></span></td>
                                </tr>
                                <tr class="col-12">
                                    <td class="w-130"><span class=""> Gravida </span></td>
                                    <td><span class="">:</span></td>
                                    <td colspan="2"><span class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="ancCard && ancCard.gravida" [textContent]="ancCard.gravida"></span></td>
                                    <td class="w-130"><span class=""> Para </span></td>
                                    <td><span class="">:</span></td>
                                    <td colspan="2"><span class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="ancCard && ancCard.para" [textContent]="ancCard.para"></span></td>
                                </tr>
                                <tr class="col-12">
                                    <td class="w-130"><span class=""> Abortion</span></td>
                                    <td><span class="">:</span></td>
                                    <td colspan="2"><span class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="ancCard && ancCard.abortion" [textContent]="ancCard.abortion"></span></td>
                                    <td class="w-130"><span class=""> Menstrual Pattern </span></td>
                                    <td><span class="">:</span></td>
                                    <td colspan="2"><span class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="ancCard && ancCard.menstrualPattern" [textContent]="ancCard.menstrualPattern"></span></td>
                                </tr>
                                <tr class="col-12">
                                    <td class="w-130"><span class=""> Contraception </span></td>
                                    <td><span class="">:</span></td>
                                    <td colspan="2"><span class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="ancCard && ancCard.contraceptives" [textContent]="ancCard.contraceptives"></span></td>
                                    <td class="w-130"><span class=""> Consanguinity </span></td>
                                    <td><span class="">:</span></td>
                                    <td colspan="2"><span class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="ancCard && ancCard.consanguinity" [textContent]="ancCard.consanguinity"></span></td>
                                </tr>
                            </table>
                        </div>
                        <div class="col mt-4">
                            <table class="w-100 border-dark" border="2">
                                <tr style="height: 70px;">
                                    <td>
                                        LMP
                                        <p>
                                            <strong *ngIf="ancCard && ancCard.lmp" [textContent]="ancCard.lmp | date: 'dd.MM.yyyy'"></strong>
                                        </p>
                                    </td>
                                    <td rowspan="2">
                                        CORRECTED EDD
                                        <p class="ml-2">
                                            <strong *ngIf="ancCard && ancCard.eddFormatted" [textContent]="ancCard.eddFormatted"></strong>
                                        </p>
                                    </td>

                                </tr>
                                <tr style="height: 70px;">
                                    <td>
                                        EDD
                                        <p>
                                            <strong *ngIf="ancCard && ancCard.eddFormatted" [textContent]="ancCard.eddFormatted"></strong>
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="border-dark border-top mt-2">
                        <div class="col-12 ng-star-inserted p-0 pt-1" *ngIf="obHistory">
                            <div>
                                <h5 class="font-weight-bold mt-0">OBSTETRIC HISTORY</h5>
                            </div>
                            <table class="w-100" border="1">
                                <thead *ngIf="obHistory">
                                    <tr class="tableBorder">
                                        <th class="tableBorder font-weight-bolder"> Preg No</th>
                                        <th class="tableBorder font-weight-bolder">Year</th>
                                        <th class="tableBorder font-weight-bolder">Birth Details</th>
                                    </tr>
                                </thead>
                                <tbody class="vertical-align-baseline">
                                    <tr *ngFor="let item of obHistory;">
                                        <td class="tableBorder" [textContent]="item.pregnancyNo"></td>
                                        <td class="tableBorder" [textContent]="item.yearOfBirth"></td>
                                        <td>
                                            <span>
                                                <strong class="d-block" *ngIf="item.noOfFetus > 1">Primary Birth Details :</strong>
                                                <ng-container *ngIf="item.modeOfDelivery">Mode of Delivery: {{item.modeOfDelivery}}, </ng-container>
                                                <ng-container *ngIf="item.pregnancyStatus">Pregnancy Status: {{item.pregnancyStatus}}, </ng-container>
                                                <ng-container *ngIf="item.ga">GA Weeks: {{item.ga}}, </ng-container>
                                                <ng-container *ngIf="item.deliveryDetail">Delivery Detail: {{item.deliveryDetail}}, </ng-container>
                                                <ng-container *ngIf="item.deliveryAt">Delivered At: {{item.deliveryAt}}, </ng-container>
                                                <ng-container *ngIf="item?.adress">Delivery At Other Place: {{item?.adress}}, </ng-container>
                                                <ng-container *ngIf="item.indication">Indication: {{item.indication}}, </ng-container>
                                                <ng-container *ngIf="item.sexOfBaby">Gender: {{item.sexOfBaby}}, </ng-container>
                                                <ng-container *ngIf="item.statusOfBaby">Status: {{item.statusOfBaby}}, </ng-container>
                                                <ng-container *ngIf="item.birthWeight">Weight: {{item.birthWeight}}, </ng-container>
                                                <ng-container *ngIf="item.conception">Conception: {{item.conception}}</ng-container>

                                            </span>
                                            <div *ngIf="item.birthOrder2">
                                                <strong class="d-block">Second Birth Details :</strong>
                                                <ng-container *ngIf="item.modeOfDelivery2">Mode of Delivery: {{item.modeOfDelivery2}}, </ng-container>
                                                <ng-container *ngIf="item.sexOfBaby2">Gender: {{item.sexOfBaby2}}, </ng-container>
                                                <ng-container *ngIf="item.statusOfBaby2">Status: {{item.statusOfBaby2}}, </ng-container>
                                                <ng-container *ngIf="item.birthWeight2">Weight: {{item.birthWeight2}}, </ng-container>
                                                <ng-container *ngIf="item.indication2">Indication: {{item.indication2}}</ng-container>
                                            </div>
                                            <div *ngIf="item.birthOrder3">
                                                <strong class="d-block">Third Birth Details :</strong>
                                                <ng-container *ngIf="item.modeOfDelivery3">Mode of Delivery: {{item.modeOfDelivery3}}, </ng-container>
                                                <ng-container *ngIf="item.sexOfBaby3">Gender: {{item.sexOfBaby3}}, </ng-container>
                                                <ng-container *ngIf="item.statusOfBaby3">Status: {{item.statusOfBaby3}}, </ng-container>
                                                <ng-container *ngIf="item.birthWeight3">Weight: {{item.birthWeight3}}, </ng-container>
                                                <ng-container *ngIf="item.indication3">Indication: {{item.indication3}}</ng-container>
                                            </div>
                                            <div *ngIf="item.birthOrder4">
                                                <strong class="d-block">Fourth Birth Details :</strong>
                                                <ng-container *ngIf="item.modeOfDelivery4">Mode of Delivery: {{item.modeOfDelivery4}}, </ng-container>
                                                <ng-container *ngIf="item.sexOfBaby4">Gender: {{item.sexOfBaby4}}, </ng-container>
                                                <ng-container *ngIf="item.statusOfBaby4">Status: {{item.statusOfBaby4}}, </ng-container>
                                                <ng-container *ngIf="item.birthWeight4">Weight: {{item.birthWeight4}}, </ng-container>
                                                <ng-container *ngIf="item.indication4">Indication: {{item.indication4}}</ng-container>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-12 ng-star-inserted p-0 pt-1" *ngIf="problemList && problemList.problems">
                        <div>
                            <h5 class="font-weight-bold">PAST MEDICAL HISTORY</h5>
                        </div>
                        <div *ngIf="problemList.isPastMedicalHistory" [textContent]="'None'"></div>
                        <table class="w-100 table table-bordered" *ngIf="!problemList.isPastMedicalHistory && problemList.problems">
                            <thead>
                                <tr class="tableBorder">
                                    <th class="tableBorder font-weight-bolder"> Disease</th>
                                    <th class="tableBorder font-weight-bolder">Year of diagnosis</th>
                                    <th class="tableBorder font-weight-bolder">ICD Code</th>
                                    <th class="tableBorder font-weight-bolder">Status</th>
                                    <th class="tableBorder font-weight-bolder min-width180px">Treatment History</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let problem of problemList.problems">
                                    <td class="tableBorder"><span *ngIf="problem && problem.usable" [textContent]="problem.usable"></span></td>
                                    <td class="tableBorder"><span *ngIf="problem && problem.date" [textContent]="problem.date"></span></td>
                                    <td class="tableBorder"><span *ngIf="problem && problem.code" [textContent]="problem.code"></span></td>
                                    <td class="tableBorder"><span *ngIf="problem && problem.status" [textContent]="problem.status"></span></td>
                                    <td class="tableBorder"><span *ngIf="problem && problem.comments" [textContent]="problem.comments"></span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 p-0 pt-1" *ngIf="procedureSurgery">
                        <div>
                            <h5 class="font-weight-bold">PAST SURGICAL HISTORY</h5>
                        </div>
                        <div *ngIf="!procedureSurgery || procedureSurgery.length ==0"> None </div>
                        <div *ngIf="procedureSurgery">
                            <div *ngFor="let item of procedureSurgery; let i=index;" class="row mb-2">
                                <div class="col-auto">
                                    <span class="fw-bold">{{ i+1 }}.</span>
                                </div>
                                <div class="col">
                                    <p *ngIf="item && item.surgeries" class="mb-0">Procedure: <span>{{ item.surgeries }}</span></p>
                                    <p *ngIf="item && item.date">Year of Surgery: <span>{{ item.date }}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="allergies border-dark border-top mt-2">
                        <h5 align="left">ALLERGY RELATED HISTORY</h5>
                        <div *ngIf="allergies && allergies.noHistory" [textContent]="allergies.noHistory ? 'None' : allergies.noHistory"></div>

                        <div class="allergies-section">
                            <p>
                                <span *ngIf="allergies && allergies.drugNames" [textContent]="'Drug Names: ' + allergies.drugNames + '.'"></span>
                                <span *ngIf="allergies && allergies.drugReactions" [textContent]="'Drug Reactions: ' + allergies.drugReactions + '.'"></span>
                                <span *ngIf="allergies && allergies.foodNames" [textContent]="'Food Names: ' + allergies.foodNames + '.'"></span>
                                <span *ngIf="allergies && allergies.foodReactions" [textContent]="'Food Reactions: ' + allergies.foodReactions + '.'"></span>
                                <span *ngIf="allergies && allergies.dustNames" [textContent]="'Dust Names: ' + allergies.dustNames + '.'"></span>
                                <span *ngIf="allergies && allergies.dustReactions" [textContent]="'Dust Reactions: ' + allergies.dustReactions + '.'"></span>
                                <span *ngIf="allergies && allergies.otherNames" [textContent]="'Other Names: ' + allergies.otherNames + '.'"></span>
                                <span *ngIf="allergies && allergies.otherReactions" [textContent]="'Other Reactions: ' + allergies.otherReactions + '.'"></span>
                            </p>
                        </div>
                    </div>


                    <div class="col-12 ng-star-inserted p-0 pt-1" *ngIf="familyHistory">
                        <div>
                            <h5 class="font-weight-bold"> FAMILY HISTORY </h5>
                        </div>
                        <div *ngIf="!familyHistory || familyHistory.length === 0"> None </div>
                        <div *ngIf="familyHistory">
                            <table>
                                <tr class="col-12" *ngFor="let item of familyHistory">
                                    <td>
                                        <span [textContent]="item.problem"></span>
                                    </td>
                                    <td class="pl-2"> : </td>
                                    <td colspan="2" class="text-right">
                                        <span class="ml-3" [textContent]="item.relation"></span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <h5 class="text-danger my-2">IMPORTANT INFORMATION</h5>
                    <div class="row">
                        <div class="col" *ngIf="antinantalRisk">
                            <div class="d-flex align-items-baseline font-weight-medium w-100">
                                <div *ngIf="antinantalRisk &&( antinantalRisk.maternalmedical.length > 0|| antinantalRisk.uterineRiskFactor.length > 0 || antinantalRisk.pregnancyComplications.length > 0 || antinantalRisk.fetusComplications.length > 0 || antinantalRisk.characteristics.length > 0 || antinantalRisk.details.length > 0)">
                                    <div class="d-flex align-items-baseline font-weight-medium w-100">
                                        <span class="white-space-nowrap  m-0 mb-1 d-inline text-danger line-height-1">Antenatal Risk :</span>
                                        <div class="w-100 mb-0 dashboard-break font-14">
                                            <div style="column-gap: 35px;" class="mb-0 line-height-1 text-black">
                                                <span *ngFor="let item of antinantalRisk.characteristics; let i=index;">
                                                    <span class="pr-1 text-danger" *ngIf="item && item !=''" [textContent]="item"></span>
                                                    <span *ngIf="(i != antinantalRisk.characteristics.length-1) && (item && item !='')">,</span>
                                                </span>
                                                <span *ngFor="let item of antinantalRisk.details; let i=index;" class="text-danger">
                                                    <span class="pr-1 text-danger" *ngIf="item && item !=''" [textContent]="item"></span>
                                                    <span *ngIf="(i != antinantalRisk.details.length-1) && (item && item !='')">,</span>
                                                </span>
                                                <span *ngFor="let item of antinantalRisk.maternalmedical; let i=index;" class="text-danger">
                                                    <span class="pr-1 text-danger" *ngIf="item && item !=''" [textContent]="item"></span>
                                                    <span *ngIf="(i !=antinantalRisk.maternalmedical.length-1) && (item && item !='')">,</span>
                                                </span>
                                                <span *ngFor="let item of antinantalRisk.uterineRiskFactor; let i=index;" class="text-danger">
                                                    <span class="pr-1 text-danger" *ngIf="item && item !=''" [textContent]="item"></span>
                                                    <span *ngIf="(i !=  antinantalRisk.uterineRiskFactor.length-1) && (item && item !='')">,</span>
                                                </span>
                                                <span *ngFor="let item of antinantalRisk.pregnancyComplications; let i=index;" class="text-danger">
                                                    <span class="pr-1 text-danger" *ngIf="item && item !=''" [textContent]="item"></span>
                                                    <span *ngIf="(i !=  antinantalRisk.pregnancyComplications.length-1) && (item && item !='')">,</span>
                                                </span>
                                                <span *ngFor="let item of antinantalRisk.fetusComplications; let i=index;" class="text-danger">
                                                    <span class="pr-1 text-danger" *ngIf="item && item !=''" [textContent]="item"></span>
                                                    <span *ngIf="(i != antinantalRisk.fetusComplications.length-1) && (item && item !='')">,</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h6 *ngIf="antinantalRisk && !( antinantalRisk.maternalmedical.length > 0|| antinantalRisk.uterineRiskFactor.length > 0 || antinantalRisk.pregnancyComplications.length > 0 || antinantalRisk.fetusComplications.length > 0 || antinantalRisk.characteristics.length > 0 || antinantalRisk.details.length > 0)" class="white-space-nowrap heading-color3 m-0 mb-1 d-inline line-height-1 mx-1">Antenatal Risk :</h6>

                                <div class="d-flex align-items-baseline font-weight-medium w-100" *ngIf="antinantalRisk && (antinantalRisk.covidInfection !=null && antinantalRisk.covidInfection !='')">
                                    <span class="white-space-nowrap text-danger  m-0 mb-1 d-inline line-height-1 "> Covid-19 Infection :</span>
                                    <span class="text-danger" [textContent]="antinantalRisk.covidInfection"></span>
                                </div>
                                <div class="d-flex align-items-baseline font-weight-medium w-100" *ngIf="antinantalRisk && (antinantalRisk.otherComplaints !=null && antinantalRisk.otherComplaints !='')">
                                    <span class="white-space-nowrap text-danger m-0 mb-1 d-inline line-height-1 "> Other :</span>
                                    <span class="text-danger" [textContent]="antinantalRisk.otherComplaints"></span>
                                </div>
                            </div>
                            <div class="d-flex align-items-baseline font-weight-medium w-100">
                                <div *ngIf="antinantalRisk &&( antinantalRisk.meternall.length > 0 || antinantalRisk.latePragnancy.length > 0 || antinantalRisk.currentPragnancy.length > 0 || antinantalRisk.funtalFetaus.length > 0 || antinantalRisk.maternalMedicalHistory.length > 0 || antinantalRisk.previousPregency.length > 0)">
                                    <div class="d-flex align-items-baseline font-weight-medium w-100">
                                        <span class="white-space-nowrap text-danger  m-0 mb-1 d-inline line-height-1 ">SGA Screening   : </span>
                                        <div class="w-100 mb-0 dashboard-break font-14">
                                            <div style="column-gap: 35px;" class="mb-0 line-height-1 text-black">
                                                <span *ngFor="let item of antinantalRisk.meternall; let i=index;">
                                                    <span class="pr-1 text-danger" *ngIf="item && item !=''" [textContent]="item"></span>
                                                    <span *ngIf="(i != antinantalRisk.meternall.length-1) && (item && item !='')">,</span>
                                                </span>
                                                <span *ngFor="let item of  antinantalRisk.previousPregency; let i=index;">
                                                    <span class="pr-1 text-danger" *ngIf="item && item !=''" [textContent]="item"></span>
                                                    <span *ngIf="(i !=  antinantalRisk.previousPregency.length-1) && (item && item !='')">,</span>
                                                </span>

                                                <span *ngFor="let item of antinantalRisk.maternalMedicalHistory; let i=index;">
                                                    <span class="pr-1 text-danger" *ngIf="item && item !=''" [textContent]="item"></span>
                                                    <span *ngIf="(i !=  antinantalRisk.maternalMedicalHistory.length-1) && (item && item !='')">,</span>
                                                </span>
                                                <span *ngFor="let item of antinantalRisk.funtalFetaus; let i=index;">
                                                    <span class="pr-1 text-danger" *ngIf="item && item !=''" [textContent]="item"></span>
                                                    <span *ngIf="(i != antinantalRisk.funtalFetaus.length-1) && (item && item !='')">,</span>
                                                </span>
                                                <span *ngFor="let item of antinantalRisk.currentPragnancy; let i=index;">
                                                    <span class="pr-1 text-danger" *ngIf="item && item !=''" [textContent]="item"></span>
                                                    <span *ngIf="(i != antinantalRisk.currentPragnancy.length-1) && (item && item !='')">,</span>
                                                </span>
                                                <span *ngFor="let item of antinantalRisk.latePragnancy; let i=index;">
                                                    <span class="pr-1 text-danger" *ngIf="item && item !=''" [textContent]="item"></span>
                                                    <span *ngIf="(i != antinantalRisk.latePragnancy.length-1) && (item && item !='')">,</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-baseline font-weight-medium w-100">
                                <div *ngIf="antinantalRisk && (antinantalRisk.highRisk.length > 0||antinantalRisk.intermediateRisk.length > 0|| antinantalRisk.lowRisk.length > 0  )">
                                    <div class="d-flex align-items-baseline font-weight-medium w-100">
                                        <span class="white-space-nowrap text-danger  m-0 mb-1 d-inline line-height-1 ">VTE Risk         : </span>
                                        <div class="w-100 mb-0 dashboard-break font-14">
                                            <div style="column-gap: 35px;" class="line-height-1 mb-0 text-black">
                                                <span *ngFor="let item of antinantalRisk.highRisk; let i=index;">
                                                    <span class="pr-1 text-danger" *ngIf="item && item !=''" [textContent]="item"></span>
                                                    <span *ngIf="(i != antinantalRisk.highRisk.length-1) && (item && item !='')">,</span>
                                                </span>
                                                <span *ngFor="let item of antinantalRisk.intermediateRisk; let i=index;">
                                                    <span class="pr-1 text-danger" *ngIf="item && item !=''" [textContent]="item"></span>
                                                    <span *ngIf="(i !=  antinantalRisk.intermediateRisk.length-1) && (item && item !='')">,</span>
                                                </span>
                                                <span *ngFor="let item of antinantalRisk.lowRisk; let i=index;">
                                                    <span class="pr-1 text-danger" *ngIf="item && item !=''" [textContent]="item"></span>
                                                    <span *ngIf="(i != antinantalRisk.lowRisk.length-1) && (item && item !='')">,</span>
                                                </span>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="d-flex align-items-baseline font-weight-medium w-100" *ngIf="antinantalRisk && (antinantalRisk.comments && antinantalRisk.comments !='')">
                                <span class="white-space-nowrap text-danger m-0 mb-1 d-inline line-height-1 "> VTE Risk Other       : </span>
                                <span class="text-danger" [textContent]="antinantalRisk.comments"></span>
                            </div>

                        </div>
                        <div class="col">
                            <div class="d-flex align-items-baseline font-weight-medium w-100 font-14">
                                <span class="white-space-nowrap text-danger  m-0 mb-1 d-inline line-height-1 mr-1 mb-2">SPECIAL FEATURES: </span>
                                <div *ngIf="specialFeature">
                                    <div class="line-height-1 mb-0 text-black"> <span class="pr-1 text-danger" [textContent]="specialFeature"></span></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="my-2">
                        <h4> CHECKLISTS </h4>
                    </div>
                    <div class="row" *ngIf="ancCard">
                        <div class="col table-responsive overflow-auto td-5">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th><h5>Patient</h5></th>
                                        <th><h5>Partner's</h5></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>BI Group/Rh typing</td>
                                        <td><span *ngIf="ancCard && ancCard.rhType" [textContent]="ancCard.bloodGroup + ancCard.rhType"></span></td>
                                        <td><span *ngIf="ancCard && ancCard.husbandRhType" [textContent]="ancCard.husbandBloodGroup + ancCard.husbandRhType"></span></td>
                                    </tr>
                                    <tr>
                                        <td>HIV</td>
                                        <td></td>
                                        <td><span *ngIf="ancCard && ancCard.hiv" [textContent]="ancCard.hiv"></span></td>
                                    </tr>
                                    <tr>
                                        <td>Hbsag</td>
                                        <td></td>
                                        <td><span *ngIf="ancCard && ancCard.hbsAg" [textContent]="ancCard.hbsAg"></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col t-5" *ngIf="ancCard">


                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th><h5>Vaccinations</h5></th>
                                        <th><h5>Date</h5></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Boosterix</td>
                                        <td><span *ngIf="ancCard.boostrix" [textContent]="ancCard.boostrix"></span></td>
                                    </tr>
                                    <tr>
                                        <td>Tetanus toxoid</td>
                                        <td *ngIf="ancCard.date5" [textContent]="ancCard.date5 | date: 'dd/MM/yyyy'"></td>
                                    </tr>
                                    <tr>
                                        <td>Covid Vaccine</td>
                                        <td>
                                            <div *ngIf="ancCard.date1">
                                                <div>{{ ancCard.vaccineName }} / Dose1</div>
                                                <div [textContent]="ancCard.date1 | date: 'dd/MM/yyyy'"></div>
                                            </div>
                                            <div *ngIf="ancCard.date2">
                                                <div>{{ ancCard.vaccineName }} / Dose2</div>
                                                <div [textContent]="ancCard.date2 | date: 'dd/MM/yyyy'"></div>
                                            </div>
                                            <div>
                                                <span *ngIf="ancCard && ancCard?.date3">Booster/Covishield</span>
                                                <div *ngIf="ancCard"></div>
                                                {{ ancCard.date3 ? (ancCard.date3 | date: 'dd/MM/yyyy') : ancCard.covidVaccine }}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Flu Vaccine</td>
                                        <td *ngIf="ancCard ">
                                            {{ ancCard.date4 ? (ancCard.date4 | date: 'dd/MM/yyyy') : ancCard.fluVaccine }}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col t-5">
                            <table class="table table-bordered" *ngIf="measure">
                                <thead>
                                    <tr>
                                        <th><h5>Booking examination</h5></th>
                                        <th><h5>Findings</h5></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Cardiovascular system</td>
                                        <td *ngIf="measure && measure.cardiioVascular" [textContent]="measure.cardiioVascular"></td>
                                    </tr>
                                    <tr>
                                        <td>Respiratory system</td>
                                        <td><span *ngIf="measure && measure.respiratorySystem" [textContent]="measure.respiratorySystem"></span></td>
                                    </tr>
                                    <tr>
                                        <td>Height</td>
                                        <td><span *ngIf="measure && measure.heightInFeet" [textContent]="measure.heightInFeet"></span></td>
                                    </tr>
                                    <tr>
                                        <td>Booking weight(kg)</td>
                                        <td><span *ngIf="measure && measure.weight" [textContent]="measure.weight"></span></td>
                                    </tr>
                                    <tr>
                                        <td>Booking BMI</td>
                                        <td><span *ngIf="measure && measure.bodyMassIndex" [textContent]="measure.bodyMassIndex"></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col t-5" *ngIf="ancCard">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th><h5>Patient preferences</h5></th>
                                        <th><h5>Options</h5></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Diet</td>
                                        <td><span *ngIf="ancCard && ancCard.diet" [textContent]="ancCard.diet"></span></td>
                                    </tr>
                                    <tr>
                                        <td>Life style activity</td>
                                        <td><span *ngIf="ancCard && ancCard.lifeStyle" [textContent]="ancCard.lifeStyle"></span></td>
                                    </tr>
                                    <tr>
                                        <td>Toilet preference</td>
                                        <td><span *ngIf="ancCard && ancCard.toiletPreference" [textContent]="ancCard.toiletPreference"></span></td>
                                    </tr>
                                    <tr>
                                        <td>Prenatal screening</td>
                                        <td><span *ngIf="ancCard && ancCard.prenatalScreeningOffered" [textContent]="ancCard.prenatalScreeningOffered"></span></td>
                                    </tr>
                                    <tr>
                                        <td>Cord blood banking</td>
                                        <td><span *ngIf="ancCard && ancCard.cordBloodBanking" [textContent]="ancCard.cordBloodBanking"></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <h4> Nutritional Review </h4>
                    <div class="border-dark border-top mt-2 ">
                        <table class="w-100 white-space-nowrap border-bottom2px">
                            <tr class="col-12">
                                <td class="w-130"><span class=""> Nutritional screening </span></td>
                                <td><h5 class="">:</h5></td>
                                <td colspan="2">
                                    <h5 class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="dietplanData.OpNutritionalAssessmentForm && dietplanData.OpNutritionalAssessmentForm.nutritionalScreening"
                                        [textContent]="dietplanData.OpNutritionalAssessmentForm.nutritionalScreening"></h5>
                                </td>

                            </tr>
                            <tr class="col-12">
                                <td class="w-130"><span class="">Referred for nutritional</span></td>
                                <td><h5 class="">:</h5></td>
                                <td colspan="2">
                                    <h5 class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="dietplanData.OpNutritionScreeningForm && dietplanData.OpNutritionScreeningForm.referredForNutritional"
                                        [textContent]="dietplanData.OpNutritionScreeningForm.referredForNutritional"></h5>
                                </td>

                            </tr>
                            <tr class="col-12">
                                <td class="w-130"><span class=""> Leaftlet Given / Diet Plan </span></td>
                                <td><h5 class="">:</h5></td>
                                <td colspan="2">
                                    <h5 class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="dietplanData.OpNutritionalAssessmentForm && dietplanData.OpNutritionalAssessmentForm.leafletGivenDietPlan"
                                        [textContent]="dietplanData.OpNutritionalAssessmentForm.leafletGivenDietPlan"></h5>
                                </td>

                            </tr>
                            <tr class="col-12">
                                <td class="w-130"><span class="">Counselled by Nutritionist</span></td>
                                <td><h5 class="">:</h5></td>
                                <td colspan="2">
                                    <h5 class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="dietplanData.OpNutritionalAssessmentForm && dietplanData.OpNutritionalAssessmentForm.counselledByNutritionist"
                                        [textContent]="dietplanData.OpNutritionalAssessmentForm.counselledByNutritionist"></h5>
                                </td>

                            </tr>
                            <tr class="col-12">
                                <td class="w-130"><span class="">Diet Recommended</span></td>
                                <td><h5 class=""> : </h5></td>
                                <td colspan="2">
                                    <h5 class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="dietplanData.OpNutritionalAssessmentForm && dietplanData.OpNutritionalAssessmentForm.dietRecommened"
                                        [textContent]="dietplanData.OpNutritionalAssessmentForm.dietRecommened"></h5>
                                </td>

                            </tr>
                        </table>
                    </div>
                    <div>
                        <div class="row">
                            <div class="col table-responsive overflow-auto td-5" *ngIf="previousHospitalization">
                                <h5>Previous Hospitalizations</h5>
                                <div *ngIf="previousHospitalization">
                                    <table class="table table-bordered table-sm ">
                                        <thead class="thead-light">
                                            <tr>
                                                <th>S.No</th>
                                                <th>Hospitalization</th>
                                                <th>Doctor Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of previousHospitalization; let i = index">
                                                <td [textContent]="i + 1"></td>
                                                <td [textContent]="item.Hospitalization | date:'dd/MM/yyyy' "></td>
                                                <td [textContent]="item.DoctorName"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col t-5" *ngIf="attachmentsDataInfo">
                                <h5>ATTACHMENTS TO EMR</h5>
                                <table class="table  table-bordered table-sm ">
                                    <thead class="thead-light">
                                        <tr>
                                            <th>S.No</th>
                                            <th> Attachments </th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of attachmentsDataInfo; let i = index">
                                            <td [textContent]="i + 1"></td>
                                            <td [textContent]="row.Attachments"></td>
                                            <td [textContent]="row.Date "></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-6" *ngIf="previousHospitalVisits">
                            <h5 class="mb-2"><b>PREVIOUS HOSPITAL VISIT DETAILS</b></h5>
                            <table class="table table-bordered">
                                <thead>
                                    <tr style="background-color: #CCCCCC;">
                                        <th></th>
                                        <th>Doctor visits</th>
                                        <th>Date</th>
                                        <th>Day</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of previousHospitalVisits; let i = index">
                                        <td [textContent]="i + 1"></td>
                                        <td [textContent]="item.DoctorVisits"></td>
                                        <td [textContent]="item.Date | date:'dd/MM/yyyy' "></td>
                                        <td [textContent]="item.Day"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-6" *ngIf="labTests">
                            <table class="table table-bordered">
                                <thead>
                                    <tr style="background-color: #ccccff;">
                                        <th>S.No</th>
                                        <th>Investigations</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of labTests; let i = index">
                                        <td [textContent]="i + 1"></td>
                                        <td [textContent]="item.TestName"></td>
                                        <td [textContent]="item.ServiceDate | date:'dd.MM.yyyy'"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="mt-1" *ngIf="hasData()">
                        <h5 class="mb-2"><b>ANTENATAL PERIOD INVESTIGATIONS</b></h5>
                        <div class="table-responsive overflow-auto">
                            <table class="table table-bordered">
                                <thead class="thead">
                                    <tr>
                                        <th>Test Name</th>
                                        <th>Parameter Name</th>
                                        <ng-container *ngFor="let date of getUniqueDates()">
                                            <th>
                                                <p>{{date}}</p>
                                                <p>{{calculateWeeksDifference(date)}}</p>
                                            </th>

                                        </ng-container>

                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let group of groupRowsByTestName() | keyvalue">
                                        <ng-container *ngFor="let row of group.value; let firstRow = first">
                                            <tr>
                                                <td *ngIf="firstRow" [attr.rowspan]="group.value.length">{{ group.key }}</td>
                                                <td>{{ row.ParameterName }}</td>
                                                <ng-container *ngFor="let date of getUniqueDates()">
                                                    <td>
                                                        <span [ngClass]="{ 'text-danger font-weight-bold': row.IsAbnormal === 1 && !['PatientId', 'LabMainDetailId',
                                                    'TestName', 'ParameterName', 'MinValue', 'MaxValue', 'IsAbnormal'].includes(date) }">{{ getDateValue(row, date) }}</span>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div *ngIf="pregnancyPlanData">
                        <h5>Pregnancy Plan</h5>
                        <div *ngFor="let item of pregnancyPlanData">
                            <ng-container *ngIf="item.PregnancyPlan">
                                <ng-container *ngFor="let plan of item.PregnancyPlan">
                                    <table class="w-100 mb-3" *ngIf="plan.pregnancyPlan">
                                        <tbody>
                                            <tr>
                                                <td class="align-top">
                                                    <span class="align-top fw-700 d-inline-block w-170px fw-medium">Plan of pregnancy </span>:
                                                    <pre class="planofpregnancy" [innerHTML]="plan.pregnancyPlan"></pre>
                                                </td>
                                            </tr>
                                            <tr><td class="align-top"><span class="w-170px fw-700 d-inline-block fw-medium">Date</span>:</td></tr>
                                            <tr>
                                                <td class="align-top" *ngIf="plan.createdByName">
                                                    <span class="w-170px fw-700 d-inline-block fw-medium">User Name </span>:
                                                    <span [textContent]="plan.createdByName"></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>

                    <div *ngIf="AntenatalVisitDetails">
                        <h5 class="mb-2"><b>ANC CARD-Summary of ANC Visits</b></h5>
                        <table class="table table-bordered w-100">
                            <thead>
                                <tr>
                                    <th>S.no</th>
                                    <th>Date</th>
                                    <th>Gest. Age</th>
                                    <th>Weight</th>
                                    <th>BMI</th>
                                    <th>PR</th>
                                    <th>BP</th>
                                    <th>SFH</th>
                                    <th>Presentation</th>
                                    <th>FHR</th>
                                    <th>Review date</th>
                                    <th>Seen by</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of AntenatalVisitDetails">
                                    <td [textContent]="item?.SNo"></td>
                                    <td [textContent]="item?.AppointmentDate | date: 'dd/MM/yyyy'"></td>
                                    <td [textContent]="formatGestationAge(item?.Measure?.gestationAge)"></td>
                                    <td [textContent]="item?.Measure?.weight"></td>
                                    <td [textContent]="item?.Measure?.bodyMassIndex"></td>
                                    <td [textContent]="item?.Measure?.pulseRate"></td>
                                    <td [textContent]="item?.Measure?.systolic && item?.Measure?.diastotic ? item?.Measure?.systolic + ' / ' + item?.Measure?.diastotic : ''"></td>
                                    <td [textContent]="item?.Measure?.sfHeight"></td>
                                    <td [textContent]="item?.Measure?.presentation"></td>
                                    <td [textContent]="item?.Measure?.fhs"></td>
                                    <td [textContent]="item?.Measure?.nextReviewDate | date: 'dd/MM/yyyy'"></td>
                                    <td [textContent]="item?.AncCard?.consulatantDoctor"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <div *ngIf="AntenatalVisitDetails">
                        <h5>CURRENT ANTENATAL VISIT DETAILS</h5>
                        <table class="table table-bordered table-sm" *ngFor="let data of AntenatalVisitDetails">
                            <tbody>
                                <ng-container>
                                    <ng-container>
                                        <tr>
                                            <td rowspan="3">{{ data.SNo }}</td>
                                            <td>Date</td>
                                            <td>Weight kg</td>
                                            <td>BMI</td>
                                            <td>Pulse Rate</td>
                                            <td>BP</td>
                                            <td>SFH cm</td>
                                            <td>Presentation</td>
                                            <td>FHR bpm</td>
                                            <td>Liquor</td>
                                            <td>Edema</td>
                                        </tr>
                                        <tr>
                                            <td [textContent]="data.AppointmentDate | date: 'dd.MM.yyyy' "></td>
                                            <td [textContent]="data?.Measure?.weight"></td>
                                            <td [textContent]="data?.Measure?.bodyMassIndex"></td>
                                            <td [textContent]="data?.Measure?.pulseRate"></td>
                                            <td [textContent]="data?.Measure?.systolic && data?.Measure?.diastotic ? data?.Measure?.systolic + ' / ' + data?.Measure?.diastotic : ''"></td>
                                            <td [textContent]="data?.Measure?.sfHeight"></td>
                                            <td [textContent]="data?.Measure?.presentation"></td>
                                            <td [textContent]="data?.Measure?.fhs"></td>
                                            <td [textContent]="data?.Measure?.liquor"></td>
                                            <td [textContent]="data?.Measure?.oedema"></td>
                                        </tr>
                                        <tr>
                                            <td><span class="text-danger">Gest.Age <span class="text-danger" *ngIf="data.Measure && data.Measure.gestationAge" [textContent]="formatGestationAge(data.Measure?.gestationAge)"></span></span></td>
                                            <td colspan="9" *ngIf="data.OrderPrescription && data.OrderPrescription.orderData">

                                                <p *ngIf="hasMedications(data.AppointmentId)">
                                                    <strong>Medications:</strong>
                                                    <span *ngFor="let medication of getMedicationsbyAppointmentId(data.AppointmentId); let i = index; let last = last">
                                                        <span *ngIf="medication?.ProductName" [textContent]="medication.ProductName + (!last ? ', ' : '')"></span>
                                                    </span>
                                                </p>

                                                <p *ngIf="hasInvestigations(data.AppointmentId)">
                                                    <strong>Investigations ordered:</strong>
                                                    <span *ngFor="let investigations of getInvestigationsOrderedbyAppointmentId(data.AppointmentId); let i = index; let last = last">
                                                        <span *ngIf="investigations?.TestName" [textContent]="investigations.TestName + (!last ? ', ' : '')"></span>
                                                    </span>
                                                </p>

                                                <p *ngFor="let Prescription of data.OrderPrescription.orderData">
                                                    <strong *ngIf="Prescription?.comments" [textContent]="Prescription.orderName + ': '"></strong>
                                                    <span class="cmt-text" *ngIf="Prescription?.comments" [innerHTML]="Prescription.comments.includes('null') ? Prescription.comments.split('null').join('') : Prescription.comments"></span>
                                                </p>
                                                <p *ngIf="data.Measure"><strong>Next review on: </strong> <span [textContent]="data.Measure?.nextReviewDate | date: 'dd.MM.yyyy'"></span></p>
                                                <div class="d-flex justify-content-between">
                                                    <p>User Name: <span *ngIf="page.userAccount && page.userAccount.fullName" [textContent]="page.userAccount.fullName"></span></p>
                                                    <p class="mr-5" *ngIf="data.Measure">Consulted by: <span [textContent]="data.Measure?.consultedByDr"></span></p>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="nonAncVisits">
                        <h5 class="mb-2"><b>Non-ANC Visits:</b></h5>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Doctor Name</th>
                                    <th>Prescription Given</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of nonAncVisits">
                                    <td class="tableBorder" [textContent]="item.Date | date:'dd.MM.yyyy'"></td>
                                    <td class="tableBorder" [textContent]="item.DoctorName"></td>
                                    <td class="tableBorder">
                                        <div *ngFor="let orderItem of item.PrescriptionGiven.orderData" class="prescription-item">
                                            <p class="d-flex justify-content-start"> <strong class="order-name" [textContent]="orderItem.orderName + ':'"></strong> <span class="comments" [innerHTML]="orderItem.comments"></span> </p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <div *ngIf="telePhoneTriangleAssessment">
                        <h5 class="mb-2"><b>Telephone conversations recorded</b></h5>
                        <table class="w-100 border-top2px border-bottom2px">
                            <tr class="col-12">
                                <td class="w-130">mr no :</td>
                                <td colspan="2"><span class="ml-1" *ngIf="obReportData.UMRNo" [textContent]="obReportData.UMRNo"></span></td>
                                <td class="w-130">anc no :</td>
                                <td colspan="2"><span class="ml-1" *ngIf="obReportData.ANCNo" [textContent]="obReportData.ANCNo"></span></td>
                            </tr>
                            <ng-container *ngFor="let item of telePhoneTriangleAssessment">

                                <tr>
                                    <ng-container *ngIf="item.teleJsonData && item.teleJsonData.callDate && item.teleJsonData.callTime">
                                        <td class="white-space-nowrap vertical-align-top"><span>call date/time</span></td>
                                        <td class="vertical-align-top"><h5>:</h5></td>
                                        <td class="vertical-align-top">
                                            <span class="pl-1" [textContent]="item.teleJsonData.callDate + ' ' + convertTo12Hour(item.teleJsonData.callTime)"></span>
                                        </td>
                                    </ng-container>
                                    <ng-container *ngIf="item && item.UserName">
                                        <td class="white-space-nowrap vertical-align-top"><span>received by</span></td>
                                        <td class="vertical-align-top"><h5>:</h5></td>
                                        <td class="vertical-align-top"><span class="pl-1" [textContent]="item.UserName"></span></td>
                                    </ng-container>
                                </tr>
                                <tr>
                                    <ng-container *ngIf="item.teleJsonData && item.teleJsonData.reasonForCall && item.teleJsonData.reasonothers">
                                        <td class="white-space-nowrap vertical-align-top"><span>reason for call</span></td>
                                        <td class="vertical-align-top"><h5>:</h5></td>
                                        <td class="vertical-align-top"><span class="pl-1" [textContent]="item.teleJsonData.reasonForCall  + item.teleJsonData.reasonothers"></span></td>
                                    </ng-container>
                                </tr>
                                <tr>
                                    <ng-container *ngIf="item.teleJsonData && item.teleJsonData.doctorName && item.teleJsonData.callCompletedDate && item.teleJsonData.callcompletedTime">
                                        <td class="white-space-nowrap vertical-align-top"><span>call answered and saved by</span></td>
                                        <td class="vertical-align-top"><h5>:</h5></td>
                                        <td class="vertical-align-top"><span class="pl-1" [textContent]="item.teleJsonData.doctorName + ' ' + item.teleJsonData.callCompletedDate + ' ' + item.teleJsonData.callcompletedTime"></span></td>
                                    </ng-container>
                                </tr>


                            </ng-container>
                        </table>

                    </div>
                    <div class="mt-2" *ngIf="crossConsultations">
                        <h5 class="mb-2"><b>Cross Consultations</b></h5>
                        <table class="w-100  border-top2px border-bottom2px">
                            <tbody>
                                <tr *ngFor="let item of crossConsultations">
                                    <td>
                                        <table class="w-100">
                                            <tr *ngIf="item.Reason">
                                                <td class="white-space-nowrap align-top w-170px">Reason for Referral</td>
                                                <td class="align-top">:<span class="pl-1 tb-cnt-align" [textContent]="item.Reason"></span></td>
                                            </tr>
                                            <tr *ngIf="item.ConsultantDoctor">
                                                <td class="align-top w-170px">From</td>
                                                <td class="align-top">:<span class="pl-1 d-inline" [textContent]="item.ConsultantDoctor"></span></td>
                                            </tr>
                                            <tr *ngIf="item.ReferralDoctor">
                                                <td class="align-top w-170px">To</td>
                                                <td class="align-top">:<span class="pl-1 d-inline" [textContent]="item.ReferralDoctor"></span></td>
                                            </tr>
                                            <tr *ngIf="item.RequiredDate">
                                                <td class="align-top w-170px">Refer Date</td>
                                                <td class="align-top">:<span class="pl-1 d-inline" [textContent]="item.RequiredDate | date: 'dd.MM.yyyy'"></span></td>
                                            </tr>
                                            <tr>
                                                <td class="align-top w-170px">Refer Time</td>
                                                <td class="align-top">
                                                    :<span class="pl-1 d-inline" [textContent]="convertTo12Hour(item.Time)"></span>
                                                </td>
                                            </tr>
                                            <tr *ngIf="item">
                                                <td class="align-top w-170px">Counselling Done</td>
                                                <td class="align-top">:<span class="pl-1 d-inline"></span></td>
                                            </tr>
                                            <tr *ngIf="item">
                                                <td class="align-top w-170px">Done By</td>
                                                <td class="align-top">:<span class="pl-1 d-inline"></span></td>
                                            </tr>
                                            <tr *ngIf="item.createddate">
                                                <td class="align-top w-170px">Date</td>
                                                <td class="align-top">:<span class="pl-1 d-inline" [textContent]="item.createddate | date: 'dd.MM.yyyy'"></span></td>
                                            </tr>
                                            <tr *ngIf="item.createdtime">
                                                <td class="align-top w-170px">Time</td>
                                                <td class="align-top">
                                                    :<span class="pl-1 d-inline" [textContent]="convertTo12Hour(item.createdtime)"></span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td class="align-top w-170px">Signature</td>
                                                <td class="align-top">
                                                    :<h5 *ngIf="appointment && appointment.signature" class="border-bottom border-dark pl-1">
                                                        <img *ngIf="appointment.signature != null" [src]="safe(appointment.signature)" class="img-fluid rounded-circle" style="height:80px" />
                                                    </h5>
                                                </td>
                                            </tr>

                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="investigationsDone">
                        <h5 class="mb-2"><b>INVESTIGATION DONE</b></h5>
                        <table class="table table-bordered border-0">
                            <thead>
                                <tr>
                                    <th class="border-0"><b>Date</b></th>
                                    <th class="border-0"> <b> Investigation</b> </th>
                                    <th class="border-0"> <b>Result</b> </th>
                                    <th class="border-0"> <b>Normal Value </b> </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let investigation of investigationsDone" class="mb-0">
                                    <td class="border-0" [textContent]="investigation.CreatedDate | date: 'dd.MM.yyyy'"></td>
                                    <td class="border-0" [textContent]="investigation.ParameterName"></td>
                                    <td class="border-0" [textContent]="investigation.Result"></td>
                                    <td class="border-0" [textContent]="investigation.NormalValue"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--Department of Neonatology-->
                    <div class=" border-bottom" *ngIf="isAdimissionCount > 0">
                        <div *ngFor="let item of departmentOfNeonatologyData">
                            <div class="page-break-after-always" style=""></div>
                            <div class="printshow">
                                <banner-setting></banner-setting>
                            </div>
                            <div class="mt-4" *ngFor="let item of getNeonatologyData(item.AdmissionId)">
                                <h4 class="fw-medium text-center">Department of Neonatology</h4>
                                <div class="border-dark border-top  mt-2">
                                    <table class="w-100 white-space-nowrap border-top2px border-bottom2px">
                                        <tr>
                                            <td class="w-130"><span class="">MR No</span></td>
                                            <td><h5 class="d-inline">:</h5> &nbsp;<h5 class="d-inline text-truncate" *ngIf="item.NeonatalBaby && item.NeonatalBaby.babyUmr" [textContent]="item.NeonatalBaby.babyUmr"></h5></td>
                                            <td class="w-130"><span class="">Mother IP No.</span></td>
                                            <td class="w-200p"><h5 class="d-inline">:</h5> &nbsp;<h5 class="d-inline" [textContent]="item.MotherIPNo"></h5></td>
                                        </tr>
                                        <tr>
                                            <td class="w-130"><span class="">Baby Of</span></td>
                                            <td colspan="3"><h5 class="d-inline">:</h5> &nbsp;<h5 class="d-inline text-truncate" *ngIf="item.NeonatalBaby && item.NeonatalBaby.babyOf" [textContent]="item.NeonatalBaby.babyOf"></h5></td>
                                        </tr>
                                        <tr>
                                            <td class="w-130"><span class="">Father Name</span></td>
                                            <td colspan="3" class="w-200p "><h5 class="d-inline">:</h5> &nbsp;<h5 class="d-inline" *ngIf="item.NeonatalBaby && item.NeonatalBaby.fatherName" [textContent]="item.NeonatalBaby.fatherName"></h5></td>
                                        </tr>
                                        <tr>
                                            <td class="w-130"><span class="">Mother MR No</span></td>
                                            <td><h5 class="d-inline">:</h5> &nbsp;<h5 class="d-inline text-truncate" *ngIf="item.NeonatalBaby && item.NeonatalBaby.motherUmr" [textContent]="item.NeonatalBaby.motherUmr"></h5></td>
                                            <td class="w-130"><span class="">ANC No</span></td>
                                            <td class="w-200p "><h5 class="d-inline">:</h5> &nbsp;<h5 class="d-inline" *ngIf="item.NeonatalBaby && item.NeonatalBaby.ancNo" [textContent]="item.NeonatalBaby.ancNo"></h5></td>
                                        </tr>
                                        <tr>
                                            <td class="w-130"><span class="">Admission Date/Time</span></td>
                                            <td><h5 class="d-inline">:</h5> &nbsp;<h5 class="d-inline text-truncate" *ngIf="item.NeonatalBaby && item.NeonatalBaby.AdmissionDateTime" [textContent]="item.AdmissionDateTime | date: 'dd.MM.yyyy HH:mm:ss' "></h5></td>
                                            <td class="w-130"><span class="">Discharge Date</span></td>
                                            <td class="w-200p"><h5 class="d-inline">:</h5> &nbsp;<h5 class="d-inline" *ngIf="item.DischargeSummary && item.DischargeSummary.dischargedDate" [textContent]="item.DischargeSummary.dischargedDate | date: 'dd.MM.yyyy' "></h5></td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="border-dark border-top  mt-2">
                                    <table class="w-100 white-space-nowrap border-top2px border-bottom2px">
                                        <tr>
                                            <td class="w-130"><span class="">Birth Date / Birth time</span></td>
                                            <td>
                                                <h5 class="d-inline">:</h5>&nbsp;<h5 class="d-inline text-truncate max-width_25vw">
                                                    <span *ngIf="item.NeonatalBaby && item.NeonatalBaby.birthDate" [textContent]="item.NeonatalBaby.birthDate">
                                                    </span> / <span *ngIf="item.NeonatalBaby && item.NeonatalBaby.birthTime" [textContent]="item.NeonatalBaby.birthTime"></span>
                                                </h5>
                                            </td>
                                            <td class="w-130"><span class="">Weight</span></td>
                                            <td class="w-200p "><h5 class="d-inline">:</h5>&nbsp;<h5 class="d-inline" *ngIf="item.NeonatalBaby && item.NeonatalBaby.weight" [textContent]="item.NeonatalBaby.weight"></h5></td>
                                        </tr>
                                        <tr>
                                            <td class="w-130"><span class="">Sex</span></td>
                                            <td><h5 class="d-inline">:</h5>&nbsp;<h5 class="d-inline text-truncate max-width_25vw" *ngIf="item.NeonatalBaby && item.NeonatalBaby.sex" [textContent]="item.NeonatalBaby.sex"></h5></td>
                                            <td class="w-130"><span class="">Length</span></td>
                                            <td class="w-200p "><h5 class="d-inline">:</h5>&nbsp;<h5 class="d-inline" *ngIf="item.NeonatalBaby && item.NeonatalBaby.length" [textContent]="item.NeonatalBaby.length"></h5></td>
                                        </tr>
                                        <tr>
                                            <td class="w-130"><span class="">Apgar</span></td>
                                            <td>
                                                <h5 class="d-inline">:</h5>&nbsp;<h5 class="d-inline text-truncate max-width_25vw" *ngIf="item.NeonatalBaby && item.NeonatalBaby.apgar && item.NeonatalBaby.apgar1 && item.NeonatalBaby.apgar10"
                                                                                     [textContent]="item.NeonatalBaby.apgar + '/' + item.NeonatalBaby.apgar1 + '/' + item.NeonatalBaby.apgar10"></h5>
                                            </td>
                                            <td class="w-130"><span class="">Head Circum</span></td>
                                            <td class="w-200p"><h5 class="d-inline">:</h5>&nbsp;<h5 class="d-inline" *ngIf="item.NeonatalBaby && item.NeonatalBaby.hc" [textContent]="item.NeonatalBaby.hc"></h5></td>
                                        </tr>
                                        <tr>
                                            <td class="w-130"><span class="">Baby Blood group</span></td>
                                            <td><h5 class="d-inline">:</h5>&nbsp;<h5 class="d-inline text-truncate max-width_25vw" *ngIf="item.NeonatalBaby && item.NeonatalBaby.bloodGroup" [textContent]="item.NeonatalBaby.bloodGroup"></h5></td>
                                            <td class="w-130"><span class="">Discharge Type</span></td>
                                            <td class="w-200p "><h5 class="d-inline">:</h5>&nbsp;<h5 class="d-inline" *ngIf="item.dischargeSummary" [textContent]="item.dischargeSummary"></h5></td>
                                        </tr>
                                        <tr>
                                            <td class="w-130"><span class="">Gestation Age</span></td>
                                            <td colspan="3"><h5 class="d-inline">:</h5>&nbsp;<h5 class="d-inline text-truncate max-width_25vw" *ngIf="item.NeonatalBaby && item.NeonatalBaby.gestAgeWks" [textContent]="item.NeonatalBaby.gestAgeWks"></h5></td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="mt-2">
                                    <h5>Maternal History</h5>
                                    <p *ngIf="item.dischargeSummary && item.DischargeSummary.maternalSummary" [innerHTML]="item.DischargeSummary.maternalSummary"></p>
                                </div>
                                <div class="mt-2">
                                    <h5>Perinatal History</h5>
                                    <p *ngIf="item.dischargeSummary && item.DischargeSummary.periSummary" [innerHTML]="item.DischargeSummary.periSummary"></p>
                                </div>
                                <div class="mt-2">
                                    <h5>Special Features</h5>
                                    <p *ngIf="item.dischargeSummary && item.DischargeSummary.SpecialFeature" [innerHTML]="item.SpecialFeature"></p>
                                </div>
                                <div class="mt-2">
                                    <h5>Congenital Anomalies</h5>
                                    <p *ngIf="item.Congenital_Anomalies" [textContent]="item.Congenital_Anomalies"></p>
                                </div>
                                <div class="mt-2">
                                    <h5>Impression</h5>
                                    <p *ngIf="item.NeonatalBaby && item.NeonatalBaby.impression" [textContent]="item.NeonatalBaby.impression"></p>
                                </div>
                                <div class="mt-2">
                                    <h5>Course In Hospital</h5>
                                    <p *ngIf="item.dischargeSummary && item.DischargeSummary.course" [innerHTML]="item.DischargeSummary.course"></p>
                                </div>
                                <h5 class="my-2">Screening Details</h5>
                                <div>
                                    <table class="w-100 white-space-nowrap border-top2px border-bottom2px">
                                        <tr>
                                            <td><span class="w-200p d-inline-block">ENBS</span><h5 class="d-inline">:</h5> &nbsp;<h5 class="d-inline text-truncate max-width_25vw" *ngIf="item.NeonatalBaby && item.NeonatalBaby.enbs" [textContent]="item.NeonatalBaby.enbs"></h5></td>
                                        </tr>
                                        <tr>
                                            <td><span class="w-200p d-inline-block">Sample Sent</span><h5 class="d-inline">:</h5> &nbsp;<h5 class="d-inline text-truncate max-width_25vw" *ngIf="item.NeonatalBaby && item.NeonatalBaby.sample" [textContent]="item.NeonatalBaby.sample"></h5></td>
                                        </tr>
                                        <tr>
                                            <td><span class="w-200p d-inline-block">Hearing Screening</span><h5 class="d-inline">:</h5> &nbsp;<h5 class="d-inline" *ngIf="item.NeonatalBaby && item.NeonatalBaby.hearingScreen" [textContent]="item.NeonatalBaby.hearingScreen"></h5></td>
                                        </tr>
                                        <tr>
                                            <td><span class="w-200p d-inline-block">Red Eye Reflex</span><h5 class="d-inline">:</h5> &nbsp;<h5 class="d-inline text-truncate max-width_25vw" *ngIf="item.NeonatalBaby && item.NeonatalBaby.redEye" [textContent]="item.NeonatalBaby.redEye"></h5></td>
                                        </tr>
                                        <tr>
                                            <td><span class="w-200p d-inline-block">SPO2 On Room Air</span><h5 class="d-inline">:</h5> &nbsp;<h5 class="d-inline text-truncate max-width_25vw" *ngIf="item.NeonatalBaby && item.NeonatalBaby.sPO2OnRoomAir" [textContent]="item.NeonatalBaby.sPO2OnRoomAir"></h5></td>
                                        </tr>
                                        <tr>
                                            <td><span class="w-200p d-inline-block">Cord Blood TSH</span><h5 class="d-inline">:</h5> &nbsp;<h5 class="d-inline text-truncate max-width_25vw" *ngIf="item.NeonatalBaby && item.NeonatalBaby.cordBlood" [textContent]="item.NeonatalBaby.cordBlood"></h5></td>
                                        </tr>
                                    </table>
                                </div>
                                <h5>Baby Details</h5>
                                <div class="mt-2">
                                    <h5>NEONATAL JAUNDICE</h5>
                                    <p *ngIf="item.dischargeSummary && item.DischargeSummary.babySummary" [innerHTML]="item.DischargeSummary.babySummary"></p>
                                </div>
                                <h4>DISCHARGE ADVICE OF BABY :</h4>
                                <div class="mt-2">
                                    <h5>Medication Advised :</h5>
                                    <!--<p [textContent]="departmentOfNeonatologyData.DS_MedicationAdvised"></p>-->
                                </div>
                                <div class="mt-2">
                                    <h5>Importance Advice</h5>
                                    <p *ngIf="item.DischargeSummary && item.DischargeSummary.importantAdvice" [innerHTML]="item.DischargeSummary.importantAdvice"></p>
                                </div>
                                <div class="mt-2">
                                    <h5>Follow Up Date</h5>
                                    <p *ngIf="item.dischargeSummary && item.DischargeSummary.followUp1" [innerHTML]="item.DischargeSummary.followUp1"></p>
                                </div>
                                <div class="mt-2">
                                    <h5>DANGER SIGNS -</h5>
                                    <p>
                                        (Explained to parents and adviced to attend emergency if they are present)<br />
                                        Excessive yellowish discoloration of skin and eyes, Fever and cold peripheries,<br />
                                        Bluish discoloration of palms and soles,<br />
                                        Fast breathing, not feeding well and Lethargic<br />
                                        In case of any emergency between 09.00 pm to 08.00 am, please report to emergency room (E/R) at Unit- 5 Banjara Hills.
                                    </p>
                                </div>
                                <div class="mt-2">
                                    <h5>IN CASE OF EMERGENCY CONTACT: 7337320891</h5>
                                </div>
                                <div class="mt-2">
                                    <h5>LOCTATION HELPLINE NUMBER :8374005353 {{currentLocation}}</h5>
                                </div>
                                <div class="mt-2">
                                    <h5>INVESTIGATIONS: </h5>
                                    <!--<p [textContent]="neonatalInfo.investigations"></p>-->
                                </div>
                                <div class="mt-2">
                                    <h5>Signature : </h5>
                                    <!--<p [textContent]="departmentOfNeonatologyData.SignatureOf_Doctor"></p>-->
                                </div>
                                <div class="mt-2">
                                    <h5>Doctor Name : </h5>
                                    <p [textContent]="departmentOfNeonatologyData.Doctor_Name"></p>
                                </div>
                                <h5>The Summary has been explained to me in a language which I understand.</h5>
                                <h5 *ngIf="regionalLanguage==='TELUGU'">
                                    డిశ్చార్జ్ సలహామరియు సూచనలను నాకు అర్ధమయ్యే భాషలో తెలియచేసినారు.
                                </h5>
                                <h5 *ngIf="regionalLanguage==='TAMIL'">வெளியேற்ற ஆலோசனைகள் மற்றும் குறிப்புகளை எனக்கு புரியும் மொழியில் தெரிவித்துள்ளனர்.</h5>
                                <div class="d-flex align-items-end">
                                    <div><span>Signature :&nbsp;</span></div>
                                    <div><h5 *ngIf="appointment && appointment.signature" class="border-bottom border-dark"><img *ngIf="appointment.signature!=null" [src]="safe(appointment.signature)" cssClass="img-fluid rounded-circle" style="height:80px" /></h5></div>
                                </div>
                                <div class="mt-2">
                                    <h5>Name of the patient / Attendant:</h5>
                                    <p *ngIf="NeonatalBaby && item.NeonatalBaby.babyOf" [textContent]="item.NeonatalBaby.babyOf"></p>
                                </div>
                                <div class="text-center border border-secondary " style="width: 45%; margin: 0 auto;">
                                    <h5>“For Birth certificate, please contact Circle No.10 Ward No. 91,</h5>
                                    <h5>Citizen Service Center (CSC), GHMC office at Khairatabad with reference of baby's case summary</h5>
                                </div>
                                <div class="border-dark mt-2 d-flex justify-content-between border-top">
                                    <div>
                                        <span>User Name :&nbsp;</span> <span class="text-uppercase" *ngIf="page.userAccount && page.userAccount.fullName"
                                                                             [textContent]="page.userAccount.fullName"></span>
                                    </div>
                                    <div>
                                        <span>Printed by :</span> <span class="text-uppercase" *ngIf="page.userAccount && page.userAccount.fullName"
                                                                        [textContent]="page.userAccount.fullName"></span>
                                    </div>
                                    <div><span>Print Date/Time :</span> <span [textContent]="date | date :'d.MM.yyyy'"></span>&nbsp;<span [textContent]="date | date :' h:mm a'"></span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Department of Obstetrics-->
                    <div *ngIf="isAdimissionCount > 0">
                        <div *ngFor="let item of obstericHistRec">
                            <div *ngFor="let obstericHist of getObstetricData(item.AdmissionId)">
                                <div>
                                    <div class="page-break-after-always" style=""></div>
                                    <div class="printshow">
                                        <banner-setting></banner-setting>
                                    </div>
                                    <h3 class="text-right font-weight-bold">{{ancCard?.consulatantDoctor}}</h3>
                                    <h3 class="text-right font-weight-bold"><b>DGO</b></h3>
                                    <h4 class="text-right font-weight-bold">Email ID: {{obReportData.Email}}</h4>
                                    <h3 class="text-center"><b>Department of Obstetrics</b></h3>
                                    <div class="container custom-container">
                                        <div *ngIf="ancCard && ancCard!=null" class="font-weight-bold">
                                            G <sub class="font-17" [textContent]="ancCard.gravida">
                                            </sub> P<sub class="font-17" [textContent]="ancCard.para"></sub> L<sub class="font-17" [textContent]="ancCard.abortion">
                                            </sub> A<sub class="font-17" [textContent]="ancCard.living"></sub> {{ancCard.ga}}
                                            <span *ngIf="obstericHist.dischargeSummary && obstericHist.dischargeSummary.medicalHistory" [innerHTML]=" obstericHist.dischargeSummary.medicalHistory"></span>
                                        </div>
                                    </div>
                                    <hr class="border-dark">
                                    <div class="mt-1" *ngIf="obstericHist ">
                                        <div *ngIf="ancCard">
                                            <table class="w-100 white-space-nowrap border-top2px border-bottom2px">
                                                <tr class="col-12">
                                                    <td class="w-130"><span class="">Name</span></td>
                                                    <td><h5 class="">:</h5></td>
                                                    <td><h5 class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="obstericHist  && obstericHist.FullName" [textContent]="obstericHist.FullName"></h5></td>
                                                </tr>
                                                <tr class="col-12">
                                                    <td class="w-130"><span class="">Age</span></td>
                                                    <td><h5 class="">:</h5></td>
                                                    <td><h5 class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="obstericHist  && obstericHist.Age" [textContent]="obstericHist.Age + ' years'"></h5></td>
                                                    <td class="w-130"><span class="">Admission Date & Time</span></td>
                                                    <td><h5 class="">:</h5></td>
                                                    <td><h5 class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="obstericHist  && obstericHist.AdmissionDateTime" [textContent]="obstericHist.AdmissionDateTime | date: 'dd.MM.yyyy hh:mm a'"></h5></td>
                                                </tr>
                                                <tr class="col-12">
                                                    <td class="w-130"><span class="">W/o</span></td>
                                                    <td><h5 class="">:</h5></td>
                                                    <td><h5 class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="obstericHist  && obstericHist.FatherOrHusband" [textContent]="obstericHist.FatherOrHusband"></h5></td>
                                                </tr>
                                                <tr class="col-12">
                                                    <td class="w-130"><span class="">Mr No</span></td>
                                                    <td><h5 class="">:</h5></td>
                                                    <td><h5 class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="obstericHist  && obstericHist.UMRNo" [textContent]="obstericHist.UMRNo"></h5></td>
                                                    <td class="w-130"><span class="">Birth Date & Time</span></td>
                                                    <td><h5 class="">:</h5></td>
                                                    <td><h5 class="d-block pl-1 text-truncate max-width_25vw" *ngIf="deliverydetails && deliverydetails.babybirthdate" [textContent]="deliverydetails.babybirthdate + ' ' + deliverydetails.babybirthtime"></h5></td>
                                                </tr>
                                                <tr class="col-12">
                                                    <td class="w-130"><span class="">IP No</span></td>
                                                    <td><h5 class="">:</h5></td>
                                                    <td><h5 class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="obstericHist  && obstericHist.appointmentNo" [textContent]="obstericHist.AdmissionNo"></h5></td>
                                                    <td class="w-130"><span class="">Discharge Date & Time</span></td>
                                                    <td><h5 class="">:</h5></td>
                                                    <td><h5 class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="obstericHist  && obstericHist.DischargeDateTime" [textContent]="obstericHist.DischargeDateTime | date: 'dd.MM.yyyy HH:mm:ss'"></h5></td>
                                                </tr>
                                                <tr class="col-12">
                                                    <td class="w-130"><span class="">LMP</span></td>
                                                    <td><h5 class="">:</h5></td>
                                                    <td><h5 class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="ancCard && ancCard.lmp" [textContent]="ancCard.lmp | date: 'dd.MM.yyyy'"></h5></td>
                                                    <td class="w-130"><span class="">EDD</span></td>
                                                    <td><h5 class="">:</h5></td>
                                                    <td><h5 class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="ancCard && ancCard.edd" [textContent]="ancCard.edd "></h5></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-12 ng-star-inserted p-0 pt-1" *ngIf="obHistory">
                                        <div>
                                            <h5 class="font-weight-bold mt-0">OBSTETRIC HISTORY</h5>
                                        </div>
                                        <table class="w-100" border="1">
                                            <thead>
                                                <tr class="tableBorder">
                                                    <th class="tableBorder font-weight-bolder">Preg No</th>
                                                    <th class="tableBorder font-weight-bolder">Year</th>
                                                    <th class="tableBorder font-weight-bolder">Birth Details</th>
                                                </tr>
                                            </thead>
                                            <tbody class="vertical-align-baseline" *ngIf="obHistory; else noData">
                                                <tr *ngFor="let item of obHistory;">
                                                    <td class="tableBorder" [textContent]="item.pregnancyNo"></td>
                                                    <td class="tableBorder" [textContent]="item.yearOfBirth"></td>
                                                    <td class="tableBorder">
                                                        <div *ngIf="item.pregnancyStatus">Pregnancy Status &nbsp;: &nbsp;<span [textContent]="item.pregnancyStatus"></span></div>
                                                        <div *ngIf="item.gaWeeks">GA Weeks &nbsp;: &nbsp;<span [textContent]="item.gaWeeks"></span></div>
                                                        <div *ngIf="item.deliveryAt">Delivered At &nbsp;: &nbsp;<span [textContent]="item.deliveryAt"></span></div>
                                                        <div *ngIf="item?.deliveryDetail"><span [textContent]="item?.deliveryDetail"></span></div>
                                                        <div *ngIf="item.indication">Indication &nbsp;: &nbsp;<span [textContent]="item.indication"></span></div>
                                                        <div *ngIf="item.other">Other Information &nbsp;: &nbsp;<span [textContent]="item.other"></span></div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <ng-template #noData>
                                            <div class="text-center">No data available</div>
                                        </ng-template>
                                    </div>
                                    <div class="col-12 ng-star-inserted p-0 pt-1">
                                        <div>
                                            <h5 class="font-weight-bold">MEDICAL HISTORY</h5>
                                        </div>
                                        <p *ngIf="obstericHist.DischargeSummary && obstericHist.DischargeSummary.medicalHistory" [innerHTML]="obstericHist.DischargeSummary.medicalHistory"></p>
                                    </div>
                                    <div class="col-12 p-0 pt-1">
                                        <div>
                                            <h5 class="font-weight-bold">SURGICAL HISTORY</h5>
                                        </div>
                                        <p *ngIf="obstericHist.DischargeSummary && obstericHist.DischargeSummary.surgicalHistory" [innerHTML]=" obstericHist.DischargeSummary.surgicalHistory"></p>
                                    </div>
                                    <div>
                                        <div class="col-12 p-0 pt-1">
                                            <div>
                                                <h5 class="font-weight-bold">ALLERGY HISTORY :</h5>
                                            </div>
                                            <p *ngIf="obstericHist.DischargeSummary && obstericHist.DischargeSummary.allergyHistory" [innerHTML]="obstericHist.DischargeSummary.allergyHistory"></p>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0 pt-1">
                                        <div>
                                            <h5 class="font-weight-bold">FAMILY HISTORY</h5>
                                        </div>
                                        <p *ngIf="obstericHist.DischargeSummary && obstericHist.DischargeSummary.familyHistory" [innerHTML]="obstericHist.DischargeSummary.familyHistory"></p>
                                    </div>
                                    <div class="col-12 p-0 pt-1">
                                        <div>
                                            <h5 class="font-weight-bold">PRESENT PREGNANCY DETAILS</h5>
                                        </div>
                                        <p *ngIf="obstericHist.DischargeSummary && obstericHist.DischargeSummary.presentPregnancyDetail" [innerHTML]="obstericHist.DischargeSummary.presentPregnancyDetail"></p>
                                    </div>
                                    <div>

                                        <h5 class="mb-2"><b>ADMISSION DETAILS :</b></h5>
                                        <p *ngIf="obstericHist.DischargeSummary && obstericHist.DischargeSummary.admssionDetails" [innerHTML]="obstericHist.DischargeSummary.admssionDetails"></p>
                                    </div>


                                    <div *ngFor="let admissionReport of getAdmissionReportData(item.AdmissionId)">
                                        <h4 class="ml-3">
                                            <b>
                                                <span> PROCEDURE :</span>
                                                <span *ngIf="admissionReport.deliverydetails && admissionReport.deliverydetails.deliveryType" [textContent]="admissionReport.deliverydetails.deliveryType">
                                                </span>
                                            </b>
                                        </h4>

                                        <table class="w-100 white-space-nowrap border-top2px border-bottom2px">
                                            <tr class="col-12" *ngIf="admissionReport.deliverydetails && admissionReport.deliverydetails.amnioticFluidVolume">
                                                <td class="w-130"><span class="" [textContent]="'Labour Type'"></span></td>
                                                <td><h5 class="" [textContent]="': '"></h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="admissionReport.deliverydetails.amnioticFluidVolume"></h5></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="admissionReport.deliverydetails && admissionReport.deliverydetails.indicationOfIOL">
                                                <td class="w-130"><span class="" [textContent]="'Indication For IOL'"></span></td>
                                                <td><h5 class="" [textContent]="': '"></h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="admissionReport.deliverydetails.indicationOfIOL"></h5></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="admissionReport.deliverydetails && admissionReport.deliverydetails.augmentation">
                                                <td class="w-130"><span class="" [textContent]="'Augmentation'"></span></td>
                                                <td><h5 class="" [textContent]="': '"></h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="admissionReport.deliverydetails.augmentation"></h5></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="admissionReport.deliverydetails && admissionReport.deliverydetails.membRupture">
                                                <td class="w-130"><span class="" [textContent]="'Membrane Rupture Type'"></span></td>
                                                <td><h5 class="" [textContent]="': '"></h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="admissionReport.deliverydetails.membRupture"></h5></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="admissionReport.deliverydetails && admissionReport.deliverydetails.amnioticFluidVolume">
                                                <td class="w-130"><span class="" [textContent]="'Amniotic Fluid'"></span></td>
                                                <td><h5 class="" [textContent]="': '"></h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="admissionReport.deliverydetails.amnioticFluidVolume"></h5></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="admissionReport.deliverydetails && admissionReport.deliverydetails.presentation">
                                                <td class="w-130"><span class="" [textContent]="'Presentation'"></span></td>
                                                <td><h5 class="" [textContent]="': '"></h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="admissionReport.deliverydetails.presentation"></h5></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="admissionReport.deliverydetails && admissionReport.deliverydetails.deliveryType">
                                                <td class="w-130"><span class="" [textContent]="'Delivery Type'"></span></td>
                                                <td><h5 class="" [textContent]="': '"></h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="admissionReport.deliverydetails.deliveryType"></h5></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="admissionReport.deliverydetails && admissionReport.deliverydetails.indicationforavd">
                                                <td class="w-130"><span class="" [textContent]="'Indication For AVD'"></span></td>
                                                <td><h5 class="" [textContent]="': '"></h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="admissionReport.deliverydetails.indicationforavd"></h5></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="admissionReport.deliverydetails && admissionReport.deliverydetails.perineum">
                                                <td class="w-130"><span class="" [textContent]="'Perineum'"></span></td>
                                                <td><h5 class="" [textContent]="': '"></h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="admissionReport.deliverydetails.perineum"></h5></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="admissionReport.deliverydetails && admissionReport.deliverydetails.anaesthesia">
                                                <td class="w-130"><span class="" [textContent]="'Anaesthesia'"></span></td>
                                                <td><h5 class="" [textContent]="': '"></h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="admissionReport.deliverydetails.anaesthesia"></h5></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="admissionReport.deliverydetails && admissionReport.deliverydetails.maternalComplication">
                                                <td class="w-130"><span class="" [textContent]="'Maternal Complication'"></span></td>
                                                <td><h5 class="" [textContent]="': '"></h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="admissionReport.deliverydetails.maternalComplication"></h5></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="admissionReport.deliverydetails && admissionReport.deliverydetails.pph">
                                                <td class="w-130"><span class="" [textContent]="'PPH'"></span></td>
                                                <td><h5 class="" [textContent]="': '"></h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="admissionReport.deliverydetails.pph"></h5></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="admissionReport.deliverydetails && admissionReport.deliverydetails.shoulderDystocia">

                                                <td class="w-130"> <span class="" [textContent]="'Shoulder Dystocia'"></span></td>
                                                <td><h5 class="" [textContent]="': '"></h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="admissionReport.deliverydetails.shoulderDystocia"></h5></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <div *ngFor="let item of getNeonatologyData(item.AdmissionId)">
                                        <h5 class="mb-2"><b>BABY DETAILS</b></h5>
                                        <table class="w-100 white-space-nowrap border-top2px border-bottom2px">

                                            <tr class="col-12" *ngIf="item.NeonatalBaby">
                                                <td class="w-130"><span class="">Baby Mrno</span></td>
                                                <td><h5 class="">:</h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="item.NeonatalBaby.babyUmr"></h5></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="item.NeonatalBaby">
                                                <td class="w-130"><span class="">Date of Birth</span></td>
                                                <td><h5 class="">:</h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="item.NeonatalBaby.birthDate | date: 'dd.MM.yyyy' "></h5></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="item.NeonatalBaby">
                                                <td class="w-130"><span class="">Time</span></td>
                                                <td><h5 class="">:</h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="item.NeonatalBaby.birthTime"></h5></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="item.NeonatalBaby">
                                                <td class="w-130"><span class="">Sex</span></td>
                                                <td><h5 class="">:</h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="item.NeonatalBaby.sex"></h5></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="item.NeonatalBaby">
                                                <td class="w-130"><span class="">Apgar</span></td>
                                                <td><h5 class="">:</h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="item.NeonatalBaby.apgar + '/' + item.NeonatalBaby.apgar1 + '/' + item.NeonatalBaby.apgar10"></h5></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="item.NeonatalBaby">
                                                <td class="w-130"><span class="">Weight</span></td>
                                                <td><h5 class="">:</h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="item.NeonatalBaby.weight"></h5></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="item.NeonatalBaby">
                                                <td class="w-130"><span class="">Status</span></td>
                                                <td><h5 class="">:</h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="item.NeonatalBaby.status"></h5></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="item.NeonatalBaby">
                                                <td class="w-130"><span class="">Grow Centile</span></td>
                                                <td><h5 class="">:</h5></td>
                                                <td colspan="2"><!--<h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="neonatalBaby.GrowCentile"></h5>--></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="item.NeonatalBaby">
                                                <td class="w-130"><span class="">CordAbg</span></td>
                                                <td><h5 class="">:</h5></td>
                                                <td colspan="2"><h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="item.NeonatalBaby.abg"></h5></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="item.NeonatalBaby">
                                                <td class="w-130"><span class="">pH Arterial</span></td>
                                                <td><h5 class="">:</h5></td>
                                                <td colspan="2"><!--<h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="patientDetails.pHVenous"></h5>--></td>
                                            </tr>
                                            <tr class="col-12" *ngIf="item.NeonatalBaby">
                                                <td class="w-130"><span class="">Venous</span></td>
                                                <td><h5 class="">:</h5></td>
                                                <td colspan="2"><!--<h5 class="d-block pl-1 text-truncate max-width_25vw" [textContent]="patientDetails.Arterial"></h5>--></td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div>
                                        <h5 class="mb-2"><b>COURSE IN THE HOSPITAL :</b></h5>
                                        <p *ngIf="obstericHist.DischargeSummary && obstericHist.DischargeSummary.courseHospital" [innerHTML]="obstericHist.DischargeSummary.courseHospital"></p>
                                    </div>
                                    <div>
                                        <h5 class="mb-2"><b>CONDITION AT DISCHARGE :</b></h5>
                                        <p *ngIf="obstericHist.DischargeSummary && obstericHist.DischargeSummary.consitionDischarge" [innerHTML]="obstericHist.DischargeSummary.consitionDischarge"></p>
                                        <div class="mt-1">
                                            <h5 class="mb-2"><b>DISCHARGE ADVICE</b></h5>
                                            <p [textContent]="dischargeAdviced"> </p>
                                        </div>
                                    </div>
                                    <div class="mt-1">
                                        <h5> <b>INVESTIGATION ADVISED</b> </h5>
                                        <p *ngIf="investigationAdviced" [textContent]="investigationAdviced"></p>
                                    </div>
                                    <div>
                                        <h5 class="mb-2"><b>FOLLOW UP / REVIEW DATE</b></h5>
                                        <p *ngIf="obstericHist.DischargeSummary && obstericHist.DischargeSummary.followUp" [innerHTML]="obstericHist.DischargeSummary.followUp"></p>
                                    </div>
                                    <div>
                                        <h5 class="mb-2"><b>FINAL DIAGNOSIS</b></h5>
                                        <p *ngIf="obstericHist.DischargeSummary && obstericHist.DischargeSummary.FinalDiagnosis" [innerHTML]="obstericHist.DischargeSummary.FinalDiagnosis"></p>
                                    </div>
                                    <div>
                                        <h5 class="mb-2"><b>INVESTIGATION DONE</b></h5>
                                        <table class="table table-bordered border-0">
                                            <thead>
                                                <tr>
                                                    <th class="border-0"><b>Date</b></th>
                                                    <th class="border-0"> <b> Investigation</b> </th>
                                                    <th class="border-0"> <b>Result</b> </th>
                                                    <th class="border-0"> <b>Normal Value </b> </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let investigation of investigationsDone" class="mb-0">
                                                    <td class="border-0" [textContent]="investigation.CreatedDate | date: 'dd.MM.yyyy'"></td>
                                                    <td class="border-0" [textContent]="investigation.ParameterName"></td>
                                                    <td class="border-0" [textContent]="investigation.Result"></td>
                                                    <td class="border-0" [textContent]="investigation.NormalValue"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div>
                                            <div> <b>Report to the hospital in case of fever,foul smelling discharge per vaginum,excessive bleeding per vaginum or abdominal pain.</b> </div>
                                            <div class="mt-2">
                                                <h5 class="font-weight-bolder">IN CASE OF EMERGENCY CONTACT:040-47807442 / 7337320890 <span></span></h5>
                                            </div>
                                            <div class="mt-2">
                                                <h5 class="font-weight-bolder">LACTATION HELPLINE NUMBER :8374005353<span> </span></h5>
                                            </div>
                                            <div class="row">
                                                <div class="col-7"></div>
                                                <div class="col-5">
                                                    <h5 class="font-weight-bolder">Signature: <span></span></h5>
                                                    <h5 class="font-weight-bolder">Doctor Name: <span></span></h5>
                                                </div>
                                            </div>
                                            <h5 class="mb-3">The discharge advice and instructions have been explained to me in a language which I understand.</h5>
                                            <h5 *ngIf="regionalLanguage==='TELUGU'">డిశ్చార్జ్ సలహామరియు సూచనలను నాకు అర్ధమయ్యే భాషలో తెలియచేసినారు.</h5>
                                            <h5 *ngIf="regionalLanguage==='TAMIL'">வெளியேற்ற ஆலோசனைகள் மற்றும் குறிப்புகளை எனக்கு புரியும் மொழியில் தெரிவித்துள்ளனர்.</h5>

                                            <h5>Signature : <span></span></h5>
                                            <h5 class="mb-3">Name of the patient / Attendant : <span></span></h5>
                                            <hr class="mt-1 border-dark mb-0">
                                            <div class="row">
                                                <div class="col-4">
                                                    <h5>Saved User : <span></span></h5>
                                                </div>
                                                <div class="col-4">
                                                    <h5>Print Date & Time : <span></span> </h5>
                                                </div>
                                                <div class="col-4">
                                                    <h5>Printed by : <span></span></h5>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div *ngIf="isAdimissionCount > 0">
                        <div *ngFor="let item of admissionReport">
                            <ng-container>
                                <div class="d-flex justify-content-center pt-2 pb-3">
                                    <h3 class="d-flex justify-content-center">
                                        CAESAREAN SECTION
                                    </h3>
                                </div>
                            </ng-container>

                            <div>
                                <div class="d-flex column-gap15px">
                                    <table class="w-100 white-space-nowrap ">
                                        <tr class="col-12 vertical-align-top">
                                            <td class="w-100px">
                                                <h5>  Name</h5>
                                            </td>
                                            <td>
                                                <h5>:</h5>
                                            </td>
                                            <td class="w-200p">
                                                <span *ngIf="appointment && appointment.patientName" [textContent]="appointment.patientName"></span>
                                            </td>
                                            <td class="w-150px">
                                                <h5> UMR No.</h5>
                                            </td>
                                            <td>
                                                <h5>:</h5>
                                            </td>
                                            <td class="w-200p">
                                                <span *ngIf="appointment && appointment.umrNo">{{appointment.umrNo}}</span>
                                            </td>
                                            <td class="w-100px">
                                                <h5>IP No.</h5>
                                            </td>
                                            <td>
                                                <h5>:</h5>
                                            </td>
                                            <td class="w-200p">
                                                <span *ngIf="appointment && appointment.appointmentNo">{{appointment.appointmentNo}}</span>
                                            </td>
                                        </tr>
                                        <tr class="col-12 vertical-align-top">
                                            <td>
                                                <h5> Room No.</h5>
                                            </td>
                                            <td>
                                                <h5>:</h5>
                                            </td>
                                            <td class="w-200p">
                                                <span *ngIf="appointment && appointment.roomName">{{appointment.roomName}}</span>
                                            </td>

                                            <td>
                                                <h5>  Date & Time</h5>
                                            </td>
                                            <td>
                                                <h5>:</h5>
                                            </td>

                                            <td class="w-200p">
                                                <span *ngIf="admissionDetails && admissionDetails.dateOfAdmission" [textContent]="admissionDetails.dateOfAdmission | date :'d.MM.yyyy'"></span>
                                            </td>
                                        </tr>

                                    </table>
                                    <h4 class="d-flex white-space-nowrap justify-content-end">
                                    </h4>
                                </div>

                                <div *ngIf="fetchCaesaren(item.admissionId).length > 0">

                                    <div *ngFor="let lscsmodel of fetchCaesaren(item.admissionId)">
                                        <div class="border-dark border-top pt-2">
                                            <div>
                                                <h5><u>SURGICAL TEAM</u></h5>
                                            </div>
                                            <div>
                                                <table class="w-100">
                                                    <tr>
                                                        <td class="w-130">
                                                            <h5> Surgeon</h5>
                                                        </td>
                                                        <td class="w-16p">
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <div class="min-width200px">
                                                                <span *ngIf="lscsmodel && lscsmodel.surgeonName" [textContent]="lscsmodel.surgeonName"></span>
                                                            </div>
                                                        </td>
                                                        <td class="w-130">
                                                            <h5> Asst.Surgeon</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="m-0">:</h5>
                                                        </td>

                                                        <td>
                                                            <div class="min-width200px">
                                                                <span *ngIf="lscsmodel && lscsmodel.asstSurgeon" [textContent]="lscsmodel.asstSurgeon"></span>
                                                            </div>
                                                        </td>
                                                        <td class="w-75p">
                                                            <h5>Nurse</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="m-0">:</h5>

                                                        </td>

                                                        <td class="w-200p">
                                                            <span *ngIf="lscsmodel && lscsmodel.asstSurgeon" [textContent]="lscsmodel.asstSurgeon"></span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-130">
                                                            <h5> Anaesthetist</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="m-0">:</h5>
                                                        </td>
                                                        <td>
                                                            <span *ngIf="lscsmodel && lscsmodel.anaesthetist" [textContent]="lscsmodel.anaesthetist"></span>
                                                        </td>
                                                        <td class="w-130">
                                                            <h5> Paediatrician</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="m-0">:</h5>
                                                        </td>
                                                        <td>
                                                            <span *ngIf="lscsmodel && lscsmodel.paediatrician" [textContent]="lscsmodel.paediatrician"></span>
                                                        </td>

                                                    </tr>
                                                </table>
                                            </div>
                                        </div>

                                        <div class="mt-2">
                                            <div>
                                                <h5>
                                                    <u>TYPE OF CS</u>
                                                </h5>
                                            </div>
                                            <div>
                                                <table class="w-100">
                                                    <tr class="col-12 vertical-align-top">
                                                        <td class="w-170px">
                                                            <h5> Type</h5>
                                                        </td>
                                                        <td class="w-16p">
                                                            <h5 class="m-0">:</h5>
                                                        </td>
                                                        <td>
                                                            <div class="min-width200px">
                                                                <span *ngIf="lscsmodel && lscsmodel.type" [textContent]="lscsmodel.type+','"></span>
                                                            </div>
                                                        </td>
                                                        <td class="w-130">
                                                            <h5> Anaesthesia</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="m-0">:</h5>
                                                        </td>
                                                        <td>
                                                            <div class="min-width200px">
                                                                <span *ngIf="lscsmodel && lscsmodel.anaesthesia" [textContent]="lscsmodel.anaesthesia"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-150 vertical-align-top">
                                                            <h5> Primary Indication</h5>
                                                        </td>
                                                        <td class="vertical-align-top">
                                                            <h5 class="m-0">:</h5>
                                                        </td>
                                                        <td class="vertical-align-top">
                                                            <span *ngFor="let item of  lscsmodel?.primaryIndication" [textContent]="item + ','"></span>
                                                        </td>
                                                        <td class="vertical-align-top">
                                                            <h5> Contributing</h5>
                                                        </td>
                                                        <td class="w-16p vertical-align-top">
                                                            <h5 class="m-0">:</h5>
                                                        </td>
                                                        <td class="vertical-align-top">
                                                            <span *ngIf="lscsmodel && lscsmodel.contributingFactors" [textContent]="lscsmodel.contributingFactors"></span>
                                                        </td>
                                                    </tr>

                                                </table>
                                            </div>

                                        </div>

                                        <div class="border-dark border-top pt-2">
                                            <div>
                                                <h5>
                                                    <u>URGENCY OF CS (NCEPOD)</u>
                                                </h5>
                                            </div>
                                            <div>
                                                <table>
                                                    <tr>
                                                        <td class="w-100-px">
                                                            <h5> Category</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="m-0">:</h5>
                                                        </td>
                                                        <td class="text-capitalize">
                                                            <span *ngIf="lscsmodel && lscsmodel.categoryName" [textContent]="lscsmodel.categoryName"></span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>

                                        </div>
                                        <div class="d-flex mt-2">
                                            <div class="col-6 pl-0">
                                                <div>
                                                    <div>
                                                        <h5><u>ROBSON CLASSIFICATION</u></h5>
                                                    </div>
                                                    <table>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5> Robson's CLS- Group</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize ">
                                                                <span [textContent]=""></span>
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p white-space-nowrap">
                                                                <h5>
                                                                    Decision to Delivery Time(min)
                                                                </h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.decisionToDeliveryTime" [textContent]="lscsmodel.decisionToDeliveryTime"></span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div class="mt-2">
                                                    <div>
                                                        <h5>
                                                            <u>PROCEDURE</u>
                                                        </h5>
                                                    </div>
                                                    <table>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Abdominal Incision</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize ">
                                                                <h5 *ngIf="lscsmodel && lscsmodel.abdominalIncision" [textContent]="lscsmodel.abdominalIncision"></h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Adhesions</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize ">
                                                                <h5 *ngIf="lscsmodel && lscsmodel.adhesions" [textContent]="lscsmodel.adhesions"></h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>LUS Condition</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize ">
                                                                <span *ngIf="lscsmodel && lscsmodel.segmentCondition" [textContent]="lscsmodel.segmentCondition"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5> Previous Scar Integrity</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize ">
                                                                <span *ngIf="lscsmodel && lscsmodel.scarIntegrity" [textContent]="lscsmodel.scarIntegrity"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5> Uterine Incision</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.uterineIncision" [textContent]="lscsmodel.uterineIncision"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5> Plac - Site</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize ">
                                                                <span *ngIf="lscsmodel && lscsmodel.placentaSite" [textContent]="lscsmodel.placentaSite"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Placenta position</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize ">
                                                                <span *ngIf="lscsmodel && lscsmodel.placentaPosition" [textContent]="lscsmodel.placentaPosition"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Vertical Uterine Tear</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize ">
                                                                <span *ngIf="lscsmodel && lscsmodel.verticalUterineTear" [textContent]="lscsmodel.verticalUterineTear"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Uterine Vessel Involved</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.uterineVesselInvolved" [textContent]="lscsmodel.uterineVesselInvolved"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5 class="white-space-nowrap">Any Additional Procedures</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.additionalProcedures" [textContent]="lscsmodel.additionalProcedures"></span>
                                                            </td>
                                                        </tr>

                                                    </table>
                                                </div>

                                                <div class="mt-2">
                                                    <div>
                                                        <h5>
                                                            <u>COMPLICATIONS</u>
                                                        </h5>
                                                    </div>
                                                    <table>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>PPH</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.pph" [textContent]="lscsmodel.pph"></span>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Est Blood Loss (ml)</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.estBloodLoss" [textContent]="lscsmodel.estBloodLoss"></span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div class="mt-2">
                                                    <div>
                                                        <h5>
                                                            <u>OTHER FINDINGS</u>
                                                        </h5>
                                                    </div>
                                                    <table>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Rt Ovary</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.rTOvary" [textContent]="lscsmodel.rTOvary"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Rt.Tube</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.rTTube" [textContent]="lscsmodel.rTTube"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Lt. Ovary</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.lTOvary" [textContent]="lscsmodel.lTOvary"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Lt. Tube</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.lTTube" [textContent]="lscsmodel.lTTube"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Fibroids</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.fibroids" [textContent]="lscsmodel.fibroids"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Uterine Anomaly</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.uterineAnomaly" [textContent]="lscsmodel.uterineAnomaly"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Tubal Ligation</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.oFtubalLigation" [textContent]="lscsmodel.oFtubalLigation"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Bladder Catheterized</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.bladderCatheterized" [textContent]="lscsmodel.bladderCatheterized"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Swab Count</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.swabs" [textContent]="lscsmodel.swabs"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Instruments Count</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.instruments" [textContent]="lscsmodel.instruments"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>
                                                                    Placenta / cord abnormalities
                                                                </h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.placenta" [textContent]="lscsmodel.placenta"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Placenta Weight (gm) </h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.placentaWeight" [textContent]="lscsmodel.placentaWeight"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Placenta HPE</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.placentaHPE" [textContent]="lscsmodel.placentaHPE"></span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>

                                            <div class="col-6 mt-2">

                                                <div>
                                                    <div>
                                                        <h5>
                                                            <u>CLOSURE</u>
                                                        </h5>
                                                    </div>
                                                    <table>
                                                        <tr class="col-12 vertical-align-top">

                                                            <td class="w-200p">
                                                                <h5>Uterine Closure</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.uterineClosure" [textContent]="lscsmodel.uterineClosure"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Skin Closure Type</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.skinClosureType" [textContent]="lscsmodel.skinClosureType"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Drains</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.drain" [textContent]="lscsmodel.drain"></span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div class="mt-4">
                                                    <div>
                                                        <h5><u> SUTURE MATERIAL USED</u></h5>

                                                    </div>
                                                    <table>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Uterine</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.uterineIncision" [textContent]="lscsmodel.uterineIncision"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Tubal Ligation</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.tubalLigation" [textContent]="lscsmodel.tubalLigation"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Rectus Sheath</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.rectusSheath" [textContent]="lscsmodel.rectusSheath"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-200p">
                                                                <h5>Skin Closure</h5>
                                                            </td>
                                                            <td>
                                                                <h5 class="m-0">:</h5>
                                                            </td>
                                                            <td class="w-200p text-capitalize">
                                                                <span *ngIf="lscsmodel && lscsmodel.skinClosureType" [textContent]="lscsmodel.skinClosureType"></span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mt-2">
                                            <table>
                                                <tr class="col-12 vertical-align-top">
                                                    <td colspan="1">
                                                        <h5 *ngIf="lscsmodel && lscsmodel.babyPresentation1"><u></u></h5>
                                                    </td>
                                                    <td colspan="2" class="w-200p">
                                                        <h5 *ngIf="lscsmodel && lscsmodel.babyPresentation1"><u>BABY DETAILS -1</u></h5>
                                                    </td>

                                                    <td class="w-200p text-capitalize">
                                                    </td>
                                                    <td colspan="2" class="w-200p">
                                                        <h5 *ngIf="lscsmodel && lscsmodel.babyPresentation2"><u>BABY DETAILS -2</u></h5>
                                                    </td>

                                                    <td class="w-200p text-capitalize">
                                                    </td>
                                                    <td colspan="3" class="w-200p">
                                                        <h5 *ngIf="lscsmodel && lscsmodel.babyPresentation3"><u>BABY DETAILS -3</u></h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                    </td>
                                                </tr>
                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>Presentation</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.babyPresentation1" [textContent]="lscsmodel.babyPresentation1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.babyPresentation2" [textContent]="lscsmodel.babyPresentation2"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.babyPresentation3" [textContent]="lscsmodel.babyPresentation3"></span>
                                                    </td>
                                                </tr>
                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>P P Position</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.presentingPartPosition1" [textContent]="lscsmodel.presentingPartPosition1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.presentingPartPosition2" [textContent]="lscsmodel.presentingPartPosition2"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.presentingPartPosition3" [textContent]="lscsmodel.presentingPartPosition3"></span>
                                                    </td>
                                                </tr>
                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>Amniotic Fluid Colour</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.amnioticFluidColour1" [textContent]="lscsmodel.amnioticFluidColour1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.amnioticFluidColour2" [textContent]="lscsmodel.amnioticFluidColour2"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.amnioticFluidColour3" [textContent]="lscsmodel.amnioticFluidColour3"></span>
                                                    </td>
                                                </tr>
                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>Quantity</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.quantity1" [textContent]="lscsmodel.quantity1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.quantity2" [textContent]="lscsmodel.quantity2"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.quantity3" [textContent]="lscsmodel.quantity3"></span>
                                                    </td>
                                                </tr>
                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>Forceps</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.forceps1" [textContent]="lscsmodel.forceps1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.forceps2" [textContent]="lscsmodel.forceps2"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.forceps3" [textContent]="lscsmodel.forceps3"></span>
                                                    </td>
                                                </tr>
                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>Sex</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.sexOfBaby1" [textContent]="lscsmodel.sexOfBaby1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.sexOfBaby2" [textContent]="lscsmodel.sexOfBaby2"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.sexOfBaby3" [textContent]="lscsmodel.sexOfBaby3"></span>
                                                    </td>
                                                </tr>
                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>
                                                            Weight (kg)
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.babyBirthWt1" [textContent]="lscsmodel.babyBirthWt1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <h5 *ngIf="lscsmodel && lscsmodel.babyBirthWt2" [textContent]="lscsmodel.babyBirthWt2"></h5>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.babyBirthWt3" [textContent]="lscsmodel.babyBirthWt3"></span>
                                                    </td>
                                                </tr>
                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>Date of Birth</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.babyBirthDate1" [textContent]="lscsmodel.babyBirthDate1 |  date: 'dd/MM/yyyy'"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.babyBirthDate2" [textContent]="lscsmodel.babyBirthDate2 |  date: 'dd/MM/yyyy'"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.babyBirthDate3" [textContent]="lscsmodel.babyBirthDate3 |  date: 'dd/MM/yyyy'"></span>
                                                    </td>
                                                </tr>
                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>Time of Birth</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.babyBirthTime1" [textContent]="lscsmodel.babyBirthTime1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.babyBirthTime2" [textContent]="lscsmodel.babyBirthTime2"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.babyBirthTime3" [textContent]="lscsmodel.babyBirthTime3"></span>
                                                    </td>
                                                </tr>
                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>Apgar</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.apgar1" [textContent]="lscsmodel.apgar1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.apgar2" [textContent]="lscsmodel.apgar2"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.apgar3" [textContent]="lscsmodel.apgar3"></span>
                                                    </td>
                                                </tr>
                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>
                                                            Cord ABG
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.cordABG1" [textContent]="lscsmodel.cordABG1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.cordABG2" [textContent]="lscsmodel.cordABG2"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.cordABG3" [textContent]="lscsmodel.cordABG3"></span>
                                                    </td>
                                                </tr>
                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>Date</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.date1" [textContent]="lscsmodel.date1 |  date: 'dd/MM/yyyy'"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.date2" [textContent]="lscsmodel.date2 |  date: 'dd/MM/yyyy'"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.date3" [textContent]="lscsmodel.date3 |  date: 'dd/MM/yyyy'"></span>
                                                    </td>
                                                </tr>
                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>Time</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.timeOfTesting1" [textContent]="lscsmodel.timeOfTesting1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.timeOfTesting2" [textContent]="lscsmodel.timeOfTesting2"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.timeOfTesting3" [textContent]="lscsmodel.timeOfTesting3"></span>
                                                    </td>
                                                </tr>

                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>pH Arterial</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.pHArterial1" [textContent]="lscsmodel.pHArterial1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.pHArterial2" [textContent]="lscsmodel.pHArterial2"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <h5 *ngIf="lscsmodel && lscsmodel.pHArterial3" [textContent]="lscsmodel.pHArterial3"></h5>
                                                    </td>
                                                </tr>
                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>Venous</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.pHVenous1" [textContent]="lscsmodel.pHVenous1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.pHVenous2" [textContent]="lscsmodel.pHVenous2"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.pHVenous3" [textContent]="lscsmodel.pHVenous3"></span>
                                                    </td>
                                                </tr>

                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>BE ECF Arterial</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.eCFArterial1" [textContent]="lscsmodel.eCFArterial1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.eCFArterial2" [textContent]="lscsmodel.eCFArterial2"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.eCFArterial3" [textContent]="lscsmodel.eCFArterial3"></span>
                                                    </td>
                                                </tr>
                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>Venous</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.eCFVenous1" [textContent]="lscsmodel.eCFVenous1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.eCFVenous2" [textContent]="lscsmodel.eCFVenous2"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.eCFVenous3" [textContent]="lscsmodel.eCFVenous3"></span>
                                                    </td>
                                                </tr>

                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>Lactate Arterial</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.lactateArterial1" [textContent]="lscsmodel.lactateArterial1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.lactateArterial2" [textContent]="lscsmodel.lactateArterial2"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.lactateArterial3" [textContent]="lscsmodel.lactateArterial3"></span>
                                                    </td>
                                                </tr>
                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>Venous</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.lactateVenous1" [textContent]="lscsmodel.lactateVenous1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.lactateVenous2" [textContent]="lscsmodel.lactateVenous2"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.lactateVenous3" [textContent]="lscsmodel.lactateVenous3"></span>
                                                    </td>
                                                </tr>

                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>PO2 Arterial</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.pO2Arterial1" [textContent]="lscsmodel.pO2Arterial1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.pO2Arterial2" [textContent]="lscsmodel.pO2Arterial2"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.pO2Arterial3" [textContent]="lscsmodel.pO2Arterial3"></span>
                                                    </td>
                                                </tr>
                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>Venous</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.pO2Venous1" [textContent]="lscsmodel.pO2Venous1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.pO2Venous2" [textContent]="lscsmodel.pO2Venous2"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.pO2Venous3" [textContent]="lscsmodel.pO2Venous3"></span>
                                                    </td>
                                                </tr>

                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>PCO2 Arterial</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.pCO2Arterial1" [textContent]="lscsmodel.pCO2Arterial1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.pCO2Arterial2" [textContent]="lscsmodel.pCO2Arterial2"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.pCO2Arterial3" [textContent]="lscsmodel.pCO2Arterial3"></span>
                                                    </td>
                                                </tr>
                                                <tr class="col-12 vertical-align-top">
                                                    <td class="w-200p">
                                                        <h5>Venous</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.pCO2Venous1" [textContent]="lscsmodel.pCO2Venous1"></span>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.pCO2Venous2" [textContent]="lscsmodel.pCO2Venous2"></span>
                                                    </td>
                                                    <td colspan="2">
                                                        <h5 class="m-0"></h5>
                                                    </td>
                                                    <td class="w-200p text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.pCO2Venous3" [textContent]="lscsmodel.pCO2Venous3"></span>
                                                    </td>
                                                </tr>
                                            </table>
                                            <h5 class="mt-2">
                                                <u>TIMINGS</u>
                                            </h5>
                                            <table class="w-100">
                                                <tr>
                                                    <td>
                                                        <h5>Shifting into OT</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0 w-16p">:</h5>
                                                    </td>
                                                    <td class="text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.shiftingIntoOT" [textContent]="lscsmodel.shiftingIntoOT"></span>
                                                    </td>
                                                    <td>
                                                        <h5>Start of Surgrey</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0 w-16p">:</h5>
                                                    </td>
                                                    <td class="text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.startOfSurgery" [textContent]="lscsmodel.startOfSurgery"></span>
                                                    </td>
                                                    <td>
                                                        <h5>Shifting out of OT</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0 w-16p">:</h5>
                                                    </td>
                                                    <td class="text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.shiftingOutOfOT" [textContent]="lscsmodel.shiftingOutOfOT"></span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="w-170px white-space-nowrap">
                                                        <h5>Start Of Anaesthesia</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="text-capitalize">
                                                        <span *ngIf="lscsmodel && lscsmodel.startOfAnaesthesia" [textContent]="lscsmodel.startOfAnaesthesia"></span>
                                                    </td>
                                                    <td>
                                                        <h5>Baby Delivery</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="text-capitalize">
                                                        <span [textContent]=""></span>
                                                    </td>
                                                    <td rowspan="2" class="vertical-align-baseline white-space-nowrap w-25">
                                                        <h5>
                                                            Giving prophy- lactic oxytocin
                                                        </h5>
                                                    </td>
                                                    <td rowspan="2" class="vertical-align-baseline">
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td rowspan="2" class=" text-capitalize vertical-align-baseline">
                                                        <span *ngIf="lscsmodel && lscsmodel.prophylacticOxytocin" [textContent]="lscsmodel.prophylacticOxytocin"></span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="w-200p">
                                                        <h5>Antibiotic Total Dose</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="text-capitalize w-130">
                                                        <span *ngIf="lscsmodel && lscsmodel.antibioticTotalDoseGivenTime" [textContent]="lscsmodel.antibioticTotalDoseGivenTime"></span>
                                                    </td>
                                                    <td class="white-space-nowrap w-150px">
                                                        <h5>End of Procedure</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="m-0">:</h5>
                                                    </td>
                                                    <td class="text-capitalize w-130">
                                                        <span *ngIf="lscsmodel && lscsmodel.endOfProcedure" [textContent]="lscsmodel.endOfProcedure"></span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 pl-0 mt-2">
                                    <div>
                                        <div *ngIf="fetchMedRecords(item.admissionId).length > 0">
                                            <div>
                                                <h5>OTHER NOTES :</h5>
                                            </div>
                                            <div class="dashboard-body mt-2">
                                                <div>
                                                    <h5> <u>Postpartum Orders</u></h5>
                                                    <div *ngIf="records.length > 0">
                                                        <div class="dashboard-body">
                                                            <div *ngFor="let record of fetchMedRecords(item.admissionId);index as j;">
                                                                <div class="overflow-auto">
                                                                    <table class="w-100" style="border-collapse: collapse;">
                                                                        <thead>
                                                                            <tr>
                                                                                <th><h5 class=" ">Medications Adviced</h5> </th>
                                                                                <th><h5 class=" ">Generic Name</h5></th>
                                                                                <th><h5 class=" ">Dosage</h5></th>
                                                                                <th><h5 class=" ">Category</h5></th>
                                                                                <th><h5 class=" ">Qty</h5></th>
                                                                                <th><h5 class=" ">Remarks</h5></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                            <tr *ngFor="let item of record.medicines; let i = index">
                                                                                <td class="min-width180px">
                                                                                    <div>
                                                                                        <div class=" text-wrap text-uppercase" [textContent]="item.productName"></div>
                                                                                        <div class=" text-wrap text-uppercase" *ngIf="item.genericName" [textContent]="'( ' + item.genericName + ' )'"></div>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="min-width180px">
                                                                                    <div>
                                                                                        <span class=" text-wrap text-uppercase" *ngIf="item.genericName" [textContent]="'( ' + item.genericName + ' )'"></span>
                                                                                        <span class=" text-wrap text-uppercase" *ngIf="!item.genericName" [textContent]="'--'"></span>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div>
                                                                                        <span class="text-capitalize" *ngIf="item.instruction" [textContent]="item.instruction"></span>

                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div>
                                                                                        <span class="text-capitalize" *ngIf="item.categoryName" [textContent]="item.categoryName"></span>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div>
                                                                                        <span class="text-capitalize" *ngIf="item.dosage" [textContent]="item.dosage"></span>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div>
                                                                                        <span class="text-capitalize" *ngIf="item.remark" [textContent]="item.remark"></span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <div *ngIf="fetchLabRecords(item.admissionId).length > 0">
                                                <h5>Investigations Ordered</h5>
                                                <div class="text-lowercase" *ngFor="let item of fetchLabRecords(item.admissionId); let i =index;">
                                                    <span class=" text-wrap" [textContent]="item.testName"></span>
                                                    (&nbsp;<span class=" text-wrap" [textContent]="item.departmentName"></span>&nbsp;)
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="fetchOrderPrescriptionRecords(item.admissionId).length > 0">
                                            <div *ngFor="let item of fetchOrderPrescriptionRecords(item.admissionId)">
                                                <div class="mt-2">
                                                    <div *ngIf="item.orderName==='Investigation Template'">
                                                        <div class="w-100" *ngIf="item.comments && item.comments !== null">

                                                            <h5 class=" dashboard-title" *ngIf="item.comments && item.comments !== null" [textContent]="item.orderName"></h5>
                                                            <table>
                                                                <tr class="pl-1" [innerHTML]="item.comments"> </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mt-2">
                                                    <div *ngIf="item.orderName==='Maternal Plan'">
                                                        <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                            <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Plan of Management - Mother</h5>
                                                            <table>
                                                                <tr class="pl-1" [innerHTML]="item.comments"></tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mt-2">
                                                    <div *ngIf="item.orderName==='Fetal Plan'">
                                                        <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                            <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Plan of Management - Fetus</h5>
                                                            <table>

                                                                <tr class="pl-1" [innerHTML]="item.comments">
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mt-2">
                                                    <div *ngIf="item.orderName==='Important Advice'">
                                                        <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                            <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Advice</h5>
                                                            <table>
                                                                <tr class="pl-1" [innerHTML]="item.comments">
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mt-2">
                                                    <div *ngIf="item.orderName==='Nutrional Orders IP'">
                                                        <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                            <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Nutrional Order</h5>
                                                            <table>
                                                                <tr class="pl-1" [innerHTML]="item.comments">
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mt-2">
                                                    <div *ngIf="item.orderName==='Diagnosis IP'">
                                                        <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                            <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Diagnosis</h5>
                                                            <table>
                                                                <tr class="pl-1" [innerHTML]="item.comments">
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mt-2">
                                                    <div *ngIf="item.orderName==='Maternal Plan IP'">
                                                        <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                            <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Plan of Management - Mother</h5>
                                                            <table>
                                                                <tr class="pl-1" [innerHTML]="item.comments">
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mt-2">
                                                    <div *ngIf="item.orderName==='Fetal Plan IP'">
                                                        <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                            <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Plan of Management - Fetus</h5>
                                                            <table>
                                                                <tr class="pl-1" [innerHTML]="item.comments">
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mt-2">
                                                    <div *ngIf="item.orderName==='Procedure Template IP'">
                                                        <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                            <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Procedure Template</h5>
                                                            <table>
                                                                <tr class="pl-1" [innerHTML]="item.comments">
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mt-2">
                                                    <div *ngIf="item.orderName==='Other Medication'">
                                                        <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                            <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null" [textContent]="item.orderName"></h5>
                                                            <table>
                                                                <tr class="pl-1" [innerHTML]="item.comments">
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <footer class="border-dark border-top font-13 justify-content-between pb-1">
                                    <div class="d-flex flex-sm-nowrap flex-wrap font-weight-semibold justify-content-between align-items-end text-black">
                                        <div class="font-14 flex-grow-1">
                                            <div class=" d-flex flex-wrap">
                                                <span class="pl-0 col-7">Saved on Date & Time : {{date | date:' dd.MM.yyyy h:mm a'}}</span>        <span class="pr-0 col-5">Saved by : {{name}}</span>

                                                <span class="pl-0 col-7">Accessed on Date &Time : </span>      <span class="pr-0 col-5">Accessed by :</span>
                                            </div>
                                        </div>
                                        <div class="align-self-end justify-content-center d-flex align-items-center">
                                            <div class="white-space-nowrap text-center">
                                                <img *ngIf="appointment.signature!=null" [src]="safe(appointment.signature)" cssClass="img-fluid rounded-circle" style="height:80px" />
                                                <div>Signature of Surgeon</div>
                                            </div>
                                        </div>

                                    </div>
                                </footer>
                            </div>

                            <div *ngIf="fetchAnaesthesia(item.admissionId).length > 0">
                                <div class="text-dark overflow-auto text-capitalize" *ngFor="let anaesthesia of fetchAnaesthesia(item.admissionId)">
                                    <h4 style="text-align:center;">PRE-ANAESTHESIA ASSESSMENT</h4>
                                    <ng-container>
                                        <div>
                                            <table class="table-container w-100 height-80">
                                                <tr>
                                                    <td class="col-6">
                                                        <h5 class="d-inline-block"> PAC done by Dr. :&nbsp;</h5><span class="text-capitalize" [textContent]="anaesthesia.pacDoneBy"></span>
                                                    </td>
                                                    <td class="col-6">
                                                        <h5 class="d-inline-block "> Consultant Surgeon :&nbsp; </h5><span class="text-capitalize" [textContent]="anaesthesia.consultantDr"></span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="col-6 vertical-align-top">
                                                        <div class="row">
                                                            <div class="col-6">
                                                                <h5 class="d-inline-block"> Name  :&nbsp;</h5><span class="mb-0 mt-0 font-weight-normal text-uppercase" *ngIf="patient && patient.patient.fullName" [textContent]="patient.patient.fullName"></span>
                                                            </div>
                                                            <div class="col-6 p-0">
                                                                <h5 class="d-inline-block"> UMR No  :&nbsp;</h5><span *ngIf="patient && patient.patient.umrNo" [textContent]="patient.patient.umrNo"></span>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-6">
                                                                <h5 class="d-inline-block"> Age & Sex  :&nbsp;</h5>
                                                                <span *ngIf="patient && patient.patient.age" [textContent]="patient.patient.age"></span>/
                                                                <span *ngIf="patient && patient.patient.gender" [textContent]="patient.patient.gender | gender"></span>
                                                            </div>
                                                            <div class="col-6 p-0">
                                                                <h5 class="d-inline-block"> Allergy  :&nbsp;</h5><span *ngIf="anaesthesia && anaesthesia.allergy" [textContent]="anaesthesia.allergy"></span>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <h5 class="d-inline-block"> Wt/Ht/BMI  :&nbsp;</h5>
                                                                <span [textContent]="anaesthesia.weight + '/'"></span>
                                                                <span [textContent]="anaesthesia.height + '/'"></span>
                                                                <span [textContent]="anaesthesia.bmi"></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 p-0 float-right">
                                                            <div>
                                                                <h5 class="d-inline-block"> Blood Group  :&nbsp;</h5><span [textContent]="anaesthesia.bloodGroup"></span><span [textContent]="anaesthesia.rhType"></span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="position-relative">
                                                        <div class="bottom-0 d-flex position-absolute top-0" style="width:-webkit-fill-available;">
                                                            <div class="border-dark border-right col-6 pl-0 d-flex flex-column">
                                                                <div class="d-flex h-50">
                                                                    <div class="d-flex">
                                                                        <h5 class="d-inline-block white-space-nowrap">Diagnosis <span>:&nbsp;</span></h5>
                                                                    </div>
                                                                    <div>
                                                                        <span [textContent]="anaesthesia.diagnosis"> </span>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <h5 class="d-inline-block white-space-nowrap"> Surgery  :&nbsp;</h5><span [textContent]="anaesthesia.surgery"></span>
                                                                </div>
                                                            </div>
                                                            <div class="col-6">
                                                                <div class="d-flex">
                                                                    <div>
                                                                        <h5 class="d-inline-block white-space-nowrap">Problems <span>:&nbsp;</span></h5>

                                                                        <span [textContent]="anaesthesia.problems"> </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="col-6">
                                                        <h5 class="d-inline-block"> CVS :&nbsp;</h5><span [textContent]="anaesthesia.cvs"></span>
                                                    </td>
                                                    <td class="col-6">
                                                        <h5 class="d-inline-block"> RS :&nbsp; </h5><span [textContent]="anaesthesia.rs"></span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="col-6">
                                                        <h5 class="d-inline-block"> Endocrine :&nbsp;</h5><span [textContent]="anaesthesia.endocrine"></span>
                                                    </td>
                                                    <td class="col-6">
                                                        <h5 class="d-inline-block"> Renal :&nbsp; </h5><span [textContent]="anaesthesia.renal"></span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="col-6">
                                                        <h5 class="d-inline-block"> CNS/Musculo Skeletal :&nbsp;</h5><span [textContent]="anaesthesia.cns"></span>
                                                    </td>
                                                    <td class="col-6">
                                                        <h5 class="d-inline-block"> GIT/Hematology :&nbsp; </h5><span [textContent]="anaesthesia.git"></span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="col-6">
                                                        <h5 class="d-inline-block"> Current Medications :&nbsp;</h5><span [textContent]="anaesthesia.currentMed"></span>
                                                    </td>
                                                    <td class="col-6">
                                                        <h5 class="d-inline-block"> Past Anaesthesia Exposure:&nbsp; </h5><span [textContent]="anaesthesia.pastAnaesthesia"></span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="col-6 vertical-align-baseline">
                                                        <div class="d-flex general align-content-stretch flex-wrap h-100">
                                                            <h5 class="w-100">General Examination</h5>
                                                            <div class="d-flex w-100">
                                                                <div class="col-5 pl-0">
                                                                    <h5 class="d-inline-block"> Temp  :&nbsp;</h5><span [textContent]="anaesthesia.temp"></span>
                                                                </div>
                                                                <div class="col-7">
                                                                    <h5 class="d-inline-block"> Built  :&nbsp;</h5><span [textContent]="anaesthesia.built"></span>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex w-100">
                                                                <div class="col-5 pl-0">
                                                                    <h5 class="d-inline-block"> PR  :&nbsp;</h5><span [textContent]="anaesthesia.pr"></span>
                                                                </div>
                                                                <div class="col-7">
                                                                    <h5 class="d-inline-block"> Hydration  :&nbsp;</h5><span [textContent]="anaesthesia.hydration"></span>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex w-100">
                                                                <div class="col-5 pl-0">
                                                                    <h5 class="d-inline-block"> BP  :&nbsp;</h5><span [textContent]="anaesthesia.sysBp"></span>
                                                                </div>
                                                                <div class="col-7">
                                                                    <h5 class="d-inline-block"> Pallor  :&nbsp;</h5><span [textContent]="anaesthesia.pallor"></span>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex w-100">
                                                                <div class="col-5 pl-0">
                                                                    <h5 class="d-inline-block"> RR  :&nbsp;</h5><span [textContent]="anaesthesia.respRate"></span>
                                                                </div>
                                                                <div class="col-7">
                                                                    <h5 class="d-inline-block"> Icterus  :&nbsp;</h5><span [textContent]="anaesthesia.icterus"></span>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex w-100">
                                                                <div class="col-5 pl-0">
                                                                    <h5 class="d-inline-block"> SpO2  :&nbsp;</h5><span [textContent]="anaesthesia.sp"></span>
                                                                </div>
                                                                <div class="col-7">
                                                                    <h5 class="d-inline-block"> Cyanosis  :&nbsp;</h5><span [textContent]="anaesthesia.cyanosis"></span>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex w-100">
                                                                <div class="col-5 pl-0">
                                                                    <h5 class="d-inline-block"> Heart  :&nbsp;</h5><span [textContent]="anaesthesia.heart"></span>
                                                                </div>
                                                                <div class="col-7">
                                                                    <h5 class="d-inline-block"> Oedema  :&nbsp;</h5><span [textContent]="anaesthesia.oedema"></span>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex w-100">
                                                                <div class="col-5 pl-0">
                                                                    <h5 class="d-inline-block"> Lungs  :&nbsp;</h5><span [textContent]="anaesthesia.lungs"></span>
                                                                </div>
                                                                <div class="col-7">
                                                                    <h5 class="d-inline-block"> Peripheral Pulses  :&nbsp;</h5><span [textContent]="anaesthesia.pp"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="col-6 border-right border-dark p-0 availableHeight vertical-align-bottom">
                                                        <h5 class="pl-1 my-2">INVESTIGATIONS :</h5>
                                                        <table class="col-12">
                                                            <tr>
                                                                <td class="pl-1">
                                                                    <h5 class="d-inline-block"> Hb/PCV :&nbsp;</h5><span [textContent]="anaesthesia.hbPcv"></span>
                                                                </td>
                                                                <td class="pl-1">
                                                                    <h5 class="d-inline-block"> WBC :&nbsp;</h5><span [textContent]="anaesthesia.wbc"></span>
                                                                </td>
                                                                <td class="pl-1">
                                                                    <h5 class="d-inline-block"> Plat :&nbsp;</h5><span [textContent]="anaesthesia.plat"></span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-1">
                                                                    <h5 class="d-inline-block"> HIV :&nbsp;</h5><span [textContent]="anaesthesia.hiv"></span>
                                                                </td>
                                                                <td class="pl-1">
                                                                    <h5 class="d-inline-block"> HbSAg :&nbsp;</h5><span [textContent]="anaesthesia.hbsag"></span>
                                                                </td>
                                                                <td class="pl-1">
                                                                    <h5 class="d-inline-block"> HCV :&nbsp;</h5><span [textContent]="anaesthesia.hcv"></span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-1">
                                                                    <h5 class="d-inline-block"> FBS:&nbsp;</h5><span [textContent]="anaesthesia.fbs"></span>
                                                                </td>
                                                                <td class="pl-1">
                                                                    <h5 class="d-inline-block"> PLBS :&nbsp;</h5><span [textContent]="anaesthesia.plbs"></span>
                                                                </td>
                                                                <td class="pl-1">
                                                                    <h5 class="d-inline-block"> RBS :&nbsp;</h5><span [textContent]="anaesthesia.rbs"></span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-1">
                                                                    <h5 class="d-inline-block"> S.Cr :&nbsp;</h5><span [textContent]="anaesthesia.scr"></span>
                                                                </td>
                                                                <td class="pl-1">
                                                                    <h5 class="d-inline-block"> BUN :&nbsp;</h5><span [textContent]="anaesthesia.bun"></span>
                                                                </td>
                                                                <td class="pl-1">
                                                                    <h5 class="d-inline-block"> Bd Urea :&nbsp;</h5><span [textContent]="anaesthesia.bdUrea"></span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-1">
                                                                    <h5 class="d-inline-block"> Na+ :&nbsp;</h5><span [textContent]="anaesthesia.na"></span>
                                                                </td>
                                                                <td class="pl-1">
                                                                    <h5 class="d-inline-block"> K+ :&nbsp;</h5><span [textContent]="anaesthesia.kplus"></span>
                                                                </td>
                                                                <td class="pl-1">
                                                                    <h5 class="d-inline-block"> Cl- :&nbsp;</h5><span [textContent]="anaesthesia.clMinus"></span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-1">
                                                                    <h5 class="d-inline-block"> S Bill :&nbsp;</h5><span [textContent]="anaesthesia.sbill"></span>
                                                                </td>
                                                                <td class="pl-1">
                                                                    <h5 class="d-inline-block"> SGPT :&nbsp;</h5><span [textContent]="anaesthesia.sgpt"></span>
                                                                </td>
                                                                <td class="pl-1">
                                                                    <h5 class="d-inline-block"> LDH :&nbsp;</h5><span [textContent]="anaesthesia.ldh"></span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-1">
                                                                    <h5 class="d-inline-block"> PT :&nbsp;</h5><span [textContent]="anaesthesia.pt"></span>
                                                                </td>
                                                                <td class="pl-1 text-transform-none">
                                                                    <h5 class="d-inline-block"> aPTT :&nbsp;</h5><span [textContent]="anaesthesia.aptt"></span>
                                                                </td>
                                                                <td class="pl-1">
                                                                    <h5 class="d-inline-block"> INR :&nbsp;</h5><span [textContent]="anaesthesia.inr"></span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="col-7 p-0">
                                                        <div class="availableHeight">
                                                            <div class="d-flex airway flex-column justify-content-between h-100">
                                                                <div class="d-flex flex-grow-1">
                                                                    <h5 class="border-dark border-right col-7 m-0  p-0 py-1">AIRWAY ASSESSMENT</h5>
                                                                </div>
                                                                <div class="d-flex flex-grow-1">
                                                                    <div class="col-7 pl-0" style="border-right:1px solid black">
                                                                        <h5 class=" d-inline-block"> Mouth Opening  :&nbsp;</h5><span [textContent]="anaesthesia.mouthOpening"></span>
                                                                    </div>
                                                                    <div class="col-5">
                                                                        <h5 class="d-inline-block white-space-nowrap"> ASA Grading  :&nbsp;</h5><span [textContent]="anaesthesia.asaGrade"></span>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-grow-1">
                                                                    <div class="col-7 pl-0" style="border-right:1px solid black">
                                                                        <h5 class="d-inline-block"> Dentition  :&nbsp;</h5><span [textContent]="anaesthesia.detention"></span>
                                                                    </div>
                                                                    <div class="col-5">
                                                                        <h5 class="d-inline-block white-space-nowrap"> Difficult Intubation  :&nbsp;</h5><span [textContent]="anaesthesia.difficultIntubation"></span>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-grow-1">
                                                                    <div class="col-7 pl-0" style="border-right: 1px solid black">
                                                                        <h5 class="d-inline-block"> TMJ  :&nbsp;</h5><span [textContent]="anaesthesia.tmj"></span>
                                                                    </div>
                                                                    <div class="col-5">
                                                                        <h5 class="d-inline-block white-space-nowrap"> Difficult Regional   :&nbsp;</h5><span [textContent]="anaesthesia.difficultRegional"></span>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-grow-1">
                                                                    <div class="col-7 pl-0" style="border-right: 1px solid black">
                                                                        <h5 class="d-inline-block"> Neck Extension  :&nbsp;</h5><span [textContent]="anaesthesia.neckExtention"></span>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-grow-1">
                                                                    <div class="col-7 pl-0" style="border-right: 1px solid black">
                                                                        <h5 class="d-inline-block"> Mallampati Gr.   :&nbsp;</h5><span [textContent]="anaesthesia.grade"></span>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-grow-1">
                                                                    <div class="col-7 pl-0" style="border-right: 1px solid black">
                                                                        <h5 class="d-inline-block"> Spine Grading  :&nbsp;</h5><span [textContent]="anaesthesia.spineGrade"></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="col-6 position-relative border-right vertical-align-bottom border-dark p-0">
                                                        <div class="d-flex justify-content-between flex-column h-100">
                                                            <div class="w-100 pt-1" style="min-height:70px;">
                                                                <h5 class="d-inline-block pl-1">Other:</h5><span [textContent]="anaesthesia.others"></span>
                                                            </div>
                                                            <div class="border-top border-dark">
                                                                <h5 class="pl-1"> Blood Products Reservation Status :</h5>
                                                                <table class="col-12">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="pl-1"> <h5 class="d-inline-block">Component</h5></th>
                                                                            <th class="pl-1"><h5 class="d-inline-block">PRBC</h5></th>
                                                                            <th class="pl-1"><h5 class="d-inline-block">FFP</h5></th>
                                                                            <th class="pl-1"><h5 class="d-inline-block">Cryo</h5></th>
                                                                            <th class="pl-1"><h5 class="d-inline-block">RDP</h5></th>
                                                                            <th class="pl-1"><h5 class="d-inline-block">SDP</h5></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td class="pl-1"> <h5 class="d-inline-block white-space-nowrap">Check availability</h5></td>
                                                                            <td>
                                                                                <span [textContent]="anaesthesia.prbsAvailability"></span>
                                                                            </td>
                                                                            <td class="pl-1">
                                                                                <span [textContent]="anaesthesia.ffpAvailability"></span>
                                                                            </td>
                                                                            <td class="pl-1">
                                                                                <span></span>
                                                                            </td>
                                                                            <td class="pl-1">
                                                                                <span [textContent]="anaesthesia.rdpAvailability"></span>
                                                                            </td>
                                                                            <td class="pl-1">
                                                                                <span></span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="pl-1"> <h5 class="d-inline-block">  Cross match &   reserve</h5> </td>
                                                                            <td class="pl-1">
                                                                                <span [textContent]="anaesthesia.prbsReserve"></span>
                                                                            </td>
                                                                            <td class="pl-1">
                                                                                <span [textContent]="anaesthesia.ffpReserve"></span>
                                                                            </td>
                                                                            <td class="pl-1">
                                                                                <span></span>
                                                                            </td>
                                                                            <td class="pl-1">
                                                                                <span [textContent]="anaesthesia.rdpReserve"></span>
                                                                            </td>
                                                                            <td class="pl-1">
                                                                                <span></span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="pl-1"> <h5 class="d-inline-block"> Issue </h5> </td>
                                                                            <td class="pl-1">
                                                                                <span [textContent]="anaesthesia.prbsIssue"></span>
                                                                            </td>
                                                                            <td class="pl-1">
                                                                                <span [textContent]="anaesthesia.ffpIssue"></span>
                                                                            </td>
                                                                            <td class="pl-1">
                                                                                <span></span>
                                                                            </td>
                                                                            <td class="pl-1">
                                                                                <span [textContent]="anaesthesia.rdpIssue"></span>
                                                                            </td>
                                                                            <td class="pl-1">
                                                                                <span [textContent]="anaesthesia.issue"></span>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>

                                                                </table>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="col-6">
                                                        <h5 class="d-inline-block">  May be Posted :&nbsp;</h5><span [textContent]="anaesthesia.mayBePosted"></span>
                                                    </td>
                                                    <td class="col-6">
                                                        <h5 class="d-inline-block"> Review PAC :&nbsp; </h5><span [textContent]="anaesthesia.reviewPac"></span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="border-bottom border-dark vertical-align-top height-185px">
                                                        <div>
                                                            <h5 class="d-inline-block white-space-nowrap">Pre Op Instructions :&nbsp;</h5><span *ngFor="let item of anaesthesia.instructions" [textContent]="item"></span>
                                                        </div>
                                                    </td>
                                                    <td class="p-0 vertical-align-top border-bottom border-dark">
                                                        <div class="column-gap10px d-flex h-100">
                                                            <div class="border-dark border-right flex-grow-1 p-1 vertical-align-top">
                                                                <h5 class="d-inline-block white-space-nowrap">Anaesthesia Plan :&nbsp; </h5><span [textContent]="anaesthesia.anaesthesiaPlan"></span>
                                                            </div>
                                                            <div class="flex-grow-1 p-1 vertical-align-top">
                                                                <h5 class="d-inline-block white-space-nowrap">Specific Post Op Instructions :&nbsp;</h5><span [textContent]="anaesthesia.specificPost"></span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="col-12" style="padding:5px;">
                                            <div class="d-flex">
                                                <div><h5 class=" d-inline-block">Date :&nbsp;</h5></div>
                                                <div><span [textContent]="date | date :'dd.MM.yyyy'"></span></div>
                                            </div>
                                            <div class="d-flex">
                                                <div><h5 class=" d-inline-block">Time :&nbsp;</h5></div>
                                                <div><span [textContent]="date | date :' h:mm a'"></span></div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div *ngIf="isAdimissionCount > 0">
                        <table class="w-100">

                            <thead>
                                <tr>
                                    <td>
                                        <!--place holder for the fixed-position header-->
                                        <div class="page-header-space"></div>
                                    </td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>
                                        <!--*** CONTENT GOES HERE ***-->
                                        <div class="d-flex text-center justify-content-center">
                                            <h3>
                                                TREATMENT PLAN FOR POST-PARTUM CARE
                                            </h3>
                                        </div>
                                        <h4 class="d-flex justify-content-end mt-3">
                                            Department of Physiotherapy
                                        </h4>
                                        <ng-container *ngIf="!page.loading && appointment">
                                            <table class="w-100 white-space-nowrap border-top-dotted mt-1 border-bottom-dotted">
                                                <tr class="col-12">
                                                    <td class="text-left">
                                                        <div class="pr-3"> Name :  <h5 class="pl-1  d-inline-block text-uppercase" [textContent]="appointment.patientName"></h5></div>
                                                    </td>
                                                    <td class="text-right">
                                                        <div class="pr-3"> Age : <h5 class="pl-1  d-inline-block" [textContent]="appointment.patientAge + ' years'"> </h5></div>
                                                    </td>
                                                    <td class="w-10px white-space-nowrap">
                                                        <span class=""> UMR No.</span>
                                                    </td>
                                                    <td>
                                                        <h5 class="">:</h5>
                                                    </td>
                                                    <td class="w-200p text-uppercase">
                                                        <h5 class="pl-1 " [textContent]="appointment.umrNo"></h5>
                                                    </td>

                                                </tr>
                                            </table>

                                        </ng-container>
                                        <div class="w-100 mt-2">
                                            <div *ngIf="physiotherapyFinal.PhysioTherapyTemplate && physiotherapyFinal.PhysioTherapyTemplate.templates">
                                                <div class="mb-0">
                                                    <div class="d-flex vertical-align-top">
                                                        <h5 class="col-1 pl-0 white-space-nowrap">Sr.No.</h5>
                                                        <h5 class="col-2 ">Apt. Date</h5>
                                                        <h5 class="col-3">Template Name</h5>
                                                        <h5 class="col-6">Description Summary </h5>
                                                    </div>
                                                    <div class="d-flex vertical-align-top" *ngFor="let item of physiotherapyFinal.PhysioTherapyTemplate.templates; let i = index">
                                                        <div class="col-1 pl-0">
                                                            <span [textContent]="i+1"></span>
                                                        </div>
                                                        <div class="col-2">
                                                            <span [textContent]="item.addDate| date :'dd.MM.yyyy'"></span>
                                                        </div>
                                                        <div class="col-3">
                                                            <span [textContent]="item.templateName"></span>
                                                        </div>
                                                        <div class="col-6 pr-0">
                                                            <span [innerHTML]="item.description"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <h5 class="d-inline">Review:</h5> All these exercises are for 6weeks after which please consult your OBG/Physiotherapist for further progress
                                        </div>

                                    </td>
                                </tr>
                            </tbody>

                            <tfoot>
                                <tr>
                                    <td>
                                        <div class="page-footer-space"></div>

                                    </td>
                                </tr>
                            </tfoot>

                        </table>
                        <div>
                            <div *ngIf="isFooter">
                                <banner-setting [reportName]='"OB All Information Report Footer"'></banner-setting>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>