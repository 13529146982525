<div id="obprintContainer" class="  mt-1 row scroll-obsecNotHeading text-black">
    <div class="col-12">
        <div>
            <div class="col-12 p-0">
                <div>
                    <button class="btn btn-info" type="button" (click)="onChangePrintType(false)">
                        <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangePrintType(true)">
                        <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangeFooter(false)">
                        <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangeFooter(true)">
                        <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
                    </button>
                </div>
                <div class="align-items-start border border-bottom-0 d-flex pl-1 pr-1 pt-1" style="background-color: #edfaf7;">
                    <div class="pl-0 col-11 d-flex">
                        <div class="d-flex flex-wrap">
                            <div>
                                <input type="checkbox" checked (click)="onChangeStatus('a',$event)">
                                <label class="ml-1 mr-2">Patient History</label>
                            </div>
                            <div>
                                <input type="checkbox" checked (click)="onChangeStatus('b',$event)">
                                <label class="ml-1 mr-2">Examination Findings</label>
                            </div>
                            <div>
                                <input type="checkbox" checked (click)="onChangeStatus('c',$event)">
                                <label class="ml-1 mr-2">Investigations Ordered</label>
                            </div>
                            <div>
                                <input type="checkbox" checked (click)="onChangeStatus('d',$event)">
                                <label class="ml-1 mr-2">Medications Adviced</label>
                            </div>
                            <div>
                                <input type="checkbox" checked (click)="onChangeStatus('e',$event)">
                                <label class="ml-1 mr-2">Clinical Notes</label>
                            </div>

                            <div>
                                <input type="checkbox" checked (click)="onChangeStatus('g',$event)">
                                <label class="ml-1 mr-2">Advice</label>
                            </div>
                            <div>
                                <input type="checkbox" checked (click)="onChangeStatus('q',$event)">
                                <label class="ml-1 mr-2">Vaccine</label>
                            </div>
                            <div>
                                <input type="checkbox" checked (click)="onChangeStatus('p',$event)">
                                <label class="ml-1 mr-2">Reffered For Admission</label>
                            </div>
                            <div>
                                <input type="checkbox" checked (click)="onChangeStatus('n',$event)">
                                <label class="ml-1 mr-2">Diagnosis</label>
                            </div>
                            <div>
                                <input type="checkbox" checked (click)="onChangeStatus('o',$event)">
                                <label class="ml-1 mr-2">Follow Up</label>
                            </div>
                            <div>
                                <input type="checkbox" checked (click)="onChangeStatus('m',$event)">
                                <label class="ml-1 mr-2">Other Medication</label>
                            </div>
                            <div>
                                <input type="checkbox" checked (click)="onChangeStatus('j',$event)">
                                <label class="ml-1 mr-2">Referral Doctors</label>
                            </div>
                        </div>
                    </div>

                    <div class="float-right row">
                        <button [useExistingCss]="true" styleSheetFile="./prescription-reports.css" id="printButton" printSectionId="obprint" ngxPrint class="close d-print-none"> <i class="fe-printer" placement="left" ngbTooltip="PrintEncounter"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div id="obprint" class="border-left border-right border-bottom">
            <div class="report bg-white">
                <div class="mb-0 pt-1">
                    <div class="clearfix mb-3">
                        <div>
                            <div class="row" *ngIf="page.loading">
                                <div class="col-12">
                                    <div class="d-flex align-items-center">
                                        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                                        <span class="ml-2">Please wait while generating IEncounter Prescription ...</span>
                                    </div>
                                </div>
                            </div>



                        </div>

                        <div class="col-12 p-0" *ngIf="isPrintLogo">
                            <div>
                                <banner-setting [reportName]='"Pediatric Prescription Reports Header"'></banner-setting>
                            </div>
                        </div>
                        <div class="col-12 p-0" *ngIf="!isPrintLogo">
                            <div style="height: 100px"></div>
                        </div>

                        <ng-container *ngIf="!page.loading && fullTranscript">
                            <div class="overflow-auto">
                                <div class="border-top2px d-flex justify-content-between mt-3 pb-1 pt-1 border-bottom-dotted">
                                    <h4 *ngIf="isTeleconsulatantReport" class="pl-0">Online Consultation</h4>
                                    <h4 *ngIf="!isTeleconsulatantReport" class="pl-0">Prescription</h4>
                                    <h4 class="white-space-nowrap">Department of Pediatrics</h4>
                                </div>
                                <table class="w-100 white-space-nowrap">
                                    <tr class="col-12 vertical-align-top ageTr">
                                        <td class="border-bottom-dotted"><span>Name </span></td>
                                        <td class="border-bottom-dotted">
                                            <h5 class="">:</h5>
                                        </td>
                                        <td colspan="3" class="w-100 border-bottom-dotted">
                                            <div>
                                                <div class="pr-1 d-flex flex-wrap">
                                                    <div class="d-inline-flex align-items-center flex-grow-1">
                                                        <h5 class="d-block  pl-1 text-uppercase min-width17vw max-width_25vw" [textContent]="fullTranscript.patientName"></h5>
                                                    </div>
                                                    <div class="flex-grow-1 age-block text-center ">
                                                        <div class="d-inline-flex align-items-center">
                                                            <span>Age</span>
                                                            <span>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;</span>
                                                            <h5 class="white-space-nowrap" *ngIf="fullTranscript && fullTranscript.patientDateOfBirth">{{this.yearsfordisplay}} year(s), {{this.monthsfordisplay}} month(s), {{this.daysfordisplay}} day(s) &nbsp;&nbsp;</h5>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </td>
                                        <td class="w-130 border-bottom-dotted">
                                            <span class=""> UMR No.</span>
                                        </td>
                                        <td class="border-bottom-dotted">
                                            <h5 class="">:</h5>
                                        </td>
                                        <td class="w-200p text-uppercase border-bottom-dotted">
                                            <h5 class="pl-1 " [textContent]="fullTranscript.umrNo"></h5>
                                        </td>
                                    </tr>
                                    <tr class="col-12 vertical-align-top">
                                        <td>
                                            <span>Visit No</span>
                                        </td>
                                        <td>
                                            <h5 class="">:</h5>
                                        </td>

                                        <td colspan="2">
                                            <h5 class="pl-1" *ngIf="measurements && measurements.todayVisited" [textContent]="measurements.todayVisited"></h5>
                                        </td>
                                        <td></td>
                                        <td>
                                            <span>Date</span>
                                        </td>
                                        <td>
                                            <h5 class="">:</h5>
                                        </td>
                                        <td>
                                            <h5 class="pl-1 text-uppercase" [textContent]="(fullTranscript.appointmentDate | date: 'dd/MM/yyyy')"></h5>
                                        </td>
                                    </tr>
                                    <tr class="vertical-align-top">
                                        <td>
                                            <span> Doctor Name</span>
                                        </td>
                                        <td>
                                            <h5 class="">:</h5>
                                        </td>
                                        <td colspan="2">
                                            <h5 class="pl-1 text-uppercase white-space-break" [textContent]="fullTranscript.providerName"></h5>
                                        </td>
                                        <td></td>
                                        <td>
                                            <span>Location</span>
                                        </td>
                                        <td>
                                            <h5 class="">:</h5>
                                        </td>
                                        <td>
                                            <h5 class="max-width_25vw pl-1 text-truncate text-uppercase" [textContent]="currentLocation"></h5>
                                        </td>
                                    </tr>
                                    <tr class="vertical-align-top">
                                        <td>
                                            <span>Medical Reg No</span>
                                        </td>
                                        <td>
                                            <h5 class="">:</h5>
                                        </td>
                                        <td colspan="2">
                                            <h5 class="pl-1 text-uppercase " [textContent]="fullTranscript.providerNo"></h5>
                                        </td>

                                    </tr>
                                </table>

                            </div>
                        </ng-container>
                        <div *ngIf="b_Block" class="mt-2">
                            <div>
                                <div class="d-flex">
                                    <div class="pl-0 pr-2 col-9" *ngIf="measurements && measurements !== null">
                                        <div class="d-flex" *ngIf="measurements && measurements !== null && ( measurements.weightInKgs|| measurements.lengthInCms)">
                                            <div class="pl-0">
                                                <h5>Examination Findings</h5>
                                            </div>
                                        </div>
                                        <div class="d-flex white-space-nowrap justify-content-between">
                                            <div *ngIf="measurements && measurements.weightInKgs">
                                                <span>Weight (Kg) : <span [textContent]="measurements.weightInKgs"></span></span>
                                            </div>

                                            <div class="col-3" *ngIf="measurements && measurements.lengthInCms">
                                                <span>Height(cms) : <span [textContent]="measurements.lengthInCms"></span></span>
                                            </div>

                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>

                        <div *ngIf="allergies" class="mt-2">
                            <div class="d-flex" *ngIf="allergies && allergies.noHistory && allergies.noHistory!=null">
                                <div>
                                    <div>
                                        Allergies  <span *ngIf="allergies && allergies.noHistory && allergies.noHistory!=null "> &nbsp; : &nbsp; There is no allergy</span>
                                    </div>
                                </div>

                            </div>
                            <table class="w-100 vertical-align-top" *ngIf="allergies && !allergies.noHistory">
                                <tr class="col-12">
                                    <td class="w-150px white-space-nowrap">
                                        <span> Drug Allergies</span>
                                    </td>
                                    <td>
                                        <h5 class="">:</h5>
                                    </td>
                                    <td>
                                        <span class="d-block pl-1" [textContent]="allergies.drugNames"></span>
                                    </td>
                                    <td class="w-150px white-space-nowrap">
                                        <span *ngIf="allergies.drugReactions"> Adverse Drug Reaction</span>
                                    </td>
                                    <td class="w-16">
                                        <span *ngIf="allergies.drugReactions" class="">:</span>
                                    </td>
                                    <td>
                                        <span class="pl-1" [textContent]="allergies.drugReactions"></span>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div *ngIf="c_Block" class="mt-2">
                            <div *ngIf="bookingHeader && bookingHeader.length > 0">
                                <h5>Investigations Ordered</h5>
                                <div class="text-lowercase" *ngFor="let item of bookingHeader;let i =index;">
                                    <span class=" text-wrap" [textContent]="item.testName"></span>
                                    (&nbsp;<span class=" text-wrap" [textContent]="item.departmentName"></span>&nbsp;)
                                </div>
                            </div>
                        </div>


                        <div *ngIf="d_Block" class="mt-2">
                            <div *ngIf="records.length > 0">
                                <div class="dashboard-body">
                                    <div *ngFor="let record of records;index as j;">
                                        <div class="overflow-auto mt-1 mb-1">
                                            <table class="w-100" style="border-collapse: collapse;">
                                                <thead>
                                                    <tr>

                                                        <th><h5 class="pr-2">Medications Adviced</h5> </th>
                                                        <th><h5 class="px-2">Generic Name</h5></th>
                                                        <th><h5 class="px-2">Dosage</h5></th>
                                                        <th><h5 class="px-2">Prefix</h5></th>
                                                        <th><h5 class="px-2">Frequency</h5></th>
                                                        <th><h5 class="px-2">Duration</h5></th>
                                                        <th><h5 class="px-2">Category</h5></th>
                                                        <th colspan="2"><h5 class="px-2">Qty</h5></th>
                                                        <th colspan="2"><h5 class="px-2">Instructions</h5></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                    <tr *ngFor="let item of record.medicines; let i = index">
                                                        <td class="min-width120px">
                                                            <div class="pr-2">
                                                                <div class=" text-wrap text-uppercase" [textContent]="item.productName"></div>
                                                            </div>
                                                        </td>
                                                        <td class="min-width120px">
                                                            <div class="px-2">
                                                                <span class=" text-wrap text-lowercase" *ngIf="item.genericName" [textContent]="item.genericName"></span>
                                                                <span class=" text-wrap text-lowercase" *ngIf="!item.genericName" [textContent]="'--'"></span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="px-2">
                                                                <span class="text-lowercase" *ngIf="item.dosage" [textContent]="item.dosage"></span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="px-2">
                                                                <span class="text-lowercase" *ngIf="item.dosage" [textContent]="item.prefix"></span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="px-2">
                                                                <span class="text-lowercase" *ngIf="item.pediatricFreq" [textContent]="item.pediatricFreq"></span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="px-2">
                                                                <span class="text-lowercase" *ngIf="item.duration" [textContent]="item.duration"></span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="px-2">
                                                                <span class="text-lowercase" *ngIf="item.categoryName" [textContent]="item.categoryName"></span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span class=" text-wrap">
                                                                <span *ngIf="item.fixedQuantity" [textContent]="item.fixedQuantity ||'0'"></span>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <div class="px-2">
                                                                <span class="text-lowercase" *ngIf="item.instruction" [textContent]="item.instruction"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="c_Block" class="mt-2">
                            <div *ngIf=" orderPrescription  && orderPrescription.orderData.length >0 ">
                                <div *ngFor="let item of orderPrescription.orderData">
                                    <div *ngIf="item.orderName==='Investigation Template'">
                                        <div class="w-100" *ngIf="item.comments && item.comments !== null">

                                            <h5 class=" dashboard-title" *ngIf="item.comments && item.comments !== null" [textContent]="item.orderName"></h5>
                                            <table>
                                                <tr class="pl-1" [innerHTML]="item.comments"> </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="e_Block " class="mt-2">
                            <div *ngIf=" orderPrescription  && orderPrescription.orderData.length >0 ">
                                <div *ngIf="orderPrescription!=null">
                                    <div *ngFor="let item of orderPrescription.orderData">
                                        <div *ngIf="item.orderName==='Clinical Notes'">
                                            <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Clinical Notes</h5>
                                                <table>
                                                    <tr class="pl-1" [innerHTML]="item.comments"></tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="g_Block" class="mt-2">
                            <div *ngIf=" orderPrescription  && orderPrescription.orderData.length >0 ">
                                <div *ngIf="orderPrescription.orderData!=null">
                                    <div *ngFor="let item of orderPrescription.orderData">
                                        <div *ngIf="orderPrescription.orderData">
                                            <div *ngIf="item.orderName==='Advice'">
                                                <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                    <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Advice</h5>
                                                    <table>
                                                        <tr class="pl-1" [innerHTML]="item.comments">
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="m_Block " class="mt-2">
                            <div *ngIf=" orderPrescription  && orderPrescription.orderData.length >0 ">
                                <div *ngIf="orderPrescription!=null">
                                    <div *ngFor="let item of orderPrescription.orderData">
                                        <div *ngIf="item.orderName==='Other Medicine'">
                                            <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Other Medicine</h5>
                                                <table>
                                                    <tr class="pl-1" [innerHTML]="item.comments"></tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div *ngIf="q_Block" class="mt-2">

                            <div *ngIf="immunization && immunization.length > 0">
                                <h4>Vaccination</h4>

                                <div class="overflow-auto">
                                    <table class="table table-centered table-sm table-striped mb-0">
                                        <tr>
                                            <th class="white-space-nowrap min-width120px">Vaccine Detail</th>
                                            <th class="white-space-nowrap min-width120px">Drug Name</th>
                                            <th>Orderby</th>
                                            <th>Status</th>
                                            <th>BatchNumber</th>
                                            <th>Expiry</th>
                                            <th>BillNumber</th>
                                            <th>GivenBy</th>
                                        </tr>
                                        <tr *ngFor="let imm of immunization;index as o;">
                                            <td>
                                                <h6 class="mb-0"><span [textContent]="imm.vaccineName"></span></h6>
                                                <span class="mb-0 d-flex text-muted">
                                                    <i class="mdi mdi-clock mr-1"></i> <span [textContent]="imm.allowedDays"></span>&nbsp;<span [textContent]="imm.allowedType"></span>
                                                </span>
                                            </td>
                                            <td>
                                                <h6 class="mb-0"><span [textContent]="imm.productName"></span></h6>
                                            </td>
                                            <td>
                                                <h6 class="mb-0"><span [textContent]="imm.orderBy || '----'"></span></h6>
                                                <span class="d-flex mb-0"><span [textContent]="imm.createdDate | date:'dd-MM-yyyy, hh:mm a' "></span></span>
                                            </td>
                                            <td>
                                                <span>{{imm.status}}</span>

                                            </td>
                                            <td>
                                                <h6 class="mb-0"><span [textContent]="imm.batchNumber || '----'"></span></h6>
                                            </td>
                                            <td>
                                                <h6 *ngIf="imm.expiryDate" class="mb-0"><span [textContent]="imm.expiryDate | date:'MM-yyyy'"></span></h6>
                                                <span *ngIf="!imm.expiryDate">----</span>
                                            </td>
                                            <td>
                                                <h6 class="mb-0"><span [textContent]="imm.billNumber || '----'"></span></h6>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="imm.vaccineGivenByName">
                                                    <h6 class="mb-0"><span [textContent]="imm.vaccineGivenByName || '----'"></span></h6>
                                                    <span class="d-flex mb-0"><span [textContent]="imm.vaccineGivenDate | date:'dd-MM-yyyy, hh:mm a' "></span></span>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="p_Block " class="mt-2">
                            <div *ngIf=" orderPrescription  && orderPrescription.orderData.length >0 ">
                                <div *ngIf="orderPrescription!=null">
                                    <div *ngFor="let item of orderPrescription.orderData">
                                        <div *ngIf="item.orderName==='Referred for Admission'">
                                            <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Referred for Admission</h5>
                                                <table>
                                                    <tr class="pl-1" [innerHTML]="item.comments"></tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="j_Block" class="mt-2">
                            <div *ngIf="refferalOrder && refferalOrder.length > 0">
                                <h5>Referrals</h5>
                                <table class="col-12 mt-1 mb-1" style="border-collapse: collapse;">
                                    <thead>
                                        <tr>
                                            <td class="w-200p vertical-align-top"><h5>Doctors</h5> </td>
                                            <td class="w-200p vertical-align-top"><h5>Comments</h5> </td>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr *ngFor="let item of refferalOrder; let i = index">
                                            <td>
                                                <div class=" text-wrap" [textContent]="item.name"></div>
                                            </td>
                                            <td>
                                                <div class=" text-wrap" [textContent]="item.comments"></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div *ngIf="n_Block" class="mt-2">
                            <div *ngIf="diagnosisRecords && diagnosisRecords.length >0">
                                <h5>
                                    Diagnosis
                                </h5>

                                <table class="col-12 mb-1 ml-2 mt-1" style="border-collapse: collapse;">
                                    <thead>
                                        <tr>
                                            <th class="w-200p vertical-align-top"><h5>Name</h5></th>
                                            <th class="w-200p vertical-align-top"><h5>ICD Code</h5></th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr *ngFor="let item of diagnosisRecords; let i = index">
                                            <td>
                                                <span class="text-wrap" [textContent]="item.usable"></span>
                                            </td>
                                            <td>
                                                <span class="text-wrap" [textContent]="item.code"></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div *ngIf="o_Block" class="mt-2">
                            <div *ngIf="visitOrders && visitOrders.length >0">
                                <h5>Follow Up</h5>
                                <table class="col-12 mb-1 ml-2 mt-1" style="border-collapse: collapse;">
                                    <thead>
                                        <tr>
                                            <th class="w-200p vertical-align-top"><h5>Visit Date</h5></th>
                                            <th class="w-200p vertical-align-top"><h5>Special Advice</h5></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of visitOrders; let i = index">
                                            <td>
                                                <span class="text-wrap" [textContent]="item.visitDate| date:'dd/MM/yyyy'"></span>
                                            </td>
                                            <td>
                                                <span class="text-wrap" [textContent]="item.specialAdvice"></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div *ngIf="futureAppointments && futureAppointments.length>0">
                            <div>
                                <h5 class="dashboard-title">Next Visit Date</h5>
                                <div *ngFor="let item of futureAppointments; index as i ">
                                    <span>Next visit with<span class="text-uppercase"> {{item.providerName}}</span> at {{item.locationName}} on {{item.appointmentDate | date :'dd/MM/yyyy'}} at {{item.appointmentTimeString}}</span>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="isTeleconsulatantReport">
                            <h5 class="dashboard-title ">Disclaimer</h5>
                            <div class="mt-2">

                                Limitations of TELE-CONSULT exist and it can never be equated to a clinical consultation. The doctor’s advice is only based on the
                                accuracy of verbal details provided by you as the mandatory physical examination is not possible. This consultation is only to
                                help you access health care and is not permitted for any medico-legal purposes. We disclaim any liability or responsibility, of
                                harm or injury happening due to misinformation, misrepresentation, misinterpretation and impersonation. If you are
                                experiencing an urgent medical problem, please visit the nearest emergency care facility. Your request / acceptance of tele consult will be taken as your consent with its ingrained limitations and your session will be recorded. Your information and
                                privacy will be guarded.
                            </div>
                        </ng-container>
                        <div class="mt-2 align-items-end col-12 d-flex flex-wrap justify-content-between p-0 pt-1 column-gap15px">
                            <div class="d-flex flex-grow-1">
                                <div><span>Email :&nbsp;</span></div>
                                <div class="text-truncate"><h5 *ngIf="fullTranscript && fullTranscript.email" class="text-lowercase" [textContent]="fullTranscript.email"></h5></div>
                            </div>
                            <div class="d-flex flex-wrap column-gap15px align-items-end justify-content-end flex-grow-1">
                                <div class="d-flex">
                                    <div><span>Mob :&nbsp;</span></div>
                                    <div><h5 *ngIf="location && location.optionalText">{{location.optionalText}}</h5></div>
                                </div>
                                <div class="d-flex flex-grow-1 justify-content-center">
                                    <div><span>User Name :&nbsp;</span></div>
                                    <div><h5 *ngIf="fullTranscript && fullTranscript.providerName" class="text-capitalize" [textContent]="fullTranscript.providerName"></h5></div>
                                </div>
                                <div class="d-flex align-items-end">
                                    <div><span>Signature :&nbsp;</span></div>
                                    <div><h5 class="border-bottom border-dark min-width100px"><img *ngIf="appointment && appointment.signature!=null" [src]="safe(appointment.signature)" cssClass="img-fluid rounded-circle" style="height:80px" /></h5></div>
                                </div>
                            </div>

                        </div>
                        <div class="mt-2 pr-0">
                            <h5>
                                EMERGENCY CONTACT : FH - {{currentLocation}} Labour Ward Contact No:
                                <span *ngFor="let contact of emergencyContacts;let i=index;">
                                    <span [textContent]="contact.value"></span>
                                    <span *ngIf="i != emergencyContacts.length - 1">, &nbsp;</span>
                                </span>
                            </h5>

                        </div>
                        <div class="mt-2 d-flex justify-content-between border-dark border-top" style="font-weight:700;">
                            <div><span>Printed by :</span> <span *ngIf="page.userAccount.fullName" [textContent]="page.userAccount.fullName"></span></div>
                            <div><span>Print Date/Time :</span> <span [textContent]="date | date :'d/MM/yyyy'"></span>/<span [textContent]="date | date :' h:mm a'"></span></div>
                        </div>
                        <div *ngIf="isFooter">
                            <banner-setting [reportName]='"Pediatric Prescription Reports Footer"'></banner-setting>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>





