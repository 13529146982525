import { Component, ViewEncapsulation, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { GenericResponse, Page, IResource } from "@shared/models";
import { HttpService, BannerService, PrintOptionService, ResourceService } from "@shared/services";
import { ApiResources } from "@shared/helpers";
import { takeUntil, finalize } from "rxjs/operators";
import { IReceiptModel, IReceiptSubModel, IReceiptCostModel } from "../../../areas/admin/services/models/receipt.model";
import { AdmissionFetchHelper } from "../../../areas/admin/progress-report/shared/helper";
import { IAdmissionModel } from "../../../areas/admin/services/models/admission.model";
import { ReceiptType } from "../../enums";
import { ImageReports } from "@shared/entities";
import * as converter from "number-to-words";

@Component({
    templateUrl: "./receipt-view.html",
    selector: "receipt-view-widget",
    encapsulation: ViewEncapsulation.None
})
export class ReceiptViewWidget implements OnInit {

    @Output() emitOnCloseModel = new EventEmitter();
    @Input() receiptId: number;
    @Input() admissionId: number;
    @Input() isAdmission: boolean;
    @Input() waterMarkText: string;
    loading: boolean;
    record: IReceiptModel;
    admission: IAdmissionModel;
    receiptType = ReceiptType;

    @Input() isPrintLogo: boolean;
    @Input() isFooter: boolean;
    bannerBasics: ImageReports;
    myImgUrl = "assets/images/careaxesLogo.png";
    printedDate: Date;
    practiceLocations: Array<IResource>;
    amtInWords: string;
    page: Page;
    constructor(
        private readonly httpService: HttpService,
        private readonly admissionFetchHelper: AdmissionFetchHelper,
        private readonly bannerService: BannerService,
        private readonly printOptionService: PrintOptionService,
        private readonly resourceService: ResourceService,

    ) {
        this.printedDate = new Date();
        this.page = new Page();
        this.practiceLocations = new Array<IResource>();
    }

    onCloseModal = () => {
        this.emitOnCloseModel.emit();
    }

    admissionFetch = (callback?: Function) => {
        this.admissionFetchHelper.admissionFetch(+this.admissionId, this.isAdmission, (data: IAdmissionModel) => {
            this.admission = data;
            callback();
        });
    }
    private fetchPracticeLocation() {
        this.resourceService.fetchPracticeLocations()
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<IResource>) => {
                this.practiceLocations = response;
            });
    }
    fetch = () => {
        
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.receipt.base, ApiResources.receipt.fetch),
                { id: this.admissionId.toString(), isAdmission: this.isAdmission, receiptId: this.receiptId })
            .subscribe(
                (response: GenericResponse) => {

                    this.loading = false;
                    const item = response.data.records[0] as IReceiptModel;
                    item.receiptNo = String(item?.receiptId).padStart(6, '0');
                    item.subRecords = response.data.subRecords as Array<IReceiptSubModel>;
                    item.costModel = response.data.costViewModel as IReceiptCostModel;
                    this.record = item;
                    this.amtInWords = converter.toWords(this.record.cost);
                }
        );

    }
    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }
    ngOnInit() {
        this.loading = true;

        this.admissionFetch(() => {
            this.fetch();
            this.fetchPracticeLocation();
        });
        this.printOptionService.get((is) => { this.isPrintLogo = is; });

        this.bannerService.getBannerImage((is) => { this.bannerBasics = is; });
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
}