import { Injectable } from "@angular/core";
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AppData, appUrls, IdentityService, MenuService } from "../services";
import { AllowType } from "../enums";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private readonly identityService: IdentityService,
        private readonly menuService: MenuService,
        private readonly appData: AppData,
        private readonly router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean> | Promise<boolean> | boolean {
        return this.activateHelper(route, state);
    }

    activateHelper = (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        if (!this.identityService.isAuthenticated()) {
            this.identityService.logout();
            return false;
        }

        const allowType = this.menuService.isAllowed(state.url, true);
        if (typeof allowType === 'string') {
            this.router.navigateByUrl(allowType);
            return false;
        } else {
            switch (allowType as AllowType) {
                case AllowType.Allowed:
                    return true;
                case AllowType.NotAllowed:
                    return false;
                case AllowType.BreakIt:
                    this.identityService.logout();
                    return false;
                default:
                    return false;
            }
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean> | Promise<boolean> | boolean {
        return this.activateHelper(route, state);
    }
}