import { Component, OnDestroy, OnInit, ViewEncapsulation, TemplateRef } from "@angular/core";
import { ApiResources, UtilHelper } from "@shared/helpers";
import { AppData, HttpService  } from "@shared/services";
import { Page, IUserAccount, ObEncounter, Pagination, IResource, } from "@shared/models";
import { OB } from "../../../full-transcript/pages/ob-report/ob.namespace";
import { finalize, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { Patient } from "../../../../../shared/entities";
import moment from "moment";

class FilterOptions {
    admissionDate: Date;
    patientName: string;
    encounterType: string;
    patientId?: number = null;
}
class Filters {
    options: FilterOptions;
    applied: boolean;
    constructor() {
        this.init();
    }

    init() {
        this.options = new FilterOptions();
        this.applied = undefined;
    }
}

@Component({
    templateUrl: "./scheduler-admission.html",
})
export class ScheduledAdmissionPage implements OnInit, OnDestroy {
    page: Page;
    loading: boolean;
    scheduledAdmissionBackup: Array<OB.AdmssionSlip>;
    scheduledAdmissionBackup2: Array<OB.AdmssionSlip>;
    scheduledAdmission: Array<OB.AdmssionSlip>;
    records: Array<ObEncounter>;
    parsedAdmissionSlip: OB.AdmssionSlip;
    pagination: Pagination;
    filters: Filters;
    isPatient: boolean;
    isEncounter: boolean;
    isAdmissionDate: boolean;
    filterPatient: Array<IResource>;
    resultsType = "today";
    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly router: Router
    ) {
        this.loading = true;
        this.page = new Page();
        this.scheduledAdmission = new Array<OB.AdmssionSlip>;
        this.records = new Array<ObEncounter>;
        this.initPagination()
        this.filters = new Filters();
        this.scheduledAdmissionBackup = new Array<OB.AdmssionSlip>;
        this.scheduledAdmissionBackup2 = new Array<OB.AdmssionSlip>;
    }

    fetchScheduledAdmission() {
        this.loading = true;
        const request = {};//UtilHelper.clone(this.pagination)
        
        this.httpService
            .get(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchScheduledAdmission), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<ObEncounter>) => {
                    if (response.length > 0) {
                        this.records = response;
                        response.forEach((item: ObEncounter) => {
                            this.parsedAdmissionSlip = JSON.parse(item.admissionSlip);
                            this.parsedAdmissionSlip.appointmentId = item.appointmentId;
                            this.parsedAdmissionSlip.fullName = item.fullName;                            
                            this.parsedAdmissionSlip.appointmentNo = item.appointmentNo;
                            this.parsedAdmissionSlip.encounterName = item.encounterType;
                            this.parsedAdmissionSlip.totalItems = item.totalItems;
                            this.parsedAdmissionSlip.isAdmitted = item.isAdmitted;
                            const time = moment(this.parsedAdmissionSlip.time, 'HH:mm');
                            if (time.isValid()) {
                                this.parsedAdmissionSlip.formattedTime = time.format('hh:mm A');
                            } else {
                                this.parsedAdmissionSlip.formattedTime = '';
                            }
                            this.scheduledAdmission.push(this.parsedAdmissionSlip);
                            this.scheduledAdmissionBackup.push(this.parsedAdmissionSlip);
                        });
                    }
                    else {
                        this.scheduledAdmission = [];
                    }
                    this.scheduledAdmissionBackup2 = this.scheduledAdmissionBackup;
                    UtilHelper.applyPagination(this.scheduledAdmission, this.pagination);
                    
                    this.onNextPage()
                },
            );
    }

    fetchPatientNameMobileUmrHelper($event?: any) {
        if ($event.term.length < 1)
            this.onApplyFilters();

        if ($event && $event.term && $event.term.length > 2) {
            var temp = [];
            for (let row of this.scheduledAdmission) {
                var stk = `^${$event.term}`;
                const regexp = new RegExp(stk.toLowerCase());
                if (regexp.test(row.name.toLowerCase())) { temp.push(row); }
            }
            this.scheduledAdmission = temp;
            let locationId = this.page.userAccount.locationId;
            this.fetchPatientNameAppointmentNo($event.term, locationId);

        } else {
            this.filterPatient = new Array<IResource>();
        }
    }

    private fetchPatientNameAppointmentNo(patientId?: string, locationId?: number) {

        this.loading = true;
        var request = {
            filter: patientId,
            locationId: locationId,
        }
        this.httpService
            .get<Array<IResource>>(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.outPatients), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<IResource>) => {
                    this.filterPatient = response;
                },
                () => {
                    this.filterPatient = new Array<IResource>();
                }
            );
    }


    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchScheduledAdmission();
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }

    onClickConvertToInPtaient(records: OB.AdmssionSlip) {
        var encryptedId = window.btoa(records.appointmentId.toString());
        var name = "fromScheduledAdmission";
        this.router.navigateByUrl(`app/admissions/view/${records.encryptedPatientId}/${name}/${encryptedId}`);
    }
    private initPagination() {
        this.pagination = new Pagination();
        this.pagination.pageSize =10;
        this.pagination.pageIndex = 1;
    }
    onNextPage() {
        $("body,html").animate({ scrollTop: 0 });
        this.scheduledAdmissionBackup = this.DescendingBasedOnAdmissionDate(this.scheduledAdmissionBackup);
        this.scheduledAdmission = [];
        this.pagination.currentPage = this.pagination.pageIndex;
        for (let i = 0; i < this.scheduledAdmissionBackup.length; i++)
        {
            var N = this.pagination.pageIndex * this.pagination.pageSize - 10;
            if (i >= N && i < (N + 10)) {
                this.scheduledAdmission.push(this.scheduledAdmissionBackup[i])
                this.pagination.currentItems = i + 1;
            }
        }
    }
    onApplyFilters() {
        this.filters.applied = UtilHelper.isFiltersApplied(this.filters.options);
        this.onFilters();
    }
    onResetFilters() {
        this.scheduledAdmission = [];
        this.scheduledAdmissionBackup = [];
        this.initPagination();
        this.fetchScheduledAdmission();
        this.filters.init();
    }
    onFilters() {
        this.scheduledAdmission = [];
        this.scheduledAdmissionBackup = [];
        this.scheduledAdmissionBackup2.forEach((m) => {
            this.isPatient = this.filters.options.patientId ? (m.patientId ===this.filters.options.patientId) : true;
            this.isEncounter = this.filters.options.encounterType ? (m.encounterName.includes(this.filters.options.encounterType)) : true;
            this.isAdmissionDate = this.filters.options.admissionDate ? (m.admissionDate === this.filters.options.admissionDate) : true;
            if (this.isPatient && this.isEncounter && this.isAdmissionDate) {
                this.scheduledAdmissionBackup.push(m);
            }
        })
        this.scheduledAdmission = this.scheduledAdmissionBackup;
        if (this.scheduledAdmission.length > 0) {
            this.scheduledAdmission[0].totalItems = this.scheduledAdmissionBackup.length;
        }
        this.resultsType = "all";
        this.initPagination();
        UtilHelper.applyPagination(this.scheduledAdmission, this.pagination);
        this.onNextPage();
    }
    DescendingBasedOnAdmissionDate(data: Array<OB.AdmssionSlip>): Array<OB.AdmssionSlip> {
        data = data.sort((firstItem, secondItem) => Number(new Date(secondItem.admissionDate)) - Number(new Date(firstItem.admissionDate)));
        return data;
    }
}