<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">
                        <!--<button *ngIf="!loading && medicines.length" [useExistingCss]="true" printSectionId="progressReportReport" ngxPrint class="btn btn-primary btn-sm mr-1 d-print-none"><i class="fe-printer mr-1"></i>Print</button>-->
                        <button *ngIf="!loading && medicines.length" type="button" class="btn btn-primary btn-sm mr-1" (click)="onOpenModel(templateProgressReport)">
                            <span><i class="fe-eye mr-1"></i> View Report</span>
                        </button>
                        <!--<a href="javascript:;" class="btn btn-secondary btn-sm ml-1" (click)="onBack()">
                            <span><i class="fe-arrow-left mr-1"></i> Back</span>
                        </a>-->
                    </div>
                    <h4 class="page-title">Medication</h4>
                </div>
            </div>
        </div>

        <div class="row mb-4" *ngIf="indentMedicines.length">
            <div class="col-lg-12">
                <div class="overflow-auto">
                    <table class="table table-centered table-sm table-striped table-bordered mb-0">
                        <thead class="table-active">
                            <tr>
                                <th colspan="3">
                                    <h5 class="m-0">Uninstructed Medicines</h5>
                                    <h6 class="mb-0 mt-1">Medicines are from Pharmacy Indent</h6>
                                </th>
                            </tr>
                        </thead>
                        <thead class="table-primary">
                            <tr>
                                <th class="w-55p">#</th>
                                <th style="width:35%;">Name</th>
                                <th class="text-right">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of indentMedicines; let i = index;">
                                <td [textContent]="(i + 1)"></td>
                                <td>
                                    <div>
                                        <div class="media">
                                            <div>
                                                <ng-container [ngSwitch]="(item.productTypeName | lowercase)">
                                                    <span *ngSwitchCase="'injection'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-injection.png'" width="28" /></span>
                                                    <span *ngSwitchCase="'tablet'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-tablet.png'" width="28" /></span>
                                                    <span *ngSwitchCase="'tablets'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-tablet.png'" width="28" /></span>
                                                    <span *ngSwitchCase="'gel'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-hair-gel.png'" width="28" /></span>
                                                    <span *ngSwitchCase="'syrup'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-syrup.png'" width="28" /></span>
                                                    <span *ngSwitchCase="'ointment'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-ointment.png'" width="28" /></span>
                                                    <span *ngSwitchCase="'respules'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-respules.png'" width="28" /></span>
                                                    <span *ngSwitchCase="'syringe'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-syringe.png'" width="28" /></span>
                                                    <span *ngSwitchCase="'surgical'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-scissors.png'" width="28" /></span>
                                                    <span *ngSwitchCase="'capsules'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-capsule.png'" width="28" /></span>
                                                    <span *ngSwitchCase="'sachets'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-sachet.png'" width="28" /></span>
                                                    <span *ngSwitchCase="'drops'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-drop.png'" width="28" /></span>
                                                    <span *ngSwitchCase="'sprays'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-spray.png'" width="28" /></span>
                                                    <span *ngSwitchCase="'suppository'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-suppository.png'" width="28" /></span>
                                                    <span *ngSwitchCase="'gloves'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-gloves.png'" width="28" /></span>
                                                    <span *ngSwitchCase="'lotion'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-lotion.png'" width="28" /></span>
                                                    <span *ngSwitchCase="'powder'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-powder.png'" width="28" /></span>
                                                    <span *ngSwitchCase="'others'"><i class="mdi mdi-medical-bag font-28"></i></span>
                                                    <span *ngSwitchDefault><i class="mdi mdi-medical-bag font-28"></i></span>
                                                </ng-container>
                                            </div>
                                            <div class="media-body ml-1">
                                                <h5 [ngClass]="{'text-line-through' : item.activeStatus === activeStatus.Stopped}" class="mb-0 mt-0 font-weight-normal" [textContent]="item.productName || '---'"></h5>
                                                <span class="d-block font-12 text-capitalize">
                                                    <small [textContent]="item.productTypeName || '---'"></small>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-right">
                                    <div class="d-flex align-items-center justify-content-end">
                                        <span class="badge badge-outline-warning mr-2">Action Required</span>
                                        <button (click)="addInstructions(item, templateAddMedication)" class="btn btn-primary btn-rounded">Add Instructions</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <form [formGroup]="indentForm">
            <div class="row">
                <div class="col-sm-12 mb-4">
                    <div class="overflow-auto">
                        <table class="table table-centered table-sm table-striped table-bordered mb-0">
                            <thead class="table-active">
                                <tr>
                                    <th colspan="8">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div>
                                                <ng-container *ngIf="currentDisplayMode === displayMode.Concept">
                                                    <span (click)="onPrevious()" class="mr-2"><i [ngClass]="{'text-muted' : !dates.showMin, 'cursor-pointer text-primary' : dates.showMin}" class="mdi mdi-arrow-left-bold mdi-18px"></i></span>
                                                    <span [textContent]="dates.current | date: 'dd MMMM, y'"></span>
                                                    <span (click)="onNext()" class="ml-2"><i [ngClass]="{'text-muted' : !dates.showMax, 'cursor-pointer text-primary' : dates.showMax}" class="mdi mdi-arrow-right-bold mdi-18px"></i></span>
                                                </ng-container>
                                            </div>
                                            <div>
                                                <div class="d-flex align-content-center" *ngIf="instructionTypeNames.length > 0">
                                                    <div><i class="mdi mdi-information mr-2" placement="left" ngbTooltip="Take all medicines: You can update status of all the medicines to 'Taken' in a single click"></i></div>
                                                    <!--<div><a (click)="onOpenMultipleTakeModel(templateTakeMedication)" href="javascript:;">Take all medicines</a></div>-->
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <thead class="table-primary">
                                <tr>
                                    <th style="width:50px;">#</th>
                                    <th style="width:35%;">Name</th>
                                    <ng-container *ngIf="currentDisplayMode === displayMode.Concept">
                                        <th>Created By</th>
                                        <th>Unit</th>
                                        <th>Duration</th>
                                        <th>Usage</th>
                                        <th>Status</th>
                                        <th>Instructions</th>
                                        <th>Route</th>
                                        <th>Dosage</th>
                                        <th class="noprint-column">Actions</th>
                                    </ng-container>
                                    <ng-container *ngIf="currentDisplayMode !== displayMode.Concept">
                                        <th class="noprint-column text-right">Enter Quantity</th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="loading">
                                    <tr>
                                        <td colspan="8">
                                            <span class="mr-1 spinner-grow spinner-grow-sm"></span> Please wait while loading progress report ...
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-container *ngIf="!loading && !medicines.length">
                                    <tr>
                                        <td colspan="8">
                                            <i class="mdi mdi-alert text-warning mr-1"></i> Medications does not exists.
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-container *ngIf="!loading && medicines.length">
                                    <ng-container formArrayName="records" *ngFor="let item of medicines; let i = index;">
                                        <tr [ngClass]="{'table-danger' : item.activeStatus === activeStatus.Stopped}">
                                            <td [textContent]="(i + 1)"></td>
                                            <td>
                                                <div>
                                                    <div class="media">
                                                        <div>
                                                            <ng-container [ngSwitch]="(item.productTypeName | lowercase)">
                                                                <span *ngSwitchCase="'injection'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-injection.png'" width="28" /></span>
                                                                <span *ngSwitchCase="'tablet'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-tablet.png'" width="28" /></span>
                                                                <span *ngSwitchCase="'gel'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-hair-gel.png'" width="28" /></span>
                                                                <span *ngSwitchCase="'syrup'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-syrup.png'" width="28" /></span>
                                                                <span *ngSwitchCase="'ointment'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-ointment.png'" width="28" /></span>
                                                                <span *ngSwitchCase="'respules'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-respules.png'" width="28" /></span>
                                                                <span *ngSwitchCase="'syringe'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-syringe.png'" width="28" /></span>
                                                                <span *ngSwitchCase="'surgical'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-scissors.png'" width="28" /></span>
                                                                <span *ngSwitchCase="'capsules'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-capsule.png'" width="28" /></span>
                                                                <span *ngSwitchCase="'sachets'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-sachet.png'" width="28" /></span>
                                                                <span *ngSwitchCase="'drops'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-drop.png'" width="28" /></span>
                                                                <span *ngSwitchCase="'sprays'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-spray.png'" width="28" /></span>
                                                                <span *ngSwitchCase="'suppository'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-suppository.png'" width="28" /></span>
                                                                <span *ngSwitchCase="'gloves'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-gloves.png'" width="28" /></span>
                                                                <span *ngSwitchCase="'lotion'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-lotion.png'" width="28" /></span>
                                                                <span *ngSwitchCase="'powder'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-powder.png'" width="28" /></span>
                                                                <span *ngSwitchCase="'others'"><i class="mdi mdi-medical-bag font-28"></i></span>
                                                                <span *ngSwitchDefault><i class="mdi mdi-medical-bag font-28"></i></span>
                                                            </ng-container>
                                                        </div>
                                                        <div class="media-body ml-1">
                                                            <h5 [ngClass]="{'text-line-through' : item.activeStatus === activeStatus.Stopped, 'text-muted' : currentDisplayMode !== displayMode.Concept && indentForm.value.records.length > 0 && indentForm.value.records[i].value <= 0}" class="mb-0 mt-0 font-weight-normal">
                                                                <span [textContent]="item.productName || '---'"></span>
                                                                <span *ngIf="currentDisplayMode !== displayMode.Concept && indentForm.value.records.length > 0 && indentForm.value.records[i].value > 0" class="ml-2"><i class="mdi mdi-checkbox-marked-circle text-success"></i></span>
                                                            </h5>
                                                            <span class="d-block font-12 text-capitalize" [ngClass]="{'text-muted' : currentDisplayMode !== displayMode.Concept && indentForm.value.records.length > 0 && indentForm.value.records[i].value <= 0}">
                                                                <small [textContent]="item.productTypeName || '---'"></small>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <ng-container *ngIf="currentDisplayMode !== displayMode.Concept && item.activeStatus != activeStatus.Stopped ">
                                                <td>
                                                    <ng-container [formGroupName]="i"  *ngIf="!item.pharmacyIndentDetailId">
                                                        <div class="d-flex align-items-center justify-content-end">
                                                            <div class="form-group mb-0">
                                                                <input type="number" numbersOnly min="0" formControlName="value" class="form-control" placeholder="Enter Quantity" />
                                                            </div>
                                                        </div>
                                                        <div *ngIf="item.pharmacyIndentDetailId">
                                                            <span>Indent has been raised</span>
                                                        </div>
                                                    </ng-container>
                                                </td>
                                            </ng-container>
                                            <ng-container *ngIf="currentDisplayMode === displayMode.Concept">
                                                <td>
                                                    <div>
                                                        <div class="media">
                                                            <div class="media-body">
                                                                <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.createdByName"></h5>
                                                                <span class="d-block font-12 mt-1">
                                                                    <small [textContent]="item.createdDate | date:'dd-MM-yyyy, hh:mm a'"></small>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td [ngClass]="{'text-line-through' : item.activeStatus === activeStatus.Stopped}" [textContent]="item.unit"></td>
                                                <td [ngClass]="{'text-line-through' : item.activeStatus === activeStatus.Stopped}">
                                                    <div>
                                                        <span [textContent]="item.duration" class="mr-1"></span><ng-container [ngSwitch]="item.medicationDurationTypeId">
                                                            <span *ngSwitchCase="1">Day<span *ngIf="item.duration > 1">s</span></span>
                                                            <span *ngSwitchCase="2">Week<span *ngIf="item.duration > 1">s</span></span>
                                                            <span *ngSwitchCase="3">Month<span *ngIf="item.duration > 1">s</span></span>
                                                        </ng-container>
                                                    </div>
                                                    <hr class="m-0" />
                                                    <div *ngIf="item.leftDays && item.activeStatus === activeStatus.Active">
                                                        <ng-container *ngIf="currentRunningMode === runningMode.Past">
                                                            <ng-container *ngIf="!(item.leftDays - 1)">
                                                                <small class="text-primary"><span [textContent]="item.duration"></span> Day<span *ngIf="item.duration > 1">s</span> Completed</small>
                                                            </ng-container>
                                                            <ng-container *ngIf="(item.leftDays - 1)">
                                                                <small class="text-danger"><span [textContent]="(item.leftDays - 1)"></span> More Day<span *ngIf="(item.leftDays - 1) > 1">s</span> Left</small>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container *ngIf="currentRunningMode === runningMode.Current">
                                                            <small class="text-danger"><span [textContent]="item.leftDays"></span> Day<span *ngIf="item.leftDays > 1">s</span> Left</small>
                                                        </ng-container>
                                                    </div>
                                                    <div *ngIf="item.progressReportMedicationId && !item.leftDays && item.activeStatus === activeStatus.Active">
                                                        <small class="text-primary">
                                                            <span [textContent]="item.duration" class="mr-1"></span><ng-container [ngSwitch]="item.medicationDurationTypeId">
                                                                <span *ngSwitchCase="1">Day<span *ngIf="item.duration > 1">s</span></span>
                                                                <span *ngSwitchCase="2">Week<span *ngIf="item.duration > 1">s</span></span>
                                                                <span *ngSwitchCase="3">Month<span *ngIf="item.duration > 1">s</span></span>
                                                            </ng-container>&nbsp;<span>completed</span>
                                                        </small>
                                                    </div>
                                                </td>
                                                <td [ngClass]="{'text-line-through' : item.activeStatus === activeStatus.Stopped}">
                                                    <div class="d-flex align-content-center">
                                                        <ng-container *ngFor="let frequency of item.medicationFrequency">
                                                            <div class="l-chip pr-1 mr-1">
                                                                <div class="d-block font-12 text-capitalize" *ngIf="item.activeStatus === activeStatus.Active">
                                                                    <span *ngIf="frequency.status === null && item.progressReportMedicationId" class="font-weight-bold text-danger white-space-nowrap"><i class="mdi mdi-flash blink"></i>Pending</span>
                                                                    <span *ngIf="frequency.status === false && item.progressReportMedicationId" class="font-weight-bold text-danger white-space-nowrap"><i class="mdi mdi-close"></i>Missed</span>
                                                                    <span *ngIf="frequency.status" class="font-weight-bold text-success white-space-nowrap"><i class="mdi mdi-check"></i>Taken By {{frequency.takenByName}}</span>
                                                                    <span *ngIf="frequency.isHighRisk" class="font-weight-bold text-success white-space-nowrap"><i class="mdi mdi-check"></i>IsHighRisk {{frequency.isHighRisk}}</span>
                                                                </div>
                                                                <div (click)="takeQuickMedicine(item,frequency.medicationInstructionDeepTypeId, frequency.progressReportMedicationFrequencyId,templatehighriskview)" class="cursor" [ngbTooltip]="frequency.medicationInstructionName" placement="bottom">
                                                                    <ng-container *ngIf="frequency.medicationInstructionDeepTypeId === 1">
                                                                        <span class="badge badge-success">
                                                                            <i *ngIf="!item.isMorningTaking" class="mdi mdi-weather-cloudy mr-1"></i>
                                                                            <span *ngIf="item.isMorningTaking" class="spinner-grow spinner-grow-sm"></span>Morning
                                                                        </span>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="frequency.medicationInstructionDeepTypeId === 2">
                                                                        <span class="badge badge-primary ">
                                                                            <i *ngIf="!item.isAfternoonTaking" class="mdi mdi-white-balance-sunny mr-1"></i>
                                                                            <span *ngIf="item.isAfternoonTaking" class="spinner-grow spinner-grow-sm"></span>Afternoon
                                                                        </span>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="frequency.medicationInstructionDeepTypeId === 3">
                                                                        <span class="badge badge-secondary ">
                                                                            <i *ngIf="!item.isNightTaking" class="mdi mdi-moon-waning-crescent mr-1"></i>
                                                                            <span *ngIf="item.isNightTaking" class="spinner-grow spinner-grow-sm"></span>Night
                                                                        </span>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="frequency.medicationInstructionDeepTypeId === 4">
                                                                        <span class="badge badge-secondary ">
                                                                            <i *ngIf="!item.isTimeslotsTaking" class="mdi mdi-weather-cloudy mr-1"></i>
                                                                            <span *ngIf="item.isTimeslotsTaking" class="spinner-grow spinner-grow-sm"></span>TimeLine
                                                                        </span>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </td>

                                                <td>
                                                    <ng-template #cancelledBy>
                                                        <div class="media">
                                                            <div class="avatar-sm mr-2">
                                                                <span class="avatar-title rounded-circle font-12 font-weight-bold text-white"
                                                                      avatar-bg
                                                                      [index]="i"
                                                                      [textContent]="item.modifiedByName | initials"></span>
                                                            </div>
                                                            <div class="media-body">
                                                                <span class="d-block font-12">
                                                                    <span [textContent]="item.modifiedByName"></span>
                                                                    <br />
                                                                    <span [textContent]="item.modifiedDate | date: 'dd-MM-yyyy, h:mm:ss a'"></span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <div *ngIf="item.progressReportMedicationId" class="font-weight-bold">
                                                        <span *ngIf="item.activeStatus === activeStatus.Active" class="text-success">
                                                            <span class="noprint font-weight-normal">Active</span>
                                                            <span class="badge badge-success mr-1 noprint-column">Active</span>
                                                        </span>
                                                        <span *ngIf="item.activeStatus === activeStatus.Completed" class="text-primary">
                                                            <span class="noprint font-weight-normal">Completed</span>
                                                            <span class="badge badge-primary mr-1 noprint-column">Completed</span>
                                                        </span>
                                                        <span *ngIf="item.activeStatus === activeStatus.NotStarted" class="text-info">
                                                            <span class="noprint font-weight-normal">Not Started</span>
                                                            <span class="badge badge-info mr-1 noprint-column">Not Started</span>
                                                        </span>
                                                        <div *ngIf="item.activeStatus === activeStatus.Stopped" class="text-danger">
                                                            <div class="d-flex align-items-center">
                                                                <i *ngIf="item.modifiedByName" class="mdi mdi-information mr-1" placement="left" [ngbPopover]="cancelledBy" triggers="mouseenter:mouseleave" popoverTitle="Stopped By"></i>
                                                                <span class="noprint font-weight-normal">Stopped</span>
                                                                <span class="badge badge-danger noprint-column">Stopped</span>
                                                            </div>
                                                            <div *ngIf="item.stopReason" class="view-reason">
                                                                <small><a href="javascript:;" (click)="viewStopReason(item.stopReason)">view reason</a></small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="!item.progressReportMedicationId">
                                                        ---
                                                    </div>
                                                </td>
                                                <td>
                                                    <ng-container *ngFor="let frequency of item.medicationFrequency">
                                                        <div class="d-block font-12 text-capitalize" *ngIf="frequency">
                                                            <span *ngIf="frequency.medicationInstructionName != null " class="font-weight-bold text-danger white-space-nowrap">{{frequency.medicationInstructionName}}</span>
                                                        </div>

                                                    </ng-container>
                                                </td>
                                                <td>
                                                    <div>{{item.route}}</div>
                                                </td>
                                                <td>
                                                    <div>{{item.dosage}}</div>
                                                </td>
                                                <td class="noprint-column">
                                                    <div class="d-flex align-items-center justify-content-center">
                                                        <a *ngIf="item.progressReportMedicationId" href="javascript:;" (click)="onOpenViewModel(templateViewMedication, item)" class="action-icon text-primary font-20" placement="left" ngbTooltip="View"><i class="mdi mdi-eye"></i></a>
                                                        <div *menuButton="'nurse_medication_edit'">
                                                            <a *ngIf="item.progressReportMedicationId" href="javascript:;" (click)="onOpenUpdateModel(templateUpdateMedication, item)" class="action-icon text-primary font-20" placement="left" ngbTooltip="Edit"><i class="fe-edit"></i></a>

                                                        </div>
                                                        <!--<a *ngIf="item.progressReportMedicationId && item.activeStatus === activeStatus.Active && (!item.morningInstructionStatus || !item.afternoonInstructionStatus || !item.nightInstructionStatus)" href="javascript:;" class="action-icon text-primary ml-1 font-20" (click)="onOpenTakeModel(templateTakeMedication, item)" placement="left" ngbTooltip="Take Medicine"><i class="mdi mdi-offer"></i></a>-->
                                                        <div *menuButton="'nurse_medication_Stop'">
                                                            <a *ngIf="item.progressReportMedicationId && item.activeStatus === activeStatus.Active" (click)="onOpenStopModel(templateStopMedication, item)" href="javascript:;" class="action-icon text-danger ml-1" placement="left" ngbTooltip="Stop this Medicine"><i class="mdi mdi-octagon font-20"></i></a>

                                                        </div>
                                                        <div *menuButton="'nurse_medication_delete'">
                                                            <a *ngIf="item.progressReportMedicationId" href="javascript:;" class="action-icon text-danger ml-1" (click)="onRemoveMedicine(item)" placement="left" ngbTooltip="Delete"><i class="mdi mdi-trash-can"></i></a>

                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </tbody>
                            <tfoot *ngIf="currentDisplayMode !== displayMode.Concept">
                                <tr>
                                    <td colspan="3">
                                        <div class="d-flex align-items-center justify-content-end">
                                            <button (click)="cancelIndent()" class="btn btn-danger btn-sm"><i class="mr-1 mdi mdi-close"></i>Cancel</button>
                                            <button (click)="submitIndent()" [disabled]="indentSubmitting" class="btn btn-primary btn-sm">
                                                <ng-container *ngIf="indentSubmitting">
                                                    <span class="spinner-grow spinner-grow-sm mr-1"></span> Please wait
                                                </ng-container>
                                                <ng-container *ngIf="!indentSubmitting">
                                                    <i class="mr-1 mdi mdi-check-bold"></i>Submit
                                                </ng-container>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </form>
        <!--<div class="row">
            <div class="col-sm-12">
                <div class="modal-footer" *ngIf="isAnyChange">
                    <button type="button" (click)="submit()" [disabled]="submitting" class="btn btn-primary btn-sm">
                        <span *ngIf="submitting">
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            Please wait..
                        </span>
                        <span *ngIf="!submitting"><i class="fe-check mr-1"></i>Submit</span>
                    </button>
                </div>
            </div>
        </div>-->
    </div>
</div>

<ng-template #templateProgressReport>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-receipt mr-1"></i>Progress Report</h4>
        <div>
            <div class="btn-group d-print-none">
                <button class="btn btn-sm" [class.btn-light]="!isPrintLogo" [class.btn-outline-light]="isPrintLogo" type="button" (click)="onChangePrintType(false)">
                    <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                </button>
                <button class="btn btn-sm" [class.btn-light]="isPrintLogo" [class.btn-outline-light]="!isPrintLogo" type="button" (click)="onChangePrintType(true)">
                    <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                </button>
            </div>
            <div class="btn-group d-print-none">
                <button class="btn btn-sm" [class.btn-light]="!isFooter" [class.btn-outline-light]="isFooter" type="button" (click)="onChangeFooter(false)">
                    <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
                </button>
                <button class="btn btn-sm" [class.btn-light]="isFooter" [class.btn-outline-light]="!isFooter" type="button" (click)="onChangeFooter(true)">
                    <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
                </button>
            </div>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();"><i class="fe-x"></i></button>
            <button [useExistingCss]="true"
                    printSectionId="progress-report"
                    ngxPrint
                    class="btn btn-light btn-xs mr-1 d-print-none">
                <i class="fe-printer mr-1"></i>Print
            </button>
        </div>
    </div>
    <div id="progress-report">
        <div class="card">
            <div class="card-body p-0">
                <div class="card-box m-0">
                    <div class="row">
                        <div class="col-lg-12" *ngIf="isPrintLogo">
                         <banner-setting [reportName]='"Medication Report Hedaer"'></banner-setting>
                        </div>
                        <div class="col-lg-12">
                            <h2 class="box-title mt-3 text-center">Progress Report</h2>
                        </div>
                    </div>
                    <div class="row" *ngIf="admission">
                        <div class="col-lg-12">
                            <div class="overflow-auto">
                                <table class="table table-sm table-borderless table-centered">
                                    <tbody>
                                        <tr>
                                            <th>
                                                <span *ngIf="isAdmission">Admission No</span>
                                                <span *ngIf="!isAdmission">Appointment No</span>
                                            </th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="admission.admissionNo"></span>
                                            </td>
                                            <th>
                                                <span *ngIf="isAdmission">Admission Date</span>
                                                <span *ngIf="!isAdmission">Appointment Date</span>
                                            </th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span *ngIf="isAdmission" [textContent]="admission.admissionDate | date: 'dd-MM-yyyy, h:mm:ss a'"></span>
                                                <span *ngIf="!isAdmission">
                                                    <span [textContent]="admission.admissionDate | date: 'dd-MM-yyyy'"></span>
                                                    <span [textContent]="' ' + admission.timeString"></span>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Patient Name</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span>
                                                    <span class="text-uppercase" [textContent]="admission.fullName + ', '"></span>
                                                    <span [textContent]="(admission.gender === 'F' ? 'Female' : 'Male') + ', '"></span>
                                                    <span [textContent]="admission.age + 'yrs'"></span>
                                                </span>
                                            </td>
                                            <th>Doctor Name</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span class="text-uppercase" [textContent]="admission.providerName"></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>UMR No</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="admission.umrNo"></span>
                                            </td>
                                            <th>Department</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="admission.departmentName"></span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="isAdmission">
                                            <th>Admitted Ward</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="!admission.bedNumber ? 'N/A' : (admission.wardName + ' / ' + admission.roomName + ' / ' + admission.bedNumber)"></span>
                                            </td>
                                            <th>Discharge Date</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="admission.isDischarged ? (admission.dischargeDate | date:'dd-MM-yyyy, hh:mm a') : 'Not Discharged Yet'"></span>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12">
                            <div class="overflow-auto">
                                <table class="table table-centered table-bordered table-sm mb-0">
                                    <thead>
                                        <tr>
                                            <th colspan="7">
                                                <div class="d-flex align-items-center">
                                                    <div>
                                                        <span>Status | </span>
                                                        <span [textContent]="dates.current | date: 'dd MMMM, y'"></span>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <thead class="table-primary">
                                        <tr>
                                            <th>#</th>
                                            <th style="width:33%;">Name</th>
                                            <th>Created By</th>
                                            <th>Unit</th>
                                            <th>Duration</th>
                                            <th>Usage</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngIf="!loading && medicines.length">
                                            <ng-container *ngFor="let item of medicines; let i = index;">
                                                <tr [ngClass]="{'table-danger' : item.activeStatus === activeStatus.Stopped}">
                                                    <td [textContent]="(i + 1)"></td>
                                                    <td class="break-all" style="min-width:150px;">
                                                        <div>
                                                            <div class="media">
                                                                <div>
                                                                    <ng-container [ngSwitch]="(item.productTypeName | lowercase)">
                                                                        <span *ngSwitchCase="'injection'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-injection.png'" width="28" /></span>
                                                                        <span *ngSwitchCase="'tablet'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-tablet.png'" width="28" /></span>
                                                                        <span *ngSwitchCase="'gel'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-hair-gel.png'" width="28" /></span>
                                                                        <span *ngSwitchCase="'syrup'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-syrup.png'" width="28" /></span>
                                                                        <span *ngSwitchCase="'ointment'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-ointment.png'" width="28" /></span>
                                                                        <span *ngSwitchCase="'respules'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-respules.png'" width="28" /></span>
                                                                        <span *ngSwitchCase="'syringe'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-syringe.png'" width="28" /></span>
                                                                        <span *ngSwitchCase="'surgical'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-scissors.png'" width="28" /></span>
                                                                        <span *ngSwitchCase="'capsules'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-capsule.png'" width="28" /></span>
                                                                        <span *ngSwitchCase="'sachets'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-sachet.png'" width="28" /></span>
                                                                        <span *ngSwitchCase="'drops'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-drop.png'" width="28" /></span>
                                                                        <span *ngSwitchCase="'sprays'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-spray.png'" width="28" /></span>
                                                                        <span *ngSwitchCase="'suppository'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-suppository.png'" width="28" /></span>
                                                                        <span *ngSwitchCase="'gloves'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-gloves.png'" width="28" /></span>
                                                                        <span *ngSwitchCase="'lotion'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-lotion.png'" width="28" /></span>
                                                                        <span *ngSwitchCase="'powder'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-powder.png'" width="28" /></span>
                                                                        <span *ngSwitchCase="'others'"><i class="mdi mdi-medical-bag font-28"></i></span>
                                                                        <span *ngSwitchDefault><i class="mdi mdi-medical-bag font-28"></i></span>
                                                                    </ng-container>
                                                                </div>
                                                                <div class="media-body ml-1">
                                                                    <h5 [ngClass]="{'text-line-through' : item.activeStatus === activeStatus.Stopped}" class="mb-0 mt-0 font-weight-normal" [textContent]="item.productName || '---'"></h5>
                                                                    <span class="d-block font-12 text-capitalize">
                                                                        <small [textContent]="item.productTypeName || '---'"></small>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <div class="media">
                                                                <div class="media-body">
                                                                    <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.createdByName"></h5>
                                                                    <span class="d-block font-12 mt-1">
                                                                        <small [textContent]="item.createdDate | date:'dd-MM-yyyy, hh:mm a'"></small>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td [ngClass]="{'text-line-through' : item.activeStatus === activeStatus.Stopped}" [textContent]="item.unit"></td>
                                                    <td [ngClass]="{'text-line-through' : item.activeStatus === activeStatus.Stopped}">
                                                        <div>
                                                            <span [textContent]="item.duration" class="mr-1"></span><ng-container [ngSwitch]="item.medicationDurationTypeId">
                                                                <span *ngSwitchCase="1">Day<span *ngIf="item.duration > 1">s</span></span>
                                                                <span *ngSwitchCase="2">Week<span *ngIf="item.duration > 1">s</span></span>
                                                                <span *ngSwitchCase="3">Month<span *ngIf="item.duration > 1">s</span></span>
                                                            </ng-container>
                                                        </div>
                                                        <div *ngIf="item.leftDays && item.activeStatus === activeStatus.Active">
                                                            <small class="text-danger"><span [textContent]="item.leftDays"></span> Day<span *ngIf="item.leftDays > 1">s</span> Left</small>
                                                        </div>
                                                        <div *ngIf="item.leftDays === 0 && item.activeStatus === activeStatus.Active">
                                                            <small class="text-primary">
                                                                <span [textContent]="item.duration" class="mr-1"></span><ng-container [ngSwitch]="item.medicationDurationTypeId">
                                                                    <span *ngSwitchCase="1">Day<span *ngIf="item.duration > 1">s</span></span>
                                                                    <span *ngSwitchCase="2">Week<span *ngIf="item.duration > 1">s</span></span>
                                                                    <span *ngSwitchCase="3">Month<span *ngIf="item.duration > 1">s</span></span>
                                                                </ng-container>&nbsp;<span>completed</span>
                                                            </small>
                                                        </div>
                                                    </td>
                                                    <td [ngClass]="{'text-line-through' : item.activeStatus === activeStatus.Stopped}">
                                                        <div class="d-flex align-content-center">
                                                            <ng-container *ngFor="let frequency of item.medicationFrequency">
                                                                <div class="l-chip pr-1 mr-1">
                                                                    <div class="d-block font-12 text-capitalize">
                                                                        <span *ngIf="frequency.status === null && item.progressReportMedicationId" class="font-weight-bold text-danger white-space-nowrap"><i class="mdi mdi-flash blink"></i>Pending</span>
                                                                        <span *ngIf="frequency.status === false && item.progressReportMedicationId" class="font-weight-bold text-danger white-space-nowrap"><i class="mdi mdi-close"></i>Missed</span>
                                                                        <span *ngIf="frequency.status" class="font-weight-bold text-success white-space-nowrap"><i class="mdi mdi-check"></i>Taken  By {{item.takenByName}}</span>
                                                                    </div>
                                                                    <div>
                                                                        <small>
                                                                            <ng-container *ngIf="frequency.medicationInstructionDeepTypeId === 1">
                                                                                Morning
                                                                            </ng-container>
                                                                            <ng-container *ngIf="frequency.medicationInstructionDeepTypeId === 2">
                                                                                Afternoon
                                                                            </ng-container>
                                                                            <ng-container *ngIf="frequency.medicationInstructionDeepTypeId === 3">
                                                                                Night
                                                                            </ng-container>
                                                                            <ng-container *ngIf="frequency.medicationInstructionDeepTypeId === 4">
                                                                                Timeline
                                                                            </ng-container>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ng-template #cancelledBy>
                                                            <div class="media">
                                                                <div class="avatar-sm mr-2">
                                                                    <span class="avatar-title rounded-circle font-12 font-weight-bold text-white"
                                                                          avatar-bg
                                                                          [index]="i"
                                                                          [textContent]="item.modifiedByName | initials"></span>
                                                                </div>
                                                                <div class="media-body">
                                                                    <span class="d-block font-12">
                                                                        <span [textContent]="item.modifiedByName"></span>
                                                                        <br />
                                                                        <span [textContent]="item.modifiedDate | date: 'dd-MM-yyyy, h:mm:ss a'"></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                        <div *ngIf="item.progressReportMedicationId" class="font-weight-bold">
                                                            <span *ngIf="item.activeStatus === activeStatus.Active" class="text-success">
                                                                <span class="font-weight-normal">Active</span>
                                                            </span>
                                                            <span *ngIf="item.activeStatus === activeStatus.Completed" class="text-primary">
                                                                <span class="font-weight-normal">Completed</span>
                                                            </span>
                                                            <span *ngIf="item.activeStatus === activeStatus.NotStarted" class="text-info">
                                                                <span class="font-weight-normal">Not Started</span>
                                                            </span>
                                                            <div *ngIf="item.activeStatus === activeStatus.Stopped" class="text-danger">
                                                                <div class="d-flex align-items-center">
                                                                    <i *ngIf="item.modifiedByName" class="mdi mdi-information mr-1" placement="left" [ngbPopover]="cancelledBy" triggers="mouseenter:mouseleave" popoverTitle="Stopped By"></i>
                                                                    <span class="font-weight-normal">Stopped</span>
                                                                </div>
                                                                <div *ngIf="item.stopReason" class="view-reason">
                                                                    <small><a href="javascript:;" (click)="viewStopReason(item.stopReason)">view reason</a></small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!item.progressReportMedicationId">
                                                            ---
                                                        </div>

                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                    </tbody>
                                </table>
                                <div *ngIf="isFooter">
                                    <banner-setting [reportName]='"Medication Report Footer"'></banner-setting>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #templateAddMedication>
    <form [formGroup]="addMedicationForm" (ngSubmit)="onAddMedication()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-pill mr-1"></i>Add Medicine</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <!--<div class="col-sm-12" *ngIf="!autoSelected">-->
                <div class="col-sm-12">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="form-group">
                            <label class="mb-1">Search Medicine <code>*</code></label>
                            <ng-select class="ng-select-sm w-500p" [items]="medicationsAsync | async"
                                       [loading]="loadingMedicationsAsync"
                                       bindLabel="name"
                                       bindValue="id"
                                       [typeahead]="medicationsInput"
                                       [minTermLength]="2"
                                       typeToSearchText="Search medicine"
                                       [virtualScroll]="true"
                                       (change)="onMedicationSelect($event)"
                                       placeholder="Enter 2 or more characters"
                                       formControlName="pharmacyProductId">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    No medicines found<span *ngIf="searchTerm"> for '<span [textContent]="searchTerm"></span>'</span>.
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.name"></h5>
                                    <span class="text-muted d-block font-13" [textContent]="item.typeName"></span>
                                </ng-template>
                            </ng-select>
                            <div *ngIf="medicationSubmitted && medicationForm.pharmacyProductId.errors" class="invalid-feedback show-must">
                                <div *ngIf="medicationForm.pharmacyProductId.errors.required">Product field is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 mb-3" *ngIf="selectedMedication">
                    <div class="overflow-auto">
                        <table class="table table-centered table-sm table-striped table-bordered mb-0">
                            <thead>
                                <tr class="table-primary">
                                    <th>Name</th>
                                    <th>Type</th>
                                    <!--<th *ngIf="!autoSelected">Company</th>-->
                                    <th>Company</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div>
                                            <div class="media">
                                                <div class="p-1">
                                                    <ng-container [ngSwitch]="(selectedMedication.typeName | lowercase)">
                                                        <span *ngSwitchCase="'injection'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-injection.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'tablet'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-tablet.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'tablets'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-tablet.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'gel'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-hair-gel.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'syrup'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-syrup.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'ointment'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-ointment.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'respules'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-respules.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'syringe'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-syringe.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'surgical'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-scissors.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'capsules'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-capsule.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'sachets'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-sachet.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'drops'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-drop.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'sprays'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-spray.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'suppository'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-suppository.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'gloves'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-gloves.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'lotion'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-lotion.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'powder'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-powder.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'others'"><i class="mdi mdi-medical-bag font-28"></i></span>
                                                        <span *ngSwitchDefault><i class="mdi mdi-medical-bag font-28"></i></span>
                                                    </ng-container>
                                                </div>
                                                <div class="media-body ml-1">
                                                    <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="selectedMedication.name"></h5>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td [textContent]="selectedMedication.typeName"></td>
                                    <!--<td *ngIf="!autoSelected" [textContent]="selectedMedication.companyName"></td>-->
                                    <td [textContent]="selectedMedication.companyName"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-sm-12 mb-3">
                    <div class="row mb-3">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Unit <span class="text-danger">*</span></label>
                                <input type="number" class="form-control" numbersOnly min="1" formControlName="unit" placeholder="Enter no of units to take at a time" [ngClass]="{ 'is-invalid': medicationSubmitted && medicationForm.unit.errors }" />
                                <div *ngIf="medicationSubmitted && medicationForm.unit.errors" class="invalid-feedback show-must">
                                    <div *ngIf="medicationForm.unit.errors.required">Unit field is required</div>
                                    <div *ngIf="medicationForm.unit.errors.pattern">Invalid Unit value</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Duration <span class="text-danger">*</span></label>
                                <div class="input-group mb-0">
                                    <div class="input-group-prepend">
                                        <select class="input-group-text custom-select" formControlName="medicationDurationTypeId">
                                            <option [ngValue]="1">Days</option>
                                            <option [ngValue]="2">Weeks</option>
                                            <option [ngValue]="3">Months</option>
                                        </select>
                                    </div>
                                    <input class="form-control" maxlength="3" placeholder="###" formControlName="duration" block autocomplete="nope" type="text" numbersOnly [ngClass]="{ 'is-invalid': medicationSubmitted && medicationForm.duration.errors }" />
                                </div>
                                <div *ngIf="medicationSubmitted && medicationForm.duration.errors" class="invalid-feedback show-must">
                                    <div *ngIf="medicationForm.duration.errors.required">Duration field is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <!--<div class="col-sm-6">
                            <div class="form-group">
                                <label>Start Medicine From <span class="text-danger">*</span></label>
                                <select class="form-control" formControlName="startFrom">
                                    <option selected [ngValue]="1">Today</option>
                                    <option [ngValue]="2">Tomorrow</option>
                                    <option [ngValue]="3">Select my own date</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Start Date <span *ngIf="medicationForm.startFrom.value === 1 || medicationForm.startFrom.value === 2" class="text-danger">read-only</span><span *ngIf="medicationForm.startFrom.value === 3" class="text-danger">*</span></label>
                                <input [min]="today | date:'yyyy-MM-dd'" class="form-control" formControlName="startDate" type="date" [ngClass]="{ 'is-invalid': medicationSubmitted && medicationForm.startDate.errors }" />
                                <div *ngIf="medicationSubmitted && medicationForm.startDate.errors" class="invalid-feedback show-must">
                                    <div *ngIf="medicationForm.startDate.errors.required">Start Date field is required</div>
                                </div>
                            </div>
                        </div>-->
                        <div class="col-lg-6">
                            <div class="form-group position-relative mb-0">
                                <label>Start Date <span class="text-danger">*</span></label>
                                <input class="form-control date-picker" type="text" block [minDate]="expiry"
                                       formControlName="startDate" autocomplete="nope"
                                       [ngClass]="{ 'is-invalid': medicationSubmitted && medicationForm.startDate.errors }"
                                       ngbDatepicker #startDate="ngbDatepicker"
                                       (click)="startDate.toggle(); $event.stopPropagation();"
                                       [value]="medicationForm.startDate.value | date: 'dd/MM/yyyy'" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 mb-3">
                    <fieldset>
                        <legend>Frequency</legend>
                        <div *ngIf="medicationSubmitted && !medicationForm.isMorning.value && !medicationForm.isAfternoon.value && !medicationForm.isNight.value && !medicationForm.isTimeLine.value" class="invalid-feedback show-must mb-2">
                            <div><i class="fe-arrow-down mr-1"></i>please select one of the option below</div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="d-flex align-items-center justify-content-center mb-2">
                                    <div class="custom-control custom-checkbox w-200p">
                                        <input id="morning" class="custom-control-input" type="checkbox" formControlName="isMorning" [ngClass]="{ 'is-invalid': medicationSubmitted && !medicationForm.isMorning.value && !medicationForm.isAfternoon.value && !medicationForm.isNight.value && !medicationForm.isTimeLine.value}" />
                                        <label class="custom-control-label font-weight-bold" for="morning"> Morning</label>
                                    </div>
                                    <div class="w-100-per">
                                        <select class="custom-select" formControlName="morningInstructionTypeId" [ngClass]="{ 'is-invalid': (medicationSubmitted && medicationForm.morningInstructionTypeId.errors) }">
                                            <option [ngValue]="null" selected hidden>Select one option</option>
                                            <option *ngFor="let item of morningInstructions" [ngValue]="item.id" [textContent]="item.name"></option>
                                        </select>
                                        <div *ngIf="medicationSubmitted && medicationForm.morningInstructionTypeId.errors" class="invalid-feedback show-must">
                                            <div *ngIf="medicationForm.morningInstructionTypeId.errors.required">this field is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="d-flex align-items-center justify-content-center mb-2">
                                    <div class="custom-control custom-checkbox w-200p">
                                        <input id="afternoon" class="custom-control-input" type="checkbox" formControlName="isAfternoon" [ngClass]="{ 'is-invalid': medicationSubmitted && !medicationForm.isMorning.value && !medicationForm.isAfternoon.value && !medicationForm.isNight.value && !medicationForm.isTimeLine.value }" />
                                        <label class="custom-control-label font-weight-bold" for="afternoon"> Afternoon</label>
                                    </div>
                                    <div class="w-100-per">
                                        <select class="custom-select" formControlName="afternoonInstructionTypeId" [ngClass]="{ 'is-invalid': (medicationSubmitted && medicationForm.afternoonInstructionTypeId.errors) }">
                                            <option [ngValue]="null" selected hidden>Select one option</option>
                                            <option *ngFor="let item of afternoonInstructions" [ngValue]="item.id" [textContent]="item.name"></option>
                                        </select>
                                        <div *ngIf="medicationSubmitted && medicationForm.afternoonInstructionTypeId.errors" class="invalid-feedback show-must">
                                            <div *ngIf="medicationForm.afternoonInstructionTypeId.errors.required">this field is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="d-flex align-items-center justify-content-center mb-2">
                                    <div class="custom-control custom-checkbox w-200p">
                                        <input id="night" class="custom-control-input" type="checkbox" formControlName="isNight" [ngClass]="{ 'is-invalid': medicationSubmitted && !medicationForm.isMorning.value && !medicationForm.isAfternoon.value && !medicationForm.isNight.value && !medicationForm.isTimeLine.value}" />
                                        <label class="custom-control-label font-weight-bold" for="night"> Night</label>
                                    </div>
                                    <div class="w-100-per">
                                        <select class="custom-select" formControlName="nightInstructionTypeId" [ngClass]="{ 'is-invalid': (medicationSubmitted && medicationForm.nightInstructionTypeId.errors) }">
                                            <option [ngValue]="null" selected hidden>Select one option</option>
                                            <option *ngFor="let item of nightInstructions" [ngValue]="item.id" [textContent]="item.name"></option>
                                        </select>
                                        <div *ngIf="medicationSubmitted && medicationForm.nightInstructionTypeId.errors" class="invalid-feedback show-must">
                                            <div *ngIf="medicationForm.nightInstructionTypeId.errors.required">this field is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="d-flex align-items-center justify-content-center mb-2">
                                    <div class="custom-control custom-checkbox w-200p">
                                        <input id="timeLine" class="custom-control-input" type="checkbox" formControlName="isTimeLine" [ngClass]="{ 'is-invalid': medicationSubmitted && !medicationForm.isMorning.value && !medicationForm.isAfternoon.value && !medicationForm.isNight.value && !medicationForm.isTimeLine.value  }" />
                                        <label class="custom-control-label font-weight-bold" for="timeLine">Time Line</label>
                                    </div>
                                    <div class="w-100-per table-responsive" style="overflow-y:auto;">
                                        <table class="table table-bordered m-0">
                                            <thead>
                                                <tr>
                                                    <ng-container *ngFor="let item of timeLineSlots; let i = index">
                                                        <th [id]="'header_'" class="position-relative" [ngbTooltip]="item.name" placement="left">
                                                            <div class="d-flex align-items-center justify-content-center flex-column">
                                                                <i [class]="'mr-1 mdi mdi-' + item.icon"></i>
                                                                <span class="white-space-nowrap" [id]="item.id" [textContent]="item.name"></span>
                                                            </div>
                                                        </th>
                                                    </ng-container>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <ng-container formArrayName="timeLineId" *ngFor="let item of timeLineSlots">
                                                        <td>
                                                            <input type="checkbox" [value]="item.id" [disabled]="isDisabled" (change)="onCheckboxChange($event)" />
                                                        </td>
                                                    </ng-container>
                                                </tr>
                                                <div *ngIf="medicationSubmitted && medicationForm.timeLineId.errors" class="invalid-feedback show-must">
                                                    <div *ngIf="medicationForm.timeLineId.errors.required">this field is required</div>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Instructions</label>
                        <textarea placeholder="Medicine Instructions (optional)" rows="3" formControlName="instructions" class="form-control"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
            <button type="submit" class="btn btn-primary btn-sm">
                <span><i class="fe-plus mr-1" placement="right"></i>Add</span>
            </button>
        </div>
    </form>
</ng-template>


<ng-template #templateStopMedication>
    <form [formGroup]="stopMedicationForm" (submit)="onStopMedication()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-octagon mr-1"></i>Stop Medicine</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-sm-12">
                    <div class="overflow-auto">
                        <table class="table table-centered table-sm table-striped table-bordered mb-0">
                            <thead class="table-primary">
                                <tr>
                                    <th>Name</th>
                                    <th>Usage</th>
                                    <th>Created By</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div>
                                            <div class="media">
                                                <div class="p-1">
                                                    <ng-container [ngSwitch]="(selectedMedicine.productTypeName | lowercase)">
                                                        <span *ngSwitchCase="'injection'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-injection.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'tablet'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-tablet.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'gel'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-hair-gel.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'syrup'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-syrup.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'ointment'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-ointment.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'respules'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-respules.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'syringe'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-syringe.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'surgical'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-scissors.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'capsules'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-capsule.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'sachets'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-sachet.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'drops'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-drop.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'sprays'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-spray.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'suppository'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-suppository.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'gloves'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-gloves.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'lotion'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-lotion.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'powder'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-powder.png'" width="28" /></span>
                                                        <span *ngSwitchCase="'others'"><i class="mdi mdi-medical-bag font-28"></i></span>
                                                        <span *ngSwitchDefault><i class="mdi mdi-medical-bag font-28"></i></span>
                                                    </ng-container>
                                                </div>
                                                <div class="media-body ml-1">
                                                    <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="selectedMedicine.productName || '---'"></h5>
                                                    <span class="d-block font-12 text-capitalize">
                                                        <small [textContent]="selectedMedicine.productTypeName || '---'"></small>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <div class="media">
                                                <div class="media-body">
                                                    <h5 class="mb-0 mt-0 font-weight-normal">
                                                        <span [textContent]="selectedMedicine.unit" class="mr-1"></span>
                                                        <span class="text-capitalize" [textContent]="selectedMedicine.productTypeName"></span>
                                                        for <span class="mr-1" [textContent]="selectedMedicine.duration"></span>
                                                        <span>
                                                            <ng-container [ngSwitch]="selectedMedicine.medicationDurationTypeId">
                                                                <span *ngSwitchCase="1">Day<span *ngIf="selectedMedicine.duration > 1">s</span></span>
                                                                <span *ngSwitchCase="2">Week<span *ngIf="selectedMedicine.duration > 1">s</span></span>
                                                                <span *ngSwitchCase="3">Month<span *ngIf="selectedMedicine.duration > 1">s</span></span>
                                                            </ng-container>
                                                        </span>
                                                    </h5>
                                                    <span class="d-block font-14">
                                                        <ng-container *ngFor="let frequency of selectedMedicine.medicationFrequency">
                                                            <span *ngIf="frequency.medicationInstructionDeepTypeId === 1" class="badge badge-success mr-1"><i class="mdi mdi-weather-cloudy mr-1"></i>Morning</span>
                                                            <span *ngIf="frequency.medicationInstructionDeepTypeId === 2" class="badge badge-primary mr-1"><i class="mdi mdi-white-balance-sunny mr-1"></i>Afternoon</span>
                                                            <span *ngIf="frequency.medicationInstructionDeepTypeId === 3" class="badge badge-secondary"><i class="mdi mdi-moon-waning-crescent mr-1"></i>Night</span>
                                                        </ng-container>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <div class="media">
                                                <div class="media-body">
                                                    <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="selectedMedicine.createdByName"></h5>
                                                    <span class="d-block font-12 mt-1">
                                                        <small [textContent]="selectedMedicine.createdDate | date:'dd-MM-yyyy, hh:mm a'"></small>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="font-weight-bold">
                                            <span class="text-success">
                                                <span class="badge badge-success mr-1">Active</span>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-sm-12 mt-3">
                    <div class="form-group">
                        <label class="mb-1">Reason</label>
                        <textarea formControlName="reason" rows="2" class="form-control" placeholder="Give a reason why to stop this medicine (optional)"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
            <button type="submit" [disabled]="stopping" class="btn btn-danger btn-sm">
                <span *ngIf="stopping">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!stopping"><i class="fe-check mr-1"></i>Stop</span>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #templateUpdateMedication>
    <form [formGroup]="addMedicationForm" (submit)="onUpdateMedication()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-pill mr-1"></i>Update Medicine</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-sm-12 mb-3">
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label>Unit <span class="text-danger">*</span></label>
                                <input type="number" class="form-control" formControlName="unit" placeholder="Enter no of units to take at a time" [ngClass]="{ 'is-invalid': medicationSubmitted && medicationForm.unit.errors }" />
                                <div *ngIf="medicationSubmitted && medicationForm.unit.errors" class="invalid-feedback show-must">
                                    <div *ngIf="medicationForm.unit.errors.required">Unit field is required</div>
                                    <div *ngIf="medicationForm.unit.errors.pattern">Invalid Unit value</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Duration <span class="text-danger">*</span></label>
                                <div class="input-group mb-0">
                                    <div class="input-group-prepend">
                                        <!--<input class="form-control" maxlength="3" placeholder="###" formControlName="duration" block autocomplete="nope" type="text" numbersOnly [ngClass]="{ 'is-invalid': medicationSubmitted && medicationForm.duration.errors }" />-->
                                        <input class="form-control" maxlength="3" placeholder="###" formControlName="duration" block autocomplete="nope" type="number" numbersOnly [ngClass]="{ 'is-invalid': medicationSubmitted && medicationForm.duration.errors }" />
                                        <select class="input-group-text custom-select" formControlName="medicationDurationTypeId">
                                            <option [ngValue]="1">Days</option>
                                            <option [ngValue]="2">Weeks</option>
                                            <option [ngValue]="3">Months</option>
                                        </select>
                                    </div>
                                </div>
                                <div *ngIf="medicationSubmitted && medicationForm.duration.errors" class="invalid-feedback show-must">
                                    <div *ngIf="medicationForm.duration.errors.required">Duration field is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="form-group position-relative mb-0">
                                <label>Start Date <span class="text-danger">*</span></label>
                                <input class="form-control date-picker" type="text" block
                                       formControlName="startDate" autocomplete="nope"
                                       [ngClass]="{ 'is-invalid': medicationSubmitted && medicationForm.startDate.errors }"
                                       ngbDatepicker #startDate="ngbDatepicker"
                                       (click)="startDate.toggle(); $event.stopPropagation();"
                                       [value]="medicationForm.startDate.value | date: 'dd/MM/yyyy'" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 mb-3">
                    <fieldset>
                        <legend>Frequency</legend>
                        <div *ngIf="medicationSubmitted && !medicationForm.isMorning.value && !medicationForm.isAfternoon.value && !medicationForm.isNight.value  && !medicationForm.isTimeLine.value" class="invalid-feedback show-must mb-2">
                            <div><i class="fe-arrow-down mr-1"></i>please select one of the option below</div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="d-flex align-items-center justify-content-center mb-2">
                                    <div class="custom-control custom-checkbox w-200p">
                                        <input id="morning" class="custom-control-input" type="checkbox" formControlName="isMorning" [ngClass]="{ 'is-invalid': medicationSubmitted && !medicationForm.isMorning.value && !medicationForm.isAfternoon.value && !medicationForm.isNight.value && !medicationForm.isTimeLine.value}" />
                                        <label class="custom-control-label font-weight-bold" for="morning"> Morning</label>
                                    </div>
                                    <div class="w-100-per">
                                        <select class="custom-select" formControlName="morningInstructionTypeId" [ngClass]="{ 'is-invalid': (medicationSubmitted && medicationForm.morningInstructionTypeId.errors) }">
                                            <option [ngValue]="null" selected hidden>Select one option</option>
                                            <option *ngFor="let item of morningInstructions" [ngValue]="item.id" [textContent]="item.name"></option>
                                        </select>
                                        <div *ngIf="medicationSubmitted && medicationForm.morningInstructionTypeId.errors" class="invalid-feedback show-must">
                                            <div *ngIf="medicationForm.morningInstructionTypeId.errors.required">this field is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="d-flex align-items-center justify-content-center mb-2">
                                    <div class="custom-control custom-checkbox w-200p">
                                        <input id="afternoon" class="custom-control-input" type="checkbox" formControlName="isAfternoon" [ngClass]="{ 'is-invalid': medicationSubmitted && !medicationForm.isMorning.value && !medicationForm.isAfternoon.value && !medicationForm.isNight.value && !medicationForm.isTimeLine.value }" />
                                        <label class="custom-control-label font-weight-bold" for="afternoon"> Afternoon</label>
                                    </div>
                                    <div class="w-100-per">
                                        <select class="custom-select" formControlName="afternoonInstructionTypeId" [ngClass]="{ 'is-invalid': (medicationSubmitted && medicationForm.afternoonInstructionTypeId.errors) }">
                                            <option [ngValue]="null" selected hidden>Select one option</option>
                                            <option *ngFor="let item of afternoonInstructions" [ngValue]="item.id" [textContent]="item.name"></option>
                                        </select>
                                        <div *ngIf="medicationSubmitted && medicationForm.afternoonInstructionTypeId.errors" class="invalid-feedback show-must">
                                            <div *ngIf="medicationForm.afternoonInstructionTypeId.errors.required">this field is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="d-flex align-items-center justify-content-center">
                                    <div class="custom-control custom-checkbox w-200p">
                                        <input id="night" class="custom-control-input" type="checkbox" formControlName="isNight" [ngClass]="{ 'is-invalid': medicationSubmitted && !medicationForm.isMorning.value && !medicationForm.isAfternoon.value && !medicationForm.isNight.value && !medicationForm.isTimeLine.value }" />
                                        <label class="custom-control-label font-weight-bold" for="night"> Night</label>
                                    </div>
                                    <div class="w-100-per">
                                        <select class="custom-select" formControlName="nightInstructionTypeId" [ngClass]="{ 'is-invalid': (medicationSubmitted && medicationForm.nightInstructionTypeId.errors) }">
                                            <option [ngValue]="null" selected hidden>Select one option</option>
                                            <option *ngFor="let item of nightInstructions" [ngValue]="item.id" [textContent]="item.name"></option>
                                        </select>
                                        <div *ngIf="medicationSubmitted && medicationForm.nightInstructionTypeId.errors" class="invalid-feedback show-must">
                                            <div *ngIf="medicationForm.nightInstructionTypeId.errors.required">this field is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <!--Time Slots-->
                            <div class="col-lg-12">
                                <div class="d-flex align-items-center justify-content-center mb-2">
                                    <div class="custom-control custom-checkbox w-200p">
                                        <input id="timeLine" class="custom-control-input" type="checkbox" formControlName="isTimeLine" [ngClass]="{ 'is-invalid': medicationSubmitted && !medicationForm.isMorning.value && !medicationForm.isAfternoon.value && !medicationForm.isNight.value && !medicationForm.isTimeLine.value  }" />
                                        <label class="custom-control-label font-weight-bold" for="timeLine">Time Line</label>
                                    </div>
                                    <div class="w-100-per table-responsive" style="overflow-y:auto;">
                                        <table class="table table-bordered m-0">
                                            <thead>
                                                <tr>
                                                    <ng-container *ngFor="let item of hours; let i = index">
                                                        <th [id]="'header_' + i" class="position-relative" [ngbTooltip]="item.name" placement="left">
                                                            <div class="d-flex align-items-center justify-content-center flex-column">
                                                                <i [class]="'mr-1 mdi mdi-' + item.icon"></i>
                                                                <span class="white-space-nowrap" [textContent]="item.meridiemHour + item.meridiem"></span>
                                                            </div>
                                                        </th>
                                                    </ng-container>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <ng-container formArrayName="timeLineId" *ngFor="let item of timeLineSlots; let i = index">
                                                        <td>
                                                            <input type="checkbox" [value]="item.id" [checked]="timeslotschecked.includes(item.name)" [disabled]="isDisabled" (change)="onCheckboxChangeEdit($event)" />
                                                        </td>
                                                    </ng-container>
                                                </tr>
                                                <div *ngIf="medicationSubmitted && medicationForm.timeLineId.errors" class="invalid-feedback show-must">
                                                    <div *ngIf="medicationForm.timeLineId.errors.required">this field is required</div>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Instructions</label>
                        <textarea placeholder="Medicine Instructions (optional)" rows="3" formControlName="instructions" class="form-control"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
            <button type="submit" class="btn btn-primary btn-sm">
                <span><i class="fe-plus mr-1" placement="right"></i>Update</span>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #templateViewMedication>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-eye mr-1"></i>View Medicine</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-12">
                <div class="overflow-auto">
                    <table class="table table-sm table-bordered table-centered table-striped">
                        <thead>
                            <tr class="table-primary">
                                <th colspan="4">
                                    <i class="mdi mdi-information mr-1"></i>Medicine Information
                                </th>
                            </tr>
                            <tr>
                                <th class="table-active">Medicine Name</th>
                                <td>
                                    <!--<span class="mr-1">:</span>-->
                                    <span [textContent]="selectedMedicine.productName"></span>
                                </td>
                                <th class="table-active">Medicine Type</th>
                                <td>
                                    <!--<span class="mr-1">:</span>-->
                                    <span class="text-capitalize" [textContent]="selectedMedicine.productTypeName"></span>
                                </td>
                            </tr>
                            <tr>
                                <th class="table-active">Created By</th>
                                <td>
                                    <!--<span class="mr-1">:</span>-->
                                    <span [textContent]="selectedMedicine.createdByName"></span>
                                </td>
                                <th class="table-active">Created Date</th>
                                <td>
                                    <!--<span class="mr-1">:</span>-->
                                    <span [textContent]="selectedMedicine.createdDate | date:'dd-MM-yyyy, hh:mm a'"></span>
                                </td>
                            </tr>
                            <tr>
                                <th class="table-active">Medicine Start Date</th>
                                <td>
                                    <!--<span class="mr-1">:</span>-->
                                    <span [textContent]="selectedMedicine.startDate | date:'dd-MM-yyyy'"></span>
                                </td>
                                <th class="table-active">Medicine End Date</th>
                                <td>
                                    <!--<span class="mr-1">:</span>-->
                                    <span [textContent]="selectedMedicine.endDate | date:'dd-MM-yyyy'"></span>
                                </td>
                            </tr>
                            <tr>
                                <th class="table-active">Unit</th>
                                <td>
                                    <!--<span class="mr-1">:</span>-->
                                    <span [textContent]="selectedMedicine.unit"></span>
                                </td>
                                <th class="table-active">Duration</th>
                                <td>
                                    <!--<span class="mr-1">:</span>-->
                                    <span>
                                        <span [textContent]="selectedMedicine.duration" class="mr-1"></span>
                                        <ng-container [ngSwitch]="selectedMedicine.medicationDurationTypeId">
                                            <span *ngSwitchCase="1">Day<span *ngIf="selectedMedicine.duration > 1">s</span></span>
                                            <span *ngSwitchCase="2">Week<span *ngIf="selectedMedicine.duration > 1">s</span></span>
                                            <span *ngSwitchCase="3">Month<span *ngIf="selectedMedicine.duration > 1">s</span></span>
                                        </ng-container>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th class="table-active">Duration Left (in days)</th>
                                <td>
                                    <!--<span class="mr-1">:</span>-->
                                    <span *ngIf="selectedMedicine.activeStatus === activeStatus.Active">
                                        <span [textContent]="selectedMedicine.leftDays"></span> Day<span *ngIf="selectedMedicine.leftDays > 1">s</span> Left
                                    </span>
                                    <span *ngIf="selectedMedicine.activeStatus === activeStatus.Stopped || selectedMedicine.activeStatus === activeStatus.Completed">
                                        <span [textContent]="selectedMedicine.leftDays"></span> Day<span *ngIf="selectedMedicine.leftDays > 1">s</span> Left
                                    </span>
                                    <span *ngIf="selectedMedicine.activeStatus === activeStatus.NotStarted">
                                        <span [textContent]="selectedMedicine.duration" class="mr-1"></span>
                                        <ng-container [ngSwitch]="selectedMedicine.medicationDurationTypeId">
                                            <span *ngSwitchCase="1">Day<span *ngIf="selectedMedicine.duration > 1">s</span></span>
                                            <span *ngSwitchCase="2">Week<span *ngIf="selectedMedicine.duration > 1">s</span></span>
                                            <span *ngSwitchCase="3">Month<span *ngIf="selectedMedicine.duration > 1">s</span></span>
                                        </ng-container>
                                    </span>
                                </td>
                                <th class="table-active">Status</th>
                                <td>
                                    <!--<span class="mr-1">:</span>-->
                                    <span>
                                        <span *ngIf="selectedMedicine.activeStatus === activeStatus.Active">
                                            <span class="badge badge-success mr-1">Active</span>
                                        </span>
                                        <span *ngIf="selectedMedicine.activeStatus === activeStatus.Completed">
                                            <span class="badge badge-primary mr-1">Completed</span>
                                        </span>
                                        <span *ngIf="selectedMedicine.activeStatus === activeStatus.Stopped">
                                            <span class="badge badge-danger mr-1">Stopped</span>
                                        </span>
                                        <span *ngIf="selectedMedicine.activeStatus === activeStatus.NotStarted">
                                            <span class="badge badge-info mr-1">Not Started</span>
                                        </span>
                                    </span>
                                </td>
                            </tr>
                            <tr *ngIf="selectedMedicine.activeStatus === activeStatus.Stopped">
                                <th class="table-active">Stopped By</th>
                                <td>
                                    <!--<span class="mr-1">:</span>-->
                                    <span [textContent]="selectedMedicine.modifiedByName"></span>
                                </td>
                                <th class="table-active">Stopped Date</th>
                                <td>
                                    <!--<span class="mr-1">:</span>-->
                                    <span [textContent]="selectedMedicine.modifiedDate | date: 'dd-MM-yyyy, h:mm:ss a'"></span>
                                </td>
                            </tr>
                            <tr *ngIf="selectedMedicine.activeStatus === activeStatus.Stopped">
                                <th class="table-active">Stopped Reason</th>
                                <td colspan="3">
                                    <!--<span class="mr-1">:</span>-->
                                    <span [textContent]="selectedMedicine.stopReason"></span>
                                </td>
                            </tr>
                            <tr *ngFor="let frequency of selectedMedicine.medicationFrequency">
                                <th class="table-active">
                                    <span *ngIf="frequency.medicationInstructionDeepTypeId === 1">Morning</span>
                                    <span *ngIf="frequency.medicationInstructionDeepTypeId === 2">Afternoon</span>
                                    <span *ngIf="frequency.medicationInstructionDeepTypeId === 3">Night</span>
                                    <span *ngIf="frequency.medicationInstructionDeepTypeId === 4">TimeSlots</span>Usage
                                </th>
                                <td colspan="3">
                                    <!--<span class="mr-1">:</span>-->
                                    <span [textContent]="frequency.medicationInstructionName"></span>
                                </td>
                            </tr>
                            <tr *ngIf="selectedMedicine.instructions">
                                <th class="table-active">Instructions</th>
                                <td colspan="3">
                                    <!--<span class="mr-1">:</span>-->
                                    <span [textContent]="selectedMedicine.instructions"></span>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
        <div class="row mt-3" *ngIf="selectedMedicine.activeStatus !== activeStatus.NotStarted">
            <div class="col-sm-12">
                <div class="overflow-auto">
                    <table class="table table-sm table-bordered table-centered table-striped">
                        <thead>
                            <tr class="table-primary">
                                <th colspan="6">
                                    <i class="mdi mdi-information mr-1"></i>Medicine Usage Timeline
                                </th>
                            </tr>
                            <tr>
                                <th>Date</th>
                                <ng-container *ngFor="let frequency of selectedMedicine.medicationFrequency">
                                    <th *ngIf="frequency.medicationInstructionDeepTypeId === 1">Morning</th>
                                    <th *ngIf="frequency.medicationInstructionDeepTypeId === 2">Afternoon</th>
                                    <th *ngIf="frequency.medicationInstructionDeepTypeId === 3">Night</th>
                                    <th *ngIf="frequency.medicationInstructionDeepTypeId === 4">TimeSlots</th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="loadingTimeline">
                                <tr>
                                    <td colspan="4">
                                        <span class="mr-1 spinner-grow spinner-grow-sm"></span> Please wait while loading medicine timeline ...
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="medicationTimeline.length && !loadingTimeline">
                                <tr *ngFor="let item of medicationTimeline">
                                    <td [textContent]="item.date | date: 'd, MMMM, y'"></td>
                                    <td *ngFor="let frequency of selectedMedicine.medicationFrequency ;let i=index">
                                        <ng-container *ngIf="frequency.medicationInstructionDeepTypeId === 1">
                                            <!--<span *ngIf="frequency.status === null && item.progressReportMedicationId" class="font-weight-bold text-danger white-space-nowrap"><i class="mdi mdi-flash blink"></i>Pending</span>
                                            <span *ngIf="frequency.status === false && item.progressReportMedicationId" class="font-weight-bold text-danger white-space-nowrap"><i class="mdi mdi-close"></i>Missed</span>
                                            <span *ngIf="frequency.status" class="font-weight-bold text-success white-space-nowrap"><i class="mdi mdi-check"></i>Taken</span>-->
                                            <span *ngIf="item.morningStatus === timelineStatus.Pending" class="font-weight-bold text-danger"><i class="mdi mdi-flash blink"></i>Pending</span>
                                            <span *ngIf="item.morningStatus === timelineStatus.Taken" class="font-weight-bold text-success"><i class="mdi mdi-check"></i>Taken   By {{frequency.takenByName}}</span>
                                            <span *ngIf="item.takenByDate !=null" class="font-weight-bold text-success"><i class="mdi mdi-check"></i>Taken   By Date {{frequency.takenByDate |date:'dd/MM/yyyy h:mm a'}}</span>
                                            <span *ngIf="item.morningStatus === timelineStatus.Missed" class="font-weight-bold text-warning"><i class="mdi mdi-close"></i>Missed</span>
                                        </ng-container>
                                        <ng-container *ngIf="frequency.medicationInstructionDeepTypeId === 2">
                                            <!--<span *ngIf="frequency.status === null && item.progressReportMedicationId" class="font-weight-bold text-danger white-space-nowrap"><i class="mdi mdi-flash blink"></i>Pending</span>
    <span *ngIf="frequency.status === false && item.progressReportMedicationId" class="font-weight-bold text-danger white-space-nowrap"><i class="mdi mdi-close"></i>Missed</span>
    <span *ngIf="frequency.status" class="font-weight-bold text-success white-space-nowrap"><i class="mdi mdi-check"></i>Taken</span>-->
                                            <span *ngIf="item.afternoonStatus === timelineStatus.Pending" class="font-weight-bold text-danger"><i class="mdi mdi-flash blink"></i>Pending</span>
                                            <span *ngIf="item.afternoonStatus === timelineStatus.Taken" class="font-weight-bold text-success"><i class="mdi mdi-check"></i>Taken  By {{frequency.takenByName}}</span>
                                            <span *ngIf="frequency.takenByDate !=null" class="font-weight-bold text-success"><i class="mdi mdi-check"></i>Taken   By Date {{frequency.takenByDate |date:'dd/MM/yyyy h:mm a'}}</span>

                                            <span *ngIf="item.afternoonStatus === timelineStatus.Missed" class="font-weight-bold text-warning"><i class="mdi mdi-close"></i>Missed</span>
                                        </ng-container>
                                        <ng-container *ngIf="frequency.medicationInstructionDeepTypeId === 3">
                                            <span *ngIf="item.nightStatus === timelineStatus.Pending" class="font-weight-bold text-danger"><i class="mdi mdi-flash blink"></i>Pending</span>
                                            <span *ngIf="item.nightStatus === timelineStatus.Taken" class="font-weight-bold text-success"><i class="mdi mdi-check"></i>Taken  By {{frequency.takenByName}}</span>
                                            <span *ngIf="frequency.takenByDate !=null" class="font-weight-bold text-success"><i class="mdi mdi-check"></i>Taken   By Date {{frequency.takenByDate |date:'dd/MM/yyyy h:mm a'}}</span>

                                            <span *ngIf="item.nightStatus === timelineStatus.Missed" class="font-weight-bold text-warning"><i class="mdi mdi-close"></i>Missed</span>
                                        </ng-container>
                                        <ng-container *ngIf="frequency.medicationInstructionDeepTypeId === 4">
                                            <ng-container *ngFor="let g of  item.slotArray.slice(i,i+1)">
                                                <span *ngIf="g.timeslotStatus === timelineStatus.Pending" class="font-weight-bold text-danger"><i class="mdi mdi-flash blink"></i>Pending</span>
                                                <span *ngIf="g.timeslotStatus === timelineStatus.Taken" class="font-weight-bold text-success"><i class="mdi mdi-check"></i>Taken  By {{frequency.takenByName}}</span>
                                                <span *ngIf="frequency.takenByDate !=null" class="font-weight-bold text-success"><i class="mdi mdi-check"></i>Taken   By Date {{frequency.takenByDate |date:'dd/MM/yyyy h:mm a'}}</span>

                                                <span *ngIf="g.timeslotStatus === timelineStatus.Missed" class="font-weight-bold text-warning"><i class="mdi mdi-close"></i>Missed</span>
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                </tr>
                            </ng-container>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
    </div>
</ng-template>




<ng-template #templatehighriskview>
    <div >
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-cash mr-1"></i>High Risk</h4>
            <div>
                <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onhighriskCloseModal()">×</button>
            </div>

        </div>
        <div class="row mt-3 pl-2 pr-2">
            <div class="col-12  form-group">
                <input type="text" class="form-control" [(ngModel)]="isHighRisk" />
            </div>
            <div class="col-12  justify-content-end d-flex">
                <button (click)="ontake(progressmedication,type,id)" class="btn btn-primary">Submit</button>
            </div>
        </div>
    </div>

</ng-template>