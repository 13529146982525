import { Component, OnDestroy, OnInit } from "@angular/core";
import { ApiResources } from "@shared/helpers";
import { AppData, HttpService, NotifyService } from "@shared/services";
import { Page, IUserAccount } from "@shared/models";
import { finalize, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { BedManagement } from "@shared/entities";
import { HttpErrorResponse } from "@angular/common/http";


@Component({
    templateUrl: "./bed-availability.html",
})
export class BedAvailabilityPage implements OnInit, OnDestroy {
    page: Page;
    loading: boolean;
    records: Array<BedManagement>;
    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly router: Router,
        private readonly notifyService: NotifyService,
    ) {
        this.loading = true;
        this.page = new Page();
        this.records = new Array<BedManagement>;
    }

    fetchBedAvailability() {
        this.loading = true;
        const request = {
            locationId:this.page.userAccount.locationId
        }
        this.httpService
            .post<Array<BedManagement>>(ApiResources.getURI(ApiResources.bed.base, ApiResources.bed.bedAvailability), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<BedManagement>) => {
                    if (response.length > 0) {
                        this.records = response;
                    }
                    else {
                        this.records = Array<BedManagement>();
                    }                 
                },
            );
    }


    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchBedAvailability();
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }    
    onModifyStatus(item: BedManagement) {
        this.notifyService.confirm(`You want to update ${item.bedNumber} this bed ?`, () => {
            const request = {
                bedId: item.bedId,
                bedNumber:item.bedNumber,
                bedStatusId: item.bedStatusId,
                bedStatusName: item.bedStatusName,

            };
            var url = ApiResources.getURI(ApiResources.bed.base, ApiResources.bed.modifyBedStatus);
            this.httpService.post(url, request)
                .subscribe(
                    (response: number) => {
                        if (response == 0) {
                            this.notifyService.successToast(`${request["bedNumber"]} updated successfully.`);
                            this.fetchBedAvailability();
                        }
                    },
                    (error: HttpErrorResponse) => {
                        this.notifyService.errorToast(error.error);
                    }
                );
        });
    }
}