import { Component, OnDestroy, OnInit, ViewEncapsulation, Input, AfterViewInit, HostListener } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ApiResources } from "@shared/helpers";
import { Appointment, PatientDocument, PreviousAppointment, PatientMedicationHeader, ImageReports, ObEncounterFullTranscript } from "@shared/entities";
import { Page, Pagination, IResource, IUserAccount, PatientProfile } from "@shared/models";
import { AppConfig, AppData, HttpService, AppointmentToggleService, TimelineToggleService, IconService, PrintOptionService, ResourceService, } from "@shared/services";
import { finalize, takeUntil } from "rxjs/operators";

import { InternalMedicine } from "@admin/internal-medicine/internal-medicine.namespace";
import { OrderPrescription } from "@shared/entities";
import { OB } from "@admin/full-transcript/pages/ob-report/ob.namespace";
import { ObEncounter } from "@shared/models/ob-encounter.model";
import { LabBillHeaderModel, LabParameterHeader } from "@admin/labs/pages/models";



class OrderPrescriptionModel {
    appoitmentId: number;
    orderPrescription: string;

}

@Component({
    templateUrl: "./discharge-summary-report.html",
    selector: "discharge-summaryreport",
    encapsulation: ViewEncapsulation.None
})
export class OBDischargeSummaryReportsPage implements OnInit, OnDestroy, AfterViewInit {
    @Input() encryptedPatientId: string;
    encryptedAppointmentId: string;
    loadingVitals: boolean;
    //filters: Filters;
    routingValue: string;
    page: Page;
    modalRef: NgbModalRef;
    modalViewRef: NgbModalRef;
    loadingCategories: boolean;
    categories: Array<IResource>;
    section: string;
    pagination: Pagination;
    loading: boolean;
    documents: Array<PatientDocument>;
    patientDocumentId: number;
    document: PatientDocument;
    appointmentId: string;
    isAdmission: boolean;
    providerId: number;
    patientId: number;
    appointments: Array<Appointment>;
    oldAppointment: PreviousAppointment;
    fullTranscript: ObEncounterFullTranscript;
    loadingDocument: boolean;
    documentError: boolean;
    showPrevious: boolean;
    showNext: boolean;
    result: number;
    patient: PatientProfile;
    appointment: Appointment;
    obEncounter: ObEncounter;
    submitting: boolean;
    locationId: number;
    locations: Array<IResource>;
    currentLocation: string;
    obHistory: Array<OB.ObBirthHistory>;
    problemList: Array<InternalMedicine.Problem>;
    isPastMedicalHistory = false;
    procedureSurgery: Array<OB.ProcedureSurgery>;
    isProcedureSurgery = false;
    isFamilyHistory = false;
    familyHistory: Array<OB.ObFamilyHistory>;
    dischargeSummary: Array<OB.DischargeSummary>;
    admissionDetails: OB.AdmssionSlip;
    useraccount: Array<IUserAccount>;
    isAppointmentClosed: boolean;
    @Input() printId: string;
    @Input() printadmission: boolean;

    records: Array<PatientMedicationHeader>;
    iconBasics: ImageReports;
    flag = true;
    @Input() isPrintLogo: boolean;
    labs: Array<PatientMedicationHeader>;
    bookingHeader: Array<LabBillHeaderModel>;
    husbandName: string;
    referralOrder: Array<OB.OrderReferral>;
    antinantalRisk: OB.AntinantalRisk;
    ordersPrescription: OB.OrdersPrescription;
    measure: OB.Measure;
    postNatalVisit: OB.PostnantalVisit;
    ancCard: OB.AncCards;
    ancCardGeneration: OB.AncCardGeneration;
    allergies: OB.Allergies;
    medicationComment: InternalMedicine.MedicationComment;
    scanAppointmentDetails: OB.ScanDetails;
    lscs: OB.LSCS;
    birthHistory: OB.ObBirthHistory
    nursedetails: OB.NursingAssessment;
    orders: Array<OrderPrescription>;
    date: Date;
    dignosis: any;
    madication: Array<OB.MotherWithEpilepsy>;
    signature: string;
    recordsResults: Array<LabParameterHeader>;
    riskFactors: OB.AntinantalRisk;
    diseaseName: any
    yearOfDiagnosis: any
    icd: any
    status: any
    treatmentHistory: any;
    printName: string;
    regionalLanguage: string;
    isFooter: boolean;
    lodingLocation: boolean;
    location: any
    constructor(
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly httpService: HttpService,
        private readonly toggleService: AppointmentToggleService,
        public timelineService: TimelineToggleService,
        private readonly printOptionService: PrintOptionService,
        private readonly iconService: IconService,
        private readonly resourceService: ResourceService,



    ) {
        this.page = new Page();
        this.pagination = new Pagination();
        this.appointments = [];
        this.records = new Array<PatientMedicationHeader>();
        //   this.filters = new Filters();
        this.labs = new Array<PatientMedicationHeader>();
        this.bookingHeader = new Array<LabBillHeaderModel>();
        this.date = new Date();
        this.recordsResults = new Array<LabParameterHeader>();
    }






    private findDashboard() {
        const request = { encryptedAppointmentId: this.encryptedAppointmentId };
        request["isAdmission"] = this.isAdmission;

        this.httpService.post<ObEncounterFullTranscript>(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.findEncounterData), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: ObEncounterFullTranscript) => {
                this.fullTranscript = response;
                this.obEncounter = response["dashboard"];
                if (this.obEncounter && this.obEncounter.obEncounterId) {
                    this.ancCardGeneration = this.obEncounter.ancCardGeneration ? JSON.parse(this.obEncounter.ancCardGeneration) : null
                    this.measure = this.obEncounter.measure ? JSON.parse(this.obEncounter.measure) : null
                    this.ancCard = this.obEncounter.ancCard ? JSON.parse(this.obEncounter.ancCard) : null
                    this.allergies = this.obEncounter.allergies ? JSON.parse(this.obEncounter.allergies) : null
                    this.problemList = this.obEncounter.problemList ? JSON.parse(this.obEncounter.problemList) : null
                    this.obHistory = this.obEncounter.birthHistory ? JSON.parse(this.obEncounter.birthHistory) : null
                    this.obHistory = this.obHistory?.reverse();
                    this.ordersPrescription = this.obEncounter.orderPrescription ? JSON.parse(this.obEncounter.orderPrescription) : null

                    this.procedureSurgery = this.obEncounter.surgeries ? JSON.parse(this.obEncounter.surgeries) : null;
                    if (this.obEncounter.surgeries && this.procedureSurgery.length == 0) {
                        this.isProcedureSurgery = true;
                    }
                    this.familyHistory = this.obEncounter.familyHistory ? JSON.parse(this.obEncounter.familyHistory) : null;
                    if (this.obEncounter.familyHistory && this.familyHistory.length == 0) {
                        this.isFamilyHistory = true;
                    }
                    this.madication = this.obEncounter.motherWithEpilepsy ? JSON.parse(this.obEncounter.motherWithEpilepsy) : null;
                    this.dischargeSummary = this.obEncounter.dischargeSummary ? JSON.parse(this.obEncounter.dischargeSummary) : null
                    this.allergies = this.obEncounter.allergies ? JSON.parse(this.obEncounter.allergies) : null
                    this.lscs = this.obEncounter.lscs ? JSON.parse(this.obEncounter.lscs) : null
                    this.admissionDetails = this.obEncounter.admissionSlip ? JSON.parse(this.obEncounter.admissionSlip) : null
                    this.birthHistory = this.obEncounter.birthHistory ? JSON.parse(this.obEncounter.birthHistory) : null
                    this.nursedetails = this.obEncounter.nurseAssessment ? JSON.parse(this.obEncounter.nurseAssessment) : null
                    this.riskFactors = this.obEncounter.antinantalRisk ? JSON.parse(this.obEncounter.antinantalRisk) : null

                }
                if (this.ancCard || this.riskFactors) {
                    var dignosis = "G" + " " + this.ancCard.gravida + " " + " P" + " " + this.ancCard.para + " " + " L" + " " + this.ancCard.living + " " + " A" + " " + this.ancCard.abortion + " " + "," +
                        (" " + this.ancCard.ga + "WEEKS OF GESTATION")
                    if (this.riskFactors != null) {
                        var risk = "";
                        if (this.riskFactors.characteristics.length > 0) {
                            risk = risk + this.riskFactors.characteristics;
                        }
                        if (this.riskFactors.previousPregency != null) {
                            risk = risk + this.riskFactors.previousPregency;
                        }
                        if (this.riskFactors.covidInfection != null) {
                            risk = risk + this.riskFactors.covidInfection;
                        }
                        if (this.riskFactors.maternalMedicalHistory != null) {
                            risk = risk + this.riskFactors.maternalMedicalHistory;
                        }
                        if (this.riskFactors.uterineRiskFactor != null) {
                            risk = risk + this.riskFactors.uterineRiskFactor;
                        }
                        if (this.riskFactors.currentPragnancy != null) {
                            risk = risk + this.riskFactors.currentPragnancy;
                        }
                        if (this.riskFactors.fetusComplications != null) {
                            risk = risk + this.riskFactors.fetusComplications;
                        }
                        if (this.riskFactors.details != null) {
                            risk = risk + this.riskFactors.details;
                        }
                        if (this.riskFactors.funtalFetaus != null) {
                            risk = risk + this.riskFactors.funtalFetaus;
                        }
                        if (this.riskFactors.highRisk != null) {
                            risk = risk + this.riskFactors.highRisk;
                        }
                        if (this.riskFactors.intermediateRisk != null) {
                            risk = risk + this.riskFactors.intermediateRisk;
                        }
                        if (this.riskFactors.maternalmedical != null) {
                            risk = risk + this.riskFactors.maternalmedical;
                        }
                        if (this.riskFactors.latePragnancy != null) {
                            risk = risk + this.riskFactors.latePragnancy;
                        }
                        if (this.riskFactors.lowRisk != null) {
                            risk = risk + this.riskFactors.lowRisk;
                        }
                        if (this.riskFactors.meternall != null) {
                            risk = risk + this.riskFactors.meternall;
                        }
                        if (this.riskFactors.pregnancyComplications != null) {
                            risk = risk + this.riskFactors.pregnancyComplications;
                        }
                        if (this.riskFactors.vteOtherComplaints != null) {
                            risk = risk + this.riskFactors.vteOtherComplaints;
                        }
                        if (this.riskFactors.otherComplaints != null) {
                            risk = risk + this.riskFactors.otherComplaints;
                        }
                        if (this.riskFactors.comments != null) {
                            risk = risk + this.riskFactors.comments;
                        }

                    }
                    this.dignosis = (dignosis && risk) ? dignosis + risk : dignosis ? dignosis : risk ? risk : '';


                }
                if (this.problemList) {
                    this.problemList = this.problemList["problems"]

                } if (this.problemList.length == 0) {
                    this.isPastMedicalHistory = true;
                }
            });

    }

    private findOrderPrescription() {
        let PatientId: number = this.patientId;
        this.httpService.get(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchOrderPrescription), { patientId: PatientId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Array<OrderPrescriptionModel>) => {
                if (response.length > 0) {
                    response.forEach((item: OrderPrescriptionModel) => {
                        var jsonParsed = JSON.parse(item.orderPrescription);
                        this.orders.push(jsonParsed)
                    })
                }
            });
    }
    private findPatientDetails(id: string) {
        this.page.loading = true;
        this.httpService.post<PatientProfile>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.profile), { encryptedPatientId: id })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: PatientProfile) => {
                this.patient = response;
                var detaills = this.patient.relativeDetails[0];
                if (detaills && detaills.relation == "Husband") {
                    this.husbandName = this.patient.relativeDetails[0].fullName
                }


            });
    }
    private findAncCard(id: number) {
        const request = {
            patientId: id,
            appointmentId: this.appointment.appointmentId,
            isAdmission: this.isAdmission
        }
        this.httpService.get(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchANC), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: OB.AncCardGeneration) => {
                if (response) {
                    this.ancCardGeneration = response;
                    this.husbandName = this.ancCardGeneration.husbandName;
                }
            });
    }

    private fetchMedication() {
        this.loading = true;
        const request = {
            encryptedAppointmentId: this.appointmentId,
            isAdmission: this.isAdmission
        }

        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                this.records = response;
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });

    }

    gotoPrescription(appointmentId: string) {
        this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/prescription/${appointmentId}`, "_blank"); });
    }





    private findAppointment(appointmentId: string) {
        const request = {
            encryptedAppointmentId: appointmentId
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointment = response;
                this.encryptedPatientId = response.encryptedPatientId;
                this.findAncCard(this.appointment.patientId);
                this.fetchMedication();
                this.findDashboard();
                this.fetchLabs();
                this.findPatientDetails(response.encryptedPatientId);
                this.findOrderPrescription();
                this.fetchLabsResult(this.appointment.patientId);
            });

    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {

                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    this.regionalLanguage = this.page.userAccount.regionalLanguage;
                    this.currentLocation = this.page.userAccount.locationName;
                    this.printName = this.page.userAccount.fullName;
                    this.isAdmission = url.split("/")[4] === "a";
                    this.appointmentId = decodeURIComponent(url.split("/")[3]);
                    this.locationId = this.page.userAccount.locationId;
                    this.encryptedAppointmentId = this.appointmentId;
                    this.encryptedAppointmentId = this.printId;
                    this.appointmentId = this.printId;
                    this.isAdmission = this.printadmission;
                    this.routingValue = url.split("/")[2];
                    this.findAppointment(this.appointmentId);
                    this.fetchLocation()
                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((params: Params) => {
                            const section = params["section"];
                            this.section = section ? section : undefined;
                        });
                } else {
                    this.page.userAccount = undefined;
                }
                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                this.iconService.getIconImage((is) => { this.iconBasics = is; });

            });
    }
    private fetchLocation() {
        this.lodingLocation = true;
        this.resourceService.locations()
            .pipe(finalize(() => { this.lodingLocation = false }))
            .subscribe((response: Array<IResource>) => {
                this.locations = response;
                this.location = this.locations.find(s => s.id == this.page.userAccount.locationId);

            });
    }
    ngAfterViewInit() {
        this.paddingTop();
    }
    paddingTop() {
        if (document.getElementById("header") != null && document.getElementById("obprintContainer") != null) {
            var paddingTopValue = document.getElementById("header").offsetHeight;
            var content = document.getElementById("obprintContainer");
            content.setAttribute('style', `padding-top:${paddingTopValue}px`);
        }
    }
    private fetchLabs() {
        this.loading = true;
        const request = {
            appointmentId: this.appointment.appointmentId,
            isAdmission: this.isAdmission
        }
        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetchLabs), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                if (response && response.length > 0) {
                    this.bookingHeader = response[0].labs;
                }
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });
    }
    @HostListener('window:load')
    onLoad() {
        setTimeout(() => { this.paddingTop(); }, 1500)
    }
    @HostListener('window:hashchange')
    onhashchange() {
        setTimeout(() => { this.paddingTop(); }, 500)
    }
    @HostListener('window:resize')
    onResize() {
        this.paddingTop();
    }



    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }

    appointmentToggle() {
        this.toggleService.toggle(true);
    }

    timelineToggle() {
        this.timelineService.toggle();
    }
    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }

    onChangePrintType(showLogo: boolean) {
        this.isPrintLogo = showLogo;
    }
    private fetchLabsResult(id: number) {
        const request = { patientId: id };
        this.httpService.get(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchAllLabResults), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Array<LabParameterHeader>) => {
                if (response && response.length > 0) {
                    this.recordsResults = response
                }
            });
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
}