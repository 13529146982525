export class NightAuditSummury {
    locationId: number;
    locationName: string;
    headName: string;
    subHeadName: string;
    creditAmount: number;
    debitAmount: number;
    creditDiscount: number;
    debitDiscount: number;
    advanceCreditAmount: number;
    advanceDebitAmount: number;
}