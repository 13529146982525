﻿<div class="content top1">
    <div class="container-fluid">
        <div>
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">
                        <ng-container >
                            <button type="button"
                                    *ngIf="!loading && dischargeAlerts && dischargeAlerts.length"
                                    class="btn btn-primary btn-sm"
                                    (click)="onOpenModel(template)">
                                <i class="fe-plus mr-1"></i> Add Discharge Alerts
                            </button>
                        </ng-container>
                    </div>
                    <h4 class="page-title">Discharge Alerts</h4>
                </div>
            </div>
        </div>

    

        <div class="col-12 p-0">
            <div class="card-box p-0">
                <div class="row p-2" *ngIf="loading">
                    <div class="col-12">
                        <div class="d-flex align-items-center">
                            <span class="spinner-grow text-warning"
                                  role="status"
                                  aria-hidden="true"></span>
                            <span class="ml-2">Please wait while loading ...</span>
                        </div>
                    </div>
                </div>
                <div class="row p-2"
                     *ngIf="!loading && (!dischargeAlerts || !dischargeAlerts.length)">
                    <div class="col-12 mh-300">
                        <div class="no-data">
                            <img src="assets/images/no-data.png" alt="No data" />
                            <p class="sub-title">There is no data to show you right now.</p>
                            <button type="button"
                                    class="btn btn-primary btn-sm"
                                    (click)="onOpenModel(template)">
                                <i class="fe-plus mr-1"></i> Add Discharge Alerts
                            </button>
                        </div>
                    </div>
                </div>

                <div class="overflow-auto"
                     *ngIf="!loading && dischargeAlerts && dischargeAlerts.length > 0">
                    <table class="table table-centered table-sm table-striped table-borderless mb-0">
                        <thead>
                            <tr>
                                <th>Priority</th>
                                <th>Department</th>
                                <th>Created By</th>
                                <th>Is Active</th>
                                <th>Notify On Records</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of dischargeAlerts;index as i" [class.text-muted]="item.isSkip" [class.disabled]="item.priority == 0">
                                <td> 
                                    <div *ngIf="!item.isSkip && item.priority == 0" class="text-muted">
                                        <i class="mdi mdi-minus text-danger"></i>
                                        <span [textContent]="item.priority" class="mx-1"></span>
                                        <i class="mdi mdi-plus text-primary"> </i>
                                    </div>
                                    <div *ngIf="item.isSkip">
                                        <h3 class="text-muted">- - -</h3>
                                    </div>
                                    <div *ngIf="!item.isSkip && item.priority>0">
                                        <a (click)="onChangePriority(false, item)"> <i class="mdi mdi-minus text-danger"></i></a>
                                        <span [textContent]="item.priority" class="mx-1"></span>
                                        <a (click)="onChangePriority(true, item)"><i class="mdi mdi-plus text-primary"> </i></a>
                                    </div>
                                </td>
                                <td [textContent]="item.roleDepartmentName || '---'"></td>
                                <td>
                                    <div>
                                        <span class="mb-0" [textContent]="item.createdByName || '---'"></span>
                                        <span class="badge badge-outline-success" [textContent]="item.createdByRole"></span>
                                    </div>
                                    <span class="small" [textContent]="(item.createdDate | date:'dd-MM-yyyy, hh:mm a') || '---'"></span>
                                </td>
                                <td>
                                    <span class="mr-1">
                                        <span *ngIf="item.isSkip">No</span>
                                        <span *ngIf="!item.isSkip">Yes</span>
                                    </span>
                                    <a href="javascript:;" class="text-danger" *ngIf="item.isSkip" placement="left" ngbTooltip="Include this department in discharge" (click)="changeStatus(item, 'IsSkip',i)">
                                        <i class="mdi mdi-pencil"></i>
                                    </a>
                                    <a href="javascript:;" class="text-success" *ngIf="!item.isSkip" placement="left" ngbTooltip="Skip this department from discharge" (click)="changeStatus(item, 'IsSkip',i)">
                                        <i class="mdi mdi-pencil"></i>
                                    </a>
                                </td>
                                <td>
                                    <span class="mr-1">
                                        <span *ngIf="item.isGetNotifiedOnRecords">No</span>
                                        <span *ngIf="!item.isGetNotifiedOnRecords">Yes</span>
                                    </span>
                                    <a href="javascript:;" class="text-danger" *ngIf="item.isGetNotifiedOnRecords" placement="left" ngbTooltip="Include this department only when this department data exists" (click)="changeNotifyStatus(item, 'IsGetNotifiedOnRecords' )">
                                        <i class="mdi mdi-pencil"></i>
                                    </a>
                                    <a href="javascript:;" class="text-success" *ngIf="!item.isGetNotifiedOnRecords" placement="left" ngbTooltip="Skip this department from discharge" (click)="changeNotifyStatus(item, 'IsGetNotifiedOnRecords')" >
                                        <i class="mdi mdi-pencil"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button type="button" class="btn btn-light btn-sm" (click)="cancelPriorities()">Cancel</button>
                    <button type="submit"
                            [disabled]="submitting"
                            class="btn btn-primary btn-sm">
                        <span *ngIf="submitting">
                            <span class="spinner-border spinner-border-sm mr-1"
                                  role="status"
                                  aria-hidden="true"></span>
                            Please wait..
                        </span>
                        <span *ngIf="!submitting" (click)="onChangePriorities()" >Submit</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #template>
    <form [formGroup]="dischargeConfigurationForm" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h4 class="modal-title">
                <i class="mdi mdi-cube-send mr-1"></i>Add Dicharge Alerts
            </h4>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    (click)="onCloseModal();">
                ×
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="mb-1">Role Department <code>*</code></label>
                        <ng-select class="ng-select-sm text-uppercase"
                                   [items]="roleDepartment"
                                   [loading]="loadingRoleDepartment"
                                   bindLabel="value"
                                   bindValue="id"
                                   autocomplete="nope"
                                   placeholder="-Select-"
                                   formControlName="roleDepartmentId">
                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                <div class="ng-option disabled">
                                    Nothing found for '{{searchTerm}}'
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="form-group mb-0 mt-2">
                        <label class="mb-1">Priority</label>
                        <div class="form-group d-flex">
                             <input type="text" formControlName="priority" disabled readonly autocomplete="nope" class="form-control" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button"
                    class="btn btn-light btn-sm mr-1"
                    (click)="onCloseModal();">
                Cancel
            </button>
            <button type="submit"
                    [disabled]="submitting"
                    class="btn btn-primary btn-sm">
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting">Submit</span>
            </button>
        </div>
    </form>
</ng-template>
