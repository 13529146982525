export class DepartmentIndentReport {
    indentDate:Date;
    indentNo: number;
    status: string;
    createdDate?: Date;
    fromDate?: string;
    toDate?: string;
    approvedDate?: string;
    statusName?: string;
    indentNumber?: number;
    departmentName: string;
    createdByName: string;
    productName: string;
    requestedQuantity: number;
}





