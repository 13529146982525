import { Component, OnDestroy, OnInit, ChangeDetectorRef, NgZone } from "@angular/core";
@Component({
    templateUrl: "./post-operative-ward.html",
    styleUrls: ["./post-operative-ward.css"]
})

export class PostOperativeWardPage implements OnInit, OnDestroy {
    constructor() {

    }
    ngOnInit() {
    }

    ngOnDestroy() {
    }
}
