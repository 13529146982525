export class WarehouseIndentReport {
    indentDate:Date;
    indentNo: number;
    status: string;
    createdDate?: Date;
    fromDate?: string;
    toDate?: string;
    approvedDate?: string;
    statusName?: string;
    indentNumber?: number;
    wareHouseName: string;
    createdByName: string;
    productName: string;
    requestedQuantity: number;
}





