
<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">Scheduled Admission</h4>
                </div>
            </div>
        </div>


        <div class="col-12 p-0">
            <div class="card-box mb-1 p-1">
                <table class="table table-borderless table-sm mb-0">
                    <tr style="font-size: 12px" class="myFlex">
                        <td class="col-6 col-sm-3">
                            <div class="form-group mb-0">
                                <label class="mb-1">Admission Date </label>
                                <input type="date" [(ngModel)]="filters.options.admissionDate" [value]="filters.options.admissionDate" class="form-control">
                            </div>
                        </td>
                        <td class="col-6 col-sm-3">
                            <div class="form-group mb-0">
                                <label class="mb-1">Patient</label>
                                <ng-select class="ng-select-sm text-uppercase"
                                           [items]="filterPatient"
                                           [loading]="loading"
                                           [clearable]="true"
                                           bindLabel="value"
                                           bindValue="id"
                                           (search)="fetchPatientNameMobileUmrHelper($event)"
                                           placeholder="Enter patient name"
                                           (keydown.enter)="onApplyFilters()"
                                           [(ngModel)]="filters.options.patientId">
                                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                        <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                            No patient found for '{{searchTerm}}'
                                        </div>
                                        <div class="ng-option disabled" *ngIf="(!searchTerm || searchTerm.length < 2)">
                                            Search patient
                                        </div>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-i="index">
                                        <div class="media">
                                            <div class="media-body">
                                                <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                                <span class="text-muted d-block font-13" [textContent]="item.optionalText"></span>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </td>
                        <td class="col-6 col-sm-3">
                            <div class="form-group mb-0">
                                <label class="mb-1">Encounter</label>
                                <select class="form-control form-control-md"
                                        [(ngModel)]="filters.options.encounterType">
                                    <option [ngValue]="null">All</option>
                                    <option [ngValue]="'OBEncounter'">OBEncounter</option>
                                    <option [ngValue]="'GynEncounter'">GynEncounter</option>
                                </select>
                            </div>
                        </td>
                        <td class="col-6 col-sm-3 align-middle">
                            <div style="margin-top: 25px"
                                 class="d-flex justify-content-center justify-content-lg-start">
                                <button type="button"
                                        (click)="onApplyFilters()"
                                        class="btn btn-sm btn-primary rounded-pill">
                                    <i class="mdi mdi-magnify mr-1"></i>Search
                                </button>
                                <button type="button"
                                        (click)="onResetFilters()"
                                        class="btn btn-sm reset-btn rounded-pill ml-1">
                                    <i class="mdi mdi-backup-restore mr-1"></i>Reset
                                </button>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="row" *ngIf="loading">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading scheduled admission ...</span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!loading && (!scheduledAdmission || !scheduledAdmission.length)">
            <div class="col-12 mh-300">
                <div class="no-data">
                    <img src="assets/images/no-data.png" alt="No data" />
                    <p class="sub-title">There is no data to show you right now.</p>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="scheduledAdmission && scheduledAdmission.length >0">
            <div class="col-12">
                <div class="card mb-0">
                    <div class="card-body p-0">
                        <div class="overflow-auto">
                            <table class="table table-centered table-sm table-striped table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th>Patient Name</th>
                                        <th>Admission Date</th>
                                        <th>Admission Time</th>
                                        <th>Doctor Unit</th>
                                        <th>Reason for Admission</th>
                                        <th>Appointment Number</th>
                                        <th>Previous Consulted Doctor</th>
                                        <th>Encounter Type</th>
                                        <th class="text-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of scheduledAdmission;">
                                        <td  class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="item.name"></td>
                                        <td [textContent]="item.admissionDate | date:'dd-MM-yyyy'"></td>
                                        <td [textContent]="item.formattedTime"></td>
                                        <td [textContent]="item.doctorUnitName"></td>
                                        <td style="white-space: normal; word-break: break-all;" [textContent]="item.resonForAdmission"></td>
                                        <td [textContent]="item.appointmentNo"></td>
                                        <td calss="text-upper" [textContent]="item.fullName"></td>
                                        <td [textContent]="item.encounterName"></td>
                                        <td>
                                            <div class="d-flex align-items-center justify-content-end">
                                                <a href="javascript:;"
                                                   class="action-icon text-primary mr-1"
                                                   href="javascript:void(0)"
                                                   (click)="onClickConvertToInPtaient(item)"
                                                   placement="left"
                                                   ngbTooltip="Convert to In Patient"
                                                   *ngIf="!item.isAdmitted">
                                                    <i class="mdi mdi-account-convert-outline"></i>
                                                </a>
                                            </div>
                                            <span class="badge badge-success" [textContent]="'Admitted'" *ngIf="item.isAdmitted"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="10">
                                            <nav class="d-flex align-items-center justify-content-between p-0">
                                                <p class="mb-0 font-13">
                                                    <span class="text-dark">
                                                        Page
                                                        <span [textContent]="pagination.currentPage"></span>
                                                        of
                                                        <span [textContent]="pagination.totalPages"></span>
                                                    </span>
                                                    <span class="ml-1">
                                                        <span>
                                                            (Showing
                                                            <span [textContent]="pagination.pageIndex * pagination.pageSize - (pagination.pageSize - 1)"></span>
                                                            -
                                                            <span [textContent]="pagination.currentItems"></span>
                                                            of
                                                            <span [textContent]="pagination.totalItems"></span>
                                                            Scheduled Admission )
                                                        </span>
                                                    </span>
                                                </p>
                                                <ngb-pagination class="pagination justify-content-end"
                                                                [maxSize]="5"
                                                                [rotate]="true"
                                                                [ellipses]="true"
                                                                [(page)]="pagination.pageIndex"
                                                                [pageSize]="pagination.pageSize"
                                                                (pageChange)="onNextPage()"
                                                                [collectionSize]="pagination.totalItems">
                                                    <ng-template ngbPaginationPrevious>
                                                        <i class="fe-arrow-left"></i>
                                                    </ng-template>
                                                    <ng-template ngbPaginationNext>
                                                        <i class="fe-arrow-right"></i>
                                                    </ng-template>
                                                </ngb-pagination>
                                            </nav>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

