import { Component, OnDestroy, OnInit, ViewChild, ElementRef, ViewEncapsulation, Input } from "@angular/core";

import { takeUntil, finalize } from "rxjs/operators";
import { ActivatedRoute, Params, Router } from "@angular/router";
//import { ScrollToService } from "ng2-scroll-to-el";
import { ApiResources, } from "@shared/helpers";
import { EncounterValue, EncounterType, Appointment, PatientMedicationHeader, ImageReports, } from "@shared/entities";
import { Page } from "../../models/page.model";
import { GeneticSpecialityEncounterList } from "../../entities/geneticSpecialityList.entity";
import { OB } from "../../../areas/admin/full-transcript/pages/ob-report/ob.namespace";
import { AppData, EncounterCommunication, HttpService, IconService, PrintOptionService, appUrls } from "../../services";
import { IUserAccount } from "../../models/user-account.model";




class FilterOptions {
    encryptedAppointmentId: string = null;
}


class Filters {
    options: FilterOptions;
    applied: boolean;

    constructor() {
        this.init();
    }

    init() {
        this.options = new FilterOptions();
        this.applied = undefined;
    }
}


@Component({
    templateUrl: "./genetic-full-report.html",
    encapsulation: ViewEncapsulation.None,
    selector: "genetic-fullreport"

})
export class GeneticfullPrintReportPage implements OnInit, OnDestroy {
    page: Page
    roleId: number;
    routingValue: string;
    isAdmission: boolean;
    appointmentId: string;
    section: any;
    filters: Filters;
    geneticSpecialityEncounter: GeneticSpecialityEncounterList;
    geneticspeciality: GeneticSpecialityEncounterList;
    specialFeature: any;
    geneticVisit: any;
    outsideTest: any;
    reminder: string;
    orderPrescription: any;
    referralOrder: Array<OB.OrderReferral>;
    issubmit: any
    appointment: Appointment;
    demoGraphicData: any;
    loading: boolean;
    bookingHeader: any[];
    records: PatientMedicationHeader[];
    @Input() useExistingCss: boolean;
    @Input() isPrintLogo: boolean;
    iconBasics: ImageReports;
    geneticSummary: any;
    reminders: any;
    genetictemplate: any[];
    geneticSummarytemplate: any[];
    isFooter: boolean;
    telephoneTriageAssessmentdata: Array<OB.TelephoneTriageAssessment>;
    referralForm: Array<any>
    outSideTests: Array<any>

    @Input() printId: string;
    @Input() printadmission: boolean;
    constructor(

        private readonly appData: AppData,
        private readonly router: Router,
        private readonly encounterCommunication: EncounterCommunication,
        //  private readonly scrollService: ScrollToService,
        private readonly httpService: HttpService,
        private readonly route: ActivatedRoute,
        private readonly printOptionService: PrintOptionService,
        private readonly iconService: IconService,
    ) {
        this.page = new Page();
        this.telephoneTriageAssessmentdata = new Array<OB.TelephoneTriageAssessment>();

    }


    private SpecialFeatureSection: ElementRef;
    @ViewChild("SpecialFeatureSection", { static: false }) set SpecialFeatureSectionContent(content: ElementRef) {
        if (content) {
            this.SpecialFeatureSection = content;
            if (this.section && this.section === "SpecialFeatureSection") {
                //  this.scrollService.scrollTo(this.SpecialFeatureSection.nativeElement, 1500, -100);
            }
        }
    }
    private GeneticVisitSection: ElementRef;
    @ViewChild("GeneticVisitSection", { static: false }) set GeneticVisitSectionContent(content: ElementRef) {
        if (content) {
            this.GeneticVisitSection = content;
            if (this.section && this.section === "GeneticVisitSection") {
                //  this.scrollService.scrollTo(this.GeneticVisitSection.nativeElement, 1500, -100);
            }
        }
    }
    private OutsideTestSection: ElementRef;
    @ViewChild("OutsideTestSection", { static: false }) set OutsideTestSectionContent(content: ElementRef) {
        if (content) {
            this.OutsideTestSection = content;
            if (this.section && this.section === "OutsideTestSection") {
                //  this.scrollService.scrollTo(this.OutsideTestSection.nativeElement, 1500, -100);
            }
        }
    }
    private ReminderSection: ElementRef;
    @ViewChild("ReminderSection", { static: false }) set ReminderSectionContent(content: ElementRef) {
        if (content) {
            this.ReminderSection = content;
            if (this.section && this.section === "ReminderSection") {
                // this.scrollService.scrollTo(this.ReminderSection.nativeElement, 1500, -100);
            }
        }
    }
    private orderPrescriptionSection: ElementRef;
    @ViewChild("orderPrescriptionSection", { static: false }) set orderPrescriptionContent(content: ElementRef) {
        if (content) {
            this.orderPrescriptionSection = content;
            if (this.section && this.section === "orderPrescriptionSection") {
                //  this.scrollService.scrollTo(this.orderPrescriptionSection.nativeElement, 1500, -100);
            }
        }
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    this.routingValue = url.split("/")[url.split("/").length - 4];
                    this.appointmentId = decodeURIComponent(url.split("/")[url.split("/").length - 3]);
                    this.isAdmission = url.split("/")[url.split("/").length - 2] === "a";
                    this.appointmentId = this.printId
                    this.isAdmission = this.printadmission
                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((params: Params) => {
                            const section = params["section"];
                            this.section = section ? section : undefined;
                            if (this.appointmentId) {
                                this.findAppointment(this.appointmentId);
                                this.findDashboard();
                                this.fetchMedication();
                            } else {
                                this.router.navigateByUrl(appUrls.notFound);
                            }

                            const bypass = params["bypass"];
                            if (!bypass || bypass !== "t") {
                                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                                this.iconService.getIconImage((is) => { this.iconBasics = is; });
                            }

                        });

                } else {
                    this.page.userAccount = undefined;
                }

                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                this.iconService.getIconImage((is) => { this.iconBasics = is; });

            });

    }
    private findAppointment(appointmentId: string) {
        const request = {
            encryptedAppointmentId: appointmentId
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointment = response;
                this.fetchLabs();
                this.fetchalltelephonetriage(this.appointment.appointmentId, this.appointment.patientId)
                this.findreferralForm(this.appointment.patientId, this.appointment.appointmentId)
            });


    }
    ngOnDestroy() {

        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
    private findDashboard() {
        const request = { encryptedAppointmentId: this.appointmentId };
        this.httpService.post<GeneticSpecialityEncounterList>(ApiResources.getURI(ApiResources.geneticSpecialityEncounter.base, ApiResources.geneticSpecialityEncounter.find), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: GeneticSpecialityEncounterList) => {
                this.geneticspeciality = response;
                this.encounterCommunication.geneticspecialityEncounter({
                    encounterType: EncounterType.GeneticSpecialityEncounter,
                    value: this.geneticspeciality,
                    isAdmission: this.isAdmission,
                } as EncounterValue);

                if (this.geneticspeciality && this.geneticspeciality.geneticVisit) {
                    this.geneticVisit = this.geneticspeciality.geneticVisit ? JSON.parse(this.geneticspeciality.geneticVisit) : null;
                    this.genetictemplate = this.geneticVisit.templates ? this.geneticVisit.templates : null;

                }
                if (this.geneticspeciality && this.geneticspeciality.outsideTest) {
                    this.outSideTests = this.geneticspeciality.outsideTest ? JSON.parse(this.geneticspeciality.outsideTest) : null;
                }
                if (this.geneticspeciality && this.geneticspeciality.reminder) {
                    this.reminder = this.geneticspeciality.reminder ? (this.geneticspeciality.reminder) : null;
                }
                if (this.geneticspeciality && this.geneticspeciality.demoGraphicData) {
                    this.demoGraphicData = this.geneticspeciality.demoGraphicData ? JSON.parse(this.geneticspeciality.demoGraphicData) : null;

                }
                this.orderPrescription = this.geneticspeciality.orders ? JSON.parse(this.geneticspeciality.orders) : null;
                this.referralOrder = this.geneticspeciality.refferalOrder ? JSON.parse(this.geneticspeciality.refferalOrder) : null;
                this.specialFeature = this.geneticspeciality.specialFeature ? JSON.parse(this.geneticspeciality.specialFeature) : null;
                this.geneticSummary = this.geneticspeciality.geneticSummary ? JSON.parse(this.geneticspeciality.geneticSummary) : null;
                this.reminders = this.geneticspeciality.reminder ? JSON.parse(this.geneticspeciality.reminder) : null;

                if (this.geneticSummary.templates) {
                    this.geneticSummarytemplate = this.geneticSummary.templates ? this.geneticSummary.templates : null;

                }
            });

    }
    private findreferralForm(id: any, appointmentId: any) {
        this.loading = true;

        const request = Object.assign({ isAdmission: this.isAdmission, patientId: id, appointmentId: appointmentId });

        this.httpService.post(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchCrossConsultation), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<OB.ReferralForm>) => {
                if (response.length > 0) {
                    this.referralForm = response;
                }
            });
    }
    private fetchLabs() {
        this.loading = true;
        const request = {
            appointmentId: this.appointment.appointmentId,
            isAdmission: this.isAdmission
        }
        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetchLabs), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                if (response && response.length > 0) {
                    this.bookingHeader = response[0].labs;
                }
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });
    }

    private fetchMedication() {

        let intervalHit = setInterval(() => {
            let setting = localStorage.getItem("settings");
            if (setting) {
                clearInterval(intervalHit);
                this.loading = true;
                const request = {
                    encryptedAppointmentId: this.appointmentId
                }

                this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetch), request)
                    .pipe(takeUntil(this.page.unSubscribe))
                    .pipe(finalize(() => {
                        this.loading = false;
                    }))
                    .subscribe((response: Array<PatientMedicationHeader>) => {
                        this.records = response;
                    }, () => {
                        this.records = Array<PatientMedicationHeader>();
                    });
            }
        }, 10);
    }
    onChangePrintType(isShow: boolean) {
        this.isPrintLogo = isShow;
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }


    fetchalltelephonetriage(appointmentId: any, patientId: any) {
        const request = {
            patientId: patientId,
            appointmentId: appointmentId,
            isAdmission: this.isAdmission
        }

        this.httpService.post(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchtelephonetraige), request)
            .subscribe((result: any) => {
                if (result) {
                    var response = result;
                    response.forEach((x) => {
                        var data = JSON.parse(x.json)
                        this.telephoneTriageAssessmentdata.push(data)
                    })
                }


            })

    }
}