export * from "./admissions.page";
export * from "./pages/view/view.page";
export * from "./pages/transfer-request/transfer-request.page";
export * from "./pages/scheduler-admission/scheduler-admission.page";
export * from "./pages/charges-configuration/charges-configuration.page";
export * from "./pages/ip-information/ip-information.page";
export * from "./pages/used-package-compare/used-package-compare.page";
export * from "./pages/discharge-configuration/discharge-configuration.page";
export * from "./pages/bed-availability/bed-availability.page";
export * from "./pages/ip-change-room/ip-change-room.page";
export * from "./pages/insurance-management/insurance-management-page";
export * from "./pages/ot-outside/ot-outside.page";
export * from "./pages/post-operative-ward/post-operative-ward.page";
export * from "./pages/ot-inside/ot-inside.page";
export * from "./pages/obstetric-ward-handover/obstetric-ward-handover.page";
export * from "./pages/labour-ward-handover/labour-ward-handover.page";
export * from "./pages/labour-ward-cubicle/labour-ward-cubicle.page"