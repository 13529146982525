import { NgModule } from "@angular/core";
import { AvailableDaysComponent, FullScreenComponent, IdleComponent, NoDataComponent, ProgressBarModalComponent, SessionTimeoutComponent } from "@shared/components";
import { AllowNumericDirective, AlphaNumericOnlyDirective, AutoResizeDirective, AutofocusDirective, AvatarBgDirective, BlockCopyPasteDirective, DecimalsOnlyDirective, MenuButtonDirective, NumbersOnlyDirective, NumericDirective, PasswordDirective, PasteOnlyDirective, TextOnlyDirective, TitleOnlyDirective, TrimSpaceDirective, TrueFalseValueDirective } from "@shared/directives";
import { AgePipe, CounsellingType, FormatBytesPipe, FromArrayPipe, GenderPipe, InitialsPipe, MaritalStatusPipe, MinuteSecondsPipe, PackageType, PayType, SafeDomPipe, SafePipe, SearchPipe, SortFormArrayPipe, SortFormPipe, TitlePipe, ToArrayPipe, UTCToLocalPipe, TimespanToTimePipe } from "@shared/pipes";

const components = [SessionTimeoutComponent, NoDataComponent, FullScreenComponent, IdleComponent, ProgressBarModalComponent, AvailableDaysComponent];
const directives = [AlphaNumericOnlyDirective, AutofocusDirective, BlockCopyPasteDirective, DecimalsOnlyDirective, NumericDirective, AvatarBgDirective, NumbersOnlyDirective, PasswordDirective, TextOnlyDirective, TitleOnlyDirective, AutoResizeDirective, PasteOnlyDirective, TrueFalseValueDirective, TrimSpaceDirective, AllowNumericDirective, MenuButtonDirective];
const pipes = [SafePipe, InitialsPipe, TitlePipe, FormatBytesPipe, CounsellingType, PayType, PackageType, UTCToLocalPipe, SortFormArrayPipe, GenderPipe, MaritalStatusPipe, FromArrayPipe, ToArrayPipe, MinuteSecondsPipe, SearchPipe, SortFormPipe, AgePipe, SafeDomPipe, TimespanToTimePipe];

@NgModule({
    declarations: [components, directives, pipes],
    exports: [components, directives, pipes]
})
export class SharedLibsModule {
}