<div class="report bg-white">

    <div class="col-12 overflow-auto pt-2" *ngIf="isPrintLogo">
        <banner-setting [reportName]='"Appointment Invoive"'></banner-setting>
    </div>
    <div class="w-100 overflow-auto border-top2px border-dark border-bottom-2px">
        <div class="d-inline-block w-100 text-center mb-2">
            <div><h4 class="mt-2" [textContent]="'Final Bill of Supply - OPD Procedure Bill'"></h4></div>
            <div><h4 class="mt-1 font-weight-normal" [textContent]="'(Health Care Service - SAC)'"></h4></div>
        </div>
    </div>
    <div>
        <table class="bg-white border-bottom-2px border-dark w-100">
            <tbody>
                <tr>
                    <td class="w-10px white-space-nowrap">Patient Name:</td>
                    <td>
                        <div class="d-flex">
                            <div class="mr-1"></div>
                            <h5 [textContent]="record?.fullName || bill && bill[0]?.patientName"></h5>
                        </div>
                    </td>
                    <td class="w-10px white-space-nowrap">UMR No:</td>
                    <td>
                        <div class="d-flex">
                            <div class="mr-1"></div>
                            <h5 [textContent]="record?.umrNo || bill && bill[0]?.umrNo"></h5>
                        </div>
                    </td>
                    <td class="w-10px white-space-nowrap">Bill Date/Time:</td>
                    <td>
                        <div class="d-flex">
                            <div class="mr-1"></div>
                            <h5 [textContent]="(finalBillBasics?.billDate || (bill && bill[0]?.createdDate)) | date: 'dd/MM/yyyy h:mm a'"></h5>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="w-10px white-space-nowrap">Relative Name:</td>
                    <td>
                        <div class="d-flex">
                            <div class="mr-1"></div>
                            <h5 class="text-uppercase" [textContent]="record?.relativeName || bill && bill[0].relativeName"></h5>
                        </div>
                    </td>
                    <td>Bill No:</td>
                    <td>
                        <div class="d-flex">
                            <div class="mr-1"></div>
                            <h5 [textContent]="billNumber || (bill?.[0]?.billNumber ?? 'N/A')"></h5>

                        </div>

                    </td>
                    <td>Age/Sex:</td>
                    <td>
                        <div class="d-flex">
                            <div class="mr-1"></div>
                            <ng-container *ngIf="patientAge || bill && bill[0].patientAge ; else fallback">
                                <h5> {{patientAge || bill && bill[0].patientAge}}/ </h5>
                            </ng-container>
                            <ng-template #fallback>
                                <h5> {{record?.age || (bill && bill[0]?.age)}} (Yrs) /</h5>
                            </ng-template>
                            <h5 [textContent]="((record?.gender === 'F' ||bill && bill[0].gender ==='F') ? 'Female' : 'Male') "></h5>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Doctor Name:</td>
                    <td>
                        <div class="d-flex">
                            <div class="mr-1"></div>
                            <h5 class="text-uppercase" [textContent]="record?.providerName || bill && bill[0]?.doctorName"></h5>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="mt-2 bg-white">
        <div class="col-12 p-0">
            <div>
                <table class="border-collapse" width="100%" border="0" cellspacing="0" cellpadding="5">
                    <ng-container *ngIf="activeGeneralRecords && activeGeneralRecords?.length > 0">

                        <thead class="border-bottom-2px border-dark">
                            <tr class="text-uppercase">
                                <th class="border_th tbwd">S.No.</th>
                                <th class="border_th">SacCode</th>
                                <th class="border_th" colspan="5">Service Name</th>
                                <th class="border_th">Qty</th>
                                <th class="border_th">Rate</th>
                                <th class="border_th">Disc in Rs</th>
                                <th class="border_th text-right">Amount</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr class="border-bottom-2px border-dark" *ngFor="let item of activeGeneralRecords; let i = index;">
                                <td><span class="mb-0 mt-0" [textContent]="i+1"></span></td>
                                <td class="mb-0 mt-0 text-capitalize"> SC{{item.serviceOrderId}}</td>
                                <td class="mb-0 mt-0 text-capitalize" colspan="5">{{item.chargeName}}</td>
                                <td class="mb-0 mt-0 tet-capitalize">{{item.unit + (item.usedQuantity || 0)}}</td>
                                <td class="mb-0 mt-0 text-capitalize">{{item.cost | currency:'INR'}}</td>
                                <td class="mb-0 mt-0 text-capitalize">{{item.discount | currency:'INR'}}</td>
                                <td class="mb-0 mt-0 text-capitalize text-right">{{item.totalCost | currency:'INR'}}</td>
                            </tr>
                        </tbody>
                    </ng-container>
                    <ng-container *ngIf="billServices && billServices?.length > 0">

                        <thead class="border-bottom-2px border-dark">
                            <tr class="text-uppercase">
                                <th class="border_th tbwd">S.No.</th>
                                <th class="border_th">SacCode</th>
                                <th class="border_th" colspan="5">Service Name</th>
                                <th class="border_th">Qty</th>
                                <th class="border_th">Rate</th>
                                <th class="border_th">DISC in Rs</th>
                                <th class="border_th text-right">Amount</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr class="border-bottom-2px border-dark" *ngFor="let item of billServices; let i = index;">
                                <td><span class="mb-0 mt-0" [textContent]="i+1"></span></td>
                                <td class="mb-0 mt-0 text-capitalize"> SC{{item.bookingDetailId}}</td>
                                <td class="mb-0 mt-0 text-capitalize" colspan="5">{{item.chargeName}}</td>
                                <td class="mb-0 mt-0 text-capitalize">1</td>
                                <td class="mb-0 mt-0 text-capitalize">{{item.totalAmount | currency:'INR'}}</td>
                                <td class="mb-0 mt-0 text-capitalize">{{item.discountAmount | currency:'INR'}}</td>
                                <td class="mb-0 mt-0 text-capitalize text-right">{{item.netAmount | currency:'INR'}}</td>
                            </tr>
                        </tbody>
                    </ng-container>


                    <tfoot>
                    </tfoot>
                </table>
                <table class="col-12 mt-3 p-0">
                    <tr>
                        <th>
                            <span>Rupees:</span>
                        </th>
                        <td>
                            <h5 class="ml-3 text-capitalize"> <span> {{amountInWord}} </span></h5>
                        </td>
                        <th colspan="5" class="text-right"><span>Total Amount Rs:</span></th>
                        <td class="text-right">
                            <h5 class="ml-3 text-capitalize"> <span [textContent]="(invoiceTotal || 0) | currency:'INR'"></span></h5>
                        </td>
                    </tr>
                    <tr *ngIf="receipts && receipts.length > 0">
                        <td class="w120">
                            Pay Mode:
                        </td>
                        <td>
                            <h5 class="ml-3 text-capitalize"> <span [textContent]="receipts[0].payTypeName"></span></h5>
                        </td>
                        <th colspan="5" class="text-right w120" >
                            <span>Paid Amount Rs:</span>
                        </th>
                        <td class="text-right" *ngIf="billNumber || (bill?.[0]?.billNumber)">
                            <h5 class="ml-3 text-capitalize"> <span [textContent]="invoiceTotal | currency:'INR'"></span></h5>
                        </td>
                    </tr>
                    <tr *ngIf="payModes && payModes.length > 0">
                        <td class="w120">
                            Pay Mode:
                        </td>
                        <th colspan="6" class="text-right w120">
                            <span>Paid Amount Rs:</span>
                        </th>
                        <td class="text-right">
                            <h5 class="ml-3 text-capitalize"> <span [textContent]="amountPaid | currency:'INR'"></span></h5>
                        </td>
                    </tr>
                    <tr *ngFor="let item of payModes;">
                        <td>
                            <h5 class="ml-3 text-capitalize"><span [textContent]="item.payTypeName"></span></h5>
                        </td>
                        <td>
                            <h5 class="ml-2 text-capitalize"><span [textContent]="item.amountPaid | currency:'INR'"></span></h5>
                        </td>
                        <td>
                            <h5 class="ml-2 text-capitalize"><span [textContent]="item.createdDate | date : 'dd-MM-yyyy'"></span></h5>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="w-120 text-align-left" *ngIf="practiceLocations[0] && practiceLocations[0].optionalText4">
                            <h5> <span > For{{practiceLocations[0].optionalText4.toLowerCase()}}</span></h5>

                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" *ngIf="practiceLocations[0] && practiceLocations[0].optionalText1">
                            <span > Note: {{practiceLocations[0].optionalText1}}</span>
                        </td>
                    </tr>

                </table>
                <table>
                    <tr>

                        <td>
                            <div class="mt-2">
                                <practice-content></practice-content>
                            </div>
                        </td>
                    </tr>

                </table>
                <table class="border-bottom-2px border-dark w-100">
                    <tr class="col-12">
                        <td>
                            <div class="d-flex justify-content-end">
                                Print Date/Time:<span [textContent]="currentDate | date :'dd/MM/yyyy, h:mm a'"></span>. &nbsp;&nbsp;&nbsp;
                                <h5 class="text_uppercase d-inline" [textContent]="page.userAccount?.fullName || ''"></h5>
                            </div>
                        </td>
                    </tr>
                </table>
                <div *ngIf="isFooter">
                    <banner-setting [reportName]='"Appointment Invoice Footer"'></banner-setting>
                </div>
            </div>
        </div>
    </div>

</div>