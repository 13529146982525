import { Component, ElementRef, HostListener, ViewEncapsulation, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';


import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ApiResources } from "@shared/helpers";
import { Appointment, PatientDocument, PreviousAppointment, PatientMedicationHeader, ImageReports, ObEncounterFullTranscript } from "@shared/entities";
import { Page, Pagination, IResource, IUserAccount, PatientProfile } from "@shared/models";
import { AppConfig, AppData, HttpService, AppointmentToggleService, TimelineToggleService, IconService, PrintOptionService, ResourceService, } from "@shared/services";
import { finalize, takeUntil } from "rxjs/operators";

import { InternalMedicine } from "@admin/internal-medicine/internal-medicine.namespace";
import { OrderPrescription } from "@shared/entities";
import { OB } from "@admin/full-transcript/pages/ob-report/ob.namespace";
import { ObEncounter } from "@shared/models/ob-encounter.model";
import { LabBillHeaderModel } from "@admin/labs/pages/models";
import { NurseAssessementResources } from '../../models/nurse-assessment-resources';
import { DomSanitizer } from '@angular/platform-browser';





class OrderPrescriptionModel {
    appoitmentId: number;
    orderPrescription: string;

}


@Component({
    templateUrl: './antinatal-summarry-report.html',
    encapsulation: ViewEncapsulation.None,
    selector: "antinatal-summaryreport",
    styleUrls: ["./antinatal-summary-report.css"]

    
})


export class ObAntinatalSummaryPage implements OnInit {
    @Input() printId: string;
    @Input() printadmission: boolean;
    routingValue: string;
    page: Page;
    modalRef: NgbModalRef;
    modalViewRef: NgbModalRef;
    loadingCategories: boolean;
    categories: Array<IResource>;
    section: string;
    pagination: Pagination;
    loading: boolean;
    documents: Array<PatientDocument>;
    patientDocumentId: number;
    document: PatientDocument;
    appointmentId: string;
    isAdmission: boolean;
    providerId: number;
    patientId: number;
    appointments: Array<Appointment>;
    oldAppointment: PreviousAppointment;
    fullTranscript: ObEncounter;


    obFullReport: ObEncounter;


    loadingDocument: boolean;
    documentError: boolean;
    showPrevious: boolean;
    showNext: boolean;
    result: number;
    patient: PatientProfile;
    appointment: ObEncounterFullTranscript;
    obEncounter: ObEncounter;
    submitting: boolean;
    locationId: number;
    locations: Array<IResource>;
    currentLocation: string;


    records: Array<PatientMedicationHeader>;
    iconBasics: ImageReports;
    flag = true;
    @Input() isPrintLogo: boolean;
    labs: Array<PatientMedicationHeader>;
    bookingHeader: Array<LabBillHeaderModel>;
    husbandName: string;
    referralOrder: Array<OB.OrderReferral>;
    antinantalRisk: OB.AntinantalRisk;
    ordersPrescription: OB.OrdersPrescription;
    measure: OB.Measure;
    postNatalVisit: OB.PostnantalVisit;
    ancCard: OB.AncCards;
    ancCardGeneration: OB.AncCardGeneration;
    neonatalInitialAssesment: OB.NeonatalInitialAsessment;
    allergies: OB.Allergies;
    medicationComment: InternalMedicine.MedicationComment;
    scanAppointmentDetails: OB.ScanDetails;

    orders: Array<OrderPrescription>;
    isTeleconsulatantReport: boolean;
    date: Date;
    encryptedPatientId: string;
    admissionDetail: OB.AdmissionDetails;
    obHistory: Array<OB.ObBirthHistory>;
    isPastMedicalHistory = false;
    problemList: Array<InternalMedicine.Problem>;
    isProcedureSurgery = false;
    procedureSurgery: Array<OB.ProcedureSurgery>;
    isFamilyHistory = false;
    familyHistory: Array<OB.ObFamilyHistory>;
    specialFeature: OB.SpecialFeature;
    signature: string;
    AllReports: any = []

    TotalReports: any = []
    TotalDashBoardData: any = []
    AttachmentsData: any = []
    OBFullReportData: any = []
    FullOB: any = []
    orderprscrptn: any = []
    OrderPrescriptionMeasures: any = []
    OrderData: any = []
    OrderCommenents: any = []
    AntenatalVisitDetails: any = []
    AncCount: any;
    NeonatalBaby: any = []
    NeonatalInfo: any = []
    birthHistory: any = []
    obReportData: any = []
    labTests: any = []
    isAllergiesHistory = false
    previousHospitalization: any = []
    previousHospitalVisits: any = []
    nonAncVisits: any = []
    NonAncOrderPrescriptions: any = [];
    telephoneTriangleAssessment: OB.TelephoneTriageAssessment
    labDataDetails: any = []
    totalWeightGain: any = []
    weightChangeSinceLastVisit: any = []
    antenatalPeriodInvestigations: any = []
    PathReportString: any = []

    @ViewChild('content', { static: false }) content: ElementRef;

    AppointmentNURSING: Appointment;
    encryptedAppointmentId: string;
    neonatalNurseNotesResource: Array<NurseAssessementResources>;
    neonatalNurseResource: any;
    nursingPlanOfCare: any;

    @Input() encryptedPatientINTRAId: string;

    fullTranscriptObEncounter: ObEncounterFullTranscript;
    appointment1: Appointment;

    deliverydetails: OB.NormalDeliveryForm
    AntenatalSummary: any = {}
    GeneralExamination: any = {}
    ANCCardGeneration: any = {}

    AllPatientsData: any = []
    PatientPrefer: any = []
    Vaccination: any = []
    PatientPartner: any = []
    CPDetails: any = []
    ObstericHist: any = []
    Bookings: any = []
    PatientTests: any = []
    NutritionalReview: any = []
    investigationsDone: any = []
    ArrayofPdfs: any = [];
    isShow = false;
    isComingFrom: any = "Html";
    isadimissioncount: any;
    ObIntailDetails: any = {};


    BloodDetails: any[] = [
        { ColumnName: "BIGroupRhtyping", DisplayName: "BI Group/Rh typing" },
        { ColumnName: "HIV", DisplayName: "HIV" },
        { ColumnName: "Hbsag", DisplayName: "Hbsag" },
    ];

    Vaccinations: any[] = [
        { ColumnName: "Boosterix", DisplayName: " Boosterix" },
        { ColumnName: "Tetanustoxoid", DisplayName: "Tetanus toxoid" },
        { ColumnName: "CovidVaccine", DisplayName: "Covid Vaccine" },
        { ColumnName: "FluVaccine", DisplayName: "Flu Vaccine" },

        { ColumnName: "booster", DisplayName: "Booster" },
        { ColumnName: "tetanus2ndDose", DisplayName: "tetanus2ndDose" },

    ];

    BookingExamination: any[] = [
        { ColumnName: "Cardiovascularsystem", DisplayName: " Cardiovascular system" },
        { ColumnName: "Respiratory system", DisplayName: "Respiratorysystem" },
        { ColumnName: "Height", DisplayName: "Height" },
        { ColumnName: "Booking weight(kg)", DisplayName: "Booking weight(kg)" },
        { ColumnName: " BookingBMI", DisplayName: " Booking BMI" },
    ];

    Patientpreferences: any[] = [
        { ColumnName: "Diet", DisplayName: "Diet" },
        { ColumnName: "Lifestyleactivity", DisplayName: "Life style activity" },
        { ColumnName: "Toiletpreference", DisplayName: "Toilet preference" },
        { ColumnName: "Prenatalscreening", DisplayName: "Prenatal screening" },
        { ColumnName: "Cordbloodbanking", DisplayName: "Cord blood banking" },
    ];





    Hospitalization = [];
    AntenentalVisitTypes = [];

    TableHeaders: any = [
        { ColumnName: "Date", DisplayName: "Date" },
        { ColumnName: "WeightKg", DisplayName: "Weight Kg" },
        { ColumnName: "BMI", DisplayName: "BMI" },
        { ColumnName: "PulseRate", DisplayName: "Pulse Rate" },
        { ColumnName: "BP", DisplayName: "BP" },
        { ColumnName: "SFHcm", DisplayName: "SFH cm" },
        { ColumnName: "Presentation", DisplayName: "Presentation" },
        { ColumnName: "FHR", DisplayName: "FHR" },
        { ColumnName: "Liquor", DisplayName: "Liquor" },
        { ColumnName: "Edema", DisplayName: "Edema" }
    ];



    DepartmentOfNeonatologyData: any = []

    afiandefw: any = [];


    DoctorVisit_details = [];
    Investigations = [];
    ANC_Visits = [];
    NonANC_Visits = [];
    patientDetails: any = {};
    Investigation_Done = [];
    Final_Diagnosis = [];
    Investigation_Advised: any = {};
    Discharge_Advice = [];
    Period_Investigations = [];
    Telephone_Conversation = [];
    PatientLabTest: any = []

    constructor(private readonly appData: AppData,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly httpService: HttpService,
        private readonly toggleService: AppointmentToggleService,
        public timelineService: TimelineToggleService,
        private readonly printOptionService: PrintOptionService,
        private readonly iconService: IconService,
        private readonly sanitizer: DomSanitizer,
        private readonly modalService: NgbModal,



    ) {

        this.page = new Page();
        this.pagination = new Pagination();
        this.appointments = [];
        this.records = new Array<PatientMedicationHeader>();
        this.labs = new Array<PatientMedicationHeader>();
        this.bookingHeader = new Array<LabBillHeaderModel>();
        this.date = new Date();





    }

    ngOnInit() {

        this.ArrayofPdfs = Array(2).fill(0).map((x, i) => i);

        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    this.currentLocation = this.page.userAccount.locationName;
                    this.encryptedAppointmentId = decodeURIComponent(url.split("/")[3]);
                    this.isTeleconsulatantReport = url.split("/")[6] === "T";
                    this.locationId = this.page.userAccount.locationId;
                    this.routingValue = url.split("/")[2];
                    this.locationId = this.page.userAccount.locationId;
                    this.routingValue = url.split("/")[2];
                    this.isAdmission = url.split("/")[4] === "a";

                    this.encryptedAppointmentId = this.printId
                    this.appointmentId = this.printId
                    this.isAdmission = this.printadmission
                    this.findAppointment(this.encryptedAppointmentId);
                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((params: Params) => {
                            const section = params["section"];
                            this.section = section ? section : undefined;
                        });
                } else {
                    this.page.userAccount = undefined;
                }
                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                this.iconService.getIconImage((is) => { this.iconBasics = is; });

            });

        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {

                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((params: Params) => {
                            const section = params["section"];
                            this.section = section ? section : undefined;
                        });
                } else {
                    this.page.userAccount = undefined;
                }
                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                this.iconService.getIconImage((is) => { this.iconBasics = is; });

            });

    }



    obAllInfo() {

        const request = {
            AppointmentId: this.appointment.appointmentId,
            PatientId: this.appointment.patientId,
            isAdmission: this.isAdmission
        };

        this.httpService
            .post<ObEncounter>(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fullreport), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: ObEncounter) => {


                this.obFullReport = response;

                const obSections = JSON.parse(this.obFullReport[0].patientdetails)

                this.obReportData = obSections[0]
                this.ancCard = obSections[0].AncCard ? JSON.parse(obSections[0].AncCard) : null;
                this.antinantalRisk = obSections[0].AntinantalRisk ? JSON.parse(obSections[0].AntinantalRisk) : null;
                this.GeneralExamination = obSections[0].GeneralExamination ? JSON.parse(obSections[0].GeneralExamination) : null;
                this.ancCardGeneration = obSections[0].ANCCardGeneration ? JSON.parse(obSections[0].ANCCardGeneration) : null;
                this.allergies = obSections[0].Allergies ? JSON.parse(obSections[0].Allergies) : null;
                this.obHistory = obSections[0].BirthHistory ? JSON.parse(obSections[0].BirthHistory) : null;
                this.familyHistory = obSections[0].FamilyHistory ? JSON.parse(obSections[0].FamilyHistory) : null;
                this.specialFeature = obSections[0].SpecialFeature ? JSON.parse(obSections[0].SpecialFeature) : null;
                this.admissionDetail = obSections[0].AdmissionDetails ? JSON.parse(obSections[0].AdmissionDetails) : null;
                const checkadmissioncount = this.obFullReport[0].admissioncount ? JSON.parse(this.obFullReport[0].admissioncount) : null;

                this.isadimissioncount = checkadmissioncount && checkadmissioncount[0] ? checkadmissioncount[0].isAdmissionCount : null;
                if (obSections[0].FamilyHistory && this.familyHistory.length == 0) {
                    this.isFamilyHistory = true;
                }
                this.measure = obSections[0].Measure ? JSON.parse(obSections[0].Measure) : null;
                this.postNatalVisit = obSections[0].PostnantalVisit ? JSON.parse(obSections[0].PostnantalVisit) : null;
                this.problemList = obSections[0].ProblemList ? JSON.parse(obSections[0].ProblemList) : null;

                if (obSections[0].ProblemList && this.problemList.length == 0) {
                    this.isPastMedicalHistory = true;
                }
                this.procedureSurgery = obSections[0].Surgeries ? JSON.parse(obSections[0].Surgeries) : null;
                if (obSections[0].Surgeries && this.procedureSurgery.length == 0) {
                    this.isProcedureSurgery = true;
                }
                this.deliverydetails = obSections[0].NormalDeliveryForm ? JSON.parse(obSections[0].NormalDeliveryForm) : null;

                const attachementInfo = JSON.parse(this.obFullReport[0].attachmentsob)
                this.AttachmentsData = attachementInfo
                // lab tests
                this.labTests = this.obFullReport[0].labtests_ob ? JSON.parse(this.obFullReport[0].labtests_ob) : null
                this.labDataDetails = this.obFullReport[0].labdatadetails ? JSON.parse(this.obFullReport[0].labdatadetails) : null
                this.antenatalPeriodInvestigations = this.obFullReport[0].antenatalperiodinvestigations ? JSON.parse(this.obFullReport[0].antenatalperiodinvestigations) : null

                //Department of Neonatology
                const neonatologyOb = this.obFullReport[0].departmentofneonatology_ob ? JSON.parse(this.obFullReport[0].departmentofneonatology_ob)[0] : null

                if (neonatologyOb) {
                    try {
                        this.DepartmentOfNeonatologyData = neonatologyOb;
                        this.NeonatalBaby = JSON.parse(neonatologyOb.NeonatalBaby);
                        this.NeonatalInfo = JSON.parse(neonatologyOb.NeonatalInfo);
                    } catch (e) {
                        console.error('Error parsing JSON:', e.message);
                    }
                } else {
                    console.warn('departmentofneonatology_ob data is null');
                }

                const departmentOfOb = this.obFullReport[0].departmentofobstetrics_ob ? JSON.parse(this.obFullReport[0].departmentofobstetrics_ob) : null;
                this.ObstericHist = departmentOfOb ? departmentOfOb[0] : null;

                this.previousHospitalization = JSON.parse(this.obFullReport[0].previoushospitalization);
                this.previousHospitalVisits = JSON.parse(this.obFullReport[0].previoushospitalvisitdetails);

                this.investigationsDone = this.obFullReport[0].investigationsdone ? JSON.parse(this.obFullReport[0].investigationsdone) : null;
                const nonAncDetails = JSON.parse(this.obFullReport[0].nonancvisits);

                if (Array.isArray(nonAncDetails)) {
                    const nonAncVisitDetails = [];
                    nonAncDetails.forEach((item: any) => {

                        const orderPrescription = item.PrescriptionGiven ? JSON.parse(item.PrescriptionGiven) : null;
                        const orderData = orderPrescription && orderPrescription.orderData ? orderPrescription.orderData.map((order) => JSON.parse(JSON.stringify(order))) : null;

                        //TelePhoneTriageAssessment
                        nonAncVisitDetails.push({
                            ...item,
                            PrescriptionGiven: {
                                orderData: orderData
                            }
                        });
                    });
                    this.nonAncVisits = nonAncVisitDetails;
                } else {
                    console.error('Error');
                    return [];
                }

                // CURRENT ANTENATAL VISIT DETAILS

                const Visitdata = JSON.parse(this.obFullReport[0].antenatalvisits_ob);

                if (Array.isArray(Visitdata)) {
                    const VisitDetails = [];
                    let firstWeight = null;
                    let lastWeight = null;

                    Visitdata.forEach((item) => {
                        const teleRecorded = item.TelePhoneTriageAssessment ? JSON.parse(item.TelePhoneTriageAssessment) : null;
                        const parsedAncCard = item.AncCard ? JSON.parse(item.AncCard) : null;
                        const parsedMeasure = item.Measure ? JSON.parse(item.Measure) : null;
                        const orderPrescription = item.OrderPrescription ? JSON.parse(item.OrderPrescription) : null;
                        const orderData = orderPrescription && orderPrescription.orderData ? orderPrescription.orderData.map((order) => JSON.parse(JSON.stringify(order))) : null;

                        if (!firstWeight) {
                            firstWeight = parsedMeasure?.weight;
                        }

                        lastWeight = parsedMeasure?.weight;


                        VisitDetails.push({
                            ...item,
                            AncCard: parsedAncCard,
                            Measure: parsedMeasure,
                            OrderPrescription: { orderData: orderData },
                            TelePhoneTriageAssessment: teleRecorded
                        });
                    });
                    const ancWeightGain = lastWeight - firstWeight;
                    const Weightchangelastvisit = VisitDetails && VisitDetails.length > 1 ? lastWeight - VisitDetails[VisitDetails.length - 2].Measure?.weight : null;
                    this.AntenatalVisitDetails = VisitDetails;
                    this.totalWeightGain = ancWeightGain;
                    this.weightChangeSinceLastVisit = Weightchangelastvisit;

                } else {
                    console.error('Error');
                    return [];
                }

            })

    }

    getAbsoluteValue(value: number): number {
        return value >= 0 ? value : -value;
    }


    getUniqueDates(): string[] {
        const dates = new Set<string>();
        this.antenatalPeriodInvestigations.forEach(row => {
            Object.keys(row).forEach(key => {
                if (key !== 'PatientId' && key !== 'LabMainDetailId' && key !== 'TestName' && key !== 'ParameterName') {
                    dates.add(key);
                }
            });
        });
        return Array.from(dates);
    }


    groupRowsByTestName(): { [key: string]: any[] } {
        const groups = {};
        this.antenatalPeriodInvestigations.forEach(row => {
            const testName = row.TestName;
            groups[testName] = groups[testName] || [];
            groups[testName].push(row);
        });
        return groups;
    }


    private findPatientDetails(id: string) {
        this.page.loading = true;
        this.httpService.post<PatientProfile>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.profile), { encryptedPatientId: id })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: PatientProfile) => {
                this.patient = response;
                var detaills = this.patient.relativeDetails[0];
                if (detaills && detaills.relation == "Husband") {
                    this.husbandName = this.patient.relativeDetails[0].fullName
                }


            });
    }


    private fetchMedication() {
        this.loading = true;
        const request = {
            encryptedAppointmentId: this.encryptedAppointmentId,
            isAdmission: this.isAdmission
        }

        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                this.records = response;
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });
    }


    gotoPrescription(appointmentId: string) {
        this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/prescription/${appointmentId}`, "_blank"); });
    }


    //Enrtry Point
    private findAppointment(appointmentId: string) {

        const request = {
            appointmentId: 0,
            encryptedAppointmentId: appointmentId,
        };
        if (this.isAdmission == null) {
            this.isAdmission = false;
        }
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<ObEncounterFullTranscript>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: ObEncounterFullTranscript) => {

                this.appointment = response;
                this.patientId = response.patientId;
                this.encryptedPatientId = response.encryptedPatientId;
                this.fetchMedication();
                this.obAllInfo()
                this.findPatientDetails(response.encryptedPatientId);
            });

    }


    ngAfterViewInit() {
        this.paddingTop();
    }
    paddingTop() {
        if (document.getElementById("header") != null && document.getElementById("obprintContainer") != null) {
            var paddingTopValue = document.getElementById("header").offsetHeight;
            var content = document.getElementById("obprintContainer");
            content.setAttribute('style', `padding-top:${paddingTopValue}px`);
        }
    }


    @HostListener('window:load')
    onLoad() {
        setTimeout(() => { this.paddingTop(); }, 1500)
    }
    @HostListener('window:hashchange')
    onhashchange() {
        setTimeout(() => { this.paddingTop(); }, 500)
    }
    @HostListener('window:resize')
    onResize() {
        this.paddingTop();
    }



    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }

}