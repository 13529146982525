<div id="obprintContainer">
    <div class="mt-1">
        <div class="col-12 p-0">
            <div>
                <button class="btn btn-info" type="button" (click)="onChangePrintType(false)">
                    <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                </button>
                <button class="btn btn-info" type="button" (click)="onChangePrintType(true)">
                    <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                </button>
                <button class="btn btn-info" type="button" (click)="onChangeFooter(false)">
                    <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
                </button>
                <button class="btn btn-info" type="button" (click)="onChangeFooter(true)">
                    <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
                </button>
            </div>
            <div class="align-items-start border border-bottom-0 d-flex justify-content-end pl-1 pr-1 pt-1" style="background-color: #edfaf7;">
                <div class="col-1">
                    <button [useExistingCss]="true" id="printButton" printSectionId="obprint" ngxPrint class="close d-print-none"> <i class="fe-printer" placement="left" ngbTooltip="PrintEncounter"></i></button>
                </div>
            </div>
        </div>
    </div>
    <div id="obprint" class="border-left border-right mb-0">
        <div class="report pl-4 pr-4 bg-white position-relative">
            <div>
                <div class="row" *ngIf="page.loading">
                    <div class="col-12">
                        <div class="d-flex align-items-center">
                            <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                            <span class="ml-2">Please wait while generating IEncounter Prescription ...</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12" *ngIf="isPrintLogo">
                    <banner-setting [reportName]='"Admission Detail Report Header"'></banner-setting>
                </div>
                <div *ngIf="!isPrintLogo" class="col-12 p-0">

                </div>

                <ng-container *ngIf="!page.loading && fullTranscript">
                    <div class="w-100">
                        <div>
                            <h5>Consultant Doctor :{{appointment.providerName}}</h5>
                            <h5>
                                Unit :{{appointment.locationUnitName}}
                            </h5>
                        </div>
                    </div>
                </ng-container>
                <h3 class=" text-center">
                    ADMISSION FORM
                </h3>
                <div class="page">
                    <div class="col-12 p-0">
                        <div class="text-dark" *ngIf="!page.loading && fullTranscript">
                            <ng-container>
                                <div class="col-12 p-0 pb-1 pt-1 white-space-nowrap">
                                    <table class="w-100 white-space-nowrap mt-2">

                                        <tr class="col-12">
                                            <td class="w-130">
                                                <span>Patient Name</span>
                                            </td>
                                            <td>
                                                <h5>:</h5>
                                            </td>

                                            <td class="text-uppercase">
                                                <h5 class="pl-1" *ngIf="appointment && appointment.patientName" [textContent]="appointment.patientName"></h5>
                                            </td>
                                            <td class="w-130">
                                                <span>Admission Date /Ward in Time </span>
                                            </td>
                                            <td>
                                                <h5>:</h5>
                                            </td>
                                            <td class="w-130 text-uppercase">
                                                <span [textContent]="appointment.appointmentDate | date: 'dd/MM/yyyy'"></span><span class="pl-1" [textContent]="appointment.admTime"></span>
                                            </td>
                                            <td class="white-space-nowrap">
                                                <span>UMR No</span>
                                            </td>
                                            <td>
                                                <h5>:</h5>
                                            </td>

                                            <td>
                                                <h5 class="pl-1" *ngIf="appointment && appointment.umrNo">{{appointment.umrNo}}</h5>
                                            </td>
                                        </tr>
                                        <tr class="col-12">

                                            <td class="white-space-nowrap">
                                                <span>IP No </span>
                                            </td>
                                            <td>
                                                <h5>:</h5>
                                            </td>
                                            <td>
                                                <h5 class="pl-1" *ngIf="appointment && appointment.appointmentNo" [textContent]="appointment.appointmentNo"></h5>
                                            </td>
                                            <td class="white-space-nowrap">
                                                <span>RoomName </span>
                                            </td>
                                            <td>
                                                <h5>:</h5>
                                            </td>
                                            <td>
                                                <h5 class="pl-1" *ngIf="appointment && appointment.roomName" [textContent]="appointment.roomName"></h5>
                                            </td>
                                            <td class="white-space-nowrap">
                                                <span>Wards</span>
                                            </td>
                                            <td>
                                                <h5>:</h5>
                                            </td>
                                            <td>
                                                <h5 class="pl-1" *ngIf="appointment && appointment.wardName" [textContent]="appointment.wardName"></h5>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <hr class="m-0" />

                <div>
                    <div class="border-dark border-top d-flex pb-1 pt-1 align-items-center">
                        <div class="col-2"><h5>COMPLAINTS :</h5></div>
                        <div *ngIf="admissionDetail && admissionDetail.complaints.length > 0">
                            <span *ngFor="let item of admissionDetail.complaints; let i=index;">
                                <span [textContent]="item"></span>
                                <span *ngIf=" i != admissionDetail.complaints.length-1">,</span>
                            </span>
                        </div>
                    </div>
                    <div class="align-items-center border-dark border-top d-flex pt-1">
                        <h5>History Of Present Illness <span>:</span></h5>
                        <div *ngIf="admissionDetail && admissionDetail.historyOfPresentIllness"><span [textContent]="admissionDetail.historyOfPresentIllness"></span></div>
                    </div>

                    <div class="d-flex mt-2">
                        <div class="col-5 pl-0">
                            <div>
                                <h5 class="text-black">Antenatal Visit Details</h5>
                            </div>
                        </div>
                        <div class="col-2 pl-0">
                        </div>
                        <div class="col-2 text-center">
                        </div>
                        <div class="col-3 text-center">
                            <h5>Period of Gestation :</h5>
                        </div>
                    </div>
                    <div class="d-flex text-black" *ngIf="ancCard && ancCard!=null  && (ancCard.gravida || ancCard.para || ancCard.abortion || ancCard.living || ancCard.husbandBloodGroup || ancCard.husbandRhType || ancCard.ga)">
                        <div class="pl-0 d-flex col-7">
                            <div>
                                <table class="pt-2" *ngIf="ancCard && ancCard!=null">
                                    <tr>
                                        <th class="line-height-1px"><h3>G</h3></th>
                                        <th></th>
                                        <th class="line-height-1px"><h3>P</h3></th>
                                        <th></th>
                                        <th class="line-height-1px"><h3>L</h3></th>
                                        <th></th>
                                        <th class="line-height-1px"><h3>A</h3></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td class="p-1" [textContent]="ancCard.gravida"></td>
                                        <td></td>
                                        <td class="p-1" [textContent]="ancCard.para"></td>
                                        <td></td>
                                        <td class="px-1" [textContent]="ancCard.living"></td>
                                        <td></td>
                                        <td class="px-1" [textContent]="ancCard.abortion"></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="col-2 d-flex justify-content-center">
                        </div>
                        <div class="col-3 text-center">
                            <h3 *ngIf="ancCard && ancCard.ga" [textContent]="ancCard.ga"></h3>
                            <div class="text-center">
                                <span>Weeks</span>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2 d-flex">
                        <table *ngIf="ancCard">
                            <tr>
                                <td>
                                    <span class="m-0"> LMP&nbsp;&nbsp;:</span>
                                </td>
                                <td>
                                    <span class="pl-1" [textContent]="ancCard.lmp |date :'dd/MM/yyyy'"></span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="m-0"> EDD&nbsp;&nbsp;:</span>
                                </td>
                                <td>
                                    <span class=" pl-1" [textContent]="ancCard.edd"></span>
                                </td>
                            </tr>

                        </table>
                    </div>
                </div>
                <div class="col-12 ng-star-inserted p-0 pt-2 border-dark border-top py-2" *ngIf="obHistory">
                    <div><h5 class=" text-black">OBSTETRIC HISTORY</h5> </div>
                    <table style="width:100%" border="1px solid black">
                        <thead>
                            <tr class="tableBorder">
                                <th class="tableBorder white-space-nowrap w-10px px-1"> <h5>Preg No</h5></th>

                                <th class="tableBorder ">Type Of Delivery</th>
                                <th class="tableBorder ">GA</th>
                                <th class="tableBorder ">Gender</th>
                                <th class="tableBorder ">Weight</th>
                                <th class="tableBorder ">Delivery At</th>
                                <th class="tableBorder ">Year</th>
                                <th class="tableBorder ">Birth Details</th>
                            </tr>
                        </thead>
                        <tbody class="vertical-align-baseline">
                            <tr *ngFor="let item of obHistory;let i = index;">
                                <td class="tableBorder"><h5 [textContent]="item.pregnancyNo"></h5></td>
                                <td class="tableBorder"><h5 [textContent]="item.modeOfDelivery"></h5></td>
                                <td class="tableBorder"><h5 [textContent]="item.ga"></h5></td>
                                <td class="tableBorder"><h5 [textContent]="item.sexOfBaby"></h5></td>
                                <td class="tableBorder"><h5 [textContent]="item.birthWeight"></h5></td>
                                <td class="tableBorder"><h5 [textContent]="item.deliveryAt"></h5></td>
                                <td class="tableBorder"><h5 [textContent]="item.yearOfBirth"></h5></td>

                                <td class="tableBorder">
                                    <div class="d-flex column-gap10px" *ngIf="item.pregnancyStatus"><span>Pregnancy Status:</span><h5 [textContent]="item.pregnancyStatus"></h5></div>
                                    <div class="d-flex column-gap10px" *ngIf="item.gaWeeks"><span>GA Weeks:</span><h5 [textContent]="item.gaWeeks"></h5></div>
                                    <div class="d-flex column-gap10px" *ngIf="item.deliveryAt"><span>Delivered:</span><h5 [textContent]="item.deliveryAt"></h5></div>
                                    <div class="d-flex column-gap10px" *ngIf="item.deliveryDetail.length"><h5 [textContent]="item.deliveryDetail"></h5></div>
                                    <div class="d-flex column-gap10px" *ngIf="item.indication"><span>Indication:</span><h5 [textContent]="item.indication"></h5></div>
                                    <div class="d-flex column-gap10px" *ngIf="item.other"><span>Other Information:</span><h5 [textContent]="item.other"></h5></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="problemList" class="mt-2">
                    <div><h5 class="text-uppercase">Past Medical History</h5> </div>
                    <div *ngIf="problemList.length ==0">
                        None
                    </div>
                    <table style="width:100%">
                        <tbody>
                            <tr *ngFor="let item of problemList; let i = index">
                                <td class="vertical-align-top w-50px">
                                    <span [textContent]="i +1"></span>
                                </td>
                                <td>
                                    <div class="d-flex">
                                        Disease:<span class="pl-1" [textContent]="item.usable"></span>
                                    </div>
                                    <div class="d-flex">
                                        ICD Code:<span class="pl-1" [textContent]="item.code"></span>
                                    </div>
                                    <div class="d-flex" *ngIf="item.date">
                                        Year of diagnosis &nbsp;:<span class="pl-1" *ngIf="item.date" [textContent]="item.date"></span>
                                    </div>
                                    <div class="d-flex" *ngIf="item.comments">
                                        Treatment History:<span class="pl-1" [textContent]="item.comments"></span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
                <div *ngIf="procedureSurgery" class="mt-2">
                    <div><h5 class="text-uppercase">Past Surgical History</h5> </div>
                    <div *ngIf="isProcedureSurgery">
                        There is no Surgical History
                    </div>
                    <table style="width:100%" *ngIf="procedureSurgery.length>0">
                        <tbody>
                            <tr *ngFor="let item of procedureSurgery; let i = index">
                                <td class="vertical-align-top w-50px">
                                    <span [textContent]="i +1"></span>
                                </td>
                                <td>
                                    <div class="d-flex">
                                        Year Of Surgery:<span class="pl-1" [textContent]="item.date"></span>
                                    </div>
                                    <div class="d-flex">
                                        Surgeries:<span class="pl-1" [textContent]="item.surgeries"></span>
                                    </div>
                                    <div class="d-flex" *ngIf="item.date">
                                        Comments &nbsp;:<span class="pl-1" *ngIf="item.date" [textContent]="item.comments"></span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="familyHistory" class="mt-2">
                    <div><h5 class="text-uppercase">Family History</h5> </div>
                    <div *ngIf="isFamilyHistory">
                        There is no Family History
                    </div>
                    <table style="width:100%" *ngIf="familyHistory.length >0">
                        <tbody>
                            <tr *ngFor="let item of familyHistory; let i = index">
                                <td class="vertical-align-top w-50px">
                                    <div class="w-50px"></div>
                                </td>
                                <td>
                                    <div class="d-flex">
                                        Relation:<span class="pl-1" [textContent]="item.relation"></span>
                                    </div>
                                    <div class="d-flex">
                                        Problem:<span class="pl-1" [textContent]="item.problem"></span>
                                    </div>
                                    <div class="d-flex" *ngIf="item.date">
                                        Comments &nbsp;:<span class="pl-1" *ngIf="item.date" [textContent]="item.comments"></span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 p-0 mt-2" *ngIf="allergies && allergies !== null">
                    <div><h5 class="text-uppercase">Allergy Related History</h5> </div>
                    <div *ngIf="allergies">
                        <div class="d-flex" *ngIf="allergies && allergies.noHistory && allergies.noHistory!=null">
                            <div>
                                <div>
                                    Allergies  <span class="pl-1" *ngIf="allergies && allergies.noHistory && allergies.noHistory!=null "> &nbsp; : &nbsp; There is no allergy</span>
                                </div>
                            </div>

                        </div>
                        <table class="w-100 vertical-align-top" *ngIf="allergies && !allergies.noHistory">
                            <tr class="col-12">
                                <td class="w-150px white-space-nowrap">
                                    <span> Drug Allergies</span>
                                </td>
                                <td>
                                    <h5 class="">:</h5>
                                </td>
                                <td>
                                    <span class="d-block pl-1" [textContent]="allergies.drugNames"></span>
                                </td>
                                <td class="w-150px white-space-nowrap">
                                    <span *ngIf="allergies.drugReactions"> Adverse Drug Reaction</span>
                                </td>
                                <td>
                                    <span *ngIf="allergies.drugReactions" class="">:</span>
                                </td>
                                <td>
                                    <span class="pl-1" [textContent]="allergies.drugReactions"></span>
                                </td>
                            </tr>
                            <tr class="col-12">
                                <td class="w-150px white-space-nowrap">
                                    <span> Food Allergy</span>
                                </td>
                                <td>
                                    <h5 class="">:</h5>
                                </td>
                                <td>
                                    <span class="d-block pl-1" [textContent]="allergies.foodNames"></span>
                                </td>
                                <td class="w-150px white-space-nowrap">
                                    <span *ngIf="allergies.foodReactions"> Adverse Drug Reaction</span>
                                </td>
                                <td>
                                    <span *ngIf="allergies.foodReactions" class="">:</span>
                                </td>
                                <td>
                                    <span class="pl-1" [textContent]="allergies.foodReactions"></span>
                                </td>
                            </tr>
                            <tr class="col-12">
                                <td class="w-150px white-space-nowrap">
                                    <span> Dust Allergy</span>
                                </td>
                                <td>
                                    <h5 class="">:</h5>
                                </td>
                                <td>
                                    <span class="d-block pl-1" [textContent]="allergies.dustNames"></span>
                                </td>
                                <td class="w-150px white-space-nowrap">
                                    <span *ngIf="allergies.dustReactions"> Adverse Drug Reaction</span>
                                </td>
                                <td>
                                    <span *ngIf="allergies.dustReactions" class="">:</span>
                                </td>
                                <td>
                                    <span class="pl-1" [textContent]="allergies.dustReactions"></span>
                                </td>
                            </tr>
                            <tr class="col-12">
                                <td class="w-150px white-space-nowrap">
                                    <span> Others</span>
                                </td>
                                <td>
                                    <h5 class="">:</h5>
                                </td>
                                <td>
                                    <span class="d-block pl-1" [textContent]="allergies.otherNames"></span>
                                </td>
                                <td class="w-150px white-space-nowrap">
                                    <span *ngIf="allergies.otherReactions"> Adverse Drug Reaction</span>
                                </td>
                                <td>
                                    <span *ngIf="allergies.otherReactions" class="">:</span>
                                </td>
                                <td>
                                    <span class="pl-1" [textContent]="allergies.otherReactions"></span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="d-flex flex-wrap  mt-2">
                    <div class="col-12 col-sm-6 pl-0">
                        <div>
                            <h5>RISK FACTORS <span>:</span></h5>
                            <div *ngIf="admissionDetail && admissionDetail.complaints.length > 0">
                                <div *ngFor="let item of admissionDetail.complaints" [textContent]="item"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 pl-0">
                        <div>
                            <h5 class="d-inline">SPECIAL FEATURES: &nbsp;</h5>
                            <span *ngIf="specialFeature && specialFeature.specialFeature" [textContent]="specialFeature.specialFeature"></span>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div>
                        <h5 class="d-inline">Risk Categorisation at admission <span>: &nbsp;</span></h5><span class="text-danger font-weight-normal">No Risk</span>
                    </div>
                    <div>
                        <h5 class="d-inline">No of Risk factors <span>: &nbsp;</span></h5><span class="font-weight-normal">3</span>
                    </div>
                </div>

                <div class="mt-2">
                    <div><h5>General Examination</h5> </div>
                    <table class="white-space-nowrap w-100 border-collapse">
                        <tr>
                            <td><div class="d-flex justify-content-between"><span>Consciousness </span><span class="text-right">&nbsp;:</span></div></td>
                            <td class="w-100px"><span class="pl-1" *ngIf="admissionDetail && admissionDetail.conscious" [textContent]="admissionDetail.conscious"></span></td>

                            <td><div class="d-flex justify-content-between"><span>Resp System </span><span class="text-right">&nbsp;:</span></div></td>
                            <td class="w-100px"><span class="pl-1" *ngIf="admissionDetail && admissionDetail.respsystem" [textContent]="admissionDetail.respsystem"></span></td>

                            <td><div class="d-flex justify-content-between"><span> Edema </span><span class="text-right">&nbsp;:</span></div></td>
                            <td class="w-100px"><span class="pl-1" *ngIf="admissionDetail && admissionDetail.oedema" [textContent]="admissionDetail.oedema"></span></td>
                        </tr>
                        <tr>
                            <td><div class="d-flex justify-content-between"><span>Shock </span><span class="text-right">&nbsp;:</span></div></td>
                            <td><span class="pl-1" *ngIf="admissionDetail && admissionDetail.shock" [textContent]="admissionDetail.shock"></span></td>

                            <td><div class="d-flex justify-content-between"><span>CVS </span><span class="text-right">&nbsp;:</span></div></td>
                            <td><span class="pl-1" *ngIf="admissionDetail && admissionDetail.cardiovasc" [textContent]="admissionDetail.cardiovasc"></span></td>

                            <td><div class="d-flex justify-content-between"><span> Reflexes</span><span class="text-right">&nbsp;:</span></div></td>
                            <td><span class="pl-1" *ngIf="admissionDetail && admissionDetail.reflex" [textContent]="admissionDetail.reflex"></span></td>
                        </tr>
                        <tr>
                            <td><div class="d-flex justify-content-between"><span> Pallor</span><span class="text-right">&nbsp;:</span></div></td>
                            <td><span class="pl-1" *ngIf="admissionDetail && admissionDetail.pallor" [textContent]="admissionDetail.pallor"></span></td>

                            <td class="w-100px"><div class="d-flex justify-content-between">Liver <span class="text-right">&nbsp;:</span></div></td>
                            <td><span class="pl-1" *ngIf="admissionDetail && admissionDetail.liver" [textContent]="admissionDetail.liver"></span></td>

                            <td class="w-100px"><div class="d-flex justify-content-between">Urine Output <span class="text-right">&nbsp;:</span></div></td>
                            <td><span class="pl-1" *ngIf="admissionDetail && admissionDetail.urineoutput" [textContent]="admissionDetail.urineoutput"></span></td>
                        </tr>
                        <tr>
                            <td class="w-100px"><div class="d-flex justify-content-between">Icterus <span class="text-right">&nbsp;:</span></div></td>
                            <td><span class="pl-1" *ngIf="admissionDetail && admissionDetail.icterus" [textContent]="admissionDetail.icterus"></span></td>

                            <td><div class="d-flex justify-content-between"><span>Spleen </span><span class="text-right">&nbsp;:</span></div></td>
                            <td colspan="3"><span class="pl-1" *ngIf="admissionDetail && admissionDetail.spleen" [textContent]="admissionDetail.spleen"></span></td>
                        </tr>
                    </table>
                </div>
                <div *ngIf="admissionDetail" class="mt-2">
                    <table class="w-100" border="1px solid black">
                        <thead>
                            <tr class="tableBorder">
                                <th class="text-center"> Temp (F) </th>
                                <th class="text-center">Pulse / min</th>
                                <th class="text-center">BP</th>
                                <th class="text-center">Resp rate</th>
                                <th class="text-center">Height(Ft)</th>
                                <th class="text-center">Weight(kg)</th>
                                <th class="text-center">BMI</th>
                                <th class="text-center">Spo2</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center" [textContent]="admissionDetail.temperature"></td>
                                <td class="text-center" [textContent]="admissionDetail.pulserate "></td>
                                <td class="text-center" [textContent]="bp"></td>
                                <td class="text-center" [textContent]="admissionDetail.respiratoryrate"></td>
                                <td class="text-center" [textContent]="admissionDetail.heightInFeet "></td>
                                <td class="text-center" [textContent]="admissionDetail.weightInKg "></td>
                                <td class="text-center" [textContent]="admissionDetail.bmi "></td>
                                <td class="text-center" [textContent]="admissionDetail.spo2"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mt-2">
                    <h5>OBSTETRIC EXAMINATION</h5>
                    <table>
                        <tr>
                            <td>
                                <span style="white-space:pre;">Fundal height(cm) </span>
                            </td>
                            <td class="w-10px">
                                :
                            </td>
                            <td class="w-200p">
                                <span *ngIf="admissionDetail && admissionDetail.fundalht" [textContent]="admissionDetail.fundalht"></span>
                            </td>

                            <td>
                                <span style="white-space:pre;">Liquor </span>
                            </td>
                            <td class="w-10px">
                                :
                            </td>
                            <td class="w-200p">
                                <span *ngIf="admissionDetail && admissionDetail.liqr" [textContent]="admissionDetail.liqr"></span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span style="white-space:pre;">Presenting Part </span>
                            </td>
                            <td>
                                :
                            </td>
                            <td>
                                <span *ngIf="admissionDetail && admissionDetail.pp" [textContent]="admissionDetail.pp"></span>
                            </td>

                            <td>
                                <span style="white-space:pre;">Draining </span>
                            </td>
                            <td>
                                :
                            </td>
                            <td>
                                <span *ngIf="admissionDetail && admissionDetail.draining" [textContent]="admissionDetail.draining"></span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span style="white-space:pre;">PP Abd  </span>
                            </td>
                            <td>
                                :
                            </td>
                            <td>
                                <span *ngIf="admissionDetail && admissionDetail.conscious" [textContent]="admissionDetail.conscious"></span>
                            </td>

                            <td>
                                <span style="white-space:pre;">Urine albumin </span>
                            </td>
                            <td>
                                :
                            </td>
                            <td>
                                <span *ngIf="admissionDetail && admissionDetail.respsystem" [textContent]="admissionDetail.respsystem"></span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span style="white-space:pre;">FHS </span>
                            </td>
                            <td>
                                :
                            </td>
                            <td>
                                <span *ngIf="admissionDetail && admissionDetail.fhs" [textContent]="admissionDetail.fhs"></span>
                            </td>

                            <td>
                                <span style="white-space:pre;">Uterine Contractions </span>
                            </td>
                            <td>
                                :
                            </td>
                            <td>
                                <span *ngIf="admissionDetail && admissionDetail.respsystem" [textContent]="admissionDetail.respsystem"></span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span style="white-space:pre;">FHR  </span>
                            </td>
                            <td>
                                :
                            </td>
                            <td colspan="3">
                                <span *ngIf="admissionDetail && admissionDetail.fhr" [textContent]="admissionDetail.fhr"></span>
                            </td>

                        </tr>
                        <tr>
                            <td colspan="5">
                                <h5 class="mt-2">VAGINAL EXAMINATION</h5>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span style="white-space:pre;">Cervix </span>
                            </td>
                            <td>
                                :
                            </td>
                            <td>
                                <span *ngIf="admissionDetail && admissionDetail.cervix" [textContent]="admissionDetail.cervix"></span>
                            </td>

                            <td>
                                <span style="white-space:pre;">Station </span>
                            </td>
                            <td>
                                :
                            </td>
                            <td>
                                <span *ngIf="admissionDetail && admissionDetail.station" [textContent]="admissionDetail.station"></span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span style="white-space:pre;">Membranes </span>
                            </td>
                            <td>
                                :
                            </td>
                            <td>
                                <span *ngIf="admissionDetail && admissionDetail.membranes" [textContent]="admissionDetail && admissionDetail.membranes"></span>
                            </td>

                            <td>
                                <span style="white-space:pre;">Cervical Dilation(cm) </span>
                            </td>
                            <td class="w-10px">
                                :
                            </td>
                            <td>
                                <span *ngIf="admissionDetail && admissionDetail.cervicaloscm" [textContent]="admissionDetail && admissionDetail.cervicaloscm"></span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span style="white-space:pre;">PP Vag </span>
                            </td>
                            <td>
                                :
                            </td>
                            <td>
                                <span *ngIf="admissionDetail && admissionDetail.ppvag" [textContent]="admissionDetail && admissionDetail.ppvag"></span>
                            </td>

                            <td>
                                <span style="white-space:pre;">Pelvis </span>
                            </td>
                            <td class="w-10px">
                                :
                            </td>
                            <td>
                                <span *ngIf="admissionDetail && admissionDetail.pelvis" [textContent]="admissionDetail.pelvis"></span>
                            </td>
                        </tr>
                    </table>
                </div>

                <div class="border-bottom border-dark d-flex w-100 align-items-center py-1">
                    <h5>PROVISIONAL DIAGNOSIS <span>: &nbsp;</span></h5>
                    <div>
                        <h5 *ngIf="ancCard && ancCard!=null">
                            <span *ngIf="ancCard.gravida || ancCard.para || ancCard.abortion || ancCard.living || ancCard.para || ancCard.para">
                                &nbsp; G <span [textContent]="ancCard.gravida"></span> P<span [textContent]="ancCard.para"></span> L<span [textContent]="ancCard.abortion"></span> A<span [textContent]="ancCard.living"></span>
                            </span>,
                            <span *ngIf="ancCard.gravida">
                                GA<span class="pl-1" [textContent]="ancCard.ga">weeks of gestation</span>
                            </span>
                        </h5>
                    </div>
                </div>
                <div *ngIf="records.length > 0" class="mt-2">
                    <div class="dashboard-body">
                        <div *ngFor="let record of records;index as j;">
                            <div class="overflow-auto">
                                <table class="w-100" style="border-collapse: collapse;">
                                    <thead>
                                        <tr>
                                            <th><h5 class=" ">Medications Adviced</h5> </th>
                                            <th><h5 class=" ">Generic Name</h5></th>
                                            <th><h5 class=" ">Dosage</h5></th>
                                            <th><h5 class=" ">Category</h5></th>
                                            <th><h5 class=" ">Qty</h5></th>
                                            <th><h5 class=" ">Remarks</h5></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <tr *ngFor="let item of record.medicines; let i = index">
                                            <td class="min-width180px">
                                                <div>
                                                    <div class=" text-wrap text-lowercase" [textContent]="item.productName"></div>
                                                    <div class=" text-wrap text-lowercase" *ngIf="item.genericName" [textContent]="'( ' + item.genericName + ' )'"></div>
                                                </div>


                                            </td>
                                            <td class="min-width180px">
                                                <div>
                                                    <span class=" text-wrap text-lowercase" *ngIf="item.genericName" [textContent]="'( ' + item.genericName + ' )'"></span>
                                                    <span class=" text-wrap text-lowercase" *ngIf="!item.genericName" [textContent]="'--'"></span>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <span class="text-lowercase" *ngIf="item.instruction" [textContent]="item.instruction"></span>

                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <span class="text-capitalize" *ngIf="item.instruction" [textContent]="item.categoryName"></span>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <span class="text-capitalize" *ngIf="item.instruction" [textContent]="item.dosage"></span>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <span class="text-capitalize" *ngIf="item.instruction" [textContent]="item.remark"></span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="mt-2">
                        <div *ngIf="bookingHeader && bookingHeader.length > 0">
                            <h5>Investigations Ordered</h5>
                            <div class="text-lowercase" *ngFor="let item of bookingHeader;let i =index;">
                                <span class=" text-wrap" [textContent]="item.testName"></span>
                                (&nbsp;<span class=" text-wrap" [textContent]="item.departmentName"></span>&nbsp;)
                            </div>
                        </div>
                    </div>



                    <div class="mt-2">
                        <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                            <div *ngFor="let item of ordersPrescription.orderData">
                                <div *ngIf="item.orderName==='Investigation Template'">
                                    <div class="w-100" *ngIf="item.comments && item.comments !== null">

                                        <h5 class=" dashboard-title" *ngIf="item.comments && item.comments !== null" [textContent]="item.orderName"></h5>
                                        <table>
                                            <tr class="pl-1" [innerHTML]="item.comments"> </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                            <div *ngIf="ordersPrescription!=null">
                                <div *ngFor="let item of ordersPrescription.orderData">
                                    <div *ngIf="item.orderName==='Maternal Plan'">
                                        <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                            <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Plan of Management - Mother</h5>
                                            <table>
                                                <tr class="pl-1" [innerHTML]="item.comments"></tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                            <div *ngIf="ordersPrescription!=null">
                                <div *ngFor="let item of ordersPrescription.orderData">
                                    <div *ngIf="item.orderName==='Fetal Plan'">
                                        <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                            <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Plan of Management - Fetus</h5>
                                            <table>

                                                <tr class="pl-1" [innerHTML]="item.comments">
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                            <div *ngIf="ordersPrescription.orderData!=null">
                                <div *ngFor="let item of ordersPrescription.orderData">
                                    <div *ngIf="ordersPrescription.orderData">
                                        <div *ngIf="item.orderName==='Important Advice'">
                                            <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Advice</h5>
                                                <table>
                                                    <tr class="pl-1" [innerHTML]="item.comments">
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-2">
                        <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                            <div *ngIf="ordersPrescription.orderData!=null">
                                <div *ngFor="let item of ordersPrescription.orderData">
                                    <div *ngIf="ordersPrescription.orderData">
                                        <div *ngIf="item.orderName==='Nutrional Orders IP'">
                                            <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Nutrional Order</h5>
                                                <table>
                                                    <tr class="pl-1" [innerHTML]="item.comments">
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                            <div *ngIf="ordersPrescription.orderData!=null">
                                <div *ngFor="let item of ordersPrescription.orderData">
                                    <div *ngIf="ordersPrescription.orderData">
                                        <div *ngIf="item.orderName==='Diagnosis IP'">
                                            <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Diagnosis</h5>
                                                <table>
                                                    <tr class="pl-1" [innerHTML]="item.comments">
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                            <div *ngIf="ordersPrescription.orderData!=null">
                                <div *ngFor="let item of ordersPrescription.orderData">
                                    <div *ngIf="ordersPrescription.orderData">
                                        <div *ngIf="item.orderName==='Maternal Plan IP'">
                                            <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Plan of Management - Mother</h5>
                                                <table>
                                                    <tr class="pl-1" [innerHTML]="item.comments">
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                            <div *ngIf="ordersPrescription.orderData!=null">
                                <div *ngFor="let item of ordersPrescription.orderData">
                                    <div *ngIf="ordersPrescription.orderData">
                                        <div *ngIf="item.orderName==='Fetal Plan IP'">
                                            <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Plan of Management - Fetus</h5>
                                                <table>
                                                    <tr class="pl-1" [innerHTML]="item.comments">
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                            <div *ngIf="ordersPrescription.orderData!=null">
                                <div *ngFor="let item of ordersPrescription.orderData">
                                    <div *ngIf="ordersPrescription.orderData">
                                        <div *ngIf="item.orderName==='Procedure Template IP'">
                                            <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null">Procedure Template</h5>
                                                <table>
                                                    <tr class="pl-1" [innerHTML]="item.comments">
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-2">
                        <div *ngIf=" ordersPrescription && ordersPrescription.orderData.length >0 ">
                            <div *ngIf="ordersPrescription.orderData!=null">
                                <div *ngFor="let item of ordersPrescription.orderData">
                                    <div *ngIf="ordersPrescription.orderData">
                                        <div *ngIf="item.orderName==='Other Medication'">
                                            <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                <h5 class="dashboard-title " *ngIf="item.comments && item.comments !== null" [textContent]="item.orderName"></h5>
                                                <table>
                                                    <tr class="pl-1" [innerHTML]="item.comments">
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <h5>Counselling Request <span>: &nbsp;</span></h5>
                    <div *ngIf="admissionDetail && admissionDetail.counselling">
                        <span [textContent]="admissionDetail.counselling"></span>
                    </div>
                </div>
                <ng-container *ngIf="!page.loading && fullTranscript">
                    <div class="overflow-auto">
                        <table class="w-100">
                            <tr class="col-12">
                                <td class="pl-0 w-10px white-space-nowrap" *ngIf="admissionDetail && admissionDetail.consultantinformed">
                                    <h5> Information to Consultant :</h5>
                                </td>
                                <td class="w-500px ">
                                    <span class=" m-0 text-uppercase text-black" *ngIf="admissionDetail && admissionDetail.consultantinformed" [textContent]="admissionDetail.consultantinformed"></span>
                                </td>
                                <td class="w-10px " *ngIf="admissionDetail && admissionDetail.date">
                                    <h5 class="m-0  text-black">Date:</h5>
                                </td>
                                <td class="w-10px">
                                    <span class="pl-1 text-black" *ngIf="admissionDetail && admissionDetail.date" [textContent]="admissionDetail.date | date: 'dd/MM/yyyy'"></span>
                                </td>
                            </tr>

                            <tr class="col-12">
                                <td class="pl-0 w-10px" *ngIf="admissionDetail && admissionDetail.consultantopinion">
                                    <h5 class="m-0  text-black">Consultant Opinion:</h5>
                                </td>
                                <td>
                                    <span class="pl-1 text-black" *ngIf="admissionDetail && admissionDetail.consultantopinion" [textContent]="admissionDetail.consultantopinion"></span>
                                </td>

                                <td class="pl-0 w-10px">
                                    <h5 class="m-0  text-black" *ngIf="admissionDetail && admissionDetail.time">Time :</h5>
                                </td>
                                <td>
                                    <span class="pl-1" *ngIf="admissionDetail && admissionDetail.time" [textContent]="admissionDetail.time"></span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </ng-container>


                <footer class="border-dark border-top font-13 justify-content-between">
                    <div class="d-flex flex-md-nowrap flex-wrap flex-sm-nowrap align-items-end font-weight-semibold justify-content-between mt-1" style="color:black;">
                        <div class="font-14 flex-grow-1">
                            <div class=" d-flex flex-wrap">
                                <span class="pl-0 col-6">Saved on Date & Time :{{date | date : ' dd/MM/yyyy h:mm a'}} </span>        <span class="pr-0 col-5 text-uppercase">Saved by :{{name}} </span>

                                <span class="pl-0 col-6">Accessed on Date &Time : {{date | date : ' dd/MM/yyyy h:mm a'}} </span>      <span class="pr-0 col-5" *ngIf="appointment && appointment.providerName">Accessed by :{{appointment.providerName}} </span>
                            </div>
                        </div>
                        <div class="align-self-end justify-content-center d-flex flex-wrap w-200p">
                            <div class="w-100 text-center" *ngIf="appointment">
                                <img *ngIf="appointment.signature!=null" [src]="safe(appointment.signature)" cssClass="img-fluid rounded-circle" style="height:80px" />
                            </div>
                            <div class="w-100 text-center">
                                Signature
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isFooter">
                        <banner-setting [reportName]='"Admission Detail Report Footer"'></banner-setting>
                    </div>
                </footer>
            </div>
        </div>
    </div>
</div>



