import { Component, OnInit, OnDestroy, Input, ViewChild, TemplateRef } from "@angular/core";
import { IUserAccount, Page, IResource } from "@shared/models";
import { HttpService, PrintOptionService, BannerService, ResourceService, AppData, NotifyService } from "@shared/services";
import { ImageReports, Appointment } from "@shared/entities";
import { ApiResources } from "@shared/helpers";
import { finalize, takeUntil } from "rxjs/operators";
import { ScanBooking, PayTypes } from "@shared/entities";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as converter from "number-to-words";
class TrackBy {
    resource(item: IResource) {
        return item.id;
    }
}

@Component({
    templateUrl: "./new-scan-invoice.html",
    styleUrls: ['./new-scan-invoice.css'],
    selector: "new-scan-invoice",
})

export class NewSacnInvoiceWidget implements OnInit, OnDestroy {
    @Input() appointmentId: number;
    @Input() isFooter: boolean;
    @Input() isPrintLogo: boolean;
    page: Page;
    loading: boolean;
    bannerBasics: ImageReports;
    appointments: Array<Appointment>;
    ancNumber: string;
    currentDate: Date;
    loadingInvoice: boolean;
    selectedAppointment: ScanBooking;
    paidAmountInWords: string;
    relationType: string;
    constructor(
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
        private readonly bannerService: BannerService,
        private readonly appData: AppData,
        private readonly resourceService: ResourceService,
        private readonly modalService: NgbModal,
        private readonly notifyService: NotifyService,
    ) {
        this.page = new Page();
        this.appointments = new Array<Appointment>();

    }


    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;

                    this.currentDate = new Date();
                } else {
                    this.page.userAccount = undefined;
                }

            });
        this.fetchInvoice(this.appointmentId);
    }
    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
    private fetchInvoice(bookScanAppointmentId?: number) {
        this.loadingInvoice = true;
        this.httpService.get<Array<ScanBooking>>(ApiResources.getURI(ApiResources.bookScanAppointment.base, ApiResources.bookScanAppointment.fetchSelectedAppointment), { bookScanAppointmentId: bookScanAppointmentId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingInvoice = false))
            .subscribe(
                (response: Array<ScanBooking>) => {
                    this.ancNumber = null;
                    this.fetchgetAncNumber(response[0].patientId);
                    this.selectedAppointment = new ScanBooking();
                    this.selectedAppointment.payTypes = new Array<PayTypes>();
                    this.selectedAppointment = response[0];
                    console.log(this.selectedAppointment)
                    this.selectedAppointment.payTypes = response[0].payTypes;
                    this.paidAmountInWords = converter.toWords(this.selectedAppointment.paidAmount);
                    if (this.selectedAppointment.relation == "Husband") {
                        this.relationType = "W/O";
                    } else if (this.selectedAppointment.relation == "Wife") {
                        this.relationType = "H/O";
                    } else if (this.selectedAppointment.relation == "Father" && this.selectedAppointment.gender == "M") {
                        this.relationType = "S/O";
                    } else if (this.selectedAppointment.relation == "Father" && this.selectedAppointment.gender == "F") {
                        this.relationType = "D/O";
                    } else if (this.selectedAppointment.relation == "Friend") {
                        this.relationType = "F/O";
                    } else if (this.selectedAppointment.relation == "Sister" && this.selectedAppointment.gender == "F") {
                        this.relationType = "Sis/O";
                    } else if (this.selectedAppointment.relation == "Sister" && this.selectedAppointment.gender == "M") {
                        this.relationType = "B/O";
                    } else if (this.selectedAppointment.relation == "Brother" && this.selectedAppointment.gender == "F") {
                        this.relationType = "Sis/O";
                    } else if (this.selectedAppointment.relation == "Brother" && this.selectedAppointment.gender == "M") {
                        this.relationType = "B/O";
                    } else if (this.selectedAppointment.relation == "Son" && this.selectedAppointment.gender == "M") {
                        this.relationType = "F/O";
                    } else if (this.selectedAppointment.relation == "Son" && this.selectedAppointment.gender == "F") {
                        this.relationType = "M/O";
                    } else if (this.selectedAppointment.relation == "Daughter" && this.selectedAppointment.gender == "M") {
                        this.relationType = "F/O";
                    } else if (this.selectedAppointment.relation == "Daughter" && this.selectedAppointment.gender == "F") {
                        this.relationType = "M/O";
                    }
                    console.log(this.relationType)
                });

    }
    private fetchgetAncNumber(patientId) {
        this.resourceService.getAncNumber(patientId)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                if (response && response.length > 0) {
                    this.ancNumber = response[0].value;
                }
            });
    }

    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
}
