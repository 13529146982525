export class OTRegister {
    patientId: number;
    surgeryId: number;
    providerId: number;
    assitantId: number;
    anaesthetistId: number;
    anesthesiaId: number;
    nurseId: number;
    surgeryTypeId: number;
    diagnosis: string;
    remarks?: string;
    orEntryDate: Date;
    signInDate: Date;
    signOutDate: Date;
    shiftWardToDate: Date;
    caseType: string;
    anaesthesiaTypeId: number;
    operationName?: string;
    operationType?: string;
    surgeon?: string;
    patient?: string;
    assistant?: string;
    anaesthetist?: string;
    nurse?: string;
    createdByName?: string;
    modifiedByName?: string;
    otRegisterId: number;
    createdBy: number;
    loginRoleId?: number;
    anaesthesiaType?: string;
    otRoomId: number;
    roomName: string;
    locationId: number;
    status: string;
    createdDate: string;
    startTime: string;
    endTime: string;
    backgroundColor: string;
    patientName: string;
    umrNo: string;
    procedure: string;
    consultant: string;
    roomData: Array<{
        signInDate: string;
        signOutDate: string;
        formattedSignInTime?: string;
        formattedSignOutTime?: string;
    }>;
    ledgerColor: string;
    modifiedByRoleName: string;
    createdByRoleName: string;
    totalItems: number;

}

export class PatientFutureOtAppointment {
    patientId: number;
    appointmentDate: string;
    appointmentDateTime: string;
    surgeryName: string;
    locationName: string;
}