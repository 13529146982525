import { Component, ViewEncapsulation, OnInit, OnDestroy, Input } from "@angular/core";
import { IUserAccount, Page, IResource } from "@shared/models";
import { HttpService, PrintOptionService, BannerService, ResourceService, AppData } from "@shared/services";
import { ImageReports, Appointment, PayTypes, PharmacySaleBill, RetailStore } from "@shared/entities";
import { ApiResources } from "@shared/helpers";
import { finalize, takeUntil } from "rxjs/operators";
import * as numberToWords from 'number-to-words';
import * as converter from "number-to-words";

class GST {
    gst: number;
    taxableAmount: number;
    cgst: number;
    sgst: number;
    igst: number;
    discount?: number;
}
@Component({
    templateUrl: "./sale-bill-report.html",
    styleUrls: ['./sale-bill-report.widget.css'],
    selector: "sale-bill-report",
    encapsulation: ViewEncapsulation.None
})
export class SalebillReportWidget implements OnInit, OnDestroy {
    @Input() pharmacySaleHeaderId: number;   
    @Input() saleReturnHeaderId: number;   
    @Input() selectedStore: RetailStore;   
    @Input() fromFinalBill: boolean;
    @Input() paymentType: string;
    @Input() typeOfPayment: string;
    @Input() isReturnBillSelected: boolean;
    page: Page;
    loading: boolean;
    currentDate: Date;
    loadingRepotName: boolean;
    netTotal: number;
    amtInWords: string;
    subvisitAmtInWrds: string;
    QrCode: string;
    relationType: string;
    practiceLocations: Array<IResource>
    formattedText: string;
    practices: Array<string>
    loadingBill: boolean;
    gstCalculation: Array<GST>;
    bills: Array<PharmacySaleBill>;
    t = 0;
    loadingPatientRecords: boolean;
    isShow = false;

    @Input() isPrintLogo: boolean;
    @Input() isFooter:boolean;
    bannerBasics: boolean;
    patientPharmacyReports: Array<PharmacySaleBill>;
    isPrint: boolean;
    isPatientRecord = false;
    isSearchAvailable: boolean;
    loadingProviders: boolean;
    providers: Array<IResource>;
    loadingProduct: boolean;

    searchFailed = false;

    storeLoading: boolean;
    stores: Array<RetailStore>;
    accountId = 0;
    todayDate: Date;

    moduleLoading: boolean;
    disabledDiscountBox: boolean;
    patientProviders: Array<IResource>;
    selectedDoctor: IResource;
    isDischarged: boolean;

    loadingSurgeryKit: boolean;
    loadingDetail: boolean;
    isKitSelected: boolean;

    loadingStocks: boolean;
    saleType = false;

    payType: Array<IResource>;
    payTypeId: number;
    loadingPayTypes: boolean;

    encryptedAppointmentId: string;

    searching: boolean;

    isPatientSelected: boolean;
    patients: Array<IResource>;
    selectedPatient: IResource;
    totalCgst = 0;
    totalSgst = 0;
    totalIgst = 0;
    totalTaxableAmt = 0;
    amtDecimalInWords: string;
    totalDiscount = 0;
    totalNetAmount = 0;
    sendpatientId: string;
    loadingSettings: boolean;
    isSalucro: boolean;
    errorMessage: string;
    paymentInitiationId: string;
    overallAmount: number;
    productDiscount: number;
    bulkDiscount: number;
    paytm: boolean;
    hdfc = false;
    fromNotification = false;
    search = false;
    defaultDiscount: number;
    isEncryptedAppointmentId: boolean;
    reportName: string;
    isFromEncounter: boolean;
    appointmentIdGet: string;

    constructor(
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
        private readonly bannerService: BannerService,
        private readonly appData: AppData,
        private readonly resourceService: ResourceService,
    ) {
        this.page = new Page();
        this.bills = new Array<PharmacySaleBill>();
        this.currentDate = new Date();
        this.practiceLocations = new Array<IResource>();
    }

    fetchBill() {
        this.loadingBill = true;
        const request = {
            pharmacySaleHeaderId: this.pharmacySaleHeaderId,
            saleReturnHeaderId:null,
        };
        var url = ApiResources.getURI(ApiResources.pharmacy.base, ApiResources.pharmacy.fetchAddedSaleBill)
        if (this.isReturnBillSelected) {
            url = ApiResources.getURI(ApiResources.pharmacy.base, ApiResources.pharmacy.fetchAddedSaleReturnBill);
            request["saleReturnHeaderId"] = this.saleReturnHeaderId;
        }

        this.httpService.post(url, request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loadingBill = false;
            }))
            .subscribe((response: Array<PharmacySaleBill>) => {

                this.gstCalculation = new Array<GST>();
                this.bills = response;

                this.bills.forEach((item) => {
                    let checkPrevious = this.gstCalculation.findIndex(m => m.gst == parseInt(item.taxPercentage));
                    let gst = new GST();
                    gst.gst = +item.taxPercentage;
                    gst.cgst = gst.sgst = gst.igst = parseFloat((item.taxAmount / 2).toFixed(2));
                    gst.taxableAmount = item.taxableAmount = item.netAmount - item.taxAmount;
                    gst.discount = item.discount ? item.discount : 0;
                    item.cgst = item.sgst = item.igst = gst.cgst;
                    if (item.isIGST) {
                        item.sgst = 0;
                        gst.sgst = 0;
                    } else {
                        item.igst = 0;
                        gst.igst = 0;
                    }
                    this.t = this.t + item.total;
                    if (checkPrevious >= 0) {
                        let previousGST = this.gstCalculation[checkPrevious];
                        previousGST.cgst += gst.cgst;
                        previousGST.sgst += gst.sgst;
                        previousGST.igst += gst.igst;
                        previousGST.taxableAmount += gst.taxableAmount;
                        previousGST.discount += gst.discount;
                    } else {
                        this.gstCalculation.push(gst);
                    }
                });
                // m.chargeInWords = converter.toWords(bills.taxableAmount);

                this.totalGST();

            }, () => {
                this.bills = new Array<PharmacySaleBill>();
            });
    }
    totalGST() {
        if (this.gstCalculation.length > 0) {
            let gst = new GST();
            gst.gst = -1;
            gst.cgst = 0;
            gst.sgst = 0;
            gst.igst = 0;
            gst.taxableAmount = 0;
            gst.discount = 0;
            let disCnt = 0;
            this.gstCalculation.forEach((item) => {
                gst.cgst += item.cgst;
                gst.sgst += item.sgst;
                gst.igst += item.igst;
                this.totalCgst = this.totalCgst + item.cgst;
                this.totalSgst = this.totalSgst + item.sgst;
                this.totalIgst = this.totalIgst + item.igst;
                disCnt = disCnt + item.discount;
                this.totalTaxableAmt = this.totalTaxableAmt + item.taxableAmount;
                gst.taxableAmount += item.taxableAmount;
                gst.discount += item.discount;
            });
            let floatNumber = this.totalTaxableAmt;

            let x = floatNumber - Math.trunc(floatNumber);

            this.amtDecimalInWords = converter.toWords(x);
            this.totalNetAmount = this.t - disCnt;
            this.bulkDiscount = Math.round(disCnt);
            this.amtInWords = converter.toWords(this.bills[0].overallNetAmount);
            this.gstCalculation.push(gst);
        }
    }
    private fetchPracticeLocation() {
        this.loading = true;
        this.resourceService.fetchPracticeLocations()
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<IResource>) => {
                this.practiceLocations = response;
                if (this.practiceLocations && this.practiceLocations[0]) {
                    this.practiceLocations[0].optionalText3 = this.practiceLocations[0].optionalText3?.replace(/\n/g, '<br/>')
                }
            });
    }

    toTitleCase(str: string): string {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchBill();
                    this.fetchPracticeLocation();
                    this.printOptionService.get((is) => { this.isPrintLogo = is; });
                    this.bannerService.getBannerImage((is) => { this.bannerBasics = is; });
                    this.currentDate = new Date();
                } else {
                    this.page.userAccount = undefined;
                }
            });

    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
}
