import { Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewEncapsulation, TemplateRef } from "@angular/core";
import { Admission, PharmacyIndentHeader, PharmacyIndentDetail, PharmacyInPatientReturnModel, ImageReports, ModuleDepartment, RetailStore, InventoryProduct, PharmacyStocks } from "@shared/entities";
import { Page, IUserAccount, IResource, GenericResponse } from "@shared/models";
import { AppData, ResourceService, HttpService, NotifyService, BannerService } from "@shared/services";
import { ApiResources, DateHelper, UtilHelper } from "@shared/helpers";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FinalBillBasicModel } from "@admin/services/models/final-bill-basic.model";
import { takeUntil, finalize, debounceTime, distinctUntilChanged, tap, switchMap, catchError } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { NgbTypeaheadSelectItemEvent, NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpErrorResponse } from "@angular/common/http";


@Component({
    templateUrl: "./pharmacy-indent.html",
    selector: "pharmacy-indent",
    encapsulation: ViewEncapsulation.None
})

export class PharmacyIndentWidget implements OnInit, OnDestroy {
    @Input() admission: Admission;
    @Output() response = new EventEmitter<number>();
    @Output() closeModal = new EventEmitter<any>();
    @Input() totalNetAmount: number;
    today = DateHelper.ngbToday;
    isAdmission: boolean;
    page: Page;

    searching = false;
    searchFailed = false;

    modalRef: NgbModalRef;
    indentForm: FormGroup;
    submitted: boolean;
    submitting: boolean;
    // resultsType: string;
    loading: boolean;
    selectedProduct: Array<PharmacyIndentHeader>;
    selectedAppointment: any;
    headers: Array<PharmacyIndentHeader>;
    // pagination: Pagination;
    loadingDetail: boolean;
    isFinalBillLoading: boolean;
    details: Array<PharmacyIndentDetail>;
    finalBillBasics: FinalBillBasicModel;
    category: Array<IResource>;
    loadingCategory: boolean;
    isPrintLogo = false;

    loadingdata: boolean;
    productdetail: Array<PharmacyIndentHeader>;

    selectedDetail: PharmacyIndentHeader;
    returnForm: FormGroup;
    returnModel: Array<PharmacyInPatientReturnModel>;
    returnLoading: boolean;
    bannerBasics: ImageReports;
    myImgUrl = "assets/images/careaxesLogo.png";
    toDepartment: boolean;
    departments: Array<ModuleDepartment>;
    loadingDepartments: boolean;
    storeLoading: boolean;
    loadingStocks: boolean;
    loadingProduct: boolean;
    stores: Array<RetailStore>;
    retailStoreStock: Array<PharmacyStocks>;
    departmentStock: Array<PharmacyStocks>;
    products: Array<InventoryProduct>;




    constructor(
        private readonly appData: AppData,
        private readonly resourceService: ResourceService,
        private readonly modalService: NgbModal,
        private readonly formBuilder: FormBuilder,
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
        private readonly bannerService: BannerService,
    ) {
        this.page = new Page();
        if (this.admission === undefined || this.admission === null) {
            this.isAdmission = true;
        }
        this.headers = new Array<PharmacyIndentHeader>();
        this.details = new Array<PharmacyIndentDetail>();

        this.productdetail = new Array<PharmacyIndentHeader>();
        this.selectedDetail = new PharmacyIndentHeader();
        this.returnModel = new Array<PharmacyInPatientReturnModel>();
        this.departments = new Array<ModuleDepartment>();
        this.stores = new Array<RetailStore>();
        this.retailStoreStock = new Array<PharmacyStocks>();
        this.departmentStock = new Array<PharmacyStocks>();
        this.products = Array<InventoryProduct>();


    }

    onCloseModal = () => {
        this.closeModal.emit();
    }

    private fetchCategory() {
        this.loadingCategory = true;
        this.resourceService.pharmacyCategories()
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingCategory = false))
            .subscribe((response: Array<IResource>) => {
                this.category = response;
            });

    }

    getFinalBillBasics = () => {
        this.isFinalBillLoading = true;
        const request = {
            id: this.admission.admissionId.toString(),
            isAdmission: true
        }
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.finalBill.base, ApiResources.finalBill.getBasics), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.isFinalBillLoading = false; }))
            .subscribe(
                (response: GenericResponse) => {
                    this.finalBillBasics = response.data as FinalBillBasicModel;
                },
                () => {
                    this.finalBillBasics = new FinalBillBasicModel();
                }
            );
    }

    private buildForm() {
        this.indentForm = this.formBuilder.group({
            admissionId: this.admission.admissionId,
            departmentId: this.admission.departmentId,
            pharmacyProductId: [null],
            productName: [null, [Validators.required]],
            quantity: [null, [Validators.required, Validators.min(1)]],
            requiredDate: [{ value: null, disabled: true }, [Validators.required]],
            reason: [null],
            createdBy: this.page.userAccount.accountId,
            categoryId: [null],
            pharmacyDepartmentId: [null],
            retailPharmacyId: [null],
            quantityInHand:[null]
        });
        this.indentForm.patchValue({
            requiredDate: DateHelper.todayInFormat
        });
    }

    get form() {
        return this.indentForm.controls;
    }

    onSelectProduct(item: InventoryProduct) {
        if ( item != null && item.pharmacyProductId) {
            var data = item as InventoryProduct;
            if (this.toDepartment) {
                var deptStocks = this.departmentStock.filter(x => x.pharmacyProductId == data.pharmacyProductId);
                var deptQuantity = deptStocks != null ? deptStocks.reduce((sum, stock) => sum + stock.availableQuantity, 0):0;

                this.indentForm.patchValue({
                    quantityInHand:  deptQuantity
                });
            }
            else {
                var retailStocks = this.retailStoreStock.filter(x => x.pharmacyProductId == data.pharmacyProductId);
                var quantity = retailStocks != null ? retailStocks.reduce((sum, stock) => sum + stock.availableQuantity, 0):0;
                this.indentForm.patchValue({
                    quantityInHand:  quantity
                });

            }
            this.indentForm.patchValue({
                pharmacyProductId: data.pharmacyProductId,
                productName: data.productName,
                categoryId: data.categoryId
            });
        }
        else {
            this.indentForm.patchValue({
                pharmacyProductId: null,
                productName: null,
                categoryId: null,
                quantityInHand: null,
            });
        }
    }

    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }

    onGetPrescription(content: TemplateRef<any>, product?: PharmacyIndentHeader) {
        this.selectedAppointment = this.admission;
        if (product) {
            this.selectedProduct = new Array<PharmacyIndentHeader>();
            this.selectedProduct.push(product);
        }
        this.onOpenModal(content, "lg");
    }

    onOpenModal(content: TemplateRef<any>, size: string) {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: size,
            windowClass: "custom-modal invoice-modal effect-scale"
        });
    }

    onCloseTicketModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
        } catch (e) {
            // ignored;
        }

        this.submitting = undefined;
        this.submitted = undefined;
        this.selectedProduct = undefined;
        this.isPrintLogo = false;
    }

    onSubmit() {
        this.submitted = true;

        if (this.totalNetAmount > 10000000) {
            this.notifyService.info("For One Admission Limit One Crore Only");
            return;
        }
        if (this.finalBillBasics.finalAmount !== 0) {
            this.notifyService.infoToast("Pharmacy indent can not be made once the final bill is generated, please cancel the Final Bill and try again.");
            return;
        }

        if (this.toDepartment) {
            this.indentForm.get('retailPharmacyId').setValue(null);
            if (!this.indentForm.get('pharmacyDepartmentId').value) {
                this.indentForm.get('pharmacyDepartmentId').setErrors({ 'is-invalid': true });
               
            }
        }
        else {
            this.indentForm.get('pharmacyDepartmentId').setValue(null);
            if (!this.indentForm.get('retailPharmacyId').value) {
                this.indentForm.get('retailPharmacyId').setErrors({ 'is-invalid': true });
               
            }
        }


        if (!this.indentForm.valid) {
            return;
        }
        if (!this.indentForm.value.pharmacyProductId) {
            this.notifyService.warningToast("This Product Is Invalid Product");
            return;
        }

        const product = this.indentForm.get("productName").value;
        const exists = this.productdetail?.find(x => x.issueProductName === product && (x.status === "A" || x.status === "P" || x.status === "PA"));
        if (exists) {
            this.notifyService.confirm(`${exists.issueProductName} already exists, do you want to add again?`,
                () => {
                    this.onSubmitIndentRequest();
                });
        }
        else {
            this.onSubmitIndentRequest();
        }
    }

    onSubmitIndentRequest() {
        this.submitting = true;

        const request = this.indentForm.getRawValue();
        request["quantity"] = parseInt(request["quantity"]);
        request["createdBy"] = this.page.userAccount.accountId;
        request["createdByName"] = this.page.userAccount.fullName;
        request["roleId"] = this.page.userAccount.roleId;
        request["roleName"] = this.page.userAccount.roleName;

        if ((request["productName"] as IResource).value) {
            request["productName"] = request["productName"].value;
        }
        request["admissionId"] = this.admission.admissionId;
        request["doctorUnitMasterId"] = this.admission.doctorUnitMasterId;

        this.httpService
            .post(ApiResources.getURI(ApiResources.pharmacyRequest.base, ApiResources.pharmacyRequest.addIndent),
                request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.submitted = this.submitting = false;
            }))
            .subscribe({
                next: (response: number) => {
                    if (response > 0) {
                        this.notifyService.successToast("Indent raised successfully.");
                    }
                    this.buildForm();
                    // this.onFetchHeaders();
                    this.onFetchProductDetails();
                },
                error: (error: any) => {
                    this.notifyService.errorToast("Error occurred while raising indent")
                }
            });
    }

    onFetchProductDetails() {
        this.loadingdata = true;
        const request = {
            admissionId: this.admission.admissionId,
        };

        request["requestDate"] = null;
        this.httpService.post(ApiResources.getURI(ApiResources.pharmacyRequest.base, ApiResources.pharmacyRequest.fetchIndent), request) ///fetchng
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loadingdata = false;
            }))
            .subscribe({
                next: (response: Array<PharmacyIndentHeader>) => {
                    this.productdetail = response.filter(x => x.status != 'C');

                },
                error: () => {
                    this.productdetail = Array<PharmacyIndentHeader>();
                }
            });
    }

    onDeleteRequest(item: PharmacyIndentHeader) {
        this.notifyService.delete(() => {
            const request = {
                pharmacyIndentHeaderId: item.pharmacyIndentHeaderId,
                admissionId: this.admission.admissionId,
                createdBy: this.page.userAccount.accountId,
                roleId: this.page.userAccount.roleId,
                createdByName: this.page.userAccount.fullName,
                quantity: item.quantity,
                PharmacyProductId: item.pharmacyProductId,
                pharmacyIndentDetailId: item.pharmacyIndentDetailId,
            }
            this.httpService.post(ApiResources.getURI(ApiResources.pharmacyRequest.base, ApiResources.pharmacyRequest.deleteIndent), request)
                .pipe(takeUntil(this.page.unSubscribe))
                .subscribe({
                    next: (response: number) => {
                        if (response > 0) {
                            this.notifyService.successToast("Cancelled successfully");
                        } else {
                            this.notifyService.warning("Unable to Cancel indent request.");
                        }
                        //this.onFetchHeaders();
                        this.onFetchProductDetails();
                    },
                    error: () => {
                        this.notifyService.defaultError();
                    }
                });
        })
    }

    onOpenReturnForm(content: TemplateRef<any>, product: PharmacyIndentHeader) {
        this.selectedDetail = product;
        this.buildReturnForm();
        this.fetchReturn();
        this.onOpenModal(content, "lg");
    }

    private buildReturnForm() {
        let returnQty = 0;
        if (UtilHelper.isEmpty(this.selectedDetail.returnQuantity)) {
            returnQty = this.selectedDetail.returnQuantity;
        }

        this.returnForm = this.formBuilder.group({
            pharmacyInPatientReturnId: 0,
            pharmacyIssueDetailId: this.selectedDetail.pharmacyIssueDetailId,
            available: this.selectedDetail.issueQuantity - returnQty,
            returnQuantity: [null, [Validators.required, Validators.min(1), Validators.max(this.selectedDetail.issueQuantity - returnQty)]],
            createdBy: this.page.userAccount.accountId
        });
    }

    get formReturn() {
        return this.returnForm.controls;
    }

    onSubmitReturn() {
        this.submitted = true;
        if (this.finalBillBasics.finalAmount !== 0) {
            this.notifyService.infoToast("Pharmacy return can not be made once the final bill is generated, please cancel the Final Bill and try again.");
            return;
        }

        if (this.returnForm.invalid) {
            return;
        }

        const request = this.returnForm.getRawValue();

        this.submitting = true;

        this.httpService.post(ApiResources.getURI(ApiResources.pharmacyRequest.base, ApiResources.pharmacyRequest.addReturnRequest), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.submitted = this.submitting = false;
            }))
            .subscribe((response: number) => {
                if (response > 0) {
                    this.notifyService.successToast("Return request raised successfully.");
                }
                this.onCloseTicketModal();
                this.onFetchProductDetails();
            });
    }

    private fetchReturn() {
        const request = {
            id: this.selectedDetail.pharmacyIssueDetailId
        };

        this.returnLoading = true;

        this.httpService.get(ApiResources.getURI(ApiResources.pharmacyRequest.base, ApiResources.pharmacyRequest.fetchReturn), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.returnLoading = false;
            }))
            .subscribe((response: Array<PharmacyInPatientReturnModel>) => {
                this.returnModel = response;
            }, (error: HttpErrorResponse) => {
                this.returnModel = new Array<PharmacyInPatientReturnModel>();
                let err = UtilHelper.handleError(error);
                if (err) {
                    this.notifyService.errorToast(err);
                }
                else {
                    this.notifyService.defaultErrorToast();
                }
            });
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchCategory();
                    this.getFinalBillBasics();
                    this.buildForm();
                    this.bannerService.getBannerImage((is) => { this.bannerBasics = is; });
                    //this.onFetchHeaders();
                    this.onFetchProductDetails();
                    this.fetchDepartments();
                    this.fetchRetailStores();
                } else {
                    this.page.userAccount = undefined;
                 
                }
            });
    }
    

    formatMatches = (item: IResource) => item.value || "";
    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(() => this.searching = true),
            switchMap((term: string) =>
                term.length < 1 ? of([]) : this.resourceService
                    .pharmacyProducts(this.indentForm.value.categoryId, term)
                    .pipe(
                        tap(() => this.searchFailed = false),
                        catchError(() => {
                            this.searchFailed = true;
                            return of([]);
                        })
                    )
            ),
            tap(() => this.searching = false)
        );

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }

    private fetchDepartments() {
        this.loadingDepartments = true;
        const request = {
            active: true,
        };
        this.httpService.post(ApiResources.getURI(ApiResources.pharmacyDepartment.base, ApiResources.pharmacyDepartment.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loadingDepartments = false }))
            .subscribe((response: Array<ModuleDepartment>) => {
                this.departments = response;
            }, (error: HttpErrorResponse) => {
                this.notifyService.errorToast(error.error);
            });
    }

    onDepartmentSelect() {
        if (this.toDepartment) {
            this.indentForm.get('pharmacyDepartmentId').setValidators([Validators.required]);
            this.indentForm.get('pharmacyDepartmentId').updateValueAndValidity();
        }
        else {
            this.indentForm.get('pharmacyDepartmentId').clearValidators();
            this.indentForm.get('pharmacyDepartmentId').updateValueAndValidity();

        }
    }

    private fetchRetailStores() {
        this.storeLoading = true;
        let request = {
           
            active: true
        };

        this.httpService.post(ApiResources.getURI(ApiResources.pharmacy.base, ApiResources.pharmacy.fetchAllRetailStore), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.storeLoading = false }))
            .subscribe((response: Array<RetailStore>) => {
                this.stores = response;
            });
    }

    onSelectRetailStore(item: RetailStore) {
        if (item != null && item.retailPharmacyId != null) {
            this.loadingStocks = true;
            var request = {
                retailPharmacyId: this.indentForm.value.retailPharmacyId,
            }

            this.httpService.post(ApiResources.getURI(ApiResources.pharmacy.base, ApiResources.pharmacy.fetchRetailStock), request)
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => { this.loadingStocks = false }))
                .subscribe((response: Array<PharmacyStocks>) => {
                    this.retailStoreStock = response;
                    if (this.retailStoreStock && this.retailStoreStock.length > 0) {
                        if (this.indentForm.value.pharmacyProductId != null) {
                            var retailStocks = this.retailStoreStock.filter(x => x.pharmacyProductId == this.indentForm.value.pharmacyProductId);
                            var quantity = retailStocks != null ? retailStocks.reduce((sum, stock) => sum + stock.availableQuantity, 0):0;
                            this.indentForm.patchValue({
                                quantityInHand:  quantity
                            });

                        }
                    }
                });

        }
        else {
            this.indentForm.patchValue({
              
                quantityInHand: null,
            });
        }
       
            
    }


    onSelectDepartment(item: ModuleDepartment) {
        if (item != null && !item.pharmacyDepartmentId) {
            this.loadingStocks = true;
            var request = {
                pharmacyDepartmentId: this.indentForm.value.pharmacyDepartmentId,
            }

            this.httpService.post(ApiResources.getURI(ApiResources.pharmacyDepartment.base, ApiResources.pharmacyDepartment.fetchStock), request)
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => { this.loadingStocks = false }))
                .subscribe((response: Array<PharmacyStocks>) => {
                    this.departmentStock = response;

                    if (this.departmentStock && this.departmentStock.length > 0) {
                        if (this.indentForm.value.pharmacyProductId != null) {
                            var deptStocks = this.departmentStock.filter(x => x.pharmacyProductId == this.indentForm.value.pharmacyProductId);
                            var quantity = deptStocks!=null?deptStocks.reduce((sum, stock) => sum + stock.availableQuantity, 0): 0;
                            this.indentForm.patchValue({
                                quantityInHand: quantity
                            });

                        }
                    }
                });
        }
        else {
            this.indentForm.patchValue({
                quantityInHand: null,
            });
        }

    }

    fetchProductsHelper = ($event: any) => {
        if (this.toDepartment) {
            if (!this.indentForm.value.pharmacyDepartmentId) {
                this.notifyService.warning("Please Select Department.")
            }
        }
        else {
            if (!this.indentForm.value.retailPharmacyId) {
                this.notifyService.warning("Please Select Retail Store.")
            }

        }
        let categoryId = this.indentForm.get("categoryId").value || null;
        this.fetchPharmacyProducts(categoryId, $event.term);
    }



    fetchPharmacyProducts(id?: number, name?: string) {
        const request = {
            categoryId: id,
            productName: name,
            pageSize: 15,
            pageIndex: 1,
            isPharmaItem: true,
            isGeneralItem: false,
            fromProduct:false,
        };

        this.loadingProduct = true;

        this.httpService.post(ApiResources.getURI(ApiResources.pharmacy.base, ApiResources.pharmacy.fetchProduct), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loadingProduct = false;
            }))
            .subscribe((response: Array<InventoryProduct>) => {
                this.products = response;
            }, () => {
                this.products = Array<InventoryProduct>();
            });
    }
    
}