import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class TimelineWidgetService {
    private source = new BehaviorSubject<boolean>(true);
    get: Observable<boolean> = this.source.asObservable();
    set(isRefresh: boolean) {
        this.source.next(isRefresh);
    }

    private finalBill = new BehaviorSubject<boolean>(true);
    getfinalBill: Observable<boolean> = this.finalBill.asObservable();
    setfinalBill(isRefresh: boolean) {
        this.finalBill.next(isRefresh);
    }   
}