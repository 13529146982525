<div class="report">
    <div *ngIf="isPrintLogo && !isReturnBillSelected" class="pt-2 border-bottom-2px border-dark">
        <banner-setting [reportName]='"Sale Bill Header"'></banner-setting>
    </div>
    <div *ngIf="isPrintLogo && isReturnBillSelected" class="pt-2 border-bottom-2px border-dark">
        <banner-setting [reportName]='"Sale Bill Return"'></banner-setting>
    </div>
    <div *ngIf="!isPrintLogo" class="p-0">
        <div>
            <pharmacy-header></pharmacy-header>
        </div>
    </div>
    <div class=" mt-2 position-relative">
        <div class="pb-2 pl-2">
            <div class="row ">
                <div class="col pr-0 pl-0">
                    <table width="100%" class="table-custom">
                        <tr>
                            <th class="px-2 w-10px ">
                                <span class="white-space-nowrap">
                                    Patient Name
                                </span>
                            </th>
                            <th>
                                <span class="margin-Right">: </span>
                            </th>
                            <td>
                                <h5 class="text-uppercase" [textContent]="bills[0] && bills[0].patientName"></h5>
                            </td>
                        </tr>
                        <tr>
                            <th class="px-2  text-left  paddingLeft  ">
                                <div class="white-space-nowrap ">
                                    Doctor Name
                                </div>
                            </th>
                            <th>
                                <span class="margin-Right">: </span>
                            </th>
                            <td>
                                <h5 [textContent]="bills[0] && bills[0].providerName "></h5>
                            </td>
                        </tr>
                        <tr *ngIf="fromFinalBill">
                            <th class="px-2  text-left  paddingLeft  ">
                                <div class="white-space-nowrap ">
                                    Mode Of Payment
                                </div>
                            </th>
                            <th>
                                <span class="margin-Right">: </span>
                            </th>
                            <td>
                                <h5 [textContent]="paymentType "></h5>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col pl-0">
                    <table width="100%" class="table-custom">
                        <tr class="mb-2 ">
                            <th>
                                <div class="white-space-nowrap">
                                    UMR No
                                </div>
                            </th>
                            <th>
                                <span class="margin-Right">: </span>
                            </th>
                            <td>
                                <h5 [textContent]="bills[0] &&  bills[0].umrNo || '-----'"></h5>
                            </td>
                            <th>
                                <div class="white-space-nowrap">
                                    Bill No
                                </div>
                            </th>
                            <th>
                                <span class="margin-Right">: </span>
                            </th>
                            <td>
                                <h5 [textContent]="bills[0] &&  bills[0].billNumber"></h5>
                            </td>

                        </tr>
                        <tr>
                            <th class="w-10px white-space-nowrap pl-8">
                                <div>
                                    D L No
                                </div>
                            </th>
                            <th>
                                <h5 class="margin-Right">: </h5>
                            </th>
                            <td>
                              <span  [textContent]="bills[0]?.dlNumber"></span>
                            </td>
                            <th class=" pl-8  text-left">
                                <div>
                                    Date
                                </div>
                            </th>
                            <th>
                                <span class="margin-Right">: </span>
                            </th>
                            <td>
                                <h5 [textContent]="bills[0] && bills[0].saleDate | date:'dd/MM/yyyy'"></h5>
                            </td>
                        </tr>
                        <tr *ngIf="fromFinalBill">
                            <th class="w-10px white-space-nowrap pl-8">
                                <div>
                                    GST NO
                                </div>
                            </th>
                            <th>
                                <h5 class="margin-Right">: </h5>
                            </th>
                            <td>
                                <span [textContent]="bills[0] && bills[0].gstNumber"></span>

                            </td>

                        </tr>

                    </table>
                </div>
            </div>
        </div>

    </div>
    <div class="overflow-auto border-bottom-2px border-dark">
        <table width="100%" class="custom-table border-collapse position-relative">
            <tbody>
                <tr class=" border-bottom border-dark border-top font-weight-bolder">
                    <th class="p-0 text-center vertical-align-middle"><h5 class="border-dark border-right h-40px pb-1 pl-1 pr-1 w-100 font-14">S.No.</h5></th>
                    <th class="p-0 text-center vertical-align-middle"><h5 class="border-dark border-right h-40px pb-1 pl-1 pr-1 w-100 font-14">HSN Code</h5></th>
                    <th class="p-0 text-center vertical-align-middle"><h5 class="border-dark border-right h-40px pb-1 pl-1 pr-1 w-100 font-14 white-space-nowrap">Medicine Name</h5></th>
                    <th class="p-0 text-center vertical-align-middle"><h5 class="border-dark border-right h-40px pb-1 pl-1 pr-1 w-100 font-14">SH</h5></th>
                    <th class="p-0 text-center vertical-align-middle"><h5 class="border-dark border-right h-40px pb-1 pl-1 pr-1 w-100 font-14">Mfg</h5></th>
                    <th class="p-0 text-center vertical-align-middle"><h5 class="border-dark border-right h-40px pb-1 pl-1 pr-1 w-100 font-14">Batch</h5></th>
                    <th class="p-0 text-center vertical-align-middle"><h5 class="border-dark border-right h-40px pb-1 pl-1 pr-1 w-100 font-14">Exp Dt</h5></th>
                    <th class="p-0 text-center vertical-align-middle"><h5 class="border-dark border-right h-40px pb-1 pl-1 pr-1 w-100 font-14">Qty</h5></th>
                    <th class="p-0 text-center vertical-align-middle"><h5 class="border-dark border-right h-40px pb-1 pl-1 pr-1 w-100 font-14">Dis</h5></th>
                    <th class="p-0 text-center vertical-align-middle"><h5 class="border-dark border-right h-40px pb-1 pl-1 pr-1 w-100 font-14">MRP</h5></th>
                    <th class="p-0 text-center vertical-align-middle"><h5 class="border-dark border-right h-40px pb-1 pl-1 pr-1 w-100 font-14">Taxable Amt.</h5></th>
                    <th class="p-0 text-center vertical-align-middle"><h5 class="border-dark border-right h-40px pb-1 pl-1 pr-1 w-100 font-14">CGST Amt.</h5></th>
                    <th class="p-0 text-center vertical-align-middle"><h5 class="border-dark border-right h-40px pb-1 pl-1 pr-1 w-100 font-14">SGST Amt.</h5></th>
                    <th class="p-0 text-center vertical-align-middle"><h5 class="h-40px pl-1 pr-1 w-100 font-14">Amount</h5></th>
                </tr>

                <tr *ngFor="let bill of bills;index as i;" class=" vertical-align-top">

                    <td class="px-1 text-center"><span class="font-14">{{i+1}}</span></td>
                    <td class="px-1 "><span class="font-14" [textContent]="bill.hsnCode"></span></td>
                    <td class="px-1 text-uppercase" style="width: 100px;"><div class="font-14" [textContent]="bill.productName"></div></td>
                    <td class="px-1 "><span class="font-14" [textContent]="bill.scheduledDrug"></span></td>
                    <td class="px-1 text-uppercase" style="width: 100px;"><div class="w-100px text-overflow-ellipsis font-14" [textContent]="bill.companyName"></div></td>
                    <td class="px-1 "><span class="font-14" [textContent]="bill.batchNumber"></span></td>
                    <td class="px-1 "><span class="font-14" [textContent]="bill.expiryDate | date:'dd/MM/yyyy'"></span></td>



                    <td class="px-1 text-center"><span [textContent]="bill.quantity"></span></td>
                    <td class="px-1"><span [textContent]="(bill.discount || 0)"></span></td>
                    <td class="px-1"><span [textContent]="(bill.mrp || 0)"></span></td>
                    <td class="px-1 "><span [textContent]="(bill.netAmount-bill.cgst-bill.sgst-bill.igst   || 0) | number: '1.2-2'"></span></td>
                    <td class="px-1 ">
                        <span class="font-14" [textContent]="bill.cgst "></span>
                    </td>
                    <td class="px-1">
                        <span class="font-14" [textContent]="bill.sgst "></span>
                    </td>
                    <td class="px-1"><span class="font-14" [textContent]="(bill.netAmount || 0) | number:'1.2-2'"></span></td>

                    <td *ngIf="bill.paymentType === 'P'" class="justify-content-center d-flex">
                        <div id="backgroundOfwaterMark" style="-webkit-transform: rotate(-30deg);"> <pre>Partial Paid</pre></div>
                    </td>
                </tr>
                <tr class="fontbold vertical-align-top ">
                    <td colspan="7" class="px-1; text-left border-top  border-dark border-bottom">
                        <h5 class="text-capitalize" [textContent]="'Rupees : ' + amtInWords + ' Only'">  &nbsp;&nbsp; </h5>
                    </td>
                    <td colspan="3" class="border-top px-1 border-bottom border-dark text-center">
                        <h5 [textContent]="'Sub Total : '"></h5>
                    </td>
                    <td class="border-top px-1 border-bottom border-dark text-left">
                        <div *ngIf="gstCalculation && gstCalculation[gstCalculation.length-1].gst === -1">
                            <h5 [textContent]="(gstCalculation[gstCalculation.length-1].taxableAmount) | number:'1.2-2'"></h5>
                        </div>
                    </td>
                    <td class="border-top px-1 border-bottom border-dark text-left">
                        <div *ngIf="gstCalculation && gstCalculation[gstCalculation.length-1].gst === -1">
                            <h5 [textContent]="gstCalculation[gstCalculation.length-1].cgst | number:'1.2-2'"></h5>
                        </div>
                    </td>

                    <td class="border-top px-1 border-bottom border-dark text-left">
                        <div *ngIf="gstCalculation && gstCalculation[gstCalculation.length-1].gst === -1">
                            <h5 [textContent]="gstCalculation[gstCalculation.length-1].sgst | number:'1.2-2'"></h5>
                        </div>
                    </td>
                    <td class="px-1 text-left px-1 border-top border-bottom border-dark text-left">
                        <h5 [textContent]="bills[0] && bills[0].overallNetAmount | number:'1.2-2'"></h5>
                    </td>
                </tr>
                <tr *ngIf="bills[0] && bills[0].payTypes &&  bills[0].payTypes.length>1">

                    <td  colspan="6" >
                        <table class="py-2">
                            <tr>
                                <th class="bg-white text-left">
                                    <div>
                                        <h5> Mode Of Payment</h5>
                                    </div>
                                </th>
                            </tr>
                            <tr class="font-weight-bolder">
                                <td class="border-bottom border-dark">
                                    <h5>PaymentType</h5>
                                </td>
                                <td class="border-bottom border-dark">
                                    <h5>AmountPaid</h5> 
                                </td>
                            </tr>
                            <tr *ngFor="let payment of bills[0].payTypes">
                                <td class="px-1 text-left ">
                                    <span [textContent]="payment.payTypeName"></span>
                                </td>
                                <td class="px-1 text-right ">
                                    <span [textContent]="(payment.amountPaid | currency: 'INR')"></span>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left border-bottom border-dark " colspan="7"><span [textContent]="'Note: Goods once sold will not be taken back'"></span></td>
                            </tr>
                        </table>
                    </td>
                    </tr>
                <tr class=" vertical-align-top mt-2">
                    <td class="text-left  border-dark pb-0" colspan="11" *ngIf="practiceLocations[0] && practiceLocations[0].optionalText3">
                        <span class="allowwrap">
                            Note: <span [innerHTML]="practiceLocations[0].optionalText3"></span>
                        </span>
                    </td>
                    <td class="paddingTop  border-dark px-1 text-right fontbold  pb-0" colspan="2">
                        <h5 [textContent]="'Total Sale :'"></h5>
                        <h5 *ngIf="fromFinalBill && !isReturnBillSelected" [textContent]="'Paid Amt :'"></h5>
                    </td>
                    <td colspan="1" class="px-1 text-left  px-1 border-dark text-left pb-0" *ngIf="bills[0]">

                        <h5 [textContent]="'   ' + bills[0].overallNetAmount | number:'1.2-2'"></h5>
                        <h5 *ngIf="fromFinalBill && !isReturnBillSelected" [textContent]="'   ' + bills[0].paidAmount | number:'1.2-2'"></h5>

                    </td>
                </tr>
                <tr class="fontbold vertical-align-top  ">
                    <td class="text-left  border-dark" colspan="11">
                    </td>
                    <td class="paddingTop  border-dark px-1 text-right netsalecolour pt-0 pb-0" colspan="2">
                        <h5 class="text-color-darkblue" [textContent]="'Net Sale:'"></h5>
                    </td>
                    <td colspan="1" class="px-1 text-left  px-1 border-dark text-left  netsalecolour pt-0 pb-0" *ngIf="bills[0]">

                        <h5 class="text-color-darkblue" [textContent]="('   ' + bills[0].overallNetAmount) | number:'1.2-2'"></h5>

                    </td>
                </tr>


            </tbody>
        </table>        
        <table>
            <tr class="col-12">

                <td>
                    <div>
                        <practice-content></practice-content>
                    </div>
                </td>
            </tr>

        </table>

        <div class="col-12 p-0 overflow-auto font-12 font-weight-bold d-flex justify-content-end column-gap15px p-1">
            <span [textContent]="'Print Date : ' + (currentDate | date:'dd/MM/yyyy, hh:mm a')" class="marginleft mr-4"></span>
            <span [textContent]="page.userAccount?.fullName || ''"></span>
        </div>
    </div>
    <div *ngIf="isFooter">
        <banner-setting [reportName]='"Sale Bill Footer"'></banner-setting>
    </div>
</div>