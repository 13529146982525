import { Component, ViewEncapsulation, OnInit, OnDestroy, Input, ElementRef, ViewChild } from "@angular/core";
import { Params, ActivatedRoute, Router } from "@angular/router";
import { takeUntil, finalize } from "rxjs/operators";
import { TimelineToggleService, AppointmentToggleService, AppConfig, PrintOptionService, IconService, HttpService } from "@shared/services";
import { Time } from "@angular/common";
import { ImageReports } from "../../entities/image.entity";
import { DietEncounter } from "../../models/diet-encounter.model";
import { Page } from "../../models/page.model";
import { PatientProfile } from "../../models/patient-profile.model";
import { Diet } from "../../../areas/admin/full-transcript/pages/diet-report/diet.namespace";
import { Appointment } from "../../entities/appointment.entity";
import { OB } from "../../../areas/admin/full-transcript/pages/ob-report/ob.namespace";
import { PatientDocument } from "../../entities/patient-document.entity";
import { PatientMedicationHeader } from "../../entities/patient-medication.entity";
import { PreviousAppointment } from "../../entities/previous-appointment.entity";
import { Pagination } from "../../models/pagination.model";
import { LabBillHeaderModel } from "../../../areas/admin/labs/pages/models/lab-booking-model";
import { IEncounterResource } from "../../models/encounter-resource.model";
import { ApiResources } from "../../helpers/api.helper";

class FilterOptions {
    encryptedAppointmentId: string = null;
}

class Filters {
    options: FilterOptions;
    applied: boolean;

    constructor() {
        this.init();
    }

    init() {
        this.options = new FilterOptions();
        this.applied = undefined;
    }
}
class OrderPrescriptionModel {
    appoitmentId: number;
    orderPrescription: string;
    appointmentDate: Date;
    appointmentId: string;
    appointmentTime: Time;
    providerName: string;

}
@Component({
    templateUrl: "./diet-full-report.html",
    encapsulation: ViewEncapsulation.None,
    selector: "diet-fullreport"
})
export class DietFullReport implements OnInit, OnDestroy {
    @Input() printId: string;
    @Input() printadmission: boolean;
    private specialFeatureSection: ElementRef;
    measure: any;
    allergies: any;
    problemList: any;
    antinantalRisk: any;
    procedureSurgery: any;
    familyHistory: any;
    isPrintLogo: boolean;
    iconBasics: ImageReports;
    data: any;
    isPastMedicalHistory: boolean
    isFamilyHistory: boolean
    isProcedureSurgery: boolean;
    isFooter: boolean;
    @ViewChild("specialFeatureSection", { static: false }) set specialFeatureSectionContent(content: ElementRef) {
        if (content) {
            this.specialFeatureSection = content;
            if (this.section && this.section === "specialFeatureSection") {
                // this.scrollService.scrollTo(this.specialFeatureSection.nativeElement, 1500, -100);
            }
        }
    }

    fullTranscript: DietEncounter;
    page: Page;
    appointmentId: string;
    section: string;
    isAdmission = false;
    filters: Filters;
    routingValue: string;
    // encryptedPatientId: string;
    patient: PatientProfile;
    dietPlanPrescribed: Diet.DietPlanPrescribed;
    pediatricCDCScreeningForm: Diet.PediatricCDCScreening;
    opNutritionScreeningForm: Diet.OpNutritionScreening;
    opNutritionalAssessmentForm: Diet.OPNutritionalAssessment;
    syndromesFormP: Diet.SyndromesFormP;
    outSideTests: any
    dietEncounter: DietEncounter;
    appointments: Array<Appointment>;
    appointment: DietEncounter;

    ipNutritionalScreeningForm: Diet.IPNutritionalAssessment;
    orders: Array<OrderPrescriptionModel>;

    orderPrescription: OB.OrdersPrescription;
    // dietEncounter: DietEncounter
    dietGuideLines: Array<any>;
    isDietGuideHistory = false;
    records: Array<PatientMedicationHeader>;
    loading: boolean;
    documents: Array<PatientDocument>;
    oldAppointment: PreviousAppointment;
    flag = true;
    patientId: number;
    patientDocumentId: number;
    pagination: Pagination;
    referralForm: Array<OB.ReferralForm>;
    addendumForm: OB.AddendumForm;
    specialFeature: OB.SpecialFeature;
    bookingHeader: Array<LabBillHeaderModel>;
    @Input() encryptedPatientId: string;
    @Input() encryptedAppointmentId: string;
    dietEncounters: IEncounterResource;
    referralOrder: Array<OB.OrderReferral>;
    reminders: string;
    useExistingCss: boolean;
    diseaseName: any
    yearOfDiagnosis: any
    icd: any
    status: any
    treatmentHistory: any;
    telephoneTriageAssessmentdata: Array<any>;
    constructor(
        private readonly route: ActivatedRoute,
        private readonly httpService: HttpService,
        public timelineService: TimelineToggleService,
        private readonly toggleService: AppointmentToggleService,
        private readonly router: Router,
        private readonly printOptionService: PrintOptionService,
        private readonly iconService: IconService,
    ) {

        this.page = new Page();
        this.appointments = [];
        this.orders = new Array<OrderPrescriptionModel>();
        this.telephoneTriageAssessmentdata = Array<any>();
    }


    private findPatient() {
        this.page.loading = true;
        const request = { encryptedAppointmentId: this.appointmentId };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<DietEncounter>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: DietEncounter) => {
                this.appointment = response;
                this.encryptedPatientId = response.encryptedPatientId;
                this.findReport();
                this.fetchLabs();
                this.fetchMedication();
                this.findreferralForm(this.appointment.patientId, this.appointment.appointmentId);
                this.fetchalltelephonetriage(this.appointment.appointmentId, this.appointment.patientId)
            });
    }



    private findReport() {
        const request = { encryptedAppointmentId: this.appointmentId, encryptedPatientId: this.encryptedPatientId };
        request["isAdmission"] = this.isAdmission;

        this.httpService.post<DietEncounter>(ApiResources.getURI(ApiResources.dietEncounter.base, ApiResources.dietEncounter.findFullTrascript), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: DietEncounter) => {
                this.fullTranscript = response;
                this.appointments = response["appointments"];
                this.oldAppointment = response["oldAppointment"];
                if (this.fullTranscript && this.fullTranscript.dietEncounterId) {
                    this.outSideTests = this.fullTranscript.outSideTests ? JSON.parse(this.fullTranscript.outSideTests) : null;
                    this.dietPlanPrescribed = this.fullTranscript.dietPlanPrescribed ? JSON.parse(this.fullTranscript.dietPlanPrescribed) : null;
                    this.opNutritionScreeningForm = this.fullTranscript.opNutritionScreeningForm ? JSON.parse(this.fullTranscript.opNutritionScreeningForm) : null;
                    this.opNutritionalAssessmentForm = this.fullTranscript.opNutritionalAssessmentForm ? JSON.parse(this.fullTranscript.opNutritionalAssessmentForm) : null;
                    this.syndromesFormP = this.fullTranscript.syndromesFormP ? JSON.parse(this.fullTranscript.syndromesFormP) : null;
                    this.dietGuideLines = this.fullTranscript.dietGuideLines ? JSON.parse(this.fullTranscript.dietGuideLines) : null;
                    if (this.fullTranscript.dietGuideLines && this.dietGuideLines.length == 0) {
                        this.isDietGuideHistory = true;
                    }
                    this.ipNutritionalScreeningForm = this.fullTranscript.ipNutritionalScreeningForm ? JSON.parse(this.fullTranscript.ipNutritionalScreeningForm) : null;
                    this.pediatricCDCScreeningForm = this.fullTranscript.pediatricCDCScreeningForm ? JSON.parse(this.fullTranscript.pediatricCDCScreeningForm) : null;
                    this.orderPrescription = this.fullTranscript.orderPrescription ? JSON.parse(this.fullTranscript.orderPrescription) : null;
                    this.addendumForm = this.fullTranscript.addendumForm ? JSON.parse(this.fullTranscript.addendumForm) : null;
                    this.specialFeature = this.fullTranscript.specialFeature ? JSON.parse(this.fullTranscript.specialFeature) : null;
                    this.referralOrder = this.fullTranscript.refferalOrder ? JSON.parse(this.fullTranscript.refferalOrder) : null;
                    this.reminders = this.fullTranscript.reminder ? this.fullTranscript.reminder : null;
                    this.measure = this.fullTranscript.measure ? JSON.parse(this.fullTranscript.measure) : null;
                    this.allergies = this.fullTranscript.allergies ? JSON.parse(this.fullTranscript.allergies) : null;
                    this.procedureSurgery = this.fullTranscript.surgeries ? JSON.parse(this.fullTranscript.surgeries) : null;
                    this.problemList = this.fullTranscript.problemList ? JSON.parse(this.fullTranscript.problemList) : null;
                    this.antinantalRisk = this.fullTranscript.antinantalRisk ? JSON.parse(this.fullTranscript.antinantalRisk) : null;
                    this.familyHistory = this.fullTranscript.familyHistory ? JSON.parse(this.fullTranscript.familyHistory) : null;
                    if (this.fullTranscript.familyHistory && this.familyHistory.length == 0) {
                        this.isFamilyHistory = true;
                    }
                    if (this.fullTranscript.surgeries && this.procedureSurgery.length == 0) {
                        this.isProcedureSurgery = true;
                    }
                }
                if (this.problemList) {
                    this.data = this.problemList["problems"]
                    if (this.data.length == 0) {
                        this.isPastMedicalHistory = true
                    }
                }
            });

    }
    private fetchLabs() {
        this.loading = true;
        const request = {
            appointmentId: this.appointment.appointmentId,
            isAdmission: this.isAdmission
        }
        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetchLabs), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                if (response && response.length > 0) {
                    this.bookingHeader = response[0].labs;
                }
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });
    }

    private fetchMedication() {
        this.loading = true;
        const request = {
            encryptedAppointmentId: this.appointmentId
        }

        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                this.records = response;
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });

    }
    ngOnInit() {
        const url = this.router.url;
        this.appointmentId = decodeURIComponent(url.split("/")[3]);
        this.routingValue = url.split("/")[2]
        this.isAdmission = url.split("/")[4] === "a";

        this.appointmentId = this.printId
        this.isAdmission = this.printadmission
        this.route.params
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((params: Params) => {

                if (params["value"] == 'gyn-ip-encounter') {
                    this.isAdmission = true;
                }
                this.findPatient();
                this.fetchMedication();

                //this.findSpeacialFeature();
                // this.fetchVitals();
                //  this.fetchMedication();
                this.route.params
                    .pipe(takeUntil(this.page.unSubscribe))
                    .subscribe((params: Params) => {
                        const section = params["section"];
                        this.section = section ? section : undefined;
                    });

            });
        this.printOptionService.get((is) => { this.isPrintLogo = is; });
        this.iconService.getIconImage((is) => { this.iconBasics = is; });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }

    appointmentToggle() {
        this.toggleService.toggle(true);
    }

    timelineToggle() {
        this.timelineService.toggle();
    }
    private findreferralForm(id: any, appointmentId: any) {
        this.loading = true;

        const request = Object.assign({ isAdmission: this.isAdmission, patientId: id, appointmentId: appointmentId });

        this.httpService.post(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchCrossConsultation), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<OB.ReferralForm>) => {
                if (response.length > 0) {
                    this.referralForm = response;
                }
            });
    }
    //private findSpeacialFeature() {
    //    const request = { encryptedAppointmentId: this.appointmentId, isAdmission: this.isAdmission, type: OBEncounterType.SpeacialFeature };
    //    request["isAdmission"] = this.isAdmission;
    //    this.httpService.post<IEncounterResource>(ApiResources.getURI(ApiResources.gynEncounter.base, ApiResources.gynEncounter.fetchCommonEncounter), request)
    //        .pipe(takeUntil(this.page.unSubscribe))
    //        .pipe(finalize(() => this.page.loading = false))
    //        .subscribe((response: IEncounterResource) => {
    //            this.dietEncounters = response;
    //            this.specialFeature = JSON.parse(this.dietEncounters.jsonString);

    //        });
    //}
    gotoPrescription(appointmentId: string) {
        this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/prescription/${appointmentId}`, "_blank"); });
    }
    onChangePrintType(isShow: boolean) {
        this.isPrintLogo = isShow;
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
    fetchalltelephonetriage(appointmentId: any, patientId: any) {
        const request = {
            patientId: patientId,
            appointmentId: appointmentId,
            isAdmission: this.isAdmission
        }

        this.httpService.post(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchtelephonetraige), request)
            .subscribe((result: any) => {
                if (result) {
                    var response = result;
                    response.forEach((x) => {
                        var data = JSON.parse(x.json)
                        this.telephoneTriageAssessmentdata.push(data)
                    })
                }


            })

    }
}