<div class="content">
    <div class="container-fluid">
        <div class="d-flex justify-content-between align-items-center">
                <div>
                    <form [formGroup]="mainForm">
                        <div class="d-flex align-items-center mt-2">
                            <div class="form-group w-250-px mr-2">
                                <label class="mb-1">Location</label>
                                <ng-select class="ng-select-sm text-uppercase" [items]="locations"
                                           bindLabel="name"
                                           bindValue="id"
                                           autocomplete="nope"
                                           placeholder="Select location"
                                           formControlName="locationId">
                                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                        <div class="ng-option disabled">
                                            No location found for '{{searchTerm}}'
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="form-group w-250-px mr-2">
                                <label class="mb-1">Patient</label>
                                <ng-select class="ng-select-sm text-uppercase"
                                           [items]="records"
                                           bindLabel="fullName"
                                           bindValue="patientId"
                                           autocomplete="nope"
                                           placeholder="Select patient"
                                           formControlName="patientId">
                                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                        <div class="ng-option disabled">
                                            No patient found for '{{searchTerm}}'
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="form-group w-250-px mr-2">
                                    <label class="mb-1">Floors</label>
                                    <ng-select [loading]="loading" placeholder="Enter Floor Name" formControlName="floorName" [items]="floorsFilter"></ng-select>
                                </div>
                                <div class="form-group w-250-px mr-2">
                                    <label class="mb-1">Wards</label>
                                    <ng-select [loading]="loading" placeholder="Enter Ward Name" formControlName="wardName" [items]="wardsFilter"></ng-select>
                                </div>
                                <div class="form-group w-250-px mr-2">
                                    <label class="mb-1">Rooms</label>
                                    <ng-select [loading]="loading" placeholder="Enter Room Name" formControlName="roomName" [items]="roomsFilter"></ng-select>
                                </div>
                                <div class="d-flex align-items-center justify-content-start">
                                    <div class="mt-2">
                                        <button (click)="fetch()" type="button"  class="btn btn-sm btn-primary rounded-pill">
                                            <i class="mdi mdi-magnify mr-1"></i> Search
                                        </button>
                                        <button (click)="resetFilter()" type="button"  class="btn btn-sm reset-btn rounded-pill ml-1">
                                            <i class="mdi mdi-backup-restore mr-1"></i> Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
        </div>
        <div class="d-flex justify-content-end align-items-center">
                <div class="btn-group mr-1" *ngIf="isNurse">
                    <button class="btn btn-sm" 
                        [class.btn-light]="currentViewType !== viewType.MyPatients" 
                        [class.btn-primary]="currentViewType == viewType.MyPatients" 
                        type="button"
                        (click)="changeViewType(viewType.MyPatients)">
                        <i class="mdi mdi-account-multiple mr-1"></i>
                        My Patients
                    </button>
                    <button class="btn btn-sm" 
                        [class.btn-light]="currentViewType !== viewType.AllPatients" 
                        [class.btn-primary]="currentViewType == viewType.AllPatients" 
                        type="button" 
                        (click)="changeViewType(viewType.AllPatients)">
                        <i class="mdi mdi-account-group mr-1"></i>
                        All Patients
                    </button>
                </div>
        </div>
        <div class="d-flex mt-2 flex-wrap" style="cursor:pointer;">
            <div *ngFor="let record of records" class="card ribbon-box in-card p-0" >
                <div (click)="onClickAlert(record)" class="ribbon float-end mb-0" [ngClass]="{'ribbon-danger' : record.totalCount, 'ribbon-success': !record.totalCount}"><i class="mdi mdi-bell-outline mr-2"></i> <span [textContent]="record.totalCount"></span> Alert<span *ngIf="record.totalCount > 1">s</span></div>
                <div class="card-body px-2 pb-3 pt-2" >
                    <div class="in-card-header pb-2 mb-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <div (click)="onClickNurseForms(record)">
                                <div class="media">
                                    <div class="avatar-sm">
                                        <span class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="0">
                                            <span [textContent]="record.fullName | initials"></span>
                                        </span>
                                    </div>
                                    <div class="media-body ml-1">
                                        <h5 class="mb-0 mt-0 font-weight-normal">
                                            <span class="mr-1" [textContent]="'Mr. ' + record.fullName"></span>
                                        </h5>
                                        <span class="d-block font-13">
                                            <span><span [textContent]="record.gender === 'M' ? 'Male' : 'Female'"></span> - <span [textContent]="record.age"></span> years</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div (click)="onClickAlert(record)" class="d-flex align-items-center">
                                <h3 class="text-danger mr-1 bold" [textContent]="record.totalCount"></h3>
                                <span>Alert<span *ngIf="record.totalCount > 1">s</span></span>
                            </div>
                        </div>
                    </div>
                    <div class="bed-details-ui">
                        <span class="mr-2" [textContent]="record.roomName"></span>
                        <span class="text-small mr-2"><i class="mdi mdi-chevron-double-right mr-1"></i><span class="text-primary font-weight-bold text-small" [textContent]="record.bedNumber"></span></span>
                        <!-- <span class="text-small mr-2"><i class="mdi mdi-chevron-double-right mr-1"></i><span [textContent]="record.wardName"></span></span>
            <span class="text-small mr-2"><i class="mdi mdi-chevron-double-right mr-1"></i><span [textContent]="record.floorName"></span></span> -->
                    </div>
                    <div class="d-flex justify-content-start flex-wrap align-items-center">
                        <button class="btn rounded-pill ml-1" (click)="onClickNurseForms(record, 'Tablets')" [ngClass]="{'btn-soft-danger': !record.medicationsCount, 'btn-soft-success': record.medicationsCount}">
                            <i class="mdi mdi-pill mr-1"></i>
                            <span>Tablets</span>
                            <span class="ml-1"><i class="mdi mdi-check-bold"></i></span>
                        </button>
                        <button class="btn rounded-pill ml-1" (click)="onClickNurseForms(record, 'Notes')" [ngClass]="{'btn-soft-danger': !record.notesCount, 'btn-soft-success': record.notesCount}">
                            <i class="mdi mdi-notebook-outline mr-1"></i>
                            <span>Notes</span>
                            <span class="ml-1"><i class="mdi mdi-check-bold"></i></span>
                        </button>
                        <button class="btn rounded-pill ml-1" (click)="onClickNurseForms(record, 'Vitals')" [ngClass]="{'btn-soft-danger': !record.vitalsCount, 'btn-soft-success': record.vitalsCount}">
                            <i class="mdi mdi-stethoscope mr-1"></i>
                            <span>Vitals</span>
                            <span class="ml-1"><i class="mdi mdi-close"></i></span>
                        </button>
                        <button class="btn rounded-pill ml-1" (click)="onClickNurseForms(record, 'Labs')" [ngClass]="{'btn-soft-danger': !record.labsCount, 'btn-soft-success': record.labsCount}">
                            <i class="mdi mdi-flask-outline mr-1"></i>
                            <span>Labs</span>
                            <span class="ml-1"><i class="mdi mdi-close"></i></span>
                        </button>
                        <button class="btn rounded-pill ml-1" (click)="onClickNurseForms(record, 'Nurse Assessment')" [ngClass]="{'btn-soft-danger': !record.nurseAssessmentCount, 'btn-soft-success': record.nurseAssessmentCount}">
                            <i class="mdi mdi-flask-outline mr-1"></i>
                            <span>Nurse Assessment</span>
                            <span class="ml-1"><i class="mdi mdi-close"></i></span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="loadingAdmission">
                <div class="col-12">
                    <div class="d-flex align-items-center">
                        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                        <span class="ml-2">Please wait while loading Admissions ...</span>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!loadingAdmission && (!records || !records.length)">
                <div class="col-12 mh-300">
                    <div class="no-data">
                        <img src="assets/images/no-data.png" alt="No data" />
                        <h4 class="title">Oops! No Admissions found based on your filters criteria.</h4>
                        <p class="sub-title">There is no data to show you right now.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>

<barcode-scanner (scannedBarcode)="onBarcodeEventEmitter($event)"></barcode-scanner>

  <ng-template #templateAlerts>
      <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-bed mr-1"></i>Alerts</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="close();">×</button>
      </div>
      <div class="modal-body px-0 py-2">
        <div class="d-flex">
            <div>
                <ul class="nav flex-column inpatients-ul">
                    <li [ngClass]="{'nav-bg-primary': currentMenuList === menuList.medications}" (click)="menuOpen(menuList.medications)">
                        <a class="px-3 py-2" href="javascript:;">
                            <span>
                                <i class="mdi mdi-pill mr-1"></i>
                                <span class="text-capitalize">Medications</span>
                            </span>
                            <span class="badge badge-primary ml-1" [textContent]="selected.medicationsCount"></span>
                        </a>
                    </li>
                    <li [ngClass]="{'nav-bg-primary': currentMenuList === menuList.vitals}" (click)="menuOpen(menuList.vitals)">
                        <a class="px-3 py-2" href="javascript:;">
                            <span>
                                <i class="mdi mdi-stethoscope mr-1"></i>
                                <span class="text-capitalize">Vitals</span>
                            </span>
                            <span class="badge badge-primary ml-1" [textContent]="selected.vitalsCount"></span>
                        </a>
                    </li>
                    <li [ngClass]="{'nav-bg-primary': currentMenuList === menuList.doctorNotes}" (click)="menuOpen(menuList.doctorNotes)">
                        <a class="px-3 py-2" href="javascript:;">
                            <span>
                                <i class="mdi mdi-notebook-outline mr-1"></i>
                                <span class="text-capitalize">Doctor Notes</span>
                            </span>
                            <span class="badge badge-primary ml-1" [textContent]="selected.notesCount"></span>
                        </a>
                    </li>
                    <li [ngClass]="{'nav-bg-primary': currentMenuList === menuList.nurseNotes}" (click)="menuOpen(menuList.nurseNotes)">
                        <a class="px-3 py-2" href="javascript:;">
                            <span>
                                <i class="mdi mdi-notebook-outline mr-1"></i>
                                <span class="text-capitalize">Nurse Notes</span>
                            </span>
                            <span class="badge badge-primary ml-1" [textContent]="selected.nurseNotesCount"></span>
                        </a>
                    </li>
                    <li [ngClass]="{'nav-bg-primary': currentMenuList === menuList.labs}" (click)="menuOpen(menuList.labs)">
                        <a class="px-3 py-2" href="javascript:;">
                            <span>
                                <i class="mdi mdi-flask-outline mr-1"></i>
                                <span class="text-capitalize">Labs</span>
                            </span>
                            <span class="badge badge-primary ml-1" [textContent]="selected.labsCount"></span>
                        </a>
                    </li>
                    <li [ngClass]="{'nav-bg-primary': currentMenuList === menuList.nurseAssessment}" (click)="menuOpen(menuList.nurseAssessment)">
                        <a class="px-3 py-2" href="javascript:;">
                            <span>
                                <i class="mdi mdi-flask-outline mr-1"></i>
                                <span class="text-capitalize">Nurse Assessment</span>
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="flex-1">
                <ng-container *ngIf="currentMenuList === menuList.vitals">
                    <progress-report-vitals [inputAdmissionId]="selected.admissionId"></progress-report-vitals>
                </ng-container>
                <ng-container *ngIf="currentMenuList === menuList.medications">
                    <progress-report-medications [inputAdmissionId]="selected.admissionId"></progress-report-medications>
                </ng-container>
                <ng-container *ngIf="currentMenuList === menuList.doctorNotes">
                    <bed-notes [inputAdmissionId]="selected.admissionId"></bed-notes>
                </ng-container>
                <ng-container *ngIf="currentMenuList === menuList.nurseNotes">
                    <nurse-notes [inputAdmissionId]="selected.admissionId"></nurse-notes>
                </ng-container>
                <ng-container *ngIf="currentMenuList === menuList.labs">
                    <progress-report-labs [inputAdmissionId]="selected.admissionId"></progress-report-labs>
                </ng-container>
                <ng-container *ngIf="currentMenuList === menuList.nurseAssessment">
                    <div class="no-data">
                        <button class="btn btn-sm btn-primary" (click)="onClickNurseAssessment();"><i class="fe-plus mr-1"></i> Add Nurse Assessment</button>
                    </div>
                </ng-container>


            </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light btn-sm mr-1" (click)="close();">Close</button>
      </div>
  </ng-template>
    