export class ModuleServicesReport {
    itemId: number;
    itemNo: string;
    patientName: string;
    umrNo: string;
    mobile: string;
    labsNetAmount: number;
    scansNetAmount: number;
    servicesNetAmount: number;
    surgeriesNetAmount: number;

}
