/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-namespace */

export namespace GYN {

    export class Vitals {
        height: string;
        heightUnits: string;
        weight: string;
        weightUnits: string;
        respiratory: string;
        pulseOximetry: string;
        temperature: string;
        heartRhythm: string;
        systolicBP: string;
        diastolicBP: string;
        bmi: string;
        randomGlucose: string;
        hba1c: string;
        fastingGlucose: string;
        t4: string;
        t3: string;
        tsh: string;
    }

    export class ImgModel {
        base64String: any;
        type: string;
        edited: boolean;
    }

    export class SkinEncounter {
        inspection: SkinInspection;
        comments: string;
        jsonString: string;
        palpation: SkinPalpation;
        rashes: SkinRashes;
    }

    export class Skin {
        images: Array<ImgModel>;
        gynEncounterId: number;
        type: number;
        encryptedAppointmentId: string;
        modifiedBy: number;
        createdBy: number;
        jsonString: string;
        isAdmission: any;
        inspection: SkinInspection;
        constructor() {
            this.images = new Array<ImgModel>();
            this.inspection = new SkinInspection();
        }
    }

    export class Notes {
        notes: string;
    }

    export class MusculoskeletalExamOfGaint {
        normal: string;
        abnormal: string;
        gaintComment: string;
        examOfGaintValue: string;
    }
    export class MusculoskeletalExam {
        joints: boolean;
        bones: boolean;
        muscles: boolean;
        examComment: string
    }
    export class MusculoskeletalInspection {
        clubbing: string;
        cyanosis: string;
        petechiea: string;
        inflammatoryCondition: string;
        nodes: string;
        ischemicInfection: string;
        inspectionComment: string;
    }
    export class Musculoskeletal {
        images: string;
        examOfGaint: MusculoskeletalExamOfGaint;
        exam: MusculoskeletalExam;
        inspection: MusculoskeletalInspection;
        comments: string;
        jsonString: string;

        constructor() {
            this.examOfGaint = new MusculoskeletalExamOfGaint();
            this.inspection = new MusculoskeletalInspection();
            this.exam = new MusculoskeletalExam();
        }
    }
    export class MusculoSkeletalEncounter {
        images: string;
        examOfGaint: MusculoskeletalExamOfGaint;
        exam: MusculoskeletalExam;
        inspection: MusculoskeletalInspection;
        comments: string;
        jsonString: string;
    }

    export class LymphaticNeck {
        normal: boolean = false;
        consistency: boolean = false;
        enlarged: boolean = false;
        tender: boolean = false;
        neckValue: string;
        comments: string;
    }
    export class LymphaticAxillary {
        normal: boolean = false;
        consistency: boolean = false;
        enlarged: boolean = false;
        tender: boolean = false;
        axillaryValue: string;
        comments: string;
    }
    export class LymphaticGroin {
        normal: boolean = false;
        consistency: boolean = false;
        enlarged: boolean = false;
        tender: boolean = false;
        groinValue: string;
        comments: string;
    }
    export class LymphaticOther {
        normal: boolean = false;
        consistency: boolean = false;
        enlarged: boolean = false;
        tender: boolean = false;
        otherValue: string;
        comments: string;
    }
    export class Lymphatic {
        images: string;
        neck: LymphaticNeck;
        axillary: LymphaticAxillary;
        groin: LymphaticGroin;
        other: LymphaticOther;
        jsonString: string;

        constructor() {
            this.neck = new LymphaticNeck();
            this.axillary = new LymphaticAxillary();
            this.groin = new LymphaticGroin();
            this.other = new LymphaticOther();
        }
    }
    export class LymphaticEncounter {
        images: string;
        neck: LymphaticNeck;
        axillary: LymphaticAxillary;
        groin: LymphaticGroin;
        other: LymphaticOther;
        jsonString: string;
    }

    export class RespiratoryEffort {
        normal: boolean = false;
        retractions: boolean = false;
        accessoryMuscles: boolean = false;
        effortValue: string;
    }
    export class RespiratoryAxillary {
        normal: boolean = false;
        egophony: boolean = false;
        inspiratoryWheezes: boolean = false;
        expiratoryWheezes: boolean = false;
        rales: boolean = false;
        bronchi: boolean = false;
        breadthsSounds: boolean = false;
        L: boolean = false;
        R: boolean = false;
    }
    export class RespiratoryGroin {
        normal: boolean = false;
        dullness: boolean = false;
        hyperresonance: boolean = false;
        L: boolean = false;
        R: boolean = false;
    }
    export class Respiratory {
        effort: RespiratoryEffort;
        axillary: RespiratoryAxillary;
        groin: RespiratoryGroin;

        constructor() {
            this.effort = new RespiratoryEffort();
            this.axillary = new RespiratoryAxillary();
            this.groin = new RespiratoryGroin();
        }
    }

    export class CvAusculation {
        normal: boolean = false;
        mumur: boolean = false;
        clicks: boolean = false;
        rubs: boolean = false;
        ausculationValue: string;
        comments: string;
    }
    export class CvRhythm {
        regular: string;
        irregular: string;
        rhythmValue: string;
        comments: string;
    }
    export class CvExtremities {
        cyanosis: string;
        calfTenderness: string;
        varicosities: string;
        edema: string;
        temperature: string;
        comments: string;
    }
    export class CvPulses {
        palpated: string;
        notPalpated: string;
        pulsesValue: string;
    }
    export class CV {
        ausculation: CvAusculation;
        rhythm: CvRhythm;
        extremities: CvExtremities;
        pulses: CvPulses;

        constructor() {
            this.ausculation = new CvAusculation();
            this.rhythm = new CvRhythm();
            this.extremities = new CvExtremities();
            this.pulses = new CvPulses();
        }
    }

    export class BirthControlMain {
        none: boolean = false;
        comments: string;
    }

    export class BirthControlSub {
        spermicides: boolean = false;
        orthoEvraPatch: boolean = false;
        oc: boolean = false;
        transdermal: boolean = false;
        vagRing: boolean = false;
        condoms: boolean = false;
        diaphragm: boolean = false;
        rhythm: boolean = false;
        withdrawal: boolean = false;
        tubalLigation: boolean = false;
        vasectomy: boolean = false;
        hysterectomy: boolean = false;
        navaring: boolean = false;
        otherBarrier: boolean = false;
        depoProvera: boolean = false;
        IUD: boolean = false;
        nexplanon: boolean = false;
        otherBarriercomments: string;
        depoProveracomments: string;
        IUDcomments: string;
        nexplanoncomments: string;
    }
    export class BirthControl {
        main: BirthControlMain;
        sub: BirthControlSub;
        jsonString: string;
        constructor() {
            this.main = new BirthControlMain();
            this.sub = new BirthControlSub();
        }
    }
    export class BirthControlEncounter {
        main: BirthControlMain;
        sub: BirthControlSub;
        jsonString: string;
    }

    export class AbdomenPalpation {
        normal: boolean;
        softNonTender: boolean;
        masses: boolean;
        tenderness: boolean;
        obese: boolean;
        rebound: boolean;
        hemia: boolean;
        distended: boolean;
        scars: boolean;
        CVtenderness: boolean;
        comments: string;
    }
    export class AbdomenHepatomegaly {
        none: boolean;
        comments: string;
    }
    export class AbdomenBowlSounds {
        normal: boolean;
        active: boolean;
        highPitched: boolean;
        absent: boolean;
        comments: string;
        bowlSoundValue: string;
    }
    export class AbdomenIncision {
        wellHealed: boolean;
        erythema: boolean;
        tender: boolean;
        discharged: boolean;
        dehiscence: boolean;
        incisionValue: string;
    }
    export class AbdomenStoolGuiac {
        negative: boolean;
        positive: boolean;
        stoolGuiacValue: string;
    }
    export class Abdomen {
        palpation: AbdomenPalpation;
        hepatomegaly: AbdomenHepatomegaly;
        bowlSounds: AbdomenBowlSounds;
        incision: AbdomenIncision;
        stoolGuiac: AbdomenStoolGuiac;
        images: ImgModel;
        jsonString: string;

        constructor() {
            this.palpation = new AbdomenPalpation();
            this.hepatomegaly = new AbdomenHepatomegaly();
            this.bowlSounds = new AbdomenBowlSounds();
            this.incision = new AbdomenIncision();
            this.stoolGuiac = new AbdomenStoolGuiac();
        }
    }
    export class AbdomenEncounter {
        palpation: AbdomenPalpation;
        hepatomegaly: AbdomenHepatomegaly;
        bowlSounds: AbdomenBowlSounds;
        incision: AbdomenIncision;
        stoolGuiac: AbdomenStoolGuiac;
        images: string;
        jsonString: string;
    }


    export class SkinRashes {
        normal: string;
        abnormal: string;
        rashValue: string;
        comments: string;
    }
    export class SkinInspection {
        papular: string;
        erythema: string;
        scaly: string;
        ulcers: string;
        brownBlack: string;
        pustular: string;
        vascular: string;
        demarcated: string;
        rash: string;
        macular: string;
        irregularMargin: string;
        vesicular: string;
        ulcerated: string;
        umbilicated: string;
        warty: string;
        flat: string;
        raised: string;
        dry: string;
        weeping: string;
        comments: string;
    }

    export class SkinPalpation {
        normal: string;
        induration: string;
        nodules: string;
        tender: string;
        palpationValue: string;
        comments: string;
    }
    export class GynSkin {
        rashes: SkinRashes;
        inspection: SkinInspection;
        palpation: SkinPalpation;
        images: string;
        jsonString: string;
        constructor() {
            this.rashes = new SkinRashes();
            this.inspection = new SkinInspection();
            this.palpation = new SkinPalpation();
        }
    }

    export class MenopausalHistory {
        none: string;
        motherAge: string;
        vaginalDryness: string;
        libidoChanges: string;
        hotFlashes: string;
        urinary: string;
        painfulIntercourse: string;
        mooodChanges: string;
        lowSexDrive: string;
        hysterectomy: string;
        hRTHistory: string;
        comments: string;
        otherComments: string;
    }

    export class LastPelvicUltraSound {
        date: string;
        lastPelvicSound: string;
        comments: string;
    }

    export class LastDexaScan {
        date: string;
        boneDensity: string;
        comments: string;
    }

    export class LastMammogram {
        date: string;
        result: string;
        radiologist: string;
        comments: string;
    }

    export class CancerHistory {
        date: string;
        ailments: string;
        relation: string;
        age: number;
        origin: string;
        comments: string;
    }

    export class AdditionalLabs {
        labs: string;
        date: string;
        result: string;
        comments: string;
    }

    export class GynLab {
        date: string;
        gynLab: string;
        comments: string;
    }

    export class GynHistory {
        lastPap: string;
        HPVStatus: string;
        colposcopyStatus: string;
        leep: string;
        cryoSurgery: string;
        coneBiopsy: string;
        endometriosis: string;
        fibroids: string;
        lastPelvicUltrasound: string;
        dysmenorrhea: string;
        tumors: string;
        PID: string;
        infertility: string;
        desExposure: string;
        abnormalReproductiveOrgans: string;
        ecotopic: string;
        thyromegaly: string;
    }

    export class SexualHistory {
        sexuallyActive: string;
        firstSexual: string;
        monogamous: string;
        sexualPartners: string;
        noOfTimesWithCurrentPartner: string;
    }
    export class SexualTransmittedDiseases {
        herpes: string;
        gonorrhea: string;
        chlamydia: string;
        syphillis: string;
        PID: string;
        HPV: string;
        condyloma: string;
        HIV: string;
        scabies: string;
        trichomoniasis: string;
        warts: string;
        comments: string;
        yes: boolean;
        no: boolean;
    }
    export class GynGynHistory {
        history: GynHistory;
        sexualHistory: SexualHistory;
        sexualTransmittedDiseases: SexualTransmittedDiseases;
        constructor() {
            this.history = new GynHistory();
            this.sexualHistory = new SexualHistory();
            this.sexualTransmittedDiseases = new SexualTransmittedDiseases();

        }
    }

    export class MensturalHistory {
        flow: string;
        duration: string;
        ageofMenopause: string;
        menarche: string;
        cycleLength: string;
        cycleLengthComments: string;
        LMP: string;
        LMPComment: string;
        qualityofLMP: string;
        qualityofLMPValue: string;
    }

    export class BreastScreening {
        fibrocysticBreastDisease: string;
        monthlySelfBreastExam: string;
        lastBreastUltrasound: string;
        lastBreastUltrasoundComment: string;
        lastMammogram: string;
        lastMammogramComment: string;
    }



    export class Breast {
        inspection: string;
        inspectionValue: string;
        inspectionComment: string;
        normal: string;
        abnormal: string;
        palpationValue: string
        massRight: string;
        massLeft: string;
        tendernessRight: string;
        tendernessLeft: string;
        skinChangeRight: string;
        skinChangeLeft: string;
        nippleDischargeRight: string;
        nippleDischargeLeft: string;
        palpableRight: string;
        palpableLeft: string;
        mastectomyRight: string;
        mastectomyLeft: string;
        moreComments: string;
        implantRight: string;
        implantLeft: string;
        selfBreastExam: string;
        images: string;
        jsonString: string;
    }

    export class BreastEncounter {
        inspection: string;
        inspectionValue: string;
        inspectionComment: string;
        normal: string;
        abnormal: string;
        palpationValue: string
        massRight: string;
        massLeft: string;
        tendernessRight: string;
        tendernessLeft: string;
        skinChangeRight: string;
        skinChangeLeft: string;
        nippleDischargeRight: string;
        nippleDischargeLeft: string;
        palpableRight: string;
        palpableLeft: string;
        mastectomyRight: string;
        mastectomyLeft: string;
        moreComments: string;
        implantRight: string;
        implantLeft: string;
        selfBreastExam: string;
        jsonString: string;
    }


    export class Heent {
        normal: boolean = false;
        abnormal: boolean = false;
        comments: string;
    }
    export class HeentThyroid {
        normal: boolean = false;
        abnormal: boolean = false;
        notEnlarged: boolean = false;
        enlarged: boolean = false;
        notenlarged: boolean = false;
        comments: string;
    }

    export class HeentOther {
        normal: boolean = false;
        consistency: boolean = false;
        enlarged: boolean = false;
        tender: boolean = false;
        comments: string;
    }

    export class GynCard {
        gynNo: string;
        consultantDoctor: string;
        registrationDate: string;
        anyOtherDetails: string;
        gynNoExists: string;
        jsonString: string;
    }
    export class GynaecOrders {
        gynaecComplaints: string;
        importantAdvice: string;
        maternalPlan: string;
        fatalPlan: string;
        prescription: string;
        investigationTemplates: string;
        referrals: string;
        proceduresTemplates: string;
        diagnosisRemarks: string;
        pregnancyPlan: string;
        prePregnancy: string;
        gynaecNotes: string;
        pusCells: string;
        rbc: string;
        epithelialCells: string;
        comments: string;
        jsonString: string;
    }
    export class GynIUI {
        iuiDate: string;
        inSeminationsList: string;
        typeOfInfertility: string;
        indicationForIUI: string;
        others: string;
        cycle: string;
        rxRegime: string;
        rxDetails: string;
        noOfDF: string;
        ovulationTriggers: string;
        timeForHCGToIUI: string;
        cycleNoIUI: string;
        inSeminations: string;
        days: number;
        volume: string;
        liquification: string;
        spermConcentration: string;
        progressiveMotility: string;
        nonProgressiveMotility: string;
        immotility: string;
        pusCells: string;
        rbc: string;
        epithelialCells: string;
        comments: string;
        spermPreparation: string;
        cultureMedium: string;
        inseminationVolume: string;
        motileSperms: string;
        progressiveMotility1: string;
        nonProgressiveMotility1: string;
        immotility1: string;
        iuiCatheterUsed: string;
        date: string;
        andrologist: string;
        gynaecologist: string;
        iuiProcedure: string;
        adviceToPatient: string;
        details: string;
        reviewdate: string;
    }
    export class GyneacPartner {
        husbandName: string;
        husbandAge: number;
        gynNo: string;
        visitNo: number;
        consultantDoctor: string;
        occupation: string;
        bloodGroup: string;
        otherbloodGroup: string;
        rhType: string;
        tobacco: string;
        ifYesDetails1: string;
        alcohol: string;
        ifYesDetails2: string;
        medicalHistory: string;
        surgicalHistory: string;
        treatmentHistory: string;
        hivDate: Date;
        hivResult: string;
        hbsAgDate: Date;
        hbsAgResult: string;
        antiHCVDate: Date;
        antiHCVResult: string;
        dateOfSemen: string;
        volume: string;
        liquificationTime: string;
        spermConcentration: string;
        activeMotility: string;
        sluggishMotility: string;
        normalForms: string;
        pusCells: string;
        impression: string;
        remarks: string;
        date: string;


    }
    export class ProcedureForm {
        date: Date;
        partnersName: string;
        consultant: string;
        specialization: string;
        procedures: string;
        team: string;
        indication: string;
        lmp: string;
        finding: string;
        analgesia: string;
        antibiotics: string;
        labSamples: string;
        abandoned: string;
        reason: string;
        duration: string;
        painScore: string;
        remarks: string;
        review: string;
    }
    export class Ectopic {
        uvSounding: Array<UVsounding>;
        investigations: Array<Investigations>;
        singleDose: Array<SingleDose>;
        multiDose: Array<MultiDose>;
        followup: Array<Followup>;
        noValue: string;
        consultantDoctor: string;
        heightInFeet: string;
        weight: string;
        bsa: string;
        bloodGroup: string;
        rhType: string;
        antiD: string;
        ifYesDose: string;
        antidDate: string;
        constructor() {
            this.uvSounding = new Array<UVsounding>();
            this.investigations = Array<Investigations>();
            this.singleDose = Array<SingleDose>();
            this.multiDose = new Array<MultiDose>();
            this.followup = new Array<Followup>();
        }
    }

    export class UVsounding {
        sn: string;
        date: string;
        comments: string;
    }
    export class Investigations {
        sn: string;
        investigationDate: string;
        haemoglobin: string;
        wbcCount: string;
        plateletCount: string;
        bilirubin: string;
        sgot: string;
        sgpt: string;
        creatinine: string;
    }
    export class SingleDose {
        date: string;
        sDose: string;
        sn: string;
        betaHCG: string;
        methotrexate: string;
        mifepristone: string;
    }
    export class MultiDose {
        date: string;
        rxDay: any;
        betaHCG: string;
        methotrexate: string;
        folinicAcid: string;
        sn: string;
    }
    export class Followup {
        sn: string;
        date: string;
        weeklyBetaHCG: string;
        reviewingDoctor: string;
        furtherPlan: string;
    }
    export class Measures {
        respiratoryRate: string;
        temparature: string;
        pulseRate: string;
        diastolicBP: string;
        systolicBP: string;
        bsa: string;
        bodyMassIndex: string;
        lmp: string;
        heightInMeters: string;
        heightInCentiMeter: any;
        weight: string;
        heightInFeet: string;
        lLmpList: string;
        bmiCategorization: string;
        visitNo: string;
        bp: string;
        husbandHeightInFeet: string;
        husbandHeightInMeters: string;
        husbandWeight: string;
        husbandBodyMassIndex: string;
        husbandBsa: string;
        husbandBMICategorization: string;
        husbandBP: string;
    }
    export class GyneacVisit {
        postMenopausal: string;
        gynNo: string; visitDate: string;
        postmenopausal: string;
        visitNo: string;
        procedureType: string;
        day: string;
        month: string;
        yr: string;
        communicated: string;
        details: string;
        lLmpList: string;
        visitType: string;
        consultantDoctor: string;
        lmp: string;
        height: string;
        weight: string;
        systolicBP: string;
        diastolicBP: string;
        pulseRate: string;
        respiratoryRate: string;
        temparature: string;
        gravida: string;
        para: string;
        living: string;
        abortion: string;
        ectopic: string;
        death: string;
        pallor: string;
        icterus: string;
        lymphNodes: string;
        thyromegaly: string;
        pigmentation: string;
        hirsutism: string;
        fgScore: string;
        acne: string;
        axillaryHair: string;
        pubicHair: string;
        tannerStagePubicHair: string;
        tannerStageBreast: string;
        rightExaminationComment: string;
        leftExaminationComment: string;
        rightExamination: rightExamination1;
        leftExamination: leftExamination1;
        abdominalExamination: string;
        externalGenitalia: string;
        speculumExamination: string;
        bimanualExamination: string;
        rectalExamination: string;
        gyanecExaminationSurgery: string;
        academicScan: string;
        specialFeatures: string;
        provisionalDiagnosis: string;
        finalDiagnosis: string;
        planOfManagement: string;
        seenByDr: string;
        examinedByDr: string;
        nextReviewDate: string;
        provisionalDiagnosis1: string;
        gynaecExaminationSummary: string;
        bmi: string;
        bsa: string;
        postabortal: string;
        lactationalAmenorrhea: string;
        postHysterectomy: string;
        heightInFeet: any; heightInMeters: string;
        bodyMassIndex: string;
        biopsyIndication: string;
        cervicalIndication: string;
        anaesthesia: string;
        cervix: string;
        uterocervicalLength: string;
        usgGuidance: string;
        mirenaIndication: string;
        cervixAnaesthesia: string;
        uterocervicalLengthCm: string;
        usgConfirmation: string;
        hpvTest: string;
        biopsy: string;
        lugolSIodine: string;
        acetowhiteAreas: string;
        scj: string;
        colposcopyCervix: string;
        colposcopyAnaesthesia: string;
        colposcopyIndication: string;
        minorOPDate: string;
        procedueDetails: string;
        cryotheraphyIndication: string;
        cryotheraphyCervix: string;
        dilatedUpto: string;
        cervicalAnaesthesia: string;
        bmiCategorization: string;
        cervicalUSGGuidance: string;
        procedureAbandoned: string;

        constructor() {
            this.rightExamination = new rightExamination1();
            this.leftExamination = new leftExamination1();
        }
    }
    export class ProformaForOasi {
        faecesList: string;
        details: string;
        howFrequent: string;
        anagles: string;
        culture: string;
        perinalTear: string;
        frequencyList: string;
        deliveryDate: string;
        visitDate: string;
        ancNo: string;
        followupVisit: string;
        gravida: string;
        para: string;
        living: string;
        abortion: string;
        bmi: string;
        weight: string;
        gaAtDelivery: string;
        previousCs: string;
        inducedLabour: string;
        anaesthesia: string;
        durationOfActivePhase: string;
        durationOf2ndStage: string;
        modeOfDelivery: string;
        episiotomy: string;
        placeOfRepair: string;
        perineum: string;
        lacerations: string;
        birthWeight: string;
        hc: string;
        suturing: string;
        liquor: string;
        shoulderDystocia: string;
        prDone: string;
        postnatalDate: string;
        durationSinceTear: string;
        symptoms: string;
        perinealPain: string;
        dyspaerunia: string;
        howFrequentD: string;
        constipation: string;
        painfulDefaecation: string;
        fecalIncontinence: string;
        solid: string;
        liquid: string;
        gas: string;
        wearPad: string;
        lifestyleAlteration: string;
        difficultyPassinUrine: string;
        frequencyDysuria: string;
        urinaryIncontinence: string;
        urineLeakage: string;
        urineLose: string;
        severity: string;
        severityScore: string;
        endoanalUltrasound: string;
        analManometry: string;
        anyOtherProblems: string;
        managementPlan: string;
        reviewDate: string;
        seenByDoctor: string;
        bowelSymptoms: string;
        bladderSymptoms: string;
        woundInfection: string;
        faeces: string; flatus: string; fluid: string;
        fistula: string;
    }

    export class GynCardGeneration {
        mrNo: string;
        name: string;
        age: string;
        sex: string;
        gynNo: string;
        gynNoExists: string;
        husbandName: string;
        husbandAge: number;
        remarks: string;
        consultantDoctor: string;
        registrationDate: string;
        department: string;
        providerId: string;
        patientId: string;
        gynCardGenerationId: number;
        otherDetails: string;
    }

    export class NewGynHistory {
        lastPapSmearDate: string;
        lLmpList: string;
        treatmentList: string;
        gainChangeList: string;
        historyUpadtedDate: string;
        outsideDoctor: string;
        menstrualCycle: string;
        menstrualCycleIntervals: string;
        durationOfFlow: string;
        amountOfFlow: string;
        dysmenorrhoea: string;
        ageAtMenarche: string;
        bloodGroup: string;
        rhType: string;
        martitalStatus: string;
        yearOfMarriage: string;
        gestationAge: string;
        contraception: string;
        consanguinity: string; para: string;
        living: string;
        ectopic: string;
        gravida: string;
        death: string;
        lmp: string;
        lLmp: string;
        postmenopausal: string;
        postabortal: string;
        lactationalAmenorrhea: string;
        postHysterectomy: string;
        menopause: string;
        dyspareunia: string;
        imb: string;
        vaginalDiscahrge: string;
        pcb: string;
        pms: string;
        pmdd: string;
        papSmear: string;
        report: string;
        hrt: string;
        presentingComplaint: string;
        presentingIllness: string;
        bowelSymptoms: string;
        bladderSymptoms: string;
        gainChange: string;
        lossChange: string;
        treatmentHistory: string;
        weightChange: string;
        appetite: string;
        gain: string;
        loss: string;
        others: string;
        noHistory: string;
        abortion: string;
        ecotopic: string;
        thyromegaly: string;
        day: string;
        month: string;
        yr: string;
        llDay: string;
        llmpMonth: string;
        llmpYr: string;
        degreeOfConsanguinity: string;
        ageAtMenopause: string;
        otherWeightChange: string;
        otherHepatitis: string;
        menstrualPattern: string;
    }

    export class NursingERData {
        painRating: string;
        tempInFahrenheit: string;
        tempIncelcius: string;
        pulsePerMinute: string;
        bpInSystolic: string;
        bpInDiastolic: string;
        respPerMinute: string;
        spo2: string;
        heightInFeet: string;
        weightInKg: string;
        bmi: string;
        patientCondition: string;
        attendantName: string;
        mobileNo: string;
        allergiesReaction: string;
        foodAllergy: string;
        reactionToPreviousTransfusion: string;
        smokingHabbit: string;
        drinkingHabbit: string;
        treatedForAlcoholRelatedProblem: string;
        psychologicalStatus: string;
        nutrition: string;
        actionUsed: string;
        location: string;
        acute: string;
        characteristic: string;
        onAdmissionPatient: string;
    }
    export class GyneacSurgery {
        isColposcopy: boolean;
        leftOstiumNotSee: string; rightOstiumNotSee: string;
        psDischarge: string;
        isOther: boolean;
        isTAH: boolean;
        gradeI: string;
        gradeII: string;
        gradeIII: string;
        rightOstium: string;
        leftOstium: string;
        isVH: boolean;
        isTLH: boolean;
        isLaparotomy: boolean;
        isHysterectomy: boolean;
        laparotomySummary: boolean;
        isHysteroscopy: boolean;
        isLaproChecked: boolean;
        tenMMPort: string;
        amount: string;
        remarks: string;
        ovarianFossaLeftAbn: string;
        ovarianFossaLeft: string;
        ovarianFossaRightAbn: string;
        ovarianFossaRight: string;
        specialInstrumentList: string;
        endometriosis: string;
        ltOvary: string;
        rtOvary: string;
        avgDuration: string;
        currentWattage: string;
        ndometriosis: string;
        siteOfEndometriosis: string;
        pelvicPeriAbn: string;
        gynNo: string;
        ipNo: string;
        roomNo: string;
        surgeryDate: string;
        surgeryType: Array<string>;
        surgeon: string;
        asstSurgeon: string;
        standByDr: string;
        scrubNurse: string;
        anaesthetis: string;
        technician: string;
        lmp: string;
        laproscopy: string;
        hysteroscopy: string;
        colposcopy: string;
        laparotomy: string;
        hysterectomy: string;
        otherSurgeries: string;
        indication: string;
        anaesthesia: string;
        otherAnaesthesia: string;
        nameOfTheProcedure: string;
        complications: string;
        findings: string;
        procedureDone: string;
        complicationsDetails: string;
        angleOfScope: string;
        distendingMedium: string;
        inflowRate: string;
        inflowPressure: string;
        input: string;
        output: string;
        fluidDeficit: string;
        uteroCervicalLength: string;
        cxDilatation: string;
        toWhichSize: string;
        externalOS: string;
        externalOSAbn: string;
        cervicalCanal: string;
        ifAbnormalDtls: string;
        otherSurgeriestext: string;
        assistSurgeontwo: string;
        assistSurgeonone: string;
        scrubNurseone: string;
        scrubNursetwo: string;
        internalOs: string;
        internalOsAbn: string;
        fundus: string;
        rightCornua: string;
        rightCornuaDetail: string;
        leftCornua: string;
        leftCornuaDetail: string;
        cavity: string;
        uterineWalls: string;
        uterineWallsAbn: string;
        others: string;
        versapoint: string;
        impressionHysteroscopy: string;
        bladderCatheterization: string;
        techniqueOfPneumoperitoneum: string;
        ifVeress: string;
        initialPressure: string;
        maintenancePressure: string;
        fiveMmSite: string;
        morcellator: string;
        uterusSize: string;
        type: string;
        uterosacrals: string;
        uterosacralsDetails: string;
        pod: string;
        podAbn: string;
        rightTube: string;
        rightTubeAbn: string;
        leftTube: string;
        leftTubeAbn: string;
        rightOvary: string;
        rightOvaryAbnormal: string;
        leftOvary: string;
        leftOvaryAbnormal: string;
        tuboOvariaRelation: string;
        tuboOvariAbn: string;
        pelvicPeritoneum: string;
        pelvicPeritoneumAbn: string;
        ectopicGestation: string;
        upperAbdomen: string;
        haemoperitoneum: string;
        findingOthers: string;
        ovarianDrilling: string;
        endobag: string;
        vasopressin: string;
        endosuturing: string;
        specimenWt: string;
        woundSuturing: string;
        impressionLaproscopy: string;
        tah: string;
        vh: string;
        tlh: string;
        colpoComplaints: string;
        complaintsOthers: string;
        menstrualCycle: string;
        yearsSexuallyActive: string;
        contraception: string;
        contraceptionOthers: string;
        pastHistory: string;
        obstetricHistory: string;
        familyCancer: string;
        familyHOIfYes: string;
        lastPapSmearImpression: string;
        dateOfLastPAP: string;
        pAndS: string;
        pAndV: string;
        scjSeenClearly: string;
        leukoplakia: string;
        acetowhiteAreas: string;
        abnormalVasculature: string;
        ifYes: string;
        vulvalChanges: string;
        ifYesDescribe: string;
        findingsOthers: string;
        coloposcopy: string;
        inadequate: string;
        colposcopyDiagnosis: string;
        followUpReviewDate: string;
        shirodkarCerclage: string;
        abdominalCerclage: string;
        qbl: string;
        furtherPalnOfManagement: string;
        shiftingIntoOT: string;
        startOfAnaesthesia: string;
        antibioticsTotalDoseTime: string;
        startOfSurgery: string;
        endOfProcedure: string;
        shiftingOutOfOT: string;
        durationOfSurgery: string;
        specialEquipment: string;
        anteversionOfUterus: string;
        specimenRemoval: string;
        vaultClosure: string;
        vhSummary: string;
        otherSurgery: string;
        tahSummary: string;
        rupture: string;
        site: string;
        abnDtls: string;
        tlhSummary: string;
        dyeTest: string;
    }
    export class GyneacAdmissionSheet {
        conscious: string;
        temparature: string;
        respiratoryRate: string;
        relativeDetails: string;
        relativeName: string;
        consultantDoctor: string;
        gynNo: string;
        ipNo: string;
        roomNo: string;
        admissionDate: string;
        reasonForAdmission: string;
        specialFeature: string;
        lmp: string;
        menstrualCycles: string;
        contraception: string;
        obstetricFormula: string;
        medicalHistory: any;
        surgicalHistory: any;
        familyHistory: any;
        drugAlleries: string;
        medication: string;
        heightInFeet: string;
        weight: string;
        bodyMassIndex: string;
        bsa: string;
        pulseRate: string;
        bp: string;
        shock: string;
        pallor: string;
        abdomen: string;
        scars: string;
        scarDetails: string;
        externalGentilia: string;
        pelExOthers: string;
        gynaecExaminationSummary: string;
        academicScan: string;
        specialFeatures: string;
        finalDiagnosis: string;
        planOfManagement: string;
        perSpeculam: string;
        psOtherdtl: string;
        uterus: string;
        utOtherDtls: string;
        size: string;
        adnexaRight: string;
        adnexaLeft: string;
        pod: string;
        pelvicTenderness: string;
        provisionalDiagnosis: string;
        cbpChecked: string;
        cbp: string;
        cbpDate: string;
        bloodGroupChecked: string;
        bloodGroup: string;
        bloodGroupDate: string;
        hivChecked: string;
        hiv: string;
        hivDate: string;
        tshChecked: string;
        tsh: string;
        tshDate: string;
        rbsChecked: string;
        rbs: string;
        rbsDate: string;
        hbsAghecked: string;
        hbsAG: string;
        hbsDate: string;
        fbsChecked: string;
        fbs: string;
        fbsDate: string;
        creatinineChecked: string;
        creatinine: string;
        creatinineDate: string;
        cueChecked: string;
        cue: string;
        cueDate: string;
        ecgChecked: string;
        ecg: string;
        ecgDate: string;
        chestXRayChecked: string;
        chestXRay: string;
        otherChecked: string;
        other: string;
        scan: string;
        mri: string;
        ctu: string;
        hsg: string;
        hpe: string;
        papSmear: string;
        bloodMatched: string;
        noOfMatched: string;
        consultantInformed: string;
        planManagement: string;
        instruction: string;
        details: string;
        antihcvDate: any;
        antihcv: string;
        decho: string;
        antihcvChecked: any;
        gynWithDate: any;
        cbcChecked: string;
        cbc: string;
        cbcDate: string;
        ureaChecked: string;
        urea: string;
        ureaDate: string;
        sgotChecked: string;
        sgot: string;
        sgotDate: string;
        sgptChecked: string;
        sgpt: string;
        sgptDate: string;
        hcvChecked: string;
        hvc: string;
        hvcDate: string;
        hbsagChecked: string;
        hbsag: string;
        hbsagDate: string;
        vrdlChecked: string;
        vrdl: string;
        vrdlDate: string;
        amhChecked: string;
        amh: string;
        amhDate: string;
    }

    export class GyneacDiscargeSummary {
        familyHistory: string;
        consultantDoctor: string;
        gynNo: string;
        relativeDetails: string;
        relativeName: string;
        bloodGroup: string;
        rhType: string;
        admissionDate: string;
        bedNo: string;
        summaryType: string;
        dischargeType: string;
        dischargeDate: string;
        transferDate: string;
        deathDate: string;
        dischargeTime: string;
        hospitalName: string;
        reasonForTranfer: string;
        reasonForLAMA: string;
        causeOfDeath: string;
        diagnosis: string;
        chiefComplaints: string;
        complaintAndHistory: string;
        medicalHistory: any;
        surgicalHistory: any;
        allergyHistory: any;
        admissionDetails: string;
        operativeFindings: string;
        courseInTheHospital: string;
        conditionAtDischarge: string;
        furtherPlanOfManagement: string;
        reviewDates: string;
        proposedProcedure: string;
        proposeddone: string;
        noOfTypes: string;
        problems: Array<any>;
        labs: Array<any>;
        birthhistory: Array<any>;
        prescriptions: Array<any>;
        scans: Array<any>;

    }
    export class Reminder {
        reminder: string;
    }
    export class Colposcopic {
        surgeryDate: string;
        surgeon: string;
        indicationForColposcopy: string;
        historyOfScreeningAndTreatment: string;
        parity: string;
        remarks: string;
        contraception: string;
        pregnancyStatus: string;
        menopausalStatus: string;
        hysterectomyStatus: string;
        smokingHistory: string;
        hivStatus: string;
        hpvVaccinationStatus: string;
        externalGenitalia: string;
        perSpeculum: string;

        cervixVisibility: string;
        scjVisibility: string;
        manipulationToVisualizeSCJ: string;
        acetowhiteningPresent: string;
        normalColoposcopicList: string;
        lesionsPresent: string;
        lowGradeFeatureList: string;
        highGradeFeatureList: string;
        suspiciousCancerList: string;
        otherFindingList: string;
        lugolsStaining: string;
        miscellaneousList: string;
        locationOfBiopsies: string;
        endocervicalSampling: string;
        colposcopicImpression: string;
        reviewDate: string;
    }
    export class AddendumForm {
        note: string;
        date: Date;
        time: string;
    }
    export class gpla {
        yearOfMarriage: string;
        gravida: number;
        para: string;
        abortion: string;
        living: string;
        menstrualPattern: string;
        days: string;
        conception: string;
        contraceptives: string;
        consanguinity: string;
        deliveryStatus: string;
        presentPregnancyDetails: string;
        bloodGroup: string;
        lmpStatus: string;
        lmp: Date;
        eddType: Date;
        ultraSoundEdd: Date;
        ga: number;
        rhType: string;
        bookingStatus: string;
        willSheDeliver: string;
        referralDoctor: string;
        specialFeature: string;
        husbandBloodGroup: string;
        husbandRhType: string;
        smoking: string;
        alcohol: string;
        otherSubstances: string;
        cordBloodBanking: string;
        prenatalScreeningOffered: string;
        diet: string;
        lifeStyle: string;
        toiletPreference: string;
        financialCouncelling: string;
        timeTakenToReachHospital: string;
        distanceInKilometers: string;
        prePregWeight: string;
        hbsAg: string;
        hiv: string;
        edd: Date;
        providerName: string;

        covidVaccine: string;
        vaccineName: string;
        firstDose: string;
        date1: Date;
        secondDose: string;
        date2: Date;
        booster: string;
        date3: Date;
        fetalLungMaturity: string;
        day1: Date;
        day2: Date;
        givenBy: string;
        fluVaccine: string;
        date4: Date;
        tetanus1stDose: string;
        date5: Date;
        tetanus2ndDose: string;
        date6: Date;
        boostrix: string;
        anticoagulant: string;
        medication: string;
        dosage: string;
        frequency: string;
        date7: Date;
        indication1: string;
        dose1: string;
        byDr: string;
        date8: Date;
        indication2: string;
        dose2: string;
        byDr2: string;
        neonatalCounseling: string;
        otherSubstancesComment: string;
        consanguinityComment: string;
        lmpUnKnow: Date;
        consulatantDoctor: string;
        consultanDoctorUserName: string;
    }
    export class SurgerySlip {
        surgicalProcedure: Array<string>;
        otherProcedure: string;
        surgeryDate: Date;
        admissionDate: Date;
        surgeon: string;
        locationId: number;
        remarks: string;
        mrno: string;
        name: string;
        age: string;
        husbandName: string;
        consultant: string;
    }
    export class ScanTest {
        id: number;
        name: string;
        comments: string;
    }
    export class TelephoneTriageAssessment {
        callDate: Date
        callTime: string;
        typeofConsultation: string;
        reasonForCall: Array<string>;
        reasonothers: string;
        adviceGiventoPatient: Array<string>;
        adviceother: string;
        actionTakenIfAdvisedToVisit: Array<string>;
        actionother: string;
        doctorName: string;
        callCompletedDate: string;
        callcompletedTime: string;
    }
}
export class rightExamination1 {
    normal: boolean = false;
    abnormal: boolean = false;
}
export class leftExamination1 {
    normal: boolean = false;
    abnormal: boolean = false;
}




