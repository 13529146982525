
<style>
    .disabled-element {
        cursor: not-allowed;
        background-color: #f0f0f0;
        opacity: 0.6;
        pointer-events: none;
    }
</style>

<div>
    <div class="modal-body" style="max-height: 65vh; min-height: 25vh; overflow-y: auto;">
        <form [formGroup]="editControlsForm" (ngSubmit)="onEditConytolsData()">
            <div class="modal-body overflow-auto" style="height:70vh">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group mb-3">
                            <label class="mb-1">Label <code>*</code></label>&nbsp;&nbsp;
                            <input type="text" id="input1" formControlName="label" class="form-control" placeholder="Enter Label"
                                   [ngClass]="{ 'is-invalid': submitted && editControlsForm.get('label').invalid && editControlsForm.get('label').touched }">

                            <div *ngIf="submitted && editControlsForm.get('label').errors?.required" class="invalid-feedback">
                                Label is required
                            </div>

                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group mb-3">
                            <label class="mb-1">Control Type <code>*</code></label>&nbsp;&nbsp;:
                            <select id="input2" formControlName="controlType" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && editControlsFormValid.controlType.errors?.required }">
                                <option value="heading">Heading</option>
                                <option value="text">Text</option>
                                <option value="number">Number</option>
                                <option value="textarea">Textarea</option>
                                <option value="date">Date</option>
                                <option value="noneYesNoSwitch">YesNoSwitch</option>
                                <option value="switch">Switch</option>
                                <option value="triState">Tri State</option>
                                <option value="checkbox">Checkbox</option>
                                <option value="radio">Radio</option>
                                <option value="dropdown">Single-Select Dropdown</option>
                                <option value="multiselect">Multi-Select Dropdown</option>
                                <option value="ckEditor">CkEditor</option>
                                <option value="total">Total</option>
                                <option value="calculation">Calculation</option>
                            </select>
                            <div *ngIf="submitted && editControlsFormValid.controlType.errors" class="invalid-feedback">
                                <div *ngIf="editControlsFormValid.controlType.errors.required"> Control Type is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group mb-3">
                            <label for="SelectedOption">Select an option:</label>
                            <select id="SelectedOption" formControlName="selectedOption" class="form-control" [class.disabled-element]="isIconDisabled">
                                <option [value]="null" selected>Choose</option>
                                <option value="manual">Manual</option>
                                <option value="lookups">Lookups</option>
                            </select>
                            <div *ngIf="submitted &&  editControlsForm.get('selectedOption').errors?.required" class="text-danger">
                                Please select an option Manual or Lookups.
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="col-11">
                            <label class="mb-1">Values:</label>
                            <div class="input-group">

                                <textarea id="input3"
                                          formControlName="htmlData"
                                          placeholder="Values"
                                          class="form-control"
                                          [class.disabled-element]="editControlsForm.get('controlType').value === 'calculation'"
                                          [readonly]="editControlsForm.get('selectedOption').value === 'lookups'" [style.cursor]="editControlsForm.get('selectedOption').value === 'lookups'? 'not-allowed' : 'auto'"></textarea>

                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <a href="javascript:;"
                                           (click)="isIconEnabled() ? openModel(manualOptions, editControlsForm.get('selectedOption').value) : null"
                                           class="action-icon text-primary font-20" placement="left" ngbTooltip="Edit"
                                           [ngClass]="{'disabled-icon': !isIconEnabled()}">
                                            <i id="editIconLookUp" class="fe-edit"></i>
                                        </a>
                                    </span>
                                </div>
                            </div>
                            <div *ngIf="submitted && editControlsFormValid.htmlData.errors?.required && editControlsForm.get('selectedOption').value" class="text-danger">
                                Values are required.
                            </div>
                            <div class="col-1" style="margin-top:45px">
                                <label class="mb-1">Is Editable</label>
                                <input type="checkbox" formControlName="isEdit" [class.disabled-element]="editControlsForm.get('controlType').value != 'dropdown' && editControlsForm.get('selectedOption').value == 'lookups'" />
                            </div>

                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-12">
                            <label class="mb-1">Normal Text</label>
                            <input type="text" id="input7" formControlName="normalText" class="form-control" placeholder="Enter Normal Text">
                        </div>
                        <div class="col-12">
                            <label class="mb-1">Abnormal Text</label>
                            <input type="text" id="input8" formControlName="abnormalText" class="form-control" placeholder="Enter AbNormal Text">
                        </div>
                        <div class="col">
                            <div class="custom-control custom-switch mb-0">
                                <input type="checkbox" id="appearance" class="custom-control-input" name="appearance" [(ngModel)]="requireComments" [ngModelOptions]="{standalone: true}">
                                <label class="custom-control-label" for="appearance"> Require Comments</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="custom-control custom-switch mb-0">
                                <input type="checkbox" id="appearance1" class="custom-control-input" formControlName="isApplyAll">
                                <label class="custom-control-label" for="appearance1"> keep always master changes</label>
                            </div>
                            <div class="custom-control custom-switch mb-0">
                                <input type="checkbox" id="appearance2" class="custom-control-input" formControlName="isFlow">
                                <label class="custom-control-label" for="appearance2"> isFlow Tag</label>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group" formGroupName="styleProperties">
                        <div class="col">
                            <div class="custom-control custom-switch mb-0">
                                <input type="checkbox" id="rquiredFeild" class="custom-control-input" formControlName="requiredField">
                                <label class="custom-control-label" for="rquiredFeild"> required Feild</label>
                            </div>
                        </div>

                        <div class="col">
                            <div class="custom-control custom-switch mb-0">
                                <input type="checkbox" id="hiddeLable" class="custom-control-input" formControlName="hideLable">
                                <label class="custom-control-label" for="hiddeLable"> Hide Lable</label>
                            </div>
                            <div class="custom-control custom-switch mb-0">
                                <input type="checkbox" id="disableFeild" class="custom-control-input" formControlName="isDisable">
                                <label class="custom-control-label" for="disableFeild">Disable Feild</label>
                            </div>
                        </div>
                        <div class="row form-group">

                            <div formArrayName="script" class="col-12">
                                <div *ngFor="let eventScript of eventsArray.controls; let i = index" [formGroupName]="i" class="col-12">
                                    <div class="col-12">
                                        <label class="mb-1">Events Type</label>
                                        <ng-select class="ng-select-sm" [items]="eventType"
                                                   autocomplete="nope"
                                                   [multiple]="false"
                                                   placeholder="-Select Type-"
                                                   formControlName="eventType"
                                                   [closeOnSelect]="false"
                                                   [ngClass]="{ 'is-invalid': submitted && toggleSettingValue}">
                                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                <div class="ng-option disabled">
                                                    No Type found for '{{searchTerm}}'
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                    <div class="col-12">
                                        <label class="mb-1">Logic Name</label>
                                        <input type="text" id="logicname" class="form-control" formControlName="logicName" />

                                    </div>
                                    <div class="col-12">
                                        <label class="mb-1">Parameters</label>
                                        <textarea id="Events" class="form-control" formControlName="events" placeholder="Enter events (comma separated)"></textarea>
                                    </div>
                                    <div class="col-12">
                                        <button type="button" class="btn btn-sm btn-danger" (click)="removeEventScript(i)">Remove</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <button type="button" class="btn btn-sm  btn-primary" (click)="addEventScript()">Add Event</button>
                            </div>
                        </div>

                        <div class="col-12">
                            <label class="mb-1">Prefix</label>
                            <input type="text" id="PreFix" class="form-control" formControlName="preFix" placeholder="Enter Prefix">
                        </div>
                        <div class="col-12">
                            <label class="mb-1">Suffix</label>
                            <input type="text" id="SuFfix" class="form-control" formControlName="suFfix" placeholder="Enter suffix">
                        </div>
                        <div class="col-12">
                            <label class="mb-1">Suffix Text</label>
                            <input type="text" id="SuFfixText" class="form-control" formControlName="suFfixText" placeholder="Enter suffix Text">
                        </div>

                        <div class="col-12">
                            <label class="mb-1">Min Length</label>
                            <input type="number" id="minlength" class="form-control" formControlName="minLength" placeholder="Min Length" min="0">
                        </div>
                        <div class="col-12">
                            <label class="mb-1">Max Length</label>
                            <input type="number" id="maxlenght" class="form-control" formControlName="maxLength" placeholder="Max Length" min="0">
                        </div>

                        <div class="col-12">
                            <label class="mb-1">Custom Validators</label>
                            <input type="text" id="customValidator" class="form-control" formControlName="customValidatorNames" placeholder="Enter Validator Name">
                        </div>
                        <div class="col-12">
                            <label class="mb-1">Custom Error Message</label>
                            <input type="text" id="customerrormsg" class="form-control" formControlName="customErrorMessage" placeholder="Custom Error text">
                        </div>
                        <div class="col-12">
                            <label class="mb-1">Place Holder</label>
                            <input type="text" id="placeholder" class="form-control" formControlName="placeHolder" placeholder="Enter place holder text">
                        </div>


                        <div class="col-12">
                            <label class="mb-1">container Css Classes</label>
                            <ng-select class="ng-select-sm" [items]="containerCssClasses"
                                       autocomplete="nope"
                                       [multiple]="true"
                                       placeholder="-Select Section-"
                                       formControlName="containerCss"
                                       [closeOnSelect]="false"
                                       [ngClass]="{ 'is-invalid': submitted && toggleSettingValue}">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled">
                                        No Sections found for '{{searchTerm}}'
                                        <button (click)="addCustomClass($event, searchTerm,ngSelectRef,'containerCssClasses')">Add "{{searchTerm}}"</button>
                                    </div>
                                </ng-template>
                            </ng-select>

                        </div>

                        <div class="col-12">
                            <label class="mb-1">container Css Styles</label>
                            <input type="text" id="container2" class="form-control" formControlName="containerStyles" placeholder="Enter container style properties">
                        </div>

                        <div class="col-12">
                            <label class="mb-1">Label Css Classes</label>
                            <ng-select class="ng-select-sm" [items]="labelCssClasses"
                                       autocomplete="nope"
                                       [multiple]="true"
                                       placeholder="Enter label css properties"
                                       formControlName="labelCss"
                                       [closeOnSelect]="false"
                                       [ngClass]="{ 'is-invalid': submitted && toggleSettingValue}">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled">
                                        No Sections found for '{{searchTerm}}'
                                        <button (click)="addCustomClass($event, searchTerm,ngSelectRef,'labelCssClasses')">Add "{{searchTerm}}"</button>
                                    </div>
                                </ng-template>
                            </ng-select>

                        </div>
                        <div class="col-12">
                            <label class="mb-1"> Label Styles</label>
                            <input type="text" id="input3" class="form-control" formControlName="labelStyles" placeholder="Enter Label style properties">
                        </div>
                        <div class="col-12">
                            <label class="mb-1">InputDiv Css Classes</label>
                            <ng-select class="ng-select-sm" [items]="inputDivCssClasses"
                                       autocomplete="nope"
                                       [multiple]="true"
                                       placeholder="Enter InputDiv css properties"
                                       formControlName="inputDivCss"
                                       [closeOnSelect]="false"
                                       [ngClass]="{ 'is-invalid': submitted && toggleSettingValue}">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled">
                                        No Sections found for '{{searchTerm}}'
                                        <button (click)="addCustomClass($event, searchTerm,ngSelectRef,'inputDivCssClasses')">Add "{{searchTerm}}"</button>
                                    </div>
                                </ng-template>
                            </ng-select>

                        </div>
                        <div class="col-12">
                            <label class="mb-1">InputDiv Styles</label>
                            <input type="text" id="InputDivStyle" class="form-control" formControlName="inputDivStyles" placeholder="Enter InputDiv style properties">
                        </div>
                        <div class="col-12">
                            <label class="mb-1">Control Css Classes</label>
                            <ng-select class="ng-select-sm" [items]="controlCssClasses"
                                       autocomplete="nope"
                                       [multiple]="true"
                                       placeholder="Enter Control css properties"
                                       formControlName="controlCss"
                                       [closeOnSelect]="false"
                                       [ngClass]="{ 'is-invalid': submitted && toggleSettingValue }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled">
                                        No Sections found for '{{searchTerm}}'
                                        <button (click)="addCustomClass($event, searchTerm,ngSelectRef,'controlCssClasses')">Add "{{searchTerm}}"</button>
                                    </div>
                                </ng-template>
                            </ng-select>

                        </div>
                        <div class="col-12">
                            <label class="mb-1">Control Styles</label>
                            <input type="text" id="input4" class="form-control" formControlName="controlStyles" placeholder="Enter Control style properties">
                        </div>

                        <div class="col">
                            <div class="custom-control custom-switch mb-0">
                                <input type="checkbox" id="appearance" class="custom-control-input" formControlName="requireComments">
                                <label class="custom-control-label" for="appearance"> Require Comments</label>
                            </div>
                        </div>
                        <div class="col">

                            <div>

                            </div>
                            <div>
                                <div class="custom-control custom-switch mb-0">
                                    <input type="checkbox" id="appearance3" class="custom-control-input" formControlName="isFlowEtoE">
                                    <label class="custom-control-label" for="appearance3"> isFlow Tag E to E</label>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-12" *ngIf="editControlsForm.get('controlType').value === 'calculation'">
                        <div class="form-group mb-3">
                            <label class="mb-1">Formula</label>
                            <input type="text" id="input4" formControlName="formula" class="form-control" placeholder="Enter Formula" [class.disabled-element]="isFormulaDisabled">
                            <div *ngIf="submitted &&editControlsFormValid.formula.errors?.required" class="text-danger">
                                Please Enter formula.
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="submit" [disabled]="submitting" class="btn  btn-primary btn-sm ml-1">
                    <span *ngIf="submitting">
                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                        Please wait..
                    </span>
                    <span *ngIf="!submitting"><i class="mdi mdi-content-save"></i>Update</span>
                </button>
                &nbsp;&nbsp;
                <!--<button type="button" class="btn btn-danger btn-sm" (click)="onCloseModal()"><i class="mdi mdi-eraser"></i> Cancel</button>-->
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-sm" (click)="onDirectOmit();">Cancel</button>
        <button type="submit" [disabled]="submitting" class="btn btn-primary btn-sm ml-1">
            <span *ngIf="submitting">
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                Please wait..
            </span>
            <span *ngIf="!submitting">Submit</span>
        </button>
    </div>
</div>


<ng-template #manualOptions>
    <div class="modal-header">
        <h4 class="modal-title">Add Values</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onOptionClose();">×</button>
    </div>
    <div class="modal-body" style="max-height: 65vh; min-height: 55vh; overflow: auto">

        <div *ngIf="selectedDesign === 'manual'">
            <table class="gridtable" width="100%">
                <tbody>
                    <tr>
                        <th>Number of Options <code>*</code> :</th>
                        <th>Add New Option :</th>
                        <td>
                            <input type="number" [(ngModel)]="numOptions" (ngModelChange)="addItemsCount()">

                            <input type="number" [(ngModel)]="numOptions" class="form-control" (ngModelChange)="addItemsCount()" required #numOptionsField="ngModel" />

                            <div *ngIf="(numOptionsField.invalid && numOptionsField.touched) || (submitClicked && numOptionsField.invalid)">
                                <div *ngIf="numOptionsField.errors?.required" class="text-danger">Number of options is required</div>
                                <div *ngIf="numOptionsField.errors?.min" class="text-danger">Please enter a value greater than zero</div>

                            </div>

                        </td>
                    </tr>

                </tbody>
            </table>

            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        Adjust the column width and offset as needed
                        <div *ngFor="let option of optionlist; let i = index" class="mb-2 d-flex align-items-center">
                            <input type="text" [(ngModel)]="optionlist[i].value" class="form-control" required #optionValueField="ngModel" />
                            <div *ngIf="(optionValueField.invalid && (optionValueField.dirty || optionValueField.touched || submitClicked))">
                                <div *ngIf="optionValueField.errors?.required" class="text-danger">Option value is required</div>

                            </div>


                            <a href="javascript:;" class="action-icon text-danger ml-1"
                               (click)="removeOption(i)" placement="right" ngbTooltip="Delete">
                                <i class="mdi mdi-trash-can"></i>
                            </a>
                            <a *ngIf="i === optionlist.length - 1" href="javascript:;" class="fe-plus plusbutton p-1 btn btn-success ml-1" (click)="addEmptyValues()" placement="right" ngbTooltip="Add">

                            </a>
                        </div>
                    </div>
                </div>
            </div>




        </div>
        <div *ngIf="selectedDesign === 'lookups'">

            <lookup-global (sendlookupselctedValue)="handleLookupData($event)" [dataFormcreation]="previousLookup" (onClose)="onCloseModal()"></lookup-global>
        </div>


    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-success btn-xs" (click)="creatingFeildsDesign()">
            <span class="mdi mdi-file-plus"></span>  Add
        </button>
        <button type="button" class="btn btn-danger btn-xs" data-dismiss="modal" aria-hidden="true" (click)="onOptionClose();"> <i class="mdi mdi-close"></i> Close</button>
    </div>
</ng-template>
