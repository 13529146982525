import { Component, OnDestroy, OnInit, ChangeDetectorRef, NgZone } from "@angular/core";
@Component({
    templateUrl: "./obstetric-ward-handover.html",
    styleUrls: ["./obstetric-ward-handover.css"]
})

export class ObstetricWardHandoverPage implements OnInit, OnDestroy {
    constructor() {

    }
    ngOnInit() {
    }

    ngOnDestroy() {
    }
}
