import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ApiResources, UtilHelper } from "@shared/helpers";
import { Counselling, CounsellingPackage, ICounsellingPatientInfo, IResource, IUserAccount, Page, GenericResponse } from "@shared/models";
import { AppData, HttpService, NotifyService, ResourceService } from "@shared/services";
import { finalize, takeUntil } from "rxjs/operators";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { MobileValidator } from "@shared/validators";
import { DoctorUnits } from "@shared/entities";
import { HttpErrorResponse } from "@angular/common/http";
@Component({
    selector: "modify-counselling",
    templateUrl: "./modify-counselling.html",
    styleUrls: ["./modify-counselling.css"],
    encapsulation: ViewEncapsulation.None
})
export class ModifyCounsellingComponent implements OnInit, OnDestroy {
    @Input() patientId: number;
    @Input() counsellingId: number;
    @Input() active: boolean;
    @Input() isCancelled: boolean;
    @Output() added: EventEmitter<boolean> = new EventEmitter();

    page: Page;
    loadingCounselling: boolean;
    counselling: Counselling;
    counsellingPackages: Array<CounsellingPackage>;
    loadingPatientInfo: boolean;
    patientInfo: ICounsellingPatientInfo;
    loadingLocations: boolean;
    locations: Array<IResource>;
    loadingChargeModuleTemplates: boolean;
    chargeModuleTemplates: Array<IResource>;
    loadingInsuranceCompanies: boolean;
    insuranceCompanies: Array<IResource>;
    loadingTPAs: boolean;
    tpas: Array<IResource>;
    loadingPackageModules: boolean;
    packageModules: Array<IResource>;
    counsellingForm: FormGroup;
    selectedCounsellingPackage: CounsellingPackage;
    isSubmitted: boolean;
    isSubmitting: boolean;
    isSavingDraft: boolean;
    signatureType: string;
    signatureFor: string;
    isSaveSignature: boolean;
    signPad: any;
    signImage: any;
    relations: Array<IResource>;
    loadingRelations: boolean;
    private modalRef: NgbModalRef;
    statusName: string;
    selectedDoctorUnit: DoctorUnits;
    doctorUnits: Array<DoctorUnits>;
    loadingDoctorUnits: boolean;
    locationId: number;
    loadSeniorSpecialization: boolean;
    seniorDoctorSpecializations: IResource[];
    tagDoctor: string;
    saveValidation: boolean;
    attendentName: string;
    attendentRelation: string;
    attendentContactNo: string;
    patientFamilyId: number;
    discountChange: boolean;
    isPublished: boolean;
    locationName: string;
    templateName: string;
    surgeries: IResource;
    loadingSurgeries: boolean;
    isSurgery = false;
    loadingLinkedSurgeryServices: boolean;
    linkedSurgeryServices: any

    notesEditor = ClassicEditor;
    config2 = {
        placeholder: "Enter your content here!",
        toolbar: ["heading", "|", "bold", "italic", "link", "bulletedList", "numberedList", "blockQuote"]
    };
    constructor(
        private readonly appData: AppData,
        private readonly modalService: NgbModal,
        private readonly httpService: HttpService,
        private readonly formBuilder: FormBuilder,
        private readonly notifyService: NotifyService,
        private readonly resourceService: ResourceService
    ) {
        this.page = new Page();
        this.counsellingPackages = new Array<CounsellingPackage>();
        this.selectedDoctorUnit = new DoctorUnits();
        this.doctorUnits = new Array<DoctorUnits>();
    }

    ngOnInit(): void {
        this.setUserAccount();
        this.fetchLocations();
        this.fetchInsuranceCompanies();
        this.fetchTPAs();
        this.fetchRelations();
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.locationId = this.page.userAccount.locationId;
                    this.locationName = this.page.userAccount.locationName;
                    this.fetchDoctorUnits(this.locationId);
                    if (this.isCancelled == false) {
                        this.counsellingPackages.forEach((x) => {
                            x.subTotal = 0;
                            x.packageDiscount = 0;
                            x.total = 0;
                            this.discountChange = true;
                        });
                    }

                } else {
                    this.page.userAccount = undefined;
                }
            });
    }



    get form() {
        return this.counsellingForm.controls;
    }
    trackByCounsellingPackage(_: number, item: CounsellingPackage): number {
        return item.id;
    }
    onSignatureDone(event: string) {
        this.isSaveSignature = true;
        this.patchSignature(event);
        this.isSaveSignature = false;
        this.onCloseModal();
    }

    onOpenModel(content: TemplateRef<any>, signatureFor: string) {
        this.signatureType = "signature";
        this.signatureFor = signatureFor;
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "xl",
            windowClass: "custom-modal effect-scale"
        });
    }

    onReceiveDrewSignature(signature: string) {
        this.patchSignature(signature);
    }

    onSaveUploadedSignature(blob: Blob) {
        this.isSaveSignature = true;
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const signature = reader.result as string;
            this.patchSignature(signature);
            this.isSaveSignature = false;
            this.onCloseModal();
        }
    }

    onChangePayType(payType: string): void {
        this.counsellingForm.get("insuranceCompanyId").setValue(null);
        this.counsellingForm.get("tpaId").setValue(null);
        this.counsellingForm.get("patientOrganization").setValue(null);

        this.loadingChargeModuleTemplates = false;
        this.chargeModuleTemplates = [];
        this.counsellingForm.get("chargeModuleTemplateId").setValue(null);
        this.counsellingPackages.forEach(x => {
            x.packageModuleId = null;
            x.chargeCategoryId = null;
        });
        const locationId = this.counsellingForm.get("locationId").value;
        payType && this.fetchChargeModuleTemplates(locationId, payType);
        this.fetchPackages(locationId, payType);
        this.fetchallSurgery();
        this.counsellingPackages.forEach((x) => {
            x.subTotal = 0;
            x.packageDiscount = 0;
            x.total = 0;
            this.discountChange = false;
        });


    }

    onChangeLocation(locationId: any): void {
        this.loadingChargeModuleTemplates = false;
        this.chargeModuleTemplates = [];
        this.locationName = locationId.name;
        this.counsellingForm.get("chargeModuleTemplateId").setValue(null);

        const payType = this.counsellingForm.get("payType").value;
        locationId && this.fetchChargeModuleTemplates(locationId, payType);
    }

    onChangeChargeModuleTemplate(chargeModuleTemplateId: number): void {
        this.loadingPackageModules = false;
        this.packageModules = [];

        const locationId = this.counsellingForm.get("locationId").value;
        const payType = this.counsellingForm.get("payType").value;
        chargeModuleTemplateId && this.fetchPackages(locationId, payType);
    }

    onChangePackageModule(counsellingPackage: CounsellingPackage, isSurgery: boolean) {
        counsellingPackage.loadingChargeCategories = false;
        counsellingPackage.chargeCategories = [];
        counsellingPackage.chargeCategory = undefined;
        counsellingPackage.chargeCategoryId = null;
        counsellingPackage.totalQuantity = 0;
        counsellingPackage.subTotal = 0;
        counsellingPackage.discountType = "P";
        counsellingPackage.discountAmount = null;
        counsellingPackage.discountPercentage = null;
        counsellingPackage.packageDiscount = 0;
        counsellingPackage.total = 0;
        if (this.isCancelled == false) {
            counsellingPackage.statusName = "R";
        }
        else {
            counsellingPackage.statusName = "C";
        }
        var id = this.counsellingForm.get("chargeModuleTemplateId")?.value ? this.counsellingForm.get("chargeModuleTemplateId")?.value : this.counselling.chargeModuleTemplateId
        const chargeModuleTemplateId = id;

        const locationId = this.counsellingForm.get("locationId")?.value;
        if (isSurgery)
            counsellingPackage.surgeryId && this.fetchChargeCategoriesForSurgery(counsellingPackage, chargeModuleTemplateId);
        else
            counsellingPackage.packageModuleId && this.fetchChargeCategories(counsellingPackage, chargeModuleTemplateId, locationId);

    }

    onChangeChargeCategory(counsellingPackage: CounsellingPackage, chargeCategoryId: number) {
        var chargeCategorys = this.counsellingPackages.filter(m => (m.packageModuleId === counsellingPackage.packageModuleId) && (m.chargeCategoryId === counsellingPackage.chargeCategoryId))
        if (chargeCategorys.length > 1) {
            this.notifyService.warning("This package and charge category is already present. Please select a package and charge category that has not been added.")
            return;
        }
        counsellingPackage.chargeCategoryId = chargeCategoryId;
        const chargeCategory = counsellingPackage.chargeCategories?.find(m => m.id === counsellingPackage.chargeCategoryId);
        counsellingPackage.chargeCategory = chargeCategory;
        counsellingPackage.subTotal = chargeCategory?.subTotal || 0;
        counsellingPackage.packageDiscount = chargeCategory?.discount || 0;
        counsellingPackage.discountType = "P";
        counsellingPackage.discountAmount = null;
        counsellingPackage.discountPercentage = null;
        counsellingPackage.discount = 0;
        counsellingPackage.total = chargeCategory?.total || 0;
    }

    onChangeDiscount(counsellingPackage: CounsellingPackage, type: string, value?: any): void {
        value = value ? parseFloat(value) : 0;
        let subTotal = parseFloat('' + (counsellingPackage.subTotal || 0));
        switch (type) {
            case "discountType": {
                counsellingPackage.discountPercentage = null;
                counsellingPackage.discountAmount = null;
                counsellingPackage.discount = 0;
                let total = subTotal - counsellingPackage.packageDiscount;
                counsellingPackage.total = total;
                break;
            }
            case "discountPercentage": {
                let discountPercentage = value || 0;
                let discountAmount = parseFloat((((subTotal * discountPercentage) / 100) || 0).toFixed(2));
                let discount = isNaN(discountAmount) ? 0 : discountAmount;
                let total = subTotal - discount - counsellingPackage.packageDiscount;
                counsellingPackage.discountPercentage = discountPercentage > 0 ? discountPercentage : null;
                counsellingPackage.discountAmount = null;
                counsellingPackage.discount = discount;
                counsellingPackage.total = total > 0 ? total : 0;
                break;
            }
            case "discountAmount": {
                let discountAmount = isNaN(value) || value <= 0 ? null : value;
                let discount = isNaN(discountAmount) ? 0 : discountAmount;
                let total = subTotal - discount - counsellingPackage.packageDiscount;
                counsellingPackage.discountPercentage = null;
                counsellingPackage.discountAmount = discountAmount;
                counsellingPackage.discount = discount;
                counsellingPackage.total = total > 0 ? total : 0;
                break;
            }
        }
    }

    onCloseModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
        } catch (e) {
            // ignored;
        }

        this.selectedCounsellingPackage = undefined;
        this.signatureType = undefined;
        this.signatureType = undefined;
        this.isSaveSignature = undefined;
    }

    onAddCounsellingPackage(): void {
        this.counsellingPackages.push(new CounsellingPackage());
    }

    onRemoveCounsellingPackage(counsellingPackage: CounsellingPackage): void {
        const isCounsellingPackageSelected = counsellingPackage.packageModuleId || counsellingPackage.chargeCategoryId;
        if (isCounsellingPackageSelected) {
            if (counsellingPackage.isPublished === true) {
                this.notifyService.warning("This package has already been published and cannot be removed");
                return;
            }
            this.notifyService.confirm("Are you sure to remove the selected package?", () => {
                const index = this.counsellingPackages.findIndex(m => m.counsellingPackageId === counsellingPackage.counsellingPackageId);
                this.counsellingPackages.splice(index, 1);
                !this.counsellingPackages?.length && this.counsellingPackages.push(new CounsellingPackage());
            }, () => { });
            if (counsellingPackage.isPublished === false)
                {
                    counsellingPackage.active = false;
                }           
            }
            else {
                const index = this.counsellingPackages.findIndex(m => m.id === counsellingPackage.id);
                this.counsellingPackages.splice(index, 1);
                !this.counsellingPackages?.length && this.counsellingPackages.push(new CounsellingPackage());
            }
    }
    
        onViewCounsellingPackage(counsellingPackage: CounsellingPackage, content: TemplateRef<any>,isSurgery:boolean): void {
            this.selectedCounsellingPackage = counsellingPackage;
            if (isSurgery) {
                this.loadingLinkedSurgeryServices = true;
                this.httpService.post<GenericResponse>(ApiResources.getURI(ApiResources.surgeryLink.base, ApiResources.surgeryLink.fetchLinkedSurgeryServices), { surgeryId: counsellingPackage.surgeryId })
                    .pipe(finalize(() => { this.loadingLinkedSurgeryServices = false }))
                    .pipe(takeUntil(this.page.unSubscribe))
                    .subscribe({
                        next: (response: GenericResponse) => {
                            this.linkedSurgeryServices = response.data;
                        },
                        error: () => {
                            this.notifyService.error("unable to view");
                        }
                    });
            }
            this.selectedCounsellingPackage.chargeModuleTemplateId = this.counsellingForm.get("chargeModuleTemplateId").value;
            this.locationId = this.counsellingForm.get("locationId").value;
            this.modalRef = this.modalService.open(content, {
                backdrop: "static",
                keyboard: false,
                centered: true,
                size: "lg",
                windowClass: "custom-modal effect-scale"
            });
        }

        onSaveAsDraft(): void {
            this.isSavingDraft = true;
            if(this.counsellingForm.invalid) {
            this.isSavingDraft = false;
            return;
        }
        this.saveValidation = false;
        this.saveCounsellingDetails(true);
    }

    onSubmit(): void {
        this.isSubmitted = true;
        if (this.counsellingForm.invalid) {
            return;
        }
        this.isPublished = true;
        this.counsellingPackages.forEach((x) => {
            if (!x.isPublished) {
                x.isPublished = this.isPublished;
            }
        });
        this.saveCounsellingDetails();
        this.isSubmitted = false;
    }

    onCancel(): void {
        this.added.emit(false);
    }

    ngOnDestroy(): void {
        this.page.unsubscribeAll();
        this.onCloseModal();
    }

    private saveCounsellingDetails(isDraft?: boolean): void {
        let counselling = this.counsellingForm.getRawValue();
        this.active = true;
        if (!isDraft && !((counselling.patientSignature && counselling.counsellorSignature && counselling.attendentSignature) || (counselling.patientBase64Signature && counselling.counsellorBase64Signature && counselling.attendentBase64Signature))) {
            this.notifyService.warning("The detailed description of the counselling must be signed by the patient and the counselor in order to be published; otherwise, save counselling as a draft.");
            this.isSubmitted = false;
            return;
        }

        if (!this.counsellingPackages?.length || this.counsellingPackages?.some(m => (!m.packageModuleId && !m.surgeryId) || !m.chargeCategoryId)) {
            this.isSubmitted = false;
            this.isSavingDraft = false;
            this.notifyService.warning("To proceed, please select added packages.");
            return;
        }

        this.isSubmitted = true
        if (counselling.counsellingId) counselling.modifiedBy = this.page.userAccount.accountId;
        else counselling.createdBy = this.page.userAccount.accountId;

        counselling.createdByRoleId = this.page.userAccount.roleId;
        counselling.createdByName = this.page.userAccount.fullName;
        counselling.umrNo = this.counselling ? this.counselling.umrNo : this.patientInfo.umrNo;
        counselling.isDraft = isDraft;
        counselling.active = true;
        counselling.patientFamilyId = this.patientFamilyId;
        isDraft ? this.isSavingDraft = true : this.isSubmitting = true;
        this.counsellingPackages.forEach((x) => {
            if (this.counsellingPackages.length == 0) {
                x.isPublished = this.isPublished;
            }
            if (this.counsellingPackages.length >= 0) {
                if (!x.counsellingPackageId) {
                    x.isPublished = this.isPublished;
                }
            }
        });


        const request = {
            counselling: counselling,
            counsellingPackages: this.counsellingPackages,
        }

        this.httpService
            .post(ApiResources.getURI(ApiResources.counselling.base, ApiResources.counselling.modify), request)
            .pipe(finalize(() => { this.isSubmitting = false; this.isSubmitted = false; this.isSavingDraft = false }), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (message: string) => {
                    this.notifyService.success(message);
                    this.added.emit(true);

                },
                error: () => this.notifyService.defaultError()
            });
    }

    private patchSignature(signature: string): void {
        if (this.signatureFor === "Counselling Given To")
            this.counsellingForm.get("patientBase64Signature").setValue(signature);
        else if (this.signatureFor === "Counsellor")
            this.counsellingForm.get("counsellorBase64Signature").setValue(signature);
        else
            this.counsellingForm.get("attendentBase64Signature").setValue(signature);
    }

    private setUserAccount(): void {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (userAccount: IUserAccount) => {
                    this.page.userAccount = userAccount;
                    this.patientId && this.findPatientInfo();
                    this.counsellingId && this.findCounselling();

                },
                error: () => this.page.userAccount = undefined
            });
    }

    private fetchLocations(): void {
        this.loadingLocations = true;
        this.resourceService.locations(true)
            .pipe(finalize(() => this.loadingLocations = false), takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => this.locations = response);
    }

    private findCounselling(): void {
        this.loadingCounselling = true;
        this.httpService
            .get(ApiResources.getURI(ApiResources.counselling.base, ApiResources.counselling.find), { counsellingId: this.counsellingId })
            .pipe(finalize(() => this.loadingCounselling = false), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (response: { counsellingRecords: Array<Counselling>, counsellingPackages: Array<CounsellingPackage> }) => {
                    const counselling = response.counsellingRecords || [];
                    this.counselling = counselling[0];
                    var counsellingAttendant = (response.counsellingRecords || []).filter(record => record.isAttendant === true);
                    if (counsellingAttendant.length > 0) {
                        const counselling = counsellingAttendant[0];
                        this.attendentName = counselling.relativeName;
                        this.attendentRelation = counselling.relation;
                        this.attendentContactNo = counselling.contactNo;
                        this.patientFamilyId = counselling.patientFamilyId
                    }
                    this.tagDoctor = this.counselling.providerName;
                    this.counsellingPackages = response.counsellingPackages || new Array<CounsellingPackage>();
                    this.buildForm();
                    this.fetchChargeModuleTemplates(this.counselling.locationId, this.counselling.payType);
                    if (this.counselling?.counsellingId && !this.counselling.isSurgery) 
                        this.fetchPackages(this.counselling.locationId, this.counselling.payType);                   
                    else 
                        this.fetchallSurgery()                   

                    if (this.counsellingPackages?.length && !this.counselling.isSurgery) {
                        this.counsellingPackages.forEach((counsellingPackage: CounsellingPackage) => {
                            this.fetchChargeCategories(counsellingPackage, this.counselling.chargeModuleTemplateId, this.counselling.locationId);
                        });
                    }
                    else {
                        this.counsellingPackages.forEach((counsellingPackage: CounsellingPackage) => {
                            this.fetchChargeCategoriesForSurgery(counsellingPackage, this.counselling.chargeModuleTemplateId);
                        });
                    }
                    if (this.counselling && this.counselling.active == true) {
                        this.updateForm();

                    } else {
                        this.buildForm();
                        if (this.counselling?.payType) {
                            this.counsellingForm.get("payType").setValue(this.counselling?.payType);
                            this.counsellingForm.get("chargeModuleTemplateId").setValue(this.counselling?.chargeModuleTemplateId);
                        }
                    }

                }
            });
    }

    private findPatientInfo(): void {
        this.loadingPatientInfo = true;
        this.httpService
            .get(ApiResources.getURI(ApiResources.counselling.base, ApiResources.counselling.patientInfo), { patientId: this.patientId || this.counselling.patientId })
            .pipe(finalize(() => this.loadingPatientInfo = false), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (patientInfo: ICounsellingPatientInfo) => {
                    this.patientInfo = patientInfo;
                    this.buildForm();
                },
                error: () => this.patientInfo = undefined
            });
    }

    private fetchChargeModuleTemplates(locationId: number, payType: string): void {
        const chargeModuleTemplateIdControl = this.counsellingForm.get("chargeModuleTemplateId");
        chargeModuleTemplateIdControl.enable();

        this.loadingChargeModuleTemplates = true;
        this.resourceService.chargeModuleTemplates(locationId, payType)
            .pipe(finalize(() => { this.loadingChargeModuleTemplates = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((chargeModuleTemplates: Array<IResource>) => {
                this.chargeModuleTemplates = chargeModuleTemplates;

                const chargeModuleTemplate = this.chargeModuleTemplates.find(m => m.status);
                if (chargeModuleTemplate) {
                    chargeModuleTemplateIdControl.setValue(chargeModuleTemplate.id);
                    chargeModuleTemplateIdControl.disable();
                    this.onChangeChargeModuleTemplate(chargeModuleTemplate.id);
                    this.templateName = chargeModuleTemplate.name
                }

            });
    }

    private fetchInsuranceCompanies(): void {
        this.loadingInsuranceCompanies = true;
        this.resourceService.insuranceCompanies()
            .pipe(finalize(() => { this.loadingInsuranceCompanies = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((insuranceCompanies: Array<IResource>) => this.insuranceCompanies = insuranceCompanies);
    }

    private fetchTPAs(): void {
        this.loadingTPAs = true;
        this.resourceService.tpaCompanies()
            .pipe(finalize(() => { this.loadingTPAs = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((tpas: Array<IResource>) => this.tpas = tpas);
    }

    private fetchPackages(locationId: number, payType: string) {
        this.loadingPackageModules = true;
        this.resourceService.packageModules(locationId, null, payType)
            .pipe(finalize(() => { this.loadingPackageModules = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((packageModules: Array<IResource>) => {
                this.packageModules = packageModules
            });
    }

    private fetchChargeCategories(counsellingPackage: CounsellingPackage, chargeModuleTemplateId: number, locationId: number): void {
        counsellingPackage.loadingChargeCategories = true;
        this.httpService
            .get(ApiResources.getURI(ApiResources.packageModule.base, ApiResources.packageModule.fetchChargeCategoriesWithTotal), { packageModuleId: counsellingPackage.packageModuleId, chargeModuleTemplateId, locationId })
            .pipe(finalize(() => counsellingPackage.loadingChargeCategories = false), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (chargeCategories: Array<IResource>) => {
                    const packageModule = this.packageModules?.find(m => m.id === counsellingPackage.packageModuleId);
                    if (chargeCategories?.length && packageModule) {
                        chargeCategories.forEach((chargeCategory: IResource) => {
                            let subTotal = chargeCategory.value ? parseFloat(chargeCategory.value) : 0, discount = 0, total = subTotal;

                            if (packageModule.discountType === "P") {
                                discount = parseFloat((((subTotal * (packageModule.discountPercentage || 0)) / 100) || 0).toFixed(2));
                                discount = isNaN(discount) ? 0 : discount;
                                total = subTotal - discount;
                                total = total > 0 ? total : 0;
                            } else if (packageModule.discountType === "A") {
                                discount = packageModule.discountAmount || 0;
                                discount = isNaN(discount) ? 0 : discount;
                                total = subTotal - discount;
                                total = total > 0 ? total : 0;
                            }

                            chargeCategory.subTotal = subTotal;
                            chargeCategory.discount = discount;
                            chargeCategory.total = total;
                        });
                    }

                    counsellingPackage.chargeCategories = chargeCategories;
                },
                error: () => counsellingPackage.chargeCategories = []
            });
    }

    private buildForm(): void {
        this.counsellingForm = this.formBuilder.group({
            counsellingId: 0,
            patientId: this.patientId,
            counsellingType: ["E", [Validators.required]],
            payType: [null, [Validators.required]],
            locationId: [this.page.userAccount.locationId, [Validators.required]],
            chargeModuleTemplateId: [null, [Validators.required]],
            insuranceCompanyId: null,
            tpaId: null,
            patientOrganization: null,
            patientBase64Signature: null,
            counsellorBase64Signature: null,
            patientSignature: null,
            counsellorSignature: null,
            attendentBase64Signature: null,
            attendentSignature: null,
            extraExclusionsNotes: null,
            attendantName: [null, [Validators.required]],
            attendantRelationship: [null, [Validators.required]],
            attendantContactNo: [null, [Validators.required, MobileValidator.isValid]],
            doctorUnitMasterId: null,
            isSurgery:[false]

        });
        if (this.patientId && this.counsellingPackages.length === 0) {
            this.counsellingPackages.push(new CounsellingPackage());
        }
        if (this.isCancelled == false) {
            this.tagDoctor = null;
            this.counsellingForm.setValue(null);
        }
    }
    private updateForm(): void {
        this.counsellingForm.patchValue({
            counsellingId: this.counselling.counsellingId,
            patientId: this.counselling.patientId,
            counsellingType: this.counselling.counsellingType,
            payType: this.counselling.payType,
            locationId: this.counselling.locationId ? this.counselling.locationId : this.page.userAccount.locationId,
            chargeModuleTemplateId: this.counselling.chargeModuleTemplateId,
            insuranceCompanyId: this.counselling.insuranceCompanyId,
            tpaId: this.counselling.tpaId,
            patientOrganization: this.counselling.patientOrganization,
            patientSignature: this.counselling?.patientSignature,
            counsellorSignature: this.counselling?.counsellorSignature,
            attendentSignature: this.counselling.attendentSignature,
            extraExclusionsNotes: this.counselling.extraExclusionsNotes,
            attendantName: this.attendentName,
            attendantRelationship: this.attendentRelation,
            attendantContactNo: this.attendentContactNo,
            doctorUnitMasterId: this.counselling.doctorUnitMasterId,
            isSurgery: this.counselling.isSurgery
        });
    }

    private resetCounsellingPackages(): void {
        this.counsellingPackages = [];
        this.counsellingPackages.push(new CounsellingPackage());
    }
    private fetchRelations() {
        this.loadingRelations = true;
        this.resourceService.relationships()
            .pipe(finalize(() => { this.loadingRelations = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.relations = response;
            });
    }
    private fetchDoctorUnits(locationId: number) {
        this.loadingDoctorUnits = true;
        const request = {
            locationId: locationId
        };
        this.httpService.post(ApiResources.getURI(ApiResources.doctorUnit.base, ApiResources.doctorUnit.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingDoctorUnits = false))
            .subscribe((response: Array<DoctorUnits>) => {
                this.doctorUnits = response;
                (error: HttpErrorResponse) => {
                    const errorMessage = UtilHelper.handleError(error);
                    if (errorMessage) {
                        this.notifyService.warningToast(errorMessage);
                    } else {
                        this.notifyService.defaultError();
                    }
                }
            });
    }
    onChangeDoctor($event: any) {
        if ($event != undefined) {
            var id = $event.doctorUnitMasterId
            var name = $event.unitName;
            if (id) {
                this.selectedDoctorUnit = this.doctorUnits.find(x => x.doctorUnitMasterId === id);
                this.tagDoctor = this.selectedDoctorUnit.tagDoctor[0].providerId != null ? this.selectedDoctorUnit.tagDoctor[0].providerName : null;

            }
        }
        else {
            this.tagDoctor = null;
        }
    }
    
    fetchallSurgery() {
        this.loadingSurgeries = true;

        this.resourceService.surgeris()
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loadingSurgeries = false }))
            .subscribe((response: any) => {
                this.surgeries = response;
            });
    }

    private fetchChargeCategoriesForSurgery(counsellingPackage: CounsellingPackage, chargeModuleTemplateId: number): void {
        counsellingPackage.loadingChargeCategories = true;
        this.httpService
            .get(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchSurgeryChargeCategoriesWithTotal), { surgeryId: counsellingPackage.surgeryId, chargeModuleTemplateId })
            .pipe(finalize(() => counsellingPackage.loadingChargeCategories = false), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (chargeCategories: Array<IResource>) => {
                    if (chargeCategories?.length) {
                        chargeCategories.forEach((chargeCategory: IResource) => {
                            let subTotal = chargeCategory.value ? parseFloat(chargeCategory.value) : 0, discount = 0, total = subTotal;                         
                            discount = isNaN(discount) ? 0 : discount;
                            total = subTotal - discount;
                            total = total > 0 ? total : 0;                             
                            chargeCategory.subTotal = subTotal;
                            chargeCategory.discount = discount;
                            chargeCategory.total = total;
                        });
                    }

                    counsellingPackage.chargeCategories = chargeCategories;
                },
                error: () => counsellingPackage.chargeCategories = []
            });
    }

    private onOptionSelect(value: boolean): void {
        this.isSurgery = value;
        this.counsellingForm.get("isSurgery").setValue(value);
        this.counsellingPackages?.forEach(x => {
            x.packageModuleId = null;
            x.chargeCategoryId = null;
            x.subTotal = 0;
            x.packageDiscount = 0;
            x.total = 0;
            this.discountChange = false;
            x.surgeryId = null;
        });        
    }
}