<style>
    .tooltip-inner {
        max-width: 200px !important;
        padding: 0.1rem 0.8rem !important;
        color: #fff !important;
        text-align: center !important;
        background-color: #005DED !important;
        border-radius: 0.8rem !important;
    }
    .blink {
        animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;
    }

    @keyframes blinker {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }
</style>

<div *ngIf="toggleService.showBox" class="timeline-close-btn" (click)="toggleService.toggle()">
    <button class="encounterBtn m-1 rounded-pill height30px">
        <img class="h-100" src="assets/images/timelineSvg/time-line.svg" /> <i class="timelineIconsAign mdi mdi-chevron-right font-26 ml6pxn"></i>
    </button>
</div>
<div *ngIf="!toggleService.showBox" class="timeline-close-box-btn" (click)="toggleService.toggle()">
    <button class="encounterBtn m-1 rounded-pill height30px">
        <i class="timelineIconsAign mdi mdi-chevron-left font-26 ml6pxn"></i><img class="h-100" src="assets/images/timelineSvg/time-line.svg" />
    </button>
</div>
<div *ngIf="toggleService.showBox" class="appointments-box card h-100  p-3px border-left-darkInfo border-top-darkInfo">
    <div class="fit-height align-items-center  overflow-auto scroll-wrapper scrollable-table height1000px">
        <div class="align-items-center overflow-auto scroll-wrapper" *ngIf="record">
            <div class="headingStyle">
                <div class="trapezium font-weight-semibold text-white">
                    <span class="p-1">Summary</span>
                </div>
            </div>
            <table class="table table-centered table-sm ">
                <thead>
                    <tr class="fixed-row theaderbgc">
                        <th>Module</th>
                        <th>Total Amt</th>
                        <th>Discount Amt</th>
                        <th>Net Amt</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Packages</td>
                        <td>{{ (serviceOrder.packageTotal || 0) | currency :'₹ ':'symbol':'1.2-2' }}</td>
                        <td>{{ (0) | currency :'₹ ':'symbol':'1.2-2' }}</td>
                        <td>{{ (serviceOrder.packageTotal || 0) | currency :'₹ ':'symbol':'1.2-2' }}</td>
                    </tr>
                    <tr>
                        <td>Services</td>
                        <td>{{(serviceOrder.generalTotal || 0) | currency :'₹ ':'symbol':'1.2-2'}}</td>
                        <td>{{ (serviceOrder.generalTotalDiscount || 0) | currency :'₹ ':'symbol':'1.2-2' }}</td>
                        <td>{{ ((serviceOrder.generalTotal || 0) - (serviceOrder.generalTotalDiscount || 0)) | currency :'₹ ':'symbol':'1.2-2' }}</td>
                    </tr>
                    <tr>
                        <td>Doctor Visits</td>
                        <td>{{(serviceOrder.doctorVisitTotal || 0) | currency :'₹ ':'symbol':'1.2-2'}}</td>
                        <td>{{(serviceOrder.doctorVisitTotalDiscount || 0) | currency :'₹ ':'symbol':'1.2-2' }}</td>
                        <td>{{((serviceOrder.doctorVisitTotal || 0) - (serviceOrder.doctorVisitTotalDiscount || 0)) | currency :'₹ ':'symbol':'1.2-2' }}</td>
                    </tr>
                    <tr>
                        <td>Room Rents</td>
                        <td>{{(serviceOrder.bedServicesTotal || 0) | currency :'₹ ':'symbol':'1.2-2'}}</td>
                        <td>{{(serviceOrder.bedServicesTotalDiscount|| 0) | currency :'₹ ':'symbol':'1.2-2' }}</td>
                        <td>{{((serviceOrder.bedServicesTotal || 0) - (serviceOrder.bedServicesTotalDiscount || 0)) | currency :'₹ ':'symbol':'1.2-2' }}</td>
                    </tr>
                    <tr>
                        <td>Pharmacy Services</td>
                        <td>{{(serviceOrder.pharmacyIndentTotal || 0) | currency :'₹ ':'symbol':'1.2-2'}}</td>
                        <td>{{(serviceOrder.pharmacyServicesTotalDiscount || 0) | currency :'₹ ':'symbol':'1.2-2' }}</td>
                        <td>{{((serviceOrder.pharmacyIndentTotal || 0) - (serviceOrder.pharmacyServicesTotalDiscount || 0)) | currency :'₹ ':'symbol':'1.2-2' }}</td>
                    </tr>
                    <tr>
                        <td>Laboratory Services</td>
                        <td>{{(serviceOrder.labServicesTotal || 0) | currency :'₹ ':'symbol':'1.2-2'}}</td>
                        <td>{{(serviceOrder.labServicesTotalDiscount || 0) | currency :'₹ ':'symbol':'1.2-2' }}</td>
                        <td>{{((serviceOrder.labServicesTotal || 0) - (serviceOrder.labServicesTotalDiscount || 0)) | currency :'₹ ':'symbol':'1.2-2' }}</td>
                    </tr>
                    <tr>
                        <td>Operation Theater</td>
                        <td>{{(serviceOrder.surgeryServicesTotal || 0) | currency :'₹ ':'symbol':'1.2-2'}}</td>
                        <td>{{(serviceOrder.surgeryServicesTotalDiscount|| 0) | currency :'₹ ':'symbol':'1.2-2' }}</td>
                        <td>{{((serviceOrder.surgeryServicesTotal || 0) - (serviceOrder.surgeryServicesTotalDiscount || 0)) | currency :'₹ ':'symbol':'1.2-2' }}</td>
                    </tr>
                    <tr>
                        <td>Scan Services</td>
                        <td>{{(serviceOrder.scanServicesTotal || 0) | currency :'₹ ':'symbol':'1.2-2'}}</td>
                        <td>{{(serviceOrder.scanServicesTotalDiscount || 0) | currency :'₹ ':'symbol':'1.2-2' }}</td>
                        <td>{{((serviceOrder.scanServicesTotal || 0) - (serviceOrder.scanServicesTotalDiscount || 0)) | currency :'₹ ':'symbol':'1.2-2' }}</td>
                    </tr>
                    <tr>
                        <td>Surgeon Charge Services</td>
                        <td>{{(serviceOrder.surgeonChargeTotal|| 0) | currency :'₹ ':'symbol':'1.2-2'}}</td>
                        <td>{{(serviceOrder.surgeonChargeTotalDiscount || 0) | currency :'₹ ':'symbol':'1.2-2' }}</td>
                        <td>{{((serviceOrder.surgeonChargeTotal || 0) - (serviceOrder.surgeonChargeTotalDiscount || 0)) | currency :'₹ ':'symbol':'1.2-2' }}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{{((serviceOrder.packageTotal || 0)+(serviceOrder.generalTotal || 0)+(serviceOrder.doctorVisitTotal || 0) +(serviceOrder.bedServicesTotal || 0)+(serviceOrder.pharmacyIndentTotal || 0)+(serviceOrder.labServicesTotal || 0)+(serviceOrder.surgeryServicesTotal || 0)+(serviceOrder.scanServicesTotal || 0) + (serviceOrder.surgeonChargeTotal|| 0)) | currency :'₹ ':'symbol':'1.2-2'}}</td>
                        <td>{{((serviceOrder.generalTotalDiscount || 0)+(serviceOrder.doctorVisitTotalDiscount || 0) +(serviceOrder.bedServicesTotalDiscount || 0)+(serviceOrder.pharmacyServicesTotalDiscount || 0)+(serviceOrder.labServicesTotalDiscount || 0)+(serviceOrder.surgeryServicesTotalDiscount || 0)+(serviceOrder.scanServicesTotalDiscount || 0) + (serviceOrder.surgeonChargeTotalDiscount|| 0)) | currency :'₹ ':'symbol':'1.2-2'}}</td>
                        <td>{{(getTotalAmount || 0) | currency :'₹ ':'symbol':'1.2-2' }}</td>

                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="receiptsData.length > 0" class="align-items-center  overflow-auto scroll-wrapper scrollable-table height400px">
            <div class="headingStyle fixed-row">
                <div class="trapezium font-weight-semibold text-white">
                    <span class="p-1">Receipts</span>
                </div>
            </div>
            <table class="table table-sm">
                <thead>
                    <tr class="fixed-row2 theaderbgc">
                        <th>Date</th>
                        <th>RCNo</th>
                        <th>Amount</th>
                        <th>Paymode</th>
                        <th>Payment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of receiptsData;let i = index;">
                        <td><small [textContent]="item.createdDate | date:'dd-MM-yyyy'"></small></td>
                        <td><small>{{'RN-0' + item.receiptId}}</small></td>
                        <td><small [textContent]="item.cost | currency :'₹ ':'symbol':'1.2-2'"></small></td>
                        <td><small [textContent]="item.payTypeName"></small></td>
                        <td>
                            <div *ngIf="item.receiptTypeId == receiptType.Refund">
                                <div [ngClass]="{'text-line-through' : !item.active}" *ngIf="item.isRefunded" class="badge badge-danger"><small>Refund Success</small></div>
                                <div [ngClass]="{'text-line-through' : !item.active}" *ngIf="!item.isRefunded" class="badge badge-warning"><small>Refund Pending</small></div>
                            </div>
                            <div *ngIf="item.receiptTypeId == receiptType.Cash">
                                <div [ngClass]="{'text-line-through' : !item.active}" *ngIf="item.isAdvance && item.payTypeName != 'Insurance'" class="badge badge-blue"><small>Advance Paid</small></div>
                                <div [ngClass]="{'text-line-through' : !item.active}" *ngIf="!item.isAdvance && item.payTypeName != 'Insurance'" class="badge badge-success"><small>{{item.paymentType}}</small></div>
                                <div [ngClass]="{'text-line-through' : !item.active}" *ngIf="item.payTypeName == 'Insurance'"><small>----</small></div>

                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>












        <div class="align-items-center  overflow-auto">
            <div class="headingStyle fixed-row">
                <div class="trapezium font-weight-semibold text-white">
                    <span class="p-1">Footer Details</span>
                </div>
            </div>
            <table class="table table-sm">
                <tbody>
                    <tr>
                        <td>
                            <div class="d-flex col-12">
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        Short Auth
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        <span [textContent]="(initialAmount || 0) | currency:'INR'"></span>
                                    </div>
                                </div>
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        Inadm.Disc
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                      ---
                                    </div>
                                </div>
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        Total Auth
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        <span [textContent]="(insurancefinalAmount || 0) | currency:'INR'"></span>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="d-flex col-12">
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        TCS
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        ---
                                    </div>
                                </div>

                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        Discount%
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        ---
                                    </div>
                                </div>
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        Total
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        <span [textContent]="(getTotalAmount || 0) | currency:'INR'"></span>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="d-flex col-12">
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        Disc
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        ---
                                    </div>
                                </div>
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        Net
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        <span [textContent]="(finalAmount || 0) | currency:'INR'"></span>
                                    </div>
                                </div>
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        CoPayAmt
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        <span [textContent]="(coPayAmount || 0) | currency:'INR'"></span>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="d-flex col-12">
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        Dis Allowed
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        ---
                                    </div>
                                </div>
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        I Panel Diff Amt(Payble)
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        ---
                                    </div>
                                </div>
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        Reason
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        ---
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="d-flex col-12">
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        Paid
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        <span  [textContent]="finalBillBasics.totalAmountPaid | currency:'INR'"></span>
                                    </div>
                                </div>
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        Balance
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        <span [textContent]=" ((finalAmount - finalBillBasics.totalAmountPaid) > 0 ? (finalAmount - finalBillBasics.totalAmountPaid)  : 0 )| currency:'INR'"></span>

                                    </div>
                                </div>
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        Refundable
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        <span [textContent]="(finalBillBasics.pendingAmount) | currency:'INR'"></span>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="d-flex col-12">
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        Org.DiscAmt
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        ---
                                    </div>
                                </div>
                                <div class="d-flex col-4">
                                    <div class="col-5" style="white-space: pre;">
                                        Post Discount
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        ---
                                    </div>
                                </div>
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        Inadm.Amt
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        ---
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="d-flex col-12">
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        G.W.Disc
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        ---
                                    </div>
                                </div>
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        Remarks
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        ---
                                    </div>
                                </div>
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        Auth By
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        ---
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="d-flex col-12">
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        Payable By Tpa
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        ---
                                    </div>
                                </div>
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        Payable By Pat
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        ---
                                    </div>
                                </div>
                                <div class="d-flex col-4">
                                    <div class="col-5">
                                        Refunded
                                    </div>
                                    <div class="col-1">
                                        :
                                    </div>
                                    <div class="col-6">
                                        <span [textContent]="(refundedPayAmount) | currency:'INR'"></span>

                                        
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

























        <div class="row" *ngIf="!loading">
            <div class="col-12">
                <div class="card mb-0">
                    <div class="headingStyle">
                        <div class="trapezium font-weight-semibold text-white">
                            <span class="p-1">Final Bill</span>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <form [formGroup]="finalBillForm" (submit)="onSubmit()">
                            <div class="overflow-auto">
                                <table class="table table-centered table-bordered table-sm mb-0">
                                    <tfoot>
                                        <tr class="table-warning">
                                            <th colspan="7" class="text-right">Gross Amount</th>
                                            <th [textContent]="(getTotalAmount || 0) | currency:'INR'"></th>
                                        </tr>
                                        <tr class="table-active">
                                            <th colspan="7" class="text-right">
                                                <div *ngIf="mode === editMode.New" class="row d-flex align-items-center">
                                                    <div class="col-lg-3"></div>
                                                    <div class="col-lg-3" *ngIf="!form.discountType.value"></div>
                                                    <div class="col-lg-3">
                                                        <h5>Discount</h5>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <select class="form-control" formControlName="discountType" (change)="onClickDiscountReason()">
                                                            <option selected [ngValue]="null">No Discount</option>
                                                            <option [ngValue]="discountTypeEnum.Number" [disabled]="disableActionBtns">Discount In Amount</option>
                                                            <option [ngValue]="discountTypeEnum.Percentage" [disabled]="disableActionBtns">Discount In Percentage</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="form.discountType.value">
                                                        <div class="input-group">
                                                            <input [ngClass]="{ 'is-invalid': form.discount.errors }" type="number" class="form-control" formControlName="discount"
                                                                   [placeholder]="'max ' + (form.discountType.value == discountTypeEnum.Number ? ('₹' + (((serviceOrder.packageTotal || 0) + (serviceOrder.generalTotal || 0)+ (serviceOrder.labServicesTotal || 0)+ (serviceOrder.surgeryServicesTotal || 0)+ (serviceOrder.scanServicesTotal || 0)+ (serviceOrder.doctorVisitTotal || 0)+ (serviceOrder.bedServicesTotal || 0) + (serviceOrder.pharmacyIndentTotal || 0)))) : '100%')">
                                                            <div class="input-group-append">
                                                                <div class="input-group-text">
                                                                    <span *ngIf="form.discountType.value != discountTypeEnum.Number">%</span>
                                                                    <span *ngIf="form.discountType.value == discountTypeEnum.Number">₹</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="mode !== editMode.New" class="row d-flex align-items-center">
                                                    <div class="col-lg-3"></div>
                                                    <div class="col-lg-3"></div>
                                                    <div class="col-lg-2"></div>
                                                    <div class="col-lg-1">
                                                        <span placement="left" *ngIf="form.discountType.value == discountTypeEnum.Number ||  form.discountType.value == discountTypeEnum.Percentage"
                                                              [ngbTooltip]="'Reason For Discount : ' + form.discountReason.value">
                                                            <i class="mdi mdi-information"></i>
                                                        </span>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <h5 *ngIf="!form.discountType.value">No Discount</h5>
                                                        <h5 *ngIf="form.discountType.value == discountTypeEnum.Number">Discount In Amount</h5>
                                                        <h5 *ngIf="form.discountType.value == discountTypeEnum.Percentage">Discount In Percentage <span class="small">(<span [textContent]="form.discount.value + '%'"></span>)</span></h5>
                                                    </div>
                                                </div>
                                            </th>
                                            <th [textContent]="(!form.discountType.value
                                        ? 0
                                        : form.discountType.value == discountTypeEnum.Number
                                            ? (form.discount.value || 0)
                                            : (getTotalAmount || 0) * ((form.discount.value || 0)/100)) | currency:'INR'"></th>
                                        </tr>
                                        <tr *ngFor="let item of insuranceAdmission">
                                            <th colspan="7" class="text-right" [textContent]="item.insuranceCompanyName"></th>
                                            <th [textContent]="(item.expectedAmount || 0) | currency:'INR'"></th>
                                        </tr>
                                        <tr class="table-warning">
                                            <th colspan="7" class="text-right">Net Amount</th>
                                            <th [textContent]="(finalAmount || 0) | currency:'INR'"></th>
                                        </tr>
                                        <!-- Need -->

                                        <!--<tr *ngIf="admission && admission.payType==='I'" class="table-warning">
                                            <th colspan="7" class="text-right">Insurance Amount(Estimated)</th>
                                            <th [textContent]="(finalAmount || 0) | currency:'INR'"></th>
                                        </tr>-->
                                        <tr *ngIf="finalBillBasics.insuranceCovered && finalBillBasics.insuranceCovered>0" class="table-active">
                                            <th colspan="7" class="text-right">Insurance Covered</th>
                                            <th [textContent]="(finalBillBasics.insuranceCovered || 0) | currency:'INR'"></th>
                                        </tr>
                                        <tr class="table-active">
                                            <th colspan="7" class="text-right">Total Amount Paid</th>
                                            <th class="text-primary" [textContent]="finalBillBasics.totalAmountPaid | currency:'INR'"></th>
                                        </tr>
                                        <tr *ngIf="finalBillBasics.finalBillId>0 && (finalBillBasics.pendingAmount) < 0 && !finalBillBasics.postDiscount" class="table-active">
                                            <th colspan="7" class="text-right">Total Amount Due</th>
                                            <th class="text-danger" [textContent]="(finalBillBasics.pendingAmount * -1) | currency:'INR'"></th>
                                        </tr>
                                        <tr *ngIf="finalBillBasics.finalBillId>0 && (finalBillBasics.pendingAmount) > 0 && !finalBillBasics.postDiscount" class="table-active">
                                            <th colspan="7" class="text-right">Total Refund Due</th>
                                            <th class="text-success" [textContent]="(finalBillBasics.pendingAmount) | currency:'INR'"></th>
                                        </tr>
                                        <tr *ngIf="finalBillBasics.postDiscount" class="table-active">
                                            <th colspan="7" class="text-right">Post Discount</th>
                                            <th class="text-success" [textContent]="(finalBillBasics.postDiscount || 0) | currency:'INR'"></th>
                                        </tr>
                                        <tr>
                                            <th colspan="8">
                                                <div class="row">
                                                    <div class="col-lg-7">
                                                        <!--<div class="text-left d-flex">
                                                      <div class="mr-3">
                                                        <h6>Total Amount Paid(Including Advance): </h6>
                                                        <h5 class="text-primary" [textContent]="finalBillBasics.totalAmountPaid | currency:'INR'"></h5>
                                                      </div>
                                                      <div >
                                                        <h6>Total Amount Due: </h6>
                                                        <h5 class="text-danger" [textContent]="((finalBillBasics.totalAmountPaid - (finalAmount || 0)) * -1) | currency:'INR'"></h5>
                                                      </div>
                                                      <div >
                                                        <h6>Total Refund Due: </h6>
                                                        <h5 class="text-success" [textContent]="(finalBillBasics.totalAmountPaid - (finalAmount || 0)) | currency:'INR'"></h5>
                                                      </div>
                                                    </div>-->
                                                    </div>
                                                    <div class="col-lg d-flex-end">
                                                        <ng-container *ngIf="mode !== editMode.New">
                                                            <div class="mr-2 badge badge-outline-blue">
                                                                <div *ngIf="(finalBillBasics.totalAmountPaid - (finalAmount || 0)) < 0">
                                                                    <div class="small">*Go to receipts to pay the due amount</div>
                                                                </div>
                                                                <div *ngIf="(finalBillBasics.totalAmountPaid - (finalAmount || 0)) > 0">
                                                                    <div class="small">*Go to receipts to issue refund</div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container *menuButton="'final_bill_send_payment_link'">
                                                            <button type="button" *ngIf="(finalBillBasics.totalAmountPaid - (finalAmount || 0)) < 0 && isPaylater" class="btn m-0 btn-sm btn-outline-primary" (click)="sendPaymentLink()">
                                                                Send Payment Link
                                                            </button>
                                                        </ng-container>
                                                        <div class="text-right">
                                                            <button *ngIf="mode === editMode.New && (deletedCharges.length > 0 || deletedPharmacy.length > 0|| deletedLabServices.length > 0)" type="button" (click)="resetHelper()" class="btn reset-btn btn-sm mr-2">
                                                                <i class="fe-refresh-cw"></i>
                                                                <span class="ml-1">Reset</span>
                                                            </button>
                                                            <button *menuButton="'generate_final_bill'" type="submit" [disabled]="submitting || disableActionBtns" [ngClass]="{'btn-success' : mode === editMode.New, 'btn-danger' : mode === editMode.Final}" class="btn btn-sm">
                                                                <span *ngIf="submitting">
                                                                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                                    Please wait..
                                                                </span>
                                                                <span *ngIf="!submitting">
                                                                    <i class="fe-check"></i>
                                                                    <span class="ml-1" [textContent]="mode === editMode.New ? 'Generate Final Bill' : 'Cancel Final Bill'"></span>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #reasonForCancel>
    <div class="modal-header">
        <h4 class="modal-title">Reason For cancel</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group mb-3">
                    <label class="mb-1">Reason<code>*</code> </label>
                    <div class="form-group">
                        <input tabindex="1" type="text" maxlength="150" [(ngModel)]="comments" (ngModelChange)="inputComments($event)" autocomplete="nope" class="form-control" placeholder="Type reason" required />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
        <button type="button" [disabled]="loading" class="btn btn-primary btn-sm" (click)="cancelHelper()">
            <span *ngIf="loading">
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                Please wait..
            </span>
            <span *ngIf="!loading">Submit</span>
        </button>
    </div>
</ng-template>
<ng-template #reasonforDiscount>
    <div class="modal-header">
        <h4 class="modal-title">Reason For Discount</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group mb-3">
                    <label class="mb-1">Reason</label>
                    <div class="form-group">
                        <select tabindex="0"
                                class="form-control min-width120px custom-select text-uppercase"
                                [(ngModel)]="discountReason" [ngClass]="{ 'is-invalid': isNeedDiscountReason }">
                            <option select hidden [ngValue]="null">Select</option>
                            <option *ngFor="let item of reasons"
                                    [ngValue]="item.name"
                                    [textContent]="item.name"></option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
        <button type="button" [disabled]="loading" class="btn btn-primary btn-sm" (click)="enterReason()">
            <span *ngIf="loading">
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                Please wait..
            </span>
            <span *ngIf="!loading">Submit</span>
        </button>
    </div>
</ng-template>



