<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box text-right">
                    <div class="page-title-right">
                        <button type="button" class="btn btn-secondary btn-sm ml-1" (click)="onShowFilters()">
                            <i class="fe-filter mr-1"></i>More Filters
                        </button>
                    </div>
                    <h4 class="page-title">IP Information Form</h4>
                </div>
            </div>
        </div>
        <div>
            <div class="card-box mb-1 p-1 pb-2">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-6 col-md-4 col-lg-4 col-xl-2">
                            <label class="mb-1">Location</label>
                            <ng-select class="ng-select-sm" [items]="locations"
                                       bindLabel="name"
                                       bindValue="id"
                                       placeholder="Enter Location Name"
                                       [(ngModel)]="filters.options.locationId"
                                       (change)="onChangeLocation($event)">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    No locations found<span *ngIf="searchTerm"> for '{{searchTerm}}'</span>.
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.name"></h5>
                                </ng-template>
                            </ng-select>
                        </div>

                        <div class="col-6 col-md-4 col-lg-4 col-xl-2">
                            <label class="mb-1">Patient</label>
                            <ng-select class="ng-select-sm text-uppercase"
                                       [items]="patients"
                                       [loading]="loading"
                                       [clearable]="true"
                                       bindLabel="value"
                                       bindValue="id"
                                       [searchFn]="customPatientSearchPatient"
                                       (search)="fetchPatientNameMobileUmrHelper($event)"
                                       placeholder="Enter patient name/umr/mobile"
                                       (keydown.enter)="onApplyFilters()"
                                       [(ngModel)]="filters.options.patientId">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                        No patient found for '{{searchTerm}}'
                                    </div>
                                    <div class="ng-option disabled" *ngIf="(!searchTerm || searchTerm.length < 2)">
                                        Search patient
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                            <span class="text-muted d-block font-13" [textContent]="item.optionalText"></span>
                                            <span class="text-muted d-block font-13" [textContent]="item.optionalText1"></span>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                        <div class="col-6 col-md-4 col-lg-4 col-xl-2">
                            <label class="mb-1">Case Type</label>
                            <ng-select class="ng-select-sm" [items]="caseTypes"
                                       bindLabel="value"
                                       bindValue="value"
                                       placeholder="Enter Case Type"
                                       [(ngModel)]="filters.options.caseTypeName">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    No casetypes found<span *ngIf="searchTerm"> for '{{searchTerm}}'</span>.
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                </ng-template>
                            </ng-select>
                        </div>
                        <div class="col-6 col-md-4 col-lg-4 col-xl-2">
                            <label class="mb-1">Insurance Company</label>
                            <ng-select class="ng-select-sm" [items]="insuranceCompanies"
                                       bindLabel="value"
                                       bindValue="value"
                                       placeholder="Enter Insurance Company"
                                       [(ngModel)]="filters.options.insuranceCompanyName">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    No InsuranceCompanies found<span *ngIf="searchTerm"> for '{{searchTerm}}'</span>.
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                </ng-template>
                            </ng-select>
                        </div>
                        <div class="col-6 col-md-4 col-lg-4 col-xl-2">
                            <label class="mb-1">Admission PayType</label>
                            <select class="form-control form-control-md" [(ngModel)]="filters.options.admissionPayTypeName">
                                <option [ngValue]="null">All</option>
                                <option [ngValue]="'C'">Cash</option>
                                <option [ngValue]="'I'">Insurance</option>
                            </select>
                        </div>
                        <div class="d-flex justify-content-center xsMarTop">
                            <div class="form-group mb-0">
                                <button type="button"
                                        (click)="onApplyFilters()"
                                        class="btn btn-sm btn-primary rounded-pill">
                                    <i class="mdi mdi-magnify mr-1"></i>Search
                                </button>
                                <button type="button"
                                        (click)="onResetFilters()"
                                        class="btn btn-sm reset-btn rounded-pill ml-1">
                                    <i class="mdi mdi-backup-restore mr-1"></i>Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="loading">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading  Ip Information ...</span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!loading && (!records || !records.length)">
            <div class="col-12 mh-300">
                <div class="no-data">
                    <img src="assets/images/no-data.png" alt="No data" />
                    <p class="sub-title">There is no data to show you right now.</p>
                </div>
            </div>
        </div>
        
        <div class="row" *ngIf="records && records.length >0">
            <div class="col-12">
                <div class="card mb-0">
                    <div class="bg-white">
                        <div class="p-1 popover-body">
                            <ul class="d-flex flex-wrap m-0 p-0 columngap10px">
                                <li class="listStyle">
                                    <span class="d-flex gap5px">
                                        <span class="d-inline-block color-option m-0 legendStyle" [ngStyle]="{'background':'#e5fe96 '}"></span>
                                        <span [textContent]=" 'Running Auto Services'"> </span>
                                    </span>
                                </li>
                                <li class="listStyle">
                                    <span class="d-flex gap5px">
                                        <span class="d-inline-block color-option m-0 legendStyle" [ngStyle]="{'background':'#fac1c0'}"></span>
                                        <span [textContent]=" 'Billed Amount is greater than Advance'"> </span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div style="margin-top:10px" class="card-body p-0 margintop30pxN">
                        <div>
                            <div class="table-responsive overflow-auto scroll-wrapper">
                                <div class="scroll-container">
                                    <table class="table table-sm table-centered table-hover m-0 table-bordered table-striped ">
                                        <thead class="bg-white ">
                                            <tr>
                                                <th class="fixed-col">S.No</th>
                                                <th *ngIf="isShowBedNo" class="fixed-col">Bed No</th>
                                                <th *ngIf="isShowPatientName">Patient Name</th>
                                                <th *ngIf="isShowAdmissionNo">Admission No</th>
                                                <th *ngIf="isShowCaseType">Case Type</th>
                                                <th *ngIf="isShowPayType">Pay Type</th>
                                                <th *ngIf="isShowTotal">Total</th>
                                                <th *ngIf="isShowAdvance">Advance</th>
                                                <th *ngIf="isShowPending">Pending</th>
                                                <th *ngIf="isShowMobile">Mobile</th>
                                                <th *ngIf="isShowDoctor">Doctor</th>
                                                <th *ngIf="isShowNoofDays">No.of.days </th>
                                                <th *ngIf="isShowUmrno">UMRNo</th>
                                                <th *ngIf="isShowAdmissionDate">Admission Date</th>
                                                <th *ngIf="isShowInsuranceCompany">Insurance Company</th>
                                                <th *ngIf="isShowAttendant">Attendant</th>
                                                <th *ngIf="isShowDoctorUnit">Doctor Unit</th>
                                                <th *ngIf="isShowCounselling">Counselling</th>
                                                <th *ngIf="isShowBillingCategory">Billing Category</th>
                                                <th *ngIf="isShowRefunded">Refunded</th>
                                                <th *ngIf="isShowAutoServices">Auto Services</th>
                                                <th *ngIf="isShowRefundAmount">Refund Amount</th>
                                                <th *ngIf="isShowPackageserviceTotal">PackageService Total</th>
                                                <th *ngIf="isShowServiceOrderTotal">ServiceOrder Total</th>
                                                <th *ngIf="isShowLabServiceTotal">LabServices Total</th>
                                                <th *ngIf="isShowDoctorVisitTotal">DoctorVisit Total</th>
                                                <th *ngIf="isShowScanServiceTotal">ScanService Total</th>
                                                <th *ngIf="isShowSurgeryServiceTotal">SurgeryService Total</th>
                                                <th *ngIf="isShowRoomVisitTotal">RoomVisit Total</th>
                                                <th *ngIf="isShowSurgenChargeServiceTotal">SurgeonChargeServices Total</th>
                                                <th *ngIf="isShowPharmacyTotal">Pharmacy Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of records; let i = index">
                                                <td class="fixed-col">
                                                    <h6 class="mb-0 mt-0">{{pagination.currentItems - records.length + 1+i}}</h6>
                                                    <ul class="d-flex flex-wrap m-0 p-0 columngap10px">
                                                        <li class="listStyle" *ngIf="item.isAutoServices">
                                                            <span class="d-flex gap5px">
                                                                <span class="d-inline-block color-option m-0 legendStyle" placement="right" ngbTooltip="Running Auto Services"  [ngStyle]="{'background':'#e5fe96 '}"></span>
                                                            </span>
                                                        </li>
                                                        <li class="listStyle" *ngIf="item.isBilledAmountIsGreaterThanAdvance">
                                                            <span class="d-flex gap5px">
                                                                <span class="d-inline-block color-option m-0 legendStyle" placement="right" ngbTooltip="Billed Amount is greater than Advance" [ngStyle]="{'background':'#fac1c0'}"></span>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td *ngIf="isShowBedNo" class="fixed-col">
                                                    <h5 class="mb-0 mt-0" [textContent]="item.bedNumber"></h5>
                                                </td>
                                                <td *ngIf="isShowPatientName" class="mb-0 mt-0  font-weight-normal text-uppercase" [textContent]="item.patientName"></td>
                                                <td *ngIf="isShowAdmissionNo">
                                                    <h5 class="mb-0 mt-0 " [textContent]="item.admissionNo"></h5>
                                                </td>
                                                <td *ngIf="isShowCaseType" class="mb-0 mt-0 " [textContent]="item.caseTypeName"></td>
                                                <td *ngIf="isShowPayType" class="mb-0 mt-0 ">
                                                    <span [textContent]="'Cash'" *ngIf="item.admissionPayTypeName =='C'"></span>
                                                    <span [textContent]="'Insurance'" *ngIf="item.admissionPayTypeName =='I'"></span>
                                                    <span [textContent]="item.admissionPayTypeName" *ngIf="item.admissionPayTypeName !='C' && item.admissionPayTypeName !='I'"></span>
                                                </td>
                                                <td class="mb-0 mt-0 " [textContent]="item.total" *ngIf="isShowTotal"></td>
                                                <td class="mb-0 mt-0 " [textContent]="item.paidAmount" *ngIf="isShowAdvance"></td>
                                                <td class="mb-0 mt-0 " [textContent]="item.pendingAmount" *ngIf="isShowPending"></td>
                                                <td *ngIf="isShowMobile" class="mb-0 mt-0 " [textContent]="item.mobile"></td>
                                                <td *ngIf="isShowDoctor" class="mb-0 mt-0  font-weight-normal text-uppercase" [textContent]="item.providerName"></td>
                                                <td *ngIf="isShowNoofDays">
                                                    <h5 class="mb-0 mt-0 " [textContent]="item.admissionTimeInveralHour"></h5>
                                                </td>
                                                <td *ngIf="isShowUmrno" class="mb-0 mt-0 " [textContent]="item.umrNo"></td>
                                                <td *ngIf="isShowAdmissionDate" class="mb-0 mt-0 " [textContent]="item.admissionDate | date:'dd-MM-yyyy, hh:mm a'"></td>
                                                <td *ngIf="isShowInsuranceCompany" class="mb-0 mt-0 " [textContent]="item.insuranceCompanyName || '---'"></td>
                                                <td *ngIf="isShowAttendant" class="mb-0 mt-0 " [textContent]="item.attendantName || '---'"></td>
                                                <td *ngIf="isShowDoctorUnit" class="mb-0 mt-0 p-0">
                                                    <span [textContent]="item.unitName"></span>
                                                    <ng-template #createdBy>
                                                        <div class="d-block">
                                                            <p class="mb-0 text-capitalize text-dark"
                                                               [textContent]="item.taggedDoctors"></p>
                                                        </div>
                                                    </ng-template>
                                                    <div *ngIf="item.taggedDoctors">
                                                        <i *ngIf="item.taggedDoctors"
                                                           class="mdi mdi-information mr-1"
                                                           placement="right"
                                                           [ngbPopover]="createdBy"
                                                           triggers="mouseenter:mouseleave"
                                                           popoverTitle="Doctor's' Name"></i>

                                                    </div>
                                                </td>
                                                <td *ngIf="isShowCounselling" class="mb-0 mt-0 ">
                                                    <span [textContent]="'Yes'" *ngIf="item.counselling"></span>
                                                    <span [textContent]="'No'" *ngIf="!item.counselling"></span>
                                                </td>
                                                <td *ngIf="isShowBillingCategory" class="mb-0 mt-0 " [textContent]="item.chargeCategoryName || '---'"></td>

                                                <td class="mb-0 mt-0 " [textContent]="item.refundAmount" *ngIf="isShowRefunded"></td>
                                                <td *ngIf="isShowAutoServices" class="mb-0 mt-0 ">
                                                    <span [textContent]="'Yes'" *ngIf="item.repeatTypeId == 3"></span>
                                                    <span [textContent]="'No'" *ngIf="item.repeatTypeId != 3"></span>
                                                </td>
                                                <td *ngIf="isShowRefundAmount" class="mb-0 mt-0 ">
                                                    <span [textContent]="'yes'" *ngIf="item.paidAmount  > item.total"></span>
                                                    <span [textContent]="'No'" *ngIf="item.paidAmount < item.total"></span>
                                                </td>
                                                <td *ngIf="isShowPackageserviceTotal" class="mb-0 mt-0 " [textContent]="item.packageServiceTotal || 0"></td>
                                                <td *ngIf="isShowServiceOrderTotal" class="mb-0 mt-0 " [textContent]="item.serviceOrderTotal || 0"></td>
                                                <td *ngIf="isShowLabServiceTotal" class="mb-0 mt-0 " [textContent]="item.labServicesTotal || 0"></td>
                                                <td *ngIf="isShowDoctorVisitTotal" class="mb-0 mt-0 " [textContent]="item.doctorVisitTotal || 0"></td>
                                                <td *ngIf="isShowScanServiceTotal" class="mb-0 mt-0 " [textContent]="item.scanServiceTotal || 0"></td>
                                                <td *ngIf="isShowSurgeryServiceTotal" class="mb-0 mt-0 " [textContent]="item.surgeryServiceTotal || 0"></td>
                                                <td *ngIf="isShowRoomVisitTotal" class="mb-0 mt-0 " [textContent]="item.roomVisitTotal || 0"></td>
                                                <td *ngIf="isShowSurgenChargeServiceTotal" class="mb-0 mt-0 " [textContent]="item.surgeonChargeServicesTotal || 0"></td>
                                                <td *ngIf="isShowPharmacyTotal" class="mb-0 mt-0 " [textContent]="item.pharmacyTotal || 0"></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr class="mb-0 mt-0">
                                                <td [attr.colspan]="filters.options.isShowFinancialDetails ? '14' : '12'" class="fixed-col">
                                                    <nav class="d-flex align-items-center justify-content-between flex-wrap">
                                                        <p class="mb-0 font-13">
                                                            <span class="text-dark">Page <span [textContent]="pagination.currentPage"></span> of <span [textContent]="pagination.totalPages"></span></span>
                                                            <span class="ml-1">
                                                                <span>(Showing <span [textContent]="pagination.currentItems - records.length + 1"></span> - <span [textContent]="pagination.currentItems"></span> of <span [textContent]="pagination.totalItems"></span> records)</span>
                                                            </span>
                                                        </p>
                                                        <ngb-pagination class="pagination justify-content-end" [maxSize]="5" [rotate]="false" [ellipses]="false" [(page)]="pagination.pageIndex" [pageSize]="pagination.pageSize" (pageChange)="onNextPage()" [collectionSize]="pagination.totalItems">
                                                            <ng-template ngbPaginationPrevious><i class="fe-arrow-left"></i></ng-template>
                                                            <ng-template ngbPaginationNext><i class="fe-arrow-right"></i></ng-template>
                                                        </ngb-pagination>
                                                    </nav>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="right-bar">
    <div data-simplebar class="h-100">
        <div class="filters-header">
            <h4>Filter Options</h4>
            <a href="javascript:;" (click)="onCloseFilters()"><i class="fe-x"></i></a>
        </div>
        <div class="filters-body">
            <div class="form-group">
                <label class="mb-1">Counselling</label>
                <select class="form-control form-control-md" [(ngModel)]="filters.options.counsellingId">
                    <option [ngValue]="null">All</option>
                    <option [ngValue]="1">Yes</option>
                    <option [ngValue]="0">No</option>
                </select>
            </div>
            <div class="form-group">
                <label class="mt-2">Billing Category</label>
                <ng-select class="ng-select-sm" [items]="chargeCategory"
                           bindLabel="name"
                           bindValue="name"
                           placeholder="Enter Billing Category"
                           [(ngModel)]="filters.options.chargeCategoryName">
                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                        No Billing Category found<span *ngIf="searchTerm"> for '{{searchTerm}}'</span>.
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-i="index">
                        <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.name"></h5>
                    </ng-template>
                </ng-select>
            </div>
            <div class="form-group">
                <label class="mt-2">Admission no</label>
                <ng-select class="ng-select-sm" [items]="admissionNos"
                           bindLabel="value"
                           bindValue="value"
                           placeholder="Enter Admission No"
                           [(ngModel)]="filters.options.admissionNo">
                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                        No Admission no found<span *ngIf="searchTerm"> for '{{searchTerm}}'</span>.
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-i="index">
                        <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                    </ng-template>
                </ng-select>
            </div>
            <div class="form-group">
                <label class="mt-2">Admission Date</label>
                <input type="date" class="form-control" [(ngModel)]="filters.options.admissionDate" />
            </div>
            <div class="form-group">
                <label class="mt-2">Doctor Unit</label>
                <ng-select class="ng-select-sm text-uppercase w-100" [items]="unitsdata"
                           [clearable]="true"
                           bindLabel="unitName"
                           bindValue="unitName"
                           [(ngModel)]="filters.options.unitName">
                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                        <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 1">
                            No  Doctor unit found for '{{searchTerm}}'
                        </div>
                        <div class="ng-option disabled" *ngIf="(!searchTerm || searchTerm.length < 1)">
                            Search Referral Doctor
                        </div>
                    </ng-template>
                </ng-select>
            </div>
            <div class="form-group">
                <label class="mt-2">Doctor </label>
                <ng-select class="ng-select-sm text-uppercase w-100" [items]="providers"
                           [clearable]="true"
                           bindLabel="value"
                           bindValue="id"
                           [(ngModel)]="filters.options.providerId">
                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                        <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 1">
                            No  Doctor found for '{{searchTerm}}'
                        </div>
                        <div class="ng-option disabled" *ngIf="(!searchTerm || searchTerm.length < 1)">
                            Search Referral Doctor
                        </div>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <div class="filters-footer">
            <div class="form-group mb-0">
                <button type="button"
                        (click)="onApplyFilters()"
                        class="btn btn-sm btn-primary rounded-pill">
                    <i class="mdi mdi-magnify mr-1"></i>Search
                </button>
                <button type="button"
                        (click)="onResetFilters()"
                        class="btn btn-sm reset-btn rounded-pill ml-1">
                    <i class="mdi mdi-backup-restore mr-1"></i>Reset
                </button>
            </div>
        </div>
    </div>
</div>

