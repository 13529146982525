import { Component, OnDestroy, OnInit, ViewEncapsulation, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ApiResources } from "@shared/helpers";
import { Appointment, PatientDocument, PreviousAppointment, PatientMedicationHeader, ImageReports } from "@shared/entities";
import { Page, IResource, IUserAccount, PatientProfile } from "@shared/models";
import { AppConfig, AppData, HttpService, AppointmentToggleService, TimelineToggleService, IconService, PrintOptionService, } from "@shared/services";
import { finalize, takeUntil } from "rxjs/operators";
import { InternalMedicine } from "@admin/internal-medicine/internal-medicine.namespace";
import { LabBillHeaderModel, LabParameterHeader } from "@admin/labs/pages/models";
import { OB } from "../../../areas/admin/full-transcript/pages/ob-report/ob.namespace";
import { GYN } from "../../../areas/admin/gyn-encounter/gyn.namespace";
import { GynEncounter } from "../../models/gyn-encounter.model";

@Component({
    templateUrl: "./gyn-discharge-summary-report.html",
    selector: "gyndischarge-summaryreport",
    encapsulation: ViewEncapsulation.None
})
export class GYNDischargeReportPage implements OnInit, OnDestroy {
    @Input() encryptedPatientId: string;
    encryptedAppointmentId: string;
    routingValue: string;
    page: Page;
    loading: boolean;
    document: PatientDocument;
    appointmentId: string;
    isAdmission: boolean;
    providerId: number;
    patientId: number;
    fullTranscript: any;
    loadingDocument: boolean;
    documentError: boolean;
    showPrevious: boolean;
    showNext: boolean;
    result: number;
    patient: PatientProfile;
    appointment: Appointment;
    submitting: boolean;
    locationId: number;
    locations: Array<IResource>;
    currentLocation: string;
    obHistory: Array<OB.ObBirthHistory>;
    problemList: Array<InternalMedicine.Problem>;
    isPastMedicalHistory = false;
    procedureSurgery: Array<OB.ProcedureSurgery>;
    isProcedureSurgery = false;
    isFamilyHistory = false;
    familyHistory: Array<OB.ObFamilyHistory>;
    dischargeSummary: Array<GYN.GyneacDiscargeSummary>;
    admissionDetails: OB.AdmssionSlip;
    useraccount: Array<IUserAccount>;
    records: Array<PatientMedicationHeader>;
    iconBasics: ImageReports;
    flag = true;
    @Input() isPrintLogo: boolean;
    labs: Array<PatientMedicationHeader>;
    bookingHeader: Array<LabBillHeaderModel>;
    husbandName: string;
    ordersPrescription: GYN.GynaecOrders;
    measure: GYN.Measures;
    ancCard: OB.AncCards;
    allergies: OB.Allergies;
    medicationComment: InternalMedicine.MedicationComment;
    birthHistory: OB.ObBirthHistory
    date: Date;
    dignosis: any;
    signature: string;
    gynHistory: GYN.NewGynHistory;
    opProcedureForm: GYN.ProcedureForm;
    printName: string;
    recordsResults: Array<LabParameterHeader>;
    diseaseName: any
    yearOfDiagnosis: any
    icd: any
    status: any
    treatmentHistory: any;
    isFooter: boolean;
    regionalLanguage: string;
    ivfFullTrascript: any
    title: any;
    @Input() printId: string;
    @Input() printadmission: boolean;

    constructor(
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly httpService: HttpService,
        private readonly toggleService: AppointmentToggleService,
        public timelineService: TimelineToggleService,
        private readonly printOptionService: PrintOptionService,
        private readonly iconService: IconService,


    ) {
        this.page = new Page();
        this.records = new Array<PatientMedicationHeader>();
        this.labs = new Array<PatientMedicationHeader>();
        this.bookingHeader = new Array<LabBillHeaderModel>();
        this.recordsResults = new Array<LabParameterHeader>();
        this.date = new Date();
    }

    private findDashboard() {
        const request = { encryptedAppointmentId: this.appointmentId, encryptedProviderId: this.page.userAccount.encryptedReferenceId, encryptedPatientId: this.encryptedPatientId };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<GynEncounter>(ApiResources.getURI(ApiResources.gynEncounter.base, ApiResources.gynEncounter.findFullTranscript), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: GynEncounter) => {
                this.fullTranscript = response;
                if (this.fullTranscript && this.fullTranscript.gynEncounterId) {
                    this.measure = this.fullTranscript.measure ? JSON.parse(this.fullTranscript.measure) : null
                    this.gynHistory = this.fullTranscript.gynHistory ? JSON.parse(this.fullTranscript.gynHistory) : null
                    this.allergies = this.fullTranscript.allergies ? JSON.parse(this.fullTranscript.allergies) : null
                    this.problemList = this.fullTranscript.problemList ? JSON.parse(this.fullTranscript.problemList) : null
                    this.obHistory = this.fullTranscript.birthHistory ? JSON.parse(this.fullTranscript.birthHistory) : null
                    this.obHistory = this.obHistory?.reverse();
                    this.ordersPrescription = this.fullTranscript.orderPrescription ? JSON.parse(this.fullTranscript.orderPrescription) : null
                    if (this.fullTranscript.problemList && this.problemList["problems"].length == 0) {
                        this.isPastMedicalHistory = true;
                    }
                    this.procedureSurgery = this.fullTranscript.surgeries ? JSON.parse(this.fullTranscript.surgeries) : null;
                    if (this.fullTranscript.surgeries && this.procedureSurgery.length == 0) {
                        this.isProcedureSurgery = true;
                    }
                    this.familyHistory = this.fullTranscript.familyHistory ? JSON.parse(this.fullTranscript.familyHistory) : null;
                    if (this.fullTranscript.familyHistory && this.familyHistory.length == 0) {
                        this.isFamilyHistory = true;
                    }
                    this.dischargeSummary = this.fullTranscript.gyneacDiscargeSummary ? JSON.parse(this.fullTranscript.gyneacDiscargeSummary) : null
                    if (this.dischargeSummary) {
                        if (this.dischargeSummary[0].summaryType == 'Gynaec') {
                            this.title = 'Gynaecology';
                        } else {
                            this.title = this.dischargeSummary[0].summaryType;
                        }
                    }
                    this.admissionDetails = this.fullTranscript.admssionSlip ? JSON.parse(this.fullTranscript.admssionSlip) : null
                    this.birthHistory = this.fullTranscript.birthHistory ? JSON.parse(this.fullTranscript.birthHistory) : null
                    this.opProcedureForm = this.fullTranscript.procedure ? JSON.parse(this.fullTranscript.procedure) : null
                }
                if (this.gynHistory && this.ancCard) {
                    this.dignosis = ("G" + this.gynHistory.gravida + "  " + "P" + this.gynHistory.para + "  " + "L" + this.gynHistory.living + "  " + "A" + this.gynHistory.abortion) + "  " +
                        ("Gestational Age" + " " + this.ancCard.ga);
                }
                if (this.problemList["problems"].length > 0) {
                    this.problemList = this.problemList["problems"]

                }
            });
    }

    private fetchIvfFullTrascript(appointmentId: string) {

        this.page.loading = true;
        const request = { encryptedAppointmentId: appointmentId, isAdmission: this.isAdmission };
        this.httpService.post<any>(ApiResources.getURI(ApiResources.ivfEncounter.base, ApiResources.ivfEncounter.fullTrascript), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: any) => {
                this.fullTranscript = response;
                if (this.fullTranscript) {
                    this.dischargeSummary = this.fullTranscript.dischargeSummary ? JSON.parse(this.fullTranscript.dischargeSummary) : null;
                    this.measure = this.fullTranscript.measure ? JSON.parse(this.fullTranscript.measure) : null
                    this.gynHistory = this.fullTranscript.gynHistory ? JSON.parse(this.fullTranscript.gynHistory) : null
                    this.allergies = this.fullTranscript.allergies ? JSON.parse(this.fullTranscript.allergies) : null
                    this.problemList = this.fullTranscript.problemList ? JSON.parse(this.fullTranscript.problemList) : null
                    this.obHistory = this.fullTranscript.birthHistory ? JSON.parse(this.fullTranscript.birthHistory) : null
                    this.obHistory = this.obHistory?.reverse();
                    this.ordersPrescription = this.fullTranscript.orderPrescription ? JSON.parse(this.fullTranscript.orderPrescription) : null
                    if (this.fullTranscript.problemList && this.problemList["problems"].length == 0) {
                        this.isPastMedicalHistory = true;
                    }
                    this.procedureSurgery = this.fullTranscript.surgeries ? JSON.parse(this.fullTranscript.surgeries) : null;
                    if (this.fullTranscript.surgeries && this.procedureSurgery.length == 0) {
                        this.isProcedureSurgery = true;
                    }
                    this.familyHistory = this.fullTranscript.familyHistory ? JSON.parse(this.fullTranscript.familyHistory) : null;
                    if (this.fullTranscript.familyHistory && this.familyHistory.length == 0) {
                        this.isFamilyHistory = true;
                    }
                    this.admissionDetails = this.fullTranscript.admssionSlip ? JSON.parse(this.fullTranscript.admssionSlip) : null
                    this.birthHistory = this.fullTranscript.birthHistory ? JSON.parse(this.fullTranscript.birthHistory) : null
                    this.opProcedureForm = this.fullTranscript.procedure ? JSON.parse(this.fullTranscript.procedure) : null

                }
            });



    }
    private findPatientDetails(id: string) {
        this.page.loading = true;
        this.httpService.post<PatientProfile>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.profile), { encryptedPatientId: id })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: PatientProfile) => {
                this.patient = response;
                var detaills = this.patient.relativeDetails[0];
                if (detaills && detaills.relation == "Husband") {
                    this.husbandName = this.patient.relativeDetails[0].fullName
                }
            });
    }
    private fetchMedication() {
        this.loading = true;
        const request = {
            encryptedAppointmentId: this.appointmentId,
            isAdmission: this.isAdmission
        }

        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                this.records = response;
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });
    }
    private findAppointment(appointmentId: string) {
        const request = {
            encryptedAppointmentId: appointmentId
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointment = response;
                this.encryptedPatientId = response.encryptedPatientId;
                this.fetchMedication();
                if (this.routingValue == 'gyn-ip-encounter') {
                    this.findDashboard();

                } else {
                    this.fetchIvfFullTrascript(this.encryptedAppointmentId)
                }
                this.fetchLabs();
                this.findPatientDetails(response.encryptedPatientId);
                this.fetchLabsResult(this.appointment.patientId);
            });

    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {

                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.regionalLanguage = this.page.userAccount.regionalLanguage;
                    const url = this.router.url;
                    this.currentLocation = this.page.userAccount.locationName;
                    this.printName = this.page.userAccount.fullName;
                    this.isAdmission = url.split("/")[4] === "a";
                    this.appointmentId = decodeURIComponent(url.split("/")[3]);
                    this.locationId = this.page.userAccount.locationId;
                    this.encryptedAppointmentId = this.appointmentId;
                    this.appointmentId = this.printId;
                    this.encryptedAppointmentId = this.printId;
                    this.isAdmission = this.printadmission;
                    this.routingValue = url.split("/")[2];
                    this.findAppointment(this.appointmentId);
                } else {
                    this.page.userAccount = undefined;
                }
                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                this.iconService.getIconImage((is) => { this.iconBasics = is; });

            });
    }
    private fetchLabs() {
        this.loading = true;
        const request = {
            appointmentId: this.appointment.appointmentId,
            isAdmission: this.isAdmission
        }
        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetchLabs), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                if (response && response.length > 0) {
                    this.bookingHeader = response[0].labs;
                }
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });
    }
    onChangePrintType(showLogo: boolean) {
        this.isPrintLogo = showLogo;
    }
    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }

    appointmentToggle() {
        this.toggleService.toggle(true);
    }

    timelineToggle() {
        this.timelineService.toggle();
    }
    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }
    private fetchLabsResult(id: number) {
        const request = { patientId: id };
        this.httpService.get(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchAllLabResults), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Array<LabParameterHeader>) => {
                if (response && response.length > 0) {
                    this.recordsResults = response
                }
            });
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
}



