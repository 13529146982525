import { from } from "rxjs";

export * from "./otp/otp.widget";
export * from "./pharmacy-product/pharmacy-product.widget";
export * from "./barcode-scanner/barcode-scanner.widget";
export * from "./common-data/common-data.widget";
export * from "./documents/documents.widget";
export * from "./aside-appointments/aside-appointments.widget";
export * from "./file-dropper/file-dropper.widget";
export * from "./banner-setting/banner-setting.widget";
export * from "./new-lab-invoice/new-lab-invoice.widget";
export * from "./new-lab-parameter/new-lab-parameter.widget";
export * from "./new-lab-report/new-lab-report.widget";
export * from "./new-lab-timeline/new-lab-timeline.widget";
export * from "./timeline/timeline.widget";
export * from "./pharmacy-indent/pharmacy-indent.widget"
export * from "./aside-appointments/aside-appointments.widget"
export * from "./appointment-invoice/appointment-invoice.widget";
export * from "./pharmacy-header/pharmacy-header.widget";
export * from "./chat-box/chat-box.widget";
export * from "./color-identification/color-identification.widget";
export * from "./img-crop/img-crop.widget";
export * from "./img-capture/img-capture.widget";
export * from "./color-identification/color-identification.widget";
export * from "./img-crop/img-crop.widget";
export * from "./Ob-header/ob-header";
export * from "./signature/signature.widget";
export * from "./diet-header/diet-header.widget";
export * from "./gyn-header/gyn-header.widget";
export * from "./pediatric-header/pediatrics-header.page";
export * from "./receipt-view/receipt-view.widget";
export * from "./parameter-input/parameter-input.widget";
export * from "./genetic-speciality-encounter-header/genetic-header";
export * from "./physiotherapy-encounter-header/physiotherapy-header";
export * from "./emergency-header/emergency-header.widget";
export * from "./lab-status-identification/lab-status-identification.widget";
export * from "./img-draw/img-draw.page";
export * from "./nurse-header/nurse-header.page";
export * from "./test-chat-box/test-chat-box.widget";
export * from "./telemedicine/telemedicine.widget";
export * from "./messaging/messaging.widget";
export * from "./neonatal-header/neonatal-header.widget";
export * from "./investigations/investigation-exclusions.page";
export * from "./reports-footer-location/reports-footer-location.widget";
export * from "./registration-invoice/registration-invoice.widget";
export * from "./transferedLabs/transfered-labs.widget";
export * from "./insurance-documents/insurance-documents.widget";
export * from "./new-lab-modify/new-lab-modify.widget";
export * from "./gate-pass-report/gate-pass-report.widget";
export * from "./new-lab-cancelled-invoice/new-lab-cancelled-invoice.widget";
export * from "./graph-view/graph-view.widget";
export * from "./interim-report/interim-report.page";
export * from "./footer-setting/footer-setting.widget";
export * from "./nurse-select-bed/nurse-select-bed.widget";
export * from "./new-banner/new-banner-setting.widget";
export * from "./ivf-header/ivf-header.widget";
export * from "./ophthalmology-encounter-header/ophthalmology-encounter-header";
export * from "./pharmacy-invoice/pharmacy-invoice.widget";
export * from "./scan-invoice/scan-invoice.widget";
export * from "./service-invoice/service-invoice.widget";
export * from "./new-lab-schema-report/new-lab-schema-report.widget";
export * from "./new-lab-schema-parameter/new-lab-schema-parameter.widget";
export * from "./new-lab-schema-interim-report/new-lab-schema-interim-report.widget"
export * from "./internal-medition/internal-medition-header.page"
export * from "./lab-header/lab-header.widgets"
export * from "./admission-timeline/admission-timeline.widget";
export * from "./lab-invoice/lab-invoice.widget";
export * from "./new-scan-invoice/new-scan-invoice.widget";

export * from "./inventory-product/inventory-product.widget";
export * from "./service-timeline/service-timeline.widget";
export * from "./tenders-timeline/tenders-timeline.widget";
export * from "./ophthalmology-encounter-header/ophthalmology-encounter-header";
export * from "./dynamic-header/dynamic-header"
export * from "./tagproperties-view/tagproperties-view.widget"
export * from "./ob--prescriptionreport/ob-prescription-report.page"
export * from "./gyn-print-prescription/gyn-print-prescription.page"
export * from "./diet-printprescription/diet-printprescription.page"
export * from "./genetic-report/genetic-report.page"
export * from "./physiotherapy-report/physiotherapy-report.page"
export * from "./pediatric-report/pediatric-report.page"
export * from "./ob-full-report/ob-full-report"
export * from "./gyn-full-report/gyn-full-report.page"
export * from "./genetic-full-report/genetic-full-report.page"
export * from "./pediatric-full-report/pediatric-full-report.page"
export * from "./physiotheray-full-report/physiotheray-full-report.page"
export * from "./diet-full-report/diet-full-report.page"
export * from "./antinatal-summarry-report/antinatal-summarry-report.page"
export * from "./obadmissiondetial-report/obadmissiondetail-report.page"
export * from "./discharge-summary-report/discharge-summary-report.page"
export * from "./gyn-discharge-summary/gyn-discharge-summary-report.page"
export * from "./neonatal-discharge-report/neonatal-discharge-report.page"
export * from "./dynamic-outside-encounter/dynamic-outside-encounter"
export * from "./new-scan-invoice/new-scan-invoice.widget";
export * from "./visiting-prescription/visiting-prescription.widget";
export * from "./practice-content/practice-content.widget";
export * from "./sale-bill-report/sale-bill-report.widget";
export * from "./scan-report-new/scan-report-new.widget";
export * from "./encounter-all-report/encounter-all-report.page";
export * from "./service-invoive-widget/service-invoive-widget.page";
export * from "./direct-po-report/direct-po-report.widget";
export * from "./goods-invoice/goods-invoice.widget";
export * from "./po-order-invoice/po-order-invoice.page";
export * from "./postnatalvisit-report/postnatalvisit-report.page";
export * from "./migrated-lab-report/migrated-lab-report.page";