/* eslint-disable no-constant-condition */
import { Component, OnInit, OnDestroy, ViewEncapsulation, TemplateRef, ElementRef, ViewChild, HostListener } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Location, DatePipe } from '@angular/common';
import { FormBuilder, Validators, FormGroup, FormControl } from "@angular/forms";
import { NgbModalRef, NgbModal, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { finalize, takeUntil } from "rxjs/operators";

import { GenericResponse, GenericStatus, Page, IUserAccount, IResource, Vitals } from "../../../../../shared/models";
import { HttpService, NotifyService, AppData, ResourceService } from "../../../../../shared/services";
import { ApiResources, LinqHelper, UtilHelper } from "../../../../../shared/helpers";
import { ActiveStatus, Dates, GlobalAllViewModel, PIXEL_PER_LETTER, BUFFER_WIDTH, AdmissionFetchHelper, USER_BUFFER_WIDTH, SNO_WIDTH, UNIT_WIDTH, TIME_USER_BUFFER_WIDTH } from "../../shared/helper";
import { WhenType, FilterType, ProgressReportMedication, DatesViewModel, ViewType, DateHolder, TimeSlotHelper, MarkDetails, IMoveModel } from "./local.helper";
import { IAdmissionModel } from "../../../services/models/admission.model";
import { Lab, LabStatusType } from "../labs/helpers/helper";
import { HourHolder } from "../../../nurse-module/helpers/helper";
import { IVitals } from "../vitals/helpers/helper";
import { json } from "stream/consumers";
import { VitalType } from "../../../../../shared/entities/vital-type.entity";
import { PharmacyInfusionTimeLine } from "../../../../../shared/entities/pharmacyInfusion.entity";

@Component({
    selector: "progress-report-timeline",
    templateUrl: "./timeline.html",
    styleUrls: ["./timeline.css"],
    encapsulation: ViewEncapsulation.None
})
export class ProgressReportTimelinePage implements OnInit, OnDestroy {

    id: number;
    encryptId: string;
    isAdmission: boolean;
    page: Page;
    modalRef: NgbModalRef;
    loading: boolean;
    dates: Dates;
    allDates: Array<Date>;
    whenType = WhenType;
    viewType = ViewType;
    filterType = FilterType;
    activeStatus = ActiveStatus;
    labStatusType = LabStatusType;

    currentViewType: ViewType;
    admission: IAdmissionModel;
    filterForm: FormGroup;
    moveForm: FormGroup;
    productWidth: number;
    userWidth: number;
    unitWidth: number;
    snoWidth: number;
    imgOrigin: string;
    previousLoading: boolean;
    nextLoading: boolean;
    contentLoading: boolean;

    cloneRecords: Array<GlobalAllViewModel<ProgressReportMedication, Lab, IVitals, PharmacyInfusionTimeLine>>;
    records: Array<GlobalAllViewModel<ProgressReportMedication, Lab, IVitals, PharmacyInfusionTimeLine>>;

    // Nurse Actions
    showNurses: boolean;
    isAdmissionMode: boolean;
    isNurseMode: boolean;
    dateHolder: DateHolder;
    nurses: Array<IResource>;
    patients: Array<IResource>;

    @ViewChild('scrollDiv', { static: true }) scrollDiv: ElementRef;
    hours: Array<HourHolder>
    markDetails: MarkDetails;
    interval: any;
    setHeight: boolean;
    movingIndex: number;
    movingObjectIndex: number;
    movingObject: TimeSlotHelper;
    moveSubmitted: boolean;
    moveSubmitting: boolean;
    moves: Array<IMoveModel>;

    vitalRecords: Array<IVitals>;
    isVitalTypeloading: boolean;
    vitalTypes: VitalType[];
    vitalArray: Array<any>;
    submitting: boolean;
    vitalObj: IVitals;
    myarray = []
    takenValue: any
    encryptedAdmissionId: string;
    isPatient: boolean;
    submittingInfusion: boolean;

    constructor(
        private readonly httpService: HttpService,
        private readonly modalService: NgbModal,
        private readonly notifyService: NotifyService,
        private readonly appData: AppData,
        private readonly formBuilder: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly locationService: Location,
        private readonly calendar: NgbCalendar,
        private readonly admissionFetchHelper: AdmissionFetchHelper,
        private readonly resourceService: ResourceService,
        private readonly datePipe: DatePipe,
        private readonly router: Router
    ) {
        this.page = new Page();
        this.currentViewType = ViewType.Patients;
        this.snoWidth = SNO_WIDTH;
        this.unitWidth = UNIT_WIDTH;

        this.moves = new Array<IMoveModel>();
        this.movingIndex = -1;
        this.movingObjectIndex = -1;

        this.markDetails = new MarkDetails();
        this.setMarkDetails();
        this.interval = setInterval(() => { this.setMarkDetails(); }, 1000 * 60)
        this.nurses = new Array<IResource>();
        this.patients = new Array<IResource>();
        this.isNurseMode = "Nurse" === this.route.snapshot.data['role'];
        this.isAdmissionMode = "Admission" === this.route.snapshot.data['role'];
        this.setDateHolder();

        this.cloneRecords = new Array<GlobalAllViewModel<ProgressReportMedication, Lab, IVitals, PharmacyInfusionTimeLine>>();
        this.records = new Array<GlobalAllViewModel<ProgressReportMedication, Lab, IVitals, PharmacyInfusionTimeLine>>();
        this.imgOrigin = location.origin + location.pathname;
        this.dates = new Dates();
        this.dates.current = new Date();
        this.dates.min = new Date(new Date().setDate(new Date().getDate() - 1));
        this.dates.max = new Date(new Date().setDate(new Date().getDate() + 1));
        this.allDates = new Array<Date>();

        this.hours = Array.from(Array(24)).map((_x, i) => {
            var hour = 1 + i;
            var icon = i >= 4 && i <= 10
                ? 'weather-sunset-up'
                : i > 10 && i <= 15
                    ? 'white-balance-sunny'
                    : i > 15 && i <= 19
                        ? 'weather-sunset-down'
                        : 'weather-night';
            var name = i >= 4 && i <= 10
                ? 'Morning'
                : i > 10 && i <= 15
                    ? 'Afternoon'
                    : i > 15 && i <= 19
                        ? 'Evening'
                        : 'Night';
            var isAm = hour <= 12;
            var meridiemHour = isAm ? hour : hour - 12;
            return {
                hour: hour,
                meridiemHour: meridiemHour,
                meridiem: isAm ? "AM" : "PM",
                icon: icon,
                name: name
            } as HourHolder;
        });

        this.buildFilterForm();
        this.buildMoveForm();
    }

    setDateHolder = () => {
        this.dateHolder = new DateHolder();
        var tempDate = this.calendar.getToday();
        this.dateHolder.date = tempDate;
        this.dateHolder.fDate = new Date(tempDate.year, tempDate.month - 1, tempDate.day);
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    const id = url.split("/")[4];
                    this.isPatient = url.split("/")[5] == 'P';
                    if (this.isPatient) {
                        this.encryptedAdmissionId = id;
                        this.changeViewType(ViewType.Time);
                    }
                    this.fetchVitalType();
                    if (this.isNurseMode) {
                        this.route.params
                            .subscribe((params: Params) => {
                                if (params["id"] != ":id") {
                                    this.encryptId = params["id"];
                                }

                                if (1 !== userAccount.roleId || this.encryptId) {
                                    this.id = userAccount.accountId;
                                    this.fetch();
                                } else {
                                    this.showNurses = true;
                                    this.fetchNurses()
                                }
                            });

                    } else {
                        this.route.parent.paramMap
                            .subscribe((params: Params) => {
                                this.id = +(params["params"]["id"] || 0);
                                this.isAdmission = "a" === params["params"]["type"];
                                this.loading = true;


                                this.fetchDates(() => {
                                    this.fetch();
                                })

                                if (this.id) {
                                    this.admissionFetchHelper.admissionFetch(this.id, this.isAdmission, (data: IAdmissionModel) => {
                                        this.admission = data;
                                    });
                                }
                            });
                    }

                } else {
                    this.page.userAccount = undefined;
                }
            });


        // initializing vitals
        this.vitalArray = []


    }

    ngOnDestroy() {
        clearInterval(this.interval);

    }

    setMarkDetails = () => {
        var now = new Date();
        this.markDetails.hour = now.getHours();
        this.markDetails.left = Math.ceil((100 / 60) * now.getMinutes());
        this.markDetails.time = now;
    }

    private buildFilterForm() {
        var filterTypeControl = new FormControl(FilterType.CurrentAndNext, [Validators.required])
        var nurseId = new FormControl(null)
        var patientId = new FormControl(null)
        this.filterForm = this.formBuilder.group({
            filterType: filterTypeControl,
            nurseId: nurseId,
            patientId: patientId
        });

        nurseId.valueChanges.subscribe(id => {
            this.id = id;
            this.patients = new Array<IResource>();
            this.fetch();
        })

        patientId.valueChanges.subscribe(fullName => {
            this.patientFilterHelper(fullName);
        })
    }

    get moveFormControls() {
        return this.moveForm.controls
    }

    private buildMoveForm() {
        this.moveForm = this.formBuilder.group({
            time: [null, [Validators.required]],
            reason: [null, [Validators.required]]
        });
    }

    patientFilterHelper = (fullName: string) => {
        this.records = fullName ? LinqHelper.cloneDeepArray(this.cloneRecords.map(x => {
            var obj = { ...x };
            obj.medications = LinqHelper.cloneDeepArray(x.medications.filter(y => y.fullName === fullName));
            obj.vitals = LinqHelper.cloneDeepArray(x.vitals.filter(y => y.fullName === fullName));
            obj.labs = LinqHelper.cloneDeepArray(x.labs.filter(y => y.fullName === fullName));
            obj.notes = LinqHelper.cloneDeepArray(x.notes.filter(y => y.fullName === fullName));
            obj.infusions = LinqHelper.cloneDeepArray(x.infusions.filter(y => y.patientFullName === fullName))
            return obj;
        })) : LinqHelper.cloneDeepArray(this.cloneRecords);

        const currentDate = new Date();
        const currentHour = new Date().getHours();
        const startHour = 8;
        const endHour = 23;
        this.records.forEach((item) => {
            let currentHour = startHour;
            item.infusions.forEach((x) => {
                if (x.durationUnit === "Hours") {
                    var duration = Number(x.duration) || 0;


                    const infusionHours = [];
                    for (let i = 0; i < endHour; i++) {
                        if (currentHour > endHour) {
                            currentHour = startHour;
                            break;
                        }
                        const timeslot = {
                            hour: currentHour,
                            status: null
                        };
                        infusionHours.push(timeslot);
                        currentHour += duration;
                    }
                    x.hour = infusionHours;
                }
            });
        });


        function parseDateString(dateString: string): Date {
            let date: Date;

            if (dateString.includes('/')) {
                const [datePart, timePart] = dateString.split(' ');
                const [month, day, year] = datePart.split('/').map(Number);
                const [hours, minutes, seconds] = timePart.split(':').map(Number);

                const isAM = timePart.includes('AM');
                const adjustedHours = isAM && hours === 12 ? 0 : (isAM ? hours : (hours + 12) % 24);
                date = new Date(year, month - 1, day, adjustedHours, minutes, seconds);
            } else if (dateString.includes('-')) {
                const [datePart, timePart] = dateString.split(' ');
                const [day, month, year] = datePart.split('-').map(Number);
                const [hours, minutes, seconds] = timePart.split(':').map(Number);
                date = new Date(year, month - 1, day, hours, minutes, seconds);
            } else {
                throw new Error('Invalid date format');
            }

            return date;
        }

        var sortedInfusion = LinqHelper.uniqueBy(this.records[0].infusions, "pharmacyInfusionDetailId")
        sortedInfusion.forEach((item) => {
            item.hour.forEach((z) => {
                this.records[0].infusions.forEach((x) => {
                    if (item.pharmacyInfusionDetailId == x.pharmacyInfusionDetailId) {

                        if (z.hour == x.takenHour) {
                            z.status = "Taken";
                        }
                        else if (z.status == null) {
                            const recordDate = parseDateString(item.fetchDate);

                            if (recordDate.getDate() < currentDate.getDate() || recordDate.getMonth() < currentDate.getMonth()
                                || recordDate.getFullYear() < currentDate.getFullYear()) {
                                z.status = "Pending"
                            }
                            if (recordDate.getDate() == currentDate.getDate() &&
                                recordDate.getMonth() == currentDate.getMonth() &&
                                recordDate.getFullYear() == currentDate.getFullYear() && z.hour == currentHour) {
                                z.status = "TakeNow"
                            }
                            if (recordDate.getDate() >= currentDate.getDate() &&
                                recordDate.getMonth() >= currentDate.getMonth() &&
                                recordDate.getFullYear() >= currentDate.getFullYear() && z.hour > currentHour) {
                                z.status = "NotStarted"
                            }
                            if (recordDate.getDate() <= currentDate.getDate() &&
                                recordDate.getMonth() <= currentDate.getMonth() &&
                                recordDate.getFullYear() <= currentDate.getFullYear()) {
                                if (z.hour < currentHour) {
                                    z.status = "Pending"
                                }

                            }
                        }
                    }
                })
            })
        })
        this.records[0].infusions = sortedInfusion;



        if (this.viewType.Time == this.currentViewType) {
            this.myarray = [];
            var z = 0;
            var data = this.records[0].vitals
            let groupedData = {};

            data.forEach(item => {
                if (!groupedData[item.progressReportId]) {
                    groupedData[item.progressReportId] = [];
                }
                groupedData[item.progressReportId].push(item);
            });

            let result = Object.values(groupedData);

            result.forEach((item1: any) => {

                var sort1 = LinqHelper.uniqueBy(item1, "vitalTypeId")
                sort1.forEach((item: any) => {
                    for (var i = 0; i <= item.vitalsStatuses.length - 1; i++) {

                        for (var j = 0; j <= this.records[0].vitals.length - 1; j++) {

                            if (item.vitalsStatuses[i].time == this.records[0].vitals[j].takenHour && item.vitalTypeId == this.records[0].vitals[j].vitalTypeId) {
                                if (item.progressReportId == this.records[0].vitals[j].progressReportId) {
                                    item.vitalsStatuses[i].takenValue = this.records[0].vitals[j].takenValue;
                                }

                            }
                            const record = this.records[0].vitals[j];
                            if (record && record.date) {
                                const recordDate = new Date(record.modelDate);
                                if (item.vitalsStatuses[i].takenValue != 0) {
                                    item.vitalsStatuses[i].missed = "taken";
                                    if (item.vitalsStatuses[i].takenValue < item.min || item.vitalsStatuses[i].takenValue > item.max) {
                                        item.vitalsStatuses[i].missed = "Abnormal"
                                    }

                                }
                                if (
                                    recordDate.getDate() == currentDate.getDate() &&
                                    recordDate.getMonth() == currentDate.getMonth() &&
                                    recordDate.getFullYear() == currentDate.getFullYear() &&
                                    item.vitalsStatuses[i].time < currentHour &&
                                    item.vitalsStatuses[i].takenValue == 0
                                ) {
                                    item.vitalsStatuses[i].missed = "missed";
                                }
                                const recordDateOnly = new Date(recordDate.getFullYear(), recordDate.getMonth(), recordDate.getDate());

                                const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

                                if (recordDateOnly < currentDateOnly && item.vitalsStatuses[i].takenValue == 0) {
                                    item.vitalsStatuses[i].missed = "missed";
                                }


                                if (
                                    recordDate.getDate() == currentDate.getDate() &&
                                    recordDate.getMonth() == currentDate.getMonth() &&
                                    recordDate.getFullYear() == currentDate.getFullYear() &&
                                    item.vitalsStatuses[i].time == currentHour &&
                                    item.vitalsStatuses[i].takenValue == 0
                                ) {
                                    item.vitalsStatuses[i].missed = "now";
                                }
                            }

                        }

                    }

                    this.myarray[z] = item;
                    z++;
                })
            })
            this.records[0].vitals = this.myarray;

        }

    }

    // MODEL
    modelOpenHelper = (content: TemplateRef<any>) => {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: "lg",
            windowClass: "custom-modal effect-scale"
        });
    }

    onCloseModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;

            this.moveReset();

        } catch (e) {
            // ignored;
        }
    }

    moveReset = () => {

        if (this.movingIndex !== -1 && this.movingObjectIndex !== -1 && this.movingObject) {
            this.records[0].medications[this.movingIndex].timeSlots[this.movingObjectIndex] = LinqHelper.cloneDeep<TimeSlotHelper>(this.movingObject);
            this.movingIndex = -1;
            this.movingObjectIndex = -1;
            this.movingObject = null;
        }
    }


    takeQuickMedicine = (item: ProgressReportMedication, id: number, slot: TimeSlotHelper = null) => {

        // if (!this.admission.isDischarged) {
        if (true) {

            if (slot && (slot.isTaking || slot.status)) return;
            if (item.isMedicineTaking) return;

            if (slot) slot.isTaking = true;
            item.isMedicineTaking = true;
            var today = new Date();
            this.dates.current = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
            const obj = {
                id: item.progressReportMedicationId,
                by: this.page.userAccount.accountId,
                admissionId: this.isNurseMode ? item.admissionId : this.id,
                createdBy: this.page.userAccount.fullName,
                medicationDate: { year: this.dates.current.getFullYear(), month: this.dates.current.getMonth() + 1, day: this.dates.current.getDate() },
                instructionIds: [id]
            }

            this.httpService
                .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReport.base, ApiResources.progressReport.takeMedication), [obj])
                .subscribe(
                    (response: GenericResponse) => {
                        if (GenericStatus[GenericStatus.Success] === response.status) {
                            item.isMedicineTaking = false;
                            if (slot) {
                                slot.isTaking = false;
                                slot.status = true;
                                slot.when = null;
                            }

                            item.status = true;
                            item.when = null;
                        } else {
                            this.notifyService.warning(response.message);
                        }
                    },
                    () => {
                        this.notifyService.defaultError();
                    }
                );
        }
    }




    takeQuickInfusion = (item: PharmacyInfusionTimeLine, id: number, slot: TimeSlotHelper = null) => {
        const request = {
            pharmacyInfusionDetailId: item.pharmacyInfusionDetailId,
            status: "Taken",
            takenHour: id,
            createdBy: this.page.userAccount.accountId
        }

        this.httpService
        this.submittingInfusion = true;
        this.httpService
            .post(ApiResources.getURI(ApiResources.pharmacyInfusion.base, ApiResources.pharmacyInfusion.insertInfusionFromTimeLine), request)
            .pipe(finalize(() => this.submittingInfusion = false))
            .subscribe(
                (response: number) => {
                    if (response > 0) {
                        this.fetch();
                        this.notifyService.successToast("Infusion Taken Successfully..")
                    }
                },
                () => {
                    this.notifyService.defaultError();
                }
            );
    }

    private fetchNurses = () => {
        this.loading = true;
        this.resourceService.fetchNurses()
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<IResource>) => {
                this.nurses = response;
            });
    }

    fetchDates = (callback?: Function) => {
        this.loading = true;
        this.contentLoading = true;
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReport.base, ApiResources.progressReport.fetchTimelineDates),
                { admissionId: this.id, date: this.dates.current })
            .subscribe(
                (response: GenericResponse) => {
                    if (GenericStatus[GenericStatus.Success] === response.status) {
                        var data = response.data as DatesViewModel;
                        const dates = data.dates;
                        this.allDates = data.allDates;

                        this.dates.min = new Date(dates.min || new Date());
                        this.dates.max = new Date(dates.max || new Date());

                        var today = new Date();
                        this.dates.current = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
                        if (this.isMaxGtThanToday()) {
                            this.dates.current = new Date(this.dates.max);
                        }

                        this.dates.currentIndex = this.allDates.findIndex(x => this.dates.current.getTime() === new Date(x).getTime());
                        if (-1 === this.dates.currentIndex) {
                            this.dates.currentIndex = this.allDates.length - 1;
                        }

                        this.dates.current = this.allDates[this.dates.currentIndex];

                        callback();
                    }
                },
                () => {
                    this.notifyService.defaultError();
                    callback();
                }
            );

    }

    changeViewType = (type: ViewType) => {
        this.currentViewType = type;
        if (type !== ViewType.Patients) {
            this.contentLoading = true;
            setTimeout(() => {
                this.fetch();
            });
        }
    }

    matchedItem = (hour: number, i: number) => {

        var mtc = this.records[0].medications.length && this.records[0].medications[i].timeSlots && this.records[0].medications[i].timeSlots.find(x => x.hour >= hour && x.hour <= hour)
        return mtc;
    }


    matchedItem1 = (hour: number, i: number) => {
        return this.records[0].vitals.length && this.records[0].vitals[i].vitalsStatuses && this.records[0].vitals[i].vitalsStatuses.find(x => x.time == hour)
    }

    matchedItem2 = (hour: number, i: number) => {

        return this.records[0].infusions.length && this.records[0].infusions[i].hour.find(x => x.hour == hour)
    }

    matchedIndex = (hour: number, i: number) => {
        if (this.records[0].medications.length && this.records[0].medications[i].timeSlots) {
            var timeSlots = this.records[0].medications.length && this.records[0].medications[i].timeSlots;
            return !timeSlots ? -1 : this.records[0].medications[i].timeSlots.findIndex(x => x.hour >= hour && x.hour <= hour);
        }
    }

    onMouseDown = (i: number, j: number) => {
        var timeSlots = this.records[0].medications[i].timeSlots;
        var found = timeSlots && j !== -1 && timeSlots[j];
        if (found) {
            found.barWidth += 5;
            found.interval = setInterval(() => {
                found.showBar = true;
                setTimeout(() => {
                    found.barWidth += 7;
                    if (found.barWidth > 100) {
                        found.barWidth = 0;
                        found.showBar = false;
                        clearInterval(found.interval);
                        this.onMove(i, j);
                    }
                });
            }, 100);
        }
    }

    onMouseUp = (i: number, j: number) => {
        var timeSlots = this.records[0].medications[i].timeSlots;
        var found = timeSlots && j !== -1 && timeSlots[j];
        if (found) {
            found.barWidth = 0;
            found.showBar = false;
            clearInterval(found.interval);
        }
    }

    fetchMoves = (i: number, j: number, content: TemplateRef<any>) => {
        var timeSlots = this.records[0].medications[i].timeSlots;
        var found = timeSlots && j !== -1 && timeSlots[j];

        if (found && found.hasMoveLoading) return;

        if (found) {
            this.moves = new Array<IMoveModel>();
            found.hasMoveLoading = true;
            var data = {
                value: found.progressReportMedicationFrequencyId
            }

            this.httpService
                .post<GenericResponse>(ApiResources.getURI(ApiResources.nurseShift.base, ApiResources.nurseShift.fetchMoves), data)
                .pipe(finalize(() => this.moveSubmitting = false))
                .subscribe(
                    (response: GenericResponse) => {
                        if (GenericStatus[GenericStatus.Success] === response.status) {
                            var moves = response.data as Array<IMoveModel>;
                            moves.forEach(x => {
                                x.time = new Date(0, 0, 0, x.hour, x.minute, 0);
                            });
                            this.moves = moves;
                            found.hasMoveLoading = false;
                            setTimeout(() => {
                                this.modelOpenHelper(content);
                            });
                        } else {
                            this.notifyService.defaultError(response.message);
                        }
                    },
                    () => {
                        found.hasMoveLoading = false;
                        this.notifyService.defaultError();
                    }
                );
        }
    }

    onMove = (i: number, j: number) => {
        this.movingIndex = i;
        this.movingObjectIndex = j;
    }

    onMoveComplete = (item: HourHolder, content: TemplateRef<any>) => {
        if (this.movingIndex !== -1 && this.movingObjectIndex !== -1) {
            var found = this.records[0].medications[this.movingIndex].timeSlots[this.movingObjectIndex];
            this.movingObject = LinqHelper.cloneDeep<TimeSlotHelper>(found);
            found.hour = item.hour;
            found.meridiemHour = item.hour > 12 ? item.hour - 12 : item.hour;
            found.minute = "0".toString().padEnd(2, "0");
            found.isTaking = true;

            this.moveForm.patchValue({
                time: this.datePipe.transform(new Date(0, 0, 0, item.hour, 0), 'HH:mm'),
            })

            this.modelOpenHelper(content);
        }
    }

    onMoveSubmit = () => {
        this.moveSubmitted = true;
        if (this.moveForm.invalid || this.moveSubmitting) return;
        this.moveSubmitting = true;

        var found = this.records[0].medications[this.movingIndex].timeSlots[this.movingObjectIndex];
        var data = {
            progressReportMedicationFrequencyId: found.progressReportMedicationFrequencyId,
            time: this.moveForm.value.time,
            reason: this.moveForm.value.reason,
            createdBy: this.page.userAccount.accountId
        }

        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.nurseShift.base, ApiResources.nurseShift.move), data)
            .pipe(finalize(() => this.moveSubmitting = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (GenericStatus[GenericStatus.Success] === response.status) {
                        found.isTaking = false;
                        found.hasMove = true;
                        found.minute = this.moveForm.value.time.split(":")[1].padEnd(2, "0");
                        this.moveSubmitted = false;
                        this.movingIndex = -1;
                        this.movingObjectIndex = -1;
                        this.movingObject = null;
                        this.moveForm.reset();
                        this.onCloseModal();
                    } else {
                        this.notifyService.defaultError(response.message);
                        this.moveReset();
                    }
                },
                () => {
                    this.notifyService.defaultError();
                    this.moveReset();
                }
            );
    }

    getActionDetails = (hour: number, i: number) => {
        var item = this.records[0].medications[i];
        return item.breakfastModel.hour === hour
            ? { short: "B-FAST", long: "Breakfast" }
            : item.lunchModel.hour === hour
                ? { short: "LUNCH", long: "Lunch" }
                : item.dinnerModel.hour === hour
                    ? { short: "DINNER", long: "Dinner" } : null;
    }
    getActionDetails1 = (hour: number) => {

        if (hour == 8) {
            return { short: "B-FAST", long: "Breakfast" }
        }
        if (hour == 13) {
            return { short: "LUNCH", long: "Lunch" }
        }
        if (hour == 20) {
            return { short: "DINNER", long: "Dinner" }
        }

    }

    fetch = () => {
        this.contentLoading = true;
        this.setHeight = false;
        setTimeout(() => {
            var subUrl = ViewType.Grid === this.currentViewType || ViewType.Time === this.currentViewType
                ? ApiResources.progressReport.fetchTimeline
                : ApiResources.progressReport.fetchAllTimeline;

            var data = !this.isNurseMode
                ? { admissionId: this.id, date: this.allDates[this.dates.currentIndex] }
                : { value: this.id, id: this.isPatient ? this.encryptedAdmissionId : this.encryptId, dateModel: this.dateHolder.date, isNurse: this.page.userAccount.roleId == 63 ? true : false, isPatient: this.isPatient };

            this.httpService
                .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReport.base, subUrl), data)
                .pipe(finalize(() => { this.loading = false; this.contentLoading = false; }))
                .subscribe(
                    (response: GenericResponse) => {

                        if (GenericStatus[GenericStatus.Success] === response.status) {
                            const data = response.data as Array<GlobalAllViewModel<ProgressReportMedication, Lab, IVitals, PharmacyInfusionTimeLine>>;

                            if (!this.isNurseMode) {
                                this.checkMin();
                                this.checkMax();
                            }

                            if (this.patients.length <= 0) {
                                this.patients = (Array.prototype.concat.apply([], data.map(x => [
                                    ...x.medications.map(y => ({ value: y.fullName })),
                                    ...x.vitals.map(y => ({ value: y.fullName })),
                                    ...x.labs.map(y => ({ value: y.fullName })),
                                    ...x.notes.map(y => ({ value: y.fullName }))])) as Array<IResource>)
                                    .filter((x, i, s) => LinqHelper.uniqueOnlyByAlt(x, i, s, "value"))
                            }

                            var tempMedications = [], tempLabs = [], tempNotes = [], tempvitals = [];
                            data.forEach(x => {
                                tempMedications.push(...x.medications);
                                tempLabs.push(...x.labs);
                                tempNotes.push(...x.notes);
                                tempvitals.push(...x.vitals);


                            });
                            this.productWidth = (Math.max(...tempMedications.map(x => x.productName.length), ...tempLabs.map(x => x.testName.length)) * PIXEL_PER_LETTER) + BUFFER_WIDTH;
                            this.userWidth = (Math.max(...tempMedications.map(x => x.fullName.length), ...tempLabs.map(x => x.fullName.length), ...tempvitals.map(x => x.fullName.length), ...tempNotes.map(x => x.fullName.length)) * PIXEL_PER_LETTER) + (ViewType.Time === this.currentViewType ? TIME_USER_BUFFER_WIDTH : USER_BUFFER_WIDTH);

                            if (ViewType.Time === this.currentViewType && data && data.length > 0) {
                                var array = new Array<ProgressReportMedication>();
                                var admissionIds = data[0].medications.map(x => x.admissionId).filter(LinqHelper.uniqueOnly);
                                admissionIds.forEach(x => {
                                    var admissions = data[0].medications.filter(y => y.admissionId === x);
                                    var productIds = admissions.map(y => y.pharmacyProductId).filter(LinqHelper.uniqueOnly);
                                    productIds.forEach(y => {
                                        var products = admissions.filter(z => z.pharmacyProductId === y);
                                        var content = { ...products[0] };
                                        content.timeSlots = products.filter(z => !!z.timeSlot).map(z => ({
                                            hour: z.timeSlot.hour,
                                            isTaking: false,
                                            meridiemHour: z.timeSlot.hour > 12 ? z.timeSlot.hour - 12 : z.timeSlot.hour,
                                            minute: z.timeSlot.minute.toString().padEnd(2, "0"),
                                            name: z.medicationInstructionName,
                                            status: z.status,
                                            hasMove: z.hasMove,
                                            when: z.when,
                                            barWidth: 0,
                                            showBar: false,
                                            hasMoveLoading: false,
                                            progressReportMedicationFrequencyId: z.progressReportMedicationFrequencyId,
                                            activeStatus: z.activeStatus,
                                        } as TimeSlotHelper)) || [];
                                        array.push(content)
                                    })
                                })
                                data[0].medications = array;
                            }

                            this.cloneRecords = data;
                            this.patientFilterHelper(this.filterForm.get("patientId").value);
                        }

                        this.previousLoading = false;
                        this.nextLoading = false;

                        ViewType.Time === this.currentViewType && setTimeout(() => {
                            var index = this.hours.findIndex(x => x.hour === new Date().getHours());
                            index !== -1 && document.getElementById('header_' + (index <= 18 ? (index + 5) : index)).scrollIntoView();
                            this.setHeight = true;
                        }, 200);
                    },
                    () => {
                        this.notifyService.defaultError();
                    }
                );
        });
    }

    // Concept Methods
    onPrevious = () => {
        if (!this.isNurseMode) {
            if (!this.dates.showMin) return;
            --this.dates.currentIndex;
            this.dates.current = new Date(this.allDates[this.dates.currentIndex]);
        } else {
            var tempDate = this.calendar.getPrev(this.dateHolder.date);
            this.dateHolder.date = tempDate;
            this.dateHolder.fDate = new Date(tempDate.year, tempDate.month - 1, tempDate.day);
        }

        this.patients = new Array<IResource>();
        this.previousLoading = true;
        this.fetch();
    }

    checkMin = () => {
        this.dates.showMin = !!this.allDates[this.dates.currentIndex - 1];
    }

    onNext = () => {
        if (!this.isNurseMode) {
            if (!this.dates.showMax) return;
            ++this.dates.currentIndex;
            this.dates.current = new Date(this.allDates[this.dates.currentIndex]);
        } else {
            var tempDate = this.calendar.getNext(this.dateHolder.date);
            this.dateHolder.date = tempDate;
            this.dateHolder.fDate = new Date(tempDate.year, tempDate.month - 1, tempDate.day);
        }

        this.patients = new Array<IResource>();
        this.nextLoading = true;
        this.fetch();
    }

    checkMax = () => {
        this.dates.showMax = !!this.allDates[this.dates.currentIndex + 1]
    }

    isGreaterDate = (date: Date, today: Date) => {
        return date.getFullYear() > today.getFullYear() ||
            (date.getMonth() > today.getMonth() &&
                date.getFullYear() === today.getFullYear()) ||
            (date.getDate() > today.getDate() &&
                date.getMonth() === today.getMonth() &&
                date.getFullYear() === today.getFullYear());
    }

    isMaxGtThanToday = () => {
        var now = new Date();
        var max = this.dates.max;
        return now.getFullYear() > max.getFullYear() ||
            (now.getMonth() > max.getMonth() &&
                now.getFullYear() === max.getFullYear()) ||
            (now.getDate() > max.getDate() &&
                now.getMonth() === max.getMonth() &&
                now.getFullYear() === max.getFullYear());
    }

    onBack = () => {
        this.locationService.back();
    }

    @HostListener('document:mousedown', ['$event'])
    onGlobalClick(event): void {
        if (this.movingIndex !== -1 && this.movingObjectIndex !== -1 && !this.movingObject && !(event.target.classList as DOMTokenList).contains("available-slot")) {
            this.movingIndex = -1;
            this.movingObjectIndex = -1;
        }
    }
    fetchVitalType = () => {
        this.isVitalTypeloading = true;
        this.httpService
            .post<Array<VitalType>>(ApiResources.getURI(ApiResources.vitalType.base, ApiResources.vitalType.fetch), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.isVitalTypeloading = false))
            .subscribe(
                (response: Array<VitalType>) => {

                    this.vitalTypes = []
                    this.vitalTypes = response;

                    this.vitalTypes.forEach((item) => {
                        item.unitArray = new Array<any>();
                        item.unitArray = item.unitType.split(",");
                    });
                },
                () => {
                    this.vitalTypes = new Array<VitalType>();
                }
            );
    }
    async takeQuickVitals(item: IVitals, hour: any, content: TemplateRef<any>) {
        this.vitalObj = item;
        this.vitalObj.hour = hour
        await this.vitalArray.push(this.vitalTypes.find(x => x.name == item.name));

        this.openModelHelper(content);
    }
    openModelHelper = (content: TemplateRef<any>) => {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "lg",
            windowClass: "custom-modal effect-scale"
        });
    }
    onCloseModal1() {
        try {
            this.vitalArray = []
            this.takenValue = ""
            this.modalRef.close();
            this.modalRef = undefined;

        } catch (e) {
            // ignored;
        }
    }
    modify() {

        this.submitting = true;

        const request = this.vitalArray.filter((item) => { return UtilHelper.isEmpty(item.vitalTypeId) });
        this.vitalObj.name = request[0].name;
        this.vitalObj.createdBy = request[0].createdBy;
        this.vitalObj.createdDate = request[0].createdDate;
        this.vitalObj.vitalTypeId = request[0].vitalTypeId;
        this.vitalObj.admissionId = this.page.userAccount.accountId;

        const obj = {
            vitalTypeId: request[0].vitalTypeId,
            appointmentId: this.vitalObj.appointmentId,
            takenValue: Number(this.takenValue),
            status: "Taken",
            createdDate: request[0].createdDate,
            progressReportVitalsId: this.vitalObj.progressReportVitalsId,
            progressReportId: this.vitalObj.progressReportId,
            takenHour: this.vitalObj.hour,
            vitalFrequencyRecordId: this.vitalObj.vitalFrequencyRecordId,
            umrNo: this.vitalObj.umrNo,
            modelDate: this.vitalObj.modelDate,
            createdBy: this.page.userAccount.accountId

        }


        this.httpService
        this.submitting = true;
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReportVitals.base, ApiResources.progressReportVitals.insertVitalFreqRecord), obj)
            .pipe(finalize(() => this.submitting = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        this.vitalArray = [];
                        this.takenValue = "";
                        this.fetch();
                        this.onCloseModal1();
                        this.notifyService.success("Vitals Record Taken Successfully");

                    } else {
                        this.notifyService.warning(response.message);
                    }
                },
                () => {
                    this.notifyService.defaultError();
                }

            );
    }
}
