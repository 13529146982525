
<div *ngIf="isCancelInvoice" class="modal-body" id="receipt-section">
    <div class="card">
        <div class="card-body p-0">
            <div class="card-box m-0">
                <div *ngIf="isPrintLogo" class="col-12" width="100%">
                    <img [src]="bannerBasics != null ? bannerBasics.imagePath : myImgUrl" [hidden]="loading" alt="Careaxes" class="t_width" />
                </div>
                <div>
                    <div colspan="4" align="center" *ngIf="isPrintLogo">
                        <banner-setting [reportName]='"Cancel Appointments Report Header"'></banner-setting>
                    </div>
                </div>

                <div class="col-lg-12">
                    <h4 class="box-title mt-3 " style="text-align:center;">CANCEL RECEIPT</h4>
                </div>
                <ng-container *ngIf="noReceipt">
                    <div class="row">
                        <span>Oops!!! </span>&nbsp;
                        <span>Sorry We are Unable to Show you the Cancel Appointment Receipt, As Receipt not generated, because of the following reasons..</span>
                        <ul>
                            <li>There is <b>no Appointment Charges</b>, Charged from you. <b>Or</b> Appointment charges is Zero</li>
                            <li>You Did not Paid any appointment charge.(Amount is still <b>Pending</b>)</li>
                            <li>The Appointment Amount is <b>Not Refunded</b> to You Back after Cancellation.</li>
                            <li>Might, Cancelled the Appointment for <b>Follow ups</b>.</li>
                        </ul>
                    </div>
                </ng-container>
                <ng-container *ngIf="selectedAppointment">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="table-responsive table-responsive-lg">
                                <table class="border-dark table table-sm table-borderless table-centered">
                                    <tbody>
                                        <tr>
                                            <th>Receipt No</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="'RN-' + selectedAppointment.receiptId"></span>
                                            </td>
                                            <th>
                                                Appointment No
                                            </th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="selectedAppointment.appointmentNo"></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Receipt Date</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="selectedAppointment.receiptDate | date:'dd-MM-yyyy, hh:mm:ss a'"></span>
                                            </td>
                                            <th>
                                                Appointment Date
                                            </th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="selectedAppointment.appointmentDate | date: 'dd-MM-yyyy'"></span>
                                                <span [textContent]="' ' + selectedAppointment.appointmentTimeString"> </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Patient Name</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span>
                                                    <span [textContent]="selectedAppointment.patientName"></span>
                                                    <span [textContent]="', '+(selectedAppointment.patientGender | gender)"></span>
                                                    <span [textContent]="selectedAppointment.patientDateOfBirth ? ', '+(selectedAppointment.patientDateOfBirth | age:'days' ): selectedAppointment.patientAge ? (', '+selectedAppointment.patientAge +' yrs '): 'N/A'"></span>
                                                </span>
                                            </td>
                                            <th>Relative Name</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="selectedAppointment.fatherOrHusband"></span>
                                            </td>

                                        </tr>
                                        <tr>
                                            <th>Doctor Name</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span class="text-uppercase" [textContent]="selectedAppointment.providerName"></span>&nbsp;
                                                <span [textContent]="' ('+ selectedAppointment.specializationName +')'"></span>
                                            </td>

                                            <th>Department</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="selectedAppointment.departmentName"></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Address</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="selectedAppointment.fullAddress">--------------</span>
                                            </td>
                                            <th>UMR No</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="selectedAppointment.umrNo || '--'"></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">
                            <div class="table-responsive table-responsive-lg">
                                <table class="border-dark table table-bordered table-sm">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Description</th>
                                            <th>Appointment Amount</th>
                                            <th>Cancel Refunded Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td [textContent]="selectedAppointment.payTypeName"></td>
                                            <td [textContent]="selectedAppointment.aptTotal | currency:'INR'"></td>
                                            <td [textContent]="selectedAppointment.aptRefundAmt | currency:'INR'"></td>
                                        </tr>
                                    </tbody>
                                    <thead>
                                        <tr>
                                            <th colspan="3" class="text-right">TOTAL</th>
                                            <th [textContent]="selectedAppointment.aptRefundAmt | currency:'INR'"></th>
                                        </tr>
                                    </thead>
                                </table>
                                <div class="mb-1">
                                    <span class="font-weight-bold">Reason :</span>
                                    <span [textContent]="selectedAppointment.reason"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-lg-6">
                            <div class="mb-1">
                                <span class="font-weight-bold">Created By: </span>
                                <span [textContent]="selectedAppointment.receiptCreatedByName"></span>
                            </div>
                            <div>
                                <span class="font-weight-bold">Created Date: </span>
                                <span [textContent]="selectedAppointment.receiptDate | date:'dd-MM-yyyy, hh:mm:ss a'"></span>
                            </div>
                            <div>
                                <span class="font-weight-bold">Printed By: </span>
                                <span [textContent]="page.userAccount.fullName"></span>
                            </div>
                            <div>
                                <span class="font-weight-bold">Printed Date: </span>
                                <span [textContent]="currentDate| date:'dd-MM-yyyy, hh:mm:ss a'"></span>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf="isFooter">
                    <banner-setting [reportName]='"Cancel Appointments Report Footer"'> </banner-setting>
                </div>
            </div>
        </div>
    </div>
</div>





<!--<div *ngIf="!isCancelInvoice && isSubVisit" Id="invoiceId2">
    <div class="modal-body bg-white reportInvoice report">
        <div class="table-responsive-lg table-responsive-sm table-responsive-md text-black">
            <table class="border-dark table_border " width="894px" border="0" cellspacing="0" cellpadding="0">
                <tbody>
                    <tr *ngIf="isPrintLogo">
                        <td colspan="2" class="td_bt">
                            <banner-setting [reportName]='"Subvisit Invoice"'> </banner-setting>
                        </td>
                    </tr>

                    <tr>
                        <td colspan="2" class="text-center ">-->
<!--<h4 style="font-family: 'Open Sans'; font-size: 15px; color: #06C; margin: 0px; padding: 5px; text-align: center; border-top: 1px solid #000; font-weight: bolder">
    {{ selectedAppointment.paymentType === 'P' ? 'RECEIPT' : 'INVOICE' }}
</h4>-->
<!--<h3 class="text-uppercase pt-2"> Final Bill of Supply - Consultation Receipt </h3>
        <h5 class="text-uppercase pt-2">(Health Care Service - SAC)</h5>
        <h4 class="text-uppercase pt-2 pb-2">DEPARTMENT OF {{ selectedAppointment.specializationName}}</h4>
    </td>
</tr>

<tr>
    <td colspan="2">
        <table class="border-dark bt_bb t_width pb-2 pt-2" border="0" cellspacing="0" cellpadding="5">
            <tbody>
                <tr class="col-12 vertical-align-top">
                    <td class="col-4" rowspan="2">
                        <div class="d-flex">
                            <div class="white-space-nowrap">Patient Name :  &nbsp;</div>
                            <div class="d-inline-block">
                                <h5 class="ms-2 text_uppercase d-inline">{{selectedAppointment.patientName}}</h5>
                                <h5 class="ms-2 text_uppercase">   {{relationType ? relationType : "" }} {{selectedAppointment.relativeName?selectedAppointment.relativeName:""}}</h5>
                            </div>
                        </div>
                    </td>

                    <td class="w-10px white-space-nowrap">
                        UMR No :
                    </td>
                    <td><h5 class="ml-2 text_uppercase"> {{selectedAppointment.umrNo}}</h5></td>
                    <td class="w-10px white-space-nowrap">
                        Date/Time :
                    </td>
                    <td><h5 class="ml-2 text_uppercase"> {{currentDate | date :'dd/MM/yyyy, h:mm a'}}</h5></td>
                </tr>
                <tr class="col-12">
                    <td class="w-10px white-space-nowrap">
                        Receipt No :
                    </td>
                    <td><h5 class="ml-2 text_uppercase">{{selectedAppointment.receiptNo }}</h5></td>
                    <td class="w-10px white-space-nowrap">
                        Last Consult Dt :
                    </td>
                    <td>
                        <h5 class="ml-2 text_uppercase">{{selectedAppointment.appointmentDate | date:'dd/MM/yyyy'}}</h5>
                    </td>
                </tr>
                <tr>
                    <td class="w-10px white-space-nowrap">
                        Consultant Dr :
                        <h5 class="ml-2 text_uppercase d-inline">{{selectedAppointment.providerName}}</h5>
                    </td>
                </tr>
            </tbody>
        </table>
    </td>
</tr>
<tr>
    <td colspan="2">
        <table table class="border-dark fs pad_left" width="100%" cellpadding="5">
            <tbody>
                <tr class="col-12">
                    <td class="align-baseline w-100" rowspan="2">
                        Rupees : <span class=" first-letter"> {{subvisitAmtInWrds}} </span>
                        <div class="pt-2"> Pay Modes </div>
                        <div colspan="2" class=" fw" *ngFor="let payType of selectedAppointment.payTypes">
                            {{ payType.payTypeName }}<span *ngIf="payType.paymentDetails"> ({{payType.paymentDetails}})</span> :
                            <h5 class="d-inline-block text_uppercase" [textContent]="payType.amountPaid  || 0 "></h5>

                        </div>
                    </td>


                    <td>
                        <table class="text-right white-space-nowrap">
                            <tr class="col-12">
                                <td class="col-6 text-sm-right">
                                    Total Bill Rs :
                                </td>
                                <td>
                                    <h5 class="d-inline text_uppercase" *ngIf="selectedAppointment.referenceId != null">
                                        <span class="text_uppercase" [textContent]="(selectedAppointment.aptAmount+selectedAppointment.patAmount)-(selectedAppointment.aptDiscount+selectedAppointment.patDiscount) | number:'1.2-2'"></span>
                                    </h5>
                                    <h5 class="d-inline text_uppercase" *ngIf="selectedAppointment.referenceId == null">
                                        <span class="text_uppercase" [textContent]="(selectedAppointment.aptAmount)-(selectedAppointment.aptDiscount) | number:'1.2-2'"></span>
                                    </h5>
                                </td>
                            </tr>

                            <!--<tr>
                                <td class="col-6 text-sm-right padding_50">
                                    Discount :
                                </td>
                                <td>
                                    <span class=" text_uppercase" *ngIf="selectedAppointment.referenceId != null" [textContent]="selectedAppointment.aptDiscount+selectedAppointment.patDiscount | number:'1.2-2'"></span>
                                    <span class=" text_uppercase" *ngIf="selectedAppointment.referenceId == null" [textContent]="selectedAppointment.aptDiscount | number:'1.2-2'"></span>

                                </td>
                            </tr>
                            <tr>
                                <td class="col-6 text-sm-right padding_50">
                                    Total Amount Rs :

                                </td>
                                <td>
                                    <span class=" text_uppercase" *ngIf="selectedAppointment.referenceId != null">
                                        <span class=" text_uppercase" [textContent]="(selectedAppointment.aptAmount+selectedAppointment.patAmount)-(selectedAppointment.aptDiscount+selectedAppointment.patDiscount) | number:'1.2-2'"></span>
                                    </span>
                                    <span class=" text_uppercase" *ngIf="selectedAppointment.referenceId == null">
                                        <span class=" text_uppercase" [textContent]="(selectedAppointment.aptAmount)-(selectedAppointment.aptDiscount) | number:'1.2-2'"></span>
                                    </span>
                                </td>
                            </tr>-->
<!--<tr>
                                        <td class="col-6 text-sm-right padding_50">
                                            Paid Rs :

                                        </td>
                                        <td>
                                            <h5 class="d-inline text_uppercase" *ngIf="selectedAppointment.referenceId != null">
                                                <span class=" text_uppercase" [textContent]="(selectedAppointment.aptAmount+selectedAppointment.patAmount)-(selectedAppointment.aptDiscount+selectedAppointment.patDiscount) | number:'1.2-2'"></span>
                                            </h5>
                                            <h5 class="d-inline text_uppercase" *ngIf="selectedAppointment.referenceId == null">
                                                <span class=" text_uppercase" [textContent]="(selectedAppointment.aptAmount)-(selectedAppointment.aptDiscount) | number:'1.2-2'"></span>
                                            </h5>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                    </tbody>
                </table>
            </td>
        </tr>

    </tbody>
</table>-->
<!--<table class="f_size">
    <tr>
        <!--<td *ngIf="practices && practices[0]">
            <span [textContent]="practices[0]">  </span>
        </td>-->
<!--<td  *ngIf="practiceLocations[0] && practiceLocations[0].optionalText4">
                        <h5 style="text-transform: capitalize">For {{practiceLocations[0].optionalText4.toLowerCase()}}</h5>
                    </td>
                </tr>
                <tr>

                    <td class="col-12" *ngIf="practiceLocations[0] && practiceLocations[0].optionalText1">
                        Note:{{practiceLocations[0].optionalText1}}

                    </td>
                </tr>
            </table>
            <table>
                <tr class="col-12">
                    <td class="col-12">
                        <div>
                            <practice-content></practice-content>
                        </div>
                    </td>
                </tr>
            </table>
            <table class="table_width">
                <tr class="col-12">
                    <td class="col-6">
                    </td>
                    <td>
                        Print Date/Time:&nbsp;<span [textContent]="currentDate | date :'dd/MM/yyyy, h:mm a'"></span>. &nbsp;
                        <span class="text_uppercase d-inline" [textContent]="page.userAccount?.fullName || ''"></span>
                    </td>
                </tr>
            </table>

            <div *ngIf="isFooter">
                <banner-setting [reportName]='"Sub visit Invoice Footer New"'> </banner-setting>
            </div>
        </div>
    </div>
</div>-->

<div *ngIf="!isCancelInvoice">
    <div class="modal-body bg-white reportInvoice report " id="invoice" #invoice>
        <div class="table-responsive-lg table-responsive-sm table-responsive-md text-black">
            <table class="border-dark table_style" width="894px" border="0" cellspacing="0" cellpadding="0">
                <tbody>
                    <tr *ngIf="isPrintLogo">
                        <td colspan="2">
                            <banner-setting [reportName]='"Appointment Invoive"'> </banner-setting>
                        </td>
                    </tr>

                    <tr>
                        <td colspan="2" class="text-center">
                            <h4 class="bordrt_top pt-1"> Final Bill of Supply - Consultation Receipt </h4>
                            <h4 class="pt-1">(Health Care Service - SAC )</h4>
                            <h4 class="pt-1 pb-1">Registration For {{ selectedAppointment.specializationName}}</h4>
                        </td>
                    </tr>

                    <tr>
                        <td colspan="2">
                            <table class="border-dark bt_bb pt-2 pb-2" width="100%" border="0" cellspacing="0" cellpadding="5">
                                <tbody>
                                    <tr>
                                        <td class="vertical-align-top" rowspan="2">
                                            <div class="d-flex">
                                                <div class="white-space-nowrap">Patient Name :  &nbsp;</div>
                                                <div class="d-inline-block">
                                                    <h5 class="ms-2 text_uppercase d-inline">{{selectedAppointment.patientName}}</h5>
                                                    <h5 class="ms-2 text_uppercase">   {{relationType ? relationType : "" }} {{selectedAppointment.relativeName?selectedAppointment.relativeName:""}}</h5>
                                                </div>
                                            </div>
                                        </td>

                                        <td class="w-10px white-space-nowrap">
                                            UMR No :
                                        </td>
                                        <td><h5 class="ml-2 text_uppercase"> {{selectedAppointment.umrNo}}</h5></td>
                                        <td class="w-10px white-space-nowrap">
                                            Receipt No :
                                        </td>
                                        <td>
                                            <h5 class="ml-2 text_uppercase">{{selectedAppointment.receiptNo}}</h5>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="w-10px white-space-nowrap">
                                            Doctor Name :
                                        </td>
                                        <td>
                                            <h5 class="text_uppercase ml-2">{{selectedAppointment.providerName}}</h5>
                                        </td>
                                        <td>
                                            Date:
                                        </td>
                                        <td>
                                            <h5 class="text_uppercase ml-2">{{selectedAppointment.appointmentDate | date:'dd/MM/yyyy'}} {{selectedAppointment.appointmentTimeString}}</h5>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Location Name : &nbsp;
                                            <h5 class="ml-2 text_uppercase d-inline">  {{selectedAppointment.locationName}}</h5>
                                        </td>
                                        <td class="w-10px white-space-nowrap">
                                            Appointment No :
                                        </td>
                                        <td><h5 class="ml-2 text_uppercase"> {{selectedAppointment.appointmentNo}}</h5></td>
                                        <td>
                                            Status :
                                        </td>
                                        <td>
                                            <h5 class="ml-2 text_uppercase d-inline" *ngIf="selectedAppointment.status === 'B'">Booked</h5>
                                            <h5 class="ml-2 text_uppercase d-inline" *ngIf="selectedAppointment.status === 'R'">Rescheduled</h5>
                                            <h5 class="ml-2 text_uppercase d-inline" *ngIf="selectedAppointment.status === 'C'">Cancelled</h5>
                                            <h5 class="ml-2 text_uppercase d-inline" *ngIf="selectedAppointment.status === 'CP'">Closed</h5>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Age / Gender : &nbsp;
                                            <h5 class="ml-2 text_uppercase d-inline">  {{selectedAppointment.patientDateOfBirth ? (selectedAppointment.patientDateOfBirth | age:'days' ): selectedAppointment.patientAge ? (selectedAppointment.patientAge +' yrs '): 'N/A'}}  / {{selectedAppointment.patientGender | gender}}</h5>
                                        </td>
                                        <td class="w-10px white-space-nowrap">
                                            Mobile No :
                                        </td>
                                        <td><h5 class="ml-2 text_uppercase">  {{selectedAppointment.patientMobile}}</h5></td>
                                        <td class="w-10px white-space-nowrap">
                                            Referred By :
                                        </td>
                                        <td>

                                            <h5 class="ml-2 text_uppercase  d-inline">  {{selectedAppointment.referredByName || 'Self'}}</h5>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <table class="border-dark fs t_width pad_left" border="0" cellspacing="0" cellpadding="5">
                                <tbody>
                                    <tr class="col-12">
                                        <td class="w-100">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td class="col-12">
                                                            Charge Type : <h5 class="d-inline ml-3 first-letter"> {{selectedAppointment.chargeTypesName}}</h5>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="col-12">
                                                            Rupees : <h5 class="d-inline ml-3 first-letter">{{amtInWords}} </h5>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="col-4"> Pay Modes : </td>
                                                    </tr>
                                                    <tr class="col-12 " *ngFor="let payType of selectedAppointment.payTypes">
                                                        <td colspan="2" class="col-4 fw">
                                                            <span class="d-inline">
                                                                {{ payType.payTypeName }}
                                                            </span>:
                                                            <h5 class="d-inline ml-3 text_uppercase" [textContent]="payType.amountPaid  || 0 "></h5>
                                                            <h5 class=" ml-3 text_uppercase d-inline" *ngIf="payType.paymentDetails"> ({{payType.paymentDetails}})</h5>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>

                                        <td>
                                            <table class="text-right white-space-nowrap">
                                                <tr class="col-12">
                                                    <td class="col-6">
                                                        Registration Fee Rs :
                                                    </td>
                                                    <td class="col-6">
                                                        <h5 class="d-inline ml-3 text_uppercase" *ngIf="selectedAppointment.patientPaymentStatus" [textContent]="(selectedAppointment.patAmount || 0) | number:'1.2-2'"></h5>
                                                        <h5 class="d-inline ml-3 text_uppercase" *ngIf="selectedAppointment.patientPaymentStatus!=true" [textContent]="selectedAppointment.patientPaymentStatus== false? 'Not Paid' : 'No Registration Charges' "></h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="col-6">
                                                        Consultation Fee Rs :
                                                    </td>
                                                    <td class="col-6">
                                                        <h5 class="ml-3 text_uppercase" [textContent]="(selectedAppointment.aptAmount || 0) | number:'1.2-2'"></h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="col-6">
                                                        Gross Total<span>:</span>
                                                    </td>
                                                    <td class="col-6">
                                                        <h4 class="d-inline ml-3 text_uppercase" *ngIf="selectedAppointment.referenceId != null">
                                                            <span [textContent]="selectedAppointment.aptAmount + selectedAppointment.patAmount | currency: 'INR'"></span>
                                                        </h4>
                                                        <h5 class="d-inline ml-3 text_uppercase" *ngIf="selectedAppointment.referenceId == null">
                                                            <span [textContent]="selectedAppointment.aptAmount | currency: 'INR'"></span>
                                                        </h5>
                                                    </td>
                                                </tr>

                                                <tr *ngIf="(selectedAppointment.referenceId != null && ((selectedAppointment.aptDiscount + selectedAppointment.patDiscount) > 0)) || (selectedAppointment.referenceId == null && (selectedAppointment.aptDiscount > 0))">
                                                    <td class="col-6">
                                                        Discount<span>:</span>
                                                    </td>
                                                    <td class="col-6">
                                                        <h4 class="d-inline ml-3 text_uppercase" *ngIf="selectedAppointment.referenceId != null" [textContent]="selectedAppointment.aptDiscount + selectedAppointment.patDiscount"></h4>
                                                        <h5 class="d-inline ml-3 text_uppercase" *ngIf="selectedAppointment.referenceId == null" [textContent]="(selectedAppointment.aptDiscount || 0) | number:'1.2-2'"></h5>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="selectedAppointment.paymentType === 'P'">
                                                    <td class="col-6">
                                                        Total Amount Rs<span>:</span>
                                                    </td>
                                                    <td class="col-6">
                                                        <h5 class="d-inline ml-3 text_uppercase" *ngIf="selectedAppointment.referenceId != null">
                                                            <span [textContent]="(selectedAppointment.aptAmount+selectedAppointment.patAmount)-(selectedAppointment.aptDiscount+selectedAppointment.patDiscount) | currency: 'INR'"></span>
                                                        </h5>
                                                        <h5 class="d-inline ml-3 text_uppercase" *ngIf="selectedAppointment.referenceId == null">
                                                            <span [textContent]="(selectedAppointment.aptAmount)-(selectedAppointment.aptDiscount) | currency: 'INR'"></span>
                                                        </h5>
                                                    </td>
                                                </tr>

                                                <tr *ngIf="((selectedAppointment.referenceId != null && (selectedAppointment.aptAmount + selectedAppointment.patAmount - (selectedAppointment.aptTotal + selectedAppointment.patTotal)) > 0)) || ((selectedAppointment.referenceId == null && selectedAppointment.discountInPercentage == 0 && (selectedAppointment.aptAmount - selectedAppointment.aptTotal) > 0)) || ((selectedAppointment.referenceId == null && selectedAppointment.discountInPercentage > 0 && (selectedAppointment.aptAmount - selectedAppointment.aptTotal - selectedAppointment.discount) > 0))">
                                                    <td class="col-6">
                                                        Due Amount Rs<span>:</span>
                                                    </td>
                                                    <td class="col-6">
                                                        <h5 *ngIf="selectedAppointment.referenceId != null" class="d-inline" [textContent]="(selectedAppointment.aptAmount+selectedAppointment.patAmount)-(selectedAppointment.aptTotal+selectedAppointment.patTotal) | currency: 'INR'"></h5>
                                                        <h5 *ngIf="selectedAppointment.referenceId == null && selectedAppointment.discountInPercentage==0" class="d-inline" [textContent]="(selectedAppointment.aptAmount)-(selectedAppointment.aptTotal) | currency: 'INR'"></h5>
                                                        <h5 *ngIf="selectedAppointment.referenceId == null && selectedAppointment.discountInPercentage>0" class="d-inline" [textContent]="(selectedAppointment.aptAmount)-(selectedAppointment.aptTotal)-(selectedAppointment.discount) | currency: 'INR'"></h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="col-6">
                                                        {{ selectedAppointment.paymentType === 'P' ? 'Paid Amount' : 'Net Total' }}<span>:</span>
                                                    </td>
                                                    <td class="col-6">
                                                        <h5 class="d-inline" *ngIf="selectedAppointment.referenceId != null && selectedAppointment.discountInPercentage==0 ">
                                                            <span class="ml-3 text_uppercase" [textContent]="selectedAppointment.aptTotal+selectedAppointment.patAmount | currency: 'INR'"></span>
                                                        </h5>
                                                        <h5 class="d-inline" *ngIf="selectedAppointment.referenceId != null && selectedAppointment.discountInPercentage >0">
                                                            <span class="ml-3 text_uppercase" [textContent]="selectedAppointment.aptTotal+selectedAppointment.patTotal | currency: 'INR'"></span>
                                                        </h5>

                                                        <h5 class="d-inline" *ngIf="selectedAppointment.referenceId == null">
                                                            <span class="ml-3 text_uppercase" [textContent]="selectedAppointment.aptTotal | currency: 'INR'"></span>
                                                        </h5>

                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>

                                        <td colspan="2" class="col-4 fw" *ngIf="practiceLocations[0] && practiceLocations[0].optionalText4">
                                            <h5 style="text-transform: capitalize">For {{practiceLocations[0].optionalText4.toLowerCase() }}</h5>
                                        </td>
                                    </tr>
                                    <tr class="col-12">
                                        <td class="col-12" *ngIf="practiceLocations[0] && practiceLocations[0].optionalText1">
                                            Note:{{practiceLocations[0].optionalText1}}

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <table class="border-dark fs t_width pad_left " border="0" cellspacing="0" cellpadding="5">
                                <tbody>
                                    <tr class="col-12">
                                        <td class="d-flex flex-row col-12">

                                            <practice-content></practice-content>
                                            <div>
                                            </div>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table class="t_width">
                                <tbody>
                                    <tr class="col-12">
                                        <td class="col-6"></td>
                                        <td class="col-6">
                                            <span>Printed Date/Time: &nbsp;</span> <span [textContent]="currentDate | date :'dd/MM/yyyy, h:mm a'"></span>&nbsp;&nbsp;
                                            <span class="text_uppercase d-inline" [textContent]="page.userAccount?.fullName || ''"></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="isFooter">
                <banner-setting [reportName]='"Appointment Invoice Footer"'> </banner-setting>
            </div>
        </div>
    </div>


</div>