
<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">Beds Under Cleaning</h4>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="loading">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading  bed availability...</span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!loading && (!records || !records.length)">
            <div class="col-12 mh-300">
                <div class="no-data">
                    <img src="assets/images/no-data.png" alt="No data" />
                    <p class="sub-title">There is no data to show you right now.</p>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="records && records.length >0">
            <div class="col-12">
                <div class="card mb-0">
                    <div class="card-body p-0">
                        <div class="overflow-auto">
                            <table class="table table-centered table-sm table-striped table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th>Room Name</th>
                                        <th>Bed Name</th>
                                        <th>Status</th>
                                        <th class="text-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of records;">
                                        <td [textContent]="item.roomName"></td>
                                        <td [textContent]="item.bedNumber"></td>
                                        <td [textContent]="item.bedStatusName"></td>
                                        <td>
                                            <div class="d-flex align-items-center justify-content-end">
                                                <div>
                                                    <a href="javascript:;" class="action-icon text-success mr-1" placement="left" ngbTooltip="Clear" (click)="onModifyStatus(item)"><b><i class="mdi mdi-check-bold"></i></b></a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>                                  
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

