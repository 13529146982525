/* eslint-disable @typescript-eslint/no-unused-vars */

import { DatePipe } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Event, NavigationEnd, Params, Router } from "@angular/router";
import { NgbCalendar, NgbDate, NgbDateStruct, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Admission, Appointment, BedManagement, DoctorUnits, InsuranceAdmission, MedicationInfo, Patient, PatientFamily, PatientInsurance, Setting } from "@shared/entities";
import { ApiResources, DateHelper, UtilHelper } from "@shared/helpers";
import { Counselling, GenericResponse, GenericStatus, IResource, ISlot, IUserAccount, LookUpModel, ObEncounter, Page, Pagination } from "@shared/models";
import { AppData, BillNotificationService, HttpService, MenuService, NotifyService, ResourceService, VideoLinkService, PrintOptionService, AppConfig, WhatsAppService } from "@shared/services";
import moment from 'moment';
import { Observable, Subject, of } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, finalize, switchMap, takeUntil, tap } from "rxjs/operators";
import { AdmissionTransferRequest } from "../../../../../shared/entities/admission-transfer-request.entity";
import { ProviderSpecialization } from "../../../../../shared/entities/provider-specialization.entity";
import { CounsellingViewType, CounsellingWithPackages, MenuType } from "../../../../../shared/enums";
import { MobileValidator, WhiteSpaceValidator } from "../../../../../shared/validators";
import { OB } from "../../../full-transcript/pages/ob-report/ob.namespace";
import { ICounsellingDetails } from "./local-helper";



enum PageView {
    MainView,
    AddView
}

enum UrgentSteps {
    Step1,
    Step2,
    Step3
}

class TrackBy {
    admission(item: Admission) {
        return item.admissionId;
    }

    resource(item: IResource) {
        return item.id;
    }
    doctorUnits(item: DoctorUnits) {
        return item.doctorUnitMasterId;
    }
}

class FilterOptions {
    providerId?: number = null;
    patientId?: number = null;
    locationId: number = null;
    patientMobile: string = null;
    admissionNo: string = null;
    fromDate: string = null;
    toDate: string = null;
    dischargeDate: string = null;
    isDischarged?: boolean = false;
    status?: boolean = null;
    umrNo: string = null;
    active?: boolean = true;
    chargeCategoryId?: number = null;
    roomId?: number = null;
    admissionVisitTypeId: number = null;
    admissionTypeId: number = null;
    departmentId: number = null;
    payType: string = null;
    isCheckOut = false;
    isFromWeb = true;

}

class Filters {
    options: FilterOptions;
    applied: boolean;

    constructor() {
        this.init();
    }

    init() {
        this.options = new FilterOptions();
        this.applied = undefined;
    }
}

class Priority {
    id: number;
    name: string;
    icon: string;
    color: string;
}

@Component({
    templateUrl: "./view.html",
    styleUrls: ['./view.css']
})
export class AdmissionsViewPage implements OnInit, OnDestroy {
    @ViewChild("templatePatientAppointment", { static: true }) templatePatientAppointment: TemplateRef<any>;
    @ViewChild("templateViewCounselling", { static: true }) templateViewCounselling: TemplateRef<any>;

    counsellingViewType = CounsellingViewType;
    payTypes = [{ value: "C", name: "Cash" }, { value: "I", name: "Insurance" }] as Array<IResource>;

    page: Page;
    today = DateHelper.ngbToday;
    minDate = DateHelper.minDate;

    pageView = PageView;
    urgentSteps = UrgentSteps;
    currentUrgentStep = UrgentSteps.Step1;
    currentPageView = PageView.MainView;

    priorities: Array<Priority>;
    filters: Filters;
    trackBy: TrackBy;
    pagination: Pagination;

    loading: boolean;
    admissions: Array<Admission>;
    @ViewChild("templateBookAdmission", { static: true }) templateBookAdmission: TemplateRef<any>;

    loadingPatients: boolean;
    patients: Array<IResource>;

    patient: Patient;
    selectedPatient: Patient;
    familyMembers: Array<PatientFamily>;
    department: Observable<Array<IResource>>;
    provider: Observable<Array<IResource>>;
    counsellingCodeWithNames: Observable<Array<IResource>>;
    patientsInput = new Subject<string>();
    departmentsInput = new Subject<string>();
    providerInput = new Subject<string>();
    counsellingInput = new Subject<string>();
    providersInput = new Subject<string>();
    spinners = false;
    meridian = false;
    maxAdmissionDate: string;

    loadingSurgeryTypes: boolean;
    loadingCountries: boolean;
    countries: Array<IResource>;

    loadingWards: boolean;
    loadingRooms: boolean;
    loadingBeds: boolean;
    loadingProviderLocations: boolean;
    loadingProviders: boolean;
    loadingDepartments: boolean;
    loadingRelationShips: boolean;
    providerLocations: Array<IResource>;
    providers: Array<IResource>;
    departments: Array<IResource>;
    surgeryTypes: Array<IResource>;
    relationships: Array<IResource>;
    wards: Array<BedManagement>;
    rooms: Array<BedManagement>;
    beds: Array<BedManagement>;
    selectedWard: BedManagement;
    selectedRoom: BedManagement;
    selectedBed: BedManagement;
    selectedBedManagement: BedManagement;
    visitors: Array<LookUpModel>;
    isVisitTypeloading: boolean;
    providerSpecialization: Array<ProviderSpecialization>
    modalRef: NgbModalRef;
    documentModalRef: NgbModalRef;
    loadingFamilyMember: boolean;
    submitting: boolean;
    submitted: boolean;
    modifying: boolean;
    modifyingContent: string;
    showWards = false;
    showRooms = false;
    showBeds = false;

    bookAdmissionForm: FormGroup;
    rescheduleAdmissionForm: FormGroup;
    selectedAdmission: Admission;
    loadingSlots: boolean;
    noOfSlots: number;
    morningSlots: Array<ISlot>;
    afternoonSlots: Array<ISlot>;
    eveningSlots: Array<ISlot>;
    nightSlots: Array<ISlot>;
    providerId: number;
    departmentId: number;
    admissionId: number;
    reason: string;
    resultsType = "today";
    minRequiredDate: NgbDateStruct;
    finalAmount: number;
    admissionNo: number;
    showSeriveOrder: boolean;
    showProgressReport: boolean;

    routingValue: string;
    urlPatientId: number;
    isPatientFilters = true;
    isShowInternalMedicine: boolean;
    isLoadingMedicationInfo: boolean;

    currentPopover: any;
    isFromAdmissions = true;
    isConvertedFromOPtoIp: boolean;
    insuranceApprovals: Array<InsuranceAdmission>;
    @ViewChild('patientInsurances', { static: false }) patientInsurancetemplate: TemplateRef<any>;
    @ViewChild('insuranceAdmission', { static: false }) insurancetemplate: TemplateRef<any>;
    @ViewChild('templateUrgentAdmission', { static: true }) templateUrgentAdmission: TemplateRef<any>;
    tempTemplate: TemplateRef<any>;
    patientForm: FormGroup;
    formArrayIndex: number;
    message: string;
    loadingInsurances: boolean;
    insurances: Array<IResource>;
    tpa: Array<IResource>;
    insuranceCompanies: Array<IResource>;
    loadingCompanies: boolean;
    appointmentId: number;
    patientProviders: Array<IResource>;
    pid: any;
    fromOp: any;
    setMaxDate: NgbDate;
    setMinDate: NgbDate;
    dischargeDate: number;
    eDate: any;
    maxDischargeDate: any;
    minDatetoday = moment(new Date()).format('YYYY-MM-DD');
    checkLength: number;
    loadingPayTypes: boolean;
    payType: Array<IResource>;
    isSalucro: boolean;
    loadingSettings: boolean;
    loadingfutureAppointment: boolean;
    patientFutureAppointments: Array<Appointment>;
    loadingCaseTypes: boolean;
    loadingEmergencyCaseTypes: boolean;
    caseTypes: Array<IResource>;
    emergencyCaseTypes: Array<IResource>;
    loadingchargeCategory: boolean;
    chargeCategory: Array<IResource>;
    loadingReferraldoctor: boolean;
    referraldoctors: Array<IResource>;
    yearsfordisplay: number;
    monthsfordisplay: number;
    daysfordisplay: number;
    filterPatient: Array<IResource>;
    surrogacyA: any;
    emergency: any;
    showDiv: boolean;
    trustee = ["Trustee", "Consultant", "Others"];
    councelled = ["Councelled", "Not Councelled"];
    bedAssoData: any;
    isCouncelled: string;
    selectedDoctorUnit: DoctorUnits;
    surrogacyValues: any;
    nRIDataDetailsValues: any;
    DoctorUnits: Array<DoctorUnits>
    loadingTpas: boolean;
    showEmergency = false;
    showSurrogacy = false;
    loadingDoctorUnits: boolean;
    chargeCategoryId: number;

    loadingPackages: boolean;
    packages: Array<IResource>;
    raiseBedChangeRequestForm: FormGroup;
    chargeCategories: Array<IResource>;
    loadingChargeCategories: boolean;
    umrNo: string;
    isAdmissionCancel: boolean;
    isAdmissionEdit: boolean;
    admissionCancelMinutes: number;
    admissionEditMinutes: number;

    counsellingFilter: Array<string>;
    loadingCounsellings: boolean;
    counsellingDetails: Array<ICounsellingDetails>;
    counsellingDetailsAll: Array<ICounsellingDetails>;
    selectedCounsellingId: number;
    selectedCounsellingPackagesIds: Array<number>;
    selectedCounsellingDetails: CounsellingWithPackages;
    loadingCategories: boolean;
    categories: Array<IResource>;
    maxFiles = 3;
    files: Array<File>;
    raiseDoctorUnitRequestForm: FormGroup;
    logoBasics: Setting;
    isDoctorUnit: boolean;
    isShowTemplate: boolean;
    loadingLocationChargeTemplates: boolean;
    locationChargeTemplates: IResource[];
    chargeBillingCategoryId: number;
    showRegister: boolean;

    showAttachmentBlock = true;
    showAttendantBlock = true;
    showTimingBlock = true;
    showPackageBlock = true;
    showPatientBlock = true;
    showNotesBlock = true;
    date: Date;
    isPrintLogo: boolean;

    admissionSlipRecords: Array<ObEncounter>;
    admissionRecords: OB.AdmssionSlip;

    loadingOccupation: boolean;
    occupation: Array<IResource>;
    loadingBabyTypes: boolean;
    babyTypes: IResource[];
    loadingAdmissionTypes: boolean;
    admissionTypes: IResource[];
    loadingAdmissionVisitTypes: boolean;
    admissionVisitTypes: IResource[];
    admissionPatients: IResource[];
    relationshipsId: any;
    relativeOcuupationId: any;
    bedselect: Admission;
    data: Array<Admission>;
    patientAge: string;
    ChangeCaseTypeForm: FormGroup;
    changecaseTypes: Array<IResource>;
    //caseTypeId: number;
    specialization: any[];
    obbutton: boolean;
    gynbutton: boolean;
    dietbutton: boolean;
    neonatalbutton: boolean;
    ivfbutton: boolean;
    isComments: boolean;
    loadSeniorSpecialization: boolean;
    seniorDoctorSpecializations: IResource[];
    patientID: any;
    patientinsurances: IResource[];
    patientinsurance: any;
    isPatient: boolean;
    loadingRoleDepartment: boolean;
    roleDepartment: IResource[];
    toDay: string;
    patientCounselling: Array<Counselling>;
    flag: boolean;
    information: Array<any>;
    referralDoctorInput = new Subject<string>();
    referrals: Observable<Array<IResource>>;
    selectedDoctor: IResource;
    Specialization: number;
    editBedStatus = true;
    admissionType: any;
    loadingAdmissions: boolean;
    surgeryId: number
    surgicalId: number;
    editInsuranceForm: FormGroup;
    encryptedPatientId: string;
    decryptedPatientId: number;
    patientDOB: string;
    encPatientId: string;
    minAdmissionDate: string;
    loadingRepotName: boolean;
    reportName: string;
    bannerName: string
    loadInsurance: boolean;
    doctorDetails: any = null;
    patientDetails: any = null;
    isFooter: boolean;
    details: Array<IResource>;
    practiceName: string
    city: string;
    regionalLanguage: string;
    showTimeLine: boolean;
    encryptedAdmissionId: string;
    nameLoc: string;
    loadingChargeCategoriesWithBeds: boolean;
    chargeCategoriesWithBeds: Array<IResource>;
    constructor(
        private readonly route: ActivatedRoute,
        private readonly httpService: HttpService,
        private readonly resourceService: ResourceService,
        private readonly modalService: NgbModal,
        private readonly notifyService: NotifyService,
        private readonly formBuilder: FormBuilder,
        private readonly appData: AppData,
        private readonly router: Router,
        public readonly videoLinkService: VideoLinkService,
        private readonly calendar: NgbCalendar,
        private readonly menuService: MenuService,
        private readonly datePipe: DatePipe,
        private readonly billNotificationService: BillNotificationService,
        private readonly menuservice: MenuService,
        private readonly printOptionService: PrintOptionService,
        private readonly whatsAppService: WhatsAppService

        //private sanitizer: DomSanitizer
    ) {
        this.showSeriveOrder = this.menuService.menus(MenuType.SubMenu, "Services & Payments").length > 0;
        this.showProgressReport = this.menuService.menus(MenuType.SubMenu, "Progress Report").length > 0;
        this.isShowInternalMedicine = this.menuService.menus(MenuType.SubMenu, "Internal Medicine").length > 0;
        this.showRegister = this.menuService.menus(MenuType.SubMenu, "Registers").length > 0;
        this.counsellingFilter = new Array<string>();
        this.counsellingDetails = new Array<ICounsellingDetails>();
        this.counsellingDetailsAll = new Array<ICounsellingDetails>();

        this.priorities = new Array<Priority>();
        this.loading = true;
        this.isLoadingMedicationInfo = true;

        this.page = new Page();
        this.filters = new Filters();
        this.trackBy = new TrackBy();

        this.minRequiredDate = this.calendar.getToday();
        this.insuranceApprovals = new Array<InsuranceAdmission>();
        this.insurances = new Array<IResource>();
        this.tpa = new Array<IResource>();
        this.insuranceCompanies = new Array<IResource>();
        this.departments = new Array<IResource>();
        this.patients = new Array<IResource>();
        this.initPagination();
        this.DoctorUnits = new Array<DoctorUnits>();
        this.selectedDoctorUnit = new DoctorUnits();
        this.admissions = new Array<Admission>();
        this.packages = new Array<IResource>();
        this.patient = new Patient();
        this.locationChargeTemplates = new Array<IResource>();
        this.date = new Date();
        this.buildPatientInsuranceForm()

        this.patientCounselling = new Array<Counselling>();
        this.buildRoleDeparmentForm();
        this.buildForm();


    }

    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`;
        }
    }


    private initPagination() {
        this.pagination = new Pagination();
        this.pagination.pageSize = 10;
        this.pagination.pageIndex = 1;
    }
    private fetchCategories() {
        this.loadingCategories = true;
        this.resourceService.documentCategories()
            .pipe(finalize(() => { this.loadingCategories = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.categories = response;
            });
    }
    onOpenDocumentModal(content: TemplateRef<any>) {
        this.documentModalRef = this.modalService.open(content, {
            keyboard: false,
            centered: true,
            size: "lg",
            windowClass: "custom-modal effect-scale",
            backdrop: "static"
        });
    }
    onCloseDocumentModel() {
        try {
            this.documentModalRef.close();
            this.documentModalRef = undefined;
        } catch (e) {
            // ignored;
        }
    }
    onFileSelect(files: Array<File>) {
        this.files = files;
    }
    private buildBookAdmissionForm() {
        function adjust(val) {
            if (val > 9) {
                return val.toString();
            } else {
                return '0' + val.toString();
            }
        }

        const today = new Date();
        const date = today.getFullYear() + '-' + adjust(today.getMonth() + 1) + '-' + adjust(today.getDate());
        const time = adjust(today.getHours()) + ":" + adjust(today.getMinutes());
        const dateTimeNow = `${date}T${time}`;
        this.maxAdmissionDate = `${date}T${time}`;
        this.minAdmissionDate = `${date}T${time}`;
        const addadmission = new FormControl(null);

        const counsellingCodeControl = new FormControl(null);
        const isUrgentAdmissionControl = new FormControl(null);
        this.bookAdmissionForm = this.formBuilder.group({
            admissionId: [0],
            counsellingCode: counsellingCodeControl,
            isUrgentAdmission: isUrgentAdmissionControl,
            counsellingId: [null],
            admissionNo: [null],
            providerId: [null, [Validators.required]],
            specializationId: [0, [Validators.required]],
            patientId: [null, [Validators.required]],
            patientFamilyId: [null],
            doctorUnitMasterId: [null, [Validators.required]],
            departmentId: [null, [Validators.required]],
            patientType: ["C", [Validators.required]],
            surgeryTypeId: [null],
            roomId: [null],
            bedId: [null, [Validators.required]],
            wardId: [null],
            isMaternity: [null],
            isDischarged: [false],
            admissionDate: [dateTimeNow, [Validators.required]],
            admissionTime: [null],
            admissionTimeString: [null],
            admissionNotes: [null],
            attendantName: [null],
            attendantContactNo: [null, [MobileValidator.isValid]],
            attendantRelationWithPatient: [null],
            createdBy: [this.page.userAccount.accountId, [Validators.required]],
            modifiedBy: [null],
            logFrom: [this.page.userAccount.roleId],
            visitTypeId: [null],
            patientPriorityId: [null],
            breakfast: [null],
            lunch: [null],
            dinner: [null],
            providerName: [null],
            paymentNumber: [null],
            paymentStatus: [null],
            //caseTypeId: [null, [Validators.required]],
            payType: [null, [Validators.required]],

            tpaId: null,
            patientOrganization: [null],
            chargeCategoryId: [null],
            chargeBillingCategoryId: [null],
            referralDoctorName: [null],
            packageModuleId: null,
            admissionPackageId: null,
            documentName: null,
            documentType: null,
            description: null,
            chargeModuleTemplateId: [null],
            paymentResponsibleName: [null],
            admissionPaymentResponsibleId: [null],
            paymentRPAddress: [null],
            paymentRPMobile: [null],
            paymentRPEmail: [null],
            rpOccupationId: [null],
            rpRelationId: [null],
            motherIpNo: [null],
            motherName: [null],
            mlcNo: [null],
            mlcType: [null],
            mlcNm: [null],
            clinicalNotes: [null],
            currentBed: [null],
            currentUnit: [null],
            isNewBornBaby: [false],
            isMLC: [false],
            mlcDetailsId: [null],
            babyTypeId: [null],
            admissionTypeId: [null],
            admissionVisitTypeId: [null],
            billingComments: [null],
            appointmentId: [null],

            patientInsuranceId: [null],

            insuranceTypeId: [null],

            validity: [null],
            cardNo: [null],
            patientName: [null],

            insuranceCompanyId: [null],
            roleDepartment: this.formBuilder.array([]),
            internalProviderId: [null],
            externalProviderId: [null],
            surgeryId: [null, [Validators.required]],
            nriData: this.formBuilder.group({
                isNri: [null], memberIdcardNo: [null], unit: [null], policyNo: [null], cardHolder: [null], serviceNo: [null], refDate: [null], rank: [null], refSerialNo: [null], insuranceCode: [null], organisation: [null], insuranceCo: [null], panCardNo: [null], passportNo: [null], ppAddress: [null], phoneNo: [null], ppIssuePlace: [null], afterDischarge: [null]
            }),
            bedAssociatedData: this.formBuilder.group({
                packageName: [null], diagnosis: [null], approxCost: [null], email: [null], patientAddress: [null], trusteeRadiobtn: [null], trustee: [null], relation: [null], recommended: [null], criticalDr: [null], coConsultantdr: [null], councelled: [null]
            }),
            emergencyInfo: this.formBuilder.group({
                emergencyContactNo: [null], emergencyTypeId: [null]
            }, { optional: true }),
            surrogacyData: this.formBuilder.group({
                geneticMotherName: [null], age: [null], geneticFatherName: [null], address: [null],
                phoneNo: [null], bloodGroup: [null], rhType: [null], fromDate: [null], toDate: [null]
            }, { optional: true })
        });

        isUrgentAdmissionControl.valueChanges.subscribe(is => {
            if (is) {
                this.currentUrgentStep = UrgentSteps.Step1;
                this.onOpen(this.templateUrgentAdmission);
            }
        })
        this.bookAdmissionForm.get('isNewBornBaby').valueChanges.subscribe((isNewBornBaby: boolean) => {
            if (!isNewBornBaby) {
                this.bookAdmissionForm.patchValue({
                    motherIpNo: null,
                    motherName: '',
                    currentBed: ''
                });
            }
        })
        counsellingCodeControl.valueChanges.subscribe(code => {
            const counselling = this.counsellingDetails.find(x => x.counsellingNo === code);
            if (counselling) {
                this.selectedCounsellingId = counselling.counsellingId;
                this.showCounsellingDetails(this.templateViewCounselling);
            }
            //this.counsellingCodeWithNames.forEach((item) => {
            //    if (item["value"] == code) {
            //        const counselling = item;
            //        if (counselling) {
            //            this.selectedCounsellingId = counselling["counsellingId"];
            //            this.showCounsellingDetails(this.templateViewCounselling);
            //            return;
            //        }
            //    }
            //})

        })

        this.bookAdmissionForm.get("payType").valueChanges.subscribe((payType: string) => {
            payType && this.onSelectPayType(payType);
            if (payType == "I") {
                this.updateValidators(
                    ["insuranceTypeId", "chargeModuleTemplateId"],
                    [Validators.required],
                    false
                );
            } else {
                this.updateValidators(
                    ["insuranceTypeId", "chargeModuleTemplateId"],
                    [],
                    false
                );
            }
            this.beds = [];
            if (this.selectedBedManagement) this.selectedBedManagement.bedId = null;
            this.bookAdmissionForm.get("cardNo").updateValueAndValidity({ emitEvent: false });
            this.bookAdmissionForm.get("validity").updateValueAndValidity({ emitEvent: false });
        });

        this.bookAdmissionForm.get("chargeModuleTemplateId").valueChanges.subscribe((id: number) => {
            if (id) {
                this.bookAdmissionForm.get("chargeModuleTemplateId").setValue(id, { emitEvent: false });
                this.onChangeChargeTemplate(id);
            }
        });

        this.bookAdmissionForm.get("providerId").valueChanges.subscribe((id: number) => {
            //this.providerId = id;
            this.bookAdmissionForm.patchValue({
                departmentId: null
            });
            this.fetchDepartments(id);
        });
        //this.bookAdmissionForm.get("caseTypeId").valueChanges.subscribe((id: number) => {
        //    const findPatient = this.caseTypes.find(m => m.id === id);
        //    if (findPatient) {
        //        if (findPatient.value == 'emergency') {
        //            this.showEmergency = true;
        //        }
        //        else {
        //            this.showEmergency = false;
        //        }
        //        if (findPatient.value == 'Surrogacy') {
        //            this.showSurrogacy = true;
        //        }
        //        else {
        //            this.showSurrogacy = false;
        //        }
        //    }
        //});


        //this.bookAdmissionForm.get("departmentId").valueChanges.subscribe((id: number) => {
        //    this.fetchProviders(id);
        //});

        this.bookAdmissionForm.get("patientId").valueChanges.subscribe((id: number) => {
            if (id) {
                this.findPatient(id)
            } else {
                this.patient = null;
            }
        });
        this.bookAdmissionForm.get("doctorUnitMasterId").valueChanges.subscribe((id: number) => {
            if (id) {
                this.selectedDoctorUnit = this.DoctorUnits.find(x => x.doctorUnitMasterId === id);
                var tagDoctor = this.selectedDoctorUnit.tagDoctor[0].providerId != null ? this.selectedDoctorUnit.tagDoctor[0].providerId : null
                this.bookAdmissionForm.patchValue({
                    providerId: tagDoctor
                });
                this.fetchSeniorDoctorSpecialization(this.selectedDoctorUnit.tagDoctor[0].providerId);
            }
            else {
                this.selectedDoctorUnit = null;
                this.bookAdmissionForm.patchValue({
                    providerId: null
                });
            }
        });


        if (!this.providerId === null) {
            this.bookAdmissionForm.get("providerId").setValue(this.providerId);
        }
    }

    showCounsellingDetails = (content: TemplateRef<any>): void => {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static", keyboard: false, centered: true, size: "view-counselling", windowClass: "custom-modal effect-scale"
        });
    }

    applyCounselling = (selectedCounselling: CounsellingWithPackages) => {
        this.onCloseUrgentModal();
        const counselling = this.counsellingDetails.find(x => x.counsellingId === this.selectedCounsellingId);

        const selectedPackage = selectedCounselling.packages[0];
        this.selectedCounsellingDetails = selectedCounselling;
        this.selectedCounsellingPackagesIds = this.selectedCounsellingDetails.packages.map(x => x.counsellingPackageId);
        const chargeCategoryId = selectedPackage.chargeCategoryId ? selectedPackage.chargeCategoryId : null;
        this.showPackageBlock = false;
        this.onPatientSearch(counselling.umrNo)
        this.findPatientDetails(null, counselling.patientId);

        this.bookAdmissionForm.patchValue({
            payType: selectedCounselling.counselling.payType,
            chargeModuleTemplateId: selectedCounselling.counselling.chargeModuleTemplateId,
            patientOrganization: counselling.patientOrganization || null,
            chargeCategoryId: chargeCategoryId,
            chargeBillingCategoryId: chargeCategoryId,
            counsellingId: counselling.counsellingId ? counselling.counsellingId : null,
            packageModuleModelId: null
        }, { emitEvent: false });
        this.showPackageBlock = selectedCounselling && selectedCounselling.packages[0] && selectedCounselling.packages[0].packageModuleId ? true : false;
        if (!this.isConvertedFromOPtoIp)
            this.bookAdmissionForm.patchValue({
                patientId: counselling.patientId
            })
        this.fetchLocationChargeTemplates(selectedCounselling.counselling.payType, true);
        this.bookAdmissionForm.patchValue({
            packageModuleId: selectedCounselling.packages[0].packageModuleId
        })
    }

    private buildUpdateBookAdmissionForm(admissionItem: Admission) {
        let surrogacyDetailsPatch = {};
        if (admissionItem.surrogacyData != null) {
            this.surrogacyValues = admissionItem.surrogacyData;
            const surrogacyDetails = JSON.parse(this.surrogacyValues);
            surrogacyDetailsPatch = {
                geneticMotherName: surrogacyDetails.geneticMotherName,
                age: surrogacyDetails.age,
                geneticFatherName: surrogacyDetails.geneticFatherName,
                address: surrogacyDetails.addressfetc,
                phoneNo: surrogacyDetails.phoneNo,
                bloodGroup: surrogacyDetails.bloodGroup,
                rhType: surrogacyDetails.rhType,
                fromDate: surrogacyDetails.fromDate,
                toDate: surrogacyDetails.toDate
            }
        }
        else {
            surrogacyDetailsPatch = {
                geneticMotherName: null, age: null, geneticFatherName: null, address: null, phoneNo: null, bloodGroup: null, rhType: null, fromDate: null, toDate: null
            }
        }

        let bedData = {};
        if (admissionItem.bedAssociatedData != null) {
            this.bedAssoData = JSON.parse(admissionItem.bedAssociatedData);
            bedData = {
                diagnosis: this.bedAssoData.diagnosis,
                packageName: this.bedAssoData.packageName,
                approxCost: this.bedAssoData.approxCost,
                coConsultantdr: this.bedAssoData.coConsultantdr,
                criticalDr: this.bedAssoData.criticalDr,
                email: this.bedAssoData.email,
                patientAddress: this.bedAssoData.patientAddress,
                recommended: this.bedAssoData.recommended,
                relation: this.bedAssoData.relation,
                trustee: this.bedAssoData.trustee,
                trusteeRadiobtn: this.bedAssoData.trusteeRadiobtn,
                councelled: this.bedAssoData.councelled
            }
        }
        else {
            bedData = {
                packageName: [null], diagnosis: [null], approxCost: [null], email: [null], patientAddress: [null], trusteeRadiobtn: [null], trustee: [null], relation: [null], recommended: [null], criticalDr: [null], coConsultantdr: [null], councelled: [null]
            }
        }

        let NRIDataDetailsPatch = {};
        if (admissionItem.nriData != null) {
            this.nRIDataDetailsValues = admissionItem.nriData;
            const NRIDataDetails = JSON.parse(this.nRIDataDetailsValues);
            NRIDataDetailsPatch = {
                isNri: NRIDataDetails.isNri,
                memberIdcardNo: NRIDataDetails.memberIdcardNo,
                unit: NRIDataDetails.unit,
                policyNo: NRIDataDetails.policyNo,
                cardHolder: NRIDataDetails.cardHolder,
                serviceNo: NRIDataDetails.serviceNo,
                refDate: NRIDataDetails.refDate,
                rank: NRIDataDetails.rank,
                refSerialNo: NRIDataDetails.refSerialNo,
                insuranceCode: NRIDataDetails.insuranceCode,
                organisation: NRIDataDetails.organisation,
                insuranceCo: NRIDataDetails.insuranceCo,
                panCardNo: NRIDataDetails.panCardNo,
                passportNo: NRIDataDetails.passportNo,
                ppAddress: NRIDataDetails.ppAddress,
                phoneNo: NRIDataDetails.phoneNo,
                ppIssuePlace: NRIDataDetails.ppIssuePlace,
                afterDischarge: NRIDataDetails.afterDischarge,

            }

        } else {
            NRIDataDetailsPatch = {
                isNri: null, memberIdcardNo: null, unit: null, policyNo: null, cardHolder: null, serviceNo: null, refDate: null, rank: null, refSerialNo: null, insuranceCode: null, organisation: null, insuranceCo: null, panCardNo: null, passportNo: null, ppAddress: null, phoneNo: null, ppIssuePlace: null, afterDischarge: null,
            }
        }

        let emergencyDetailsPatch = {};
        if (admissionItem.emergencyInfo != null) {
            this.emergency = admissionItem.emergencyInfo;
            const emergencyDetails = JSON.parse(this.emergency);
            emergencyDetailsPatch = {
                emergencyContactNo: emergencyDetails.emergencyContactNo,
                emergencyTypeId: emergencyDetails.emergencyTypeId
            }

        }
        else {
            emergencyDetailsPatch = {
                emergencyContactNo: null,
                emergencyTypeId: null
            }
        }

        this.bookAdmissionForm.patchValue({
            doctorUnitMasterId: admissionItem.doctorUnitMasterId,
            admissionId: admissionItem.admissionId,
            admissionNo: admissionItem.admissionNo,
            providerId: admissionItem.providerId,
            patientId: admissionItem.patientId,
            patientFamilyId: admissionItem.patientFamilyId,
            departmentId: admissionItem.departmentId,
            patientType: admissionItem.patientType,
            surgeryTypeId: admissionItem.surgeryTypeId,
            roomId: admissionItem.roomId,
            bedId: admissionItem.bedId,
            wardId: admissionItem.wardId,
            isMaternity: admissionItem.isMaternity,
            isDischarged: admissionItem.isDischarged,
            admissionDate: admissionItem.admissionDate + ".000",
            admissionTime: admissionItem.admissionTime,
            admissionTimeString: admissionItem.admissionTimeString,
            admissionNotes: admissionItem.admissionNotes,
            attendantName: admissionItem.attendantName,
            attendantContactNo: admissionItem.attendantContactNo,
            attendantRelationWithPatient: admissionItem.attendantRelationWithPatient,
            createdBy: admissionItem.createdBy,
            modifiedBy: this.page.userAccount.accountId,
            logFrom: this.page.userAccount.roleId,
            visitTypeId: admissionItem.visitTypeId,
            patientPriorityId: admissionItem.patientPriorityId,
            providerName: admissionItem.providerName,
            surrogacyData: surrogacyDetailsPatch,
            nriData: NRIDataDetailsPatch,
            bedAssociatedData: bedData,
            emergencyInfo: emergencyDetailsPatch,
            //caseTypeId: admissionItem.caseTypeId,
            surgeryId: admissionItem.surgeryId,
            payType: admissionItem.payType,
            insuranceCompanyId: admissionItem.insuranceCompanyId,
            tpaId: admissionItem.tpaId,
            patientOrganization: admissionItem.patientOrganization,
            packageModuleId: admissionItem.packageModuleId,
            admissionPackageId: admissionItem.admissionPackageId,
            paymentResponsibleName: admissionItem.paymentResponsibleName,
            admissionPaymentResponsibleId: admissionItem.admissionPaymentResponsibleId,
            paymentRPAddress: admissionItem.paymentRPAddress,
            paymentRPMobile: admissionItem.paymentRPMobile,
            paymentRPEmail: admissionItem.paymentRPEmail,
            rpOccupationId: admissionItem.rpOccupationId,
            rpRelationId: admissionItem.rpRelationId,
            motherIpNo: admissionItem.motherIPNo,
            motherName: admissionItem.motherName,
            mlcNo: admissionItem.mlcNo,
            mlcType: admissionItem.mlcType,
            mlcNm: admissionItem.mlcNm,
            clinicalNotes: admissionItem.clinicalNotes,
            currentBed: admissionItem.currentBed,
            currentUnit: admissionItem.currentUnit,
            isNewBornBaby: admissionItem.isNewBornBaby,
            isMLC: admissionItem.isMLC,
            babyTypeId: admissionItem.bornTypeId,
            admissionTypeId: admissionItem.admissionTypeId,
            admissionVisitTypeId: admissionItem.admissionVisitTypeId,
            billingComments: admissionItem.billingComments,
            chargeModuleTemplateId: admissionItem.chargeModuleTemplateId,

            //breakfast: admissionItem.breakfast,
            //lunch: admissionItem.lunch,
            //dinner: admissionItem.dinner
            patientInsuranceId: admissionItem.patientInsuranceId,
            insuranceTypeId: admissionItem.insuranceTypeId,
            validity: this.formatValidityDate(admissionItem.validity),
            cardNo: admissionItem.cardNo,
            mlcDetailsId: admissionItem.mlcDetailsId
        });
        
        if (admissionItem.internalProviderId) {
            this.bookAdmissionForm.get('referralDoctorName').setValue(admissionItem.internalDoctor);
        }
        else {
            this.bookAdmissionForm.get('referralDoctorName').setValue(admissionItem.externalDoctor);
        }

        
        const bedManagementInfo = new BedManagement;
        bedManagementInfo.wardId = admissionItem.wardId;
        bedManagementInfo.wardName = admissionItem.wardName;
        bedManagementInfo.roomId = admissionItem.roomId;
        bedManagementInfo.roomName = admissionItem.roomName;
        bedManagementInfo.bedId = admissionItem.bedId;
        bedManagementInfo.bedNumber = admissionItem.bedNumber;

        this.selectedBed = new BedManagement;
        this.selectedRoom = new BedManagement;
        this.selectedWard = new BedManagement;
        this.selectedBedManagement = new BedManagement;

        this.selectedBedManagement = bedManagementInfo;
        this.selectedBed = bedManagementInfo;
        this.selectedRoom = bedManagementInfo;
        this.selectedWard = bedManagementInfo;

        if (admissionItem) {
            this.bookAdmissionForm.controls['payType'].disable();
                this.bookAdmissionForm.controls['insuranceCompanyId'].disable();
                this.bookAdmissionForm.controls['tpaId'].disable();
                this.bookAdmissionForm.controls['insuranceTypeId'].disable();
                this.bookAdmissionForm.controls['chargeModuleTemplateId'].disable();
                this.bookAdmissionForm.controls['cardNo'].disable();
                this.bookAdmissionForm.controls['validity'].disable();
            }           

        if (admissionItem.payType)
            this.fetchPackages(admissionItem.payType, admissionItem.chargeModuleTemplateId);

    }
    formatValidityDate(validity: Date | string): string {
        if (validity instanceof Date) {
            return validity.toISOString().split('T')[0];
        } else if (typeof validity === 'string') {
            return validity.split('T')[0];
        } else {
            return null;
        }
    }
    handleChange(event) {
        this.isCouncelled = event;
    }
    get forms() {
        return {
            bookAdmission: this.bookAdmissionForm ? this.bookAdmissionForm.controls : {},
            bedRequestChange: this.raiseBedChangeRequestForm ? this.raiseBedChangeRequestForm.controls : {},
            doctorUnitChange: this.raiseDoctorUnitRequestForm ? this.raiseDoctorUnitRequestForm.controls : {},
        }
    }
    hideTimeline() { sessionStorage.setItem("istimeline", "false") }
    private fetch(ids: Array<number>) {
        this.httpService
            .post(ApiResources.getURI(ApiResources.serviceOrder.base, ApiResources.serviceOrder.fetchAdmission), { admissionIds: ids })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: any) => {
                    (response as Array<any>).forEach(item => {
                        const found = this.admissions.find(x => x.admissionId === item.admissionId);
                        //if (found.length > 1) {
                        //    this.checkLength = found.length;
                        //}
                        if (found) {
                            found.serviceOrder = item.count;
                        }
                    });
                },
            );
    }


    private fetchAdmissions() {
        this.loadingAdmissions = true;
        const request = Object.assign(UtilHelper.clone(this.filters.options), UtilHelper.clone(this.pagination));
        request.locationId = this.page.userAccount.locationId;
        request.isFromPatientProfile = !this.isPatientFilters ? true : false;
        //if (this.resultsType === "today") {
        //    request["fromDate"] = moment().format("YYYY-MM-DD");
        //    request["toDate"] = moment().format("YYYY-MM-DD");
        //}
        if (this.urlPatientId && this.urlPatientId > 0) {
            request.patientId = this.urlPatientId;
            request.isDischarged = null;
        }

        if (this.page.userAccount.roleId === 3) {
            request.providerId = this.page.userAccount.referenceId;
        }

        this.httpService
            .post<Array<Admission>>(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingAdmissions = false))
            .subscribe(
                (response: Array<Admission>) => {
                    this.admissions = response;
                    this.admissions.forEach((item) => {
                        if (UtilHelper.isEmpty(item.patientThumbnailUrl)) {
                            item.patientThumbnailUrl = `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${item.patientThumbnailUrl}`;
                        }
                        if (UtilHelper.isEmpty(item.providerThumbnailUrl)) {
                            item.providerThumbnailUrl = `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${item.providerThumbnailUrl}`;
                        }
                    });
                    this.fetchMedicationInfo(response.map(x => x.admissionId));
                    this.fetch(response.map(x => x.admissionId));
                    this.admissions.forEach((item: Admission) => {
                        this.admissions = response;
                        if (item.isDischarged) {
                            item.dischargeTimeString = item.dischargeTime.toString();
                            item.dischargeDate =
                                new Date(item.dischargeDate.toString().replace("T00:00:00", item.dischargeTimeString));
                        }

                        if (item.expectedDischargeDate) {
                            item.expectedDischargeDateView = this.datePipe.transform(new Date(item.expectedDischargeDate), 'yyyy-MM-dd');
                        }
                        UtilHelper.applyPagination(this.admissions, this.pagination);
                    });

                },
                () => {
                    this.admissions = [];
                }
            );
    }

    private fetchMedicationInfo(ids: Array<number>) {
        this.httpService
            .post(ApiResources.getURI(ApiResources.progressReport.base, ApiResources.progressReport.fetchMedicationInfo),
                { admissionIds: ids })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.isLoadingMedicationInfo = false))
            .subscribe(
                (response?: GenericResponse) => {
                    if (response.data) {
                        (response.data as Array<MedicationInfo>).forEach(item => {
                            const found = this.admissions.find(x => x.admissionId === item.admissionId);
                            found.medicationInfo = item;
                        });
                    }

                },
                () => { }
            );
    }


    private findPatient(patientId?: number, encryptedPatientId?: string) {

        this.patient = undefined;
        // const patientDetails = this.patients.find(m => m.id === patientId);

        const request = {
            patientId: patientId,
            encryptedPatientId: encryptedPatientId 
        }
        this.httpService
            .post<Patient>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.find), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Patient) => {
                    this.patient = response;
                    this.selectedPatient = response;
                    if (this.patient.relativeOccupation != null) {
                        this.relativeOcuupationId = this.occupation.find(x => x.value.toLowerCase().trim() === this.patient.relativeOccupation);
                    }
                    this.billNotificationService.get(patientId);
                    const dateOfBirth = DateHelper.toNgbDateStruct(String(this.patient.dateOfBirth));
                    if (dateOfBirth != null) {
                        const mdob = moment(String(this.patient.dateOfBirth), "YYYY-MM-DD");
                        const age = moment().diff(mdob, "months");
                        const yrs = (age / 12).toFixed(1);
                        this.yearsfordisplay = parseInt(yrs, 10);

                        const months = age % 12;
                        const days = moment().diff(mdob.add(yrs, 'years'), 'days', false);
                        this.yearsfordisplay = days;
                        this.yearsfordisplay = parseInt(yrs);
                        this.monthsfordisplay = months;
                        const date1 = moment(String(this.patient.dateOfBirth), "YYYY-MM-DD");
                        const monthsa = moment().diff(date1, 'months');
                        date1.add(monthsa, 'months');
                        const daysa = moment().diff(date1, 'days');
                        monthsa + ' ' + 'Month(s)' + ' ' + daysa + " Day(s)"
                        this.daysfordisplay = daysa;
                        this.patient.age = this.patient.dateOfBirth ? this.yearsfordisplay + 'Y ' + this.monthsfordisplay + 'M ' + this.daysfordisplay + 'D' : null;
                    }
                    if (this.fromOp === "fromAppointments") {
                        this.bookAdmissionForm.patchValue({
                            providerName: this.patient.providerName,
                            providerId: this.patient.providerId,
                            departmentId: this.patient.departmentId,
                            patientName: this.patient.fullName
                        })
                    }
                    if (this.bookAdmissionForm.value["admissionId"] === 0 && this.patient && this.patient.admissionId !== null && this.patient.isDischarged === false) {
                        this.bookAdmissionForm.controls.patientId.setErrors({ 'incorrect': true });
                        //this.notifyService.warning("This Patient already has admission & not discharged!");
                    }
                    if (this.bookAdmissionForm.get("patientId").value === null)
                        this.bookAdmissionForm.get("patientId").setValue(this.selectedPatient.patientId);
                        
                    this.bookAdmissionForm.patchValue({
                        breakfast: this.datePipe.transform(new Date(0, 0, 0, this.patient.breakfastTime.hour, this.patient.breakfastTime.minute), 'HH:mm'),
                        lunch: this.datePipe.transform(new Date(0, 0, 0, this.patient.lunchTime.hour, this.patient.lunchTime.minute), 'HH:mm'),
                        dinner: this.datePipe.transform(new Date(0, 0, 0, this.patient.dinnerTime.hour, this.patient.dinnerTime.minute), 'HH:mm')
                    })

                },
                //this.bookAdmissionForm.get("patientId").reset();
                () => {
                    this.patient = null;
                }
            );
    }

    private buildPatientInsuranceForm() {
        this.patientForm = this.formBuilder.group({
            patientInsuranceId: [null],
            patientId: [null],
            insuranceTypeId: [null, [Validators.required]],
            insuranceCompanyId: [null, [Validators.required]],
            validity: [null, [Validators.required]],
            cardNo: [null],
            createdBy: [null]
        });
    }
    onOpeninsurance() {
        if (this.patientForm.valid) {
            const insurance = this.patientForm.getRawValue();
            this.patientinsurance = insurance;
            this.isPatient = false
        }
    }
    private fetchInsurances() {
        this.loadingInsurances = true;
        this.resourceService.insuranceCompanies()
            .pipe(finalize(() => { this.loadingInsurances = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.insurances = response;
                if (this.insurances.length > 0 && this.locationChargeTemplates && this.locationChargeTemplates.length > 0) {
                    this.insurances = this.insurances.filter((obj2) =>
                        this.locationChargeTemplates.some((obj1) => obj1.optionalData === obj2.id)
                    );
                }

            });
    }
    private fetchChargeCategory() {
        this.loadingchargeCategory = true;
        this.resourceService.chargeCategory()
            .pipe(finalize(() => { this.loadingchargeCategory = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.chargeCategory = response;
            });
    }

    onChangeCharge(record: Array<IResource>, event?: any) {
        const value = (event.target as HTMLSelectElement).value;
        const [id, nullable] = value.split(':').map(part => part.trim());
        const charge = this.bookAdmissionForm.get("chargeCategoryId").value;
        this.bookAdmissionForm.patchValue({
            chargeBillingCategoryId: charge,
        })
        const selectedCharge = record.find(x => x.id == charge);
        this.chargeCategoryId = charge
        if (charge != 0 || charge != undefined || charge == null) {
            if (!this.bookAdmissionForm.value) {
                return this.notifyService.warningToast("Please select Charge Template");
            }
            const chargeCategoryId = nullable === 'null' ? null : Number(id);
            if (chargeCategoryId === null) {
                this.fetchBeds(null, false);
            }
            else {
                this.fetchBeds(null, true);
            }
            // this.fetchRooms(null, charge);
        }
        else {
            this.fetchWards();
        }
    }

    onChangeChargeBilling(record: Array<IResource>) {
        const charge = this.bookAdmissionForm.get("chargeBillingCategoryId").value;
        const selectedCharge = record.find(x => x.id == charge);
        const selectedChargeCategoryId = this.beds.find(x => x.chargeCategoryId == charge)
        this.chargeBillingCategoryId = charge
        this.selectedBed = selectedChargeCategoryId;
        this.selectedBedManagement = selectedChargeCategoryId;
        this.selectedBedManagement.wardId = selectedChargeCategoryId.wardId;
        this.selectedBedManagement.wardName = selectedChargeCategoryId.wardName;
        this.selectedBedManagement.roomId = selectedChargeCategoryId.roomId;
        this.selectedBedManagement.roomName = selectedChargeCategoryId.roomName;
        this.selectedBedManagement.roomRent = selectedChargeCategoryId.roomRent;
        this.bookAdmissionForm.patchValue({
            bedId: selectedChargeCategoryId.bedId
        });
    }

    wardsData() {
        this.fetchWards();
    }
    onChange(value: any, index: number) {
        this.information = this.insurances.filter(x => x.id == value)
        this.bookAdmissionForm.patchValue({
            tpaId: this.information[0]?.tpaId,
            chargeModuleTemplateId: this.information[0]?.chargeModuleTemplateId
        })

        this.bookAdmissionForm.patchValue({
            bedId: null,
            chargeCategoryId: null,
            chargeBillingCategoryId: null,
        });
        this.fetchChargeCategoriesWithBeds();
    }
    get insuranceFormArray() { return this.patientForm.controls["insurances"] as FormArray }

    buildInsurancesForm() {
        this.patientForm = this.formBuilder.group({
            insurances: this.formBuilder.array([])
        });
        this.onaddpatientInsurence();
    }

    get insuranceForm() {
        return this.insuranceFormArray;
    }
    onaddpatientInsurence() {
        this.insuranceFormArray.push(this.buildPatientInsuranceForm())
    }
    onAddInsurance() {
        const formArray = this.insuranceFormArray as FormArray;
        if (formArray.length <= 3) {
            /*const company = this.insurances.find(m => Number(m.optionalText) == Number(this.insuranceForm.get('insuranceCompanyId').value));*/
            this.insuranceFormArray.push(this.buildPatientInsuranceForm());
        }
    }

    onRemoveInsurance(index: number) {
        this.insuranceFormArray.removeAt(index);
    }

    fetchInsuranceApprovals(admissionId: number) {
        this.loading = true;

        this.httpService.post(ApiResources.getURI(ApiResources.insuranceAdmission.base, ApiResources.insuranceAdmission.fetchInsuranceApprovals), { admissionId: admissionId, appointmentId: null })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loading = false }))
            .subscribe((response: Array<InsuranceAdmission>) => {
                if (response != null) {
                    this.insuranceApprovals = response;
                } else {
                    this.insuranceApprovals = new Array<InsuranceAdmission>();
                }
            }, () => {
                this.notifyService.defaultErrorToast();
            });
    }

    onOpenInsuranceModel(template: TemplateRef<any>, admission: Admission) {
        this.tempTemplate = template;
        this.selectedAdmission = admission;
        this.buildPatientInsuranceForm();
        this.fetchPatientInsurances(this.selectedAdmission.admissionId);
    }

    onOpen(template: TemplateRef<any>) {
        this.modalRef = this.modalService.open(template, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "lg",
            windowClass: "custom-modal effect-scale"
        });
    }

    getMinDate() {
        const date = this.filters.options.fromDate.split("-");
        const year = Number(date[0]);
        const month = Number(date[1]);
        const day = Number(date[2]);
        this.setMinDate = new NgbDate(year, month, day);
    }

    getMaxDate() {
        const date = this.filters.options.toDate.split("-");
        const year = Number(date[0]);
        const month = Number(date[1]);
        const day = Number(date[2]);
        this.setMaxDate = new NgbDate(year, month, day);
    }

    private getSettingsAdmissionCancel() {
        this.loadingSettings = true;
        this.httpService.get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), { name: "Admission to cancel in minutes" })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingSettings = false))
            .subscribe((response: Array<Setting>) => {

                if (response.length > 0) {
                    this.isAdmissionCancel = response[0].active;
                    this.admissionCancelMinutes = +response[0].value;
                }
            });
    }

    private getSettingsAdmissionEdit() {
        this.loadingSettings = true;
        this.httpService.get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), { name: "Admission to edit in minutes" })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingSettings = false))
            .subscribe((response: Array<Setting>) => {

                if (response.length > 0) {
                    this.isAdmissionEdit = response[0].active;
                    this.admissionEditMinutes = +response[0].value;
                }
            });
    }

    private fetchDepartments(providerId?: number, callback?: Function) {
        this.loadingDepartments = true;
        this.resourceService.departments(providerId)
            .pipe(finalize(() => { this.loadingDepartments = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.departments = response;
                this.departmentId = this.departments && this.departments.length ? this.departments[0].id : null;
                callback && callback();
                //this.bookAdmissionForm.patchValue({
                //    departmentId: this.departmentId
                //})
            });
    }

    fetchPriorities = () => {
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchPriorities), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingWards = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (GenericStatus[GenericStatus.Success] === response.status) {
                        this.priorities = response.data as Array<Priority>;
                    } else {
                        this.notifyService.defaultError();
                    }
                },
                () => {
                    this.priorities = [];
                }
            );
    }

    customSearchPatient(term: string, item) {
        return item['FullName'].indexOf(term) > -1 || item['counsellingNo'].indexOf(term) > -1;
    }
    private fetchCounselling() {
        this.counsellingCodeWithNames = this.counsellingInput.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap((term: string) =>
                term && term.length >= 2
                    ? this.resourceService.counsellingNumbers(term).pipe(
                        tap(() => this.loadingCounsellings = true),
                        catchError(() => { return of([]) }),
                        finalize(() => this.loadingCounsellings = false)
                    )
                    : of([])
            )
        );
    }


    fetchCounsellingCodesSpecific() {
        this.loading = true;
        var request = {

            type: "IP"
        }

        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.counselling.base, ApiResources.counselling.fetchBasics), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingCounsellings = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (GenericStatus[GenericStatus.Success] === response.status) {
                        if (response.data.length > 0) {
                            this.counsellingDetails = response.data as Array<ICounsellingDetails>;
                            this.counsellingFilter = response.data.map(x => x.counsellingNo);
                        }
                    } else {
                        this.notifyService.defaultError();
                    }
                },
                () => {
                    this.counsellingDetails = [];
                }
            );
    }

    fetchRelationShips() {
        this.loadingRelationShips = true;
        this.resourceService.relationships()
            .pipe(finalize(() => { this.loadingRelationShips = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.relationships = response;
                this.relationshipsId = this.relationships.find(x => x.value.toLowerCase().trim() === "Husband".toLowerCase().trim());
            });
    }
    private fetchOccupation() {
        this.loadingOccupation = true;
        this.resourceService.occupation()
            .pipe(finalize(() => { this.loadingOccupation = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.occupation = response;
            });
    }
    private fetchLookUpValue() {
        this.loadingBabyTypes = true;
        this.resourceService.lookUpValueOnLookUpName("New Born Baby")
            .pipe(finalize(() => { this.loadingBabyTypes = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (response: Array<IResource>) => {
                    this.babyTypes = response;
                },
                error: (err: any) => {
                    this.babyTypes = [];
                }
            });
    }
    private fetchAdmTypesLookUpValue() {
        this.loadingAdmissionTypes = true;
        this.resourceService.lookUpValueOnLookUpName("AdmissionType")
            .pipe(finalize(() => { this.loadingAdmissionTypes = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (response: Array<IResource>) => {
                    this.admissionTypes = response;
                    this.admissionType = this.admissionTypes.find(x => x.value.toLowerCase().trim() === "elective".toLowerCase().trim())

                },
                error: (err: any) => {
                    this.admissionTypes = [];
                }
            });
    }
    private fetchAdmVisitTypesLookUpValue() {
        this.loadingAdmissionVisitTypes = true;
        this.resourceService.lookUpValueOnLookUpName("Admission Visit Type")
            .pipe(finalize(() => { this.loadingAdmissionVisitTypes = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (response: Array<IResource>) => {
                    this.admissionVisitTypes = response;
                },
                error: (err: any) => {
                    this.admissionVisitTypes = [];
                }
            });
    } changeAdmissionFor(patientFamilyId?: number) {
        const selectedFamilyMember = this.familyMembers.find(m => m.patientFamilyId === patientFamilyId);
        this.patient.age = selectedFamilyMember.age;
        this.patient.gender = selectedFamilyMember.relativeGender;
        this.patient.relationWithPatient = selectedFamilyMember.relation;
    }

    onChangeResultsType(type: string) {
        this.resultsType = type;
        this.initPagination();
        this.fetchAdmissions();
    }

    private fetchProvidersFilter() {
        this.provider = this.providerInput.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap((term: string) => {
                const doctorType = "IP";
                return term && term.length >= 2
                    ? this.resourceService.provider(term, doctorType).pipe(
                        tap(() => this.loadingProviders = true),
                        catchError(() => { return of([]) }),
                        finalize(() => this.loadingProviders = false)
                    )
                    : of([])
            })
        );
    }

    private fetchAdmissionPatients() {
        this.loadingPatients = true;
        this.resourceService.admissionActivePatient()
            .pipe(finalize(() => { this.loadingPatients = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.admissionPatients = response;
            });
    }
    onSelectMother(value: any) {
        this.bookAdmissionForm.patchValue({
            motherIpNo: value.id,
            motherName: value.name,
            currentBed: value.optionalText3
        })
    }

    private replaceLastLetter(originalString: string, newLetter: string): string {
        // Check if the string is not empty
        if (originalString.length === 0) {
            return originalString; // Return the original string if it's empty
        }
        // Ensure the new letter is a single character
        if (newLetter.length !== 3) {
            throw new Error("New letter must be a double character");
        }
        // Get the part of the string except the last character
        const substring = originalString.slice(0, -1);
        // Append the new letter
        this.encryptedPatientId = substring + newLetter;
    }
    fetchPatients(patientId?: number) {
        this.loadingPatients = true;
        this.resourceService.patientsAll(patientId)
            .pipe(finalize(() => { this.loadingPatients = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.patients = response;
                if (this.patients.length > 0) {

                    //this.decryptedPatientId = this.encryptedPatientId && this.encryptedPatientId != null ? this.patients.find(d => d.encryptedId === this.encryptedPatientId)?.id : undefined;
                    if (this.fromOp === "fromScheduledAdmission") {
                        this.fetchAdmissinDetail(this.appointmentId);
                    }
                }
            });
    }


    fetchWards() {
        this.loadingWards = true;

        this.showWards = true;
        this.showRooms = false;
        this.showBeds = false;

        this.selectedWard = undefined;
        this.selectedRoom = undefined;
        this.selectedBed = undefined;

        this.httpService
            .post<Array<BedManagement>>(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchWards), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingWards = false))
            .subscribe(
                (response: Array<BedManagement>) => {
                    this.wards = response;
                },
                () => {
                    this.wards = [];
                }
            );
    }

    fetchRooms(wardId) {
        this.showRooms = true;
        this.showWards = false;
        this.loadingRooms = true;
        this.httpService
            .post<Array<BedManagement>>(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchRooms), { wardId: wardId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingRooms = false))
            .subscribe(
                (response: Array<BedManagement>) => {
                    this.rooms = response;
                },
                () => {
                    this.rooms = [];
                }
            );
    }

    fetchBeds(callback?: Function, value?: boolean) {
        if (!this.bookAdmissionForm.value.chargeModuleTemplateId) {
            return;
        }
        this.showBeds = true;
        this.loadingBeds = true;
        this.selectedBed = new BedManagement;
        const request = {
            chargeCategoryId: this.chargeCategoryId,
            bedStatusId: 1,
            chargeModuleTemplateId: this.bookAdmissionForm.value.chargeModuleTemplateId
        };
        this.httpService
            .post<Array<BedManagement>>(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchBeds), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingBeds = false))
            .subscribe(
                (response: Array<BedManagement>) => {
                    this.beds = response;
                    if (callback)
                        callback();
                    else {
                        const bed = this.beds.find(x => x.bedStatusName === 'Available');
                        if (value === false) {
                            this.selectedBedManagement.bedId = null;
                            this.bookAdmissionForm.patchValue({
                                bedId: null,
                                chargeCategoryId: null,
                                chargeBillingCategoryId: null,
                            }, { emitEvent: false });
                        }
                        else {
                            if (bed) {
                                this.selectBed(bed);
                            }
                        }
                    }
                },
                () => {
                    this.beds = [];
                }
            );
    }

    openWard(ward: BedManagement) {
        this.showWards = false;
        this.selectedWard = ward;

        this.showBeds = false;
        this.selectedBed = undefined;
        this.fetchRooms(ward.wardId);
        //this.showWards = false;
        //this.selectedWard = ward;

        //this.showBeds = false;
        //if (ward === undefined) {
        //    this.fetchWards();
        //    this.selectedWard = ward;
        //}
        //else {
        //    this.fetchRooms(ward.wardId);
        //}
        //this.selectedBed = undefined;
    }

    openRoom(room: BedManagement) {
        this.showRooms = false;
        this.selectedRoom = room;
        //this.fetchBeds(room.roomId);
    }

    bookBed(bed: BedManagement) {

        if (bed.bedStatusName === 'Available') {
            this.selectedBedManagement = new BedManagement();
            this.selectedBed = bed;
            this.selectedBedManagement = bed;
            this.selectedBedManagement.wardId = bed.wardId;
            this.selectedBedManagement.wardName = bed.wardName;
            this.selectedBedManagement.roomId = bed.roomId;
            this.selectedBedManagement.roomName = bed.roomName;
            this.selectedBedManagement.roomRent = bed.roomRent;
            this.bookAdmissionForm.patchValue({
                bedId: bed.bedId
            });
        }
    }

    selectBed(bed: BedManagement) {
        this.selectedBed = bed;
        this.selectedBedManagement = bed;
        this.selectedBedManagement.wardId = bed.wardId;
        this.selectedBedManagement.wardName = bed.wardName;
        this.selectedBedManagement.roomId = bed.roomId;
        this.selectedBedManagement.roomName = bed.roomName;
        this.selectedBedManagement.roomRent = bed.roomRent;
        this.bookAdmissionForm.patchValue({
            bedId: bed.bedId,
            chargeCategoryId: bed.chargeCategoryId,
            chargeBillingCategoryId: bed.chargeCategoryId,
        }, { emitEvent: false });
    }

    onNextPage() {
        $("body,html").animate({ scrollTop: 0 });
        this.fetchAdmissions();
    }

    onApplyFilters() {
        this.filters.applied = UtilHelper.isFiltersApplied(this.filters.options);
        this.initPagination();
        this.resultsType = "all";
        this.loading = true;
        this.fetchAdmissions();

        this.onCloseFilters();
    }

    onResetFilters() {
        this.filters.init();

        this.loading = true;
        this.fetchAdmissions();
        this.onCloseFilters();
    }

    onShowFilters() {
        this.filters.options.dischargeDate = null;
        $("body").addClass("right-bar-enabled");
    }

    onCloseFilters() {
        $("body").removeClass("right-bar-enabled");
    }

    onEncounter(encryptedAdmissionId: string) {
        this.router.navigate(["/app/encounter", encryptedAdmissionId]);
    }

    onClickAddedEncounter(item: Admission) {
        this.router.navigate(['app', item.routingValue, item.encryptedAdmissionId, 'a']);
    }
   
    onEncounterPrescription(item: Admission) {
      
        if (item) {

            this.router.navigate([`app/` + item.routingValue, item.encryptedAdmissionId,  'a' , 'reports', 'N']);
        }
    }
    onViewPatientProfile(encryptedPatientId: string) {
        this.router.navigateByUrl(`app/patient/${encryptedPatientId}`);
    }

    fetchVisitTypeIp = () => {
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.providerLocations.base, ApiResources.providerLocations.fetchVisitTypeIp), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.isVisitTypeloading = false))
            .subscribe(
                (response: GenericResponse) => {
                    this.visitors = response.data as Array<LookUpModel>;
                    // var findInPatient = this.visitors.find(x => x.name === 'IN PATIENT');
                    const findInPatient = this.visitors.find(m => m.name.toLowerCase().trim() === "In Patient".toLowerCase().trim());
                    //if (findInPatient) {
                    //    setTimeout(() => {
                    //        this.bookAdmissionForm.patchValue({ visitTypeId: findInPatient.id });
                    //    }, 1000);
                    //}
                    if (findInPatient != undefined) {
                        this.bookAdmissionForm.patchValue({
                            visitTypeId: findInPatient.id
                        });
                    }
                },
                () => {
                    this.visitors = new Array<LookUpModel>();
                }
            );
    }

    //onChangeProvider(event: IResource) {
    //    this.providerId = event.id;
    //    //this.fetchProviderLocations(true);
    //    this.fetchDepartments(this.providerId);
    //}
    private fetchDoctorUnits(locationId: number) {

        this.loadingDoctorUnits = true;
        const request = {
            locationId: locationId
        };
        this.httpService.post(ApiResources.getURI(ApiResources.doctorUnit.base, ApiResources.doctorUnit.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingDoctorUnits = false))
            .subscribe((response: Array<DoctorUnits>) => {
                this.DoctorUnits = response;
                (error: HttpErrorResponse) => {
                    const errorMessage = UtilHelper.handleError(error);
                    if (errorMessage) {
                        this.notifyService.warningToast(errorMessage);
                    } else {
                        this.notifyService.defaultError();
                    }
                }
            });

    }
    onOpenBookAdmissionModel(admissionItem?: Admission, callback?: Function) {
        this.editBedStatus = true
        if (admissionItem && admissionItem.admissionId) {
            this.editBedStatus = false
        }

        this.bedselect = null;
        this.patient = null;
        if (admissionItem && this.isAdmissionEdit) {
            const adjustedDatTime = new Date(new Date(admissionItem.admissionDate).getTime() + this.admissionEditMinutes * 60000);
            var isAllowed = this.menuservice.isMenuButtonAllowed("admission_edit_access");
            if ((adjustedDatTime < new Date()) || !isAllowed) {
                return this.notifyService.warningToast('Cannot Modify Admission, you crossed beyond time limit');
            }
        }
        if (admissionItem != undefined && admissionItem != null) {
            this.chargeCategoryId = admissionItem.chargeCategoryId;

        }
        this.fetchVisitTypeIp();
        this.fetchTPAOrinizations();
        this.selectedBedManagement = new BedManagement;
        this.fetchDoctorSpecialization(null, this.page.userAccount.locationId);
        this.fetchDepartments();
        this.fetchReferralDoctor();
        this.fetchChargeCategory();
        this.fetchBeds();
        if (admissionItem?.patientId) {
            this.fetchPatients(admissionItem?.patientId);
        }
        this.fetchCategories();
        if (admissionItem && admissionItem.admissionId > 0) {
            this.bedselect = admissionItem
        }
        if (admissionItem) {           
            this.buildUpdateBookAdmissionForm(admissionItem);


            this.bookAdmissionForm.patchValue({
                chargeCategoryId: admissionItem.chargeCategoryId,
                chargeBillingCategoryId: admissionItem.chargeCategoryId,
                selectedBedManagement: admissionItem.bedId
            })         
        } else {
            this.buildBookAdmissionForm();
        }
        if (this.isConvertedFromOPtoIp) {
            this.bookAdmissionForm.patchValue({
                admissionTypeId: this.admissionType?.id
            });
        }
        if (this.currentPageView !== PageView.AddView) {
            this.currentPageView = PageView.AddView;
            if (admissionItem == null || admissionItem == undefined) {
                this.bookAdmissionForm.patchValue({
                    payType: "C"
                });
            }
        }
    }

    onOpenViewAdmissionModel(content: TemplateRef<any>, admission: Admission) {
        this.selectedAdmission = admission;
        this.providerId = admission.providerId;

        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            windowClass: "custom-modal invoice-modal effect-scale"
        });
    }

    onOpenIndentModel(content: TemplateRef<any>, admission: Admission) {
        this.selectedAdmission = admission;

        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: "xxl",
            windowClass: "custom-modal extra-large-modal effect-scale"
        });
    }

    onGenerateBarCodeModel(content: TemplateRef<any>, admission: Admission) {
        this.selectedAdmission = admission;

        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            //size: "md",
            windowClass: "custom-modal effect-scale"
        });
    }

    private fetchPackages(payType: string, chargeModuleTemplateId?: number, callback?: Function): void {
        if (this.showPackageBlock) {
            this.loadingPackages = true;
            this.resourceService.packageModules(this.page.userAccount.locationId, "I", payType, chargeModuleTemplateId)
                .pipe(finalize(() => { this.loadingPackages = false }))
                .pipe(takeUntil(this.page.unSubscribe))
                .subscribe((response: Array<IResource>) => {
                    this.packages = response;
                    callback && callback();
                });
        }
    }

    private fetchPatientInsurances(admissionId: number): void {


        this.httpService.post(ApiResources.getURI(ApiResources.patientInsurance.base, ApiResources.patientInsurance.fetch), admissionId)
            .pipe(finalize(() => { this.loadingCompanies = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.insuranceCompanies = response;

                if (this.insuranceCompanies.length > 0) {
                    var insurence = this.insurances.find(x => x.id == this.insuranceCompanies[0].insuranceCompanyId);
                    this.bookAdmissionForm.patchValue({
                        insuranceCompanyId: insurence.id,

                    });
                }

            });
    }

    onSubmit() {
        this.submitted = true;
        if (!this.patientinsurance) {
            return;
        }

        const request = Object.assign(UtilHelper.clone(this.patientinsurance));

        this.submitting = true;
        this.httpService.post(ApiResources.getURI(ApiResources.patientInsurance.base, ApiResources.patientInsurance.add), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.submitting = false;
                this.submitted = false;
            }))
            .subscribe((response: string) => {
                this.notifyService.successToast(response);
                this.onCloseDocumentModel();
            }, (error: HttpErrorResponse) => {
                const errorMessage = UtilHelper.handleError(error);
                if (errorMessage) {
                    this.notifyService.warningToast(errorMessage);
                } else {
                    this.notifyService.defaultErrorToast();
                }
            });
    }

    onCloseUrgentModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;

        } catch (e) {
            // ignored;
        }
        this.bookAdmissionForm.patchValue({
            isUrgentAdmission: false,
            counsellingCode: null,
        }, { emitEvent: false });
    }

    sendWhatsAppMessage() {

        var body = {
            patientMobile: this.patientDetails.optionalText2,
            patientName: this.patientDetails.value,
            practiceName: this.page.userAccount.practiceName,
            bedNumber: `${this.selectedBed.bedNumber},Floor: ${this.selectedBed.floorName},Ward: ${this.selectedBed.wardName},RoomName: ${this.selectedBed.roomName}`,
            floorName: this.selectedBed.floorName,
            wardNumber: this.selectedBed.wardName,
            roomName: this.selectedBed.roomName,
            mrNo: this.patient.umrNo,
            providerName: this.selectedDoctorUnit.tagDoctor[0].providerName,
            providerMobile: this.selectedDoctorUnit.tagDoctor[0].providerMobile == null ? null : this.selectedDoctorUnit.tagDoctor[0].providerMobile,
            contactNumber: this.page.userAccount.contactNumber
        }

        const settings = JSON.parse(localStorage.getItem("settings"));
        var url = settings.whatsAppService.base
        this.httpService.post(url + ApiResources.whatsAppService.ipPatient, Object.assign(body))
            .subscribe(
                (respones: any) => {
                    if (body.providerMobile == null)
                        this.notifyService.successToast("message only sent to patient;invalid doctor number");
                    else
                        this.notifyService.successToast("message sent to patient")
                },
                (error: any) => {
                    console.log("The error is :" + error)
                    this.notifyService.errorToast("Sorry message not sent to patient ");
                }
            )

    }

    onCloseModal() {
        try {
            this.patientCounselling = [];
            this.bookAdmissionForm.reset();
            if (this.isConvertedFromOPtoIp)
                this.router.navigateByUrl(`/app/out-patients`);
            this.modalRef.close();
            this.modalRef = undefined;
            this.patientDOB = null;
            
        } catch (e) {
            // ignored;
        }

        this.currentPageView = PageView.MainView;
        this.submitting = undefined;
        this.submitted = undefined;
        this.selectedAdmission = undefined;
        this.loadingSlots = undefined;
        this.admissionId = undefined;
        this.reason = null;
        this.showWards = false;
        this.loadingWards = false;
        this.wards = undefined;
        this.showRooms = false;
        this.loadingRooms = false;
        this.rooms = undefined;
        this.selectedWard = undefined;
        this.selectedRoom = undefined;
        this.selectedBedManagement = new BedManagement;
        this.finalAmount = 0;
        this.admissionNo = 0;
        this.umrNo = null;
        this.patient = new Patient;
        this.departments = undefined;
        this.providers = undefined;
        this.showBeds = false;
        this.selectedCounsellingId = undefined;
        this.selectedCounsellingDetails = null;

    }
    changeOPpatientRoutingurl() {
        if (this.isConvertedFromOPtoIp) {
            this.isConvertedFromOPtoIp = false;
            this.router.navigateByUrl("/app/admissions/view");
        }
    }

    onBookAdmission() {
        this.submitted = true;
        this.bookAdmissionForm.controls.admissionDate.setErrors(null);

        //if (this.bookAdmissionForm.value["admissionId"] === 0 && this.patient && this.patient.admissionId !== null && this.patient.isDischarged === false) {
        //    this.bookAdmissionForm.controls.patientId.setErrors({ 'incorrect': true });
        //    this.notifyService.warning("This Patient already has admission & not discharged!");
        //}
        //this.bookAdmissionForm.controls.bedId.setErrors(null);
        this.bookAdmissionForm.get("createdBy").setValue(this.page.userAccount.accountId);
        if (!this.bookAdmissionForm.valid) {
            return;
        }
        if (this.files && this.files.length > 0) {
            if (!UtilHelper.isEmpty(this.bookAdmissionForm.get("documentName").value)) {
                this.notifyService.warning("Please enter document name");
                return;
            }
            if (this.bookAdmissionForm.get("documentType").value === null) {
                this.notifyService.warning("Please enter document type");
                return;
            }
        }
        const model = UtilHelper.clone(this.bookAdmissionForm.value);
        //if (model["chargeBillingCategoryId"] === null) {
        //    this.notifyService.warningToast("Please select Billing Charge Category")
        //}
        this.submitting = true;



        model["locationId"] = this.page.userAccount.locationId;
        model["breakfastStr"] = model.breakfast;
        model["lunchStr"] = model.lunch;
        model["dinnerStr"] = model.dinner;
        model["emergencyInfo"] = JSON.stringify(model.emergencyInfo);
        model["surrogacyData"] = JSON.stringify(model.surrogacyData);
        model["nriData"] = JSON.stringify(model.nriData);
        model["bedAssociatedData"] = JSON.stringify(model.bedAssociatedData);
        if (model["chargeBillingCategoryId"] != null) {
            model["chargeCategoryId"] = model["chargeBillingCategoryId"];
        }
        model.breakfast = null;
        model.lunch = null;
        model.dinner = null;
        // ReSharper disable once TsResolvedFromInaccessibleModule
        model.admissionDate = this.bookAdmissionForm.value.admissionDate.replace(":00.000", "");
        //model.isConvertedFromOp = this.appointmentNo;
        const time = this.bookAdmissionForm.value.admissionDate.split('T');
        model.admissionTimeString = time[1].toString();
        if (this.selectedBedManagement && this.selectedBedManagement.bedId) {
            model["bedId"] = this.selectedBedManagement.bedId;
        }
        if (this.isConvertedFromOPtoIp) {
            model["isConvertedFromOPtoIp"] = true;
        }
        if (this.selectedCounsellingDetails && this.selectedCounsellingDetails.counselling.counsellingId) {
            model["counsellingId"] = this.selectedCounsellingDetails.counselling.counsellingId;
            model["counsellingPackageIds"] = this.selectedCounsellingDetails.packages.map(x => +x.counsellingPackageId).join(',');
        }
        if (model.packageModuleId && model.packageModuleId.length > 0) {
            model["packageModuleIds"] = model.packageModuleId.join(",");
            delete model.packageModuleId;
        }

        const formData = UtilHelper.prepareFormData(UtilHelper.clone(model));

        const files = this.files;
        if (files) {
            const n = files.length;
            if (n > this.maxFiles) {
                files.splice(this.maxFiles, files.length);
            }

            files.forEach((file: File, index: number) => {
                formData.append(`File${index + 1}`, file, file.name);
            });
        }



        this.httpService
            .postFile(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.modify), formData)
            .pipe(finalize(() => { this.submitted = false; }))
            .pipe(takeUntil(this.page.unSubscribe))                 
            .subscribe({
                next: (res: any) => {
                    if (res === -2) {
                        this.notifyService.warning("This bed is already in use, please select another bed");
                        this.submitted = false;
                        this.submitting = false;
                        return;
                    }
                    if (this.patientDetails)
                        this.sendWhatsAppMessage();
                    this.fetchPatientFutureAppointment();
                    this.onCloseModal();
                    this.bookAdmissionForm.reset();
                    this.notifyService.successToast("Admission has created successfully.");
                    if (this.isConvertedFromOPtoIp) {
                        this.changeOPpatientRoutingurl();
                    }
                    this.fetchAdmissions();
                    this.router.navigate(["/app/receipts", res.data, 'a', 'receipts-main']);
                    this.bedselect = null;
                    this.beds = null;
                    this.patient = null;
                },
                error: (error: HttpErrorResponse) => {
                this.submitting = false;
                this.onCloseModal();
                this.bookAdmissionForm.reset();
                const errorMessage = UtilHelper.handleError(error);
                if(errorMessage) {
                    this.notifyService.warning(errorMessage);
                  } else {
                    this.notifyService.defaultError();
                }
            }
            });
    }

    onCancel = (item: Admission) => {

        if (item.cancelling) return;

        if (item && this.isAdmissionCancel) {
            const adjustedDatTime = new Date(new Date(item.admissionDate).getTime() + this.admissionCancelMinutes * 60000);
            if (adjustedDatTime < new Date()) {
                return this.notifyService.warningToast('Cannot Cancel Admission, you crossed beyond time limit');
            }
        }

        item.cancelling = true;
        this.notifyService.confirm("Do you really want to cancel this admission? This process cannot be undone.",
            () => {
                this.httpService
                    .post(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.cancel), {
                        id: item.admissionId, createdBy: this.page.userAccount.accountId, roleId: this.page.userAccount.roleId, roleName: this.page.userAccount.roleName, createdByName: this.page.userAccount.fullName
                    })
                    .pipe(finalize(() => { item.cancelling = false; }))
                    .pipe(takeUntil(this.page.unSubscribe))
                    .subscribe((response: GenericResponse) => {
                        if (response.status === GenericStatus[GenericStatus.Success]) {
                            this.fetchAdmissions();
                        } else {
                            this.notifyService.defaultError();
                        }
                    }, (error: HttpErrorResponse) => {
                        item.cancelling = false;
                        const errorMessage = UtilHelper.handleError(error);
                        if (errorMessage) {
                            this.notifyService.warning(errorMessage);
                        } else {
                            this.notifyService.defaultError();
                        }
                    });
            }, () => {
                item.cancelling = false;
            })
    }

    onOpenCancelModel(content: TemplateRef<any>, admissionId: number) {
        this.admissionId = admissionId;
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "lg",
            windowClass: "custom-modal slots-modal effect-scale"
        });
    }


    onCancelSubmit() {
        const admissionId = this.admissionId;
        this.onCancelAdmission(admissionId, this.reason);
    }

    onCancelAdmission(admissionId: number, cancelReason: string) {
        this.onCloseModal();
        const request = Object.assign(UtilHelper.clone({ admissionId: admissionId, modifiedBy: this.page.userAccount.accountId, reason: cancelReason, logFrom: this.page.userAccount.roleId }));
        this.notifyService.confirm("Do you really want to cancel this admission? This process can not be undone.", () => {
            this.modifying = true;
            this.modifyingContent = " cancelling the selected admission";

            this.httpService.put(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.cancel), request)
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => { this.modifying = false }))
                .subscribe(() => {

                    this.fetchAdmissions();
                });
        });


    }

    private fetchDoctorSpecialization(providerId?: string, locationId?: number) {
        this.loading = true;
        const request = {
            filter: providerId,
            locationId: locationId,

        }

        this.httpService
            .post<Array<ProviderSpecialization>>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.fetchDoctorSpecialization), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<ProviderSpecialization>) => {
                    this.providerSpecialization = response;
                },
                () => {
                    this.providerSpecialization = new Array<ProviderSpecialization>();
                }
            );
    }

    onChangeProvider(p: DoctorUnits) {
        //this.filters.options.providerId = p.providerId;
    }
    //private fetchCaseType() {
    //    this.loadingCaseTypes = true;
    //    this.resourceService.caseTypes()
    //        .pipe(finalize(() => { this.loadingCaseTypes = false }))
    //        .pipe(takeUntil(this.page.unSubscribe))
    //        .subscribe((response: Array<IResource>) => {
    //            this.caseTypes = response;
    //        });
    //}

    private fetchCaseType() {
        this.loadingCaseTypes = true;
        this.resourceService.surgeryNames()
            .pipe(finalize(() => { this.loadingCaseTypes = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.caseTypes = response;
            });
    }

    //private fetchEmergencyCaseTypes() {
    //    this.loadingEmergencyCaseTypes = true;
    //    this.resourceService.emergencyCaseTypes()
    //        .pipe(finalize(() => { this.loadingEmergencyCaseTypes = false }))
    //        .pipe(takeUntil(this.page.unSubscribe))
    //        .subscribe((response: Array<IResource>) => {
    //            this.emergencyCaseTypes = response;
    //        });
    //}
    @HostListener('document:click', ['$event'])
    handleKeyUp(event: MouseEvent): void {
        let flag1;
        let flag2;
        flag1 = (event.target as HTMLElement).classList.contains('mdi-dots-horizontal');
        flag2 = (event.target as HTMLElement).classList.contains('dropdown-toggle');
        if (flag1 || flag2) {
            let scrolllength = (event.target as HTMLElement).parentElement.parentElement.parentElement.parentElement.parentElement.offsetTop - 50;
            $('.overflow-auto')[0].scrollIntoView({
                behavior: 'smooth'
            });
            $('.overflow-auto')[0].scrollTo(event.pageX, scrolllength);
            window.scrollTo(0, event.pageY - 100);
        }
    }


    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.regionalLanguage = this.page.userAccount.regionalLanguage;
                    this.fetchDoctorUnits(this.page.userAccount.locationId);
                    this.fetchPriorities();
                    //this.fetchCounsellingCodes();
                    this.fetchpatientInsurance();
                    this.fetchCounsellingCodesSpecific();
                    this.fetchCounselling();
                    this.fetchRelationShips();
                    this.fetchAdmTypesLookUpValue();
                    this.fetchAdmVisitTypesLookUpValue();
                    this.fetchOccupation();
                    this.fetchLookUpValue();
                    this.fetchProvidersFilter();
                    this.fetchVisitTypeIp();
                    //this.fetchPatients();
                    this.fetchCaseType();
                    this.buildBookAdmissionForm();
                    this.getSettingsAdmissionCancel();
                    this.getSettingsAdmissionEdit();
                    this.fetchChargeCategory();
                    this.fetchAdmissionPatients();
                    this.fetchRoomsBasedOnLocation();
                    this.fetchDepartments();
                    this.fetchRoleDepartment();
                    this.onAddRoleDepartment();
                    this.fetchTPAOrinizations();
                    this.city = this.page.userAccount.city;
                    this.practiceName = this.page.userAccount.practiceName;

                    this.toDay = moment(new Date()).format('YYYY-MM-DD');
                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((params: Params) => {
                            if (params["aid"]) {
                                const FromOp = params["isOpToIp"];
                                this.fromOp = params["isOpToIp"];
                                this.isConvertedFromOPtoIp = FromOp === "fromAppointments";
                                this.appointmentId = parseInt(window.atob(params["aid"]));
                                if (FromOp == "fromScheduledAdmission") {
                                    this.replaceLastLetter(params["pid"], "%3D");
                                    this.onOpenBookAdmissionModel();
                                    this.fetchAdmissinDetail(this.appointmentId);
                                }
                                else {
                                    this.onOpenBookAdmissionModel();
                                }
                            }
                            else if (params["pid"] != 'bed' && params["pid"] != undefined) {
                                let patientId = params["pid"];
                                this.pid = patientId;
                                const FromOp = params["isOpToIp"];
                                this.fromOp = params["isOpToIp"];
                                this.isConvertedFromOPtoIp = FromOp === "fromAppointments";

                                if (patientId) {

                                    //setTimeout(() => {
                                    //
                                    //    this.resourceService.patientsAll()
                                    //        .pipe(finalize(() => { this.loadingPatients = false }))
                                    //        .pipe(takeUntil(this.page.unSubscribe))
                                    //        .subscribe((response: Array<IResource>) => {
                                    //            this.patients = response;

                                    //        });

                                    //}, 200);
                                    this.onOpenBookAdmissionModel();
                                    this.fetchAdmissions();
                                    this.findPatient(null,patientId);
                                    // this.fetchAdmissionProviders();
                                    if (this.fromOp === "fromScheduledAdmission") {
                                        this.fetchAdmissinDetail(this.appointmentId);
                                    }
                                }

                            }
                            else if (params["pid"] == 'bed') {
                                setTimeout(() => {
                                    this.onOpenBookAdmissionModel();
                                }, 200);
                                //this.resourceService.patientsAll()
                                //    .pipe(finalize(() => { this.loadingPatients = false }))
                                //    .pipe(takeUntil(this.page.unSubscribe))
                                //    .subscribe((response: Array<IResource>) => {
                                //        this.patients = response;

                                //    });
                                this.fetchAdmissions();
                            }
                            else {
                                this.isPatientFilters = true;
                                const url = this.router.url;
                                this.routingValue = url.split("/")[url.split("/").length - 3];
                                const encryptedPatientId = url.split("/")[url.split("/").length - 2];
                                if (encryptedPatientId && encryptedPatientId != "admissions") {
                                    this.httpService
                                        .post<any>(ApiResources.getURI(ApiResources.application.base, ApiResources.application.decode), { json: encryptedPatientId })
                                        .pipe(takeUntil(this.page.unSubscribe))
                                        .subscribe(
                                            (response: any) => {
                                                this.urlPatientId = parseInt(response);
                                                if (this.urlPatientId && this.urlPatientId > 0) {
                                                    this.isPatientFilters = false;
                                                }
                                                this.fetchAdmissions();
                                            },
                                            () => { }
                                        );
                                } else {
                                    this.fetchAdmissions();
                                }
                            }
                        });
                } else {
                    this.page.userAccount = undefined;
                }
                this.printOptionService.get((is) => { this.isPrintLogo = is; });
            });




        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.currentPageView = PageView.MainView;
                this.selectedCounsellingDetails = null;
                this.patientCounselling = [];
            }
        });
    }

    onInternalMedicine(encryptedAppointmentId: string) {
        this.router.navigate(["/app/internal-medicine", encryptedAppointmentId, 'a']);
    }
    onObEncounter(item: Admission) {
        this.encryptedAdmissionId = item.encryptedAdmissionId;
        //if (item) {
        //    const adjustedDatTime = new Date(new Date(item.admissionDate).getTime() + this.admissionCancelMinutes * 120000);
        //    if (adjustedDatTime < new Date()) {
        //        return this.notifyService.warningToast('Cannot edit Ob Encounter, you crossed beyond time limit');
        //    }
        //}
        this.router.navigate(["/app/ob-ip-encounter", item.encryptedAdmissionId, 'a']);
    }
    onGynEncounter(encryptedAppointmentId: string) {
        this.router.navigate(["/app/gyn-ip-encounter", encryptedAppointmentId, 'a']);
    }
    onDietEncounter(encryptedAppointmentId: string) {
        this.router.navigate(["/app/diet-plan-encounter", encryptedAppointmentId, 'a']);

    }
    onIVFIPEncounter(encryptedAppointmentId: string) {
        this.router.navigate(["/app/ivf-ip-encounter", encryptedAppointmentId, 'a']);

    }
    onNeonatalDashboardEncounter(encryptedAppointmentId: string) {
        this.router.navigate(["/app/neonatal-ip-encounter", encryptedAppointmentId, 'a']);
    }
    onViewDocument(encryptedPatientId: string) {
        this.router.navigate(["/app/patient/", encryptedPatientId, 'documents']);
    }
    onGatepass(encryptedAdmissionId: string) {
        this.router.navigate(["/app/", 'gate-pass', encryptedAdmissionId, 'a']);  
    }
    ngOnDestroy() {
        this.onCloseModal();
        this.onCloseFilters();
        this.page.unsubscribeAll();
    }


    fetchDoctorSpecializationHelper($event: any) {
        if ($event && $event.term && $event.term.length > 1) {
            //let locationId = this.bookAdmissionForm.get("locationId").value
            //let consultationTypeId = this.bookAdmissionForm.get("consultationTypeId").value
            this.fetchDoctorSpecialization($event.term, this.page.userAccount.locationId);
        } else {
            this.providerSpecialization = new Array<ProviderSpecialization>();
        }
    }

    openPopover = (p: any, item: Admission) => {
        p.open();
        item.editExpectedDischargeDate = false;
        this.currentPopover = p;
    }

    closePopover = () => {
        this.currentPopover.close()
    }

    editExpectedDischargeDate = (item: Admission) => {

        item.editExpectedDischargeDate = true;
    }

    onExpectedDischargeDateChange = ($event: any, item: Admission) => {

        item.editExpectedDischargeDate = true;
        this.eDate = $event.target.value;
        if (item.isUpdatingDischargeDate === true) {
            item.expectedDischargeDateView = this.datePipe.transform(new Date($event.target.value), 'yyyy-MM-dd');
        }
    }

    updateExpectedDischargeDate = (item: Admission) => {
        item.expectedDischargeDate = this.eDate;
        if (item.expectedDischargeDate == null) {
            this.notifyService.warning("you can't update without selecting date");
            return;
        }
        item.isUpdatingDischargeDate = true;
        this.httpService
            .post<any>(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.updateDischargeDate),
                {
                    id: item.admissionId,
                    date: item.expectedDischargeDate,
                    dischargedBy: this.page.userAccount.accountId
                })
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                (response: number) => {
                    this.dischargeDate = response
                    item.isUpdatingDischargeDate = false;
                    item.editExpectedDischargeDate = false;
                    this.fetchAdmissions();
                },
                () => { }
            );

    }
    private fetchReferralDoctor() {
        this.referrals = this.referralDoctorInput.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap((term: string) => {
                return term && term.length >= 2
                    ? this.resourceService.fetchAdmissionReferralDoctor(term).pipe(
                        tap(() => this.loadingReferraldoctor = true),
                        catchError(() => { return of([]) }),
                        finalize(() => this.loadingReferraldoctor = false)
                    )
                    : of([])
            }
            )
        );
    }
    onChangeReferralDoctor = (model: IResource) => {
        const key = model.value == 'P' ? 'internalProviderId' : 'externalProviderId';
        this.bookAdmissionForm.patchValue({
            [key]: model.id
        })
    }
    fetchPatientFutureAppointment() {
        this.loadingfutureAppointment = true;
        const request = {};
        request["patientId"] = this.bookAdmissionForm.value["patientId"];
        request["admissionDate"] = this.bookAdmissionForm.value.admissionDate.replace(":00.000", "");
        const a = request["admissionDate"].split("T");
        const time = a[1];
        request["admissionTime"] = time;

        this.httpService
            .post(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findPatientAppointmentDate), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingfutureAppointment = false))
            .subscribe(
                (response: Array<Appointment>) => {
                    this.patientFutureAppointments = response;
                    if (this.patientFutureAppointments.length > 0) {
                        this.onOpenAppointmentModel(this.templatePatientAppointment);
                    }
                },
                () => {
                    this.patientFutureAppointments = [];
                }
            );
    }

    onSelectPayType(payType: string) {
        this.locationChargeTemplates = [];
        this.bookAdmissionForm.get("chargeModuleTemplateId").setValue(null);
        this.packages = [];
        this.bookAdmissionForm.get("packageModuleId").setValue(null);
        // removed Code of making insurance and TPA  as required  in new change we are changing only Charge Module template Id. 
        this.fetchLocationChargeTemplates(payType);

    }

    onOpenAppointmentModel(content?: TemplateRef<any>) {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "xl",
            windowClass: "custom-modal effect-scale"
        });
    }
    fetchPatientHelper($event: any) {

        if (UtilHelper.isEmpty($event)) {
            if (UtilHelper.isEmpty($event.term)) {
                const request = $event.term;
                this.onPatientSearch(request);
            } else {
                this.patients = new Array<IResource>();
            }
        } else {
            this.patients = new Array<IResource>();
        }
    }
    onClickPatient(event: any) {
        if (event) {
            this.patientDetails = event;

            this.patientID = event.id;
            this.fetchCounsellingsForPatient(event.id);
            this.showPackageBlock = false;
            if (this.bookAdmissionForm.get('payType').value == 'I') {
                this.fetchPatientInsurances(this.patientID);
            }
        }
        else {
            this.flag = false;
            this.selectedCounsellingDetails = null
        }
        this.onAddPatientInsurance();

    }
    private onPatientSearch(request: string) {
        if (UtilHelper.isEmpty(request) && request.length > 2) {
            this.loadingPatients = true;
            this.resourceService.patients(request.trim())
                .pipe(finalize(() => { this.loadingPatients = false }))
                .pipe(takeUntil(this.page.unSubscribe))
                .subscribe((response: Array<IResource>) => {
                    this.patients = response;
                });
        } else {
            this.loadingPatients = false;
            this.patients = new Array<IResource>();
        }

    }

    navigateToReschedule(pid: string, value: string) {
        this.router.navigateByUrl(`app/out-patients/${pid}/${value}`);

    }
    navigateToAdmissions(pid: string, value: string) {
        this.router.navigateByUrl(`app/out-patients/${pid}/${value}`);

    }

    private fetchTPAOrinizations() {
        this.loadingTpas = true;
        this.resourceService.tpaCompanies()
            .pipe(finalize(() => { this.loadingTpas = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.tpa = response;
            });
    }

    onOpenRequestBedModel(template: TemplateRef<any>, admission: Admission) {
        if (admission.admissionBedChangeRequestId) {
            this.notifyService.warning("Request Raised Already");
        }
        else {
            this.selectedAdmission = admission;
            this.buildRaiseBedRequestForm();
            this.fetchChargeCategories();
            this.modalRef = this.modalService.open(template, {
                backdrop: "static",
                keyboard: false,
                centered: true,
                size: "lg",
                windowClass: "custom-modal slots-modal effect-scale"
            });
        }

    }

    private buildRaiseBedRequestForm() {
        this.raiseBedChangeRequestForm = this.formBuilder.group({
            admissionBedRequestId: 0,
            admissionId: this.selectedAdmission.admissionId,
            requestComments: [null, [Validators.required]],
            chargeCategories: [null, [Validators.required]],
            createdBy: this.page.userAccount.accountId,
            locationId: this.page.userAccount.locationId,
            admissionChangeRequestTypeId: 1,
            admissionTransferRequestId: 0,
        });

    }

    onSubmitBedChangeRequest() {
        this.submitted = true;
        if (this.raiseBedChangeRequestForm.invalid) {
            return;
        }
        const request = Object.assign(UtilHelper.clone(this.raiseBedChangeRequestForm.getRawValue()));
        if (request.chargeCategories) {
            request.chargeCategories = request.chargeCategories.join(",");
        }
        this.httpService
            .post(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.raiseBedChangeRequest), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingfutureAppointment = false))
            .subscribe(
                (response: number) => {
                    if (response > 1) {
                        this.onCloseModal();
                        this.notifyService.successToast("Request Raised Succesfull");
                        this.fetchAdmissions();
                    }
                    else {
                        switch (response) {
                            case -1:
                                this.notifyService.warningToast("Sorry Error Occured");
                                break;
                            case -2:
                                this.notifyService.warningToast("Request Already Raised For this Admissison");
                                break;
                        }

                        //this.notifyService.warningToast("Sorry Error Occured");
                    }
                },
                () => {
                    this.patientFutureAppointments = [];
                }
            );
    }

    private fetchChargeCategories() {
        this.resourceService.chargeCategory()
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.chargeCategories = response;
                this.chargeCategories = this.chargeCategories.filter((item) => {
                    if (item.id != this.selectedAdmission.chargeCategoryId) {
                        return item;
                    }
                })
            });
    }

    onGenerateBarcodeView(content: TemplateRef<any>, umrNo: string,nameLoc:string) {
        this.umrNo = umrNo;
        this.nameLoc = nameLoc;

        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: "sm",
            windowClass: "custom-modal effect-scale"
        });
    }
    onRedirectBabyRegistration(record: Admission, value: string) {
        var encryptedAdmissionId = window.btoa(record.admissionId.toString());
        var encryptedPatientId = window.btoa(record.patientId.toString());
        this.router.navigate(['app/masters/baby-registration', encryptedPatientId, value, encryptedAdmissionId]);
    }
    onRedirectBirthCertificate(record: Admission, value: string) {
        this.router.navigate(['app/masters/birth-certificate', record.patientId, value]);
    }
    onRedirectDeathCertificate(record: Admission, value: string) {
        this.router.navigate(['app/masters/death-certificate', record.patientId, value]);
    }
    removePrintCss() {
        const head = document.getElementsByTagName('head')[0];
        const fileref = document.getElementById("BarcodePrint");
        if (fileref != null)
            head.removeChild(fileref);
        this.onCloseModal();
    }

    onOpenDoctorUnitRequest(template: TemplateRef<any>, admission: Admission) {
        this.selectedAdmission = admission;
        this.isShowTemplate = false;
        this.fetchrequests();
        setTimeout(() => {
            if (this.isShowTemplate) {
                this.notifyService.warning("Request Raised")
            }
            else {
                this.buildRaisedDotcorUnitRequest();
                this.modalRef = this.modalService.open(template, {
                    backdrop: "static",
                    keyboard: false,
                    centered: true,
                    size: "lg",
                    windowClass: "custom-modal slots-modal effect-scale"
                });
                this.fetchDoctorUnits(this.page.userAccount.locationId);
            }

        }, 50)

    }
    private buildRaisedDotcorUnitRequest() {
        this.raiseDoctorUnitRequestForm = this.formBuilder.group({
            admissionTransferRequestId: 0,
            admissionId: this.selectedAdmission.admissionId,
            requestComments: [null, [Validators.required, WhiteSpaceValidator.isValid]],
            doctorUnitMasterId: [null, [Validators.required]],
            createdBy: this.page.userAccount.accountId,
            locationId: this.page.userAccount.locationId,
            admissionChangeRequestTypeId: 2,
            specializationIds: [null, [Validators.required]]

        });
        this.raiseDoctorUnitRequestForm.get("doctorUnitMasterId").valueChanges.subscribe((id: number) => {
            if (id) {
                this.selectedDoctorUnit = this.DoctorUnits.find(x => x.doctorUnitMasterId === id);
                this.bookAdmissionForm.patchValue({
                    providerId: this.selectedDoctorUnit.tagDoctor[0].providerId
                });
                this.fetchSeniorDoctorSpecialization(this.selectedDoctorUnit.tagDoctor[0].providerId);
            }
            else {
                this.selectedDoctorUnit = null;
                this.raiseDoctorUnitRequestForm.patchValue({
                    providerId: null
                });
            }
        });

    }
    onOpenAttendentView(template: TemplateRef<any>, admission: Admission) {
        this.getLogoImage();
        this.selectedAdmission = admission
        this.modalRef = this.modalService.open(template, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "sm",
            windowClass: "custom-modal slots-modal effect-scale"
        });
    }
    onOpenSticker(template: TemplateRef<any>, admission: Admission) {
        this.selectedAdmission = admission;
        this.modalRef = this.modalService.open(template, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "xxl",
            windowClass: "custom-modal slots-modal effect-scale"
        });
    }
    onOpenConsentReport(template: TemplateRef<any>, admission: Admission) {
        this.getLogoImage();
        this.selectedAdmission = admission
        this.modalRef = this.modalService.open(template, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "sm",
            windowClass: "custom-modal slots-modal effect-scale"
        });
        this.calculatePatientAge(this.selectedAdmission);
    }
    onOpenResearchConsentReport(template: TemplateRef<any>, admission: Admission) {
        this.getLogoImage();
        this.selectedAdmission = admission
        this.modalRef = this.modalService.open(template, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "sm",
            windowClass: "custom-modal slots-modal effect-scale"
        });
        this.calculatePatientAge(this.selectedAdmission);
    }
    onOpenPatientDetailForm(template: TemplateRef<any>, patient: Admission) {
        this.getLogoImage();
        this.selectedAdmission = patient;
        const dateOfBirth = DateHelper.toNgbDateStruct(String(this.selectedAdmission.dateOfBirth));
        if (dateOfBirth != null) {
            let mdob = moment(String(this.selectedAdmission.dateOfBirth), "YYYY-MM-DD");
            let age = moment().diff(mdob, "months");
            let yrs = (age / 12).toFixed(1);
            this.yearsfordisplay = parseInt(yrs, 10);
            let months = age % 12;
            var days = moment().diff(mdob.add(yrs, 'years'), 'days', false);
            this.yearsfordisplay = days;
            this.yearsfordisplay = parseInt(yrs);
            this.monthsfordisplay = months;
            var date1 = moment(String(this.selectedAdmission.dateOfBirth), "YYYY-MM-DD");
            var monthsa = moment().diff(date1, 'months');
            date1.add(monthsa, 'months');
            var daysa = moment().diff(date1, 'days');
            monthsa + ' ' + 'Month(s)' + ' ' + daysa + " Day(s)"
            this.daysfordisplay = daysa;
            this.patientAge = this.selectedAdmission.dateOfBirth ? this.yearsfordisplay + 'Y ' + this.monthsfordisplay + 'M ' + this.daysfordisplay + 'D' : null;
        }


        this.modalRef = this.modalService.open(template, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "sm",
            windowClass: "custom-modal slots-modal effect-scale"
        });
    }
    getLogoImage = () => {
        this.loading = true;

        this.httpService
            .get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), { type: "Logo", active: true })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loading = false }))
            .subscribe(
                (response: Array<Setting>) => {
                    if (response && response.length > 0) {
                        this.logoBasics = response[0];
                        if (UtilHelper.isEmpty(response[0].imageUrl)) {
                            response[0].imageUrl = `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${response[0].imageUrl}`;
                        }
                    }
                },
                () => {
                    this.logoBasics = new Setting();
                }
            );
    }

    onSubmitDoctorUnitChangeRequest(isType: boolean) {
        this.submitted = true;
        this.isDoctorUnit = isType;
        if (this.isDoctorUnit && this.raiseDoctorUnitRequestForm.invalid) {
            return;
        }
        else if (!this.isDoctorUnit && this.raiseBedChangeRequestForm.invalid) {
            return;
        }

        this.submitting = true;
        const request = this.isDoctorUnit ? { ...this.raiseDoctorUnitRequestForm.getRawValue() } : { ...this.raiseBedChangeRequestForm.getRawValue(), };
        if (!this.isDoctorUnit) {
            request.chargeCategories = request.chargeCategories.join(",");
        }
        request["patientName"] = this.selectedAdmission.patientName;
        request["roleId"] = this.page.userAccount.roleId;
        request["createdByName"] = this.page.userAccount.fullName;
        const url = ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.addTransferRequest);
        this.httpService.post(url, request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.submitted = this.submitting = false))
            .subscribe(
                {
                    next: (response: number) => {
                        if (response > 0) {
                            this.notifyService.successToast(`Requested Successfully`);

                        }
                        else {
                            switch (response) {
                                case -1:
                                    this.notifyService.warningToast(`Sorry Error Occured`);
                                    break;
                                case -2:
                                    this.notifyService.warningToast(`Request Already Raised For this Admissison`);
                                    break;
                            }
                        }
                        this.onCloseModal();
                    },
                    error: (error: any) => {
                        this.notifyService.warningToast(`Sorry Error Occured`);
                    }
                }
            );
    }

    onChangeDoctorUnit(value: any) {
        const id = value.doctorUnitMasterId;
        if (this.selectedAdmission.doctorUnitMasterId == id) {
            this.notifyService.warningToast("present you are having same doctor unit");
            this.raiseDoctorUnitRequestForm.patchValue({
                doctorUnitMasterId: 0
            })
        }

    }


    private fetchrequests() {
        this.loading = true;
        const request = {
            admissionId: this.selectedAdmission.admissionId,
            admissionChangeRequestTypeId: 2,
            active: true
        };
        this.httpService.post(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchTransferRequest), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<AdmissionTransferRequest>) => {
                    if (response.length > 0) {
                        this.isShowTemplate = true;
                    }
                },
            );
    }

    private fetchLocationChargeTemplates(payType: string, isCounselling = false): void {
        const chargeTemplateControl = this.bookAdmissionForm.get("chargeModuleTemplateId");
        chargeTemplateControl.enable();

        this.loadingLocationChargeTemplates = true;
        this.resourceService.chargeModuleTemplates(this.page.userAccount.locationId, payType)
            .pipe(finalize(() => { this.loadingLocationChargeTemplates = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((chargeTemplates: Array<IResource>) => {
                this.locationChargeTemplates = chargeTemplates;
                if (chargeTemplates?.length) {
                    if (!isCounselling) {
                        if (payType === "C" && chargeTemplates?.length) {
                            const chargeTemplate = chargeTemplates.find(m => m.status)
                            if (chargeTemplate) {
                                chargeTemplateControl.setValue(chargeTemplate.id);
                                //chargeTemplateControl.disable();
                                this.fetchPackages(payType, chargeTemplate.id);
                                this.fetchBeds(() => {
                                    const availableBed = this.beds.find(x => x.bedStatusName === 'Available');
                                    if (availableBed) {
                                        this.selectBed(availableBed);
                                    }
                                });
                                this.fetchChargeCategoriesWithBeds();
                            }
                        }
                    }
                    else {
                        this.fetchBeds(() => {
                            const availableBed = this.beds.find(x => x.bedStatusName === 'Available');
                            if (availableBed) {
                                this.selectBed(availableBed);
                            }
                        });
                        this.fetchChargeCategoriesWithBeds();
                    }
                }
            });
    }

    onChangeChargeTemplate(id: number) {
        const payTypeControl = this.bookAdmissionForm.get("payType");
        const selectedChargeTemplate = this.locationChargeTemplates.find(x => x.id === id);
        if (payTypeControl.value === "I" && (selectedChargeTemplate?.optionalId1 != null && selectedChargeTemplate?.optionalId2 != null)) {
            this.bookAdmissionForm.patchValue({
                insuranceCompanyId: selectedChargeTemplate.optionalId1,
                tpaId: selectedChargeTemplate.optionalId2
            });
        }
        this.fetchPackages(payTypeControl.value, id);
        this.fetchChargeCategoriesWithBeds();
        this.fetchBeds();
    }

    fetchRoomsBasedOnLocation() {
        this.showRooms = true;
        this.showWards = false;
        this.loadingRooms = true;
        this.httpService
            .post<Array<BedManagement>>(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchRooms), { locationId: this.page.userAccount.locationId, chargeCategoryId: this.filters.options.chargeCategoryId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingRooms = false))
            .subscribe(
                (response: Array<BedManagement>) => {
                    this.rooms = response;
                },
                () => {
                    this.rooms = [];
                }
            );
    }


    fetchAdmissinDetail(appointmentId: number) {
        const request = { appointmentId: appointmentId };
        this.httpService
            .get(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchScheduledAdmission), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<ObEncounter>) => {
                    if (response.length > 0) {
                        this.admissionSlipRecords = response;
                        var records = this.admissionSlipRecords[0].admissionSlip;
                        this.admissionRecords = JSON.parse(records);
                        this.caseTypes.forEach((item) => {
                            if (this.admissionRecords.surgicalPrcedure) {
                                if (item.value.trim().toLowerCase() == this.admissionRecords.surgicalPrcedure.trim().toLowerCase()) {
                                    this.surgicalId = item.id
                                }
                            }
                        });

                        this.bookAdmissionForm.patchValue({
                            patientName: response[0].patientName,
                            patientId: this.admissionRecords.patientId,
                            doctorUnitMasterId: this.admissionRecords.doctorUnitMasterId,
                            appointmentId: this.admissionRecords.appointmentId,
                            admissionTypeId: this.admissionType?.id,
                            surgeryId: this.surgicalId != 0 ? this.surgicalId : null,
                        })
                        this.selectedDoctorUnit = this.DoctorUnits.find(x => x.doctorUnitMasterId === this.admissionRecords.doctorUnitMasterId);

                        this.bookAdmissionForm.patchValue({
                            providerId: this.selectedDoctorUnit?.tagDoctor[0].providerId
                        });
                        this.fetchCounsellings(this.admissionRecords.encryptedPatientId)

                    }

                },
            );
    }
    private fetchCounsellings(encryptedPatientId: string): void {
        this.loading = true;
        this.httpService
            .get(ApiResources.getURI(ApiResources.counselling.base, ApiResources.counselling.fetch), { patientId: encryptedPatientId })
            .pipe(finalize(() => this.loading = false), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (counsellings: Array<Counselling>) => {
                    if (counsellings) {
                        var counselling = counsellings[0];
                        this.bookAdmissionForm.patchValue({
                            counsellingCode: counselling && counselling.counsellingNo ? counselling.counsellingNo : null
                        })
                    }

                }
            });
    }

    private fetchCounsellingsForPatient(encryptedPatientId: number): void {
        this.loading = true;
        this.httpService
            .get<GenericResponse>(ApiResources.getURI(ApiResources.counselling.base, ApiResources.counselling.fetch), { patientId: encryptedPatientId })
            .pipe(finalize(() => this.loading = false), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        this.patientCounselling = response.data;
                        if (response.data != null) {
                            this.flag = true;
                        }
                        else {
                            this.flag = false;
                        }
                    }

                }
            });
    }
    showCounsellingData(event: Counselling) {
        const counselling = this.counsellingDetails.find(x => x.counsellingNo === event.counsellingNo);
        if (counselling) {
            this.selectedCounsellingId = counselling.counsellingId;
            this.showCounsellingDetails(this.templateViewCounselling);
        }
    }

    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }
    private fetchRoleDepartment() {
        this.loadingRoleDepartment = true;
        this.resourceService.fetchRoleDepartment()
            .pipe(finalize(() => { this.loadingRoleDepartment = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                if (response.length > 0) {
                    this.roleDepartment = response;
                }

            });
    }


    onAddRoleDepartment() {
        this.isComments = true;
        this.roleDepartmentArray.push(this.buildRoleDeparmentForm());
    }


    get roleDepartmentArray() { return this.bookAdmissionForm.controls["roleDepartment"] as FormArray }


    private buildRoleDeparmentForm() {
        return this.formBuilder.group({
            roleDepartmentId: [null],
            comments: [null],
            roleDepartmentName: [null]

        });

    }


    get roleDepartmentForm() {
        return this.roleDepartmentArray;
    }

    onRemoveRoleDepartment(index: number) {
        this.roleDepartmentArray.removeAt(index);
    }


    onChangeRoleDepartment(control: FormGroup) {

        const id = control.controls["roleDepartmentId"].value;
        const roleDepartmentId = control.controls["roleDepartmentId"];
        let roleDepartmentArray = this.bookAdmissionForm.value.roleDepartment as Array<any>;
        let ifExists = roleDepartmentArray.filter(l => l.roleDepartmentId === id);
        if (ifExists.length > 1) {
            this.notifyService.warningToast("This Role Department is already added");
            roleDepartmentId.setValue(null);
            return;
        }
    }

    fetchSpecialization(admission: Admission) {
        const request = {
            specializations: admission.specializations
        }
        this.httpService
            .post<Array<Admission>>(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchspeciallization), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<Admission>) => {
                this.data = response;

                this.data.forEach((m) => {
                    if (m.encounterName == "OB Encounter") {
                        this.obbutton = true;
                    }
                    if (m.encounterName == "Gyne Encounter") {
                        this.gynbutton = true;
                    }
                    if (m.encounterName == "Diet Encounter") {
                        this.dietbutton = true;
                    }
                    if (m.encounterName == "NeonatalIP Encounter") {
                        this.neonatalbutton = true;
                    }
                    if (m.encounterName == "IVF IP Encounter") {
                        this.ivfbutton = true;
                    }
                })
            })
    }


    private fetchSeniorDoctorSpecialization(providerId: number) {
        this.loadSeniorSpecialization = true;
        this.resourceService.fetchDoctorSpecializations(providerId)
            .pipe(finalize(() => { this.loadSeniorSpecialization = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.seniorDoctorSpecializations = response;
                this.bookAdmissionForm.get("specializationId").setValue(response.length > 0 ? response[0].id : 0);
            });
    }

    onclick(admission: Admission) {
        this.obbutton = false
        this.gynbutton = false;
        this.dietbutton = false;
        this.neonatalbutton = false;
        if (admission.encounterName == null) {
            this.fetchSpecialization(admission);
        }
    }

    onConvertToCash(admission: any) {
        this.notifyService.confirm('Do you want to Convert Insurance to Cash?', () => {
            const request = {
                admissionId: admission.admissionId,
                locationId: admission.locationId,
                createdBy: this.page.userAccount.accountId
            }
            this.httpService.post(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.updateInsuranceToCash), request)
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => this.submitted = this.submitting = false))
                .subscribe(
                    (response: number) => {
                        if (response > 0) {
                            this.notifyService.successToast('Succesfully Converted to Cash.. ')
                            this.fetchAdmissions();
                        }
                    }
                )

        })


    }
    onChangeCaseType(template: TemplateRef<any>, admission: Admission) {
        this.selectedAdmission = admission;
        this.changecaseTypes = this.caseTypes.filter((m) => {
            if (m.id != this.selectedAdmission.surgeryId) {
                return m;
            }
        })
        this.modalRef = this.modalService.open(template, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "lg",
            windowClass: "custom-modal slots-modal effect-scale"
        });
    }
    onSubmitChangeCaseTypeForm() {
        const request = { surgeryId: this.surgeryId, admissionid: this.selectedAdmission.admissionId, locationId: this.page.userAccount.locationId, createdBy: this.page.userAccount.accountId};
        this.httpService
            .get<number>(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.updatecasetype), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { }))
            .subscribe((response: number) => {
                if (response == 1) {
                    this.fetchAdmissions()
                    this.onCloseModal();
                    this.surgeryId = null;
                    this.notifyService.successToast("CaseType successfully Changed")
                }

            })
    }
    onOpenWidget(content: TemplateRef<any>, appointment: Admission) {

        this.admissionId = appointment.admissionId

        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "lg",
            windowClass: "custom-modal invoice-modal effect-scale"
        });
    }
    countchangedHandler(count: number) {
        this.admissions.forEach(item => {
            if (item.admissionId == this.admissionId) {
                item.messageCount = count;
            }
        })
    }

    fetchPatientNameMobileUmrHelper($event?: any) {
        if ($event.term.length < 1)
            this.onApplyFilters();

        if ($event && $event.term && $event.term.length > 2) {
            var temp = [];
            for (let row of this.admissions) {
                var stk = `^${$event.term}`;
                const regexp = new RegExp(stk.toLowerCase());
                if (regexp.test(row.patientName.toLowerCase())) { temp.push(row); }
            }
            let locationId = this.page.userAccount.locationId;
            this.fetchPatientNameMobile($event.term, locationId);

        } else {
            this.filterPatient = new Array<IResource>();
        }
    }
    private fetchPatientNameMobile(patientId?: string, locationId?: number) {

        this.loading = true;
        var request = {
            filter: patientId,
            locationId: locationId,
        }
        this.httpService
            .get<Array<IResource>>(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchAdmissionPatientNameMobileUmr), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<IResource>) => {
                    this.filterPatient = response;
                },
                () => {
                    this.filterPatient = new Array<IResource>();
                }
            );
    }
    customPatientSearchPatient(term: string, item) {
        return item['value'].toLocaleLowerCase().indexOf(term) > -1 || item['optionalText'].indexOf(term) > -1 || item['optionalText1'].indexOf(term) > -1;
    }
    onOpenPatientInsurence(content: TemplateRef<any>, event?: any) {
        if (event == 'I') {
            this.isPatient = true
            this.documentModalRef = this.modalService.open(content, {
                backdrop: "static",
                keyboard: false,
                centered: true,
                size: "sm",
                windowClass: "custom-modal slots-modal effect-scale"
            });
        }
    }

    private buildForm() {
        this.patientForm = this.formBuilder.group({

            insurances: this.formBuilder.array([])
        });
    }


    private buildpatientInsurance(record?: PatientInsurance) {
        const group = this.formBuilder.group({
            patientInsuranceId: [record ? record.patientInsuranceId : 0],
            patientId: [record ? record.patientId : this.patientID ? this.patientID : null],
            insuranceCompanyId: [record ? record.insuranceCompanyId : null],
            insuranceTypeId: [record ? record.insuranceTypeId : null],
            validity: [record ? record.validity : null],
            cardNo: [record ? record.cardNo : null],
            createdby: this.page.userAccount.accountId,
            insuranceCompanyName: [record ? record.insuranceCompanyName : null],
        })
        return group;

    }
    get patientInsuranceArray() { return this.patientForm.controls["insurances"] as FormArray }

    onAddPatientInsurance() {
        this.patientInsuranceArray.push(this.buildpatientInsurance());

    }
    get form() {
        return this.patientForm.controls;
    }

    onRemovepatientInsuranceArray(idex: number) {
        this.patientInsuranceArray.removeAt(idex)
    }
    private fetchpatientInsurance() {
        const request = {
            locationId: this.page.userAccount.locationId
        }

        this.httpService.post(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchinsurance), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<any>) => {
                    this.insurances = response
                },
            );
    }
    private fetchInsurance(patientId: number) {
        this.loadInsurance = true;
        this.resourceService.fetchInsurancedetails(patientId)
            .pipe(finalize(() => { this.loadInsurance = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.details = response;
                const date = new Date(this.details[0].optionalText3);
                const formattedDate = date.toISOString().split('T')[0];
                if (this.details.length > 0) {
                    this.editInsuranceForm.patchValue({
                        insuranceCompanyId: this.details[0].optionalId,
                        validity: formattedDate,
                        cardNo: this.details[0].optionalText1
                    });
                }
            });
    }

    onEditInsurance(template: TemplateRef<any>, admission: Admission) {
        this.admissionId = admission.admissionId;
        this.patientID = admission.patientId;
        this.buildEditInsuranceForm();
        this.fetchInsurance(this.patientID);

        this.modalRef = this.modalService.open(template, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "smonOpenBookAdmissionModel",
            windowClass: "custom-modal slots-modal effect-scale"
        });
    }

    get insuranceform() {
        return this.editInsuranceForm.controls;
    }

    private buildEditInsuranceForm() {
        this.editInsuranceForm = this.formBuilder.group({
            insuranceCompanyId: [null, [Validators.required]],

            cardNo: [null, [Validators.required]],
            validity: [null, [Validators.required]],
            createdBy: this.page.userAccount.accountId,
            locationId: this.page.userAccount.locationId,

        });

    }
    onSubmiteditInsurance() {
        this.submitted = true;
        if (this.editInsuranceForm.invalid) {
            return;
        }
        const request = {
            ...this.editInsuranceForm.getRawValue(),
            admissionid: this.admissionId,
            patientId: this.patientID

        };
        this.submitting = true;
        this.httpService.post(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.updateInsurance), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.submitted = this.submitting = false))
            .subscribe(
                (response: number) => {
                    if (response > 0) {
                        this.notifyService.successToast('Record is Updated .. ')
                    }
                    this.onCloseModal();
                }
            )
    }


    onPaymentConsentForm(content: TemplateRef<any>) {
        this.reportName = "PAYMENT CONSENT FORM"
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: "xl",
            windowClass: "custom-modal effect-scale"
        });
    }
    OpenAddmisionForm(content: TemplateRef<any>) {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: "xl",
            windowClass: "custom-modal effect-scale"
        });
    }
    CashLessUndertakingForm(template: TemplateRef<any>) {
        this.modalRef = this.modalService.open(template, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "lg",
            windowClass: "custom-modal slots-modal effect-scale"
        });
    }
    onCheckOut(item: Admission) {
        const request = {
            admissionId: item.admissionId,
            checkOutBy: this.page.userAccount.accountId
        }
        this.httpService.post(ApiResources.getURI(ApiResources.gatePass.base, ApiResources.gatePass.checkout), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.submitted = this.submitting = false))
            .subscribe(
                {
                    next: (response: GenericResponse) => {
                        if (response.status === "Success") {
                            this.notifyService.successToast('Discharged Successfully');
                            this.fetchAdmissions();
                        }
                        else if (response.status === "Info") {
                            this.notifyService.warningToast(response.message);
                        }
                        else {
                            this.notifyService.warningToast('Unable to discharge');
                        }
                    },
                    error: () => {
                        this.notifyService.warningToast('Unable to discharge');
                    }
                }
            )
    }
    onPayTypeChange(item: any): void {

        if (item.value === 'I') {
            this.fetchPatientInsurances(this.patientID);
            this.bookAdmissionForm.patchValue({
                insuranceCompanyId: null,
                tpaId: null
            });
        }
        else {
            this.bookAdmissionForm.patchValue({
                insuranceCompanyId: null,
                tpaId: null
            });
        }
    }
    calculatePatientAge(admission: Admission): void {
        this.selectedAdmission = admission;
        const dateOfBirth = DateHelper.toNgbDateStruct(String(this.selectedAdmission.dateOfBirth));
        if (dateOfBirth != null) {
            let mdob = moment(String(this.selectedAdmission.dateOfBirth), "YYYY-MM-DD");
            let age = moment().diff(mdob, "months");
            let yrs = (age / 12).toFixed(1);
            this.yearsfordisplay = parseInt(yrs, 10);
            let months = age % 12;
            var days = moment().diff(mdob.add(yrs, 'years'), 'days', false);
            this.yearsfordisplay = days;
            this.yearsfordisplay = parseInt(yrs);
            this.monthsfordisplay = months;
            var date1 = moment(String(this.selectedAdmission.dateOfBirth), "YYYY-MM-DD");
            var monthsa = moment().diff(date1, 'months');
            date1.add(monthsa, 'months');
            var daysa = moment().diff(date1, 'days');
            monthsa + ' ' + 'Month(s)' + ' ' + daysa + " Day(s)"
            this.daysfordisplay = daysa;
            this.patientDOB = this.selectedAdmission.dateOfBirth ? this.yearsfordisplay + 'Y ' + this.monthsfordisplay + 'M ' + this.daysfordisplay + 'D' : null;
        }
    }

    onOpenPatientDetails(template: TemplateRef<any>, encryptedPatientId: number) {
        this.modalRef = this.modalService.open(template, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "lg",
            windowClass: "custom-modal slots-modal effect-scale"
        });
        this.findPatientDetails(encryptedPatientId);

    }
    private findPatientDetails(encryptedPatientId, patientId?: number) {
        const request = {
            patientId: patientId,
            encryptedPatientId: encryptedPatientId
        };
        this.httpService.post<Patient>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.find), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Patient) => {
                this.patient = response;
            });
    }


    updateValidators(controls: string[], validators: any[], emitEvent = false) {
        controls.forEach(control => {
            const formControl = this.bookAdmissionForm.get(control);
            formControl.setValidators(validators);
            formControl.updateValueAndValidity({ emitEvent });
        });
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
   

    onViewTimeLine(item: Admission) {
        this.encryptedAdmissionId =item.encryptedAdmissionId
        this.showTimeLine = true; 
        $("body").addClass("right-bar-enabled");

    }
    onCloseTimeLine() {
        this.admissionId = null;
        this.encryptedAdmissionId = null;
        this.encryptedPatientId = null;
     
        $("body").removeClass("right-bar-enabled");
        this.showTimeLine = false;
    }
    private fetchChargeCategoriesWithBeds() {
        this.loadingChargeCategoriesWithBeds = true;
        const request = {
            chargeModuleTemplateId: this.bookAdmissionForm.value.chargeModuleTemplateId
        };
        this.httpService.post<Array<IResource>>(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchChargeCategories), request)
            .pipe(finalize(() => { this.loadingChargeCategoriesWithBeds = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.chargeCategoriesWithBeds = response;
            });
    }


    onOpenReceiptModel(record: Admission) {
        this.router.navigate(["/app/receipts", record.encryptedAdmissionId, 'a', 'receipts-main']);
    }


}

