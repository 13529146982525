<!--container image_section   report-content-->
<div id="obprintContainer" class="  mt-1 row scroll-obsecNotHeading text-black">
    <div class="col-12">
        <div class="mt-1">
            <div class="col-12 p-0">
                <div>
                    <button class="btn btn-info" type="button" (click)="onChangePrintType(false)">
                        <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangePrintType(true)">
                        <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangeFooter(false)">
                        <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
                    </button>
                    <button class="btn btn-info" type="button" (click)="onChangeFooter(true)">
                        <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
                    </button>
                </div>
            </div>
        </div>
        <div class="scroll-obsec">
            <div class="align-items-start border border-bottom-0 d-flex pl-1 pr-1 pt-1" style="background-color: #edfaf7;">
                <div class="pl-0 col-11 d-flex">
                    <div class="d-flex flex-wrap">
                        <div *ngIf="measures && measures !=null">
                            <input type="checkbox" checked (click)="onChangeStatus('a1',$event)">
                            <label class="ml-1 mr-2">Measures</label>
                        </div>

                        <div *ngIf="bookingHeader && bookingHeader.length > 0">
                            <input type="checkbox" checked (click)="onChangeStatus('a',$event)">
                            <label class="ml-1 mr-2">Investigations Ordered</label>
                        </div>
                        <div *ngIf="records && records.length > 0">
                            <input type="checkbox" checked (click)="onChangeStatus('b',$event)">
                            <label class="ml-1 mr-2">Medication</label>
                        </div>

                        <div *ngIf="referralOrder && referralOrder.length >= 0">
                            <input type="checkbox" checked (click)="onChangeStatus('j',$event)">
                            <label class="ml-1 mr-2">Referral Doctors</label>
                        </div>
                        <div *ngIf="gyneacVisit && gyneacVisit.provisionalDiagnosis && gyneacVisit.provisionalDiagnosis.length >0">
                            <input type="checkbox" checked (click)="onChangeStatus('c',$event)">
                            <label class="ml-1 mr-2">Provisional Diagnosis</label>
                        </div>
                        <div *ngIf="fullTranscript">
                            <input type="checkbox" checked (click)="onChangeStatus('k',$event)">
                            <label class="ml-1 mr-2">Signature Block</label>
                        </div>
                        <div *ngIf="vitalsdata && vitalsdata.length > 0">
                            <input type="checkbox" checked (click)="onChangeStatus('VT',$event)">
                            <label class="ml-1 mr-2">Vitals</label>
                        </div>

                        <div *ngIf="infusions && infusions.length>0">
                            <input type="checkbox" checked (click)="onChangeStatus('infusion',$event)">
                            <label class="ml-1 mr-2">Infusion</label>
                        </div>
                        <div *ngIf="scanTests && scanTests.length > 0">
                            <input type="checkbox" checked (click)="onChangeStatus('sc',$event)">
                            <label class="ml-1 mr-2">Scan Appointments</label>
                        </div>
                        <div *ngFor="let item  of  orderdata; let i=index;">
                            <input type="checkbox" [(ngModel)]="item.isSelected" />
                            <label class="ml-1 mr-2">{{item.orderName}}</label>

                        </div>
                    </div>
                </div>
                <div class="col-1">
                    <button [useExistingCss]="true" id="printButton" printSectionId="obprint" ngxPrint class="close d-print-none"> <i class="fe-printer" placement="left" ngbTooltip="PrintEncounter"></i></button>
                </div>
            </div>
            <div id="obprint" class="border-left border-right border-bottom">
                <div class="report bg-white position-relative">
                    <div class="clearfix mb-3">
                        <div>
                            <div class="row" *ngIf="page.loading">
                                <div class="col-12">
                                    <div class="d-flex align-items-center">
                                        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                                        <span class="ml-2">Please wait while generating IEncounter Prescription ...</span>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="!page.loading && fullTranscript && !isTeleconsulatantReport">
                                <div class="col-12" *ngIf="isPrintLogo">
                                    <banner-setting [reportName]='"Gny Prescription Header"'></banner-setting>
                                </div><hr class="mb-0" />
                            </ng-container>

                            <ng-container *ngIf="!page.loading && fullTranscript">
                                <div class="overflow-auto">
                                    <div class="border-dark border-top d-flex justify-content-between  pb-1 pt-1">
                                        <h4 class="pl-0" *ngIf="isTeleconsulatantReport">Online Consultation</h4>
                                        <h4 *ngIf="!isTeleconsulatantReport" class="pl-0"><strong>Prescription</strong></h4>
                                        <h4 class="white-space-nowrap pr-0 pr-lg-3"><strong>Department of Gynaecology</strong></h4>
                                    </div>
                                    <table class="w-100 mb-1 white-space-nowrap border-dark border-bottom border-collapse">
                                        <tr class="border-top-dotted vertical-align-top">
                                            <td class="w-10px">
                                                <div>
                                                    <span class="text-black">Patient Name</span>
                                                </div>
                                            </td>
                                            <td class="w-10px">
                                                <span>:</span>
                                            </td>
                                            <td class="white-space-break">
                                                <strong *ngIf="fullTranscript && fullTranscript.patientName" class="font-weight-bold text-uppercase" [textContent]="fullTranscript.patientName"></strong>
                                            </td>
                                            <td class="w-10px">
                                                <span class=" text-black">UMR No</span>
                                            </td>
                                            <td class="w-10px">
                                                <span>:</span>
                                            </td>
                                            <td class="w176-md w200-lg">
                                                <strong *ngIf="fullTranscript && fullTranscript.umrNo" class="font-weight-bold" [textContent]="fullTranscript.umrNo"></strong>
                                            </td>
                                        </tr>
                                        <tr class="border-top-dotted vertical-align-top">
                                            <td>
                                                <div>
                                                    <span class="text-black">Age</span>
                                                </div>
                                            </td>
                                            <td>
                                                <span>:</span>
                                            </td>
                                            <td>
                                                <strong *ngIf="appointment && appointment.patientAge" class="font-weight-bold">{{appointment.patientAge}} &nbsp;years &nbsp;&nbsp;</strong>
                                            </td>
                                            <td>

                                                <span class="text-black">Gync No</span>

                                            </td>
                                            <td><span>:</span></td>
                                            <td>
                                                <strong *ngIf="gynCardGeneration && gynCardGeneration.gynNo" class="font-weight-bold" [textContent]="gynCardGeneration.gynNo"></strong>
                                            </td>
                                        </tr>
                                        <tr class="vertical-align-top">
                                            <td>
                                                <span class="text-black">W/O</span>
                                            </td>
                                            <td><span>:</span></td>
                                            <td><strong *ngIf="husbandName" class="font-weight-bold text-uppercase white-space-break" [textContent]="husbandName"></strong></td>
                                            <td>
                                                <span class="text-black">Visit Date</span>
                                            </td>
                                            <td>
                                                <span>:</span>
                                            </td>
                                            <td>
                                                <strong class="font-weight-bold" *ngIf="appointment && appointment.appointmentDate" [textContent]="appointment.appointmentDate | date: 'dd/MM/yyyy'"></strong>
                                            </td>
                                        </tr>
                                        <tr class="white-space-nowrap">
                                            <td>
                                                <span class="text-black">Consultant Dr.</span>
                                            </td>
                                            <td>
                                                <span>:</span>
                                            </td>
                                            <td>
                                                <strong class="font-weight-bold white-space-break text-uppercase" *ngIf="fullTranscript.providerName" [textContent]="fullTranscript.providerName"></strong>
                                            </td>
                                            <td>
                                                <span class="text-black">Visit No</span>
                                            </td>
                                            <td>
                                                <span>:</span>
                                            </td>
                                            <td>
                                                <strong class="font-weight-bold" *ngIf="visitNo" [textContent]="visitNo"></strong>
                                            </td>
                                        </tr>
                                        <tr class="vertical-align-top">
                                            <td>
                                                <span class="text-black">Medical Reg No</span>
                                            </td>
                                            <td>
                                                <span>:</span>
                                            </td>
                                            <td>
                                                <strong class="font-weight-bold" *ngIf="fullTranscript && fullTranscript.providerNo" [textContent]="fullTranscript.providerNo"></strong>
                                            </td>
                                            <td>
                                                <span class="text-black">LMP</span>
                                            </td>
                                            <td> <span>:</span></td>
                                            <td> <strong class="font-weight-bold" *ngIf="gynHistory && gynHistory.lmp" [textContent]="gynHistory.lmp | date: 'dd/MM/yyyy'"></strong></td>
                                        </tr>
                                    </table>
                                </div>
                            </ng-container>
                            <div *ngIf="a1_Block">
                                <div *ngIf="measures && measures!== null  && (measures.weight|| measures.heightInFeet|| measures.bodyMassIndex )"> <h5 class="font-weight-bold text-black">Measures</h5></div>
                                <div class="mb-2" *ngIf="measures && measures!== null  && (measures.weight|| measures.heightInFeet|| measures.bodyMassIndex )">
                                    <table width="100%">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span class="text-black">
                                                        Weight(Kg) :
                                                    </span>
                                                    <strong class="text-black font-weight-bold" *ngIf="measures && measures.weight" [textContent]="measures.weight"></strong>
                                                </td>
                                                <td>
                                                    <span class=" text-black">
                                                        Height(In Feet) :
                                                    </span>
                                                    <strong class="text-black font-weight-bold" *ngIf="measures && measures.heightInFeet" [textContent]="measures.heightInFeet"></strong>
                                                </td>

                                                <td>
                                                    <span class="text-black pl-2">
                                                        BMI :
                                                    </span>
                                                    <strong class="text-black font-weight-bold" *ngIf="measures && measures.bodyMassIndex" [textContent]="measures.bodyMassIndex +'  Kg/m2'"></strong>
                                                </td>
                                                <td *ngIf="gyneacVisit && gyneacVisit.systolicBP && gyneacVisit.diastolicBP ">
                                                    <span class="text-black pl-2">
                                                        BP :
                                                    </span>
                                                    <strong class="text-black font-weight-bold" *ngIf="gyneacVisit && gyneacVisit.systolicBP && gyneacVisit.diastolicBP ">{{gyneacVisit.systolicBP}}/{{gyneacVisit.diastolicBP + '  mmHg'}}</strong>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>



                            <div *ngIf="a_Block">
                                <div class="mb-2" *ngIf="bookingHeader && bookingHeader.length > 0">
                                    <div> <h5 class="font-weight-bold text-black">Investigations</h5> </div>
                                    <div class="pr-3" *ngFor="let item of bookingHeader;let i =index;">
                                        <span class=" text-wrap" [textContent]="item.testName"></span>
                                        (&nbsp;<span class=" text-wrap" [textContent]="item.departmentName"></span>&nbsp;)
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="b_Block" class="mt-2">
                                <div *ngIf="records.length > 0">
                                    <div class="dashboard-body">
                                        <div *ngFor="let record of records;index as j;">
                                            <div class="overflow-auto mt-1 mb-1">
                                                <table class="w-100" style="border-collapse: collapse;">
                                                    <thead>
                                                        <tr class=" vertical-align-top">
                                                            <th><h5>Medications Adviced</h5> </th>
                                                            <th><h5>Generic Name</h5></th>
                                                            <th><h5>Frequency</h5></th>
                                                            <th *ngIf="isMedicationInstruction"><h5>Instructions</h5></th>
                                                            <th><h5>Route</h5></th>
                                                            <th><h5>Duration</h5></th>
                                                            <th><h5>Qty</h5></th>
                                                            <th><h5>Dosage</h5></th>

                                                            <th><h5>Category</h5></th>

                                                            <th><h5>Remarks</h5></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr class=" vertical-align-top">
                                                        <tr class=" vertical-align-top" *ngFor="let item of record.medicines; let i = index">
                                                            <td class="min-width120px">
                                                                <div>
                                                                    <div class=" text-wrap text-uppercase " [textContent]="item.productName "></div>
                                                                </div>
                                                            </td>
                                                            <td class="min-width120px">
                                                                <div>
                                                                    <span class=" text-wrap " *ngIf="item.genericName" [textContent]="item.genericName | titlecase "></span>
                                                                    <span class=" text-wrap text-lowercase" *ngIf="!item.genericName" [textContent]="'--'"></span>
                                                                </div>
                                                            </td>
                                                            <td class="min-width120px">
                                                                <div>
                                                                    <div class=" text-wrap text-lowercase " [textContent]="item.instruction "></div>
                                                                </div>
                                                            </td>
                                                            <td *ngIf="isMedicationInstruction">
                                                                <div>
                                                                    <span class="text-capitalize" *ngIf="item.isMorning" [textContent]="item.morningDosage"></span>
                                                                </div>
                                                                <div>
                                                                    <span class="text-capitalize" *ngIf="item.isAfternoon" [textContent]="item.afternoonDosage"></span>
                                                                </div>
                                                                <div>
                                                                    <span class="text-capitalize" *ngIf="item.isNight" [textContent]="item.nightDosage"></span>
                                                                </div>
                                                            </td>
                                                            <td class="min-width120px">
                                                                <div>
                                                                    <div class=" text-wrap text-lowercase " [textContent]="item.route "></div>
                                                                </div>
                                                            </td>
                                                            <td class="min-width120px">
                                                                <div>
                                                                    <div class=" text-wrap text-lowercase " [textContent]="item.duration "></div>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div>
                                                                    <span class="text-capitalize" *ngIf="item.dosage" [textContent]="item.dosage"></span>
                                                                </div>
                                                            </td>

                                                            <td class="min-width100px">
                                                                <div>
                                                                    <span class="" *ngIf="item.instruction" [textContent]="item.instruction | titlecase"></span>

                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div>
                                                                    <span class="text-capitalize" *ngIf="item.categoryName" [textContent]="item.categoryName">
                                                                    </span>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div>
                                                                    <span class="text-capitalize" *ngIf="item.remark" [textContent]="item.remark"></span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="vt_block" class="mt-2">
                                <div *ngIf="vitalsdata && vitalsdata.length > 0">
                                    <div class="dashboard-body">
                                        <div>
                                            <div class="overflow-auto mt-1 mb-1">
                                                <h5>Vitals</h5>
                                                <table class="w-100">
                                                    <thead>
                                                        <tr class=" vertical-align-top">
                                                            <th><h5 class=" ">vitals Name</h5> </th>
                                                            <th><h5 class=" ">Gtime</h5></th>
                                                            <th><h5 class=" ">Freqvency</h5></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr class=" vertical-align-top">
                                                        <tr class=" vertical-align-top" *ngFor="let item of vitalsdata; let i = index">
                                                            <td class="min-width120px">
                                                                <div>
                                                                    <div class=" text-wrap" [textContent]="item.name"></div>
                                                                </div>
                                                            </td>
                                                            <td class="min-width120px">
                                                                <div>
                                                                    <div class=" text-wrap" [textContent]="item.givenTime"></div>
                                                                </div>
                                                            </td>
                                                            <td class="min-width120px">
                                                                <div>
                                                                    <div class=" text-wrap" [textContent]="item.frequency"></div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="infuion_Block">
                                <div *ngIf="isAdmission" class="mt-2">
                                    <div *ngIf="infusions && infusions.length>0">
                                        <div class="dashboard-body">
                                            <div>
                                                <div class="overflow-auto mt-1 mb-1">
                                                    <table class="w-100">
                                                        <thead>
                                                            <tr class=" vertical-align-top">

                                                                <th><h5>Infusion Name</h5> </th>
                                                                <th><h5>Medicine Name</h5></th>
                                                                <th><h5>Device</h5></th>
                                                                <th><h5>Infusion Route</h5></th>
                                                                <th><h5>Duration Unit</h5></th>
                                                                <th><h5>Duration</h5></th>
                                                                <th><h5>Flowrate Units</h5></th>
                                                                <th><h5>FlowRate Ml</h5></th>
                                                                <th><h5>Preparation</h5></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr class=" vertical-align-top">
                                                            <tr class=" vertical-align-top" *ngFor="let item of infusions;">
                                                                <td class="min-width120px">
                                                                    <div>
                                                                        <div class=" text-wrap text-uppercase" [textContent]="item.pharmacyInfusionName || '--'"></div>
                                                                    </div>
                                                                </td>
                                                                <td class="min-width120px">
                                                                    <div>
                                                                        <span class=" text-wrap text-uppercase" *ngIf="item.pharmacyProductName" [textContent]="item.pharmacyProductName"></span>
                                                                        <span class=" text-wrap text-uppercase" *ngIf="!item.pharmacyProductName" [textContent]="'--'"></span>
                                                                    </div>
                                                                </td>
                                                                <td class="min-width100px">
                                                                    <div>
                                                                        <span class="text-capitalize" *ngIf="item.deviceName" [textContent]="item.deviceName"></span>

                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <span class="text-capitalize" *ngIf="item.infusionRoute" [textContent]="item.infusionRoute"></span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <span class="text-capitalize" *ngIf="item.durationUnit" [textContent]="item.durationUnit"></span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <span class="text-capitalize" *ngIf="item.duration" [textContent]="item.duration"></span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <span class="text-capitalize" *ngIf="item.flowrateUnits" [textContent]="item.flowrateUnits"></span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <span class="text-capitalize" *ngIf="item.flowrateMl" [textContent]="item.flowrateMl"></span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <span class="text-capitalize" *ngIf="item.prepration" [textContent]="item.prepration"></span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="l_Block">
                                <div class="mb-2" *ngIf="gyneacVisit && gyneacVisit.provisionalDiagnosis && gyneacVisit.provisionalDiagnosis.length >0">
                                    <!--gt-->
                                    <div>
                                        <h5 class="font-weight-bold text-black">Provisional Diagnosis</h5>
                                    </div>
                                    <ul class="list-unstyled">
                                        <li *ngFor="let item of gyneacVisit.provisionalDiagnosis; let i =index;">
                                            <span class=" text-wrap" [textContent]="item"></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>


                            <div class="mt-2">
                                <div *ngIf="ordersPrescription && ordersPrescription.orderData.length >0 ">
                                    <div *ngFor="let it of orderdata;let i=index;">
                                        <div *ngIf="it.isSelected">
                                            <div *ngFor="let item of ordersPrescription.orderData">
                                                <div *ngIf="item.orderName===it.orderName">
                                                    <div class="w-100" *ngIf="item.comments && item.comments !== null">
                                                        <h5 class=" dashboard-title" *ngIf="item.comments && item.comments !== null" [textContent]="item.orderName"></h5>
                                                        <table>
                                                            <tr class="pl-1 vertical-align-top" [innerHTML]="item.comments"> </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div *ngIf="j_Block">
                                <div class="mb-2" *ngIf="referralOrder && referralOrder.length !== 0">
                                    <div><h5 class="font-weight-bold text-black">Referrals</h5></div>
                                    <table class=" mt-1 mb-1" style="border-collapse: collapse;">
                                        <thead>
                                            <tr>
                                                <th>Doctors</th>
                                                <th>Comments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of referralOrder; let i = index">
                                                <td class="w-200p vertical-align-top   ">
                                                    <div [textContent]="item.name"></div>
                                                <td class="w-200p vertical-align-top   ">
                                                    <div [textContent]="item.comments"></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div *ngIf="sc_Block" class="mt-2">
                                <div *ngIf="scanTests && scanTests.length > 0">
                                    <h5>Scan Appointments</h5>
                                    <table class=" mt-1 mb-1">
                                        <thead>
                                            <tr class=" vertical-align-top">
                                                <td class="w-200p vertical-align-top "><h5>Sacn Test Name</h5> </td>
                                                <td class="w-200p vertical-align-top "><h5>Comments</h5> </td>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr *ngFor="let item of scanTests; let i = index">
                                                <td>
                                                    <div class=" text-wrap" [textContent]="item.name"></div>
                                                </td>
                                                <td>
                                                    <div class=" text-wrap" [textContent]="item.comments"></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="d-flex align-items-baseline font-weight-medium w-100" *ngIf="gynHistory && gynHistory.presentingIllness">
                                <div class="col-3">
                                    <h5>Presenting Illness:</h5>
                                </div>
                                <span [textContent]="gynHistory.presentingIllness"></span>
                            </div>
                            <div *ngIf="scanAppointments && scanAppointments.length>0">
                                <div>
                                    <div><h5 class="font-weight-bold text-black">Scan Appointment</h5></div>
                                    <div *ngFor="let item of scanAppointments; index as i ">
                                        <span>Scan Scheduled for {{item.machineName}} at {{item.locationName}} on {{item.appointmentDate | date :'dd/MM/yyyy'}} at {{item.appointmentTimeString}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2" *ngIf="futureAppointments && futureAppointments.length>0">
                                <div>
                                    <h5 class="dashboard-title ">Next Visit Date:</h5>
                                    <div *ngFor="let item of futureAppointments; index as i ">
                                        <span>Next visit with  {{item.providerName}} at {{item.locationName}} on {{item.appointmentDate | date :'dd/MM/yyyy'}} at {{item.appointmentTimeString}}</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="k_Block">
                                <div *ngIf="!isTeleconsulatantReport">
                                    <div class=" align-items-end col-12 d-flex flex-wrap justify-content-between p-0 pt-1 column-gap15px">
                                        <div class="d-flex flex-grow-1">
                                            <div><span>Email :&nbsp;</span></div>
                                            <div *ngIf="fullTranscript && fullTranscript.providerEmail" class="text-truncate"><h5 class="text-lowercase" [textContent]="fullTranscript.providerEmail"></h5></div>
                                        </div>
                                        <div class="d-flex flex-wrap column-gap15px align-items-end justify-content-end flex-grow-1">
                                            <div class="d-flex">
                                                <div><span>Mob :&nbsp;</span></div>
                                                <div *ngIf="fullTranscript && fullTranscript.providerMobile"><h5 [textContent]="fullTranscript.providerMobile"></h5></div>
                                            </div>
                                            <div class="d-flex flex-grow-1 justify-content-center">
                                                <div>
                                                    <span>
                                                        User Name :&nbsp;
                                                    </span>
                                                </div>
                                                <div><h5 class="text-uppercase" *ngIf="fullTranscript && fullTranscript.friendlyName" [textContent]="fullTranscript.friendlyName"></h5></div>
                                            </div>
                                            <div class="d-flex align-items-end">
                                                <div><span>Signature :&nbsp;</span></div>
                                                <div><h5 class="border-bottom border-dark min-width100px"><img *ngIf="appointment && appointment.signature!=null" [src]="safe(appointment.signature)" class="height img-fluid rounded-circle" /></h5></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-2">
                                <h4>
                                    EMERGENCY CONTACT : FH - {{currentLocation}} Labour Ward Contact No:
                                    <span *ngIf="emergencyContactsLocationBased">
                                        <span [textContent]="emergencyContactsLocationBased[0].emergencyContacts"></span>
                                    </span>
                                </h4>
                            </div>
                            <div *ngIf="isTeleconsulatantReport">
                                <h4 class="dashboard-title font-17  ">Disclaimer</h4>
                                <div class="mb-2">
                                    Limitations of TELE-CONSULT exist and it can never be equated to a clinical consultation. The doctor’s advice is only based on the
                                    accuracy of verbal details provided by you as the mandatory physical examination is not possible. This consultation is only to help you
                                    access health care and is not permitted for any medico-legal purposes. We disclaim any liability or responsibility, of harm or injury
                                    happening due to misinformation, misrepresentation, misinterpretation and impersonation. If you are experiencing an urgent
                                    medical problem, please visit the nearest emergency care facility. Your request / acceptance of tele-consult will be taken as your
                                    consent with its ingrained limitations and your session will be recorded. Your information and privacy will be guarded
                                </div>
                            </div>
                            <table class="w-100" *ngIf="isTeleconsulatantReport">
                                <tr>
                                    <td><span>Email ID:</span><strong>{{fullTranscript.providerEmail}}</strong></td>
                                    <td><span class="mr-4 text-uppercase">User Nmae:</span><strong>{{fullTranscript.providerEmail}}</strong></td>
                                </tr>
                            </table>
                            <div class="border-dark border-top p-0 pt-1">
                                <table class="w-100">
                                    <tr>
                                        <td><strong>Print Date/Time :&nbsp;</strong>  <span *ngIf="currentDate">{{currentDate | date:'dd/MM/yyyy, hh:mm a'}}</span></td>
                                        <td><strong>Printed By :&nbsp;</strong>  <span class="text-uppercase" *ngIf="printBy">{{printBy}}</span></td>
                                        <td *ngIf="isTeleconsulatantReport" class="text-right"><span>This is a computer generated prescription</span></td>
                                    </tr>
                                </table>
                            </div>
                            <div class="border-dark border-top p-0 pt-1">
                                <div class="col-12" *ngIf="isFooter">
                                    <banner-setting [reportName]='"Gny Prescription Footer"'></banner-setting>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>