<div>
    <div class="sidebar-main-menu bg-pattern lgSubMenu   addedLeft" style="background-size: 200%; background-repeat: no-repeat;" *ngIf="sendpatientId && isPatientSelected">
        <common-data *ngIf="sendpatientId && isPatientSelected" [commonid]="sendpatientId"></common-data>
        <div>
            <div class="overflow-auto">
                <table class="table table-centered table-bordered table-sm mb-0">
                    <tbody>
                        <tr>
                            <td class="small">
                                <div class="d-flex justify-content-center">
                                    <span class="font-weight-bold">Admission No</span>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <div class="small" [textContent]="menuPatientData.admissionNo"></div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="small">
                                <div class="d-flex justify-content-center">
                                    <div class="small"
                                         [textContent]="(menuPatientData.admissionDate | date: 'dd-MM-yyyy') + (menuPatientData.timeString ? ' ' + (menuPatientData.timeString) : '')">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="small">
                                <div class="d-flex justify-content-center">
                                    <i class="mdi mdi-information mr-1 text-primary" placement="left" ngbTooltip="Room Name >> Bed Number"></i>
                                    <span class="small" [textContent]="menuPatientData.roomName || '---'"></span>
                                    <span><i class="mdi mdi-chevron-double-right mx-1 text-primary"></i></span>
                                    <span class="small" [textContent]="menuPatientData.bedNumber || '---'"></span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="small">
                                <div class="d-flex justify-content-center">
                                    <i class="mdi mdi-information mr-1 text-primary" placement="left" ngbTooltip="Floor Name >> Ward Name"></i>
                                    <span class="small" [textContent]="menuPatientData.floorName || '---'"></span>
                                    <span><i class="mdi mdi-chevron-double-right mx-1 text-primary"></i></span>
                                    <span class="small" [textContent]="menuPatientData.wardName || '---'"></span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="small">
                                <div class="d-flex justify-content-center">
                                    <i class="mdi mdi-information mr-1 text-primary" placement="left" ngbTooltip="Charge Category"></i>
                                    <span class="small" [textContent]="menuPatientData.chargeCategoryName || '---'"></span>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="small">
                                <div class="d-flex justify-content-center">
                                    <span class="small">
                                        <i class="mdi mdi-information mr-1 text-primary" placement="left" ngbTooltip="Pay Type"></i>
                                        <span [textContent]="menuPatientData.payType || '---'"></span>
                                    </span>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <!--Please.. Don't remove-->
    <div id="dvContent"></div>
    <!-- This is related to salucro iframe content-->
    <div [ngClass]="{'margin-left-220': isPatientSelected}">
        <div class="content">
            <div class="container-fluid">

                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box text-right">
                            <div class="page-title-right text-right">
                                <div class="radio-inline-group p-2">
                                    <div class="custom-control custom-radio mb-0">
                                        <input type="radio" class="custom-control-input" name="test" (change)="onchange('C')" checked value="changeroom" id="changeroom">
                                        <label class="custom-control-label" for="changeroom">Change Room</label>
                                    </div>
                                    <div class="custom-control custom-radio mb-0">
                                        <input type="radio" class="custom-control-input" name="test" (change)="onchange('R')" value="releaseroom" id="releaseroom">
                                        <label class="custom-control-label" for="releaseroom">Release Room</label>
                                    </div>
                                </div>
                            </div>
                            <h4 class="text-uppercase">IP Change Room</h4>
                        </div>



                        <div class="container" *ngIf="showRoom">
                            <div col="row">
                                <form [formGroup]="ipChangeRoom" (ngSubmit)="onSubmitChanged()">
                                    <ng-container>
                                        <div class="row">
                                            <div class="col-9">
                                                <div class="card mb-0">
                                                    <div class="card-body font-13 p-2">
                                                        <div class="row col-12">
                                                            <div class="col-12 col">
                                                                <div class="form-group">
                                                                    <label class="mb-1">Admission number</label>
                                                                    <!--<ng-select class="ng-select-sm text-uppercase" [items]="admissions"
                                                                               bindLabel="patientName"
                                                                               bindValue="admissionId"
                                                                               autocomplete="nope"
                                                                               placeholder="Select admission no"
                                                                               [multiple]="false"
                                                                               (change)="onSelectAdmission($event)"
                                                                               [ngClass]="{ 'is-invalid': submitted && form.admissionId.errors }"
                                                                               formControlName="admissionId">
                                                                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                                            <div class="ng-option disabled">
                                                                                No admissions found for '{{searchTerm}}'
                                                                            </div>
                                                                        </ng-template>
                                                                        <ng-template ng-option-tmp let-item="item" let-i="index">
                                                                            <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.admissionNo"></h5>
                                                                            <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.patientName"></h5>
                                                                            <span class="text-muted d-block font-13" [textContent]="item.umrNo"></span>
                                                                            <span class="text-muted d-block font-13" [textContent]="item.patientMobile"></span>
                                                                        </ng-template>
                                                                    </ng-select>-->



                                                                    <ng-select class="ng-select-sm text-uppercase" [items]="admissions"
                                                                               bindLabel="admissionNo"
                                                                               bindValue="admissionId"
                                                                               autocomplete="nope"
                                                                               placeholder="Select admission no"
                                                                               [multiple]="false"
                                                                               [searchFn]="searchAdmission"
                                                                               (change)="onSelectAdmission($event)"
                                                                               [ngClass]="{ 'is-invalid': submitted && form.admissionId.errors }"
                                                                               formControlName="admissionId">
                                                                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                                            <div class="ng-option disabled">
                                                                                No admissions found for '{{searchTerm}}'
                                                                            </div>
                                                                        </ng-template>
                                                                        <ng-template ng-option-tmp let-item="item" let-i="index">
                                                                            <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.admissionNo"></h5>
                                                                            <h4 class="mb-1 mt-0 font-weight-normal" [textContent]="item.patientName"></h4>
                                                                            <span class="text-muted d-block font-13" [textContent]="item.umrNo"></span>
                                                                            <span class="text-muted d-block font-13" [textContent]="item.patientMobile"></span>
                                                                        </ng-template>
                                                                    </ng-select>




                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="page-title-box">
                                                        <h4 class="page-title">Change Bed To</h4>
                                                    </div>
                                                    <div class="card-body font-13 p-2">
                                                        <div class="row col-12">
                                                            <div class="col-12 col">
                                                                <div class="form-group">
                                                                    <label class="mb-1">Bed Number</label>
                                                                    <ng-select type="text" class="ng-select-sm text-uppercase" [items]="allBeds"
                                                                               bindLabel="bedNumber"
                                                                               bindValue="bedId"
                                                                               autocomplete="nope"
                                                                               placeholder="Search by Bed"
                                                                               (change)="onchangeBed($event)"
                                                                               [ngClass]="{ 'is-invalid': submitted && form.changedBedNumber.errors }"
                                                                               formControlName="changedBedNumber">
                                                                    </ng-select>
                                                                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                                        <div class="ng-option disabled">
                                                                            No beds found for '{{searchTerm}}'
                                                                        </div>
                                                                    </ng-template>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col">
                                                                <div class="form-group">
                                                                    <label class="mb-1">Room Name</label>
                                                                    <input type="text" readonly formControlName="changedRoomName" autocomplete="nope" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col">
                                                                <div class="form-group">
                                                                    <label class="mb-1">Ward Name</label>
                                                                    <input type="text" readonly formControlName="changedWardName" autocomplete="nope" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col">
                                                                <div class="form-group">
                                                                    <label class="mb-1">Floor Name</label>
                                                                    <div class="form-group">
                                                                        <input type="text" readonly formControlName="changedFloorName" autocomplete="nope" class="form-control" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="justify-content-end d-flex">
                                                            <button type="submit" [disabled]="isSubmitting" tabindex="0" class="btn btn-primary btn-sm ml-1">
                                                                <span *ngIf="isSubmitting">
                                                                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                                    Please wait..
                                                                </span>
                                                                <span *ngIf="!isSubmitting">Submit</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </form>
                            </div>
                        </div>
                        <div class="container" *ngIf="!showRoom">
                            <div col="row">
                                <form [formGroup]="ipChangeRoom" (ngSubmit)="onSubmitReleased()">
                                    <ng-container>
                                        <div class="row">
                                            <div class="col-9">
                                                <div class="card-body font-13 p-2">
                                                    <div class="row col-12">
                                                        <div class="col-12 col">
                                                            <div class="form-group">
                                                                <label class="mb-1">Admission number</label>
                                                                <!--<ng-select class="ng-select-sm text-uppercase" [items]="changedRooms"
                                                                           bindLabel="patientName"
                                                                           bindValue="admissionId"
                                                                           autocomplete="nope"
                                                                           placeholder="Select admission no"
                                                                           [multiple]="false"
                                                                           (change)="getSelectedChangedRoom($event)"
                                                                           [ngClass]="{ 'is-invalid': submitted && form.admissionId.errors }"
                                                                           formControlName="admissionId">
                                                                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                                        <div class="ng-option disabled">
                                                                            No admissions found for '{{searchTerm}}'
                                                                        </div>
                                                                    </ng-template>
                                                                    <ng-template ng-option-tmp let-item="item" let-i="index">
                                                                        <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.admissionNo"></h5>
                                                                        <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.patientName"></h5>
                                                                        <span class="text-muted d-block font-13" [textContent]="item.umrNo"></span>
                                                                        <span class="text-muted d-block font-13" [textContent]="item.patientMobile"></span>
                                                                    </ng-template>
                                                                </ng-select>-->



                                                                <ng-select class="ng-select-sm text-uppercase" [items]="changedRooms"
                                                                           bindLabel="admissionNo"
                                                                           bindValue="admissionId"
                                                                           autocomplete="nope"
                                                                           placeholder="Select admission no"
                                                                           [multiple]="false"
                                                                           [searchFn]="searchAdmission"
                                                                           (change)="getSelectedChangedRoom($event)"
                                                                           [ngClass]="{ 'is-invalid': submitted && form.admissionId.errors }"
                                                                           formControlName="admissionId">
                                                                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                                        <div class="ng-option disabled">
                                                                            No admissions found for '{{searchTerm}}'
                                                                        </div>
                                                                    </ng-template>
                                                                    <ng-template ng-option-tmp let-item="item" let-i="index">
                                                                        <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.admissionNo"></h5>
                                                                        <h4 class="mb-1 mt-0 font-weight-normal" [textContent]="item.patientName"></h4>
                                                                        <span class="text-muted d-block font-13" [textContent]="item.umrNo"></span>
                                                                        <span class="text-muted d-block font-13" [textContent]="item.patientMobile"></span>
                                                                    </ng-template>
                                                                </ng-select>



                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card mb-0">
                                                    <div class="page-title-box">
                                                        <h4 class="page-title">Change Bed To</h4>
                                                    </div>
                                                    <div class="card-body font-13 p-2">
                                                        <div class="row col-12">
                                                            <div class="col-12 col">
                                                                <div class="form-group">
                                                                    <label class="mb-1">Bed Number</label>
                                                                    <input type="text" readonly formControlName="changedBedNumber" autocomplete="nope" class="form-control" />

                                                                </div>
                                                            </div>
                                                            <div class="col-12 col">
                                                                <div class="form-group">
                                                                    <label class="mb-1">Room Name</label>
                                                                    <input type="text" readonly formControlName="changedRoomName" autocomplete="nope" class="form-control" />

                                                                </div>
                                                            </div>

                                                            <div class="col-12 col">
                                                                <div class="form-group">
                                                                    <label class="mb-1">Ward Name</label>
                                                                    <input type="text" readonly formControlName="changedWardName" autocomplete="nope" class="form-control" />

                                                                </div>
                                                            </div>
                                                            <div class="col-12 col">
                                                                <div class="form-group">
                                                                    <label class="mb-1">Floor Name</label>
                                                                    <input type="text" readonly formControlName="changedFloorName" autocomplete="nope" class="form-control" />

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="justify-content-end d-flex">
                                                            <button type="submit" [disabled]="isSubmitting" tabindex="0" class="btn btn-primary btn-sm ml-1">
                                                                <span *ngIf="isSubmitting">
                                                                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                                    Please wait..
                                                                </span>
                                                                <span *ngIf="!isSubmitting">Submit</span>
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
