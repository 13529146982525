<div class="content" style="background-color:white; font-size:30px" >
    <div class="container-fluid">
        <div class="d-flex align-content-between">
            <div class="col-6">
                <h1 style="color: #fbde06; margin-left: 30px">Operation Theater Schedule</h1>
            </div>
            <div class="col-2" style="margin-top:20px">
                Date : 2024-11-05
            </div>
            <div class="col-4">
                <banner-setting [reportName]='"Lab Invoice Header"'></banner-setting>
            </div>
        </div>
        <!--<div class="row">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading...</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mh-300">
                <div class="no-data">
                    <img src="assets/images/no-data.png" alt="No data" />
                    <h4 class="title">Oops! No Data found.</h4>
                    <p class="sub-title">There is no data to show you right now.</p>
                </div>
            </div>
        </div>-->
        <div class="col-12 d-flex">
            <div class="col-6">
                <div class="d-flex">
                    <div class="col-6">
                        <span>Procedure:</span>
                    </div>
                    <div class="col-6">
                        <span>Data</span>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="col-6">
                        <span>Anaesthesia:</span>
                    </div>
                    <div class="col-6">
                        <span>Data</span>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="col-6">
                        <span>Additional Procedure:</span>
                    </div>
                    <div class="col-6">
                        <span>Data</span>
                    </div>
                </div>
                <div style="background-color: #fbde06">
                    <div class="d-flex align-content-lg-around">
                        <div class="col-11">
                            <h2>Patient Details</h2>
                        </div>
                        <div class="col-1">
                            <h1 style="color:white;"> &nbsp;</h1>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <table class="col-12 table table-striped">
                        <tbody>
                            <tr>
                                <td>
                                    <span>Name</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>W/o - D/o</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Age/wt/BMI</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>IP No./MR No.</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style="background-color: #fbde06">
                    <div class="d-flex align-content-lg-around">
                        <div class="col-11">
                            <h2>Operating Team</h2>
                        </div>
                        <div class="col-1">
                            <h1 style="color:white;"> &nbsp;</h1>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <table class="col-12 table table-striped">
                        <tbody>
                            <tr>
                                <td>
                                    <span>Surgeon(s)</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>
                                        Anaesthetist
                                    </span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Scrub Nurse 1</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Scrub Nurse 2</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style="background-color: #fbde06">
                    <div class="d-flex align-content-lg-around">
                        <div class="col-11">
                            <h2>Investigations</h2>
                        </div>
                        <div class="col-1">
                            <span style="color:white;"> &nbsp;</span>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <table class="col-12 table table-striped">
                        <thead>
                            <tr>
                                <td>NAME</td>
                                <td>DATE</td>
                                <td>REPORT</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span>Blood Group</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Hb/PCV</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>HIV</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>HbsAg</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>HCV</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style="background-color: #fbde06">
                    <div class="d-flex align-content-lg-around">
                        <div class="col-11">
                            <h2>Medications</h2>
                        </div>
                        <div class="col-1">
                            <h1 style="color:white;"> &nbsp;</h1>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <table class="col-12 table table-striped">
                        <thead>
                            <tr>
                                <td>NAME</td>
                                <td>DOSE</td>
                                <td>TIME</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span>Antibiotic</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Oxytocin</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Other</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <banner-setting [reportName]='"Lab Invoice Header"'></banner-setting>
                </div>
            </div>
            <div class="col-6">
                <div class="d-flex">
                    <div class="col-6">
                        <span>Consent:</span>
                    </div>
                    <div class="col-6">
                        <span>Data</span>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="col-6">
                        <span>Patient In-time:</span>
                    </div>
                    <div class="col-6">
                        <span>Data</span>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="col-6">
                        <span>Blood Reservation:</span>
                    </div>
                    <div class="col-6">
                        <span>Data</span>
                    </div>
                </div>



                <div style="background-color: #fbde06">
                    <div class="d-flex align-content-lg-around">
                        <div class="col-11">
                            <h2>Surgical Tray Details</h2>
                        </div>
                        <div class="col-1">
                            <h1 style="color:white;">&nbsp;</h1>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <table class="col-12 table table-striped">
                        <tbody>
                            <tr>
                                <td>
                                    <span>Swabs</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Gauze Piece</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Abdominal Pack</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Straight Artery Forceps</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Curved Artery Forceps</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Mosquito Forceps</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Allis Forceps</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Needles</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Needle Holders</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Toothed Forceps</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Non-toothed Forceps</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Towel Clips</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Badcock Forceps</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Green Armytage</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Green Armytage</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>BP Handle</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Straight Scissors</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Curved  Scissors</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Sponge Holder</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Additional Instruments</span>
                                </td>
                                <td>
                                    <span>data</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style="background-color: #fbde06">
                    <div class="d-flex align-content-lg-around">
                        <div class="col-11">
                            <h2>Clinical Details</h2>
                        </div>
                        <div class="col-1">
                            <h1 style="color:white;">&nbsp;</h1>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <table class="col-12 table table-striped">
                        <thead>
                            <tr>
                                <td>G</td>
                                <td>P</td>
                                <td>L</td>
                                <td>A</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowspan="3" colspan="4">
                                    Data
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
