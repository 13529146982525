/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { finalize, takeUntil } from "rxjs";
import { neonatalIPEncounterFullTranscript } from "../../entities/neonatal-encounter-full-transcript.entity";
import { Appointment } from "../../entities/appointment.entity";
import { Page } from "../../models/page.model";
import { PreviousAppointment } from "../../entities/previous-appointment.entity";
import { Pagination } from "../../models/pagination.model";
import { ImageReports } from "../../entities/image.entity";
import { NeoIPEncounter } from "../../models/neonatal-ip-encounter.model";
import { PatientMedicationHeader } from "../../entities/patient-medication.entity";
import { OB } from "../../../areas/admin/full-transcript/pages/ob-report/ob.namespace";
import { NeonatalIpEncounter } from "../../../areas/admin/neonatal-ip-encounter/neonatal.namespace";
import { PatientProfile } from "../../models/patient-profile.model";
import { LabBillHeaderModel } from "../../../areas/admin/labs/pages/models/lab-booking-model";
import { AppData, AppointmentToggleService, EncounterCommunication, HttpService, IconService, NotifyService, PrintOptionService, TimelineToggleService } from "../../services";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ApiResources } from "../../helpers/api.helper";
import { IUserAccount } from "../../models/user-account.model";


@Component({
    templateUrl: "./neonatal-discharge-report.html",
    selector: "neonataldischarge-summaryreport",

})
export class NeonatalDishargeReportPage implements OnInit, OnDestroy {

    @Input() printId: string;
    @Input() printadmission: boolean;
    @Input() isPrintLogo: boolean;
    fullTranscript: neonatalIPEncounterFullTranscript;
    appointments: Appointment;
    oldAppointment: PreviousAppointment;
    page: Page;
    isAdmission: boolean;
    appointmentId: string;
    routingValue: string;
    section: string;
    pagination: Pagination;
    currentDate: Date;
    iconBasics: ImageReports;
    appointment: NeoIPEncounter;
    loading: boolean;
    records: Array<PatientMedicationHeader>;
    reminders: string;
    specialFeature: OB.SpecialFeature;
    neonatalBaby: NeonatalIpEncounter.NeonatalBaby;
    nicu: NeonatalIpEncounter.Nicu;
    birthInjury: NeonatalIpEncounter.BirthInjuryConsentForm;
    neonatalInfo: NeonatalIpEncounter.NeonatalInfo;
    dischargeSummary: NeonatalIpEncounter.DischargeSummary;
    reAdmission: NeonatalIpEncounter.Readmission;
    iufdmodel: OB.IUFDEntryForm;
    refferalOrder: OB.OrderReferral;
    neonatalIntialAssesment: OB.NeonatalInitialAsessment;
    admssionSlip: OB.AdmssionSlip;

    diagnosisRecords: Array<OB.DiagnosisOrder>;
    @Input() encryptedPatientId: string;
    @Input() encryptedAppointmentId: string;
    patient: PatientProfile;
    model: NeonatalIpEncounter.NeonatalBaby;
    refferalForm: OB.ReferralForm;
    roleId: number;
    @Input() useExistingCss: boolean;
    date: Date;
    currentLocation: string;
    bookingHeader: Array<LabBillHeaderModel>;
    isFooter: boolean;
    constructor(
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
        private readonly toggleService: AppointmentToggleService,
        public timelineService: TimelineToggleService,
        private readonly printOptionService: PrintOptionService,
        private readonly iconService: IconService,
        private readonly psetEncounter: EncounterCommunication,

    ) {
        this.page = new Page();
        this.pagination = new Pagination();

        this.records = new Array<PatientMedicationHeader>();

        this.currentDate = new Date();
        this.date = new Date();
    }
    private findAppointment(appointmentId: string) {
        const request = {
            encryptedAppointmentId: appointmentId
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointments = response;
                this.fetchLabs();
                this.findPatientDetails();
              

            });

    }
    private findPatientDetails() {
        this.page.loading = true;
        this.httpService.post<PatientProfile>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.profile), { encryptedPatientId: this.appointment.encryptedPatientId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: PatientProfile) => {
                if (response) {
                    this.patient = response;

                }


            });
    }

    private findPatient() {

        this.page.loading = true;
        const request = { encryptedAppointmentId: this.appointmentId };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<any>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: any) => {
                this.encryptedPatientId = response.encryptedPatientId;              
                this.findDashboard();
            
            });

    }

    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }
    private findDashboard() {
        const request = { encryptedAppointmentId: this.appointmentId, encryptedProviderId: this.page.userAccount.encryptedReferenceId, encryptedPatientId: this.encryptedPatientId };
        request["isAdmission"] = this.isAdmission;

        this.httpService.post<neonatalIPEncounterFullTranscript>(ApiResources.getURI(ApiResources.neonatalEncounter.base, ApiResources.neonatalEncounter.findFullTranscript), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: neonatalIPEncounterFullTranscript) => {
                this.fullTranscript = response;
                if (this.fullTranscript) {
                  
                    this.neonatalBaby = this.fullTranscript.neonatalBaby ? JSON.parse(this.fullTranscript.neonatalBaby) : null;
                    this.dischargeSummary = this.fullTranscript.dischargeSummary ? JSON.parse(this.fullTranscript.dischargeSummary) : null;
                    this.admssionSlip = this.fullTranscript.admssionSlip ? JSON.parse(this.fullTranscript.admssionSlip) : null;
                    this.diagnosisRecords = this.fullTranscript.diagnosisOrder ? JSON.parse(this.fullTranscript.diagnosisOrder) : null;

                }
            });
    }
    private fetchMedication() {
        this.loading = true;
        const request = {
            encryptedAppointmentId: this.appointmentId,
            isAdmission: this.isAdmission
        }

        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                this.records = response;
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });

    }

    private fetchLabs() {
        this.loading = true;
        const request = {
            appointmentId: this.appointments.appointmentId,
            isAdmission: this.isAdmission
        }
        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetchLabs), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                if (response && response.length > 0) {
                    this.bookingHeader = response[0].labs;
                }
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.currentLocation = this.page.userAccount.locationName;
                    const url = this.router.url;
                    this.isAdmission = url.split("/")[4] === "a";
                    this.appointmentId = decodeURIComponent(url.split("/")[3]);

                    this.routingValue = url.split("/")[2];

                    this.appointmentId = this.printId;
                    this.isAdmission = this.printadmission;
                    this.findAppointment(this.appointmentId);
                    this.findPatient();
                    this.fetchMedication();
                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((params: Params) => {
                            const section = params["section"];
                            this.section = section ? section : undefined;
                        });
                } else {
                    this.page.userAccount = undefined;
                }
                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                this.iconService.getIconImage((is) => { this.iconBasics = is; });

            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }
    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
}