
<div id="invoiceId2">
    <div class="modal-body bg-white report">
        <div>
            <div class="page-header pb-1 border-bottom border-dark text-align-center" *ngIf="isPrintLogo">
                <banner-setting [reportName]='"Appointment Invoive"'> </banner-setting>
            </div>
            
        </div>
        <table class="w-100">
            <thead>
                <tr>
                    <th>

                        <!--place holder for the fixed-position header-->
                        <div class="page-header-space" *ngIf="isPrintLogo"></div>
                        <div class="height-185px" *ngIf="!isPrintLogo" ></div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div>
                            <table class="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <table class="w-100 border-collapse">
                                                <tbody>
                                                    <tr class="border-bottom-2px border-dark">
                                                        <td></td>
                                                        <td>
                                                            <h5 class="text_uppercase mb-3">DR. {{selectedAppointment.providerName}}</h5>
                                                            <div>
                                                                Consultant: <span class="text_uppercase">{{selectedAppointment.specializationName}}</span>
                                                            </div>
                                                            <div class="mb-3">
                                                                Medical Regn. No: <span class="text_uppercase">{{selectedAppointment.providerNo}}</span>
                                                            </div>
                                                        </td>
                                                        <td></td>

                                                    </tr>
                                                    <tr class="col-12 border-bottom-dotted">
                                                        <td class="col-7">
                                                            Patient Name: <h5 class="ml-3 ms-2 d-inline text_uppercase">{{selectedAppointment.patientName}}</h5>
                                                        </td>
                                                        <td class="col-3">
                                                            Age:<h5 class="ml-3 text_uppercase d-inline">{{selectedAppointment.patientAge}}</h5>
                                                        </td>
                                                        <td class="col-4">
                                                            UMR No:<h5 class="ml-3 text_uppercase d-inline">{{selectedAppointment.umrNo}}</h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">

                                                        <td class="col-4">
                                                            Partner's Name:
                                                            <div>
                                                                <h5 class="ms-2 text_uppercase ml-3 d-inline" *ngIf="relationType">   {{relationType ? relationType : "" }} {{selectedAppointment.relativeName?selectedAppointment.relativeName:""}}</h5>
                                                            </div>
                                                        </td>
                                                        <td class="col-4"></td>
                                                        <td class="col-4 white-space-nowrap">
                                                            Date:<h5 class="ml-3 text_uppercase d-inline">{{currentDate | date :'dd/MM/yyyy, h:mm a'}}</h5>
                                                        </td>


                                                    </tr>

                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <!--<tr>
                                        <td colspan="2">
                                            <table class="border-dark" width="100%" border="0" cellspacing="0" cellpadding="5" style="border-bottom: 1px dashed #333;  font-size: 16px;">
                                                <tbody>
                                                    <tr class="col-12">
                                                        <td class="col-4">
                                                            Date:<span class="ml-3 text_uppercase">{{currentDate | date :'dd/MM/yyyy, h:mm a'}}</span>
                                                        </td>
                                                        <td class="col-4">
                                                            Last Consultation Date:<span class="ml-3 text_uppercase">{{currentDate | date :'dd/MM/yyyy, h:mm a'}}</span>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>-->
                                </tbody>
                            </table>
                            <h5>
                                Prescription
                            </h5>

                        </div>
                        <div class="min-height50vh">

                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <div class="page-footer border-top2px border-dark" *ngIf="isFooter">
                            <banner-setting [reportName]='"Appointment Invoice Footer"'> </banner-setting>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>

