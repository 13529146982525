import { Component, OnDestroy, OnInit, TemplateRef, Input, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { takeUntil, finalize } from "rxjs/operators";

import { IUserAccount, Page, GenericResponse, GenericStatus, IResource, IssueIndentDisplay, AdmissionInformationModel } from "@shared/models";
import { AppData, HttpService, NotifyService, FinalBillService, PrintOptionService, ResourceService } from "@shared/services";
import { IChargeModel } from "../../../areas/admin/services/models/charge.model";
import { IPharmacyIndentModel } from "../../../areas/admin/services/models/pharmacy-indent.model";
import { DiscountType } from "../../../areas/admin/services/models/discount-type.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FinalBillBasicModel } from "../../../areas/admin/services/models/final-bill-basic.model";
import { IAdmissionModel } from "../../../areas/admin/services/models/admission.model";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { RepeatType } from "../../../areas/admin/services/models/repeat-type.model";
import { IAdmissionPackageModuleModel } from "../../../areas/admin/services/models/admission-package.model";
import { IReceiptModel } from "../../../areas/admin/services/models/receipt.model";
import { PayType } from "../../../areas/admin/services/models/pay-type.model";
import { ReceiptType } from "../../../areas/admin/services/models/receipt-type.model";
import { AutomaticType } from "../../../areas/admin/services/models/automatic-type.model";
import { ILabServicesModel } from "../../../areas/admin/services/models/lab-services.model";
import { Location } from "@angular/common";
import { NewLabServicesModel } from "../../../areas/admin/services/models/new-lab-services.model";
import { ISurgeryChargeModel } from "../../../areas/admin/services/models/surgery-charge.model";
import { IScanChargeModel } from "../../../areas/admin/services/models/scan-charge.model";
import { IDoctorVisitModel } from "../../../areas/admin/services/models/doctor-visit.model";
import { IBedChargeModel } from "../../../areas/admin/services/models/bed-service.model";
import moment from "moment";
import { DiscountTypeStrings, OverallDiscount } from "../../../areas/admin/services/models/order.model";
import { forkJoin, Subscription } from "rxjs";
import { Appointment, PharmacyStore, InsuranceAdmission, PracticeLocation, Setting, IPChangeRoom } from "@shared/entities";
import { ISurgeonChargemodel } from "../../../areas/admin/services/models/surgeonCharge.model";
import { PackageModuleModel } from "../../../areas/admin/masters/pages/package-modules/models";
import { ChargeType } from "../../enums";
import { ApiResources, DateHelper, LinqHelper, MoneyHelper } from "../../helpers";
import { AdmissionFetchHelper } from "../../../areas/admin/progress-report/shared/helper";
import { TimelineToggleService, TimelineWidgetService } from "@src/app/shared/services";

class ServiceOrder {
    generalRecords: Array<IChargeModel>;
    generalTotal: number;
    generalTotalDiscount: number;
    pharmacyIndentRecords: Array<IPharmacyIndentModel>;
    pharmacyIssuedIndentRecords: Array<IPharmacyIndentModel>;
    pharmacyIndentTotal: number;
    packageRecords: Array<IAdmissionPackageModuleModel>;
    packageTotal: number;
    labServicesRecords: Array<NewLabServicesModel>;
    labServicesTotal: number;
    labServicesTotalDiscount: number;
    insuranceForAdmissionId: number;
    surgeryServicesRecords: Array<ISurgeryChargeModel>;
    surgeryServicesTotal: number;
    surgeryServicesTotalDiscount: number;
    scanServicesRecords: Array<IScanChargeModel>;
    scanServicesTotal: number;
    scanServicesTotalDiscount: number;
    doctorVisitRecords: Array<IDoctorVisitModel>;
    doctorVisitTotal: number;
    doctorVisitTotalDiscount: number;
    bedServicesRecords: Array<IBedChargeModel>;
    bedServicesTotal: number;
    bedServicesTotalDiscount: number;
    surgeonChargeRecords: Array<ISurgeonChargemodel>;
    surgeonChargeTotal: number;
    surgeonChargeTotalDiscount: number;
    pharmacyServicesTotalDiscount: number;
}

class FinalBill {
    generalRecords: Array<IChargeModel>;
    pharmacyRecords: Array<IPharmacyIndentModel>;
    packageRecords: Array<IAdmissionPackageModuleModel>;
    labServicesRecords: Array<ILabServicesModel>;
    surgeryServiceRecords: Array<ISurgeryChargeModel>;
    scanServiceRecords: Array<IScanChargeModel>;
    doctorVisitRecords: Array<IDoctorVisitModel>;
    bedServiceRecords: Array<IBedChargeModel>;
    surgeonChargeRecords: Array<ISurgeonChargemodel>;
    basics: FinalBillBasicModel;
    overallServiceDiscounts: Array<OverallDiscount>;
}

class PharmacyInpatientReport {
    billNumber: string;
    createdDate: Date;
    record: Array<IssueIndentDisplay>;

    constructor() {
        this.record = new Array<IssueIndentDisplay>();
    }
}

enum EditMode {
    New = 1,
    Final = 2
}

@Component({
    selector: "service-timeline",
    styleUrls: ["./service-timeline.css"],
    templateUrl: "./service-timeline.html"
})
export class ServiceTimelineWidget implements OnInit, OnDestroy {
    @ViewChild("reasonForCancel", { static: true }) reasonForCancel: TemplateRef<any>;
    @ViewChild("reasonforDiscount", { static: true }) reasonforDiscount: TemplateRef<any>;
    page: Page;
    editMode = EditMode;
    discountTypeEnum = DiscountType;
    repeatType = RepeatType;
    automaticType = AutomaticType;
    chargeType = ChargeType;
    receiptType = ReceiptType;
    payType = PayType;
    loading: boolean;
    isChargesLoading: boolean;
    serviceOrder: ServiceOrder;
    chargeRecords: Array<IChargeModel>;

    now: Date;

    submitting: boolean;
    submitted: boolean;
    isAdmission: boolean;
    disableActionBtns = false;
    currentDate: Date;
    finalBillBasics: FinalBillBasicModel;
    appointment: Appointment;

    receipts: Array<IReceiptModel>;

    selectedPackage: PackageModuleModel;
    chargeCategory: IResource;
    departments: Array<string>;
    chargeGroups: Array<string>;
    charges: Array<string>;
    deletedCharges: Array<number>;
    deletedPharmacy: Array<number>;
    deletedLabServices: Array<number>;

    finalBillForm: FormGroup;
    mode: EditMode;

    modalRef: NgbModalRef;
    admission: IAdmissionModel;
    noOfDays: number;
    billNumber: string;
    @Input() isPrintLogo: boolean;
    insuranceAdmission: Array<InsuranceAdmission>;
    insuranceAmount: number;
    doctorVisitRecordsSubTotalCost: number;
    generalRecordsSubTotalCost: number;
    labServicesRecordsSubTotalCost: number;
    scanServicesRecordsSubTotalCost: number;
    surgeryServicesRecordsSubTotalCost: number;
    pharmacyIndentRecordsSubTotalCost: number;
    amountInWords: string;
    yearsfordisplay: number;
    monthsfordisplay: number;
    daysfordisplay: number;
    patientAge: string;
    loadingIssue: boolean;
    issuedRecord: Array<IssueIndentDisplay>;
    total = 0;
    finalRecords: Array<PharmacyInpatientReport>;
    flag: boolean;
    comments: string
    Location: PracticeLocation;
    returnedProducts: Array<IssueIndentDisplay>;
    returnedTotal = 0;
    finalNetAmount = 0;
    caseTypeArray: { name: string; }[];
    store: PharmacyStore;
    loadingRepotName: boolean;
    reportName: string;
    isFooter: boolean;
    loadingSettings: boolean;
    isPaylater: boolean;
    loadingIssuedIndents: boolean;
    excludedAmount: number;
    pharmacyTotalAmount: number;
    changedRooms: Array<IPChangeRoom>;
    isPendingLabs: boolean;
    isPendingScans: boolean;
    isPharmacyPendingIndents: boolean;
    QrCode: string;
    @Input() admissionNo: string;
    @Input() admissionId: string;
    record: AdmissionInformationModel;
    receiptsData: IReceiptModel[];
    isShowFinalBill = true;
    discountReason: string;
    private subscription: Subscription;
    isNeedDiscountReason: boolean;
    constructor(
        private readonly httpService: HttpService,
        private readonly finalBillService: FinalBillService,
        private readonly route: ActivatedRoute,
        private readonly appData: AppData,
        private readonly notifyService: NotifyService,
        private readonly formBuilder: FormBuilder,
        private readonly modalService: NgbModal,
        private readonly loc: Location,
        private readonly printOptionService: PrintOptionService,
        private readonly admissionFetchHelper: AdmissionFetchHelper,
        private readonly router: Router,
        private readonly resourceService: ResourceService,
        public toggleService: TimelineToggleService,
        private timelineWidgetService: TimelineWidgetService
    ) {
        this.receipts = new Array<IReceiptModel>();
        this.finalBillBasics = new FinalBillBasicModel();
        this.deletedCharges = new Array<number>();
        this.deletedPharmacy = new Array<number>();
        this.deletedLabServices = new Array<number>();
        this.mode = EditMode.New;
        this.serviceOrder = new ServiceOrder();
        this.serviceOrder.generalRecords = new Array<IChargeModel>();
        this.serviceOrder.pharmacyIndentRecords = new Array<IPharmacyIndentModel>();
        this.serviceOrder.packageRecords = new Array<IAdmissionPackageModuleModel>();
        this.serviceOrder.labServicesRecords = new Array<NewLabServicesModel>();
        this.serviceOrder.surgeryServicesRecords = new Array<ISurgeryChargeModel>();
        this.serviceOrder.scanServicesRecords = new Array<IScanChargeModel>();
        this.serviceOrder.doctorVisitRecords = new Array<IDoctorVisitModel>();
        this.serviceOrder.bedServicesRecords = new Array<IBedChargeModel>();
        this.serviceOrder.surgeonChargeRecords = new Array<ISurgeonChargemodel>();
        this.isChargesLoading = true;
        this.loading = true;
        this.insuranceAdmission = new Array<InsuranceAdmission>();
        this.page = new Page();
        this.buildForm();
        this.issuedRecord = new Array<IssueIndentDisplay>();
        this.finalRecords = new Array<PharmacyInpatientReport>();
        this.returnedProducts = new Array<IssueIndentDisplay>();
        this.store = new PharmacyStore();
        this.toggleService.showBox = false;
    }
  
    private fetchIpInformation() {
        const request = {
            admissionNo: this.admissionNo,
            admissionId: this.admissionId
        };
        this.loading = true;
        this.httpService.post(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchIPInformation), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loading = false }))
            .subscribe((response: Array<AdmissionInformationModel>) => {
                this.record = response.find(x => x.admissionNo == this.admissionNo);
            }, () => {
                this.record = new AdmissionInformationModel();
            });

    }

    fetchReceiptData = () => {
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.receipt.base, ApiResources.receipt.fetch),
                { id: this.admissionId, isAdmission: true })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: GenericResponse) => {
                    this.receiptsData = response.data.records as Array<IReceiptModel>;
                },
                () => {
                    this.receiptsData = new Array<IReceiptModel>();
                }
            );

    }
    private fetchAppliedSettings() {
        this.loading = true;
        const request = {
            requestId: this.page.userAccount && this.page.userAccount.locationId,
            fromBanner: true
        };
        this.httpService
            .get(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.getAppliedPharmacySettings), request)
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: PharmacyStore) => {
                    this.store = response;
                },
                () => {
                    this.store = new PharmacyStore();
                }
            );
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;

                    this.route.parent.paramMap
                        .subscribe((params: Params) => {
                            this.isAdmission = true;
                            if (!this.isAdmission)
                                this.getAppointment(this.admissionId);

                            this.subscription = this.timelineWidgetService.get.subscribe((isRefresh) => {
                                if (isRefresh) {                                 
                                    this.fetchReceiptData();
                                    this.fetchIpInformation();
                                    this.getFinalBillBasics(() => {
                                        this.admissionFetch();
                                        this.fetchFinal();
                                    });                                   
                                    this.timelineWidgetService.set(false);
                                }
                            });
                        });
                    this.fetch(this.page.userAccount.locationId);
                    this.fetchAppliedSettings();
                    this.getSettingsDateForPaymentLink();
                    this.currentDate = new Date();
                } else {
                    this.page.userAccount = undefined;
                }
                this.printOptionService.get((is) => { this.isPrintLogo = is; });
            });
    }

    fetchReceipts = () => {
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.receipt.base, ApiResources.receipt.fetch), { id: this.admissionId, isAdmission: this.isAdmission, activeOnly: true })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: GenericResponse) => {
                    const records = response.data as Array<IReceiptModel>;
                    if (records.length > 0) {
                        records.forEach((item) => {
                            item.receiptNo = String(item.receiptId).padStart(6, '0');
                        });
                    }

                    this.receipts = records;
                },
                () => {
                    this.receipts = new Array<IReceiptModel>();
                }
            );

    }

    buildForm = () => {
        this.finalBillForm = this.formBuilder.group({
            discountType: [null],
            discount: [null],
            discountReason: [null]
        });

        this.finalBillForm.get("discountType").valueChanges.subscribe((value: DiscountType) => {
            this.finalBillForm.patchValue({ discount: null });

            if (!+value) return;

            const discount = this.finalBillForm.get('discount');
            discount.clearValidators();
            if (+value === DiscountType.Number) {
                discount.setValidators([
                    Validators.min(0),
                    Validators.max(((this.serviceOrder.packageTotal || 0) + (this.serviceOrder.generalTotal || 0) + (this.serviceOrder.labServicesTotal || 0) + (this.serviceOrder.surgeryServicesTotal || 0) + (this.serviceOrder.scanServicesTotal || 0) + (this.serviceOrder.doctorVisitTotal || 0) + (this.serviceOrder.bedServicesTotal || 0) + (this.serviceOrder.surgeonChargeTotal || 0) + (this.serviceOrder.pharmacyIndentTotal || 0)))
                ]);
            } else {
                discount.setValidators([
                    Validators.min(0),
                    Validators.max(100)
                ]);
            }

            discount.updateValueAndValidity();

        });
    }

    get form() {
        return this.finalBillForm.controls;
    }

    get receiptsTotal() {
        if (!this.receipts.length) return 0;
        return this.receipts.filter(x => x.receiptTypeId !== ReceiptType.Refund).map(x => x.cost).reduce((a, b) => a + b, 0);
    }

    get finalAmount() {
        var amount = !+this.finalBillForm.value.discountType
            ? +((this.getTotalAmount || 0)).toFixed(2)
            : +((this.getTotalAmount || 0) -
                (
                    (+this.finalBillForm.value.discountType) === DiscountType.Number
                        ? (this.finalBillForm.value.discount || 0)
                        : ((this.getTotalAmount || 0) * ((this.finalBillForm.value.discount || 0) / 100))
                )).toFixed(2);

        if (this.insuranceAmount > 0) {
            amount = amount > this.insuranceAmount ? amount - this.insuranceAmount : 0;
        }
        return Math.round(amount);
    }

    admissionFetch = () => {
        this.admissionFetchHelper.admissionFetch(+this.admissionId, this.isAdmission, (data: IAdmissionModel) => {
            this.admission = data;
            if (this.isAdmission && this.admission)
                this.fetchPharmacyIssuedIndents();
            const dateOfBirth = DateHelper.toNgbDateStruct(String(this.admission.patientDateOfBirth));
            if (dateOfBirth != null) {
                let mdob = moment(String(this.admission.patientDateOfBirth), "YYYY-MM-DD");
                let age = moment().diff(mdob, "months");
                let yrs = (age / 12).toFixed(1);
                this.yearsfordisplay = parseInt(yrs, 10);
                let months = age % 12;
                var days = moment().diff(mdob.add(yrs, 'years'), 'days', false);
                this.yearsfordisplay = days;
                this.yearsfordisplay = parseInt(yrs);
                this.monthsfordisplay = months;
                var date1 = moment(String(this.admission.patientDateOfBirth), "YYYY-MM-DD");
                var monthsa = moment().diff(date1, 'months');
                date1.add(monthsa, 'months');
                var daysa = moment().diff(date1, 'days');
                monthsa + ' ' + 'Month(s)' + ' ' + daysa + " Day(s)"
                this.daysfordisplay = daysa;
                this.patientAge = this.admission.patientDateOfBirth ? this.yearsfordisplay + 'Y ' + this.monthsfordisplay + 'M ' + this.daysfordisplay + 'D' : null;
            }

        });
    }

    onOpenModel(content: TemplateRef<any>) {
        this.reportName = "Detailed Final Bill Duplicate print";
        this.now = new Date();
        this.moneyHelper();
        this.calculateSubTotals();
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "lg",
            windowClass: "custom-modal slots-modal effect-scale"
        });

        return;
    }

    onCloseModal() {
        try {
            this.discountReason = null;
            this.isNeedDiscountReason = false;
            this.modalRef.close();
            this.modalRef = undefined;
            this.comments = "";
        } catch (e) {
            // ignored;
        }

        this.submitting = undefined;
        this.submitted = undefined;
    }

    automatic = (callback?: Function) => {
        var data = {
            id: this.admissionId,
            isAdmission: this.isAdmission,
            createdBy: this.page.userAccount.accountId
        };
        this.httpService
            .post(ApiResources.getURI(ApiResources.serviceOrder.base, ApiResources.serviceOrder.automatic), data)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(() => {
                callback();
            }, () => {
                callback();
            });
    }

    onBack = () => {
        this.loc.back();
    }

    getFinalBillBasics = (callback?: Function) => {
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.finalBill.base, ApiResources.finalBill.getBasics), { id: this.admissionId, isAdmission: this.isAdmission })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.isChargesLoading = false))
            .subscribe(
                (response: GenericResponse) => {
                    this.finalBillBasics = response.data as FinalBillBasicModel;
                    this.moneyHelper();
                    callback();
                },
                () => {
                    this.finalBillBasics = new FinalBillBasicModel();
                    callback();
                }
            );
    }

    getAppointment = (admissionId: string) => {
        const request = { appointmentId: parseInt(admissionId) };
        this.httpService.post(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.find), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Appointment) => {
                this.appointment = response;
                this.disableActionBtns = this.appointment && this.appointment.status === "C";
            });
    }

    moneyHelper = () => {
        this.finalBillBasics.totalAmountPaidInWords = MoneyHelper.numberCurrencyIn(this.finalBillBasics.totalAmountPaid);
        this.finalBillBasics.totalDueAmountInWords = MoneyHelper.numberCurrencyIn(((this.finalBillBasics.totalAmountPaid - (this.finalAmount || 0)) * -1));
        this.finalBillBasics.totalRefundPendingAmountInWords = MoneyHelper.numberCurrencyIn(((this.finalBillBasics.totalAmountPaid - (this.finalAmount || 0))));
        this.finalBillBasics.netAmountInWords = MoneyHelper.numberCurrencyIn((this.finalAmount || 0));
        this.finalBillBasics.totalAmountInWords = MoneyHelper.numberCurrencyIn(this.finalBillBasics.totalAmount);
        sessionStorage.setItem("finalBill", JSON.stringify(this.finalBillBasics))
    }

    fetchFinal = () => {
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.finalBill.base, ApiResources.finalBill.fetch),
                {
                    id: this.admissionId, isAdmission: this.isAdmission, modifiedBy: this.page.userAccount.accountId, roleId: this.page.userAccount.roleId, fullName: this.page.userAccount.fullName
                })
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        const record = response.data as FinalBill;
                        this.chargeCategory = { id: record["roomChargeCategoryId"] || record["chargeCategoryId"], value: record["chargeCategoryName"] } as IResource;
                        if (this.chargeCategory?.id == null) {
                            this.chargeCategory.id = this.admission.chargeCategoryId;
                        }
                        if (record.basics && record.basics.finalBillId) {
                            this.billNumber = 'FN' + String(record.basics.finalBillId).padStart(6, '0');
                        }

                        // General Records
                        const generalRecords = record.generalRecords as Array<IChargeModel>;
                        if (generalRecords.length > 0) {
                            const generalSubRecords = new Array<IChargeModel>();
                            generalRecords.forEach(item => {
                                generalSubRecords.push({
                                    serviceOrderId: item.serviceOrderId,
                                    chargeId: item.chargeId,
                                    cost: item.cost,
                                    discount: item.discount,
                                    chargeName: item.chargeName,
                                    chargeGroupName: item.chargeGroupName,
                                    departmentName: item.departmentName,
                                    unit: item.unit,
                                    totalCost: item.cost * item.unit,
                                    status: true,
                                    repeatTypeId: item.repeatTypeId,
                                    chargeTypeMainId: item.chargeTypeMainId,
                                    chargeTypeMainName: item.chargeTypeMainName,
                                    chargeTypeId: item.chargeTypeId,
                                    showAmount: true,
                                    usedQuantity: item.usedQuantity,
                                    serviceDate: item.serviceDate

                                } as IChargeModel);
                            });

                            this.serviceOrder.generalRecords = generalSubRecords;
                            this.serviceOrder.generalTotal = generalSubRecords.map(x => (x.cost * x.unit) - (x.discount || 0)).reduce((a, b) => a + b, 0);

                        } else {
                            this.serviceOrder.generalRecords = new Array<IChargeModel>();
                            this.serviceOrder.generalTotal = 0;
                        }
                        // Lab Services Records
                        const labServices = record.labServicesRecords as unknown as Array<NewLabServicesModel>;
                        if (labServices.length > 0) {
                            const labServicesSubRecords = new Array<NewLabServicesModel>();
                            labServices.forEach(item => {
                                labServicesSubRecords.push({
                                    labServicesId: item.labServicesId,
                                    labMainDetailId: item.labMainDetailId,
                                    cost: item.cost,
                                    discount: item.discount,
                                    testName: item.testName,
                                    testCode: item.testCode,
                                    unit: item.unit,
                                    totalCost: item.cost * item.unit,
                                    status: true,
                                    repeatTypeId: item.repeatTypeId,
                                    automaticTypeId: item.automaticTypeId,
                                    active: item.active,
                                    createdDate: item.createdDate,
                                    modifiedDate: item.modifiedDate,
                                    createdByName: item.createdByName,
                                    modifiedByName: item.modifiedByName,
                                    isMain: item.isMain,
                                    showAmount: true,
                                    usedQuantity: item.usedQuantity,
                                    serviceDate: item.serviceDate
                                } as unknown as NewLabServicesModel);
                            });

                            this.serviceOrder.labServicesRecords = labServicesSubRecords;
                            this.serviceOrder.labServicesTotal = labServicesSubRecords.filter(x => x.active).map(x => (x.cost * x.unit) - (x.discount || 0)).reduce((a, b) => a + b, 0);
                        } else {
                            this.serviceOrder.labServicesRecords = new Array<NewLabServicesModel>();
                            this.serviceOrder.labServicesTotal = 0;
                        }

                        // Surgery Services Records
                        const surgeryServices = record.surgeryServiceRecords as unknown as Array<ISurgeryChargeModel>;
                        if (surgeryServices.length > 0) {
                            const surgeryServicesSubRecords = new Array<ISurgeryChargeModel>();
                            const caseTypeArray = surgeryServices.map(item => ({
                                name: item.name
                            }));
                            this.caseTypeArray = caseTypeArray
                            surgeryServices.forEach(item => {
                                surgeryServicesSubRecords.push({
                                    surgeryServiceId: item.surgeryServiceId,
                                    surgeryId: item.surgeryId,
                                    cost: item.cost,
                                    discount: item.discount,
                                    name: item.name,
                                    unit: item.unit,
                                    totalCost: item.cost * item.unit,
                                    status: true,
                                    repeatTypeId: item.repeatTypeId,
                                    automaticTypeId: item.automaticTypeId,
                                    active: item.active,
                                    createdDate: item.createdDate,
                                    modifiedDate: item.modifiedDate,
                                    createdByName: item.createdByName,
                                    modifiedByName: item.modifiedByName,
                                    isMain: item.isMain,
                                    showAmount: true,
                                    usedQuantity: item.usedQuantity,
                                    serviceDate: item.serviceDate
                                } as unknown as ISurgeryChargeModel);

                            });


                            this.serviceOrder.surgeryServicesRecords = surgeryServicesSubRecords;
                            this.serviceOrder.surgeryServicesTotal = surgeryServicesSubRecords.filter(x => x.active).map(x => (x.cost * x.unit) - (x.discount || 0)).reduce((a, b) => a + b, 0);
                        } else {
                            this.serviceOrder.surgeryServicesRecords = new Array<ISurgeryChargeModel>();
                            this.serviceOrder.surgeryServicesTotal = 0;
                        }
                        // Scan Services Records
                        const scanServices = record.scanServiceRecords as unknown as Array<IScanChargeModel>;
                        if (scanServices.length > 0) {
                            const scanServicesSubRecords = new Array<IScanChargeModel>();
                            scanServices.forEach(item => {
                                scanServicesSubRecords.push({
                                    scanServiceId: item.scanServiceId,
                                    scanTestMasterId: item.scanTestMasterId,
                                    cost: item.cost,
                                    discount: item.discount,
                                    scanTestName: item.scanTestName,
                                    unit: item.unit,
                                    totalCost: item.cost * item.unit,
                                    status: true,
                                    repeatTypeId: item.repeatTypeId,
                                    automaticTypeId: item.automaticTypeId,
                                    active: item.active,
                                    createdDate: item.createdDate,
                                    modifiedDate: item.modifiedDate,
                                    createdByName: item.createdByName,
                                    modifiedByName: item.modifiedByName,
                                    isMain: item.isMain,
                                    showAmount: true,
                                    usedQuantity: item.usedQuantity,
                                    serviceDate: item.serviceDate
                                } as unknown as IScanChargeModel);
                            });

                            this.serviceOrder.scanServicesRecords = scanServicesSubRecords;
                            this.serviceOrder.scanServicesTotal = scanServicesSubRecords.filter(x => x.active).map(x => (x.cost * x.unit) - (x.discount || 0)).reduce((a, b) => a + b, 0);
                        } else {
                            this.serviceOrder.scanServicesRecords = new Array<IScanChargeModel>();
                            this.serviceOrder.scanServicesTotal = 0;
                        }
                        // Doctor Visits Records
                        const doctorVisists = record.doctorVisitRecords as unknown as Array<IDoctorVisitModel>;
                        if (doctorVisists.length > 0) {
                            const doctorVisistsSubRecords = new Array<IDoctorVisitModel>();
                            doctorVisists.forEach(item => {
                                doctorVisistsSubRecords.push({
                                    doctorVisitId: item.doctorVisitId,
                                    providerId: item.providerId,
                                    cost: item.cost,
                                    discount: item.discount,
                                    providerName: item.providerName,
                                    unit: item.unit,
                                    totalCost: item.cost * item.unit,
                                    status: true,
                                    active: item.active,
                                    createdDate: item.createdDate,
                                    modifiedDate: item.modifiedDate,
                                    createdByName: item.createdByName,
                                    modifiedByName: item.modifiedByName,
                                    isMain: item.isMain,
                                    showAmount: true,
                                    usedQuantity: item.usedQuantity,
                                    serviceDate: item.serviceDate
                                } as unknown as IDoctorVisitModel);
                            });

                            this.serviceOrder.doctorVisitRecords = doctorVisistsSubRecords;
                            this.serviceOrder.doctorVisitTotal = doctorVisistsSubRecords.filter(x => x.active).map(x => (x.cost * x.unit) - (x.discount || 0)).reduce((a, b) => a + b, 0);
                        } else {
                            this.serviceOrder.doctorVisitRecords = new Array<IDoctorVisitModel>();
                            this.serviceOrder.doctorVisitTotal = 0;
                        }
                        // Room Rents Records
                        const bedServices = record.bedServiceRecords as unknown as Array<IBedChargeModel>;
                        if (bedServices.length > 0) {
                            const bedServicesSubRecords = new Array<IBedChargeModel>();
                            bedServices.forEach(item => {
                                bedServicesSubRecords.push({
                                    roomVisitId: item.roomVisitId,
                                    bedId: item.bedId,
                                    cost: item.cost,
                                    discount: item.discount,
                                    bedNumber: item.bedNumber,
                                    unit: item.unit,
                                    totalCost: item.cost * item.unit,
                                    status: true,
                                    active: item.active,
                                    createdDate: item.createdDate,
                                    modifiedDate: item.modifiedDate,
                                    createdByName: item.createdByName,
                                    modifiedByName: item.modifiedByName,
                                    isMain: item.isMain,
                                    showAmount: true,
                                    usedQuantity: item.usedQuantity,
                                    serviceDate: item.serviceDate
                                } as unknown as IBedChargeModel);
                            });

                            this.serviceOrder.bedServicesRecords = bedServicesSubRecords;
                            this.serviceOrder.bedServicesTotal = bedServicesSubRecords.filter(x => x.active).map(x => (x.cost * x.unit) - (x.discount || 0)).reduce((a, b) => a + b, 0);
                        } else {
                            this.serviceOrder.bedServicesRecords = new Array<IBedChargeModel>();
                            this.serviceOrder.bedServicesTotal = 0;
                        }

                        // Surgeon Charge Services Records
                        const surgeonChargeServices = record.surgeonChargeRecords as unknown as Array<ISurgeonChargemodel>;
                        if (surgeonChargeServices.length > 0) {
                            const surgeonChargeServicesSubRecords = new Array<ISurgeonChargemodel>();
                            const caseTypeArray = surgeonChargeServices.map(item => ({
                                name: item.name
                            }));
                            this.caseTypeArray = caseTypeArray
                            surgeonChargeServices.forEach(item => {
                                surgeonChargeServicesSubRecords.push({
                                    surgeonChargeServicesId: item.surgeonChargeServicesId,
                                    surgeonChargeMasterId: item.surgeonChargeMasterId,
                                    cost: item.cost,
                                    discount: item.discount,
                                    name: item.name,
                                    unit: item.unit,
                                    totalCost: item.cost * item.unit,
                                    status: true,
                                    active: item.active,
                                    createdDate: item.createdDate,
                                    modifiedDate: item.modifiedDate,
                                    createdByName: item.createdByName,
                                    modifiedByName: item.modifiedByName,
                                    isMain: item.isMain,
                                    showAmount: true,
                                    usedQuantity: item.usedQuantity,
                                    serviceDate: item.serviceDate
                                } as unknown as ISurgeonChargemodel);

                            });


                            this.serviceOrder.surgeonChargeRecords = surgeonChargeServicesSubRecords;
                            this.serviceOrder.surgeonChargeTotal = surgeonChargeServicesSubRecords.filter(x => x.active).map(x => (x.cost * x.unit) - (x.discount || 0)).reduce((a, b) => a + b, 0);
                        } else {
                            this.serviceOrder.surgeonChargeRecords = new Array<ISurgeonChargemodel>();
                            this.serviceOrder.surgeonChargeTotal = 0;
                        }

                        // Pharmacy Records
                        const pharmacyRecords = record.pharmacyRecords as Array<any>;
                        if (pharmacyRecords.length > 0) {
                            const pharmacySubRecords = new Array<IPharmacyIndentModel>();
                            pharmacyRecords.forEach(item => {
                                pharmacySubRecords.push({
                                    pharmacyIssueDetailId: item.productId,
                                    productId: item.productId,
                                    cost: item.cost,
                                    unit: item.unit,
                                    categoryName: item.chargeGroupName,
                                    departmentName: item.departmentName,
                                    productName: item.chargeName,
                                    totalCost: item.cost * item.unit,
                                    discountAmount: (item.cost * item.unit) * item.discountPercentage / 100,
                                    discountPercentage: item.discountPercentage,
                                    status: true,
                                    showAmount: true,
                                    isIncluded: item.isIncluded

                                } as IPharmacyIndentModel);
                            });

                            this.serviceOrder.pharmacyIndentRecords = pharmacySubRecords;
                            this.serviceOrder.pharmacyIndentTotal = pharmacySubRecords.filter(x => !x.isIncluded).map(x => (x.cost * x.unit) - x.discountAmount)
                                .reduce((a, b) => a + b, 0);
                        } else {
                            this.serviceOrder.pharmacyIndentRecords = new Array<IPharmacyIndentModel>();
                            this.serviceOrder.pharmacyIndentTotal = 0;
                        }

                        // Package Records
                        const packageRecords = record.packageRecords as Array<IAdmissionPackageModuleModel>;
                        if (packageRecords.length) {
                            this.flag = true;
                            this.serviceOrder.packageRecords = packageRecords;
                            this.serviceOrder.packageTotal = packageRecords.filter(x => x.active).map(x => (x.total)).reduce((a, b) => a + b, 0);
                        } else {
                            this.serviceOrder.packageRecords = new Array<IAdmissionPackageModuleModel>();
                            this.serviceOrder.packageTotal = 0;
                        }
                        sessionStorage.setItem("serviceOrder", JSON.stringify(this.serviceOrder))
                        this.finalBillForm.patchValue({
                            discountType: record.basics.discountTypeId,
                            discount: record.basics.discountDetails,
                            discountReason: record.basics.discountReason
                        });
                        this.QrCode = `${location.origin}${location.pathname}#/new-lab-webreports/${this.appointment}`;
                        this.mode = EditMode.Final;
                        this.setOverallDiscount(record.overallServiceDiscounts)
                        this.fetchReceipts();
                    } else {
                        this.mode = EditMode.New;
                        this.fetchServiceOrder();
                        this.fetchAllChangedData();
                    }
                    this.getInsurance();
                },
                () => {
                    this.serviceOrder.generalRecords = new Array<IChargeModel>();
                    this.serviceOrder.generalTotal = 0;
                    this.serviceOrder.pharmacyIndentRecords = new Array<IPharmacyIndentModel>();
                    this.serviceOrder.pharmacyIndentTotal = 0;
                    this.serviceOrder.packageRecords = new Array<IAdmissionPackageModuleModel>();
                    this.serviceOrder.packageTotal = 0;
                    this.serviceOrder.labServicesRecords = new Array<NewLabServicesModel>();
                    this.serviceOrder.labServicesTotal = 0;
                    this.serviceOrder.surgeryServicesRecords = new Array<ISurgeryChargeModel>();
                    this.serviceOrder.surgeryServicesTotal = 0;
                    this.serviceOrder.scanServicesRecords = new Array<IScanChargeModel>();
                    this.serviceOrder.scanServicesTotal = 0;
                    this.serviceOrder.doctorVisitRecords = new Array<IDoctorVisitModel>();
                    this.serviceOrder.doctorVisitTotal = 0;
                    this.serviceOrder.bedServicesRecords = new Array<IBedChargeModel>();
                    this.serviceOrder.bedServicesTotal = 0;
                    this.serviceOrder.surgeonChargeRecords = new Array<ISurgeonChargemodel>();
                    this.serviceOrder.surgeonChargeTotal = 0;

                }
            );
    }

    calculateTotals = () => {
        this.serviceOrder.generalTotal = this.serviceOrder.generalRecords.filter(x => x.showAmount).map(x => x.cost * x.unit).reduce((a, b) => a + b, 0);
        this.serviceOrder.labServicesTotal = this.serviceOrder.labServicesRecords.filter(x => x.active && x.showAmount).map(x => x.cost * x.unit).reduce((a, b) => a + b, 0);
        this.serviceOrder.pharmacyIndentTotal = this.serviceOrder.pharmacyIndentRecords.filter(x => x.showAmount).map(x => (x.cost * x.unit) - x.discountAmount).reduce((a, b) => a + b, 0);
        this.serviceOrder.surgeryServicesTotal = this.serviceOrder.surgeryServicesRecords.filter(x => x.showAmount).map(x => (x.cost * x.unit) - x.discountAmount).reduce((a, b) => a + b, 0);
        this.serviceOrder.scanServicesTotal = this.serviceOrder.scanServicesRecords.filter(x => x.showAmount).map(x => (x.cost * x.unit) - x.discountAmount).reduce((a, b) => a + b, 0);
        this.serviceOrder.doctorVisitTotal = this.serviceOrder.doctorVisitRecords.filter(x => x.showAmount).map(x => (x.cost * x.unit) - x.discountAmount).reduce((a, b) => a + b, 0);
        this.serviceOrder.bedServicesTotal = this.serviceOrder.bedServicesRecords.filter(x => x.showAmount).map(x => (x.cost * x.unit) - x.discountAmount).reduce((a, b) => a + b, 0);
        this.serviceOrder.surgeonChargeTotal = this.serviceOrder.surgeonChargeRecords.filter(x => x.showAmount).map(x => (x.cost * x.unit) - x.discountAmount).reduce((a, b) => a + b, 0);
    }

    calculateSubTotals = () => {
        if (this.serviceOrder.doctorVisitRecords.length > 0) {
            this.doctorVisitRecordsSubTotalCost = 0;
            this.serviceOrder.doctorVisitRecords.forEach(item => {
                this.doctorVisitRecordsSubTotalCost += item.cost * item.unit;
            });
        }
        if (this.serviceOrder.generalRecords.length > 0) {
            this.generalRecordsSubTotalCost = 0;
            this.serviceOrder.generalRecords.forEach(item => {
                this.generalRecordsSubTotalCost += item.cost * item.unit;
            });
        }
        if (this.serviceOrder.labServicesRecords.length > 0) {
            this.labServicesRecordsSubTotalCost = 0;
            this.serviceOrder.labServicesRecords.forEach(item => {
                this.labServicesRecordsSubTotalCost += item.cost * item.unit;
            });
        }

        if (this.serviceOrder.scanServicesRecords.length > 0) {
            this.scanServicesRecordsSubTotalCost = 0;
            this.serviceOrder.scanServicesRecords.forEach(item => {
                this.scanServicesRecordsSubTotalCost += item.cost * item.unit;
            });
        }
        if (this.serviceOrder.surgeryServicesRecords.length > 0) {
            this.surgeryServicesRecordsSubTotalCost = 0;
            this.serviceOrder.surgeryServicesRecords.forEach(item => {
                this.surgeryServicesRecordsSubTotalCost += item.cost * item.unit;
            });
        }

        if (this.serviceOrder.pharmacyIndentRecords.length > 0) {
            this.pharmacyIndentRecordsSubTotalCost = 0;
            this.serviceOrder.pharmacyIndentRecords.forEach(item => {
                this.pharmacyIndentRecordsSubTotalCost += item.cost * item.unit;
            });
        }
    }

    private setOverallDiscount = (items: Array<OverallDiscount> | null) => {
        if (!items || !items.length) return;

        items.forEach(item => {
            switch (item.serviceCode) {
                case DiscountTypeStrings.GeneralServices: {
                    this.serviceOrder.generalTotalDiscount = item.discountType === DiscountType.Percentage
                        ? this.serviceOrder.generalTotal * item.discountPercentage / 100
                        : item.discountAmount;
                    break;
                }
                case DiscountTypeStrings.LabServices: {
                    this.serviceOrder.labServicesTotalDiscount = item.discountType === DiscountType.Percentage
                        ? this.serviceOrder.labServicesTotal * item.discountPercentage / 100
                        : item.discountAmount;
                    break;
                }
                case DiscountTypeStrings.DoctorVisits: {
                    this.serviceOrder.doctorVisitTotalDiscount = item.discountType === DiscountType.Percentage
                        ? this.serviceOrder.doctorVisitTotal * item.discountPercentage / 100
                        : item.discountAmount;
                    break;
                }
                case DiscountTypeStrings.BedServices: {
                    this.serviceOrder.bedServicesTotalDiscount = item.discountType === DiscountType.Percentage
                        ? this.serviceOrder.bedServicesTotal * item.discountPercentage / 100
                        : item.discountAmount;
                    break;
                }
                case DiscountTypeStrings.ScanServices: {
                    this.serviceOrder.scanServicesTotalDiscount = item.discountType === DiscountType.Percentage
                        ? this.serviceOrder.scanServicesTotal * item.discountPercentage / 100
                        : item.discountAmount;
                    break;
                }
                case DiscountTypeStrings.OtServices: {
                    this.serviceOrder.surgeryServicesTotalDiscount = item.discountType === DiscountType.Percentage
                        ? this.serviceOrder.surgeryServicesTotal * item.discountPercentage / 100
                        : item.discountAmount;
                    break;
                }
                case DiscountTypeStrings.SurgeonChargeServices: {
                    this.serviceOrder.surgeonChargeTotalDiscount = item.discountType === DiscountType.Percentage
                        ? this.serviceOrder.surgeonChargeTotal * item.discountPercentage / 100
                        : item.discountAmount;
                    break;
                }
                case DiscountTypeStrings.PharmacyServices: {
                    this.serviceOrder.pharmacyServicesTotalDiscount = item.discountType === DiscountType.Percentage
                        ? this.serviceOrder.pharmacyIndentTotal * item.discountPercentage / 100
                        : item.discountAmount;
                    break;
                }
            }
        });
    }

    fetchServiceOrder = () => {
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.serviceOrder.base, ApiResources.serviceOrder.fetch), { id: this.admissionId, isAdmission: this.isAdmission, activeOnly: true })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: GenericResponse) => {
                    this.chargeCategory = { id: response.data["roomChargeCategoryId"] || response.data["chargeCategoryId"], value: response.data["chargeCategoryName"] } as IResource;
                    // General Records
                    const generalRecords = response.data["generalRecords"] as Array<any>;
                    if (generalRecords.length > 0) {
                        const generalSubRecords = new Array<IChargeModel>();
                        generalRecords.forEach(item => {
                            generalSubRecords.push({
                                serviceOrderId: item.serviceOrderId,
                                chargeId: item.chargeId,
                                cost: item.cost,
                                chargeName: item.chargeName,
                                chargeGroupName: item.chargeGroupName,
                                departmentName: item.departmentName,
                                unit: item.unit,
                                totalCost: item.cost * item.unit,
                                discount: item.discount,
                                status: true,
                                repeatTypeId: item.repeatTypeId,
                                automaticTypeId: item.automaticTypeId,
                                chargeTypeMainId: item.chargeTypeMainId,
                                chargeTypeMainName: item.chargeTypeMainName,
                                chargeTypeId: item.chargeTypeId,
                                usedQuantity: item.usedQuantity,
                                showAmount: true,
                                serviceDate: item.serviceDate
                            } as IChargeModel);
                        });

                        this.serviceOrder.generalRecords = generalSubRecords;
                        this.serviceOrder.generalTotal = generalSubRecords.map(x => (x.cost * x.unit) - (x.discount || 0)).reduce((a, b) => a + b, 0);

                    } else {
                        this.serviceOrder.generalRecords = new Array<IChargeModel>();
                        this.serviceOrder.generalTotal = 0;
                    }

                    // Lab Services Records
                    const labServices = response.data["labServices"] as Array<any>;
                    if (labServices.length > 0) {
                        const labServicesSubRecords = new Array<NewLabServicesModel>();
                        labServices.forEach(item => {
                            labServicesSubRecords.push({
                                labServicesId: item.labServicesId,
                                labMainDetailId: item.labMainDetailId,
                                cost: item.cost,
                                testName: item.testName,
                                testCode: item.testCode,
                                unit: item.unit,
                                totalCost: item.cost * item.unit,
                                discount: item.discount,
                                status: true,
                                repeatTypeId: item.repeatTypeId,
                                automaticTypeId: item.automaticTypeId,
                                active: item.active,
                                createdDate: item.createdDate,
                                modifiedDate: item.modifiedDate,
                                createdByName: item.createdByName,
                                modifiedByName: item.modifiedByName,
                                isMain: item.isMain,
                                usedQuantity: item.usedQuantity,
                                showAmount: true,
                                serviceDate: item.serviceDate,
                                sampleCollectedBy: item.sampleCollectedBy,
                            } as unknown as NewLabServicesModel);
                        });

                        this.serviceOrder.labServicesRecords = labServicesSubRecords;
                        this.serviceOrder.labServicesTotal = labServicesSubRecords.filter(x => x.active).map(x => (x.cost * x.unit) - (x.discount || 0)).reduce((a, b) => a + b, 0);
                        this.isPendingLabs = labServicesSubRecords.some(x => x.sampleCollectedBy === null);
                    } else {
                        this.serviceOrder.labServicesRecords = new Array<NewLabServicesModel>();
                        this.serviceOrder.labServicesTotal = 0;
                    }

                    // Pharmacy Indent Records
                    const pharmacyIndentRecords = response.data["pharmacyIndentRecords"] as Array<any>;
                    if (pharmacyIndentRecords.length > 0) {
                        const pharmacySubRecords = new Array<IPharmacyIndentModel>();
                        pharmacyIndentRecords.forEach(item => {
                            pharmacySubRecords.push({
                                pharmacyIssueDetailId: item.pharmacyIssueDetailId,
                                productId: item.productId,
                                cost: item.cost,
                                unit: item.unit,
                                categoryName: item.categoryName,
                                departmentName: item.departmentName,
                                productName: item.productName,
                                totalCost: item.cost * item.unit,
                                discountAmount: (item.cost * item.unit) * item.discountPercentage / 100,
                                discountPercentage: item.discountPercentage,
                                status: true,
                                showAmount: true,
                                isIncluded: item.isIncluded
                            } as IPharmacyIndentModel);
                        });

                        this.serviceOrder.pharmacyIndentRecords = pharmacySubRecords;
                        this.serviceOrder.pharmacyIndentTotal = pharmacySubRecords.map(x => (!x.isIncluded && x.cost * x.unit) - x.discountAmount)
                            .reduce((a, b) => a + b, 0);
                        this.excludedAmount = pharmacySubRecords.filter(x => x.isIncluded != true).map(x => (x.cost * x.unit) - x.discountAmount)
                            .reduce((a, b) => a + b, 0);
                        this.pharmacyTotalAmount = pharmacySubRecords.map(x => x.cost * x.unit - x.discountAmount)
                            .reduce((a, b) => a + b, 0);

                    } else {
                        this.serviceOrder.pharmacyIndentRecords = new Array<IPharmacyIndentModel>();
                        this.serviceOrder.pharmacyIndentTotal = 0;
                    }

                    //pending pharmacy indents
                    const pharmacyPendingIndentRecords = response.data["pharmacyPendingIndentRecords"] ? response.data["pharmacyPendingIndentRecords"] as Array<any> : [];
                    this.isPharmacyPendingIndents = pharmacyPendingIndentRecords.some(x => x.status === 'P');
                    // Surgery Services Records
                    const surgeryServices = response.data["surgeryServiceRecords"] as unknown as Array<ISurgeryChargeModel>;
                    if (surgeryServices.length > 0) {
                        const surgeryServicesSubRecords = new Array<ISurgeryChargeModel>();
                        const caseTypeArray = surgeryServices.map(item => ({
                            name: item.name
                        }));
                        this.caseTypeArray = caseTypeArray
                        surgeryServices.forEach(item => {
                            surgeryServicesSubRecords.push({
                                surgeryServiceId: item.surgeryServiceId,
                                surgeryId: item.surgeryId,
                                cost: item.cost,
                                discount: item.discount,
                                name: item.name,
                                unit: item.unit,
                                totalCost: item.cost * item.unit,
                                status: true,
                                repeatTypeId: item.repeatTypeId,
                                automaticTypeId: item.automaticTypeId,
                                active: item.active,
                                createdDate: item.createdDate,
                                modifiedDate: item.modifiedDate,
                                createdByName: item.createdByName,
                                modifiedByName: item.modifiedByName,
                                isMain: item.isMain,
                                showAmount: true,
                                usedQuantity: item.usedQuantity,
                                serviceDate: item.serviceDate
                            } as unknown as ISurgeryChargeModel);
                        });

                        this.serviceOrder.surgeryServicesRecords = surgeryServicesSubRecords;
                        this.serviceOrder.surgeryServicesTotal = surgeryServicesSubRecords.filter(x => x.active).map(x => (x.cost * x.unit) - (x.discount || 0)).reduce((a, b) => a + b, 0);
                    } else {
                        this.serviceOrder.surgeryServicesRecords = new Array<ISurgeryChargeModel>();
                        this.serviceOrder.surgeryServicesTotal = 0;
                    }
                    // Scan Services Records
                    const scanServices = response.data["scanServiceRecords"] as unknown as Array<IScanChargeModel>;
                    if (scanServices.length > 0) {
                        const scanServicesSubRecords = new Array<IScanChargeModel>();
                        scanServices.forEach(item => {
                            scanServicesSubRecords.push({
                                scanServiceId: item.scanServiceId,
                                scanTestMasterId: item.scanTestMasterId,
                                cost: item.cost,
                                discount: item.discount,
                                scanTestName: item.scanTestName,
                                unit: item.unit,
                                totalCost: item.cost * item.unit,
                                status: true,
                                repeatTypeId: item.repeatTypeId,
                                automaticTypeId: item.automaticTypeId,
                                active: item.active,
                                createdDate: item.createdDate,
                                modifiedDate: item.modifiedDate,
                                createdByName: item.createdByName,
                                modifiedByName: item.modifiedByName,
                                isMain: item.isMain,
                                showAmount: true,
                                usedQuantity: item.usedQuantity,
                                serviceDate: item.serviceDate,
                                isAccepted: item.isAccepted
                            } as unknown as IScanChargeModel);
                        });

                        this.serviceOrder.scanServicesRecords = scanServicesSubRecords;
                        this.serviceOrder.scanServicesTotal = scanServicesSubRecords.filter(x => x.active).map(x => (x.cost * x.unit) - (x.discount || 0)).reduce((a, b) => a + b, 0);
                        this.isPendingScans = scanServicesSubRecords.some(x => x.isAccepted === false);
                    } else {
                        this.serviceOrder.scanServicesRecords = new Array<IScanChargeModel>();
                        this.serviceOrder.scanServicesTotal = 0;
                    }
                    // Doctor Visits Records
                    const doctorVisists = response.data["doctorVisitRecords"] as unknown as Array<IDoctorVisitModel>;
                    if (doctorVisists.length > 0) {
                        const doctorVisistsSubRecords = new Array<IDoctorVisitModel>();
                        doctorVisists.forEach(item => {
                            doctorVisistsSubRecords.push({
                                doctorVisitId: item.doctorVisitId,
                                providerId: item.providerId,
                                cost: item.cost,
                                discount: item.discount,
                                providerName: item.providerName,
                                unit: item.unit,
                                totalCost: item.cost * item.unit,
                                status: true,
                                active: item.active,
                                createdDate: item.createdDate,
                                modifiedDate: item.modifiedDate,
                                createdByName: item.createdByName,
                                modifiedByName: item.modifiedByName,
                                isMain: item.isMain,
                                showAmount: true,
                                usedQuantity: item.usedQuantity,
                                serviceDate: item.serviceDate
                            } as unknown as IDoctorVisitModel);
                        });

                        this.serviceOrder.doctorVisitRecords = doctorVisistsSubRecords;
                        this.serviceOrder.doctorVisitTotal = doctorVisistsSubRecords.filter(x => x.active).map(x => (x.cost * x.unit) - (x.discount || 0)).reduce((a, b) => a + b, 0);
                    } else {
                        this.serviceOrder.doctorVisitRecords = new Array<IDoctorVisitModel>();
                        this.serviceOrder.doctorVisitTotal = 0;
                    }
                    // Room Rents Records
                    const bedServices = response.data["bedServiceRecords"] as unknown as Array<IBedChargeModel>;
                    if (bedServices.length > 0) {
                        const bedServicesSubRecords = new Array<IBedChargeModel>();
                        bedServices.forEach(item => {
                            bedServicesSubRecords.push({
                                roomVisitId: item.roomVisitId,
                                bedId: item.bedId,
                                cost: item.cost,
                                discount: item.discount,
                                bedNumber: item.bedNumber,
                                unit: item.unit,
                                totalCost: item.cost * item.unit,
                                status: true,
                                active: item.active,
                                createdDate: item.createdDate,
                                modifiedDate: item.modifiedDate,
                                createdByName: item.createdByName,
                                modifiedByName: item.modifiedByName,
                                isMain: item.isMain,
                                showAmount: true,
                                usedQuantity: item.usedQuantity,
                                serviceDate: item.serviceDate
                            } as unknown as IBedChargeModel);
                        });

                        this.serviceOrder.bedServicesRecords = bedServicesSubRecords;
                        this.serviceOrder.bedServicesTotal = bedServicesSubRecords.filter(x => x.active).map(x => (x.cost * x.unit) - (x.discount || 0)).reduce((a, b) => a + b, 0);
                    } else {
                        this.serviceOrder.bedServicesRecords = new Array<IBedChargeModel>();
                        this.serviceOrder.bedServicesTotal = 0;
                    }

                    // Surgeon Charge Services Records
                    const surgeonChargeServices = response.data["surgeonChargeServiceRecords"] as unknown as Array<ISurgeonChargemodel>;
                    if (surgeonChargeServices.length > 0) {
                        const surgeonChargeSubSubRecords = new Array<ISurgeonChargemodel>();
                        surgeonChargeServices.forEach(item => {
                            surgeonChargeSubSubRecords.push({
                                surgeonChargeServicesId: item.surgeonChargeServicesId,
                                surgeonChargeMasterId: item.surgeonChargeMasterId,
                                cost: item.cost,
                                discount: item.discount,
                                name: item.name,
                                unit: item.unit,
                                totalCost: item.cost * item.unit,
                                status: true,
                                active: item.active,
                                createdDate: item.createdDate,
                                modifiedDate: item.modifiedDate,
                                createdByName: item.createdByName,
                                modifiedByName: item.modifiedByName,
                                isMain: item.isMain,
                                showAmount: true,
                                usedQuantity: item.usedQuantity,
                                serviceDate: item.serviceDate
                            } as unknown as ISurgeonChargemodel);
                        });

                        this.serviceOrder.surgeonChargeRecords = surgeonChargeSubSubRecords;
                        this.serviceOrder.surgeonChargeTotal = surgeonChargeSubSubRecords.filter(x => x.active).map(x => (x.cost * x.unit) - (x.discount || 0)).reduce((a, b) => a + b, 0);
                    } else {
                        this.serviceOrder.surgeonChargeRecords = new Array<ISurgeonChargemodel>();
                        this.serviceOrder.surgeonChargeTotal = 0;
                    }

                    // Package Records
                    const packageRecords = response.data["packageRecords"] as Array<IAdmissionPackageModuleModel>;
                    if (packageRecords.length) {
                        this.flag = true;
                        this.serviceOrder.packageRecords = packageRecords;
                        this.serviceOrder.packageTotal = packageRecords.filter(x => x.active).map(x => (x.total)).reduce((a, b) => a + b, 0);
                    } else {
                        this.serviceOrder.packageRecords = new Array<IAdmissionPackageModuleModel>();
                        this.serviceOrder.packageTotal = 0;
                    }


                    this.setOverallDiscount(response.data["overallServiceDiscounts"] as Array<OverallDiscount>);
                    this.mode = EditMode.New;
                },
                () => {
                    this.serviceOrder.generalRecords = new Array<IChargeModel>();
                    this.serviceOrder.generalTotal = 0;
                    this.serviceOrder.pharmacyIndentRecords = new Array<IPharmacyIndentModel>();
                    this.serviceOrder.pharmacyIndentTotal = 0;
                    this.serviceOrder.packageRecords = new Array<IAdmissionPackageModuleModel>();
                    this.serviceOrder.packageTotal = 0;
                    this.serviceOrder.labServicesRecords = new Array<NewLabServicesModel>();
                    this.serviceOrder.labServicesTotal = 0;
                    this.serviceOrder.surgeryServicesRecords = new Array<ISurgeryChargeModel>();
                    this.serviceOrder.surgeryServicesTotal = 0;
                    this.serviceOrder.scanServicesRecords = new Array<IScanChargeModel>();
                    this.serviceOrder.scanServicesTotal = 0;
                    this.serviceOrder.doctorVisitRecords = new Array<IDoctorVisitModel>();
                    this.serviceOrder.doctorVisitTotal = 0;
                    this.serviceOrder.bedServicesRecords = new Array<IBedChargeModel>();
                    this.serviceOrder.bedServicesTotal = 0;
                    this.serviceOrder.surgeonChargeRecords = new Array<ISurgeonChargemodel>();
                    this.serviceOrder.surgeonChargeTotal = 0;
                }
            );
    }

    trackByPackage(_: number, item: PackageModuleModel): number {
        return item.packageModuleId;
    }

    get getTotalAmount() {
        return this.serviceOrder.packageTotal +
            (this.serviceOrder.generalTotal - (this.serviceOrder.generalTotalDiscount || 0)) +
            (this.serviceOrder.labServicesTotal - (this.serviceOrder.labServicesTotalDiscount || 0)) +
            (this.serviceOrder.surgeryServicesTotal - (this.serviceOrder.surgeryServicesTotalDiscount || 0)) +
            (this.serviceOrder.scanServicesTotal - (this.serviceOrder.scanServicesTotalDiscount || 0)) +
            (this.serviceOrder.doctorVisitTotal - (this.serviceOrder.doctorVisitTotalDiscount || 0)) +
            (this.serviceOrder.bedServicesTotal - (this.serviceOrder.bedServicesTotalDiscount || 0)) +
            (this.serviceOrder.surgeonChargeTotal - (this.serviceOrder.surgeonChargeTotalDiscount || 0)) +
            (this.serviceOrder.pharmacyIndentTotal - (this.serviceOrder.pharmacyServicesTotalDiscount || 0));
    }

    insertHelper = () => {
        if (!this.finalBillForm.valid) return;
        if (this.serviceOrder?.pharmacyIssuedIndentRecords?.length > 0 || this.isPharmacyPendingIndents || this.isPendingLabs || this.isPendingScans) {
            let message = "There are pending";
            if (this.isAdmission && this.serviceOrder?.pharmacyIssuedIndentRecords?.length > 0) {
                message += " Pharmacy Issued Indents,";
            }
            if (this.isAdmission && this.isPharmacyPendingIndents) {
                message += " Pharmacy Raised Indents,";
            }
            if (this.isPendingLabs) {
                message += " Lab Tests,";
            }
            if (this.isPendingScans) {
                message += " Scan Tests";
            }
            this.notifyService.warning(message);
            return;
        }
        if (this.isAdmission && this.changedRooms?.length > 0) {
            this.notifyService.warning("Please shift the Patient to Admitted Room");
            return;
        }
        this.submitting = true;

        var data = {
            id: this.admissionId,
            isAdmission: this.isAdmission,
            createdBy: this.page.userAccount.accountId,
            totalAmount: isNaN(this.getTotalAmount) ? 0.0 : this.getTotalAmount,
            discountTypeId: this.finalBillForm.value.discountType,
            discountDetails: this.finalBillForm.value.discount,
            discountReason: this.finalBillForm.value.discountReason,
            finalAmount: this.finalAmount,
            labServices: [],
            generalRecords: [],
            pharmacyRecords: [],
            packageRecords: [],
            roleId: this.page.userAccount.roleId,
            roleName: this.page.userAccount.roleName,
            fullName: this.page.userAccount.fullName
        }

        this.serviceOrder.generalRecords.forEach(item => {
            data.generalRecords.push(
                {
                    chargeId: item.chargeId,
                    unit: item.unit,
                    cost: item.cost,
                    discount: +(item.discount || 0),
                    chargeTypeMainId: item.chargeTypeId ? item.chargeTypeMainId : null,
                    chargeTypeId: item.chargeTypeId,
                    serviceDate: item.serviceDate

                });
        });

        this.serviceOrder.labServicesRecords.forEach(item => {
            data.labServices.push(
                {
                    labMainDetailId: item.labMainDetailId,
                    unit: item.unit,
                    cost: item.cost,
                    notes: null
                });
        });

        this.serviceOrder.packageRecords.forEach(item => {
            data.packageRecords.push(
                {
                    packageId: item.packageModuleId,
                    total: item.total
                });
        });

        this.serviceOrder.pharmacyIndentRecords.forEach(item => {
            data.pharmacyRecords.push(
                {
                    productId: item.productId,
                    unit: item.unit,
                    cost: item.cost,
                    discountPercentage: item.discountPercentage,
                    pharmacyIssueDetailId: item.pharmacyIssueDetailId
                });
        });

        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.finalBill.base, ApiResources.finalBill.insert), data)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.submitting = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        this.notifyService.success("Final Bill has been Saved successfully");
                        this.mode = EditMode.Final;
                        this.getFinalBillBasics(() => {
                            this.fetchFinal();
                            this.finalBillService.set(true);
                        });
                    } else {
                        if (response.message) {
                            this.notifyService.info(response.message);
                        } else {
                            this.notifyService.defaultError();
                        }
                    }
                },
                () => {
                    this.chargeRecords = new Array<IChargeModel>();
                }
            );
    }

    cancelHelper = () => {
        if (this.finalBillBasics.isDischarged) {
            this.notifyService.info("Final Bill can not be cancelled once the patient is discharged");
            return;
        }

        if (this.comments == null && this.comments == undefined) {
            this.notifyService.warningToast("Please enter the comments.");
            return;
        }
        this.comments = this.comments.trim();
        this.submitting = true;

        var data = {
            id: this.admissionId,
            isAdmission: this.isAdmission,
            createdBy: this.page.userAccount.accountId,
            roleId: this.page.userAccount.roleId,
            roleName: this.page.userAccount.roleName,
            fullName: this.page.userAccount.fullName,
            reasonForCancel: this.comments
        }


        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.finalBill.base, ApiResources.finalBill.cancel),
                data)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.submitting = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        this.notifyService.success("Final Bill has been cancelled");
                        this.deletedCharges = new Array<number>();
                        this.deletedLabServices = new Array<number>();
                        this.deletedPharmacy = new Array<number>();
                        this.mode = EditMode.New;
                        this.getFinalBillBasics(() => {
                            this.fetchServiceOrder();
                            this.fetchAllChangedData();
                            this.finalBillService.set(true);
                            this.comments = "";
                            this.onCloseModal();
                        });
                    } else {
                        if (response.message) {
                            this.notifyService.info(response.message);
                        } else {
                            this.notifyService.defaultError();
                        }
                    }
                },
                () => {
                    this.chargeRecords = new Array<IChargeModel>();
                }
            );
    }



    inputComments(value: any) {
        this.comments = value.target.value;
    }
    resetHelper = () => {
        this.deletedCharges = new Array<number>();
        this.deletedPharmacy = new Array<number>();
        if (this.mode === EditMode.Final) {
            this.fetchFinal();
        } else {
            this.fetchServiceOrder();
            this.fetchAllChangedData();
        }
    }

    onSubmit = () => {
        if (this.mode === EditMode.New) {
            this.insertHelper();
        } else {
            this.notifyService.confirm('you want to cancel this final bill', () => {
                this.onOpenModel(this.reasonForCancel);
            })
        }
    }

    getInsurance() {
        var admissionId = null;
        var appointmentId = null;
        if (this.isAdmission) {
            admissionId = this.admissionId;
        } else {
            appointmentId = this.appointment.appointmentId
        }
        this.httpService.post(ApiResources.getURI(ApiResources.insuranceAdmission.base, ApiResources.insuranceAdmission.getInsuranceApprovals), { admissionId: Number(admissionId), appointmentId: appointmentId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loading = false }))
            .subscribe((response: Array<InsuranceAdmission>) => {
                if (response != undefined && response !== null) {
                    this.insuranceAdmission = response;
                    if (this.insuranceAdmission) {
                        var amount = 0;
                        this.insuranceAdmission.forEach((m) => {
                            amount += m.expectedAmount
                        });
                        this.insuranceAmount = amount;
                    }
                }
            });
    }

    onViewPackage(content: TemplateRef<any>, model: PackageModuleModel): void {
        this.selectedPackage = model;
        this.modalRef = this.modalService.open(content, { size: "lg", windowClass: "custom-modal" });
    }

    onCloseViewPackageModal(): void {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
        } catch (e) {
            //ignore
        }

        this.selectedPackage = undefined;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.page.unsubscribeAll();
    }
    onProvisionalBill(content: TemplateRef<any>) {
        this.reportName = ""
        this.now = new Date();
        this.getAppointment(this.admission.admissionId.toString())
        this.fetchReceipts();
        this.finalBillBasics.totalDueAmountInWords = MoneyHelper.numberCurrencyIn(((this.finalBillBasics.totalAmountPaid - (this.finalAmount || 0)) * -1));
        this.finalBillBasics.totalRefundPendingAmountInWords = MoneyHelper.numberCurrencyIn(((this.finalBillBasics.totalAmountPaid - (this.finalAmount || 0))));
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "lg",
            windowClass: "custom-modal slots-modal effect-scale"
        });

    }

    onClickPharmacyReport(content: TemplateRef<any>) {
        this.fetchIssuedIndent(this.admissionId);
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "lg",
            windowClass: "custom-modal slots-modal effect-scale"
        });
    }


    fetchIssuedIndent(id: string) {
        this.loadingIssue = true;
        const request = {
            id: id
        };
        let issued = this.httpService.get(ApiResources.getURI(ApiResources.pharmacyRequest.base, ApiResources.pharmacyRequest.pharmacyInpatientSaleReport), request)
        let returned = this.httpService.get(ApiResources.getURI(ApiResources.pharmacyRequest.base, ApiResources.pharmacyRequest.pharmacyIpReturnReport), request)

        forkJoin([issued, returned])
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loadingIssue = false;
            }))
            .subscribe((response: Array<any>) => {
                if (response) {
                    this.total = 0;
                    this.issuedRecord = response[0];
                    this.returnedProducts = response[1];
                    this.issuedRecord.forEach((item: IssueIndentDisplay) => {
                        if (item.admissionId) {
                            this.total += item.netAmount;
                        }
                    })
                    let helper = LinqHelper.groupBy(this.issuedRecord, (s) => s.billNumber);
                    const group = new Array<PharmacyInpatientReport>();
                    helper.forEach(items => {
                        group.push({
                            billNumber: items[0].billNumber,
                            createdDate: items[0].createdDate,
                            record: items

                        });
                    });
                    this.finalRecords = new Array<PharmacyInpatientReport>();
                    this.finalRecords = group;
                    this.returnedTotal = 0;
                    this.returnedProducts.forEach((item: IssueIndentDisplay) => {
                        this.returnedTotal += item.netAmount;
                    })
                    this.finalNetAmount = this.total - this.returnedTotal;
                }



            }, () => {
                this.issuedRecord = new Array<IssueIndentDisplay>();
            });
    }

    private getSettingsDateForPaymentLink() {
        this.loadingSettings = true;
        this.httpService.get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), { name: "PaylaterLink", active: true })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingSettings = false))
            .subscribe((response: Array<Setting>) => {
                if (response.length > 0) {
                    this.isPaylater = response[0].active;
                }
            });
    }

    sendPaymentLink = () => {
        this.httpService.get(ApiResources.getURI(ApiResources.finalBill.base, ApiResources.finalBill.sendPaymentLink), { id: this.admissionId, isAdmission: this.isAdmission, locationId: this.page.userAccount.locationId, amount: ((this.finalAmount || 0) - this.finalBillBasics.totalAmountPaid), createdByName: this.page.userAccount.fullName, createdBy: this.page.userAccount.accountId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loading = false }))
            .subscribe((response: any) => {
                if (response) {
                    alert(response.message);
                }
            });
    }
    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }
    fetch(id: any) {
        this.httpService
            .get<PracticeLocation>(ApiResources.getURI(ApiResources.practices.base, ApiResources.practices.practicelocationinfo), { locationId: id })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { }))
            .subscribe(
                (response: PracticeLocation) => {
                    if (response) {
                        this.Location = response

                    }
                }
            );
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }

    fetchPharmacyIssuedIndents() {
        this.loadingIssuedIndents = true
        const request = {
            admissionId: this.admission.admissionId
        }
        this.httpService.post<any>(ApiResources.getURI(ApiResources.serviceOrder.base, ApiResources.serviceOrder.fetchPharmacyIssuedIndents), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingIssuedIndents = false))
            .subscribe({
                next: (response: GenericResponse) => {
                    if (response.status == "Success") {
                        this.serviceOrder.pharmacyIssuedIndentRecords = response["data"]
                    }
                    else {
                        this.notifyService.warningToast("Error occured");
                    }
                },
                error: (error: Error) => {
                    this.notifyService.errorToast("Error occured");
                }
            });
    }
    fetchAllChangedData = () => {
        const request = {
            locationId: this.page.userAccount.locationId,
            admissionId: +this.admissionId
        };
        this.httpService.post<Array<IPChangeRoom>>(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchChangedRoomData), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IPChangeRoom>) => {
                this.changedRooms = response;

            });
    }
    enterReason() {
        if (!this.discountReason) {
            this.isNeedDiscountReason = true;
            return;
        } else {
            this.isNeedDiscountReason = false;
        }
        this.finalBillForm.patchValue({
            discountReason: this.discountReason
        })
        this.onCloseModal();
    }
    onClickDiscountReason() {
        if (this.finalBillForm.controls["discountType"].value) {
            this.modalRef = this.modalService.open(this.reasonforDiscount, {
                backdrop: "static",
                keyboard: false,
                centered: true,
                size: "sm",
                windowClass: "custom-modal slots-modal effect-scale"
            });
        }
    }

}