<ng-template #gridviewdata>
    <div *ngIf="format === 'GridFormat'">
        <div class="page-title-box mt-0">
            <div class="page-title-right">
                <span (click)="addForm1()" class=" icon-plus bigger-100 btn btn-xs btn-primary">
                    <i class="fe-plus mr-1"></i> Add
                </span>
            </div><h4 class="page-title"> {{descripion}}</h4>
        </div>
        <form [formGroup]="formGroup">
            <div class="table-responsive">
                <table class="table table-bordered fixedLayout" >
                    <thead>
                        <tr>
                            <th *ngFor="let control of formObject ; let i = index">{{ control.label }}</th> 
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let form of formsArray;  let i = index">
                            <tr>
                                <td *ngFor="let control of formObject ; let j = index">
                                    <input *ngIf="control.type === 'number'" class="form-control" type="number" [formControlName]="form.controls[control.tag]" />
                                    <textarea *ngIf="control.type === 'textarea'" class="form-control" [formControlName]="form.controls[control.tag]"></textarea>
                                    <input *ngIf="control.type === 'text'" class="form-control" type="text" [formControl]="form.controls[control.tag]" />
                                    <input *ngIf="control.type === 'date'" class="form-control" type="date" [formControl]="form.controls[control.tag]" />
                                    <select *ngIf="control.type === 'select'" class="form-control" [formControl]="form.controls[control.tag]">
                                        <option *ngFor="let option of control.choices" [value]="option">{{ option }}</option>
                                    </select>
                                    <ng-container *ngIf="control.type === 'radio'">
                                        <ng-container *ngFor="let option of control.values ">
                                            <input type="radio" [formControl]="form.controls[control.tag ] " [name]="control.name  + i + j" [value]="option.value" /> {{ option.label }}<br>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="control.type === 'checkbox'">
                                        <ng-container *ngFor="let option of control.values ">
                                            <input type="checkbox" [formControl]="form.controls[control.tag ] " id="checkbox" (change)="checkedone($event)" [name]="control.name  + i + j" [value]="option.value" /> {{ option.label }}<br>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="control.type === 'multiselect'">
                                        <ng-select [items]="control.choices"
                                                   [multiple]="true"
                                                   [closeOnSelect]="false"
                                                   placeholder="Items"
                                                   [formControl]="form.controls[control.tag]">
                                        </ng-select>
                                    </ng-container>
                                    <input *ngIf="control.type === 'time'" class="form-control" type="time" [formControl]="form.controls[control.tag]" />
                                </td>
                                <td>
                                    <button type="button" class="btn btn-danger" (click)="Remove(i)">Remove</button>
                                </td>
                            </tr>
                        </ng-container>

                    </tbody>
                </table>

            </div>
        </form>
        <div class="text-right">
            <button (click)="savingValues()" class="btn btn-primary" [disabled]="submitting">
                <i class="fe-save"></i>
                <span *ngIf="!submitting">Save</span>
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Submitting
                </span>
            </button>
        </div>
    </div>
</ng-template>

<!--//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////-->


<div *ngIf="isShown">
    <div *ngIf="format === 'GridFormat'">
        <table class="table tbno-border m-0">
            <tbody>
                <tr>
                    <th class="sec-head">
                        <div class="sec-heading"><span>{{descripion}}</span></div>
                    </th>
                </tr>
            </tbody>
        </table>
        <div class="f-right ">
            <div class="page-title-right">
                <span (click)="addForm1()" class=" icon-plus bigger-100 btn btn-xs btn-primary ">
                    <i class="fe-plus mr-1"></i> Add
                </span>
            </div>
        </div>
        <form [formGroup]="formGroup">
            <div class="table-responsive">
                <table class="table table-bordered fixedLayout">
                    <thead>
                        <tr>
                            <th *ngFor="let control of formObject ; let i = index">{{ control.label }}</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let form of formsArray;  let i = index">
                            <tr>
                                <td *ngFor="let control of formObject ; let j = index">
                                    <input *ngIf="control.type === 'text'" class="form-control" type="text" [formControl]="form.controls[control.tag]" />
                                    <input *ngIf="control.type === 'number'" class="form-control" type="number" [formControl]="form.controls[control.tag]" />
                                    <textarea *ngIf="control.type === 'textarea'" class="form-control" [formControl]="form.controls[control.tag]"></textarea>
                                    <input *ngIf="control.type === 'date'" class="form-control" type="date" [formControl]="form.controls[control.tag]" />
                                    <select *ngIf="control.type === 'select'" class="form-control" [formControl]="form.controls[control.tag]">
                                        <option *ngFor="let option of control.choices" [value]="option">{{ option }}</option>
                                    </select>
                                    <ng-container *ngIf="control.type === 'radio'">
                                        <ng-container *ngFor="let option of control.values ">
                                            <input type="radio" [formControl]="form.controls[control.tag ] " [name]="control.name  + i + j" [value]="option.value" /> {{ option.label }}<br>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="control.type === 'checkbox'">
                                        <ng-container *ngFor="let option of control.values ">
                                            <input type="checkbox" [formControl]="form.controls[control.tag ] " id="checkbox" (change)="checkedone($event)" [name]="control.name  + i + j" [value]="option.value" /> {{ option.label }}<br>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="control.type === 'multiselect'">
                                        <ng-select [items]="control.choices"
                                                   [multiple]="true"
                                                   [closeOnSelect]="false"
                                                   placeholder="Items"
                                                   [formControl]="form.controls[control.tag]">
                                        </ng-select>
                                    </ng-container>
                                    <input *ngIf="control.type === 'time'" class="form-control" type="time" [formControl]="form.controls[control.tag]" />
                                </td>
                                <td>
                                    <button type="button" class="btn btn-danger" (click)="Remove(i)">Remove</button>
                                </td>
                            </tr>
                        </ng-container>

                    </tbody>
                </table>
            </div>
        </form>
        <div class="text-right">
            <button (click)="savingGridValues()" class="btn btn-primary" [disabled]="submitting">
                <i class="fe-save"></i>
                <span *ngIf="!submitting">Save</span>
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Submitting
                </span>
            </button>
        </div>
    </div>
</div>

<div *ngIf="format === 'FormFormat'">
    <form [formGroup]="formGroup">
        <table class="table tbno-border m-0">
            <tbody>
                <tr>
                    <th class="sec-head">
                        <div class="sec-heading"><span>{{descripion}}</span></div>
                    </th>
                </tr>
            </tbody>
        </table>
        <div class="form-group ">
            <div *ngFor="let control of formObject; let j = index">
                <label>{{ control.label }}</label>
                <input *ngIf="control.type === 'number'" class="form-control" type="number" [formControlName]="control.tag" />
                <textarea *ngIf="control.type === 'textarea'" class="form-control" [formControlName]="control.tag"></textarea>
                <input *ngIf="control.type === 'text'" class="form-control" type="text" [formControlName]="control.tag" />
                <input *ngIf="control.type === 'date'" class="form-control" type="date" [formControlName]="control.tag" />
                <select *ngIf="control.type === 'select'" class="form-control" [formControlName]="control.tag">
                    <option *ngFor="let option of control.choices" [value]="option">{{ option }}</option>
                </select>
                <ng-container *ngIf="control.type === 'multiselect'">
                    <ng-select [items]="control.choices"
                               [multiple]="true"
                               [closeOnSelect]="false"
                               placeholder="Items"
                               [formControlName]="control.tag">
                    </ng-select>
                </ng-container>
                <div *ngIf="control.type === 'checkbox'" class="checkbox-options">
                    <div>
                        <div class="custom-control custom-checkbox p-0">
                            <input type="checkbox" [id]="'customCheckBox1' + j" [formControlName]="control.tag" class="custom-control-input" (change)="checkedone($event)" [name]="control.name + j" />
                            <label class="custom-control-label" [for]="'customCheckBox1' + j">
                                <span class="toggle-group">
                                    <span class="btn btn-success rounded-0">Yes</span>
                                    <span class="btn bg-white"></span>
                                    <span class="btn btn-light rounded-0">No</span>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="btn btn-primary f-right"  (click)="saveFormFormatValues()" [disabled]="submitting">
            <i class="fe-save"></i>
            <span *ngIf="!submitting">Save</span>
            <span *ngIf="submitting">
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                Submitting
            </span>
        </button>
    </form>
</div>
















