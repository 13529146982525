import { Component, OnDestroy, OnInit, Input, HostListener } from '@angular/core';
import { OB } from '../../../areas/admin/full-transcript/pages/ob-report/ob.namespace';
import { takeUntil, finalize } from 'rxjs/operators';
import { Page, IUserAccount, PatientProfile, IEncounterResource } from '../../models';
import { HttpService, AppData, EncounterCommunication, TimelineToggleService } from '../../services';
import { Appointment, PreviousAppointment } from '../../entities';
import { ApiResources, UtilHelper } from '../../helpers';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DietEncounter } from '../../models/diet-encounter.model';
import { Diet } from '../../../areas/admin/full-transcript/pages/diet-report/diet.namespace';
import { OBEncounterType } from '../../enums';

@Component({
    selector: 'diet-header',
    templateUrl: './diet-header.html',
    styleUrls: ['./diet-header.css']
})
export class DietHeaderWidget implements OnDestroy, OnInit {
    @Input() patientId: string;
    @Input() appointmentId: string;
    page: Page;
    isAdmission: any;
    dietEncounter: DietEncounter;
    appointments: any;
    oldAppointment: any;
    encounterCommunicationSubscription: Subscription;
    appointment: Appointment;
    patient: PatientProfile;
    husbandName: string;
    visitNumber: number;
    specialFeature: OB.SpecialFeature;
    visit: number;
    patientName: any;
    umrNo: any;
    roomName: any;
    dietvisit: Diet.DietPlanPrescribed;
    obEncounter: IEncounterResource;
    wifeName: string;
    roleId: number;
    sideNav: boolean;
    gpla: any;
    gplaData: any;
    measureData: any;
    antinantalRisk: OB.AntinantalRisk;
    allergies: OB.Allergies;
    measure: any;

    constructor(
        private readonly httpService: HttpService,
        private readonly appData: AppData,
        private readonly encounterCommunication: EncounterCommunication,
        private readonly router: Router,
        public timelineService: TimelineToggleService
    ) {
        this.page = new Page();
    }

    private findAppointment(appointmentId: string) {
        const request = {
            encryptedAppointmentId: appointmentId
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointment = response;
                this.patientName = this.appointment.patientName;
                this.umrNo = this.appointment.umrNo;
                this.roomName = this.appointment.roomName

                this.findPatientDetails();
                 this.find();
                
                this.paddingTop();
            });
    }

    private findPatientDetails() {
        this.page.loading = true;
        this.httpService.post<PatientProfile>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.profile), { encryptedPatientId: this.appointment.encryptedPatientId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: PatientProfile) => {
                this.patient = response;
                if (this.appointment.patientGender == "F")
                    this.husbandName = this.patient.relativeDetails[0] ? this.patient.relativeDetails[0].fullName : null;

                this.wifeName = this.patient.relativeDetails[0] ? this.patient.relativeDetails[0].fullName : null;


            });
    }

 

    private find() {
        const request = {
            encryptedAppointmentId: this.appointmentId,
            encryptedProviderId: this.page.userAccount && this.page.userAccount.encryptedReferenceId ? this.page.userAccount.encryptedReferenceId : null,
            encryptedPatientId: this.patientId,
            isAdmission: this.isAdmission
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<[DietEncounter, Appointment, PreviousAppointment]>(ApiResources.getURI(ApiResources.dietEncounter.base, ApiResources.dietEncounter.find), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: [DietEncounter, Appointment, PreviousAppointment]) => {
                this.dietEncounter = response["dashboard"];
               
                if (this.dietEncounter) {
                    this.antinantalRisk = this.dietEncounter.antinantalRisk ? JSON.parse(this.dietEncounter.antinantalRisk) : null;
                    this.allergies = this.dietEncounter.allergies ? JSON.parse(this.dietEncounter.allergies) : null;
                    this.specialFeature = this.dietEncounter.specialFeature ? JSON.parse(this.dietEncounter.specialFeature) : null;
                    this.measure = this.dietEncounter.measure ? JSON.parse(this.dietEncounter.measure) : null;
                    this.visitNumber = this.measure && this.measure.visitNo ? this.measure.visitNo : null;
                }
            });
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    this.isAdmission = url.split("/")[4] === "a";
                    this.appointmentId = url.split("/")[url.split("/").length - 3];
                    this.roleId = this.page.userAccount.roleId;
                    this.encounterCommunicationSubscription = this.encounterCommunication.dietEncounter.subscribe(() => {

                        if (this.appointmentId == "b" || this.appointmentId == "a") {
                            var currentUrl = this.router.url;
                            this.appointmentId = currentUrl.split("/")[currentUrl.split("/").length - 4];
                        }
                        this.findAppointment(this.appointmentId);
                    });
                } else {
                    this.page.userAccount = undefined;
                }
                this.findAppointment(this.appointmentId);
                this.fetchGPLA(this.appointmentId);
                this.find();
            });
    }
    @HostListener('window:resize')
    onResize() {
        this.paddingTop();
    }
    @HostListener('window:load')
    onLoad() {
        setTimeout(() => { this.paddingTop(); }, 1500)
    }
    @HostListener('window:hashchange')
    onhashchange() {
        setTimeout(() => { this.paddingTop(); }, 500)
    }
    paddingTop() {
        if (document.getElementById("header") != null && document.getElementsByClassName("content")[0] != null) {
            var paddingTopValue = document.getElementById("header").offsetHeight;
            var content = document.getElementsByClassName("content")[0];
            content.setAttribute('style', `padding-top:${paddingTopValue}px`);
        }
    }
    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.encounterCommunicationSubscription.unsubscribe();
    }

    toggleIt() {
        this.timelineService.toggle();
        if (this.timelineService.showBox == false) {
            $("body")[0].setAttribute("data-timeline-size", "timelineabsent");
        } else {
            $("body")[0].setAttribute("data-timeline-size", "timeline");
        }
    }
    toggleSideNav() {
        $(".sidebar-main-menu")[0].classList.toggle("removeLeft");
        if ($("body")[0].getAttribute('data-sidebar-size') === "default") {
            $("body")[0].setAttribute("data-sidebar-size", "condensed");
            this.sideNav = true;
        } else if ($("body")[0].getAttribute('data-sidebar-size') === "condensed") {
            $("body")[0].setAttribute("data-sidebar-size", "default");
            this.sideNav = false;
        }
    }
    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }

    private fetchGPLA(appointmentId: any) {
        const request = Object.assign(UtilHelper.clone({ encryptedAppointmentId: appointmentId, isAdmission: this.isAdmission }));
        this.httpService.post(ApiResources.getURI(ApiResources.dietEncounter.base, ApiResources.dietEncounter.findgpla), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: any) => {
                this.gpla = response;
                if (this.gpla != null) {
                    if (this.gpla.gynHistory || this.gpla.gplaData) {
                        const gynHistory = JSON.parse(this.gpla.gynHistory);
                        this.gplaData = gynHistory;
                    }
                }
            });
    }
}