<style>
    .dropdown-menu {
        padding: 0.3rem;
        box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
        animation-name: DropDownSlide;
        animation-duration: .3s;
        animation-fill-mode: both;
        margin: 0;
        position: absolute;
        z-index: 1000;
        max-height: 400px !important;
        overflow: auto;
    }
</style>
<div>
    <form [formGroup]="productForm" (submit)="onSubmit()">
        <div class="modal-body" style="max-height:650px;overflow-y:auto;">
            <div class="row">
                <div class="col-12">
                    <div class="grid-container bg-light p-2 mt-0 mb-1">
                        <i class="mdi mdi-medical-bag mr-1"></i>Item Details
                    </div>
                </div>
                <ng-container *ngIf="productForm.value.inventoryItem">
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="!loading && toggleSettingValue && toggleSettingValue.itemName">
                        <div class="form-group">
                            <label class="mb-0">Item Name <code *ngIf="!loading && toggleSettingValue && toggleSettingValue.isItemNameManditory">*</code></label>
                            <input type="text" class="form-control" block autocomplete="off"
                                   autofocus
                                   placeholder="Search or enter general item name"
                                   formControlName="productName"
                                   [ngClass]="{ 'is-invalid': (submitted && form.productName.errors) }">

                            <div *ngIf="submitted && form.productName.errors" class="invalid-feedback show-must">
                                <div *ngIf="submitted && form.productName.errors.productExists">Product already exists</div>
                            </div>

                            <div *ngIf="submitted && form.productName.errors" class="invalid-feedback show-must">
                                <div *ngIf="form.productName.errors.whiteSpaces">Please remove white spaces.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="!loading && toggleSettingValue && toggleSettingValue.category">
                        <div class="form-group" >
                            <div class="d-flex justify-content-between">
                                <label class="mb-0">Category <code *ngIf="!loading && toggleSettingValue && toggleSettingValue.isCategoryManditory">*</code></label>
                                <a href="javaScript:;" (click)="onOpenOtherFormModel(templateLookUp,'C')"><i class="mdi mdi-plus mr-1"></i>Add</a>
                            </div>
                            <ng-select class="ng-select-sm" [items]="category"
                                       [loading]="loadingCategory"
                                       bindLabel="value"
                                       bindValue="id"
                                       typeToSearchText="Search Category"
                                       [virtualScroll]="true"
                                       placeholder="Enter category name"
                                       formControlName="categoryId"
                                       [ngClass]="{ 'is-invalid': submitted && form.categoryId.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                        No category found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.value"></h5>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="!loading && toggleSettingValue && toggleSettingValue.subcategory">
                        <div class="form-group">
                            <label class="mb-0">Sub Category <code *ngIf="!loading && toggleSettingValue && toggleSettingValue.isSubCategoryManditory">*</code></label>
                            <input type="text" class="form-control" block autocomplete="off" placeholder="Enter Sub Category name" formControlName="subCategoryName" [ngClass]="{ 'is-invalid': submitted && form.subCategoryName.errors }" />
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="!loading && toggleSettingValue && toggleSettingValue.manfacturer">
                        <div class="form-group">
                            <div class="d-flex justify-content-between">
                                <label class="mb-0">Manufacturer <code *ngIf="!loading && toggleSettingValue && toggleSettingValue.isManfacturerManditory">*</code></label>
                                <a href="javaScript:;" (click)="onOpenOtherFormModel(templateCompany,'M')"><i class="mdi mdi-plus mr-1"></i>Add</a>
                            </div>
                            <ng-select class="ng-select-sm" [items]="companies"
                                       [loading]="loadingCompany"
                                       bindLabel="name"
                                       bindValue="companyId"
                                       typeToSearchText="Search manufacturer"
                                       [virtualScroll]="true"
                                       placeholder="Enter manufacturer"
                                       formControlName="companyId"
                                       [ngClass]="{ 'is-invalid': submitted && form.companyId.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                        No manufacturer found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.name"></h5>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="!loading && toggleSettingValue && toggleSettingValue.gst">
                        <div class="form-group">
                            <label class="mb-0">GST<code *ngIf="!loading && toggleSettingValue && toggleSettingValue.isGstManditory">*</code></label>
                            <ng-select class="ng-select-sm" [items]="tax"
                                       [loading]="loadingTax"
                                       bindLabel="value"
                                       bindValue="id"
                                       typeToSearchText="Search GST"
                                       [virtualScroll]="true"
                                       placeholder="Enter GST"
                                       formControlName="taxId"
                                       [ngClass]="{ 'is-invalid': submitted && form.taxId.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                        No GST found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.value + '%'"></h5>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="!loading && toggleSettingValue && toggleSettingValue.assest">
                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" formControlName="asset" id="flexCheckDefaultAsset">
                                <label class="form-check-label" for="flexCheckDefaultAsset">
                                    Asset
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="!loading && toggleSettingValue && toggleSettingValue.isProductExpire">
                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" formControlName="isProductExpire" id="flexCheckDefaultExpired">
                                <label class="form-check-label" for="flexCheckDefaultExpired">
                                    Is Product Expires?
                                </label>
                            </div>
                        </div>
                    </div>
             
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="!loading && toggleSettingValue && toggleSettingValue.discount" >
                        <div class="form-group">
                            <label class="mb-0">Discount <code *ngIf="!loading && toggleSettingValue && toggleSettingValue.isDiscountManditory"> *</code></label>
                            <input type="number" class="form-control" min="0" numbersOnly placeholder="Enter discount " formControlName="discount"  [ngClass]="{ 'is-invalid': (submitted && form.discount.errors) }" />
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="!loading && toggleSettingValue && toggleSettingValue.department">
                        <div class="form-group">
                            <label class="mb-0">Department <code *ngIf="!loading && toggleSettingValue && toggleSettingValue.isDeptManditory">*</code></label>
                            <input type="text" class="form-control" min="0" placeholder="Enter departmnent " formControlName="department" [ngClass]="{ 'is-invalid': (submitted && form.department.errors) }" />
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4 " *ngIf="!loading && toggleSettingValue && toggleSettingValue.group">
                        <div class="form-group">
                            <label class="mb-0">Group<code *ngIf="!loading && toggleSettingValue && toggleSettingValue.isGroupManditory">*</code></label>
                            <input type="text" class="form-control" block autocomplete="off" placeholder="Enter Group name" formControlName="groupName" [ngClass]="{ 'is-invalid': (submitted && form.groupName.errors) }" />
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="!loading && toggleSettingValue && toggleSettingValue.subgroup">
                        <div class="form-group">
                            <label class="mb-0">Sub Group <code *ngIf="!loading && toggleSettingValue && toggleSettingValue.isSubgroupManditory">*</code></label>
                            <input type="text" class="form-control" block autocomplete="off" placeholder="Enter Sub Group name" formControlName="subGroupName" [ngClass]="{ 'is-invalid': (submitted && form.subGroupName.errors) }" />
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="grid-container bg-light p-2 mt-0 mb-1">
                            <i class="mdi mdi-numeric mr-1"></i>Purchase Policy
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="!loading && toggleSettingValue && toggleSettingValue.purchaseUnit">
                        <div class="form-group">
                            <div class="d-flex justify-content-between">
                                <label class="mb-0"> Purchase UOM<span class="font-11">(e.g Tablet,Strip,Box..etc) </span> <code *ngIf="!loading && toggleSettingValue && toggleSettingValue.isPurchaseUnitManditory">*</code></label>
                                <a href="javaScript:;" (click)="onOpenOtherFormModel(templateLookUp,'U','P')"><i class="mdi mdi-plus mr-1"></i>Add</a>
                            </div>
                            <ng-select class="ng-select-sm" [items]="units"
                                       [loading]="loadingUnit"
                                       bindLabel="value"
                                       bindValue="id"
                                       typeToSearchText="Search Unit"
                                       [virtualScroll]="true"
                                       placeholder="Enter unit name"
                                       formControlName="purchaseUnit"
                                       [ngClass]="{ 'is-invalid': submitted && form.purchaseUnit.errors }">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                        No unit found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-i="index">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mb-0 mt-0 font-13 font-weight-normal" [textContent]="item.value"></h5>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4" *ngIf="!loading && toggleSettingValue && toggleSettingValue.purchaseUnitQty">
                        <div class="form-group">
                            <label class="mb-0">Issue UOM
                                <span class="font-11">(e.g 15,10,1..etc) </span> <code *ngIf="!loading && toggleSettingValue && toggleSettingValue.isPurchaseUnitQtyManditory">*</code>
                            </label>
                            <input type="number" class="form-control" min="0" numbersOnly placeholder="Enter pack size" formControlName="purchaseUnitQty" [ngClass]="{ 'is-invalid': (submitted && form.purchaseUnitQty.errors) }" />
                        </div>
                    </div>
                </ng-container>
               
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default btn-sm" (click)="onDirectOmit();">Cancel</button>
            <button type="submit" [disabled]="submitting" class="btn btn-primary btn-sm ml-1">
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting">Submit</span>
            </button>
        </div>
    </form>
</div>

<ng-template #templateLookUp>
    <form [formGroup]="lookUpForm" (submit)="onSubmitLookup()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-account-cog-outline mr-1"></i><span>Add</span> {{typeOf}}</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="mb-0">{{typeOf}} Name <code>*</code></label>
                        <input type="text" class="form-control" formControlName="name" placeholder="Enter Unit Name" [ngClass]="{ 'is-invalid': submitted && otherForms.lookup.name.errors }" />
                        <div *ngIf="submitted && otherForms.lookup.name.errors" class="invalid-feedback show-must">
                            <div *ngIf="otherForms.lookup.name.errors.required">Name is required.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default btn-sm" (click)="onCloseModal();">Cancel</button>
            <button type="submit" [disabled]="submitting" class="btn btn-primary btn-sm ml-1">
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting">Submit</span>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #templateCompany>
    <form [formGroup]="companyForm" (submit)="onSubmitCompany()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-account-cog-outline mr-1"></i><span>Add</span> Manufacturer</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="mb-0">Manufacturer Name <code>*</code></label>
                        <input type="text" formControlName="name" placeholder="Enter name" class="form-control" [ngClass]="{'is-invalid': submitted && otherForms.company.name.errors }" />
                        <div class="show-must text-danger" *ngIf="submitted && otherForms.company.name.errors">
                            <span *ngIf="otherForms.company.name.errors.required">This field is required.</span>
                            <span *ngIf="otherForms.company.name.errors.whiteSpaces">Please remove whitespaces.</span>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label class="mb-0">Location <code>*</code></label>
                        <textarea class="form-control" formControlName="location" placeholder="Enter location." [ngClass]="{'is-invalid': submitted && otherForms.company.location.errors }"></textarea>
                        <div class="show-must text-danger" *ngIf="submitted && otherForms.company.location.errors">
                            <span *ngIf="otherForms.company.location.errors.required">This field is required.</span>
                            <span *ngIf="otherForms.company.location.errors.whiteSpaces">Please remove whitespaces.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default btn-sm" (click)="onCloseModal();">Cancel</button>
            <button type="submit" [disabled]="submitting" class="btn btn-primary btn-sm ml-1">
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting">Submit</span>
            </button>
        </div>
    </form>
</ng-template>