export class LabSampleType {
    labSampleTypeId: number;
    typeName: string;
    active: boolean;
}

export class LabMainDetail {
    labMainDetailId: number;
    labDepartmentId: number;
    labSampleTypeId: number;
    createdBy: number;
    modifiedBy?: number;
    totalItems: number;

    testName: string;
    testCode: string;
    typeName: string;
    departmentName: string;
    createdByName: string;
    modifiedByName: string;
    joined: string;

    active: boolean;
    isInternalLab: boolean;
    isExternalLab: boolean;
    sampleUsage: string;
    createdDate: Date;
    modifiedDate?: Date;

    charges: Array<LabMainDetailCharge>;
    templates: Array<LabMainDetailTemplate>;
    isLoading: boolean;
    patientLabDetailId?: number;
    chargeExistForCurrentLocation?: number;
    chargesSet?: boolean;
    selected: boolean;
    nablRequired: boolean;
    consentFormRequired: boolean;
    assignDoctorRequired: boolean;
    labVacutainerId: number;
    labVacutainerName: string;
    noOfSamplesCollect: number;
    noOfSamplesCollectText: string;
    testPrecaution: string;
    entryCode: string;
    trim: any;
    createdByRole: string;
    modifiedByRole: string;
    isPaid: boolean;
    agencyNames: string;
    templateDetailId: number;
    templateHeaderId: number;
    isPackage: boolean;
    testDurationTime: any;
    isSelected: boolean;
    isChecked: boolean;
    amount: number;
    emergencyAmount: number;
    schemaReport: boolean;
    needAdditionalSignature: boolean;
    isEmergency: boolean;
    isQuickReport: boolean;
    encounterLabAddDate: any;
    encounterLabAdd: any;
    constructor() {
        this.charges = new Array<LabMainDetailCharge>();
        this.templates = new Array<LabMainDetailTemplate>();
    }
}

export class LabMainDetailCharge {
    amount: any;
    labMainDetailChargeId: number;
    labMainDetailId: number;
    chargeCategoryId: number;
    rate?: number;
    locationId: number;
    locationName: string;
    chargeCategoryName: string;
    default: boolean;
    referenceId: number;
}

export class LabMainDetailTemplate {
    labMainDetailTemplateId: number;
    labMainDetailId: number;
    labTemplateHeaderId: number;
    priority: number;
    templateName: string;
    templateId: string;
}