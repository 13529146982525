import { Component, OnInit, Input, ElementRef, ViewChild, TemplateRef } from "@angular/core";
import { FormControl, FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { HttpService, NotifyService, AppData } from "../../../../../shared/services";
import { ApiResources } from "../../../../../shared/helpers";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as ts from 'typescript';
import { takeUntil } from "rxjs/operators";
import { IUserAccount, Page } from "../../../../../shared/models";
import { DatePipe } from "@angular/common";

@Component({
    selector: "gridview-selector",
    templateUrl: "./gridview.html",

})

export class gridviewcomponent implements OnInit {
    @ViewChild('editor', { static: true }) editor: ElementRef;
    @ViewChild("gridviewdata", { static: true }) gridviewdata: TemplateRef<any>;
    @ViewChild('datepickerInput') datepickerInput: ElementRef;
    emptyFormIndices:any=[]
    @Input() patientId: any;
    @Input() TemplateId: any;
    @Input() EncounterId: any;
    @Input() IsDashBoardTemplateId: any;
    @Input() Sections: any; 
    page: Page
    @Input() sectionId: any
    modalRef: NgbModalRef;
    @Input() typeid: any;
    format: any
    sectionData1: any = []
    data: any
    patientValues: any
    isShown: boolean = true
    @Input() extragriddata: any
    products: any = []
    patientData: any
    selectedRadio: any = {};
    myVar1: any
    checked: boolean = false
    submitting: boolean = false;
    descripion: any
    controls: FormArray;
    selectedProducts: any;
    constructor(private formbulider: FormBuilder, private httpService: HttpService, private notifyService: NotifyService, private readonly modalService: NgbModal, private readonly appData: AppData, private datePipe: DatePipe) {
        this.page = new Page();
    }

    formObject: any;
    formsArray: any[] = []
    arraydata: any[] = []
    formGroup: FormGroup;


    addForm1() {
        this.patientId
        this.sectionId
        const formGroup = {};
        this.formObject.forEach((field) => {
            if (field.type === 'date') {
                formGroup[field.tag] = new FormControl('');
            } else {
                formGroup[field.tag] = new FormControl('');
            }

        });
        this.formsArray.push(this.formbulider.group(formGroup));
    }


    formatDateForDisplay(date: Date): string {
        return this.datePipe.transform(date, 'dd-MM-yyyy');
    }


    onDateInput(event: any, controlTag: string) {
        const inputValue = event.target.value;
        this.formsArray[0].controls[controlTag].setValue(inputValue);
    }



    onOpenAppointmentModel(content?: TemplateRef<any>) {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "xl",
            windowClass: "custom-modal effect-scale"
        });
    }


    onCloseModal() {
        this.modalRef.close();
        this.modalRef = undefined;
    }



    portalForm() {
        this.formObject.forEach((control) => {
            if (control.type === 'checkbox') {
                this.formGroup.addControl(control.tag, this.formbulider.control(false));
            } else if (control.type === 'text') {
                this.formGroup.addControl(control.tag, this.formbulider.control(''));
            } else if (control.type === 'date') {
                this.formGroup.addControl(control.tag, this.formbulider.control(''));
            } else if (control.type === 'select') {
                this.formGroup.addControl(control.tag, this.formbulider.control(''));
            } else if (control.type === 'number') {
                this.formGroup.addControl(control.tag, this.formbulider.control(''));
            } else if (control.type === 'textarea') {
                this.formGroup.addControl(control.tag, this.formbulider.control(''));
            } else if (control.type === 'multiselect') {
                this.formGroup.addControl(control.tag, this.formbulider.control(''));
            }
        });
    }


    checkEmpty(data: any[]): boolean {
        return Object.values(data).every(value => {
            if (typeof value === 'boolean') {
                return true; // Skip boolean values
            }
            return !value || (Array.isArray(value) && value.length === 0);
        });
    }


    saveFormFormatValues() {
        this.submitting = true;
        const hasNonEmptyValue = this.checkEmpty(this.formGroup.value);
        if (hasNonEmptyValue) {
            this.notifyService.warning("Please Enter Values");
            this.submitting = false;
            this.fetchSectionData() 
            return;
        }

        if (this.formGroup.valid) {
            const formData = this.formGroup.value;
            delete formData.controls; // to delete control
            this.data = formData;
            this.addDynamic()
        }
    }



    fetchGridData() {
      
        const request = { SectionId: this.sectionId }
        this.httpService.post(ApiResources.getURI(ApiResources.Adddynamictempsec.base, ApiResources.Adddynamictempsec.fetchbyId), request)
            .subscribe((response: any) => {
                this.descripion = response.name
                let y = (response.view)
                let z = JSON.parse(y)
                this.formObject = z
                this.fetchSectionData();
                this.format = response.script
                if (this.format == "FormFormat") {
                    this.portalForm()
                } else {
                    this.addForm1();
                }
                if (this.extragriddata) {
                    this.isShown = false
                    this.onOpenAppointmentModel(this.gridviewdata);

                }
            });
    }


    savingGridValues() {
    
        this.submitting = true;
        const formvalues = [];
        for (const form of this.formsArray) {
            const formgroupvalue = {};
            for (const field of this.formObject) {
                const controlvalue = form.controls[field.tag].value;
                formgroupvalue[field.tag] = controlvalue;
            }
            formvalues.push(formgroupvalue);
        }
        this.patientValues = formvalues
        this.addDynamic()
        this.onCloseModal()
    }

    

    Remove(index) {
     
        this.formsArray.splice(index, 1)
        return [...this.formsArray]
    }


    ngOnInit() {
        this.fetchGridData();
        this.formGroup = this.formbulider.group({
            controls: this.formbulider.array([])
        });
        this.controls = this.formGroup.get('controls') as FormArray;
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((account: IUserAccount) => {
                if (account) {
                    this.page.userAccount = account;
                } else {
                    this.page.userAccount = undefined;
                }
            });

    }
    

    addDynamic() {
        if (this.format == "FormFormat") {
            y = JSON.stringify(this.data)
        } else {
            //const hasNonEmpty = this.checkEmptyGridformate(this.patientdata)
            if (this.patientValues != undefined && this.patientValues === '') {
                this.notifyService.warning("Please Enter Values");
                this.submitting = false;
                this.fetchSectionData()
                return;
            }
            var y = JSON.stringify(this.patientValues)
        }
        let modififed = this.page.userAccount.accountId
        let createdby = this.page.userAccount.accountId
        const request = { isactive: true, PatientId: this.patientId, data: y, SectionId: this.sectionId, ModifiedBy: modififed, CreatedBy: createdby, EncounterId: this.EncounterId, TemplateId: this.TemplateId, TemplateIDs: this.IsDashBoardTemplateId, Sections: this.Sections };
        this.httpService.post(ApiResources.getURI(ApiResources.sectiondata.base, ApiResources.sectiondata.insert), request)
            .subscribe((response: any) => {
                this.fetchSectionData();
                this.submitting = false;
                this.notifyService.success("Data Saved Succesfully");
            });
    }


    patchSavedValues() {
        
        let count = 0
        this.sectionData1.forEach(x => {
            count = count + 1;
            if (count > this.formsArray.length) {
                const formGroup = {};
                this.formObject.forEach((field) => {
                    formGroup[field.tag] = new FormControl('');
                });
                this.formsArray.push(this.formbulider.group(formGroup));
            }
            this.formsArray[count - 1].value = x 
            this.formsArray[count - 1].patchValue(x)
        })
    }


    fetchSectionData() {
       
        const request = { EncounterId: this.EncounterId, SectionID: this.sectionId, TemplateId: this.TemplateId, PatientId: this.patientId };
        this.httpService.post(ApiResources.getURI(ApiResources.sectiondata.base, ApiResources.sectiondata.fetch), request)
            .subscribe((response: any) => {
                this.sectionData1 = JSON.parse(response.data)
                this.sectionData1.Remarks
                for (let i = 0; i < this.sectionData1.length; i++) {
                    const obj = this.sectionData1[i];
                    // Iterate through object properties
                    for (const key in obj) {
                        if (obj.hasOwnProperty(key)) {
                            const originalValue = obj[key];
                            // Check if the value is a string and in "dd-mm-yyyy" format
                            if (typeof originalValue === 'string' && /^\d{2}-\d{2}-\d{4}$/.test(originalValue)) {
                                const dateParts = originalValue.split('-');
                                const month = dateParts[1];
                                const day = dateParts[0];
                                const year = dateParts[2];

                                // Update the value to "mm-dd-yyyy" format ``
                                const convertedDate = `${year}-${month}-${day}`;
                                //const convertedDate = `${month}-${day}-${year}`;
                                obj[key] = convertedDate;
                            }
                        }
                    }
                }
                this.formGroup.patchValue(this.sectionData1);
                this.patchSavedValues();
            })
    }


    transpileTypeScript(code: string): string {
        const transpiledCode = ts.transpileModule(code, { compilerOptions: { module: ts.ModuleKind.CommonJS } });
        return transpiledCode.outputText;
    }

}