export class InventoryProduct {
    approvedQuantity: number;
    approvedQuantityCompleted: number;
    inventoryProductId: number;
    pharmacyProductId: number;
    productName: string;
    companyId: number;
    categoryId: number;
    supplierId: number;
    taxId: number;
    maxQuantity: number;
    minQuantity: number;
    reorderLevelQuantity: number;
    rolQuantity: number;
    rackId: number;
    purchaseUnit: number;
    purchaseUnitQuantity: number;
    purchaseUnitQty: number;
    saleUnit: number;
    saleUnitQuantity: number;
    saleUnitQty: number;
    isBatchNumber: boolean;
    isExpiry: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy?: number;
    modifiedDate?: Date;
    companyName: string;
    categoryName: string;
    tax: string;
    rackName: string;
    purchaseUnitName: string;
    saleUnitName: string;
    createdByName: string;
    modifiedByName: string;
    genericName: string;
    barcode: string;
    isProductExpire: boolean;
    isAdded: boolean;
    totalItems: number;
    pharmacyStockId: number;
    pharmacyRetailStockId: number;
    quantityIn: number;
    quantityOut: number;
    availableQuantity: number;
    batchNumber: string;
    expiryDate?: Date;
    purchaseRate: number;
    mrp: number;
    free?: number;
    taxPercentage: number;
    isGetProductId: boolean;
    supplierProductId: number;
    quantity: number;
    requiredQuantity: number;
    indentType: string;
    status: string;
    total: number;
    taxPerItem: number;
    taxAmount: number;
    discountPerItem: number;
    discountAmount: number;
    netAmount: number;
    pharmacyPurchaseDetailId: number;
    pharmacyPurchaseHeaderId: number;
    toDate: string;
    fromDate: string;
    produt: string;
    retailStore: string;
    hsnCode: string;
    medicationId: string;
    requestedQuantity: number;
    inventoryPurchaseDetailId: number;
    inventoryPurchaseHeaderId: number;
    suppliers: string;

    gstType: string;
    isSGST: boolean;
    isIGST: boolean;
    isGeneralItem: boolean;

    scheduledDrug: string;

    storageTypeId?: number;
    storageTypeName?: string;
    pharmacyProductSubTypeId: number;
    subTypeName: string;
    pharmacyProductTypeId: number;
    typeName: string;
    onlyConsumable: boolean;


    potency: string;
    dosage: any;
    formula: string;
    strength: string;
    drugRiskId: number | null;
    nabhCategoryId: number | null;
    dosageTypeId: number | null;
    medFrequencyMasterId: number | null;
    noOfTimes: number | null;
    inventoryExpiry: number | null;
    formulationId: number | null;
    medRouteId: number | null;
    saleLoose: boolean;
    fixedDose: number | null;
    alchoholInteraction: string;
    pregnancyInteraction: string;
    expertAdvice: string;
    commonSideEffects: string;
    medicineFaq: string;
    medicineInteraction: string;
    usage: string;
    drugRiskName: string;
    nABHCategoryName: string;
    dosageTypeName: string;
    frequencyName: string;
    formulationName: string;
    medRouteName: string;
    searchParam: string;

    retailName: string;
    inventoryItem: boolean;
    consumable: boolean;
    asset: boolean;
    discount: number;
    active: boolean;
    requestedRetailId?: number;

    pharmacyDepartmentalStockId: number;
    issuedQty: number;

    //pediatric item master
    dosageBased: string;
    age_Weight: string;
    ageBasedItems: string;
    weightBasedItems: string;
    frequencyDays_Years: string;
    instructions: string;
    fixedQuantity: number;
    duration: string;
    invoiceNumber: number;
    warehouse: string;
    vendor: string
    roq: number;
    rol: number;
    supplierName: string;
    catagoryId: number;
    returnQuantity: number;
    availableQty: number;
    returnToVender: boolean;
    selected: boolean;
    isSelected: boolean;
    pharmacyWareHouseId: number;
    loginRoleId: number;
    amount: number;
    returnFreeQuantity: number;
    loginAccountId: number;
    purchaseCost: number;
    department: string;
    groupName: string;
    subGroupName: string;
    categorysName: string;
    subCategoryName: string;
    requestType: string;
    isSurgicalProduct: boolean;
    emergencyDrug: boolean;
    soundAlike: boolean;
    lookAlike: boolean;
    highRisk: boolean;

 
    iswithCreditNote: boolean;
    pediatricFreq: number;
    frequency: number;
    per: number;
    strengthMg: number;
    totDay: string;
    qtyInOneBottle: number;
    productNumber: string;
    isRaiseIndent: boolean;

    name: string;
    lookupValueId: number;
    pharmacyPurchaseReturnHeaderId: number;
    documentName: string;
    documentType: string;
    contentType: string;
    size: number;
    description?: string;
    documentUrl: string;
    thumbnailUrl: boolean;
    uploadedDate: Date;
    base64: string;
    formedUrl: any;
    uploadedBy: number;
    returnToVendorDocumentId: number;
    maxFileSize: string;
    isImage: boolean;
    isVideo: boolean;
    documentCount: number;
    inHandQuantity: number;
    isWorkOrderItem: boolean;
    pharmacyDepartmentIndentDetailId: number;
    rejectedBy: number;
    pharmacyProductRequestId: number;
    quantityToApprove: number;
    isEditable: boolean;
    prescribedQty: number;
    workOrderDocumentId: number;
    isChecked = false;
    gatepassNumber: number;
    gatepassDate: Date;
    selectedProductName: string;
}