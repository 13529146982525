
<div class="bg-white margin-left-14px margin-right-14px">
    <div class="border-soft-secondary border-left-0 border-right-0 border d-flex justify-content-between bg-white">
        <div class="align-items-center d-flex gap5px headerHeight textColorEncounter white-space-nowrap">
            <div class="gap5px align-items-center d-flex">
                <div class="align-self-start avatar-sm">
                    <img *ngIf="appointment && appointment.patientThumbnailUrl" [src]="safe(appointment.patientThumbnailUrl)" class="rounded-circle avatar-sm" [alt]="appointment.patientName">
                    <span *ngIf="appointment && !appointment.patientThumbnailUrl" class="avatar-title rounded-circle bg-primary text-white" [textContent]="appointment.patientName | initials"></span>
                </div>
                <h4 class="font-16 white-space-nowrap"><a href="javascript:;" class="mb-0 mt-0  text-uppercase fontAt1366px text-primary" *ngIf="appointment && appointment.patientName">{{appointment.patientName}}</a></h4>
                <h4 class="font-16 white-space-nowrap">MR NO:<a href="javascript:;" class="mb-0 mt-0  text-uppercase text-primary fontAt1366px" *ngIf="appointment && appointment.umrNo">{{appointment.umrNo}}</a></h4><br />
            </div>
            <div class="d-flex gap5px h-100 p-1 align-items-center" *ngIf="appointment && appointment.providerName!==null">
                <div class="width30px">
                    <img class="w-100" src="assets/images/timelineSvg/encounter.svg" />
                </div>
                <div >
                    <h4 class="font-16 white-space-nowrap">Visit by <a href="javascript:;" class="mb-0 mt-0  text-uppercase text-primary fontAt1366px">{{appointment.providerName}}</a> on &nbsp;<span class="text-primary fontAt1366px" [textContent]="appointment.appointmentDate|date:'dd/MM/yyyy'"></span><span class="text-primary fontAt1366px">&nbsp;{{appointment.appointmentTimeString}}</span></h4>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex pt-1 pb-1">
        <div class="col-6 p-1">
            <div class="d-flex border encounterWedgets">
                <div class="align-items-center justify-content-center d-flex w-100-px w-75p">
                    <img class="w-75" src="../../../../assets/images/timelineSvg/special-features-c1.svg" />
                </div>
                <div class="p-0 font-weight-medium w-100" *ngIf="!specialFeature">
                    <h5 class="heading-color2 mb-0" *ngIf="!allergies">Special Features:</h5>
                    <div>
                        <span class="line-height-20px heading-color1 font-16"><i class="rotatingExclamation mdi mdi-exclamation text-danger mr-1"></i>Section is not reviewed yet</span><br>
                    </div>
                </div>
                <div class="flex-grow-1" *ngIf="specialFeature && specialFeature.specialFeature && roleId != 63 && roleId != 82">
                    <h5 class="heading-color2 mb-0">Special Features:</h5>
                    <div class="break-all text-black" [textContent]="specialFeature.specialFeature"></div>
                </div>
            </div>
        </div>
    </div>
</div>