import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class TimelineWidgetService {
    private source = new BehaviorSubject<boolean>(true);
    get: Observable<boolean> = this.source.asObservable();
    set(isRefresh: boolean) {
        this.source.next(isRefresh);
    }
}