import { Injectable } from "@angular/core";


@Injectable()
export class NavigationService {

    public hideNavigation = false;

}

