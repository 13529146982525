<div class="content" style="background-color:white;">
    <div class="container-fluid">
        <div class="d-flex align-content-between">
            <div class="col-6">
                <h1 style="color: #fbde06; margin-left: 30px">Labour Ward Cubicle</h1>
            </div>
            <div class="col-2" style="margin-top:20px">
                Date : 2024-11-05
            </div>
            <div class="col-4">
                <banner-setting [reportName]='"Lab Invoice Header"'></banner-setting>
            </div>
        </div>
        <!--<div class="row">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading...</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mh-300">
                <div class="no-data">
                    <img src="assets/images/no-data.png" alt="No data" />
                    <h4 class="title">Oops! No Data found.</h4>
                    <p class="sub-title">There is no data to show you right now.</p>
                </div>
            </div>
        </div>-->
        <div class="col-12 d-flex">
            <div class="col-6">
                <div style="background-color: #fbde06">
                    <div class="d-flex align-content-lg-around">
                        <div class="col-11">
                            <h2>Details</h2>
                        </div>
                        <div class="col-1">
                            <h1 style="color:white;"> &nbsp;</h1>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <table class="col-12 table table-striped">
                        <tbody>
                            <tr>
                                <td>
                                    <span>Name</span>
                                </td>
                                <td>
                                    <span>--</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>W/o - D/o</span>
                                </td>
                                <td>
                                    <span>--</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Consultant</span>
                                </td>
                                <td>
                                    <span>--</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Blood Group</span>
                                </td>
                                <td>
                                    <span>--</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>HB%</span>
                                </td>
                                <td>
                                    <span>--</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Diagnosis</span>
                                </td>
                                <td>
                                    <span>--</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>
                                        Risk Factors
                                    </span>
                                </td>
                                <td>
                                    <span>--</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style="background-color: #fbde06">
                    <div class="d-flex align-content-lg-around">
                        <div class="col-11">
                            <h2>VE Findings</h2>
                        </div>
                        <div class="col-1">
                            <h1 style="color:white;"> &nbsp;</h1>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <table class="col-12 table table-striped">
                        <tbody>
                            <tr>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <banner-setting [reportName]='"Lab Invoice Header"'></banner-setting>
                </div>
            </div>
            <div class="col-6">
                <div style="background-color: #fbde06">
                    <div class="d-flex align-content-lg-around">
                        <div class="col-11">
                            <h2>CTG</h2>
                        </div>
                        <div class="col-1">
                            <h1 style="color:white;">&nbsp;</h1>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <table class="col-12 table table-striped">
                        <tbody>
                            <tr>
                                <td>
                                    <span>Previous</span>
                                </td>
                                <td>
                                    <span>--</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Next</span>
                                </td>
                                <td>
                                    <span>--</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style="background-color: #fbde06">
                    <div class="d-flex align-content-lg-around">
                        <div class="col-11">
                            <h2>Medications</h2>
                        </div>
                        <div class="col-1">
                            <h1 style="color:white;">&nbsp;</h1>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <table class="col-12 table table-striped">
                        <tbody>
                            <tr>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div style="background-color: #fbde06">
                    <div class="d-flex align-content-lg-around">
                        <div class="col-11">
                            <h2>Epidural</h2>
                        </div>
                        <div class="col-1">
                            <h1 style="color:white;">&nbsp;</h1>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <table class="col-12 table table-striped">
                        <tbody>
                            <tr>
                                <td>
                                    <span>Sited at</span>
                                </td>
                                <td>
                                    <span>--</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Top-ups</span>
                                </td>
                                <td>
                                    <span>--</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div style="background-color: #fbde06">
                    <div class="d-flex align-content-lg-around">
                        <div class="col-11">
                            <h2>Syntocinon Infusion</h2>
                        </div>
                        <div class="col-1">
                            <h1 style="color:white;"> &nbsp;</h1>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <table class="col-12 table table-striped">
                        <tbody>
                            <tr>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                                <td>
                                    <span>&nbsp;</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
