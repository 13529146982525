import { Component, ViewEncapsulation, OnInit, OnDestroy, Input } from "@angular/core";
import { IUserAccount, Page } from "../../models";
import { AppData, HttpService, PrintOptionService, ResourceService } from "../../services";
import { finalize, takeUntil } from "rxjs";
import { AdmissionFetchHelper } from "../../../areas/admin/progress-report/shared/helper";
import { ApiResources } from "../../helpers";
import * as converter from "number-to-words";

class PoVsGrnReports {
    createdDate: string;
    approvedDate: string;
    poNumber: string;
    grnNumber: string;
    productName: string;
    requestedQuantity: number;
    approvedQuantity: number;
    billNumber: string;
    billDate: string;
    free: number;
    batchNumber: string;
    expiryDate: string;
    fullName: string;
    supplierName: string;
    companyName: string;
    purchaseRate: number;
    mrp: number;
    pharmacyPurchaseHeaderId: number;
    mobile: string;
    city: string;
    drugLicense: string;
    discountAmount: number;
    taxAmount: number;
    address: string;


}
@Component({
    templateUrl: "./po-order-invoice.html",
    //styleUrls: ['./service-invoive-widget.css'],
    selector: "po-order-invoice",
    encapsulation: ViewEncapsulation.None
})

export class POOrderInvoicePage implements OnInit, OnDestroy {
    @Input() pharmacyPurchaseHeaderId: number;
    @Input() isPrintLogo: boolean;
    @Input() isFooter: boolean;
    page: Page;
    loading: boolean;
    responses: Array<PoVsGrnReports>;
    totalamt: number;
    totaltax: number;
    totalAmtInWords: string;

    constructor(
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
        private readonly appData: AppData,
        private readonly admissionFetchHelper: AdmissionFetchHelper,
        private readonly resourceService: ResourceService
    ) {        
        this.page = new Page();
        this.responses = new Array<PoVsGrnReports>();
        //this.practiceLocations = new Array<IResource>();
    }

    fetch() {
        this.loading = true;             
        const request = {
            pharmacyPurchaseHeaderId: this.pharmacyPurchaseHeaderId
        }
        
        this.httpService.post(ApiResources.getURI(ApiResources.report.base, ApiResources.report.poVsGRNReport), request)
            .pipe(
                takeUntil(this.page.unSubscribe),
                finalize(() => this.loading = false)
            )
            .subscribe((response: Array<PoVsGrnReports>) => {
                this.responses = response;
                this.totalamt = this.responses.reduce((sum, x) => sum + x.mrp, 0);
                this.totaltax = this.responses.reduce((sum, x) => sum + x.discountAmount, 0);
                this.totalAmtInWords = converter.toWords(this.totalamt)
            });
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetch();
                    this.printOptionService.get((is) => { this.isPrintLogo = is; });
                    //this.bannerService.getBannerImage((is) => { this.bannerBasics = is; });
                    //this.currentDate = new Date();
                } else {
                    this.page.userAccount = undefined;
                }
            });

    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }


    
}

