import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { finalize, takeUntil } from "rxjs";
import { ActivatedRoute, Params, Router } from "@angular/router";

import { ApiResources, } from "@shared/helpers";
import { EncounterValue, EncounterType, Appointment, PatientMedicationHeader, ImageReports, PreviousAppointment, PatientDocument, } from "@shared/entities";
import { Page } from "../../models/page.model";
import { OB } from "../../../areas/admin/full-transcript/pages/ob-report/ob.namespace";
import { AppData, EncounterCommunication, HttpService, IconService, PrintOptionService, appUrls } from "../../services";
import { IUserAccount } from "../../models/user-account.model";
import { PhysiotherapyEncounterList } from "../../entities/physiotherapy-encounter.entity";
import { PatientProfile } from "../../models/patient-profile.model";
import { ObEncounter } from "../../models/ob-encounter.model";
import { Pagination } from "../../models/pagination.model";
import { IEncounterResource } from "../../models/encounter-resource.model";

import { PE } from "@admin/physiotherapy-encounter/physiotherapy-encounter.namespace"


@Component({
    selector: "physiotheray-fullreport",
    templateUrl: "./physiotheray-full-report.html",
  
    encapsulation: ViewEncapsulation.None 
})
export class PhyiothearpyFullReportPage implements OnInit, OnDestroy {

    patient: PatientProfile;
    records: PatientMedicationHeader[];

    @Input() printId: string;
    @Input() printadmission: boolean;
    @Input() encryptedPatientId: string;
    @Input() encryptedAppointmentId: string;

    physiotherapy: PhysiotherapyEncounterList;
    loading: boolean;
    page: Page;
    roleId: number;
    isAdmission = false;
    appointmentId: string;
    routingValue: string;
    section: string;
    appointment: ObEncounter;
    oldAppointment: PreviousAppointment;
    data:any

    documents: Array<PatientDocument>;
    patientId: number;
    patientDocumentId: number;
    bookingHeader: Array<any>;
    pagination: Pagination;
    reminders: string;
    referralForm: Array<OB.ReferralForm>;
    physioTherapyTemplate: any
    phusiotherapyEncounter: IEncounterResource;
    specialFeature: string;
    outSideTests: OB.OutsideTest;
    refferalOrder: Array<OB.OrderReferral>;
    @Input() useExistingCss: true;
    iconBasics: ImageReports;
    @Input() isPrintLogo: boolean;
    isFooter: boolean;
    telephoneTriageAssessmentdata: Array<OB.TelephoneTriageAssessment>;
    constructor(
        private readonly router: Router,
        private readonly httpService: HttpService,
        private readonly encounterCommunication: EncounterCommunication,
        private readonly route: ActivatedRoute,
        private readonly printOptionService: PrintOptionService,
        private readonly iconService: IconService,
        private readonly appData: AppData,
    ) {
        this.page = new Page();
        this.outSideTests = new OB.OutsideTest();
        this.telephoneTriageAssessmentdata = new Array<OB.TelephoneTriageAssessment>();
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((account: IUserAccount) => {
                if (account) {
                    const url = this.router.url;
                    this.routingValue = url.split("/")[url.split("/").length - 4];
                    this.appointmentId = decodeURIComponent(url.split("/")[url.split("/").length - 3]);
                    this.isAdmission = url.split("/")[url.split("/").length - 2] === "a";

                    this.appointmentId = this.printId
                    this.isAdmission = this.printadmission
                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((params: Params) => {
                            if (this.appointmentId) {
                                this.findPatient();
                                this.findDashboard();
                                this.fetchMedication();
                            } else {
                                this.router.navigateByUrl(appUrls.notFound);
                            }
                            const bypass = params["bypass"];
                            if (!bypass || bypass !== "t") {
                                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                                this.iconService.getIconImage((is) => { this.iconBasics = is; });
                            }
                        });
                }
            });
    }


    private findDashboard() {
        const request = { encryptedAppointmentId: this.appointmentId };
        this.httpService.post<PhysiotherapyEncounterList>(ApiResources.getURI(ApiResources.physiotherapyEncounter.base, ApiResources.physiotherapyEncounter.findFullTranscript), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: PhysiotherapyEncounterList) => {
                this.physiotherapy = response;
                this.encounterCommunication.physioTherapyEncounter({
                    encryptedAppointmentId: this.appointmentId,
                    isSubmitandNext: true,
                    isAdmission: this.isAdmission
                } as EncounterValue);
                if (this.physiotherapy) {
                    this.specialFeature = this.physiotherapy.specialFeature ? JSON.parse(this.physiotherapy.specialFeature) : null;
                    this.outSideTests = this.physiotherapy.outsideTest ? JSON.parse(this.physiotherapy.outsideTest) : null;
                    this.reminders = this.physiotherapy.reminder ? this.physiotherapy.reminder : null;
                    this.physioTherapyTemplate = this.physiotherapy.physioTherapyTemplate ? JSON.parse(this.physiotherapy.physioTherapyTemplate) : null;
                    this.refferalOrder = this.physiotherapy.refferalOrder ? JSON.parse(this.physiotherapy.refferalOrder) : null;
                    this.physioTherapyTemplate = this.physiotherapy.physioTherapyTemplate ? JSON.parse(this.physiotherapy.physioTherapyTemplate) : null;
                    this.physioTherapyTemplate.physionTherapyTemplate = null;
                    this.physioTherapyTemplate.templates = this.physioTherapyTemplate.templates.filter((item: PE.PhysioTherapyTemplate) => {
                        if (item.addDate == this.physiotherapy.appointmentDate.toString()) {
                            if (item.encounterTemplateId > 0) {
                                this.physioTherapyTemplate.physionTherapyTemplate = this.physioTherapyTemplate.physionTherapyTemplate ? this.physioTherapyTemplate.physionTherapyTemplate + ' , ' + item.templateName : item.templateName;
                            }
                            return item;
                        }
                    })
                    this.physioTherapyTemplate.templates = this.physioTherapyTemplate.templates.sort(function (a: PE.PhysioTherapyTemplate, b: PE.PhysioTherapyTemplate) {
                        return Date.parse(b.addDate) - Date.parse(a.addDate);
                    });
                }
                this.fetchLabs();

            });

    }
    private findreferralForm(id: any, appointmentId: any) {
        this.loading = true;

        const request = Object.assign({ isAdmission: this.isAdmission, patientId: id, appointmentId: appointmentId });

        this.httpService.post(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchCrossConsultation), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<OB.ReferralForm>) => {
                if (response.length > 0) {
                    this.referralForm = response;
                }
            });
    }
    ngOnDestroy() {
        this.page.unsubscribeAll();
    }
    onViewPrescription(isTele: boolean) {
        if (isTele) {
            this.router.navigate([`app/physiotherapy-encounter`, this.appointmentId, 'b', 'reports', 'T']);
        }
        else {
            this.router.navigate([`app/physiotherapy-encounter`, this.appointmentId, 'b', 'reports', 'N']);
        }
    }
    private findPatient() {
        this.page.loading = true;
        const request = { encryptedAppointmentId: this.appointmentId };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<ObEncounter>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: ObEncounter) => {
                this.appointment = response;
                this.encryptedPatientId = response.encryptedPatientId;
                this.findPatientDetails();
                this.findreferralForm(this.appointment.patientId, this.appointment.appointmentId)
                this.fetchalltelephonetriage(this.appointment.appointmentId, this.appointment.patientId)
            });
    }
    private findPatientDetails() {
        this.page.loading = true;
        this.httpService.post<PatientProfile>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.profile), { encryptedPatientId: this.appointment.encryptedPatientId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: PatientProfile) => {
                if (response) {
                    this.patient = response;
                    this.patientId = this.patient.patient.patientId;
                }

            });
    }
    private fetchMedication() {

        let intervalHit = setInterval(() => {
            let setting = localStorage.getItem("settings");
            if (setting) {
                clearInterval(intervalHit);
                this.loading = true;
                const request = {
                    encryptedAppointmentId: this.appointmentId
                }

                this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetch), request)
                    .pipe(takeUntil(this.page.unSubscribe))
                    .pipe(finalize(() => {
                        this.loading = false;
                    }))
                    .subscribe((response: Array<PatientMedicationHeader>) => {
                        this.records = response;
                    }, () => {
                        this.records = Array<PatientMedicationHeader>();
                    });
            }
        }, 10);
    }
    private fetchLabs() {
        this.loading = true;
        const request = {
            appointmentId: this.physiotherapy.appointmentId,
            isAdmission: this.isAdmission
        }
        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetchLabs), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                if (response && response.length > 0) {
                    this.bookingHeader = response[0].labs;
                }
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });
    }
    onChangePrintType(showLogo: boolean) {
        this.isPrintLogo = showLogo;
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
    fetchalltelephonetriage(appointmentId: any, patientId: any) {
        const request = {
            patientId: patientId,
            appointmentId: appointmentId,
            isAdmission: this.isAdmission
        }

        this.httpService.post(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchtelephonetraige), request)
            .subscribe((result: any) => {
                if (result) {
                    var response = result;
                    response.forEach((x) => {
                        var data = JSON.parse(x.json)
                        this.telephoneTriageAssessmentdata.push(data)
                    })
                }


            })

    }
}