import { IResource } from "./resource.model";

export class CounsellingPackage {
    counsellingPackageId: number;
    counsellingId: number;
    packageModuleId: number;
    packageName: string;
    chargeCategoryId: number;
    chargeCategoryName: string;
    totalQuantity: number;
    subTotal: number;
    packageDiscount: number;
    discountType: string;
    discountPercentage?: number;
    discountAmount?: number;
    discount: number;
    total: number;
    isInUse: boolean;
    active: boolean;
    createdBy: number;
    createdByName: string;
    createdDate: string;
    modifiedBy?: Date;
    modifiedByName: string;
    modifiedDate?: Date;
    isAdded?: boolean;
    strikeItem?: boolean;
    patientAge: number;
    dateOfBirth: Date;
    id: number;
    loadingChargeCategories: boolean;
    chargeCategories: Array<IResource>;
    chargeCategory: IResource;
    patientId: number;
    isCancelled: boolean;
    statusName: string;
    isPublished: boolean;
    locationId: number;
    chargeModuleTemplateId: number;
    expiresIn: number;
    surgeryId: number;

    constructor() {
        this.chargeCategories = [];
        this.id = new Date().getTime();
        this.discountType = "P";
        this.subTotal = 0;
        this.packageDiscount = 0;
        this.total = 0;
    }
}