import { Component, OnInit, ViewEncapsulation, Input, AfterViewInit } from "@angular/core";
import { Page, IUserAccount } from "@shared/models";
import { finalize, takeUntil } from "rxjs/operators";
import { AppData, HttpService, PrintOptionService, IconService, NotifyService, EncounterCommunication } from "@shared/services";
import { Params, Router, ActivatedRoute } from "@angular/router";
import { Appointment, ImageReports, OrderPrescription, PatientMedicationHeader } from "@shared/entities";
import { ApiResources, DateHelper } from "@shared/helpers";

import moment from "moment";
import { Diet } from "../../../areas/admin/full-transcript/pages/diet-report/diet.namespace";
import { OB } from "../../../areas/admin/full-transcript/pages/ob-report/ob.namespace";
import { LabBillHeaderModel } from "../../../areas/admin/labs/pages/models/lab-booking-model";
import { DietEncounter } from "../../models/diet-encounter.model";

class FilterOptions {
    encryptedAppointmentId: string = null;
}

class Filters {
    options: FilterOptions;
    applied: boolean;

    constructor() {
        this.init();
    }

    init() {
        this.options = new FilterOptions();
        this.applied = undefined;
    }
}

class OrderPrescriptionModel {
    appoitmentId: number;
    orderPrescription: string;

}

@Component({
    templateUrl: "./diet-printprescription.html",
    selector: "diet-printprescription",

    encapsulation: ViewEncapsulation.None
})

export class DietPrintReportPage implements OnInit, AfterViewInit {
    @Input() isPrintLogo: boolean;
    @Input() encryptedPatientId: string;
    @Input() conditionObsId: string;
    @Input() printId: string;
    @Input() printadmission: boolean;
    iconBasics: ImageReports;
    page: Page;
    currentLocation: string;
    isAdmission: boolean;
    appointmentId: string;
    isTeleconsulatantReport: boolean;
    locationId: number;
    filters: Filters;
    routingValue: string;
    appointment: Appointment;
    section: string;
    loading: boolean;
    fullTranscript: DietEncounter;
    date: Date;
    dietCondition: string;
    dietItems: Array<any>;
    isAppointmentClosed: boolean;
    records: Array<PatientMedicationHeader>;
    bookingHeader: Array<LabBillHeaderModel>;
    ordersPrescription: any;
    referralOrder: Array<OB.OrderReferral>;
    patientId: number;
    orders: Array<OrderPrescription>;
    signature: string;
    futureAppointments: Array<Appointment>;
    opNutritionalAssessmentForm: Diet.OPNutritionalAssessment;
    visitNumber: number;
    dietPlanPrescribed: any;
    yearsfordisplay: number;
    daysfordisplay: any;
    monthsfordisplay: number;
    isFooter: boolean;
    constructor(
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly httpService: HttpService,
        private readonly route: ActivatedRoute,
        private readonly printOptionService: PrintOptionService,
        private readonly iconService: IconService,
        private readonly notifyService: NotifyService,
        private readonly encounterCommunication: EncounterCommunication,
    ) {
        this.page = new Page();
        this.filters = new Filters();
        this.date = new Date();
        this.dietItems = new Array<any>();
    }


    ngAfterViewInit() {
        this.paddingTop();
    }

    paddingTop() {
        if (document.getElementById("header") != null && document.getElementById("dietPrintContainer") != null) {
            var paddingTopValue = document.getElementById("header").offsetHeight;
            var content = document.getElementById("dietPrintContainer");
            content.setAttribute('style', `padding-top:${paddingTopValue}px`);
        }
    }

    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }

    private findDashboard() {
        const request = { encryptedAppointmentId: this.appointmentId, encryptedPatientId: this.encryptedPatientId };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<DietEncounter>(ApiResources.getURI(ApiResources.dietEncounter.base, ApiResources.dietEncounter.findFullTrascript), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: DietEncounter) => {
                this.fullTranscript = response;
                if (response) {
                    let dietPlanPrescribedata = JSON.parse(response.dietPlanPrescribed);
                    this.dietPlanPrescribed = dietPlanPrescribedata;
                    this.conditionObsId = dietPlanPrescribedata.conditionObsId;
                    this.fullTranscript.conditionObs = dietPlanPrescribedata.conditionObs;
                    this.fullTranscript.bmi = dietPlanPrescribedata.bmi;
                    this.fullTranscript.counsellingNot = dietPlanPrescribedata.counsellingNot;
                    this.fullTranscript.dietPlanPrescribe = dietPlanPrescribedata.dietPlanPrescribe;
                    this.fullTranscript.haemoglobin = dietPlanPrescribedata.haemoglobin;
                    this.fullTranscript.hbA1C = dietPlanPrescribedata.hbA1C;
                    this.fullTranscript.presentWeight = dietPlanPrescribedata.presentWeight;
                    this.fullTranscript.visitNo = dietPlanPrescribedata.visitNo;
                    this.fullTranscript.patientType = dietPlanPrescribedata.patientType;
                    this.fullTranscript.date1 = dietPlanPrescribedata.date1;
                    this.ordersPrescription = this.fullTranscript.orderPrescription ? JSON.parse(this.fullTranscript.orderPrescription) : null;
                    this.referralOrder = this.fullTranscript.refferalOrder ? JSON.parse(this.fullTranscript.refferalOrder) : null;
                    this.opNutritionalAssessmentForm = this.fullTranscript.opNutritionalAssessmentForm ? JSON.parse(this.fullTranscript.opNutritionalAssessmentForm) : null;
                    this.dietItems = dietPlanPrescribedata.dietArray;
                }
                if (this.fullTranscript && this.fullTranscript.dietEncounterId) {

                    if (this.fullTranscript.patientDateOfBirth != null) {
                        const dateOfBirth = DateHelper.toNgbDateStruct(String(this.fullTranscript.patientDateOfBirth))
                        if (dateOfBirth != null) {
                            let mdob = moment(String(this.fullTranscript.patientDateOfBirth), "YYYY-MM-DD");
                            let age = moment().diff(mdob, "months");
                            let yrs = (age / 12).toFixed(1);
                            this.yearsfordisplay = parseInt(yrs, 10);
                            let months = age % 12;
                            var days = moment().diff(mdob.add(yrs, 'years'), 'days', false);
                            this.daysfordisplay = days;
                            this.monthsfordisplay = months;
                            var date1 = moment(String(this.fullTranscript.patientDateOfBirth), "YYYY-MM-DD");
                            var monthsa = moment().diff(date1, 'months');
                            date1.add(monthsa, 'months');
                            var daysa = moment().diff(date1, 'days');
                            monthsa + ' ' + 'Month(s)' + ' ' + daysa + " Day(s)"
                            this.daysfordisplay = daysa;
                        }

                    }

                }
                if (!this.isTeleconsulatantReport) {
                    if (response.orderPrescription != null) {
                        this.notifyService.confirm(" you want to complete the transaction for this visit", () => {
                            this.encounterCommunication.modifyDietEncounterStatus(this.appointment.appointmentId);
                        }, () => { $("#printButton").click(); })
                    }
                    else {
                        this.notifyService.confirm("Order details are not available for this patient visit, still you would like to continue", () => {
                            this.notifyService.confirm("do you want to complete the transaction for this visit", () => {
                                this.encounterCommunication.modifyDietEncounterStatus(this.appointment.appointmentId);
                            }, () => { $("#printButton").click(); })
                        })
                    }
                }
            });
    }

    private fetchvisitno() {
        const request = {
            patientId: this.appointment.patientId
        };
        this.httpService.get<number>(ApiResources.getURI(ApiResources.dietEncounter.base, ApiResources.dietEncounter.findVisitNo), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: number) => {
                if (response) {
                    this.visitNumber = response;
                }

            });
    }

    private findAppointment(appointmentId: string) {
        const request = {
            encryptedAppointmentId: appointmentId
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointment = response;
                this.encryptedPatientId = response.encryptedPatientId;
                this.fetchvisitno();
                this.findDashboard();
                this.fetchMedication();
                this.fetchLabs();
                this.findOrderPrescription();
                this.fetchFutureAppointments();
            });
    }



    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    this.currentLocation = this.page.userAccount.locationName;
                    this.isAdmission = url.split("/")[4] === "a";
                    this.appointmentId = decodeURIComponent(url.split("/")[3]);
                    this.isTeleconsulatantReport = url.split("/")[6] === "T";
                    this.locationId = this.page.userAccount.locationId;
                    this.filters.options.encryptedAppointmentId = decodeURIComponent(this.appointmentId);
                    this.routingValue = url.split("/")[2];
                    this.findAppointment(this.appointmentId);
                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((params: Params) => {
                            const section = params["section"];
                            this.section = section ? section : undefined;
                        });
                } else {
                    this.page.userAccount = undefined;
                }
                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                this.iconService.getIconImage((is) => { this.iconBasics = is; });
            });
    }
    private fetchMedication() {
        this.loading = true;
        const request = {
            encryptedAppointmentId: this.appointmentId
        }
        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                this.records = response;
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });
    }
    private fetchLabs() {
        this.loading = true;
        const request = {
            appointmentId: this.appointment.appointmentId,
            isAdmission: this.isAdmission
        }
        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetchLabs), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                if (response && response.length > 0) {
                    this.bookingHeader = response[0].labs;
                }
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });
    }


    private findOrderPrescription() {
        let PatientId: number = this.patientId;
        this.httpService.get(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchOrderPrescription), { patientId: PatientId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Array<OrderPrescriptionModel>) => {
                if (response.length > 0) {
                    response.forEach((item: OrderPrescriptionModel) => {
                        var jsonParsed = JSON.parse(item.orderPrescription);
                        this.orders.push(jsonParsed)
                    })
                }

            });
    }
    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }
    private fetchFutureAppointments() {
        let request = {
            encryptedPatientId: this.encryptedPatientId,
            // appointmentDate: this.appointment.appointmentDate
        }
        this.httpService
            .post<[Appointment]>(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.findFutureAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: [Appointment]) => {
                    this.futureAppointments = response["appointments"];
                    //this.scanAppointments = response["scanAppointments"];
                },
                () => {
                    this.futureAppointments = [];
                    // this.scanAppointments = [];
                }
            );
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
}    