export enum NurseAssessmentType {
    PainScale = 1,
    NursingCarePlan,
    NurseReviewForm,
    CheckListForShiftingForm,
    MidWifeAuditForm,
    NeonatalInitialAssessmentForm,
    PostnatalAssessmentForm,
    FallsAssessmentForm,
    NurseReAssesmentCarePlan,
    Isbar,
    CheckListForDischarge,
    NeonatalNurseNotes,
    DvtAssesmentTool,
    nurseInitialAssessmentForm,
    Nst,
    PatientMovementChat
}