import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { IdentityService, MenuService } from "@shared/services";

@Injectable()
export class AccessGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly identityService: IdentityService,
        private readonly menuService: MenuService

    ) { }

    canActivate(): Observable<boolean> | boolean {
        const isAuthenticated = this.identityService.isAuthenticated();
        if (isAuthenticated) {
            this.router.navigate([this.menuService.getDefaultRoute]);
            return false;
        } else {
            return true;
        }
    }

    canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate();
    }
}