import { s } from "@fullcalendar/resource/internal-common";
import { TimeModel } from "../models/time.model";

export class Patient {
    patientId: number;
    ageAtMarriage: string;
    relativeIDProofUrl: string;
    signUrl: string;
    idProofUrl: string; 
    aadharNo: string;
    encryptedPatientId: string;
    salutation: string;
    firstName: string;
    middleName: string;
    lastName: string;
    fullName: string;
    fatherOrHusband: string;
    dateOfBirth?: Date;
    age: any;
    gender: string;
    maritalStatus: string;
    umrNo: string;
    email: string;
    mobile: string;
    streetAddress: string;
    addressLine2: string;
    city: string;
    state: string;
    zipcode: string;
    specialCategory: string;
    countryId: number;
    countryName: string;
    countryCode: string;
    isoCode: string;
    profileImageUrl: string;
    thumbnailUrl: string;
    active: boolean;
    isLocked: boolean;
    otpVerified: boolean;
    manualVerified: boolean;
    createdBy: number;
    createdByName: string;
    createdDate: Date;
    modifiedBy: number;
    modifiedByName: string;
    modifiedDate?: Date;
    totalItems: number;
    isCouponApplied: boolean;
    isOnline: boolean;
    isDischarged?: boolean;
    admissionId?: number;
    isActiveAppointmentExists?: boolean;
    isActiveAdmissionExists?: boolean;
    relationWithPatient: string;
    referredBy: string;
    referredByName: string;
    referredByNameId: number;
    breakfastTime: TimeModel;
    lunchTime: TimeModel;
    dinnerTime: TimeModel;
    providerId: number;
    providerName: string;
    locationId: number;
    locationName: string;
    hwcPatientId?: number;
    hwcName: string;
    description: string;
    rowColor: string;
    education: string;
    occupation: string;
    religion: string;
    nationality: string;
    departmentId: number;
    appointmentType: number;
    patientReferredById: number;
    name: string;
    idProofId?: number;
    idProofValue: string;
    idProofName: string;
    timeOfBirth: any;
    billNumber: string;
    bloodGroup: string;
    idProofNo: string;
    appointmentDate?: Date;
    paymentStatus?: boolean;
    paymentNumber: string;
    payTypeId: number;
    amount: number;
    tempPatient: boolean;
    howDidYouKnowId?: number;
    educationId?: number;
    occupationId?: number;
    birthMark1?: string;
    birthMark2?: string;
    educationName: string;
    occupationName: string;
    howDidYouKnow: string;
    errorMessage: string;
    occupationDetail: string;
    relationType: string;
    area: string;
    relativeName: string;
    relation: string;
    insuranceCompanyId: number;
    otherRemarks: string;

    appointmentId: number;
    patientType: string;
    appointmentNo: string;
    appointmentTime: any;
    appointmentNotes: string;
    couponId: number;
    discount: number;
    total: number;
    status: boolean;
    patientFamilyId: number;
    paymentType: string;
    visitTypeId: number;
    chargeTypesId: number;
    appointmentTypeId: number;
    tokenNumber: number;
    specializationId: number;
    specializationName: string;
    providerAvailabilityId: number;
    consultationTypeId: number;
    doctorSpecializationChargeModuleDetailsId: number;
    authorityMasterId: number;
    reasonsId: number;
    remarks: string;
    appointmentPaymentStatus: boolean;
    appointmentTypeValue: string;
    appointmentAmount: number;
    appointmentEndTime: any;

    lastConsultedDate: Date;
    lastConsultedTime: string;
    lastConsultedDoctor: string;
    lastPaidAmount: number;
    nextAppointment: string;
    moduleName?: string;
    insuranceCompany: string;
    relativeEducation: string;
    relativeOccupation: string;
    parentPatientId: number;
    relativeAge: any;
    relativeDOB: any;
    relativeMobile: string;
    createdByRole: string;
    childUMRNo: string;
    parentUMRNo: string;
    sessionId: number;
    slotCount: number;
    patientsOrganization: string;
    educationsId: number;
    occupationsId: number;
    bedNumber: number;
    floorName: string;
    roomName: string;
    roomRent: number;
    caseTypeName: string;
    admissionNo: string;
    referralDoctorId: number;
    attendantRelationWithPatient: string;
    CreatedByNm: string;
    tpaName: string;
    payType: string;
    cost: number;
    bedRequestName: string;
    amountPaid: number;
    paymentResponsibleName: string;
    unitDoctor: string;
    unitName: string;
    patientName: string;
    referrealDoctorName: string;
    relativeBloodGroup: string;
    relativeEmail: string;
    relativeReligion: string;
    relativeNationality: string;
    relativeAlternateNumber: string;
    alternateNumber: string;
    relativeIdProofId?: number;
    location: string;
    ro: string;
    childrens: Array<Patient>;
    
}