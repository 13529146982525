
<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">Capitalized Packages </h4>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="loading">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading  charges configuration...</span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!loading && (!records || !records.length)">
            <div class="col-12 mh-355">
                <div class="no-data">
                    <img src="assets/images/no-data.png" alt="No data" />
                    <p class="sub-title">There is no data to show you right now.</p>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="records && records.length >0">
            <div class="col-12">
                <div class="card mb-0">
                    <div class="card-body p-0">
                        <div class="overflow-auto">
                            <table class="table table-centered table-sm table-striped table-borderless mb-0">
                                <thead class="title">
                                    <tr>
                                        <th>Patient Name</th>
                                        <th>Admission No</th>
                                        <th>UMRNo</th>
                                        <th>Package Name</th>                                     
                                        <th>Package Brief</th>
                                        <th>Services Used</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of records;">
                                        <td  class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="item.fullName"></td>
                                        <td [textContent]="item.admissionNo"></td>
                                        <td [textContent]="item.umrNo"></td>
                                        <td [textContent]="item.packageName"></td>                                      
                                        <td [textContent]="item.availableService"></td>                                      
                                        <td [textContent]="item.tookServices"></td>                                      
                                        <td>
                                            <div class="d-flex align-items-center justify-content-end">                                               
                                                <div>
                                                    <a href="javascript:;" class="text-primary" (click)="onViewPackage(templateViewPackage, item)"placement="left" ngbTooltip="View">
                                                        <i class="mdi mdi-eye"></i>
                                                    </a>
                                                   
                                                </div>
                                            </div>
                                        </td>                                      
                                    </tr>                                   
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #templateViewPackage>
    <div class="modal-header d-block">
        <div class="d-flex align-items-center justify-content-between">
            <h4 class="modal-title">
                <i class="mdi mdi-medical-bag mr-1"></i>View Package
            </h4>
            <div>
                <button type="button"
                        [useExistingCss]="true"
                        printSectionId="packages-section"
                        ngxPrint
                        class="btn btn-light btn-xs mr-1 d-print-none">
                    <i class="fe-printer mr-1"></i>Print
                </button>
                <button type="button"
                        class="btn btn-light btn-xs"
                        (click)="onCloseViewPackageModal();">
                    <i class="fe-x mr-1"></i> Close
                </button>
            </div>
        </div>
    </div>
    <div class="modal-body p-0" id="packages-section" style="min-height: 400px">
        <view-package-module [packageModuleId]="selectedPackage.packageModuleId"
                             [chargeCategory]="chargeCategory"></view-package-module>
    </div>
</ng-template>

