import { Component, ViewEncapsulation, OnInit, OnDestroy } from "@angular/core";
import { IUserAccount, Page, IResource } from "@shared/models";
import { ResourceService, AppData } from "@shared/services";
import { finalize, takeUntil } from "rxjs/operators";


@Component({
    templateUrl: "./practice-content.html",
    styleUrls: ['./practice-content.widget.css'],
    selector: "practice-content",
    encapsulation: ViewEncapsulation.None
})

export class PracticeContentWidget implements OnInit, OnDestroy {
    page: Page;
    loading: boolean;
    practiceLocations: Array<IResource>
    practices: Array<string>

    constructor(
        private readonly appData: AppData,
        private readonly resourceService: ResourceService,
    ) {
        this.page = new Page();
    }

    private fetchPracticeLocation() {
        this.loading = true;
        this.resourceService.fetchPracticeLocations()
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<IResource>) => {
                this.practiceLocations = response;
                this.practices = this.practiceLocations[0].optionalText2.split(/\n/g);

            });
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchPracticeLocation();
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
}
