/* eslint-disable @typescript-eslint/no-inferrable-types */

import { finalize } from "rxjs";
import { QuestionControlService } from "../../services/formCreator.service";
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { NgSelectComponent } from "@ng-select/ng-select";
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AppData, HttpService, NotifyService } from "../../services";
import { Page } from "../../models";
import { ApiResources } from "../../helpers";

@Component({
    selector: "tagproperties-view",
    templateUrl: "./tagproperties-view.html",
    providers: [QuestionControlService]

})
export class TagPropertiesViewWidget implements OnInit {

    @Input() fromPage: any;
    @Output() omit = new EventEmitter<any>();
    @Output() omitOnDirect = new EventEmitter<any>();
    @ViewChild('ngSelectRef') ngSelectRef: NgSelectComponent;
    submitting: boolean = false;
    subbmit: boolean = false;
    submitted: boolean;
    editControlsForm: FormGroup;
    isIconDisabled: boolean = false;
    isFormulaDisabled: boolean = false;
    sendlookupselctedValue: any;
    sendlookupID: any;
    modalRef1: NgbModalRef;
    requireComments: any;
    styleTag: HTMLStyleElement;
    manualOptions: any;
    toggleSettingValue: any;
    eventType = ["click", 'change'];
    numOptions: number;
    optionlist: { value: string }[] = [];
    selectedDesign: any;
    previousLookup: any;
    lookupData: any[] = [];
    page: Page;
    columnClasses: string[] = ['col', 'col-auto',
        'col-1', 'col-2', 'col-3', 'col-4', 'col-5', 'col-6',
        'col-7', 'col-8', 'col-9', 'col-10', 'col-11', 'col-12',
        'col-md-1', 'col-md-2', 'col-md-3', 'col-md-4', 'col-md-5',
        'col-md-6', 'col-md-7', 'col-md-8', 'col-md-9', 'col-md-10',
        'col-md-11', 'col-md-12'
    ];
    containerCssClasses: string[] = this.columnClasses.slice();
    labelCssClasses: string[] = this.columnClasses.slice();
    inputDivCssClasses: string[] = this.columnClasses.slice();
    controlCssClasses: string[] = this.columnClasses.slice();
    constructor(private qcs: QuestionControlService, private readonly appData: AppData, private readonly notifyService: NotifyService, private readonly httpService: HttpService, private readonly modalService: NgbModal, private formbuilder: FormBuilder, private cdr: ChangeDetectorRef, private elementRef: ElementRef) { this.page = new Page(); }

    ngOnInit() {
        this.fetchLookup();
        this.editControls();
        this.onEditConytolsData(true);

    }

    onOmitCloseModel(value) {
        this.omit.emit(value);
    }
    onDirectOmit() {
        const obj = JSON.parse(this.fromPage)
        if (Array.isArray(obj.htmlData)) {
            obj.htmlData = JSON.stringify(obj.htmlData);
        }
        obj.styleProperties = JSON.stringify(obj.styleProperties)
        this.omitOnDirect.emit(obj);
    }
    private editControls() {
        this.editControlsForm = this.formbuilder.group({
            label: [null, [Validators.required]],
            controlType: [null, [Validators.required]],
            id: [null],
            htmlData: [null],
            selectedOption: [null],
            lookupId: [null],
            formula: [null],
            isEdit: [null],
            normalText: [null],
            abnormalText: [null],
            isApplyAll: [null],
            isFlow: [null],
            styleProperties: this.formbuilder.group({
                labelCss: [null],
                controlCss: [null],
                labelStyles: [null],
                controlStyles: [null],
                requireComments: [false],
                orderAllignment: [null],
                /*  isFlow: [null],*/
                isFlowEtoE: [null],
                containerCss: [null],
                containerStyles: [null],
                inputDivCss: [null],
                inputDivStyles: [null],
                requiredField: [false],
                placeHolder: [null],
                hideLable: [false],
                events: [null],
                eventType: [null],
                isDisable: [false],
                customValidatorNames: [null],
                customErrorMessage: [null],
                preFix: [null],
                suFfix: [null],
                suFfixText: [null],
                minLength: [0],
                maxLength: [null],
                script: this.formbuilder.array([])

            })
        });

        this.setupDropdownValueChanges('controlType');

    }

    addItemsCount() {
        if (this.numOptions) {
            this.optionlist = Array(this.numOptions).fill('').map(() => ({ value: '' }));
        } else {
            this.optionlist = []
        }

    }
    addEmptyValues() {
        this.optionlist.push({ value: '' });
        this.numOptions = this.optionlist.length;
    }
    removeOption(index: number) {
        this.optionlist.splice(index, 1);
        this.numOptions = this.optionlist.length > 0 ? this.optionlist.length : null;


    }

    handleLookupData(lookupData: { response: any, lookupId: number }): void {
        this.sendlookupselctedValue = lookupData.response;
        this.sendlookupID = lookupData.lookupId;
    }
  
    onOptionClose() {

        try {

            this.modalRef1.close();
            this.modalRef1 = undefined;
            this.selectedDesign = '';
            this.numOptions = undefined;
            this.optionlist = [];
            //this.submitClicked = false;

        } catch (e) {
            // ignored
        }
    }

    creatingFeildsDesign() {


        if (this.selectedDesign == "manual") {

            const nonEmptyValues = this.optionlist
                .map(option => option.value.trim()) // Trim the values
                .filter(value => value !== ''); // Filter out empty values

            if (nonEmptyValues.length > 0) {

                const values = nonEmptyValues.join(',');
                this.editControlsForm.get('htmlData').setValue(values);

                this.notifyService.success("Manually given values have been added successfully");
            } else {
                // Notify that no valid values were added
                this.notifyService.warning("No valid values were provided");
                return
            }


        } else if (this.selectedDesign == "lookups") {

            if (!this.sendlookupselctedValue || this.sendlookupselctedValue.length == 0) {
                this.notifyService.warning("Please Select Lookup");
                return
            }

            const lookupvalues = this.sendlookupselctedValue.map(option => option.name).join(',');
            this.editControlsForm.get('htmlData').setValue(lookupvalues);

            this.editControlsForm.get('lookupId').setValue(this.sendlookupID);




        }
        this.onOptionClose();
    }
    setupDropdownValueChanges(formControlName: string) {

        this.editControlsForm.get(formControlName).valueChanges.subscribe((selectedValue) => {
            const selectedOptionControl = this.editControlsForm.get('selectedOption');
            const formulaControl = this.editControlsForm.get('formula')
            const htmlDataControl = this.editControlsForm.get('htmlData');

            if (formControlName === 'controlType') {
                this.editControlsForm.get('lookupId').setValue(null);
                if (selectedValue === 'text' || selectedValue === 'number' || selectedValue === 'textarea' || selectedValue === 'date' || selectedValue === 'total' || selectedValue === 'calculation' || selectedValue === 'heading' || selectedValue === 'ckEditor') {
                    this.isIconDisabled = true;
                    this.manualOptions = false;
                    this.editControlsForm.get('selectedOption').setValue(null);
                    this.editControlsForm.get('selectedOption').disable();
                    this.editControlsForm.get('htmlData').setValue(null);

                    selectedOptionControl?.clearValidators();
                    htmlDataControl?.clearValidators();
                } else {
                    this.isIconDisabled = false;
                    this.manualOptions = true;
                    this.editControlsForm.get('selectedOption').setValue(null);
                    this.editControlsForm.get('htmlData').setValue(null);
                    this.editControlsForm.get('selectedOption').enable();
                    selectedOptionControl?.setValidators([Validators.required]);
                    htmlDataControl?.setValidators([Validators.required]);
                    selectedOptionControl?.updateValueAndValidity();
                    htmlDataControl?.updateValueAndValidity();
                }
                if (selectedValue === 'calculation') {
                    this.isFormulaDisabled = false;
                    formulaControl?.setValidators([Validators.required]);
                } else {
                    this.isFormulaDisabled = true;
                    formulaControl?.clearValidators();
                    formulaControl?.updateValueAndValidity();
                }


            }
            selectedOptionControl?.updateValueAndValidity();
            htmlDataControl?.updateValueAndValidity();
            formulaControl?.updateValueAndValidity();

        });

        this.editControlsForm.get('selectedOption').valueChanges.subscribe((selectedOptionValue) => {
            if (selectedOptionValue) {
                // Clear HtmlData when SelectedOption changes
                this.editControlsForm.get('htmlData').setValue(null);
                this.editControlsForm.get('lookupId').setValue(null);
                this.sendlookupselctedValue = [];
                this.sendlookupID = null;
            }
        });
    }

    createEventScript(eventType: string = null, logicName: string = null, events: string = null): FormGroup {
        return this.formbuilder.group({
            eventType: [eventType, Validators.required],
            logicName: [logicName, Validators.required],
            events: [events, Validators.required]
        });
    }

    get styleProperties(): FormGroup {

        return this.editControlsForm.get('styleProperties') as FormGroup;
    }

    get eventsArray(): FormArray {

        return this.styleProperties.get('script') as FormArray;
    }
    addEventScript(eventType: string = null, logicName: string = null, events: string = null): void {
        this.eventsArray.push(this.createEventScript(eventType, logicName, events));
    }


    removeEventScript(index: number): void {
        this.eventsArray.removeAt(index);

    }
    get editControlsFormValid(): { [key: string]: AbstractControl } {

        return this.editControlsForm.controls;
    }
    isIconEnabled(): boolean {
        const selectedOption = this.editControlsForm.get('selectedOption').value;
        return selectedOption !== null && selectedOption !== '';
    }
    openModel(content: TemplateRef<any>, type: any) {

        // You can continue to use the openModel function
        this.modalRef1 = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "xl",
            windowClass: "custom-modal effect-scale"
        });
        this.selectedDesign = type;
        this.previousLookup = this.editControlsForm.getRawValue();
        if (this.previousLookup && this.previousLookup.htmlData && type === 'manual') {

            if (!Array.isArray(this.previousLookup.htmlData)) {
                this.previousLookup.htmlData = this.previousLookup.htmlData.split(',');
            }

            this.numOptions = this.previousLookup.htmlData.length; // Set the number of options
            this.optionlist = this.previousLookup.htmlData.map((value: any) => ({ value }));
        }
        // this.addItemsCount();
        this.fetchLookup();
    }
    fetchLookup() {

        this.httpService.get(ApiResources.getURI(ApiResources.lookups.base, ApiResources.lookups.fetchlookup), {})
            // .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: any) => {
                this.lookupData = response
            })

    }

    onEditConytolsData(oninit?) {
        this.submitted = true;

        if (oninit) {
            var object = JSON.parse(this.fromPage);
        } else {
            object = this.editControlsForm.value;
            const requiresSelectedOption = ['heading', 'text', 'number', 'textarea', 'date', 'total', 'checkbox', 'calculation', 'ckEditor', 'radio', 'dropdown', 'multiselect', 'switch', 'triState'];

            if (requiresSelectedOption.includes(object.controlType) && object.selectedOption == "null") {
                this.editControlsForm.get('selectedOption').setErrors({ required: true });
            }

            //this.onOmitCloseModel(object)
            if (this.editControlsForm.invalid) {
                return
            }

        }

        //const object = this.fromPage;
        if (object) {
            let htmlData = oninit ? object.htmlData : object.htmlData;

            if ((typeof htmlData === 'string' && /^\[.*\]$/.test(htmlData))) {
                htmlData = JSON.parse(htmlData);
            } else if (Array.isArray(htmlData)) {
                object.htmlData = JSON.stringify(htmlData);
            }
            else {
                if (htmlData) {

                    const commaSeparatedValue = object.htmlData as string;

                    if (!(commaSeparatedValue.includes(',')) && Array.isArray(htmlData)) {
                        const sampleArray = [];
                        sampleArray.push(commaSeparatedValue)
                        // Reassign the dataArray to the htmlData key
                        object.htmlData = JSON.stringify(sampleArray);
                    } else {

                        // Split the comma-separated string into an array
                        const dataArray = commaSeparatedValue.split(',');
                        // Reassign the dataArray to the htmlData key
                        object.htmlData = JSON.stringify(dataArray);
                    }
                }

            }
            let styleProperties;
            try {
                styleProperties = JSON.parse(oninit ? object?.styleProperties : object?.styleProperties);
            } catch (error) {
                // If parsing fails, assign the original value to styleProperties
                styleProperties = object?.styleProperties;
            }
            if (styleProperties) {
                //this.containerCssClasses = styleProperties.containerCssList;
                //this.labelCssClasses = styleProperties.labelCssList;
                //this.inputDivCssClasses = styleProperties.inputDivCssList;
                //this.controlCssClasses = styleProperties.controlCssList;

                //containerCssList: [this.containerCssClasses],
                //    labelCssList: [this.labelCssClasses],
                //        controlCssList: [this.controlCssClasses],
                //            inputDivCssList: [this.inputDivCssClasses]
            }

            this.editControlsForm.patchValue({
                label: object.label,
                id: object.id,
                controlType: object.controlType,
                selectedOption: object.selectedOption,
                htmlData: htmlData,
                lookupId: object.lookupId,
                isEdit: object.isEdit,
                normalText: object.normalText,
                abnormalText: object.abnormalText,
                isApplyAll: object.isApplyAll,
                isFlow: object?.isFlow,
                styleProperties: {
                    labelCss: styleProperties?.labelCss,
                    controlCss: styleProperties?.controlCss,
                    labelStyles: styleProperties?.labelStyles,
                    controlStyles: styleProperties?.controlStyles,
                    requireComments: styleProperties?.requireComments,
                    orderAllignment: styleProperties?.orderAllignment,
                    /*    isFlow: object?.isFlow,*/
                    isFlowEtoE: styleProperties?.isFlowEtoE,
                    containerCss: styleProperties?.containerCss,
                    containerStyles: styleProperties?.containerStyles,
                    inputDivCss: styleProperties?.inputDivCss,
                    inputDivStyles: styleProperties?.inputDivStyles,
                    placeHolder: styleProperties?.placeHolder,
                    requiredField: styleProperties?.requiredField,
                    hideLable: styleProperties?.hideLable,
                    events: styleProperties?.events,
                    eventType: styleProperties?.eventType,
                    isDisable: styleProperties?.isDisable,
                    customValidatorNames: styleProperties?.customValidatorNames,
                    customErrorMessage: styleProperties?.customErrorMessage,
                    preFix: styleProperties?.preFix,
                    suFfix: styleProperties?.suFfix,
                    suFfixText: styleProperties?.suFfixText,
                    minLength: styleProperties?.minLength,
                    maxLength: styleProperties?.maxLength

                }
            });
            // Clear existing event scripts
            this.eventsArray.clear();

            // Patch the events array
            if (styleProperties?.script) {
                styleProperties.script.forEach(event => {
                    this.addEventScript(event.eventType, event.logicName, event.events);
                });
            }



            if (!oninit) {
                this.submitting = true;
                object.styleProperties = JSON.stringify(object.styleProperties)
                this.omit.emit(object);
                this.submitted = false;
                this.submitting = false;
            } else {
                this.setupDropdownValueChanges('controlType');
            }
        }

    }
    addCustomClass(event: MouseEvent, newClass: string, ngSelectRef: NgSelectComponent, classesArrayName) {
        event.preventDefault();
        if (!this[classesArrayName].includes(newClass)) {
            this[classesArrayName] = [...this[classesArrayName], newClass];
            this.cdr.detectChanges();
        }
    }








}