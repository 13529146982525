import { DatePipe } from '@angular/common';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { AppComponent } from "@app/app.component";
import { AppRoutingModule, routeGuards, routePages } from "@app/app.routing.module";
import { AvatarImageComponent, SymptomsViewComponent } from "@shared/components";
import { AppConfig, AppData } from "@shared/services";
import { SharedModule } from "@shared/shared.module";
import { environment } from '@src/environments';
import { QRCodeModule } from 'angularx-qrcode';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TagInputModule } from 'ngx-chips';
import { CountdownModule } from 'ngx-countdown';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { LayoutComponent } from './areas/layout/layout.component';

const components = [AvatarImageComponent, SymptomsViewComponent];

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        SharedModule.forRoot(),
        AppRoutingModule,
        QRCodeModule,
        CountdownModule,
        NgxExtendedPdfViewerModule,
        PdfViewerModule,
        TagInputModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideDatabase(() => getDatabase()),
    ],
    declarations: [
        AppComponent,
        LayoutComponent,
        components,
        routePages
    ],
    providers: [
        AppData,
        AppConfig,
        routeGuards,
        DatePipe,
        {
            provide: APP_INITIALIZER,
            useFactory: (config: AppConfig) => () => config.load(),
            deps: [AppConfig],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}