
import { Component, OnDestroy, OnInit, Input } from "@angular/core";
import { UtilHelper, ApiResources } from "@shared/helpers";
import { Setting } from "../../entities";
import { HttpService, PrintOptionService } from "../../services";
import { Page, IUserAccount } from "@shared/models";
import { takeUntil } from "rxjs/operators";
import { AppData } from "@shared/services";

@Component({
    template: `<div *ngIf ="isPrintLogo" class="text-center ">
<img  [src]="url !== null ? url : myImgUrl" alt="Careaxes" class=" max-width100" />
</div>`,
    selector: "banner-setting"
})


export class BannerSettingWidget implements OnInit, OnDestroy {

    @Input() reportName: any;
    page: Page;
    myImgUrl: string;
    url: string;
    isPrintLogo: boolean;
    locationId: number;
    constructor(
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
        private readonly appData: AppData,
    ) {
        this.page = new Page();
    }
    getBannerImage() {
        const request = {
            active: true,
            locationId: this.locationId
        };
        if (this.reportName != null || this.reportName != undefined) {
            request["name"] = this.reportName;
        }

        this.httpService
            .get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetchNewBanner), request, true)
            .subscribe(
                (response: Array<Setting>) => {
                    if (response && response.length > 0) {
                        if (UtilHelper.isEmpty(response[0].imageUrl)) {
                            response[0].imageUrl = `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${response[0].imageUrl}`;
                            this.url = response[0].imageUrl;
                        }
                    }
                },
                () => {
                    this.url = null;
                }
            );
    }


    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.locationId = this.page.userAccount.locationId;
                    this.printOptionService.get((is) => { this.isPrintLogo = is; });
                    this.getBannerImage();
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }
    ngOnDestroy() {
        this.page.unsubscribeAll();
    }
}