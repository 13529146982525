import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { RequestInterceptor } from "@shared/interceptors";
import { AppointmentRefreshService, AppointmentToggleService, BannerService, BillNotificationService, CommunicationService, DashboardService, FinalBillService, HttpErrorService, HttpService, IconService, IdentityService, JitsiService, MenuService, NotifyService, PrintOptionService, PrintService, QueueService, RedirectAppointmentService, ResourceService, SettingService, TimelineToggleService, ValidatorService, VideoLinkService, ExportService, EncounterCommunication, RealTimeNodeService, EmergencyContact, WhatsAppService, SmsService, TimelineWidgetService } from "@shared/services";
import { SharedCommonModule } from "@shared/shared.common.module";
import { SharedLibsModule } from "@shared/shared.libs.module";
import { SharedService } from "./services/shared.service";
import { NavigationService } from "./services/navigation-service";
import { CimsService } from "./services/cims.service";
import { NurseNotesPage, ProgressReportLabsPage,  ProgressReportMedicationPage, ProgressReportNotesPage, ProgressReportVitalsPage } from "../areas/admin/progress-report";
import {
    NewLabTimelineWidget, GatePassReportWidget, SignatureWidget, InsuranceDocumentsWidget, OTPWidget, DietHeaderWidget, PharmacyProductWidget, BarcodeScannerWidget, VisitingPrescriptionWidget, PracticeContentWidget, ScanReportNewReportWidget, SalebillReportWidget, DirectPOReportWidget, GoodsInvoiceWidget,
    PatientDocumentsWidget, AsideAppointmentsWidget, CommonData, FileDropperWidget, TimelineWidget, AppointmentInvoiceWidget, PharmacyIndentWidget, NewLabParameterWidget,
    BannerSettingWidget, FooterSettingWidget, PharmacyHeaderWidget, ChatBoxWidget, ColorIdentification, ImgCropWidget, ImgCaptureWidget, ObHeaderComponent, GynHeaderWidget,
    PediatricsHeaderComponent, NewLabReportWidget, ReceiptViewWidget, ParameterInputWidget, NewLabInvoiceWidget, GeneticHeaderComponent, PhysiotherapyHeaderComponent, NewLabSchemaReportWidget,
    EmergencyHeaderWidget, LabStatusIdentification, NurseHeaderComponent, TestChatBoxWidget, TelemedicineWidget, MessagingWidget, NeonatalHeaderWidget, ReportFooterLocationWidget,
    RegistrationInvoiceWidget, TransferedLabsWidget, NewLabModifyWidget, NewLabCancelledInvoiceWidget, GraphViewPage, InterimReportWidget, NurseSelectBedComponent, 
    NewBannerSettingWidget, IVFHeaderComponent, OphthalmologyEncounterComponent, PharmacyInvoiceWidget, SacnInvoiceWidget, NewLabSchemaParameterWidget, NewLabSchemaInterimReportWidget, InternalMeditionHeaderComponent, ServiceInvoiceWidget, LabHeader, AdmissionTimelineWidget, NewSacnInvoiceWidget, TendersTimelineWidget, InventoryProductWidget, ServiceTimelineWidget, DynamicHeaderComponent, TagPropertiesViewWidget, DynamicOutsideEncounter
    , OBPrescriptionReportPage, GYNPrescriptionPage, DietPrintReportPage, GeneticPrintReportPage, PhysioReportsPage, PediatricReportPage, GYNDischargeReportPage,
    ObFullReportPage, DietFullReport, GynFullReportPrintPage, GeneticfullPrintReportPage, PediatricfullReportPage, PhyiothearpyFullReportPage, ObAntinatalSummaryPage,
    OBAdmissionDetailReportPrintPage, OBDischargeSummaryReportsPage, NeonatalDishargeReportPage, ServicesInvoicePage, POOrderInvoicePage, PostnantalVisitReportPage
    , EncounterAllReportPage, MigratedLabReportWidget
} from "@shared/widgets"; 
//ImageDrawWidget
import { ProgressReportTimelinePage } from "../areas/admin/progress-report/pages/timeline/timeline.page";
import { InPatientsViewPage } from "../areas/admin/nurse-module";
import { ModifyCounsellingComponent, ViewCounsellingComponent } from "../areas/admin/counselling";
const widgets = [InsuranceDocumentsWidget, GatePassReportWidget, SignatureWidget, OTPWidget, PharmacyProductWidget, TimelineWidget, BarcodeScannerWidget, PatientDocumentsWidget, ScanReportNewReportWidget, SalebillReportWidget, GoodsInvoiceWidget,
    AsideAppointmentsWidget, CommonData, FileDropperWidget, AppointmentInvoiceWidget, PharmacyIndentWidget, NewLabParameterWidget, DietHeaderWidget, BannerSettingWidget, DirectPOReportWidget,
    FooterSettingWidget, PharmacyHeaderWidget, ChatBoxWidget, ColorIdentification, ImgCropWidget, ImgCaptureWidget, ObHeaderComponent, GynHeaderWidget, EmergencyHeaderWidget,
    PediatricsHeaderComponent, NewLabReportWidget, ReceiptViewWidget, ParameterInputWidget, NewLabInvoiceWidget, NewLabTimelineWidget, GeneticHeaderComponent, NewLabSchemaReportWidget,
    PhysiotherapyHeaderComponent, EmergencyHeaderWidget, LabStatusIdentification, NurseHeaderComponent, TestChatBoxWidget, TelemedicineWidget, MessagingWidget,
    NeonatalHeaderWidget, InvestigationWidget, ReportFooterLocationWidget, RegistrationInvoiceWidget, TransferedLabsWidget, NewLabModifyWidget, NewLabCancelledInvoiceWidget,
    GraphViewPage, InterimReportWidget, NurseSelectBedComponent, NewBannerSettingWidget, IVFHeaderComponent, OphthalmologyEncounterComponent, PharmacyInvoiceWidget, SacnInvoiceWidget, NewLabSchemaParameterWidget, NewLabSchemaInterimReportWidget, InternalMeditionHeaderComponent, ServiceInvoiceWidget, LabHeader, AdmissionTimelineWidget, NewSacnInvoiceWidget, TendersTimelineWidget, InventoryProductWidget, ServiceTimelineWidget, DynamicHeaderComponent, TagPropertiesViewWidget, DynamicOutsideEncounter,
    OBPrescriptionReportPage, GYNPrescriptionPage, DietPrintReportPage, GeneticPrintReportPage, GYNDischargeReportPage, PhysioReportsPage, DietFullReport, PediatricReportPage, ObFullReportPage,
    GynFullReportPrintPage, GeneticfullPrintReportPage, PediatricfullReportPage, PhyiothearpyFullReportPage, ObAntinatalSummaryPage, OBAdmissionDetailReportPrintPage, OBDischargeSummaryReportsPage,
    NeonatalDishargeReportPage, VisitingPrescriptionWidget, PostnantalVisitReportPage, EncounterAllReportPage,
    PracticeContentWidget, ServicesInvoicePage, POOrderInvoicePage, MigratedLabReportWidget];
//ImageDrawWidget
import { ViewPackageModuleComponent } from "../areas/admin/masters/pages/package-modules";
import { BillNotificationCommentModalComponent, LookupGlobalPage } from "./components";
import { ProgressInfusionPage } from "../areas/admin/progress-report/pages/infusion/infusion.page";
import { InvestigationWidget } from "./widgets/investigations/investigation-exclusions.page";
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@NgModule({
    declarations: [widgets, BillNotificationCommentModalComponent, ViewPackageModuleComponent, ProgressReportTimelinePage, InPatientsViewPage, ModifyCounsellingComponent, ViewCounsellingComponent, ProgressReportLabsPage, ProgressReportMedicationPage, ProgressReportVitalsPage, NurseNotesPage, LookupGlobalPage, ProgressReportNotesPage],
    imports: [SharedCommonModule, SharedLibsModule, NgxExtendedPdfViewerModule],
    exports: [SharedCommonModule, SharedLibsModule, widgets,  ViewPackageModuleComponent, ProgressReportTimelinePage, InPatientsViewPage, ModifyCounsellingComponent, ViewCounsellingComponent]
})

export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                RealTimeNodeService, TimelineToggleService, CommunicationService, VideoLinkService, AppointmentToggleService, IdentityService, NotifyService, HttpService, ResourceService, JitsiService, QueueService, ValidatorService, FinalBillService, MenuService, PrintService, HttpErrorService, DashboardService, SettingService, AppointmentRefreshService, RedirectAppointmentService, PrintOptionService, BannerService, IconService, SharedService, BillNotificationService, ExportService, EncounterCommunication, EmergencyContact, WhatsAppService, SmsService, TimelineWidgetService, NavigationService, CimsService,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: RequestInterceptor,
                    multi: true
                }
            ]
        };
    }
}
