import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { ApiResources, UtilHelper } from "@shared/helpers";
import { AppData, HttpService, NotifyService, ResourceService } from "@shared/services";
import { Page, IUserAccount, Pagination, IResource, } from "@shared/models";
import { finalize, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { Package } from "../../../../../shared/entities/admission/package.entity";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";


@Component({
    templateUrl: "./used-package-compare.html",
})
export class UsedPackageComparePage implements OnInit, OnDestroy {
    page: Page;
    loading: boolean;
    
    records: Array<Package>;
    pagination: Pagination;
    selectedPackage: Package;
    modalViewRef: NgbModalRef;
    chargeCategory: IResource;
    loadingChargeCategory: boolean;

    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly router: Router,
        private readonly notifyService: NotifyService,
        private readonly modalService: NgbModal,
        private readonly resourceService: ResourceService,
    ) {
        this.loading = true;
        this.page = new Page();       
        this.records = new Array<Package>;
       this.initPagination()
    }

    fetchPackages() {
        this.loading = true;
        const request = UtilHelper.clone(this.pagination)

        this.httpService
            .get(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchUsedPackages), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<Package>) => {
                    if (response.length > 0) {
                        this.records = response;

                    }
                    else {
                        this.records = [];
                    }
                   // UtilHelper.applyPagination(this.records, this.pagination);
                },
            );
    }
    onViewPackage(content: TemplateRef<any>, model: Package): void {
        debugger;
        this.selectedPackage = model;
        this.modalViewRef = this.modalService.open(content, { size: "lg", windowClass: "custom-modal" });
    }
    private fetchDefaultChargeCategory() {
        this.loadingChargeCategory = true;
        this.resourceService.defaultChargeCategory()
            .pipe(finalize(() => { this.loadingChargeCategory = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: any) => {
                this.chargeCategory = response;              
            });
    }
    onCloseViewPackageModal(): void {
        try {
            this.modalViewRef.close();
            this.modalViewRef = undefined;
        } catch (e) {
            //ignore
        }

        this.selectedPackage = undefined;
    }


    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchPackages();
                    this.fetchDefaultChargeCategory();
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }
  
    private initPagination() {
        this.pagination = new Pagination();
        this.pagination.pageSize = 10;
        this.pagination.pageIndex = 1;
    }
   
}