import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Admission, InsuranceReceipt } from "@shared/entities";
import { ApiResources, UtilHelper } from "@shared/helpers";
import { GenericResponse, GenericStatus, IUserAccount, Page, Pagination } from "@shared/models";
import { AppData, HttpService, NotifyService, ResourceService } from "@shared/services";
import { finalize, takeUntil } from "rxjs/operators";
import { ModalType } from "../../../../../shared/enums";
import { DomSanitizer } from '@angular/platform-browser';
import { min } from "moment";
import { DiscountType } from "../../../services/models/discount-type.model";
import { FinalBillBasicModel } from "../../../services/models/final-bill-basic.model";

@Component({
    templateUrl: "./insurance-management.html",
    styleUrls: ['./insurance-management.css']
})
export class InsuranceManagementPage implements OnInit, OnDestroy {

    page: Page;

    pagination: Pagination;

    discountTypeEnum = DiscountType;
    loading: boolean;
    admissions: Array<Admission>;
    modalRef: NgbModalRef;
    modelRef: NgbModalRef;
    modalViewRef: NgbModalRef;
    submitting: boolean;
    submitted: boolean;

    selectedAdmission: Admission;
    date: Date;
    admissionId: number;
    insuranceManagementId: number;
    addReceiptForm: FormGroup;
    copayForm: FormGroup;
    addForm: FormGroup;
    postDiscount: number;
    files: Array<File>;
    maxFiles = 1;
    loadingDocument: boolean;
    document: InsuranceReceipt;
    showPrevious: boolean;
    showNext: boolean;
    documentError: boolean;
    modalType = ModalType;
    ext: string;

    finalBillBasics: FinalBillBasicModel;
    finalBillLoading: boolean;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly httpService: HttpService,
        private readonly resourceService: ResourceService,
        private readonly modalService: NgbModal,
        private readonly formBuilder: FormBuilder,
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly notifyService: NotifyService,
        private readonly sanitizer: DomSanitizer
    ) {
        this.loading = true;

        this.page = new Page();
        this.initPagination();
        this.admissions = new Array<Admission>();
        this.date = new Date();
        this.buildCopayForm();
    }

    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`;
        }
    }


    private initPagination() {
        this.pagination = new Pagination();
        this.pagination.pageSize = 10;
        this.pagination.pageIndex = 1;
    }

    private fetch() {
        const request = Object.assign(UtilHelper.clone(this.pagination));
        request.locationId = this.page.userAccount.locationId;
        if (this.page.userAccount.roleId === 3) {
            request.providerId = this.page.userAccount.referenceId;
        }
        request.payType = 'I';
        request.active = true;
        request.isDischarged = false;

        this.httpService
            .post<Array<Admission>>(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<Admission>) => {
                    this.admissions = response;
                    if (this.selectedAdmission && this.selectedAdmission.admissionId) {
                        let selectedAdmission = this.admissions.filter(x => x.admissionId == this.selectedAdmission.admissionId)[0];
                        if (this.selectedAdmission.insuranceReceipts.length != selectedAdmission.insuranceReceipts.length) {
                            let receipt = selectedAdmission.insuranceReceipts[selectedAdmission.insuranceReceipts.length - 1];
                            this.selectedAdmission.insuranceReceipts.push(receipt);
                        }
                    }
                    this.admissions.forEach((item) => {
                        if (UtilHelper.isEmpty(item.patientThumbnailUrl)) {
                            item.patientThumbnailUrl = `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${item.patientThumbnailUrl}`;
                        }
                        if (UtilHelper.isEmpty(item.providerThumbnailUrl)) {
                            item.providerThumbnailUrl = `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${item.providerThumbnailUrl}`;
                        }
                    });
                    UtilHelper.applyPagination(this.admissions, this.pagination);
                },
                () => {
                    this.admissions = [];
                }
            );
    }

    onNextPage() {
        $("body,html").animate({ scrollTop: 0 });
        this.fetch();
    }

    onViewPatientProfile(encryptedPatientId: string) {
        this.router.navigateByUrl(`app/patient/${encryptedPatientId}`);
    }

    onOpenModal(template: TemplateRef<any>, admission: Admission) {
        this.selectedAdmission = admission;
        const postDiscount = (this.selectedAdmission.finalAmount || 0) - (this.selectedAdmission.paidAmount || 0);
        this.postDiscount = postDiscount > 0 ? postDiscount : 0;
        this.admissionId = this.selectedAdmission.admissionId;
        this.modalRef = this.modalService.open(template, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "xl",
            windowClass: "custom-modal effect-scale"
        });
        this.buildForm();
    }

    private buildForm() {
        this.addForm = this.formBuilder.group({
            postDiscount: [this.postDiscount]
        });
    }

    private buildCopayForm() {
        this.copayForm = this.formBuilder.group({
            copayDiscountType: [null, [Validators.required]],
            copayDiscountAmount: [null, [Validators.required, Validators.min(0)]],
            copayDiscountPercentage: [null, [Validators.min(0), Validators.max(100)]]
        });

        this.copayForm.get("copayDiscountType").valueChanges.subscribe((value: DiscountType | null) => {
            this.copayForm.patchValue({
                copayDiscountAmount: null
            });
            if(!value) {
                this.copayForm.get("copayDiscountAmount").disable();
            } else {
                this.copayForm.get("copayDiscountAmount").enable();
            }
        });
        this.copayForm.get("copayDiscountAmount").valueChanges.subscribe((value: number | null) => {
            const type = this.copayForm.get("copayDiscountType").value;
            if(type === DiscountType.Percentage) {
                this.copayForm.patchValue({
                    copayDiscountPercentage: value
                });
            }
        });
    }

    onUpdatePostDicount() {
        this.submitted = true;
        this.submitting = true;

        const model = UtilHelper.clone(this.addForm.value);
        model["modifiedBy"] = this.page.userAccount.accountId;
        model["insuranceManagementId"] = this.insuranceManagementId;
        model["admissionid"] = this.admissionId;

        this.httpService
            .postFile<GenericResponse>(ApiResources.getURI(ApiResources.insuranceManagement.base, ApiResources.insuranceManagement.updatePostDiscount), model)
            .pipe(finalize(() => { this.submitted = false; this.submitting = false; }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: GenericResponse) => {
                if (response.data == -2) {
                    this.notifyService.warningToast("Finall bill is not available, please generate finall bill");
                }
                else {
                    this.notifyService.successToast("Discount has been updated successfully.");
                    this.onCloseModal();
                    this.fetch();
                }
            }, (error: HttpErrorResponse) => {
                const errorMessage = UtilHelper.handleError(error);
                if (errorMessage) {
                    this.notifyService.warning(errorMessage);
                } else {
                    this.notifyService.defaultError();
                }
            });
    }

    onCloseModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;

        } catch (e) {
            // ignored;
        }

        this.selectedAdmission = undefined;
    }
    onCloseViewModal() {
        try {
            this.modalViewRef.close();
            this.modalViewRef = undefined;

        } catch (e) {
            // ignored;
        }
    }


    onOpenReceiptModal(template: TemplateRef<any>, insuranceManagementId: number, admissionId: number) {
        this.insuranceManagementId = insuranceManagementId;
        this.admissionId = admissionId;
        this.modelRef = this.modalService.open(template, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "lg",
            windowClass: "custom-modal effect-scale"
        });
        setTimeout(() => {
            this.getFinalBillBasics();
        })
        this.buildAddReceiptForm();
    }

    private buildAddReceiptForm() {
        this.addReceiptForm = this.formBuilder.group({
            status: [null, [Validators.required]],
            amount: [null, [Validators.required]],
            tpaTxnRef: [null, [Validators.required]],
            tpaDocRef: [null]
        });
    }

    onFileSelect(files: Array<File>) {
        this.files = files;
    }

    onAddReceipt() {
        this.submitted = true;
        if (!this.addReceiptForm.valid) {
            return;
        }

        this.submitting = true;

        const model = UtilHelper.clone(this.addReceiptForm.value);
        model["createdBy"] = this.page.userAccount.accountId;
        model["insuranceManagementId"] = this.insuranceManagementId;
        model["admissionid"] = this.admissionId;

        const formData = UtilHelper.prepareFormData(UtilHelper.clone(model));
        const files = this.files;
        if (files) {
            const n = files.length;
            if (n > this.maxFiles) {
                files.splice(this.maxFiles, files.length);
            }
           
            files.forEach((file: File, index: number) => {
                formData.append(`File${index + 1}`, file, file.name);
                this.ext=file.name.split('.').pop();
            });
        }

        this.httpService
            .postFile(ApiResources.getURI(ApiResources.insuranceManagement.base, ApiResources.insuranceManagement.addReceipt), formData)
            .pipe(finalize(() => { this.submitted = false; this.submitting = false; }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(() => {
                this.onCloseReceiptModal();
                this.notifyService.successToast("Receipt Added successfully.");
                this.fetch();
            }, (error: HttpErrorResponse) => {
                this.addReceiptForm.reset();
                const errorMessage = UtilHelper.handleError(error);
                if (errorMessage) {
                    this.notifyService.warning(errorMessage);
                } else {
                    this.notifyService.defaultError();
                }
            });
    }

    get form() {
        return this.addReceiptForm.controls;
    }

    onCloseReceiptModal() {
        try {
            this.modelRef.close();
            this.modelRef = undefined;

        } catch (e) {
            // ignored;
        }

        this.submitting = undefined;
        this.submitted = undefined;
        this.insuranceManagementId = undefined;
        this.admissionId = undefined;
        this.files = [];
    }


    onOpenModalForDownload(content: TemplateRef<any>, type: ModalType, document?: InsuranceReceipt) {
        if (type === ModalType.View) {
            this.prepareDocument(document);          
            this.modalViewRef = this.modalService.open(content, {
                keyboard: false,
                centered: true,
                windowClass: "document-view-modal custom-modal effect-scale"
            });
        } 
    }

    private prepareDocument(document: InsuranceReceipt) {
        this.loadingDocument = true;
        this.document = document;
        this.documentError = false;
        this.document.isImage = this.document.contentType.indexOf("pdf") < 0 && this.document.contentType.indexOf("video") < 0;
        this.document.isVideo = this.document.contentType.indexOf("video") >= 0;

        if (this.document.isImage || this.document.isVideo) {
            this.document.formedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.downloadFileGet)}?id=${this.document.insuranceReceiptId}&url=${this.document.documentUrl}&type=${'insuranceDocuments'}`);
        }

        if (!this.document.isImage && !this.document.isVideo) {
            this.httpService
                .post(ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.downloadFile), { ...document })
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => { this.loadingDocument = false; }))
                .subscribe(
                    (response: InsuranceReceipt) => {
                        this.document.formedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64, ${response.base64}`);
                    }
                );

        } else {
            this.loadingDocument = false;
        }
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetch();
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }


    ngOnDestroy() {
        this.onCloseModal();
        this.page.unsubscribeAll();
    }

    onCopaySubmit() {
        if (!this.copayForm.valid) {
            return;
        }

        this.submitting = true;
        const model = UtilHelper.clone(this.copayForm.value);
        model["copayDiscountAmount"] = +model["copayDiscountAmount"];
        if(model["copayDiscountType"] === DiscountType.Percentage) {
            model["copayDiscountPercentage"] = +model["copayDiscountAmount"];
            model["copayDiscountAmount"] = null;
        }
        model["copayDiscountType"] = model["copayDiscountType"] === DiscountType.Percentage ? "P" : "A";
        model["id"] = this.admissionId;
        model["createdBy"] = this.page.userAccount.accountId;

        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.finalBill.base, ApiResources.finalBill.applyCopay), model)
            .pipe(finalize(() => { this.submitting = false; }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: GenericResponse) => {
                if(response.status === GenericStatus[GenericStatus.Success]) {
                    this.notifyService.successToast("Copay added successfully.");
                    this.onCloseModal();
                    this.fetch();
                } else {
                    this.notifyService.warningToast("Final bill is not available, please generate final bill.");
                }
            }, (error: HttpErrorResponse) => {
                const errorMessage = UtilHelper.handleError(error);
                if (errorMessage) {
                    this.notifyService.warning(errorMessage);
                } else {
                    this.notifyService.defaultError();
                }
            });
    }

    getFinalBillBasics = () => {
        this.finalBillLoading = true;
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.finalBill.base, ApiResources.finalBill.getBasics), { id: this.admissionId.toString(), isAdmission: true })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.finalBillLoading = false))
            .subscribe(
                (response: GenericResponse) => {
                    this.finalBillBasics = response.data as FinalBillBasicModel;
                    if(this.finalBillBasics.copayType) {
                        const type = this.finalBillBasics.copayType === "A" ? DiscountType.Number : DiscountType.Percentage;
                        const amount = type === DiscountType.Number 
                        ? +this.finalBillBasics.copayAmount : +this.finalBillBasics.copayPercentage;
                        this.copayForm.patchValue({
                            copayDiscountAmount: amount,
                            copayDiscountType: type
                        }, { emitEvent: false });
                        this.copayForm.get("copayDiscountAmount").enable();
                    }
                },
                (error: HttpErrorResponse) => {
                    const errorMessage = UtilHelper.handleError(error);
                    if (errorMessage) {
                        this.notifyService.warning(errorMessage);
                    } else {
                        this.notifyService.defaultError();
                    }
                }
            );
    }

    onCopayOpenModal(template: TemplateRef<any>, admission: Admission) {
        this.selectedAdmission = admission;
        this.admissionId = admission.admissionId;
        this.copayForm.reset();
        this.modalRef = this.modalService.open(template, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "xl",
            windowClass: "custom-modal effect-scale"
        });
        setTimeout(() => {
            this.getFinalBillBasics();
        })
    }
}
