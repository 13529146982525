import { Component, OnDestroy, Input, OnInit, ViewEncapsulation, ViewChild, ElementRef } from "@angular/core";
import { takeUntil, finalize } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { Appointment, PatientMedicationHeader, PreviousAppointment, PatientDocument, EncounterType, EncounterValue, AppointmentDashboard, ImageReports, ScanBooking, TemplateDocument } from "@shared/entities";


import { Time } from "@angular/common";
import { PE } from "@admin/physiotherapy-encounter/physiotherapy-encounter.namespace"

import { DomSanitizer } from "@angular/platform-browser";
import { HttpErrorResponse } from "@angular/common/http";
import { PhysiotherapyEncounterList } from "../../entities/physiotherapy-encounter.entity";
import { Page } from "../../models/page.model";
import { IEncounterResource } from "../../models/encounter-resource.model";
import { ApiResources } from "../../helpers/api.helper";
import { IUserAccount } from "../../models/user-account.model";
import { UtilHelper } from "../../helpers/util.helper";
import { AppData, HttpService, IconService, NotifyService, PrintOptionService } from "../../services";
@Component({
    templateUrl: "./physiotherapy-report.html",
    selector: "physio-report",

    encapsulation: ViewEncapsulation.None
})
export class PhysioReportsPage implements OnInit, OnDestroy {
    @Input() encryptedPatientId: string;
    @Input() encryptedAppointmentId: string;
    physiotherapy: PhysiotherapyEncounterList;
    loading: boolean;
    page: Page;
    roleId: number;
    isAdmission: boolean;
    appointmentId: string;
    routingValue: string;
    section: string;
    appointment: Appointment;

    appointments: Array<Appointment>;
    physioTherapyTemplate: PE.PhysioTherapyTemplate
    physiotherapyEncounter: IEncounterResource;
    sendappId: string;
    @ViewChild("templateViewDocument", { static: false })
    iconBasics: ImageReports;
    flag = true;
    @Input() isPrintLogo: boolean;
    date: Date;
    signature: string;
    teplateDocuments: TemplateDocument[];
    errorMessage: any;
    pageheader: string;
    isFooter: boolean;

    @Input() printId: string;
    @Input() printadmission: boolean;
    constructor(
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
        private readonly iconService: IconService,
        private readonly sanitizer: DomSanitizer,
        private readonly notifyService: NotifyService,
    ) {
        this.page = new Page();
        this.appointments = new Array<Appointment>;
        this.date = new Date();
    }
    private findDashboard() {
        const request = { encryptedAppointmentId: this.appointmentId };
        this.httpService.post<PhysiotherapyEncounterList>(ApiResources.getURI(ApiResources.physiotherapyEncounter.base, ApiResources.physiotherapyEncounter.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: PhysiotherapyEncounterList) => {
                this.physiotherapy = response;
                this.physioTherapyTemplate = this.physiotherapy.physioTherapyTemplate ? JSON.parse(this.physiotherapy.physioTherapyTemplate) : null;
                this.physioTherapyTemplate.templates = this.physioTherapyTemplate.templates.filter((item: PE.PhysioTherapyTemplate) => {
                    if (item.addDate == this.physiotherapy.appointmentDate?.toString()) {
                        if (item.templateName) {
                            this.pageheader = this.pageheader ? this.pageheader + ' , ' + item.templateName : item.templateName;
                            return item;
                        }
                    }
                })
                this.physioTherapyTemplate.templates = this.physioTherapyTemplate.templates.sort(function (a: PE.PhysioTherapyTemplate, b: PE.PhysioTherapyTemplate) {
                    return Date.parse(b.addDate) - Date.parse(a.addDate);
                });
            });

    }

    gotoGeneticCounselling() {
        this.router.navigate([`app/genetic-speciality`, this.appointmentId, 'b', 'reports-counselling']);
    }

    findAppointment(id: string) {
        if (id) {
            this.sendappId = id;
        }
        const request = { encryptedAppointmentId: id };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointment = response;
                this.fetchDocuments(this.appointment.appointmentId)
            })
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;

                    const url = this.router.url;
                    this.isAdmission = url.split("/")[4] === "a";
                    this.appointmentId = decodeURIComponent(url.split("/")[3]);

                    this.routingValue = url.split("/")[2];

                    this.findAppointment(this.appointmentId);
                    this.findDashboard();

                } else {
                    this.page.userAccount = undefined;
                }
                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                this.iconService.getIconImage((is) => { this.iconBasics = is; });

            });
    }
    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }

    onChangePrintType(showLogo: boolean) {
        this.isPrintLogo = showLogo;
    }
    fetchDocuments(appointmentId: number) {
        this.errorMessage = undefined;
        this.httpService
            .get(ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.fetchtemplatedocuments), { appointmentId: appointmentId })
            .pipe(finalize(() => { }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                (response: Array<TemplateDocument>) => {
                    this.teplateDocuments = response;
                    if (this.teplateDocuments?.length > 0) {
                        this.teplateDocuments.forEach((template) => {
                            this.prepareDocument(template);
                        })
                    }
                },
                (error: HttpErrorResponse) => {
                    this.errorMessage = UtilHelper.handleError(error);
                    this.notifyService.warning(this.errorMessage);
                }
            );
    }
    private prepareDocument(document: TemplateDocument) {
        document.formedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${document.imageUrl}`);
    }

    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
}