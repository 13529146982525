import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation, Input } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ApiResources } from "@shared/helpers";
import { ElementRef } from "@angular/core";
import { Appointment, PatientDocument, PreviousAppointment, PatientMedicationHeader, ImageReports, Setting } from "@shared/entities";
import { Page, Pagination, IResource, ProgressData, Vitals, IUserAccount } from "@shared/models";
//import { HttpService, AppointmentToggleService, TimelineToggleService } from "@shared/services";
import { finalize, takeUntil } from "rxjs/operators";
import { GynEncounter } from "@shared/models/gyn-encounter.model";
import { AppConfig, HttpService, AppointmentToggleService, TimelineToggleService, IconService, PrintOptionService, appUrls, AppData } from "@shared/services";

import { LabBillHeaderModel } from "@admin/labs/pages/models";
//import { ScrollToService } from "ng2-scroll-to-el";
//import { GYN } from "../../../full-transcript/pages/gyn-report/gyn.namespace";
import { Time } from "@angular/common";
import { GYN } from "../../../areas/admin/gyn-encounter/gyn.namespace";
import { OB } from "../../../areas/admin/full-transcript/pages/ob-report/ob.namespace";
import { InternalMedicine } from "../../../areas/admin/internal-medicine/internal-medicine.namespace";


class FilterOptions {
    encryptedAppointmentId: string = null;
}

class Filters {
    options: FilterOptions;
    applied: boolean;

    constructor() {
        this.init();
    }

    init() {
        this.options = new FilterOptions();
        this.applied = undefined;
    }
}
class OrderPrescriptionModel {
    appoitmentId: number;
    orderPrescription: string;
    appointmentDate: Date;
    appointmentId: string;
    appointmentTime: Time;
    providerName: string;

}
@Component({
    templateUrl: "./gyn-full-report.html",
    selector: "gyn-fullreport"
})
export class GynFullReportPrintPage implements OnInit, OnDestroy {
    @Input() encryptedPatientId: string;
    @Input() encryptedAppointmentId: string;
    @Input() isPrintLogo: boolean;
    loadingVitals: boolean;
    filters: Filters;
    routingValue: string;

    @Input() printId: string;
    @Input() printadmission: boolean;
    roleId: number;
    page: Page;
    modalRef: NgbModalRef;
    modalViewRef: NgbModalRef;
    loadingCategories: boolean;
    categories: Array<IResource>;
    section: string;
    pagination: Pagination;
    loading: boolean;
    documents: Array<PatientDocument>;
    patientDocumentId: number;
    document: PatientDocument;
    appointmentId: string;
    isAdmission = false;
    fullTranscript: GynEncounter;
    vitals: GYN.Vitals;
    iconBasics: ImageReports;
    dischargeSummary: GYN.GyneacDiscargeSummary;
    admissionSheet: GYN.GyneacAdmissionSheet;
    gynSurgeries: GYN.GyneacSurgery;
    colposcopy: GYN.Colposcopic;
    ectopic: GYN.Ectopic;
    reminders: string;
    birthHistory: Array<GYN.BirthControl>;

    orders: Array<OrderPrescriptionModel>;
    private vitalsSection: ElementRef;

    gynCardGeneration: GYN.GynCardGeneration;
    gynHistory: GYN.NewGynHistory;
    IUIData: Array<GYN.GynIUI>;
    allergies: OB.Allergies;
    familyHistory: Array<OB.ObFamilyHistory>
    problemList: Array<InternalMedicine.Problem>;
    procedureFormData: Array<GYN.ProcedureForm>;
    partnerDetails: GYN.GyneacPartner;
    data: any;
    gynVisit: GYN.GyneacVisit;
    proformaForOASI: GYN.ProformaForOasi;
    orderPrescription: OB.OrdersPrescription;
    // otAppointment:OB
    patientId: number;
    appointments: Array<Appointment>;
    flag = true;
    appointment: GynEncounter;
    procedureSurgery: OB.ProcedureSurgery;
    isPastMedicalHistory: boolean;
    isProcedureSurgery: boolean;
    measure: GYN.Measures;
    referralOrder: Array<OB.OrderReferral>;
    private measureSection: ElementRef;
    bookingHeader: Array<LabBillHeaderModel>;
    signature: string;
    addendumForm: OB.AddendumForm;
    telephoneTriageAssessment: GYN.TelephoneTriageAssessment;
    diseaseName: any
    yearOfDiagnosis: any
    icd: any
    status: any
    treatmentHistory: any
    anaesthesiaRecord: OB.AnaesthesiaRecordPostOP;
    aldreteScore: OB.AldreteScore;
    anaesthesia: OB.Anaesthesia;
    isFooter: boolean;
    isMedicationInstruction: boolean;
    isCheckPrintPrsecription: boolean;
    telephoneTriageAssessmentdata: Array<OB.TelephoneTriageAssessment>
    @ViewChild("measureSection", { static: false }) set measureSectionContent(content: ElementRef) {
        if (content) {
            this.measureSection = content;
            if (this.section && this.section === "measureSection") {
                // this.scrollService.scrollTo(this.measureSection.nativeElement, 1500, -100);
            }
        }
    }
    @ViewChild("vitalsSection", { static: false })
    //  @ViewChild("vitalsSection", { static: false })
    set vitalsContent(content: ElementRef) {
        if (content) {
            this.vitalsSection = content;
            if (this.section && this.section === "vitalsSection") {
                // this.scrollService.scrollTo(this.vitalsSection.nativeElement, 1500, -100);
            }
        }
    }

    //@ViewChild("abdomenSection", { static: false }) set abdomenContent(content: ElementRef) {
    //    if (content) {
    //        this.abdomenSection = content;
    //        if (this.section && this.section === "abdomenSection") {
    //            this.scrollService.scrollTo(this.abdomenSection.nativeElement, 1500, -100);
    //        }
    //    }
    //}

    abdomenEncounter: GYN.AbdomenEncounter;
    breastEncounter: GYN.BreastEncounter;
    skinEncounter: GYN.SkinEncounter;
    musculoskeletal: GYN.Musculoskeletal;
    musculoskeletalEncounter: GYN.MusculoSkeletalEncounter;
    lymphatic: GYN.Lymphatic;
    lymphaticEncounter: GYN.LymphaticEncounter;
    oldAppointment: PreviousAppointment;
    surgeries: Array<OB.ProcedureSurgery>;
    patientDeferred: GYN.Notes;

    gynCards: GYN.GynCard;
    abdomen: GYN.Abdomen;
    respiratory: GYN.Respiratory;
    birthControlEncounter: GYN.BirthControlEncounter;
    breast: GYN.Breast;
    lastPelvicSound: GYN.LastPelvicUltraSound;
    lastMammogram: GYN.LastMammogram;
    cancerHistory: GYN.CancerHistory;
    lastBreastSound: GYN.LastMammogram;
    additionalLab: GYN.AdditionalLabs;
    lastDexaScan: GYN.LastDexaScan;
    gynLab: GYN.GynLab;
    cv: GYN.CV;
    menopausal: GYN.MenopausalHistory;
    mensturalHistory: GYN.MensturalHistory;
    breastScreening: GYN.BreastScreening;
    heent: InternalMedicine.Heent;
    skin: GYN.GynSkin;
    gyneacCard: GYN.GynCard;
    gynaecOrders: GYN.GynaecOrders
    gyneacVisit: GYN.GyneacVisit;
    providerId: number;
    isFamilyHistory = false;
    loadingDocument: boolean;
    documentError: boolean;
    showPrevious: boolean;
    showNext: boolean;
    diabetes: boolean;
    thyroid: boolean;
    bp: boolean;
    vitalModel: Array<Vitals>;
    model: GYN.ImgModel;
    records: Array<PatientMedicationHeader>;
    uvsounding: Array<GYN.UVsounding>;
    investigations: Array<GYN.Investigations>;
    singleDose: Array<GYN.SingleDose>;
    multiDose: Array<GYN.MultiDose>;
    followup: Array<GYN.Followup>;
    specialFeature: OB.SpecialFeature;
    referralForm: Array<OB.ReferralForm>;
    outSideTests: OB.OutsideTest;
    surgerySlip: GYN.SurgerySlip;
    admissionSlip: OB.AdmssionSlip;
    doctorsReviewForm: OB.DoctorReviewForm;
    @Input() useExistingCss: boolean;
    appointmentsList: Appointment;
    antinantalRisk: any
    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly httpService: HttpService,
        // private readonly scrollService: ScrollToService,
        private readonly toggleService: AppointmentToggleService,
        public timelineService: TimelineToggleService,
        private readonly printOptionService: PrintOptionService,
        private readonly iconService: IconService,
        // private readonly menuService: MenuService,
        private readonly appData: AppData,
    ) {

        this.page = new Page();
        this.pagination = new Pagination();
        this.appointments = [];
        this.breastEncounter = new GYN.BreastEncounter();
        this.skinEncounter = new GYN.SkinEncounter();
        this.abdomenEncounter = new GYN.AbdomenEncounter();
        this.musculoskeletalEncounter = new GYN.MusculoSkeletalEncounter();
        this.records = new Array<PatientMedicationHeader>();
        this.uvsounding = new Array<GYN.UVsounding>();
        this.investigations = new Array<GYN.Investigations>();
        this.singleDose = new Array<GYN.SingleDose>();
        this.multiDose = new Array<GYN.MultiDose>();
        this.followup = new Array<GYN.Followup>();
        // this.patientDeferred = new GYN.Notes();
        //this.birthControl = new GYN.BirthControl();
        this.filters = new Filters();
        this.bookingHeader = new Array<LabBillHeaderModel>();
        this.telephoneTriageAssessmentdata = new Array<OB.TelephoneTriageAssessment>();

    }
    private findAncCard() {

        let PatientId: number = this.patientId;
        this.httpService.get(ApiResources.getURI(ApiResources.gynEncounter.base, ApiResources.gynEncounter.fetchGynCard), { patientId: PatientId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: GYN.GynCardGeneration) => {
                this.gynCardGeneration = response;
            });
    }
    gotoPrescription(appointmentId: string) {
        this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/prescription/${appointmentId}`, "_blank"); });
    }
    private fetchMedication() {
        this.loading = true;
        const request = {
            encryptedAppointmentId: this.appointmentId
        }

        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                this.records = response;
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });

    }
    private findPatient() {
        let intervalHit = setInterval(() => {
            let setting = localStorage.getItem("settings");
            if (setting) {
                clearInterval(intervalHit);
                this.page.loading = true;
                const request = { encryptedAppointmentId: this.appointmentId };
                request["isAdmission"] = this.isAdmission;
                this.httpService.post<GynEncounter>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
                    .pipe(takeUntil(this.page.unSubscribe))
                    .subscribe((response: GynEncounter) => {
                        this.encryptedPatientId = response.encryptedPatientId;
                        this.appointment = response;
                        this.patientId = this.appointment.patientId;
                        this.findReport();
                        this.findAncCard();
                        this.fetchLabs();
                        this.fetchMedication();
                    });
            }
        }, 10)
    }

    thresholdBMI() {
        return this.bmiModel.color;
    }

    thresholdBP() {
        return this.bpModel.color;
    }
    get bmiModel() {
        const bmi = parseFloat(this.vitals.bmi);
        if (bmi < 18.5) {
            return { color: "#5a5a5a", title: "Underweight" };
        }

        if (bmi > 18.5 && bmi <= 25) {
            return { color: "#44cf9c", title: "Normal" };
        }

        if (bmi > 25 && bmi <= 30) {
            return { color: "#fcc015", title: "Overweight" };
        }

        if (bmi > 30) {
            return { color: "#fcc015", title: "Overweight" };
        }

        return { color: "#f86262", title: "Obesity" };
    }

    get bpModel() {
        const systolicBP = parseFloat(this.vitals.systolicBP);
        const diastolicBP = parseFloat(this.vitals.diastolicBP);

        if (systolicBP <= 90 && diastolicBP <= 60) {
            return { color: "#5a5a5a", title: "Low" };
        }

        if (systolicBP <= 120 && diastolicBP <= 80) {
            return { color: "#44cf9c", title: "Normal" };
        }

        if (systolicBP <= 140 && diastolicBP <= 90) {
            return { color: "#fcc015", title: "Elevated" };
        }

        if (140 > systolicBP && diastolicBP > 90) {
            return { color: "#f86262", title: "Hypertension" };
        }

        return { color: "#f86262", title: "Hypertension" };
    }
    thresholdRespiratory = {
        '0': { color: "#fcc015" },
        '12': { color: "#44cf9c" },
        '19': { color: "#f86262" }
    };

    thresholdPulse = {
        '50': { color: "#fcc015" },
        '94': { color: "#44cf9c" },
        '98': { color: "#f86262" }
    };

    thresholdTemperature = {
        '94.0': { color: "#fcc015" },
        '97.8': { color: "#44cf9c" },
        '99.1': { color: "#f86262" }
    };

    thresholdHeart = {
        '30': { color: "#fcc015" },
        '60': { color: "#44cf9c" },
        '91': { color: "#f86262" }
    };


    //private fetchMedication() {
    //    this.loading = true;
    //    const request = {
    //        encryptedAppointmentId: this.appointmentId
    //    }

    //    this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetch), request)
    //        .pipe(takeUntil(this.page.unSubscribe))
    //        .pipe(finalize(() => {
    //            this.loading = false;
    //        }))
    //        .subscribe((response: Array<PatientMedicationHeader>) => {
    //            this.records = response;
    //        }, () => {
    //            this.records = Array<PatientMedicationHeader>();
    //        });

    //}
    private fetchLabs() {
        this.loading = true;
        const request = {
            appointmentId: this.appointment.appointmentId,
            isAdmission: this.isAdmission
        }
        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetchLabs), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                if (response && response.length > 0) {
                    this.bookingHeader = response[0].labs;
                }
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });
    }
    filterEkg(item: any) {
        // ReSharper disable once TsResolvedFromInaccessibleModule
        for (const [key, value] of Object.entries(item)) {
            if (value === null) {
                delete item[key];
            }
        }
        return item;
    }
    private findReport() {
        let intervalHit = setInterval(() => {
            let setting = localStorage.getItem("settings");
            if (setting) {
                clearInterval(intervalHit);
                const request = { encryptedAppointmentId: this.appointmentId, encryptedPatientId: this.encryptedPatientId };
                request["isAdmission"] = this.isAdmission;

                this.httpService.post<GynEncounter>(ApiResources.getURI(ApiResources.gynEncounter.base, ApiResources.gynEncounter.findFullTranscript), request)
                    .pipe(takeUntil(this.page.unSubscribe))
                    .pipe(finalize(() => this.page.loading = false))
                    .subscribe((response: GynEncounter) => {
                        this.fullTranscript = response;
                        this.appointments = response["appointments"];
                        this.oldAppointment = response["oldAppointment"];
                        if (this.fullTranscript && this.fullTranscript.gynEncounterId) {
                            this.antinantalRisk = this.fullTranscript.antinantalRisk ? JSON.parse(this.fullTranscript.antinantalRisk) : null;
                            this.abdomen = this.fullTranscript.abdomen ? JSON.parse(this.fullTranscript.abdomen) : null;
                            this.patientDeferred = this.fullTranscript.patientDeferred ? JSON.parse(this.fullTranscript.patientDeferred) : null;
                            //  this.birthControl = this.fullTranscript.birthControl ? JSON.parse(this.fullTranscript.birthControl) : null;
                            this.breast = this.fullTranscript.breast ? JSON.parse(this.fullTranscript.breast) : null;
                            this.musculoskeletal = this.fullTranscript.musculoSkeletal ? JSON.parse(this.fullTranscript.musculoSkeletal) : null;
                            this.lymphatic = this.fullTranscript.lymphatic ? JSON.parse(this.fullTranscript.lymphatic) : null;
                            this.respiratory = this.fullTranscript.respiratory ? JSON.parse(this.fullTranscript.respiratory) : null;
                            this.lastPelvicSound = this.fullTranscript.lastPelvicUltraSound ? JSON.parse(this.fullTranscript.lastPelvicUltraSound) : [];
                            this.lastMammogram = this.fullTranscript.lastMammogram ? JSON.parse(this.fullTranscript.lastMammogram) : [];
                            this.cancerHistory = this.fullTranscript.cancerHistory ? JSON.parse(this.fullTranscript.cancerHistory) : [];
                            this.lastBreastSound = this.fullTranscript.lastBreastUltraSound ? JSON.parse(this.fullTranscript.lastBreastUltraSound) : null;
                            this.additionalLab = this.fullTranscript.additionalLabs ? JSON.parse(this.fullTranscript.additionalLabs) : null;
                            this.lastDexaScan = this.fullTranscript.lastDexaScan ? JSON.parse(this.fullTranscript.lastDexaScan) : null;
                            this.gynLab = this.fullTranscript.gynLabs ? JSON.parse(this.fullTranscript.gynLabs) : null;
                            this.vitals = this.fullTranscript.vitals ? JSON.parse(this.fullTranscript.vitals) : null;
                            this.cv = this.fullTranscript.cardiovascular ? JSON.parse(this.fullTranscript.cardiovascular) : null;
                            this.menopausal = this.fullTranscript.menopausalHistory ? JSON.parse(this.fullTranscript.menopausalHistory) : null;
                            this.mensturalHistory = this.fullTranscript.mensutralHistory ? JSON.parse(this.fullTranscript.mensutralHistory) : null;
                            this.breastScreening = this.fullTranscript.breastScreening ? JSON.parse(this.fullTranscript.breastScreening) : null;
                            this.gynHistory = this.fullTranscript.gynHistory ? JSON.parse(this.fullTranscript.gynHistory) : null;
                            this.heent = this.fullTranscript.heent ? JSON.parse(this.fullTranscript.heent) : null;
                            this.skin = this.fullTranscript.skin ? JSON.parse(this.fullTranscript.skin) : null;
                            this.gynaecOrders = this.fullTranscript.gynaecOrders ? JSON.parse(this.fullTranscript.gynaecOrders) : null;
                            this.reminders = this.fullTranscript.reminder ? this.fullTranscript.reminder : null;

                            this.gynHistory = this.fullTranscript.gynHistory ? JSON.parse(this.fullTranscript.gynHistory) : null;
                            this.gyneacCard = this.fullTranscript.gyneacCard ? JSON.parse(this.fullTranscript.gyneacCard) : null;
                            var data = this.fullTranscript.gynIUI ? JSON.parse(this.fullTranscript.gynIUI) : null;
                            this.IUIData = data;
                            this.gyneacVisit = this.fullTranscript.gynaecVisit ? JSON.parse(this.fullTranscript.gynaecVisit) : null;

                            this.problemList = this.fullTranscript.problemList ? JSON.parse(this.fullTranscript.problemList) : null;

                            this.familyHistory = this.fullTranscript.familyHistory ? JSON.parse(this.fullTranscript.familyHistory) : null;
                            var result = this.fullTranscript.procedure ? JSON.parse(this.fullTranscript.procedure) : null;
                            this.procedureFormData = result;
                            this.allergies = this.fullTranscript.allergies ? JSON.parse(this.fullTranscript.allergies) : null;

                            this.birthHistory = this.fullTranscript.birthHistory ? JSON.parse(this.fullTranscript.birthHistory) : null;
                            this.partnerDetails = this.fullTranscript.gyneacPartner ? JSON.parse(this.fullTranscript.gyneacPartner) : null;
                            this.proformaForOASI = this.fullTranscript.proformaForOasi ? JSON.parse(this.fullTranscript.proformaForOasi) : null;
                            this.orderPrescription = this.fullTranscript.orderPrescription ? JSON.parse(this.fullTranscript.orderPrescription) : null;
                            this.procedureSurgery = this.fullTranscript.procedureSurgery ? JSON.parse(this.fullTranscript.procedureSurgery) : null;
                            this.gynCards = this.fullTranscript.gyneacCard ? JSON.parse(this.fullTranscript.gyneacCard) : null;
                            this.surgeries = this.fullTranscript.surgeries ? JSON.parse(this.fullTranscript.surgeries) : null;
                            this.measure = this.fullTranscript.measure ? JSON.parse(this.fullTranscript.measure) : null;
                            this.referralOrder = this.fullTranscript.refferalOrder ? JSON.parse(this.fullTranscript.refferalOrder) : null;
                            this.gynSurgeries = this.fullTranscript.gyneacSurgery ? JSON.parse(this.fullTranscript.gyneacSurgery) : null;
                            this.specialFeature = this.fullTranscript.specialFeature ? JSON.parse(this.fullTranscript.specialFeature) : null;
                            this.dischargeSummary = this.fullTranscript.gyneacDiscargeSummary ? JSON.parse(this.fullTranscript.gyneacDiscargeSummary) : null;
                            this.admissionSheet = this.fullTranscript.gyneacAdmissionSheet ? JSON.parse(this.fullTranscript.gyneacAdmissionSheet) : null;
                            this.colposcopy = this.fullTranscript.colposcopic ? JSON.parse(this.fullTranscript.colposcopic) : null;
                            this.ectopic = this.fullTranscript.ectopic ? JSON.parse(this.fullTranscript.ectopic) : null;
                            this.outSideTests = this.fullTranscript.outSideTests ? JSON.parse(this.fullTranscript.outSideTests) : null;
                            this.addendumForm = this.fullTranscript.addendumForm ? JSON.parse(this.fullTranscript.addendumForm) : null;
                            this.surgerySlip = this.fullTranscript.surgerySlip ? JSON.parse(this.fullTranscript.surgerySlip) : null;
                            this.doctorsReviewForm = this.fullTranscript.doctorsReview ? JSON.parse(this.fullTranscript.doctorsReview) : null;
                            this.telephoneTriageAssessment = this.fullTranscript.telePhoneTriageAssessment ? JSON.parse(this.fullTranscript.telePhoneTriageAssessment) : null;
                            this.anaesthesiaRecord = this.fullTranscript.anaesthesiaRecordPostOP ? JSON.parse(this.fullTranscript.anaesthesiaRecordPostOP) : null
                            this.aldreteScore = this.fullTranscript.aldreteScore ? JSON.parse(this.fullTranscript.aldreteScore) : null
                            this.anaesthesia = this.fullTranscript.anaesthesiaRecord ? JSON.parse(this.fullTranscript.anaesthesiaRecord) : null
                            if (this.fullTranscript.ectopic) {
                                this.uvsounding = this.ectopic.uvSounding;
                                this.investigations = this.ectopic.investigations;
                                this.singleDose = this.ectopic.singleDose;
                                this.multiDose = this.ectopic.multiDose;
                                this.followup = this.ectopic.followup;
                            }
                            this.admissionSlip = this.fullTranscript.admssionSlip ? JSON.parse(this.fullTranscript.admssionSlip) : null;

                            if (this.fullTranscript.surgeries && this.surgeries.length == 0) {
                                this.isProcedureSurgery = true;
                            }
                            if (this.fullTranscript.familyHistory && this.familyHistory.length == 0) {
                                this.isFamilyHistory = true;
                            }
                            // this.gynCardGeneration = this.fullTranscript.gyneacCard ? JSON.parse(this.fullTranscript.gyneacCard) : null;
                            if (this.abdomen) {
                                if (this.abdomen.jsonString) {
                                    this.abdomenEncounter = JSON.parse(this.abdomen.jsonString);
                                }
                            }
                            if (this.musculoskeletal) {
                                if (this.musculoskeletal.jsonString) {
                                    this.musculoskeletalEncounter = JSON.parse(this.musculoskeletal.jsonString);
                                }
                            }

                            if (this.lymphatic) {
                                if (this.lymphatic.jsonString) {
                                    this.lymphaticEncounter = JSON.parse(this.lymphatic.jsonString);
                                }
                            }
                            if (this.problemList) {
                                this.data = this.problemList["problems"]
                                if (this.data.length == 0) {
                                    this.isPastMedicalHistory = true;
                                }

                            }
                        }
                    });
            }
        }, 10)

    }


    onProgress(progressData: ProgressData) {
        if (progressData.loaded === progressData.total) {
            this.loadingDocument = false;
        }
    }



    onCloseModal(type?: string) {
        if (type === "document") {
            try {
                this.modalViewRef.close();
                this.modalViewRef = undefined;
            } catch (e) {
                // ignored;
            }
        } else {
            try {
                this.modalRef.close();
                this.modalRef = undefined;
            } catch (e) {
                // ignored;
            }
        }
    }
    private findAppointment() {
        const request = {
            encryptedAppointmentId: this.encryptedAppointmentId,
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointmentsList = response;
                this.findreferralForm(this.appointmentsList.patientId, this.appointmentsList.appointmentId);
                this.fetchalltelephonetriage(this.appointmentsList.appointmentId, this.appointmentsList.patientId)
            });

    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    this.isAdmission = url.split("/")[4] === "a";
                    this.appointmentId = decodeURIComponent(url.split("/")[3]);
                    this.filters.options.encryptedAppointmentId = decodeURIComponent(this.appointmentId);
                    this.routingValue = url.split("/")[2];
                    this.encryptedAppointmentId = this.appointmentId;
                    this.encryptedAppointmentId = this.printId
                    this.isAdmission = this.printadmission
                    this.findAppointment();
                    this.getSettings();
                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((params: Params) => {
                            const section = params["section"];
                            this.section = section ? section : undefined;
                            const bypass = params["bypass"];
                            if (!bypass || bypass !== "t") {
                                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                                this.iconService.getIconImage((is) => { this.iconBasics = is; });
                            }
                        });
                    if (this.appointmentId) {
                        this.findPatient();
                        this.findReport();
                    } else {
                        this.router.navigateByUrl(appUrls.notFound);
                    }

                } else {
                    this.page.userAccount = undefined;
                }

                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                this.iconService.getIconImage((is) => { this.iconBasics = is; });

            });

    }
    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }

    appointmentToggle() {
        this.toggleService.toggle(true);
    }

    timelineToggle() {
        this.timelineService.toggle();
    }
    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }
    onChangePrintType(isShow: boolean) {
        this.isPrintLogo = isShow;
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
    private findreferralForm(id: any, appointmentId: any) {
        this.loading = true;

        const request = Object.assign({ isAdmission: this.isAdmission, patientId: id, appointmentId: appointmentId });

        this.httpService.post(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchCrossConsultation), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<OB.ReferralForm>) => {
                if (response.length > 0) {
                    this.referralForm = response;
                }
            });
    }
    fetchalltelephonetriage(appointmentId: any, patientId: any) {
        const request = {
            patientId: patientId,
            appointmentId: appointmentId,
            isAdmission: this.isAdmission
        }

        this.httpService.post(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchtelephonetraige), request)
            .subscribe((result: any) => {
                if (result) {
                    var response = result;
                    response.forEach((x) => {
                        var data = JSON.parse(x.json)
                        this.telephoneTriageAssessmentdata.push(data)
                    })
                }


            })

    }
    private getSettings() {
        this.loading = true;
        this.httpService.get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: (response: Array<Setting>) => {
                    if (response && response.length > 0) {
                        var medciationINstruction = response.find(s => s.name == "OrderPrescriptionMedicationInstruction");
                        var isOrderPrescription = response.find(s => s.name == "ObOrderPrescriptionDesign");
                        this.isMedicationInstruction = medciationINstruction && medciationINstruction.active ? medciationINstruction.active : false;
                        this.isCheckPrintPrsecription = isOrderPrescription && isOrderPrescription.active ? isOrderPrescription.active : false;

                    }
                }, error: () => { }
            });
    }

}