
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <table width="100%" class="heading1">
                <tr *ngIf="isPrintLogo">
                    <td colspan="4" align="center">
                        <banner-setting [reportName]='"Booking Scan Invoice Header"'></banner-setting>
                    </td>
                </tr>
                <tr *ngIf="!isPrintLogo">
                    <td colspan="4" align="center">
                        <h4 class="font-weight-bolder">
                            Final Bill of Supply - Diagnostics Bill
                        </h4>
                        <h4 class="font-weight-bolder">
                            Health Care Service - SAC
                        </h4>
                    </td>
                </tr>
                <tr>
                    <td colspan="4" class="scanInvoice">
                        {{selectedAppointment && selectedAppointment.paymentStatus === true && selectedAppointment.paymentType === 'P' ? "Scan Receipt" : "Scan Invoice"}}
                    </td>
                </tr>
                <tr class="heading2">
                    <th class="headingDesign">
                        MR No
                    </th>
                    <td class="dataDesign">
                        <span class="colonMargin">: </span>
                        <span [textContent]="selectedAppointment && selectedAppointment.umrno || '---'"></span>
                    </td>
                    <th class="headingDesign">
                        <span>Bill Date</span>
                    </th>
                    <td class="dataDesign">
                        <span class="colonMargin">: </span>
                        <span [textContent]="(selectedAppointment && selectedAppointment.payTypes && selectedAppointment.payTypes.length > 0) ? (selectedAppointment.payTypes[selectedAppointment.payTypes.length - 1].createdDate | date:'dd-MM-yyyy, h:mm a') : ''"></span>
                    </td>
                </tr>
                <tr>
                    <th class="headingDesign">
                        Patient Name
                    </th>
                    <td class="dataDesign">
                        <span class="colonMargin">: </span>
                        <span class="text-uppercase" [textContent]="selectedAppointment && selectedAppointment.patientName"></span>
                    </td>
                    <th class="headingDesign">
                        Age/Gender
                    </th>
                    <td class="dataDesign">
                        <span class="colonMargin">: </span>
                        <span *ngIf="selectedAppointment && selectedAppointment.ageInYMD" [textContent]="selectedAppointment.ageInYMD"></span>
                        <span *ngIf="selectedAppointment && selectedAppointment.gender" [textContent]="selectedAppointment.ageInYMD ? ' / ' + selectedAppointment.gender : selectedAppointment.gender"></span>
                    </td>
                </tr>
                <tr>
                    <th class="headingDesign">
                        Doctor Name
                    </th>
                    <td class="dataDesign">
                        <span class="colonMargin">: </span>
                        <span class="text-uppercase" [textContent]="selectedAppointment && selectedAppointment.providerName"></span>
                    </td>
                    <th class="headingDesign">
                        Requisition No
                    </th>
                    <td class="dataDesign">
                        <span class="colonMargin">: </span>
                        <span [textContent]="selectedAppointment && selectedAppointment.requisitionNumber"></span>
                    </td>
                </tr>
                <tr>
                    <th class="headingDesign">
                        <span>Location</span>
                    </th>
                    <td class="dataDesign">
                        <span class="colonMargin">: </span>
                        <span [textContent]="selectedAppointment.locationName"></span>
                    </td>
                    <th class="headingDesign">
                        Mobile No
                    </th>
                    <td class="dataDesign">
                        <span class="colonMargin">: </span>
                        <span [textContent]="selectedAppointment.mobile"></span>
                    </td>
                </tr>
                <tr *ngIf="ancNumber" class="heading2">
                    <th class="headingDesign">
                        ANC No
                    </th>
                    <td class="dataDesign">
                        <span class="colonMargin">: </span>
                        <span [textContent]="ancNumber"></span>
                    </td>
                </tr>
            </table>
        </div>
        <div class="col-12 p-0 mt-2">
            <table class="table table-sm mb-0">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Test Name</th>
                        <th>Status</th>
                        <th>Appointment Time</th>
                        <th>Payment Status</th>
                        <th *ngIf="selectedAppointment.paymentStatus === true">
                            Payment Type
                        </th>
                        <th *ngIf="selectedAppointment.isPatRegPaid">Reg Charges</th>
                        <th>Scan Amount</th>
                        <th>Discount</th>
                        <th>Net Amount</th>
                        <th>Paid Amount</th>
                        <th>Due Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><h6 class="mb-0 mt-0" [textContent]="1"></h6></td>
                        <td>
                            <h6 class="mb-0 mt-0"
                                [textContent]="selectedAppointment.scanTestName"></h6>
                        </td>
                        <td>
                            <h6 class="mb-0 mt-0"
                                [textContent]="selectedAppointment.status"></h6>
                        </td>
                        <td>
                            <h6 class="mb-0 mt-0"
                                [textContent]="selectedAppointment.appointmentDate + ' ' + selectedAppointment.appointmentTime"></h6>
                        </td>
                        <td>
                            <span class="badge badge-soft-success"
                                  *ngIf="selectedAppointment.paymentStatus === true && selectedAppointment.paymentType === 'F'"
                                  [textContent]="'Paid'"></span>
                            <span class="badge badge-soft-warning"
                                  *ngIf="selectedAppointment.paymentStatus === true && selectedAppointment.paymentType === 'P'"
                                  [textContent]="'PartiallyPaid'"></span>
                            <span class="badge badge-soft-danger"
                                  *ngIf="selectedAppointment.paymentStatus === false"
                                  [textContent]="'Not Paid'"></span>
                        </td>
                        <td *ngIf="selectedAppointment.paymentStatus === true">
                            <h6 class="mb-0 mt-0"
                                [textContent]="selectedAppointment.payTypeName"></h6>
                        </td>
                        <td *ngIf="selectedAppointment.isPatRegPaid">
                            <h6 class="mb-0 mt-0"
                                [textContent]="selectedAppointment.regCharges || 0 | currency :'INR'"></h6>
                        </td>
                        <td>
                            <h6 class="mb-0 mt-0"
                                [textContent]="selectedAppointment.scanAmount || 0 | currency :'INR'"></h6>
                        </td>
                        <td>
                            <h6 class="mb-0 mt-0"
                                [textContent]="selectedAppointment.totalDiscount || 0 | currency :'INR'"></h6>
                        </td>
                        <td>
                            <h6 class="mb-0 mt-0"
                                [textContent]="selectedAppointment.netAmount || 0 | currency :'INR'"></h6>
                        </td>
                        <td>
                            <h6 class="mb-0 mt-0"
                                [textContent]="selectedAppointment.paidAmount || 0 | currency :'INR'"></h6>
                        </td>
                        <td>
                            <h6 class="mb-0 mt-0"
                                [textContent]="selectedAppointment.status == 'Cancelled' && selectedAppointment.isPatRegPaid ? 0 : selectedAppointment.netAmount - selectedAppointment.paidAmount || 0 | currency :'INR'"></h6>
                        </td>
                    </tr>
                    <tr>
                        <td *ngIf="selectedAppointment.paymentType === 'P'" class="justify-content-center d-flex">
                            <div id="backgroundOfwaterMark" class="waterMarkRotate"> <span>Partial Paid</span></div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="12">
                            <div class="d-flex justify-content-start">
                                <h6 *ngIf="selectedAppointment.indication">
                                    COMMENTS :{{selectedAppointment.indication}}
                                </h6>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
        <div class="col-12 row mt-1">
            <div class="col-6">
                <table width="100%" class="heading1">
                    <tr>
                        <td class="DataFontWeight">
                            <span *ngIf="selectedAppointment.paymentType === 'F'">
                                {{selectedAppointment.paymentType==='F' ? 'Full Paid' : 'Partial Paid'}}
                            </span>
                        </td>

                    </tr>
                    <tr *ngFor="let payType of selectedAppointment.payTypes">
                        <td class="DataFontWeight" [textContent]="payType.payTypeName">
                        </td>
                        <td>
                            <span class="DataFontWeight">:</span>
                            <span>&nbsp; &nbsp;</span>
                            <span class="DataFontWeight" [textContent]="payType.amountPaid || 0 | currency :'INR'"> </span>
                            <span>&nbsp; &nbsp;</span>
                            <span [textContent]="payType.createdDate  | date:'dd-MM-yyyy, h:mm a'"></span>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-6">
                <table width="100%" class="heading1">
                    <tr class="heading2">
                        <th>Prepared By:</th>
                        <td>
                            <span [textContent]="selectedAppointment.createdByName"></span>
                        </td>
                    </tr>
                    <tr class="heading2">
                        <th *ngIf="selectedAppointment.paymentStatus === true">
                            Paid Via:
                        </th>
                        <td *ngIf="selectedAppointment.paymentStatus === true">
                            <span>
                                <span [textContent]="selectedAppointment.payTypeName"></span>
                            </span>
                        </td>
                    </tr>
                    <tr class="heading2">
                        <th>Printed By:</th>
                        <td>
                            <span [textContent]="page.userAccount.fullName"></span>
                        </td>
                    </tr>
                    <tr class="heading2">
                        <th>Printed Date:</th>
                        <td>
                            <span [textContent]="currentDate | date:'dd-MM-yyyy, h:mm a'"></span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-12" *ngIf="isFooter">
            <banner-setting [reportName]='"Booking Scan Invoice Footer"'> </banner-setting>
        </div>
    </div>
</div>
