
<div class="my-2">
    <div class="align-items-end d-flex justify-content-between">
        <div class="text-right">
            <button class="btn btn-info" type="button" (click)="onChangePrintType(false)">
                <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
            </button>
            <button class="btn btn-info" type="button" (click)="onChangePrintType(true)">
                <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
            </button>
            <button class="btn btn-info" type="button" (click)="onChangeFooter(false)">
                <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
            </button>
            <button class="btn btn-info" type="button" (click)="onChangeFooter(true)">
                <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
            </button>
        </div>
        <div class="d-print-none">
            <div class="align-items-center d-flex float-right gap10px">
                <h4 class="m-0 d-print-none heading2">Physiotherapy Encounter Full Transcript</h4>
                <button [useExistingCss]="true" printSectionId="phyprint" ngxPrint class="close d-print-none"> <i class="fe-printer" placement="left" ngbTooltip="PrintEncounter"></i></button>
            </div>
        </div>
    </div>
    <div id="phyprint" class="scroll-obsecNotHeading">
        <div class="mb-0 bg-white">
            <div class="row" *ngIf="page.loading">
                <div class="col-12">
                    <div class="d-flex align-items-center">
                        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                        <span class="ml-2">Please wait while generating Physiotherapy Full Transcript ...</span>
                    </div>
                </div>
            </div>
            <div class="report position-relative">

                <div class="page-header text-center">
                    <div *ngIf="isPrintLogo">
                        <banner-setting [reportName]='"Physiotherapy Full Transcript Header"'></banner-setting>
                    </div>
                </div>

                <div class="page-footer">
                    <!--this space is for footer-->
                </div>

                <table class="w-100">

                    <thead>
                        <tr>
                            <td>
                                <!--place holder for the fixed-position header-->
                                <div class="page-header-space"></div>
                            </td>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>
                                <!--*** CONTENT GOES HERE ***-->
                                <div class="page">
                                    <ng-container *ngIf="physiotherapy">
                                        <div class="col-12 d-flex justify-content-between heading1 white-space-nowrap">
                                            <div class="col-6 p-0">
                                                <div class="text-truncate">
                                                    <label>Patient Name:</label>
                                                    <span class="pl-1 text-uppercase" [textContent]="appointment.patientName"></span>
                                                </div>
                                                <div class="text-truncate" *ngIf="appointment.patientMobile">
                                                    <label>Mobile No:</label>
                                                    <span class="pl-1" [textContent]="appointment.patientMobile + ' / '"></span>
                                                    <span class="pl-1" *ngIf="appointment.patientGender" [textContent]="(appointment.patientGender | gender) + ' / '"></span>
                                                    <span class="pl-1" *ngIf="appointment.patientAge" [textContent]="appointment.patientAge +' yrs'"></span>
                                                    <span class="pl-1" *ngIf="!appointment.patientAge" [textContent]="'0 yrs' "></span>
                                                </div>
                                            </div>
                                            <div class="d-inline-block" *ngIf="isAdmission==false">
                                                <div>
                                                    <label>Appointment No:</label>
                                                    <span class="pl-1">{{appointment.appointmentNo}}</span>
                                                </div>
                                                <div>
                                                    <label>Appointment Date:</label>
                                                    <span class="pl-1" [textContent]="(appointment.appointmentDate |  date :'dd/MM/yyyy') + ' ' + appointment.appointmentTimeString"></span>
                                                </div>

                                            </div>
                                            <div class="d-inline-block" *ngIf="isAdmission==true">
                                                <div>
                                                    <label>Admission No:</label>
                                                    <span class="pl-1">{{patient.patient.admissionId}}</span>
                                                </div>
                                                <div>
                                                    <label>Admission Date:</label>
                                                    <span class="pl-1" [textContent]="(appointment.admissionDate |  date :'dd/MM/yyyy') + ' ' + appointment.appointmentTimeString"></span>
                                                </div>

                                            </div>
                                        </div><hr />
                                    </ng-container>
                                    <div>
                                        <ng-container *ngIf="physiotherapy">
                                            <div class="col-12 textOfEveryVw" *ngIf=" physiotherapy">
                                                <div class="col-12 textOfEveryVw" *ngIf="!page.loading">
                                                    <div class="dashboard-box dashboard-table-box" #specialFeatureSection *ngIf="specialFeature && specialFeature.specialFeature !== null">
                                                        <h4 class="dashboard-title">Special Features</h4>
                                                        <div class="dashboard-body">
                                                            <div class="overflow-auto">
                                                                <h6 class="font-14 mt-0 mb-2">Special Features</h6>
                                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                                    <tr *ngIf="specialFeature.specialFeature">
                                                                        <td class="col-8">Special Features</td>
                                                                        <td class="col-4" [textContent]="specialFeature.specialFeature"> </td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="dashboard-box dashboard-table-box" #physioTherapyTemplateSection *ngIf="physioTherapyTemplate && physioTherapyTemplate !== null && physioTherapyTemplate.templates.length > 0">
                                                        <h4 class="dashboard-title">Physiotherapy Template</h4>
                                                        <div class="dashboard-body">
                                                            <div class="overflow-auto">

                                                                <div class="table table-sm table-striped table-bordered mb-0 font-13 pad-imp">
                                                                    <div class="d-flex">
                                                                        <div class="col-1 border-right border-bottom">SN</div>
                                                                        <div class="col-3 border-right border-bottom">Appointment Date</div>
                                                                        <div class="col-3 border-right border-bottom">Template Name</div>
                                                                        <div class="col-5 border-bottom">Description Summary </div>
                                                                    </div>
                                                                    <div class="d-flex" *ngFor="let item of physioTherapyTemplate.templates; let i = index">
                                                                        <div class="col-1 border-right border-bottom">
                                                                            <span [textContent]="i+1"></span>
                                                                        </div>
                                                                        <div class="col-3 border-right border-bottom">
                                                                            <span [textContent]="item.addDate| date :'dd/MM/yyyy'"></span>
                                                                        </div>
                                                                        <div class="col-3 border-right border-bottom">
                                                                            <span [textContent]="item.templateName"></span>
                                                                        </div>
                                                                        <div class="col-5 border-bottom">
                                                                            <span class="word-break" [innerHTML]="item.description"></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="roleId != 63">
                                                        <div class="dashboard-box dashboard-table-box" #reminderSection *ngIf="reminders && reminders !== null">
                                                            <div class="dashboard-body">
                                                                <div class="overflow-auto">
                                                                    <h6 class="font-14 mt-0 mb-2">Reminders</h6>
                                                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                                        <tr *ngIf="reminders && reminders !== null">
                                                                            <td class="col-8">Reminders</td>
                                                                            <td class="col-4" [textContent]="reminders"> </td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="dashboard-box dashboard-table-box" #orderPrescriptionSection *ngIf="(records && records.length > 0) ||  (bookingHeader && bookingHeader.length > 0) ||(refferalOrder && refferalOrder.length > 0)">
                                                        <h4 class="dashboard-title">Order Prescription</h4>
                                                        <div class="dashboard-body">
                                                            <div class="overflow-auto">
                                                                <table class="table table-centered table-nowrap table-sm table-striped transcipt mb-0">
                                                                    <tr *ngIf="bookingHeader && bookingHeader.length > 0">
                                                                        <td>Investigations</td>
                                                                        <td>
                                                                            <table class="table table-centered table-nowrap table-sm table-striped transcipt mb-0">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Test Name</th>
                                                                                        <th>Test Code</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                    <tr *ngFor="let item of bookingHeader;let i =index;">
                                                                                        <td>
                                                                                            <span class=" text-wrap" [textContent]="item.testName"></span><br />
                                                                                        </td>
                                                                                        <td>
                                                                                            <span class=" text-wrap" [textContent]="item.testCode"></span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="records && records.length > 0">
                                                                        <td>
                                                                            Medications
                                                                        </td>
                                                                        <td *ngFor="let record of records;">
                                                                            <table class="table table-centered table-nowrap table-sm table-striped transcipt mb-0">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Medicine Name</th>
                                                                                        <th>Generic Name</th>
                                                                                        <th>Frequency</th>
                                                                                        <th>Route	</th>
                                                                                        <th>Duration</th>
                                                                                        <th>Qty</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                    <tr *ngFor="let item of record.medicines; let i = index">
                                                                                        <td>
                                                                                            <span class=" text-wrap" [textContent]="item.productName"></span><br />
                                                                                        </td>
                                                                                        <td>
                                                                                            <span class=" text-wrap" *ngIf="item.genericName" [textContent]="item.genericName"></span>

                                                                                        </td>
                                                                                        <td>
                                                                                            <span class=" text-wrap" *ngIf="item.instruction" [textContent]="item.instruction"></span>


                                                                                        </td>
                                                                                        <td>
                                                                                            <span class=" text-wrap" *ngIf="item.route" [textContent]="item.route"></span>

                                                                                        </td>
                                                                                        <td>
                                                                                            <span class=" text-wrap" *ngIf="item.duration" [textContent]="item.duration"></span>

                                                                                        </td>
                                                                                        <td>
                                                                                            <span class=" text-wrap" *ngIf="item.dosage" [textContent]="item.dosage"></span>

                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div *ngIf="refferalOrder && refferalOrder.length > 0">
                                                                    <h5>Referrals</h5>
                                                                </div>

                                                                <div class="overflow-auto" *ngIf="refferalOrder && refferalOrder.length > 0">
                                                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th class="col-8">Doctors</th>
                                                                                <th class="col-4">Comments</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr *ngFor="let item of refferalOrder; let i = index">
                                                                                <td>
                                                                                    <span class="col-8 text-wrap" [textContent]="item.name"></span><br />
                                                                                </td>
                                                                                <td>
                                                                                    <span class="col-4 text-wrap" [textContent]="item.comments"></span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 textOfEveryVw">
                                                    <div class="dashboard-box dashboard-table-box" *ngIf="outSideTests && outSideTests !== null" #outsideTestsSection>
                                                        <h4 class="dashboard-title">Outside Tests</h4>
                                                        <div class="dashboard-body" *ngFor="let items of outSideTests">
                                                            <div class="overflow-auto">
                                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="items">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Done Date</th>
                                                                            <th>Test Name</th>
                                                                            <th>Other Tests</th>
                                                                            <th>Other Test Results</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <div class="media">
                                                                                    <div class="media-body">
                                                                                        <h5 class="mb-0 mt-0 font-weight-normal text-truncate-line" [textContent]=" items.doneDate | date:'dd/MM/yyyy'"></h5>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <p *ngIf="items.testname" [textContent]="items.testname"></p>
                                                                            </td>
                                                                            <td>
                                                                                <p *ngIf="items.otherTests" [textContent]="items.otherTests "></p>
                                                                            </td>
                                                                            <td>
                                                                                <p *ngIf="items.otherTestResults" [textContent]="items.otherTestResults "></p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <table *ngIf="items.parameters && items.parameters.length>0" class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>SN</th>
                                                                            <th>Component</th>
                                                                            <th>Parameter</th>
                                                                            <th>Result Value</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tfoot>
                                                                        <tr *ngFor="let item of items.parameters; let i=index">
                                                                            <td>
                                                                                <p *ngIf="item.parameterName" [textContent]="i+1"></p>
                                                                            </td>
                                                                            <td>
                                                                                <p *ngIf="item.componentName" [textContent]="item.componentName"></p>
                                                                            </td>
                                                                            <td>
                                                                                <p *ngIf="item.parameterName" [textContent]="item.parameterName"></p>
                                                                            </td>
                                                                            <td>
                                                                                <p *ngIf="item.resultValues" [textContent]="item.resultValues"></p>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="dashboard-box dashboard-table-box" #referralFormSection *ngIf="referralForm && referralForm.length>0 ">
                                                    <h4 class="dashboard-title">Referral Form</h4>
                                                    <div class="dashboard-body">
                                                        <div class="overflow-auto">
                                                            <table class="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Referral To Doctor</th>
                                                                        <th>Reason For Referring</th>
                                                                        <th>Req Date</th>
                                                                        <th>Time</th>
                                                                        <th>Consultant Doctor</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let item of referralForm">
                                                                        <td><span [textContent]="item.referralDoctor"></span></td>
                                                                        <td><span [textContent]="item.reason"></span></td>
                                                                        <td><span [textContent]="item.requiredDate | date: 'dd/MM/yyyy'"></span></td>
                                                                        <td><span [textContent]="item.time"></span></td>
                                                                        <td><span [textContent]="item.consultantDoctor"></span></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                </div>


                                                <div #telephoneTriageAssessmentSection *ngIf="telephoneTriageAssessmentdata && telephoneTriageAssessmentdata.length>0">
                                                    <div class="dashboard-box dashboard-table-box" *ngFor="let telephoneTriageAssessments of telephoneTriageAssessmentdata">
                                                        <h4 class="dashboard-title">TelePhone Triage Assessment </h4>
                                                        <div class="dashboard-body">
                                                            <div class="overflow-auto" *ngIf="telephoneTriageAssessments && telephoneTriageAssessments !== null && (telephoneTriageAssessments.callDate || telephoneTriageAssessments.callTime || telephoneTriageAssessments.typeofConsultation || telephoneTriageAssessments.reasonForCall || telephoneTriageAssessments.reasonothers
                               || telephoneTriageAssessments.adviceother || telephoneTriageAssessments.adviceGiventoPatient || telephoneTriageAssessments.actionTakenIfAdvisedToVisit || telephoneTriageAssessments.actionother || telephoneTriageAssessments.doctorName || telephoneTriageAssessments.callCompletedDate || telephoneTriageAssessments.callcompletedTime)">
                                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                                    <tr>
                                                                        <td class="col-8">Call Date</td>
                                                                        <td class="col-4" [textContent]="telephoneTriageAssessments.callDate | date:'dd/MM/yyyy'"> </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="col-8">Call Time</td>
                                                                        <td class="col-4" [textContent]="telephoneTriageAssessments.callTime"> </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="col-8">Type of Consultation</td>
                                                                        <td class="col-4" [textContent]="telephoneTriageAssessments.typeofConsultation"> </td>
                                                                    </tr>
                                                                    <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.reasonForCall?.length > 0">
                                                                        <td class="col-4">Reason For Call</td>
                                                                        <td class="col-8" *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.reasonForCall">
                                                                            <ul class="dashboard-ul row">
                                                                                <li *ngFor="let item of  telephoneTriageAssessments.reasonForCall" [textContent]="item"></li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="col-8">Other</td>
                                                                        <td class="col-4" [textContent]="telephoneTriageAssessments.reasonothers"> </td>
                                                                    </tr>
                                                                    <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.adviceGiventoPatient?.length > 0">
                                                                        <td class="col-4">Advice Given To Patient</td>
                                                                        <td class="col-8">
                                                                            <ul class="dashboard-ul row">
                                                                                <li *ngFor="let item of  telephoneTriageAssessments.adviceGiventoPatient" [textContent]="item"></li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="col-8"> Others</td>
                                                                        <td class="col-4" [textContent]="telephoneTriageAssessments.adviceother"> </td>
                                                                    </tr>
                                                                    <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.actionTakenIfAdvisedToVisit?.length > 0">
                                                                        <td class="col-4">Action Taken If Advised To Visit</td>
                                                                        <td class="col-8">
                                                                            <ul class="dashboard-ul row">
                                                                                <li *ngFor="let item of  telephoneTriageAssessments.actionTakenIfAdvisedToVisit" [textContent]="item"></li>

                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="col-8">Others</td>
                                                                        <td class="col-4" [textContent]="telephoneTriageAssessments.actionother"> </td>
                                                                    </tr>
                                                                    <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.doctorName">
                                                                        <td class="col-8">Doctor Name</td>
                                                                        <td class="col-4 text-uppercase" [textContent]="telephoneTriageAssessments.doctorName"> </td>
                                                                    </tr>
                                                                    <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.callCompletedDate">
                                                                        <td class="col-8">Call Completed Date</td>
                                                                        <td class="col-4" [textContent]="telephoneTriageAssessments.callCompletedDate | date:'dd/MM/yyyy'"> </td>
                                                                    </tr>
                                                                    <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.callcompletedTime">
                                                                        <td class="col-8">Call Completed Time</td>
                                                                        <td class="col-4" [textContent]="telephoneTriageAssessments.callcompletedTime"> </td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </ng-container>
                                    </div>
                                </div>

                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <div class="page-footer-space">
                                    <div *ngIf="isFooter">
                                        <banner-setting [reportName]='"Physiotherapy Full Transcript Footer"'></banner-setting>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tfoot>

                </table>
            </div>
        </div>
    </div>
</div>




