export class IPChangeRoom {
    ipRoomChangedId: number;
    admissionId: number;
    admissionNo: string;
    admissionDate: Date;
    //patientName: string;
    //ralativeName: string;
    //age: number;
    //gender: string;
    //streetAddress: string;
    //addressLine2: string;
    //city: string;
    //state: string;
    //zipcode: string;    
    //currentChargeCategoryName: string;
    //currentChargeCategoryId: number;    
    currentBedId: number;
    currentBedNumber: string;
    unitName: string;
    currentRoomId: number;
    currentRoomName: string;
    currentWardId: number;
    currentWardName: string;
    currentFloorId: number;
    currentFloorName: string;
    //currentDoctorUnitMasterId: number;
    //currentUnitName: string;

    changedChargeCategoryName: string;
    //changedChargeCategoryId: number;
    changedBedId: number;
    changedBedNumber?: string;
    changedRoomId: number;
    changedRoomName: string;
    changedWardId: number;
    changedWardName: string;
    changedFloorId: number;
    changedFloorName: string;
    //changedDoctorUnitMasterId: number;
    //changedUnitName: string;
    encryptedPatientId: string;
    patientId: number;
    payType: string;
    patientMobile: string;
    patientName: String;
    umrNo: string;
    isAdmissonBed: string;
}