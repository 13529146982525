import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "gender"
})
export class GenderPipe implements PipeTransform {
    transform(val: string) {
        switch (val) {
            case "M":
                return "Male";
            case "F":
                return "Female";
            case "O":
                return "Other";
            case "A":
                return "Ambiguous";
            default:
                return "N/A";
        }
    }
}

@Pipe({
    name: "counsellingType"
})
export class CounsellingType implements PipeTransform {
    transform(val: string) {
        switch (val) {
            case "E":
                return "Estimation";
            case "F":
                return "Finance";
            default:
                return "N/A";
        }
    }
}

@Pipe({
    name: "payType"
})
export class PayType implements PipeTransform {
    transform(val: string) {
        switch (val) {
            case "C":
                return "Cash";
            case "I":
                return "Insurance";
            default:
                return "N/A";
        }
    }
}

@Pipe({
    name: "packageType"
})
export class PackageType implements PipeTransform {
    transform(val: string) {
        switch (val) {
            case "O":
                return "Out-patient";
            case "I":
                return "In-patient";
            case "OI":
                return "OP & IP";
            default:
                return "N/A";
        }
    }
}


@Pipe({
    name: "formatLabel"
})
export class FormatLabelPipe implements PipeTransform {
    transform(label: string): string {
        return label.trim().replace(/\s+/g, '_');
    }
}