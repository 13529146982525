export class pediatricEncounterFullTranscript {
    appointmentId: number;
    appointmentNo: string;
    patientId: number;
    encryptedPatientId: string;
    encounterId?: number;
    patientName: string;
    patientAge?: number;
    patientDateOfBirth?: Date;
    patientGender: string;
    patientMobile: string;
    patientThumbnailUrl: string;
    patientCountryCode: string;
    appointmentDate: Date;
    appointmentTime: object;
    providerName: string;
    appointmentTimeString: string;
    problemList: string;
    briefRos: string;
    geneticScreening: string;
    infectionHistory: string;
    followUp: string;
    medications: string;
    notes: string;
    pediatricEncounterId: number;
    nextVisitOrder: string;
    signature: string;
    clinicPicture: string;
    practiceLocation: string;
    practiceName: string;
    specializationName: string;
    providerNo: string;
    educations: string;
    complaints: string;
    encounterDate: Date;
    active: boolean;
    createdBy: number;
    mobile: string;
    fullName: string;
    email: string;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
    measurements: string;
    neonatalRisk: string;
    pediatricRisk: string;
    syndrome: string;
    pediatricGuide: string;
    clinicalExamination: string;
    downSyndromeFollowUp: string;
    highRiskIntial: string;
    orderPrescription: string;
    allergies: string;
    refferalOrder: string;
    specialFeature: string;
    umrNo: string;

    diagnosisOrder: string;
    medicationComment: string;
    birthHistory: string;
    familyHistory: string;
    mchat: string;
    vaccineDetail: string;
    reminder: string;
    others: string;
    addendumForm: string;
    surgeries: string;
    cimsAllergy: string;
    aldreteScore: string;
    telePhoneTriageAssessment: string;
    anaesthesiaRecord: string;
    anaesthesiaRecordPostOP: string;
    outSideTests: string;
}