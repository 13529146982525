
<div id="postnantalReportContainer" class="scroll-obsecNotHeading row">
    <div class="col-12">
        <div class="mt-1">
            <div class="col-12 p-0">
                <div class="col border border-bottom-0 d-flex pl-1 pr-1 pt-1" style="background-color: #edfaf7;">
                    <div>
                        <button class="btn btn-info" type="button" (click)="onChangePrintType( false)">
                            <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                        </button>
                        <button class="btn btn-info" type="button" (click)="onChangePrintType(true)">
                            <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                        </button>
                        <button class="btn btn-info" type="button" (click)="onChangeFooter(false)">
                            <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
                        </button>
                        <button class="btn btn-info" type="button" (click)="onChangeFooter(true)">
                            <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
                        </button>
                    </div>
                    <div class="col">
                        <button [useExistingCss]="true" id="printButton" printSectionId="postnantalReport" ngxPrint class="close d-print-none"> <i class="fe-printer" placement="left" ngbTooltip="PrintEncounter"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="border-left border-right bg-white mb-0 pt-1">
            <div class="clearfix mb-3">
                <div id="postnantalReport">
                    <div class="report position-relative">
                        <div class="page-header text-center" *ngIf="isPrintLogo">
                            <banner-setting [reportName]='"Ob Postnantal Report Header"'></banner-setting>
                        </div>
                        <div class="page-footer">
                            <div class="border-dark border-bottom d-flex flex-wrap justify-content-between">
                                <div class="d-flex">
                                    <div><span>Saved User :&nbsp;</span></div>
                                    <div *ngIf="appointment"><span class="text-uppercase" [textContent]="appointment.providerName"></span></div>
                                </div>
                                <div class="d-flex">
                                    <div><span>Date :&nbsp;</span></div>
                                    <div><span [textContent]="date | date :'d/MM/yyyy'"></span>,<span [textContent]="date | date :' h:mm a'"></span></div>
                                </div>
                                <div class="d-flex">
                                    <div><span>Printed by :&nbsp;</span></div>
                                    <div><span class="text-uppercase" [textContent]="printName"></span></div>
                                </div>
                            </div>
                            <div *ngIf="isFooter">
                                <banner-setting [reportName]='"Ob Postnantal Report Footer"'></banner-setting>
                            </div>
                        </div>

                        <table class="w-100">

                            <thead>
                                <tr>
                                    <td>
                                        <!--place holder for the fixed-position header-->
                                        <div class="page-header-space"></div>
                                    </td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>
                                        <!--*** CONTENT GOES HERE ***-->
                                        <div class="page">
                                            <div class="col-12 p-0">
                                                <div class="text-dark" *ngIf="postnantalModel && postnantalModel !== null">
                                                    <div class="border-dark border-top2px d-flex justify-content-between pb-1 pt-1 border-bottom-dotted">
                                                        <h4>Postnatal Visit</h4>
                                                        <h4 class="white-space-nowrap">Department of Obesterics</h4>
                                                    </div>
                                                    <ng-container>
                                                        <div class="col-12 p-0 pb-1 pt-1 white-space-nowrap">
                                                            <table class="w-100 white-space-nowrap">
                                                                <tr class="col-12">
                                                                    <td class="w-130 border-bottom-dotted">
                                                                        <span class="">Name</span>
                                                                    </td>
                                                                    <td class="border-bottom-dotted">
                                                                        <h5 class="">:</h5>
                                                                    </td>
                                                                    <td class="border-bottom-dotted text-uppercase">
                                                                        <h5 class="d-block  pl-1 text-truncate min-width17vw max-width_25vw" *ngIf="patient && patient.patient" [textContent]="patient.patient.fullName"></h5>
                                                                    </td>
                                                                    <td class="w-130 border-bottom-dotted">
                                                                        <span class=""> UMR No.</span>
                                                                    </td>
                                                                    <td class="border-bottom-dotted">
                                                                        <h5 class="">:</h5>
                                                                    </td>
                                                                    <td class="w-130 text-uppercase border-bottom-dotted">
                                                                        <h5 class="pl-1 " *ngIf="patient && patient.patient" [textContent]="patient.patient.umrNo"></h5>
                                                                    </td>

                                                                </tr>

                                                                <tr class="col-12">
                                                                    <td>
                                                                        <span> Doctor Name</span>
                                                                    </td>
                                                                    <td>
                                                                        <h5 class="">:</h5>
                                                                    </td>

                                                                    <td>
                                                                        <h5 class="pl-1 text-uppercase " [textContent]="postnantalModel.consultedByDr"></h5>
                                                                    </td>
                                                                    <td>
                                                                        <span> Visit Date</span>
                                                                    </td>
                                                                    <td>
                                                                        <h5 class="">:</h5>
                                                                    </td>
                                                                    <td><h5 class=" pl-1 text-uppercase" [textContent]="postnantalModel.visitDate | date:'dd/MM/yyyy'"></h5> </td>
                                                                </tr>
                                                                <tr>

                                                                    <td>
                                                                        <span>Mode of Birth</span>
                                                                    </td>
                                                                    <td>
                                                                        <h5 class="">:</h5>
                                                                    </td>
                                                                    <td>
                                                                        <h5 class="pl-1 text-uppercase " [textContent]="postnantalModel.modeOfDelivery"></h5>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>

                                                        <div class="w-100 border-top2px pt-2 white-space-nowrap">
                                                            <div>
                                                                <h5>Baby Details</h5>
                                                            </div>
                                                            <div>
                                                                <span class="font-weight-bold">No.Of Fetus:{{postnantalModel.noOfFetus}}</span>
                                                            </div>
                                                            <div class="pl-2" *ngIf="postnantalModel.noOfFetus =='1' || postnantalModel.noOfFetus =='2' || postnantalModel.noOfFetus =='3' ||postnantalModel.noOfFetus =='4'">
                                                                <h5>First Baby Details</h5>
                                                            </div>
                                                            <div class="d-flex justify-content-between" *ngIf="postnantalModel.noOfFetus =='1' || postnantalModel.noOfFetus =='2' || postnantalModel.noOfFetus =='3' ||postnantalModel.noOfFetus =='4'">
                                                                <div class="col-4">
                                                                    <span class="font-weight-bold"> Sex  :&nbsp;</span><span class="d-inline-block" [textContent]="postnantalModel.sexOfTheBaby"></span>
                                                                </div>
                                                                <div class="col-4">
                                                                    <span class="font-weight-bold">Birth Weight :&nbsp;</span><span class="d-inline-block" [textContent]="postnantalModel.birthWeight"></span>
                                                                </div>
                                                                <div class="col-4">
                                                                    <span class="font-weight-bold"> Baby Status  :&nbsp;</span><span class="d-inline-block" [textContent]="postnantalModel.babyStatus"></span>
                                                                </div>

                                                            </div>
                                                            <div class="pl-2" *ngIf="postnantalModel.noOfFetus =='2' ||postnantalModel.noOfFetus =='3' ||postnantalModel.noOfFetus =='4'">
                                                                <h5>Second Baby Details</h5>
                                                            </div>
                                                            <div class="d-flex justify-content-between" *ngIf="postnantalModel.noOfFetus =='2' ||postnantalModel.noOfFetus =='3' ||postnantalModel.noOfFetus =='4'">
                                                                <div class="col-4">
                                                                    <span class="font-weight-bold"> Sex  :&nbsp;</span><span class="d-inline-block" [textContent]="postnantalModel.sexOfTheBabytwo"></span>
                                                                </div>
                                                                <div class="col-4">
                                                                    <span class="font-weight-bold">Birth Weight :&nbsp;</span><span class="d-inline-block" [textContent]="postnantalModel.birthWeighttwo"></span>
                                                                </div>
                                                                <div class="col-4">
                                                                    <span class="font-weight-bold"> Baby Status  :&nbsp;</span><span class="d-inline-block" [textContent]="postnantalModel.babyStatustwo"></span>
                                                                </div>
                                                            </div>
                                                            <div class="pl-2" *ngIf="postnantalModel.noOfFetus =='3' || postnantalModel.noOfFetus =='4' ">
                                                                <h5>Third Baby Details</h5>
                                                            </div>
                                                            <div class="d-flex justify-content-between" *ngIf="postnantalModel.noOfFetus =='3' || postnantalModel.noOfFetus =='4' ">

                                                                <div class="col-4">

                                                                    <span class="font-weight-bold"> Sex  :&nbsp;</span><span class="d-inline-block" [textContent]="postnantalModel.sexOfTheBabythree"></span>
                                                                </div>
                                                                <div class="col-4">
                                                                    <span class="font-weight-bold">Birth Weight :&nbsp;</span><span class="d-inline-block" [textContent]="postnantalModel.birthWeightthree"></span>
                                                                </div>
                                                                <div class="col-4">
                                                                    <span class="font-weight-bold"> Baby Status  :&nbsp;</span><span class="d-inline-block" [textContent]="postnantalModel.babyStatusthree"></span>
                                                                </div>
                                                            </div>
                                                            <div class="pl-2" *ngIf="postnantalModel.noOfFetus =='4'">
                                                                <h5>Fourth Baby Details</h5>
                                                            </div>
                                                            <div class="d-flex justify-content-between" *ngIf="postnantalModel.noOfFetus =='4'">

                                                                <div class="col-4">

                                                                    <span class="font-weight-bold"> Sex  :&nbsp;</span><span class="d-inline-block" [textContent]="postnantalModel.sexOfTheBabyfour"></span>
                                                                </div>
                                                                <div class="col-4">
                                                                    <span class="font-weight-bold">Birth Weight :&nbsp;</span><span class="d-inline-block" [textContent]="postnantalModel.birthWeightfour"></span>
                                                                </div>
                                                                <div class="col-4">
                                                                    <span class="font-weight-bold"> Baby Status  :&nbsp;</span><span class="d-inline-block" [textContent]="postnantalModel.babyStatusfour"></span>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex justify-content-between">
                                                                <div class="col-3">
                                                                    <span class="font-weight-bold"> Lactating  :&nbsp;</span><span class="d-inline-block" [textContent]="postnantalModel.lactating"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 p-0 pt-1">
                                                            <div class="pl-0 d-flex">
                                                                <div class="d-flex justify-content-between pl-0 min-width180px">
                                                                    <span>Antenatal Concerns</span>&nbsp; :&nbsp;
                                                                </div>
                                                                <div class="">
                                                                    <span style=" white-space: normal; word-break: break-all;" [textContent]="postnantalModel.antenatal"> </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 p-0">
                                                            <div class="pl-0 d-flex">
                                                                <div class="d-flex justify-content-between pl-0 min-width180px">
                                                                    <span>Intrapartum concerns</span>&nbsp; :&nbsp;
                                                                </div>
                                                                <div class=" ">
                                                                    <span style=" white-space: normal; word-break: break-all;" [textContent]="postnantalModel.intrapartum"> </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 p-0">
                                                            <div class="pl-0 d-flex">
                                                                <div class="d-flex justify-content-between pl-0 min-width180px">
                                                                    <span>Postpartum concerns</span>&nbsp; :&nbsp;
                                                                </div>
                                                                <div class="">
                                                                    <span style=" white-space: normal; word-break: break-all;" [textContent]="postnantalModel.postpartum"> </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 p-0">
                                                            <div class="pl-0 d-flex">
                                                                <div class="d-flex justify-content-between min-width180px pl-0">
                                                                    <h5>Complaint Details</h5>&nbsp; :&nbsp;
                                                                </div>
                                                                <div>
                                                                    <span [textContent]="postnantalModel.complains"> </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mt-2 col-12 p-0">
                                                            <h5>Examination Findings</h5>
                                                            <div class="w-100 d-flex flex-wrap">
                                                                <div class="col-12 col-sm-9 pl-0">
                                                                    <table class="w-100" border="1px solid black">
                                                                        <thead>
                                                                            <tr>
                                                                                <th><span>Temperature</span></th>
                                                                                <th><span> Pulse (/Min)</span> </th>
                                                                                <th><span>BP (mm Hg)</span></th>
                                                                                <th><span>DBP (mm Hg)</span></th>
                                                                                <th><span>Weight (Kg)</span></th>
                                                                                <th><span>BMI</span></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td><span [textContent]="postnantalModel.temperature"></span></td>
                                                                                <td><span [textContent]="postnantalModel.pulseRate"></span></td>
                                                                                <td><span [textContent]="postnantalModel.systolic"></span></td>
                                                                                <td><span [textContent]="postnantalModel.diastolic"></span></td>
                                                                                <td><span [textContent]="postnantalModel.weight"></span></td>
                                                                                <td><span [textContent]="postnantalModel.bMI"></span></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <div class="col-12 p-0">
                                                                        <div class="col-12 pl-0 d-flex">
                                                                            <div class="d-flex justify-content-between min-width180px pl-0">
                                                                                <span>Pallor</span>&nbsp; :&nbsp;
                                                                            </div>
                                                                            <div>
                                                                                <span [textContent]="postnantalModel.pallor"> </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 p-0">
                                                                        <div class="col-12 pl-0 d-flex">
                                                                            <div class="d-flex justify-content-between min-width180px pl-0">
                                                                                <span>Abdominal Incision</span>&nbsp; :&nbsp;
                                                                            </div>
                                                                            <div>
                                                                                <span [textContent]="postnantalModel.abdominal"> </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 p-0">
                                                                        <div class="pl-0 d-flex">
                                                                            <div class="d-flex justify-content-between min-width180px pl-0">
                                                                                <span>Perineal Wound</span>&nbsp; :&nbsp;
                                                                            </div>
                                                                            <div>
                                                                                <span [textContent]="postnantalModel.perineal"> </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 p-0">
                                                                        <div class="pl-0 d-flex">
                                                                            <div class="d-flex justify-content-between min-width180px pl-0">
                                                                                <span>Per Speculam</span>&nbsp; :&nbsp;
                                                                            </div>
                                                                            <div>
                                                                                <span [textContent]="postnantalModel.perSpeculam"> </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 p-0">
                                                                        <div class="pl-0 d-flex">
                                                                            <div class="d-flex justify-content-between min-width180px pl-0">
                                                                                <span>Per Vaginum</span>&nbsp; :&nbsp;
                                                                            </div>
                                                                            <div>
                                                                                <span [textContent]="postnantalModel.perVaginum"> </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 p-0">
                                                                        <div class="pl-0 d-flex">
                                                                            <div class="d-flex justify-content-between min-width180px pl-0">
                                                                                <span>Per Rectal</span>&nbsp; :&nbsp;
                                                                            </div>
                                                                            <div>
                                                                                <span [textContent]="postnantalModel.perRectal"> </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 p-0">
                                                                        <div class="pl-0 d-flex">
                                                                            <div class="d-flex justify-content-between min-width180px pl-0">
                                                                                <span>Suture Removal</span>&nbsp; :&nbsp;
                                                                            </div>
                                                                            <div>
                                                                                <span [textContent]="postnantalModel.sutureRemoval"> </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 p-0">
                                                                        <div class="pl-0 d-flex">
                                                                            <div class="d-flex justify-content-between min-width180px pl-0">
                                                                                <span>Important Advice</span>&nbsp; :&nbsp;
                                                                            </div>
                                                                            <div>
                                                                                <span style=" white-space: normal; word-break: break-all;" [textContent]="postnantalModel.importantAdvice"> </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-3 pl-0" *ngIf="postnantalModel.antenatalRiskFactor">
                                                                    <div class="d-flex justify-content-between min-width180px pl-0">
                                                                        <h5>Antenatal Risk Factors</h5>
                                                                    </div>
                                                                    <div>
                                                                        <span>
                                                                            <span class="white-space-nowrap" [textContent]="postnantalModel.antenatalRiskFactor"></span>&nbsp;,
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="border-dark mt-2 py-1 border-top">
                                                            <div class="pl-0 d-flex">
                                                                <div class="d-flex justify-content-between min-width180px pl-0">
                                                                    <h5>Medication And Advice</h5>&nbsp; :&nbsp;
                                                                </div>
                                                                <div>
                                                                    <span [textContent]="postnantalModel.medicationAndAdvice"> </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="col-12 pl-0 d-flex">
                                                                <div class="d-flex justify-content-between min-width180px">
                                                                    <h5>Postnatal Exercises</h5>&nbsp; :&nbsp;
                                                                </div>
                                                                <div>
                                                                    <span [textContent]="postnantalModel.postnantalExercise"> </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="col-12 pl-0 d-flex">
                                                                <div class="d-flex justify-content-between min-width180px">
                                                                    <span>Contraception</span>&nbsp; :&nbsp;
                                                                </div>
                                                                <div>
                                                                    <span style=" white-space: normal; word-break: break-all;" [textContent]="postnantalModel.contraception"> </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="col-12 pl-0 d-flex">
                                                                <div class="d-flex justify-content-between min-width180px">
                                                                    <span>Date</span>&nbsp; :&nbsp;
                                                                </div>
                                                                <div>
                                                                    <span [textContent]="postnantalModel.date | date:'dd/MM/yyyy'"> </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="col-12 pl-0 d-flex">
                                                                <div class="d-flex justify-content-between min-width180px">
                                                                    <span>Next Review Date</span>&nbsp; :&nbsp;
                                                                </div>
                                                                <div>
                                                                    <span [textContent]="postnantalModel.nextReviewDate | date:'dd/MM/yyyy'"> </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="col-12 pl-0 d-flex">
                                                                <div class="d-flex justify-content-between min-width180px">
                                                                    <span>Seen By Dr</span>&nbsp; :&nbsp;
                                                                </div>
                                                                <div>
                                                                    <span class="text-uppercase" [textContent]="postnantalModel.seenByDr"> </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mt-2 border-dark border-top">
                                                            <h5>Prescription</h5>
                                                            <div *ngIf="records && records.length !== 0">
                                                                <div class="dashboard-body">
                                                                    <div *ngFor="let record of records;">
                                                                        <div class="overflow-auto mt-1 mb-1">
                                                                            <table class="w-100" style="border-collapse: collapse;">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th><h5>Medications Adviced</h5> </th>
                                                                                        <th><h5>Generic Name</h5></th>
                                                                                        <th><h5>Dosage</h5></th>
                                                                                        <th><h5>Category</h5></th>
                                                                                        <th><h5>Qty</h5></th>
                                                                                        <th><h5>Remarks</h5></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr *ngFor="let item of record.medicines; let i = index">
                                                                                        <td class="min-width200px">
                                                                                            <div>
                                                                                                <div class=" text-wrap text-uppercase" [textContent]="item.productName"></div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="min-width200px">
                                                                                            <div>
                                                                                                <span class=" text-wrap text-lowercase" *ngIf="item.genericName" [textContent]="item.genericName"></span>
                                                                                                <span class=" text-wrap text-lowercase" *ngIf="!item.genericName" [textContent]="'--'"></span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div>
                                                                                                <span class="text-lowercase" *ngIf="item.instruction" [textContent]="item.instruction"></span>

                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div>
                                                                                                <span class="text-capitalize" *ngIf="item.instruction" [textContent]="item.categoryName"></span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div>
                                                                                                <span class="text-capitalize" *ngIf="item.instruction" [textContent]="item.dosage"></span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div>
                                                                                                <span class="text-capitalize" *ngIf="item.instruction" [textContent]="item.remark"></span>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mt-2" *ngIf="bookingHeader && bookingHeader.length > 0">
                                                            <h5>Investigations Ordered</h5>
                                                            <div class="text-lowercase" *ngFor="let item of bookingHeader;let i =index;">
                                                                <span class=" text-wrap" [textContent]="item.testName"></span>
                                                                (&nbsp;<span class=" text-wrap" [textContent]="item.testCode"></span>&nbsp;)
                                                            </div>
                                                        </div>
                                                        <div class="mt-2" *ngIf="ordersPrescription && ordersPrescription.orderData!=null">
                                                            <div *ngFor="let item of ordersPrescription.orderData">
                                                                <div *ngIf="ordersPrescription.orderData">
                                                                    <div>
                                                                        <div class="w-100 dashboard-break" *ngIf="item.comments && item.comments !== null">
                                                                            <h5 *ngIf="item.comments && item.comments !== null" [textContent]="item.orderName"></h5>
                                                                            <table class="table table-centered table-nowrap table-sm table-striped transcipt mb-0">
                                                                                <tr class="pl-1 vertical-align-top" [innerHTML]="item.comments">
                                                                                </tr>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mt-2 pb-1 justify-content-between d-flex flex-wrap align-items-end">
                                                            <div class="pl-0 d-flex">
                                                                <div><h5>Name :&nbsp;</h5></div>
                                                                <div><h5 class="text-uppercase" [textContent]="postnantalModel.consultedByDr"></h5></div>
                                                            </div>
                                                            <div class="pl-0 d-flex">
                                                                <div><h5>Reg No :&nbsp;</h5></div>
                                                                <div><h5 [textContent]="appointment.providerId"></h5></div>
                                                            </div>
                                                            <div class="pl-1 d-flex">
                                                                <div><h5>Email :&nbsp;</h5></div>
                                                                <div><h5 [textContent]="appointment.providerEmail"></h5></div>
                                                            </div>
                                                            <div class="pl-1  d-flex align-items-end">
                                                                <div><h5>Signature :&nbsp;</h5></div>
                                                                <div class="border-dark border-bottom"><h5><img *ngIf="appointment.signature!=null" [src]="safe(appointment.signature)" cssClass="img-fluid rounded-circle" style="height:80px" /></h5></div>

                                                            </div>

                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                            </tbody>

                            <tfoot>
                                <tr>
                                    <td>
                                        <!--place holder for the fixed-position footer-->
                                        <div class="page-footer-space"></div>
                                    </td>
                                </tr>
                            </tfoot>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
