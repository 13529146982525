import { Component, OnDestroy, OnInit, ChangeDetectorRef, NgZone } from "@angular/core";
@Component({
    templateUrl: "./labour-ward-handover.html",
    styleUrls: ["./labour-ward-handover.css"]
})

export class LabourWardHandoverPage implements OnInit, OnDestroy {
    constructor() {

    }
    ngOnInit() {
    }

    ngOnDestroy() {
    }
}
