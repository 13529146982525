export class LabTemplateDetail {
    labTemplateDetailId: number;
    labTemplateHeaderId: number;
    labComponentHeaderId?: number;
    labParameterHeaderId?: number;
    priority: number;
    componentId: string;
    componentName: string;
    parameterName: string;
    displayName: string;
    interpretationText: string;
}