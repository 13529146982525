import { Injectable } from "@angular/core";
import { Observable, Subject, forkJoin, BehaviorSubject, takeUntil, finalize } from "rxjs";
import { EncounterValue, Appointment, Admission, OphthalmologyEncounterList, Setting, AppointmentDashboard } from "@shared/entities";
import { ObEncounter } from "@shared/models/ob-encounter.model";
import { PEncounter } from "@shared/models/pediatric-encounter.model";
import { ApiResources } from "../helpers";
import { HttpService, NotifyService } from "@shared/services";
import { OB } from "@admin/full-transcript/pages/ob-report/ob.namespace";
import { GynEncounter } from "../models/gyn-encounter.model";
import { EmergencyEncounter } from "../models";
import { DietEncounter } from "../models/diet-encounter.model";
import { GYN } from "../../areas/admin/gyn-encounter/gyn.namespace";
import { PhysiotherapyEncounterList } from "../entities/physiotherapy-encounter.entity";
import { NeonatalEncounterType } from "../enums/neonatal-encounter-type.enum";
import { NurseAssessementResources } from "../models/nurse-assessment-resources";
import { NeoIPEncounter } from "../models/neonatal-ip-encounter.model";
import { GeneticSpecialityEncounterList } from "../entities/geneticSpecialityList.entity";

@Injectable()
export class EncounterCommunication {
    isAdmission: boolean;
    encounterDashboard: EncounterValue;
    ancCardDetais: OB.AncCardGeneration;
    isANC: boolean;
    gynEncounterDashboard: EncounterValue;
    pencounterDashboard: EncounterValue;
    geneticencounterDashboard: EncounterValue;
    neonatalEncounterDashboard: EncounterValue;
    emergencyEncounterDashboard: EncounterValue;
    dietEncouterDashboard: EncounterValue;
    ivfEncouterDashboard: EncounterValue;
    obEncounter: ObEncounter;
    measures: OB.Measure;
    isAncCrad: boolean;
    isGynCard: boolean;
    gynEncounters: GynEncounter;
    gynCardDetails: GYN.GynCard;
    appointmentDetail: Appointment;
    appointment: AppointmentDashboard;
    admissionDetail: Admission;
    ophthalmologyEncounterDashboard: EncounterValue;
    loadingSettings: boolean;
    constructor(

        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
    ) { }
    private encounterSource = new Subject();
    private gynEncounterSource = new Subject();
    private pencounterSource = new Subject();
    private emergencyEncounterSource = new Subject();
    private dietEncounterSource = new Subject();
    private geneticEncounterSource = new Subject();
    private physionTherapySource = new Subject();
    private neonatalipEncounterSource = new Subject();
    private ivfEncounterSource = new Subject();
    private ophthalmologyEncounterSource = new Subject();
    private appointmentDetailSource = new Subject();

     orderPrescirptionSource = new BehaviorSubject<boolean>(false);

    encounter: Observable<any> = this.encounterSource.asObservable();
    gynEncounter: Observable<any> = this.gynEncounterSource.asObservable();
    pEncounter: Observable<any> = this.pencounterSource.asObservable();
    emergencyEncounter: Observable<any> = this.emergencyEncounterSource.asObservable();
    dietEncounter: Observable<any> = this.dietEncounterSource.asObservable();
    geneticEncounter: Observable<any> = this.geneticEncounterSource.asObservable();
    physionTherapyEncounter: Observable<any> = this.physionTherapySource.asObservable();
    neonatalipEncounter: Observable<any> = this.neonatalipEncounterSource.asObservable();
    ivfEncounter: Observable<any> = this.ivfEncounterSource.asObservable();
    orderPrescriptionObserv: Observable<any> = this.orderPrescirptionSource.asObservable();
    ophthalmologyEncounterobj: Observable<any> = this.ophthalmologyEncounterSource.asObservable();
    appointmentFecthDetail: Observable<any> = this.appointmentDetailSource.asObservable();
    
    setEncounter(value: EncounterValue) {
        if (value.isSubmitandNext) {
            const request = { encryptedAppointmentId: value.encryptedAppointmentId };
            request["isAdmission"] = value.isAdmission;
            this.httpService
                .post<[ObEncounter]>(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.findEncounterData), request)
                .subscribe((response: [ObEncounter]) => {
                    this.encounterDashboard = new EncounterValue();
                    this.encounterDashboard.value = response["dashboard"];
                    this.encounterSource.next(this.encounterDashboard);
                });

        }
        else {
            value.isSubmitandNext = false;
            this.encounterSource.next(value);
        }

    }



    findAppointmentDetail(appointmentId: string, isAdmission?: boolean) {
        const request = {
            encryptedAppointmentId: appointmentId
        };
        request["isAdmission"] = isAdmission != null ? isAdmission : this.isAdmission;
        this.httpService.post<AppointmentDashboard>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .subscribe((response: AppointmentDashboard) => {
                if (response) {
                    this.appointment = response;
                    this.appointmentDetailSource.next(response);
                }
            });
    }

    getSettingsData(unsubscribe$): Observable<Array<Setting>> {
        this.loadingSettings = true;
        return this.httpService.get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch))
            .pipe(
                takeUntil(unsubscribe$),
                finalize(() => this.loadingSettings = false)
            );
    }
    gynSetEncounter(value: EncounterValue) {
        if (value.isSubmitandNext) {
            const request = { encryptedAppointmentId: value.encryptedAppointmentId };
            request["isAdmission"] = value.isAdmission;
            this.httpService
                .post<[GynEncounter]>(ApiResources.getURI(ApiResources.gynEncounter.base, ApiResources.gynEncounter.findData), request)
                .subscribe((response: [GynEncounter]) => {
                    this.gynEncounterDashboard = new EncounterValue();
                    this.gynEncounterDashboard.value = response["dashboard"];
                    this.gynEncounterSource.next(this.gynEncounterDashboard);
                });
        }
        else {
            value.isSubmitandNext = false;
            this.gynEncounterSource.next(value);
        }

    }

    dietSetEncounter(value: EncounterValue) {
        if (value.isSubmitandNext) {
            const request = { encryptedAppointmentId: value.encryptedAppointmentId };
            request["isAdmission"] = value.isAdmission;
            this.httpService
                .post<[DietEncounter]>(ApiResources.getURI(ApiResources.dietEncounter.base, ApiResources.dietEncounter.find), request)
                .subscribe((response: [DietEncounter]) => {
                    this.dietEncouterDashboard = new EncounterValue();
                    this.dietEncouterDashboard.value = response["dashboard"];
                    this.dietEncounterSource.next(this.dietEncouterDashboard);
                });
        }
        else {
            value.isSubmitandNext = false;
            this.dietEncounterSource.next(value);
        }

    }

    psetEncounter(value: EncounterValue) {
        if (value.isSubmitandNext) {
            const request = { encryptedAppointmentId: value.encryptedAppointmentId };
            request["isAdmission"] = value.isAdmission;
            this.httpService
                .post<[PEncounter]>(ApiResources.getURI(ApiResources.pediatricEncounter.base, ApiResources.pediatricEncounter.findData), request)
                .subscribe((response: [PEncounter]) => {
                    this.pencounterDashboard = new EncounterValue();
                    this.pencounterDashboard.value = response["dashboard"];
                    this.pencounterSource.next(this.pencounterDashboard);
                });
        }
        else {
            value.isSubmitandNext = false;
            this.pencounterSource.next(value);
        }
    }

    geneticspecialityEncounter(value: EncounterValue) {
        if (value.isSubmitandNext) {
            const request = { encryptedAppointmentId: value.encryptedAppointmentId };
            request["isAdmission"] = value.isAdmission;
            this.httpService
                .post<[GeneticSpecialityEncounterList]>(ApiResources.getURI(ApiResources.geneticSpecialityEncounter.base, ApiResources.geneticSpecialityEncounter.findDashboard), request)
                .subscribe((response: [GeneticSpecialityEncounterList]) => {
                    this.geneticencounterDashboard = new EncounterValue();
                    this.geneticencounterDashboard.value = response;
                    this.geneticEncounterSource.next(this.geneticencounterDashboard);
                });

        }
        else {
            value.isSubmitandNext = false;
            this.geneticEncounterSource.next(value);
        }

    }

    ophthamologyCommunication(value: EncounterValue) {
        if (value.isSubmitandNext) {
            const request = { encryptedAppointmentId: value.encryptedAppointmentId };
            request["isAdmission"] = value.isAdmission;
            this.httpService
                .post<[OphthalmologyEncounterList]>(ApiResources.getURI(ApiResources.ophthalmologyEncounter.base, ApiResources.ophthalmologyEncounter.findDashboard), request)
                .subscribe((response: [OphthalmologyEncounterList]) => {
                    this.ophthalmologyEncounterDashboard = new EncounterValue();
                    this.ophthalmologyEncounterDashboard.value = response;
                    this.ophthalmologyEncounterSource.next(this.ophthalmologyEncounterDashboard);
                });

        }
        else {
            value.isSubmitandNext = false;
            this.ophthalmologyEncounterSource.next(value);
        }

    }

    ivfEncounterCommunication(value: EncounterValue) {
        if (value.isSubmitandNext) {
            const request = { encryptedAppointmentId: value.encryptedAppointmentId };
            request["isAdmission"] = value.isAdmission;
            this.httpService
                .post<[OphthalmologyEncounterList]>(ApiResources.getURI(ApiResources.ivfEncounter.base, ApiResources.ivfEncounter.find), request)
                .subscribe((response: [OphthalmologyEncounterList]) => {
                    this.ivfEncouterDashboard = new EncounterValue();
                    this.ivfEncouterDashboard.value = response["dashboard"];
                    this.ivfEncounterSource.next(this.ivfEncouterDashboard);
                });

        }
        else {
            value.isSubmitandNext = false;
            this.ivfEncounterSource.next(value);
        }

    }

    internalmeditionCommunication(value: EncounterValue) {
        if (value.isSubmitandNext) {
            const request = { encryptedAppointmentId: value.encryptedAppointmentId };
            request["isAdmission"] = value.isAdmission;
            this.httpService
                .post<[OphthalmologyEncounterList]>(ApiResources.getURI(ApiResources.encounters.base, ApiResources.encounters.find), request)
                .subscribe((response: [OphthalmologyEncounterList]) => {
                    this.ivfEncouterDashboard = new EncounterValue();
                    this.ivfEncouterDashboard.value = response["dashboard"];
                    this.ivfEncounterSource.next(this.ivfEncouterDashboard);
                });

        }
        else {
            value.isSubmitandNext = false;
            this.ivfEncounterSource.next(value);
        }

    }
    neonatalsetEncounter(value: EncounterValue) {
        if (value.isSubmitandNext) {
            const request = { encryptedAppointmentId: value.encryptedAppointmentId };
            request["isAdmission"] = value.isAdmission;
            this.httpService
                .post<[NeonatalEncounterType]>(ApiResources.getURI(ApiResources.neonatalEncounter.base, ApiResources.neonatalEncounter.findData), request)
                .subscribe((response: [NeonatalEncounterType]) => {
                    this.neonatalEncounterDashboard = new EncounterValue();
                    this.neonatalEncounterDashboard.value = response["dashboard"];
                    this.neonatalipEncounterSource.next(this.neonatalEncounterDashboard);
                });

        }
        else {
            value.isSubmitandNext = false;
            this.neonatalipEncounterSource.next(value);
        }   
    }
    physioTherapyEncounter(value: EncounterValue) {
        if (value.isSubmitandNext) {
            const request = { encryptedAppointmentId: value.encryptedAppointmentId };
            request["isAdmission"] = value.isAdmission;
            this.httpService
                .post<[PhysiotherapyEncounterList]>(ApiResources.getURI(ApiResources.physiotherapyEncounter.base, ApiResources.physiotherapyEncounter.fetch), request)
                .subscribe((response: [PhysiotherapyEncounterList]) => {
                    this.encounterDashboard = new EncounterValue();
                    this.encounterDashboard.value = response;
                    this.physionTherapySource.next(this.encounterDashboard);
                });

        }
        else {
            value.isSubmitandNext = false;
            this.physionTherapySource.next(value);
        }

    }
    ophthalmologyEncounter(value: EncounterValue) {
        if (value.isSubmitandNext) {
            const request = { encryptedAppointmentId: value.encryptedAppointmentId };
            request["isAdmission"] = value.isAdmission;
            this.httpService
                .post<[GeneticSpecialityEncounterList]>(ApiResources.getURI(ApiResources.ophthalmologyEncounter.base, ApiResources.ophthalmologyEncounter.findDashboard), request)
                .subscribe((response: [GeneticSpecialityEncounterList]) => {
                    this.ophthalmologyEncounterDashboard = new EncounterValue();
                    this.ophthalmologyEncounterDashboard.value = response;
                    this.ophthalmologyEncounterSource.next(this.geneticencounterDashboard);
                });

        }
        else {
            value.isSubmitandNext = false;
            this.ophthalmologyEncounterSource.next(value);
        }

    }
  
    private encounterComplete = new Subject();
    encounterComplets: Observable<any> = this.encounterComplete.asObservable();
    modifyEncounterStatus(appointmentId: number, isAdmission?:boolean) {
        this.httpService
            .get<[ObEncounter]>(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.completedEncounter), { appointmentId: appointmentId, isAdmission:isAdmission })
            .subscribe((response: [any]) => {
                this.encounterComplete.next(true);
                $("#printButton").click();
            });
    }
    private ophthalmologyencounterComplete = new Subject();
    ophthalmologyencounterCompletes: Observable<any> = this.ophthalmologyencounterComplete.asObservable();
    modifyophthalmologyEncounterStatus(appointmentId?: number, isAdmission?: boolean) {
        this.httpService
            .get<[OphthalmologyEncounterList]>(ApiResources.getURI(ApiResources.ophthalmologyEncounter.base, ApiResources.ophthalmologyEncounter.completedEncounter), { appointmentId: appointmentId, isAdmission: isAdmission })
            .subscribe((response: [any]) => {
                this.ophthalmologyencounterComplete.next(true);
                $("#printButton").click();
            });
    }
    private gynEncounterComplete = new Subject();
    gynEncounterComplets: Observable<any> = this.gynEncounterComplete.asObservable();
    modifyGynEncounterStatus(appointmentId: number) {
        this.httpService
            .get<[GynEncounter]>(ApiResources.getURI(ApiResources.gynEncounter.base, ApiResources.gynEncounter.encounterComplete), { appointmentId: appointmentId })
            .subscribe((response: [any]) => {
                this.gynEncounterComplete.next(true);
                $("#printButton").click();
                
            });
    }

    private pencounterComplete = new Subject();
    pencounterComplets: Observable<any> = this.pencounterComplete.asObservable();
    modifyPEncounterStatus(appointmentId: number) {
        this.httpService
            .get<[PEncounter]>(ApiResources.getURI(ApiResources.pediatricEncounter.base, ApiResources.pediatricEncounter.encounterCompleted), { appointmentId: appointmentId })
            .subscribe((response: [any]) => {
                this.pencounterComplete.next(true);
                $("#printButton").click();
            });
    }

    private dietencounterComplete = new Subject();
    dietencounterCompletes: Observable<any> = this.dietencounterComplete.asObservable();
    modifyDietEncounterStatus(appointmentId: number) {
        this.httpService
            .get<[ObEncounter]>(ApiResources.getURI(ApiResources.dietEncounter.base, ApiResources.dietEncounter.completedEncounter), { appointmentId: appointmentId })
            .subscribe((response: [any]) => {
                this.dietencounterComplete.next(true);
                $("#printButton").click();
            });
    }


    private physiotherapyencounterComplete = new Subject();
    physiotherapyencounterCompletes: Observable<any> = this.physiotherapyencounterComplete.asObservable();
    modifyPhysiotherapyEncounterStatus(appointmentId: number) {
        this.httpService
            .get<[PhysiotherapyEncounterList]>(ApiResources.getURI(ApiResources.physiotherapyEncounter.base, ApiResources.physiotherapyEncounter.completedEncounter), { appointmentId: appointmentId })
            .subscribe((response: [any]) => {
                this.physiotherapyencounterComplete.next(true);
                $("#printButton").click();
            });
    }
    private geneticEncounterComplete = new Subject();
    geneticencounterComplets: Observable<any> = this.geneticEncounterComplete.asObservable();
    modifyGeneticEncounterStatus(appointmentId: number) {
        this.httpService
            .get<[GeneticSpecialityEncounterList]>(ApiResources.getURI(ApiResources.geneticSpecialityEncounter.base, ApiResources.geneticSpecialityEncounter.completedEncounter), { appointmentId: appointmentId })
            .subscribe((response: [any]) => {
                this.geneticEncounterComplete.next(true);
                $("#printButton").click();
            });
    }
  
    findANCCard(patientId: number, appointmentId: number, encounterType: string) {
        if (encounterType === 'ob-encounter') {
            const request = {
                patientId: patientId,
                appointmentId: appointmentId,
                isAdmission :this.isAdmission
            }
            this.httpService.get(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchANC), request)
                .subscribe((response: OB.AncCardGeneration) => {
                    if (response && response.ancNo == null) {
                        this.notifyService.warning("Please Generate ANC Card");
                    }
                    this.ancCardDetais = response;
                    if (this.ancCardDetais && this.ancCardDetais.ancNo == null) {
                        return true;
                    }
                    else {
                        return false
                    }
                });
        }
        if (encounterType === 'gyn-encounter') {
            const request = {
                patientId: patientId,
                appointmentId: appointmentId
            }
            this.httpService.get(ApiResources.getURI(ApiResources.gynEncounter.base, ApiResources.gynEncounter.fetchGynCard), request)
                .subscribe((response: GYN.GynCard) => {
                    if (response == null) {
                   
                            this.isGynCard = true;
                            this.notifyService.warning("Please Generate gyn Card");
                            return true;

                    }
                       
                    else {
                        this.gynCardDetails = response;
                        
                            this.isGynCard = false;
                            return false
                        }                 
                   

                });
        }
        return this.isGynCard;
        
    

        
    }

    findAppointment(appointmentId: string, type: string, isAdmission?: boolean) {
        const request = {
            encryptedAppointmentId: appointmentId
        };
        request["isAdmission"] = isAdmission != null ? isAdmission : this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .subscribe((response: Appointment) => {
                if (response) {
                    this.appointmentDetail = response;
                   
                    if (type === 'ob-encounter') {
                        this.checkAncCondition(response.patientId, response.appointmentId, appointmentId, false);
                    }
                    else {
                        this.isANC = this.findANCCard(response.patientId, response.appointmentId, type);
                    }
                    
                }
            });
        return this.isANC;
    }
    findAdmission(appointmentId: string, type: string) {
        const request = {
            encryptedAdmissionId: appointmentId
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.get(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.find), request)
            .subscribe((response: Admission) => {
                if (response) {
                    this.admissionDetail = response;
                    if (type === 'ob-encounter' || type === 'ob-ip-encounter') {
                        this.checkAncCondition(response.patientId, response.admissionId, appointmentId, true);
                    }
                    if (type === 'gyn-encounter' || type === 'gyn-ip-encounter') {
                        this.checkGynCardCondition(response.patientId, response.admissionId);
                    }
                }
            });
        return this.isANC;
    }

    checkAncCondition(patientId: number, appointmentId: number, encryptedAppointmentId?: string, isAdmission?: boolean) {
        const request = { patientId: patientId, appointmentId: appointmentId, isAdmission: isAdmission }
            let ancCard = this.httpService.get(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchANC), request)
        let dashboard = this.httpService.post<[ObEncounter]>(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.findEncounterData), { encryptedAppointmentId: encryptedAppointmentId })
            forkJoin([ancCard, dashboard])
                .subscribe((response: Array<any>) => {
                    this.ancCardDetais = response[0] as OB.AncCardGeneration;
                    var values = response[1] as ObEncounter;
                    this.obEncounter = values["dashboard"];
                    if (this.obEncounter && this.obEncounter.generalExamination) {
                        this.measures = JSON.parse(this.obEncounter.generalExamination);
                        if (this.measures.department == "Pre Pregnancy counselling" || this.measures.department == "Postnatal") {
                            this.isAncCrad = false;
                        }
                        else if (this.ancCardDetais && this.ancCardDetais.ancNo == null) {
                            this.notifyService.warning("Please generate anc card");
                            this.isAncCrad = true;
                        }
                        else if (this.ancCardDetais == null) {
                            this.notifyService.warning("Please generate anc card");
                            this.isAncCrad = true;
                        }
                        else {
                            this.isAncCrad = false;
                        }
                    }
                    else if (this.ancCardDetais == null) {
                        this.notifyService.warning("Please generate anc card");
                        this.isAncCrad = true;
                    }
                    else if (this.ancCardDetais && this.ancCardDetais.ancNo == null) {
                        this.notifyService.warning("Please generate anc card");
                        this.isAncCrad = true;
                    }
                    else {
                        this.isAncCrad = false;
                    }
                });
                
    }

    checkGynCardCondition(patientId: number, appointmentId: number) {
        const request = {
            patientId: patientId,
            appointmentId: appointmentId
        }
        this.httpService.get(ApiResources.getURI(ApiResources.gynEncounter.base, ApiResources.gynEncounter.fetchGynCard), request)
            .subscribe((response: GYN.GynCard) => {
                this.gynCardDetails = response;
                if (response == null) {
                    this.isGynCard = true;
                    this.notifyService.warning("Please Generate gyn Card");
                    return;
                }
                else {
                    this.isGynCard = false;
                }
            });                    
                    //if (this.gynCardDetails && this.gynCardDetails.gynNo == null) {
                    //    this.notifyService.warning("Please generate gyn card");
                    //    this.isGynCard = true;
                    //    return;
                    //}
                    //else if (this.gynCardDetails == null) {
                    //    this.notifyService.warning("Please generate gyn card");
                    //    this.isGynCard = true;
                    //    return;
                    //}
                    //else {
                    //    this.isGynCard = false;
                    //}
                }
    emergencysetEncounter(value: EncounterValue) {
        if (value.isSubmitandNext) {
            const request = { encryptedAppointmentId: value.encryptedAppointmentId };
            request["isAdmission"] = value.isAdmission;
            this.httpService
                .post<[EmergencyEncounter]>(ApiResources.getURI(ApiResources.emergencyEncounter.base, ApiResources.emergencyEncounter.find), request)
                .subscribe((response: [EmergencyEncounter]) => {
                    this.emergencyEncounterDashboard = new EncounterValue();
                    this.emergencyEncounterDashboard.value = response["dashboard"];
                    this.emergencyEncounterSource.next(this.emergencyEncounterDashboard);
                });

        }
        else {
            this.emergencyEncounterSource.next(value);
        }

    }

    neonatalSetEncounter(value: EncounterValue) {
        if (value.isSubmitandNext) {
            const request = { encryptedAppointmentId: value.encryptedAppointmentId };
            request["isAdmission"] = value.isAdmission;
            this.httpService
                .post<[NeoIPEncounter]>(ApiResources.getURI(ApiResources.neonatalEncounter.base, ApiResources.neonatalEncounter.findDashboard), request)
                .subscribe((response: [NeoIPEncounter]) => {
                    this.encounterDashboard = new EncounterValue();
                    this.encounterDashboard.value = response;
                    this.neonatalipEncounterSource.next(this.encounterDashboard);
                });

        }
        else {
            value.isSubmitandNext = false;
            this.neonatalipEncounterSource.next(value);
        }

    }

    private nurseAssessmentSource = new Subject();
    nurseForms: Observable<any> = this.nurseAssessmentSource.asObservable();
    nurseAssessment(value: NurseAssessementResources) {
        const request = { encryptedAdmissionId: value.encryptedAdmissionId };
        this.httpService
            .post < Array<NurseAssessementResources>>(ApiResources.getURI(ApiResources.nurseAssessmentForms.base, ApiResources.nurseAssessmentForms.findAll), request)
            .subscribe((response: Array<NurseAssessementResources>) => {
                var records = response;
                this.nurseAssessmentSource.next(records);
                });
    }


    orderPrescriptionCheck(isCheck: boolean) {
        this.orderPrescirptionSource.next(isCheck);
    }
}