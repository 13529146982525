import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Subject } from "rxjs";

@Injectable()
export class SharedService {
    private _listners = new Subject<any>();
    private stage: string =null;
    private OTRegisterId: number = null;

    listen(): Observable<any> {
        return this._listners.asObservable();
    }

    filter(filterBy: string) {
        this._listners.next(filterBy);
    }

    setStageData(stage: string, OTRegisterId: number): void {
        this.stage = stage;
        this.OTRegisterId = OTRegisterId;
    }

    getStageData(): { stage: string; OTRegisterId: number } {
        return { stage: this.stage, OTRegisterId: this.OTRegisterId };
    }
}

