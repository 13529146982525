/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable no-prototype-builtins */
import { OnInit, Component, TemplateRef, ViewChild, Output, EventEmitter, Input, OnDestroy, HostListener } from "@angular/core";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, FormControl, FormArray, AbstractControl } from "@angular/forms";
import { ApiResources, UtilHelper } from "../../../../../shared/helpers";
import { finalize, takeUntil } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { NotifyService, HttpService, AppData } from "../../../../../shared/services";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { Page, IUserAccount } from "../../../../../shared/models";
import { QuestionControlService } from "../../../../../shared/services/formCreator.service";
import { Dates } from "../../../progress-report/shared/helper";
import { Location } from "@angular/common";
import Swal from "sweetalert2";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Observable, forkJoin, of } from "rxjs";
import ts from "typescript";



@Component({
    selector:"section-render",
    templateUrl: "./sectionrender.html",
    providers: [QuestionControlService]

})
export class SectionRenderPage implements OnInit, OnDestroy {
    @ViewChild("tagMasterpop", { static: true }) tagMasterpop: TemplateRef<any>;
    @Output() selectionChange = new EventEmitter<{ action: string }>();
    @Output() optionSelected = new EventEmitter<any>();
    @Output() closeModalAppointment = new EventEmitter<any>();
    @Input() optionchange: any;
    @Input() patientId: any;
    @Input() sectionId: any;
    @Input() templateid: any;
    @Input() TemplateIDs: any;
    @Input() selsectionname: any;
    @Input() appointmentDate: any;
    @Input() appointmentId: any
    @Input() Sections: any;
    @Input() outsideEncounter: boolean;
    @Input() encounterType: number;
    @Input() encounterModeType: number;
    @Input() sectionType: any;
    @Output() dataReady = new EventEmitter<void>(); 
    visibleform: boolean
    modalRef: NgbModalRef;
    page: Page;

    location: Location
    dates: Dates;
    submitting: boolean;
    submitted: boolean;
    addTemplate: FormGroup;
    container = [];
    selectTag: any;
    sendChildValue: Array<any>;
    dropdownData: any;
    selectedOptions = [];
    dropdownselVal: any;
    dropdownselVal2: boolean = false;
    flowSheetControls = [];
    sectionControls = [];
    selectedObjects: any[] = [];
    render: FormGroup;
    questions: any;
    po: any[] = [];
    editControlsForm: FormGroup;
    selectedDate: Date;
    tableData: any[] = [];
    clonedArray: any[] = [];
    myformArray: FormArray;
    form: FormGroup;
    displayedHead: string[] = [];
    displayedFields: string[] = ['name', 'surname'];
    Today_values: any;
    CountValue: any;
    dropdownCount: any;
    selectedCheckboxValues: any[] = [];
    Total: any[] = [];
    calLabel: any;
    formula: any[] = [];
    Tscore: any[] = [];
    calculationFields: { label: string, formula: string }[] = [];
    // calculateValue: any;
    lookupvalueGet: any;
    bindLookupValues: any[] = [];
    lookupData: any;
    modalRef1: NgbModalRef;
    previousLookup: any;
    selectedQuestionLabel: any;
    sendlookupID: any;
    sendlookupselctedValue: any;
    masterTags: any[] = [];
    sectionContainer: any;
    isflowCommonTagData: any[] = [];
    isflowEtoEData: any[] = [];

    sectionDataValue1 = [
        {
            "tagId": 504,
            "controlType": "textarea",
            "label": "SeRaNa100",
            "value": "fsfsdf900"
        },
        {
            "tagId": 505,
            "controlType": "text",
            "label": "testSeRaNa",
            "value": "dsfsdfsd123"
        },
        {
            "tagId": 506,
            "controlType": "checkbox",
            "label": "test new tag4",
            "value": [
                "Neonatal Scan",
                "Pregnancy",
                "Gyn"
            ]
        },
        {
            "tagId": 507,
            "controlType": "multiselect",
            "label": "check8",
            "value": [
                "Neonatal Scan",
                "Pregnancy",
                "Gyn"
            ]
        },
        {
            "tagId": 508,
            "controlType": "radio",
            "label": "check9",
            "value": "ID"
        },
        {
            "tagId": 502,
            "controlType": "text",
            "label": "asd1",
            "value": "sdfsdf90"
        },
        {
            "tagId": 502,
            "controlType": "text",
            "label": "asd1_comments",
            "value": "sdfsdfdsf45"
        }
    ]
    config = {
        placeholder: "Enter method text here!",
        toolbar: ["heading", "|", "bold", "italic", "link", "bulletedList", "numberedList", "blockQuote"]
    };
    notesEditor = ClassicEditor;
    cssObjectTotal: any;
    scriptContent: any;
    styleTag: HTMLStyleElement;
    groupOfLookupValues = [];
    savedFormData = [];
    showSuggestionsFor: any | null = null;
    suggestionsList: any;

    a = [{
        "label": "ExampleTextbox", "value": "ancd"
    }, {
        "label": "EmployeeDetails", "value": "2022171", "controlType": "container"
    }, { "label": "Detail", "value": "Hyderabad" }]
    constructor(private qcs: QuestionControlService, private readonly notifyService: NotifyService, private readonly httpService: HttpService, private readonly modalService: NgbModal, private formbuilder: FormBuilder, private readonly appData: AppData) {
        this.dates = new Dates();
        this.dates.current = new Date();
        this.page = new Page();

    }

    ngOnInit() {
        (window as any).angularComponent = this;
        this.getAllLookupValues();
    }

    isEventBound(eventType: string, scriptArray: any[]): boolean {
        return scriptArray?.some(script => script.eventType === eventType);
    }
    getAllLookupValues() {
        this.httpService.post(ApiResources.getURI(ApiResources.lookups.base, ApiResources.lookups.fetchAllookupValues), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: any) => {
                this.groupOfLookupValues = response;
                this.addTemplateMetod();
                this.myformArray = this.formbuilder.array([]);
                this.dropdownselVal2 = false;
                this.editControls();
                this.fetchScoreValues();
                this.appData.userAccount
                    // .pipe(takeUntil(this.page.unSubscribe))
                    .subscribe((account: IUserAccount) => {
                        if (account) {
                            this.page.userAccount = account;
                        } else {
                            this.page.userAccount = undefined;
                        }
                    });
                this.fetchForms();
              
            })
    }
    parseCssString(cssString: string): { [key: string]: string } {
        const cssProperties = {};
        const regex = /([^:\s]+)\s*:\s*([^;]+)/g;

        let match;
        while ((match = regex.exec(cssString)) !== null) {
            const [, key, value] = match;
            cssProperties[key.trim()] = value.trim();
        }

        return cssProperties;
    }
    fetchLookup() {

        this.httpService.get(ApiResources.getURI(ApiResources.lookups.base, ApiResources.lookups.fetchlookup), {})
            // .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: any) => {
                this.groupOfLookupValues = response
            })

    }
    getLookupOptions() {
        const request = { LookupIds: this.bindLookupValues };
        this.lookupvalueGet = [];
        this.httpService.post(ApiResources.getURI(ApiResources.lookups.base, ApiResources.lookups.fetchlookupvaluesbind), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: any) => {
                this.lookupvalueGet = response
            })
    }
    getDataType(controlType) {

        switch (controlType) {
            case "number":
                return "INTEGER";
            case 'boolean':
                return 'BOOLEAN'
            case "date":
                return "TIMESTAMP";
            case "textarea":
                return "TEXT";

            default:
                return "VARCHAR";
        } 
    }
    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent): void {
        const suggestionPopup = document.getElementById('SuggestionPoplayout');
        const inputField = document.querySelector('input');
        if (suggestionPopup && !suggestionPopup.contains(event.target as Node) &&
            inputField && !inputField.contains(event.target as Node)) {
            this.showSuggestionsFor = null; // Close the suggestion list
        }
    }
    saveSectionData(dropdownselVal) {

        this.submitted = true;
        let modififed = this.page.userAccount.accountId;
        let dataValues = [];
        const isAnyInvalid = this.myformArray.controls.some((formGroup: FormGroup) => formGroup.invalid);

        if (isAnyInvalid) {
            // Return early if any form group is invalid
            return;
        }




        const extractFormGroupData = (group: FormGroup) => {
            const dataValues = []; // Initialize an array to hold the resulting data

            // Iterate through the controls in the form group
            Object.keys(group.controls).forEach(key => {
                const control = group.get(key);
                const controlData = processControl(control, key); // Call helper function to process control

                // If control is a FormGroup, add its data
                if (control instanceof FormGroup ) {
                    const childData = extractFormGroupData(control); // Recursively get child data
                    controlData.childGroupTags.push(...childData); // Add child data to parent control
                } else if (control instanceof FormArray && (control as any).controlType === 'grid') {
                    (control as FormArray).controls.forEach((childControl, index) => {
                        if (childControl instanceof FormGroup) {
                            const childData = extractFormGroupData(childControl); // Recursively get child data
                            //controlData.childGroupTags.push(...childData);
                            controlData.childGroupTags.push({
                                "childGroupTags": childData
                            });
                        }
                    })
                }
              
                // Push the processed control data if it's valid
                if (controlData) {
                    dataValues.push(controlData);
                }
            });

            return dataValues; // Return the structured output
        };

        // Helper function to process individual controls
        const processControl = (control, label: string) => {
            // Create a base structure for the new form group value
            const newFormGroupValue: any = {
                tagId: control.id || 0,
                controlType: control.controlType || '',
                label: label,
                value: '',
                displayName: control.displayName || '',
                childGroupTags: [] // Initialize childGroupTags for nested FormGroups
            };

            // Check the type of control and populate data accordingly
            if (control.controlType == "container" && control instanceof FormGroup && 'id' in control && 'controlType' in control && 'displayName' in control && 'labelName' in control) {
                newFormGroupValue.controlType = control.controlType;
                newFormGroupValue.displayName = control.displayName || '';
                newFormGroupValue.label = control.labelName || '';
            } else if (control instanceof FormControl && 'id' in control && 'controlType' in control && 'displayName' in control && 'labelName' in control) {
                newFormGroupValue.controlType = control.controlType || 'text'; // Default to 'text' if not defined
                newFormGroupValue.value = control.value || ''; // Set the control value
                newFormGroupValue.displayName = control.displayName || ''; // Use displayName or label
                newFormGroupValue.label = control.labelName || '';
            } else if (Array.isArray(control.value) && control.value.every(item => typeof item === 'object' && 'name' in item && 'value' in item)) {
                // Handle array of objects
                const filteredValues = control.value.filter(item => item.value !== "").map(item => item.value);

                if (filteredValues.length > 0) {
                    newFormGroupValue.value = filteredValues; // Set filtered values
                }
            }

            return newFormGroupValue; // Return the processed control data
        };

        const formArrayValues = this.myformArray.controls.map((formGroup: FormGroup) => {
            // Check if the form group is valid
            if (formGroup.invalid) {
                return; // Skip this form group
            }

            // Start extracting data from the main form group
            this.savedFormData = extractFormGroupData(formGroup);
            
        });


        //if (this.savedFormData.length == 0) {

        //    this.notifyService.warning("Pleas Enter Values");

        //    return
        //}

        let allValuesEmpty = true;

        for (let obj of this.savedFormData) {
            if (obj.controlType !== 'container') {

                if (obj.controlType === 'grid') {
                    const checkValues = (obj) => {
                        // Check if every group and every tag has an empty value
                        return obj.childGroupTags.every(group => {
                            return group.childGroupTags.every(tag => {
                                // Check if the tag's value is not empty
                                // If any value is non-empty, return false immediately
                                if (tag.value && tag.value !== '') {
                                    return false; // Early exit when a non-empty value is found
                                }
                                return true; // Continue checking if the value is empty
                            });
                        });
                    };

                    // If any grid has a non-empty value, set allValuesEmpty to false
                    if (!checkValues(obj)) {
                        allValuesEmpty = false;
                        break; // No need to check further if we already know some values are non-empty
                    }
                } else if (obj.value && obj.value !== ''){
                    allValuesEmpty = false;
                }
               
            } else {
                // For container objects, check the childGroupTags
                for (let child of obj.childGroupTags || []) {
                    if (child.value && child.value !== '') {
                        allValuesEmpty = false;
                    }
                }
            }
        }

        // If all values are empty, show warning
        if (allValuesEmpty) {
            this.notifyService.warning("Pleas Enter Values");

            return
        }




        //let tagIds = this.sectionControls
        //    .filter(control => control?.isFlow)
        //    .map(control => control.id);
        const tagIds = this.sectionControls.reduce((ids, control) => {
            if (control.isFlow) {
                ids.push(control.id);
            }

            if (control.controlType === 'container' && Array.isArray(control.childGroupTags)) {
                const childIds = control.childGroupTags.reduce((childAccum, childControl) => {
                    if (childControl.isFlow) {
                        childAccum.push(childControl.id);
                    }
                    return childAccum;
                }, []);
                ids.push(...childIds);
            }

            return ids;
        }, []);



        // let filteredData = dataValues.filter(data => tagIds.find(id => id === data.tagId));

        const filterDataByTagIds = (dataValues, tagIds) => {
            return dataValues
                .map(item => {
                    // Check if the current item's tagId is in the tagIds
                    const shouldInclude = tagIds.includes(item.tagId);

                    // Recursively filter childGroupTags if the parent should be included
                    const filteredChildTags = shouldInclude
                        ? filterDataByTagIds(item.childGroupTags, tagIds)
                        : []; // Clear child tags if the parent is not included

                    // Return a new object with filtered child tags
                    return {
                        ...item,
                        childGroupTags: filteredChildTags // Set the filtered child tags
                    };
                })
                .filter(item => item.childGroupTags.length > 0 || tagIds.includes(item.tagId)); // Filter out empty child tags unless the parent is included
        };

        const filteredData = filterDataByTagIds(this.savedFormData, tagIds);

        //let AllTagIds = this.sectionControls
        //    .filter(control => control.isApplyAll === true)
        //    .map(control => control.id)

        const AllTagIds = this.sectionControls.reduce((ids, control) => {
            if (control.isApplyAll) {
                ids.push(control.id);
            }

            if (control.controlType === 'container' && Array.isArray(control.childGroupTags)) {
                const childIds = control.childGroupTags.reduce((childAccum, childControl) => {
                    if (childControl.isApplyAll) {
                        childAccum.push(childControl.id);
                    }
                    return childAccum;
                }, []);
                ids.push(...childIds);
            }

            return ids;
        }, []);



        //let isEtoEfLOW = this.sectionControls
        //    .filter(control => control.styleProperties?.isFlowEtoE === true)
        //    .map(control => control.id)
        const isEtoEfLOW = this.sectionControls.reduce((ids, control) => {
            if (control.isFlowEtoE) {
                ids.push(control.id);
            }

            if (control.controlType === 'container' && Array.isArray(control.childGroupTags)) {
                const childIds = control.childGroupTags.reduce((childAccum, childControl) => {
                    if (childControl.isFlowEtoE) {
                        childAccum.push(childControl.id);
                    }
                    return childAccum;
                }, []);
                ids.push(...childIds);
            }

            return ids;
        }, []);

        //let filteredEtoE = dataValues.filter(data => isEtoEfLOW.find(id => id === data.tagId));
        const filterDataByEtoEfLOW = (dataValues, isEtoEfLOW) => {
            return dataValues
                .map(item => {
                    // Check if the current item's tagId is in the isEtoEfLOW
                    const shouldInclude = isEtoEfLOW.includes(item.tagId);

                    // Recursively filter childGroupTags if the parent should be included
                    const filteredChildTags = shouldInclude
                        ? filterDataByEtoEfLOW(item.childGroupTags, isEtoEfLOW)
                        : []; // Clear child tags if the parent is not included

                    // Return a new object with filtered child tags
                    return {
                        ...item,
                        childGroupTags: filteredChildTags // Set the filtered child tags
                    };
                })
                .filter(item => item.childGroupTags.length > 0 || isEtoEfLOW.includes(item.tagId)); // Filter out empty child tags unless the parent is included
        };

        const filteredEtoE = filterDataByEtoEfLOW(this.savedFormData, isEtoEfLOW);
        const capitalizeFirstLetter = (string) => {
            if (!string) return '';
            return string.charAt(0).toUpperCase() + string.slice(1);
        };

        const result = this.savedFormData.flatMap(item => {
            if (item.controlType === "container") {
                return item.childGroupTags.map(child => ({
                    label: capitalizeFirstLetter(child.label),
                    value: child.value,
                    dataType: this.getDataType(child.controlType),
                    displayName: child.displayName
                }));
            } else if (item.controlType === "grid") {
                const flattenedData = item.childGroupTags.map(group => {
                    const flattenedGroup = group.childGroupTags.reduce((acc, control) => {
                        acc[control.label] = control.value; // Use control label as the key and value as the value
                        return acc;
                    }, {});
                    return flattenedGroup; // Return the transformed object
                });

                const filteredData = flattenedData.filter(obj =>
                    Object.values(obj).some(value => value !== "") // Keep objects where at least one value is not empty
                );
                let gridData = "";
                // If filteredData is empty, set gridData to ""
                if (filteredData.length === 0) {
                    gridData = "";
                } else {
                    gridData = filteredData;
                }

                return [{
                    label: capitalizeFirstLetter(item.label),
                    value: gridData,
                    dataType: this.getDataType(item.controlType),
                    displayName: item.displayName
                }];
               

            } else {
                return [{
                    label: capitalizeFirstLetter(item.label),
                    value: item.value,
                    dataType: this.getDataType(item.controlType),
                    displayName: item.displayName
                }];
            }
        });
        
        this.submitting = true;


        var resultToUse = this.sectionContainer.encounterMode === 1 ? result : this.savedFormData;

        var request = { PatientId: this.patientId, SectionID: this.sectionId, TemplateId: this.templateid, isactive: true, description: dropdownselVal, data: JSON.stringify(resultToUse), EncounterId: this.appointmentId, CreatedBy: modififed, TemplateIDs: this.TemplateIDs, Sections: this.Sections, Type: this.sectionType, TagsValueList: JSON.stringify(filteredData), AllTagIds: AllTagIds, EncounterType: this.sectionContainer.encounterType, EncounterMode: this.sectionContainer.encounterMode, TableName: this.sectionContainer.tableName, AppointmentId: this.appointmentId };

        var request1 = { PatientId: this.patientId, SectionId: this.sectionId, TemplateId: this.templateid, isActive: true, TagsValueList: JSON.stringify(filteredEtoE), CreatedBy: modififed };

        let requestObservable1: Observable<any>;
        if (filteredEtoE.length > 0) {
            requestObservable1 = this.httpService.post(ApiResources.getURI(ApiResources.tagdataflow.base, ApiResources.tagdataflow.addTagDataEncounter), request1);
        } else {


            requestObservable1 = of([])
        }
        const requestObservable2 = this.httpService.post(ApiResources.getURI(ApiResources.sectiondata.base, ApiResources.sectiondata.insert), request);

        let patientCentricTags = this.sectionControls
            .filter(control => control.styleProperties?.patientCentricEncounter === true)
            .map(control => control.id)
        let patientCentricTagValues = this.savedFormData.filter(data => patientCentricTags.find(id => id === data.tagId));
        let transformedValues = patientCentricTagValues.map(data => ({
            name: data.label,
            value: data.value,
            type: 2
        }));
        let patientCentricRequest = {
            // Value = model.Value,
            PatientId: this.patientId,
            EncounterId: this.appointmentId,
            TagName: JSON.stringify(transformedValues),
            TemplateId: this.templateid,
            SectionID: this.sectionId
        }
        let patientCentricObservable1: Observable<any>;
        if (patientCentricTagValues.length > 0) {
            patientCentricObservable1 = this.httpService
                .post<Array<any>>(ApiResources.getURI(ApiResources.patientCommonData.base, ApiResources.patientCommonData.insert), patientCentricRequest)

        } else {
            patientCentricObservable1 = of([])
        }

        forkJoin([requestObservable1, requestObservable2, patientCentricObservable1])
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.submitted = this.submitting = false))
            .subscribe({
                next: (responses: any[]) => {
                    this.notifyService.success("Data Saved Successfully");
                   // this.closeModalAppointment.emit();
                    // Update master tag and show success notification
                    this.updateMasterTag();

                },
                error: (error) => {
                    // Handle errors
                    console.error('Error:', error);
                }
            });



    }

    updateMasterTag() {
        this.sectionContainer["IsUpdateTag"] = true;
        this.sectionContainer.view = JSON.stringify(this.sectionControls);
        this.httpService.post(ApiResources.getURI(ApiResources.tagformlist.base, ApiResources.tagformlist.update), this.sectionContainer)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.submitting = false;
                this.submitted = false;
            }))
            .subscribe((response: string) => {
                this.sectionContainer["IsUpdateTag"] = false;

            })

    }
    addCustomStyles(): void {
        this.styleTag = document.createElement('style');
        this.styleTag.type = 'text/css';
        document.head.appendChild(this.styleTag);

    }


    updateCustomStyles(): void {
        if (!this.styleTag) {
            return;
        }

        const customCss = this.cssObjectTotal?.customCss || '';
        const styleSheet = this.styleTag.sheet as CSSStyleSheet;

        // Clear existing styles
        while (styleSheet.cssRules.length > 0) {
            styleSheet.deleteRule(0);
        }

        // Split the custom CSS into individual style rules
        const rules = customCss.split('}').map(rule => rule.trim()).filter(rule => rule.length > 0);

        // Process each style rule
        for (const rule of rules) {
            const [selector, styles] = rule.split('{').map(part => part.trim());
            const className = selector.replace('.', ''); // Extract class name

            // Add the updated or new style rule
            styleSheet.insertRule(`${selector} { ${styles} }`, styleSheet.cssRules.length);
        }
    }


    removeCustomStyles(): void {
        if (this.styleTag && this.styleTag.parentNode) {
            this.styleTag.parentNode.removeChild(this.styleTag);
        }
    }

    transpileTypeScript(code: string): string {
        const transpiledCode = ts.transpileModule(code, { compilerOptions: { module: ts.ModuleKind.CommonJS } });
        return transpiledCode.outputText;
    }

    async createSection() {
        this.fetchTableData();

        var sectionDataValue = [];
        this.dropdownselVal2 = true;

        //const allTagIds = this.sectionControls
        //    .filter(control => control.isApplyAll === true)
        //    .map(control => control.id);
        const allTagIds = this.sectionControls.reduce((ids, control) => {
            if (control.isApplyAll) {
                ids.push(control.id);
            }

            if ((control.controlType === 'container' || control.controlType === 'grid') && Array.isArray(control.childGroupTags)) {
                const childIds = control.childGroupTags.reduce((childAccum, childControl) => {
                    if (childControl.isApplyAll) {
                        childAccum.push(childControl.id);
                    }
                    return childAccum;
                }, []);
                ids.push(...childIds);
            }

            return ids;
        }, []);

        const request12 = {
            AllTagIds: allTagIds,
            SectionID: this.sectionId
        };

        this.masterTags = [];
        let masterChangesTagsRequest$: Observable<any>;
        if (request12.AllTagIds.length > 0) {
            masterChangesTagsRequest$ = this.httpService.get(ApiResources.getURI(ApiResources.tagdataflow.base, ApiResources.tagdataflow.fetch), request12);
        } else {
            masterChangesTagsRequest$ = of([]);
        }



        // Filter flowsheetcontrols with isFlow true and get the corresponding tagIds
        //let isFlowtagIds = this.sectionControls.filter(control => control?.isFlow).map(control => control.id);
        const isFlowtagIds = this.sectionControls.reduce((ids, control) => {
            // If isFlow is true, add the id to the accumulator
            if (control?.isFlow) {
                ids.push(control.id);
            }

            // Check if the control type is 'container' and has child group tags
            if ((control.controlType === 'container' || control.controlType === 'grid') && Array.isArray(control.childGroupTags)) {
                const childIds = control.childGroupTags.reduce((childAccum, childControl) => {
                    if (childControl?.isFlow) {
                        childAccum.push(childControl.id);
                    }
                    return childAccum;
                }, []);
                ids.push(...childIds);
            }

            return ids;
        }, []);

        var request13 = { PatientId: this.patientId, EncounterId: this.appointmentId };
        let tagDataSectionRequest$: Observable<any>;
        let patientCentrictagvalues$: Observable<any>;
        if ((this.patientId == 0 && this.appointmentId == 0) || (this.outsideEncounter)) {
            tagDataSectionRequest$ = of([]);
            patientCentrictagvalues$ = of([]);
        } else {
            tagDataSectionRequest$ = this.httpService.get(ApiResources.getURI(ApiResources.tagdataflow.base, ApiResources.tagdataflow.fetchTagDataSection), request13);
            patientCentrictagvalues$ = this.httpService.post<Array<any>>(ApiResources.getURI(ApiResources.patientCommonData.base, ApiResources.patientCommonData.fetch), request13)
        }

        let sectionPreviousDataRequest$: Observable<any>;
        var request = { PatientId: this.patientId, SectionID: this.sectionId, TemplateId: this.templateid, EncounterId: this.appointmentId, AllTagIds: isFlowtagIds };
        if ((this.patientId && this.templateid == 0 && this.sectionId == 0 && this.templateid == 0 && this.appointmentId == 0) || (this.outsideEncounter)) {

            sectionPreviousDataRequest$ = of([]);

        } else if ((this.patientId && this.templateid && this.sectionId && this.templateid && this.appointmentId)) {
            sectionPreviousDataRequest$ = this.httpService.post(ApiResources.getURI(ApiResources.sectiondata.base, ApiResources.sectiondata.fetch), request);
        } else {
            sectionPreviousDataRequest$ = of([]);  // Add a fallback if none of the conditions are met
        }


        let tagEtoEFlowDataRequest$: Observable<any>;
        var requestE = { PatientId: this.patientId, SectionID: this.sectionId, TemplateId: this.templateid };
        if ((this.patientId == 0 && this.templateid == 0 && this.sectionId == 0) || (this.outsideEncounter)) {

            tagEtoEFlowDataRequest$ = of([]);
        } else {

            tagEtoEFlowDataRequest$ = this.httpService.get(ApiResources.getURI(ApiResources.tagdataflow.base, ApiResources.tagdataflow.fetchTagDataEncounter), requestE);
        }
        let sectionDataTableMode$: Observable<any>;

        if (this.outsideEncounter && this.encounterModeType == 1 && this.patientId) {

            const re = { EncounterType: this.encounterType, EncounterMode: this.encounterModeType, PatientId: this.patientId, AppointmentId: this.appointmentId, TableName: this.sectionContainer.tableName }
            sectionDataTableMode$ = this.httpService
                .post<Array<any>>(
                    ApiResources.getURI(ApiResources.tagformlist.base, ApiResources.tagformlist.commonSavedData), re
                )
        } else {
            sectionDataTableMode$ = of([]);
        }


        forkJoin({
            masterChangesTags: masterChangesTagsRequest$,
            tagDataSection: tagDataSectionRequest$,
            sectionPreviousData: sectionPreviousDataRequest$,
            tagEtoEFlowData: tagEtoEFlowDataRequest$,
            patientCentrictagvalues: patientCentrictagvalues$,
            sectionDataTableModeValues: sectionDataTableMode$

        })
            .pipe(
                takeUntil(this.page.unSubscribe),
                finalize(() => this.page.loading = false)
            )
            .subscribe({
                next: async (responses: { masterChangesTags: any, tagDataSection: any, sectionPreviousData: any, tagEtoEFlowData: any, patientCentrictagvalues: any, sectionDataTableModeValues: any }) => {
                    this.masterTags = responses.masterChangesTags;
                    responses.tagDataSection.forEach(obj => {
                        const tagValue = obj?.tagValue;
                        try {
                            const parsedValue = JSON.parse(tagValue);
                            this.isflowCommonTagData.push(parsedValue);
                        } catch (error) {
                            console.error('Error parsing JSON:', error);
                        }
                    });
                    responses.tagEtoEFlowData.forEach(obj => {
                        const tagValue = obj?.tagValue;
                        try {
                            const parsedValue = JSON.parse(tagValue);
                            this.isflowEtoEData.push(parsedValue);
                        } catch (error) {
                            console.error('Error parsing JSON:', error);
                        }
                    });

                    if (this.masterTags.length > 0 && this.sectionControls.length > 0) {


                        this.sectionControls = this.sectionControls.map(control => {
                            const matchingTag = this.masterTags.find(tag => tag.id === control.id);
                            return matchingTag ? matchingTag : control;
                        });

                        // Push unmatched tags into SectionControls
                        this.masterTags.forEach(tag => {
                            const isMatched = this.sectionControls.some(control => control.id === tag.id);
                            if (!isMatched) {
                                this.sectionControls.push(tag);
                            }
                        });
                    }

                    if (responses?.sectionPreviousData !== null && responses?.sectionPreviousData !== undefined) {
                        if (responses?.sectionPreviousData.length > 0) {
                            sectionDataValue = JSON.parse(responses.sectionPreviousData.data);
                        }


                    }
                    if (this.sectionControls !== null) {

                        this.clonedArray = this.sectionControls.map(obj => {
                            try {
                                const parsedHtmlData = JSON.parse(obj.htmlData);
                                return {
                                    ...obj,
                                    htmlData: Array.isArray(parsedHtmlData) ? parsedHtmlData : obj.htmlData
                                };
                            } catch (error) {
                                return {
                                    ...obj,
                                    htmlData: obj.htmlData
                                };
                            }
                        });

                        await this.processQuestions()

                        this.clonedArray = this.sectionControls;


                    }



                    // Iterate through the array and modify values with a trailing '.'
                    this.clonedArray.forEach(item => {
                        for (const key in item) {
                            if (item.hasOwnProperty(key) && typeof item[key] === 'string' && item[key].endsWith('.')) {
                                item[key] = item[key].slice(0, -1); // Remove the trailing '.'
                            }
                        }
                        if (item.id == this.masterTags[0]?.id) {
                            item.htmlData = this.masterTags[0].htmlData;
                        }

                    });


                    this.form = this.qcs.toFormGroup(this.clonedArray);
                    this.myformArray.controls.push(this.form)


                    let allLogicContainers = [];

                    if (this.scriptContent) {
                        Object.keys(this.scriptContent).forEach(key => {
                            const items = this.scriptContent[key];

                            // Iterate over each object in the array
                            items.forEach(item => {
                                if (item.logicContainer) {
                                    allLogicContainers.push(item.logicContainer);
                                }
                            });
                        });

                        // allLogicContainers.push(this.scriptContent)
                        // const scriptContent = allLogicContainers.join('\n');
                        const scriptContent = this.transpileTypeScript(allLogicContainers.join('\n'));

                        // create a script element and set its content to the transpiled javascript code
                        const scriptcode = document.createElement('script');
                        scriptcode.textContent = scriptContent;

                        // append the script element to the document body to execute the function
                        document.body.appendChild(scriptcode);
                    }



                    this.addCustomStyles();
                    this.updateCustomStyles();
                    this.isflowCommonTagData.forEach(commonObj => {
                        const matchingIndex = sectionDataValue.findIndex(sectionObj => sectionObj.tagid === commonObj.tagId);

                        if (matchingIndex !== -1) {

                            sectionDataValue[matchingIndex] = commonObj;
                        } else {

                            sectionDataValue.push(commonObj);
                        }
                    });
                    if (this.isflowEtoEData) {
                        this.isflowEtoEData.forEach(commonObj => {
                            const matchingIndex = sectionDataValue.findIndex(sectionObj => sectionObj.tagid === commonObj.tagId);
                            if (matchingIndex !== -1) {

                                sectionDataValue[matchingIndex] = commonObj
                            }
                            else {
                                sectionDataValue.push(commonObj);
                            }
                        });
                    }
                    this.masterTags.forEach(masterTag => {
                        // Filter sectionDataValue to remove objects with matching tagid
                        sectionDataValue = sectionDataValue.filter(sectionObj => sectionObj.tagId !== masterTag.id);
                    });


                    if (sectionDataValue.length != 0) {
                        //let sectionDataValue = JSON.parse(response.data);

                        sectionDataValue.forEach(data => {
                            const { label, value } = data;
                            const controlType = data.controlType;
                            const tagId = data.tagId;

                            // Loop through the form controls in the form array
                            this.myformArray.controls.forEach((group: FormGroup) => {
                                // Iterate through the controls in the group
                                Object.keys(group.controls).forEach((key) => {

                                    if (key === label) {

                                        if (group.get(label) instanceof FormGroup) {
                                            // Iterate through the controls in the nested FormGroup
                                            Object.keys((group.get(label) as FormGroup).controls).forEach((nestedKey) => {
                                                const nestedControl = group.get(label).get(nestedKey);

                                                if (nestedControl instanceof FormControl) {
                                                    // Handle the logic for FormControl within the nested FormGroup
                                                    if (value.indexOf(nestedKey) !== -1) {
                                                        nestedControl.setValue({
                                                            name: nestedKey,
                                                            value: nestedKey,
                                                            isChecked: true
                                                        });
                                                    } else {
                                                        nestedControl.setValue({
                                                            name: nestedKey,
                                                            value: '',
                                                            isChecked: false
                                                        });
                                                    }
                                                } else if (nestedControl instanceof FormArray) {
                                                    // Handle the FormArray within the nested FormGroup
                                                    nestedControl.controls = nestedControl.controls.map((arrayControl) => {
                                                        const arrayControlName = arrayControl.value.name;

                                                        if (value.indexOf(arrayControlName) !== -1) {
                                                            // Matched, set the value to the control
                                                            arrayControl.setValue({
                                                                value: arrayControlName,
                                                                isChecked: true
                                                            });
                                                        } else {
                                                            // Not matched, set the value to an empty string
                                                            arrayControl.setValue({
                                                                value: '',
                                                                isChecked: false
                                                            });
                                                        }

                                                        return arrayControl;
                                                    });
                                                } else {
                                                    nestedControl.setValue(value);

                                                }
                                            });

                                        }


                                        else if (group.get(label) instanceof FormArray) {
                                            const formArray = group.get(label) as FormArray;

                                            formArray.controls = formArray.controls.map((control) => {
                                                const controlformarray = control.value.name;

                                                if (value.indexOf(controlformarray) !== -1) {
                                                    // Matched, set the value to the control
                                                    control.value.value = controlformarray;
                                                    control.value.isChecked = true;
                                                } else {
                                                    // Not matched, set the value to an empty string
                                                    control.value.value = '';
                                                    control.value.isChecked = false;
                                                }

                                                return control;
                                            });

                                        } else {
                                            // Set the value of the control with the matching name
                                            group.controls[key].setValue(value);
                                        }

                                    }
                                });

                            });
                        });
                    }

                    if (responses.sectionDataTableModeValues.length > 0) {
                        responses.sectionDataTableModeValues.forEach(data => {
                            const { label, value } = data;
                            const controlType = data.controlType;

                            this.myformArray.controls.forEach((group: FormGroup) => {
                                let labelFound = false;

                                // Check if the label exists in the current group
                                if (group.controls[label]) {
                                    labelFound = true;
                                    const control = group.controls[label];

                                    // If it's a FormArray
                                    if (control instanceof FormArray && (control as any).controlType == 'checkbox') {
                                        const formArray = control as FormArray;

                                        formArray.controls = formArray.controls.map((control) => {
                                            const controlFormArray = control.value.name;

                                            if (value && value.indexOf(controlFormArray) !== -1) {
                                                // Matched, set the value to the control
                                                control.setValue({ value: controlFormArray, name: controlFormArray, isChecked: true });
                                            } else {
                                                // Not matched, set the value to an empty string
                                                control.setValue({ value: '', name: controlFormArray, isChecked: false });
                                            }

                                            return control;
                                        });

                                    } else if (control instanceof FormArray && (control as any).controlType == 'grid') {
                                        const formArray = control as FormArray;
                                        if (value) {
                                            const valuesOfRows = JSON.parse(value);
                                            this.generateFormArrayGrops(control, valuesOfRows)
                                        }

                                    } else {
                                        // If it's a FormControl, set the value
                                        if (this.isStringifiedArray(value)) {
                                            let formattedValue = JSON.parse(value);
                                            group.controls[label].setValue(formattedValue);
                                        } else {
                                            // Set the value directly
                                            group.controls[label].setValue(value);
                                        }
                                    }

                                } else {
                                    // If the label is not found in the main group, check nested controls
                                    const checkNestedControls = (currentGroup: FormGroup) => {
                                        Object.keys(currentGroup.controls).forEach(key => {
                                            const control = currentGroup.get(key);

                                            if (key === label) {
                                                labelFound = true; // Found the label in the nested group
                                                // Now handle the control based on its type (FormControl or FormArray)
                                                if (control instanceof FormControl) {
                                                    control.setValue(value);
                                                } else if (control instanceof FormArray) {
                                                    control.controls = control.controls.map((formControl) => {
                                                        const controlFormArray = formControl.value.name;

                                                        if (value && value.indexOf(controlFormArray) !== -1) {
                                                            // Matched, set the value to the control
                                                            formControl.setValue({ value: controlFormArray, name: controlFormArray, isChecked: true });
                                                            
                                                        } else {
                                                            // Not matched, set the value to an empty string
                                                            formControl.setValue({ value: '', name: controlFormArray, isChecked: false });
                                                           
                                                        }

                                                        return formControl;
                                                    });
                                                }
                                            } else if (control instanceof FormGroup) {
                                                // If it's a nested group, recurse
                                                checkNestedControls(control);
                                            }
                                        });
                                    };

                                    // Call the recursive function to check nested controls
                                    checkNestedControls(group);
                                }
                            });
                        });
                    }

                    if (responses.patientCentrictagvalues.length > 0) {

                        this.myformArray.controls.forEach((group: FormGroup) => {
                            // Iterate through the controls in the group
                            Object.keys(group.controls).forEach((key) => {
                                const control = group.get(key);
                                if (control && control instanceof FormControl) {
                                    // Find the matching object in data array based on tagName
                                    const matchingObject = responses.patientCentrictagvalues.find(item => item.tagName === key);
                                    if (matchingObject) {
                                        // Set the value to the control
                                        control.setValue(matchingObject.value);
                                    }
                                }
                            });
                        });
                    }

                    this.emitDataReady();
                    if (this.scriptContent) {
                        if (this.scriptContent.hasOwnProperty("Section")) {
                            this.scriptContent["Section"].forEach((sectionObject) => {

                                const func = (window as any)[sectionObject.logicName] as unknown as Function;

                                if (typeof func === 'function') {
                                    func(this.clonedArray, this.myformArray?.controls[0], this.scriptContent, this.notifyService);
                                } else {
                                    console.error(`Function  is not defined.`);
                                }
                            })
                        }
                    }

                },
                error: (error) => {
                    // Handle errors
                    console.error('Error:', error);
                }

                

            });

    }

    emitDataReady(): void {
        this.dataReady.emit();
    }
    async processQuestions() {

        const promises = this.sectionControls.map(async question => {

            if (question.queryList && question.selectedOption === 'querylist' && question.controlType != 'text') {
                try {
                    const data = await this.commmonQueryExecute(question.queryList);
                    const allValues = (data || []).map(item => item.Value);
                    question.htmlData = allValues;
                } catch (error) {
                    console.error("Error fetching query data for question: ", error);
                }
            } else if (question.lookupId) {
                const matchedObjects = this.groupOfLookupValues.filter(obj => obj.lookupId === question.lookupId);
                const namesArray = matchedObjects.map(obj => obj.name);
                question.htmlData = namesArray;
            } else if ((typeof question.htmlData === 'string' && /^\[.*\]$/.test(question.htmlData) && question.selectedOption == 'manual')) {
                try {
                    question.htmlData = JSON.parse(question.htmlData);
                } catch (error) {
                    console.error('Error parsing JSON string:', error);
                }

            }

            if (question?.styleProperties) {
                try {

                    const isStringifiedJson = typeof question.styleProperties === 'string' && question.styleProperties.trim().startsWith('{') && question.styleProperties.trim().endsWith('}');

                    if (isStringifiedJson) {
                        // Parse JSON string if it's a stringified JSON object
                        question.styleProperties = JSON.parse(question.styleProperties);
                    }
                } catch (error) {
                    console.error('Error parsing JSON string:', error);
                }

            }



        });

        await Promise.all(promises);

    }
    commmonQueryExecute(request: string): Promise<any> {
        const query = { query: request };

        return new Promise((resolve, reject) => {
            this.httpService
                .get<Array<any>>(
                    ApiResources.getURI(ApiResources.tagformlist.base, ApiResources.tagformlist.commonQueryData), query
                )
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => this.page.loading = false))
                .subscribe(
                    (response: any) => {
                        resolve(response); // Resolve the promise with the response
                    },
                    (error: any) => {
                        console.error("Error: ", error);
                        reject(error); // Reject the promise if there is an error
                    }
                );
        });
    }



    updateTestingScript(event: Event, script: any, control: any, formGroup: FormGroup) {


    }



    getStyleObject(styleString: string): { [key: string]: string } {
        const styleObject: { [key: string]: string } = {};
        if (styleString) {
            const styles = styleString.split(';');
            styles.forEach(style => {
                const [property, value] = style.split(':');
                if (property && value) {

                    styleObject[property.trim()] = value.trim();
                }
            });
        }
        return styleObject;
    }


   

    calculateAndUpdateValue() {
        let calculatedValue = 0;
        let controlsWithValueCount = 0;

        // Calculate the total number of controls excluding calculation and total fields
        const totalControls = Object.keys(this.form.controls).length - this.calculationFields.length - this.Total.length;

        // Iterate through the controls
        Object.keys(this.form.controls).forEach((controlName) => {
            const control = this.form.get(controlName);

            // Check if the control is not a calculation or total field
            if (!this.calculationFields.find(field => field.label === controlName) && !this.Total.includes(controlName)) {
                if (control && control.value) {
                    const parts = control.value.split('-');
                    if (parts.length === 2) {
                        const number = parseInt(parts[0], 10);
                        if (!isNaN(number)) {
                            calculatedValue += number;
                            controlsWithValueCount++;
                        }
                    }
                }
            }
        });

        // Update the total controls asynchronously
        this.Total.forEach(totalLabel => {
            if (this.form.get(totalLabel)) {
                this.form.get(totalLabel).setValue(calculatedValue);
            }

        });

        // Update the calculation controls asynchronously
        //this.calculationFields.forEach(calculationField => {
        //    if (this.form.get(calculationField.label)) {
        //        const calculatedResult = eval(calculationField.formula);
        //        this.form.get(calculationField.label).setValue(calculatedResult);
        //    } else {
        //        console.error(`Calculation control '${calculationField.label}' not found in the form.`);
        //    }
        //});
        //this.Tscore
        //this.calculationFields.forEach(calculationField => {
        //    if (this.form.get(calculationField.label)) {
        //        const calculatedResult = eval(calculationField.formula);
        //        const intValue = parseInt(calculatedResult, 10); // or use parseFloat for float values
        //        this.form.get(calculationField.label).setValue(intValue);
        //    } else {
        //        console.error(`Calculation control '${calculationField.label}' not found in the form.`);
        //    }
        //});
        this.calculationFields.forEach(calculationField => {
            if (this.form.get(calculationField.label)) {
                const calculatedResult = eval(calculationField.formula);
                const intValue = parseInt(calculatedResult, 10); // or use parseFloat for float values

                // Find the object in the array with matching rawScore
                const matchingObject = this.Tscore.find(obj => obj.rawScore === intValue);

                if (matchingObject) {
                    // If a matching object is found, set the value property to the form control
                    this.form.get(calculationField.label).setValue(matchingObject.value);
                }
            }
        });


    }





    private editControls() {

        this.editControlsForm = this.formbuilder.group({
            label: [null],
            id: [null]
        });
    }

    addColumn() {
        this.fetchTableData();
        this.sectionControls;
        this.dropdownselVal2 = true;
        let newField = `${this.appointmentDate}`;



        var request = { PatientId: this.patientId, SectionID: this.sectionId, TemplateId: this.templateid, EncounterId: this.appointmentId };

        this.httpService.post(ApiResources.getURI(ApiResources.sectiondata.base, ApiResources.sectiondata.fetch), request)
            //.pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { }))
            .subscribe((response: any) => {
                if (this.sectionControls !== null) {

                    this.clonedArray = this.sectionControls.map(obj => {
                        try {
                            const parsedHtmlData = JSON.parse(obj.htmlData);
                            return {
                                ...obj,
                                htmlData: Array.isArray(parsedHtmlData) ? parsedHtmlData : obj.htmlData
                            };
                        } catch (error) {
                            return {
                                ...obj,
                                htmlData: obj.htmlData
                            };
                        }
                    });

                }



                // Iterate through the array and modify values with a trailing '.'
                this.clonedArray.forEach(item => {
                    for (const key in item) {
                        if (item.hasOwnProperty(key) && typeof item[key] === 'string' && item[key].endsWith('.')) {
                            item[key] = item[key].slice(0, -1); // Remove the trailing '.'
                        }
                    }
                });

                this.form = this.qcs.toFormGroup(this.clonedArray);
                this.myformArray.controls.push(this.form)
                this.myformArray.controls.forEach((group: FormGroup) => {
                    group.addControl("apointment_date", new FormControl(newField))
                })
                this.displayedHead.push(newField)
                this.displayedFields.push(newField);


                if (response != null) {
                    let sectionDataValue = JSON.parse(response.data);

                    sectionDataValue.forEach((object) => {
                        for (const controlName in object) {
                            if (object.hasOwnProperty(controlName)) {
                                const controlValue = object[controlName];

                                // Loop through the form controls in the form array
                                this.myformArray.controls.forEach((group: FormGroup) => {
                                    // Iterate through the controls in the group
                                    Object.keys(group.controls).forEach((key) => {

                                        if (key === controlName) {
                                            if (group.get(controlName) instanceof FormArray) {
                                                const formArray = group.get(controlName) as FormArray;

                                                formArray.controls = formArray.controls.map((control) => {
                                                    const controlformarray = control.value.name;

                                                    if (controlValue.indexOf(controlformarray) !== -1) {
                                                        // Matched, set the value to the control
                                                        control.value.value = controlformarray;
                                                        control.value.isChecked = true;
                                                    } else {
                                                        // Not matched, set the value to an empty string
                                                        control.value.value = '';
                                                        control.value.isChecked = true;
                                                    }

                                                    return control;
                                                });

                                            } else {
                                                // Set the value of the control with the matching name
                                                group.controls[key].setValue(controlValue);
                                            }

                                        }
                                    });
                                });
                            }
                        }
                    });

                }


                this.clonedArray.forEach(x => {
                    if (x.controlType == "calculation") {
                        this.calculationFields.push({ label: x.label, formula: x.formula });
                        this.calLabel = x.label;
                    }
                    if (x.controlType == 'total') {
                        // Push the label into the array
                        this.Total.push(x.label);
                        // this.Total = x.label;
                    }
                });
            });

    }

    handleEvent(event: Event, script: any, formGroup: FormGroup, controls?, actionControl?, mainformGroup?, selectedRecord?, parentContolInfo?, gridrowIndex?): void {
        
            const eventObject = script.find(fn => fn.eventType === event.type);
            if (eventObject) {
                //const scriptContent = this.transpileTypeScript(eventObject.logicContainer);

                //// create a script element and set its content to the transpiled javascript code
                //const scriptcode = document.createElement('script');
                //scriptcode.textContent = scriptContent;

                //// append the script element to the document body to execute the function
                //document.body.appendChild(scriptcode);

                const logicFunction = eventObject.logicName;
                const additionalArgs = eventObject.events.split(',');
                const func = (window as any)[logicFunction] as unknown as Function;

                if (typeof func === 'function') {
                    func(event, additionalArgs, formGroup, controls, actionControl, mainformGroup, this.notifyService, selectedRecord, parentContolInfo, gridrowIndex);
                } else {
                    console.error(`Function ${eventObject.logicName} is not defined.`);
                }
            }



        
    }
    createFormGroup(obj: any): FormGroup {
        const formGroup = this.formbuilder.group({});
        Object.keys(obj).forEach((key) => {
            formGroup.addControl(key, this.formbuilder.control(obj[key]));
        });
        return formGroup;
    }



    //checkbox value Binding
    checkBoxValue(event, row) {

        const field = 'isChecked';
        const isChecked = event.target.checked;

        if (!row.contains(field)) {
            row.addControl(field, new FormControl(isChecked));
        } else {
            row.get(field).setValue(isChecked);
        }

    }




    onCheckboxChange(event: any, optionValue: string, formArrayName, $event?, script?, question?, row?, controls?, rowIndex?,mainformGroup?) {
        //const formArray = this.form.get(formArrayName) as FormArray;

        const findControl = (formGroup: FormGroup, controlName: string) => {
            // Check if the control is at the current level
            if (formGroup.controls[controlName]) {
                return formGroup.controls[controlName];
            }

            // If not found, recursively search through nested FormGroups
            for (const controlKey in formGroup.controls) {
                const control = formGroup.controls[controlKey];
                if (control instanceof FormGroup) {
                    const nestedControl = findControl(control, controlName);
                    if (nestedControl) {
                        return nestedControl;
                    }
                } else if (control instanceof FormArray && (control as any).controlType == 'grid') {
                    const formArray = control as FormArray;
                    const nested = formArray.at(rowIndex) as FormGroup;
                    if (nested instanceof FormGroup) {
                        const nestedControl = findControl(nested, controlName);
                        if (nestedControl) {
                            return nestedControl;
                        }
                    }
                }
            }

            return null; // Return null if control not found
        };

        // First, try to get the control directly from the top-level form
        let control = this.form.get(formArrayName);

        // If control not found at the top level, search recursively in nested FormGroups
        if (!control) {
            control = findControl(this.form, formArrayName);
        }

        // If control is still not found, log an error and return
        if (!control) {
            console.error(`Control with name '${formArrayName}' not found.`);
            return;
        }


        const formArray = control as FormArray;

        // Iterate through the controls in the FormArray
        const matchingControl = formArray.controls.find((control) => control.value.name === optionValue);

        const childTagIdsFromControl = matchingControl['childTagIds'];
        if (matchingControl) {
            if (event.target.checked) {
                matchingControl.setValue({ value: optionValue, name: optionValue, isChecked: true });

                if (Array.isArray(childTagIdsFromControl)) {
                    childTagIdsFromControl.forEach(childTagId => {
                        // Find matching object in sectioncontrols
                        const matchingSectionControl = this.sectionControls.find(sectionControl => sectionControl.id === childTagId);

                        if (matchingSectionControl && matchingSectionControl.normalText) {
                            // Find the control with the corresponding childTagId in formArray
                            this.myformArray.controls.forEach((formGroup: FormGroup) => {
                                Object.keys(formGroup.controls).forEach(controlName => {
                                    const control = formGroup.get(controlName);
                                    if (control && control['id'] === childTagId) {
                                        // Perform the desired action with the matching control
                                        control.setValue(matchingSectionControl.normalText);
                                    }
                                });
                            });
                        }
                    });
                }

            } else {

                matchingControl.setValue({ value: "", name: optionValue, isChecked: false });
                if (childTagIdsFromControl) {
                    childTagIdsFromControl.forEach(childTagId => {
                        // Find matching object in sectioncontrols
                        const matchingSectionControl = this.sectionControls.find(sectionControl => sectionControl.id === childTagId);

                        if (matchingSectionControl && matchingSectionControl.normalText) {
                            // Find the control with the corresponding childTagId in formArray
                            this.myformArray.controls.forEach((formGroup: FormGroup) => {
                                Object.keys(formGroup.controls).forEach(controlName => {
                                    const control = formGroup.get(controlName);
                                    if (control && control['id'] === childTagId) {
                                        // Perform the desired action with the matching control
                                        control.setValue(null);
                                    }
                                });
                            });
                        }
                    });
                }

            }
            if (script) {
                this.handleEvent(event, script, question, row, controls, mainformGroup)
            }
        }

        // Trigger the form control's value change to ensure it reflects the updated state
        formArray.updateValueAndValidity();


    }




    onRadioChange(option: any, row: FormGroup, controlName: string) {
        row.get(controlName).setValue(option);
    }

    onOpenlookUp(content: TemplateRef<any>, control: any) {
        this.modalRef1 = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "xl",
            windowClass: "custom-modal effect-scale"
        });
        // this.openLookupModal = false;
        this.selectedQuestionLabel = control;
        this.previousLookup = control;
    }
    handleLookupData(lookupData: { response: any, lookupId: number }): void {
        this.sendlookupselctedValue = lookupData.response;
        this.sendlookupID = lookupData.lookupId;
    }
    changeLookupOptions(question) {
        //if (!this.sendlookupID && this.sendlookupselctedValue.length == 0) {
        //    this.notifyService.warning("Please Select Lookup");
        //    return
        //}
        //if (this.sendlookupID && !this.sendlookupselctedValue || this.sendlookupselctedValue.length == 0) {
        //    this.notifyService.warning("Selected Lookup doesnt have any lookupValues");
        //    return
        //}
        if (!this.sendlookupID && this.sendlookupselctedValue.length == 0) {
            this.notifyService.warning("Please Select Lookup");
            return
        }
        if (this.sendlookupID && !this.sendlookupselctedValue || this.sendlookupselctedValue.length == 0 || this.sendlookupselctedValue.every(option => option.edit === true)) {
            this.notifyService.warning("Selected Lookup doesnt have any lookupValues");
            return
        }

        let lookupValues = this.sendlookupselctedValue
            .filter(option => !option.edit) // Filter out objects with edit true
            .map(option => option.name)
            .join(',');



        this.questions
        //let lookupValues = this.sendlookupselctedValue
        //    .filter(option => option.name !== undefined && option.name !== null)
        //    .map(option => option.name);


        var controls = [];
        this.container.filter(item => {
            const lowercaseName = item.name.toLowerCase();
            const lowercaseDropdownVal = this.dropdownselVal.toLowerCase();

            const isExactMatch = lowercaseName === lowercaseDropdownVal;

            if (isExactMatch) {
                controls = JSON.parse(item.view);

                controls.find(obj => {
                    if (obj.id === question.id) {
                        obj.lookupId = this.sendlookupID;
                        obj.htmlData = JSON.stringify(lookupValues);

                        item.view = JSON.stringify(controls);
                        this.httpService.post(ApiResources.getURI(ApiResources.tagformlist.base, ApiResources.tagformlist.update), item)
                            //.pipe(takeUntil(this.page.unSubscribe))
                            .pipe(finalize(() => {
                            }))
                            .subscribe((response: string) => {
                                this.fetchTableData();
                                question.htmlData = lookupValues;
                                question.lookupId = this.sendlookupID;
                                this.notifyService.success("Lookup Updated Sucessfully");
                                this.onOptionClose();
                            }, (error: HttpErrorResponse) => {
                                const errorMessage = UtilHelper.handleError(error);
                                if (errorMessage) {
                                    this.notifyService.warningToast(errorMessage);
                                } else {
                                    this.notifyService.defaultErrorToast();
                                }
                            });




                    }
                })


            }

        })



    }



    onOptionClose() {

        try {

            this.modalRef1.close();
            this.modalRef1 = undefined;
            //this.selectedDesign = '';
            //this.numOptions = undefined;
            //this.optionlist = [];
            //this.submitClicked = false;

        } catch (e) {
            // ignored
        }
    }
    saveFormData(dropdownselVal) {
        let modififed = this.page.userAccount.accountId;
        const formArrayValues = this.myformArray.controls.map((formGroup: FormGroup) => {
            const formGroupValue = formGroup.value;
            // Iterate through the keys in the form group value
            Object.keys(formGroupValue).forEach(key => {
                // Check if the value is an array
                if (Array.isArray(formGroupValue[key]) && formGroupValue[key].every(item => typeof item === 'object' && 'name' in item && 'value' in item)) {
                    // Filter out empty strings ('') from the array
                    const filteredValues = formGroupValue[key].filter(item => item.value !== "").map(item => item.value);

                    // Replace the key with the filtered values if they are not empty
                    if (filteredValues.length > 0) {
                        formGroupValue[key] = filteredValues;
                    } else {
                        delete formGroupValue[key];
                    }
                }
                // Accessing the id attribute
                let controlId;
                const control = formGroup.get(key);
                if (control instanceof FormControl && 'id' in control) {
                    controlId = control.id;
                }
            });



            return formGroupValue;
            //return formGroup.value;

        });





        // Check if all values (except "apointment_date") in the objects are empty or null
        const allValuesEmptyOrNull = formArrayValues.every(function (obj) {
            return Object.keys(obj).every(function (key) {
                return key === "apointment_date" || obj[key] === null || obj[key] === '';
            });
        });

        if (allValuesEmptyOrNull) {
            if (Object.keys(formArrayValues[0]).length === 1) {
                this.notifyService.warning("No Flowsheet Items Found")
            } else {
                this.notifyService.warning("Pleas Enter Values")
            }

            return
        }
        let modifiedFormArrayValues = formArrayValues.map(item => {
            let modifiedItem = {};
            for (let key in item) {
                modifiedItem[key] = item[key] === null ? "" : item[key];
            }
            return modifiedItem;
        });


        const updatedFormValues = formArrayValues.map(obj => {
            // Check if the object contains the 'apointment_date' key
            if ('apointment_date' in obj) {
                // Extract the value of the 'apointment_date' key
                const apointmentDate = obj['apointment_date'];

                // Create a new object with the 'apointment_date' key at the first position
                return { apointment_date: apointmentDate, ...obj };
            }

            // Return the original object if 'apointment_date' key is not present
            return obj;
        });
        this.submitting = true;
        var request = { PatientId: this.patientId, SectionID: this.sectionId, TemplateId: this.templateid, isactive: true, description: dropdownselVal, data: JSON.stringify(updatedFormValues), EncounterId: this.appointmentId, CreatedBy: modififed, TemplateIDs: this.TemplateIDs, FlowSheetSections: this.Sections, Type: 12 };




        this.httpService.post(ApiResources.getURI(ApiResources.sectiondata.base, ApiResources.sectiondata.insert), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.submitted = this.submitting = false))
            .subscribe((response: any) => {
                response
                this.notifyService.success("Data Saved Succesfully");

            });

    }


    onEditConytolsData() {
        if (this.editControlsForm.invalid) return;
        const request = this.editControlsForm.getRawValue();
        var controls = [];
        this.container.filter(item => {
            const i = item.name.toLowerCase().includes(this.dropdownselVal.toLowerCase());
            if (i) {
                controls = JSON.parse(item.type);
                controls.find(obj => {
                    if (obj.id === request.id) {
                        obj.label = request.label;


                        item.type = JSON.stringify(controls);

                        this.submitting = true;
                        this.httpService.post(ApiResources.getURI(ApiResources.tagformlist.base, ApiResources.tagformlist.update), item)
                            //.pipe(takeUntil(this.page.unSubscribe))
                            .pipe(finalize(() => {
                                this.submitting = false;
                                this.submitted = false;
                            }))
                            .subscribe((response: string) => {
                                this.fetchTableData()
                                this.notifyService.successToast(response);

                                this.onCloseModal();
                            }, (error: HttpErrorResponse) => {
                                const errorMessage = UtilHelper.handleError(error);
                                if (errorMessage) {
                                    this.notifyService.warningToast(errorMessage);
                                } else {
                                    this.notifyService.defaultErrorToast();
                                }
                            });
                    }

                })
            }
        });
    }


    private addTemplateMetod() {

        this.addTemplate = this.formbuilder.group({
            Name: [null]
        });
    }

    onOptionSelected(event: any) {
        this.dropdownselVal = event

        this.fetchTableData();
        this.sectionControls;
        // Clone the array using the spread operator and map function
        this.clonedArray = this.sectionControls.map(obj => ({
            ...obj,
            htmlData: obj.htmlData ? JSON.parse(obj.htmlData) : null
        }));

        var request = { PatientId: this.patientId, SectionID: this.sectionId, TemplateId: this.templateid };


        this.httpService.post(ApiResources.getURI(ApiResources.sectiondata.base, ApiResources.sectiondata.fetch), request)
            //.pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { }))
            .subscribe((response: any) => {

                if (response.data && (response.description === event)) {
                    let sectionDataValue = JSON.parse(response.data);
                    this.myformArray = this.formbuilder.array([]);

                    this.displayedHead = []
                    sectionDataValue.forEach((obj) => {
                        const formGroup = this.createFormGroup(obj);
                        this.myformArray.push(formGroup);
                        this.displayedHead.push(obj.date)

                    });
                } else {
                    this.displayedHead = [];
                    this.myformArray = this.formbuilder.array([]);

                }



            });
    }




    onCloseModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
            this.fetchTableData();
        } catch (e) {
            // ignored;
        }
    }

    // Navaigating to TagMaster Component 
    onOpenTagMaster(content: TemplateRef<any>) {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "xl",
            windowClass: "custom-modal effect-scale"
        });

        this.selectedOptions = []
        const selectedObject = this.container.find(option => option.name === this.dropdownselVal)
        this.selectedOptions.push(selectedObject)
        this.optionSelected.emit(this.selectedOptions);


    }
    onAddForm(content: TemplateRef<any>) {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "sm",
            windowClass: "custom-modal effect-scale"
        });
    }
    //forms creation
    saveTemplate() {

        const request = Object.assign(UtilHelper.clone(this.addTemplate.getRawValue()));

        this.submitting = true;
        this.httpService.post(ApiResources.getURI(ApiResources.tagformlist.base, ApiResources.tagformlist.insert), request)
            //.pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.submitting = false;
                this.submitted = false;
            }))
            .subscribe((response: string) => {
                this.notifyService.successToast(response);
                this.addTemplate.reset();
                this.fetchForms();
                // this.fetchFields();
            }, (error: HttpErrorResponse) => {
                const errorMessage = UtilHelper.handleError(error);
                if (errorMessage) {
                    this.notifyService.warningToast(errorMessage);
                } else {
                    this.notifyService.defaultErrorToast();
                }
            });



    }
    async fetchForms() {
        const request = { Type: 5 }
        await this.httpService
            .post<Array<any>>(ApiResources.getURI(ApiResources.tagformlist.base, ApiResources.tagformlist.fetchdynamicsections), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { }))
            .subscribe(
                (response: any) => {
                    this.container = [];

                    if (response.length > 0) {

                        this.container.push(...response);
                    }
                    if (this.optionchange || this.outsideEncounter) {
                        this.dropdownselVal = this.selsectionname;
                        //this.onOptionSelected(this.selsectionname)

                    } else {
                        this.fetchTableData()

                    }
                   
                    this.createSection();
                   
                },
                () => {
                    this.container = [];
                }
            );
    }


    outSideEncounterSection() {
        this.fetchTableData();
        this.dropdownselVal2 = true;
        if (this.sectionControls !== null) {

            this.clonedArray = this.sectionControls.map(obj => {
                try {
                    const parsedHtmlData = JSON.parse(obj.htmlData);
                    return {
                        ...obj,
                        htmlData: Array.isArray(parsedHtmlData) ? parsedHtmlData : obj.htmlData
                    };
                } catch (error) {
                    return {
                        ...obj,
                        htmlData: obj.htmlData
                    };
                }
            });


            this.sectionControls.forEach(question => {
                if (question.lookupId) {
                    const matchedObjects = this.groupOfLookupValues.filter(obj => obj.lookupId === question.lookupId);
                    const namesArray = matchedObjects.map(obj => obj.name);
                    question.htmlData = namesArray;
                } else if ((typeof question.htmlData === 'string' && /^\[.*\]$/.test(question.htmlData) && question.selectedOption == 'manual')) {
                    try {
                        question.htmlData = JSON.parse(question.htmlData);
                    } catch (error) {
                        console.error('Error parsing JSON string:', error);
                    }

                }

                if (question?.styleProperties) {
                    try {

                        const isStringifiedJson = typeof question.styleProperties === 'string' && question.styleProperties.trim().startsWith('{') && question.styleProperties.trim().endsWith('}');

                        if (isStringifiedJson) {
                            // Parse JSON string if it's a stringified JSON object
                            question.styleProperties = JSON.parse(question.styleProperties);
                        }
                    } catch (error) {
                        console.error('Error parsing JSON string:', error);
                    }

                }


            });

            this.clonedArray = this.sectionControls;

        }



        // Iterate through the array and modify values with a trailing '.'
        this.clonedArray.forEach(item => {
            for (const key in item) {
                if (item.hasOwnProperty(key) && typeof item[key] === 'string' && item[key].endsWith('.')) {
                    item[key] = item[key].slice(0, -1); // Remove the trailing '.'
                }
            }
            if (item.id == this.masterTags[0]?.id) {
                item.htmlData = this.masterTags[0].htmlData;
            }

        });


        this.form = this.qcs.toFormGroup(this.clonedArray);
        this.myformArray.controls.push(this.form)


        let allLogicContainers = [];

        if (this.scriptContent) {
            Object.keys(this.scriptContent).forEach(key => {
                const items = this.scriptContent[key];

                // Iterate over each object in the array
                items.forEach(item => {
                    if (item.logicContainer) {
                        allLogicContainers.push(item.logicContainer);
                    }
                });
            });

            // allLogicContainers.push(this.scriptContent)
            // const scriptContent = allLogicContainers.join('\n');
            const scriptContent = this.transpileTypeScript(allLogicContainers.join('\n'));

            // create a script element and set its content to the transpiled javascript code
            const scriptcode = document.createElement('script');
            scriptcode.textContent = scriptContent;

            // append the script element to the document body to execute the function
            document.body.appendChild(scriptcode);

        }




        this.addCustomStyles();
        this.updateCustomStyles();
    }
    //fetchTableData(sectionName?) {
    //    if (this.SectionName) {
    //        this.dropdownselVal = this.SectionName
    //    }
    //    this.bindLookupValues = [];
    //    if (this.dropdownselVal) {
    //        this.container.find(option => {
    //            if (option.name === this.dropdownselVal) {
    //                this.formView = option;
    //                const j = option.view
    //                this.sectionControls = JSON.parse(j);
    //                this.cssStyles = option.cssStyles ? JSON.parse(option.cssStyles) : null;
    //                this.scriptContent = JSON.parse(this.formView.script) ? JSON.parse(this.formView.script) : { Section: [] };
    //                this.sectionControls = this.sectionControls.map(question => {
    //                    if (question.controlType === 'container' && typeof question.childGroupTags === 'string') {
    //                        try {
    //                            // Parse childGroupTags if it's a string
    //                            question.childGroupTags = JSON.parse(question.childGroupTags);
    //                        } catch (error) {
    //                            question.childGroupTags = question.childGroupTags;
    //                        }
    //                    }
    //                    // Return the updated question
    //                    return question;
    //                });

    //                this.manageControls = this.sectionControls;
    //                this.sectionControls.forEach(data => {
    //                    if (data?.lookupId) {
    //                        this.bindLookupValues.push(data?.lookupId)

    //                    }
    //                })
    //            }
    //        })
    //    }

    //}




    fetchTableData() {

        this.sectionControls = [];
        this.bindLookupValues = [];
        if (this.dropdownselVal) {
            this.container.find(option => {
                if (option.name === this.dropdownselVal) {
                    this.sectionContainer = option
                    this.cssObjectTotal = JSON.parse(option.cssStyles);
                    this.scriptContent = JSON.parse(option.script);
                    this.sectionControls = JSON.parse(option.view);
                    this.sectionControls = this.sectionControls.map(question => {
                        if ((question.controlType === 'container' || question.controlType === 'grid') && typeof question.childGroupTags === 'string') {
                            try {
                                // Parse childGroupTags if it's a string
                                question.childGroupTags = JSON.parse(question.childGroupTags);
                            } catch (error) {
                                question.childGroupTags = question.childGroupTags;
                            }
                        }
                        // Return the updated question
                        return question;
                    });

                    // this.manageControls = this.flowSheetControls;
                    //this.SectionControls.forEach(data => {
                    //    if (data?.lookupId) {
                    //        this.bindLookupValues.push(data?.lookupId)

                    //    }
                    //})
                    //if (this.bindLookupValues.length > 0) {
                    //    this.getLookupOptions()
                    //}
                    // reorder flowsheetcontrols based on orderalignment
                    //this.flowSheetControls.sort((a, b) => {
                    //    const orderalignmenta = JSON.parse(a.styleproperties)?.orderallignment;
                    //    const orderalignmentb = JSON.parse(b.styleproperties)?.orderallignment;

                    //    // prioritize controls with 'top' alignment to appear at the top
                    //    if (orderalignmenta === 'top' && orderalignmentb !== 'top') return -1;
                    //    if (orderalignmentb === 'top' && orderalignmenta !== 'top') return 1;

                    //    // prioritize controls with 'bottom' alignment to appear at the end
                    //    if (orderalignmenta === 'bottom' && orderalignmentb !== 'bottom') return 1;
                    //    if (orderalignmentb === 'bottom' && orderalignmenta !== 'bottom') return -1;

                    //    return 0; // default case
                    //});


                }
            })


        }






    }
    preview(content: TemplateRef<any>) {

        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "xl",
            windowClass: "custom-modal effect-scale"
        });
        this.questions = [];
        if (this.dropdownselVal != undefined || this.dropdownselVal != null) {
            this.questions = this.container.find(option => option.name === this.dropdownselVal);
            this.questions = JSON.parse(this.questions.type);
        }

    }



    onOpenControlEditModal(content: TemplateRef<any>, data: any) {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "xl",
            windowClass: "custom-modal effect-scale"
        });

        var controls = [];

        this.container.filter(item => {
            const i = item.name.toLowerCase().includes(this.dropdownselVal.toLowerCase());
            if (i) {
                controls = JSON.parse(item.type)
                const object = controls.find(obj => obj.id === data.id);

                this.editControlsForm.patchValue({
                    label: object.label,
                    id: object.id
                })
            }
        });
    }





    deleteFields(data: any) {

        this.notifyService.delete(() => {
            var controls = [];

            this.container.filter(item => {
                const i = item.name.toLowerCase().includes(this.dropdownselVal.toLowerCase());
                if (i) {
                    controls = JSON.parse(item.type)
                    controls.splice(controls.findIndex(a => a.id === data.id), 1)
                    item.type = JSON.stringify(controls);

                    this.httpService.post(ApiResources.getURI(ApiResources.tagformlist.base, ApiResources.tagformlist.update), item)
                        //.pipe(takeUntil(this.page.unSubscribe))
                        .pipe(finalize(() => {
                        }))
                        .subscribe((response: string) => {
                            this.fetchTableData()
                            this.notifyService.successToast(response);

                            this.onCloseModal();
                        }, (error: HttpErrorResponse) => {
                            const errorMessage = UtilHelper.handleError(error);
                            if (errorMessage) {
                                this.notifyService.warningToast(errorMessage);
                            } else {
                                this.notifyService.defaultErrorToast();
                            }
                        });
                }

            });



        });
    }
    onOpenControlsArrangment(content: TemplateRef<any>) {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "sm",
            windowClass: "custom-modal effect-scale"
        });

    }


    dropItem(event: CdkDragDrop<string[]>) {

        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }

    }

    onArrangments(group) {

        group
        this.container.find(option => {
            if (option.name == this.dropdownselVal) {
                option.type = JSON.stringify(group)

                this.httpService.post(ApiResources.getURI(ApiResources.tagformlist.base, ApiResources.tagformlist.update), option)
                    //.pipe(takeUntil(this.page.unSubscribe))
                    //.pipe(finalize(() => {
                    //}))
                    .subscribe((response: string) => {
                        this.fetchForms();
                        this.notifyService.successToast(response);

                        this.onCloseModal();
                    }, (error: HttpErrorResponse) => {
                        const errorMessage = UtilHelper.handleError(error);
                        if (errorMessage) {
                            this.notifyService.warningToast(errorMessage);
                        } else {
                            this.notifyService.defaultErrorToast();
                        }
                    });




            }
        });








    }




    getDateWise() {

        this.myformArray.controls.find(x => {
            var givenDate = x.get("date").value;
            var startdate = new Date(`${givenDate}`);
            //this.isSameDate(x.get("date"), givenDate));
            const currentDate = new Date();
            if (startdate.toDateString() === currentDate.toDateString()) {
                this.Today_values = x.value
            }


        }

        );



    }


    ngOnDestroy() {
        this.onCloseModal();
        this.page.unsubscribeAll();
        Swal.close();

        this.removeCustomStyles();
    }


    fetchScoreValues() {
        this.httpService
            .get<Array<any>>(ApiResources.getURI(ApiResources.template.base, ApiResources.template.fetchall))
            .subscribe(
                (response: Array<any>) => {
                    this.Tscore = response;

                })
    }
    isStringifiedArray(value: string): boolean {
        const regex = /^\[\s*(?:"[^"]*"|'[^']*'|[^,"'\s]+)(?:\s*,\s*(?:"[^"]*"|'[^']*'|[^,"'\s]+))*?\s*\]$/;
        return regex.test(value);
    }

    handleSave() {
        if (this.encounterModeType == 1 && this.encounterType == 2) {
            this.saveSectionData(this.dropdownselVal); // Only execute this if `addTagToContainer` is true
           
        } else {
             this.saveSectionData(this.dropdownselVal);
        }
    }

    handleClose() {
        if (this.encounterModeType == 1 && this.encounterType == 2) {
            this.closeModalAppointment.emit();
        }
    }
    closeModal() {
        this.selectionChange.emit({ action: 'close'});
    }
    addRow(formArrayName: any) {
         
        const formArray = this.form.get(formArrayName.label) as FormArray;
        let findobj = this.clonedArray.find(obj => obj.id == formArrayName.id)
      
        let ab = [];
        ab.push(findobj)
        let formGrp = this.qcs.toFormGroup(ab);

        const clonedRowGroup = formGrp.get(formArrayName.label) as FormArray
        formArray.push(clonedRowGroup.controls[0]);
       
    }
    generateFormArrayGrops(formArrayControl: any, valuesOfFormArray) {

        const formArray = this.form.get(formArrayControl.labelName) as FormArray;
        let findobj = this.clonedArray.find(obj => obj.id == formArrayControl.id)

        let ab = [];
        ab.push(findobj);
        if (valuesOfFormArray) {
            if (valuesOfFormArray.length > 1) {
                for (let i = 0; i < valuesOfFormArray.length; i++) {
                    let formGrp = this.qcs.toFormGroup(ab);
                    const clonedRowGroup = formGrp.get(formArrayControl.labelName) as FormArray
                    formArray.push(clonedRowGroup.controls[0]);
                }
            }
        }
        if (formArray.controls.length > 1) {
            formArray.removeAt(0);
        }
        if (valuesOfFormArray) {
            formArray.controls.forEach((formGroup: FormGroup, index: number) => {
                // Get the corresponding row from valuesOfFormArray based on the index
                const rowValue = valuesOfFormArray[index];
                // Loop through each key in rowValue (i.e., the control names)
                Object.keys(rowValue).forEach((key) => {
                    // Get the corresponding control in the FormGroup
                  const control = formGroup.controls[key];

                    if (control && rowValue[key]) {

                        if (control instanceof FormArray && (control as any).controlType == 'checkbox') {
                            const formArray = control as FormArray;

                            formArray.controls = formArray.controls.map((control) => {
                                const controlFormArray = control.value.name;

                                if (rowValue[key].indexOf(controlFormArray) !== -1) {
                                    // Matched, set the value to the control
                                    control.setValue({ value: controlFormArray, name: controlFormArray, isChecked: true });
                                } else {
                                    // Not matched, set the value to an empty string
                                    control.setValue({ value: '', name: controlFormArray, isChecked: false });
                                }

                                return control;
                            });

                        } else if (control as FormControl) {
                            // Set the value for each control
                            control.setValue(rowValue[key]);
                        }

                        
                    }
                });
            });
        }
    }



    deleteRow(formArrayName: string, rowIndex: number) {
         
        const formArray = this.form.get(formArrayName) as FormArray;
        formArray.removeAt(rowIndex);
    }

    ngAfterViewInit(): void {
        // Make sure the data (clonedArray and scriptContent) is populated
        if (this.clonedArray && this.scriptContent && this.myformArray) {
            // Emit an event to notify the parent that the data is ready
            this.dataReady.emit();
        }
    }

    objectKeys(obj: any): string[] {
        return Object.keys(obj);
    }

}