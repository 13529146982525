import { Component, ViewEncapsulation, OnDestroy, OnInit } from "@angular/core";
import { AppData, HttpService, NotifyService, ResourceService } from "../../../../../shared/services";
import { Page, IUserAccount, Pagination, AdmissionInformationModel, IResource, GenericResponse } from "../../../../../shared/models";
import { takeUntil, finalize } from "rxjs/operators";
import { ApiResources, UtilHelper } from "@shared/helpers";
import { Admission, Setting } from "../../../../../shared/entities";
import { event } from "jquery";


class FilterOptions {
    fromDate: string;
    toDate: string;
    requisitionNumber: string;
    departmentName: string;
    testName: string;
    locationId: number;
    bookingType: string = null;
    labBookingStatusId?: number = null;
    patientId: number;
    locationName: string;
    patientName: any;
    umrNo: string;
    mobile: string;
    caseTypeName: number;
    insuranceCompanyName: number;
    admissionPayTypeName: string = null;
    counsellingId: number ;
    chargeCategoryName: number;
    admissionNo: number;
    doctorName: string;
    unitName: string;
    providerId: any;
    admissionDate: any;
}

class Filters {
    options: FilterOptions;
    applied: boolean;

    init() {
        this.options = new FilterOptions();
        this.applied = false;
    }

    constructor() { this.init(); }

}


@Component({
    templateUrl: "./ip-information.html",
    encapsulation: ViewEncapsulation.None,
    styleUrls: ["./ip-information.css"]

})

export class IPInformationPage implements OnInit, OnDestroy {
    page: Page;
    filters: Filters;
    pagination: Pagination;
    loading: boolean;
    records: Array<AdmissionInformationModel>;
    filter1Applied: boolean;
    locations: Array<IResource>;
    filterPatient: any;
    loadingCaseTypes: boolean;
    caseTypes: Array<IResource>;
    insuranceCompanies: Array<IResource>;
    admissionPayType: Array<IResource>;
    chargeCategory: Array<IResource>;
    admissionNos: Array<IResource>;
    loadingInsuranceCompanies: boolean;
    loadingAdmissionPayType: boolean;
    loadingChargeCategory: boolean;
    loadingAdmissionNo: boolean;
    providers: any;
    unitsdata: any;
    ipInformation: any;
    totalDiscount: number;
    ipInformationData: GenericResponse;
    admittedData: Admission[];
    patients: Array<IResource>;
    searching: boolean;
    colSpan: number;
    ipInformationValidation: any;

    isShowBedNo: boolean;
    isShowPatientName: boolean;
    isShowAdmissionNo: boolean;
    isShowCaseType: boolean;
    isShowPayType: boolean;
    isShowTotal: boolean;
    isShowAdvance: boolean;
    isShowPending: boolean;
    isShowMobile: boolean;
    isShowDoctor: boolean;
    isShowNoofDays: boolean;
    isShowUmrno: boolean;
    isShowAdmissionDate: boolean;
    isShowInsuranceCompany: boolean;
    isShowAttendant: boolean;
    isShowDoctorUnit: boolean;
    isShowCounselling: boolean;
    isShowBillingCategory: boolean;
    isShowRefunded: boolean;
    isShowAutoServices: boolean;
    isShowRefundAmount: boolean;
    isShowPackageserviceTotal: boolean;
    isShowServiceOrderTotal: boolean;
    isShowLabServiceTotal: boolean;
    isShowDoctorVisitTotal: boolean;
    isShowScanServiceTotal: boolean;
    isShowSurgeryServiceTotal: boolean;
    isShowRoomVisitTotal: boolean;
    isShowSurgenChargeServiceTotal: boolean;
    isShowPharmacyTotal: boolean;

    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
        private readonly resourceService: ResourceService,
    ) {
        this.filters = new Filters();
        this.page = new Page();
        this.initPagination();
        this.records = new Array<AdmissionInformationModel>();
        this.locations = new Array<IResource>();
    }
    private initPagination() {
        this.pagination = new Pagination();
        this.pagination.pageIndex = 1;
        this.pagination.pageSize = 10;
    }

    private fetchCaseType() {
        this.loadingCaseTypes = true;
        this.resourceService.caseTypes()
            .pipe(finalize(() => { this.loadingCaseTypes = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.caseTypes = response;
            });
    }
    private fetchInsuraceCompany() {
        this.loadingInsuranceCompanies = true;
        this.resourceService.insuranceCompanies()
            .pipe(finalize(() => { this.loadingInsuranceCompanies = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.insuranceCompanies = response;
            });
    }
    private fetchAdmissionPayType() {
        this.loadingAdmissionPayType = true;
        this.resourceService.admissionPayTypes()
            .pipe(finalize(() => { this.loadingAdmissionPayType = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.admissionPayType = response;
            });
    }

    private fetchChargeCategory() {
        this.loadingChargeCategory = true;
        this.resourceService.chargeCategory()
            .pipe(finalize(() => { this.loadingChargeCategory = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.chargeCategory = response;
            });
    }
    private fetchAdmissionNo(locId?:number) {
        this.loadingAdmissionNo = true;
        let locationId = locId ?? this.filters.options.locationId;

        this.resourceService.AdmissionNo(locationId)
            .pipe(finalize(() => { this.loadingAdmissionNo = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.admissionNos = response;
            });
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.filters.options.locationId = this.page.userAccount.locationId;
                    this.filters.options.locationName = this.page.userAccount.locationName;
                    if (this.filters.options.locationName != null) {
                        this.filter1Applied = true;
                    }
                    this.fetchCommonSettings();
                    this.fetchLocations();
                    this.fetchIpInformation();
                    this.fetchCaseType();
                    this.fetchInsuraceCompany();
                    this.fetchAdmissionPayType();
                    this.fetchChargeCategory();
                    this.fetchAdmissionNo();
                    this.fetchDoctorUnit();
                    this.fetchAdmissions(this.filters.options.locationId);
                    this.fetchProviders(this.page.userAccount.locationId);
                } else {
                    this.page.userAccount = undefined;
                }
            });

    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
    

    private fetchLocations() {
        this.resourceService.locations()
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.locations = response;
            });
    }

    onApplyFilters() {
        this.filters.applied = UtilHelper.isFiltersApplied(this.filters.options);
        this.initPagination();
        this.fetchIpInformation();
        this.onCloseFilters();
    }

    onResetFilters() {
        this.filters.init();
        this.filters.options.locationId = this.page.userAccount.locationId;
        this.initPagination();
        this.fetchIpInformation();
        this.onCloseFilters();

    }
    onNextPage() {
        this.fetchIpInformation();
    }
    private fetchIpInformation() {
        const request = {
            ...this.filters.options,
              ...this.pagination
        };
        this.loading = true;
        this.httpService.post(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchIPInformation), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loading = false }))
            .subscribe((response: Array<AdmissionInformationModel>) => {
                this.records = new Array<AdmissionInformationModel>();
                this.records = response;
                this.records.forEach(x => {
                    x.taggedDoctors = x.taggedDoctors?.toUpperCase();
                   this.fetchOverAllDiscount(x.admissionId ,x);
                })     
                this.filterPatient = this.records
                this.records.forEach((item) => {
                    item.isBilledAmountIsGreaterThanAdvance = item.total > item.paidAmount && item.paidAmount > 0 && item.repeatTypeId == 3;
                    item.isAutoServices = item.total > item.paidAmount && item.paidAmount > 0;
                })
                
                UtilHelper.applyPagination(this.records, this.pagination);

            }, () => {
                this.records = new Array<AdmissionInformationModel>();
                this.filterPatient = this.records
                
            });

    }
    customPatientSearchPatient(term: string, item) {
        return item['value'].toLocaleLowerCase().indexOf(term) > -1 || item['optionalText'].indexOf(term) > -1 || item['optionalText1'].indexOf(term) > -1;
    }
    
    fetchPatientNameMobileUmrHelper($event?: any) {
        if ($event.term.length < 1)
            this.onApplyFilters();

        if ($event && $event.term && $event.term.length > 2) {
            var temp = [];
            for (let row of this.records) {
                var stk = `^${$event.term}`;
                const regexp = new RegExp(stk.toLowerCase());
                if (regexp.test(row.patientName.toLowerCase())) { temp.push(row); }
            }
            this.records = temp;
            let locationId = this.page.userAccount.locationId;
            this.fetchPatientNameMobile($event.term, locationId);

        } else {
            this.filterPatient = new Array<IResource>();
        }
    }

    private fetchPatientNameMobile(patientId?: string, locationId?: number) {

        this.loading = true;
        var request = {
            filter: patientId,
            locationId: locationId,
        }
        this.httpService
            .get<Array<IResource>>(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchAdmissionPatientNameMobileUmr), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<IResource>) => {
                    this.patients = response;
                },
                () => {
                    this.patients = new Array<IResource>();
                }
            );
    }
    fetchProviders(id?: number) {
        this.resourceService.providerAccountLocationMap(id)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.providers = response;              

            });
    }

    private fetchDoctorUnit() {   
        this.httpService.post(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchDoctorUnit), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<any>) => {               
                this.unitsdata = response;
            })
    }
    public fetchOverAllDiscount(admissionId: number, model: AdmissionInformationModel){
        const request = {
            id: admissionId.toString(),
            isAdmission: true,
            activeOnly: false,
            isDischarged: true,
            active: true 
        }
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.serviceOrder.base, ApiResources.serviceOrder.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: GenericResponse) => {
                    this.totalDiscount = 0;
                    this.ipInformationData = response;
                    this.ipInformation = this.ipInformationData["data"];
                    var overAllDiscount = this.ipInformation["overallServiceDiscounts"];
                    overAllDiscount?.forEach((item) => {
                        if (item?.discountType == 1) {
                            this.totalDiscount += item?.discountAmount;
                        } else if (item?.discountType == 2) {
                            var service;
                            if (item?.serviceCode == "general_services") {
                                 service = this.ipInformation["generalRecords"];
                            }
                            else if (item?.serviceCode == "lab_services") {
                                 service = this.ipInformation["labServices"];
                            }
                            else if (item?.serviceCode == "ot_services") {
                                 service = this.ipInformation["surgeryServiceRecords"];
                            }
                            else if (item?.serviceCode == "scan_services") {
                                 service = this.ipInformation["scanServiceRecords"];
                            }
                            else if (item?.serviceCode == "doctor_visits") {
                                 service = this.ipInformation["doctorVisitRecords"];
                            }
                            else if (item?.serviceCode == "bed_services") {
                                 service = this.ipInformation["bedServiceRecords"];
                            }
                            var totalAmount = 0;
                            service.forEach((data) => {
                                totalAmount += data?.cost
                            })
                            if (totalAmount > 0) {
                                this.totalDiscount += (totalAmount / 100) * item?.discountPercentage
                            }
                        }
                        
                    })
                    this.records.forEach((record) => {
                        if (record.admissionId == model.admissionId) {
                            record.total = record.total - this.totalDiscount;
                            record.pendingAmount = record.pendingAmount - this.totalDiscount;
                        }
                    })
                })
        
       
    }
    private fetchAdmissions(locationId:number) {
        const request = {};
        request["locationId"] = locationId;
        request["isDischarged"] = false;
        request["active"] = true;

        this.httpService
            .post<Array<Admission>>(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<Admission>) => {
                    this.admittedData = response;
                },
                () => {
                    
                }
            );
    }
    onChangeLocation(data: any) {
        if (data) {
            this.fetchAdmissions(data.id);
            this.fetchAdmissionNo(data.id);
        }
    }
    onShowFilters() {
        $("body").addClass("right-bar-enabled");
    }

    onCloseFilters() {
        $("body").removeClass("right-bar-enabled");
    }
    private fetchCommonSettings() {
        this.loading = true;
        const request = {
        }
        request["name"] = "IpInformation Validation";
        request["type"] = "Admission";

        this.httpService.get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<Setting>) => {
                if (response) {
                    this.ipInformationValidation = response[0].value ? JSON.parse(response[0].value) : null;
                    this.isShowBedNo = response[0].active && this.ipInformationValidation.bedNo && this.ipInformationValidation["bedNoRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowPatientName = response[0].active &&  this.ipInformationValidation.patientName && this.ipInformationValidation["patientNameRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowAdmissionNo = response[0].active &&  this.ipInformationValidation.admissionNo && this.ipInformationValidation["admissionNoRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowCaseType = response[0].active &&  this.ipInformationValidation.caseType && this.ipInformationValidation["caseTypeRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowPayType = response[0].active &&  this.ipInformationValidation.payType && this.ipInformationValidation["payTypeRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowTotal = response[0].active &&  this.ipInformationValidation.total && this.ipInformationValidation["totalRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowAdvance = response[0].active &&  this.ipInformationValidation.advance && this.ipInformationValidation["advanceRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowPending = response[0].active &&  this.ipInformationValidation.pending && this.ipInformationValidation["pendingRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowMobile = response[0].active &&  this.ipInformationValidation.mobile && this.ipInformationValidation["mobileRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowDoctor = response[0].active &&  this.ipInformationValidation.doctor && this.ipInformationValidation["doctorRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowNoofDays = response[0].active &&  this.ipInformationValidation.noofDays && this.ipInformationValidation["noofDaysRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowUmrno = response[0].active &&  this.ipInformationValidation.umrno && this.ipInformationValidation["umrnoRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowAdmissionDate = response[0].active &&  this.ipInformationValidation.admissionDate && this.ipInformationValidation["admissionDateRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowInsuranceCompany = response[0].active &&  this.ipInformationValidation.insuranceCompany && this.ipInformationValidation["insuranceCompanyRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowAttendant = response[0].active &&  this.ipInformationValidation.attendant && this.ipInformationValidation["attendantRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowDoctorUnit = response[0].active &&  this.ipInformationValidation.doctorUnit && this.ipInformationValidation["doctorUnitRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowCounselling = response[0].active &&  this.ipInformationValidation.counselling && this.ipInformationValidation["counsellingRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowBillingCategory = response[0].active &&  this.ipInformationValidation.billingCategory && this.ipInformationValidation["billingCategoryRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowRefunded = response[0].active &&  this.ipInformationValidation.refunded && this.ipInformationValidation["refundedRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowAutoServices = response[0].active &&  this.ipInformationValidation.autoServices && this.ipInformationValidation["autoServicesRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowRefundAmount = response[0].active && this.ipInformationValidation.refundAmount && this.ipInformationValidation["refundAmountRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowPackageserviceTotal = response[0].active &&  this.ipInformationValidation.packageserviceTotal && this.ipInformationValidation["packageserviceTotalRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowServiceOrderTotal = response[0].active &&  this.ipInformationValidation.serviceOrderTotal && this.ipInformationValidation["serviceOrderTotalRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowLabServiceTotal = response[0].active &&  this.ipInformationValidation.labServiceTotal && this.ipInformationValidation["labServiceTotalRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowDoctorVisitTotal = response[0].active &&  this.ipInformationValidation.doctorVisitTotal && this.ipInformationValidation["doctorVisitTotalRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowScanServiceTotal = response[0].active &&  this.ipInformationValidation.scanServiceTotal && this.ipInformationValidation["scanServiceTotalRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowSurgeryServiceTotal = response[0].active &&  this.ipInformationValidation.surgeryServiceTotal && this.ipInformationValidation["surgeryServiceTotalRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowRoomVisitTotal = response[0].active &&  this.ipInformationValidation.roomVisitTotal && this.ipInformationValidation["roomVisitTotalRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowSurgenChargeServiceTotal = response[0].active &&  this.ipInformationValidation.surgenChargeServiceTotal && this.ipInformationValidation["surgenChargeServiceTotalRoleIds"].includes(this.page.userAccount.roleId);
                    this.isShowPharmacyTotal = response[0].active &&  this.ipInformationValidation.pharmacyTotal && this.ipInformationValidation["pharmacyTotalRoleIds"].includes(this.page.userAccount.roleId);
                }
            });
    }
}