import { ChargeModuleDetailsModel, PackageModuleDetailModel, PackageModuleViewModel, PackageType, SurgeryLinkModel } from "@admin/masters/pages/package-modules/models";
import { Component, Input, OnChanges, OnDestroy, ViewEncapsulation } from "@angular/core";
import { ApiResources } from "@shared/helpers";
import { IResource, Page, Counselling } from "@shared/models";
import { HttpService, NotifyService, ResourceService } from "@shared/services";
import { finalize, takeUntil } from "rxjs/operators";
import { IAdmissionPackageModuleModel } from "../../../../../services/models/admission-package.model";
import { ModuleModel } from "@admin/masters/pages/package-modules/models";

@Component({
    templateUrl: "./view-package-module.html",
    selector: "view-package-module",
    styleUrls: ['./view-package-module.css'],
    encapsulation: ViewEncapsulation.None
})
export class ViewPackageModuleComponent implements OnChanges, OnDestroy {
    packageType = PackageType;

    @Input() packageModuleId: number;
    @Input() packageModuleIds: string;
    @Input() isCounselling: boolean;
    @Input() chargeCategory!: IResource;
    @Input() hidePrice!: boolean;
    @Input() isShow: boolean;
    @Input() packages: Array<IAdmissionPackageModuleModel>;
    @Input() showReport: boolean;
    @Input() isFooter: boolean;
    @Input() reportName: string;
    @Input() chargeModuleTemplateId: number;
    @Input() templateName: string;
    @Input() counsellingId: number;
    @Input() extraExclusionsNotes: string;
    @Input() counselling: Counselling;   
    @Input() doctorUnitMasterId: number;   

    @Input() counsellingAttendant: Array<Counselling>;
    @Input() isFromCouselling: boolean;
    @Input() locationName: string;
    @Input() locationId: number;  
    page: Page;
    loading: boolean;
    packageInfo: PackageModuleViewModel;
    packageDetails: Array<PackageModuleDetailModel>;
    packageInfoAll: Array<PackageModuleViewModel>;
    packageInfoForServices: PackageModuleViewModel;
    loadingChargeCategories: boolean;
    chargeCategories: Array<IResource>;
    loadingCharges: boolean;
    charges: Array<ChargeModuleDetailsModel>;
    hidePrices: boolean;
    showChargeCategories: boolean;

    surgeryLinkServices: Array<SurgeryLinkModel>;
    packageNames: string[];
    attendentName: string;
    attendentRelation: string;
    attendentContactNo: string;
    husbandName: string;
    loadingLocations: boolean;
    locations: Array<IResource>;
    loadingChargeTemplates: boolean;
    chargeTemplates: Array<IResource>;
    date: Date;  
    constructor(private readonly httpService: HttpService, private readonly notifyService: NotifyService, private readonly resourceService: ResourceService) {
        this.page = new Page();
        this.packageInfoAll = new Array<PackageModuleViewModel>();
        this.surgeryLinkServices = new Array<SurgeryLinkModel>();
        this.date = new Date();
    }

    ngOnChanges(): void {
        if (this.isCounselling)
            this.hidePrices = true;
        if (this.packages && this.packages.length > 0 && this.showReport) {
            this.findPackageInfo(null, this.packageModuleIds);
        }
        else if (this.packages && this.packages.length > 0) {
            this.packages.forEach(item => {
                if (this.isShow)
                    this.findPackageInfo(item.packageModuleId, item.createdDate);
                else
                    this.findPackageInfo(item.packageModuleId);
            })
        }
        else {

            this.findPackageInfo();
        }
        this.fetchLocations();
        if (this.counsellingAttendant) {
            for (const counseling of this.counsellingAttendant) {
                if (counseling.isAttendant == true) {
                    this.attendentName = this.counselling.relativeName;
                    this.attendentRelation = this.counselling.relation;
                    this.attendentContactNo = this.counselling.contactNo;
                }
            }
        }      
    }
    fetchPackageModuleDetails(packageModuleId: number): Array<ModuleModel> {
        return this.packageInfo?.modules?.filter(m => m.packageModuleId === packageModuleId) || [];
    }
    fetchPackageDetails(modulesMasterId: number): Array<PackageModuleDetailModel> {
        return this.packageInfo?.packageModuleDetails?.filter(m => m.modulesMasterId === modulesMasterId) || [];
    }
    fetchModulePackageDetails(packageModuleId: number): Array<PackageModuleDetailModel> {
        return this.packageInfo?.packageModuleDetails?.filter(m => m.packageModuleId === packageModuleId) || [];
    }
    fetchSurgeries(surgeryId: number) {
        return this.surgeryLinkServices?.filter(m => m.surgeryId === surgeryId) || [];
    }

    fetchPackageDetailsAll(modulesMasterId: number, packModel: PackageModuleViewModel): Array<PackageModuleDetailModel> {
        return packModel?.packageModuleDetails?.filter(m => m.modulesMasterId === modulesMasterId) || [];
    }

    onSelectChargeCategory(chargeCategoryId: number): void {
        if (chargeCategoryId) {
            this.chargeCategory = this.chargeCategories?.find(m => m.id === chargeCategoryId);
            this.fetchCharges();
        }
    }

    onClearChargeCategory(): void {
        this.packageInfo.totalQuantity = 0;
        this.packageInfo.freeQuantity = 0;
        this.packageInfo.discount = 0;
        this.packageInfo.subTotal = 0;
        this.packageInfo.freeQuantityTotal = 0;
        this.packageInfo.total = 0;
        this.packageInfo?.packageModuleDetails?.every(m => m.isNA = false);
    }

    ngOnDestroy(): void {
        this.packageInfo = null;
        this.packageNames = [];
        this.page.unsubscribeAll();
    }
    private findPackageInfo(id?: number, admissionPackageDate?: any): void {
        this.loading = true;
        let requestBody = {}
        if (this.showReport) {
            requestBody = Object.assign({ packageModuleIds: this.packageModuleIds, counsellingId: this.counselling.counsellingId });

        } else {
            requestBody = Object.assign({ packageModuleId: id ? id : this.packageModuleId });
        }
        let moduleId = { packageModuleId: id ? id : (this.packageModuleId > 0 ? this.packageModuleId : null) };
        if (moduleId == null) {
            this.loading = false;
            return;
        }
        requestBody = { ...requestBody, chargeModuleTemplateId: this.chargeModuleTemplateId }
        if (this.showReport) {
            this.httpService
                .get(ApiResources.getURI(ApiResources.packageModule.base, ApiResources.packageModule.detailReport), requestBody)
                .pipe(finalize(() => this.loading = false), takeUntil(this.page.unSubscribe))
                .subscribe({
                    next: (response: PackageModuleViewModel) => {
                        this.packageInfo = response;
                        this.preprocessModules();
                        if (this.packageInfo && this.packageInfo.packageModules) {
                            this.packageNames = this.packageInfo.packageModules.map(m => m.packageName);                  
                        }
                    }
                });
        }
        else {
            this.httpService
                .get(ApiResources.getURI(ApiResources.packageModule.base, ApiResources.packageModule.view), requestBody)
                .pipe(finalize(() => this.loading = false), takeUntil(this.page.unSubscribe))
                .subscribe({
                    next: (response: PackageModuleViewModel) => {
                        this.packageInfo = response;
                        this.preprocessModules();
                        if (admissionPackageDate)
                            this.packageInfo.admissionPackageDate = admissionPackageDate;
                        if (this.isCounselling) {
                            this.fetchCharges();
                        } else {
                            if (this.packageInfo?.packageModule?.packageType === "O") {
                                this.chargeCategory = this.packageInfo.chargeCategory;
                                this.fetchCharges();
                            }
                            else if (this.chargeCategory) {
                                this.setChareCategoryTotals(this.chargeCategory);
                                
                                this.fetchCharges();
                            }
                          
                             
                        }
                        this.packageInfoAll.push(this.packageInfo);
                    },
                    error: () => this.packageInfo = undefined
                });
        }
    }
    private fetchChargeCategories(): void {
        this.showChargeCategories = true;
        const requestBody = {
            chargeModuleTemplateId: this.chargeModuleTemplateId,
            locationId: this.locationId,
            packageModuleId: this.packageInfo.packageModule.packageModuleId
        };

        this.loadingChargeCategories = true;
        this.httpService
            .get(ApiResources.getURI(ApiResources.packageModule.base, ApiResources.packageModule.fetchChargeCategoriesWithTotal), requestBody)
            .pipe(finalize(() => this.loadingChargeCategories = false), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (chargeCategories: Array<IResource>) => {
                    if (chargeCategories.length) {
                        chargeCategories.forEach((chargeCategory: IResource) => {
                            this.setChareCategoryTotals(chargeCategory);
                        });
                    }

                    this.chargeCategories = chargeCategories;
                    if (this.chargeCategory) {
                        this.chargeCategory = this.chargeCategories.find(s => s.id == this.chargeCategory.id);
                    }
                },
                error: () => this.chargeCategories = []
            });
    }

    private setChareCategoryTotals(chargeCategory: IResource): void {
        let subTotal = chargeCategory.value ? parseFloat(chargeCategory.value) : 0, discount = 0, total = subTotal;
        if (this.packageInfo.packageModule.discountType === "P") {
            discount = parseFloat((((subTotal * (this.packageInfo.packageModule.discountPercentage || 0)) / 100) || 0).toFixed(2));
            discount = isNaN(discount) ? 0 : discount;
            total = subTotal - discount;
            total = total > 0 ? total : 0;
        } else if (this.packageInfo.packageModule.discountType === "A") {
            discount = this.packageInfo.packageModule.discountAmount || 0;
            discount = isNaN(discount) ? 0 : discount;
            total = subTotal - discount;
            total = total > 0 ? total : 0;
        }

        chargeCategory.subTotal = subTotal;
        chargeCategory.discount = discount;
        chargeCategory.total = total;
    }


    fetchLinkedServices = (callback?: Function) => {
        this.packageDetails = this.packageInfo.packageModuleDetails.filter(m => !m.isNA);
        if (this.isShow && this.packageDetails && this.packageDetails.length > 0) {
            var surgeryIds = "";
            var modulesMasterId;
            this.packageDetails.forEach(item => {
                if (item.moduleName === 'OT') {
                    modulesMasterId = item.modulesMasterId;
                    surgeryIds += item.referenceId + ",";
                }
            })
            const req = {
                chargeCategoryId: this.chargeCategory?.id,
                chargeModuleTemplateId: this.chargeModuleTemplateId,
                locationId: this.locationId,
                modulesMasterId: modulesMasterId,
                surgeryIds: surgeryIds
            }
            this.loadingCharges = true;

            this.httpService
                .post(ApiResources.getURI(ApiResources.packageModule.base, ApiResources.packageModule.fetchServiceLinkCharges), req)
                .pipe(finalize(() => this.loadingCharges = false), takeUntil(this.page.unSubscribe))
                .subscribe(
                    {
                        next: (response: Array<SurgeryLinkModel>) => {
                            this.surgeryLinkServices = response;
                            callback && callback(this.surgeryLinkServices);
                        },
                        error: (err: any) => {
                            this.notifyService.errorToast("Server Error");
                        }
                    }
                )

        }

    }
    //For backup
    //fetchLinkedServices = ( callback?: Function) =>  {
    //    this.packageDetails = this.packageInfo.packageModuleDetails.filter(m => !m.isNA );
    //    if (this.isShow && this.packageDetails && this.packageDetails.length > 0) {
    //        this.packageDetails.forEach(item => {
    //            if (item.moduleName === 'OT') {
    //                var surgeryIds = "";
    //                surgeryIds += item.referenceId + ",";
    //                const req = {
    //                    chargeCategoryId: this.chargeCategory.id,
    //                    chargeModuleTemplateId: this.packageInfo.packageModule.chargeModuleTemplateId,
    //                    locationId: this.packageInfo.packageModule.locationId,
    //                    referenceId: item.referenceId,
    //                    modulesMasterId: item.modulesMasterId
    //                }
    //                this.loadingCharges = true;

    //                this.httpService
    //                    .post(ApiResources.getURI(ApiResources.packageModule.base, ApiResources.packageModule.fetchServiceLinkCharges), req)
    //                    .pipe(finalize(() => this.loadingCharges = false), takeUntil(this.page.unSubscribe))
    //                    .subscribe(
    //                        (response: Array<SurgeryLinkModel>) => {

    //                                response.forEach(linkService => {

    //                                    this.surgeryLinkServices.push(linkService);

    //                                })
    //                                item.amount = item.amount + this.surgeryLinkServices.filter(m => m.surgeryId == item.referenceId).map(m => m.amount).reduce((sum, current) => sum + current, 0)
    //                        }
    //                    )
    //            }

    //        })

    //    }
    //    callback && callback(this.surgeryLinkServices);
    //}


    private fetchCharges(): void {
        const requestBody = {
            chargeCategoryId: this.chargeCategory?.id,
            chargeModuleTemplateId:  this.chargeModuleTemplateId,
            locationId: this.locationId,
            packageModuleId: this.packageInfo?.packageModule?.packageModuleId,
            modulesMasterIds: this.packageInfo?.packageModule?.modulesMasterIds,
            doctorUnitMasterId: this.doctorUnitMasterId
        };
        if (this.chargeModuleTemplateId && this.chargeModuleTemplateId > 0) {
            requestBody.chargeModuleTemplateId = this.chargeModuleTemplateId;
            if (this.packageInfo?.packageModule?.chargeModuleTemplateId) {
                this.packageInfo.packageModule.chargeModuleTemplateId = this.chargeModuleTemplateId;
                this.packageInfo.packageModule.templateName = this.templateName
            }
        }
        this.loadingCharges = true;
        this.httpService
            .post(ApiResources.getURI(ApiResources.packageModule.base, ApiResources.packageModule.fetchChargeDetails), requestBody)
            .pipe(finalize(() => this.loadingCharges = false), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (charges: Array<ChargeModuleDetailsModel>) => {
                    this.charges = charges;
                    if (charges && charges.length) {
                        this.packageInfo.packageModuleDetails.forEach(m => {
                            if (m.referenceId > 0) {
                                const charge = charges.find(n => n.modulesMasterId === m.modulesMasterId && n.referenceId === m.referenceId);
                                if (charge) {
                                    m.isNA = false;
                                    m.amount = charge.amount;
                                }
                                else
                                    m.isNA = true;
                            } else if (m.modulesMasterId > 0) {
                                const charge = charges.find(n => n.modulesMasterId === m.modulesMasterId);
                                if (charge) {
                                    m.isNA = false;
                                    m.amount = charge.amount;
                                }
                            }
                            else
                                m.isNA = false;
                        });
                        if (this.isShow) {

                            this.fetchLinkedServices(() => {
                                if (this.packageDetails)
                                    this.packageInfo.packageModuleDetails = this.packageDetails;

                                this.packageInfo.packageModuleDetails?.forEach(item => {
                                    if (item.moduleName === 'OT') {
                                        item.serviceTotal = item.amount;
                                    }
                                })
                                const packageDetails = this.packageInfo?.packageModuleDetails?.filter(m => !m.isNA);
                                this.packageInfo.totalQuantity = packageDetails?.map(m => m.quantity).reduce((sum, current) => sum + current, 0);
                                this.packageInfo.subTotal = packageDetails?.map(m => m.amount * m.quantity).reduce((sum, current) => sum + current, 0);

                                let subTotal = this.packageInfo?.subTotal || 0, discount = 0, total = subTotal;
                                if (this.packageInfo?.packageModule?.discountType === "P") {
                                    discount = parseFloat((((subTotal * (this.packageInfo.packageModule.discountPercentage || 0)) / 100) || 0).toFixed(2));
                                    discount = isNaN(discount) ? 0 : discount;
                                    total = subTotal - discount;
                                    total = total > 0 ? total : 0;
                                } else if (this.packageInfo?.packageModule?.discountType === "A") {
                                    discount = this.packageInfo.packageModule.discountAmount || 0;
                                    discount = isNaN(discount) ? 0 : discount;
                                    total = subTotal - discount;
                                    total = total > 0 ? total : 0;
                                }
                                this.packageInfo.discount = discount;
                                this.packageInfo.total = total;
                                this.packageInfoForServices = this.packageInfo;
                            })
                        }

                        else {
                            if (this.packageDetails)
                                this.packageInfo.packageModuleDetails = this.packageDetails;
                            const packageDetails = this.packageInfo.packageModuleDetails.filter(m => !m.isNA);

                            this.packageInfo.totalQuantity = packageDetails.map(m => m.quantity).reduce((sum, current) => sum + current, 0);
                            this.packageInfo.subTotal = packageDetails.map(m => m.amount * m.quantity).reduce((sum, current) => sum + current, 0);

                            let subTotal = this.packageInfo.subTotal || 0, discount = 0, total = subTotal;
                            if (this.packageInfo.packageModule.discountType === "P") {
                                discount = parseFloat((((subTotal * (this.packageInfo.packageModule.discountPercentage || 0)) / 100) || 0).toFixed(2));
                                discount = isNaN(discount) ? 0 : discount;
                                total = subTotal - discount;
                                total = total > 0 ? total : 0;
                            } else if (this.packageInfo.packageModule.discountType === "A") {
                                discount = this.packageInfo.packageModule.discountAmount || 0;
                                discount = isNaN(discount) ? 0 : discount;
                                total = subTotal - discount;
                                total = total > 0 ? total : 0;
                            }

                            this.packageInfo.discount = discount;
                            this.packageInfo.total = total;
                            this.packageInfoForServices = this.packageInfo;

                        }
                    }
                },
                error: () => this.charges = []
            });
    }

    private fetchLocations(): void {
        this.loadingLocations = true;
        this.resourceService.locations(true)
            .pipe(finalize(() => this.loadingLocations = false), takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => this.locations = response);
    }

    onChangeLocation(record: IResource) {
        this.locationId = record.id;
        this.chargeTemplates = [];
        this.chargeModuleTemplateId = null;
        this.fetchChargeTemplates(record, this.packageInfo.packageModule.payType);
    }

    private fetchChargeTemplates(location: IResource, payType: string): void {
        this.loadingChargeTemplates = true;
        this.resourceService.chargeModuleTemplates(location.id, payType)
            .pipe(finalize(() => { this.loadingChargeTemplates = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((chargeTemplates: Array<IResource>) => {
                this.chargeTemplates = chargeTemplates.filter(s => s.status)
                
            });
    }

    onChangeChargeTemplate(record: IResource) {
        this.chargeModuleTemplateId = record.id;
        this.fetchChargeCategories();
    }

    preprocessModules() {
        let counter = 1;
        this.packageInfo.modules.forEach(module => {
            this.fetchPackageDetails(module.modulesMasterId).forEach(detail => {
                detail.index = counter++
            });
        });
    }
}
