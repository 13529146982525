import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { IUserAccount } from "@shared/models";
import { PermissionHelper } from "@shared/helpers/permission.helper";
import { AppData, IdentityService, appUrls } from "@shared/services";

class RouteData {
    title?: string;
    filters?: boolean;
    roles?: Array<number>;
}

@Injectable()
export class RoleGuard implements CanActivate {
    routeData: RouteData;
    permissions: PermissionHelper;

    constructor(
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly identityService: IdentityService,
    ) {
        this.routeData = new RouteData();
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        this.routeData = route.data;
        return this.appData.userAccount.pipe(
            map((account: IUserAccount) => {
                this.permissions = new PermissionHelper(account.roleId);
                const exists = this.routeData.roles.find((o: number) => o === account.roleId);
                if (exists !== undefined) {
                    return true;
                } else {
                    this.identityService.logout();
                }

            }),
            catchError(() => {
                return of(false);
            })
        );
    }
}