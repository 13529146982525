<div>
    <ng-container>
        <div class="d-flex justify-content-between col-12 p-0">
            <div class="flex-grow-1 d-flex col-12">
                <h3 class="d-flex justify-content-xl-center antennal-heading">
                    ANTENATAL SUMMARY
                </h3>

            </div>
        </div>
    </ng-container>

    <table class="w-100 table-striped white-space-nowrap mt-2">
        <tr class="col-12" *ngIf="obReportData">
            <td class="w-200p">
                <h5>  ANC No</h5>
            </td>
            <td>
                <h5>:</h5>
            </td>
            <td class="w-200p">
                <h5 [textContent]="obReportData.ANCNo"></h5>
            </td>
            <td class="w-200p">

                <h5> M.R.No.</h5>
            </td>
            <td>
                <h5>:</h5>
            </td>
            <td class="w-200p">
                <h5 [textContent]="obReportData.UMRNo"></h5>
            </td>
            <td class="w-200p">

                <h5> Consultant</h5>
            </td>
            <td>
                <h5>:</h5>
            </td>
            <td class="w-200p">
                <h5 [textContent]="obReportData.ConsultantDoctor"></h5>
            </td>

            <td class="w-200p">

                <h5> Booked</h5>
            </td>
            <td>
                <h5>:</h5>
            </td>

            <td class="w-200p">
                <h5 [textContent]="obReportData.booked"></h5>
            </td>

        </tr>

    </table>




    <div *ngIf="obReportData" class="border-dark border-top mt-2">

        <table class="w-100 white-space-nowrap border-top2px border-bottom2px">
            <tr class="col-12">
                <td class="w-130">
                    <span class="">Name</span>
                </td>
                <td>
                    <h5 class="">:</h5>
                </td>
                <td colspan="2" class="text-uppercase">
                    <h5 class="d-block  pl-1 text-truncate min-width17vw max-width_25vw" [textContent]="obReportData.PatinetName"></h5>
                </td>

                <td class="w-130">
                    <span class="">Age</span>
                </td>
                <td>
                    <h5>:</h5>
                </td>
                <td colspan="2" class="text-uppercase">
                    <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="obReportData.Age"></h5>
                </td>

                <td class="w-130">
                    <span class="">Date of Birth</span>
                </td>
                <td>
                    <h5>:</h5>
                </td>

                <td colspan="2" class="text-uppercase">
                    <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="obReportData.DateOfBirth | date: 'dd-MM-yyyy'"></h5>
                </td>

            </tr>


            <tr class="col-12">
                <td class="w-130">
                    <span class="">Education</span>
                </td>
                <td>
                    <h5>:</h5>
                </td>
                <td colspan="2" class="text-uppercase">
                    <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="obReportData.Education"></h5>
                </td>

                <td class="w-130">
                    <span class="">Occupation</span>
                </td>
                <td>
                    <h5>:</h5>
                </td>
                <td colspan="2" class="text-uppercase">
                    <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="obReportData.Occupation"></h5>
                </td>
            </tr>


        </table>
    </div>



    <div *ngIf="obReportData" class="border-dark border-top mt-2 ">
        <table class="w-100 white-space-nowrap border-top2px border-bottom2px">

            <tr class="col-12">
                <td class="w-130">
                    <span class=""> Partner's Name</span>
                </td>
                <td>
                    <h5 class="">:</h5>
                </td>
                <td colspan="2" class="text-uppercase">
                    <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="obReportData.RelationName"></h5>
                </td>
                <td class="w-130">
                    <span class=""> Age</span>
                </td>
                <td>
                    <h5 class="">:</h5>
                </td>
                <td colspan="2" class="text-uppercase">
                    <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="obReportData.RelationAge"></h5>
                </td>
            </tr>



            <tr class="col-12">
                <td class="w-130">
                    <span class="">Education </span>
                </td>
                <td>
                    <h5 class="">:</h5>
                </td>
                <td colspan="2" class="text-uppercase">
                    <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="obReportData.relationeducation"></h5>
                </td>
                <td class="w-130">
                    <span class=""> Occupation </span>
                </td>
                <td>
                    <h5 class="">:</h5>
                </td>
                <td colspan="2" class="text-uppercase">
                    <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="obReportData.relationoccupation"></h5>
                </td>
            </tr>

        </table>
    </div>


    <div class="border-dark border-top  mt-2" *ngIf="obReportData">
        <table class="w-100 white-space-nowrap border-top2px border-bottom2px">

            <tr class="col-12">
                <td class="w-130">
                    <span class=""> Address </span>
                </td>
                <td>
                    <h5 class="">:</h5>
                </td>
                <td colspan="2" class="text-uppercase">
                    <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="obReportData.address"></h5>
                </td>
                <td class="w-130">
                    <span class=""> District </span>
                </td>
                <td>
                    <h5 class="">:</h5>
                </td>
                <td colspan="2" class="text-uppercase">
                    <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="obReportData.City"></h5>
                </td>
                <td class="w-130">
                    <span class=""> State</span>
                </td>
                <td>
                    <h5 class="">:</h5>
                </td>
                <td colspan="2" class="text-uppercase">
                    <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="obReportData.State"></h5>
                </td>
            </tr>

            <tr class="col-12">
                <td class="w-130">
                    <span class=""> Contact_No </span>
                </td>
                <td>
                    <h5 class="">:</h5>
                </td>
                <td colspan="2" class="text-uppercase">
                    <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="obReportData.Mobile"></h5>
                </td>
                <td class="w-130">
                    <span class=""> Email</span>
                </td>
                <td>
                    <h5 class="">:</h5>
                </td>
                <td colspan="2" class="text-uppercase">
                    <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="obReportData.Email"></h5>
                </td>

            </tr>

            <tr class="col-12" *ngIf="ancCard">
                <td class="w-130">
                    <span class=""> Time taken to reach hospital </span>
                </td>
                <td>
                    <h5 class="">:</h5>
                </td>
                <td colspan="2" class="text-uppercase">
                    <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="ancCard.timeTakenToReachHospital"></h5>
                </td>
                <td class="w-130">
                    <span class=""> Distance in kilometers </span>
                </td>
                <td>
                    <h5 class="">:</h5>
                </td>
                <td colspan="2" class="text-uppercase">
                    <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="ancCard.distanceInKilometers"></h5>
                </td>

            </tr>


        </table>
    </div>

    <div class="border-dark border-top border-bottom  mt-2">

        <div class="row mt-2" *ngIf="ancCard">
            <div class="col ">
                <h3 class="current-pregnancy-heading">
                    CURRENT PREGNANCY DETAILS
                </h3>
                <table class="w-100 white-space-nowrap">

                    <tr class="col-12">
                        <td class="w-130">
                            <span class=""> Year of Marriage: </span>
                        </td>
                        <td>
                            <h5 class="">:</h5>
                        </td>
                        <td colspan="2" class="text-uppercase">
                            <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="ancCard.yearOfMarriage"></h5>
                        </td>
                        <td class="w-130">
                            <span class=""> Living Children: </span>
                        </td>
                        <td>
                            <h5 class="">:</h5>
                        </td>
                        <td colspan="2" class="text-uppercase">
                            <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="ancCard.living"></h5>
                        </td>

                    </tr>
                    <tr class="col-12">
                        <td class="w-130">
                            <span class=""> Gravida </span>
                        </td>
                        <td>
                            <h5 class="">:</h5>
                        </td>
                        <td colspan="2" class="text-uppercase">
                            <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="ancCard.gravida"></h5>
                        </td>
                        <td class="w-130">
                            <span class=""> Para </span>
                        </td>
                        <td>
                            <h5 class="">:</h5>
                        </td>
                        <td colspan="2" class="text-uppercase">
                            <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="ancCard.para"></h5>
                        </td>

                    </tr>
                    <tr class="col-12">
                        <td class="w-130">
                            <span class=""> Abortion</span>
                        </td>
                        <td>
                            <h5 class="">:</h5>
                        </td>
                        <td colspan="2" class="text-uppercase">
                            <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="ancCard.abortion"></h5>
                        </td>
                        <td class="w-130">
                            <span class=""> Menstrual Pattern </span>
                        </td>
                        <td>
                            <h5 class="">:</h5>
                        </td>
                        <td colspan="2" class="text-uppercase">
                            <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="ancCard.menstrualPattern"></h5>
                        </td>

                    </tr>


                    <tr class="col-12">
                        <td class="w-130">
                            <span class=""> Contraception </span>
                        </td>
                        <td>
                            <h5 class="">:</h5>
                        </td>
                        <td colspan="2" class="text-uppercase">
                            <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="ancCard.consanguinity"></h5>
                        </td>
                        <td class="w-130">
                            <span class=""> Consanguinity </span>
                        </td>
                        <td>
                            <h5 class="">:</h5>
                        </td>
                        <td colspan="2" class="text-uppercase">
                            <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="ancCard.consanguinity"></h5>
                        </td>

                    </tr>

                </table>
            </div>
            <div class="col mt-4">
                <table class="w-100 border-dark" border="2">
                    <tr class="lmp-hight">
                        <td>
                            LMP
                            <h5 [textContent]="ancCard.lmp"></h5>
                        </td>

                        <td rowspan="2">
                            EDD
                            <h5 [textContent]="ancCard.edd"></h5>
                        </td>
                    </tr>
                    <tr calss="lmp-hight">
                        <td>
                            CORRECTED EDD
                            <h5 [textContent]="ancCard.edd"></h5>
                        </td>
                    </tr>
                </table>
            </div>
        </div>


        <div class="border-dark border-top mt-2">


            <div class="col-12 ng-star-inserted p-0 pt-1" *ngIf="obHistory">
                <div><h5 class="font-weight-bold mt-0 current-pregnancy-heading">OBSTETRIC HISTORY</h5> </div>
                <table class="ob-width" border="1">
                    <thead *ngIf="obHistory.length >0">
                        <tr class="tableBorder">
                            <th class="tableBorder font-weight-bolder"> Preg No</th>
                            <th class="tableBorder font-weight-bolder">Year</th>
                            <th class="tableBorder font-weight-bolder">Birth Details</th>
                        </tr>
                    </thead>
                    <tbody class="vertical-align-baseline">
                        <tr *ngFor="let item of obHistory;">
                            <td class="tableBorder" [textContent]="item.pregnancyNo"></td>
                            <td class="tableBorder" [textContent]="item.yearOfBirth"></td>
                            <td class="tableBorder">
                                <div *ngIf="item.pregnancyStatus">Pregnancy Status &nbsp;: &nbsp;<span [textContent]="item.pregnancyStatus"></span></div>
                                <div *ngIf="item.gaWeeks">GA Weeks &nbsp;: &nbsp;<span [textContent]="item.gaWeeks"></span></div>
                                <div *ngIf="item.deliveryAt">Delivered At &nbsp;: &nbsp;<span [textContent]="item.deliveryAt"></span></div>
                                <div *ngIf="item.deliveryDetail.length"><span [textContent]="item.deliveryDetail"></span></div>
                                <div *ngIf="item.indication">Indication &nbsp;: &nbsp;<span [textContent]="item.indication"></span></div>
                                <div *ngIf="item.other">Other Information &nbsp;: &nbsp;<span [textContent]="item.other"></span></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>




        <h4 class="text-danger">IMPORTANT INFORMATION</h4>


        <div *ngIf="antinantalRisk">
            <table class="w-100 table-striped white-space-nowrap ">
                <tr class="col-12">
                    <td class="w-200p">
                        <h5 class="text-danger">  ANTENATAL RISK FACTORS</h5>
                    </td>
                    <td>
                        <h5>:</h5>
                    </td>
                    <td class="w-200p">
                        <h5 [textContent]="antinantalRisk.maternalmedical"></h5>

                    </td>
                    <td class="w-200p">
                        <h5 class="text-danger">
                            SPECIAL FEATURES
                        </h5>
                    </td>
                    <td>
                        <h5>:</h5>
                    </td>
                    <td class="w-200p">
                        <h5 [textContent]="antinantalRisk.specialFeature"></h5>
                    </td>

                </tr>





            </table>
        </div>

        <div class="d-flex justify-content-between col-12 p-0">
            <div class="flex-grow-1 d-flex col-12">
                <h4 class="d-flex antennal-heading">
                    CHECKLISTS
                </h4>
            </div>
        </div>

        <br />

        <div class="row" *ngIf="ancCard">
            <div class="col-lg-6 col-md-6 col-sm-12 table-responsive td-5">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th></th>
                            <th><h5>Patient</h5></th>
                            <th><h5>Partner's</h5></th>
                        </tr>
                    </thead>


                    <ng-container *ngFor="let booking of BloodDetails; let i = index">

                        <tr>
                            <td [textContent]="booking.DisplayName"></td>
                            <td *ngIf="i == 0" [textContent]="ancCard.bloodGroup + ancCard.rhType"></td>
                            <td *ngIf="i == 0" [textContent]="ancCard.husbandBloodGroup + ancCard.husbandRhType"></td>
                            <td *ngIf="i == 1" [textContent]="ancCard.hiv"></td>
                            <td *ngIf="i == 1"></td>
                            <td *ngIf="i == 2" [textContent]="ancCard.hbsAg"></td>
                            <td *ngIf="i == 2"></td>

                        </tr>
                    </ng-container>


                </table>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 t-5" *ngIf="ancCard">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th><h5>Vaccinations</h5></th>
                            <th><h5>Date</h5></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let Vaccination of Vaccinations; let i = index">
                            <tr>
                                <td [textContent]="Vaccination.DisplayName"></td>
                                <td *ngIf="i == 0" [textContent]="ancCard.date1"></td>
                                <td *ngIf="i == 1" [textContent]="ancCard.date2"></td>
                                <td *ngIf="i == 2" [textContent]="ancCard.date3"></td>
                                <td *ngIf="i == 3" [textContent]="ancCard.date4"></td>
                                <td *ngIf="i == 4" [textContent]="ancCard.date5"></td>
                                <td *ngIf="i == 5" [textContent]="ancCard.date6"></td>
                                <td *ngIf="i == 6" [textContent]="ancCard.date7"></td>

                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 t-5" *ngIf="measure">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th><h5>Booking examination</h5></th>
                            <th><h5>Findings</h5></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let Booking of BookingExamination; let i = index">
                            <tr>
                                <td [textContent]="Booking.DisplayName"></td>
                                <td *ngIf="i == 0">Cardiovascular system</td>
                                <td *ngIf="i == 1" [textContent]="measure.respiratoryRate"></td>
                                <td *ngIf="i == 2" [textContent]="measure.heightInFeet"></td>
                                <td *ngIf="i == 3" [textContent]="measure.weight"></td>
                                <td *ngIf="i == 4" [textContent]="measure.bodyMassIndex"></td>


                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 t-5" *ngIf="ancCard">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th><h5> Patient preferences</h5></th>
                            <th><h5>Options / counseling</h5></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let pp of Patientpreferences; let i = index">
                            <tr>
                                <td [textContent]="pp.DisplayName"></td>
                                <td *ngIf="i == 0" [textContent]="ancCard.diet"></td>
                                <td *ngIf="i == 1" [textContent]="ancCard.lifeStyle"></td>
                                <td *ngIf="i == 2" [textContent]="ancCard.toiletPreference"></td>
                                <td *ngIf="i == 3" [textContent]="ancCard.prenatalScreeningOffered"></td>
                                <td *ngIf="i == 4" [textContent]="ancCard.cordBloodBanking"></td>

                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>


        <h4 class="current-pregnancy-heading"> Nutritional Review </h4>


        <div *ngFor="let neutri of NutritionalReview" class="border-dark border-top mt-2 ">
            <table class="w-100 white-space-nowrap border-bottom2px">

                <tr class="col-12">
                    <td class="w-130">
                        <span class=""> Nutritional screening </span>
                    </td>
                    <td>
                        <h5 class="">:</h5>
                    </td>
                    <td colspan="2" class="text-uppercase">
                        <h5 class="d-block  pl-1 text-truncate max-width_25vw" [textContent]="obReportData.IpNutritionalScreeningForm"></h5>
                    </td>
                </tr>

                <tr class="col-12">
                    <td class="w-130">
                        <span class="">Referred for nutritional</span>
                    </td>
                    <td>
                        <h5 class="">:</h5>
                    </td>
                    <td colspan="2" class="text-uppercase">
                        <h5 class="d-block  pl-1 text-truncate max-width_25vw"></h5>
                    </td>
                </tr>

                <tr class="col-12">
                    <td class="w-130">
                        <span class="">  Leaftlet Given / Diet Plan </span>
                    </td>
                    <td>
                        <h5 class="">:</h5>
                    </td>
                    <td colspan="2" class="text-uppercase">
                        <h5 class="d-block  pl-1 text-truncate max-width_25vw">{{FullOB.DietPlanPrescribed }}</h5>
                    </td>
                </tr>

                <tr class="col-12">
                    <td class="w-130">
                        <span class="">Counselled by Nutritionist</span>
                    </td>
                    <td>
                        <h5 class="">:</h5>
                    </td>
                    <td colspan="2" class="text-uppercase">
                        <h5 class="d-block  pl-1 text-truncate max-width_25vw"></h5>
                    </td>
                </tr>

                <tr class="col-12">
                    <td class="w-130">
                        <span class="">Diet Recommended</span>
                    </td>
                    <td>
                        <h5 class="">:</h5>
                    </td>
                    <td colspan="2" class="text-uppercase">
                        <h5 class="d-block  pl-1 text-truncate max-width_25vw"></h5>
                    </td>
                </tr>

            </table>
        </div>

        <div>
            <div class="row">

                <div class="col-lg-6 col-md-6 col-sm-12 table-responsive td-5">

                    <h5>Previous Hospitalizations</h5>
                    <table class="table table-bordered table-sm ">
                        <thead class="thead-light">
                            <tr>
                                <th>S.No</th>
                                <th>Hospitalization</th>
                                <th>Doctor Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of previousHospitalization; let i = index">
                                <td [textContent]="i + 1"></td>
                                <td [textContent]="item.Hospitalization"></td>
                                <td [textContent]="item.DoctorName"></td>

                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 t-5">
                    <h5>ATTACHMENTS TO EMR</h5>
                    <table class="table  table-bordered table-sm ">
                        <thead class="thead-light">
                            <tr>
                                <th>S.No</th>
                                <th> Attachments </th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of AttachmentsData; let i = index">
                                <td [textContent]="i + 1"></td>
                                <td [textContent]="row.Attachments"></td>
                                <td [textContent]="row.Date"></td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <h5>CURRENT ANTENATAL VISIT DETAILS</h5>

            <div *ngIf="AntenatalVisitDetails">
                <table class="table table-bordered table-sm" *ngFor="let data of AntenatalVisitDetails">
                    <tbody>
                        <ng-container>
                            <ng-container>
                                <tr>
                                    <td rowspan="3">{{ data.SNo }}</td>
                                    <td>Date</td>
                                    <td>Weight kg</td>
                                    <td>BMI</td>
                                    <td>Pulse Rate</td>
                                    <td>BP</td>
                                    <td>SFH cm</td>
                                    <td>Presentation</td>
                                    <td>FHR bpm</td>
                                    <td>Liquor</td>
                                    <td>Edema</td>
                                </tr>
                                <tr>
                                    <td [textContent]="data?.AppointmentDate"></td>
                                    <td [textContent]="data.Measure?.weight"></td>
                                    <td [textContent]="data.Measure?.bodyMassIndex"></td>
                                    <td [textContent]="data.Measure?.pulseRate"></td>
                                    <td [textContent]="data.Measure?.systolic + ' / ' + data.Measure?.diastotic"></td>
                                    <td [textContent]="data.Measure?.sfHeight"></td>
                                    <td [textContent]="data.Measure?.presentation"></td>
                                    <td [textContent]="data.Measure?.fhs"></td>
                                    <td [textContent]="data.Measure?.liquor"></td>
                                    <td [textContent]="data.Measure?.oedema"></td>

                                </tr>
                                <tr>
                                    <td class="anc-ga">Gest.Age <span [textContent]="data.AncCard.ga"></span></td>
                                    <td colspan="9" *ngIf="data.OrderPrescription && data.OrderPrescription.orderData">
                                        <p class="d-flex justify-content-start" *ngFor="let Prescription of data.OrderPrescription.orderData">
                                            <strong>{{ Prescription.orderName }}:</strong> <span [innerHTML]="Prescription?.comments"></span>
                                        </p>
                                        <p *ngIf="data.Measure"><strong>Next review on</strong> <span [textContent]="data.Measure.nextReviewDate"></span></p>
                                        <div class="d-flex justify-content-between">
                                            <p>User Name: <span [textContent]="data.AncCard.consultanDoctorUserName"></span></p>
                                            <p class="mr-5" *ngIf="data.Measure">Consulted by: <span [textContent]="data.Measure.consultedByDr"></span></p>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </tbody>
                </table>




            </div>

        </div>



        <h5><b>PREVIOUS HOSPITAL VISIT DETAILS</b></h5>

        <div class="row">
            <div class="col-md-6">
                <table class="table table-bordered">
                    <thead>
                        <tr class="visitcolor">
                            <th></th>
                            <th>Doctor visits</th>
                            <th>Date</th>
                            <th>Day</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of previousHospitalVisits; let i = index">
                            <td [textContent]="i + 1"></td>
                            <td [textContent]="item.DoctorVisits"></td>
                            <td [textContent]="item.Date | date:'dd.MM.yyyy'"></td>
                            <td [textContent]="item.Day"></td>

                        </tr>
                    </tbody>
                </table>

            </div>
            <div class="col-md-6">

                <table class="table table-bordered">
                    <thead>
                        <tr class="visits-tbodycolor">
                            <th>S.No</th>
                            <th>Investigations</th>
                            <th>Date</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of labTests; let i = index">
                            <td [textContent]="i + 1"></td>
                            <td [textContent]="row.TestName"></td>
                            <td [textContent]="row.ServiceDate | date:'dd.MM.yyyy'"></td>


                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="mt-1">
            <h5><b>ANTENATAL PERIOD INVESTIGATIONS</b></h5>

            <div class="overflow-auto">
                <table class="table table-bordered">
                    <thead class="thead">
                        <tr>
                            <th>Test Name</th>
                            <th>Parameter Name</th>
                            <ng-container *ngFor="let date of getUniqueDates()">
                                <th>{{ date }}</th>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let group of groupRowsByTestName() | keyvalue">
                            <ng-container *ngFor="let row of group.value; let firstRow = first">
                                <tr>
                                    <td *ngIf="firstRow" [attr.rowspan]="group.value.length">{{ group.key }}</td>
                                    <td>{{ row.ParameterName }}</td>
                                    <ng-container *ngFor="let date of getUniqueDates()">
                                        <td>{{ row[date] }}</td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>


        <h5><b>ANC CARD-Summary of ANC Visits</b></h5>
        <div class="row">
            <div class="col-md-12">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>S.no</th>
                            <th>Date</th>
                            <th>Gest. Age</th>
                            <th>Weight</th>
                            <th>BMI</th>
                            <th>PR</th>
                            <th>BP</th>
                            <th>SFH</th>
                            <th>Presentation</th>
                            <th>FHR</th>
                            <th>Review date</th>
                            <th>Seen by</th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr *ngFor="let item of AntenatalVisitDetails">
                            <td [textContent]="item?.SNo"></td>
                            <td [textContent]="item?.AppointmentDate | date:'dd.MM.yyyy'"></td>
                            <td [textContent]="item.AncCard?.ga"></td>
                            <td [textContent]="item.Measure?.weight"></td>
                            <td [textContent]="item.Measure?.bodyMassIndex"></td>
                            <td [textContent]="item.Measure?.pulseRate"></td>
                            <td [textContent]="item.Measure?.systolic + ' / ' + item.Measure?.diastotic"></td>
                            <td [textContent]="item.Measure?.sfHeight"></td>
                            <td [textContent]="item.Measure?.presentation"></td>
                            <td [textContent]="item.Measure?.FHR"></td>
                            <td [textContent]="item.Measure?.nextReviewDate | date:'dd.MM.yyyy'"></td>
                            <td [textContent]="item.Measure?.consultedByDr"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <h5><b>Non-ANC Visits:</b></h5>

        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Doctor Name</th>
                    <th>Prescription Given</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of nonAncVisits">
                    <td class="tableBorder" [textContent]="item.Date | date:'dd.MM.yyyy'"></td>
                    <td class="tableBorder" [textContent]="item.DoctorName"></td>
                    <td class="tableBorder">
                        <div *ngFor="let orderItem of item.PrescriptionGiven.orderData" class="prescription-item">
                            <p class="d-flex justify-content-start">
                                <strong class="order-name" [textContent]="orderItem.orderName + ':'"></strong>
                                <span class="comments" [innerHTML]="orderItem.comments"></span>
                            </p>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>