
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation, Input, AfterViewInit, HostListener } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ApiResources, DateHelper, UtilHelper } from "@shared/helpers";

import { InternalMedicine } from "@admin/internal-medicine/internal-medicine.namespace";
import { Page, Pagination, IResource, IUserAccount, PatientProfile } from "@shared/models";
import { AppConfig, AppData, HttpService, AppointmentToggleService, TimelineToggleService, IconService, PrintOptionService, NotifyService, EncounterCommunication, EmergencyContact, ResourceService } from "@shared/services";
import { finalize, takeUntil } from "rxjs/operators";
import { Appointment, PreviousAppointment, pediatricEncounterFullTranscript, PatientMedicationHeader, ImageReports, ScanBooking } from "@shared/entities";
import { Time } from "@angular/common";
import { LabBillHeaderModel } from "@admin/labs/pages/models";

import moment from "moment";
import { OB } from "../../../areas/admin/full-transcript/pages/ob-report/ob.namespace";
import { PEncounter } from "../../models/pediatric-encounter.model";



class FilterOptions {
    encryptedAppointmentId: string = null;
}


class Filters {
    options: FilterOptions;
    applied: boolean;

    constructor() {
        this.init();
    }

    init() {
        this.options = new FilterOptions();
        this.applied = undefined;
    }
}
class OrderPrescriptionModel {
    appoitmentId: number;
    orderPrescription: string;
    appointmentDate: Date;
    appointmentId: string;
    appointmentTime: Time;
    providerName: string;

}

@Component({
    templateUrl: "./pediatric-report.html",
    selector: "pediatric-report",
    encapsulation: ViewEncapsulation.None
})
export class PediatricReportPage implements OnInit, OnDestroy, AfterViewInit {
    @Input() encryptedPatientId: string;
    @Input() encryptedAppointmentId: string;
    loadingVitals: boolean;
    filters: Filters;
    routingValue: string;
    page: Page;
    modalRef: NgbModalRef;
    modalViewRef: NgbModalRef;
    loadingCategories: boolean;
    categories: Array<IResource>;
    section: string;
    pagination: Pagination;
    loading: boolean;
    fullTranscript: pediatricEncounterFullTranscript;
    patientDocumentId: number;
    currentDate: Date;
    appointmentId: string;
    isAdmission: boolean;
    providerId: number;
    patientId: number;
    appointments: Appointment;
    oldAppointment: PreviousAppointment;
    orders: Array<OrderPrescriptionModel>;
    loadingDocument: boolean;
    documentError: boolean;
    showPrevious: boolean;
    showNext: boolean;
    result: number;
    date: Date;
    currentLocation: string;
    patient: PatientProfile;
    appointment: Appointment;
    records: Array<PatientMedicationHeader>;
    submitting: boolean;
    isTeleconsulatantReport: boolean;
    isAppointmentClosed: boolean;
    futureAppointments: Array<Appointment>;
    vaccineDetail: string;
    a_Block = true;
    b_Block = true;
    c_Block = true;
    d_Block = true;
    e_Block = true;
    f_Block = true;
    g_Block = true;
    h_Block = true;
    i_Block = true;
    j_Block = true;
    k_Block = true;
    l_Block = true;
    m_Block = true;
    n_Block = true;
    o_Block = true;
    p_Block = true;
    q_Block = true;
    @ViewChild("templateViewDocument", { static: false })
    iconBasics: ImageReports;
    flag = true;
    @Input() isPrintLogo: boolean;
    labs: Array<PatientMedicationHeader>;
    bookingHeader: Array<LabBillHeaderModel>;
    scanAppointments: Array<ScanBooking>
    allergies: OB.Allergies;
    yearsfordisplay: number;
    daysfordisplay: any;
    monthsfordisplay: number;
    loadingImm: boolean;
    immunization: Array<any>;
    emergencyContacts: any;
    isFooter: boolean;
    @Input() printId: string;
    @Input() printadmission: boolean;
    lodingLocation: boolean;
    locations: Array<any>
    location:any
    constructor(
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
        private readonly toggleService: AppointmentToggleService,
        public timelineService: TimelineToggleService,
        private readonly printOptionService: PrintOptionService,
        private readonly iconService: IconService,
        private readonly psetEncounter: EncounterCommunication,
        public readonly emergencyContact: EmergencyContact,
        private readonly resourceService: ResourceService,


    ) {
        this.page = new Page();
        this.pagination = new Pagination();

        this.records = new Array<PatientMedicationHeader>();
        this.filters = new Filters();
        this.labs = new Array<PatientMedicationHeader>();
        this.bookingHeader = new Array<LabBillHeaderModel>();
        this.currentDate = new Date();
        this.date = new Date();
        this.emergencyContact.getContact((is) => { this.emergencyContacts = is; });
    }

    measurements:any;
    orderPrescription: OB.OrdersPrescription;
    refferalOrder: Array<OB.OrderReferral>;
    diagnosisRecords: Array<OB.DiagnosisOrder>;
    visitOrders: Array<OB.VisitForm>;
    medicationComment: InternalMedicine.MedicationComment;
    private fetchMedication() {
        let intervalHit = setInterval(() => {
            let setting = localStorage.getItem("settings");
            if (setting) {
                clearInterval(intervalHit);
                this.loading = true;
                const request = {
                    encryptedAppointmentId: this.appointmentId
                }

                this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetch), request)
                    .pipe(takeUntil(this.page.unSubscribe))
                    .pipe(finalize(() => {
                        this.loading = false;
                    }))
                    .subscribe((response: Array<PatientMedicationHeader>) => {
                        this.records = response;
                    }, () => {
                        this.records = Array<PatientMedicationHeader>();
                    });
            }
        }, 10);
    }
    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }
    private fetchLabs() {
        this.loading = true;
        const request = {
            appointmentId: this.appointment.appointmentId,
            isAdmission: this.isAdmission
        }
        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetchLabs), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                if (response && response.length > 0) {
                    this.bookingHeader = response[0].labs;
                }
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });
    }
    private findPatient() {
        let intervalHit = setInterval(() => {
            let setting = localStorage.getItem("settings");
            if (setting) {
                clearInterval(intervalHit);
                this.page.loading = true;
                const request = { encryptedAppointmentId: this.appointmentId };
                request["isAdmission"] = this.isAdmission;
                this.httpService.post<PEncounter>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
                    .pipe(takeUntil(this.page.unSubscribe))
                    .subscribe((response: PEncounter) => {
                        this.encryptedPatientId = response.encryptedPatientId;
                        
                        this.findDashboard();
                        this.fetchMedication();
                        this.fetchLabs();
                        this.findPatientDetails(response.encryptedPatientId);
                     
                    });
            }
        }, 10);
    }
    onChangeStatus = (type: string, e: MouseEvent) => {
        switch (type) {

            case "a":
                this.a_Block = !this.a_Block;
                break;
            case "b":
                this.b_Block = !this.b_Block;
                break;
            case "c":
                this.c_Block = !this.c_Block;
                break;
            case "d":
                this.d_Block = !this.d_Block;
                break;
            case "e":
                this.e_Block = !this.e_Block;
                break;
            case "f":
                this.f_Block = !this.f_Block;
                break;
            case "g":
                this.g_Block = !this.g_Block;
                break;
            case "h":
                this.h_Block = !this.h_Block;
                break;
            case "i":
                this.i_Block = !this.i_Block;
                break;
            case "j":
                this.j_Block = !this.j_Block;
                break;
            case "k":
                this.k_Block = !this.k_Block;
                break;
            case "l":
                this.l_Block = !this.l_Block;
                break;
            case "m":
                this.m_Block = !this.m_Block;
                break;
            case "n":
                this.n_Block = !this.n_Block;
                break;
            case "o":
                this.o_Block = !this.o_Block;
                break;
            case "p":
                this.p_Block = !this.p_Block;
                break;
            case "q":
                this.q_Block = !this.q_Block;
                break;
        }
    }
    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }

    private findDashboard() {

        let intervalHit = setInterval(() => {
            let setting = localStorage.getItem("settings");
            if (setting) {
                clearInterval(intervalHit);

                const request = { encryptedAppointmentId: this.appointmentId, encryptedProviderId: this.page.userAccount.encryptedReferenceId, encryptedPatientId: this.encryptedPatientId };
                request["isAdmission"] = this.isAdmission;

                this.httpService.post<pediatricEncounterFullTranscript>(ApiResources.getURI(ApiResources.pediatricEncounter.base, ApiResources.pediatricEncounter.findFullTranscript), request)
                    .pipe(takeUntil(this.page.unSubscribe))
                    .pipe(finalize(() => this.page.loading = false))
                    .subscribe((response: pediatricEncounterFullTranscript) => {
                        this.fullTranscript = response;
                        if (this.fullTranscript && this.fullTranscript.pediatricEncounterId) {
                            this.measurements = this.fullTranscript.measurements ? JSON.parse(this.fullTranscript.measurements) : null;

                            if (this.fullTranscript.patientDateOfBirth != null) {
                                const dateOfBirth = DateHelper.toNgbDateStruct(String(this.fullTranscript.patientDateOfBirth))
                                if (dateOfBirth != null) {
                                    let mdob = moment(String(this.fullTranscript.patientDateOfBirth), "YYYY-MM-DD");
                                    let age = moment().diff(mdob, "months");
                                    let yrs = (age / 12).toFixed(1);
                                    this.yearsfordisplay = parseInt(yrs, 10);
                                    let months = age % 12;
                                    var days = moment().diff(mdob.add(yrs, 'years'), 'days', false);
                                    this.daysfordisplay = days;
                                    this.monthsfordisplay = months;
                                    var date1 = moment(String(this.fullTranscript.patientDateOfBirth), "YYYY-MM-DD");
                                    var monthsa = moment().diff(date1, 'months');
                                    date1.add(monthsa, 'months');
                                    var daysa = moment().diff(date1, 'days');
                                    monthsa + ' ' + 'Month(s)' + ' ' + daysa + " Day(s)"
                                    this.daysfordisplay = daysa;
                                }

                            }


                            this.orderPrescription = this.fullTranscript.orderPrescription ? JSON.parse(this.fullTranscript.orderPrescription) : null;
                            this.refferalOrder = this.fullTranscript.refferalOrder ? JSON.parse(this.fullTranscript.refferalOrder) : null;
                            this.diagnosisRecords = this.fullTranscript.diagnosisOrder ? JSON.parse(this.fullTranscript.diagnosisOrder) : null;
                            this.visitOrders = this.fullTranscript.nextVisitOrder ? JSON.parse(this.fullTranscript.nextVisitOrder) : null;
                            this.medicationComment = this.fullTranscript.medicationComment ? JSON.parse(this.fullTranscript.medicationComment) : null;
                            this.allergies = this.fullTranscript.allergies ? JSON.parse(this.fullTranscript.allergies) : null;
                            if (UtilHelper.isEmpty(this.vaccineDetail)) {
                                this.fetchImmunizaion();
                            }
                            if (!this.isTeleconsulatantReport) {
                                if (response.orderPrescription != null) {
                                    this.notifyService.confirm(" you want to complete the transaction for this visit", () => {
                                        this.psetEncounter.modifyPEncounterStatus(this.appointment.appointmentId);
                                    }, () => { $("#printButton").click(); })
                                }

                                else {
                                    this.notifyService.confirm("Order details are not available for this patient visit, still you would like to continue", () => {
                                        this.notifyService.confirm("do you want to complete the transaction for this visit", () => {
                                            this.psetEncounter.modifyPEncounterStatus(this.appointment.appointmentId);
                                        }, () => { $("#printButton").click(); })
                                    })
                                }
                            }


                        }
                    });


            }
        }, 10);

    }
    private fetchImmunizaion() {
        this.loadingImm = true;
        const request = {
            patientId: this.appointment.patientId
        };

        this.httpService.get(ApiResources.getURI(ApiResources.vaccine.base, ApiResources.vaccine.fetchPatientImmunizationHistory), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loadingImm = false;
            }))
            .subscribe((response: Array<any>) => {
                this.immunization = response;
            }, () => { this.immunization = new Array<any>(); });
    }
    private findOrderPrescription() {
        let PatientId: number = this.patientId;
        this.httpService.get(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchOrderPrescription), { patientId: PatientId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Array<OrderPrescriptionModel>) => {
                if (response.length > 0) {
                    response.forEach((item: OrderPrescriptionModel) => {
                        var jsonParsed = JSON.parse(item.orderPrescription);
                        this.orders.push(jsonParsed)
                    })
                }
            });
    }
    private findPatientDetails(id: string) {
        this.page.loading = true;
        this.httpService.post<PatientProfile>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.profile), { encryptedPatientId: id })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: PatientProfile) => {
                this.patient = response;


            });
    }

    gotoPrescription(appointmentId: string) {
        this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/prescription/${appointmentId}`, "_blank"); });
    }

    onCloseModal(type?: string) {
        if (type === "document") {
            try {
                this.modalViewRef.close();
                this.modalViewRef = undefined;
            } catch (e) {
                //empty
            }
        } else {
            try {
                this.modalRef.close();
                this.modalRef = undefined;
            } catch (e) {
                //empty  
            }
        }
    }
    private findAppointment(appointmentId: string) {
        const request = {
            encryptedAppointmentId: appointmentId
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointment = response;
                this.encryptedPatientId = response.encryptedPatientId;
                this.fetchFutureAppointments();
                this.fetchLabs();
                this.fetchMedication();
                this.fetchImmunizaion()
                this.fetchLocation()

            });

    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.currentLocation = this.page.userAccount.locationName;
                    const url = this.router.url;
                    this.isAdmission = url.split("/")[4] === "a";
                    this.appointmentId = decodeURIComponent(url.split("/")[3]);
                    this.filters.options.encryptedAppointmentId = decodeURIComponent(this.appointmentId);
                    this.routingValue = url.split("/")[2];
                    this.isTeleconsulatantReport = url.split("/")[6] === "T";
                    this.findPatient();
                    this.findAppointment(this.appointmentId);
                    this.findOrderPrescription();
                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((params: Params) => {
                            const section = params["section"];
                            this.section = section ? section : undefined;
                        });
                } else {
                    this.page.userAccount = undefined;
                }
                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                this.iconService.getIconImage((is) => { this.iconBasics = is; });

            });
    }
    ngAfterViewInit() {
        this.paddingTop();
    }
    paddingTop() {
        if (document.getElementById("header") != null && document.getElementById("obprintContainer") != null) {
            var paddingTopValue = document.getElementById("header").offsetHeight;
            var content = document.getElementById("obprintContainer");
            content.setAttribute('style', `padding-top:${paddingTopValue}px`);
        }
    }

    @HostListener('window:load')
    onLoad() {
        setTimeout(() => { this.paddingTop(); }, 1500)
    }
    @HostListener('window:hashchange')
    onhashchange() {
        setTimeout(() => { this.paddingTop(); }, 500)
    }
    @HostListener('window:resize')
    onResize() {
        this.paddingTop();
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }

    appointmentToggle() {
        this.toggleService.toggle(true);
    }

    timelineToggle() {
        this.timelineService.toggle();
    }

    private fetchFutureAppointments() {
        let request = {

            encryptedPatientId: this.encryptedPatientId,
        }
        this.httpService
            .post<[Appointment]>(ApiResources.getURI(ApiResources.pediatricEncounter.base, ApiResources.pediatricEncounter.findFutureAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: [Appointment]) => {
                    this.futureAppointments = response["appointments"];

                },
                () => {
                    this.futureAppointments = [];

                }
            );
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
    private fetchLocation() {
        this.lodingLocation = true;
        this.resourceService.locations()
            .pipe(finalize(() => { this.lodingLocation = false }))
            .subscribe((response: Array<IResource>) => {
                this.locations = response;
                this.location = this.locations.find(s => s.id == this.appointment.locationId);

            });
    }
}