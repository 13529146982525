import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { IResource, IUserAccount, Page } from "../../../../../shared/models";
import { AppData } from "../../../../../app.data";
import { finalize, takeUntil } from "rxjs";
import { HttpService, NotifyService, ResourceService } from "../../../../../shared/services";
import { ApiResources, DateHelper, UtilHelper } from "../../../../../shared/helpers";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Admission, BedManagement, IPChangeRoom } from "../../../../../shared/entities";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe, Time } from "@angular/common";

class pagePatient {
    admissionId: number;
    admissionNo: string;
    admissionDate: Date;
    roomName: string;
    bedNumber: string;
    chargeCategoryName: string;
    payType: string;
    timeString: Time;
    floorName: string;
    wardName: string;
}
class FilterOptions {
    admissionId?: number
}

class Filters {
    options: FilterOptions;
    applied: boolean;

    constructor() {
        this.init();
    }

    init() {
        this.options = new FilterOptions();
        this.applied = false;
    }
}

@Component({
    templateUrl: "./ip-change-room.html",    
    encapsulation: ViewEncapsulation.None
})


export class IPChangeRoomPage implements OnInit, OnDestroy {
    page: Page;
    filters: Filters;
    admission: IPChangeRoom;
    ipChangeRoom: FormGroup;
    allRooms: Array<IResource>;
    allFloors: Array<IResource>;
    allBeds: Array<BedManagement>;
    isSubmitting: boolean;
    allWards: Array<IResource>;
    chargeCategories: Array<IResource>;
    doctorUnits: Array<IResource>;
    submitting: boolean;
    submitted: boolean;
    showRoom: boolean;
    showRelease: boolean;
    changedRooms: Array<IPChangeRoom>;
    selectedRecord: IPChangeRoom;
    admissions: Array<Admission>;
    sendpatientId: string =null;
    selectedPatient: Admission;
    loading: boolean;
    menuPatientData: pagePatient;
    isPatientSelected: boolean;
    filteredAdmissionNos: Admission[];
    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly formBuilder: FormBuilder,
        private readonly resourceService: ResourceService,
        private readonly notifyService: NotifyService,
        private datePipe:DatePipe,
    ) {
        this.page = new Page();
        this.filters = new Filters();
        this.admission = new IPChangeRoom;
        this.changedRooms = new Array<IPChangeRoom>();
        this.allBeds=new Array<BedManagement>();
        this.buildForm();
        this.menuPatientData = new pagePatient();
        this.isPatientSelected = false;
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchAdmissions();
                    this.fetchAllChangedData();
                    this.onchange("C");
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }
    
    ngOnDestroy() {       
        this.page.unsubscribeAll();
    }

    private buildForm() {
        this.ipChangeRoom = this.formBuilder.group({

            admissionId: [null, [Validators.required]],
            currentBedId: [null],
            currentBedNumber: [null],
            currentUnitName: [null],
            currentRoomId: [null],
            currentRoomName: [null],
            currentWardId: [null],
            currentWardName: [null],
            currentFloorId: [null],
            currentFloorName: [null],
            cost: [null],
          
            changedBedId: [null],
            changedBedNumber: [null, [Validators.required]],
            changedRoomId: [null],
            changedRoomName: [null],
            changedWardId: [null],
            changedWardName: [null],
            changedFloorId: [null],
            changedFloorName: [null],
           
            
        })
    }
   

    onchange(value: string) {
        if (value == "C") {
            this.isPatientSelected = false;
            this.showRoom = true;             
            this.sendpatientId = null;
            this.selectedPatient = null;
            this.selectedRecord = null;
            //this.onResetFilters();
            this.buildForm();
            this.ipChangeRoom.get('admissionId').setValidators(Validators.required);
            this.ipChangeRoom.get('changedBedNumber').setValidators(Validators.required);
            this.fetchAdmissions();
        }
        else {
            this.showRoom = false; 
            this.isPatientSelected = false;
            this.sendpatientId = null;
            this.selectedPatient = null;
            this.selectedRecord = null;
            //this.onResetFilters();
            this.buildForm();
            this.fetchAllChangedData();
            this.ipChangeRoom.get('changedBedNumber').setValidators(Validators.required);        }
    }
    
    get form() {
        return this.ipChangeRoom.controls;
    }

    onSelectAdmission(event: any) {
        this.sendpatientId = null;
        this.selectedPatient = new Admission();
        this.selectedRecord = new IPChangeRoom();
        this.menuPatientData = new pagePatient();
        if (event != undefined) {         
            this.isPatientSelected = true;
            this.selectedPatient = this.admissions.find(x => x.admissionId == event.admissionId);
            this.sendpatientId = this.selectedPatient.encryptedPatientId;

            this.menuPatientData.admissionNo = this.selectedPatient.admissionNo;
            this.menuPatientData.admissionDate = this.selectedPatient.admissionDate;
            this.menuPatientData.bedNumber = this.selectedPatient.bedNumber;
            this.menuPatientData.chargeCategoryName = this.selectedPatient.chargeCategoryName;
            this.menuPatientData.payType = this.selectedPatient.payType;
            this.menuPatientData.roomName = this.selectedPatient.roomName;
            this.menuPatientData.floorName = this.selectedPatient.floorName;
            this.menuPatientData.wardName = this.selectedPatient.wardName;
            this.fetchAllBeds();
        }
        else {
            this.isPatientSelected = false;
        }
    }



    onchangeBed(event: any) {
        var selectedBed = this.allBeds.find(x => x.bedId == event.bedId);
        if (selectedBed) {
            this.ipChangeRoom.patchValue({
                changedBedId: selectedBed.bedId,
                changedRoomId: selectedBed.roomId,
                changedRoomName: selectedBed.roomName,
                changedWardId: selectedBed.wardId,
                changedWardName: selectedBed.wardName,
                changedFloorId: selectedBed.floorId,
                changedFloorName: selectedBed.floorName,
                cost: selectedBed.cost
            });
        }
        else {
            this.ipChangeRoom.patchValue({
                changedBedId: null,
                changedRoomId: null,
                changedRoomName: null,
                changedWardId: null,
                changedWardName: null,
                changedFloorId: null,
                changedFloorName: null,
            });
        }
        
    }

    fetchAllChangedData = () => {
        const request = {
            locationId: this.page.userAccount.locationId
        };
        this.httpService.post<Array<IPChangeRoom>>(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchChangedRoomData), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IPChangeRoom>) => {
                this.changedRooms = response;
                if (this.changedRooms?.length > 0 && this.admissions?.length > 0) {
                    this.filteredAdmissionNos = this.admissions.filter(admission =>
                        this.changedRooms.some(changedRoom => admission.admissionNo === changedRoom.admissionNo)
                    );
                }
            });

    }
   
    getSelectedChangedRoom(event: any) {
        this.sendpatientId = null;
        this.selectedPatient = new Admission();
        this.selectedRecord = new IPChangeRoom();
        this.menuPatientData = new pagePatient();
        //this.isPatientSelected = false;
        if (event == undefined) {           
            this.isPatientSelected = false;
            this.ipChangeRoom.patchValue({
                changedBedNumber: null,
                changedRoomName: null,
                changedWardName: null,
                changedFloorName: null,
                changedBedId: null,
            })
        }          
           
            this.selectedRecord = this.changedRooms.find(x => x.admissionId == event.admissionId);
        if (this.selectedRecord) {
            this.isPatientSelected = true;
            this.sendpatientId = this.selectedRecord.encryptedPatientId;
            this.menuPatientData.admissionId = this.selectedRecord.admissionId;
            this.menuPatientData.admissionNo = this.selectedRecord.admissionNo;
            this.menuPatientData.admissionDate = this.selectedRecord.admissionDate;
            this.menuPatientData.bedNumber = this.selectedRecord.changedBedNumber;
            this.menuPatientData.chargeCategoryName = this.selectedRecord.changedChargeCategoryName;
            this.menuPatientData.payType = this.selectedRecord.payType;
            this.menuPatientData.roomName = this.selectedRecord.changedRoomName;
            this.menuPatientData.floorName = this.selectedRecord.changedFloorName;
            this.menuPatientData.wardName = this.selectedRecord.changedWardName;
                this.ipChangeRoom.patchValue({
                    changedBedNumber: this.selectedRecord.currentBedNumber,
                    changedRoomName: this.selectedRecord.currentRoomName,
                    changedWardName: this.selectedRecord.currentWardName,
                    changedFloorName: this.selectedRecord.currentFloorName,
                    changedBedId: this.selectedRecord.changedBedId,
                })
            }
            
    }



    //onApplyFilters() {
    //    this.filters.applied = UtilHelper.isFiltersApplied(this.filters.options);
    //    if (this.showRoom) {
    //        //this.getAdmission();
    //    }
    //    else {
    //        this.getSelectedChangedRoom();
    //    }                    
    //}


    //onResetFilters() {
    //    this.filters.init();
    //    this.sendpatientId = null;
    //    this.selectedPatient = new Array<Admission>();
    //    this.selectedRecord = new Array<IPChangeRoom>();
    //    this.menuPatientData = new pagePatient();
    //    this.buildForm();
    //} 
   
    private fetchAllBeds() {
        const request = {
            locationId: this.page.userAccount.locationId,
            chargeModuleTemplateId: this.selectedPatient?.chargeModuleTemplateId 
        };
        this.httpService
            .post<Array<BedManagement>>(ApiResources.getURI(ApiResources.bed.base, ApiResources.bed.fetchNonTransitBeds), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: (response: Array<BedManagement>) => {
                    this.allBeds = response;

                },
                error: (error:any) => {
                    this.allBeds = Array<BedManagement>();
                }
            });
    }
   
    private fetchAdmissions() {
        const request = Object.assign(UtilHelper.clone(this.filters.options));
        request.locationId = this.page.userAccount.locationId;   
        this.httpService
            .post<Array<Admission>>(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.admissionsWithoutDischarge), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: (response: Array<Admission>) => {

                    this.admissions = response;
                    this.admissions.forEach((item: Admission) => {
                        if (item.isDischarged) {
                            item.dischargeTimeString =
                                DateHelper.getTimeFromTicks(item.dischargeTime["value"]["ticks"]);
                            item.dischargeDate =
                                new Date(item.dischargeDate.toString().replace("T00:00:00", item.dischargeTimeString));
                        }
                        if (item.expectedDischargeDate) {
                            item.expectedDischargeDateView = this.datePipe.transform(new Date(item.expectedDischargeDate), 'yyyy-MM-dd');
                        }
                    });

                },
                error: () => {
                    this.admissions = [];
                }
            });
    }

    searchAdmission(term: string, item: any): boolean {
        term = term.toLowerCase();
        return item.patientName.toLowerCase().includes(term)
            || item.admissionNo.toLowerCase().includes(term)
            || item.umrNo.toLowerCase().includes(term)
            || item.patientMobile.toLowerCase().includes(term);
    }


    onSubmitChanged() {
        this.submitted = true;
       
       if (!this.ipChangeRoom.valid) {
            return;
        }
        this.submitting = true;
        var admissionId = this.ipChangeRoom.get('admissionId').value;
        const request = Object.assign(UtilHelper.clone(this.ipChangeRoom.getRawValue()));        
        request["changedBy"] = this.page.userAccount.accountId;
        request["isReleased"] = false;
        request["admissionId"] = this.selectedPatient.admissionId;
        request["currentBedId"] = this.selectedPatient.bedId;
        request["changedBedNumber"] = "";
        request["createdBy"] = this.page.userAccount.accountId;
        this.httpService.post<IPChangeRoom>(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.addRoomChange), request)
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => {
                    this.submitting = false;
                    this.submitted = false;
                }))
            .subscribe(
                {
                    next: () => {
                        var count = this.changedRooms.filter(x => x.admissionId == admissionId);
                        if (count.length > 0) {
                            this.getSelectedChangedRoom({ admissionId });
                        }
                        this.notifyService.successToast("Room has been changed successfully.");
                        this.filters.init();
                        this.sendpatientId = null;
                        this.selectedPatient = new Admission();
                        this.selectedRecord = new IPChangeRoom();
                        this.menuPatientData = new pagePatient();
                        this.isPatientSelected = false;
                        this.buildForm();
                        this.ipChangeRoom.get('admissionId').clearValidators();
                        this.ipChangeRoom.get('changedBedNumber').clearValidators();
                    },
                    error: (error: HttpErrorResponse) => {
                        const errorMessage = UtilHelper.handleError(error);
                        if (errorMessage) {
                            this.notifyService.warning(errorMessage);
                        } else {
                            this.notifyService.defaultError();
                        }
                    }
                }
            );

        
    }

    onSubmitReleased(flag=true) {
        this.submitted = true;
        if (!this.ipChangeRoom.valid) {
            return;
        }
        this.submitting = true;
        const request = Object.assign(UtilHelper.clone(this.ipChangeRoom.getRawValue()));
        request["ipRoomChangedId"] = this.selectedRecord.ipRoomChangedId;
        request["admissionId"] = this.selectedRecord.admissionId;  
        request["releasedByName"] = this.page.userAccount.fullName;
        request["releasedBy"] = this.page.userAccount.accountId;
        request["isReleased"] = true;       
        this.httpService.post(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.releaseRoom), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.submitting = false;
                this.submitted = false;
            }))
            .subscribe({
                  next: (res:any) => {
                        if (flag) {
                            this.notifyService.successToast("Room has been released successfully.");
                        }
                        this.filters.init();
                        this.sendpatientId = null;
                        this.selectedPatient = new Admission();
                        this.selectedRecord = new IPChangeRoom();
                        this.menuPatientData = new pagePatient();
                        this.isPatientSelected = false;
                        this.buildForm();
                        this.fetchAllChangedData();
                        this.ipChangeRoom.get('changedBedNumber').clearValidators();
                  },
                  error: (error: HttpErrorResponse) => {
                        const errorMessage = UtilHelper.handleError(error);
                        if (errorMessage) {
                            this.notifyService.warning(errorMessage);
                        } else {
                            this.notifyService.defaultError();
                        }
                  }
            });
        
    }
}

   
