export class PackageModuleDetailModel {
    packageModuleDetailId: number;
    packageModuleId: number;
    modulesMasterId: number;
    moduleName: string;
    moduleIcon: string;
    referenceId: number;
    quantity: number;
    isFree: boolean;
    amount: number;
    active: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy?: number;
    modifiedDate?: Date;
    isChargeCategoryApplicable: boolean;
    packageType: string;
    locationId: number;
    userAccountId: number;
    chargeName: string;
    chargeGroupId?: number;
    chargeGroupName: string;
    departmentId?: number;
    departmentName: string;
    repeatTypeId?: number;
    repeatTypeName: string;
    isNA: boolean;
    serviceTotal?: number;
    packageName: string;
    admissionPackageId: number;
    expiryDate: Date;
    expiryDateString: string;
    index?: number;
}