<div class="mt-1 scroll-obsecNotHeading">
    <div class="col-12 p-0">

        <div class="d-flex justify-content-between align-items-center pl-1 pr-1 pt-1" style="background-color: #edfaf7;">
            <div>
                <button class="btn btn-info" type="button" (click)="onChangePrintType( false)">
                    <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                </button>
                <button class="btn btn-info" type="button" (click)="onChangePrintType(true)">
                    <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                </button>
                <button class="btn btn-info" type="button" (click)="onChangeFooter( false)">
                    <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
                </button>
                <button class="btn btn-info" type="button" (click)="onChangeFooter(true)">
                    <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
                </button>
            </div>
            <div>
                <button printSectionId="geneticprint" id="printButton" ngxPrint [useExistingCss]="true" class="btn btn-lg d-print-none"><i class="fe-printer" placement="left" ngbTooltip="Print"></i></button>
            </div>
        </div>
    </div>


    <div id="geneticprint" class="border-left border-right bg-white">
        <div class="report pb-2 w-100 position-relative">
            <div class="row" *ngIf="page.loading">
                <div class="col-12">
                    <div class="d-flex align-items-center">
                        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                        <span class="ml-2">Please wait while generating IEncounter Prescription ...</span>
                    </div>
                </div>
            </div>
            <div class="page-header  text-center" [ngClass]="isPrintLogo ? '' :'d-none'">
                <banner-setting [reportName]='"Physiotherapy Encounter Report Counselling Header"'></banner-setting>
            </div>
            <div class="pl-0 pr-0 page-footer">
                <div class="border-dark border-bottom w-100 pt-1 d-flex align-content-end">
                    <div class="d-flex" style="font-weight:700;">
                        <div><span>Print Date/Time :&nbsp;</span></div>
                        <div><span [textContent]="date | date :'d/MM/yyyy'"></span>&nbsp;<span [textContent]="date | date :' h:mm a'"></span></div>
                    </div>
                </div>
                <div *ngIf="isFooter">
                    <banner-setting [reportName]='"Physiotherapy Encounter Report Counselling Footer"'></banner-setting>
                </div>
            </div>
            <table class="w-100">
                <thead>
                    <tr>
                        <td>
                            <!--place holder for the fixed-position header-->
                            <div class="page-header-space"></div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <!--*** CONTENT GOES HERE ***-->
                            <div class="d-flex text-center justify-content-center text-uppercase">
                                <h3>
                                    {{pageheader}}
                                </h3>
                            </div>
                            <h4 class="d-flex justify-content-end mt-3">
                                Department of Physiotherapy
                            </h4>
                            <ng-container *ngIf="!page.loading && appointment">
                                <div class="overflow-auto">
                                    <table class="w-100 white-space-nowrap border-top-dotted mt-1 border-bottom-dotted">
                                        <tr class="col-12">
                                            <td class="text-left">
                                                <div class="pr-3"> Name  </div>
                                            </td>
                                            <td>:</td>
                                            <td>
                                                <h5 class="pl-1  d-inline-block text-uppercase" [textContent]="appointment.patientName"></h5>
                                            </td>
                                            <td class="text-left">
                                                <div class="pr-3"> Age </div>
                                            </td>
                                            <td>:</td>
                                            <td>
                                                <h5 class="pl-1  d-inline-block" [textContent]="appointment.patientAge + ' years'"> </h5>
                                            </td>
                                            <td class="text-left">
                                                <div class="pr-3">UMR No </div>
                                            </td>
                                            <td>:</td>
                                            <td>
                                                <h5 class="pl-1  d-inline-block text-uppercase" [textContent]="appointment.umrNo"></h5>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="pr-3">Location </div>
                                            </td>
                                            <td>:</td>
                                            <td colspan="4">
                                                <h5 class="pl-1  d-inline-block text-uppercase" [textContent]="appointment.appointmentLocationName"></h5>
                                            </td>
                                            <td>
                                                <div class="pr-3">Date </div>
                                            </td>
                                            <td>:</td>
                                            <td>
                                                <h5 class="pl-1  d-inline-block text-uppercase" [textContent]="appointment.appointmentDate| date :'dd/MM/yyyy'"></h5>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </ng-container>
                            <div class="w-100 mt-2">
                                <div *ngIf="physioTherapyTemplate && physioTherapyTemplate.templates.length >0">
                                    <div class="mb-0">
                                        <div class="vertical-align-top" *ngFor="let item of physioTherapyTemplate.templates; let i = index">
                                            <h4>Description Summary ({{item.templateName}}) </h4>
                                            <div class="col-12 pr-0">
                                                <span [innerHTML]="item.description"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngFor="let image of teplateDocuments">
                                <div class="document-container">
                                    <div class="document-body">
                                        <img Image.value [src]="image.formedUrl['changingThisBreaksApplicationSecurity']" alt="URL" class="img-fluid imgstyle">
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="appointment" class=" mt-2 align-items-end col-12 d-flex flex-wrap justify-content-between p-0 column-gap15px">
                                <div class="d-flex flex-grow-1">
                                    <div><span>Email :&nbsp;</span></div>
                                    <div class="text-truncate"><h5 class="text-lowercase" [textContent]="appointment.providerEmail"></h5></div>
                                </div>
                                <div class="d-flex flex-wrap column-gap15px align-items-end justify-content-end flex-grow-1">
                                    <div class="d-flex flex-grow-1 justify-content-center">
                                        <div><span>Provider Name :&nbsp;</span></div>
                                        <div><h5 class="text-capitalize" [textContent]="appointment.providerName"> </h5></div>
                                    </div>
                                    <div class="d-flex align-items-end">
                                        <div><span>Signature :&nbsp;</span></div>
                                        <div><h5 class="border-bottom border-dark"><img *ngIf="appointment.signature!=null" [src]="safe(appointment.signature)" cssClass="img-fluid rounded-circle" style="height:80px" /></h5></div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2">
                                <h5 class="d-inline">Review:</h5> All these exercises are for 6weeks after which please consult your OBG/Physiotherapist for further progress
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <div class="page-footer-space">
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>