<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box text-right text-right">
                    <div class="page-title-right">
                        <button [disabled]="loading" class="btn btn-sm btn-success mr-1" type="button" (click)="exportToExcel()"><i class="mdi mdi-file-excel mr-1"></i>Excel</button>
                    </div>
                    <h4 class="page-title">OP Feedbck Report</h4>
                </div>
            </div>
        </div>
        <div class="col-12 p-0" *ngIf="!loading">
            <div class="card-box mb-1 p-1">
                <table class="table table-borderless table-sm mb-0">
                    <tr class="myFlex">
                        <td class="col-6 col-sm-4 col-lg-2">
                            <div class="form-group mb-0">
                                <label class="mb-1">From Date</label>
                                <input class=" form-control overflow-hidden"
                                       type="date"
                                       placeholder="Select from date"
                                       [(ngModel)]="filters.options.dateFrom"
                                       #fromDate
                                       [max]="maxDate"
                                       (input)="changeMaxDate(fromDate.value)" />
                            </div>
                        </td>
                        <td class="col-6 col-sm-4 col-lg-2">
                            <div class="form-group mb-0 ">
                                <label class="mb-1">To Date</label>
                                <input class=" form-control overflow-hidden"
                                       type="date"
                                       placeholder="Select from date"
                                       [(ngModel)]="filters.options.dateTo"
                                       #toDate
                                       [min]="minDate"
                                       (input)="changeMinDate(toDate.value)" />
                            </div>
                        </td>
                        <td class="col-6 col-sm-4 col-lg-2">
                            <div class="d-flex form-group mb-0 justify-content-center lessThan1468">
                                <button type="button" (click)="onApplyFilters()" class="btn btn-sm  btn-primary rounded-pill"><i class="mdi mdi-magnify mr-1"></i>Search</button>
                                <button type="button" (click)="onResetFilters()" class="btn btn-sm reset-btn rounded-pill ml-1"> <i class="mdi mdi-backup-restore mr-1"></i>Reset</button>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="row" *ngIf="loading">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading  reports...</span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!loading && records.length === 0">
            <div class="col-12 mh-300">
                <no-data [applied]="filters.applied" [title]="'Reports'"></no-data>
            </div>
        </div>
        <div class="row" *ngIf="!loading && records && records.length > 0">
            <div class="col-12">

                <div class="card mb-0">
                    <div class="card-body p-0">
                        <div class="tableFixHead table-responsive">
                            <table class="table table-centered table-sm table-striped table-bordered mb-0">

                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Apt No</th>
                                        <th>Patient Name</th>
                                        <th>UMR NO</th>
                                        <th>Patient Mobile</th>
                                        <th>Provider Name</th>
                                        <th>Specialization</th>
                                        <th>Apt Date</th>
                                        <th>Patient Age</th>
                                        <th>Location</th>
                                        <th>Email</th>
                                        <th>Gender</th>
                                        <th>Patient Type</th>
                                        <th>Alternate Number</th>
                                        <th>Flag</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of records;let i = index;">
                                        <td>{{i+1}}</td>
                                        <td [textContent]="item.appointmentNo || '----'"></td>
                                        <td [textContent]="item.patientName || '----'"></td>
                                        <td [textContent]="item.umrNo || '----'"></td>
                                        <td [textContent]="item.patientMobile || '----'"></td>
                                        <td [textContent]="item.providerName || '----'"></td>
                                        <td [textContent]="item.specializationName || '----'"></td>
                                        <td [textContent]="item.appointmentDate | date:'dd-MM-yyyy'"> </td>
                                        <td [textContent]="item.patientAge || '----'"></td>
                                        <td [textContent]="item.locationName"></td>
                                        <td [textContent]="item.patientEmail"></td>
                                        <td [textContent]="item.patientGender"></td>
                                        <td [textContent]="item.patientsType"></td>
                                        <td [textContent]="item.alternateNumber"></td>
                                        <td [textContent]="item.flag"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
