
<div class="  mt-1 text-black" [class]="{'scroll-obsecNotHeading':!onlyUrl}">
    <div class="container image_section">
        <button class="btn btn-info" type="button" (click)="onChangePrintType( false)">
            <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
        </button>
        <button class="btn btn-info" type="button" (click)="onChangePrintType(true)">
            <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
        </button>
        <button class="btn btn-info" type="button" (click)="onChangeFooter(false)">
            <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
        </button>
        <button class="btn btn-info" type="button" (click)="onChangeFooter(true)">
            <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
        </button>
    </div>
    <div id="obprint" class="container image_section" style="text-align:initial;">
        <div class="card-box mb-0 font-20">
            <div class="clearfix mb-3">
                <div class="align-items-center float-right row">
                    <h4 class="m-0 d-print-none heading2">OB Encounter Full Transcript</h4>
                    <button [useExistingCss]="true" printSectionId="obprint" ngxPrint class="close d-print-none"><i class="mdi mdi-printer" placement="left" ngbTooltip="Print"></i></button>
                </div>
            </div>
            <div class="row" *ngIf="page.loading">
                <div class="col-12">
                    <div class="d-flex align-items-center">
                        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                        <span class="ml-2">Please wait while generating IEncounter Full Transcript ...</span>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!page.loading && (!fullTranscript || (!fullTranscript.obEncounterId))">
                <div class="col-12 mh-300">
                    <no-data title=" Full Transcript for OB Encounter"></no-data>
                </div>
            </div>
            <ng-container *ngIf="!page.loading && fullTranscript">
                <ng-container *ngIf="!page.loading && fullTranscript && !isTeleconsulatantReport">
                    <div class="col-12 p-0" *ngIf="isPrintLogo">
                        <banner-setting [reportName]='"Ob FullTranscript Header"'></banner-setting>
                    </div>
                    <div *ngIf="!isPrintLogo" class="col-12 p-0 mtp100">
                    </div>
                </ng-container>
                <hr />
                <div class="d-flex flex-wrap justify-content-between heading1 white-space-nowrap">
                    <div class="col-6 p-0">
                        <div>
                            <label>Patient Name:</label>
                            <span class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="fullTranscript.patientName"></span>
                        </div>
                        <div *ngIf="fullTranscript.patientMobile">
                            <label>Mobile No:</label>
                            <span class="pl-1" [textContent]="fullTranscript.patientMobile + ' / '"></span>
                            <span class="pl-1" *ngIf="fullTranscript.patientGender" [textContent]="(fullTranscript.patientGender | gender) + ' / '"></span>
                            <span class="pl-1" *ngIf="fullTranscript.patientAge" [textContent]="fullTranscript.patientAge +' yrs'"></span>
                            <span class="pl-1" *ngIf="!fullTranscript.patientAge" [textContent]="'0 yrs' "></span>
                        </div>
                    </div>
                    <div class="d-inline-block">
                        <div>
                            <label>Appointment No:</label>
                            <span class="pl-1">{{fullTranscript.appointmentNo  ? fullTranscript.appointmentNo :fullTranscript.admissionId }}</span>
                        </div>
                        <div>
                            <label>Appointment Date:</label>
                            <span class="pl-1" *ngIf="!fullTranscript.admissionId" [textContent]="(fullTranscript.appointmentDate | date:'dd/MM/yyyy') + ' '+ fullTranscript.appointmentTimeString"></span>
                            <span class="pl-1" *ngIf="fullTranscript.admissionId" [textContent]="(fullTranscript.admissionDate |date:'dd/MM/yyyy') + ' '+ (appointmentList.admTime) "></span>
                        </div>
                    </div>
                    <div class="d-flex" *ngIf="ancCards && ancCards!=null  && (ancCards.gravida || ancCards.para || ancCards.abortion || ancCards.living)">
                        <div class="pl-0 d-flex col-7">
                            <div>
                                <div><h5 class="white-space-nowrap">Obstetric Formula &nbsp;:</h5></div>
                            </div>
                            <div *ngIf="ancCards && ancCards!=null" class="pl-3">
                                <table class="pt-2">
                                    <tr class=" vertical-align-top">
                                        <th class="line-height-1px">G</th>
                                        <th></th>
                                        <th class="line-height-1px">P</th>
                                        <th></th>
                                        <th class="line-height-1px">L</th>
                                        <th></th>
                                        <th class="line-height-1px">A</th>
                                        <th></th>
                                    </tr>
                                    <tr class=" vertical-align-top">
                                        <td></td>
                                        <td class="p-1" [textContent]="ancCards.gravida"></td>
                                        <td></td>
                                        <td class="p-1" [textContent]="ancCards.para"></td>
                                        <td></td>
                                        <td class="px-1" [textContent]="ancCards.living"></td>
                                        <td></td>
                                        <td class="px-1" [textContent]="ancCards.abortion"></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div><hr />
            </ng-container>
            <ng-container *ngIf="!page.loading && fullTranscript">
                <div class="w-100">
                    <div class="dashboard dashboard-table" #ancCardGenerationSection *ngIf="ancCardGeneration && ancCardGeneration !== null">
                        <h4 class="dashboard-title font-weight-bold pl-2">ANC Card Generation</h4>
                        <div class=" border-bottom border-top border-dark">
                            <div class="overflow-auto">
                                <table class="  table-nowrap table-sm  mb-0">
                                    <tr *ngIf="ancCardGeneration.department">
                                        <td class="col-3">Department</td>
                                        <td class="col-4" [textContent]="ancCardGeneration.department"> </td>
                                    </tr>
                                    <tr *ngIf="ancCardGeneration.ancNo">
                                        <td class="col-3">ANC No </td>
                                        <td class="col-4" [textContent]="ancCardGeneration.ancNo"> </td>
                                    </tr>
                                    <tr *ngIf="ancCardGeneration.husbandName">
                                        <td class="col-3">Husband Name</td>
                                        <td class="col-4" [textContent]="ancCardGeneration.husbandName"> </td>
                                    </tr>
                                    <tr *ngIf="ancCardGeneration.husbandAge">
                                        <td class="col-3">Husband Age</td>
                                        <td class="col-4" [textContent]="ancCardGeneration.husbandAge"> </td>
                                    </tr>
                                    <tr *ngIf="ancCardGeneration.remarks">
                                        <td class="col-3">Remarks</td>
                                        <td class="col-4" [textContent]="ancCardGeneration.remarks"> </td>
                                    </tr>
                                    <tr>
                                        <td class="col-3">RCHID / PICME NO</td>
                                        <td class="col-3" *ngIf="ancCardGeneration.rchidNo" [textContent]="ancCardGeneration.rchidNo"> </td>
                                        <td class="col-4" *ngIf="ancCardGeneration && ancCardGeneration.rchidNo==null"> </td>
                                    </tr>
                                    <tr *ngIf="ancCardGeneration.consultantDoctor">
                                        <td class="col-3">Consultant Doctor</td>
                                        <td class="col-4" [textContent]="ancCardGeneration.consultantDoctor.toUpperCase()"> </td>
                                    </tr>
                                    <tr *ngIf="ancCardGeneration.registrationDate">
                                        <td class="width-fixed">Registration Date</td>
                                        <td class="col-4" [textContent]="ancCardGeneration.registrationDate | date: 'dd/MM/yyyy'"> </td>
                                    </tr>
                                    <tr *ngIf="ancCardGeneration.ancNoExists">
                                        <td class="col-3">ANC No Exists</td>
                                        <td class="col-4" [textContent]="ancCardGeneration.ancNoExists"> </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="dashboard dashboard-table" #meausreSection *ngIf="measure && measure !== null">
                            <h4 class="dashboard-title font-weight-bold pl-2">Measures</h4>
                            <div class="dashboard-body">
                                <div>
                                    <div *ngIf="measure && measure !== null && (measure.visitNumber || measure.department || measure.heightInFeet || measure.heightInMeters || measure.weight || measure.bodyMassIndex || measure.bmiCategorization || measure.pulseRate || measure.systolic || measure.diastotic || measure.respiratoryRate || measure.tempFahrenhit|| measure.gestationAge ||
                                         measure.uterusPulpable || measure.fundusHeight || measure.sfHeight || measure.presentation || measure.fhs || measure.amountofliquor || measure.oedema || measure.urineAlbumin  || measure.otherComplaints || measure.highRisk || measure.lastUSGDate || measure.typeOfUltraSoundScan || measure.usgReport || measure.consultedByDr || measure.otherConsultedDr || measure.nextReviewDate ||
                                         measure.externalGenitalia || measure.psVagina || measure.psCervix || measure.psDischarge || measure.cervix || measure.presentingPart || measure.stationPP || measure.liquor|| measure.pelvis|| measure.other || measure.externalGenitaliaComment|| measure.cervicalOSCm|| measure.membranes )">
                                        <div class="row" *ngIf="measure && measure !== null && (measure.visitNumber || measure.department || measure.heightInFeet || measure.heightInMeters || measure.weight || measure.bodyMassIndex || measure.bmiCategorization || measure.pulseRate || measure.systolic || measure.diastotic || measure.respiratoryRate || measure.tempFahrenhit|| measure.gestationAge)">
                                            <div class="col-12 d-flex mb-2">
                                                <div class="col-6 pl-1 pr-0 border-bottom border-top border-dark">
                                                    <table class="  table-nowrap table-sm  mb-0">
                                                        <tr *ngIf="measure.visitNumber">
                                                            <td class="col-8">Visit Number</td>
                                                            <td class="col-4" [textContent]="measure.visitNumber"> </td>
                                                        </tr>
                                                        <tr *ngIf="measure.heightInFeet">
                                                            <td class="col-8">Height In Feet</td>
                                                            <td class="col-4" [textContent]="measure.heightInFeet"> </td>
                                                        </tr>

                                                        <tr *ngIf="measure.weight">
                                                            <td class="col-8">Weight (Kg)</td>
                                                            <td class="col-4" [textContent]="measure.weight"> </td>
                                                        </tr>

                                                        <tr *ngIf="measure.bmiCategorization">
                                                            <td class="col-8">BMI Categorization</td>
                                                            <td class="col-4" [textContent]="measure.bmiCategorization"> </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div class="col-6 border-left pr-0 pl-0 border-bottom border-top border-dark">
                                                    <table class=" table-nowrap table-sm mb-0">
                                                        <tr *ngIf="measure.department">
                                                            <td class="col-8">Department</td>
                                                            <td class="col-4" [textContent]="measure.department"> </td>
                                                        </tr>
                                                        <tr *ngIf="measure.heightInMeters">
                                                            <td class="col-8">Height In Meters</td>
                                                            <td class="col-4" [textContent]="measure.heightInMeters"> </td>
                                                        </tr>

                                                        <tr *ngIf="measure.bodyMassIndex">
                                                            <td class="col-8">Body Mass Index (BMI)</td>
                                                            <td class="col-4" [textContent]="measure.bodyMassIndex"> </td>
                                                        </tr>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!isNurseDisplay">
                        <div class="dashboard dashboard-table" #ancCardsSection *ngIf="ancCards && ancCards !== null">
                            <h4 class="dashboard-title font-weight-bold pl-2">ANC Card</h4>
                            <div class="dashboard-body">
                                <div>
                                    <div *ngIf=" ancCards && ancCards !== null && ( ancCards.yearOfMarriage || ancCards.gravida || ancCards.para || ancCards.abortion || ancCards.living || ancCards.menstrualPattern || ancCards.days || ancCards.conception || ancCards.contraceptives || ancCards.consanguinity || ancCards.deliveryStatus || ancCards.presentPregnancyDetails
                                        || ancCards.bloodGroup || ancCards.rhType || ancCards.lmpStatus || ancCards.lmp || ancCards.eddType || ancCards.ultraSoundEdd  || ancCards.ga ||  ancCards.bookingStatus || ancCards.willSheDeliver || ancCards.providerName || ancCards.specialFeature
                                         ||  ancCards.husbandBloodGroup || ancCards.husbandRhType || ancCards.referralDoctor || ancCards.smoking || ancCards.alcohol || ancCards.otherSubstances || ancCards.cordBloodBanking || ancCards.prenatalScreeningOffered || ancCards.diet || ancCards.lifeStyle || ancCards.toiletPreference || ancCards.financialCouncelling || ancCards.timeTakenToReachHospital || ancCards.distanceInKilometers || ancCards. prePregWeight)">

                                        <div class="row" *ngIf=" ancCards && ancCards !== null && ( ancCards.yearOfMarriage || ancCards.gravida || ancCards.para || ancCards.abortion || ancCards.living || ancCards.menstrualPattern || ancCards.days || ancCards.conception || ancCards.contraceptives || ancCards.consanguinity || ancCards.deliveryStatus || ancCards.presentPregnancyDetails)">
                                            <h6 class="font-18 mt-0 mb-2 pl-3">MENSTRUAL HISTORY AND EDD</h6>
                                            <div class="col-12 d-flex">
                                                <div class="col-6 pl-1 pr-0 border-bottom border-top border-dark">
                                                    <table class=" table-nowrap table-sm  mb-0">
                                                        <tr *ngIf="ancCards.yearOfMarriage">
                                                            <td class="col-8">Year Of Marriage</td>
                                                            <td class="col-4" [textContent]="ancCards.yearOfMarriage"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.menstrualPattern">
                                                            <td class="col-8">Menstrual Pattern</td>
                                                            <td class="col-4" [textContent]="ancCards.menstrualPattern"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.gravida ">
                                                            <td class="col-8"> Gravida </td>
                                                            <td class="col-4" [textContent]="ancCards.gravida "></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.living">
                                                            <td class="col-8">Living</td>
                                                            <td class="col-4" [textContent]="ancCards.living"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.ectopic ">
                                                            <td class="col-8">Ectopic </td>
                                                            <td class="col-4" [textContent]="ancCards.ectopic "></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.contraceptives">
                                                            <td class="col-8"> Contraceptives</td>
                                                            <td class="col-4" [textContent]="ancCards.contraceptives"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.deliveryStatus">
                                                            <td class="col-8">Delivery Status</td>
                                                            <td class="col-4" [textContent]="ancCards.deliveryStatus"></td>
                                                        </tr>

                                                    </table>
                                                </div>
                                                <div class="col-6 border-left pr-0 pl-0 border-bottom border-top border-dark">
                                                    <table class=" table-nowrap table-sm  mb-0">
                                                        <tr *ngIf="ancCards.conception">
                                                            <td class="col-8"> Conception</td>
                                                            <td class="col-4" [textContent]="ancCards.conception"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.days">
                                                            <td class="col-8">Days</td>
                                                            <td class="col-4" [textContent]="ancCards.days"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.para">
                                                            <td class="col-8">Para</td>
                                                            <td class="col-4" [textContent]="ancCards.para"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.abortion">
                                                            <td class="col-8">Abortion</td>
                                                            <td class="col-4" [textContent]="ancCards.abortion"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.consanguinity">
                                                            <td class="col-8">Consanguinity</td>
                                                            <td class="col-4" [textContent]="ancCards.consanguinity"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.consanguinityComment">
                                                            <td class="col-8">If Yes Degree</td>
                                                            <td class="col-4" [textContent]="ancCards.consanguinityComment"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.presentPregnancyDetails">
                                                            <td class="col-8">Present Pregnancy Details</td>
                                                            <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="ancCards.presentPregnancyDetails"></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf=" ancCards && ancCards !== null && ( ancCards.bloodGroup || ancCards.rhType)">
                                            <h6 class="font-18 mt-0 mb-2 mt-1 pl-3">BLOOD GROUP DETAILS</h6>
                                            <div class="col-12 d-flex">

                                                <div class="col-6 pl-1">
                                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                        <tr *ngIf="ancCards.bloodGroup">
                                                            <td class="col-8">Blood Group</td>
                                                            <td class="col-4" [textContent]="ancCards.bloodGroup"></td>
                                                        </tr>

                                                    </table>
                                                </div>
                                                <div class="col-6">
                                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                        <tr *ngIf="ancCards.rhType">
                                                            <td class="col-8"> RhType </td>
                                                            <td class="col-4" [textContent]="ancCards.rhType"></td>
                                                        </tr>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="row" *ngIf=" ancCards && ancCards !== null && ( ancCards.lmpStatus || ancCards.lmp || ancCards.eddType || ancCards.ultraSoundEdd  || ancCards.ga)">
                                            <h6 class="font-18 mt-0 mb-2 pl-3">LMP AND EDD</h6>
                                            <div class="col-12 d-flex">
                                                <div class="col-6 pl-1  pr-0 border-bottom border-top border-dark">
                                                    <table class=" table-nowrap table-sm  mb-0">
                                                        <tr *ngIf="ancCards.lmpStatus">
                                                            <td class="col-8">LMP Status</td>
                                                            <td class="col-4" [textContent]="ancCards.lmpStatus"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.eddType">
                                                            <td class="col-8"> EDD Type </td>
                                                            <td class="col-4" [textContent]="ancCards.eddType"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.ultraSoundEdd">
                                                            <td class="col-8"> UltraSound EDD </td>
                                                            <td class="col-4" [textContent]="ancCards.ultraSoundEdd | date: 'dd/MM/yyyy'"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.lmp && ancCards.lmpStatus != 'Unknown' ">
                                                            <td class="col-8"> Gestational age </td>
                                                            <td class="col-4" [textContent]="ancCards.ga"></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div class="col-6 border-left pr-0 pl-0 border-bottom border-top border-dark">
                                                    <table class="  table-nowrap table-sm  mb-0">
                                                        <tr *ngIf="ancCards.lmp && ancCards.lmpStatus == 'Known'">
                                                            <td class="col-8"> LMP </td>
                                                            <td class="col-4" [textContent]="ancCards.lmp | date: 'dd/MM/yyyy'"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.lmp && ancCards.lmpStatus == 'Unknown' ">
                                                            <td class="col-8"> LMP </td>
                                                            <td class="col-4" [textContent]="ancCards.lmp | date: 'dd/MM/yyyy'"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.edd">
                                                            <td class="col-8"> EDD  </td>
                                                            <td class="col-4" [textContent]="ancCards.edd"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.lmp && ancCards.lmpStatus == 'Unknown' ">
                                                            <td class="col-8"> GA </td>
                                                            <td class="col-4" [textContent]="ancCards.ga"></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf=" ancCards && ancCards !== null && ( ancCards.bookingStatus || ancCards.willSheDeliver || ancCards.providerName || ancCards.specialFeature)">
                                            <h6 class="font-18 mt-0 mb-2 mt-1 pl-3">Booking Status</h6>
                                            <div class="col-12 d-flex">
                                                <div class="col-6 pl-1 pr-0 border-bottom border-top border-dark">
                                                    <table class="  table-nowrap table-sm  mb-0">
                                                        <tr *ngIf="ancCards.bookingStatus">
                                                            <td class="col-8">Booking Status</td>
                                                            <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="ancCards.bookingStatus"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.providerName">
                                                            <td class="col-8"> Referral Doctor </td>
                                                            <td class="col-4 text-uppercase" style=" white-space: normal; word-break: break-all;" [textContent]="ancCards.providerName"></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div class="col-6 border-left pr-0 pl-0 border-bottom border-top border-dark">
                                                    <table class="  table-nowrap table-sm mb-0">
                                                        <tr *ngIf="ancCards.willSheDeliver">
                                                            <td class="col-8"> Will She Deliver </td>
                                                            <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="ancCards.willSheDeliver"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.specialFeature">
                                                            <td class="col-8"> Special Features </td>
                                                            <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="ancCards.specialFeature"></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf=" ancCards && ancCards !== null && ( ancCards.husbandBloodGroup || ancCards.husbandRhType || ancCards.referralDoctor)">
                                            <h6 class="font-18 mt-0 mb-2 mt-1 pl-3">PARTNER DETAILS</h6>
                                            <div class="col-12 d-flex">
                                                <div class="col-6 pl-1  pr-0 border-bottom border-top border-dark">
                                                    <table class="  table-nowrap table-sm  mb-0">
                                                        <tr *ngIf="ancCards.husbandBloodGroup">
                                                            <td class="col-8">Husband Blood Group</td>
                                                            <td class="col-4" [textContent]="ancCards.husbandBloodGroup"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.hiv">
                                                            <td class="col-8"> HIV </td>
                                                            <td class="col-4" [textContent]="ancCards.hiv"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.hcv">
                                                            <td class="col-8"> HCV</td>
                                                            <td class="col-4" [textContent]="ancCards.hcv"></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div class="col-6 border-left pr-0 pl-0 border-bottom border-top border-dark">
                                                    <table class="  table-nowrap table-sm  mb-0">
                                                        <tr *ngIf="ancCards.husbandRhType">
                                                            <td class="col-8">Rh Type </td>
                                                            <td class="col-4" [textContent]="ancCards.husbandRhType"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.hbsAg">
                                                            <td class="col-8"> HbsAg</td>
                                                            <td class="col-4" [textContent]="ancCards.hbsAg"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.vdrl">
                                                            <td class="col-8"> VDRL </td>
                                                            <td class="col-4" [textContent]="ancCards.vdrl"></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf=" ancCards && ancCards !== null && ( ancCards.smoking || ancCards.alcohol || ancCards.otherSubstances)">
                                            <h6 class="font-18 mt-0 mb-2 mt-1 pl-3">RISK BEHAVIOR</h6>
                                            <div class="col-12 d-flex">
                                                <div class="col-6 pl-1 pr-0 border-bottom border-top border-dark">
                                                    <table class="  table-nowrap table-sm  mb-0">
                                                        <tr *ngIf="ancCards.smoking">
                                                            <td class="col-8">Smoking</td>
                                                            <td class="col-4" [textContent]="ancCards.smoking"></td>
                                                        </tr>

                                                        <tr *ngIf="ancCards.otherSubstances">
                                                            <td class="col-8"> Other Substances </td>
                                                            <td class="col-4" [textContent]="ancCards.otherSubstances"></td>
                                                        </tr>

                                                    </table>
                                                </div>
                                                <div class="col-6 border-left pr-0 pl-0 border-bottom border-top border-dark">
                                                    <table class=" table-nowrap table-sm  mb-0">
                                                        <tr *ngIf="ancCards.alcohol">
                                                            <td class="col-8">Alcohol</td>
                                                            <td class="col-4" [textContent]="ancCards.alcohol"></td>
                                                        </tr>
                                                        <tr *ngIf="ancCards.otherSubstancesComment">
                                                            <td class="col-8"> If Yes </td>
                                                            <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="ancCards.otherSubstancesComment"></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf=" ancCards && ancCards !== null && ( ancCards.cordBloodBanking || ancCards.prenatalScreeningOffered || ancCards.diet || ancCards.lifeStyle || ancCards.toiletPreference || ancCards.financialCouncelling || ancCards.timeTakenToReachHospital || ancCards.distanceInKilometers || ancCards. prePregWeight)">
                                            <div class="col-12">
                                                <h6 class="font-18 mt-0 mb-2 mt-1 pl-2">OTHER DETAILS</h6>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0 pl-1">
                                                    <tr *ngIf="ancCards.cordBloodBanking">
                                                        <td class="col-3">Cord Blood Banking</td>
                                                        <td class="col-4" [textContent]="ancCards.cordBloodBanking"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.prenatalScreeningOffered">
                                                        <td class="col-3">Prenatal Screening Offered</td>
                                                        <td class="col-4" [textContent]="ancCards.prenatalScreeningOffered"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.diet">
                                                        <td class="col-3"> Diet</td>
                                                        <td class="col-4" [textContent]="ancCards.diet"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.lifeStyle">
                                                        <td class="col-3"> LifeStyle</td>
                                                        <td class="col-4" [textContent]="ancCards.lifeStyle"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.toiletPreference">
                                                        <td class="col-3"> Toilet Preference</td>
                                                        <td class="col-4" [textContent]="ancCards.toiletPreference"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.financialCouncelling">
                                                        <td class="col-3"> Financial Councelling</td>
                                                        <td class="col-4" [textContent]="ancCards.financialCouncelling"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.timeTakenToReachHospital">
                                                        <td class="col-3">Time Taken To Reach Hospital</td>
                                                        <td class="col-4" [textContent]="ancCards.timeTakenToReachHospital"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.distanceInKilometers">
                                                        <td class="col-3">Distance In Kilometers</td>
                                                        <td class="col-4" [textContent]="ancCards.distanceInKilometers"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.prePregWeight">
                                                        <td class="col-3">Pre Preg Weight</td>
                                                        <td class="col-4" [textContent]="ancCards.prePregWeight"></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>



                                    <div *ngIf="ancCards && ancCards !== null && (ancCards.covidVaccine || ancCards.vaccineName || ancCards.firstDose || ancCards.date1|| ancCards.secondDose|| ancCards.date2 || ancCards.booster || ancCards.date3 ||
                                         ancCards.fetalLungMaturity || ancCards.day1 || ancCards.day2 || ancCards.fluVaccine || ancCards.date4 || ancCards.tetanus1stDose || ancCards.date5 || ancCards.tetanus2ndDose  || ancCards.date6   || ancCards.boostrix||
                                         ancCards.anticoagulant || ancCards.medication || ancCards.dosage || ancCards.frequency ||ancCards.date7 || ancCards.indication1 || ancCards.dose1  || ancCards.date8 || ancCards.indication2 || ancCards.dose2  || ancCards.neonatalCounseling)">

                                        <h4 style="font-size:19px;">Covid vaccine/Steroids/Anti D/ TT/Anticoagulant</h4>

                                        <div class="row" *ngIf=" ancCards && ancCards !== null && ( ancCards.covidVaccine || ancCards.vaccineName || ancCards.firstDose || ancCards.date1|| ancCards.secondDose|| ancCards.date2 || ancCards.booster || ancCards.date3 )">

                                            <div class="col-12">
                                                <h6 class="font-18 mt-0 mb-2">COVID</h6>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr *ngIf="ancCards.covidVaccine">
                                                        <td class="col-8">Covid Vaccine</td>
                                                        <td class="col-4" [textContent]="ancCards.covidVaccine"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.vaccineName ">
                                                        <td class="col-8"> Vaccine Name </td>
                                                        <td class="col-4" [textContent]="ancCards.vaccineName "></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.firstDose ">
                                                        <td class="col-8">1st Dose </td>
                                                        <td class="col-4" [textContent]="ancCards.firstDose"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.date1">
                                                        <td class="col-8">Date</td>
                                                        <td class="col-4" [textContent]="ancCards.date1 | date:'dd/MM/yyyy'"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.secondDose">
                                                        <td class="col-8">2nd Dose</td>
                                                        <td class="col-4" [textContent]="ancCards.secondDose"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.date2">
                                                        <td class="col-8">Date</td>
                                                        <td class="col-4" [textContent]="ancCards.date2 | date:'dd/MM/yyyy'"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.booster">
                                                        <td class="col-8">Booster</td>
                                                        <td class="col-4" [textContent]="ancCards.booster"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.date3">
                                                        <td class="col-8"> Date</td>
                                                        <td class="col-4" [textContent]="ancCards.date3 | date:'dd/MM/yyyy'"></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf=" ancCards && ancCards !== null && ( ancCards.fetalLungMaturity || ancCards.day1 || ancCards.day2  )">
                                            <div class="col-12">
                                                <h6 class="font-18 mt-0 mb-2">STEROIDS ADMINISTRATION FOR FETAL LUNG MATURITY</h6>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr *ngIf="ancCards.fetalLungMaturity">
                                                        <td class="col-8">Fetal Lung Maturity</td>
                                                        <td class="col-4" [textContent]="ancCards.fetalLungMaturity"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.day1 ">
                                                        <td class="col-8">Day1</td>
                                                        <td class="col-4" [textContent]="ancCards.day1 | date:'dd/MM/yyyy' "></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.day2">
                                                        <td class="col-8">Day2</td>
                                                        <td class="col-4" [textContent]="ancCards.day2 | date:'dd/MM/yyyy'"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.givenBy">
                                                        <td class="col-8">Given By</td>
                                                        <td class="col-4" [textContent]="ancCards.givenBy"></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf=" ancCards && ancCards !== null && ( ancCards.fluVaccine || ancCards.date4   )">
                                            <div class="col-12">
                                                <h6 class="font-18 mt-0 mb-2">FLU VACCINE</h6>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr *ngIf="ancCards.fluVaccine ">
                                                        <td class="col-8">Flu Vaccine</td>
                                                        <td class="col-4" [textContent]="ancCards.fluVaccine "></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.date4">
                                                        <td class="col-8">Date</td>
                                                        <td class="col-4" [textContent]="ancCards.date4 | date:'dd/MM/yyyy'"></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf=" ancCards && ancCards !== null && ( ancCards.tetanus1stDose || ancCards.date5 || ancCards.tetanus2ndDose  || ancCards.date6   || ancCards.boostrix)">
                                            <div class="col-12">
                                                <h6 class="font-18 mt-0 mb-2">TETANUS</h6>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr *ngIf="ancCards.tetanus1stDose ">
                                                        <td class="col-8">Tetanus 1st Dose</td>
                                                        <td class="col-4" [textContent]="ancCards.tetanus1stDose"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.date5">
                                                        <td class="col-8">Date</td>
                                                        <td class="col-4" [textContent]="ancCards.date5 | date:'dd/MM/yyyy'"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.tetanus2ndDose">
                                                        <td class="col-8">Tetanus 2nd Dose</td>
                                                        <td class="col-4" [textContent]="ancCards.tetanus2ndDose"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.date6">
                                                        <td class="col-8">Date</td>
                                                        <td class="col-4" [textContent]="ancCards.date6 | date:'dd/MM/yyyy'"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.boostrix">
                                                        <td class="col-8">Boostrix</td>
                                                        <td class="col-4" [textContent]="ancCards.boostrix"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.date7">
                                                        <td class="col-8">Date</td>
                                                        <td class="col-4" [textContent]="ancCards.date7 | date:'dd/MM/yyyy'"></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf=" ancCards && ancCards !== null && ( ancCards.anticoagulant || ancCards.medication || ancCards.dosage || ancCards.frequency )">
                                            <div class="col-12">
                                                <h6 class="font-18 mt-0 mb-2">ANTICOAGULANT</h6>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr *ngIf="ancCards.anticoagulant">
                                                        <td class="col-8">Anticoagulant</td>
                                                        <td class="col-4" [textContent]="ancCards.anticoagulant"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.medication">
                                                        <td class="col-8">Medication</td>
                                                        <td class="col-4" [textContent]="ancCards.medication"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.dosage">
                                                        <td class="col-8">Dosage</td>
                                                        <td class="col-4" [textContent]="ancCards.dosage"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.frequency">
                                                        <td class="col-8">Frequency</td>
                                                        <td class="col-4" [textContent]="ancCards.frequency"></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf=" ancCards && ancCards !== null && ( ancCards.date7 || ancCards.indication1 || ancCards.dose1  || ancCards.date8 || ancCards.indication2 || ancCards.dose2  || ancCards.neonatalCounseling)">
                                            <div class="col-12">
                                                <h6 class="font-18 mt-0 mb-2">ANTI D ADMINISTRATION</h6>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr *ngIf="ancCards.date7">
                                                        <td class="col-8">Date</td>
                                                        <td class="col-4" [textContent]="ancCards.date8 | date:'dd/MM/yyyy'"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.indication1 ">
                                                        <td class="col-8">Indication </td>
                                                        <td class="col-4" [textContent]="ancCards.indication1 "></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.dose1">
                                                        <td class="col-8">Dose</td>
                                                        <td class="col-4" [textContent]="ancCards.dose1"></td>
                                                    </tr>
                                                    <tr *ngIf="ancCards.date7 ||  ancCards.indication1 ||  ancCards.dose1 ">
                                                        <td class="col-8">By Dr</td>
                                                        <td class="col-4" [textContent]="ancCards.byDr.toUpperCase()"></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!isNurseDisplay">
                        <div class="dashboard dashboard-table" #measureSection *ngIf="measure && measure !== null &&  measure.pulseRate">
                            <h4 class="dashboard-title font-weight-bold pl-2">ANC Visit</h4>
                            <div class="dashboard-body">
                                <div>
                                    <div *ngIf="measure && measure !== null && ( measure.pulseRate || measure.systolic || measure.diastotic || measure.respiratoryRate || measure.tempFahrenhit|| measure.gestationAge ||
                                         measure.uterusPulpable || measure.fundusHeight || measure.sfHeight || measure.presentation || measure.fhs || measure.amountofliquor || measure.oedema || measure.urineAlbumin  || measure.otherComplaints || measure.highRisk || measure.lastUSGDate || measure.typeOfUltraSoundScan || measure.usgReport || measure.consultedByDr || measure.otherConsultedDr || measure.nextReviewDate ||
                                         measure.externalGenitalia || measure.psVagina || measure.psCervix || measure.psDischarge || measure.cervix || measure.presentingPart || measure.stationPP || measure.liquor|| measure.pelvis|| measure.other || measure.externalGenitaliaComment|| measure.cervicalOSCm|| measure.membranes )">
                                        <div class="row" *ngIf="measure && measure !== null && (measure.visitNumber || measure.department || measure.heightInFeet || measure.heightInMeters || measure.weight || measure.bodyMassIndex || measure.bmiCategorization || measure.pulseRate || measure.systolic || measure.diastotic || measure.respiratoryRate || measure.tempFahrenhit|| measure.gestationAge)">
                                            <h6 class="font-18 mt-0 mb-2 pl-3">Measures</h6>
                                            <div class="col-12 d-flex">
                                                <div class="col-6 pl-1  pr-0 border-bottom border-top border-dark">
                                                    <table class="  table-nowrap table-sm  mb-0">
                                                        <tr *ngIf="measure.visitNumber">
                                                            <td class="col-8">Visit Number</td>
                                                            <td class="col-4" [textContent]="measure.visitNumber"> </td>
                                                        </tr>
                                                        <tr *ngIf="measure.heightInFeet">
                                                            <td class="col-8">Height In Feet</td>
                                                            <td class="col-4" [textContent]="measure.heightInFeet"> </td>
                                                        </tr>
                                                        <tr *ngIf="measure.weight">
                                                            <td class="col-8">Weight (Kg)</td>
                                                            <td class="col-4" [textContent]="measure.weight"> </td>
                                                        </tr>
                                                        <tr *ngIf="measure.bmiCategorization">
                                                            <td class="col-8">BMI Categorization</td>
                                                            <td class="col-4" [textContent]="measure.bmiCategorization"> </td>
                                                        </tr>
                                                        <tr *ngIf="measure.systolic">
                                                            <td class="col-8">Systolic BP (Mm Hg)</td>
                                                            <td class="col-4" [textContent]="measure.systolic"> </td>
                                                        </tr>

                                                        <tr *ngIf="measure.respiratoryRate">
                                                            <td class="col-8">Respiratory Rate</td>
                                                            <td class="col-4" [textContent]="measure.respiratoryRate"> </td>
                                                        </tr>
                                                        <tr *ngIf="measure.gestationAge">
                                                            <td class="col-8">Gestation Age (Wks/Days)</td>
                                                            <td class="col-4" [textContent]="measure.gestationAge"> </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div class="col-6 border-left pr-0 pl-0 border-bottom border-top border-dark">
                                                    <table class="  table-nowrap table-sm  mb-0">

                                                        <tr *ngIf="measure.department">
                                                            <td class="col-8">Department</td>
                                                            <td class="col-4" [textContent]="measure.department"> </td>
                                                        </tr>
                                                        <tr *ngIf="measure.heightInMeters">
                                                            <td class="col-8">Height In Meters</td>
                                                            <td class="col-4" [textContent]="measure.heightInMeters"> </td>
                                                        </tr>
                                                        <tr *ngIf="measure.bodyMassIndex">
                                                            <td class="col-8">Body Mass Index (BMI)</td>
                                                            <td class="col-4" [textContent]="measure.bodyMassIndex"> </td>
                                                        </tr>
                                                        <tr *ngIf="measure.pulseRate">
                                                            <td class="col-8">Pulse Rate (Per Minute)</td>
                                                            <td class="col-4" [textContent]="measure.pulseRate"> </td>
                                                        </tr>

                                                        <tr *ngIf="measure.diastotic">
                                                            <td class="col-8">Diastolic BP (Mm Hg)</td>
                                                            <td class="col-4" [textContent]="measure.diastotic"> </td>
                                                        </tr>
                                                        <tr *ngIf="measure.tempFahrenhit">
                                                            <td class="col-8">Temp Fahrenheit</td>
                                                            <td class="col-4" [textContent]="measure.tempFahrenhit"> </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="measure && measure !== null && (measure.uterusPulpable || measure.fundusHeight || measure.sfHeight || measure.presentation || measure.fhs || measure.amountofliquor || measure.oedema || measure.urineAlbumin)">
                                            <div class="col-12">
                                                <h6 class="font-18 mt-0 mb-2 mt-1 pl-2">Obstetric Examination</h6>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0 pl-1">
                                                    <tr *ngIf="measure.uterusPulpable">
                                                        <td class="col-8">Uterus Palpable</td>
                                                        <td class="col-4" [textContent]="measure.uterusPulpable"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.fundusHeight">
                                                        <td class="col-8">Fundus Height</td>
                                                        <td class="col-4" [textContent]="measure.fundusHeight"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.sfHeight">
                                                        <td class="col-8">SF Height (Cms)</td>
                                                        <td class="col-4" [textContent]="measure.sfHeight"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.presentation">
                                                        <td class="col-8">Presentation</td>
                                                        <td class="col-4" [textContent]="measure.presentation"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.fhs">
                                                        <td class="col-8">FHS (Per Minute)</td>
                                                        <td class="col-4" [textContent]="measure.fhs"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.amountofliquor">
                                                        <td class="col-8">Amount Of Liquor</td>
                                                        <td class="col-4" [textContent]="measure.amountofliquor"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.oedema">
                                                        <td class="col-8">Oedema</td>
                                                        <td class="col-4" [textContent]="measure.oedema"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.urineAlbumin">
                                                        <td class="col-8">Urine Albumin</td>
                                                        <td class="col-4" [textContent]="measure.urineAlbumin"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <!--<div class="row" *ngIf="measure && measure !== null && (measure.complains && measure.complains.length > 0 || measure.otherComplaints || measure.highRisk )">
                                            <div class="col-12">
                                                <div class="col-12">
                                                    <h6 class="font-14 mt-0 mb-1">Complaints</h6>
                                                </div>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr *ngIf="measure.complains && measure.complains.length > 0">
                                                        <td class="col-8">Complaints</td>
                                                        <td class="col-4" style=" white-space: normal; word-break: break-all;">
                                                            <ul class="dashboard-ul row">
                                                                <li *ngFor="let item of measure.complains" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="measure.otherComplaints">
                                                        <td class="col-8">Other Complaints</td>
                                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="measure.otherComplaints"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.highRisk">
                                                        <td class="col-8">High Risk</td>
                                                        <td class="col-4" [textContent]="measure.highRisk"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>-->

                                        <div class="row" *ngIf="measure && measure !== null && (measure.complains && measure.complains.length > 0 || measure.otherComplaints || measure.highRisk )">
                                            <div class="col-12">
                                                <div class="col-12" *ngIf="measure.complains && measure.complains.length > 0">
                                                    <h6 class="font-18 mt-0 mb-1">Complaints</h6>
                                                    <ul class=" row">
                                                        <li *ngFor="let item of measure.complains" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                    </ul>
                                                </div>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0 pl-1">
                                                    <tr *ngIf="measure.otherComplaints">
                                                        <td class="col-8">Other Complaints</td>
                                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="measure.otherComplaints"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.highRisk">
                                                        <td class="col-8">High Risk</td>
                                                        <td class="col-4" [textContent]="measure.highRisk"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>


                                        <div class="row" *ngIf="measure && measure !== null && (measure.lastUSGDate || measure.typeOfUltraSoundScan || measure.usgReport || measure.consultedByDr || measure.otherConsultedDr || measure.nextReviewDate )">
                                            <div class="col-12 border-bottom border-top border-dark">
                                                <h6 class="font-18 mt-0 mb-2 p-2">Ultrasound Examination/Other Details</h6>
                                                <table class="  table-nowrap table-sm  mb-0 pl-1">
                                                    <tr *ngIf="measure.lastUSGDate">
                                                        <td class="col-3">Last USG Date</td>
                                                        <td class="col-4" [textContent]="measure.lastUSGDate | date: 'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.typeOfUltraSoundScan">
                                                        <td class="col-3">Type Of Ultra Sound Scan</td>
                                                        <td class="col-4" [textContent]="measure.typeOfUltraSoundScan"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.usgReport">
                                                        <td class="col-3">USG Report</td>
                                                        <td class="col-4" [textContent]="measure.usgReport"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.otherReport">
                                                        <td class="col-3">Other Report</td>
                                                        <td class="col-4" [textContent]="measure.otherReport"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.consultedByDr">
                                                        <td class="col-3">Consulted By Dr</td>
                                                        <td class="col-4" [textContent]="measure.consultedByDr.toUpperCase()"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.otherConsultedDr">
                                                        <td class="col-3">Other Consulted By Dr</td>
                                                        <td class="col-4" [textContent]="measure.otherConsultedDr.toUpperCase()"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.nextReviewDate">
                                                        <td class="col-3">Next Review Date</td>
                                                        <td class="col-4" [textContent]="measure.nextReviewDate | date: 'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="measure && measure !== null && (measure.externalGenitalia || measure.psVagina || measure.psCervix || measure.psDischarge || measure.cervix || measure.presentingPart || measure.stationPP || measure.liquor|| measure.pelvis|| measure.other || measure.externalGenitaliaComment|| measure.cervicalOSCm|| measure.membranes )">
                                            <div class="col-12">
                                                <h6 class="font-18 mt-0 mb-2 mt-1 pl-2">Vaginal Examination</h6>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0 pl-1">
                                                    <tr *ngIf="measure.externalGenitalia">
                                                        <td class="col-8">External Genitalia</td>
                                                        <td class="col-4" [textContent]="measure.externalGenitalia"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.externalGenitaliaComment">
                                                        <td class="col-8"> Reason</td>
                                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="measure.externalGenitaliaComment"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.psVagina">
                                                        <td class="col-8">PS Vagina</td>
                                                        <td class="col-4" [textContent]="measure.psVagina"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.psVaginaComment">
                                                        <td class="col-8">Reason</td>
                                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="measure.psVaginaComment"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.psCervix">
                                                        <td class="col-8">PS Cervix</td>
                                                        <td class="col-4" [textContent]="measure.psCervix"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.cervicalOSCm">
                                                        <td class="col-8">Cervical OS Cm</td>
                                                        <td class="col-4" [textContent]="measure.cervicalOSCm"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.membranes">
                                                        <td class="col-8">Membranes</td>
                                                        <td class="col-4" [textContent]="measure.membranes"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.psCervixComment">
                                                        <td class="col-8">Reason</td>
                                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="measure.psCervixComment"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.psDischarge">
                                                        <td class="col-8">PS Discharge</td>
                                                        <td class="col-4" [textContent]="measure.psDischarge"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.cervix">
                                                        <td class="col-8">Cervix</td>
                                                        <td class="col-4" [textContent]="measure.cervix"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.presentingPart">
                                                        <td class="col-8">Presenting Part</td>
                                                        <td class="col-4" [textContent]="measure.presentingPart"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.stationPP">
                                                        <td class="col-8">Station Of PP</td>
                                                        <td class="col-4" [textContent]="measure.stationPP"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.liquor">
                                                        <td class="col-8">Liquor</td>
                                                        <td class="col-4" [textContent]="measure.liquor"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.pelvis">
                                                        <td class="col-8">Pelvis</td>
                                                        <td class="col-4" [textContent]="measure.pelvis"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.other">
                                                        <td class="col-8">Others</td>
                                                        <td class="col-4" [textContent]="measure.other"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="measure && measure !== null && (measure.conscious || measure.shock || measure.pallor || measure.icterus || measure.spleen || measure.liver || measure.reflex || measure.cardiioVascular|| measure.respiratorySystem|| measure.cns|| measure.examinationSummary || measure.breastExamination)">
                                        <h5 class="font-18 pl-2">General Examination</h5>
                                        <div class="row" *ngIf="measure && measure !== null && (measure.conscious || measure.shock || measure.pallor || measure.icterus || measure.spleen || measure.liver || measure.reflex || measure.cardiioVascular|| measure.respiratorySystem|| measure.cns|| measure.examinationSummary)">
                                            <div class="col-12">
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0 pl-1">
                                                    <tr *ngIf="measure.conscious">
                                                        <td class="col-8">Conscious</td>
                                                        <td class="col-4" [textContent]="measure.conscious"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.shock">
                                                        <td class="col-8">Shock</td>
                                                        <td class="col-4" [textContent]="measure.shock"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.pallor">
                                                        <td class="col-8">Pallor</td>
                                                        <td class="col-4" [textContent]="measure.pallor"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.icterus">
                                                        <td class="col-8">Icterus</td>
                                                        <td class="col-4" [textContent]="measure.icterus"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.spleen">
                                                        <td class="col-8">Spleen</td>
                                                        <td class="col-4" [textContent]="measure.spleen"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.liver">
                                                        <td class="col-8">Liver</td>
                                                        <td class="col-4" [textContent]="measure.liver"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.reflex">
                                                        <td class="col-8">Reflex</td>
                                                        <td class="col-4" [textContent]="measure.reflex"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.cardiioVascular">
                                                        <td class="col-8">Cardio Vascular</td>
                                                        <td class="col-4" [textContent]="measure.cardiioVascular"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.cardiioVascularComment">
                                                        <td class="col-8">Cardio Vascular Reason</td>
                                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="measure.cardiioVascularComment"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.respiratorySystem">
                                                        <td class="col-8">Respiratory System</td>
                                                        <td class="col-4" [textContent]="measure.respiratorySystem"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.respiratorySystemComment">
                                                        <td class="col-8">Respiratory System Reason</td>
                                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="measure.respiratorySystemComment"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.cns">
                                                        <td class="col-8">CNS</td>
                                                        <td class="col-4" [textContent]="measure.cns"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.cnsComment">
                                                        <td class="col-8">CNS Reason</td>
                                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="measure.cnsComment"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.examinationSummary">
                                                        <td class="col-8">Examination Summary</td>
                                                        <td class="col-4" [textContent]="measure.examinationSummary"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.examinationSummaryComment">
                                                        <td class="col-8">Examination Summary Reason</td>
                                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="measure.examinationSummaryComment"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="measure && measure !== null && measure.breastExamination ">
                                            <div class="col-12">
                                                <h6 class="font-18 mt-0 mb-2 pl-2">Breast Examination</h6>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0 pl-1">
                                                    <tr *ngIf="measure.breastExamination">
                                                        <td class="col-8">Breast Examination</td>
                                                        <td class="col-4" [textContent]="measure.breastExamination"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.breastExaminationComment">
                                                        <td class="col-8">Reason</td>
                                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="measure.breastExaminationComment"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="measure && measure !== null && (measure.discussedBirth || measure.offeredSpinning || measure.ecvOffered || measure.ecvDoneBy || measure.modeOfBirth || measure.vaginalBreech || measure.optionOfVaginal ||measure.vbacCounseling || measure.vbacCounselingComment || measure.ifYes || measure.vbac )">
                                        <h5 class="font-18 pl-2">Breech_VBAC Clinic</h5>
                                        <div class="row" *ngIf="measure && measure !== null && (measure.discussedBirth || measure.offeredSpinning || measure.ecvOffered || measure.ecvDoneBy || measure.modeOfBirth || measure.vaginalBreech || measure.optionOfVaginal)">
                                            <div class="col-12">
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0 pl-1">
                                                    <tr *ngIf="measure.discussedBirth">
                                                        <td class="col-8">Discussed Birth Options With</td>
                                                        <td class="col-4" [textContent]="measure.discussedBirth"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.offeredSpinning">
                                                        <td class="col-8">Offered Spinning Babies</td>
                                                        <td class="col-4" [textContent]="measure.offeredSpinning"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.ifYesofferedSpinning">
                                                        <td class="col-8">If Yes</td>
                                                        <td class="col-4" [textContent]="measure.ifYesofferedSpinning"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.offeredSpinningComment">
                                                        <td class="col-8">Offered Spinning Reason</td>
                                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="measure.offeredSpinningComment"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.ecvOffered">
                                                        <td class="col-8">ECV Offered</td>
                                                        <td class="col-4" [textContent]="measure.ecvOffered"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.ifYesecvOffered">
                                                        <td class="col-8">If Yes</td>
                                                        <td class="col-4" [textContent]="measure.ifYesecvOffered"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.ifAcceptedEcv">
                                                        <td class="col-8">If Yes</td>
                                                        <td class="col-4" [textContent]="measure.ifAcceptedEcv"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.ecvOfferedComment">
                                                        <td class="col-8">ECV Offered Reason</td>
                                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="measure.ecvOfferedComment"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.ecvDoneBy">
                                                        <td class="col-8">ECV Done By</td>
                                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="measure.ecvDoneBy"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.modeOfBirth">
                                                        <td class="col-8">Mode Of Birth</td>
                                                        <td class="col-4" [textContent]="measure.modeOfBirth"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.vaginalBreech">
                                                        <td class="col-8">Vaginal Breech Conducted By</td>
                                                        <td class="col-4" [textContent]="measure.vaginalBreech"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.optionOfVaginal">
                                                        <td class="col-8">Option Of Vaginal Breech Birth</td>
                                                        <td class="col-4" [textContent]="measure.optionOfVaginal"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="measure && measure !== null && (measure.vbacCounseling || measure.vbacCounselingComment || measure.ifYes || measure.vbac  ) ">
                                            <div class="col-12">
                                                <h6 class="font-18 mt-0 mb-2 pl-2">Vaginal Birth After Caesarean Section (VBAC)</h6>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0 pl-1">
                                                    <tr *ngIf="measure.vbacCounseling">
                                                        <td class="col-8">VBAC Counselling</td>
                                                        <td class="col-4" [textContent]="measure.vbacCounseling"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.ifYes">
                                                        <td class="col-8">If Done</td>
                                                        <td class="col-4" [textContent]="measure.ifYes"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.ifNotAcceptedReason">
                                                        <td class="col-8"> If Not AcceptedReason</td>
                                                        <td class="col-4" [textContent]="measure.ifNotAcceptedReason"></td>
                                                    </tr>
                                                    <tr *ngIf="measure.vbacCounselingComment">
                                                        <td class="col-8">VBAC Counselling Reason</td>
                                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="measure.vbacCounselingComment"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.vbac">
                                                        <td class="col-8">If Accepted</td>
                                                        <td class="col-4" [textContent]="measure.vbac"> </td>
                                                    </tr>
                                                    <tr *ngIf="measure.ifElectiveCsReason">
                                                        <td class="col-8">If Elective CS Reason</td>
                                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="measure.ifElectiveCsReason"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isNurseDisplay">
                        <div class="dashboard dashboard-table" #birthHistorySection *ngIf="birthHistory && birthHistory.length">
                            <h4 class="dashboard-title">OB Birth History</h4>

                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <table class="table table-centered table-sm table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th>Pregnancy No</th>
                                                <th>Birth Year</th>
                                                <th>Pregnancy Status </th>
                                                <th>Mode Of Delivery</th>
                                                <th>Indication</th>
                                                <th>Weight</th>
                                                <th>Sex Of Baby</th>
                                                <th>Baby's Status</th>
                                                <th>Delivery At</th>
                                                <th>Other</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of birthHistory;">
                                                <td [textContent]="item.pregnancyNo"></td>
                                                <td [textContent]="item.yearOfBirth"></td>
                                                <td [textContent]="item.pregnancyStatus"></td>
                                                <td [textContent]="item.modeOfDelivery"></td>
                                                <td [textContent]="item.indication"></td>
                                                <td [textContent]="item.birthWeight"></td>
                                                <td [textContent]="item.sexOfBaby"></td>
                                                <td [textContent]="item.statusOfBaby"></td>
                                                <td [textContent]="item.deliveryAt"></td>
                                                <td [textContent]="item.other"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isNurseDisplay">
                        <div class="dashboard dashboard-table" #problemListSection *ngIf="problemList">
                            <h4 class="dashboard-title font-weight-bold">Past Medical History</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div *ngIf="isPastMedicalHistory">
                                        There is no past medical history
                                    </div>
                                    <table class="table-bordered table-dark bg-white text-black" *ngIf="fullTranscript && fullTranscript.problemList">
                                        <thead>
                                            <tr>
                                                <th>Disease name</th>
                                                <th>Year Of Diagnosis</th>
                                                <th>ICD</th>
                                                <th>Status</th>
                                                <th style="width: 250px">Treatment history</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of  problemList">
                                                <td>
                                                    <div class="media">
                                                        <div class="media-body">
                                                            <h5 class="mb-0 mt-0 font-weight-normal text-truncate-line" [textContent]="item.usable"></h5>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p *ngIf="item.date" [textContent]="item.date"></p>
                                                    <p style="padding-left: 25px" *ngIf="!item.date" [textContent]="'-'"></p>
                                                </td>
                                                <td [textContent]="item.code"></td>
                                                <td [textContent]="item.status"></td>
                                                <td>
                                                    <p class="mb-0 text-truncate-line" [textContent]="item.comments || '-'"></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div *ngIf="!isNurseDisplay">
                        <div class="dashboard dashboard-table" #familyHistorySection *ngIf="familyHistory">
                            <h4 class="dashboard-title font-weight-bold">Family History</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div *ngIf="isFamilyHistory">
                                        There is no Family History
                                    </div>
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="familyHistory &&  familyHistory.length >0">
                                        <thead>
                                            <tr>
                                                <th>Relation</th>
                                                <th>Age/DOB</th>
                                                <th>Problem</th>
                                                <th>Status</th>
                                                <th>Comments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of familyHistory;">
                                                <td style="align-content:center">
                                                    <div class="media">
                                                        <div class="media-body">
                                                            <h5 class="mb-0 mt-0 font-weight-normal text-truncate-line" [textContent]=" item.relation"></h5>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style="align-content:center">
                                                    <p *ngIf="item.age" [textContent]="item.age  "></p>
                                                    <p style="padding-left: 25px" *ngIf="!item.age" [textContent]="'-'"></p>
                                                </td>
                                                <td style="align-content:center">
                                                    <p *ngIf="item.problem" [textContent]="item.problem "></p>
                                                    <p style="padding-left: 25px" *ngIf="!item.problem" [textContent]="'-'"></p>
                                                </td>
                                                <td style="align-content:center">
                                                    <p *ngIf="item.status" [textContent]="item.status "></p>
                                                    <p style="padding-left: 25px" *ngIf="!item.status" [textContent]="'-'"></p>
                                                </td>
                                                <td style="align-content:center">
                                                    <p *ngIf="item.comments" [textContent]="item.comments "></p>
                                                    <p style="padding-left: 25px" *ngIf="!item.comments" [textContent]="'-'"></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div *ngIf="familyHistory && familyHistory.length>0 && familyHistory[0].mainComment">
                                        <strong>Comments</strong>
                                        <div><span [textContent]="familyHistory[0].mainComment "></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!isNurseDisplay">
                        <div class="dashboard dashboard-table" #surgerySection *ngIf="procedureSurgery">
                            <h4 class="dashboard-title font-weight-bold">Past surgical history</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div *ngIf="isProcedureSurgery">
                                        There is no Surgical History
                                    </div>
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="procedureSurgery && procedureSurgery.length >0">
                                        <thead>
                                            <tr>
                                                <th class="col-4">Year Of Surgery</th>
                                                <th class="col-4">Surgeries</th>
                                                <th class="col-4">Comments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of procedureSurgery;">
                                                <td style="align-content:center">
                                                    <div class="media">
                                                        <div class="media-body">
                                                            <h5 class="mb-0 mt-0 font-weight-normal text-truncate-line" [textContent]=" item.date"></h5>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style="align-content:center">
                                                    <p *ngIf="item.surgeries" [textContent]="item.surgeries "></p>
                                                    <p style="padding-left: 25px" *ngIf="!item.surgeries" [textContent]="'-'"></p>
                                                </td>
                                                <td style="align-content:center">
                                                    <p *ngIf="item.comments" [textContent]="item.comments "></p>
                                                    <p style="padding-left: 25px" *ngIf="!item.comments" [textContent]="'-'"></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="dashboard dashboard-table" #allergiesSection *ngIf="allergies && allergies !== null">
                            <h4 class="dashboard-title font-weight-bold">Allergies</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div *ngIf="allergies && allergies !== null">
                                        <div>
                                            <div *ngIf="allergies && allergies.noHistory && allergies.noHistory!=null ">
                                                There is no allergy
                                            </div>

                                            <div class="dashboard-break" *ngIf="!allergies.noHistory && (allergies.drugNames!=null || allergies.drugNames!=null || allergies.drugReactions!=null
                                                 || allergies.dustNames!=null || allergies.dustReactions!=null || allergies.foodNames!=null || allergies.foodReactions!=null || allergies.otherNames!=null || allergies.otherReactions!=null)">
                                                <div class="table-responsive horizontal-scroll">
                                                    <table class="table table-sm mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th width="30%">Allergy Type</th>
                                                                <th width="30%">Allergy</th>
                                                                <th width="40%">Reaction </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngIf="allergies.drugNames">
                                                                <td class="col-4">Drug Allergy</td>
                                                                <td class="col-4" [textContent]="allergies.drugNames"></td>
                                                                <td class="col-4" [textContent]="allergies.drugReactions"></td>
                                                            </tr>
                                                            <tr *ngIf="allergies.foodNames">
                                                                <td class="col-4">Food Allergy</td>
                                                                <td class="col-4" [textContent]="allergies.foodNames"></td>
                                                                <td class="col-4" [textContent]="allergies.foodReactions"></td>
                                                            </tr>
                                                            <tr *ngIf="allergies.dustNames">
                                                                <td class="col-4">Dust Allergy</td>
                                                                <td class="col-4" [textContent]="allergies.dustNames"></td>
                                                                <td class="col-4" [textContent]="allergies.dustReactions"></td>
                                                            </tr>
                                                            <tr *ngIf="allergies.otherNames">
                                                                <td class="col-4">Others</td>
                                                                <td class="col-4" [textContent]="allergies.otherNames"></td>
                                                                <td class="col-4" [textContent]="allergies.otherReactions"></td>
                                                            </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="dashboard" #antinantalRiskSection *ngIf="antinantalRisk && antinantalRisk !== null ">
                            <h4 class="dashboard-title font-weight-bold">Risk Factor</h4>
                            <div class="dashboard-body">
                                <div *ngIf="antinantalRisk && antinantalRisk.noRisk">
                                    There is No Risk
                                </div>
                                <table border="collapse" class="border dashboard-break table table-sm" *ngIf="antinantalRisk && !antinantalRisk.noRisk">
                                    <tr *ngIf="antinantalRisk.characteristics && antinantalRisk.characteristics.length >0" style="border-bottom:1px solid black">
                                        <th colspan="2" class="font-weight-bold">Antenatal Risk</th>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.characteristics && antinantalRisk.characteristics.length >0">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Material characteristics</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.characteristics; let i=index;" class="">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i != antinantalRisk.characteristics.length-1) && (item && item !='')">,</span>
                                            </span>
                                        </td>
                                    </tr>

                                    <tr *ngIf="antinantalRisk.covidInfection && antinantalRisk.covidInfection != null" style="border-bottom:1px solid black">
                                        <td>
                                            <div class="font-18 font-weight-bold mt-0 mb-1">Covid-19 infection </div>
                                        </td>
                                        <td>
                                            <div>
                                                <span style=" white-space: normal; word-break: break-all;" [textContent]="antinantalRisk.covidInfection"></span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.details && antinantalRisk.details.length >0">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Previous Pregnancy Details</span>
                                        </td>

                                        <td>
                                            <span *ngFor="let item of antinantalRisk.details; let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i != antinantalRisk.details.length-1) && (item && item !='')">,</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.maternalmedical && antinantalRisk.maternalmedical.length > 0">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Maternal Medical History</span>
                                        </td>

                                        <td>
                                            <span *ngFor="let item of antinantalRisk.maternalmedical; let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i != antinantalRisk.maternalmedical.length-1 ) && (item && item !='')">,</span>

                                            </span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.uterineRiskFactor && antinantalRisk.uterineRiskFactor.length > 0">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Uterine Risk Factor</span>
                                        </td>

                                        <td>
                                            <span *ngFor="let item of antinantalRisk.uterineRiskFactor; let i=index;" [textContent]="item">
                                                <span *ngIf="item && item !=''" [textContent]="item">
                                                    <span *ngIf="(i != antinantalRisk.uterineRiskFactor.length-1) && (item && item !='')">,</span>
                                                </span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.pregnancyComplications && antinantalRisk.pregnancyComplications.length > 0" style="border-bottom:1px solid black">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Current Pregnancy Complications</span>
                                        </td>

                                        <td>
                                            <span *ngFor="let item of antinantalRisk.pregnancyComplications; let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i != antinantalRisk.pregnancyComplications.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.fetusComplications && antinantalRisk.fetusComplications.length > 0 || antinantalRisk.otherComplaints !==null">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Fetus Complications</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.fetusComplications; let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i != antinantalRisk.fetusComplications.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk && antinantalRisk.otherComplaints !==null" style="border-bottom:1px solid black">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Other</span>
                                        </td>

                                        <td>
                                            <span style=" white-space: normal; word-break: break-all;" [textContent]="antinantalRisk.otherComplaints"></span>
                                        </td>
                                    </tr>
                                    <tr *ngIf=" antinantalRisk.meternall.length > 0|| antinantalRisk.latePragnancy.length > 0 || antinantalRisk.currentPragnancy.length > 0 || antinantalRisk.funtalFetaus.length > 0 || antinantalRisk.maternalMedicalHistory.length > 0 || antinantalRisk.previousPregency.length > 0" style="border-bottom:1px solid black">
                                        <th colspan="2" class="font-weight-bold">SGA Screening</th>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.meternall && antinantalRisk.meternall.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Maternal Risk Factor</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.meternall; let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i != antinantalRisk.meternall.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.previousPregency && antinantalRisk.previousPregency.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Previous Pregnancy History</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.previousPregency; let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i != antinantalRisk.previousPregency.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.maternalMedicalHistory && antinantalRisk.maternalMedicalHistory.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Maternal Medical History</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.maternalMedicalHistory; let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i != antinantalRisk.maternalMedicalHistory.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>

                                    <tr *ngIf="antinantalRisk.funtalFetaus && antinantalRisk.funtalFetaus.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Unsuitable for monitoring Fundal Fetaus</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.funtalFetaus; let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i != antinantalRisk.funtalFetaus.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.currentPragnancy && antinantalRisk.currentPragnancy.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Current Pragnancy Complecations - Early Pragnancy</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.currentPragnancy; let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i != antinantalRisk.currentPragnancy.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.latePragnancy && antinantalRisk.latePragnancy.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Late Pragnancy</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.latePragnancy; let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i != antinantalRisk.latePragnancy.length-1 ) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.highRisk.length > 0||antinantalRisk.intermediateRisk.length > 0|| antinantalRisk.lowRisk.length > 0 || antinantalRisk.comments !=null " style="border-bottom:1px solid black">
                                        <th colspan="2" class="font-weight-bold">VTE Risk</th>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.highRisk && antinantalRisk.highRisk.length > 0 ">
                                        <td>
                                            <span class="font-14 font-weight-bold mt-0 mb-1">High Risk</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.highRisk; let i=index;">
                                                <span *ngIf="item && item !='' " [textContent]="item"></span>
                                                <span *ngIf="(i != antinantalRisk.highRisk.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.intermediateRisk && antinantalRisk.intermediateRisk.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">IntermediateRisk Risk</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.intermediateRisk; let i=index;">
                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i != antinantalRisk.intermediateRisk.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.lowRisk && antinantalRisk.lowRisk.length > 0 ">
                                        <td>
                                            <span class="font-18 font-weight-bold mt-0 mb-1">Low Risk</span>
                                        </td>
                                        <td>
                                            <span *ngFor="let item of antinantalRisk.lowRisk; let i=index;">

                                                <span *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i  !== antinantalRisk.lowRisk.length-1) && (item && item !='')">,</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="antinantalRisk.comments && antinantalRisk.comments  !== null" style="border-bottom:1px solid black">
                                        <td>
                                            <div class="font-18 font-weight-bold mt-0 mb-1">Other </div>
                                        </td>
                                        <td>
                                            <div><span style=" white-space: normal; word-break: break-all;" [textContent]="antinantalRisk.comments"></span> </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="dashboard dashboard-table" #orderPrescriptionSection *ngIf="orderPrescription && orderPrescription !== null">
                            <h4 class="dashboard-title font-weight-bold">Order/Prescription</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <table class="table table-centered table-sm table-striped mb-0">
                                        <tr *ngIf="bookingHeader && bookingHeader.length > 0">
                                            <td>Investigations</td>
                                            <td>
                                                <table class="table-bordered table-dark bg-white text-black transcipt mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Test Name</th>
                                                            <th>Test Code</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                        <tr *ngFor="let item of bookingHeader;let i =index;">
                                                            <td>
                                                                <span class=" text-wrap" [textContent]="item.testName"></span><br />
                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap" [textContent]="item.testCode"></span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr *ngIf="records && records.length !== 0">
                                            <td>
                                                Prescription
                                            </td>
                                            <td *ngFor="let record of records;">
                                                <table class="table-bordered table-dark bg-white text-black transcipt mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Medicine Name</th>
                                                            <th>Generic Name</th>
                                                            <th>Dosage</th>
                                                            <th *ngIf="isMedicationInstruction">Instructions</th>
                                                            <th>Category</th>
                                                            <th>Qty</th>
                                                            <th>Remarks</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                        <tr *ngFor="let item of record.medicines; let i = index">
                                                            <td>
                                                                <span class=" text-wrap" [textContent]="item.productName"></span><br />
                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap" *ngIf="item.genericName" [textContent]="item.genericName"></span>

                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap">
                                                                    <span *ngIf="item.instruction" [textContent]="item.instruction"></span>
                                                                </span>
                                                            </td>
                                                            <td *ngIf=" isMedicationInstruction">
                                                                <div>
                                                                    <span class="text-capitalize" *ngIf="item.isMorning" [textContent]="item.morningDosage"></span>
                                                                </div>
                                                                <div>
                                                                    <span class="text-capitalize" *ngIf="item.isAfternoon" [textContent]="item.afternoonDosage"></span>
                                                                </div>
                                                                <div>
                                                                    <span class="text-capitalize" *ngIf="item.isNight" [textContent]="item.nightDosage"></span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap">
                                                                    <span *ngIf="item.instruction" [textContent]="item.categoryName"></span>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap">
                                                                    <span *ngIf="item.instruction" [textContent]="item.dosage"></span>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap">
                                                                    <span *ngIf="item.instruction" [textContent]="item.remark"></span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let item of orderPrescription.orderData">
                                            <td *ngIf="item.comments && item.comments !== null " [textContent]="item.orderName"></td>
                                            <td *ngIf="item.comments && item.comments !== null " [innerHTML]="item.comments"></td>
                                        </tr>
                                        <tr *ngIf="referralOrder && referralOrder.length !== 0">
                                            <td>
                                                Referrals
                                            </td>
                                            <td>
                                                <table class="table-bordered table-dark bg-white text-black transcipt mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Doctors</th>
                                                            <th>Comments</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                        <tr *ngFor="let item of referralOrder; let i = index">
                                                            <td>
                                                                <span class=" text-wrap" [textContent]="item.name"></span><br />
                                                            </td>
                                                            <td>
                                                                <span class=" text-wrap" [textContent]="item.comments"></span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr *ngIf="infusionrecords && infusionrecords.length>0">
                                            <td>Infusion</td>
                                            <td>
                                                <table class="table-bordered table-dark bg-white text-black transcipt mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Infusion Name</th>
                                                            <th>Medicine Name</th>
                                                            <th>Device</th>
                                                            <th>Infusion Route</th>
                                                            <th>Duration Unit</th>
                                                            <th>Duration</th>
                                                            <th>Flowrate Units</th>
                                                            <th>FlowRate Ml</th>
                                                            <th>Preparation</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr *ngFor="let item of infusions;">
                                                            <td>
                                                                <p [textContent]="item.pharmacyInfusionName || '-' "></p>
                                                            </td>
                                                            <td>
                                                                <p [textContent]="item.pharmacyProductName || '-'"></p>
                                                            </td>
                                                            <td>
                                                                <p [textContent]="item.deviceName || '-' "></p>
                                                            </td>
                                                            <td>
                                                                <p [textContent]="item.infusionRoute|| '-' "></p>
                                                            </td>
                                                            <td>
                                                                <p [textContent]="item.durationUnit ||'-'"></p>
                                                            </td>
                                                            <td>
                                                                <p [textContent]="item.duration || '-'"></p>
                                                            </td>
                                                            <td>
                                                                <p [textContent]="item.flowrateUnits ||'-' "></p>
                                                            </td>
                                                            <td>
                                                                <p [textContent]="item.flowrateMl ||'-'"></p>
                                                            </td>
                                                            <td>
                                                                <p [textContent]="item.prepration ||'-' "></p>
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>

                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isNurseDisplay">
                        <div class="dashboard dashboard-table" #ancCaredClosureSection *ngIf="ancCaredClosure && ancCaredClosure !== null">
                            <h4 class="dashboard-title font-weight-bold">ANC Card Closure</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <h6 class="font-18 mt-0 mb-2">ANC Card Closure</h6>
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <tr *ngIf="ancCaredClosure.ancCardClosed">
                                            <td class="col-8">ANC Card Closed</td>
                                            <td class="col-4" [textContent]="ancCaredClosure.ancCardClosed"> </td>
                                        </tr>
                                        <tr *ngIf="ancCaredClosure.reason">
                                            <td class="col-8">Reason</td>
                                            <td class="col-4" [textContent]="ancCaredClosure.reason"> </td>
                                        </tr>
                                        <tr *ngIf="ancCaredClosure.comment">
                                            <td class="col-8">Comments</td>
                                            <td class="col-4" [textContent]="ancCaredClosure.comment"> </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isNurseDisplay">
                        <div class="dashboard-box dashboard-table-box" #referralFormSection *ngIf="referralForm && referralForm.length>0 ">
                            <h4 class="dashboard-title font-weight-bold">Referral Form</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Referral To Doctor</th>
                                                <th>Reason For Referring</th>
                                                <th>Req Date</th>
                                                <th>Time</th>
                                                <th>Consultant Doctor</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of referralForm">
                                                <td><span [textContent]="item.referralDoctor"></span></td>
                                                <td><span [textContent]="item.reason"></span></td>
                                                <td><span [textContent]="item.requiredDate | date: 'dd/MM/yyyy'"></span></td>
                                                <td><span [textContent]="item.time"></span></td>
                                                <td><span [textContent]="item.consultantDoctor"></span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div *ngIf="!isNurseDisplay">
                        <div class="dashboard dashboard-table" #outPatientManagementSection *ngIf="outPatientManagement && outPatientManagement !== null">
                            <h4 class="dashboard-title font-weight-bold">Out Patient Management of Early Pregnancy loss</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div class="row" *ngIf="outPatientManagement && (outPatientManagement.date || outPatientManagement.diagnosis || outPatientManagement.lmp || outPatientManagement.ga || outPatientManagement.usgFindings || outPatientManagement.conservativeManagements)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">CRL Of Less Than 9 Weeks</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="outPatientManagement.date">
                                                    <td class="col-8">Date</td>
                                                    <td class="col-4" [textContent]="outPatientManagement.date | date: 'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="outPatientManagement.diagnosis">
                                                    <td class="col-8">Diagnosis</td>
                                                    <td class="col-4" [textContent]="outPatientManagement.diagnosis"> </td>
                                                </tr>
                                                <tr *ngIf="outPatientManagement.lmp">
                                                    <td class="col-8">LMP</td>
                                                    <td class="col-4" [textContent]="outPatientManagement.lmp | date: 'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="outPatientManagement.ga">
                                                    <td class="col-8">GA</td>
                                                    <td class="col-4" [textContent]="outPatientManagement.ga"> </td>
                                                </tr>
                                                <tr *ngIf="outPatientManagement.usgFindings">
                                                    <td class="col-8">USG Findings</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="outPatientManagement.usgFindings"> </td>
                                                </tr>
                                                <tr *ngIf="outPatientManagement.conservativeManagements">
                                                    <td class="col-8">Conservative Managements</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="outPatientManagement.conservativeManagements"> </td>
                                                </tr>

                                            </table>
                                        </div>
                                    </div>


                                    <div class="row" *ngIf="outPatientManagement && (outPatientManagement.mifepristone || outPatientManagement.dateOfUsingMifepristone || outPatientManagement.timeMifepristone || outPatientManagement.antibiotic || outPatientManagement.dateOfDoxycline || outPatientManagement.timeDoxycycline
                                         || outPatientManagement.microgramVaginal || outPatientManagement.dateOfMisoprosto || outPatientManagement.timeOfMisoprostol || outPatientManagement.reviewOfUltrasound || outPatientManagement.dateOfUltrasound || outPatientManagement.timeOfUltrasound || outPatientManagement.reportToTheHospital || outPatientManagement.otherDetail)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Medical Regime For Evacuation Of Products Of Conception ERPC</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="outPatientManagement.mifepristone">
                                                    <td class="col-8">STEP I: MIFEPRISTONE 200MG ORAL</td>
                                                    <td class="col-4" [textContent]="outPatientManagement.mifepristone"> </td>
                                                </tr>
                                                <tr *ngIf="outPatientManagement.dateOfUsingMifepristone">
                                                    <td class="col-8">Date Of Using Mifepristone</td>
                                                    <td class="col-4" [textContent]="outPatientManagement.dateOfUsingMifepristone | date: 'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="outPatientManagement.timeMifepristone">
                                                    <td class="col-8">Time</td>
                                                    <td class="col-4" [textContent]="outPatientManagement.timeMifepristone"> </td>
                                                </tr>
                                                <tr *ngIf="outPatientManagement.antibiotic">
                                                    <td class="col-8">STEP II : ANTIBIOTICS CAP. DOXYCYCLINE 100MG TWICE DAILY FOR 5 DAYS</td>
                                                    <td class="col-4" [textContent]="true"> </td>
                                                </tr>
                                                <tr *ngIf="outPatientManagement.dateOfDoxycline">
                                                    <td class="col-8">Date Of Using Doxycycline</td>
                                                    <td class="col-4" [textContent]="outPatientManagement.dateOfDoxycline | date: 'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="outPatientManagement.timeDoxycycline">
                                                    <td class="col-8">Time</td>
                                                    <td class="col-4" [textContent]="outPatientManagement.timeDoxycycline"> </td>
                                                </tr>
                                                <tr *ngIf="outPatientManagement.microgramVaginal">
                                                    <td class="col-8">STEP III : MISOPROSTOL 800 MICROGRAM VAGINAL</td>
                                                    <td class="col-4" [textContent]="true"> </td>
                                                </tr>
                                                <tr *ngIf="outPatientManagement.dateOfMisoprosto">
                                                    <td class="col-8">Date Of Using Misoprostol</td>
                                                    <td class="col-4" [textContent]="outPatientManagement.dateOfMisoprosto | date: 'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="outPatientManagement.timeOfMisoprostol">
                                                    <td class="col-8">Time</td>
                                                    <td class="col-4" [textContent]="outPatientManagement.timeOfMisoprostol"> </td>
                                                </tr>
                                                <tr *ngIf="outPatientManagement.reviewOfUltrasound">
                                                    <td class="col-8">REVIEW FOR AN ULTRASOUND AFTER A WEEK</td>
                                                    <td class="col-4" [textContent]="true"> </td>
                                                </tr>
                                                <tr *ngIf="outPatientManagement.dateOfUltrasound">
                                                    <td class="col-8">Date Of Ultrasound</td>
                                                    <td class="col-4" [textContent]="outPatientManagement.dateOfUltrasound | date: 'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="outPatientManagement.timeOfUltrasound">
                                                    <td class="col-8">Time</td>
                                                    <td class="col-4" [textContent]="outPatientManagement.timeOfUltrasound"> </td>
                                                </tr>
                                                <tr *ngIf="outPatientManagement.reportToTheHospital">
                                                    <td class="col-8" style=" white-space: normal; word-break: break-all;">REPORT TO THE HOSPITAL IF THERE IS EXCESSIVE BLEEDING, EXCESSIVE PAIN, FEVER OR FOUL SMELLING VAGINAL DISCHARGE</td>
                                                    <td class="col-4" [textContent]="true"> </td>
                                                </tr>
                                                <tr *ngIf="outPatientManagement.otherDetail">
                                                    <td class="col-8">Other Details</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="outPatientManagement.otherDetail"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="roleId != 63">
                        <div class="dashboard dashboard-table" #motherWithEpilepsySection *ngIf="motherEpilepsy && motherEpilepsy !== null">
                            <h4 class="dashboard-title font-weight-bold">Mothers With Epilepsy Counselling Form</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div class="row" *ngIf="motherEpilepsy && (motherEpilepsy.ga || motherEpilepsy.date || motherEpilepsy.typeOfEpilepsy || motherEpilepsy.cause)">
                                        <div class="col-12">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="motherEpilepsy.ga">
                                                    <td class="col-8">GA</td>
                                                    <td class="col-4" [textContent]="motherEpilepsy.ga"> </td>
                                                </tr>
                                                <tr *ngIf="motherEpilepsy.date">
                                                    <td class="col-8">Date</td>
                                                    <td class="col-4" [textContent]="motherEpilepsy.date | date: 'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="motherEpilepsy.typeOfEpilepsy">
                                                    <td class="col-8">Type Of Epilepsy</td>
                                                    <td class="col-4" [textContent]="motherEpilepsy.typeOfEpilepsy"> </td>
                                                </tr>
                                                <tr *ngIf="motherEpilepsy.cause">
                                                    <td class="col-8">Cause</td>
                                                    <td class="col-4" [textContent]="motherEpilepsy.cause"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="motherEpilepsy && (motherEpilepsy.medication1 || motherEpilepsy.dose1 || motherEpilepsy.mdeication2 || motherEpilepsy.dose2 || motherEpilepsy.medication3 || motherEpilepsy.dose3
                  || motherEpilepsy.frequencyOfSeizures|| motherEpilepsy.lastEpisodeOfSeizures|| motherEpilepsy.counselingNeedstobeDone|| motherEpilepsy.note|| motherEpilepsy.complains)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Medication Used</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="motherEpilepsy.medication1">
                                                    <td class="col-8">1. Medication</td>
                                                    <td class="col-4" [textContent]="motherEpilepsy.medication1"> </td>
                                                </tr>
                                                <tr *ngIf="motherEpilepsy.dose1">
                                                    <td class="col-8">Dose</td>
                                                    <td class="col-4" [textContent]="motherEpilepsy.dose1"> </td>
                                                </tr>
                                                <tr *ngIf="motherEpilepsy.mdeication2">
                                                    <td class="col-8">2. Medication</td>
                                                    <td class="col-4" [textContent]="motherEpilepsy.mdeication2"> </td>
                                                </tr>
                                                <tr *ngIf="motherEpilepsy.dose2">
                                                    <td class="col-8">Dose</td>
                                                    <td class="col-4" [textContent]="motherEpilepsy.dose2"> </td>
                                                </tr>
                                                <tr *ngIf="motherEpilepsy.medication3">
                                                    <td class="col-8">3. Medication</td>
                                                    <td class="col-4" [textContent]="motherEpilepsy.medication3"> </td>
                                                </tr>
                                                <tr *ngIf="motherEpilepsy.dose3">
                                                    <td class="col-8">Dose</td>
                                                    <td class="col-4" [textContent]="motherEpilepsy.dose3"> </td>
                                                </tr>
                                                <tr *ngIf="motherEpilepsy.frequencyOfSeizures">
                                                    <td class="col-8">Frequency Of Seizures</td>
                                                    <td class="col-4" [textContent]="motherEpilepsy.frequencyOfSeizures"> </td>
                                                </tr>
                                                <tr *ngIf="motherEpilepsy.lastEpisodeOfSeizures">
                                                    <td class="col-8">Last Episode Of Seizures</td>
                                                    <td class="col-4" [textContent]="motherEpilepsy.lastEpisodeOfSeizures"> </td>
                                                </tr>
                                                <tr *ngIf="motherEpilepsy.counselingNeedstobeDone">
                                                    <td class="col-8">Counselling Needs To Be Done About</td>
                                                    <td class="col-4" [textContent]="motherEpilepsy.counselingNeedstobeDone"> </td>
                                                </tr>
                                                <tr *ngIf="motherEpilepsy.complains.length">
                                                    <td class="col-4"></td>
                                                    <td class="col-8">
                                                        <div>
                                                            <ul class="dashboard-ul row " style="white-space: normal; word-break: break-all;">
                                                                <li *ngFor="let item of motherEpilepsy.complains" class="col-lg-12" [textContent]="item"></li>
                                                            </ul>
                                                        </div>

                                                    </td>
                                                </tr>
                                                <tr *ngIf="motherEpilepsy.note">
                                                    <td class="col-4">Note</td>
                                                    <td class="col-8">
                                                        <span class="wordBreak" [textContent]="motherEpilepsy.note"></span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div *ngIf="roleId != 63">
                        <div class="dashboard dashboard-table" #externalCephalicVersionSection *ngIf="externalCephalic && externalCephalic !== null">
                            <h4 class="dashboard-title font-weight-bold">External Cephalic Version (ECV)</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div class="row" *ngIf="externalCephalic && (externalCephalic.consultedByDr || externalCephalic.ipNo || externalCephalic.visitDate || externalCephalic.timeV|| externalCephalic.parity|| externalCephalic.gestation|| externalCephalic.bloodGroup|| externalCephalic.previousSection|| externalCephalic.labour)">
                                        <div class="col-12">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="externalCephalic.consultedByDr">
                                                    <td class="col-8">Consultant Dr</td>
                                                    <td class="col-4" [textContent]="externalCephalic.consultedByDr.toUpperCase()"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.ipNo">
                                                    <td class="col-8">IP No </td>
                                                    <td class="col-4" [textContent]="externalCephalic.ipNo"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.visitDate">
                                                    <td class="col-8">Visit Date</td>
                                                    <td class="col-4" [textContent]="externalCephalic.visitDate | date: 'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.timeV">
                                                    <td class="col-8">Time V</td>
                                                    <td class="col-4" [textContent]="externalCephalic.timeV"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.parity">
                                                    <td class="col-8">Parity</td>
                                                    <td class="col-4" [textContent]="externalCephalic.parity"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.gestation">
                                                    <td class="col-8">Gestation</td>
                                                    <td class="col-4" [textContent]="externalCephalic.gestation"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.bloodGroup">
                                                    <td class="col-8">Blood Group</td>
                                                    <td class="col-4" [textContent]="externalCephalic.bloodGroup"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.previousSection">
                                                    <td class="col-8">Previous Section</td>
                                                    <td class="col-4" [textContent]="externalCephalic.previousSection"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.labour">
                                                    <td class="col-8">Labour</td>
                                                    <td class="col-4" [textContent]="externalCephalic.labour"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="externalCephalic && (externalCephalic.presentation || externalCephalic.fhrMin || externalCephalic.afi || externalCephalic.ebw|| externalCephalic.hyperextension|| externalCephalic.sideOfBack|| externalCephalic.placenta|| externalCephalic.comments|| externalCephalic.tocolysis
                                             || externalCephalic.noOfAttempts|| externalCephalic.ecv|| externalCephalic.postProcedure|| externalCephalic.antiD)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Ultrasound</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="externalCephalic.presentation">
                                                    <td class="col-8">Presentation</td>
                                                    <td class="col-4" [textContent]="externalCephalic.presentation"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.fhrMin">
                                                    <td class="col-8">FHR Min</td>
                                                    <td class="col-4" [textContent]="externalCephalic.fhrMin"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.afi">
                                                    <td class="col-8">AFI</td>
                                                    <td class="col-4" [textContent]="externalCephalic.afi"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.ebw">
                                                    <td class="col-8">EBW</td>
                                                    <td class="col-4" [textContent]="externalCephalic.ebw"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.hyperextension">
                                                    <td class="col-8">Hyperextension Of Head</td>
                                                    <td class="col-4" [textContent]="externalCephalic.hyperextension"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.sideOfBack">
                                                    <td class="col-8">Side Of Back</td>
                                                    <td class="col-4" [textContent]="externalCephalic.sideOfBack"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.placenta">
                                                    <td class="col-8">Placenta</td>
                                                    <td class="col-4" [textContent]="externalCephalic.placenta"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.comments">
                                                    <td class="col-8">Comments</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="externalCephalic.comments"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.tocolysis">
                                                    <td class="col-8">Tocolysis</td>
                                                    <td class="col-4" [textContent]="externalCephalic.tocolysis"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.ifOtherdetails">
                                                    <td class="col-8">If Others Details</td>
                                                    <td class="col-4" [textContent]="externalCephalic.ifOtherdetails"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.noOfAttempts">
                                                    <td class="col-8">No. Of Attempts At ECV</td>
                                                    <td class="col-4" [textContent]="externalCephalic.noOfAttempts"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.ecv">
                                                    <td class="col-8">ECV</td>
                                                    <td class="col-4" [textContent]="externalCephalic.ecv"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.postProcedure">
                                                    <td class="col-8">Post Procedure NST</td>
                                                    <td class="col-4" [textContent]="externalCephalic.postProcedure"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.antiD">
                                                    <td class="col-8">Anti D</td>
                                                    <td class="col-4" [textContent]="externalCephalic.antiD"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="externalCephalic && (externalCephalic.postnantalExercise.length >0 || externalCephalic.outcome ||externalCephalic.typeOfEpilepsy)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Further Management</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="externalCephalic.postnantalExercise.length >0">
                                                    <td class="col-8"></td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="externalCephalic.postnantalExercise">
                                                        <ul class="dashboard-ul row">
                                                            <li *ngFor="let item of externalCephalic.postnantalExercise" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.outcome">
                                                    <td class="col-8">Outcome</td>
                                                    <td class="col-4" [textContent]="externalCephalic.outcome"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.typeOfEpilepsy">
                                                    <td class="col-8">EMERGENCY CAESAREAN SECTIONS</td>
                                                    <td class="col-4" [textContent]="externalCephalic.typeOfEpilepsy"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="externalCephalic && (externalCephalic.birthDate || externalCephalic.indication ||externalCephalic.birthWeight||externalCephalic.commentsIfAny)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Birth Details</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="externalCephalic.birthDate">
                                                    <td class="col-8">Birth Date</td>
                                                    <td class="col-4" [textContent]="externalCephalic.birthDate | date: 'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.indication">
                                                    <td class="col-8">Indication</td>
                                                    <td class="col-4" [textContent]="externalCephalic.indication"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.birthWeight">
                                                    <td class="col-8">Birth Weight</td>
                                                    <td class="col-4" [textContent]="externalCephalic.birthWeight"> </td>
                                                </tr>
                                                <tr *ngIf="externalCephalic.commentsIfAny">
                                                    <td class="col-8">Comments If Any</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="externalCephalic.commentsIfAny"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="dashboard dashboard-table" *ngIf="opManagement && opManagement !== null">
                        <h4 class="dashboard-title font-weight-bold">
                            OP Management Of HTN In Pregnancy
                        </h4>
                        <div class="dashboard-body">
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <tr *ngIf="opManagement.diagnosis">
                                            <td>Diagnosis</td>
                                            <td [textContent]="opManagement.diagnosis"></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div class="row" *ngIf="opManagement != null">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Plan For The Next One Week</h6>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="opManagement.maternalMonitoring.length > 0">
                                        <div class="col-12">
                                            <h6 class="font-14 mt-0 mb-2">Maternal Monitoring</h6>
                                            <ul class="dashboard-ul row">
                                                <li *ngFor="let item of opManagement.maternalMonitoring" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="opManagement.nextTesting">
                                                    <td>Next Testing</td>
                                                    <td [textContent]="opManagement.nextTesting"></td>
                                                </tr>
                                                <tr *ngIf="opManagement.nextAppointment">
                                                    <td>Next Appointment</td>
                                                    <td [textContent]="opManagement.nextAppointment | date:'dd/MM/yyyy'"></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="opManagement.fetalMonitoring.length > 0">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Fetal Monitoring</h6>
                                            <ul class="dashboard-ul row">
                                                <li *ngFor="let item of opManagement.fetalMonitoring" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="opManagement.ultrasoundEvaluation.length > 0">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">3.Ultrasound Evaluations</h6>
                                            <ul class="dashboard-ul row">
                                                <li *ngFor="let item of opManagement.ultrasoundEvaluation" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="opManagement.nextNST">
                                                    <td>Next NST</td>
                                                    <td [textContent]="opManagement.nextNST"></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="opManagement.bpData || opManagement.systolicBP || opManagement.diasystolicBP || opManagement.urineProtein">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">BP Monitoring</h6>
                                        </div>
                                    </div>
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="opManagement.bpData">
                                                    <td>Date</td>
                                                    <td [textContent]="opManagement.bpData | date:'dd/MM/yyyy'"></td>
                                                </tr>
                                                <tr *ngIf="opManagement.systolicBP">
                                                    <td>Systolic Bp Mm Hg</td>
                                                    <td [textContent]="opManagement.systolicBP"></td>
                                                </tr>
                                                <tr *ngIf="opManagement.diasystolicBP">
                                                    <td>Diasystolic BP Mm Hg</td>
                                                    <td [textContent]="opManagement.diasystolicBP"></td>
                                                </tr>
                                                <tr *ngIf="opManagement.urineProtein">
                                                    <td>Urine Protein</td>
                                                    <td [textContent]="opManagement.urineProtein"></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!isNurseDisplay">
                        <div class="dashboard dashboard-table" #specialFeatureSection *ngIf="specialFeature && specialFeature !== null && specialFeature.specialFeature">
                            <h4 class="dashboard-title font-weight-bold">Special Features</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <tr *ngIf="specialFeature.specialFeature">
                                            <td class="col-8">Special Features</td>
                                            <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="specialFeature.specialFeature"> </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isNurseDisplay">
                        <div class="dashboard dashboard-table" #admssionFormSection *ngIf="admissionSlip && admissionSlip !== null">
                            <h4 class="dashboard-title font-weight-bold">Admission Slip</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <tr *ngIf="admissionSlip.mrno">
                                            <td class="col-8">Mr No</td>
                                            <td class="col-4" [textContent]="admissionSlip.mrno"> </td>
                                        </tr>
                                        <tr *ngIf="admissionSlip.name">
                                            <td class="col-8">Name</td>
                                            <td class="col-4" [textContent]="admissionSlip.name"> </td>
                                        </tr>
                                        <tr *ngIf="admissionSlip.age">
                                            <td class="col-8">Age</td>
                                            <td class="col-4" [textContent]="admissionSlip.age"> </td>
                                        </tr>
                                        <tr *ngIf="admissionSlip.wifeOf">
                                            <td class="col-8">Wife Of</td>
                                            <td class="col-4" [textContent]="admissionSlip.wifeOf"> </td>
                                        </tr>
                                        <tr *ngIf="admissionSlip.department">
                                            <td class="col-8">Department</td>
                                            <td class="col-4" [textContent]="admissionSlip.department"> </td>
                                        </tr>
                                        <tr *ngIf="admissionSlip.admissionDate">
                                            <td class="col-8">Admssion Date</td>
                                            <td class="col-4" [textContent]="admissionSlip.admissionDate | date: 'dd/MM/yyyy'"> </td>
                                        </tr>
                                        <tr *ngIf="admissionSlip.time">
                                            <td class="col-8">Time</td>
                                            <td class="col-4" [textContent]="admissionSlip.time"> </td>
                                        </tr>
                                        <tr *ngIf="admissionSlip.admssionDoctor">
                                            <td class="col-8">Admission Doctor</td>
                                            <td class="col-4" [textContent]="admissionSlip.admssionDoctor.toUpperCase()"> </td>
                                        </tr>
                                        <tr *ngIf="admissionSlip.fhConsultant">
                                            <td class="col-8">Consultant</td>
                                            <td class="col-4" [textContent]="admissionSlip.fhConsultant"> </td>
                                        </tr>
                                        <tr *ngIf="admissionSlip.surgeryDate">
                                            <td class="col-8">Surgery Date</td>
                                            <td class="col-4" [textContent]="admissionSlip.surgeryDate | date: 'dd/MM/yyyy'"> </td>
                                        </tr>
                                        <tr *ngIf="admissionSlip.surgeon">
                                            <td class="col-8">Surgeon</td>
                                            <td class="col-4" [textContent]="admissionSlip.surgeon.toUpperCase()"> </td>
                                        </tr>
                                        <tr *ngIf="admissionSlip.surgicalPrcedure">
                                            <td class="col-8">Surgical Procedure</td>
                                            <td class="col-4" [textContent]="admissionSlip.surgicalPrcedure"> </td>
                                        </tr>
                                        <tr *ngIf="admissionSlip.gestationalAge">
                                            <td class="col-8">Gestational Age</td>
                                            <td class="col-4" [textContent]="admissionSlip.gestationalAge"> </td>
                                        </tr>
                                        <tr *ngIf="admissionSlip.phoneNo">
                                            <td class="col-8">Phone No</td>
                                            <td class="col-4" [textContent]="admissionSlip.phoneNo"> </td>
                                        </tr>
                                        <tr *ngIf="admissionSlip.resonForAdmission">
                                            <td class="col-8">Reason For Admission</td>
                                            <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="admissionSlip.resonForAdmission"> </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!isNurseDisplay">
                        <div class="dashboard dashboard-table" *ngIf="outSideTests && outSideTests !== null" #outsideTestsSection>
                            <h4 class="dashboard-title font-weight-bold">Outside Tests</h4>
                            <div class="dashboard-body" *ngFor="let item of outSideTests">
                                <div class="overflow-auto">
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="item">
                                        <thead>
                                            <tr>
                                                <th>Done Date</th>
                                                <th>Test Name</th>
                                                <th>Other Tests</th>
                                                <th>Other Test Results</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="media">
                                                        <div class="media-body">
                                                            <h5 class="mb-0 mt-0 font-weight-normal text-truncate-line" [textContent]=" item.doneDate | date:'dd/MM/yyyy'"></h5>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p *ngIf="item.testname" [textContent]="item.testname"></p>
                                                </td>
                                                <td>
                                                    <p *ngIf="item.otherTests" [textContent]="item.otherTests "></p>
                                                </td>
                                                <td>
                                                    <p *ngIf="item.otherTestResults" [textContent]="item.otherTestResults "></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table *ngIf="item.parameters && item.parameters.length>0" class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th>SN</th>
                                                <th>Component</th>
                                                <th>Parameter</th>
                                                <th>Result Value</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr *ngFor="let items of item.parameters; let i=index">
                                                <td>
                                                    <p *ngIf="items.parameterName" [textContent]="i+1"></p>
                                                </td>
                                                <td>
                                                    <p *ngIf="items.componentName" [textContent]="items.componentName"></p>
                                                </td>
                                                <td>
                                                    <p *ngIf="items.parameterName" [textContent]="items.parameterName"></p>
                                                </td>
                                                <td>
                                                    <p *ngIf="items.resultValues" [textContent]="items.resultValues"></p>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!isNurseDisplay">
                        <div class="dashboard dashboard-table" #postnantalVisitSection *ngIf="postnantalVisit && postnantalVisit !== null">
                            <h4 class="dashboard-title font-weight-bold">Postnatal Visit</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div class="row" *ngIf="postnantalVisit && postnantalVisit !== null && (postnantalVisit.mrNo || postnantalVisit.name || postnantalVisit.aNCNo || postnantalVisit.visitNo || postnantalVisit.visitDate)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Postnatal Visit</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="postnantalVisit.mrNo">
                                                    <td class="col-8">MRNO</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.mrNo"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.name">
                                                    <td class="col-8">Name</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.name"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.aNCNo">
                                                    <td class="col-8">ANC No</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.aNCNo"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.visitNo">
                                                    <td class="col-8">Visit No</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.visitNo"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.visitDate">
                                                    <td class="col-8">Visit Date</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.visitDate | date:'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.consultedByDr">
                                                    <td class="col-8">Consultant Dr</td>
                                                    <td class="col-4 text-uppercase" [textContent]="postnantalVisit.consultedByDr"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="postnantalVisit && postnantalVisit !== null && (postnantalVisit.pulseRate || postnantalVisit.systolic || postnantalVisit.diastolic || postnantalVisit.temperature || postnantalVisit.weight||postnantalVisit.bMI)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">General Examination</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="postnantalVisit.weight">
                                                    <td class="col-8">Weight</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.weight"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.bMI">
                                                    <td class="col-8">BMI</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.bMI"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.pulseRate">
                                                    <td class="col-8">Pulse Rate</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.pulseRate"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.systolic">
                                                    <td class="col-8">Systolic BP Mm Hg</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.systolic"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.diastolic">
                                                    <td class="col-8">Diastolic BP Mm Hg</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.diastolic"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.temperature">
                                                    <td class="col-8">Temperature</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.temperature"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="postnantalVisit && postnantalVisit !== null && (postnantalVisit.dateOfDelivery || postnantalVisit.reason || postnantalVisit.seen || postnantalVisit.nextReview)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Delivery Details</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="postnantalVisit.dateOfDelivery">
                                                    <td class="col-8">Date Of Delivery</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.dateOfDelivery | date: 'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.modeOfDelivery">
                                                    <td class="col-8"> Mode Of Delivery  </td>
                                                    <td class="col-4" [textContent]="postnantalVisit.modeOfDelivery"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.sexOfTheBaby">
                                                    <td class="col-8">Sex Of The Baby</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.sexOfTheBaby"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.birthWeight">
                                                    <td class="col-8">Birth Weight</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.birthWeight"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.babyStatus">
                                                    <td class="col-8">Baby Status</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.babyStatus"> </td>
                                                </tr>

                                                <tr *ngIf="postnantalVisit.lactating">
                                                    <td class="col-8">Lactating</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.lactating"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.antenatal">
                                                    <td class="col-8">Antenatal Complications</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="postnantalVisit.antenatal"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.intrapartum">
                                                    <td class="col-8">Intrapartum Complications</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="postnantalVisit.intrapartum"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.postpartum">
                                                    <td class="col-8">Postpartum Complications</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="postnantalVisit.postpartum"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="postnantalVisit && postnantalVisit !== null && (postnantalVisit.complaints || postnantalVisit.antenatalRiskFactor)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Postnatal Visit</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="postnantalVisit.complains">
                                                    <td class="col-8">Complaints</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;">
                                                        <ul class="dashboard-ul row">
                                                            <li *ngFor="let item of postnantalVisit.complains" [textContent]="item"></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.antenatalRiskFactor">
                                                    <td class="col-8">Antenatal Risk Factors</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="postnantalVisit.antenatalRiskFactor"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="postnantalVisit && postnantalVisit !== null && (postnantalVisit.pallor || postnantalVisit.abdominal || postnantalVisit.perineal || postnantalVisit.perSpeculam || postnantalVisit.perVaginum||postnantalVisit.perRectal ||postnantalVisit.importantAdvice)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Obstetric Examination</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="postnantalVisit.pallor">
                                                    <td class="col-8">Pallor</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.pallor"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.abdominal">
                                                    <td class="col-8">Abdominal Incision</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.abdominal"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.perineal">
                                                    <td class="col-8">Perineal Wound</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.perineal"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.perSpeculam">
                                                    <td class="col-8">Per Speculam</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.perSpeculam"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.perVaginum">
                                                    <td class="col-8">Per Vaginum</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.perVaginum"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.perRectal">
                                                    <td class="col-8">Per Rectal</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.perRectal"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.importantAdvice">
                                                    <td class="col-8">Important Advice</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="postnantalVisit.importantAdvice"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="postnantalVisit && postnantalVisit !== null && (postnantalVisit.medicationAndAdvice.length >0 || postnantalVisit.medicationAndReason)  ">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Medication And Advice</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="postnantalVisit.medicationAndAdvice.length >0">
                                                    <td class="col-8">Medication And Advice</td>
                                                    <td>
                                                        <span *ngFor="let item of postnantalVisit.medicationAndAdvice" class="" [textContent]="item +',  '"></span>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.medicationAndReason">
                                                    <td class="col-8">Comments</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="postnantalVisit.medicationAndReason"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="postnantalVisit && postnantalVisit !== null &&(postnantalVisit.postnantalExercise || postnantalVisit.contraception|| postnantalVisit.papSmear|| postnantalVisit.date|| postnantalVisit.nextReviewDate|| postnantalVisit.seenByDr)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Postnatal Exercises</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="postnantalVisit.postnantalExercise">
                                                    <td class="col-8">Postnatal Exercises</td>
                                                    <td>
                                                        <span *ngFor="let item of postnantalVisit.postnantalExercise" class="" [textContent]="item +',  '"></span>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.contraception">
                                                    <td class="col-8">Contraception</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="postnantalVisit.contraception"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.papSmear">
                                                    <td class="col-8">PAP SMEAR</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.papSmear"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.date">
                                                    <td class="col-8">Date</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.date | date: 'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.nextReviewDate">
                                                    <td class="col-8">Next Review Date</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.nextReviewDate | date: 'dd/MM/yyyy'"></td>
                                                </tr>
                                                <tr *ngIf="postnantalVisit.seenByDr">
                                                    <td class="col-8">Seen By Dr</td>
                                                    <td class="col-4" [textContent]="postnantalVisit.seenByDr"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div *ngIf="!isNurseDisplay">
                        <div class="dashboard dashboard-table" #prePregnancySection *ngIf="prePregnancy && prePregnancy !== null">
                            <h4 class="dashboard-title font-weight-bold">Pre Pregnancy Counselling</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div class="row" *ngIf="prePregnancy && prePregnancy !== null && (prePregnancy.height || prePregnancy.height|| prePregnancy.weight||prePregnancy.bmi||prePregnancy.pulse||prePregnancy.temperature||prePregnancy.systolicBp||
                                                                                            prePregnancy.dbp|| prePregnancy.respRate|| prePregnancy.marriedLif|| prePregnancy.para|| prePregnancy.living|| prePregnancy.abort|| prePregnancy.firstRhType|| prePregnancy.menstrualCycle|| prePregnancy.firstBloodGroup|| prePregnancy.lmp)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Pre Pregnancy Counseling</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="prePregnancy.height">
                                                    <td class="col-8">Height (In Feet)</td>
                                                    <td class="col-4" [textContent]="prePregnancy.height"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.weight">
                                                    <td class="col-8">Weight</td>
                                                    <td class="col-4" [textContent]="prePregnancy.weight"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.bmi">
                                                    <td class="col-8">BMI</td>
                                                    <td class="col-4" [textContent]="prePregnancy.bmi"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.pulse">
                                                    <td class="col-8">Pulse</td>
                                                    <td class="col-4" [textContent]="prePregnancy.pulse"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.temperature">
                                                    <td class="col-8">Temperature</td>
                                                    <td class="col-4" [textContent]="prePregnancy.temperature"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.systolicBp">
                                                    <td class="col-8">Systolic BP</td>
                                                    <td class="col-4" [textContent]="prePregnancy.systolicBp"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.dbp">
                                                    <td class="col-8">DBP</td>
                                                    <td class="col-4" [textContent]="prePregnancy.dbp"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.respRate">
                                                    <td class="col-8">Resp Rate</td>
                                                    <td class="col-4" [textContent]="prePregnancy.respRate"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.marriedLif">
                                                    <td class="col-8">Married Life</td>
                                                    <td class="col-4" [textContent]="prePregnancy.marriedLif"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.para">
                                                    <td class="col-8">Para</td>
                                                    <td class="col-4" [textContent]="prePregnancy.para"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.living">
                                                    <td class="col-8">Living</td>
                                                    <td class="col-4" [textContent]="prePregnancy.living"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.abort">
                                                    <td class="col-8">Abort</td>
                                                    <td class="col-4" [textContent]="prePregnancy.abort"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.menstrualCycle">
                                                    <td class="col-8">Menstrual Cycles</td>
                                                    <td class="col-4" [textContent]="prePregnancy.menstrualCycle"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.firstBloodGroup">
                                                    <td class="col-8">Blood Group</td>
                                                    <td class="col-4" [textContent]="prePregnancy.firstBloodGroup"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.firstRhType">
                                                    <td class="col-8">RH Type</td>
                                                    <td class="col-4" [textContent]="prePregnancy.firstRhType"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.lmp">
                                                    <td class="col-8">LMP</td>
                                                    <td class="col-4" [textContent]="prePregnancy.lmp | date: 'dd/MM/yyyy'"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>


                                    <div class="row" *ngIf="prePregnancy && prePregnancy !== null && (prePregnancy.bloodGroup || prePregnancy.rhType|| prePregnancy.reasonForCounseling)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Husband Details</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="prePregnancy.bloodGroup">
                                                    <td class="col-8">Blood Group</td>
                                                    <td class="col-4" [textContent]="prePregnancy.bloodGroup"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.rhType">
                                                    <td class="col-8">RH Type</td>
                                                    <td class="col-4" [textContent]="prePregnancy.rhType"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.reasonForCounseling">
                                                    <td class="col-8">Reason For Counseling</td>
                                                    <td class="col-4">
                                                        <div class="break-all">
                                                            <span style=" white-space: normal; word-break: break-all;" [textContent]="prePregnancy.reasonForCounseling"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="prePregnancy && prePregnancy !== null && (prePregnancy.indicationForCounselling || prePregnancy.counsellingSession|| prePregnancy.counsellingDetail)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Counselling Details</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="prePregnancy.indicationForCounselling">
                                                    <td class="col-8">Indication For Counselling</td>
                                                    <td class="col-4" [textContent]="prePregnancy.indicationForCounselling"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.counsellingSession">
                                                    <td class="col-8">Counselling Session</td>
                                                    <td class="col-4" [textContent]="prePregnancy.counsellingSession"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.counsellingDetail">
                                                    <td class="col-8">Counselling Details</td>
                                                    <td class="col-4">
                                                        <div class="break-all">
                                                            <span style=" white-space: normal; word-break: break-all;" [textContent]="prePregnancy.counsellingDetail"></span>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </table>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="prePregnancy && prePregnancy !== null && (prePregnancy.preConception || prePregnancy.lowDoseAspirin|| prePregnancy.rubellaVaccine|| prePregnancy.planAPregaancyAfter|| prePregnancy.assessThyroid|| prePregnancy.pedigreeChar|| prePregnancy.remark|| prePregnancy.reviewDate)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Plan For Next Pregnancy</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="prePregnancy.preConception">
                                                    <td class="col-8">Pre Conception Folic Acid</td>
                                                    <td class="col-4" [textContent]="prePregnancy.preConception"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.lowDoseAspirin">
                                                    <td class="col-8">Low Dose Aspirin</td>
                                                    <td class="col-4" [textContent]="prePregnancy.lowDoseAspirin"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.rubellaVaccine">
                                                    <td class="col-8">Rubella Vaccine</td>
                                                    <td class="col-4" [textContent]="prePregnancy.rubellaVaccine"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.assessThyroid">
                                                    <td class="col-8">Assess Thyroid Function</td>
                                                    <td class="col-4" [textContent]="prePregnancy.assessThyroid"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.planAPregaancyAfter">
                                                    <td class="col-8">Plan A Pregnancy After</td>
                                                    <td class="col-4" [textContent]="prePregnancy.planAPregaancyAfter"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.pedigreeChar">
                                                    <td class="col-8">Pedigree Char</td>
                                                    <td class="col-4" [textContent]="prePregnancy.pedigreeChar"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.remark">
                                                    <td class="col-8">Remarks</td>
                                                    <td class="col-4">
                                                        <div class="break-all">
                                                            <span style="white-space:break-spaces" [textContent]="prePregnancy.remark"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.reviewDate">
                                                    <td class="col-8">Review Date</td>
                                                    <td class="col-4" [textContent]="prePregnancy.reviewDate | date: 'dd/MM/yyyy'"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="prePregnancy && prePregnancy !== null && (prePregnancy.psVagina || prePregnancy.psDischarge|| prePregnancy.cervix|| prePregnancy.cervical|| prePregnancy.membranes|| prePregnancy.presentingPart|| prePregnancy.stationOfPP|| prePregnancy.pelvis|| prePregnancy.perAbdomen)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Vaginal Examination</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="prePregnancy.psVagina">
                                                    <td class="col-8">P S Vagina</td>
                                                    <td class="col-4" [textContent]="prePregnancy.psVagina"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.psDischarge">
                                                    <td class="col-8">P S Discharge</td>
                                                    <td class="col-4" [textContent]="prePregnancy.psDischarge"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.cervix">
                                                    <td class="col-8">Cervix</td>
                                                    <td class="col-4" [textContent]="prePregnancy.cervix"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.cervical">
                                                    <td class="col-8">Cervical OS Cm</td>
                                                    <td class="col-4" [textContent]="prePregnancy.cervical"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.membranes">
                                                    <td class="col-8">Membranes</td>
                                                    <td class="col-4" [textContent]="prePregnancy.membranes"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.presentingPart">
                                                    <td class="col-8">Presenting Part</td>
                                                    <td class="col-4" [textContent]="prePregnancy.presentingPart"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.stationOfPP">
                                                    <td class="col-8">Station Of PP</td>
                                                    <td class="col-4" [textContent]="prePregnancy.stationOfPP"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.pelvis">
                                                    <td class="col-8">Pelvis</td>
                                                    <td class="col-4" [textContent]="prePregnancy.pelvis"> </td>
                                                </tr>
                                                <tr *ngIf="prePregnancy.perAbdomen">
                                                    <td class="col-8">Per Abdomen</td>
                                                    <td class="col-4">
                                                        <div class="break-all">
                                                            <span style="white-space:break-spaces" [textContent]="prePregnancy.perAbdomen"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!isNurseDisplay">
                        <div class="dashboard dashboard-table" #anaesthesiaSection *ngIf="anaesthesia && anaesthesia !== null">
                            <h4 class="dashboard-title font-weight-bold">Anaesthesia Record</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia.weight || anaesthesia.height|| anaesthesia.bmi||anaesthesia.pacDoneBy||anaesthesia.consultantDr||anaesthesia.bloodGroup||anaesthesia.rhType||
                                                                                            anaesthesia.allergy|| anaesthesia.diagnosis|| anaesthesia.surgery|| anaesthesia.problems)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Anaesthesia Record</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="anaesthesia.weight">
                                                    <td class="col-8">Weight</td>
                                                    <td class="col-4" [textContent]="anaesthesia.weight"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.height">
                                                    <td class="col-8">Height</td>
                                                    <td class="col-4" [textContent]="anaesthesia.height"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.bmi">
                                                    <td class="col-8">BMI</td>
                                                    <td class="col-4" [textContent]="anaesthesia.bmi"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.pacDoneBy">
                                                    <td class="col-8">PAC Done By Dr</td>
                                                    <td class="col-4" [textContent]="anaesthesia.pacDoneBy"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.consultantDr">
                                                    <td class="col-8">Consultant Surgeon</td>
                                                    <td class="col-4" [textContent]="anaesthesia.consultantDr"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.bloodGroup">
                                                    <td class="col-8">Blood Group</td>
                                                    <td class="col-4" [textContent]="anaesthesia.bloodGroup"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.rhType">
                                                    <td class="col-8">Rh Type</td>
                                                    <td class="col-4" [textContent]="anaesthesia.rhType"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.allergy">
                                                    <td class="col-8">Allergy</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="anaesthesia.allergy"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.diagnosis">
                                                    <td class="col-8">Diagnosis</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="anaesthesia.diagnosis"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.surgery">
                                                    <td class="col-8">Surgery</td>
                                                    <td class="col-4" [textContent]="anaesthesia.surgery"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.problems">
                                                    <td class="col-8">Problems</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="anaesthesia.problems"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia.reasonForConsutation || anaesthesia.temp|| anaesthesia.sp||anaesthesia.pr||anaesthesia.respRate||anaesthesia.sysBp||anaesthesia.diasBp)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">General Examination</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="anaesthesia.reasonForConsutation">
                                                    <td class="col-8">Reason For Consultation</td>
                                                    <td class="col-4" [textContent]="anaesthesia.reasonForConsutation"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.temp">
                                                    <td class="col-8">
                                                        Temp(Â°C / Fh)
                                                    </td>
                                                    <td class="col-4" [textContent]="anaesthesia.temp"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.pr">
                                                    <td class="col-8">PR(/Min)</td>
                                                    <td class="col-4" [textContent]="anaesthesia.pr"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.respRate">
                                                    <td class="col-8">Resp Rate(/Min)</td>
                                                    <td class="col-4" [textContent]="anaesthesia.respRate"> </td>
                                                </tr>

                                                <tr *ngIf="anaesthesia.sysBp">
                                                    <td class="col-8">Sys BP(Mm Of Hg)</td>
                                                    <td class="col-4" [textContent]="anaesthesia.sysBp"> </td>
                                                </tr>

                                                <tr *ngIf="anaesthesia.diasBp">
                                                    <td class="col-8">Dias BP(Mm Of Hg)</td>
                                                    <td class="col-4" [textContent]="anaesthesia.diasBp"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.sp">
                                                    <td class="col-8">SpO2</td>
                                                    <td class="col-4" [textContent]="anaesthesia.sp"> </td>
                                                </tr>


                                                <tr *ngIf="anaesthesia.sp">
                                                    <td class="col-8">Heart</td>
                                                    <td class="col-4" [textContent]="anaesthesia.heart"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.sp">
                                                    <td class="col-8">Lungs</td>
                                                    <td class="col-4" [textContent]="anaesthesia.lungs"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.sp">
                                                    <td class="col-8">Lungs-Adv Sounds</td>
                                                    <td class="col-4" [textContent]="anaesthesia.lungsSound"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.sp">
                                                    <td class="col-8">Built</td>
                                                    <td class="col-4" [textContent]="anaesthesia.built"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.sp">
                                                    <td class="col-8">Hydration</td>
                                                    <td class="col-4" [textContent]="anaesthesia.hydration"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.sp">
                                                    <td class="col-8">Pallor</td>
                                                    <td class="col-4" [textContent]="anaesthesia.pallor"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.sp">
                                                    <td class="col-8">Icterus</td>
                                                    <td class="col-4" [textContent]="anaesthesia.icterus"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.sp">
                                                    <td class="col-8">Cyanosis</td>
                                                    <td class="col-4" [textContent]="anaesthesia.cyanosis"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.sp">
                                                    <td class="col-8">Oedema</td>
                                                    <td class="col-4" [textContent]="anaesthesia.oedema"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.sp">
                                                    <td class="col-8">PP</td>
                                                    <td class="col-4" [textContent]="anaesthesia.pp"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia.mouthOpening || anaesthesia.detention.length >0|| anaesthesia.tmj||anaesthesia.neckExtention||anaesthesia.grade||anaesthesia.spineGrade||anaesthesia.asaGrade
                                         ||anaesthesia.difficultIntubation||anaesthesia.difficultRegional) !== null">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Airway Assessment</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="anaesthesia.mouthOpening">
                                                    <td class="col-8">Mouth Opening</td>
                                                    <td class="col-4" [textContent]="anaesthesia.mouthOpening"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.detention.length >0">
                                                    <td class="col-8">Dentition </td>
                                                    <td class="col-4" [textContent]="anaesthesia.detention"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.tmj">
                                                    <td class="col-8">TMJ</td>
                                                    <td class="col-4" [textContent]="anaesthesia.tmj"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.neckExtention">
                                                    <td class="col-8">
                                                        Neck Extension
                                                    </td>
                                                    <td class="col-4" [textContent]="anaesthesia.neckExtention"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.grade">
                                                    <td class="col-8"> Mallampati Grade</td>
                                                    <td class="col-4" [textContent]="anaesthesia.grade"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.spineGrade">
                                                    <td class="col-8">Spine Grade</td>
                                                    <td class="col-4" [textContent]="anaesthesia.spineGrade"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.asaGrade">
                                                    <td class="col-8">ASA Grade</td>
                                                    <td class="col-4" [textContent]="anaesthesia.asaGrade"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.difficultIntubation">
                                                    <td class="col-8">Difficult Intubation</td>
                                                    <td class="col-4" [textContent]="anaesthesia.difficultIntubation"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.difficultRegional">
                                                    <td class="col-8">Difficult Regional</td>
                                                    <td class="col-4" [textContent]="anaesthesia.difficultRegional"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia.cvs || anaesthesia.endocrine|| anaesthesia.cns||anaesthesia.rs||anaesthesia.renal||anaesthesia.git||anaesthesia.currentMed.length >0
                                         ||anaesthesia.others||anaesthesia.pastAnaesthesia)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">System Based Assessment</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="anaesthesia.cvs">
                                                    <td class="col-8">CVS</td>
                                                    <td class="col-4" [textContent]="anaesthesia.cvs"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.endocrine">
                                                    <td class="col-8">Endocrine </td>
                                                    <td class="col-4" [textContent]="anaesthesia.endocrine"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.cns">
                                                    <td class="col-8">CNS /Musculo Skeletal</td>
                                                    <td class="col-4" [textContent]="anaesthesia.cns"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.rs">
                                                    <td class="col-8">
                                                        RS
                                                    </td>
                                                    <td class="col-4" [textContent]="anaesthesia.rs"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.renal">
                                                    <td class="col-8"> Renal</td>
                                                    <td class="col-4" [textContent]="anaesthesia.renal"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.git">
                                                    <td class="col-8">GIT /Hematology</td>
                                                    <td class="col-4" [textContent]="anaesthesia.git"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.currentMed.length >0">
                                                    <td class="col-8">Current Med</td>
                                                    <td class="col-4" [textContent]="anaesthesia.currentMed"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.others">
                                                    <td class="col-8">Others</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="anaesthesia.others"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.pastAnaesthesia">
                                                    <td class="col-8">Past Anaesthesia Exposure</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="anaesthesia.pastAnaesthesia"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia.prbsAvailability || anaesthesia.prbsReserve|| anaesthesia.prbsIssue)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">PRBC</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="anaesthesia.prbsAvailability">
                                                    <td class="col-8">Availability</td>
                                                    <td class="col-4" [textContent]="anaesthesia.prbsAvailability"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.prbsReserve">
                                                    <td class="col-8">Reserve </td>
                                                    <td class="col-4" [textContent]="anaesthesia.prbsReserve"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.prbsIssue">
                                                    <td class="col-8">Issue</td>
                                                    <td class="col-4" [textContent]="anaesthesia.prbsIssue"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia.ffpAvailability || anaesthesia.ffpReserve|| anaesthesia.ffpIssue)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">FFP</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="anaesthesia.ffpAvailability">
                                                    <td class="col-8">Availability</td>
                                                    <td class="col-4" [textContent]="anaesthesia.ffpAvailability"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.ffpReserve">
                                                    <td class="col-8">Reserve </td>
                                                    <td class="col-4" [textContent]="anaesthesia.ffpReserve"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.ffpIssue">
                                                    <td class="col-8">Issue</td>
                                                    <td class="col-4" [textContent]="anaesthesia.ffpIssue"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia.rdpAvailability || anaesthesia.rdpReserve|| anaesthesia.rdpIssue)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">RDP</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="anaesthesia.rdpAvailability">
                                                    <td class="col-8">Availability</td>
                                                    <td class="col-4" [textContent]="anaesthesia.rdpAvailability"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.rdpReserve">
                                                    <td class="col-8">Reserve </td>
                                                    <td class="col-4" [textContent]="anaesthesia.rdpReserve"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.rdpIssue">
                                                    <td class="col-8">Issue</td>
                                                    <td class="col-4" [textContent]="anaesthesia.rdpIssue"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia.donorScreen || anaesthesia.onAgitator|| anaesthesia.issue||anaesthesia.mayBePosted||anaesthesia.reviewPac||anaesthesia.instructions.length >0||anaesthesia.anaesthesiaPlan
                                         ||anaesthesia.specificPost||anaesthesia.date||anaesthesia.time)">
                                        <div class="col-12">
                                            <h6 class="font-148 mt-0 mb-2">SDP</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="anaesthesia.donorScreen">
                                                    <td class="col-8">Donor Screen</td>
                                                    <td class="col-4" [textContent]="anaesthesia.donorScreen"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.onAgitator">
                                                    <td class="col-8">On Agitator </td>
                                                    <td class="col-4" [textContent]="anaesthesia.onAgitator"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.issue">
                                                    <td class="col-8"> Issue </td>
                                                    <td class="col-4" [textContent]="anaesthesia.issue"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.mayBePosted">
                                                    <td class="col-8"> May Be Posted</td>
                                                    <td class="col-4" [textContent]="anaesthesia.mayBePosted"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.reviewPac">
                                                    <td class="col-8">Review PAC</td>
                                                    <td class="col-4" [textContent]="anaesthesia.reviewPac"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.instructions.length >0">
                                                    <td class="col-8">Pre OP Instructions</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;">
                                                        <ul class="dashboard-ul row">
                                                            <li *ngFor="let item of anaesthesia.instructions" [textContent]="item"></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.anaesthesiaPlan">
                                                    <td class="col-8">Anaesthesia Plan</td>
                                                    <td class="col-4" [textContent]="anaesthesia.anaesthesiaPlan"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.specificPost">
                                                    <td class="col-8">Specific Post OP Instructions</td>
                                                    <td class="col-4" [textContent]="anaesthesia.specificPost"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.date">
                                                    <td class="col-8">Date</td>
                                                    <td class="col-4" [textContent]="anaesthesia.date | date:'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.time">
                                                    <td class="col-8">Time</td>
                                                    <td class="col-4" [textContent]="anaesthesia.time"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="anaesthesia && anaesthesia !== null && (anaesthesia.hbPcv || anaesthesia.hiv|| anaesthesia.fbs||anaesthesia.scr||anaesthesia.na||anaesthesia.sbill||anaesthesia.pt
                                         ||anaesthesia.wbc||anaesthesia.hbsag||anaesthesia.plbs||anaesthesia.bun||anaesthesia.kplus||anaesthesia.sgpt||anaesthesia.aptt||anaesthesia.plat||anaesthesia.hcv||anaesthesia.rbs||anaesthesia.bdUrea
                                         ||anaesthesia.clMinus||anaesthesia.ldh||anaesthesia.inr||anaesthesia.lastothers)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Investigations</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="anaesthesia.hbPcv">
                                                    <td class="col-8">Hb/PCV</td>
                                                    <td class="col-4" [textContent]="anaesthesia.hbPcv"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.hiv">
                                                    <td class="col-8">HIV </td>
                                                    <td class="col-4" [textContent]="anaesthesia.hiv"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.fbs">
                                                    <td class="col-8"> FBS </td>
                                                    <td class="col-4" [textContent]="anaesthesia.fbs"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.scr">
                                                    <td class="col-8"> S Cr</td>
                                                    <td class="col-4" [textContent]="anaesthesia.scr"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.na">
                                                    <td class="col-8">Na+</td>
                                                    <td class="col-4" [textContent]="anaesthesia.na"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.sbill">
                                                    <td class="col-8">S Bill</td>
                                                    <td class="col-4" [textContent]="anaesthesia.sbill"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.pt">
                                                    <td class="col-8">PT</td>
                                                    <td class="col-4" [textContent]="anaesthesia.pt"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.wbc">
                                                    <td class="col-8">WBC</td>
                                                    <td class="col-4" [textContent]="anaesthesia.wbc"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.hbsag">
                                                    <td class="col-8">HbSAg</td>
                                                    <td class="col-4" [textContent]="anaesthesia.hbsag"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.plbs">
                                                    <td class="col-8">PLBS</td>
                                                    <td class="col-4" [textContent]="anaesthesia.plbs"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.bun">
                                                    <td class="col-8">BUN</td>
                                                    <td class="col-4" [textContent]="anaesthesia.bun"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.kplus">
                                                    <td class="col-8">K+</td>
                                                    <td class="col-4" [textContent]="anaesthesia.kplus"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.sgpt">
                                                    <td class="col-8">SGPT</td>
                                                    <td class="col-4" [textContent]="anaesthesia.sgpt"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.aptt">
                                                    <td class="col-8">APTT</td>
                                                    <td class="col-4" [textContent]="anaesthesia.aptt"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.plat">
                                                    <td class="col-8">Plat</td>
                                                    <td class="col-4" [textContent]="anaesthesia.plat"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.hcv">
                                                    <td class="col-8">HCV</td>
                                                    <td class="col-4" [textContent]="anaesthesia.hcv"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.rbs">
                                                    <td class="col-8">RBS</td>
                                                    <td class="col-4" [textContent]="anaesthesia.rbs"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.bdUrea">
                                                    <td class="col-8">Bd Urea</td>
                                                    <td class="col-4" [textContent]="anaesthesia.bdUrea"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.clMinus">
                                                    <td class="col-8">Cl-</td>
                                                    <td class="col-4" [textContent]="anaesthesia.clMinus"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.ldh">
                                                    <td class="col-8">LDH</td>
                                                    <td class="col-4" [textContent]="anaesthesia.ldh"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.inr">
                                                    <td class="col-8">INR</td>
                                                    <td class="col-4" [textContent]="anaesthesia.inr"> </td>
                                                </tr>
                                                <tr *ngIf="anaesthesia.lastothers">
                                                    <td class="col-8">Others</td>
                                                    <td class="col-4" [textContent]="anaesthesia.lastothers"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!isNurseDisplay">
                        <div class="dashboard dashboard-table" #prePregnancyPlanSection *ngIf="prePregnancyPlan">
                            <h4 class="dashboard-title font-weight-bold">Pregnancy Plan</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="prePregnancyPlan &&  prePregnancyPlan.length >0">
                                        <thead>
                                            <tr>
                                                <th>Year</th>
                                                <th>ANC No</th>
                                                <th>Pregnancy Plan</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of prePregnancyPlan;">
                                                <td>
                                                    <p *ngIf="item.year" [textContent]="item.year "></p>
                                                </td>
                                                <td>
                                                    <p *ngIf="item.ancNo" [textContent]="item.ancNo "></p>
                                                    <p style="padding-left: 25px" *ngIf="!item.ancNo" [textContent]="'-'"></p>
                                                </td>
                                                <td style="white-space: normal; word-break: break-all;">
                                                    <p *ngIf="item.pregnancyPlan" [textContent]="item.pregnancyPlan "></p>
                                                    <p style="padding-left: 25px" *ngIf="!item.pregnancyPlan" [textContent]="'-'"></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div *ngIf="!isNurseDisplay">
                        <div class="dashboard dashboard-table" #procedureSection *ngIf="procedureForm && procedureForm !== null">
                            <h4 class="dashboard-title font-weight-bold">OP Procedure</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <tr *ngIf="procedureForm.date">
                                            <td class="col-8">Date</td>
                                            <td class="col-4" [textContent]="procedureForm.date | date:'dd-MM-yyyy'"> </td>
                                        </tr>
                                        <tr *ngIf="procedureForm.partnersName">
                                            <td class="col-8">PartnersName</td>
                                            <td class="col-4" [textContent]="procedureForm.partnersName"> </td>
                                        </tr>
                                        <tr *ngIf="procedureForm.consultant">
                                            <td class="col-8">Consultant</td>
                                            <td class="col-4" [textContent]="procedureForm.consultant"> </td>
                                        </tr>
                                        <tr *ngIf="procedureForm.specialization">
                                            <td class="col-8">Specialization</td>
                                            <td class="col-4" [textContent]="procedureForm.specialization"> </td>
                                        </tr>
                                        <tr *ngIf="procedureForm.procedure">
                                            <td class="col-8">Procedure</td>
                                            <td class="col-4" [textContent]="procedureForm.procedure"> </td>
                                        </tr>
                                        <tr *ngIf="procedureForm.team">
                                            <td class="col-8">Team</td>
                                            <td class="col-4" [textContent]="procedureForm.team"> </td>
                                        </tr>
                                        <tr *ngIf="procedureForm.indication">
                                            <td class="col-8">Indication</td>
                                            <td class="col-4" [textContent]="procedureForm.indication"> </td>
                                        </tr>
                                        <tr *ngIf="procedureForm.lmp">
                                            <td class="col-8">Lmp</td>
                                            <td class="col-4" [textContent]="procedureForm.lmp"> </td>
                                        </tr>
                                        <tr *ngIf="procedureForm.finding">
                                            <td class="col-8">Finding</td>
                                            <td class="col-4" [textContent]="procedureForm.finding"> </td>
                                        </tr>
                                        <tr *ngIf="procedureForm.analgesia">
                                            <td class="col-8">Analgesia</td>
                                            <td class="col-4" [textContent]="procedureForm.analgesia"> </td>
                                        </tr>
                                        <tr *ngIf="procedureForm.antibiotics">
                                            <td class="col-8">Antibiotics</td>
                                            <td class="col-4" [textContent]="procedureForm.antibiotics"> </td>
                                        </tr>
                                        <tr *ngIf="procedureForm.labSamples">
                                            <td class="col-8">LabSamples</td>
                                            <td class="col-4" [textContent]="procedureForm.labSamples"> </td>
                                        </tr>
                                        <tr *ngIf="procedureForm.abandoned">
                                            <td class="col-8">Abandoned</td>
                                            <td class="col-4" [textContent]="procedureForm.abandoned"> </td>
                                        </tr>
                                        <tr *ngIf="procedureForm.reason">
                                            <td class="col-8">Reason</td>
                                            <td class="col-4" [textContent]="procedureForm.reason"> </td>
                                        </tr>
                                        <tr *ngIf="procedureForm.duration">
                                            <td class="col-8">Duration</td>
                                            <td class="col-4" [textContent]="procedureForm.duration"> </td>
                                        </tr>
                                        <tr *ngIf="procedureForm.painScore">
                                            <td class="col-8">PainScore</td>
                                            <td class="col-4" [textContent]="procedureForm.painScore"> </td>
                                        </tr>
                                        <tr *ngIf="procedureForm.remarks">
                                            <td class="col-8">Remarks</td>
                                            <td class="col-4" [textContent]="procedureForm.remarks"> </td>
                                        </tr>
                                        <tr *ngIf="procedureForm.review">
                                            <td class="col-8">Review</td>
                                            <td class="col-4" [textContent]="procedureForm.review"> </td>
                                        </tr>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div *ngIf="roleId != 63">
                        <div class="dashboard dashboard-table" #allergiesSection *ngIf="reminders && reminders !== null">
                            <h4 class="dashboard-title font-weight-bold">Reminders</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div class="row col-12" *ngIf="reminders && reminders !== null">
                                        <div class="row col-12">
                                            <div *ngIf="reminders && reminders==null">
                                                There is no reminders
                                            </div>
                                        </div>
                                        <div class="col-12" *ngIf="reminders && reminders !== null">
                                            <div class="table-responsive horizontal-scroll">
                                                <table class="table table-sm mb-0">
                                                    <tr *ngIf="reminders">
                                                        <td class="col-8">Reminders</td>
                                                        <td class="col-4">
                                                            <span class=" text-wrap" [textContent]="reminders"></span><br />
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="roleId != 63">
                        <div class="dashboard dashboard-table" *ngIf="addendumForm && addendumForm !== null">
                            <h4 class="dashboard-title font-weight-bold">Addedndum Form</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <tr *ngIf="addendumForm.date">
                                            <td class="col-8">Date</td>
                                            <td class="col-4" [textContent]="addendumForm.date  | date: 'dd/MM/yyyy'"> </td>
                                        </tr>
                                        <tr *ngIf="addendumForm.time">
                                            <td class="col-8">Time</td>
                                            <td class="col-4" [textContent]="addendumForm.time"> </td>
                                        </tr>
                                        <tr *ngIf="addendumForm.note">
                                            <td class="col-8">Note</td>
                                            <td class="col-4" [textContent]="addendumForm.note"> </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="dashboard dashboard-table" #admissiondetailsSection *ngIf="admissionDetails && admissionDetails !== null">
                        <h4 class="dashboard-title font-weight-bold">Admission Details </h4>
                        <div class="dashboard-body">
                            <div class="overflow-auto">
                                <h6 class="font-18 mt-0 mb-2">Obstetric Admission Info</h6>
                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                    <tr *ngIf="admissionDetails.casetype">
                                        <td class="col-8">Case Type</td>
                                        <td class="col-4" [textContent]="admissionDetails.casetype"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.mrno">
                                        <td class="col-8">MR No</td>
                                        <td class="col-4" [textContent]="admissionDetails.mrno"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.name">
                                        <td class="col-8">Name</td>
                                        <td class="col-4" [textContent]="admissionDetails.name"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.husbandName">
                                        <td class="col-8">Husband Name</td>
                                        <td class="col-4" [textContent]="admissionDetails.husbandName"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.ancno">
                                        <td class="col-8">ANCNO</td>
                                        <td class="col-4" [textContent]="admissionDetails.ancno"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.ipNo">
                                        <td class="col-8">IP No</td>
                                        <td class="col-4" [textContent]="admissionDetails.ipNo"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.dateOfAdmission">
                                        <td class="col-8">DateOfAdmission</td>
                                        <td class="col-4" [textContent]="admissionDetails.dateOfAdmission | date:'dd/MM/yyyy'"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.admnTime">
                                        <td class="col-8">ADMN Time</td>
                                        <td class="col-4" [textContent]="admissionDetails.admnTime"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.roomNo">
                                        <td class="col-8">roomNo</td>
                                        <td class="col-4" [textContent]="admissionDetails.roomNo"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.examinationTime">
                                        <td class="col-8">Examination Time</td>
                                        <td class="col-4" [textContent]="admissionDetails.examinationTime"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.complaints && admissionDetails.complaints.length >0">
                                        <td colspan="2">
                                            <div class="row">
                                                <div class="col-12">
                                                    <h6 class="font-18 mt-0 mb-1">Complaints</h6>
                                                    <ul class="dashboard-ul row">
                                                        <li *ngFor="let item of admissionDetails.complaints" class="col-lg-6 col-md-10 col-sm-14" [textContent]="item"></li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </td>



                                    </tr>
                                    <tr *ngIf="admissionDetails.otherComplaints">
                                        <td class="col-8">Other Complaints</td>
                                        <td class="col-4" [textContent]="admissionDetails.otherComplaints"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.historyOfPresentIllness">
                                        <td class="col-8">History Of PresentIllness</td>
                                        <td class="col-4" [textContent]="admissionDetails.historyOfPresentIllness"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.yearOfMarriage">
                                        <td class="col-8">Year Of Marriage</td>
                                        <td class="col-4" [textContent]="admissionDetails.yearOfMarriage"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.para">
                                        <td class="col-8">Para</td>
                                        <td class="col-4" [textContent]="admissionDetails.para"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.living">
                                        <td class="col-8">Living</td>
                                        <td class="col-4" [textContent]="admissionDetails.living"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.abortion">
                                        <td class="col-8">Abortion</td>
                                        <td class="col-4" [textContent]="admissionDetails.abortion"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.lmp">
                                        <td class="col-8">LMP</td>
                                        <td class="col-4" [textContent]="admissionDetails.lmp"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.edd">
                                        <td class="col-8">EDD</td>
                                        <td class="col-4" [textContent]="admissionDetails.edd"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.ga">
                                        <td class="col-8">GA</td>
                                        <td class="col-4" [textContent]="admissionDetails.ga"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.scanedd">
                                        <td class="col-8">Scan EDD</td>
                                        <td class="col-4" [textContent]="admissionDetails.scanedd | date:'dd/MM/yyyy'"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-1">General Examination</h6>
                                    </tr>
                                    <tr *ngIf="admissionDetails.heightInFeet">
                                        <td class="col-8">Height In Feet</td>
                                        <td class="col-4" [textContent]="admissionDetails.heightInFeet"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.weightInKg">
                                        <td class="col-8">Weight In Kg</td>
                                        <td class="col-4" [textContent]="admissionDetails.weightInKg"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.bmi">
                                        <td class="col-8">BMI</td>
                                        <td class="col-4" [textContent]="admissionDetails.bmi"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.systolicbp">
                                        <td class="col-8">Systolic BP</td>
                                        <td class="col-4" [textContent]="admissionDetails.systolicbp"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.diastolicbp">
                                        <td class="col-8">Diastolic BP</td>
                                        <td class="col-4" [textContent]="admissionDetails.diastolicbp"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.pulserate">
                                        <td class="col-8">Pulse Rate</td>
                                        <td class="col-4" [textContent]="admissionDetails.pulserate"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.respiratoryrate">
                                        <td class="col-8">Respiratory Rate</td>
                                        <td class="col-4" [textContent]="admissionDetails.respiratoryrate"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.temperature">
                                        <td class="col-8">Temperature</td>
                                        <td class="col-4" [textContent]="admissionDetails.temperature"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.spo2">
                                        <td class="col-8">SpO2</td>
                                        <td class="col-4" [textContent]="admissionDetails.spo2"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.conscious">
                                        <td class="col-8">Conscious</td>
                                        <td class="col-4" [textContent]="admissionDetails.conscious"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.shock">
                                        <td class="col-8">Shock</td>
                                        <td class="col-4" [textContent]="admissionDetails.shock"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.pallor">
                                        <td class="col-8">Pallor</td>
                                        <td class="col-4" [textContent]="admissionDetails.pallor"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.icterus">
                                        <td class="col-8">Icterus</td>
                                        <td class="col-4" [textContent]="admissionDetails.icterus"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.oedema">
                                        <td class="col-8">Oedema</td>
                                        <td class="col-4" [textContent]="admissionDetails.oedema"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.spleen">
                                        <td class="col-8">Spleen</td>
                                        <td class="col-4" [textContent]="admissionDetails.spleen"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.liver">
                                        <td class="col-8">Liver</td>
                                        <td class="col-4" [textContent]="admissionDetails.liver"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.reflex">
                                        <td class="col-8">Reflex</td>
                                        <td class="col-4" [textContent]="admissionDetails.reflex"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.cardiovasc">
                                        <td class="col-8">Cardiovasc</td>
                                        <td class="col-4" [textContent]="admissionDetails.cardiovasc"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.respsystem">
                                        <td class="col-8">Resp system</td>
                                        <td class="col-4" [textContent]="admissionDetails.respsystem"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.urineoutput">
                                        <td class="col-8">Urine Output</td>
                                        <td class="col-4" [textContent]="admissionDetails.urineoutput"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.urineAlbumin">
                                        <td class="col-8">Urine Albumin</td>
                                        <td class="col-4" [textContent]="admissionDetails.urineAlbumin"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-1">Obstetric Examination</h6>
                                    </tr>
                                    <tr *ngIf="admissionDetails.utcontr">
                                        <td class="col-8">U T Contr</td>
                                        <td class="col-4" [textContent]="admissionDetails.utcontr"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.pp">
                                        <td class="col-8">P P</td>
                                        <td class="col-4" [textContent]="admissionDetails.pp"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.fundalht">
                                        <td class="col-8">Fundal HT</td>
                                        <td class="col-4" [textContent]="admissionDetails.fundalht"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.ppabd">
                                        <td class="col-8">P P Abd</td>
                                        <td class="col-4" [textContent]="admissionDetails.ppabd"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.liqr">
                                        <td class="col-8">Liqr</td>
                                        <td class="col-4" [textContent]="admissionDetails.liqr"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.fhs">
                                        <td class="col-8">FHS</td>
                                        <td class="col-4" [textContent]="admissionDetails.fhs"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.fhr">
                                        <td class="col-8">FHR</td>
                                        <td class="col-4" [textContent]="admissionDetails.fhr"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.psexam">
                                        <td class="col-8">P/ S Exam</td>
                                        <td class="col-4" [textContent]="admissionDetails.psexam"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.draining">
                                        <td class="col-8">Draining</td>
                                        <td class="col-4" [textContent]="admissionDetails.draining"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Obstetric Vaginal Examination</h6>
                                    </tr>
                                    <tr *ngIf="admissionDetails.cervix">
                                        <td class="col-8">Cervix</td>
                                        <td class="col-4" [textContent]="admissionDetails.cervix"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.membranes">
                                        <td class="col-8">Membranes</td>
                                        <td class="col-4" [textContent]="admissionDetails.membranes"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.ppvag">
                                        <td class="col-8">PP Vag</td>
                                        <td class="col-4" [textContent]="admissionDetails.ppvag"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.station">
                                        <td class="col-8">Station</td>
                                        <td class="col-4" [textContent]="admissionDetails.station"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.cervicaloscm">
                                        <td class="col-8">Cervical OS Cm</td>
                                        <td class="col-4" [textContent]="admissionDetails.cervicaloscm"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.pelvis">
                                        <td class="col-8">Pelvis</td>
                                        <td class="col-4" [textContent]="admissionDetails.pelvis"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.drugallergies">
                                        <td class="col-8">Drug Allergies</td>
                                        <td class="col-4" [textContent]="admissionDetails.drugallergies"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.diagnosis">
                                        <td class="col-8">Diagnosis</td>
                                        <td class="col-4" [textContent]="admissionDetails.diagnosis"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.diagnosisDetails">
                                        <td class="col-8">Diagnosis Details	</td>
                                        <td class="col-4" [textContent]="admissionDetails.diagnosisDetails"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">VBAC (Applicable For One Previous CS Only)</h6>
                                    </tr>
                                    <tr *ngIf="admissionDetails.issheapreviousonecs">
                                        <td class="col-8">Is She A Previous One CS</td>
                                        <td class="col-4" [textContent]="admissionDetails.issheapreviousonecs"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.vbacformgiven">
                                        <td class="col-8">VBAC Form Given</td>
                                        <td class="col-4" [textContent]="admissionDetails.vbacformgiven"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.acceptance">
                                        <td class="col-8">Acceptance</td>
                                        <td class="col-4" [textContent]="admissionDetails.acceptance"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.plan">
                                        <td class="col-8">Plan</td>
                                        <td class="col-4" [textContent]="admissionDetails.plan"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.ifiol">
                                        <td class="col-8">If IOL</td>
                                        <td class="col-4" [textContent]="admissionDetails.ifiol"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Other Details</h6>
                                    </tr>
                                    <tr *ngIf="admissionDetails.counselling">
                                        <td class="col-8">Counselling</td>
                                        <td class="col-4" [textContent]="admissionDetails.counselling"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.consultantopinion">
                                        <td class="col-8">Consultant Opinion</td>
                                        <td class="col-4" [textContent]="admissionDetails.consultantopinion"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.consultantinformed">
                                        <td class="col-8">Consultant informed</td>
                                        <td class="col-4" [textContent]="admissionDetails.consultantinformed"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.date">
                                        <td class="col-8">Date</td>
                                        <td class="col-4" [textContent]="admissionDetails.date | date:'dd/MM/yyyy'"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.time">
                                        <td class="col-8">TIME</td>
                                        <td class="col-4" [textContent]="admissionDetails.time"> </td>
                                    </tr>
                                    <tr *ngIf="admissionDetails.consultant">
                                        <td class="col-8">Consultant</td>
                                        <td class="col-4" [textContent]="admissionDetails.consultant"> </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="dashboard dashboard-table" #lscsSection *ngIf="lscsmodel && lscsmodel !== null">
                        <h4 class="dashboard-title font-weight-bold"> LSCS</h4>
                        <div class="dashboard-body">
                            <div class="overflow-auto">

                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">

                                    <tr *ngIf="lscsmodel.roomNO">
                                        <td class="col-8">RoomNO</td>
                                        <td class="col-4" [textContent]="lscsmodel.roomNO"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.deliveryDate">
                                        <td class="col-8">Delivery Date </td>
                                        <td class="col-4" [textContent]="lscsmodel.deliveryDate |  date: 'dd/MM/yyyy'"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.deliveryTime">
                                        <td class="col-8">Delivery Time</td>
                                        <td class="col-4" [textContent]="lscsmodel.deliveryTime"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.surgeonName || lscsmodel.nurse || lscsmodel.anaesthetist  || lscsmodel.paediatrician || lscsmodel.asstSurgeon">

                                        <h6 class="font-18 mt-0 mb-2">SURGICAL TEAM</h6>

                                    </tr>

                                    <tr *ngIf="lscsmodel.surgeonName">
                                        <td class="col-8">Surgeon Name</td>
                                        <td class="col-4" [textContent]="lscsmodel.surgeonName"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.asstSurgeon">
                                        <td class="col-8">Asst.Surgeon</td>
                                        <td class="col-4" [textContent]="lscsmodel.asstSurgeon"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.nurse">
                                        <td class="col-8">Nurse</td>
                                        <td class="col-4" [textContent]="lscsmodel.nurse"> </td>

                                    </tr>
                                    <tr *ngIf="lscsmodel.anaesthetist">
                                        <td class="width-fixed">Anaesthetist</td>
                                        <td class="col-4" [textContent]="lscsmodel.anaesthetist"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.paediatrician">
                                        <td class="col-8">Paediatrician</td>
                                        <td class="col-4" [textContent]="lscsmodel.paediatrician"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.type || lscsmodel.anaesthesia || lscsmodel.primaryIndication  || lscsmodel.otherComment || lscsmodel.contributingFactors">

                                        <h6 class="font-18 mt-0 mb-2">TYPE OF CS</h6>

                                    </tr>

                                    <tr *ngIf="lscsmodel.type">
                                        <td class="col-8">Type</td>
                                        <td class="col-4" [textContent]="lscsmodel.type"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.labourAnalgesia">
                                        <td class="col-8">Labour Analgesia</td>
                                        <td class="col-4" [textContent]="lscsmodel.labourAnalgesia"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.anaesthesia">
                                        <td class="col-8">Anaesthesia</td>
                                        <td class="col-4" [textContent]="lscsmodel.anaesthesia"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.primaryIndication">
                                        <td class="col-8">Primary Indication</td>
                                        <td class="col-4">
                                            <ul class="dashboard-ul row">
                                                <li *ngFor="let item of  lscsmodel.primaryIndication" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.otherComment">
                                        <td class="col-8"> Other Comment</td>
                                        <td class="col-4" [textContent]="lscsmodel.otherComment"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.contributingFactors">
                                        <td class="col-8">Contributing Factors</td>
                                        <td class="col-4" [textContent]="lscsmodel.contributingFactors"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.categoryName || lscsmodel.decisionToDeliveryTime">

                                        <h6 class="font-18 mt-0 mb-2"> Urgency Of Cs (NCEPOD)</h6>

                                    </tr>


                                    <tr *ngIf="lscsmodel.categoryName">
                                        <td class="col-8">Category Name</td>
                                        <td class="col-4" [textContent]="lscsmodel.categoryName "> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.decisionToDeliveryTime">
                                        <td class="col-8">Decision To Delivery Time</td>
                                        <td class="col-4" [textContent]="lscsmodel.decisionToDeliveryTime"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.fetus || lscsmodel.presentation || lscsmodel.termtype  || lscsmodel.cephalictype || lscsmodel.nulliparitytype || lscsmodel.robsonValue">

                                        <h6 class="font-18 mt-0 mb-2">Robson Classification</h6>

                                    </tr>

                                    <tr *ngIf="lscsmodel.fetus">
                                        <td class="col-8">Fetus</td>
                                        <td class="col-4" [textContent]="lscsmodel.fetus"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.presentation">
                                        <td class="col-8">Presentation</td>
                                        <td class="col-4" [textContent]="lscsmodel.presentation"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.termtype">
                                        <td class="col-8">Termtype</td>
                                        <td class="col-4" [textContent]="lscsmodel.termtype"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.cephalictype">
                                        <td class="col-8">Cephalictype</td>
                                        <td class="col-4" [textContent]="lscsmodel.cephalictype "> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.nulliparitytype">
                                        <td class="col-8">Nulliparity type</td>
                                        <td class="col-4" [textContent]="lscsmodel.nulliparitytype"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.robsonValue">
                                        <td class="col-8">Robson Value</td>
                                        <td class="col-4" [textContent]="lscsmodel.robsonValue"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.labour || lscsmodel.iOLAgent || lscsmodel.indicationsForIOLs || lscsmodel.rupture ">

                                        <h6 class="font-18 mt-0 mb-2"> Labour </h6>

                                    </tr>

                                    <tr *ngIf="lscsmodel.labour">
                                        <td class="col-8">Labour</td>
                                        <td class="col-4" [textContent]="lscsmodel.labour"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.iOLAgent">
                                        <td class="col-8">IOLAgent</td>
                                        <td class="col-4" [textContent]="lscsmodel.iOLAgent"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.indicationsForIOLs">
                                        <td class="col-8">Indications For IOLs</td>
                                        <td class="col-4" [textContent]="lscsmodel.indicationsForIOLs"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.rupture">
                                        <td class="col-8">Rupture</td>
                                        <td class="col-4" [textContent]="lscsmodel.rupture "> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.abdominalIncision || lscsmodel.adhesions || lscsmodel.ifYesAdhesions  || lscsmodel.segmentCondition || lscsmodel.scarIntegrity || lscsmodel.uterineIncision

                           || lscsmodel.placentaSite || lscsmodel.placentaPosition  || lscsmodel.verticalUterineTear || lscsmodel.ifYesUterineTear || lscsmodel.uterineVesselInvolved
                            || lscsmodel.ifUterineVesselInvolved  || lscsmodel.additionalProcedures || lscsmodel.otherprocedures ">

                                        <h6 class="font-18 mt-0 mb-2">PROCEDURE</h6>

                                    </tr>

                                    <tr *ngIf="lscsmodel.abdominalIncision">
                                        <td class="col-8">Abdominal Incision</td>
                                        <td class="col-4" [textContent]="lscsmodel.abdominalIncision"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.adhesions">
                                        <td class="col-8">Adhesions</td>
                                        <td class="col-4" [textContent]="lscsmodel.adhesions"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.ifYesAdhesions">
                                        <td class="col-8">If Yes Adhesions</td>
                                        <td class="col-4" [textContent]="lscsmodel.ifYesAdhesions"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.segmentCondition">
                                        <td class="col-8">Segment Condition</td>
                                        <td class="col-4" [textContent]="lscsmodel.segmentCondition"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.scarIntegrity">
                                        <td class="col-8">Scar Integrity</td>
                                        <td class="col-4" [textContent]="lscsmodel.scarIntegrity"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.uterineIncision">
                                        <td class="col-8">Uterine Incision</td>
                                        <td class="col-4" [textContent]="lscsmodel.uterineIncision"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.placentaSite">
                                        <td class="col-8">Placenta Site</td>
                                        <td class="col-4" [textContent]="lscsmodel.placentaSite"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.placentaPosition">
                                        <td class="col-8">Placenta Position</td>
                                        <td class="col-4" [textContent]="lscsmodel.placentaPosition"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.verticalUterineTear">
                                        <td class="col-8">Vertical Uterine Tear</td>
                                        <td class="col-4" [textContent]="lscsmodel.verticalUterineTear"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.ifYesUterineTear">
                                        <td class="col-8">If Yes Uterine Tear</td>
                                        <td class="col-4" [textContent]="lscsmodel.ifYesUterineTear"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.uterineVesselInvolved">
                                        <td class="col-8">Uterine Vessel Involved</td>
                                        <td class="col-4" [textContent]="lscsmodel.uterineVesselInvolved"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.ifUterineVesselInvolved">
                                        <td class="col-8">If Uterine Vessel Involved</td>
                                        <td class="col-4" [textContent]="lscsmodel.ifUterineVesselInvolved"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.additionalProcedures">
                                        <td class="col-8">Additional Procedures</td>
                                        <td class="col-4" [textContent]="lscsmodel.additionalProcedures"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.otherprocedures">
                                        <td class="col-8">Other Procedures</td>
                                        <td class="col-4" [textContent]="lscsmodel.otherprocedures"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pph || lscsmodel.ifpphyes || lscsmodel.estBloodLoss ">
                                        <h6 class="font-18 mt-0 mb-2">COMPLICATIONS</h6>
                                    </tr>

                                    <tr *ngIf="lscsmodel.pph">
                                        <td class="col-8">PPH</td>
                                        <td class="col-4" [textContent]="lscsmodel.pph"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.ifpphyes">
                                        <td class="col-8">If pphyes</td>
                                        <td class="col-4" [textContent]="lscsmodel.ifpphyes"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.estBloodLoss">
                                        <td class="col-8">Est Blood Loss</td>
                                        <td class="col-4" [textContent]="lscsmodel.estBloodLoss"> </td>
                                    </tr>

                                    <tr *ngIf="lscsmodel.uterineClosure || lscsmodel.skinClosureType || lscsmodel.drain ">
                                        <h6 class="font-18 mt-0 mb-2">CLOSURE</h6>
                                    </tr>

                                    <tr *ngIf="lscsmodel.uterineClosure">
                                        <td class="col-8">Uterine Closure</td>
                                        <td class="col-4" [textContent]="lscsmodel.uterineClosure"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.skinClosureType">
                                        <td class="col-8">Skin Closure Type</td>
                                        <td class="col-4" [textContent]="lscsmodel.skinClosureType"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.drain">
                                        <td class="col-8">Drain</td>
                                        <td class="col-4" [textContent]="lscsmodel.drain"> </td>
                                    </tr>

                                    <tr *ngIf="lscsmodel.intraperitonel">
                                        <td class="col-8">Intraperitonel</td>
                                        <td class="col-4" [textContent]="lscsmodel.intraperitonel"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.subcutaneous">
                                        <td class="col-8">Subcutaneous</td>
                                        <td class="col-4" [textContent]="lscsmodel.subcutaneous"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.sMUuterineClosure || lscsmodel.tubalLigation || lscsmodel.rectusSheath || lscsmodel.skinClosure ">
                                        <h6 class="font-18 mt-0 mb-2">Suture Material Used</h6>
                                    </tr>

                                    <tr *ngIf="lscsmodel.sMUuterineClosure">
                                        <td class="col-8">SMUuterine Closure</td>
                                        <td class="col-4" [textContent]="lscsmodel.sMUuterineClosure"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.tubalLigation">
                                        <td class="col-8">Tubal Ligation</td>
                                        <td class="col-4" [textContent]="lscsmodel.tubalLigation"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.rectusSheath">
                                        <td class="col-8">Rectus Sheath</td>
                                        <td class="col-4" [textContent]="lscsmodel.rectusSheath"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.skinClosure">
                                        <td class="col-8">SkinClosure</td>
                                        <td class="col-4" [textContent]="lscsmodel.skinClosure"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.rTOvary || lscsmodel.rTTube || lscsmodel.lTOvary || lscsmodel.lTTube  || lscsmodel.fibroids || lscsmodel.uterineAnomaly || lscsmodel.tubalLigationMethod
                            || lscsmodel.bladderCatheterized  || lscsmodel.swabs || lscsmodel.instruments || lscsmodel.placenta || lscsmodel.placentaWeight || lscsmodel.placentaWeight">
                                        <h6 class="font-18 mt-0 mb-2">Other Findings</h6>
                                    </tr>

                                    <tr *ngIf="lscsmodel.rTOvary">
                                        <td class="col-8">RTOvary</td>
                                        <td class="col-4" [textContent]="lscsmodel.rTOvary"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.cystTumorSize">
                                        <td class="col-8">Cyst TumorSize</td>
                                        <td class="col-4" [textContent]="lscsmodel.cystTumorSize"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.rTTube">
                                        <td class="col-8">RTTube</td>
                                        <td class="col-4" [textContent]="lscsmodel.rTTube"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.lTOvary">
                                        <td class="col-8">LTOvary</td>
                                        <td class="col-4" [textContent]="lscsmodel.lTOvary"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.cystTumSize">
                                        <td class="col-8">CystTumSize</td>
                                        <td class="col-4" [textContent]="lscsmodel.cystTumSize"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.lTTube">
                                        <td class="col-8">LTTube</td>
                                        <td class="col-4" [textContent]="lscsmodel.lTTube"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.fibroids">
                                        <td class="col-8">Fibroids</td>
                                        <td class="col-4" [textContent]="lscsmodel.fibroids"> </td>
                                    </tr>

                                    <tr *ngIf="lscsmodel.typeOfFibroid">
                                        <td class="col-8">Type Of Fibroid</td>
                                        <td class="col-4" [textContent]="lscsmodel.typeOfFibroid"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.noOfFibroid">
                                        <td class="col-8">No Of Fibroid</td>
                                        <td class="col-4" [textContent]="lscsmodel.noOfFibroid"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.sizeOfFibroid">
                                        <td class="col-8">Size Of Fibroid</td>
                                        <td class="col-4" [textContent]="lscsmodel.sizeOfFibroid"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.oFtubalLigation">
                                        <td class="col-8">OFtubalLigation</td>
                                        <td class="col-4" [textContent]="lscsmodel.oFtubalLigation"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.uterineAnomaly">
                                        <td class="col-8">Uterine Anomaly</td>
                                        <td class="col-4" [textContent]="lscsmodel.uterineAnomaly"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.tubalLigationMethod">
                                        <td class="col-8">Tubal Ligation Method</td>
                                        <td class="col-4" [textContent]="lscsmodel.tubalLigationMethod"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.sentForHPE">
                                        <td class="col-8">Sent For HPE</td>
                                        <td class="col-4" [textContent]="lscsmodel.sentForHPE"> </td>
                                    </tr>



                                    <tr *ngIf="lscsmodel.bladderCatheterized">
                                        <td class="col-8">Bladder Catheterized</td>
                                        <td class="col-4" [textContent]="lscsmodel.bladderCatheterized"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.swabs">
                                        <td class="col-8">Swabs</td>
                                        <td class="col-4" [textContent]="lscsmodel.swabs"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.instruments">
                                        <td class="col-8">Instruments</td>
                                        <td class="col-4" [textContent]="lscsmodel.instruments"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.placenta">
                                        <td class="col-8">Placenta</td>
                                        <td class="col-4" [textContent]="lscsmodel.placenta"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.placentaWeight">
                                        <td class="col-8">Placenta Weight</td>
                                        <td class="col-4" [textContent]="lscsmodel.placentaWeight"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.placentaHPE">
                                        <td class="col-8">Placenta HPE</td>
                                        <td class="col-4" [textContent]="lscsmodel.placentaHPE"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.shiftingIntoOT || lscsmodel.startOfAnaesthesia || lscsmodel.antibioticTotalDoseGivenTime || lscsmodel.startOfSurgery
                            || lscsmodel.endOfProcedure || lscsmodel.prophylacticOxytocin || lscsmodel.shiftingOutOfOT || lscsmodel.othernotes">
                                        <h6 class="font-18 mt-0 mb-2">Time Tracking- Time Of</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.shiftingIntoOT">
                                        <td class="col-8">Shifting Into OT</td>
                                        <td class="col-4" [textContent]="lscsmodel.shiftingIntoOT"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.startOfAnaesthesia">
                                        <td class="col-8">Start Of Anaesthesia</td>
                                        <td class="col-4" [textContent]="lscsmodel.startOfAnaesthesia"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.antibioticTotalDoseGivenTime">
                                        <td class="col-8">Antibiotic Total Dose Given Time</td>
                                        <td class="col-4" [textContent]="lscsmodel.antibioticTotalDoseGivenTime"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.startOfSurgery">
                                        <td class="col-8">Start Of Surgery</td>
                                        <td class="col-4" [textContent]="lscsmodel.startOfSurgery"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.endOfProcedure">
                                        <td class="col-8">End Of Procedure</td>
                                        <td class="col-4" [textContent]="lscsmodel.endOfProcedure"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.prophylacticOxytocin">
                                        <td class="col-8">Prophylactic Oxytocin</td>
                                        <td class="col-4" [textContent]="lscsmodel.prophylacticOxytocin"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.shiftingOutOfOT">
                                        <td class="col-8">Shifting Out Of OT</td>
                                        <td class="col-4" [textContent]="lscsmodel.shiftingOutOfOT"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.othernotes">
                                        <td class="col-8">Othernotes</td>
                                        <td class="col-4" [textContent]="lscsmodel.othernotes"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.noOfBabies">
                                        <h6 class="font-18 mt-0 mb-2">Baby Details</h6>
                                    </tr>

                                    <tr *ngIf="lscsmodel.noOfBabies">
                                        <td class="col-8">No Of Babies</td>
                                        <td class="col-4" [textContent]="lscsmodel.noOfBabies"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.birthOrder1 || lscsmodel.babyPresentation1 || lscsmodel.presentingPartPosition1 || lscsmodel.amnioticFluidColour1 || lscsmodel.quantity1 || lscsmodel.forceps1 || lscsmodel.fetalBloodSampling1 ||lscsmodel.scalpLactateValue1
                            || lscsmodel.skinToSkinBabyCare1 || lscsmodel.skinToSkinBabyCareDetails1 || lscsmodel.cordClamping1 || lscsmodel.ifImmediateDtl1 || lscsmodel.clampingTime1 || lscsmodel.cordABG1 || lscsmodel.modeOfDelivery1 ||  lscsmodel.sexOfBaby1 || lscsmodel.statusOfBaby1
                            || lscsmodel.babyBirthDate1 || lscsmodel.babyBirthTime1 || lscsmodel.babyBirthWt1 || lscsmodel.apgar1 ">
                                        <h6 class="font-18 mt-0 mb-2">FIRST BABY DETAILS</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.birthOrder1">
                                        <td class="col-8">BirthOrder</td>
                                        <td class="col-4" [textContent]="lscsmodel.birthOrder1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.babyPresentation1">
                                        <td class="col-8">Baby Presentation</td>
                                        <td class="col-4" [textContent]="lscsmodel.babyPresentation1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.presentingPartPosition1">
                                        <td class="col-8">Presenting Part Position</td>
                                        <td class="col-4" [textContent]="lscsmodel.presentingPartPosition1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.amnioticFluidColour1">
                                        <td class="col-8">Amniotic Fluid Colour</td>
                                        <td class="col-4" [textContent]="lscsmodel.amnioticFluidColour1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.quantity1">
                                        <td class="col-8">Quantity</td>
                                        <td class="col-4" [textContent]="lscsmodel.quantity1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.forceps1">
                                        <td class="col-8">Forceps</td>
                                        <td class="col-4" [textContent]="lscsmodel.forceps1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.fetalBloodSampling1">
                                        <td class="col-8">Fetal Blood Sampling</td>
                                        <td class="col-4" [textContent]="lscsmodel.fetalBloodSampling1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.scalpLactateValue1">
                                        <td class="col-8">Scalp Lactate Value</td>
                                        <td class="col-4" [textContent]="lscsmodel.scalpLactateValue1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.skinToSkinBabyCare1">
                                        <td class="col-8">Skin To Skin Baby Care</td>
                                        <td class="col-4" [textContent]="lscsmodel.skinToSkinBabyCare1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.skinToSkinBabyCareDetails1">
                                        <td class="col-8">Skin To Skin Baby Care Details</td>
                                        <td class="col-4" [textContent]="lscsmodel.skinToSkinBabyCareDetails1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.cordClamping1">
                                        <td class="col-8">Cord Clamping</td>
                                        <td class="col-4" [textContent]="lscsmodel.cordClamping1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.ifImmediateDtl1">
                                        <td class="col-8">If ImmediateDtl</td>
                                        <td class="col-4" [textContent]="lscsmodel.ifImmediateDtl1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.clampingTime1">
                                        <td class="col-8">Clamping Time</td>
                                        <td class="col-4" [textContent]="lscsmodel.clampingTime1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.cordABG1">
                                        <td class="col-8">Cord ABG</td>
                                        <td class="col-4" [textContent]="lscsmodel.cordABG1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.date1">
                                        <td class="col-8">Date</td>
                                        <td class="col-4" [textContent]="lscsmodel.date1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.timeOfTesting1">
                                        <td class="col-8">Time Of Testing</td>
                                        <td class="col-4" [textContent]="lscsmodel.timeOfTesting1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel">
                                        <h6 class="font-18 mt-0 mb-2">Delivery And Advice</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.deliveryAndAdvice && lscsmodel.deliveryAndAdvice.length > 0">
                                        <td colspan="2">
                                            <div class="col-12">
                                                <div class="col-12">
                                                    <ul class="dashboard-ul row">
                                                        <li *ngFor="let item of  lscsmodel.deliveryAndAdvice" class="col-lg-4 col-md-8 col-sm-12" [textContent]="item"></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.indicationsForIOLs">
                                        <td class="col-8">Indications For IOL</td>
                                        <td class="col-4" [textContent]="lscsmodel.indicationsForIOLs"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pHArterial1 || lscsmodel.pHVenous1 ">
                                        <h6 class="font-18 mt-0 mb-2">PH</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pHArterial1">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="lscsmodel.pHArterial1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pHVenous1">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="lscsmodel.pHVenous1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.eCFArterial1 || lscsmodel.eCFVenous1 ">
                                        <h6 class="font-14 mt-0 mb-2">ECF</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.eCFArterial1">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="lscsmodel.eCFArterial1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.eCFVenous1">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="lscsmodel.eCFVenous1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.lactateArterial1 || lscsmodel.lactateVenous1 ">
                                        <h6 class="font-18 mt-0 mb-2">Lactate</h6>
                                    </tr>

                                    <tr *ngIf="lscsmodel.lactateArterial1">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="lscsmodel.lactateArterial1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.lactateVenous1">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="lscsmodel.lactateVenous1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pO2Arterial1 || lscsmodel.pO2Venous1 ">
                                        <h6 class="font-14 mt-0 mb-2">PO2</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pO2Arterial1">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="lscsmodel.pO2Arterial1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pO2Venous1">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="lscsmodel.pO2Venous1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pCO2Arterial1 || lscsmodel.pCO2Venous1 ">
                                        <h6 class="font-18 mt-0 mb-2">PCO2</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pCO2Arterial1">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="lscsmodel.pCO2Arterial1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pCO2Venous1">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="lscsmodel.pCO2Venous1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.modeOfDelivery1">
                                        <td class="col-8">Mode Of Delivery</td>
                                        <td class="col-4" [textContent]="lscsmodel.modeOfDelivery1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.sexOfBaby1">
                                        <td class="col-8">Sex Of Baby</td>
                                        <td class="col-4" [textContent]="lscsmodel.sexOfBaby1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.statusOfBaby1">
                                        <td class="col-8">Status Of Baby</td>
                                        <td class="col-4" [textContent]="lscsmodel.statusOfBaby1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.babyBirthDate1">
                                        <td class="col-8">Baby Birth Date</td>
                                        <td class="col-4" [textContent]="lscsmodel.babyBirthDate1 |  date: 'dd/MM/yyyy'"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.babyBirthTime1">
                                        <td class="col-8">Baby Birth Time</td>
                                        <td class="col-4" [textContent]="lscsmodel.babyBirthTime1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.babyBirthWt1">
                                        <td class="col-8">Baby Birth Wt</td>
                                        <td class="col-4" [textContent]="lscsmodel.babyBirthWt1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.apgar1">
                                        <td class="col-8">Apgar</td>
                                        <td class="col-4" [textContent]="lscsmodel.apgar1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.birthOrder2 || lscsmodel.babyPresentation2 || lscsmodel.presentingPartPosition2 || lscsmodel.amnioticFluidColour2 || lscsmodel.quantity2 || lscsmodel.forceps2 || lscsmodel.fetalBloodSampling2 ||lscsmodel.scalpLactateValue2
                              || lscsmodel.skinToSkinBabyCare2 || lscsmodel.skinToSkinBabyCareDetails2 || lscsmodel.cordClamping2 || lscsmodel.ifImmediateDtl2 || lscsmodel.clampingTime2 || lscsmodel.cordABG2 || lscsmodel.modeOfDelivery2 ||  lscsmodel.sexOfBaby2 || lscsmodel.statusOfBaby2
                              || lscsmodel.babyBirthDate2 || lscsmodel.babyBirthTime2 || lscsmodel.babyBirthWt2 || lscsmodel.apgar2 ">
                                        <h6 class="font-18 mt-0 mb-2">SECOND BABY DETAILS</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.birthOrder2">
                                        <td class="col-8">Birth Order</td>
                                        <td class="col-4" [textContent]="lscsmodel.birthOrder2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.babyPresentation2">
                                        <td class="col-8">Baby Presentation</td>
                                        <td class="col-4" [textContent]="lscsmodel.babyPresentation2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.presentingPartPosition2">
                                        <td class="col-8">Presenting Part Position</td>
                                        <td class="col-4" [textContent]="lscsmodel.presentingPartPosition2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.amnioticFluidColour2">
                                        <td class="col-8">Amniotic Fluid Colour</td>
                                        <td class="col-4" [textContent]="lscsmodel.amnioticFluidColour2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.quantity2">
                                        <td class="col-8">Quantity</td>
                                        <td class="col-4" [textContent]="lscsmodel.quantity2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.forceps2">
                                        <td class="col-8">Forceps</td>
                                        <td class="col-4" [textContent]="lscsmodel.forceps2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.fetalBloodSampling2">
                                        <td class="col-8">Fetal Blood Sampling</td>
                                        <td class="col-4" [textContent]="lscsmodel.fetalBloodSampling2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.scalpLactateValue2">
                                        <td class="col-8">Scalp Lactate Value</td>
                                        <td class="col-4" [textContent]="lscsmodel.scalpLactateValue2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.skinToSkinBabyCare2">
                                        <td class="col-8">Skin To Skin Baby Care</td>
                                        <td class="col-4" [textContent]="lscsmodel.skinToSkinBabyCare2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.skinToSkinBabyCareDetails2">
                                        <td class="col-8">Skin To Skin Baby Care Details</td>
                                        <td class="col-4" [textContent]="lscsmodel.skinToSkinBabyCareDetails2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.cordClamping2">
                                        <td class="col-8">Cord Clamping</td>
                                        <td class="col-4" [textContent]="lscsmodel.cordClamping2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.ifImmediateDtl2">
                                        <td class="col-8">If Immediate Dtl</td>
                                        <td class="col-4" [textContent]="lscsmodel.ifImmediateDtl2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.clampingTime2">
                                        <td class="col-8">Clamping Time</td>
                                        <td class="col-4" [textContent]="lscsmodel.clampingTime2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.cordABG2">
                                        <td class="col-8">Cord ABG</td>
                                        <td class="col-4" [textContent]="lscsmodel.cordABG2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.date2">
                                        <td class="col-8">Date</td>
                                        <td class="col-4" [textContent]="lscsmodel.date2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.timeOfTesting2">
                                        <td class="col-8">Time Of Testing</td>
                                        <td class="col-4" [textContent]="lscsmodel.timeOfTesting2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pHArterial2 || lscsmodel.pHVenous2 ">
                                        <h6 class="font-14 mt-0 mb-2">PH</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pHArterial2">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="lscsmodel.pHArterial2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pHVenous2">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="lscsmodel.pHVenous2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.eCFArterial2 || lscsmodel.eCFVenous2 ">
                                        <h6 class="font-18 mt-0 mb-2">ECF</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.eCFArterial2">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="lscsmodel.eCFArterial2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.eCFVenous2">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="lscsmodel.eCFVenous2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.lactateArterial2 || lscsmodel.lactateVenous2 ">
                                        <h6 class="font-18 mt-0 mb-2">Lactate</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.lactateArterial2">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="lscsmodel.lactateArterial2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.lactateVenous2">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="lscsmodel.lactateVenous2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pO2Arterial2 || lscsmodel.pO2Venous2 ">
                                        <h6 class="font-14 mt-0 mb-2">PO2</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pO2Arterial2">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="lscsmodel.pO2Arterial2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pO2Venous2">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="lscsmodel.pO2Venous2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pCO2Arterial2 || lscsmodel.pCO2Venous2 ">
                                        <h6 class="font-14 mt-0 mb-2">PCO2</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pCO2Arterial2">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="lscsmodel.pCO2Arterial2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pCO2Venous2">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="lscsmodel.pCO2Venous2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.modeOfDelivery2">
                                        <td class="col-8">Mode Of Delivery</td>
                                        <td class="col-4" [textContent]="lscsmodel.modeOfDelivery2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.sexOfBaby2">
                                        <td class="col-8">Sex Of Baby</td>
                                        <td class="col-4" [textContent]="lscsmodel.sexOfBaby2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.statusOfBaby2">
                                        <td class="col-8">Status Of Baby</td>
                                        <td class="col-4" [textContent]="lscsmodel.statusOfBaby2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.babyBirthDate2">
                                        <td class="col-8">Baby Birth Date</td>
                                        <td class="col-4" [textContent]="lscsmodel.babyBirthDate2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.babyBirthTime2">
                                        <td class="col-8">Baby Birth Time</td>
                                        <td class="col-4" [textContent]="lscsmodel.babyBirthTime2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.babyBirthWt2">
                                        <td class="col-8">Baby Birth Wt</td>
                                        <td class="col-4" [textContent]="lscsmodel.babyBirthWt2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.apgar2">
                                        <td class="col-8">Apgar</td>
                                        <td class="col-4" [textContent]="lscsmodel.apgar2"> </td>
                                    </tr>

                                    <tr *ngIf="lscsmodel.deliveryAndAdvice2.length >0">
                                        <td class="col-8">Delivery And Advice</td>
                                        <td class="col-4">
                                            <ul class="dashboard-ul row">
                                                <li *ngFor="let item of  lscsmodel.deliveryAndAdvice2" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.birthOrder3 || lscsmodel.babyPresentation3 || lscsmodel.presentingPartPosition3 || lscsmodel.amnioticFluidColour3 || lscsmodel.quantity3 || lscsmodel.forceps3 || lscsmodel.fetalBloodSampling3 ||lscsmodel.scalpLactateValue3
                           || lscsmodel.skinToSkinBabyCare3 || lscsmodel.skinToSkinBabyCareDetails3 || lscsmodel.cordClamping3 || lscsmodel.ifImmediateDtl3 || lscsmodel.clampingTime3 || lscsmodel.cordABG3 || lscsmodel.modeOfDelivery3 ||  lscsmodel.sexOfBaby3 || lscsmodel.statusOfBaby3
                           || lscsmodel.babyBirthDate3 || lscsmodel.babyBirthTime3 || lscsmodel.babyBirthWt3 || lscsmodel.apgar3 ">
                                        <h6 class="font-18 mt-0 mb-2">THIRD BABY DETAILS</h6>
                                    </tr>

                                    <tr *ngIf="lscsmodel.birthOrder3">
                                        <td class="col-8">Birth Order</td>
                                        <td class="col-4" [textContent]="lscsmodel.birthOrder3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.babyPresentation3">
                                        <td class="col-8">Baby Presentation</td>
                                        <td class="col-4" [textContent]="lscsmodel.babyPresentation3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.presentingPartPosition3">
                                        <td class="col-8">Presenting Part Position</td>
                                        <td class="col-4" [textContent]="lscsmodel.presentingPartPosition3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.amnioticFluidColour3">
                                        <td class="col-8">Amniotic Fluid Colour</td>
                                        <td class="col-4" [textContent]="lscsmodel.amnioticFluidColour3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.quantity3">
                                        <td class="col-8">Quantity</td>
                                        <td class="col-4" [textContent]="lscsmodel.quantity3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.forceps3">
                                        <td class="col-8">Forceps</td>
                                        <td class="col-4" [textContent]="lscsmodel.forceps3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.fetalBloodSampling3">
                                        <td class="col-8">Fetal Blood Sampling</td>
                                        <td class="col-4" [textContent]="lscsmodel.fetalBloodSampling3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.scalpLactateValue3">
                                        <td class="col-8">Scalp Lactate Value</td>
                                        <td class="col-4" [textContent]="lscsmodel.scalpLactateValue3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.skinToSkinBabyCare3">
                                        <td class="col-8">Skin To Skin Baby Care</td>
                                        <td class="col-4" [textContent]="lscsmodel.skinToSkinBabyCare3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.skinToSkinBabyCareDetails3">
                                        <td class="col-8">Skin To Skin Baby Care Details</td>
                                        <td class="col-4" [textContent]="lscsmodel.skinToSkinBabyCareDetails3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.cordClamping3">
                                        <td class="col-8">Cord Clamping</td>
                                        <td class="col-4" [textContent]="lscsmodel.cordClamping3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.ifImmediateDtl3">
                                        <td class="col-8">If Immediate Dtl</td>
                                        <td class="col-4" [textContent]="lscsmodel.ifImmediateDtl3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.clampingTime3">
                                        <td class="col-8">Clamping Time</td>
                                        <td class="col-4" [textContent]="lscsmodel.clampingTime3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.cordABG3">
                                        <td class="col-8">Cord ABG</td>
                                        <td class="col-4" [textContent]="lscsmodel.cordABG3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.date3">
                                        <td class="col-8">Date</td>
                                        <td class="col-4" [textContent]="lscsmodel.date3 |  date: 'dd/MM/yyyy'"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.timeOfTesting3">
                                        <td class="col-8">Time Of Testing</td>
                                        <td class="col-4" [textContent]="lscsmodel.timeOfTesting3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pHArterial3 || lscsmodel.pHVenous3 ">
                                        <h6 class="font-18 mt-0 mb-2">PH</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pHArterial3">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="lscsmodel.pHArterial3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pHVenous3">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="lscsmodel.pHVenous3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.eCFArterial3 || lscsmodel.eCFVenous3 ">
                                        <h6 class="font-18 mt-0 mb-2">ECF</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.eCFArterial3">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="lscsmodel.eCFArterial3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.eCFVenous3">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="lscsmodel.eCFVenous3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.lactateArterial3 || lscsmodel.lactateVenous3 ">
                                        <h6 class="font-18 mt-0 mb-2">Lactate</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.lactateArterial3">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="lscsmodel.lactateArterial3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.lactateVenous3">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="lscsmodel.lactateVenous3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pO2Arterial3 || lscsmodel.pO2Venous3 ">
                                        <h6 class="font-14 mt-0 mb-2">PO2</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pO2Arterial3">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="lscsmodel.pO2Arterial3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pO2Venous3">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="lscsmodel.pO2Venous3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pCO2Arterial3 || lscsmodel.pCO2Venous3 ">
                                        <h6 class="font-18 mt-0 mb-2">PCO2</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pCO2Arterial3">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="lscsmodel.pCO2Arterial3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.pCO2Venous3">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="lscsmodel.pCO2Venous3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.modeOfDelivery3">
                                        <td class="col-8">Mode Of Delivery</td>
                                        <td class="col-4" [textContent]="lscsmodel.modeOfDelivery3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.sexOfBaby3">
                                        <td class="col-8">Sex Of Baby</td>
                                        <td class="col-4" [textContent]="lscsmodel.sexOfBaby3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.statusOfBaby3">
                                        <td class="col-8">Status Of Baby</td>
                                        <td class="col-4" [textContent]="lscsmodel.statusOfBaby3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.babyBirthDate3">
                                        <td class="col-8">Baby Birth Date</td>
                                        <td class="col-4" [textContent]="lscsmodel.babyBirthDate3 |  date: 'dd/MM/yyyy'"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.babyBirthTime3">
                                        <td class="col-8">Baby Birth Time</td>
                                        <td class="col-4" [textContent]="lscsmodel.babyBirthTime3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.babyBirthWt3">
                                        <td class="col-8">Baby Birth Wt</td>
                                        <td class="col-4" [textContent]="lscsmodel.babyBirthWt3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.apgar3">
                                        <td class="col-8">Apgar</td>
                                        <td class="col-4" [textContent]="lscsmodel.apgar3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.deliveryAndAdvice3.length>0">
                                        <td class="col-8">Delivery And Advice</td>
                                        <td class="col-4">
                                            <ul class="dashboard-ul row">
                                                <li *ngFor="let item of  lscsmodel.deliveryAndAdvice3" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.qbMRNo || lscsmodel.qbName || lscsmodel.qbIPNo || lscsmodel.qbDate || lscsmodel.qbProcedure ">
                                        <h6 class="font-18 mt-0 mb-2">Quantitative Blood Loss Estimation Cesarean</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.qbMRNo">
                                        <td class="col-8">MRNo</td>
                                        <td class="col-4" [textContent]="lscsmodel.qbMRNo"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.qbName">
                                        <td class="col-8">Name</td>
                                        <td class="col-4" [textContent]="lscsmodel.qbName"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.qbIPNo">
                                        <td class="col-8">IPNo</td>
                                        <td class="col-4" [textContent]="lscsmodel.qbIPNo"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.qbDate">
                                        <td class="col-8">Date</td>
                                        <td class="col-4" [textContent]="lscsmodel.qbDate"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.qbProcedure">
                                        <td class="col-8">Procedure</td>
                                        <td class="col-4" [textContent]="lscsmodel.qbProcedure"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.amnioticFluidMl || lscsmodel.bloodCollection || lscsmodel.vaginalUnderButtocksDrapes ">
                                        <h6 class="font-18 mt-0 mb-2">Measured Amount (Ml)</h6>
                                        <h6 class="font-18 mt-0 mb-2">Suction Canister</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.amnioticFluidMl">
                                        <td class="col-8">amnioticFluidMl</td>
                                        <td class="col-4" [textContent]="lscsmodel.amnioticFluidMl"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.bloodCollection">
                                        <td class="col-8">bloodCollection</td>
                                        <td class="col-4" [textContent]="lscsmodel.bloodCollection"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.vaginalUnderButtocksDrapes">
                                        <td class="col-8">vaginalUnderButtocksDrapes</td>
                                        <td class="col-4" [textContent]="lscsmodel.vaginalUnderButtocksDrapes"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.spillage">

                                        <h6 class="font-18 mt-0 mb-2">Measure Clots (1 Gm Equals 1 Ml)</h6>

                                    </tr>

                                    <tr *ngIf="lscsmodel.spillage">
                                        <td class="col-8">SPILLAGE If Any (1cm Diameter = 1 Ml)</td>
                                        <td class="col-4" [textContent]="lscsmodel.spillage"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.lscsDrapes250gm || lscsmodel.lscsNumber || lscsmodel.lscsTotalDryWeight || lscsmodel.lscsWetWeight ||
                            lscsmodel.stickingDrapes80gm || lscsmodel.stickingNumber || lscsmodel.stickingTotalDryWeight || lscsmodel.stickingWetWeight ||
                            lscsmodel.cozyDrapes80gm || lscsmodel.cozyNumber || lscsmodel.cozyTotalDryWeight || lscsmodel.cozyWetWeight ||
                            lscsmodel.holeTowelClothDrapes160gm || lscsmodel.holeTowelClothNumber || lscsmodel.holeTowelClothTotalDryWeight || lscsmodel.holeTowelClothWetWeight ||
                            lscsmodel.smallBlueSheetDrapes30gm || lscsmodel.smallBlueSheetNumber || lscsmodel.smallBlueSheetTotalDryWeight || lscsmodel.smallBlueSheetWetWeight ||
                            lscsmodel.surgicalDrapes20gm || lscsmodel.surgicalNumber || lscsmodel.surgicalTotalDryWeight || lscsmodel.surgicalWetWeight ||
                            lscsmodel.greenSheetBigDrapes675gm || lscsmodel.greenSheetBigNumber || lscsmodel.greenSheetBigTotalDryWeight || lscsmodel.greenSheetBigWetWeight ||
                            lscsmodel.greenSheetMediumDrapes300gm || lscsmodel.greenSheetMediumNumber || lscsmodel.greenSheetMediumTotalDryWeight || lscsmodel.greenSheetMediumWetWeight ||
                            lscsmodel.vaginalSanitaryPadDrapes40gm || lscsmodel.vaginalSanitaryPadNumber || lscsmodel.vaginalSanitaryPadTotalDryWeight || lscsmodel.vaginalSanitaryPadWetWeight ||
                            lscsmodel.totalDrapes || lscsmodel.diffwetWeightDryWeight || lscsmodel.suctionTotalOnly1 || lscsmodel.clots1 || lscsmodel.diffWetDryWeight1 || lscsmodel.spillage2 || lscsmodel.totalQBL1 || lscsmodel.suctionTotalOnly2 ||
                            lscsmodel.clots2 || lscsmodel.diffWetDryWeight2 || lscsmodel.spillage3 || lscsmodel.totalQBL2 || lscsmodel.qblDoneByDoctor || lscsmodel.qblDoneByNurse
                            ">
                                        <h6 class="font-18 mt-0 mb-2">Soaked Blood Loss Estimation (By Weight)</h6>
                                        <h6 class="font-18 mt-0 mb-2">CS Set Drapes</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.lscsDrapes250gm">
                                        <td class="col-8">lscsDrapes250gm</td>
                                        <td class="col-4" [textContent]="lscsmodel.lscsDrapes250gm"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.lscsNumber">
                                        <td class="col-8">lscsNumber</td>
                                        <td class="col-4" [textContent]="lscsmodel.lscsNumber"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.lscsTotalDryWeight">
                                        <td class="col-8">lscsTotalDryWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.lscsTotalDryWeight"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.lscsWetWeight">
                                        <td class="col-8">lscsWetWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.lscsWetWeight"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.stickingDrapes80gm">
                                        <td class="col-8">stickingDrapes80gm</td>
                                        <td class="col-4" [textContent]="lscsmodel.stickingDrapes80gm"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.stickingNumber">
                                        <td class="col-8">stickingNumber</td>
                                        <td class="col-4" [textContent]="lscsmodel.stickingNumber"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.stickingTotalDryWeight">
                                        <td class="col-8">stickingTotalDryWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.stickingTotalDryWeight"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.stickingWetWeight">
                                        <td class="col-8">stickingWetWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.stickingWetWeight"> </td>
                                    </tr>


                                    <tr *ngIf="lscsmodel.cozyDrapes80gm">
                                        <td class="col-8">cozyDrapes80gm</td>
                                        <td class="col-4" [textContent]="lscsmodel.cozyDrapes80gm"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.cozyNumber">
                                        <td class="col-8">cozyNumber</td>
                                        <td class="col-4" [textContent]="lscsmodel.cozyNumber"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.cozyTotalDryWeight">
                                        <td class="col-8">cozyTotalDryWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.cozyTotalDryWeight"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.cozyWetWeight">
                                        <td class="col-8">cozyWetWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.cozyWetWeight"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.holeTowelClothDrapes160gm">
                                        <td class="col-8">holeTowelClothDrapes160gm</td>
                                        <td class="col-4" [textContent]="lscsmodel.holeTowelClothDrapes160gm"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.holeTowelClothNumber">
                                        <td class="col-8">holeTowelClothNumber</td>
                                        <td class="col-4" [textContent]="lscsmodel.holeTowelClothNumber"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.holeTowelClothTotalDryWeight">
                                        <td class="col-8">holeTowelClothTotalDryWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.holeTowelClothTotalDryWeight"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.holeTowelClothWetWeight">
                                        <td class="col-8">holeTowelClothWetWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.holeTowelClothWetWeight"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.smallBlueSheetDrapes30gm">
                                        <td class="col-8">smallBlueSheetDrapes30gm</td>
                                        <td class="col-4" [textContent]="lscsmodel.smallBlueSheetDrapes30gm"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.smallBlueSheetNumber">
                                        <td class="col-8">smallBlueSheetNumber</td>
                                        <td class="col-4" [textContent]="lscsmodel.smallBlueSheetNumber"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.smallBlueSheetTotalDryWeight">
                                        <td class="col-8">smallBlueSheetTotalDryWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.smallBlueSheetTotalDryWeight"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.smallBlueSheetWetWeight">
                                        <td class="col-8">smallBlueSheetWetWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.smallBlueSheetWetWeight"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.surgicalDrapes20gm">
                                        <td class="col-8">surgicalDrapes20gm</td>
                                        <td class="col-4" [textContent]="lscsmodel.surgicalDrapes20gm"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.surgicalNumber">
                                        <td class="col-8">surgicalNumber</td>
                                        <td class="col-4" [textContent]="lscsmodel.surgicalNumber"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.surgicalTotalDryWeight">
                                        <td class="col-8">surgicalTotalDryWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.surgicalTotalDryWeight"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.surgicalWetWeight">
                                        <td class="col-8">surgicalWetWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.surgicalWetWeight"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.surgicalDrapes20gm">
                                        <td class="col-8">surgicalDrapes20gm</td>
                                        <td class="col-4" [textContent]="lscsmodel.surgicalDrapes20gm"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.surgicalNumber">
                                        <td class="col-8">surgicalNumber</td>
                                        <td class="col-4" [textContent]="lscsmodel.surgicalNumber"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.surgicalTotalDryWeight">
                                        <td class="col-8">surgicalTotalDryWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.surgicalTotalDryWeight"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.surgicalWetWeight">
                                        <td class="col-8">surgicalWetWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.surgicalWetWeight"> </td>
                                    </tr>

                                    <tr *ngIf="lscsmodel.greenSheetBigDrapes675gm">
                                        <td class="col-8">greenSheetBigDrapes675gm</td>
                                        <td class="col-4" [textContent]="lscsmodel.greenSheetBigDrapes675gm"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.greenSheetBigNumber">
                                        <td class="col-8">greenSheetBigNumber</td>
                                        <td class="col-4" [textContent]="lscsmodel.greenSheetBigNumber"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.greenSheetBigTotalDryWeight">
                                        <td class="col-8">greenSheetBigTotalDryWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.greenSheetBigTotalDryWeight"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.greenSheetBigWetWeight">
                                        <td class="col-8">greenSheetBigWetWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.greenSheetBigWetWeight"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.greenSheetMediumDrapes300gm">
                                        <td class="col-8">greenSheetMediumDrapes300gm</td>
                                        <td class="col-4" [textContent]="lscsmodel.greenSheetMediumDrapes300gm"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.greenSheetMediumNumber">
                                        <td class="col-8">greenSheetMediumNumber</td>
                                        <td class="col-4" [textContent]="lscsmodel.greenSheetMediumNumber"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.greenSheetMediumTotalDryWeight">
                                        <td class="col-8">greenSheetMediumTotalDryWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.greenSheetMediumTotalDryWeight"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.greenSheetMediumWetWeight">
                                        <td class="col-8">greenSheetMediumWetWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.greenSheetMediumWetWeight"> </td>
                                    </tr>

                                    <tr *ngIf="lscsmodel.vaginalSanitaryPadDrapes40gm">
                                        <td class="col-8">vaginalSanitaryPadDrapes40gm</td>
                                        <td class="col-4" [textContent]="lscsmodel.vaginalSanitaryPadDrapes40gm"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.vaginalSanitaryPadNumber">
                                        <td class="col-8">vaginalSanitaryPadNumber</td>
                                        <td class="col-4" [textContent]="lscsmodel.vaginalSanitaryPadNumber"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.vaginalSanitaryPadTotalDryWeight">
                                        <td class="col-8">vaginalSanitaryPadTotalDryWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.vaginalSanitaryPadTotalDryWeight"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.vaginalSanitaryPadWetWeight">
                                        <td class="col-8">vaginalSanitaryPadWetWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.vaginalSanitaryPadWetWeight"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.totalDrapes">
                                        <td class="col-8">totalDrapes</td>
                                        <td class="col-4" [textContent]="lscsmodel.totalDrapes"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.diffwetWeightDryWeight">
                                        <td class="col-8">diffwetWeightDryWeight</td>
                                        <td class="col-4" [textContent]="lscsmodel.diffwetWeightDryWeight"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.suctionTotalOnly1 || lscsmodel.clots1 || lscsmodel.diffWetDryWeight1 || lscsmodel.spillage2 || lscsmodel.totalQBL1">
                                        <h6 class="font-18 mt-0 mb-2">AMNIOTIC FLUD SUCTION BEFORE BABY BIRTH</h6>
                                        <h6 class="font-18 mt-0 mb-2">Suction Total Only + Clots + Wet -Dry Weight +Spillage = Total QBL</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.suctionTotalOnly1">
                                        <td class="col-8">suctionTotalOnly1</td>
                                        <td class="col-4" [textContent]="lscsmodel.suctionTotalOnly1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.clots1">
                                        <td class="col-8">clots1</td>
                                        <td class="col-4" [textContent]="lscsmodel.clots1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.diffWetDryWeight1">
                                        <td class="col-8">diffWetDryWeight1</td>
                                        <td class="col-4" [textContent]="lscsmodel.diffWetDryWeight1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.spillage2">
                                        <td class="col-8">spillage2</td>
                                        <td class="col-4" [textContent]="lscsmodel.spillage2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.totalQBL1">
                                        <td class="col-8">totalQBL1</td>
                                        <td class="col-4" [textContent]="lscsmodel.totalQBL1"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.suctionTotalOnly2 || lscsmodel.clots2 || lscsmodel.diffWetDryWeight2 || lscsmodel.spillage3 || lscsmodel.totalQBL2  || lscsmodel.qblDoneByDoctor">
                                        <h6 class="font-18 mt-0 mb-2">AMNIOTIC FLUD SUCTION NOT DONE BEFORE BABY BIRTH</h6>
                                        <h6 class="font-18 mt-0 mb-2">Suction Total Amount +Clots +Wet -Dry Weight+ Spillage-Amniotic Fluid = Total QBL</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.suctionTotalOnly2">
                                        <td class="col-8">suctionTotalOnly2</td>
                                        <td class="col-4" [textContent]="lscsmodel.suctionTotalOnly2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.clots2">
                                        <td class="col-8">clots2</td>
                                        <td class="col-4" [textContent]="lscsmodel.clots2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.diffWetDryWeight2">
                                        <td class="col-8">diffWetDryWeight2</td>
                                        <td class="col-4" [textContent]="lscsmodel.diffWetDryWeight2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.spillage3">
                                        <td class="col-8">spillage3</td>
                                        <td class="col-4" [textContent]="lscsmodel.spillage3"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.totalQBL2">
                                        <td class="col-8">totalQBL2</td>
                                        <td class="col-4" [textContent]="lscsmodel.totalQBL2"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.qblDoneByDoctor">
                                        <td class="col-8">qblDoneByDoctor</td>
                                        <td class="col-4" [textContent]="lscsmodel.qblDoneByDoctor"> </td>
                                    </tr>
                                    <tr *ngIf="lscsmodel.qblDoneByNurse">
                                        <h6 class="font-18 mt-0 mb-2">AMNIOTIC FLUD SUCTION NOT DONE BEFORE BABY BIRTH</h6>
                                    </tr>
                                    <tr *ngIf="lscsmodel.qblDoneByNurse">
                                        <td class="col-8">qblDoneByNurse</td>
                                        <td class="col-4" [textContent]="lscsmodel.qblDoneByNurse"> </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>



                    <div class="dashboard dashboard-table" #iufdSection *ngIf="iufdmodel && iufdmodel !== null">
                        <h4 class="dashboard-title font-weight-bold"> IUFD Entry Form</h4>
                        <div class="dashboard-body">
                            <div class="overflow-auto">
                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">

                                    <tr *ngIf=" iufdmodel.mrNo || iufdmodel.name || iufdmodel.fatherName || iufdmodel.ancNo || iufdmodel.ipno || iufdmodel.modeOfDelivery || iufdmodel.date || iufdmodel.time || iufdmodel.gestAge || iufdmodel.birthOrder ||
                            iufdmodel.weight || iufdmodel.sex || iufdmodel.status || iufdmodel.apgar1min || iufdmodel.apgar5min || iufdmodel.apgar10min || iufdmodel.pediatrician || iufdmodel.congenitalAnomalies || iufdmodel.congenitalAnomalyDetails || iufdmodel.otherDetails  ">

                                        <h6 class="font-18 mt-0 mb-2"> IUFD </h6>

                                    </tr>

                                    <tr *ngIf="iufdmodel.mrNo">
                                        <td class="col-8">MR No</td>
                                        <td class="col-4" [textContent]="iufdmodel.mrNo"> </td>
                                    </tr>
                                    <tr *ngIf="iufdmodel.name">
                                        <td class="col-8">Name</td>
                                        <td class="col-4" [textContent]="iufdmodel.name"> </td>
                                    </tr>
                                    <tr *ngIf="iufdmodel.fatherName">
                                        <td class="col-8">Father Name</td>
                                        <td class="col-4" [textContent]="iufdmodel.fatherName"> </td>
                                    </tr>
                                    <tr *ngIf="iufdmodel.ancNo">
                                        <td class="col-8">ANC No</td>
                                        <td class="col-4" [textContent]="iufdmodel.ancNo"> </td>
                                    </tr>
                                    <tr *ngIf="iufdmodel.ipno">
                                        <td class="col-8">IP no</td>
                                        <td class="col-4" [textContent]="iufdmodel.ipno"> </td>
                                    </tr>
                                    <tr *ngIf="iufdmodel.modeOfDelivery">
                                        <td class="col-8">Mode Of Delivery</td>
                                        <td class="col-4" [textContent]="iufdmodel.modeOfDelivery"> </td>
                                    </tr>
                                    <tr *ngIf="iufdmodel.date">
                                        <td class="col-8">Date</td>
                                        <td class="col-4" [textContent]="iufdmodel.date"> </td>
                                    </tr>
                                    <tr *ngIf="iufdmodel.time">
                                        <td class="col-8">Time</td>
                                        <td class="col-4" [textContent]="iufdmodel.time"> </td>
                                    </tr>
                                    <tr *ngIf="iufdmodel.gestAge">
                                        <td class="col-8">Gest Age</td>
                                        <td class="col-4" [textContent]="iufdmodel.gestAge"> </td>
                                    </tr>
                                    <tr *ngIf="iufdmodel.birthOrder">
                                        <td class="col-8">Birth Order</td>
                                        <td class="col-4" [textContent]="iufdmodel.birthOrder"> </td>
                                    </tr>
                                    <tr *ngIf="iufdmodel.weight">
                                        <td class="col-8">Weight</td>
                                        <td class="col-4" [textContent]="iufdmodel.weight"> </td>
                                    </tr>
                                    <tr *ngIf="iufdmodel.sex">
                                        <td class="col-8">Sex</td>
                                        <td class="col-4" [textContent]="iufdmodel.sex"> </td>
                                    </tr>
                                    <tr *ngIf="iufdmodel.status">
                                        <td class="col-8">Status</td>
                                        <td class="col-4" [textContent]="iufdmodel.status"> </td>
                                    </tr>
                                    <tr *ngIf="iufdmodel.apgar1min">
                                        <td class="col-8">Apgar 1min</td>
                                        <td class="col-4" [textContent]="iufdmodel.apgar1min"> </td>
                                    </tr>
                                    <tr *ngIf="iufdmodel.apgar5min">
                                        <td class="col-8">Apgar 5min</td>
                                        <td class="col-4" [textContent]="iufdmodel.apgar5min"> </td>
                                    </tr>
                                    <tr *ngIf="iufdmodel.apgar10min">
                                        <td class="col-8">Apgar 10min</td>
                                        <td class="col-4" [textContent]="iufdmodel.apgar10min"> </td>
                                    </tr>
                                    <tr *ngIf="iufdmodel.pediatrician">
                                        <td class="col-8">Pediatrician</td>
                                        <td class="col-4" [textContent]="iufdmodel.pediatrician"> </td>
                                    </tr>
                                    <tr *ngIf="iufdmodel.congenitalAnomalies">
                                        <td class="col-8">Congenital Anomalies</td>
                                        <td class="col-4" [textContent]="iufdmodel.congenitalAnomalies"> </td>
                                    </tr>
                                    <tr *ngIf="iufdmodel.congenitalAnomalyDetails">
                                        <td class="col-8">Congenital AnomalyDetails</td>
                                        <td class="col-4" [textContent]="iufdmodel.congenitalAnomalyDetails"> </td>
                                    </tr>
                                    <tr *ngIf="iufdmodel.otherDetails">
                                        <td class="col-8">Other Details</td>
                                        <td class="col-4" [textContent]="iufdmodel.otherDetails"> </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>


                    <div class="dashboard dashboard-table" #normoldeliverySection *ngIf="normoldeliveryform && normoldeliveryform !== null">
                        <h4 class="dashboard-title font-weight-bold">Normal Delivery </h4>
                        <div class="dashboard-body">
                            <div class="overflow-auto">
                                <h6 class="font-18 mt-0 mb-2">New Normal Delivery </h6>
                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                    <tr *ngIf="normoldeliveryform.labourType">
                                        <td class="col-8">Labour Type</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.labourType"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.iolAgent">
                                        <td class="col-8">IOL Agent Or Method</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.iolAgent"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.indicationOfIOL && normoldeliveryform.indicationOfIOL.length >0">
                                        <div class="col-12" *ngIf="normoldeliveryform.indicationOfIOL && normoldeliveryform.indicationOfIOL.length > 0">
                                            <h6 class="font-18 mt-0 mb-1">Indication Of IOL</h6>
                                            <ul class="dashboard-ul row">
                                                <li *ngFor="let item of  normoldeliveryform.indicationOfIOL" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                            </ul>
                                        </div>
                                    </tr>
                                    <!-- <tr *ngIf="normoldeliveryform.indicationOfIOL">

                                        <td class="col-8">Indication Of IOL	</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.indicationOfIOL"> </td>
                                    </tr>-->
                                    <tr *ngIf="normoldeliveryform.iolOther">
                                        <td class="col-8">Other</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.iolOther"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.augmentation">
                                        <td class="col-8">Augmentation</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.augmentation"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.indicationForOxytocin">
                                        <td class="col-8">Indication For Oxytocin</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.indicationForOxytocin"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.membRupture">
                                        <td class="col-8">Memb Rupture</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.membRupture"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.presentation">
                                        <td class="col-8">Presentation</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.presentation"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.presentationOther">
                                        <td class="col-8">Other</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.presentationOther"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Delivery Details</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.labourAnalgesia">
                                        <td class="col-8">Labour Analgesia</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.labourAnalgesia"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.anaesthesia">
                                        <td class="col-8">Anaesthesia</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.anaesthesia"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.anaesthesiaOther">
                                        <td class="col-8">Others</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.anaesthesiaOther"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.nonEpidural">
                                        <td class="col-8">Non-Epidural</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.nonEpidural"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.deliveryType">
                                        <td class="col-8">Delivery Type</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.deliveryType"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.trialOfForceps">
                                        <td class="col-8">Trial Of Forceps</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.trialOfForceps"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.doubleSetup">
                                        <td class="col-8"> Double Setup</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.doubleSetup"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.catheterized">
                                        <td class="col-8">Catheterized</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.catheterized"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.catheterType">
                                        <td class="col-8">Catheter Type</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.catheterType"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.perineum">
                                        <td class="col-8">Perineum</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.perineum"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.perineal">
                                        <td class="col-8">Perineal</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.perineal"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.sequential">
                                        <td class="col-8">Sequential</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.sequential"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.placeOfInstrument">
                                        <td class="col-8">Place Of Instrument</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.placeOfInstrument"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform">
                                        <h6 class="font-18 mt-0 mb-2">Indication For AVD</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.indicationforavd && normoldeliveryform.indicationforavd.length >0">
                                        <td colspan="2">
                                            <div class="col-12">
                                                <div class="col-12">
                                                    <ul class="dashboard-ul row">
                                                        <li *ngFor="let item of  normoldeliveryform.indicationforavd" class="col-lg-4 col-md-8 col-sm-12" [textContent]="item"></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.applLockAndTraction">
                                        <td class="col-8">Appl Lock And Traction</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.applLockAndTraction"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.durOfInstrument">
                                        <td class="col-8">Dur Of Instrument (Min)</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.durOfInstrument"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.noofPulls">
                                        <td class="col-8">No Of Pulls</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.noofPulls"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.rotation45">
                                        <td class="col-8">Rotation> 45%</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.rotation45"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.ifyes">
                                        <td class="col-8">If Yes</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.ifyes"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Prerequisites</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.placeOfDelivery">
                                        <td class="col-8">Place Of Delivery</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.placeOfDelivery"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.analgesiaProcedure">
                                        <td class="col-8">Analgesia Procedure</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.analgesiaProcedure"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.consent">
                                        <td class="col-8">Consent</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.consent"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Examination</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.PerAbdomen">
                                        <td class="col-8">1/5th Per Abdomen</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.PerAbdomen"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.dilatation">
                                        <td class="col-8">Dilatation</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.dilatation"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.station">
                                        <td class="col-8">Station</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.station"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.moulding">
                                        <td class="col-8">Moulding</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.moulding"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.caput">
                                        <td class="col-8">Caput</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.caput"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Procedure</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.timeOfApplication">
                                        <td class="col-8">Time Of Application</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.timeOfApplication"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.application">
                                        <td class="col-8">Application</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.application"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.traction">
                                        <td class="col-8">Traction</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.traction"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.noofpopoffs">
                                        <td class="col-8">No.Of Pop Offs</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.noofpopoffs"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.maternaleffort">
                                        <td class="col-8">Maternal Effort</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.maternaleffort"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.multipleinstrumentUse">
                                        <td class="col-8">Multiple Instrument Us</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.multipleinstrumentUse"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.examinationbefore2ndinstrument">
                                        <td class="col-8">Examination Before 2nd Instrument</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.examinationbefore2ndinstrument"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.per15thAbdomen">
                                        <td class="col-8">1/5th Per Abdomen</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.per15thAbdomen"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.position">
                                        <td class="col-8">Position</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.position"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.stations">
                                        <td class="col-8">Station</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.stations"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.mouldings">
                                        <td class="col-8">Moulding</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.mouldings"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.caputs">
                                        <td class="col-8">Caput</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.caputs"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.reasonsforsecondinstrument">
                                        <td class="col-8">Reasons For Second Instrument</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.reasonsforsecondinstrument"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.timeof2ndinstrument">
                                        <td class="col-8">Time Of 2nd Instrument</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.timeof2ndinstrument"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.episiotomy">
                                        <td class="col-8">Episiotomy</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.episiotomy"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.indicationepisiotomy">
                                        <td class="col-8">Indication Episiotomy</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.indicationepisiotomy"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.episiotomydoneby">
                                        <td class="col-8">Episiotomy Done By</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.episiotomydoneby"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.decisionforepisiotomdoneby">
                                        <td class="col-8">Decision For Episiotomy Done By</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.decisionforepisiotomdoneby"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Birth Canal Lacerations</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.vaginal">
                                        <td class="col-8">Vaginal</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.vaginal"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.vaginalyes">
                                        <td class="col-8">Vaginalyes</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.vaginalyes"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.paraurethral">
                                        <td class="col-8">Paraurethral</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.paraurethral"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.paraurethralyes">
                                        <td class="col-8">Paraurethralyes</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.paraurethralyes"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.cervical">
                                        <td class="col-8">Cervical</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.cervical"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.cervicalyes">
                                        <td class="col-8">Cervicalyes</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.cervicalyes"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Suture Material Used</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform">
                                        <h6 class="font-18 mt-0 mb-2">Indication For AVD</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.SutureMaterialUsed && normoldeliveryform.SutureMaterialUsed.length >0">
                                        <td colspan="2">
                                            <div class="col-12">
                                                <div class="col-12">
                                                    <ul class="dashboard-ul row">
                                                        <li *ngFor="let item of  normoldeliveryform.SutureMaterialUsed" class="col-lg-4 col-md-8 col-sm-12" [textContent]="item"></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Duration Of Labour</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.Iststage">
                                        <td class="col-8">Ist Stage (Min)</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.Iststage"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.IIndstage">
                                        <td class="col-8">IInd Stage (Min)</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.IIndstage"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.IIIrdstage">
                                        <td class="col-8">IIIrd Stage (Min)</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.IIIrdstage"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.noofVEs">
                                        <td class="col-8">No Of VEs</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.noofVEs"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.noVEAfterARMSROM">
                                        <td class="col-8">No VE After ARM/ SROM</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.noVEAfterARMSROM"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.durationOfActivePushing">
                                        <td class="col-8">Duration Of Active Pushing(Min)</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.durationOfActivePushing"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Intrapartum Complications </h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.maternalComplication">
                                        <td class="col-8">Maternal Complication</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.maternalComplication"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.amnioticFluidVolume">
                                        <td class="col-8">Amniotic Fluid Volume</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.amnioticFluidVolume"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.amnioticFluidColour">
                                        <td class="col-8">Amniotic Fluid Colour</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.amnioticFluidColour"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.shoulderDystocia">
                                        <td class="col-8">Shoulder Dystocia</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.shoulderDystocia"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.ifYesManouvers">
                                        <td class="col-8">If Yes Manouvers</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.ifYesManouvers"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.shoulderDystociaFormFilled">
                                        <td class="col-8">Shoulder Dystocia Form Filled</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.shoulderDystociaFormFilled"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Stage III</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.placenta">
                                        <td class="col-8">Placenta</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.placenta"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.ifAbnormalDts">
                                        <td class="col-8">If Abnormal Dts</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.ifAbnormalDts"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.placentadtls">
                                        <td class="col-8">Placenta Dtls</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.placentadtls"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.placentalDelivery">
                                        <td class="col-8">Placental Delivery</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.placentalDelivery"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.membraneDtls">
                                        <td class="col-8">Membrane Dtls</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.membraneDtls"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.PlacentaWt">
                                        <td class="col-8">Placenta Wt (Gm)</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.PlacentaWt"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.cord">
                                        <td class="col-8">Cord</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.cord"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.abnCordDtls">
                                        <td class="col-8">Abn Cord Dtls</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.abnCordDtls"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.cordLength">
                                        <td class="col-8">Cord Length (Cm)</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.cordLength"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.pph">
                                        <td class="col-8">PPH</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.pph"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.pphprophylaxis && normoldeliveryform.pphprophylaxis.length >0">
                                        <td class="col-8"> PPH Prophylaxis</td>
                                        <td class="col-4">
                                            <ul class="dashboard-ul row">
                                                <li *ngFor="let item of  normoldeliveryform.pphprophylaxis" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.stageother">
                                        <td class="col-8">Others</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.stageother"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.bloodLoss">
                                        <td class="col-8">Blood Loss (Ml)</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.bloodLoss"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.bloodTransfusion">
                                        <td class="col-8">Blood Transfusion</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.bloodTransfusion"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.bloodTransfusionYes">
                                        <td class="col-8">Blood Transfusion Yes</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.bloodTransfusionYes"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Anal Sphincter Details</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.preSuturingPR">
                                        <td class="col-8">Pre Suturing PR</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.preSuturingPR"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.postSuturingPR">
                                        <td class="col-8">Post Suturing PR</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.postSuturingPR"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.analSpincterCondition">
                                        <td class="col-8">Anal Spincter Condition</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.analSpincterCondition"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.otherDetails">
                                        <td class="col-8">Other Details</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.otherDetails"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Delivery Done By</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.birthedBy">
                                        <td class="col-8">Birthed By</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.birthedBy"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.birthedByName">
                                        <td class="col-8">Name</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.birthedByName"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.suturingBy">
                                        <td class="col-8">Suturing By</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.suturingBy"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.name">
                                        <td class="col-8">Name</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.name"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.standByDoctor">
                                        <td class="col-8">Stand By Doctor</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.standByDoctor"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.fetalBloodSampling">
                                        <td class="col-8">Fetal Blood Sampling (Scalp Lactate)</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.fetalBloodSampling"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.scalpLactateValue">
                                        <td class="col-8">Scalp Lactate Value</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.scalpLactateValue"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.perineumWarmCompression">
                                        <td class="col-8">Perineum Warm Compression</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.perineumWarmCompression"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.perinealMassageDoneInANPeriod">
                                        <td class="col-8">Perineal Massage Done In AN Period</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.perinealMassageDoneInANPeriod"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.waterBirth">
                                        <td class="col-8">Water Birth</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.waterBirth"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.hydrotherapy">
                                        <td class="col-8">Hydrotherapy</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.hydrotherapy"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.birthInTheUnit">
                                        <td class="col-8">Birth In The Unit</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.birthInTheUnit"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.birthCompanion">
                                        <td class="col-8">Birth Companion</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.birthCompanion"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.occipito">
                                        <td class="col-8">Occipito</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.occipito"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.birthingPosition">
                                        <td class="col-8">Birthing Position</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.birthingPosition"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.midwiferyToObstetricTransfers">
                                        <td class="col-8">Midwifery To Obstetric Transfers	</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.midwiferyToObstetricTransfers"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.ifYes">
                                        <td class="col-8">If Yes</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.ifYes"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.oasi">
                                        <td class="col-8">OASI</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.oasi"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.oasiyes">
                                        <td class="col-8">OASI Yes</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.oasiyes"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Robson Classification</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.noofFetus">
                                        <td class="col-8">No Of Fetus</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.noofFetus"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.presentations">
                                        <td class="col-8">Presentation</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.presentations"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.ifCephalic">
                                        <td class="col-8">If Cephalic</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.ifCephalic"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.ifterm">
                                        <td class="col-8">If Term</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.ifterm"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.ifNulliparity">
                                        <td class="col-8">If Nulliparity</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.ifNulliparity"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.ifBreech">
                                        <td class="col-8">If Breech</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.ifBreech"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.robsonValue">
                                        <td class="col-8"> Robson Value</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.robsonValue"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Baby Details</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.noofFetuses">
                                        <td class="col-8"> No.Of Fetus</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.noofFetuses"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.birthorder">
                                        <td class="col-8">Birth Order</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.birthorder"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.modeOfDelivery">
                                        <td class="col-8">Mode Of Delivery</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.modeOfDelivery"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.indication">
                                        <td class="col-8">Indication</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.indication"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.babybirthdate">
                                        <td class="col-8">Baby Birth Date</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.babybirthdate | date:'dd/MM/yyyy'"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.babybirthtime">
                                        <td class="col-8">Baby Birth Time</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.babybirthtime"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.sexOfBaby">
                                        <td class="col-8">Sex Of Baby</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.sexOfBaby"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.statusOfBaby">
                                        <td class="col-8">Status Of Baby</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.statusOfBaby"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.babyBirthWeight">
                                        <td class="col-8">Baby Birth Weight(Kg)</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.babyBirthWeight"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.apgar">
                                        <td class="col-8">Apgar</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.apgar"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.cordClamping">
                                        <td class="col-8">Cord Clamping</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.cordClamping"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.ifimmediateReason">
                                        <td class="col-8">If Immediate Reason</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.ifimmediateReason"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.cordClampingTime">
                                        <td class="col-8">Cord Clamping Time</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.cordClampingTime"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.skinToSkinBabyCare">
                                        <td class="col-8">Skin To Skin Baby Care</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.skinToSkinBabyCare"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.ifnoreason">
                                        <td class="col-8">If No Reason</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.ifnoreason"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.cordabg">
                                        <td class="col-8">Cord Abg</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.cordabg"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Umbilical Cord Blood Gases</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.date">
                                        <td class="col-8">Date</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.date | date:'dd/MM/yyyy'"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.timeoftesting">
                                        <td class="col-8">Time Of Testing</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.timeoftesting"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Indication For Requesting Cord Blood Gas Analysis</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.pphprophylaxis && normoldeliveryform.pphprophylaxis.length >0">
                                        <td class="col-8">Indication For Requesting Cord Blood Gas Analysis</td>
                                        <td class="col-4">
                                            <ul class="dashboard-ul row">
                                                <li *ngFor="let item of  normoldeliveryform.pphprophylaxis" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">PH</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.phArterial">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.phArterial"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.phVenous">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.phVenous"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">BE ECF</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.beecfarterial">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.beecfarterial"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.beecVenous">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.beecVenous"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Lactate</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.lactatearterial">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.lactatearterial"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.lactatevenous">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.lactatevenous"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">PO2</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.po2arterial">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.po2arterial"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.po2venous">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.po2venous"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">PCO2</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.pco2arterial">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.pco2arterial"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.pco2venous">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.pco2venous"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Indication</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.babyindication">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.babyindication"> </td>
                                    </tr>
                                    <!--second-->

                                    <tr></tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Baby Details</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform && normoldeliveryform.birthorder2 || normoldeliveryform && normoldeliveryform.noofFetuses =='2'">
                                        <td class="col-8">Birth Order</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.birthorder2"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.modeOfDelivery2">
                                        <td class="col-8">Mode Of Delivery</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.modeOfDelivery2"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.indication2">
                                        <td class="col-8">Indication</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.indication2"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.babybirthdate2">
                                        <td class="col-8">Baby Birth Date</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.babybirthdate2 | date:'dd/MM/yyyy'"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.babybirthtime2">
                                        <td class="col-8">Baby Birth Time</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.babybirthtime2"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.sexOfBaby2">
                                        <td class="col-8">Sex Of Baby</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.sexOfBaby2"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.statusOfBaby2">
                                        <td class="col-8">Status Of Baby</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.statusOfBaby2"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.babyBirthWeight2">
                                        <td class="col-8">Baby Birth Weight(Kg)</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.babyBirthWeight2"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.apgar2">
                                        <td class="col-8">Apgar</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.apgar2"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.cordClamping2">
                                        <td class="col-8">Cord Clamping</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.cordClamping2"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.ifimmediateReason2">
                                        <td class="col-8">If Immediate Reason</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.ifimmediateReason2"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.cordClampingTime2">
                                        <td class="col-8">Cord Clamping Time</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.cordClampingTime2"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.skinToSkinBabyCare2">
                                        <td class="col-8">Skin To Skin Baby Care</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.skinToSkinBabyCare2"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.ifnoreason2">
                                        <td class="col-8">If No Reason</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.ifnoreason2"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.cordabg2">
                                        <td class="col-8">Cord Abg</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.cordabg2"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Umbilical Cord Blood Gases</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.date2">
                                        <td class="col-8">Date</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.date2 | date:'dd/MM/yyyy'"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.timeoftesting2">
                                        <td class="col-8">Time Of Testing</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.timeoftesting2"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Indication For Requesting Cord Blood Gas Analysis</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.pphprophylaxis && normoldeliveryform.pphprophylaxis.length >0">
                                        <td class="col-8">Indication For Requesting Cord Blood Gas Analysis</td>
                                        <td class="col-4">
                                            <ul class="dashboard-ul row">
                                                <li *ngFor="let item of  normoldeliveryform.pphprophylaxis" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">PH</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.phArterial2">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.phArterial2"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.phVenous2">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.phVenous2"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">BE ECF</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.beecfarterial2">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.beecfarterial2"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.beecVenous2">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.beecVenous2"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Lactate</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.lactatearterial2">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.lactatearterial2"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.lactatevenous2">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.lactatevenous2"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">PO2</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.po2arterial2">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.po2arterial2"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.po2venous2">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.po2venous2"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">PCO2</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.pco2arterial2">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.pco2arterial2"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.pco2venous2">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.pco2venous2"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Indication</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.babyindication2">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.babyindication2"> </td>
                                    </tr>

                                    <!--third-->

                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Baby Details</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform && normoldeliveryform.birthorder3 || normoldeliveryform && normoldeliveryform.noofFetuses =='3'">
                                        <td class="col-8">Birth Order</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.birthorder3"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.modeOfDelivery3">
                                        <td class="col-8">Mode Of Delivery</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.modeOfDelivery3"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.indication3">
                                        <td class="col-8">Indication</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.indication3"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.babybirthdate3">
                                        <td class="col-8">Baby Birth Date</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.babybirthdate3 | date:'dd/MM/yyyy'"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.babybirthtime3">
                                        <td class="col-8">Baby Birth Time</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.babybirthtime3"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.sexOfBaby3">
                                        <td class="col-8">Sex Of Baby</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.sexOfBaby3"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.statusOfBaby3">
                                        <td class="col-8">Status Of Baby</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.statusOfBaby3"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.babyBirthWeight3">
                                        <td class="col-8">Baby Birth Weight(Kg)</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.babyBirthWeight3"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.apgar3">
                                        <td class="col-8">Apgar</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.apgar3"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.cordClamping3">
                                        <td class="col-8">Cord Clamping</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.cordClamping3"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.ifimmediateReason3">
                                        <td class="col-8">If Immediate Reason</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.ifimmediateReason3"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.cordClampingTime3">
                                        <td class="col-8">Cord Clamping Time</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.cordClampingTime3"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.skinToSkinBabyCare3">
                                        <td class="col-8">Skin To Skin Baby Care</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.skinToSkinBabyCare3"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.ifnoreason3">
                                        <td class="col-8">If No Reason</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.ifnoreason3"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.cordabg3">
                                        <td class="col-8">Cord Abg</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.cordabg3"> </td>
                                    </tr>

                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Umbilical Cord Blood Gases</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.date3">
                                        <td class="col-8">Date</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.date3 | date:'dd/MM/yyyy'"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.timeoftesting3">
                                        <td class="col-8">Time Of Testing</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.timeoftesting3"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Indication For Requesting Cord Blood Gas Analysis</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.pphprophylaxis && normoldeliveryform.pphprophylaxis.length >0">
                                        <td class="col-8">Indication For Requesting Cord Blood Gas Analysis</td>
                                        <td class="col-4">
                                            <ul class="dashboard-ul row">
                                                <li *ngFor="let item of  normoldeliveryform.pphprophylaxis" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">PH</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.phArterial3">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.phArterial3"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.phVenous">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.phVenous3"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">BE ECF</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.beecfarterial3">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.beecfarterial3"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.beecVenous3">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.beecVenous3"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Lactate</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.lactatearterial3">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.lactatearterial3"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.lactatevenous3">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.lactatevenous3"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">PO2</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.po2arterial3">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.po2arterial3"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.po2venous3">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.po2venous3"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">PCO2</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.pco2arterial3">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.pco2arterial3"> </td>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.pco2venous3">
                                        <td class="col-8">Venous</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.pco2venous3"> </td>
                                    </tr>
                                    <tr>
                                        <h6 class="font-18 mt-0 mb-2">Indication</h6>
                                    </tr>
                                    <tr *ngIf="normoldeliveryform.babyindication3">
                                        <td class="col-8">Arterial</td>
                                        <td class="col-4" [textContent]="normoldeliveryform.babyindication3"> </td>
                                    </tr>


                                </table>
                            </div>
                        </div>
                    </div>


                    <div *ngIf="roleId != 63 && routingValue == 'ob-ip-encounter'">
                        <div class="dashboard dashboard-table" #dischargeSummarySection *ngIf="dischargeSummary && dischargeSummary !== null">
                            <h4 class="dashboard-title font-weight-bold">Discharge Summary</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div class="row" *ngIf="dischargeSummary && (dischargeSummary.reltiveDetails || dischargeSummary.reltiveName || dischargeSummary.bloodGroup || dischargeSummary.dateOfAdmission|| dischargeSummary.timeOfAdmission|| dischargeSummary.birthDate|| dischargeSummary.birthTime|| dischargeSummary.bedNo)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">PATIENT DETAILS</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="dischargeSummary.reltiveDetails">
                                                    <td class="col-8">Relative Details</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.reltiveDetails"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.reltiveName">
                                                    <td class="col-8">Relative Name </td>
                                                    <td class="col-4" [textContent]="dischargeSummary.reltiveName"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.bloodGroup">
                                                    <td class="col-8">Blood Group</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.bloodGroup"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.dateOfAdmission">
                                                    <td class="col-8">Date Of Admission</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.dateOfAdmission | date:'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.timeOfAdmission">
                                                    <td class="col-8">Time Of Admission</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.timeOfAdmission"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.birthDate">
                                                    <td class="col-8">Birth Date</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.birthDate | date:'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.birthTime">
                                                    <td class="col-8">Birth Time</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.birthTime"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.bedNo">
                                                    <td class="col-8">Bed No</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.bedNo"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="dischargeSummary && (dischargeSummary.sumaryType || dischargeSummary.dischargeType || dischargeSummary.dischargeDate || dischargeSummary.transferDate|| dischargeSummary.transferTime|| dischargeSummary.deathDate|| dischargeSummary.deathTime|| dischargeSummary.timeOfDischarge|| dischargeSummary.hospitalName
                                             || dischargeSummary.reasonForTransfer|| dischargeSummary.reasonForLama|| dischargeSummary.causeOfDeath|| dischargeSummary.consultanatDotcor|| dischargeSummary.noOfFetus|| dischargeSummary.growCentile|| dischargeSummary.noOfFetus|| dischargeSummary.consultanatDotcor
                                             || dischargeSummary.consultanatDotcor|| dischargeSummary.consultanatDotcor|| dischargeSummary.consultanatDotcor|| dischargeSummary.consultanatDotcor|| dischargeSummary.consultanatDotcor|| dischargeSummary.consultanatDotcor|| dischargeSummary.consultanatDotcor|| dischargeSummary.consultanatDotcor|| dischargeSummary.consultanatDotcor
                                             || dischargeSummary.consultanatDotcor|| dischargeSummary.consultanatDotcor|| dischargeSummary.consultanatDotcor|| dischargeSummary.consultanatDotcor|| dischargeSummary.consultanatDotcor|| dischargeSummary.consultanatDotcor)">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">DISCHARGE DETAILS</h6>
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="dischargeSummary.sumaryType">
                                                    <td class="col-8">Summary Type</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.sumaryType"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.dischargeType">
                                                    <td class="col-8">Discharge Type</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.dischargeType"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.dischargeDate">
                                                    <td class="col-8">Discharge Date</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.dischargeDate | date:'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.transferDate">
                                                    <td class="col-8">Transfer Date</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.transferDate | date:'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.transferTime">
                                                    <td class="col-8">Transfer Time</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.transferTime"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.deathDate">
                                                    <td class="col-8">Death Date</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.deathDate | date:'dd/MM/yyyy'"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.deathTime">
                                                    <td class="col-8">Death Time</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.deathTime"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.timeOfDischarge">
                                                    <td class="col-8">Time Of Discharge</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.timeOfDischarge"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.hospitalName">
                                                    <td class="col-8">Hospital Name</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.hospitalName"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.reasonForTransfer">
                                                    <td class="col-8">Reason For Tranfer</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="dischargeSummary.reasonForTransfer"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.reasonForLama">
                                                    <td class="col-8">Reason For LAMA</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.reasonForLama"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.causeOfDeath">
                                                    <td class="col-8">Cause Of Death</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="dischargeSummary.causeOfDeath"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.consultanatDotcor">
                                                    <td class="col-8">Consultant Doctor</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="dischargeSummary.consultanatDotcor"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.growCentile">
                                                    <td class="col-8">Grow Centile</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.growCentile"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.noOfFetus">
                                                    <td class="col-8">No Of Fetus</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.noOfFetus"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.baby1Mrno">
                                                    <td class="col-8">Baby 1 Mrno</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.baby1Mrno"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.arteral">
                                                    <td class="col-8">PH - Arterial</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.arteral"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.venous">
                                                    <td class="col-8">Venous</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.venous"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.venousCheck">
                                                    <td class="col-8"></td>
                                                    <td class="col-4" [textContent]="dischargeSummary.venousCheck"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.pendingForApproval">
                                                    <td class="col-8">Pending For Approval</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.pendingForApproval"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.consultedByDr">
                                                    <td class="col-8">Assigned To Consent Doctor</td>
                                                    <td class="col-4" [textContent]="dischargeSummary.consultedByDr.toUpperCase()"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.diagnosis">
                                                    <td class="col-8">Diagnosis</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [innerHTML]="dischargeSummary.diagnosis"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.chiefComplaints">
                                                    <td class="col-8">Chief Complaints</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [innerHTML]="dischargeSummary.chiefComplaints"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.HistoryOfPresentillness">
                                                    <td class="col-8">History of Present illness</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [innerHTML]="dischargeSummary.HistoryOfPresentillness"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.medicalHistory">
                                                    <td class="col-8">Medical History</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [innerHTML]="dischargeSummary.medicalHistory"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.surgicalHistory">
                                                    <td class="col-8">Surgical History</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [innerHTML]="dischargeSummary.surgicalHistory"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.allergyHistory">
                                                    <td class="col-8">Allergy History</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [innerHTML]="dischargeSummary.allergyHistory"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.familyHistory">
                                                    <td class="col-8">Family History</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [innerHTML]="dischargeSummary.familyHistory"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.presentPregnancyDetail">
                                                    <td class="col-8">Present pregnancy details</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [innerHTML]="dischargeSummary.presentPregnancyDetail"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.admssionDetails">
                                                    <td class="col-8">Admission Details</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [innerHTML]="dischargeSummary.admssionDetails"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.otherProceduresDetails">
                                                    <td class="col-8">Other Procedures Details</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [innerHTML]="dischargeSummary.otherProceduresDetails"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.courseHospital">
                                                    <td class="col-8">Course in the Hospital</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [innerHTML]="dischargeSummary.courseHospital"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.consitionDischarge">
                                                    <td class="col-8">Condition at Discharge</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [innerHTML]="dischargeSummary.consitionDischarge"> </td>
                                                </tr>
                                                <tr *ngIf="dischargeSummary.followUp">
                                                    <td class="col-8">Follow up / Review dates</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [innerHTML]="dischargeSummary.followUp"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="roleId != 63 && routingValue == 'ob-ip-encounter'">
                        <div class="dashboard dashboard-table" #syndromeSection *ngIf="syndromeDetails && syndromeDetails !== null">
                            <h4 class="dashboard-title font-weight-bold">Syndromes Form-P</h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="syndromeDetails.consultantDoctor">
                                                    <td>Consultant Doctor</td>
                                                    <td [textContent]="syndromeDetails.consultantDoctor"></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="syndromeDetails.syndromes.length > 0">
                                        <div class="col-12">
                                            <h6 class="font-18 mt-0 mb-2">Syndromes</h6>
                                            <ul class="dashboard-ul row">
                                                <li *ngFor="let item of syndromeDetails.syndromes" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="syndromeDetails.diseases.length > 0">
                                        <div class="col-12">
                                            <h6 class="font-148 mt-0 mb-2">Diseases</h6>
                                            <ul class="dashboard-ul row">
                                                <li *ngFor="let item of syndromeDetails.diseases" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="syndromeDetails.others">
                                                    <td>Others</td>
                                                    <td [textContent]="syndromeDetails.others"></td>
                                                </tr>
                                                <tr *ngIf="syndromeDetails.provisionalDiagnosis">
                                                    <td>Provisional Diagnosis</td>
                                                    <td [textContent]="syndromeDetails.provisionalDiagnosis"></td>
                                                </tr>
                                                <tr *ngIf="syndromeDetails.testOrdered">
                                                    <td>Test Ordered</td>
                                                    <td [textContent]="syndromeDetails.testOrdered"></td>
                                                </tr>
                                                <tr *ngIf="syndromeDetails.patientWilling">
                                                    <td>If The Patient Is Willing Willing to give sample</td>
                                                    <td [textContent]="syndromeDetails.patientWilling"></td>
                                                </tr>
                                                <tr *ngIf="syndromeDetails.onsetDate">
                                                    <td>Date Of Onset Of The Syndromes</td>
                                                    <td [textContent]="syndromeDetails.onsetDate | date:'dd/MM/yyyy'"></td>
                                                </tr>
                                                <tr *ngIf="syndromeDetails.transactionId">
                                                    <td>Transaction Id</td>
                                                    <td [textContent]="syndromeDetails.transactionId"></td>
                                                </tr>
                                                <tr *ngIf="syndromeDetails.typeOfSample">
                                                    <td>Type Of Sample</td>
                                                    <td [textContent]="syndromeDetails.typeOfSample"></td>
                                                </tr>
                                                <tr *ngIf="syndromeDetails.dateSample">
                                                    <td>Date Of Sample Collection</td>
                                                    <td [textContent]="syndromeDetails.dateSample | date:'dd/MM/yyyy'"></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div #doctorReviewSection *ngIf="doctorsReviewForm && doctorsReviewForm !== null">
                        <div class="dashboard dashboard-table">
                            <h4 class="dashboard-title font-weight-bold"> Doctors Review</h4>
                            <div class="dashboard-body" *ngFor="let doctorsReviewForm of doctorsReviewForm">
                                <div class="overflow-auto">
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <tr *ngIf="doctorsReviewForm.timeOfAssessment">
                                            <td class="col-8">Time Of Assessment</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.timeOfAssessment"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.mrNo">
                                            <td class="col-8">MR No</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.mrNo"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.name">
                                            <td class="col-8">Name</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.name"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.husbandName">
                                            <td class="col-8">Husband Name</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.husbandName"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.ipNo">
                                            <td class="col-8">IP No</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.ipNo"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.dateOfAdmission">
                                            <td class="col-8">Date Of Admission</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.dateOfAdmission | date:'dd/MM/yyyy'"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.time">
                                            <td class="col-8">Time(hrs)</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.time"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.roomNo">
                                            <td class="col-8">Room No</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.roomNo"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.complaints && doctorsReviewForm.complaints.length >0">
                                            <div class="col-12" *ngIf="doctorsReviewForm.complaints && doctorsReviewForm.complaints.length > 0">
                                                <h6 class="font-18 mt-0 mb-1">Complaints</h6>
                                                <ul class="dashboard-ul row">
                                                    <li *ngFor="let item of  doctorsReviewForm.complaints" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                </ul>
                                            </div>
                                        </tr>
                                        <!-- <tr *ngIf="doctorsReviewForm.complaints && doctorsReviewForm.complaints.length >0">
                                            <td class="col-8">
                                                Complaints
                                            </td>
                                            <td class="col-4">
                                                <ul class="dashboard-ul row">
                                                    <li *ngFor="let item of  doctorsReviewForm.complaints" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                </ul>
                                            </td>
                                        </tr>-->
                                        <tr *ngIf="doctorsReviewForm.otherComplaints">
                                            <td class="col-8">Other Complaints</td>
                                            <td class="col-4">
                                                <div style="white-space:normal; word-break:break-all;">
                                                    <span [textContent]="doctorsReviewForm.otherComplaints"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm && (doctorsReviewForm.heightInFeet || doctorsReviewForm.weightInKg || doctorsReviewForm.bmi || doctorsReviewForm.systolicBp || doctorsReviewForm.diastolicBp || doctorsReviewForm.pulseRate ||
                                                doctorsReviewForm.respiratoryRate || doctorsReviewForm.spO2 || doctorsReviewForm.temperature || doctorsReviewForm.conscious || doctorsReviewForm.shock || doctorsReviewForm.pallor || doctorsReviewForm.icterus ||
                                                doctorsReviewForm.oedema ||  doctorsReviewForm.spleen || doctorsReviewForm.liver || doctorsReviewForm.reflex || doctorsReviewForm.cardiovasc || doctorsReviewForm.respSystem || doctorsReviewForm.urineOutput || doctorsReviewForm.urineAlbumin)">
                                            <h6 class="font-18 mt-0 mb-2">General Examination</h6>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.heightInFeet">
                                            <td class="col-8">Height In Feet</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.heightInFeet"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.weightInKg">
                                            <td class="col-8">Weight In Kg</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.weightInKg"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.bmi">
                                            <td class="col-8">BMI</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.bmi"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.systolicBp">
                                            <td class="col-8">Systolic BP</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.systolicBp"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.diastolicBp">
                                            <td class="col-8">Diastolic BP</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.diastolicBp"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.pulseRate">
                                            <td class="col-8">Pulse Rate</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.pulseRate"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.respiratoryRate">
                                            <td class="col-8">Respiratory Rate</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.respiratoryRate"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.spO2">
                                            <td class="col-8">SpO2</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.spO2"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.temperature">
                                            <td class="col-8">Temperature</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.temperature"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.conscious">
                                            <td class="col-8">Conscious</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.conscious"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.shock">
                                            <td class="col-8">Shock</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.shock"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.pallor">
                                            <td class="col-8">Pallor</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.pallor"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.icterus">
                                            <td class="col-8">Icterus</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.icterus"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.oedema">
                                            <td class="col-8">Oedema</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.oedema"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.spleen">
                                            <td class="col-8">Spleen</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.spleen"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.liver">
                                            <td class="col-8">Liver</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.liver"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.reflex">
                                            <td class="col-8">Reflex</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.reflex"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.cardiovasc">
                                            <td class="col-8">Cardiovasc</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.cardiovasc"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.respSystem">
                                            <td class="col-8">Resp. System</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.respSystem"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.urineOutput">
                                            <td class="col-8">Urine Output</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.urineOutput"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.urineAlbumin">
                                            <td class="col-8">Urine Albumin</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.urineAlbumin"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.type">
                                            <h6 class="font-18 mt-0 mb-2">
                                                Specific Examination
                                            </h6>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.type">
                                            <td class="col-8">Type</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.type"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.others1">
                                            <td class="col-8">Others</td>
                                            <td class="col-4">
                                                <div style="white-space:normal; word-break:break-all;">
                                                    <span [textContent]="doctorsReviewForm.others1"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.uterusPalpable || doctorsReviewForm.fundalHt || doctorsReviewForm.utContractions || doctorsReviewForm.pp1 || doctorsReviewForm.fifthPalpable || doctorsReviewForm.amnioticFluid || doctorsReviewForm.fhs || doctorsReviewForm.fhr || doctorsReviewForm.others2 || doctorsReviewForm.others4 || doctorsReviewForm.others5">
                                            <h6 class="font-18 mt-0 mb-2">
                                                Obstetric Examination
                                            </h6>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.uterusPalpable">
                                            <td class="col-8">Uterus Palpable</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.uterusPalpable"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.fundalHt">
                                            <td class="col-8">Fundal Ht	</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.fundalHt"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.utContractions">
                                            <td class="col-8">Ut Contractions</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.utContractions"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.pp1">
                                            <td class="col-8">P P</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.pp1"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.fifthPalpable">
                                            <td class="col-8">Fifth Palpable</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.fifthPalpable"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.amnioticFluid">
                                            <td class="col-8">Amniotic Fluid</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.amnioticFluid"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.fhs">
                                            <td class="col-8">FHS</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.fhs"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.fhr">
                                            <td class="col-8">FHR</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.fhr"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.others2">
                                            <td class="col-8">Others</td>
                                            <td class="col-4">
                                                <div style="white-space:normal; word-break:break-all;">
                                                    <span [textContent]="doctorsReviewForm.others2"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.others4">
                                            <td class="col-8">Others</td>
                                            <td class="col-4">
                                                <div style="white-space:normal; word-break:break-all;">
                                                    <span [textContent]="doctorsReviewForm.others4"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.others5">
                                            <td class="col-8">Others</td>
                                            <td class="col-4">
                                                <div style="white-space:normal; word-break:break-all;">
                                                    <span [textContent]="doctorsReviewForm.others5"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.psEaxm || doctorsReviewForm.psOthers || doctorsReviewForm.cxLength || doctorsReviewForm.cxDilation || doctorsReviewForm.membranes || doctorsReviewForm.presentingPart || doctorsReviewForm.station || doctorsReviewForm.pelvis || doctorsReviewForm.others5">
                                            <h6 class="font-18 mt-0 mb-2">
                                                Obstetric Vaginal Examination
                                            </h6>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.psEaxm">
                                            <td class="col-8">P/S Exam</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.psEaxm"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.psEaxm">
                                            <td class="col-8">P/S Others</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.psOthers"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.cxLength">
                                            <td class="col-8">Cx Length	</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.cxLength"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.cxDilation">
                                            <td class="col-8">Cx Dilation</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.cxDilation"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.membranes">
                                            <td class="col-8">Membranes</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.membranes"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.presentingPart">
                                            <td class="col-8">Presenting Part</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.presentingPart"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.station">
                                            <td class="col-8">Station</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.station"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.pelvis">
                                            <td class="col-8">Pelvis</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.pelvis"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.others3">
                                            <td class="col-8">Others</td>
                                            <td class="col-4">
                                                <div style="white-space:normal; word-break:break-all;">
                                                    <span [textContent]="doctorsReviewForm.others3"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.postnatalComplaints || doctorsReviewForm.otherComplaints2 || doctorsReviewForm.lactating || doctorsReviewForm.perineum || doctorsReviewForm.perinealWound || doctorsReviewForm.abdominalIncision || doctorsReviewForm.perSpeculam || doctorsReviewForm.perVaginum ||
                                                   doctorsReviewForm.perRectal || doctorsReviewForm.bleedingPv || doctorsReviewForm.details || doctorsReviewForm.others6 || doctorsReviewForm.psOthers">
                                            <h6 class="font-18 mt-0 mb-2">
                                                Postnatal Visit
                                            </h6>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.postnatalComplaints && doctorsReviewForm.postnatalComplaints.length >0">
                                            <td class="col-8">
                                                Postnatal Complaints
                                            </td>
                                            <td class="col-4">
                                                <ul class="dashboard-ul row">
                                                    <li *ngFor="let item of  doctorsReviewForm.postnatalComplaints" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.otherComplaints2">
                                            <td class="col-8">Other Complaints</td>
                                            <td class="col-4">
                                                <div style="white-space:normal; word-break:break-all;">
                                                    <span [textContent]="doctorsReviewForm.otherComplaints2"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.lactating">
                                            <td class="col-8">Lactating</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.lactating"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.perineum">
                                            <td class="col-8">Perineum</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.perineum"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.perinealWound">
                                            <td class="col-8">Perineal Wound</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.perinealWound"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.abdominalIncision">
                                            <td class="col-8">Abdominal Incision</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.abdominalIncision"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.perSpeculam">
                                            <td class="col-8">Per Speculam</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.perSpeculam"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.perVaginum">
                                            <td class="col-8">Per Vaginum</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.perVaginum"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.perRectal">
                                            <td class="col-8">Per Rectal</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.perRectal"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.bleedingPv">
                                            <td class="col-8">Bleeding PV</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.bleedingPv"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.details">
                                            <td class="col-8">Details</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.details"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.others6">
                                            <td class="col-8">>Others</td>
                                            <td class="col-4">
                                                <div style="white-space:normal; word-break:break-all;">
                                                    <span [textContent]="doctorsReviewForm.others6"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.psEaxm">
                                            <td class="col-8">P/S Others</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.psOthers"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.drugAllergies">
                                            <td class="col-8">Drug Allergies</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.drugAllergies"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.consultantOpinion">
                                            <td class="col-8">Consultant Opinion</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.consultantOpinion"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.counselling">
                                            <td class="col-8">Counselling</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.counselling"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.consultantInformed">
                                            <td class="col-8">Consultant Informed</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.consultantInformed"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.doctorsReview">
                                            <td class="col-8">Doctors Review</td>
                                            <td class="col-4">
                                                <div style="white-space:normal; word-break:break-all;">
                                                    <span [textContent]="doctorsReviewForm.doctorsReview"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.seenByDr">
                                            <td class="col-8">Seen By Dr</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.seenByDr"> </td>
                                        </tr>
                                        <tr *ngIf="doctorsReviewForm.otherConsultant">
                                            <td class="col-8">Other Consultant</td>
                                            <td class="col-4" [textContent]="doctorsReviewForm.otherConsultant"> </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div #telephoneTriageAssessment *ngIf="telephoneTriageAssessmentdata && telephoneTriageAssessmentdata.length>0">
                        <div class="dashboard dashboard-table" *ngFor="let telephoneTriageAssessments of telephoneTriageAssessmentdata">
                            <h4 class="dashboard-title font-weight-bold">TelePhone Triage Assessment </h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">
                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">

                                        <tr>
                                            <td class="col-8">Call Date</td>
                                            <td class="col-4" [textContent]="telephoneTriageAssessments.callDate | date:'dd/MM/yyyy'"> </td>
                                        </tr>
                                        <tr>
                                            <td class="col-8">Call Time</td>
                                            <td class="col-4" [textContent]="telephoneTriageAssessments.callTime"> </td>
                                        </tr>
                                        <tr>
                                            <td class="col-8">Type of Consultation</td>
                                            <td class="col-4" [textContent]="telephoneTriageAssessments.typeofConsultation"> </td>
                                        </tr>
                                        <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.reasonForCall">
                                            <td class="col-4">Reason For Call</td>
                                            <td class="col-8">
                                                <ul class="dashboard-ul row">
                                                    <li *ngFor="let item of  telephoneTriageAssessments.reasonForCall" [textContent]="item"></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="col-8">Other</td>
                                            <td class="col-4" [textContent]="telephoneTriageAssessments.reasonothers"> </td>
                                        </tr>
                                        <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.adviceGiventoPatient">
                                            <td class="col-4">Advice Given To Patient</td>
                                            <td class="col-8">
                                                <ul class="dashboard-ul row">
                                                    <li *ngFor="let item of  telephoneTriageAssessments.adviceGiventoPatient" [textContent]="item"></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="col-8"> Others</td>
                                            <td class="col-4" [textContent]="telephoneTriageAssessments.adviceother"> </td>
                                        </tr>
                                        <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.actionTakenIfAdvisedToVisit">
                                            <td class="col-4">Action Taken If Advised To Visit</td>
                                            <td class="col-8">
                                                <ul class="dashboard-ul row">
                                                    <li *ngFor="let item of  telephoneTriageAssessments.actionTakenIfAdvisedToVisit" [textContent]="item"></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="col-8">Others</td>
                                            <td class="col-4" [textContent]="telephoneTriageAssessments.actionother"> </td>
                                        </tr>
                                        <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.doctorName">
                                            <td class="col-8">Doctor Name</td>
                                            <td class="col-4 text-uppercase" [textContent]="telephoneTriageAssessments.doctorName"> </td>
                                        </tr>
                                        <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.callCompletedDate">
                                            <td class="col-8">Call Completed Date</td>
                                            <td class="col-4" [textContent]="telephoneTriageAssessments.callCompletedDate | date:'dd/MM/yyyy'"> </td>
                                        </tr>
                                        <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.callcompletedTime">
                                            <td class="col-8">Call Completed Time</td>
                                            <td class="col-4" [textContent]="telephoneTriageAssessments.callcompletedTime"> </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard dashboard-table" *ngIf="cimsAllergies && cimsAllergies.length">
                        <h4 class="dashboard-title font-weight-bold">Allergies</h4>
                        <div class="dashboard-body">
                            <div class="overflow-auto">
                                <table class=" table-centered table-nowrap table-sm table-striped table-borderless mb-0">
                                    <thead>
                                        <tr>
                                            <th>Allergies</th>
                                            <th>Allegy Type</th>
                                            <th>Reactions</th>
                                            <th>StartDate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of cimsAllergies; let i = index;">
                                            <td>
                                                <div class="media">
                                                    <div class="media-body">
                                                        <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.allergiesName"></h5>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-capitalize" [textContent]="item.allergyType"></td>
                                            <td class="text-capitalize" [textContent]="item.reaction"></td>
                                            <td>
                                                <p *ngIf="item.startDate" [textContent]="item.startDate | utcToLocal:false"></p>
                                                <p style="padding-left: 25px" *ngIf="!item.startDate" [textContent]="'-'"></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                    <div>
                        <div #aldretescoreSection *ngIf="aldreteScore && aldreteScore !== null">
                            <h4 class="dashboard-title font-weight-bold">Aldrete Score </h4>
                            <div class="row">
                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                    <tr *ngIf="aldreteScore.activity">
                                        <td class="col-8">Activity</td>
                                        <td class="col-4" [textContent]="aldreteScore.activity"> </td>
                                    </tr>
                                    <tr *ngIf="aldreteScore.respiration">
                                        <td class="col-8">Respiration</td>
                                        <td class="col-4" [textContent]="aldreteScore.respiration"> </td>
                                    </tr>
                                    <tr *ngIf="aldreteScore.circulation">
                                        <td class="col-8">Circulation</td>
                                        <td class="col-4" [textContent]="aldreteScore.circulation"> </td>
                                    </tr>
                                    <tr *ngIf="aldreteScore.consciousness">
                                        <td class="col-8">Consciousness</td>
                                        <td class="col-4" [textContent]="aldreteScore.consciousness"> </td>
                                    </tr>
                                    <tr *ngIf="aldreteScore.oxygensaturation">
                                        <td class="col-8">Oxygen Saturation</td>
                                        <td class="col-4" [textContent]="aldreteScore.oxygensaturation"> </td>
                                    </tr>
                                    <tr *ngIf="aldreteScore.totalscore">
                                        <td class="col-8">Total Score</td>
                                        <td class="col-4" [textContent]="aldreteScore.totalscore"> </td>
                                    </tr>
                                    <tr *ngIf="aldreteScore.scoreDoneBy">
                                        <td class="col-8">Score Done By</td>
                                        <td class="col-4" [textContent]="aldreteScore.scoreDoneBy"> </td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="dashboard-box dashboard-table-box" #anaesthesiarecordPostopSection *ngIf="anaesthesiaRecord && anaesthesiaRecord !== null">
                            <h4 class="dashboard-title font-weight-bold">Anaesthesia Record (Post OP) </h4>
                            <div class="dashboard-body">
                                <div class="overflow-auto">

                                    <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                        <tr *ngIf="anaesthesiaRecord.date">
                                            <td class="col-8">Date</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.date |date:'dd/MM/yyyy'"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.ot">
                                            <td class="col-8">OT</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.ot"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.consent">
                                            <td class="col-8">Consent</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.consent"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.npoStatus">
                                            <td class="col-8">NPO Status (Hrs)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.npoStatus"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.anaesthesiologist">
                                            <td class="col-8">Anaesthesiologist</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.anaesthesiologist"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.surgeon">
                                            <td class="col-8">Surgeon</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.surgeon"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.surgeryDone">
                                            <td class="col-8">Surgery Done</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.surgeryDone"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.anaesthesiaTechnique">
                                            <td class="col-8">Anaesthesia. Technique</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.anaesthesiaTechnique"> </td>
                                        </tr>
                                        <tr>
                                            <th>Immediate Pre-Op Vitals</th>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.pulseRate">
                                            <td class="col-8">Pulse Rate (Min)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.pulseRate"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.systolicBP">
                                            <td class="col-8">Systolic BP (Mm Hg)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.systolicBP"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.diastoticBP">
                                            <td class="col-8">Diastolic BP Mm Hg</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.diastoticBP"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.respiratoryRate">
                                            <td class="col-8">Respiratory Rate (Min)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.respiratoryRate"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.spo2">
                                            <td class="col-8">SPO2 (%)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.spo2"> </td>
                                        </tr>
                                        <tr>
                                            <th>Time Tracking</th>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.anaesthesiaStartedTime">
                                            <td class="col-8">Anaesthesia Started Time</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.anaesthesiaStartedTime"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.completedTime">
                                            <td class="col-8">Completed Time</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.completedTime"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.surgeryStartedTime">
                                            <td class="col-8">Surgery Started Time</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.surgeryStartedTime"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.scompletedTime">
                                            <td class="col-8">S Completed Time</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.scompletedTime"> </td>
                                        </tr>
                                        <tr>
                                            <th>GA Details</th>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.lma">
                                            <td class="col-8">LMA</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.lma"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.size">
                                            <td class="col-8">Size</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.size"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.cufDtls">
                                            <td class="col-8">Cuf Dtls</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.cufDtls"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.ettSize">
                                            <td class="col-8">ETT Size</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.ettSize"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.noofAttempts">
                                            <td class="col-8">No Of Attempts</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.noofAttempts"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.difficultIntubation">
                                            <td class="col-8">Difficult Intubation</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.difficultIntubation"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.clGroup">
                                            <td class="col-8">CL Group</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.clGroup"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.vls">
                                            <td class="col-8">VLS</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.vls"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.sellicksManoeuvre">
                                            <td class="col-8">Sellicks Manoeuvre</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.sellicksManoeuvre"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.oxygen">
                                            <td class="col-8">Oxygen (L/Min)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.oxygen"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.n2o">
                                            <td class="col-8">N2O (L/Min)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.n2o"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.air">
                                            <td class="col-8">Air (L/Min)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.air"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.inhalationalAgent">
                                            <td class="col-8">Inhalational Agent</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.inhalationalAgent"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.ventilation">
                                            <td class="col-8">Ventilation</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.ventilation"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.vt">
                                            <td class="col-8">VT</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.vt"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.rr">
                                            <td class="col-8">RR</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.rr"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.awp">
                                            <td class="col-8">AWP</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.awp"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.peep">
                                            <td class="col-8">PEEP</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.peep"> </td>
                                        </tr>
                                        <tr><th>Regional Anaesthesia DTL</th></tr>
                                        <tr *ngIf="anaesthesiaRecord.regionalAnesthesia">
                                            <td class="col-8">Regional Anesthesia</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.regionalAnesthesia"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.tipg">
                                            <td class="col-8">Tip / G</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.tipg"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.positionInsert">
                                            <td class="col-8">Position Insert / Inj.</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.positionInsert"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.spacelorsa">
                                            <td class="col-8">Space LORS / A</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.spacelorsa"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.time">
                                            <td class="col-8">Time</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.time"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.drug">
                                            <td class="col-8">Drug</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.drug"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.vdose">
                                            <td class="col-8">V / Dose</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.vdose"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.onset">
                                            <td class="col-8">Onset</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.onset"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.dermLevel">
                                            <td class="col-8">Derm. Level</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.dermLevel"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.motorBlock">
                                            <td class="col-8">Motor Block</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.motorBlock"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.paraesthesiaInsert">
                                            <td class="col-8">Paraesthesia Insert / Inj.</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.paraesthesiaInsert"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.btoodTap">
                                            <td class="col-8">Btood Tap</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.btoodTap"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.dp">
                                            <td class="col-8">DP</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.dp"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.comments">
                                            <td class="col-8">Comments</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.comments"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.topups">
                                            <td class="col-8">Topups</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.topups"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.patientPosition">
                                            <td class="col-8">Patient Position</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.patientPosition"> </td>
                                        </tr>
                                        <tr><th>Monitoring</th></tr>
                                        <tr>
                                            <td>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <h6 class="font-18 mt-0 mb-2"></h6>
                                                        <ul class="dashboard-ul row">
                                                            <li *ngFor="let item of anaesthesiaRecord.monitoring" class="col-xl-3" [textContent]="item"></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr><th>Intake Output Details </th></tr>
                                        <tr><th>Intake </th></tr>
                                        <tr *ngIf="anaesthesiaRecord.crystal">
                                            <td class="col-8">Crystal (Ml)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.crystal"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.colloids">
                                            <td class="col-8">Colloids (Ml)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.colloids"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.bllod">
                                            <td class="col-8">Bllod (Ml)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.bllod"> </td>
                                        </tr>
                                        <tr><th>Output</th></tr>
                                        <tr *ngIf="anaesthesiaRecord.estimatedBloodLoss">
                                            <td class="col-8">Estimated Blood Loss (Ml)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.estimatedBloodLoss"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.urine">
                                            <td class="col-8">Urine (Ml)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.urine"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.ascitis">
                                            <td class="col-8">Ascitis (Ml)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.ascitis"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.rylesTubeAspiration">
                                            <td class="col-8">Ryles Tube Aspiration (Ml)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.rylesTubeAspiration"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.recovery">
                                            <td class="col-8">Recovery</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.recovery"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.status">
                                            <td class="col-8">Status</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.status"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.reflwxesMuscleTone">
                                            <td class="col-8">Reflwxes / Muscle Tone</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.reflwxesMuscleTone"> </td>
                                        </tr>
                                        <tr><th>Vitals Before Shifting</th></tr>
                                        <tr *ngIf="anaesthesiaRecord.pr">
                                            <td class="col-8">PR (Min)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.pr"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.sysbp">
                                            <td class="col-8">Sys BP (Mm Hg)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.sysbp"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.dbp">
                                            <td class="col-8">D BP (Mm Hg)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.dbp"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.spo">
                                            <td class="col-8">SpO2</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.spo"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.vas">
                                            <td class="col-8">VAS</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.vas"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.shiftedTo">
                                            <td class="col-8">Shifted To</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.shiftedTo"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.electiveVentilation">
                                            <td class="col-8">Elective Ventilation</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.electiveVentilation"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.anyAdverseEvents">
                                            <td class="col-8">Any Adverse Events</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.anyAdverseEvents"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.totalIntakeOutput">
                                            <td class="col-8">Total Intake Output (Ml)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.totalIntakeOutput"> </td>
                                        </tr>
                                        <tr><th>Continue Vitals Monitoring During Procedure</th></tr>
                                        <tr *ngIf="anaesthesiaRecord.continuevitalsdate">
                                            <td class="col-8">Date</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.continuevitalsdate|date:'dd/MM/yyyy'"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.continuevitalstime">
                                            <td class="col-8">Time</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.continuevitalstime"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.continuevitalsspo2">
                                            <td class="col-8">SpO2</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.continuevitalsspo2"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.etCo2">
                                            <td class="col-8">EtCo2</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.etCo2"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.fio2">
                                            <td class="col-8">FiO2</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.fio2"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.inhalionalagent">
                                            <td class="col-8">Inhalional Agent</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.inhalionalagent"> </td>
                                        </tr>
                                        <tr *ngIf="anaesthesiaRecord.temperature">
                                            <td class="col-8">Temperature (Fh)</td>
                                            <td class="col-4" [textContent]="anaesthesiaRecord.temperature"> </td>
                                        </tr>

                                    </table>
                                    <div>

                                        <table class="table">
                                            <th> Vitals</th>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>SPO2</th>
                                                    <th>ETCO2</th>
                                                    <th>FIO2</th>
                                                    <th>Inhalional Agent</th>
                                                    <th>Temperature</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of anaesthesiaRecord.vitalshistory">
                                                    <td>
                                                        <span>{{item.vitalsdate |date:'dd/MM/yyyy'}}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{item.vitalstime }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{item.vitalsSPO2 }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{item.vitalsETCO2 }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{item.vitalsFIO2 }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{item.vitalsInhalionalagent }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{item.vitalsTemperature}}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row topHeading">
                    <div class="col-12 pl-3">
                        <div class="row mb-0">
                            <label class="mb-0 text-mute">Prescribed By</label>
                        </div>
                        <div class="row" *ngIf="fullTranscript.signature">
                            <img [src]="fullTranscript.signature" alt="clinic logo" class="img-fluid" style="height: 100px !important; width: 100px !important;" />
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <address>
                                    <h5 class="font-family-primary font-weight-bold text-uppercase" [textContent]="fullTranscript.providerName"></h5>
                                    <h6 class="font-weight-light font-14" [textContent]="fullTranscript.specializationName"></h6>
                                    <h6 class="font-weight-light font-14" [textContent]="fullTranscript.practiceName"></h6>
                                    <h6 *ngIf="fullTranscript.providerNo" class="font-weight-light font-16" [textContent]="'License No: '+ fullTranscript.providerNo"></h6>
                                    <h6 class="font-weight-normal font-10" [textContent]="fullTranscript.practiceLocation"></h6>
                                </address>
                            </div><div class="col-md-4"></div>
                            <div class="col-md-4">
                                <div class="row">
                                    <div *ngIf="appointment && appointment.signature!=null">
                                        <!-- <img [src]="iconBasics.imagePath" [hidden]="loading" alt="icon" style="width: 100%" />-->
                                        <img [src]="safe(appointment.signature)" class="img-fluid" style="height: 100px !important; width: 100px !important;" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isFooter">
                    <banner-setting [reportName]='"Ob FullTranscript Footer"'></banner-setting>
                </div>
                <hr />
            </ng-container>
        </div>
    </div>
</div>

