import { ActiveStatus, Dates } from "../../shared/helper";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

export enum WhenType {
    Now = 1,
    Later = 2,
    Taken = 3,
    NotStarted = 4,
    Pending = 5
}

export enum ViewType {
    Scroll = 1,
    Grid = 2,
    Time = 3,
    Patients
}

export enum FilterType {
    All = 1,
    CurrentAndNext = 2,
    Taken = 3,
    Missed = 4
}

export class DatesViewModel {
    dates: Dates;
    allDates: Array<Date>;
}

export class ProgressReportMedication {
    admissionId: number;
    progressReportMedicationId: number;
    pharmacyProductId: number;
    unit: number;
    duration: number;
    medicationDurationTypeId: number;
    takenByName: any
    active: boolean;
    createdDate: Date;
    createdByName: string;
    modifiedDate: Date;
    modifiedByName: string;
    progressReportId: number;
    instructions: string;
    stopReason: string;
    startDate: Date;
    endDate: Date;
    productName: string;
    productIcon: string;
    productTypeName: string;
    productGenericName: string;
    productCompanyName: string;
    productSupplierName: string;

    activeStatus: ActiveStatus;
    isMedicineTaking: boolean;
    hasMove: boolean;

    when: WhenType;

    medicationInstructionTypeId: number;
    medicationInstructionDeepTypeId: number;
    medicationInstructionName: string;
    progressReportMedicationFrequencyId: number;
    status: boolean;

    salutation: string;
    fullName: string;
    age: number;
    gender: string;
    Umrno: string;
    patientImage: string;

    breakfastModel: TimeSlotHelper;
    lunchModel: TimeSlotHelper;
    dinnerModel: TimeSlotHelper;
    timeSlot: TimeSlotHelper;
    timeSlots: Array<TimeSlotHelper>;
}

export class TimeSlotHelper {
    hour: number;
    meridiemHour: number;
    minute: string;
    name: string;
    isTaking: boolean;
    hasMove: boolean;

    progressReportMedicationFrequencyId: number;
    activeStatus: ActiveStatus;
    status: boolean;
    hasMoveLoading: boolean;
    when: WhenType;

    actionName: string;

    interval: any;
    barWidth: number;
    showBar: boolean;
}

export class DateHolder {
    date: NgbDate;
    fDate: Date;
}

export class MarkDetails {
    left: number;
    hour: number;
    time: Date;
}

export interface IMoveModel {
    minute: number;
    hour: number;
    time: Date;
    reason: string;
}