<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">
                        <button type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-hidden="true"
                                (click)="onCloseModal();">
                            <i class="mdi mdi-close"></i>
                        </button>
                    </div>
                    <h4 class="page-title">Pharmacy Indent</h4>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-3 col-xl-3">
                <div class="headingStyle">
                    <div class="trapezium font-weight-semibold text-white">
                        <span class="p-1"><i class="mdi mdi-pill me-1"></i> Raise New Indent</span>

                    </div>
                    <span class="p-1">
                        <input class=" form-check-input " type="checkbox" (change)="onDepartmentSelect()" [(ngModel)]="toDepartment" />
                        <label class="form-check-label text-dark">To Department</label>
                    </span>
                </div>


                <div class="card p-1">
                    <form [formGroup]="indentForm" (submit)="onSubmit()">
                        <div class="row">


                            <div class="col-12" *ngIf="!toDepartment">
                                <div class="form-group">
                                    <label class="mb-0">Retail Store <code>*</code></label>
                                    <ng-select class="ng-select-sm" [items]="stores" autofocus
                                               [loading]="storeLoading"
                                               bindLabel="retailName"
                                               bindValue="retailPharmacyId"
                                               placeholder="Enter retail Name"
                                               formControlName="retailPharmacyId"
                                               [ngClass]="{ 'is-invalid': (submitted && form.retailPharmacyId.errors) }"
                                               (change)="onSelectRetailStore($event)">
                                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                            <div class="ng-option disabled" *ngIf="searchTerm">
                                                No store found for '{{searchTerm}}'
                                            </div>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-i="index">
                                            <div class="media">
                                                <div class="media-body">
                                                    <h5 class="mb-0 mt-0 font-13 font-weight-normal">{{item.retailName}}({{item.wareHouseNames}})</h5>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>

                            <div *ngIf="toDepartment" class="col-12">
                                <div class="form-group">
                                    <label class="mb-0">Pharmacy Departments <code>*</code></label>
                                    <ng-select class="ng-select-sm"
                                               [items]="departments"
                                               [loading]="loadingDepartments"
                                               bindLabel="name"
                                               bindValue="pharmacyDepartmentId"
                                               placeholder="Enter Department Name"
                                               formControlName="pharmacyDepartmentId"
                                               [ngClass]="{ 'is-invalid': (submitted && indentForm.pharmacyDepartmentId?.errors) }"
                                                (change)="onSelectDepartment($event)">
                                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                            <div class="ng-option disabled" *ngIf="searchTerm">
                                                No department found for '{{searchTerm}}'
                                            </div>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-i="index">
                                            <div class="media">
                                                <div class="media-body">
                                                    <h5 class="mb-0 mt-0 font-13 font-weight-normal">{{item.name}}</h5>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>

                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label class="mb-0">Product <code>*</code></label>
                                    <ng-select class="ng-select-sm" [items]="products"
                                               [loading]="loadingProduct"
                                               bindLabel="searchParam"
                                               bindValue="searchParam"
                                               placeholder="Search Product Name"
                                               formControlName="productName"
                                               (search)="fetchProductsHelper($event)"
                                               (change)="onSelectProduct($event)">
                                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                            <div class="ng-option disabled" *ngIf="searchTerm">
                                                No Product found for '{{searchTerm}}'
                                            </div>
                                            <div class="ng-option disabled" *ngIf="products.length ===0 ">
                                                Type to start searching
                                            </div>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-i="index" let-searchTerm="searchTerm">
                                            <div>
                                                <h5 class="mb-0 mt-0 font-13 font-weight-normal">
                                                    <ngb-highlight [result]="item.productName" [term]="searchTerm"></ngb-highlight>
                                                </h5>
                                                <span class="text-muted"><span [textContent]="item.companyName"></span></span>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label class="mb-0">Qty In Hand </label>
                                    <input type="text" placeholder=" Quantity In Hand" numbersOnly readonly class="form-control" formControlName="quantityInHand" />
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="mb-0">Category</label>
                                    <ng-select class="ng-select-sm" [items]="category"
                                               [loading]="loadingCategory"
                                               bindLabel="value"
                                               bindValue="id"
                                               placeholder="Enter category Name"
                                               formControlName="categoryId"
                                               [ngClass]="{ 'is-invalid': (submitted && form.categoryId.errors) }">
                                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                            <div class="ng-option disabled" *ngIf="searchTerm">
                                                No category found for '{{searchTerm}}'
                                            </div>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-i="index">
                                            <div class="media">
                                                <div class="media-body">
                                                    <h5 class="mb-0 mt-0 font-13 font-weight-normal">{{item.value}}</h5>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>


                            <div class="col-12">
                                <div class="form-group">
                                    <label class="mb-0">Quantity <code>*</code></label>
                                    <input type="text" placeholder="Enter Quantity" numbersOnly class="form-control" formControlName="quantity" [ngClass]="{ 'is-invalid': (submitted && form.quantity.errors) }" />
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="mb-0">Reason</label>
                                    <textarea type="text" rows="2" class="form-control" placeholder="Enter reason for requirement" formControlName="reason"></textarea>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="d-flex justify-content-center">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        <span *ngIf="submitting">
                                            <span class="spinner-border text-white spinner-border-sm mr-1"></span> Please Wait
                                        </span>
                                        <span *ngIf="!submitting">
                                            <i class="mdi mdi-plus-thick mr-1"></i>Add
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-sm-12 col-md-8 col-lg-9 col-xl-9">
                <div class="headingStyle">
                    <div class="trapezium font-weight-semibold text-white">
                        <span class="p-1"><i class="mdi mdi-medical-bag me-1"></i> Added Indent's</span>
                    </div>
                </div>
                <div class="card p-0">
                    <div class="row" *ngIf="loadingdata || isFinalBillLoading">
                        <div class="col-12">
                            <div class="d-flex align-items-center">
                                <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                                <span class="ml-2">Please wait while loading...</span>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="!loadingdata && !isFinalBillLoading && (!productdetail || !productdetail.length)">
                        <div class="col-12 mh-300">
                            <no-data [applied]="false" [title]="'Request'"></no-data>
                        </div>
                    </div>
                    <div class="overflow-auto" *ngIf="!loadingdata && !isFinalBillLoading && productdetail.length > 0" style="max-height: 550px;overflow: auto;">
                        <table class="table table-sm table-bordered table-centered">
                            <thead class=" ">
                                <tr>
                                    <th>Product Name</th>
                                    <th>Requested Date</th>
                                    <th>Requested By</th>
                                    <th>Requested Quantity</th>
                                    <th>Approved Quantity</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-template ngFor let-item [ngForOf]="productdetail" let-i="index">
                                    <tr>
                                        <td>
                                            <p class="text-truncate-line mb-0" [textContent]="item.issueProductName || '----'"></p>
                                            <small [textContent]="item.genericName"></small>
                                        </td>
                                        <td>
                                            <p class="text-truncate-line mb-0" [textContent]="item.requestedDate ? (item.requestedDate | date:'dd-MM-yyyy, hh:mm a') : item.createdDate | date:'dd-MM-yyyy, hh:mm a'"></p>
                                        </td>
                                        <td>
                                            <!--<p class="text-truncate-line mb-0" [textContent]="item.createdByName || '----'"></p>-->
                                            <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.createdByName || '----'"></h5>
                                            <span class="d-block font-13">
                                                <span [textContent]="item.createdByRoleName"></span>
                                            </span>
                                        </td>

                                        <td>
                                            <p class="mb-0" [textContent]="item.quantity"></p>
                                        </td>
                                        <td>
                                            <div class="media" *ngIf="item.issueQuantity">
                                                <div class="media-body font-12">
                                                    <span class="d-block mb-0"><strong>Approved :</strong> <span [textContent]="item.issueQuantity || '-----'"></span></span>
                                                    <span class="d-block mb-0" *ngIf="item.returnQuantity">
                                                        <strong>Returned :</strong> <span [textContent]="item.returnQuantity || '-----'"></span>
                                                    </span>
                                                    <span class="d-block mb-0" *ngIf="item.returnQuantity">
                                                        <strong>Available :</strong> <span [textContent]="(item.issueQuantity - item.returnQuantity) || 0"></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span *ngIf="item.status === 'R'" class="mb-0 badge badge-danger">Rejected</span>
                                            <span *ngIf="item.status === 'P' " class="mb-0 badge badge-warning">Not Approved</span>
                                            <span *ngIf="item.status === 'PA'" class="mb-0 badge badge-warning">Partially Approved</span>
                                            <span *ngIf="item.status === 'A' " class="mb-0 badge badge-success">Approved</span>
                                            <span *ngIf="item.status === 'O'" class="mb-0 badge badge-info">Out of stock</span>
                                            <span class="d-block mb-0" *ngIf="item.status === 'A' || item.status === 'PA' ">
                                                <span class="badge badge-blue"><i class="mdi mdi-store mr-1"></i> {{item.retailName}}</span>
                                            </span>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-end align-items-center">
                                                <a *ngIf="item.status === 'P' "
                                                   href="javascript:;"
                                                   class="action-icon text-danger mr-1"
                                                   (click)="onDeleteRequest(item)"
                                                   placement="left"
                                                   ngbTooltip="Delete">
                                                    <i class="mdi mdi-delete"></i>
                                                </a>
                                                <a *ngIf="item.status === 'R'"
                                                   href="javascript:;"
                                                   class="action-icon text-primary mr-1"
                                                   (click)="onGetPrescription(templateOPTicketWithPrescription, item)"
                                                   placement="left"
                                                   ngbTooltip="View Prescription">
                                                    <i class="mdi mdi-eye"></i>
                                                </a>
                                                <a *ngIf="item.status === 'A' || item.status === 'PA'"
                                                   href="javascript:;"
                                                   class="action-icon text-primary mr-1"
                                                   (click)="onOpenReturnForm(templateReturn, item)"
                                                   placement="left"
                                                   ngbTooltip="Return">
                                                    <i class="mdi mdi-keyboard-return"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #templateReturn>
    <form [formGroup]="returnForm" (ngSubmit)="onSubmitReturn()">
        <div class="modal-header">
            <h4 class="modal-title d-print-none"><i class="fe-shopping-cart mr-1"></i>Medication Return Request</h4>
            <div>
                <button type="button" class="close d-print-none" data-dismiss="modal" aria-hidden="true" (click)="onCloseTicketModal();">×</button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12" *ngIf="returnLoading">
                    <div class="d-flex align-items-center">
                        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                        <span class="ml-2">Please wait while loading previous return information...</span>
                    </div>
                </div>
                <div class="col-12" *ngIf="!returnLoading && returnModel.length > 0">
                    <div class="headingStyle">
                        <div class="trapezium font-weight-semibold text-white">
                            <span class="p-1"><i class="mdi mdi-cart-arrow-down me-1"></i> Returned Records</span>
                        </div>
                    </div>
                    <div class="card p-0">
                        <table class="table table-sm table-bordered">
                            <thead class=" ">
                                <tr>
                                    <th>Request Created By</th>
                                    <th>Return Quantity</th>
                                    <th>Status</th>
                                    <th>Approved By</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let record of returnModel;index as i;">
                                    <td>
                                        <h6 class="mb-0"><span [textContent]="record.createdByName"></span></h6>
                                        <span class="d-block text-muted" [textContent]="record.createdDate | date: 'dd-MM-yyyy, hh:mm a'"></span>
                                    </td>
                                    <td>
                                        <span class="mb-0 font-weight-bold" [textContent]="record.returnQuantity"></span>
                                    </td>
                                    <td>
                                        <span *ngIf="record.acceptedByName" class="badge badge-outline-success">Accepted</span>
                                        <span *ngIf="!record.acceptedByName" class="badge badge-outline-danger">Pending</span>
                                    </td>
                                    <td>
                                        <div class="mb-0" *ngIf="record.acceptedByName">
                                            <h6 class="mb-0"><span [textContent]="record.acceptedByName"></span></h6>
                                            <span class="d-block text-muted" [textContent]="record.acceptedDate | date: 'dd-MM-yyyy, hh:mm a'"></span>
                                        </div>
                                        <span *ngIf="!record.acceptedByName">----</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-12">
                    <table class="table table-borderless table-sm">
                        <tr>
                            <th>Product Description</th>
                            <th>Issued Quantity</th>
                            <th>ReturnPrev</th>
                            <th>Return</th>
                        </tr>
                        <tr>
                            <td>
                                <span class="text-truncate-line mb-0" [textContent]="selectedDetail.issueProductName || '----'"></span>
                                <small class="d-block text-muted" [textContent]="selectedDetail.genericName"></small>
                            </td>
                            <td><span class="font-weight-bolder" [textContent]="selectedDetail.issueQuantity"></span></td>
                            <td><span class="font-weight-bolder" [textContent]="selectedDetail.returnQuantity"></span></td>
                            <td>
                                <input type="number" min="0" class="form-control" placeholder="Enter return qty" formControlName="returnQuantity" numbersOnly
                                       [ngClass]="{ 'is-invalid': (submitted && formReturn.returnQuantity.errors) }" />
                                <div class="show-must text-danger" *ngIf="submitted && formReturn.returnQuantity.errors">
                                    <span *ngIf="formReturn.returnQuantity.errors.required">Return quantity is required.</span>
                                    <span *ngIf="formReturn.returnQuantity.errors.max">Given quantity must be smaller or equal to {{returnForm.value.available}}</span>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary btn-sm">
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting">Submit</span>
            </button>
        </div>
    </form>
</ng-template>


<ng-template #tempalteSearchResults let-item="result" let-term="term" let-i="index">
    <div class="media">
        <div class="media-body">
            <ngb-highlight [result]="item.value" [term]="term"></ngb-highlight><br />
            <ngb-highlight class="font-13" [result]="item.optionalText2" [term]="term"></ngb-highlight>
        </div>
      
    </div>
</ng-template>


<ng-template #templateOPTicketWithPrescription>
    <div Id="OPId">
        <div class="modal-header">
            <h4 class="modal-title d-print-none"><i class="fe-calendar mr-1"></i>Out Patient Ticket</h4>
            <div>
                <img class="d-none d-print-none" width="135" src="assets/images/logo.png" alt="Careaxes" />
                <div class="btn-group d-print-none">
                    <button class="btn btn-sm" [class.btn-light]="!isPrintLogo" [class.btn-outline-light]="isPrintLogo" type="button" (click)="onChangePrintType(false)"><i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo</button>
                    <button class="btn btn-sm" [class.btn-light]="isPrintLogo" [class.btn-outline-light]="!isPrintLogo" type="button" (click)="onChangePrintType(true)"><i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo</button>
                </div>
                <button [useExistingCss]="true" printSectionId="OPId" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none"><i class="fe-printer mr-1"></i>Print</button>
                <button type="button" class="close d-print-none" data-dismiss="modal" aria-hidden="true" (click)="onCloseTicketModal();">×</button>
            </div>
        </div>
        <div class="modal-body bg-light">
            <div class="table-responsive-sm table-responsive-lg table-responsive-md">
                <table width="894px" border="0" cellspacing="0" cellpadding="0" style="margin: 0px auto;
            border-bottom: 1px solid #333; border-collapse: unset;width: 100%" [ngStyle]="{'border': isPrintLogo ? '1px solid #333' : 'none'}">
                    <tbody>
                        <tr style="background: #f3f3f3;" *ngIf="isPrintLogo">
                            <td colspan="2">
                                <img [src]="bannerBasics != null ? bannerBasics.imagePath : myImgUrl" [hidden]="loading" alt="" style="width: 100%" />
                                <!--<img src="assets/images/careaxesLogo.png" alt="" style="width: 100%">-->
                            </td>
                        </tr>
                        <tr *ngIf="!isPrintLogo">
                            <td colspan="2" style="height: 260px;">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <h4 style="font-family: 'Open Sans'; font-size: 17px;font-weight: bolder; color: #06C; margin: 0px; padding: 5px;
                        text-align: center; border-top: 1px solid #000;">
                                    PRESCRIPTION
                                </h4>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <table width="100%" border="0" cellspacing="0" cellpadding="5" style="border-top: 1px solid #333;
                        border-bottom: 1px solid #333; font-family: 'Open Sans'; font-size: 16px;font-weight: bolder;">
                                    <tbody>
                                        <tr>
                                            <td style="font-weight: bold">
                                                Patient Name
                                            </td>
                                            <td>
                                                :
                                            </td>
                                            <td class="text-uppercase">
                                                {{selectedAppointment.patientName}}
                                            </td>
                                            <td style="width: 10%">
                                                &nbsp;
                                            </td>
                                            <td style="font-weight: bold">
                                                Age / Gender
                                            </td>
                                            <td>
                                                :
                                            </td>
                                            <td>
                                                {{selectedAppointment.patientAge}} (Yrs) / {{selectedAppointment.patientGender | gender}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-weight: bold">
                                                UMR No
                                            </td>
                                            <td>
                                                :
                                            </td>
                                            <td>
                                                {{selectedAppointment.umrNo}}
                                            </td>
                                            <td style="width: 10%">
                                                &nbsp;
                                            </td>
                                            <td style="font-weight: bold">
                                                Mobile No
                                            </td>
                                            <td>
                                                :
                                            </td>
                                            <td>
                                                {{selectedAppointment.patientMobile}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="8">
                                <table width="100%" cellpadding="5" style="font-family: 'Open sans'; font-size: 16px;font-weight: bolder;border-collapse: collapse;">
                                    <tbody>
                                        <tr>
                                            <td style="font-weight: bold; vertical-align: top">
                                                Doctor Name
                                            </td>
                                            <td style="font-weight: bold; vertical-align: top">
                                                :
                                            </td>
                                            <td style="font-weight: bold" class="text-uppercase">
                                                Dr. {{selectedAppointment.providerName}}
                                            </td>
                                            <td>
                                                &nbsp;
                                            </td>
                                            <td style="font-weight: bold; vertical-align: top">
                                                Department
                                            </td>
                                            <td style="font-weight: bold; vertical-align: top">
                                                :
                                            </td>
                                            <td style="font-weight: bold; vertical-align: top">
                                                {{selectedAppointment.departmentName}}
                                            </td>
                                        </tr>
                                        <!--<tr>
                                            <td colspan="2">
                                                <table border="0" cellpadding="5" cellspacing="0" style="border-bottom: 1px solid #333;font-family: 'Open Sans';font-size: 16px;font-weight: bolder;" width="100%">
                                                    <tbody><tr><td style="text-align: center;padding: 0px;font-size: 14px;text-transform: uppercase;">Pharmacy Products</td></tr></tbody>
                                                </table>
                                            </td>
                                        </tr>-->
                                        <tr>
                                            <td colspan="8">
                                                <div style="min-height:700px;">
                                                    <table width="100%" cellpadding="5" style="font-family: 'Open sans'; font-size: 16px;
                                    border-collapse: collapse; border-right: none; border-left: none;border-bottom: none;">
                                                        <tbody>
                                                            <tr>
                                                                <th style="font-weight: bold; vertical-align: top">Product Name</th>
                                                                <th style="font-weight: bold; vertical-align: top">Requested Quantity</th>
                                                            </tr>
                                                            <tr *ngFor="let record of selectedProduct;let index = i;">
                                                                <td style="font-weight: bold; vertical-align: top" [textContent]="record.issueProductName"></td>
                                                                <td style="font-weight: bold; vertical-align: top" [textContent]="record.quantity"></td>
                                                            </tr>
                                                            <tr></tr>
                                                        </tbody>
                                                    </table>
                                                    <table width="100%" cellpadding="5" style="font-family: 'Open sans'; font-size: 16px;
                                    border-collapse: collapse; border-right: none; border-left: none;border-bottom: none;">
                                                        <tbody>
                                                            <!--<tr>
                                                                <td style="font-weight: bold;border-color: #ffffff00;">
                                                                    Date &amp; Time
                                                                </td>
                                                                <td style="font-weight: bold; text-align: center;border-color: #ffffff00;">
                                                                    Clinical Notes / Investigations
                                                                </td>
                                                                <td style="font-weight: bold; text-align: center;border-color: #ffffff00;">
                                                                    Treatment
                                                                </td>
                                                            </tr>-->
                                                            <tr class="d-print-block" [ngStyle]="{'height': isPrintLogo ? '720px' : '700px'}" [ngClass]="{ 'watermark': isPrintLogo }">
                                                                <td style="border-color: rgb(243 247 249);"></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style="border-bottom: 1px solid #000; border-top: 1px solid #000;" *ngIf="isPrintLogo">
                                            <td colspan="8">
                                                <strong>Note:</strong> If there are any side effects with the medicine, stop the
                                                medicine and report immediately to the hospital.
                                            </td>
                                        </tr>
                                        <tr *ngIf="isPrintLogo">
                                            <td colspan="8" style="text-align: center">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr *ngIf="isPrintLogo">
                            <td colspan="11" style="font-family: 'Open Sans'; font-size: 12px; color: #000;">
                                <table width="100%">
                                    <tbody>
                                        <tr>
                                            <td style="text-align: left; font-size: 12px;">
                                                Suggest Clinical Correlation * If neccessary, Please discuss<br>
                                                Verified By : <br>
                                                Issued By : <span [textContent]="page.userAccount.fullName"></span><br>
                                                Test results related only to the item tested<br>
                                                No part of the report can be reproduced without written permission of the laboratory.
                                            </td>
                                            <td style="text-align: right; text-transform: uppercase; font-size: 12px;">
                                                Dr. {{selectedAppointment.providerName}}<br>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr *ngIf="!isPrintLogo" style="height: 100px;">
                            <td colspan="11" style="font-family: 'Open Sans'; font-size: 12px; color: #000;">
                                <table width="100%" style="margin-top: 50px;">
                                    <tbody>
                                        <tr>
                                            <td style="text-align: left; font-size: 12px;">
                                                Issued By : <span [textContent]="page.userAccount.fullName"></span><br>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>
