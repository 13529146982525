import { HttpService } from '../services/http.service';
import { NotifyService } from '../services/notify.service';
import { ApiResources } from "../helpers";
import { Setting } from "@shared/entities";
import { lastValueFrom } from 'rxjs';

export class SmsService {
    smsServiceActive: boolean = null;
    emailServiceActive: boolean = null;
    constructor() {
        this.smsServiceActive = null;
        this.emailServiceActive = null;
    }

    async getCommonSettings(httpService: HttpService, serviceName: string) {
        try {
            const response: Setting[] = await lastValueFrom(
                httpService.get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), { name: serviceName })
            );
            if (serviceName == "SmsService") {
                this.smsServiceActive = response.length > 0 ? response[0].active : false;
                return this.smsServiceActive;
            }
            if (serviceName == "EmailService") {
                this.emailServiceActive = response.length > 0 ? response[0].active : false;
                return this.emailServiceActive;
            }
        } catch (error) {
            console.error('Error fetching settings:', error);
            return null;
        }
    }

    async sendMessageAsync(body: Record<any, any>, httpService: HttpService, notifyService: NotifyService) {
        if (this.smsServiceActive == null) {
            this.smsServiceActive = await this.getCommonSettings(httpService, "SmsService");
        }
        if (this.smsServiceActive == false) {
            return;
        }
        else if (this.smsServiceActive == true) {
            const settings = JSON.parse(localStorage.getItem("settings"));
            var url = settings.whatsAppService.base + "sendSms";
            var contactNumber = settings.whatsAppService.contactNumber;
            var foundationName = settings.whatsAppService.practiceName;
            body['FoundationName'] = foundationName;
            body['PracticeName'] = foundationName;
            body["ContactNumber"] = contactNumber;
            httpService.post(url, body).subscribe((response: any) => {
                
                notifyService.successToast("The  message has sent !!")
            },
                (error: any) => {
                    notifyService.errorToast("Could not send message" + error); console.log(error)
                });
        }
    }

    async sendEmailAsync(body: Record<any, any>, httpService: HttpService, notifyService: NotifyService) {
        if (this.emailServiceActive == null) {
            this.emailServiceActive = await this.getCommonSettings(httpService, "EmailService");
        }
        if (this.emailServiceActive == false) {
            return;
        }
        else if (this.emailServiceActive == true) {
            const settings = JSON.parse(localStorage.getItem("settings"));
            var url = settings.whatsAppService.base + "SendEmail";
            var contactNumber = settings.whatsAppService.contactNumber;
            var foundationName = settings.whatsAppService.practiceName;
            var Source = settings.EmailService.AppointmentEmail;
            var Passcode = settings.EmailService.AppointmentCode;
            body['FoundationName'] = foundationName;
            body['PracticeName'] = foundationName;
            body["ContactNumber"] = contactNumber;
            body["Source"] = Source;
            body["Passcode"] = Passcode;
            httpService.post(url, body).subscribe((response: any) => {
                notifyService.successToast("The  Email has sent !!")
            },
                (error: any) => {
                    notifyService.errorToast("Could not send message" + error); console.log(error)
                });
        }
    }
}