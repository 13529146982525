import { Component, OnDestroy, OnInit } from "@angular/core";
import { ApiResources, UtilHelper } from "@shared/helpers";
import { AppData, HttpService, NotifyService } from "@shared/services";
import { Page, IUserAccount, Pagination, } from "@shared/models";
import { finalize, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { ChargeManagement } from "../../../../../shared/entities";
import { DietItems } from "../../../../../shared/entities/diet-items.entity";
import { HttpErrorResponse } from "@angular/common/http";
class FilterOptions {
    chargeName: string ;
    chargeGroupName: string ;
}

class Filters {
    options: FilterOptions;
    applied: boolean;
    constructor() {
        this.init();
    }
    init() {
        this.options = new FilterOptions();
        this.applied = undefined;
    }
}

@Component({
    templateUrl: "./charges-configuration.html",
})
export class CharagesConfigurationPage implements OnInit, OnDestroy {
    page: Page;
    loading: boolean;
    
    records: Array<ChargeManagement>;
    pagination: Pagination;
    filters: Filters;
    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly router: Router,
        private readonly notifyService: NotifyService,
    ) {
        this.loading = true;
        this.page = new Page();       
        this.records = new Array<ChargeManagement>;
        this.initPagination()
        this.filters = new Filters();
    }

    fetchChargesConfiguration() {
        this.loading = true;
       
        const request = Object.assign(UtilHelper.clone(this.filters.options), UtilHelper.clone(this.pagination));

        this.httpService
            .get(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchChargeConfiguration), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<ChargeManagement>) => {
                    if (response.length > 0) {
                        this.records = response;

                    }
                    else {
                        this.records = [];
                    }
                    UtilHelper.applyPagination(this.records, this.pagination);
                },
            );
    }


    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchChargesConfiguration();
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }
  
    private initPagination() {
        this.pagination = new Pagination();
        this.pagination.pageSize = 10;
        this.pagination.pageIndex = 1;
    }
    onNextPage() {
        $("body,html").animate({ scrollTop: 0 });
        this.fetchChargesConfiguration();
    }
    onModifyStatus(item: ChargeManagement, status: boolean) {
        this.notifyService.confirm(`You want to ${status ? 'Activate' : 'Deactivate'} this Charge Configuration ?`, () => {
            const request = {
                createdBy: this.page.userAccount.accountId,
                isAutomaticAdd: status,
                chargeId: item.chargeId,
                chargeGroupId: item.chargeGroupId,
                modifiedBy: this.page.userAccount.accountId,
                modifiedByName: this.page.userAccount.fullName,
                chargeGroupName: item.chargeGroupName,
                chargeName: item.chargeName,
                LoginRoleId: this.page.userAccount.roleId

            };

            var url = ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.modifyChargeConfigurationStatus);
            this.httpService.post(url, request)
                .subscribe(
                    (response: number) => {
                        if (response > 0) {
                            this.notifyService.successToast(`Record ${request["chargeName"] ? 'Activated' : 'Deactivated'} successfully.`);
                            this.fetchChargesConfiguration();
                        }
                    },
                    (error: HttpErrorResponse) => {
                        this.notifyService.errorToast(error.error);
                    }
                );
        });
    }

    onApplyFilters() {
        this.filters.applied = UtilHelper.isFiltersApplied(this.filters.options);
        this.loading = true;
        this.initPagination();
        this.fetchChargesConfiguration();
    }
    onResetFilters() {
        this.filters.init();
        this.fetchChargesConfiguration();
    }
}