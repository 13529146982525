<div *ngIf="toggleService.showBox" class="appointments-box h-100 card p-3px border-left-darkInfo border-top-darkInfo">
    <div class="timeline-close-btn" (click)=" toggleIt()">
        <button class="encounterBtn m-1 rounded-pill height30px">
            <img class="h-100" src="assets/images/timelineSvg/time-line.svg" /> <i style="margin-left: -6px " class="timelineIconsAign mdi mdi-chevron-right font-26"></i>
        </button>
    </div>
    <div class="d-flex fit-height">
        <div class="d-flex justify-content-between align-items-center">
            <div class="w-100">
                <div class="d-flex align-items-center gap10px">
                    <div class="p-1" *ngIf="appointment && appointment.patientThumbnailUrl != ''">
                        <img *ngIf="appointment && appointment.patientThumbnailUrl != ''" [src]="appointment.patientThumbnailUrl != null ? safe(appointment.patientThumbnailUrl) : defaultImgUrl" class="rounded-circle avatar-lg">
                    </div>
                    <div class="avatar-lg p-1" *ngIf="appointment && appointment.patientThumbnailUrl == ''">
                        <!--<img *ngIf="appointment.patientThumbnailUrl" [src]="appointment.patientThumbnailUrl" class="rounded-circle avatar-lg" alt="profile-image">-->
                        <span *ngIf="appointment && appointment.patientThumbnailUrl == ''" class="avatar-title avatar-lg bg-primary text-white rounded-circle" [textContent]="appointment && appointment.patientName | initials"></span>
                    </div>
                    <div class="text-black">
                        <div class="d-flex flex-wrap align-items-center column-gap5px">
                            <h4 *ngIf="appointment && appointment.patientName" class="font-16 font-weight-normal text-uppercase"><a class="font-weight-normal text-uppercase" href="javascript:;">{{appointment.patientName}}</a></h4>
                            <div>
                                <span class="badge badge-success" *ngIf="isAdmission">In Patient</span>
                                <span class="badge badge-warning" *ngIf="!isAdmission">Out Patient</span>
                            </div>
                        </div>
                        <div *ngIf="appointment && appointment.umrNo" class="d-flex white-space-nowrap">
                            <div class="align-self-end">
                                <div class="white-space-nowrap line-height-normal"><span *ngIf="appointment && appointment.umrNo" [textContent]="appointment.umrNo"></span></div>
                            </div>
                        </div>
                        <div *ngIf="appointment && appointment.patientAge" class="d-flex white-space-nowrap">
                            <div class="d-flex align-self-end">
                                <div class="line-height-normal" *ngIf="appointment && appointment.patientDateOfBirth" [textContent]="appointment.patientDateOfBirth | date:'dd/MM/yyyy' "></div>
                                <div class="line-height-normal" *ngIf="appointment && appointment.patientAge" [textContent]="' (' + appointment.patientAge + ' yrs)'  "></div>
                                <div class="line-height-normal" *ngIf="appointment && !appointment.patientAge" [textContent]="' (0 yrs)'  "></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="sectionscroll border-bottom" style=" height: calc(100vh - 147px); overflow: auto;">
        <div class="d-flex justify-content-between align-items-start w-100">
            <div class="w-100" style="line-height:34px;">

                <div class="d-flex border font-14 flex-wrap text-black font-weight-semibold w-100">
                    <div style="padding: 0px 3px;" class="d-flex flex-column flex-grow-1 justify-content-between">

                        <div class="d-flex mb-1 white-space-nowrap">
                            <i class="icon-advanced-gendar align-self-end"></i>

                            <div class="align-self-end pl-2px">
                                <div style="line-height:4px;">Gender</div>
                                <div *ngIf="appointment && appointment.patientGender" class="line-height-normal"><span [textContent]="appointment.patientGender | gender "></span></div>
                                <div *ngIf="appointment && appointment.patientGender==null" class="white-space-nowrap text-primary line-height-normal"><span>no record</span></div>
                            </div>
                        </div>
                        <div class="d-flex mb-1 white-space-nowrap">
                            <i class="icon-advanced-telephone align-self-end"></i>
                            <div class="align-self-center pl-2px">
                                <div style="line-height: 10px;" class="mt-1" [textContent]="'(+'+ appointment?.patientCountryCode +') '"></div>
                                <div style="line-height: 17px;" class="white-space-nowrap"><span *ngIf="appointment && appointment.patientMobile" [textContent]="appointment.patientMobile"></span></div>
                                <div *ngIf="appointment && appointment.patientMobile==null" class="white-space-nowrap text-primary line-height-normal"><span>no record</span></div>
                            </div>
                        </div>
                        <!--<div class="d-flex mb-1 white-space-nowrap heading-color1" *ngIf="encounterType !='pediatric-encounter'">
                            <i class="icon-advanced-edd-date align-self-end"></i>
                            <div class="align-self-end pl-2px">
                                <div style="line-height:4px;">EDD</div>
                                <div *ngIf="ancCards && ancCards.edd && ancCards.lmpStatus=='D5 Embryo transfer'" class="line-height-normal"><span [textContent]="ancCards.edd"></span></div>
                                <div *ngIf="ancCards && ancCards.edd && ancCards.lmpStatus=='D3 Embryo transfer'" class="line-height-normal"><span [textContent]="ancCards.edd"></span></div>
                                <div *ngIf="ancCards && ancCards.edd && ancCards.lmpStatus=='Known'" class="line-height-normal"><span [textContent]="ancCards.edd"></span></div>
                                <div *ngIf="ancCards && ancCards.ultraSoundEdd && ancCards.lmpStatus=='Unknown'" class="line-height-normal"><span [textContent]="ancCards.ultraSoundEdd | date: 'dd/MM/yyyy'"></span></div>
                                <div *ngIf="ancCards === null" class="white-space-nowrap text-primary line-height-normal"><span>no record</span></div>
                            </div>
                        </div>-->
                    </div>
                    <div style="padding: 0px 3px;" class="d-flex flex-column flex-grow-1 justify-content-between">
                        <div class="d-flex mb-1 white-space-nowrap">
                            <i class="icon-advanced-husband-name align-self-end"></i>
                            <div class="align-self-end pl-2px">
                                <div style="line-height:4px;">{{relationType}}</div>
                                <div class="line-height-normal text-primary" *ngIf="patient && patient.relativeName">{{appointment.relativeName}}</div>
                                <div class="white-space-nowrap text-primary line-height-normal" *ngIf="!(patient && patient.relativeName)"><span>no record</span></div>
                            </div>
                        </div>
                        <div class="d-flex mb-1 white-space-nowrap">
                            <i class="icon-advanced-account-number align-self-end"></i>
                            <div class="align-self-end pl-2px" *ngIf="encounterType!='gyn-encounter' && encounterType!='gyn-ip-encounter'">
                                <div style="line-height:4px;">Anc No</div>
                                <div class="line-height-normal" *ngIf="obEncounter && obEncounter.ancNo "><span [textContent]="obEncounter.ancNo"></span><span class="heading-color1" *ngIf="obEncounter && !obEncounter.active">&nbsp;(C)</span></div>
                                <div *ngIf="obEncounter && obEncounter.ancNo==null" class="white-space-nowrap text-primary line-height-normal"><span>no record</span></div>
                            </div>
                            <div class="align-self-end pl-2px" *ngIf="encounterType =='gyn-encounter' || encounterType =='gyn-ip-encounter'">
                                <div style="line-height:4px;">Gyn No</div>
                                <div class="line-height-normal" *ngIf="appointment && appointment.gynNo "><span [textContent]="appointment.gynNo"></span></div>
                                <div *ngIf="appointment && appointment.gynNo==null" class="white-space-nowrap text-primary line-height-normal"><span>no record</span></div>
                            </div>
                        </div>
                        <div class="d-flex mb-1 white-space-nowrap heading-color1">
                            <i class="icon-advanced-blood-group align-self-end"></i>
                            <div class="align-self-end pl-2px">
                                <div style="line-height:4px;">Blood Gr</div>
                                <div *ngIf="bloodgroup !=''" class="line-height-normal"><strong [textContent]="bloodgroup"></strong></div>
                                <div *ngIf="bloodgroup==''" class="white-space-nowrap text-primary line-height-normal"><span>no record</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between w-100">
            <div class="w-100">

                <ng-container *ngIf="isOb">
                    <span class="mdi mdi-file-document font-26 cursor-pointer text-primary" (click)="OnClickAllReports()" [ngbTooltip]="'OB Report'"></span>
                </ng-container>

                <ng-container *ngIf="isOb">
                    <span class="mdi mdi-file-pdf-box font-28 cursor-pointer text-primary" (click)="PatientInfoDocument()" [ngbTooltip]="'OB Document'"></span>
                </ng-container>

                <ng-container *ngIf="isGyne">
                    <span class="mdi mdi-file-document font-26 cursor-pointer text-primary" (click)="OnClickAllReportsGyne()" [ngbTooltip]="'Gyne Report'"></span>
                </ng-container>

                <ng-container *ngIf="isGyne">
                    <span class="mdi mdi-file-pdf-box font-28 cursor-pointer text-primary" (click)="PatientInfoDocumentGyne()" [ngbTooltip]="'Gyne Document'"></span>
                </ng-container>

                <ng-container *ngIf="isPed">
                    <span class="mdi mdi-file-document font-26 cursor-pointer text-primary" (click)="OnClickAllReportsPedtric()" [ngbTooltip]="'Pediatric Report'"></span>
                </ng-container>

                <ng-container *ngIf="isPed">
                    <span class="mdi mdi-file-pdf-box font-28 cursor-pointer text-primary" (click)="PatientInfoDocumentpediatric()" [ngbTooltip]="'Pediatric Document'"></span>
                </ng-container>


                <div class="align-items-center d-flex white-space-nowrap justify-content-end" style="gap:10px;">
                    <button *ngIf="roleName && roleName  == 'DOCTOR'" class=" btn-primary btn mdi mdi-plus" [ngbTooltip]="'Add Encounter'" (click)="onOpen(templateofAddAppointent);"></button>
                    <button class="bg-videochat btn-videochat btn" (click)="onCall()"></button>
                    <button class="bg-audiochat btn-audiochat btn" (click)="toggleMessageBox()"></button>
                </div>
                <div class="appointments-box card mb-0  position-sticky top-0">
                    <div class="font-17 font-weight-semibold p-1 border-bottom">
                        <div class="align-items-center d-flex justify-content-start">
                            <div class="form-group" style="width:130%;">

                                <select class="form-control h-32" (change)="onChange($event.target.value,true)" [(ngModel)]="selectedSpecializationName">
                                    <option value="All">All</option>
                                    <option *ngFor="let item of specializationlist" [value]="item.specializationName">{{ item.specializationName }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between" style="background-color: #E3FEF8;">
                        <div class="d-flex justify-content-between" style="background-color: #E3FEF8;">
                            <div *ngFor="let icon of dropdownlist" class="icon-container1" [ngbTooltip]="icon.sourceName" (click)="onMenuChange(icon)">
                                <img class="w-100" [src]="icon.image" />
                            </div>
                        </div>
                    </div>
                    <div class="border border-white card-body  pl-0 pr-0 pt-0" style="padding-bottom:2px;">

                        <div class="h-100">
                            <div class="bg-white">
                                <div class="border-bottom position-absolute w-100" style="top: 114px; "></div>

                                <form method="post" class="m-1 d-flex gap10px position-relative">

                                    <div class="form-group" style="width:130%;">
                                        <ng-container *ngIf="submenulist.length > 3; else radioButtons">
                                            <select class="form-control h-32" (change)="onSelectValue($event.target.value)">
                                                <option *ngFor="let icon of filteredSubmenuList()" [value]="icon.eventID">{{ icon.sourceName }}</option>
                                            </select>
                                        </ng-container>
                                        <ng-template #radioButtons>
                                            <div class="radio-buttons mt-3">
                                                <label *ngFor="let icon of filteredSubmenuList(); let i = index" class="radio-inline">
                                                    <input type="radio"
                                                           name="iconRadio"
                                                           [value]="icon.eventID"
                                                           [checked]="i === 0"
                                                           (change)="onSelectValue(icon.eventID,icon)">
                                                    {{ icon.sourceName }} &nbsp;&nbsp;
                                                </label>
                                            </div>
                                        </ng-template>
                                    </div>

                                    <div class="form-group col-12 d-flex mt-2" *ngIf="testinvestigation == 20">
                                        <div class="form-group mb-1 col-5">
                                            <input type="radio" value="outside" name="investigation" (click)="OnClickTest('outside')" />&nbsp;
                                            <label value="outside"> Outside</label>
                                        </div>
                                        <div class="form-group mb-1 col-5">
                                            <input type="radio" value="internal" name="investigation" (click)="OnClickTest('internal')" />&nbsp;
                                            <label value="internal"> Internal</label>
                                        </div>
                                    </div>
                                </form>

                                <div class="m-1 overflow-auto" style=" height: calc(100vh - 303px);">
                                    <div *ngIf="!(labs?.length > 0 ||(investigationCheckList && investigationCheckList?.length > 0) )" class="overflow-auto p-1 track-order-list">
                                        <ul class="list-unstyled ">

                                            <li class="completed cursor-pointer" *ngFor="let item of timelineData; let i = index;" (click)="onViewChange(item, templateTimelineView,templatePacsImage,templatePrintReceipt,templateFullTranscripit,templateprintReport,templateFullReport)">
                                                <div class="icon-container" *ngIf="item.eventID !== 43">
                                                    <img class="w-100" [src]="item.image">
                                                </div>
                                                <span class="icon-line"></span>
                                                <ng-container *ngIf="item.eventID === 24 || item.eventID === 37">
                                                    <span>{{ item.createdDate | date:'dd/MM/yy' }} {{ item.description }} </span>
                                                </ng-container>
                                                <ng-container *ngIf="item.eventID === 28">
                                                    <span>{{ item.ancNo }}  {{ item.ancGeneratedDatae | date:'dd/MM/yy' }} {{ item.description }}</span>
                                                </ng-container>

                                                <ng-container *ngIf="item.eventID !== 24 && item.eventID !== 37 && item.eventID !== 28">
                                                    <ng-container *ngIf="item.specializationName">
                                                        <span>{{ item.appointmentDate | date:'dd/MM/yy' }} {{ item.description }}</span>
                                                    </ng-container>

                                                    <ng-container *ngIf="!item.specializationName && item.eventID !== 43">
                                                        <span>{{ item.createdDate | date:'dd/MM/yy' }} {{ item.description }}</span>
                                                    </ng-container>
                                                </ng-container>
                                            </li>

                                            <li class="completed" *ngIf="archiveStatus">
                                                <p class="text-danger">NOT YET IMPLEMENTED</p>
                                            </li>

                                            <li *ngIf="reOpenStatus">
                                                <p class="text-primary" (click)="reOpenFullReport()">REOPEN REPORT</p>
                                            </li>

                                            <li>
                                                <div class="d-flex align-items-center" *ngIf="obReportLoding" style="position: relative;">
                                                    <div style="position:relative">
                                                        <span class="spinner-border text-primary" role="status" aria-hidden="true" style="width: 3rem; height: 3rem;"></span>
                                                        <span style="position: absolute; left: 50%;top:50%; transform: translate(-50%, -50%);">
                                                            {{ progressPercentage }}%
                                                        </span>
                                                    </div>

                                                    <span class="ml-2">Please wait while loading Document ...</span>
                                                </div>
                                            </li>

                                            <li *ngIf="selectedSpecializationName === 'All' && multiview" class="completed_spe">
                                                <div *ngFor="let specality of specalities; let i = index"
                                                     class="d-flex align-items-center mb-2 clickable-item_spe"
                                                     (click)="openSelectedPdfDocument(specality)">
                                                    <div class="icon-wrapper_spe">
                                                        <img src="https://cdn-icons-png.freepik.com/512/237/237510.png"
                                                             alt="Icon" class="icon_spe">
                                                    </div>
                                                    <span class="ms-spe cursor-pointer">{{ specality }}</span>
                                                </div>
                                            </li>

                                        </ul>

                                        <div class="container modalHeight" *ngIf="checklEventId == 34">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Sno</th>
                                                        <th>Name</th>
                                                        <th>ReportDate</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngFor="let name of testNames;let i = index">
                                                    <tr (click)="viewReport()">
                                                        <td>{{i+1}}</td>
                                                        <td>{{name}}</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div *ngIf="outside && checkedEventId ==20" class="overflow-auto">
                                        <div *ngIf="!outsidedata">
                                            <span>there is no data</span>
                                        </div>
                                        <table class="table table-sm table-centered table-hover m-0 table-bordered table-striped " *ngIf="outsidedata && outsidedata.length>0">
                                            <thead>
                                                <tr role="row">
                                                    <th>S.No</th>
                                                    <th>Test Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="cursor-pointer" *ngFor="let item of outsidedata;index as i;">
                                                    <td>{{i+1}}</td>
                                                    <td class="text-uppercase" [textContent]="item.testname"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div *ngIf="eventId == 34 || eventId==23" class="overflow-auto">
                                        <table class="table table-sm table-centered table-hover m-0 table-bordered table-striped" *ngIf="investigationCheckList && investigationCheckList.length > 0">
                                            <thead>
                                                <tr role="row">
                                                    <th>S.No</th>
                                                    <th>Lab Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="cursor-pointer" *ngFor="let item of investigationCheckList;index as i;">
                                                    <td>{{i+1}}</td>
                                                    <td class="text-uppercase" [textContent]="item.testName"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div *ngIf="labs.length > 0 && checkedEventId ==20 && investigation" class="overflow-auto">
                                        <table class="table table-sm table-centered table-hover m-0 table-bordered table-striped">
                                            <thead>
                                                <tr role="row">
                                                    <th>S.No</th>
                                                    <th>Date</th>
                                                    <th>Lab Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="cursor-pointer" *ngFor="let item of labs;index as i;" (click)="OnviewReport(templateLabReport ,item)">
                                                    <td>{{i+1}}</td>
                                                    <td [textContent]="item.technicianVerificationDate | date:'dd/MM/yyyy'"></td>
                                                    <td class="text-uppercase" [textContent]="item.testName"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!toggleService.showBox" class="timeline-close-box-btn" (click)=" toggleIt()">
    <button class="encounterBtn m-1 rounded-pill height30px">
        <i style="margin-right: -6px" class="timelineIconsAign mdi mdi-chevron-left font-26"></i><img class="h-100" src="assets/images/timelineSvg/time-line.svg" />
    </button>
</div>
<ng-template #templateTimelineView>
    <div class="modal-header bg-primary">
        <h4 class="modal-title text-light"><i class="fe-file mr-1"></i>Document</h4>

        <button type="button" class="close text-light" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="modal-body">
        <div class="row" *ngIf="viewData?.documents">
            <div class="col-9" *ngFor="let document of viewData.documents">
                <div>
                    <div class="col-12">
                        <div class="p-3" *ngIf="loadingDocument">
                            <div class="d-flex align-items-center">
                                <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                                <span class="ml-3">Please wait while loading Health Document ...</span>
                            </div>
                        </div>
                        <div *ngIf="documentError">
                            <div class="position-relative mh-355">
                                <no-data placement="left" ngbTooltip="Health Document"></no-data>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="document.isImage">
                        <img class="img-responsive" style="width: 100%; height: 100%" [src]="document.formedUrl" [hidden]="loadingDocument || documentError" (load)="onDocumentLoad()" (error)="onDocumentError()" [alt]="document.documentName" />
                    </div>
                    <ng-container class="col-12" *ngIf="document.isVideo">
                        <video style="width: 100%; height: 100%" controls (click)="toggleVideo()" #videoPlayer>
                            <source [src]="document.formedUrl" (load)="onDocumentLoad()" [type]="document.contentType">
                        </video>
                    </ng-container>
                    <div style="max-height: 600px;overflow-x: auto">
                        <ng-container *ngIf="!document.isImage && !document.isVideo">
                            <pdf-viewer [src]="document.formedUrl['changingThisBreaksApplicationSecurity']"
                                        [render-text]="true"
                                        [autoresize]="true"
                                        [original-size]="false"
                                        [fit-to-page]="false"
                                        (on-progress)="onProgress($event)"
                                        (error)="onDocumentError()"
                                        style="width: 100%; height: 500px">
                            </pdf-viewer>
                        </ng-container>
                    </div>
                </div>

            </div>

            <div class="col-4">

            </div>
        </div>

        <ng-container *ngIf="viewData?.data">
            <div class="dashboard-box" *ngIf="viewData.followUp">
                <h4 class="dashboard-title">Follow Up</h4>
                <div class="dashboard-body">
                    <div class="row">
                        <div class="col-12">
                            <h6 class="font-14 mt-0 mb-1">Frequency</h6>
                            <div>
                                Follow up after <span>{{viewData.followUp.frequency}} {{viewData.followUp.frequencyType === 'd' ? 'Days': (viewData.followUp.frequencyType === 'm' ? 'Month' : 'Weeks' ) }}</span> ({{viewData.followUp.followUpDate | utcToLocal:false}})
                            </div>
                        </div>
                        <div class="col-12">
                            <h6 class="font-14 mt-0 mb-1">Counseling</h6>
                            <ul class="dashboard-ul row">
                                <li *ngFor="let item of viewData.followUp.counseling" class="col-lg-3 col-md-4 col-sm-12" [textContent]="item"></li>
                            </ul>
                        </div>
                        <div class="col-md-12">
                            <h6 class="font-14 mt-0 mb-1">Comments</h6>
                            <div [textContent]="viewData.followUp.comments ? viewData.followUp.comments : '-'"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard-box" *ngIf="viewData.notes">
                <h4 class="dashboard-title">Notes</h4>
                <div class="dashboard-body pb-0">
                    <div class="row">
                        <div class="col-md-12">
                            <div [innerHTML]="viewData.notes?.notes"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard-box dashboard-table-box" *ngIf="viewData.medications && viewData.medications.length">
                <h4 class="dashboard-title">Medications</h4>
                <div class="dashboard-body">
                    <div class="overflow-auto">
                        <table class="table table-centered table-nowrap table-sm table-striped table-bordered mt-4 transcipt">
                            <thead>
                                <tr>
                                    <th>Medicine Name</th>
                                    <th>SIG</th>
                                    <th>MORNING</th>
                                    <th>AFTERNOON</th>
                                    <th>NIGHT</th>
                                    <th>INSTRUCTIONS</th>
                                    <th>DURATION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <tr *ngFor="let item of viewData.medications; let i = index">
                                    <td>
                                        <span class=" text-wrap" *ngIf="item.medicineName" [textContent]="item.medicineName.value || item.medicineName"></span><br />
                                        <span class=" text-wrap" *ngIf="!item.medicineName && item.name" [textContent]="item.name"></span>
                                        <span class=" text-wrap" *ngIf="item.genericName" [textContent]="'( ' + item.genericName + ' )'"></span>
                                    </td>
                                    <td>
                                        <span class=" text-wrap" *ngIf="item.medicineName" [textContent]="item.dosage +' '+ item.medicineType+' for '+item.duration+(item.durationType === 'D' ? ' Day' : (item.durationType === 'W' ? ' Week' : ' Month')) + (item.duration > 1 ? 's' : '')"></span>
                                        <span class=" text-wrap" *ngIf="!item.medicineName && item.name" [textContent]="item.dose+item.medicineType+item.frequency"></span>
                                    </td>
                                    <td>
                                        <span class=" ml-1 text-wrap" *ngIf="item.medicineName">
                                            <span *ngIf="item.isMorning && item.morningDosage" [textContent]="item.morningDosage"></span>
                                        </span>
                                        <span class=" ml-1 text-wrap" *ngIf="!item.medicineName && item.name">
                                            <span *ngIf="item.isMorning && item.morningDose" [textContent]="item.morningDose"></span>
                                        </span>
                                    </td>
                                    <td>
                                        <span class=" ml-1 text-wrap" *ngIf="item.medicineName">
                                            <span *ngIf="item.isAfternoon && item.afternoonDosage" [textContent]="item.afternoonDosage"></span>
                                        </span>
                                        <span class=" ml-1 text-wrap" *ngIf="!item.medicineName && item.name">
                                            <span *ngIf="item.isAfternoon && item.afternoonDose" [textContent]="item.afternoonDose"></span>
                                        </span>
                                    </td>
                                    <td>
                                        <span class=" ml-1 text-wrap" *ngIf="item.medicineName">
                                            <span *ngIf="item.isNight && item.nightDosage" [textContent]="item.nightDosage"></span>
                                        </span>
                                        <span class=" ml-1 text-wrap" *ngIf="!item.medicineName && item.name">
                                            <span *ngIf="item.isNight && item.nightDose" [textContent]="item.nightDose"></span>
                                        </span>
                                    </td>
                                    <td>
                                        <span class=" text-wrap" *ngIf="item.medicineName">
                                            <span *ngIf="item.instructions" [textContent]="item.instructions"></span>
                                        </span>
                                        <span class=" text-wrap" *ngIf="!item.medicineName && item.name">
                                            <span *ngIf="item.instruction" [textContent]="item.instruction"></span>
                                        </span>
                                    </td>
                                    <td>
                                        <span class=" text-wrap" [textContent]="item.duration + (item.durationType === 'D' ? ' Day' : (item.durationType === 'W' ? ' Week' : ' Month')) + (item.duration > 1 ? 's' : '')"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button (click)="close()" class="btn btn-secondary btn-sm float-right"><i class="fe-x mr-1"></i>Close</button>
    </div>
</ng-template>


<ng-template #templateViewDocument>
    <div class="modal-header">
        <h4 class="modal-title"><span [textContent]="selectedDocument.documentName"></span><small class="ml-2" [textContent]="selectedDocument.contentType"></small></h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        <button  [useExistingCss]="true" (click)="onPrintPdf()" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none"><i class="fe-printer mr-1"></i>Print</button>

    </div>
    <div class="modal-body">
        <div class="p-3" *ngIf="loadingDocument">
            <div class="d-flex align-items-center">
                <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                <span class="ml-3">Please wait while loading Document ...</span>
            </div>
        </div>
        <div *ngIf="!loadingDocument && documentError">
            <div class="position-relative mh-355">
                <no-data title="Document"></no-data>
            </div>
        </div>
        <ng-container *ngIf="selectedDocument && selectedDocument.isImage">
            <img [src]="selectedDocument.formedUrl" [hidden]="loadingDocument || documentError" (load)="onDocumentLoad()" (error)="onDocumentError()" [alt]="selectedDocument.documentName" />
        </ng-container>
        <ng-container *ngIf="selectedDocument.isVideo">
            <video style="width: 100%; height: 100%" controls (click)="toggleVideo()" #videoPlayer autoplay>
                <source [src]="selectedDocument.formedUrl" (load)="onDocumentLoad()" [type]="selectedDocument.contentType">
            </video>
        </ng-container>
        <ng-container *ngIf="!selectedDocument && selectedDocument.isImage && selectedDocument && !selectedDocument.isVideo">
            <pdf-viewer [src]="selectedDocument.formedUrl['changingThisBreaksApplicationSecurity']"
                        [render-text]="true"
                        [autoresize]="true"
                        [original-size]="false"
                        [fit-to-page]="false"
                        (on-progress)="onProgress($event)"
                        (error)="onDocumentError()"
                        style="width: 100%; height: 500px">
            </pdf-viewer>
        </ng-container>
    </div>
</ng-template>


<ng-template #templateLabReport>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-cash mr-1"></i>Lab Report</h4>
        <div class="d-flex">
            <div>
                <button class="btn btn-sm" [class.btn-light]="!isPrintLogo" [class.btn-outline-light]="isPrintLogo" type="button" (click)="onChangePrintType(false)"><i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo</button>
                <button class="btn btn-sm" [class.btn-light]="isPrintLogo" [class.btn-outline-light]="!isPrintLogo" type="button" (click)="onChangePrintType(true)"><i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo</button>
            </div>
            <div>
                <button *ngIf="!isExternal" [useExistingCss]="true" printSectionId="invoiceId" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none"><i class="fe-printer mr-1"></i>Print</button>
                <button *ngIf="isExternal" [useExistingCss]="true" (click)="onPrintPdf2()" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none"><i class="fe-printer mr-1"></i>Print</button>
                <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
            </div>
        </div>
    </div>
    <div Id="invoiceId" *ngIf="!isMigrated">
        <new-lab-report [isPrintLogo]="isPrintLogo" [encryptedNewLabBookingDetailId]="selectedLabBookingDetail.encryptedNewLabBookingDetailId" (isExternal)="OnCheckIsExternal($event)" (uplodpfd)="OnPdf($event)" (onClose)="onCloseModal()"></new-lab-report>
        <div *ngIf="isExternal">
            <div *ngIf="isPrintLogo">
                <banner-setting [reportName]='"Lab Report Header"'></banner-setting>
            </div>
            <div class=" pl-1 pr-1" *ngIf="reportData">
                <pdf-viewer [src]="reportData['changingThisBreaksApplicationSecurity']"
                            [render-text]="true"
                            [autoresize]="true"
                            [original-size]="false"
                            [fit-to-page]="false"
                            (on-progress)="onProgress($event)"
                            class="pdfstyle">
                </pdf-viewer>
            </div>
        </div>
    </div>
    <div *ngIf="isMigrated">
        <migrated-lab-report [isPrintLogo]="isPrintLogo" [encryptedNewLabBookingDetailId]="selectedLabBookingDetail.reqid" [encryptedPatientId]="encryptedPatientId" (onClose)="onCloseModal()"></migrated-lab-report>

    </div>
</ng-template>

<ng-template #templatePacsImage>
    <div class="modal-header">
        <h4 class="modal-title"><span *ngIf="selectedPacsImg && selectedPacsImg.documentName !=null" [textContent]="selectedPacsImg.documentName"></span><small class="ml-2" *ngIf="selectedPacsImg && selectedPacsImg.contentType" [textContent]="selectedPacsImg.contentType"></small></h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
      
    </div>
    <div class="modal-body">
        <div class="p-3" *ngIf="loadingPacsImg">
            <div class="d-flex align-items-center">
                <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                <span class="ml-3">Please wait while loading Image ...</span>
            </div>
        </div>
        <div *ngIf="!loadingPacsImg && documentError">
            <div class="position-relative mh-355">
                <no-data title="Document"></no-data>
            </div>
        </div>
        <ng-container *ngIf="selectedPacsImg && selectedPacsImg.isImage">
            <img [src]="selectedPacsImg.formedUrl" [hidden]="loadingPacsImg || documentError" (load)="onPacsLoad()" (error)="onDocumentError()" [alt]="selectedPacsImg.documentName" />
        </ng-container>
        <ng-container *ngIf="selectedPacsImg.isVideo">
            <video style="width: 100%; height: 100%" controls (click)="toggleVideo()" #videoPlayer autoplay>
                <source [src]="selectedPacsImg.formedUrl" (load)="onPacsLoad()" [type]="selectedPacsImg.contentType">
            </video>
        </ng-container>
        <ng-container *ngIf="!selectedPacsImg && selectedPacsImg.isImage && selectedPacsImg && !selectedPacsImg.isVideo">
            <pdf-viewer [src]="selectedPacsImg.formedUrl['changingThisBreaksApplicationSecurity']"
                        [render-text]="true"
                        [autoresize]="true"
                        [original-size]="false"
                        [fit-to-page]="false"
                        (on-progress)="onProgressImg($event)"
                        (error)="onDocumentError()"
                        style="width: 100%; height: 500px">
            </pdf-viewer>
        </ng-container>
        <ng-container *ngIf="selectedPacsImg && !selectedPacsImg.isImage && !selectedPacsImg.isVideo">
            <pdf-viewer [src]="selectedPacsImg.formedUrl['changingThisBreaksApplicationSecurity']"
                        [render-text]="true"
                        [autoresize]="true"
                        [original-size]="false"
                        [fit-to-page]="false"
                        (on-progress)="onProgressImg($event)"
                        (error)="onDocumentError()"
                        style="width: 100%; height: 500px">
            </pdf-viewer>
        </ng-container>
    </div>
</ng-template>


<ng-template #templateArchiveViewDocument>
    <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="modal-body">
        <div *ngIf="!loadingDocument && !PathReportString" class="p-3">
            <div class="d-flex align-items-center">
                <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                <span class="ml-3">Please wait while loading Document ...</span>
            </div>
        </div>
        <div *ngIf="!loadingDocument && PathReportString">
            <div>

                <pdf-viewer [src]="PathReportString"
                            [render-text]="true"
                            [original-size]="false"
                            style="width: 100%; height: 90vh;overflow-y: auto; overflow-x: hidden;">
                </pdf-viewer>

                <!--<ngx-extended-pdf-viewer [src]="PathReportString" [showHandToolButton]="true" [handTool]="false" [zoom]="'page-width'" style="width: 100%; height: 90vh; overflow-y: auto; overflow-x: hidden; ">
                </ngx-extended-pdf-viewer>-->
            </div>
        </div>
        <div *ngIf="!loadingDocument && !PathReportString && errorMessage" class="position-relative mh-300">
            <no-data title="Document"></no-data>
        </div>
    </div>
</ng-template>

<div class="message-box" *ngIf="showMessageBox">
    <div *ngIf="loadMessageWindow">
        <messaging-widget [appointment]="appointment" (close)="toggleMessageBox()"></messaging-widget>
    </div>
</div>
<style>
    .icon-container1 {
        width: 36px !important;
    }
</style>
<ng-template #templateofAddAppointent>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-cash mr-1"></i>Add Appointment</h4>
        <div>
            <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal()">×</button>
        </div>

    </div>
    <div class="p-1 font-weight-bold">


        <div>
            <label>Specialization Name</label>
            <ng-select [items]="data" [clearable]="true" bindLabel="specializationName" bindValue="specializationId" [(ngModel)]="specializationId" [ngClass]="{ 'is-invalid': submitted && specializationId.errors }">
                <ng-template ng-option-tmp let-item="item">
                    <span class="font-13" [textContent]="item.specializationName"></span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    <span class="font-13" [textContent]="item.specializationName"></span>
                </ng-template>
            </ng-select>
        </div>
        <div class="form-group mb-1 d-flex mt-2">
            <div class="form-group mb-1 col-3">
                <label>Visit Type <code>*</code></label>
            </div>
            <div class="form-group mb-1 col-3">
                <input type="radio" value="Emergency" [(ngModel)]="emergencyId" />&nbsp;
                <label class="radio-inline" for="Emergency">Emergency</label>
            </div>
            <div class="form-group mb-1 col-3">
                <input type="radio" value="WALK IN" [(ngModel)]="emergencyId" />&nbsp;
                <label class="radio-inline" for="WALK IN">Walk In</label>
            </div>
        </div>


        <div class="mt-2">
            <button class="btn btn-primary" (click)="onSubmit()">Submit</button>
        </div>

    </div>



</ng-template>
<ng-template #templatePrintReceipt>
    <div class="modal-header">
        <h4 class="modal-title"><i class="fe-calendar mr-1"></i>Prescription</h4>
        <div>
            <button type="button" class="close d-print-none" data-dismiss="modal" (click)="onCloseModalPrint();">
                ×
            </button>
        </div>
    </div>
    <div *ngIf="printRoutingValue =='ob-encounter' || printRoutingValue =='ob-ip-encounter'">
        <ob-prescriptionreports [printId]="printAppointmentId" [printadmission]="printadmission"></ob-prescriptionreports>

    </div>
    <div *ngIf="printRoutingValue == 'gyn-encounter' || printRoutingValue == 'gyn-ip-encounter'">
        <gyn-printprescription [printId]="printAppointmentId" [printadmission]="printadmission"></gyn-printprescription>
    </div>
    <div *ngIf="printRoutingValue == 'diet-plan-encounter' ">
        <diet-printprescription [printId]="printAppointmentId" [printadmission]="printadmission"></diet-printprescription>
    </div>
    <div *ngIf="printRoutingValue == 'genetic-speciality' ">
        <genetic-report [printId]="printAppointmentId" [printadmission]="printadmission"></genetic-report>
    </div>
    <div *ngIf="printRoutingValue == 'physiotherapy-encounter' ">
        <physio-report [printId]="printAppointmentId" [printadmission]="printadmission"></physio-report>
    </div>
    <div *ngIf="printRoutingValue == 'pediatric-encounter' ">
        <pediatric-report [printId]="printAppointmentId" [printadmission]="printadmission"></pediatric-report>
    </div>
</ng-template>

<ng-template #templateFullTranscripit>
    <div class="modal-header">
        <h4 class="modal-title"><i class="fe-calendar mr-1"></i>Full Transcripit</h4>
        <div>
            <button type="button" class="close d-print-none" data-dismiss="modal" (click)="onCloseModalPrint();">
                ×
            </button>
        </div>
    </div>
    <div *ngIf="printRoutingValue =='ob-encounter' || printRoutingValue =='ob-ip-encounter'">
        <ob-fullreport [printId]="printAppointmentId" [printadmission]="printadmission"></ob-fullreport>

    </div>
    <div *ngIf="printRoutingValue == 'gyn-encounter' || printRoutingValue == 'gyn-ip-encounter'">
        <gyn-fullreport [printId]="printAppointmentId" [printadmission]="printadmission"></gyn-fullreport>
    </div>
    <div *ngIf="printRoutingValue == 'diet-plan-encounter' ">
        <diet-fullreport [printId]="printAppointmentId" [printadmission]="printadmission"></diet-fullreport>
    </div>
    <div *ngIf="printRoutingValue == 'genetic-speciality' ">
        <genetic-fullreport [printId]="printAppointmentId" [printadmission]="printadmission"></genetic-fullreport>
    </div>
    <div *ngIf="printRoutingValue == 'physiotherapy-encounter' ">
        <physiotheray-fullreport [printId]="printAppointmentId" [printadmission]="printadmission"></physiotheray-fullreport>
    </div>
    <div *ngIf="printRoutingValue == 'pediatric-encounter' ">
        <pediatric-fullreport [printId]="printAppointmentId" [printadmission]="printadmission"></pediatric-fullreport>
    </div>
</ng-template>
<ng-template #templateprintReport>
    <div class="modal-header">
        <div>
            <button type="button" class="close d-print-none" data-dismiss="modal" (click)="onCloseModalPrint();">
                ×
            </button>
        </div>
    </div>
    <div *ngIf="reportname == 'obdischargesummary'">
        <discharge-summaryreport [printId]="printAppointmentId" [printadmission]="printadmission"></discharge-summaryreport>

    </div>
    <div *ngIf="reportname == 'gyndischargesummary'">
        <gyndischarge-summaryreport [printId]="printAppointmentId" [printadmission]="printadmission"></gyndischarge-summaryreport>

    </div>
    <div *ngIf="reportname == 'neonataldischargesummary'">
        <neonataldischarge-summaryreport [printId]="printAppointmentId" [printadmission]="printadmission"></neonataldischarge-summaryreport>

    </div>
    <div *ngIf="reportname == 'ancreport'">
        <div>
            <button [useExistingCss]="true" printSectionId="geneticprint" ngxPrint class="close d-print-none"><i class="fe-printer" placement="left" ngbTooltip="Print"></i></button>

        </div>
        <div id="geneticprint">
            <antinatal-summaryreport [printId]="printAppointmentId" [printadmission]="printadmission"></antinatal-summaryreport>

        </div>

    </div>
    <div *ngIf="reportname == 'admissiondetail'">
        <admissiondetail-report [printId]="printAppointmentId" [printadmission]="printadmission"></admissiondetail-report>
    </div>

    <div *ngIf="reportname == 'nurse-notes-report'">

        <div>
            <div class="mt-1">
                <div class="col-12 p-0">
                    <div class="align-items-start border border-bottom-0 d-flex pl-1 pr-1 pt-1">
                        <div class="pl-0 col-11 d-flex">
                        </div>
                        <div class="col-1">
                            <button [useExistingCss]="true" id="printButton" printSectionId="checklistprint" ngxPrint class="close d-print-none"> <i class="fe-printer" placement="left" ngbTooltip="PrintEncounter"></i></button>
                        </div>
                    </div>
                    <div>
                        <div class="pl-0 col-11 d-flex">
                            <div class="right5">
                                <button class="btn btn-info" type="button" (click)="onChangePrintType( false)">
                                    <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                                </button>
                                <button class="btn btn-info" type="button" (click)="onChangePrintType(true)">
                                    <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                                </button>
                            </div>
                            <div class="right5">
                                <button class="btn btn-info" type="button" (click)="onChangeFooter( false)">
                                    <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
                                </button>
                                <button class="btn btn-info" type="button" (click)="onChangeFooter(true)">
                                    <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="checklistprint">
                <div class="border-left border-right card-box mb-0">


                    <div *ngIf="isPrintLogo">
                        <banner-setting [reportName]='"Nurses Review Notes Report Header"'></banner-setting>
                    </div>
                    <div class="border-dark border-top ">
                        <div style="margin-top:20px;">
                            <div class="d-flex justify-content-between mt-4 col-12 p-0">
                                <div class="flex-grow-1 d-flex col-12">
                                    <h3 class="d-flex position-absolute justify-content-xl-center" style="bottom: 0px; width: 100%; color:black;">
                                        NURSES NOTES
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="border-top border-dark">
                            <div class="col-12 p-0 pb-1 pt-1">
                                <table class="w-100 mt-2">
                                    <tr>
                                        <td class="white-space-nowrap">
                                            <span>Name</span>
                                        </td>
                                        <td>
                                            <h5>:</h5>
                                        </td>
                                        <td>
                                            <h5 class="pl-2" *ngIf="appointment && appointment.patientName" [textContent]="appointment.patientName"></h5>
                                        </td>
                                        <td>
                                            <span>Mr No</span>
                                        </td>
                                        <td>
                                            <h5>:</h5>
                                        </td>
                                        <td>
                                            <h5 class="pl-2" *ngIf="appointment && appointment.umrNo" [textContent]="appointment.umrNo"></h5>
                                        </td>
                                        <td>
                                            <span>IP No </span>
                                        </td>
                                        <td>
                                            <h5>:</h5>
                                        </td>
                                        <td>
                                            <h5 class="pl-2" *ngIf="appointment && appointment.appointmentNo" [textContent]="appointment.appointmentNo"></h5>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 mb-4">
                                    <div class="overflow-auto">
                                        <table class="table table-centered table-sm table-striped table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>Item</th>
                                                    <th>Bed No</th>
                                                    <th>Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngIf="loading">
                                                    <tr>
                                                        <td colspan="8">
                                                            <span class="mr-1 spinner-grow spinner-grow-sm"></span> Please wait while loading nurse notes ...
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                                <ng-container *ngIf="!records.length">
                                                    <tr>
                                                        <td colspan="8">
                                                            <i class="mdi mdi-alert text-warning mr-1"></i> Nurse Notes does not exists.
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                                <ng-container *ngIf="records.length">
                                                    <ng-container *ngFor="let item of records; let i = index;">
                                                        <tr>
                                                            <td [textContent]="(item.date || item.createdDate) | date:'dd-MM-yyyy'"></td>
                                                            <td>
                                                                <small [textContent]="item.createdDate | date:'dd-MM-yyyy, hh:mm a'"></small>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex">
                                                                    <div [textContent]="item.note"></div>
                                                                </div>
                                                            </td>
                                                            <td *ngIf="appointment && appointment.bedId">
                                                                {{appointment.bedId}}
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div class="media">
                                                                        <div class="media-body">
                                                                            <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.createdByName"></h5>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isFooter">
                    <banner-setting [reportName]='"Nurses Review Notes Report Footer"'></banner-setting>
                </div>
            </div>



        </div>
    </div>
</ng-template>


<ng-template #templateFullReport>
    <div class="modal-header">
        <h4 class="modal-title"><i class="fe-calendar mr-1"></i>Full Transcripit</h4>
        <div>
            <button type="button" class="close d-print-none" data-dismiss="modal" (click)="onCloseModalPrint();">
                ×
            </button>
        </div>
    </div>
    <div >
        <encounter-fullreport [printId]="printAppointmentId" [printadmission]="printadmission"></encounter-fullreport>

    </div>
    
</ng-template>