
<div class="sidebar-main-menu lgSubMenu   addedLeft" style="background-size: 200%; background-repeat: no-repeat;" *ngIf="!loadingSample && demographData.length > 0">

    <common-data [commonid]="sendpatientId"></common-data>
    <div class="encounter-profile-list text-mute">
        <h5 class="menu-title text-primary break-all pl-0 pt-0">Lab Details <span *ngIf="!loadingSample && demographData.length > 0">({{demographData[0].testName}})</span></h5>
        <ul style="line-height:30px;">
            <li>
                <div class="text-truncate">
                    <span class="font-14 mt-1x" [textContent]="demographData[0].doctorName"></span>
                </div>
            </li>
            <li>
                <i class="mdi mdi-numeric text-primary mr-1"></i><span class="font-weight-bolder">Req No :</span>
                <span class="font-13 mt-1x" [textContent]="demographData[0].requisitionNumber"></span>
            </li>
            <li class="d-flex" style="line-height:1.4;">
                <i class="mdi mdi-account text-primary mr-1"></i> <span><span class="font-14 mt-1x break-all" [textContent]="demographData[0].sampleCollectedByName +'&nbsp;'"></span><span _ngcontent-tjk-c1="" class="badge bg-primary text-white font-weight-lighter" style="padding:3px;">Collected</span></span>
            </li>
            <li class="ml-4" style="list-style:circle;">
                <i class="mdi mdi-calendar text-primary mr-1"></i>
                <span class="font-13 mt-1x" [textContent]="demographData[0].collectionDate | date:'dd/MM/yyyy'"></span>
            </li>
            <li class="ml-4" style="list-style:circle;">
                <i class="mdi mdi-clock text-primary mr-1"></i>
                <span class="font-13 mt-1x" [textContent]="demographData[0].collectionDate | date:'hh:mm a'"></span>
            </li>
            <li class="d-flex" style="line-height:1.4;">
                <i class="mdi mdi-account text-primary mr-1"></i> <span><span class="font-14 mt-1x break-all" [textContent]="demographData[0].recievedByName +'&nbsp;'"></span><span class="badge bg-primary text-white font-weight-lighter" style="padding:3px;">Received</span></span>
            </li>
            <li class="ml-4" style="list-style:circle;">
                <i class="mdi mdi-calendar text-primary mr-1"></i>
                <span class="font-13 mt-1x" [textContent]="demographData[0].receivedDate | date:'dd/MM/yyyy'"></span>
            </li>
            <li class="ml-4" style="list-style:circle;">
                <i class="mdi mdi-clock text-primary mr-1"></i>
                <span class="font-13 mt-1x" [textContent]="demographData[0].receivedDate | date:'hh:mm a'"></span>
            </li>
        </ul>
    </div>
</div>


<div [ngClass]="{'margin-left-220':!loadingSample && demographData.length > 0}" *ngIf="!loadingSample && demographData.length > 0">
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box">
                        <div class="page-title-right">
                            <button *ngIf="isHaveParameters && page.userAccount.roleId != 3  && (demographData[0].status == 'ParameterAdded' || demographData[0].status == 'SampleVerified' )" class="btn btn-sm btn-outline-danger"
                                    (click)="OpenReasonView(templateReason)">
                                Clear Results
                            </button>
                            <button *ngIf="!isHaveParameters && page.userAccount.roleId != 3  && (demographData[0].status == 'ParameterAdded' || demographData[0].status == 'SampleVerified' )" class="btn btn-sm btn-outline-danger"
                                    (click)="onFetchInputParameters()">
                                Clear Results
                            </button>
                            <button class="btn btn-sm btn-outline-primary" (click)="onGetParameters(demographData[0] , parametersListView)">
                                <div placement="top" ngbTooltip="Parameters">Parameters</div>
                            </button>
                            <button class="btn btn-sm btn-outline-primary" (click)="onGetAllPatientLabs(demographData[0] , getAllLabsView)">
                                <div placement="top" ngbTooltip="All Labs Details">All Labs Details</div>
                            </button>
                            <button class="btn btn-sm btn-outline-primary" (click)="onViewPastLabReports(templateShowPastReports)">
                                <div ngbTooltip="Past Lab Results">Past Lab Results</div>
                            </button>
                            <button *ngIf="!showReport" class="btn btn-sm btn-outline-primary" (click)="onOpenModalMultiple(templateMultipleUploadDocument)">
                                <div>Upload Document</div>
                            </button>
                            <button class="btn btn-sm btn-outline-primary" *ngIf="showReport" (click)="onViewDocument(templateUploadDocument)">
                                Upload & View Document
                            </button>
                            <button *menuButton="'lab_techview_parameter_allresult'" class="btn btn-sm btn-outline-primary" (click)="viewPreviousResultsAdded(showPreviousResults)">
                                Results History
                            </button>
                            <span *menuButton="'lab_techview_parameter_assign_doctor'">
                                <button class="btn btn-sm btn-outline-primary" (click)="selectDoctor(assignDoctor)" *ngIf=" demographData && demographData[0].assignDoctorRequired">
                                    Assign Doctor
                                </button>
                            </span>
                            <button *menuButton="'lab_techview_parameter_viewreport'" class="btn btn-sm btn-outline-primary" (click)="onViewReportNew(templateLabReport)">
                                <i class="mdi mdi-text-box mr-1"></i> View Interim Report
                            </button>
                            <button class="btn btn-sm btn-outline-danger" (click)="onCloseModel()">
                                <i class="mdi mdi-close mr-1"></i> Close
                            </button>
                        </div>
                        <h4 class="page-title">
                            <i class="mdi mdi-flask-plus mr-1"></i> Verify Report
                        </h4>
                    </div>
                </div>
                <div class="col-9">
                    <div class="row p-2" *ngIf="loading">
                        <div class="col-12">
                            <div class="d-flex align-items-center">
                                <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                                <span class="ml-2">Please wait while loading ...</span>
                            </div>
                        </div>
                    </div>
                    <div class="row p-1" *ngIf="!loading && parameters === null">
                        <div class="col-12 mh-300">
                            <no-data [applied]="false" [title]="'parameter'"></no-data>
                        </div>
                    </div>
                    <div *ngIf="!loading && parameters !== null">
                        <div class="row">
                            <div class="col-12">
                                <div class="card mb-1 shadow-none border bg-pattern">
                                    <div class="p-2">
                                        <div class="row align-items-center">
                                            <div class="col-auto">
                                                <div class="avatar-sm">
                                                    <span class="avatar-title badge-soft-primary text-primary rounded">
                                                        <i class="mdi mdi-link-plus mdi-18px"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col ps-0">
                                                <a href="javascript:void(0);" class="font-20 font-weight-bold">{{reports.templates[0]?.templateName}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <ng-container *ngFor="let parameter of templateDetailsOrder;index as tdo;">
                                            <div class="p-2 card col-12" *ngIf="parameter.parameterName">
                                                <div>
                                                    <div class="row col-12" *ngIf="parameter.type === 'Regular'">
                                                        <div class="col-6">
                                                            <label class="mb-0"> {{parameter.parameterName}} <code *ngIf="isValidation && parameter.validation">*</code></label>
                                                            <div class="form-group d-flex col-12 leftmargin-25">
                                                                <div class="col-11">
                                                                    <input *ngIf="parameter.referenceOutput === 'numeric'" [ngStyle]="{'color': parameter.isBold ? 'red':'black' }" class="form-control" placeholder="Enter {{parameter.displayName}}" (keyup)="onChange()"  [(ngModel)]="parameter.observedValue" (input)="changeTextColor(parameter)" type="text" />
                                                                    <textarea rows="2" *ngIf="parameter.referenceOutput === 'text'" class="form-control" placeholder="Enter {{parameter.displayName}}" (keyup)="onChange()" [(ngModel)]="parameter.observedValue" ></textarea>
                                                                    <select *ngIf="parameter.referenceOutput === 'selection'" class="form-control custom-select form-control-sm" (change)="onChange()" autocomplete="nope" [(ngModel)]="parameter.observedValue">
                                                                        <option [ngValue]="null">None</option>
                                                                        <option *ngFor="let item of parameter.allParameterDetail" [textContent]="item.selectionName" [ngValue]="item.selectionName"></option>
                                                                    </select>
                                                                </div>
                                                                <div class="col-1 mt-1">
                                                                    <h5 class="mt-0 mb-0 text-danger" *ngIf="parameter.labParameterDetailId">{{parameter.showText}}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-4">
                                                            <div class="form-group">
                                                                <label class="mb-0"> Range<code *ngIf="isValidation && parameter.validation">*</code></label>
                                                                <div class="p-0 mb-0" *ngIf="(parameter.referenceOutput === 'numeric' ||  parameter.referenceOutput === 'text' )&& parameter.type === 'Regular'">
                                                                    <div class="d-flex justify-content-between">
                                                                        <div>
                                                                            <i class="fe-edit text-primary" style="cursor: pointer;" (click)="onOpenForParameterSelection(templateReferenceRange,parameter)"></i>
                                                                            <h5 class="mt-0 mb-0 d-inline" *ngIf="parameter.labParameterDetailId && parameter.referenceOutput === 'numeric' && (parameter.selected.rangeText == null || parameter.selected.rangeText == '') && (parameter.selected.minValue != null && parameter.selected.maxValue != null )">
                                                                                {{parameter.selected.minValue}}-{{parameter.selected.maxValue}}&nbsp;
                                                                            </h5>
                                                                            <h5 class="mt-0 mb-0 d-inline" [innerHTML]="parameter.selected.rangeText" *ngIf="parameter.labParameterDetailId && parameter.referenceOutput === 'numeric' &&( parameter.selected.rangeText != null || parameter.selected.rangeText != '')">
                                                                                &nbsp;
                                                                            </h5>
                                                                            <h5 class="mt-0 mb-0 d-inline" [innerHTML]="parameter.selected.rangeText" *ngIf="parameter.labParameterDetailId && parameter.referenceOutput === 'text' &&( parameter.selected.rangeText != null || parameter.selected.rangeText != '')">
                                                                                &nbsp;
                                                                            </h5>
                                                                            <h5 class="mt-0 mb-0 d-inline" *ngIf="parameter.labParameterDetailId && parameter.referenceOutput === 'text' && (parameter.selected.rangeText == null || parameter.selected.rangeText == '') && (parameter.selected.minValue != null && parameter.selected.maxValue != null ) ">
                                                                                {{parameter.selected.minValue}}-{{parameter.selected.maxValue}}&nbsp;
                                                                            </h5>
                                                                            <h5 class="mt-0 mb-0 d-inline" [innerHTML]="parameter.text" *ngIf="parameter.referenceOutput === 'selection' && parameter.text != '' && parameter.text != null ">
                                                                                &nbsp;
                                                                            </h5>
                                                                        </div>
                                                                        <div *ngIf="!parameter.labParameterDetailId">
                                                                            <button type="button" class="btn btn-sm waves-effect waves-light"
                                                                                    (click)="onOpenForParameterSelection(templateReferenceRange,parameter)">
                                                                                Choose
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="p-0 mb-0" *ngIf="parameter.referenceOutput != 'numeric' && parameter.type === 'Regular'">
                                                                    <h5 class="mt-0 mb-0" *ngIf="parameter.labParameterDetailId" [innerHTML]="parameter.text ||'-'">
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label class="mb-0"> Unit <code *ngIf="isValidation && parameter.validation">*</code></label>
                                                                <div class="d-flex justify-content-between">
                                                                    <h5 class="mt-0 mb-0" *ngIf="parameter.labParameterDetailId">
                                                                        {{parameter.selected.unitName || '--'}}&nbsp;&nbsp;&nbsp;
                                                                    </h5>
                                                                    <button *ngIf="parameter.referenceOutput == 'numeric'" type="button" class="btn btn-xs btn-primary white-space-nowrap waves-effect waves-light" (click)="onClickParameter(parameter , true)">
                                                                        <i class="fe-activity" style="cursor: pointer;"></i><span>Graph</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row col-12" *ngIf="parameter.type != 'Regular'">
                                                        <div class="col-12 d-flex mb-1">
                                                            <div class="form-group width31">
                                                                <label class="mb-0 text-capitalize text-primary"> {{parameter.parameterName}}</label>
                                                            </div>
                                                            <div class="width32">
                                                                <div class="form-group mb-0 position-relative">
                                                                    <ng-select class="ng-select-sm" [items]="antiBioticMaster"
                                                                               bindLabel="name"
                                                                               bindValue="antiBioticMasterId"
                                                                               typeToSearchText="Search organism"
                                                                               placeholder="Enter organism name"
                                                                               (change)="onSetClickMicroParameter($event ,tdo ,undefined,true)">
                                                                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                                            No organism found<span *ngIf="searchTerm"> for '{{searchTerm}}'</span>.
                                                                        </ng-template>
                                                                        <ng-template ng-option-tmp let-item="item" let-i="index">
                                                                            <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.name"></h5>
                                                                        </ng-template>
                                                                    </ng-select>
                                                                </div>
                                                            </div>
                                                            <div class="width32">
                                                                <div class="form-group mb-0 position-relative">
                                                                    <ng-select class="ng-select-sm" [items]="labAntibiotics" #ngSelectOption
                                                                               bindLabel="antibioticName"
                                                                               bindValue="antibioticName"
                                                                               typeToSearchText="Search Antibiotic"
                                                                               placeholder="Enter Antibiotic name"
                                                                               (change)="onSetClickMicroParameter($event ,tdo,undefined , false)" (click)="ngSelectOption.handleClearClick()">
                                                                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                                            No Antibiotic found<span *ngIf="searchTerm"> for '{{searchTerm}}'</span>.
                                                                        </ng-template>
                                                                        <ng-template ng-option-tmp let-item="item" let-i="index">
                                                                            <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.antibioticName"></h5>
                                                                        </ng-template>
                                                                    </ng-select>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="row col-12" *ngFor="let antibiotic of parameter.antibiotics;index as j;">
                                                            <div class="row form-group col-12">
                                                                <label class="mb-0 col-4"> {{antibiotic.antibioticName}}</label>
                                                                <select (change)="onChange()" class="form-control custom-select form-control-sm col-4" autocomplete="nope" [(ngModel)]="antibiotic.observedValue">
                                                                    <option [ngValue]="null">None</option>
                                                                    <option *ngFor="let item of labAntibioticsResultTypes" [textContent]="item.name" [ngValue]="item.name"></option>
                                                                </select>
                                                                <div *ngIf="!antibiotic.labParameterobservedValueId">
                                                                    <button type="button" class="btn btn-sm waves-effect waves-light"
                                                                            (click)="removeAntibiotic(parameter,j , tdo)">
                                                                        Remove
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                              
                                        <div class="col-12 card" *ngIf="parameter.componentName">
                                            <div class="headingStyle">
                                                <div class="trapezium font-weight-semibold text-white">
                                                    <span class="p-1"><i class="mdi mdi-chart-gantt me-1"></i> <span [textContent]="parameter.componentName"></span></span>
                                                </div>
                                            </div>
                                            <div class="p-2" *ngFor="let componentParameter of parameter.parameters;index as cp;">
                                                <div class="row">
                                                    <div class="row col-12" *ngIf="componentParameter.type === 'Regular'">
                                                        <div class="col-6">
                                                            <label class="mb-0"> {{componentParameter.parameterName}} <code *ngIf="isValidation && componentParameter.validation">*</code></label>
                                                            <div class="form-group d-flex col-12 leftmargin-25">
                                                                <div class="col-11">
                                                                    <input *ngIf="componentParameter.referenceOutput === 'numeric'" class="form-control" (keyup)="onChange()" placeholder="Enter {{componentParameter.displayName}}" type="text" (blur)="onChange()" [(ngModel)]="componentParameter.observedValue" [ngStyle]="{'color': componentParameter.isBold ? 'red':'black'}" (input)="changeTextColor(componentParameter)" />
                                                                    <textarea rows="2" *ngIf="componentParameter.referenceOutput === 'text'" (keyup)="onChange()" class="form-control" placeholder="Enter {{componentParameter.displayName}}" [(ngModel)]="componentParameter.observedValue" ></textarea>
                                                                    <select *ngIf="componentParameter.referenceOutput === 'selection'" class="form-control custom-select form-control-sm" autocomplete="nope" [(ngModel)]="componentParameter.observedValue" >
                                                                        <option [ngValue]="null">None</option>
                                                                        <option *ngFor="let item of componentParameter.allParameterDetail" [textContent]="item.selectionName" [ngValue]="item.selectionName"></option>
                                                                    </select>
                                                                </div>
                                                                <div class="col-1 mt-1">
                                                                    <h5 class="mt-0 mb-0 text-danger" *ngIf="componentParameter.labParameterDetailId">{{componentParameter.showText}}</h5>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="col-4">
                                                            <div class="form-group">
                                                                <label class="mb-0"> Range<code *ngIf="isValidation && componentParameter.validation">*</code></label>
                                                                <div class="p-0 mb-0" *ngIf="componentParameter.referenceOutput === 'numeric' && componentParameter.type === 'Regular'">
                                                                    <div class="d-flex justify-content-between">
                                                                        <div>
                                                                            <i class="fe-edit text-primary" style="cursor: pointer;" (click)="onOpenForParameterSelection(templateReferenceRange,componentParameter)"></i>
                                                                            <h5 class="mt-0 mb-0 d-inline" *ngIf="componentParameter.labParameterDetailId  && (componentParameter.selected.rangeText == null || componentParameter.selected.rangeText == '') && (componentParameter.selected.minValue != null && componentParameter.selected.maxValue != null )">
                                                                                {{componentParameter.selected.minValue}}-{{componentParameter.selected.maxValue}}&nbsp;
                                                                            </h5>
                                                                            <h5 class="mt-0 mb-0 d-inline" [innerHTML]="componentParameter.selected.rangeText" *ngIf="componentParameter.labParameterDetailId  && componentParameter.selected.rangeText != null && componentParameter.selected.rangeText != ''">
                                                                                &nbsp;
                                                                            </h5>
                                                                        </div>
                                                                        <div *ngIf="!componentParameter.labParameterDetailId">
                                                                            <button type="button" class="btn btn-sm waves-effect waves-light"
                                                                                    (click)="onOpenForParameterSelection(templateReferenceRange,componentParameter)">
                                                                                Choose
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="p-0 mb-0" *ngIf="componentParameter.referenceOutput == 'text' && componentParameter.type === 'Regular'">
                                                                    <h5 class="mt-0 mb-0 d-inline" [innerHTML]="componentParameter.selected.rangeText" *ngIf="componentParameter.labParameterDetailId && componentParameter.referenceOutput === 'text' && componentParameter.selected.rangeText != null && componentParameter.selected.rangeText != ''">
                                                                        &nbsp;
                                                                    </h5>
                                                                    <h5 class="mt-0 mb-0 d-inline" *ngIf="componentParameter.labParameterDetailId && componentParameter.referenceOutput === 'text' && (componentParameter.selected.rangeText == null || componentParameter.selected.rangeText == '') && (componentParameter.selected.minValue != null && componentParameter.selected.maxValue != null )">
                                                                        {{componentParameter.selected.minValue}}-{{componentParameter.selected.maxValue}}&nbsp;
                                                                    </h5>
                                                                </div>
                                                                <div class="p-0 mb-0" *ngIf="componentParameter.referenceOutput == 'selection' && componentParameter.type === 'Regular'">
                                                                    <h5 class="mt-0 mb-0 d-inline" [innerHTML]="parameter.text" *ngIf="parameter.referenceOutput === 'selection' && parameter.text != '' && parameter.text != null ">
                                                                        &nbsp;
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label class="mb-0"> Unit <code *ngIf="isValidation && componentParameter.validation">*</code></label>
                                                                <div class="d-flex justify-content-between">
                                                                    <h5 class="mt-0 mb-0" *ngIf="componentParameter.labParameterDetailId">
                                                                        {{componentParameter.selected.unitName || '--'}}&nbsp;&nbsp;&nbsp;
                                                                    </h5>
                                                                    <button *ngIf="componentParameter.referenceOutput == 'numeric'" type="button" class="btn btn-xs btn-primary white-space-nowrap waves-effect waves-light" (click)="onClickParameter(componentParameter , true)">
                                                                        <i class="fe-activity" style="cursor: pointer;"></i><span>Graph</span>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="row col-12" *ngIf="componentParameter.type != 'Regular'">
                                                        <div class="col-12 d-flex mb-1">
                                                            <div class="form-group width31">
                                                                <label class="mb-0 text-capitalize text-primary"> {{componentParameter.parameterName}}</label>
                                                            </div>
                                                            <div class="width32">
                                                                <div class="form-group mb-0 position-relative">
                                                                    <ng-select class="ng-select-sm" [items]="antiBioticMaster"
                                                                               bindLabel="name"
                                                                               bindValue="antiBioticMasterId"
                                                                               typeToSearchText="Search Organism"
                                                                               placeholder="Enter organism name"
                                                                               (change)="onSetClickMicroParameter($event ,tdo,cp, true)">
                                                                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                                            No Organism found<span *ngIf="searchTerm"> for '{{searchTerm}}'</span>.
                                                                        </ng-template>
                                                                        <ng-template ng-option-tmp let-item="item" let-i="index">
                                                                            <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.name"></h5>
                                                                        </ng-template>
                                                                    </ng-select>
                                                                </div>
                                                            </div>
                                                            <div class="width32">
                                                                <div class="form-group mb-0 position-relative">
                                                                    <ng-select class="ng-select-sm" [items]="labAntibiotics" #ngSelectOption
                                                                               bindLabel="antibioticName"
                                                                               bindValue="antibioticName"
                                                                               typeToSearchText="Search Antibiotic"
                                                                               placeholder="Enter Antibiotic name"
                                                                               (change)="onSetClickMicroParameter($event ,tdo,cp, false)" (click)="ngSelectOption.handleClearClick()">
                                                                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                                            No Antibiotic found<span *ngIf="searchTerm"> for '{{searchTerm}}'</span>.
                                                                        </ng-template>
                                                                        <ng-template ng-option-tmp let-item="item" let-i="index">
                                                                            <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.antibioticName"></h5>
                                                                        </ng-template>
                                                                    </ng-select>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="row col-12" *ngFor="let antibiotic of componentParameter.antibiotics;index as j;">
                                                            <div class="row form-group col-12">
                                                                <label class="mb-0 col-4"> {{antibiotic.antibioticName}}</label>
                                                                <select (change)="onChange()" class="form-control custom-select form-control-sm col-4" autocomplete="nope" [(ngModel)]="antibiotic.observedValue">
                                                                    <option [ngValue]="null">None</option>
                                                                    <option *ngFor="let item of labAntibioticsResultTypes" [textContent]="item.name" [ngValue]="item.name"></option>
                                                                </select>
                                                                <div *ngIf="!antibiotic.labParameterobservedValueId">
                                                                    <button type="button" class="btn btn-sm waves-effect waves-light"
                                                                            (click)="onSetremoveAntibiotic(componentParameter,j,tdo , cp)">
                                                                        Remove
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    
                                    <div class="col-12" style="margin-top: -20px !important;" *ngIf="reports.templates[0].isMethod || reports.templates[0].isInterpretation">
                                        <div class="card-box">
                                            <div class="row">
                                                <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="reports.templates[0].isMethod">
                                                    <div class="form-group">
                                                        <label class="mb-1 font-16 font-weight-bold">Method</label>
                                                        <div>
                                                            <!--<textarea class="form-control" rows="3" placeholder="Enter method text" [(ngModel)]="template.methodText"></textarea>-->
                                                            <ckeditor [editor]="notesEditor" [config]="config" placeholder="Enter method text" (keyup)="onChange()" [(ngModel)]="reports.templates[0].methodText"></ckeditor>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="reports.templates[0].isInterpretation">
                                                    <div class="form-group">
                                                        <label class="mb-1 font-16 font-weight-bold">Interpretation/Result</label>
                                                        <div>
                                                            <!--<textarea class="form-control" rows="3" placeholder="Enter interpretation text" [(ngModel)]="template.interpretationText"></textarea>-->
                                                            <ckeditor [editor]="notesEditor" (keyup)="onChange()" [config]="config" placeholder="Enter interpretation text" [(ngModel)]="reports.templates[0].interpretationText"></ckeditor>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="d-flex justify-content-end">

                                    <span class="badge badge-success mr-1" *ngIf="resultsType !='verifiedReports' && selectedLabBookingDetail && selectedLabBookingDetail.technicianId  && page.userAccount.roleId != 3 && selectedLabBookingDetail.status != 'Hold'">Verified by technician</span>
                                    <span class="badge badge-success mr-1" *ngIf="resultsType=='verifiedReports' && page.userAccount.roleId == 3 && doctorDetails">Verified by Doctor</span>

                                    <span>
                                        <button type="button" (click)="onSubmit()" class="btn btn-sm btn-success mr-1"
                                                *ngIf="(selectedLabBookingDetail && ((selectedLabBookingDetail.labBookingStatusId === 6 && selectedLabBookingDetail.technicianId === null) ||  selectedLabBookingDetail.status == 'Hold' ) && showTechnitionVerify)">
                                            <span *ngIf="!submitting">
                                                <i class="mdi mdi-check mr-1"></i>Submit
                                            </span>
                                            <span *ngIf="submitting">
                                                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                Please wait..
                                            </span>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-success mr-1" (click)="onOpenTechForm(templateTechVerify)"
                                                *ngIf="(selectedLabBookingDetail && ((selectedLabBookingDetail.labBookingStatusId === 6 && selectedLabBookingDetail.technicianId === null) ||  selectedLabBookingDetail.status == 'Hold' ) &&showTechnitionVerify)">
                                            <i class="mdi mdi-check mr-1"></i> Verify and Submit
                                        </button>
                                    </span>
                                    <span>
                                        <button type="button" (click)="onSubmit()" class="btn btn-sm btn-success mr-1"
                                                *ngIf="(!doctorDetails && page.userAccount.roleId == 3) && !showTechnitionVerify && showDoctorVerify">
                                            <span *ngIf="!submitting">
                                                <i class="mdi mdi-check mr-1"></i>Submit
                                            </span>
                                            <span *ngIf="submitting">
                                                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                Please wait..
                                            </span>

                                        </button>
                                        <button type="button" class="btn btn-sm btn-success mr-1" (click)="onVerifyDoctor()"
                                                *ngIf="(!doctorDetails && page.userAccount.roleId == 3) && !showTechnitionVerify && showDoctorVerify">
                                            <i class="mdi mdi-check mr-1"></i> Verify
                                        </button>
                                    </span>
                                    <span>
                                        <button type="button" [disabled]="showButton" (click)="onSubmit()" class="btn btn-sm btn-primary"
                                                *ngIf="((((!doctorDetails && page.userAccount.roleId == 3 && resultsType=='verifiedReports') || (selectedLabBookingDetail && !selectedLabBookingDetail?.technicianId )) && !showTechnitionVerify)  ||  selectedLabBookingDetail.status == 'Hold' && !showTechnitionVerify && !doctorDetails )">
                                            <span *ngIf="!submitting">
                                                <i class="mdi mdi-check mr-1"></i>Submit
                                            </span>
                                            <span *ngIf="submitting">
                                                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                Please wait..
                                            </span>

                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3" *ngIf="showTechnitionVerify || showDoctorVerify">
                    <div class="headingStyle">
                        <div class="trapezium font-weight-semibold text-white">
                            <span class="p-1"><i class="mdi mdi-timeline-text-outline me-1"></i> Timeline</span>
                        </div>
                    </div>
                    <div class="card p-2">
                        <new-lab-timeline [newLabBookingDetailId]="newLabBookingDetailId" [newLabBookingHeaderId]="newLabBookingHeaderId" [isCloseHeader]="true"></new-lab-timeline>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<ng-template #templateReferenceRange>
    <div class="modal-header d-flex justify-content-between">
        <div>
            <h4 class="modal-title">Lab Parameters Group</h4>
        </div>
        <div>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
    </div>
    <div class="modal-body">
        <table class="table table-centered table-sm table-striped table-bordered mb-0 heading1" style="padding:0px !important">
            <thead class="table-user">
                <tr>
                    <th>S.No</th>
                    <th>RangeText</th>
                    <th>Gender</th>
                    <th>FromAge</th>
                    <th>ToAge</th>
                    <th>MinValue</th>
                    <th>MaxValue</th>
                    <th>Min Critical</th>
                    <th>Max Critical</th>
                    <th>Unit</th>
                    <th class="text-right">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let group of selectedParameter.allParameterDetail;index as i;">
                    <td>#{{i+1}}</td>
                    <td>
                        <span [textContent]="group.rangeText"></span>
                    </td>
                    <td>
                        <span [textContent]="group.gender"></span>
                    </td>
                    <td>
                        <span [textContent]="group.fromAge"></span>&nbsp;<span [textContent]="group.fromAgeType"></span>
                    </td>
                    <td>
                        <span [textContent]="group.toAge"></span>&nbsp;<span [textContent]="group.toAgeType"></span>
                    </td>
                    <td>
                        <span [textContent]="group.minValue"></span>
                    </td>
                    <td>
                        <span [textContent]="group.maxValue"></span>
                    </td>
                    <td>
                        <span [textContent]="group.minCriticalValue || '--'"></span>
                    </td>
                    <td>
                        <span [textContent]="group.maxCriticalValue || '--'"></span>
                    </td>
                    <td>
                        <span [textContent]="group.unitName || '--'"></span>
                    </td>
                    <td>
                        <button type="button" class="btn btn-sm btn-outline-primary" (click)="onSelectParameter(group)"><i class="mdi mdi-check"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #assignDoctor>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-cash mr-1"></i>Doctors</h4>
        <div>
            <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal()">×</button>
        </div>
    </div>
    <div class="modal-body" *ngIf="loadingProviders">
        <div class="d-flex align-items-center">
            <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
            <span class="ml-2">Please wait while Provider details....</span>
        </div>
    </div>
    <div class="modal-body" *ngIf="provideredit">
        <div>
            <table class="table table-centered table-bordered table-sm mb-0">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Assigned To</th>
                        <th>Result</th>
                        <th *ngIf="doctorDropDown">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let provider of providerAssigned;index as p;">
                        <td><h6 class="mb-0 mt-0" [textContent]="p+1"></h6></td>
                        <td> <h6 class="mb-0 mt-0" [textContent]="provider.providerName"></h6></td>
                        <td><h6>{{provider.approved ? "Verified" : "NotVerified"}}</h6></td>
                        <td *ngIf="!provider.approved">
                            <button class="btn btn-sm btn-outline-danger" (click)="onDeleteAssignedDoctor(provider)"><i class="mdi mdi-close mr-1"></i> Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-body" *ngIf="!loadingProviders && doctorDropDown">
        <div>
            <form [formGroup]="providerAssigningForm" (ngSubmit)="assignDoctors()">
                <div class="col-12 mb-2">
                    <div>
                        <ng-select [items]="providers"
                                   [loading]="loadingproviderAssigning"
                                   [clearable]="!provideredit"
                                   bindLabel="value"
                                   bindValue="id"
                                   [searchFn]="customSearch"
                                   placeholder="Enter Doctor Name/Department Name"
                                   (search)="fetchDoctorSpecializationHelper($event)"
                                   [multiple]="true"
                                   (change)="onChangeProvider($event)"
                                   [ngClass]="{ 'is-invalid': (submitted && forms.providerSchedule.approvedBy.errors) }"
                                   (remove)="onRemove($event)"
                                   formControlName="approvedBy">
                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                <div class="ng-option disabled" *ngIf="searchTerm && searchTerm.length >= 2">
                                    No Doctor found for '{{searchTerm}}'
                                </div>
                                <div class="ng-option disabled" *ngIf="(!searchTerm || searchTerm.length < 2)">
                                    Search Doctor
                                </div>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item" let-i="index">
                                <div class="media">
                                    <div class="avatar-xs mr-1">
                                        <span class="avatar-title rounded-circle font-11 font-weight-bold bg-soft-warning text-primary"><i class="fe-map-pin"></i></span>
                                    </div>
                                    <div class="media-body">
                                        <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                        <span class="d-block font-13" [textContent]="item.optionalText || '-----'"></span>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
                    <button type="submit" [disabled]="submitting" class="btn btn-primary btn-sm">
                        <span *ngIf="submitting">
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            Please wait..
                        </span>
                        <span *ngIf="!submitting">Submit</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</ng-template>


<ng-template #showPreviousResults>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-cash mr-1"></i>Amendment's </h4>
        <div>
            <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal()">×</button>
        </div>
    </div>
    <div class="modal-body" *ngIf="loadingObservedValues">
        <div class="d-flex align-items-center">
            <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
            <span class="ml-2">Please wait while Provider details....</span>
        </div>
    </div>
    <div class="modal-body">
        <div>
            <h5 class="badge badge-outline-primary"> <i class="mdi mdi-information mr-1 text-primary "></i>The Final result will Appear on Left side of screen </h5>
        </div>
        <div class="d-flex border font-15 font-weight-bold" *ngIf="!loadingObservedValues">
            <div class="p-1">
                <div *ngFor="let i of parameterNames" class="p-1 white-space-nowrap border-bottom">
                    {{i.parameterName}}
                </div>
                <br />
                <div>UpdatedBy</div>
            </div>

            <div class="overflow-auto d-flex flex-grow-1 pr-1" *ngIf="totalObservedValues2">
                <div *ngFor="let item of totalObservedValues2.slice().reverse() " class="border-left flex-grow-1 p-1">
                    <div class="flex-grow-1" [ngClass]="{'text-success':item[0].active}">
                        <div *ngFor="let each of item" class="p-1 border-bottom">
                            <div [ngStyle]="{'color': each.isChanged ? '#ff0000':'#000000'}">
                                {{each.observedValue || '--'}}
                            </div>

                        </div>
                        <br />
                        <span class="white-space-nowrap"><i [ngClass]="{'text-success':item[0].active}" class="mdi mdi-account text-primary mr-1"></i>{{item[0].createdByName}}</span>
                        <br />
                        <div class="white-space-nowrap"><i [ngClass]="{'text-success':item[0].active}" class="mdi mdi-calendar text-primary mr-1"></i><small class="white-space-nowrap" [textContent]="item[0].createdDate |date: 'dd-MM-yyyy'"></small></div>
                        <div class="white-space-nowrap"><i [ngClass]="{'text-success':item[0].active}" class="mdi mdi-clock text-primary mr-1"></i><small [textContent]="item[0].createdDate |date: 'hh:mm a'"></small></div>
                        <h5 class="badge badge-outline-success" *ngIf="item[0].active">ActiveResult</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #templateLabReport>
    <div class="modal-header" *ngIf="showLabReport">
        <h4 class="modal-title"><i class="mdi mdi-cash mr-1"></i>Lab Report</h4>
        <div class="d-flex">
            <div>
                <button class="btn btn-sm" [class.btn-light]="!isPrintLogo" [class.btn-outline-light]="isPrintLogo" type="button" (click)="onChangePrintType(false)"><i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo</button>
                <button class="btn btn-sm" [class.btn-light]="isPrintLogo" [class.btn-outline-light]="!isPrintLogo" type="button" (click)="onChangePrintType(true)"><i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo</button>
                <button class="btn btn-sm" [class.btn-light]="!isFooter" [class.btn-outline-light]="isFooter" type="button" (click)="onChangeFooter(false)"><i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer</button>
                <button class="btn btn-sm" [class.btn-light]="isFooter" [class.btn-outline-light]="!isFooter" type="button" (click)="onChangeFooter(true)"><i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer</button>
            </div>
            <div>
                <button [useExistingCss]="true" printSectionId="invoiceId" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none"><i class="fe-printer mr-1"></i>Print</button>
                <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
            </div>
        </div>
    </div>
    <interim-report [isPrintLogo]="isPrintLogo" [encryptedNewLabBookingDetailId]="encryptedNewLabBookingDetailId" (onClose)="onCloseExtraPopup()" [isFooter]="isFooter"></interim-report>

</ng-template>


<ng-template #templateTechVerify>
    <div *ngIf="showLabReport">
        <form [formGroup]="techForm" (ngSubmit)="onSubmitVerify()">
            <div class="modal-header">
                <h4 class="modal-title"><i class="mdi mdi-check mr-1"></i>Verification</h4>
                <div>
                    <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal()">×</button>
                </div>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label>Approval Comment</label>
                    <div>
                        <ckeditor [editor]="notesEditor" [config]="config" placeholder="Enter approving comment here" formControlName="technicianComment"></ckeditor>
                    </div>
                    
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
                <button type="submit" [disabled]="submitting" class="btn btn-primary btn-sm">
                    <span *ngIf="submitting">
                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                        Please wait..
                    </span>
                    <span *ngIf="!submitting">Submit</span>
                </button>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #templateViewReport>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-upload mr-1"></i>View Document</h4>
        <div>
            <button class="btn btn-outline-danger mr-1" (click)="onDeleteDocument()">
                <i class="mdi mdi-delete-variant"></i> Delete
            </button>
            <button class="btn btn-light btn-xs mr-1 d-print-none" placement="right" type="button" (click)="onPrintPdf()"><i placement="right" class="mdi mdi-printer mr-1"></i> print</button>
            <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal()">×</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12" *ngIf="!loadingFile && !formedUrl">
                <div class="form-group mb-1">
                    <p class="text-justify font-weight-bold" style="box-sizing:border-box"></p>
                </div>
            </div>
        </div>
    </div>
    <div class="heading1 pl-1 pr-1">
        <pdf-viewer [src]="reportData['changingThisBreaksApplicationSecurity']"
                    [render-text]="true"
                    [autoresize]="true"
                    [original-size]="false"
                    [fit-to-page]="false"
                    (on-progress)="onProgress($event)"
                    style="width: 100%; height: 500px">
        </pdf-viewer>
    </div>
</ng-template>

<ng-template #templateMultipleUploadDocument>
    <form [formGroup]="uploadForm" (ngSubmit)="onUploadDocument()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-file-multiple mr-1"></i>Upload Documents</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onMutipleDocumetCloseModel()">×</button>
        </div>
        <div class="modal-body">
            <div class="row appointment-form bg-pattern bg-light mb-3">
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="mb-1">Document Name <code>*</code></label>
                        <input type="text" autofocus formControlName="documentName" maxlength="100" block autocomplete="nope" class="form-control" placeholder="Enter document name" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group mb-0">
                        <label class="mb-1">Description</label>
                        <textarea rows="1" cols="20" formControlName="description" block maxlength="250" autocomplete="nope" class="form-control" placeholder="Enter document description"></textarea>
                    </div>
                </div>
            </div>
            <file-dropper [expandable]="true"
                          [maxFiles]="maxFiles"
                          [maxFileSize]="10000"
                          [accept]="'application/pdf,image/jpeg, image/pjpeg, image/png, image/gif,video/mp4'"
                          (onSelectEmitter)="onFileSelect($event)">
            </file-dropper>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default btn-sm" (click)="onCloseModal();">Cancel</button>
            <button type="submit" [disabled]="submitting" class="btn btn-primary btn-sm ml-1">
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting">Upload Document</span>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #templateUploadDocument>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-upload mr-1"></i>View Document and Upload Document</h4>
        <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onCloseModalView()">×</button>
    </div>
    <div class="row">
        <div class="modifying-content" *ngIf="modifying">
            <p>Please wait while {{modifyingContent}}.</p>
        </div>
        <div class="col-lg-4 mb-3" *ngFor="let item of documents">
            <div class="document-container">
                <div class="document-thumbnail">
                    <a href="javascript:;" (click)="onOpenModalView(templateViewDocument,item)"><img src="assets/images/documentImage1.png" [alt]="item.documentName" /></a>
                </div>
                <div class="document-body">
                    <h4><a href="javascript:;" (click)="onOpenModalView(templateViewDocument,item)" class="text-dark" [textContent]="item.documentName"></a></h4>
                    <h6 [textContent]="item.documentType"></h6>
                    <p class="text-lowercase"><span [textContent]="item.size | formatBytes"></span> - <span class="text-primary" [textContent]="item.uploadedDate | date:'dd-MM-yyyy hh:mm a'"></span></p>
                </div>
                <div class="document-actions">
                    <div *ngIf="item.uploadedBy === page.userAccount.accountId">
                        <a href="javascript:;" placement="left" ngbTooltip="Delete" (click)="onDelete(item)"><i class="mdi mdi-trash-can-outline"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 mh-300">
            <div class="no-data">
                <button class="btn btn-sm btn-outline-primary"
                        (click)="onOpenModalMultiple(templateMultipleUploadDocument)">
                    Upload Document
                </button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #templateViewDocument>
    <div class="modal-header">
        <h4 class="modal-title"><span [textContent]="document.documentName"></span></h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="modal-body">
        <a href="javascript:;" (click)="onPrevious()" class="previous-document" placement="left" ngbTooltip="Previous document" *ngIf="showPrevious"><i class="mdi mdi-arrow-left-bold"></i></a>
        <a href="javascript:;" (click)="onNext()" class="next-document" placement="left" ngbTooltip="Next document" *ngIf="showNext"><i class="mdi mdi-arrow-right-bold"></i></a>
        <div class="p-3" *ngIf="loadingDocument">
            <div class="d-flex align-items-center">
                <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                <span class="ml-3">Please wait while loading Document ...</span>
            </div>
        </div>
        <div *ngIf="!loadingDocument && documentError">
            <div class="position-relative mh-300">
                <no-data title="Document"></no-data>
            </div>
        </div>
        <ng-container *ngIf="document && document.isImage">
            <img [src]="document.formedUrl" [hidden]="loadingDocument || documentError" (load)="onDocumentLoad()" (error)="onDocumentError()" [alt]="document.documentName" />
        </ng-container>
        <ng-container *ngIf="document && document.isVideo">
            <video style="width: 100%; height: 100%" controls (click)="toggleVideo()" #videoPlayer autoplay>
                <source [src]="document.formedUrl" (load)="onDocumentLoad()" [type]="document.contentType">
            </video>
        </ng-container>
        <ng-container *ngIf="document && !document.isImage && !document.isVideo">
            <ng-container *ngIf="document && document.formedUrl">
                <pdf-viewer [src]="document.formedUrl['changingThisBreaksApplicationSecurity']"
                            [render-text]="true"
                            [autoresize]="true"
                            [original-size]="false"
                            [fit-to-page]="false"
                            (on-progress)="onProgress($event)"
                            (error)="onDocumentError()"
                            style="width: 100%; height: 500px">
                </pdf-viewer>
            </ng-container>
        </ng-container>
    </div>
</ng-template>
<ng-template #templateShowPastReports>
    <div class="modal-header d-flex justify-content-between">
        <div>
            <h4 class="modal-title">Past Lab Reports</h4>
        </div>
        <div>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="row p-2" *ngIf="loadingPastLabReports">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading ...</span>
                </div>
            </div>
        </div>
        <div class="row p-1" *ngIf="!loadingPastLabReports && (!patientPastLabReports || !patientPastLabReports.length)">
            <div class="col-12 mh-300">
                <no-data [applied]="false" [title]="'Past Tests'"></no-data>
            </div>
        </div>
        <div class="col-12">
            <div class="card-box p-0 overflow-auto">
                <table class="table table-sm table-bordered" *ngIf="!loadingPastLabReports && patientPastLabReports.length > 0">
                    <thead class=" ">
                        <tr>
                            <th>Test Name </th>
                            <th>TypeOfLab</th>
                            <th>BookedDate</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let item of patientPastLabReports;index as i;">
                            <td>
                                <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.testName"></h5>
                                <small class="d-block" [textContent]="item.testCode || '--'"></small>
                            </td>
                            <td>
                                <span class="badge badge-success" *ngIf="item.isInternalLab">Internal</span>
                                <span class="badge badge-secondary" *ngIf="item.isExternalLab">External</span>
                            </td>
                            <td>
                                <span [textContent]="item.createdDate | date:'dd-MM-yyyy, hh:mm a'"></span>
                            </td>
                            <td>
                                <span class="badge badge-outline-secondary" [textContent]="item.status"></span>
                            </td>
                            <td>
                                <button type="button" placement="left" ngbTooltip="View Report" class="btn mt-1 btn-sm btn-outline-info" (click)="onViewLabReport(item,templateReport)">
                                    <i class="mdi mdi-eye mr-1"></i> View Report
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="5">
                                <nav class="d-flex align-items-center justify-content-between flex-wrap">
                                    <p class="mb-0 font-13">
                                        <span class="text-dark">Page <span [textContent]="pagination1.currentPage+1"></span> of <span [textContent]="pagination1.totalPages"></span></span>
                                        <span class="ml-1">
                                            <span>(Showing <span [textContent]="pagination1.currentItems - patientPastLabReports.length + 1"></span> - <span [textContent]="pagination1.currentItems"></span> of <span [textContent]="pagination1.totalItems"></span> records)</span>
                                        </span>
                                    </p>
                                    <ngb-pagination class="pagination justify-content-end" [maxSize]="5" [rotate]="false" [ellipses]="false" [(page)]="pagination1.pageIndex" [pageSize]="pagination1.pageSize" (pageChange)="onNextTemplatePage()" [collectionSize]="pagination1.totalItems">
                                        <ng-template ngbPaginationPrevious><i class="fe-arrow-left"></i></ng-template>
                                        <ng-template ngbPaginationNext><i class="fe-arrow-right"></i></ng-template>
                                    </ngb-pagination>
                                </nav>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #templateReason>
    <div class="center-position">
        <div class="modal-header">
            <h4 class="modal-title"><span [textContent]="'Reason'"></span></h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseReasonView();">×</button>
        </div>
        <div class="modal-body">
            <div class="col-md-12">
                <div class="form-group">
                    <label class="mb-1">Reason <code>*</code></label>
                    <input type="text" class="form-control" [(ngModel)]="reason" placeholder="Enter Reason">
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end  mb-1 mr-3">
            <button class="btn btn-sm btn-primary"
                    (click)="onRemoveParameters()">
                Submit
            </button>
        </div>
    </div>
</ng-template>
<ng-template #parametersListView>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-cash mr-1"></i>Lab Reports</h4>
        <div>
            <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal()">×</button>
        </div>
    </div>
    <div class="content top">
        <div class="container-fluid">
            <div class="col-12 p-0 d-flex">
                <div class="table-responsive col-6">
                    <table class="table table-sm table-centered table-hover m-0 table-bordered table-striped">
                        <thead>
                            <tr role="row">
                                <th></th>
                                <th>Test Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let param of parametersList;index as i;" (click)="onClickParameter(param)">
                                <td>{{i+1}}</td>
                                <td class="text-uppercase break-all" [textContent]="param.parameterName"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-6" *ngIf="isGraphShow">
                    <graph-view [graphData]="graphData"></graph-view>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #getAllLabsView>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-cash mr-1"></i>Lab Reports</h4>
        <div>
            <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal()">×</button>
        </div>
    </div>
    <div class="content top">
        <div class="container-fluid">
            <div class="col-12 p-0 d-flex">
                <div class="table-responsive col-3">
                    <table class="table table-sm table-centered table-hover m-0 table-bordered table-striped">
                        <thead>
                            <tr role="row">
                                <th></th>
                                <th>Lab Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lab of labs;index as i;" (click)="onClickLab(lab)">
                                <td>{{i+1}}</td>
                                <td class="text-uppercase break-all" [textContent]="lab.testName"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <table *ngIf="isShowParameters" class="table table-sm table-centered table-hover m-0 table-bordered table-striped">
                    <tbody>
                        <tr *ngFor="let lab of labObservedValues;index as i;">
                            <td class="text-uppercase break-all" [textContent]="lab.parameterName"></td>
                            <td class="text-uppercase break-all" *ngFor="let observedValue of lab.observedValues">{{observedValue}}</td>
                            <td>
                                <button type="button" (click)="onClickParameter(lab)" class="btn btn-xs btn-primary rounded-pill">Show Graph</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #graphView>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-cash mr-1"></i>Graph</h4>
        <div>
            <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onCloseGraph()">×</button>
        </div>
    </div>
    <div>
        <graph-view [graphData]="graphData"></graph-view>
    </div>
</ng-template>
<ng-template #templateReport>
    <div *ngIf="showLabReport">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-cash mr-1"></i>Lab Report</h4>
            <div class="d-flex">
                <div>
                    <button class="btn btn-sm" [class.btn-light]="!isPrintLogo" [class.btn-outline-light]="isPrintLogo" type="button" (click)="onChangePrintType(false)"><i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo</button>
                    <button class="btn btn-sm" [class.btn-light]="isPrintLogo" [class.btn-outline-light]="!isPrintLogo" type="button" (click)="onChangePrintType(true)"><i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo</button>
                </div>
                <div>
                    <button *ngIf="!isExternal1" [useExistingCss]="true" printSectionId="invoiceId" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none"><i class="fe-printer mr-1"></i>Print</button>
                    <button *ngIf="isExternal1" [useExistingCss]="true" (click)="onPrintPdf2()" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none"><i class="fe-printer mr-1"></i>Print</button>
                    <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onLabReportModal();">×</button>
                </div>
            </div>
        </div>
        <div Id="invoiceId">
            <new-lab-report [isPrintLogo]="isPrintLogo" [encryptedNewLabBookingDetailId]="selectedLabBookingDetail.encryptedNewLabBookingDetailId" (isExternal)="OnCheckIsExternal($event)" (uplodpfd)="OnPdf($event)" (onClose)="onCloseExtraPopup()"></new-lab-report>
            <div *ngIf="isExternal1">
                <div *ngIf="isPrintLogo">
                    <banner-setting [reportName]='"Lab Report Header"'></banner-setting>
                </div>
                <div class=" pl-1 pr-1" *ngIf="reportData">
                    <pdf-viewer [src]="reportData['changingThisBreaksApplicationSecurity']"
                                [render-text]="true"
                                [autoresize]="true"
                                [original-size]="false"
                                [fit-to-page]="false"
                                (on-progress)="onProgress($event)"
                                class="pdfstyle">
                    </pdf-viewer>
                </div>
            </div>
        </div>
    </div>
</ng-template>

