<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <!--<div class="page-title-right">
                              <button type="button" class="btn btn-sm btn-primary" (click)="onOpenModel(templateForTransferRequest)"><i class="mdi mdi-plus mr-1"></i>Raise Transfer Request</button>
                          </div>-->
                    <h4 class="page-title">Transfer Request</h4>
                </div>
            </div>
        </div>

        <div>
            <div class="card-box mb-1 p-1 pb-2">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-6 col-md-4 col-lg-4 col-xl-2">
                            <label class="mb-1"> From Date </label>
                            <input class="form-control" type="date" placeholder="Select from date" [(ngModel)]="filters.options.fromDate" #fromDate (input)="changeMaxDate(fromDate.value)" />
                        </div>
                        <div class="col-6 col-md-4 col-lg-4 col-xl-2">
                            <label class="mb-1">To date</label>
                            <input class="form-control" type="date"  placeholder="Select To date" [(ngModel)]="filters.options.toDate" #toDate (input)="changeMinDate(toDate.value)" />
                        </div>
                        <div class="col-6 col-md-4 col-lg-4 col-xl-2">
                            <div class="form-group mb-0">
                                <label class="mb-1">Patient Name</label>
                                <ng-select class="ng-select-sm text-uppercase"  [items]="patients"
                                           bindLabel="optionalText1"
                                           bindValue="optionalText2"
                                           typeToSearch="Search Patient"
                                           placeholder="Enter Patient Name"
                                           [(ngModel)]="filters.options.fullName">
                                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                        No Patient found<span *ngIf="searchTerm"> for '{{searchTerm}}'</span>.
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-6 col-md-4 col-lg-4 col-xl-2">
                            <div class="form-group mb-0">
                                <label class="mb-1">Type</label>
                                <select class="form-control form-control-sm" [(ngModel)]="filters.options.changeRequestType">
                                    <option [ngValue]="null">All</option>
                                    <option [ngValue]="'Bed'">Bed</option>
                                    <option [ngValue]="'DoctorUnit'">Doctor Unit</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6 col-md-4 col-lg-4 col-xl-2">
                            <div class="form-group mb-0">
                                <label class="mb-1">Status</label>
                                <select class="form-control form-control-sm" [(ngModel)]="filters.options.status">
                                    <option [ngValue]="null">All</option>
                                    <option [ngValue]="'Accepted'">Accepted</option>
                                    <option [ngValue]="'Rejected'">Rejected</option>
                                    <option [ngValue]="'Pending'">Pending</option>
                                </select>
                            </div>
                        </div>
                        <div class="d-flex form-group mb-0 justify-content-center lessThan1468">
                            <button type="button"
                                    (click)="onApplyFilters()"
                                    class="btn btn-sm btn-primary rounded-pill">
                                <i class="mdi mdi-magnify mr-1"></i>Search
                            </button>
                            <button type="button"
                                    (click)="onResetFilters()"
                                    class="btn btn-sm reset-btn rounded-pill ml-1">
                                <i class="mdi mdi-backup-restore mr-1"></i>Reset
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 p-0">
            <div class="card-box p-0">
                <div class="row p-2" *ngIf="loading">
                    <div class="col-12">
                        <div class="d-flex align-items-center">
                            <span class="spinner-grow text-warning"
                                  role="status"
                                  aria-hidden="true"></span>
                            <span class="ml-2">Please wait while loading ...</span>
                        </div>
                    </div>
                </div>
                <div class="row p-2" *ngIf="!loading && (!records || !records.length)">
                    <div class="col-12 mh-355">
                        <div class="no-data">
                            <img src="assets/images/no-data.png" alt="No data" />
                            <p class="sub-title">There is no data to show you right now.</p>
                            <!--<button type="button" class="btn btn-primary btn-sm" (click)="onOpenModel(templateForTransferRequest)"><i class="fe-plus mr-1"></i> Raise Transfer Request</button>-->
                        </div>
                    </div>
                </div>

                <div class="overflow-auto"
                     *ngIf="!loading && records && records.length > 0">
                    <table class="table table-centered table-sm table-striped table-borderless mb-0">
                        <thead>
                            <tr>
                                <th>Patient Name</th>
                                <th>Type</th>
                                <th>Doctor Unit Name</th>
                                <th>Charge Categories</th>
                                <th>Admission No</th>
                                <th>UMR No</th>
                                <th>Created By</th>
                                <th>Comments</th>
                                <th>Approved By</th>
                                <th>Rejected By</th>
                                <th>Rejected Comments</th>
                                <th class="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of records;index as i;">
                                <td  [textContent]="(item.fullName || '---')|uppercase"></td>
                                <td [textContent]="item.changeRequestType || '---'"></td>
                                <td>
                                    <span *ngIf="item.unitName"
                                          [textContent]="item.unitName"></span>                                    
                                </td>
                                <td>
                                    <span *ngIf="item.chargeCategoryName"
                                          [textContent]="item.chargeCategoryName"></span>
                                </td>
                                <td [textContent]="item.admissionNo"></td>
                                <td [textContent]="item.umrNo"></td>
                                <td>
                                    <div class="media-body">
                                        <h5 class="mb-0 mt-0 font-weight-normal"
                                            [textContent]="item.createdByName"></h5>
                                        <small class="tx-13"
                                               [textContent]="item.createdDate | date:'dd-MM-yyyy, hh:mm a'"></small>
                                    </div>
                                </td>
                                <td [textContent]="item.requestComments"></td>
                                <!--<td [textContent]="item.createdByName || '---'"></td>-->
                                <td>
                                    <div class="media-body">
                                        <h5 class="mb-0 mt-0 font-weight-normal"
                                            [textContent]="item.approvedByName"></h5>
                                        <small class="tx-13"
                                               [textContent]="item.approvedDate | date:'dd-MM-yyyy, hh:mm a'"></small>
                                    </div>
                                </td>
                                <td>
                                    <div class="media-body">
                                        <h5 class="mb-0 mt-0 font-weight-normal"
                                            [textContent]="item.rejectedByName"></h5>
                                        <small class="tx-13"
                                               [textContent]="item.rejectedDate | date:'dd-MM-yyyy, hh:mm a'"></small>
                                    </div>
                                </td>
                                <td [textContent]="item.rejectComments"></td>
                                <td>
                                    <div class="d-flex align-items-center justify-content-start">
                                        <!--<a href="javascript:;"  placement="left" ngbTooltip="Reject" ><i class="mdi mdi-close"></i></a>-->
                                        <a *ngIf="item.active"
                                           href="javascript:;"
                                           (click)="onReject(item)"
                                           placement="left"
                                           ngbTooltip="Reject">
                                            <i class="mdi mdi-close text-danger mdi-18px"></i>
                                        </a>
                                        <a *ngIf="item.active"
                                           href="javascript:;"
                                           (click)="onAcceptRequest(item)"
                                           placement="left"
                                           ngbTooltip="Accept">
                                            <i class="mdi mdi-check mdi-18px"></i>
                                        </a>
                                        <a *ngIf="!item.active && item.approvedByName"
                                           href="javascript:;"
                                           placement="left"><span class="badge badge-success">Accepted</span></a>
                                        <a *ngIf="!item.active && !item.approvedByName"
                                           href="javascript:;"
                                           placement="left"><span class="badge badge-danger">Rejected</span></a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <nav class="d-flex align-items-center justify-content-between p-2"
                     *ngIf="pagination.totalPages > 1">
                    <p class="mb-0 font-13">
                        <span class="text-dark">
                            Page <span [textContent]="pagination.currentPage"></span> of
                            <span [textContent]="pagination.totalPages"></span>
                        </span>
                        <span class="ml-1">
                            <span>
                                (Showing
                                <span [textContent]="pagination.currentItems - records.length + 1"></span>
                                - <span [textContent]="pagination.currentItems"></span> of
                                <span [textContent]="pagination.totalItems"></span> Records
                                )
                            </span>
                        </span>
                    </p>
                     <ngb-pagination class="pagination justify-content-end" [maxSize]="5" [rotate]="false" [ellipses]="false" [(page)]="pagination.pageIndex" [pageSize]="pagination.pageSize" (pageChange)="onNextPage()" [collectionSize]="pagination.totalItems">
                              <ng-template ngbPaginationPrevious><i class="fe-arrow-left"></i></ng-template>
                              <ng-template ngbPaginationNext><i class="fe-arrow-right"></i></ng-template>
                          </ngb-pagination> 
                </nav>
            </div>
        </div>
    </div>
</div>

<ng-template #templateForTransferRequest>
    <form [formGroup]="transferRequestForm" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h4 class="modal-title">Raise Transfer Request</h4>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    (click)="onCloseModal();">
                ×
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-4">
                    <div class="form-group">
                        <label class="mb-0">Patient Search <code>*</code></label>
                        <ng-select [items]="patients"
                                   [loading]="searching"
                                   [clearable]="true"
                                   bindLabel="name"
                                   bindValue="id"
                                   placeholder="Enter Patient Name/mobile/Umr"
                                   (search)="fetchPatient($event)"
                                   formControlName="admissionId"
                                   [ngClass]="{ 'is-invalid': submitted && form.admissionId.errors }">
                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                <div class="ng-option disabled"
                                     *ngIf="searchTerm && searchTerm.length >= 2">
                                    No patient found for '{{searchTerm}}'
                                </div>
                                <div class="ng-option disabled"
                                     *ngIf="(!searchTerm || searchTerm.length < 2)">
                                    Search patient
                                </div>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item" let-i="index">
                                <div class="media">
                                    <div class="avatar-xs mr-2">
                                        <!-- <avatar-img
                                          *ngIf="item.optionalText"
                                          [src]="item.optionalText"
                                          [alt]="item.name"
                                          cssClass="img-fluid rounded-circle"
                                        ></avatar-img> -->
                                        <span *ngIf="!item.optionalText"
                                              class="avatar-title rounded-circle font-10 bg-soft-warning font-weight-bold text-warning"
                                              [textContent]="item.name | initials"></span>
                                    </div>
                                    <div class="media-body">
                                        <h5 class="mb-0 mt-0 font-weight-normal"
                                            [textContent]="item.name"></h5>
                                        <span class="d-block font-13"
                                              [textContent]="item.optionalText1 +' / '+ item.optionalText2 || '-----'"></span>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-5 mb-1">
                                <div class="custom-control custom-switch mb-0">
                                    <input type="checkbox"
                                           id="bed"
                                           name="bed"
                                           (change)="onChangeBed()" />
                                    <label for="bed" [textContent]="'Bed Request'"></label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-1">
                                <div class="custom-control custom-switch mb-0">
                                    <input type="checkbox"
                                           id="generalItem"
                                           name="doctor"
                                           (change)="onChangeDoctorRequest()" />
                                    <label for="generalItem"
                                           [textContent]="'Doctor Request'"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="isBed">
                <div class="form-group">
                    <label class="mb-1">Charge Category</label>
                    <ng-select class="ng-select-sm text-uppercase"
                               [items]="chargeCategories"
                               bindLabel="name"
                               bindValue="id"
                               autocomplete="nope"
                               placeholder="Select charge categories"
                               formControlName="chargeCategoryId">
                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                            <div class="ng-option disabled">
                                No charge Categories found for '{{searchTerm}}' Z
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="row" *ngIf="isDoctorRequest">
                <div class="form-group">
                    <label class="mb-1">Doctor Units</label>
                    <ng-select class="ng-select-sm"
                               [items]="DoctorUnits"
                               [loading]="loadingDoctorUnits"
                               bindLabel="unitName"
                               bindValue="doctorUnitMasterId"
                               typeToSearchText="Search doctorUnits"
                               placeholder="Enter Doctor Unit Name"
                               formControlName="doctorUnitMasterId">
                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                            No Doctor Units found<span *ngIf="searchTerm">
                                for '{{searchTerm}}'
                            </span>.
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-i="index">
                            <h5 class="mb-1 mt-0 font-weight-normal"
                                [textContent]="item.unitName"></h5>
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item" let-i="index">
                            <div class="media">
                                <div class="avatar-xs mr-1">
                                    <span class="avatar-title rounded-circle font-11 font-weight-bold bg-soft-warning text-primary">
                                        <i class="fe-map-pin"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h5 class="mb-0 mt-0 font-weight-normal"
                                        [textContent]="item.unitName"></h5>
                                </div>
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit"
                    class="btn btn-sm btn-primary mr-1"
                    [disabled]="submitting">
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting"><i class="mdi mdi-content-save mr-1"></i>Submit</span>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #chargeCategoryChangeTemplate>
    <div class="modal-header">
        <h4 class="modal-title">Bed Request</h4>
        <button type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
                (click)="onCloseModal();">
            ×
        </button>
    </div>
    <div class="modal-body">
        <div>
            <button tabindex="0"
                    type="button"
                    *ngFor="let item of filterChargeCategory; let i = index;"
                    class="slot btn btn-primary"
                    (click)="onSelectChargeCategory(item)">
                <i [class]="'icon-' + 'clock-1'"></i>
                <span [textContent]="item.name"></span>
            </button>
        </div>
        <div *ngIf="!showBeds">
            <span style="font-weight: bold">
                <span class="align-items-center">Please select the charge type to see available beds..</span>
            </span>
        </div>
        <div *ngIf="showBeds">
            <div class="row" *ngIf="loadingBeds">
                <div class="col-md-12">
                    <span class="align-items-center">Please wait while loading beds..</span>
                </div>
            </div>
            <div class="row" *ngIf="!loadingBeds && beds.length <=0">
                <div class="col-md-12">
                    <span style="font-weight: bold">
                        <span class="align-items-center">There are no beds to select.</span>
                    </span>
                </div>
            </div>
            <div class="row" *ngIf="!loadingBeds">
                <div class="mb-1" *ngFor="let item of beds">
                    <button type="button"
                            class="btn mr-1"
                            [ngClass]="{'btn-soft-pink': item.gender !== 'M' && item.bedStatusName !== 'Available', 'btn-soft-blue': item.gender === 'M' && item.bedStatusName !=='Available', 'btn-success' : selectedBedManagement.bedId === item.bedId , 'btn-light' : selectedBedManagement.bedId !== item.bedId}"
                            [ngStyle]="{'background-color': (selectedBedManagement.bedId === item.bedId) ? 'btn-success':'btn-light'}"
                            (click)="selectBed(item);"
                            style="box-shadow: 5px 5px 37px #c3c3c3">
                        {{item.bedNumber}}<br />
                        <span class="text-white"
                              *ngIf="selectedBedManagement.bedId === item.bedId"
                              style="font-size: 16px">
                            <i class="fe-check"></i>
                        </span>
                        <!--<span class="text-primary mr-1" style="font-size: 11px">Room Rent: <b>₹ {{item.roomRent}}</b></span>-->
                        <span class="text-primary"
                              *ngIf="selectedBedManagement.bedId !== item.bedId"
                              [ngClass]="(item.bedStatusName === 'Available')?'text-success':'text-danger'"
                              style="font-size: 11px">
                            <b>{{item.bedStatusName}}</b>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="form-group mb-0">
            <input type="checkbox" id="unCollect" name="unCollect" [(ngModel)]="changeBillingCategory">
            <span for="unCollect">Change Billing category</span>&nbsp;
            <!--<label class="mb-1">show financial details</label>-->
            <!--<input type="checkbox" [(ngmodel)]="filters.options.isShowFinancialDetails" class="form-control">
        <label [textContent]="'Show Finacial Details'"></label>-->
        </div>
        <button type="submit"
                class="btn btn-sm btn-primary mr-1"
                [disabled]="submitting"
                (click)="onSubmit()">
            <span *ngIf="submitting">
                <span class="spinner-border spinner-border-sm mr-1"
                      role="status"
                      aria-hidden="true"></span>
                Please wait..
            </span>
            <span *ngIf="!submitting"><i class="mdi mdi-content-save mr-1"></i>Submit</span>
        </button>
    </div>
</ng-template>
<ng-template #reasonForCancel>
    <div class="modal-header">
        <h4 class="modal-title">Reason For Reject</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group mb-3">
                    <label class="mb-1">Reason<code>*</code> </label>
                    <div class="form-group">
                        <input tabindex="1" type="text" maxlength="150" [(ngModel)]="comments" (ngModelChange)="inputComments($event)" autocomplete="nope" class="form-control" placeholder="Type reason" required />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
        <button type="button" [disabled]="loading" class="btn btn-primary btn-sm" (click)="cancelHelper()">
            <span *ngIf="loading">
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                Please wait..
            </span>
            <span *ngIf="!loading">Submit</span>
        </button>
    </div>
</ng-template>
