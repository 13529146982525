<div class="container-fluid">
    <div>
        <div class="col-12">
            <div class="page-title-box">
                <h4 class="page-title">Insurance Management</h4>
            </div>
        </div>
    </div>
    <div class="" *ngIf="loading">
        <div class="col-12">
            <div class="d-flex align-items-center">
                <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                <span class="ml-2">Please wait while loading Admissions ...</span>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!loading && (!admissions || !admissions.length)">
        <div class="col-12 mh-300">
            <no-data [applied]="filters.applied" [title]="'Admissions'"></no-data>
        </div>
    </div>
    <div>
        <div class="col-12 p-0" id="admission_print">
            <div class="card">
                <div class="card-body p-0">
                    <div class="appointmentscroll  pb-5" *ngIf="!loading && admissions.length">
                        <table class="table table-centered table-sm mb-0">
                            <thead class="stickOntop">
                                <tr>
                                    <th>Patient</th>
                                    <th>Admission No</th>
                                    <th>Doctor</th>
                                    <th>Insurance Company</th>
                                    <th>TPA Name</th>
                                    <th class="noprint-column text-right">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let item of admissions; let i = index">
                                    <tr [ngClass]="{'table-white' :  i % 2 === 0, 'table-active' : i % 2 !== 0}">
                                        <td>
                                            <div class="media rowHover" style="cursor: pointer" (click)="onViewPatientProfile(item.encryptedPatientId)">
                                                <div class="avatar-sm mr-2 patient-details-block">
                                                    <span *ngIf="!item.patientThumbnailUrl" class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="i">
                                                        <span [textContent]="item.patientName | initials"></span>
                                                        <span style="margin-left: 25px" *ngIf="item.isOnline" class="badge-active"></span>
                                                    </span>
                                                    <img *ngIf="item.patientThumbnailUrl" [src]="item.patientThumbnailUrl" [alt]="item.patientName" class="img-fluid rounded-circle">
                                                    <span style="margin-left: -10px" *ngIf="item.patientThumbnailUrl && item.isOnline" class="badge-active"></span>
                                                    <span *ngIf="item.hwcName" class="vip-badge">
                                                        <i class="mdi mdi-crown mdi-rotate-315 mdi-24px" placement="right" [ngbTooltip]="item.hwcName"></i>
                                                    </span>
                                                </div>
                                                <div class="media-body">
                                                    <h5 [ngClass]="{'text-line-through' : !item.active}" class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="item.patientName | titlecase"></h5>
                                                    <span class="d-block font-13">
                                                        <span [textContent]="item.patientGender | gender"></span>
                                                        <span class="content-breaker" [textContent]="item.patientAge + ' yrs'"></span>
                                                        <span class="fe-flag" *ngIf="item.isConvertedFromOPtoIp"></span>
                                                    </span>
                                                    <span *ngIf="item.patientPriorityId">
                                                        <i [class]="'mdi mr-1 mdi-' + item.priorityIcon + ' ' + item.priorityColor"></i><span [textContent]="item.patientPriorityName"></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="media">
                                                <div class="media-body">
                                                    <h5 [ngClass]="{'text-line-through' : !item.active}" class="mb-0 mt-0 font-weight-normal" [textContent]="'#' + item.admissionNo"></h5>
                                                    <span class="d-block font-13">
                                                        <!--<span [textContent]="item.patientType ==='I'?'In Patient ':'Casuality '"></span>-->
                                                        <span *ngIf="item.admissionNotes">
                                                            <i class="mdi mdi-information text-primary mr-1" placement="right" [ngbTooltip]="item.admissionNotes"></i>
                                                        </span>
                                                        <span *ngIf="item.locationName">
                                                            <i class="fe-map-pin mr-1"></i>
                                                            <span [textContent]="item.locationName"></span>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="media">
                                                <div class="avatar-sm mr-2">
                                                    <span class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="i" [textContent]="item.providerName | initials"></span>
                                                </div>
                                                <div class="media-body">
                                                    <h5 [ngClass]="{'text-line-through' : !item.active}" class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="item.providerName | titlecase"></h5>
                                                    <span class="d-block font-13">
                                                        <span [textContent]="item.departmentName | titlecase"></span>
                                                    </span>
                                                    <span class="small">
                                                        <span [textContent]="item.specializationName || '---' | titlecase"></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span class="d-block font-13">
                                                <span [textContent]="item.insuranceCompany || '---'"></span>
                                            </span>
                                        </td>
                                        <td>
                                            <span class="d-block font-13">
                                                <span [textContent]="item.tpaName || '---'"></span>
                                            </span>
                                        </td>
                                        <td class="noprint-column">
                                            <div class="d-flex align-items-center justify-content-end">
                                                <a class="mr-1" href="javascript:;" (click)="onCopayOpenModal(templateCopay, item)" ngbTooltip="Copay" placement="left">
                                                    <i class="mdi mdi-hand-coin"></i>
                                                </a>
                                                <a class="mr-1" href="javascript:;" (click)="onOpenModal(templateAddDocument, item)" ngbTooltip="Upload Documents" placement="left">
                                                    <i class="mdi mdi-file-upload"></i>
                                                </a>
                                                <a href="javascript:;" (click)="onOpenModal(templateAddMoney, item)" ngbTooltip="Add Money" placement="left">
                                                    <i class="mdi mdi-cash"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <tbody *ngIf="pagination.totalPages > 1">
                                <tr>
                                    <td colspan="10">
                                        <nav class="d-flex align-items-center justify-content-between p-0">
                                            <p class="mb-0 font-13">
                                                <span class="text-dark">
                                                    Page
                                                    <span [textContent]="pagination.currentPage"></span>
                                                    of
                                                    <span [textContent]="pagination.totalPages"></span>
                                                </span>
                                                <span class="ml-1">
                                                    <span>
                                                        (Showing
                                                        <span [textContent]="pagination.currentItems - admissions.length + 1"></span>
                                                        -
                                                        <span [textContent]="pagination.currentItems"></span>
                                                        of
                                                        <span [textContent]="pagination.totalItems"></span>
                                                        admissions)
                                                    </span>
                                                </span>
                                            </p>
                                            <ngb-pagination class="pagination justify-content-end" [maxSize]="5" [rotate]="true" [ellipses]="true" [(page)]="pagination.pageIndex" [pageSize]="pagination.pageSize" (pageChange)="onNextPage()" [collectionSize]="pagination.totalItems">
                                                <ng-template ngbPaginationPrevious>
                                                    <i class="fe-arrow-left"></i>
                                                </ng-template>
                                                <ng-template ngbPaginationNext>
                                                    <i class="fe-arrow-right"></i>
                                                </ng-template>
                                            </ngb-pagination>
                                        </nav>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #templateAddMoney>
    <form [formGroup]="addForm" (submit)="onUpdatePostDicount()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-cash-lock mr-1"></i>Add Money</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">
                ×
            </button>
        </div>
        <div class="modal-body">
            <ng-container *ngIf="selectedAdmission">
                <div>
                    <table *ngIf="selectedAdmission" class="table table-sm mt-1 table-bordered mb-0">
                        <tr>
                            <th class="bg-soft-warning">Patient Name</th>
                            <th class="text-uppercase" [textContent]="selectedAdmission.patientName"></th>
                            <th class="bg-soft-warning">Age</th>
                            <th [textContent]="selectedAdmission.patientAge"></th>
                            <th class="bg-soft-warning">Gender</th>
                            <th [textContent]="selectedAdmission.patientGender | gender"></th>  
                        </tr>
                        <tr>
                            <th class="bg-soft-warning">UMR No</th>
                            <th [textContent]="selectedAdmission.umrNo"></th>
                            <th class="bg-soft-warning">Mobile No</th>
                            <th [textContent]="selectedAdmission.patientMobile"></th>
                            <th class="bg-soft-warning">IPNo</th>
                            <th [textContent]="selectedAdmission.admissionNo"></th>
                        </tr>
                        <tr>
                            <th colspan="5" class="text-right">Final Bill Amount</th>
                            <th class="text-right" [textContent]="(selectedAdmission.finalAmount || 0) | currency: 'INR'"></th>
                        </tr>
                    </table>
                </div>
                <div class="card card-2 card-bordered">
                    <div class="card-header">
                        <h4 class="card-title">
                            <span class="bg-primary text-white"><i class="mdi mdi-cash-check"></i></span>Receipts
                        </h4>
                        <a href="javascript:;" (click)="onOpenReceiptModal(templateAddReceipt,selectedAdmission.insuranceManagementId,selectedAdmission.admissionId)"
                           class="font-weight-bold">
                            <i class="mdi mdi-plus"></i> Add
                        </a>
                    </div>
                    <div class="card-body">
                        <div class="overflow-auto">
                            <table class="table table-centered table-sm">
                                <thead>
                                    <tr>
                                        <th>Receipt No</th>
                                        <th>Amount</th>
                                        <th>Type</th>
                                        <th>TPA Txn</th>
                                        <th>Docs</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="!selectedAdmission.insuranceReceipts">
                                        <td colspan="8" class="p-2">No receipts has been added</td>
                                    </tr>
                                    <ng-container *ngIf="selectedAdmission.insuranceReceipts">
                                        <tr *ngFor="let item of selectedAdmission.insuranceReceipts;">
                                            <td class="text-left" [textContent]="'#'+item.insuranceReceiptId"></td>
                                            <td [textContent]="item.amount"></td>
                                            <td [textContent]="item.status==='A'?'Approved':'Rejected'"></td>
                                            <td [textContent]="item.tpaTxnRef"></td>
                                            <td>
                                                <a href="javascript:;" (click)="onOpenModalForDownload(templateViewDocument,modalType.View, item)" [textContent]="item.tpaDocRef"></a>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card card-2 card-bordered">
                    <div class="card-header">
                        <h4 class="card-title">
                            <span class="bg-primary text-white"><i class="mdi mdi-cash-minus"></i></span>Post Discount
                        </h4>
                    </div>
                    <div class="card-body">
                        <div class="overflow-auto">
                            <table class="table table-centered table-sm">
                                <tbody>
                                    <tr>
                                        <td class="text-right col-md-8 font-weight-bold">Amount</td>
                                        <td class="font-weight-bold">{{postDiscount}}</td>
                                        <td>
                                            <button type="submit" [disabled]="submitting" class="btn btn-primary ml-1">
                                                <span *ngIf="submitting">
                                                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                    Please wait..
                                                </span>
                                                <span *ngIf="!submitting">Submit</span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </form>
</ng-template>

<ng-template #templateAddReceipt>
    <form [formGroup]="addReceiptForm" (submit)="onAddReceipt()">
        <div class="modal-header d-flex justify-content-between">
            <div>
                <h4 class="modal-title"><i class="mdi mdi-exclamation-thick mr-1"></i>Add Receipt</h4>
            </div>
            <div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseReceiptModal();">
                    ×
                </button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="d-flex">
                    <div class="d-flex col-11 flex-wrap">
                        <div class="col-lg-4 col-sm-6 col-11">
                            <div class="form-group mb-0">
                                <label class="mb-1">Status <code>*</code></label>
                                <select disabled class="form-control custom-select" formControlName="status" [ngClass]="{ 'is-invalid': submitted && form.status.errors }">
                                    <option [ngValue]="null">Select</option>
                                    <option [ngValue]="'A'">Approved</option>
                                    <option [ngValue]="'R'">Rejected</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-11">
                            <div class="form-group mb-0">
                                <label class="mb-1">Amount <code>*</code></label>
                                <input type="number" class="form-control" formControlName="amount" maxlength="20" numbersOnly block autocomplete="nope" placeholder="Enter amount" [ngClass]="{ 'is-invalid': submitted && form.amount.errors }" />
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-11">
                            <div class="form-group mb-0">
                                <label class="mb-1">TPA Txn <code>*</code></label>
                                <input type="text" class="form-control" formControlName="tpaTxnRef" block autocomplete="nope" placeholder="Enter txn number" [ngClass]="{ 'is-invalid': submitted && form.tpaTxnRef.errors }" />
                            </div>
                        </div>
                        <div>
                            <div class="modal-body">
                                <file-dropper [expandable]="true"
                                              [maxFiles]="maxFiles"
                                              [maxFileSize]="10000"
                                              [accept]="'application/pdf, image/jpeg, image/pjpeg, image/png, image/gif, video/mp4'"
                                              (onSelectEmitter)="onFileSelect($event)">
                                </file-dropper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="modal-footer">
            <div class="d-flex align-items-center justify-content-end">
                <button class="btn btn-soft-secondary" type="button" (click)="onCloseReceiptModal();"><i class="mdi mdi-close"></i>Cancel</button>
                <button type="submit" [disabled]="submitting" class="btn btn-primary ml-1">
                    <span *ngIf="submitting">
                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                        Please wait..
                    </span>
                    <span *ngIf="!submitting">Submit</span>
                </button>
            </div>
        </div>
    </form>
</ng-template>


<ng-template #templateAddDocument>
    <insurance-documents (emitOnCloseModel)="onCloseModal()" [admissionId]="admissionId"></insurance-documents>
</ng-template>

<ng-template #templateViewDocument>
    <div class="modal-header">
        <h4 class="modal-title"><span [textContent]="document.documentName"></span><small class="ml-2" [textContent]="document.documentType"></small></h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseViewModal();">×</button>
    </div>
    <div class="modal-body">
        <a href="javascript:;" (click)="onPrevious()" class="previous-document" placement="left" ngbTooltip="Previous document" *ngIf="showPrevious"><i class="mdi mdi-arrow-left-bold"></i></a>
        <a href="javascript:;" (click)="onNext()" class="next-document" placement="left" ngbTooltip="Next document" *ngIf="showNext"><i class="mdi mdi-arrow-right-bold"></i></a>
        <div class="p-3" *ngIf="loadingDocument">
            <div class="d-flex align-items-center">
                <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                <span class="ml-3">Please wait while loading Document ...</span>
            </div>
        </div>
        <div *ngIf="!loadingDocument && documentError">

            <div class="position-relative mh-300">
                <no-data title="Document"></no-data>
            </div>
        </div>
        <ng-container *ngIf="document && document.isImage">
            <img [src]="document.formedUrl" [hidden]="loadingDocument || documentError" (load)="onDocumentLoad()" (error)="onDocumentError()" [alt]="document.documentName" />
        </ng-container>
        <ng-container *ngIf="document.isVideo">
            <video style="width: 100%; height: 100%" controls (click)="toggleVideo()" #videoPlayer autoplay>
                <source [src]="document.formedUrl" (load)="onDocumentLoad()" [type]="document.contentType">
            </video>
        </ng-container>
        <ng-container *ngIf="document && !document.isImage && !document.isVideo">
            <pdf-viewer [src]="document.formedUrl['changingThisBreaksApplicationSecurity']"
                        [render-text]="true"
                        [autoresize]="true"
                        [original-size]="false"
                        [fit-to-page]="false"
                        (on-progress)="onProgress($event)"
                        (error)="onDocumentError()"
                        style="width: 100%; height: 500px">
            </pdf-viewer>
        </ng-container>
    </div>
</ng-template>

<ng-template #templateCopay>
    <Form [formGroup]="copayForm" (submit)="onCopaySubmit()">
    <div class="modal-header">
        <h4 class="modal-title">Copay Details</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="modal-body">
        <div *ngIf="finalBillLoading">
            <div class="d-flex align-items-center">
                <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                <span class="ml-2">Please wait while loading Final Bill ...</span>
            </div>
        </div>
        <div *ngIf="!finalBillLoading && finalBillBasics" class="overflow-auto">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <td class="text-right">Final Bill Amount</td>
                        <td width="200" [textContent]="(finalBillBasics.finalAmount || 0) | currency: 'INR'"></td>
                    </tr>
                    <tr>
                        <td class="text-right">Copay Amount</td>
                        <td width="200">
                            <div class="input-group discount-batch">
                                <div class="input-group-prepend">
                                    <select class="form-control"
                                            formControlName="copayDiscountType">
                                        <option selected [ngValue]="null">-</option>
                                        <option [ngValue]="discountTypeEnum.Number">
                                            ₹
                                        </option>
                                        <option [ngValue]="discountTypeEnum.Percentage">
                                            %
                                        </option>
                                    </select>
                                </div>
                                <input type="text"
                                       class="form-control"
                                       [placeholder]="(copayForm.value.copayDiscountType == discountTypeEnum.Number ? ('max ₹ ' + (finalBillBasics.finalAmount || 0)) : copayForm.value.copayDiscountType == discountTypeEnum.Percentage ? 'max 100%' : 'select discount type')"
                                       formControlName="copayDiscountAmount" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="text-right">Insurance Amount will be</td>
                        <td width="200" [textContent]="((finalBillBasics.finalAmount || 0) - 
                            (copayForm.value.copayDiscountType == discountTypeEnum.Number 
                            ? (copayForm.value.copayDiscountAmount || 0) : 
                            ((finalBillBasics.finalAmount || 0) * +('0.' + (copayForm.value.copayDiscountAmount || 0)))
                            )) | currency: 'INR'"></td>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
    <div class="modal-footer" *ngIf="!finalBillLoading">
        <div class="d-flex align-items-center justify-content-end">
            <button type="button" class="btn btn-soft-secondary" (click)="onCloseModal();">Cancel</button>
            <button type="submit" class="btn btn-primary ml-1">
                <span *ngIf="submitting">
                    <span class="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting">Submit</span>
            </button>
        </div>
    </div>
</Form>
</ng-template>