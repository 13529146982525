import { Component, ViewEncapsulation, OnInit, OnDestroy, Input } from "@angular/core";
import { IUserAccount, Page, IResource } from "@shared/models";
import { HttpService, PrintOptionService, BannerService, ResourceService, AppData } from "@shared/services";
import { ImageReports, Appointment, PayTypes, Supplier, PracticeLocation } from "@shared/entities";
import { ApiResources } from "@shared/helpers";
import { finalize, takeUntil } from "rxjs/operators";
import * as numberToWords from 'number-to-words';
import * as converter from "number-to-words";
import { FormArray } from "@angular/forms";

@Component({
    templateUrl: "./direct-po-report.html",
    styleUrls: ['./direct-po-report.widget.css'],
    selector: "direct-po-report",
    encapsulation: ViewEncapsulation.None
})
export class DirectPOReportWidget implements OnInit, OnDestroy {
    @Input() selectedSupplier: Supplier
    @Input() practice: PracticeLocation;
    @Input() termsAndConditions: string;
    @Input() totalNet: number;
    @Input() taxValue: number;
    @Input() amtInWords: string;
    @Input() isPrintLogo: boolean;
    page: Page;
    loading: boolean;
    date: Date;
    bannerBasics: ImageReports;
    myImgUrl = "assets/images/careaxesLogo.png";
    
    relationType: string;
    practiceLocations: Array<IResource>
    formattedText: string;
    practices: Array<string>
    productFormArray: FormArray;
    constructor(
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
        private readonly bannerService: BannerService,
        private readonly appData: AppData,
        private readonly resourceService: ResourceService,
    ) {
        this.page = new Page();
        this.date = new Date();
    }
    private fetchPracticeLocation() {
        this.loading = true;
        this.resourceService.fetchPracticeLocations()
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<IResource>) => {
                this.practiceLocations = response;
                this.practices = this.practiceLocations[0].optionalText2.split(/\n/g);
               
            });
    }
    
    

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchPracticeLocation();
                    console.log(this.selectedSupplier);
                    console.log(this.practice)
                } else {
                    this.page.userAccount = undefined;
                }
            });

    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
}
