/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-inferrable-types */






import { Component, ElementRef, HostListener, ViewEncapsulation, Input, OnInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';


import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ApiResources } from "@shared/helpers";
import { Appointment, PatientDocument, PreviousAppointment, PatientMedicationHeader, ImageReports, ObEncounterFullTranscript, PatientMedicationDetail, OrderModel } from "@shared/entities";
import { Page, Pagination, IResource, IUserAccount, PatientProfile, GenericResponse, GenericStatus } from "@shared/models";
import { AppConfig, AppData, HttpService, AppointmentToggleService, TimelineToggleService, IconService, PrintOptionService, ResourceService, NotifyService, } from "@shared/services";
import { finalize, takeUntil } from "rxjs/operators";
import { PE } from "@admin/physiotherapy-encounter/physiotherapy-encounter.namespace"
import { NurseAssessmentType } from "@shared/enums/nurse-assessment-forms"
import { InternalMedicine } from "@admin/internal-medicine/internal-medicine.namespace";
import { OrderPrescription } from "@shared/entities";
import { OB } from "@admin/full-transcript/pages/ob-report/ob.namespace";
import { ObEncounter } from "@shared/models/ob-encounter.model";
import { LabBillHeaderModel } from "@admin/labs/pages/models";
import { NurseAssessementResources } from '../../models/nurse-assessment-resources';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe, Time } from '@angular/common';
import { PhysiotherapyEncounterList } from '../../entities/physiotherapy-encounter.entity';
class OrderPrescriptionModel {
    appoitmentId: number;
    orderPrescription: string;
    appointmentDate: Date;
    appointmentId: string;
    appointmentTime: Time;
    providerName: string;
    encryptedAppointmentId: string;
    labRecords: Array<any>;
    medicineRecords: Array<PatientMedicationDetail>;
    labs: Array<LabBillHeaderModel>;
    medications: Array<PatientMedicationHeader>;
    orderData: Array<OrderModel>;
    medicines: Array<any>;
    labsRsponce: Array<any>;
    bookingHeader: Array<LabBillHeaderModel>;
    constructor() {
        this.orderData = new Array<OrderModel>();

    }
}

@Component({
    templateUrl: "./encounter-all-report.html",
    styleUrls: ['./encounter-all-report.css'],
    selector: "encounter-fullreport",

})


export class EncounterAllReportPage implements OnInit {
    @Input() printId: string;
    @Input() printadmission: boolean;
    routingValue: string;
    page: Page;
    modalRef: NgbModalRef;
    modalViewRef: NgbModalRef;
    loadingCategories: boolean;
    categories: Array<IResource>;
    section: string;
    pagination: Pagination;
    loading: boolean = true;
    document: PatientDocument;
    appointmentId: string;
    isAdmission: boolean;
    patientId: number;
    appointments: Array<Appointment>;
    obFullReport: ObEncounter;
    patient: PatientProfile;
    appointment: ObEncounterFullTranscript;
    obEncounter: ObEncounter;
    locationId: number;
    currentLocation: string;
    records: Array<PatientMedicationHeader>;
    iconBasics: ImageReports;
    @Input() isPrintLogo: boolean;
    labs: Array<PatientMedicationHeader>;
    bookingHeader: Array<LabBillHeaderModel>;
    husbandName: string;
    antinantalRisk: OB.AntinantalRisk;
    ordersPrescription: any = [];
    measure: OB.Measure;
    postNatalVisit: OB.PostnantalVisit;
    ancCard: any
    neonatalInitialAssesment: OB.NeonatalInitialAsessment;
    allergies: OB.Allergies;
    medicationComment: InternalMedicine.MedicationComment;
    scanAppointmentDetails: OB.ScanDetails;
    orders: Array<OrderPrescription>;
    isTeleconsulatantReport: boolean;
    date: Date;
    encryptedPatientId: string;
    admissionDetail: OB.AdmissionDetails;
    obHistory: Array<OB.ObBirthHistory>;
    isPastMedicalHistory = false;
    problemList: any = []
    isProcedureSurgery = false;
    procedureSurgery: Array<OB.ProcedureSurgery>;
    isFamilyHistory = false;
    familyHistory: Array<OB.ObFamilyHistory>;
    specialFeature: OB.SpecialFeature;
    crossConsultations: OB.ReferralForm
    attachmentsDataInfo: any = []
    OrderData: any = []
    AntenatalVisitDetails: any = []
    medicationInfo: any = []
    neonatalBaby: any = []
    obReportData: any = []
    labTests: any = []
    isAllergiesHistory: boolean = false
    previousHospitalization: any = []
    previousHospitalVisits: any = []
    nonAncVisits: any = []
    patientMedicationInfo: any = []
    telePhoneTriangleAssessment: any
    filteredInvestigation: any = []
    totalWeightGain: any = []
    weightChangeSinceLastVisit: any = []
    investigationsOrder: any = []
    appointmentNursing: Appointment;
    encryptedAppointmentId: string;
    neonatalNurseResource: any = []
    nursingPlanOfCare: any;
    @Input() encryptedPatientINTRAId: string;
    fullTranscriptObEncounter: ObEncounterFullTranscript;
    deliverydetails: OB.NormalDeliveryForm
    anaesthesia: Array<OB.Anaesthesia>;
    lscsmodel: any = [];
    generalExamination: any = {}
    obstericHistRec: any = []
    investigationsDone: any = []
    isAdimissionCount: any;
    obIntailDetails: any = [];
    departmentOfNeonatologyData: any = []
    recentAdmissionId: any;
    recentAdmissionEId: any
    appointmentEId: any;
    labReportDeatails: any = []
    dischargeSummary: any = []
    dischargeAdviced: any = []
    pregnancyPlanData: any = []
    investigationAdviced: any = []
    abnormalTestResults: any = []
    filteredMedication: any = []
    patientOrdersActionReport: any = [];
    medicines: Array<any>;
    createdDate: Date;
    labsData: Array<LabBillHeaderModel>;
    nurseNotesRecords: Array<any>;
    antenatalDates: any = []
    weeksDifference: any = [];
    anaesthesiaRecordAndLscsData: any = []
    anaesthesiaAndLSCS: any = []
    isLoadingDropdownOptions: boolean = false;
    dropdownOptions: string[] = [
        'ADMISSION FORM', 'NURSING INITIAL ASSESSMENT RECORD', 'INTRA PARTUM DETAILS',
        'Abnormal test results', 'ANTENATAL SUMMARY', 'Nutritional Review', 'Previous Hospitalizations',
        'ATTACHMENTS TO EMR', 'CURRENT ANTENATAL VISIT DETAILS', 'PREVIOUS HOSPITAL VISIT DETAILS',
        'ANTENATAL PERIOD INVESTIGATIONS', 'ANC CARD-Summary of ANC Visits', 'Non-ANC Visits',
        'TELEPHONE CONVERSATIONS RECORDED', 'Cross Consultations', 'Department of Neonatology',
        'Department of Obstetrics', 'INVESTIGATION DONE', 'CAESAREAN SECTION', 'TREATMENT PLAN FOR POST - PARTUM CARE'
    ];



    admissionReport: any = []
    obSectionsData: any = []
    medRecords: any = [];
    labDetails: any = [];
    physiotherapy: PhysiotherapyEncounterList;
    physioTherapyTemplate: any;
    searchInput: any;
    @ViewChild('textContainer', { static: false }) textContainer!: ElementRef;
    markerTicks: any[] = [];
    markerHeight = 0;
    scrollHeight = 0;
    activeIndex = 1;
    searchElements: any;
    TotalOccurances = 0;
    spanclass = 1;
    prevdownop = ""
    dropdownOP = ""
    attrInnerHTML: any;
    dietplanDetails: any = []
    dietplanInformation: any = []
    dietplanData: any = []
    physiotherapyInformation: any = []
    physiotherapyData: any = []
    physiotherapyFinal: any = []
    isFooter: boolean;
    totalMedicines: any = [];
    regionalLanguage: string;
    constructor(private readonly appData: AppData,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly httpService: HttpService,
        private readonly toggleService: AppointmentToggleService,
        public timelineService: TimelineToggleService,
        private readonly printOptionService: PrintOptionService,
        private readonly iconService: IconService,
        private datePipe: DatePipe,
        private readonly notifyService: NotifyService,
        private cdRef: ChangeDetectorRef,
    ) {

        this.page = new Page();
        this.pagination = new Pagination();
        this.appointments = [];
        this.records = new Array<PatientMedicationHeader>();
        this.labs = new Array<PatientMedicationHeader>();
        this.bookingHeader = new Array<LabBillHeaderModel>();
        this.date = new Date();
        this.nurseNotesRecords = new Array<any>();
    }

    ngOnInit() {

        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    this.currentLocation = this.page.userAccount.locationName;
                    this.regionalLanguage = this.page.userAccount.regionalLanguage;
                    this.isAdmission = url.split("/")[4] === "a" ? true : false;
                    this.encryptedAppointmentId = decodeURIComponent(url.split("/")[3]);
                    this.isTeleconsulatantReport = url.split("/")[6] === "T";
                    this.locationId = this.page.userAccount.locationId;
                    this.routingValue = url.split("/")[2];
                    this.encryptedAppointmentId = this.printId,
                        this.isAdmission = this.printadmission,
                    this.findAppointment(this.encryptedAppointmentId);
                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((params: Params) => {
                            const section = params["section"];
                            this.section = section ? section : undefined;
                        });
                } else {
                    this.page.userAccount = undefined;
                }
                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                this.iconService.getIconImage((is) => { this.iconBasics = is; });

            });

        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {

                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((params: Params) => {
                            const section = params["section"];
                            this.section = section ? section : undefined;
                        });
                } else {
                    this.page.userAccount = undefined;
                }
                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                this.iconService.getIconImage((is) => { this.iconBasics = is; });

            });
        this.loading = true
    }

    nurseInitialData() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.currentLocation = this.page.userAccount.locationName;
                    this.findNursinginitial(this.encryptedAppointmentId);

                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    obAllInfo() {
        const request = {
            AppointmentId: this.appointmentId,
            PatientId: this.patientId
        };
        this.httpService
            .post<ObEncounter>(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fullreport), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: ObEncounter) => {

                this.obFullReport = response;
                const obSections = JSON.parse(this.obFullReport[0].patientdetails)
                this.obSectionsData = obSections;

                this.obSectionsData.forEach(section => {
                    if (section.AdmissionId != null && section.CEAppointmentId == null) {
                        const stageData = {
                            admissionDetail: section.AdmissionDetails ? JSON.parse(section.AdmissionDetails) : null,
                            ancCard: section.AncCard ? JSON.parse(section.AncCard) : null,
                            measure: section.Measure ? JSON.parse(section.Measure) : null,
                            generalExamination: section.GeneralExamination ? JSON.parse(section.GeneralExamination) : null,
                            antinantalRisk: section.AntinantalRisk ? JSON.parse(section.AntinantalRisk) : null,
                            allergies: section.Allergies ? JSON.parse(section.Allergies) : null,
                            obHistory: section.BirthHistory ? JSON.parse(section.BirthHistory) : null,
                            familyHistory: section.FamilyHistory ? JSON.parse(section.FamilyHistory) : null,
                            specialFeature: section.SpecialFeature ? JSON.parse(section.SpecialFeature) : null,
                            problemList: section.ProblemList ? JSON.parse(section.ProblemList) : null,
                            procedureSurgery: section.Surgeries ? JSON.parse(section.Surgeries) : null,
                            deliverydetails: section.NormalDeliveryForm ? JSON.parse(section.NormalDeliveryForm) : null,
                            admissionId: section.AdmissionId,
                            appointmentId: section.AppointmentId
                        };
                        this.admissionReport.push(stageData);
                    }
                })
                this.admissionReport.forEach(section => {

                    this.fetchMedication(section.admissionId);
                    this.findNurseCarePlan(section.admissionId)
                    this.fetchNurseRecords(section.admissionId)
                    this.patientOrderreport(section.admissionId)
                    this.fetchLabs(section.admissionId)
                    this.findAnaesthesiaAndLSCS(section.admissionId)
                    this.fetchMedicines(section.admissionId)
                })

                this.obReportData = obSections[0]
                this.measure = obSections[0].Measure ? JSON.parse(obSections[0].Measure) : null;
                this.ancCard = obSections[0].AncCard ? JSON.parse(obSections[0].AncCard) : null;
                const eddString = this.ancCard && this.ancCard.edd ? this.ancCard.edd : null;
                if (eddString) {
                    const [day, month, year] = eddString ? eddString.split('/') : null;
                    this.ancCard.eddFormatted = `${day}.${month}.${year}`;
                }
                this.generalExamination = obSections[0].GeneralExamination ? JSON.parse(obSections[0].GeneralExamination) : null;
                this.antinantalRisk = obSections[0].AntinantalRisk ? JSON.parse(obSections[0].AntinantalRisk) : null;
                this.allergies = obSections[0].Allergies ? JSON.parse(obSections[0].Allergies) : null;
                this.obHistory = obSections[0].BirthHistory ? JSON.parse(obSections[0].BirthHistory) : null;
                this.familyHistory = obSections[0].FamilyHistory ? JSON.parse(obSections[0].FamilyHistory) : null;
                const specialFeaturesData = obSections[0].SpecialFeature ? JSON.parse(obSections[0].SpecialFeature) : null;
                this.specialFeature = specialFeaturesData && specialFeaturesData.specialFeature ?
                    specialFeaturesData.specialFeature : this.ancCard && this.ancCard.specialFeature ? this.ancCard.specialFeature : null
                this.admissionDetail = obSections[0].AdmissionDetails ? JSON.parse(obSections[0].AdmissionDetails) : null;
                if (obSections[0].FamilyHistory && this.familyHistory.length == 0) {
                    this.isFamilyHistory = true;
                }
                this.problemList = obSections[0].ProblemList ? JSON.parse(obSections[0].ProblemList) : null;

                if (obSections[0].ProblemList && this.problemList.length == 0) {
                    this.isPastMedicalHistory = true;
                }
                this.procedureSurgery = obSections[0].Surgeries ? JSON.parse(obSections[0].Surgeries) : null;
                if (obSections[0].Surgeries && this.procedureSurgery.length == 0) {
                    this.isProcedureSurgery = true;
                }
                this.deliverydetails = obSections[0].NormalDeliveryForm ? JSON.parse(obSections[0].NormalDeliveryForm) : null;

                if (this.dietplanInformation) {
                    this.dietplanInformation.forEach((dietData: any) => {
                        const sectionDiet = {
                            OpNutritionalAssessmentForm: dietData.OpNutritionalAssessmentForm ? JSON.parse(dietData.OpNutritionalAssessmentForm) : null,
                            OpNutritionScreeningForm: dietData.OpNutritionScreeningForm ? JSON.parse(dietData.OpNutritionScreeningForm) : null
                        };
                        this.dietplanDetails.push(sectionDiet);
                    });
                    if (this.dietplanDetails.length > 0) {
                        this.dietplanData = this.dietplanDetails[0];
                    }
                }

                if (this.physiotherapyData) {
                    this.physiotherapyData.forEach((physioData: any) => {
                        const sectionPhysio = {
                            PhysioTherapyTemplate: physioData.PhysioTherapyTemplate ? JSON.parse(physioData.PhysioTherapyTemplate) : null,
                        };
                        this.physiotherapyInformation.push(sectionPhysio);
                    });
                    if (this.physiotherapyInformation.length > 0) {
                        this.physiotherapyFinal = this.physiotherapyInformation[0];
                    }
                }

                this.attachmentsDataInfo = this.obFullReport[0].attachmentsdata ? JSON.parse(this.obFullReport[0].attachmentsdata) : null;
                this.labTests = this.obFullReport[0].labtests ? JSON.parse(this.obFullReport[0].labtests) : null

                this.pregnancyPlanData = this.obFullReport[0].pregnancyplan ? JSON.parse(this.obFullReport[0].pregnancyplan) : null;
                if (this.pregnancyPlanData) {
                    const tempData: any[] = [];
                    this.pregnancyPlanData.forEach((section: any) => {
                        const stageData = {
                            PregnancyPlan: section.PregnancyPlan ? JSON.parse(section.PregnancyPlan) : null
                        };
                        tempData.push(stageData);
                    });
                    this.pregnancyPlanData = tempData;
                }
                this.crossConsultations = this.obFullReport[0].crossconsultations ? JSON.parse(this.obFullReport[0].crossconsultations) : null
                this.abnormalTestResults = this.obFullReport[0].abnormaltestresults ? JSON.parse(this.obFullReport[0].abnormaltestresults) : null
                const obstetricsData = this.obFullReport[0].departmentofobstetrics ? JSON.parse(this.obFullReport[0].departmentofobstetrics) : null;
                this.obstericHistRec = obstetricsData ? obstetricsData : null
                if (this.obstericHistRec != null && this.obstericHistRec != undefined) {
                    this.obstericHistRec.forEach(section => {
                        if (section.AdmissionId != null) {
                            section.DischargeSummary = section.DischargeSummary ? JSON.parse(section.DischargeSummary) : null;
                        }
                    })
                }

                const obOrders = obstetricsData && obstetricsData[0] && obstetricsData[0].OrderPrescription ? JSON.parse(obstetricsData[0].OrderPrescription) : null;
                if (obOrders && obOrders.orderData && obOrders.orderData[0] && obOrders.orderData[0].orderPrescriptionMasterId != null) {
                    const masterId = obOrders.orderData[0].orderPrescriptionMasterId;
                    if (masterId == 12) {
                        this.dischargeAdviced = obOrders.orderData[0].comments ? obOrders.orderData[0].comments : null;
                    }
                    if (masterId == 32) {
                        this.investigationAdviced = obOrders.orderData[0].comments ? obOrders.orderData[0].comments : null;
                    }
                }
                if (obOrders != null) {
                    const orderName = obOrders.orderData[0].orderName;
                    if (orderName != null) {
                        if (orderName != null && orderName == "Prescription") {
                            this.dischargeAdviced = obOrders.orderData[0].comments;
                        }
                        if (orderName != null && orderName == "Investigations") {
                            this.investigationAdviced = obOrders.orderData[0].comments;
                        }

                    }
                }

                this.telePhoneTriangleAssessment = this.obFullReport[0].telephonetriageassessment
                    ? JSON.parse(this.obFullReport[0].telephonetriageassessment).map((item: any) => ({
                        ...item,
                        teleJsonData: JSON.parse(item.Json)
                    })) : null;

                this.previousHospitalization = JSON.parse(this.obFullReport[0].previoushospitalization);
                this.previousHospitalVisits = JSON.parse(this.obFullReport[0].previoushospitalvisitdetails);

                this.investigationsDone = this.obFullReport[0].investigationsdone ? JSON.parse(this.obFullReport[0].investigationsdone) : null;
                const nonAncDetails = this.obFullReport[0].nonancvisitsdetails ? JSON.parse(this.obFullReport[0].nonancvisitsdetails) : null;
                if (nonAncDetails != null) {

                    if (Array.isArray(nonAncDetails)) {
                        const nonAncVisitDetails = [];
                        nonAncDetails.forEach((item: any) => {
                            const orderPrescription = item.PrescriptionGiven ? JSON.parse(item.PrescriptionGiven) : null;
                            const orderData = orderPrescription && orderPrescription.orderData ? orderPrescription.orderData.map((order) => JSON.parse(JSON.stringify(order))) : null;
                            nonAncVisitDetails.push({
                                ...item,
                                PrescriptionGiven: {
                                    orderData: orderData
                                }
                            });
                        });
                        this.nonAncVisits = nonAncVisitDetails;
                    } else {
                        console.error('Error');
                        return [];
                    }
                }

                this.medicationInfo = this.obFullReport[0].patientmedicationdetail ? JSON.parse(this.obFullReport[0].patientmedicationdetail) : null
                this.investigationsOrder = this.obFullReport[0].investigationsorder ? JSON.parse(this.obFullReport[0].investigationsorder) : null
                const visitData = this.obFullReport[0]?.antenatalvisits ? JSON.parse(this.obFullReport[0].antenatalvisits) : null;

                if (visitData != null) {
                    const noOfVisits = visitData ? visitData.length : 0;
                    if (Array.isArray(visitData)) {
                        const VisitDetails = [];
                        let firstVisitWeight = 0;
                        let prevVisitWeight = 0;
                        let lastVisitWeight = 0;

                        visitData.forEach((item, index) => {
                            const parsedAncCard = item?.AncCard ? JSON.parse(item.AncCard) : null;
                            const parsedMeasure = item?.Measure ? JSON.parse(item.Measure) : null;
                            const orderPrescription = item?.OrderPrescription ? JSON.parse(item.OrderPrescription) : null;
                            const orderData = orderPrescription && orderPrescription.orderData ? orderPrescription.orderData.map((order) => JSON.parse(JSON.stringify(order))) : null;

                            if (item?.SNo === 1 && parsedMeasure?.weight !== undefined && parsedMeasure?.weight !== null) {
                                firstVisitWeight = parsedMeasure.weight;
                            }

                            if (index === noOfVisits - 2 && parsedMeasure?.weight !== undefined && parsedMeasure?.weight !== null) {
                                prevVisitWeight = parsedMeasure.weight;
                            }

                            if (index === noOfVisits - 1 && parsedMeasure?.weight !== undefined && parsedMeasure?.weight !== null) {
                                lastVisitWeight = parsedMeasure.weight;
                            }

                            VisitDetails.push({
                                ...item,
                                AncCard: parsedAncCard,
                                Measure: parsedMeasure,
                                OrderPrescription: { orderData: orderData },
                            });
                        });

                        const ancWeightGain = ((lastVisitWeight == 0 ? firstVisitWeight : lastVisitWeight) - this.ancCard?.prePregWeight).toFixed(1);
                        const weightChangeSinceLastVisit = ((lastVisitWeight == 0 ? prevVisitWeight : lastVisitWeight) - prevVisitWeight).toFixed(1);

                        this.AntenatalVisitDetails = VisitDetails;
                        this.totalWeightGain = parseFloat(ancWeightGain);
                        this.weightChangeSinceLastVisit = parseFloat(weightChangeSinceLastVisit);
                    } else {
                        console.error('Error');
                        return [];
                    }

                }

                const neonatologyOb = this.obFullReport[0].departmentofneonatology ? JSON.parse(this.obFullReport[0].departmentofneonatology) : null
                if (neonatologyOb != null) {
                    this.departmentOfNeonatologyData = neonatologyOb;
                    this.departmentOfNeonatologyData.forEach(section => {
                        if (section.AdmissionId != null) {
                            section.NeonatalBaby = section.NeonatalBaby ? JSON.parse(section.NeonatalBaby) : null;
                            section.DischargeSummary = section.DischargeSummary ? JSON.parse(section.DischargeSummary) : null;
                        }
                    })
                }
                this.loading = false
            })
    }


    getMedicationsbyAppointmentId(AppointmentId: number): any[] {
        if (this.medicationInfo) {
            this.filteredMedication = this.medicationInfo.filter(item => item.AppointmentId === AppointmentId);
        } else {
            this.filteredMedication = [];
        }
        return this.filteredMedication;
    }

    hasMedications(appointmentId: any): boolean {
        const medications = this.getMedicationsbyAppointmentId(appointmentId);
        return medications ? medications.some(medication => medication?.ProductName) : false;
    }

    getInvestigationsOrderedbyAppointmentId(AppointmentId: number): any[] {
        if (this.investigationsOrder) {
            this.filteredInvestigation = this.investigationsOrder.filter(item => item.AppointmentId === AppointmentId);
        } else {
            this.filteredInvestigation = [];
        }
        return this.filteredInvestigation;
    }

    hasInvestigations(appointmentId: any): boolean {
        const investigations = this.getInvestigationsOrderedbyAppointmentId(appointmentId);
        return investigations ? investigations.some(investigation => investigation?.TestName) : false;
    }

    formatGestationAge(gestationAge: any): any {
        if (!gestationAge) {
            return '';
        }
        const [weeks, daysFraction] = gestationAge.toString().split('.');
        const days = Math.round(parseFloat('0.' + (daysFraction || 0)) * 7);
        return `${weeks} Weeks ${days} Days`;
    }


    convertTo12Hour(time: string): string {
        const [hour, minute] = time.split(':');
        const hourNum = +hour;
        const ampm = hourNum >= 12 ? 'PM' : 'AM';
        const hour12 = hourNum % 12 || 12;
        return `${hour12}:${minute} ${ampm}`;
    }

    getAdmissionReportData(AdmissionId: number): any[] {
        const filteredReport = this.admissionReport.filter((item: any) => {
            if (item.hasOwnProperty('admissionId') && item.hasOwnProperty('appointmentId')) {
                return item.admissionId == AdmissionId && item.appointmentId == null;
            } else {
                return false;
            }
        });
        return filteredReport;
    }

    getNeonatologyData(AdmissionId: number): any[] {
        this.departmentOfNeonatologyData.filter((item: any) => item.AdmissionId == AdmissionId)
        return this.departmentOfNeonatologyData;

    }

    getObstetricData(AdmissionId: number): any[] {
        const filteredData = this.obstericHistRec.filter((item: any) => item.AdmissionId === AdmissionId);
        if (!filteredData.length) return [];
        const obstetricsData = filteredData[0];

        const obOrders = obstetricsData.OrderPrescription ? JSON.parse(obstetricsData.OrderPrescription) : null;
        this.investigationsDone = this.obFullReport[0]?.investigationsdone ? JSON.parse(this.obFullReport[0].investigationsdone) : null;

        if (obOrders && obOrders.orderData && obOrders.orderData[0]) {
            const masterId = obOrders.orderData[0].orderPrescriptionMasterId;
            const orderName = obOrders.orderData[0].orderName;
            const comments = obOrders.orderData[0].comments;

            if (masterId !== null) {
                if (masterId === 12) {
                    this.dischargeAdviced = comments || null;
                }
                if (masterId === 32) {
                    this.investigationAdviced = comments || null;
                }
            }

            if (orderName) {
                if (orderName === "Prescription") {
                    this.dischargeAdviced = comments || null;
                }
                if (orderName === "Investigations") {
                    this.investigationAdviced = comments || null;
                }
            }
        }
        return filteredData;
    }


    getAbsoluteValue(value: number): number {
        return value >= 0 ? value : -value;
    }

    getOrdinalSuffix(number: number): string {
        if (!number || isNaN(number)) return '';
        let suffix = 'th';
        if (number % 10 === 1 && number % 100 !== 11) {
            suffix = 'st';
        } else if (number % 10 === 2 && number % 100 !== 12) {
            suffix = 'nd';
        } else if (number % 10 === 3 && number % 100 !== 13) {
            suffix = 'rd';
        }
        return suffix;
    }

    getUniqueDates(): string[] {
        this.antenatalDates = new Set<string>();
        this.labReportDeatails.forEach(row => {
            Object.keys(row).forEach(key => {
                if (key !== 'PatientId' && key !== 'LabMainDetailId' && key !== 'TestName' &&
                    key !== 'ParameterName' && key !== 'MinValue' && key !== 'MaxValue' && key !== 'IsAbnormal') {
                    this.antenatalDates.add(key);
                }
            });

        });
        return Array.from(this.antenatalDates);
    }

    calculateWeeksDifference(EndDate: any) {
        const startDate = this.ancCard?.lmp ? new Date(this.ancCard?.lmp) : new Date();
        const endDate = EndDate ? new Date(EndDate) : new Date();
        const timeDifference = endDate.getTime() - startDate.getTime();
        const weekResult = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 7));
        this.weeksDifference = weekResult + " Weeks"
        return this.weeksDifference;
    }

    getDateValue(row: any, date: string): string {
        return date === 'CreatedDate' ? this.datePipe.transform(row[date], 'dd/MM/yyyy') : row[date];
    }
    groupRowsByTestName(): { [key: string]: any[] } {
        const groups = {};
        this.labReportDeatails.forEach(row => {
            const testName = row.TestName;
            groups[testName] = groups[testName] || [];
            groups[testName].push(row);
        });
        return groups;
    }

    hasData(): any {
        const groupedRows = this.groupRowsByTestName();
        return groupedRows && Object.keys(groupedRows).length > 0;
    }

    private fetchLabDetails() {
        const request = {
            AppointmentId: this.appointmentId,
            PatientId: this.patientId
        };
        this.httpService.post(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.labDetails), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: any) => {
                if (response.length > 0) {
                    this.labReportDeatails = response;
                }
            });
    }

    private findPatientDetails(id: string) {
        this.page.loading = true;
        this.httpService.post<PatientProfile>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.profile), { encryptedPatientId: id })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: PatientProfile) => {
                this.patient = response;
                var detaills = this.patient.relativeDetails[0];
                if (detaills && detaills.relation == "Husband") {
                    this.husbandName = this.patient.relativeDetails[0].fullName
                }
            });
    }

    findAnaesthesiaAndLSCS(admissionId: any) {
        const request = {
            AppointmentId: admissionId,
            PatientId: this.patientId
        };
        this.httpService
            .post<ObEncounter>(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fullreport), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: ObEncounter) => {
                this.anaesthesiaRecordAndLscsData = response
                this.anaesthesiaAndLSCS = this.anaesthesiaRecordAndLscsData[0].anaesthesiaandlscs ? JSON.parse(this.anaesthesiaRecordAndLscsData[0].anaesthesiaandlscs) : null;
                this.anaesthesia = this.anaesthesiaAndLSCS[0].AnaesthesiaRecord ? JSON.parse(this.anaesthesiaAndLSCS[0].AnaesthesiaRecord) : null;
            })
    }

    fetchCaesaren(admissionId: any): any[] {
        const lscs = this.anaesthesiaAndLSCS.filter((item: any) => item.AdmissionId == admissionId);
        this.lscsmodel = [];
        lscs.forEach(item => {
            if (item.LSCS) {
                try {
                    const parsedLSCS = JSON.parse(item.LSCS);
                    if (Array.isArray(parsedLSCS)) {
                        this.lscsmodel.push(...parsedLSCS);
                    } else {
                        this.lscsmodel.push(parsedLSCS);
                    }
                } catch (error) {
                    console.error("Error parsing LSCS JSON:", error);
                }
            }
        });

        return this.lscsmodel;
    }

    fetchOrderPrescriptionRecords(admissionId: any) {
        const lscs = this.anaesthesiaAndLSCS.filter((item: any) => item.AdmissionId == admissionId);
        this.ordersPrescription = [];
        lscs.forEach(item => {
            if (item.OrderPrescription) {
                const parsedOrderPrescription = JSON.parse(item.OrderPrescription);
                if (parsedOrderPrescription) {
                    this.ordersPrescription = parsedOrderPrescription.orderData;
                }
            }
        });

        return this.ordersPrescription;

    }

    fetchAnaesthesia(admissionId: any) {
        const lscs = this.anaesthesiaAndLSCS.filter((item: any) => item.AdmissionId == admissionId);
        this.anaesthesia = []
        lscs.forEach(item => {
            if (item.AnaesthesiaRecord) {
                const parsedAnaesthesiaRecord = JSON.parse(item.AnaesthesiaRecord);
                if (parsedAnaesthesiaRecord) {
                    this.anaesthesia = parsedAnaesthesiaRecord;
                }
            }
        });

        return this.anaesthesia;

    }

    private patientOrderreport(admissionId: any) {
        this.loading = true;
        const request = {
            EncryptedAppointmentId: '',
            EncryptedPatientId: this.encryptedPatientId,
            IsAdmission: this.isAdmission,
            AppointmentId: admissionId
        };

        this.httpService.post(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.findPrescription), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: ObEncounterFullTranscript) => {
                this.patientOrdersActionReport.push(response);
                this.patientOrdersActionReport.forEach(order => {
                    if (order && order.obEncounterId) {
                        this.medicationComment = order.medicationComment ? JSON.parse(order.medicationComment) : null;
                    }
                });

            });
    }


    fetchOrderReports(admissionId: any): any[] {
        return this.patientOrdersActionReport.filter((item: any) => item.admissionId === admissionId);
    }

    gotoPrescription(appointmentId: string) {
        this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/prescription/${appointmentId}`, "_blank"); });
    }

    fetchMedication(admissionId: any) {
        this.loading = true;

        const request = {
            EncryptedAppointmentId: "",
            IsAdmission: this.isAdmission,
            AppointmentId: admissionId
        };

        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;

            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                this.medRecords = response;
                this.records.push(...response);

                this.records.forEach(item => {
                    this.medicines = item.medicines;
                });
                this.createdDate = this.medicines ? this.medicines[0].createdDate : null;

            }, () => {
                this.records = Array<PatientMedicationHeader>();

            });
    }

    fetchMedRecords(admissionId: number): any[] {

        return this.records.filter((item: any) => item.admissionId === admissionId);

    }

    fetchMedicines(admissionId: any) {
        const request = {
            EncryptedAppointmentId: "",
            IsAdmission: this.isAdmission,
            AppointmentId: admissionId
        };

        this.loading = true;

        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetch), request)
            .pipe(
                takeUntil(this.page.unSubscribe),
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe(
                (response: Array<PatientMedicationHeader>) => {
                    this.totalMedicines = response

                },
                (error) => {
                    console.error('Error fetching medicines:', error);
                    this.totalMedicines = [];
                }
            );
    }

    fetchmedicinesRecords(admissionId: any) {
        let filteredMedicines: any = []
        let allMedicines = this.totalMedicines.filter((item: any) => item.admissionId === admissionId);
        allMedicines.forEach(item => {
            filteredMedicines.push(...item.medicines);
        });
        return filteredMedicines;
    }


    fetchNurseRecords(admissionId: any) {
        this.loading = true;
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.nurseNote.base, ApiResources.nurseNote.fetch),
                { id: admissionId })
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        var data = response.data;
                        data.forEach((item: any) => {
                            this.nurseNotesRecords.push(item)
                        })
                    } else {
                        this.notifyService.warning(response.message);
                    }
                },
                () => {
                    this.notifyService.defaultError();
                }
            );
    }

    fetchNurseNotesRecords(admissionId: any): any[] {
        return this.nurseNotesRecords.filter((item: any) => item.admissionId === admissionId);
    }


    private findNurseCarePlan(admissionId: any) {
        const request = {
            EncryptedAdmissionId: "",
            AdmissionId: admissionId,
            type: NurseAssessmentType.nurseInitialAssessmentForm,
        };
        this.httpService.post(ApiResources.getURI(ApiResources.nurseAssessmentForms.base, ApiResources.nurseAssessmentForms.find), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Array<NurseAssessementResources>) => {
                if (response.length > 0) {

                    this.neonatalNurseResource = response;

                    this.neonatalNurseResource.forEach(section => {
                        if (section.admissionId != null && typeof section.json === 'string') {
                            try {
                                section.json = section.json ? JSON.parse(section.json) : null;
                            } catch (error) {
                                console.error('Error parsing JSON:', error);
                            }
                        }
                    });
                }
            });
    }

    findNurseCarePlanRecords(admissionId: any) {
        if (!this.neonatalNurseResource || !Array.isArray(this.neonatalNurseResource)) {
            console.error('neonatalNurseNotesResource is not initialized or is not an array');
            return [];
        }
        return this.neonatalNurseResource.filter((item: NurseAssessementResources) => item.admissionId == admissionId);
    }



    private findNursinginitial(appointmentId: string) {
        const request = {
            encryptedAppointmentId: appointmentId
        };
        request["isAdmission"] = true;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointmentNursing = response;
            });
    }


    private fetchLabs(admissionid: number) {
        this.loading = true;
        const request = {
            AppointmentId: admissionid,
            IsAdmission: this.isAdmission,
        }
        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetchLabs), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                if (response && response.length > 0) {
                    if (response && response.length > 0) {

                        this.labDetails = response
                    }
                }
            }, () => {
                this.labsData = Array<LabBillHeaderModel>();
            });
    }

    fetchLabRecords(admissionId: any): any[] {
        const labDetails = this.labDetails.filter((item: any) => item.admissionId == admissionId);
        if (labDetails != null && labDetails != undefined) {
            if (labDetails.length > 0 && labDetails[0].labs) {
                this.bookingHeader = labDetails[0].labs;
            } else {
                this.bookingHeader = [];
            }
        }
        return this.bookingHeader;
    }


    private fetchPhysiotherapy(admissionId: any) {
        const request = {
            EncryptedAppointmentId: admissionId,
            IsAdmission: this.isAdmission,
        };

        this.httpService.post<PhysiotherapyEncounterList>(ApiResources.getURI(ApiResources.physiotherapyEncounter.base, ApiResources.physiotherapyEncounter.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: PhysiotherapyEncounterList) => {
                this.physiotherapy = response;
                if (this.physiotherapy != null && this.physiotherapy != undefined) {

                    this.physioTherapyTemplate = this.physiotherapy.physioTherapyTemplate ? JSON.parse(this.physiotherapy.physioTherapyTemplate) : null;

                    if (this.physioTherapyTemplate != null && this.physioTherapyTemplate != undefined) {

                        this.physioTherapyTemplate.templates = this.physioTherapyTemplate.templates.filter((item: PE.PhysioTherapyTemplate) => {
                            if (item.addDate == this.physiotherapy.appointmentDate?.toString()) {
                                return item;
                            }
                        })
                        this.physioTherapyTemplate.templates = this.physioTherapyTemplate.templates.sort(function (a: PE.PhysioTherapyTemplate, b: PE.PhysioTherapyTemplate) {
                            return Date.parse(b.addDate) - Date.parse(a.addDate);
                        });
                    }
                }

            });
    }

    private getIntialDetailes(patientId: string) {
        const request = {
            encryptedPatientId: patientId
        };
        this.httpService.post(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.intialDetails), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Array<OrderPrescriptionModel>) => {
                if (response.length > 0) {
                    this.obIntailDetails = response;
                    this.isAdmission = this.obIntailDetails[0].IsAdmission ? this.obIntailDetails[0].IsAdmission : null;
                    this.patientId = this.obIntailDetails[0].PatientId ? this.obIntailDetails[0].PatientId : null;
                    this.isAdimissionCount = this.obIntailDetails[0].IsAdmissionCount ? this.obIntailDetails[0].IsAdmissionCount : null;
                    this.recentAdmissionId = this.obIntailDetails[0].RecentAdmissionId ? this.obIntailDetails[0].RecentAdmissionId : null;
                    this.recentAdmissionEId = this.obIntailDetails[0].RecentAdmissionEId ? this.obIntailDetails[0].RecentAdmissionEId : null;
                    if (!this.isAdmission) {
                        this.appointmentId = this.obIntailDetails[0].AppointmentId ? this.obIntailDetails[0].AppointmentId : null;
                        this.appointmentEId = this.obIntailDetails[0].AppointmentEId ? this.obIntailDetails[0].AppointmentEId : null;
                        this.encryptedAppointmentId = this.obIntailDetails[0].AppointmentEId ? this.obIntailDetails[0].AppointmentEId : null;
                    } else {
                        this.appointmentId = this.obIntailDetails[0].AdmissionId ? this.obIntailDetails[0].AdmissionId : null;
                        this.appointmentEId = this.obIntailDetails[0].AdmissionEId ? this.obIntailDetails[0].AdmissionEId : null;
                        this.encryptedAppointmentId = this.obIntailDetails[0].AdmissionEId ? this.obIntailDetails[0].AdmissionEId : null;
                    }
                    if (this.isAdimissionCount > 0) {
                        this.isAdmission = true;
                        this.findPatientDetails(this.encryptedPatientId);
                    }
                    this.obAllInfo()
                    this.fetchLabDetails()
                }

            });
    }

    //Enrtry Point
    private findAppointment(appointmentId: string) {

        const request = {
            appointmentId: 0,
            encryptedAppointmentId: appointmentId,
        };
        if (this.isAdmission == null) {
            this.isAdmission = false;
        }
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<ObEncounterFullTranscript>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: ObEncounterFullTranscript) => {
                this.appointment = response;
                this.encryptedPatientId = response.encryptedPatientId;
                this.getIntialDetailes(this.encryptedPatientId);
                this.fetchPhysiotherapy(this.encryptedAppointmentId);
            });
    }

    ngAfterViewInit() {
        this.paddingTop();
        this.attrInnerHTML = this.textContainer.nativeElement.innerHTML;
    }
    paddingTop() {
        if (document.getElementById("header") != null && document.getElementById("obprintContainer") != null) {
            var paddingTopValue = document.getElementById("header").offsetHeight;
            var content = document.getElementById("obprintContainer");
            content.setAttribute('style', `padding-top:${paddingTopValue}px`);
        }
    }

    @HostListener('window:load')
    onLoad() {
        setTimeout(() => { this.paddingTop(); }, 1500)
    }
    @HostListener('window:hashchange')
    onhashchange() {
        setTimeout(() => { this.paddingTop(); }, 500)
    }
    @HostListener('window:resize')
    onResize() {
        this.paddingTop();
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }

    appointmentToggle() {
        this.toggleService.toggle(true);
    }
    timelineToggle() {
        this.timelineService.toggle();
    }
    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }

    safeINTRA = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }

    clearHighlights() {
        const highlightElements = this.textContainer.nativeElement.querySelectorAll('[class^="highlight"]');
        highlightElements.forEach(el => {
            el.replaceWith(...el.childNodes);
        });
        this.markerTicks = [];
        this.textContainer.nativeElement.scrollTop = 0;
    }

    highlightText() {
        const searchValue = this.searchInput.trim().toLowerCase();
        this.clearHighlights();
        this.TotalOccurances = 0;

        if (searchValue !== '') {
            this.findAndHighlight(searchValue);
        }
        this.getMarkerTicks();
    }

    findAndHighlight(searchText) {
        this.spanclass = 1;
        this.highlightTextInElement(this.textContainer.nativeElement, searchText);
    }

    highlightTextInElement(element, searchText) {
        if (element.nodeType === Node.TEXT_NODE) {
            const originalText = element.textContent;
            if (originalText.trim() !== '') {
                const regex = new RegExp(`(${searchText})`, 'gi');
                const newText = originalText.replace(
                    regex,
                    `<span class="highlight${this.spanclass} highlight-default">$1</span>`
                );

                if (newText !== originalText) {
                    const newNode = document.createElement('span');
                    newNode.innerHTML = newText;
                    element.parentNode.replaceChild(newNode, element);
                    this.spanclass++;
                }
            }
        } else if (element.nodeType === Node.ELEMENT_NODE) {
            element.childNodes.forEach((child) => {
                this.highlightTextInElement(child as HTMLElement, searchText);
            });
        }
    }

    getMarkerTicks() {
        setTimeout(() => {
            this.searchElements = this.textContainer.nativeElement.querySelectorAll('[class^="highlight"]');
            this.TotalOccurances = 0;
            this.markerTicks = [];
            this.searchElements.forEach((element: any) => {
                this.TotalOccurances++;
                this.markerTicks.push((this.markerHeight / this.scrollHeight) * element.getBoundingClientRect().top);
            });
            this.activeIndex = 1;
            this.scrollToActiveIndex();
            this.highlightActiveIndex();
            this.cdRef.detectChanges();
        }, 1);
    }

    moveToNext() {
        if (this.activeIndex == this.TotalOccurances) {
            this.activeIndex = 0;
        }
        this.activeIndex++;
        this.scrollToActiveIndex();
        this.highlightActiveIndex();
        this.cdRef.detectChanges();
    }

    moveToBack() {
        if (this.activeIndex == 1) {
            return;
        }
        this.activeIndex--;
        this.scrollToActiveIndex();
        this.highlightActiveIndex();
        this.cdRef.detectChanges();
    }

    scrollToActiveIndex() {
        this.textContainer.nativeElement.querySelectorAll('[class^="highlight"]')[this.activeIndex - 1].scrollIntoView({
            block: 'center',
            behavior: 'smooth',
        });
    }

    highlightActiveIndex() {
        this.searchElements.forEach((element: any) => {
            element.classList.remove('highlight-active');
            element.classList.add('highlight-default');
        });
        const spantag = this.textContainer.nativeElement.querySelectorAll('[class^="highlight"]')[this.activeIndex - 1];
        spantag.classList.remove('highlight-default');
        spantag.classList.add('highlight-active');
    }

    onHeaderSelect() {
        if (this.prevdownop !== "") {
            const elements = document.getElementById(this.prevdownop);
            elements.classList.remove('dropdown-selected');
        }
        const element = document.getElementById(this.dropdownOP);
        if (element) {
            this.prevdownop = this.dropdownOP;
            element.classList.add('dropdown-selected');
            element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }
    }

    cancelSearch() {
        this.searchInput = '';
        this.clearHighlights();
        this.TotalOccurances = 0;
        this.activeIndex = 0;
        this.cdRef.detectChanges();
    }

    onDropdownSelect(selectedItem: string) {
        this.searchInput = selectedItem;
        this.highlightText();
    }

    onChangePrintType(isShow: boolean) {
        this.isPrintLogo = isShow;
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }


}