
<div id="dietPrintContainer" class="text-black">
    <div class="mt-1">
        <div class="col-12 p-0">
            <div>
                <button class="btn btn-info" type="button" (click)="onChangePrintType(false)">
                    <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                </button>
                <button class="btn btn-info" type="button" (click)="onChangePrintType(true)">
                    <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                </button>
                <button class="btn btn-info" type="button" (click)="onChangeFooter(false)">
                    <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
                </button>
                <button class="btn btn-info" type="button" (click)="onChangeFooter(true)">
                    <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
                </button>
            </div>
            <div class="align-items-start border border-bottom-0 d-flex pl-1 pr-1 pt-1" style="background-color: #edfaf7;">
                <div class="col-11">
                </div>
                <div class="col-1 pr-5 mr-5" style="display:flex">

                    <button [useExistingCss]="true" id="printButton" printSectionId="dietprint" ngxPrint class="close d-print-none"> <i class="fe-printer" placement="left" ngbTooltip="PrintEncounter"></i></button>
                </div>

            </div>
        </div>
    </div>
    <div class="fixHeaight">
        <div id="dietprint" class="border-left border-right">
            <div class="bg-white mb-0 pb-2 pl-4 pr-4 pt-1 report">
                <div class="clearfix mb-3">

                    <div class="row" *ngIf="page.loading">
                        <div class="col-12">
                            <div class="d-flex align-items-center">
                                <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                                <span class="ml-2">Please wait while generating IEncounter Prescription ...</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!page.loading && isTeleconsulatantReport" class="d-flex justify-content-between pb-2 pl-2 pr-2 pt-0 w-100 flex-wrap">
                        <div style="width: 376px;">
                            <div class="d-flex align-items-center">
                                <div class="mr-2">
                                    <img src="assets/images/fernandezoriginal.svg" alt="logo" height="60">
                                    <h5 style="margin-top: -4px; margin-left: 56px;">Build for Birthing</h5>
                                </div>
                                <div style="color: #e3a400; line-height: 23px;" class="border-dark border-left font-20 "><div class="ml-2">TELE CARE</div></div>
                            </div>
                        </div>

                    </div>
                    <ng-container *ngIf="!page.loading && !isTeleconsulatantReport">
                        <div class="col-lg-12" *ngIf="isPrintLogo">
                            <banner-setting [reportName]='"Diet Plan Prescription Report Header"'></banner-setting>
                        </div>
                        <div *ngIf="!isPrintLogo" class="w-100 h-200px">

                        </div>
                    </ng-container>

                    <ng-container *ngIf="!page.loading && fullTranscript">
                        <div class="overflow-auto">
                            <div class="border-top2px d-flex justify-content-between pb-1 pt-1 border-bottom-dotted">
                                <h4 *ngIf="isTeleconsulatantReport" class="pl-0">Online Consultation</h4>
                                <h4 *ngIf="!isTeleconsulatantReport" class="pl-0">Prescription</h4>
                                <h4 class="white-space-nowrap">Department of Nutrition</h4>
                            </div>
                            <table class="w-100 white-space-nowrap">
                                <tr class="col-12 ageTr vertical-align-top">
                                    <td class="w-130 border-bottom-dotted">
                                        <span class="">Name</span>
                                    </td>
                                    <td class="border-bottom-dotted">
                                        <h5 class="">:</h5>
                                    </td>
                                    <td class="border-bottom-dotted text-uppercase w-200p">
                                        <h5 class="d-block  pl-1 text-truncate white-space-break min-width17vw max-width_25vw" [textContent]="fullTranscript.patientName ? fullTranscript.patientName : ''"></h5>
                                    </td>
                                    <td class="age-block text-right border-bottom-dotted">
                                        <div><div class="d-inline-block">Age</div>  : <h5 class="px-1  d-inline-block  min-width-50-px" *ngIf="fullTranscript && fullTranscript.patientAge" [textContent]="(yearsfordisplay ? yearsfordisplay + 'Y ' : '') + (monthsfordisplay ? monthsfordisplay + 'M ' : '0M ') + (daysfordisplay ? daysfordisplay + 'D' : '0D') "></h5></div>
                                    </td>
                                    <td class="w-130 border-bottom-dotted">
                                        <span class=""> UMR No.</span>
                                    </td>
                                    <td class="border-bottom-dotted">
                                        <h5 class="">:</h5>
                                    </td>
                                    <td class="w-200p text-uppercase border-bottom-dotted">
                                        <h5 class="pl-1 " [textContent]="fullTranscript.patientNo ? fullTranscript.patientNo : ''"></h5>
                                    </td>

                                </tr>

                                <tr class="col-12 vertical-align-top">
                                    <td>
                                        <span>Present Weight </span>
                                    </td>
                                    <td>
                                        <h5 class="">:</h5>
                                    </td>

                                    <td colspan="2">
                                        <h5 class="pl-1 text-uppercase " [textContent]="fullTranscript.presentWeight ? fullTranscript.presentWeight + 'Kg' : ''"> </h5>
                                    </td>
                                    <td>
                                        <span>Date</span>
                                    </td>
                                    <td>
                                        <h5 class="">:</h5>
                                    </td>

                                    <td>
                                        <h5 class="pl-1 text-uppercase " [textContent]="fullTranscript.appointmentDate ? (fullTranscript.appointmentDate | date: 'dd/MM/yyyy') : ''"></h5>
                                    </td>

                                </tr>
                                <tr class=" vertical-align-top">
                                    <td>
                                        <span> Present BMI</span>
                                    </td>
                                    <td>
                                        <h5 class="">:</h5>
                                    </td>
                                    <td colspan="2"><h5 class=" pl-1 text-uppercase" [textContent]="fullTranscript.bmi || ''"></h5> </td>
                                    <td>
                                        <span> Visit No</span>
                                    </td>
                                    <td>
                                        <h5 class="">:</h5>
                                    </td>
                                    <td>
                                        <h5 class="pl-1 text-uppercase white-space-break" [textContent]="visitNumber"></h5>
                                    </td>

                                </tr>
                                <tr class=" vertical-align-top border-bottom">
                                    <td>
                                        <span>Patient Type</span>
                                    </td>
                                    <td>
                                        <h5 class="">:</h5>
                                    </td>
                                    <td colspan="2">
                                        <h5 class="pl-1 text-uppercase " [textContent]="appointment.consultationTypeName"></h5>
                                    </td>

                                </tr>
                            </table>

                        </div>
                    </ng-container>
                    <div class="col-12 border-dark  border-top p-0">
                        <div>
                            <table class="w-100 col-12 p-0">
                                <tr class="vertical-align-top" *ngIf="dietPlanPrescribed && dietPlanPrescribed.conditionObs">
                                    <td>
                                        <span>Condition</span>
                                    </td>
                                    <td>
                                        <span>:</span>
                                    </td>
                                    <td>
                                        <span [textContent]="dietPlanPrescribed.conditionObs"></span>
                                    </td>
                                </tr>
                                <tr class="vertical-align-top" *ngIf="dietPlanPrescribed && dietPlanPrescribed.counsellingNot">
                                    <td class="white-space-nowrap">
                                        <span>Couselling Notes</span>
                                    </td>
                                    <td>
                                        <span>:</span>
                                    </td>
                                    <td>
                                        <span class="white-space-break " [innerHTML]="dietPlanPrescribed.counsellingNot"></span>
                                    </td>
                                </tr>
                                <tr class="vertical-align-top" *ngIf="dietPlanPrescribed && dietPlanPrescribed.haemoglobin">
                                    <td>
                                        <span>Haemoglobin</span>
                                    </td>
                                    <td>
                                        <span>:</span>
                                    </td>
                                    <td>
                                        <span *ngIf="dietPlanPrescribed && dietPlanPrescribed.haemoglobin" [textContent]="dietPlanPrescribed.haemoglobin"></span>
                                        &nbsp;&nbsp;
                                        <span *ngIf="dietPlanPrescribed && dietPlanPrescribed.date">Date  : {{dietPlanPrescribed.date | date:'dd/MM/yyyy'}}</span>


                                    </td>
                                </tr>
                                <tr class="vertical-align-top" *ngIf="dietPlanPrescribed && dietPlanPrescribed.hbA1C">
                                    <td>
                                        <span>HBA1C</span>
                                    </td>
                                    <td>
                                        <span>:</span>
                                    </td>
                                    <td>
                                        <span [textContent]="dietPlanPrescribed.hbA1C"></span>
                                    </td>
                                </tr>
                                <tr class="vertical-align-top" *ngIf="dietPlanPrescribed && dietPlanPrescribed.dietPlanPrescribe">
                                    <td class="white-space-nowrap">
                                        <span>Diet Prescribed</span>
                                    </td>
                                    <td>
                                        <span>:</span>
                                    </td>
                                    <td>
                                        <span class="white-space-break" [textContent]="dietPlanPrescribed.dietPlanPrescribe"></span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <ng-container *ngIf="dietItems && dietItems.length">
                        <div class="col-12 pl-0 mt-2">
                            <div>
                                <table border=" 1px solid black" class="w-100 ">
                                    <thead>
                                        <tr>
                                            <th class="pl-1"><h5>TIME</h5></th>
                                            <th class="pl-1"><h5>MEAL PLAN</h5></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let condition of dietItems;">
                                            <td class="pl-1"><span [textContent]="condition.dietShiftName"></span></td>
                                            <td class="pl-1"><span [textContent]="condition.dietItemsName"></span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-container>

                    <div class="dashboard-body border-bottom mt-2 ">
                        <table>
                            <tr *ngIf="opNutritionalAssessmentForm">
                                <td><span class="m-0" [textContent]="'Physical Activities/Day '"></span></td>
                                <td *ngIf="opNutritionalAssessmentForm.physicalActivity">
                                    <h5 class="pl-1" [textContent]="': ' +opNutritionalAssessmentForm.physicalActivity"></h5>
                                </td>

                            </tr>
                            <tr *ngIf="!opNutritionalAssessmentForm">
                                <td><span class="m-0" [textContent]="'Physical Activities/Day '"></span></td>
                                <td><h5 [textContent]="': None'"></h5></td>
                            </tr>

                            <tr>
                                <td><span class="m-0" [textContent]="'Water Per Day' "></span></td>
                                <td><h5 *ngIf="dietPlanPrescribed && dietPlanPrescribed.waterPerDay" [textContent]="dietPlanPrescribed.waterPerDay ? ':  '+ dietPlanPrescribed.waterPerDay +' ltrs': ':  --'"></h5></td>

                            </tr>

                            <tr>
                                <td><span class="m-0" [textContent]="'Oil Per Day' "></span></td>
                                <td *ngIf="dietPlanPrescribed && dietPlanPrescribed.oilPerDay"><h5 [textContent]="dietPlanPrescribed.oilPerDay ? ':  ' + dietPlanPrescribed.oilPerDay +' tsp': ':  --'"></h5></td>
                            </tr>
                            <tr>
                                <td><span class="m-0" [textContent]="'Milk Products'"></span></td>
                                <td *ngIf="dietPlanPrescribed"><h5 [textContent]="dietPlanPrescribed.milkProducts ? ':  ' + dietPlanPrescribed.milkProducts +' Slim<1%' : ':  --'"></h5></td>
                            </tr>

                            <tr>
                                <td><span class="m-0" [textContent]="'Note'"></span></td>
                                <td><h5 [textContent]="': 1 Cup = 200 ml, 1 Teaspoon(tsp) - 5 ml, 1 Table Spoon(TBS) - 15 ml'"></h5></td>

                                <!-- <td *ngIf="fullTranscript.providerName">  <span class="pl-1">:&nbsp;\______________</span></td>-->
                                <!--<td>
                                    <h5 class="pl-1" [textContent]="'\u00A0: ' + fullTranscript.providerName"></h5>

                                </td>-->
                            </tr>
                        </table>
                    </div>

                    <!--<div>
                    <div *ngIf="bookingHeader && bookingHeader.length > 0">
                        <h4 style="color: #7c0000;" class="font-17">Investigations</h4>-->
                    <!--<table class="table table-centered table-nowrap table-sm table-striped table-bordered transcipt">
                        <thead>
                            <tr>
                                <th>Test Name</th>
                                <th>Test Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <tr *ngFor="let item of bookingHeader;let i =index;">
                                <td>
                                    <span class=" text-wrap" [textContent]="item.testName"></span><br />
                                </td>
                                <td>
                                    <span class=" text-wrap" [textContent]="item.testCode"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>-->
                    <!--<table border="1px solid black" class="w-100 mt-1 mb-1" style="border-collapse: collapse;">
                                <tr>
                                    <td>
                                        <h5 class="w-200p vertical-align-top ">Test Name (&nbsp;<span class=" text-wrap">Test Code</span>&nbsp;)</h5>
                                    </td>
                                    <td>
                                        <h5>
                                            <ul class="row flex-wrap mb-0">
                                                <li class="pr-3" *ngFor="let item of bookingHeader;let i =index;">
                                                    <span class=" text-wrap" [textContent]="item.testName"></span>
                                                    (&nbsp;<span class=" text-wrap" style=" font-size: 14px; color: black;" [textContent]="item.testCode"></span>&nbsp;)
                                                </li>
                                            </ul>
                                        </h5>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>-->
                    <!--<div>
                    <div *ngIf="records && records.length > 0">
                        <div class="dashboard-body">
                            <div *ngFor="let record of records;index as j;">
                                <h4 style="color: #7c0000;" class="font-17">Medications Adviced</h4>
                                <div class="overflow-auto mt-1 mb-1">
                                    <table border="1px solid black" class="w-100" style="border-collapse: collapse;">
                                        <thead>
                                            <tr>
                                                <th><h4>Medications Adviced</h4> </th>
                                                <th><h4>Generic Name</h4></th>-->
                    <!--<th>SIG</th>
                    <th>MORNING</th>
                    <th>AFTERNOON</th>
                    <th>NIGHT</th>-->
                    <!--<th><h4>Dosage</h4></th>
                            <th><h4>Category</h4></th>
                            <th><h4>Qty</h4></th>
                            <th><h4>Remarks</h4></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <tr *ngFor="let item of record.medicines; let i = index">
                            <td class="min-width200px">
                                <h5 [textContent]="item.productName"></h5><br />
                                <h5 *ngIf="item.genericName" [textContent]="'( ' + item.genericName + ' )'"></h5>
                            </td>
                            <td class="min-width200px">
                                <h5 class=" text-wrap" style=" font-size: 14px; color: black;" *ngIf="item.genericName" [textContent]="'( ' + item.genericName + ' )'"></h5>
                            </td>-->
                    <!--<td>
                       <span class=" text-wrap" [textContent]="item.dosage +' '+ item.categoryName +' for '+item.duration+(item.durationType === 'D' ? ' Day' : (item.durationType === 'W' ? ' Week' : ' Month')) + (item.duration > 1 ? 's' : '')"></span>
                       <span class=" text-wrap" *ngIf="!item.productName" [textContent]="item.dose+item.categoryName+item.frequency"></span>
                    </td>
                    <td>
                       <span class="text-wrap" *ngIf="item.isMorning">
                          <span *ngIf="item.isMorning && item.morningDosage" [textContent]="item.morningDosage"></span>
                       </span>
                    </td>
                    <td>
                        <span class="text-wrap" *ngIf="item.isAfternoon">
                          <span *ngIf="item.isAfternoon && item.afternoonDosage" [textContent]="item.afternoonDosage"></span>
                       </span>
                    </td>
                    <td>
                      <span class="text-wrap" *ngIf="item.isNight">
                        <span *ngIf="item.isNight && item.nightDosage" [textContent]="item.nightDosage"></span>
                      </span>
                    </td>-->
                    <!--<td>
                                                        <h5>
                                                            <span style=" font-size: 14px; color: black;" *ngIf="item.instruction" [textContent]="item.instruction"></span>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <span style=" font-size: 14px; color: black;" *ngIf="item.instruction" [textContent]="item.categoryName"></span>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <span style=" font-size: 14px; color: black;" *ngIf="item.instruction" [textContent]="item.dosage"></span>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <span style=" font-size: 14px; color: black;" *ngIf="item.instruction" [textContent]="item.remark"></span>
                                                        </h5>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>-->


                    <div>
                        <div *ngIf="referralOrder && referralOrder.length > 0">
                            <strong style="color: #7c0000;" class="font-17">Referrals</strong>
                            <table class="col-4 mt-1 mb-1" style="border-collapse: collapse;">
                                <thead>
                                    <tr>
                                        <td class="w-200p vertical-align-top "><h4>Doctors</h4> </td>
                                        <td class="w-200p vertical-align-top "><h4>Comments</h4> </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <tr *ngFor="let item of referralOrder; let i = index">
                                        <td>
                                            <h5 class=" text-wrap" [textContent]="item.name"></h5><br />
                                        </td>
                                        <td>
                                            <h5 class=" text-wrap" [textContent]="item.comments"></h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!--<div *ngIf="futureAppointments && futureAppointments.length>0">
                        <div>
                            <h4 style="color: #7c0000;" class="dashboard-title font-17 m-0">Next Visit Date</h4>
                            <div *ngFor="let item of futureAppointments; index as i ">
                                <h5>Next visit with {{item.providerName}} at {{item.locationName}} on {{item.appointmentDate | date :'dd/MM/yyyy'}} at {{item.appointmentTimeString}}</h5>
                            </div>
                        </div>
                    </div><br />-->
                    <!--<div class="dashboard-body">
                    <table *ngIf="fullTranscript" class=" text-dark">
                        <tr>
                            <td><h5 class="m-0">Consultant Doctor:</h5></td>
                            <td *ngIf="fullTranscript.providerName">  <h5 class="pl-1">{{fullTranscript.providerName}}</h5></td>
                        </tr>
                        <tr>
                            <td><h5 class="m-0">Medical Registration No:</h5></td>
                            <td *ngIf="fullTranscript.providerNo">  <h5 class="pl-1">{{fullTranscript.providerNo}}</h5></td>
                        </tr>
                        <tr>
                            <td><h5 class="m-0">Email Id:</h5></td>
                            <td *ngIf="fullTranscript.email">  <h5 class="pl-1">{{fullTranscript.email}}</h5></td>
                        </tr>
                        <tr>
                            <td><h5 class="m-0">User Name:</h5></td>
                            <td *ngIf="fullTranscript.friendlyName">  <h5 class="pl-1">{{fullTranscript.friendlyName}}</h5></td>
                        </tr>
                        <tr>
                            <td><h5 class="m-0">Signature:</h5></td>
                            <td>
                                <img *ngIf="appointment.signature!=null" [src]="safe(appointment.signature)" cssClass="img-fluid rounded-circle" style="height:80px" />
                            </td>-->
                    <!-- <td *ngIf="fullTranscript.providerName">  <span class="pl-1">{{fullTranscript.providerName}}______________</span></td>-->
                    <!--</tr>
                        </table>
                    </div>-->
                    <div class="dashboard-body border-top border-dark ">
                        <div class="mb-0 d-inline-flex ">
                            <div class="pr-2"><h5>For any queries Email :</h5></div>

                            <div></div>
                        </div>

                        <div class=" d-flex flex-wrap justify-content-between p-0 pt-1">
                            <div class="d-inline-flex" *ngIf="dietPlanPrescribed && dietPlanPrescribed.reviewDate">
                                <span>Review Date :&nbsp;</span>
                                <h5 [textContent]="dietPlanPrescribed.reviewDate | date:'dd/MM/yyyy'"></h5>
                            </div>
                            <div class="d-inline-flex">
                                <span>Counselled By :&nbsp;</span>
                                <h5 [textContent]="page.userAccount.fullName"></h5>
                            </div>
                            <div class="d-inline-flex">
                                <div><span>Sign.&nbsp;</span></div>
                                <div class="border-bottom border-dark min-width100px"><span [textContent]="''"></span></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div>
                <div *ngIf="isFooter">
                    <banner-setting [reportName]='"Diet Plan Prescription Report Footer"'></banner-setting>
                </div>
            </div>
        </div>

    </div>
</div>
