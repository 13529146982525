import { Component, OnDestroy, OnInit, ViewEncapsulation, Input, AfterViewInit, HostListener } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ApiResources } from "@shared/helpers";
import { Appointment, PatientDocument, PreviousAppointment, ObEncounterFullTranscript, PatientMedicationHeader, ImageReports, ScanBooking, Setting } from "@shared/entities";
import { Page, Pagination, IResource, IUserAccount, PatientProfile } from "@shared/models";
import { AppConfig, AppData, HttpService, AppointmentToggleService, TimelineToggleService, EncounterCommunication, IconService, PrintOptionService, NotifyService } from "@shared/services";
import { finalize, takeUntil } from "rxjs/operators";

import { InternalMedicine } from "@admin/internal-medicine/internal-medicine.namespace";
import { OrderPrescription } from "@shared/entities";

import { ObEncounter } from "@shared/models/ob-encounter.model";
import { LabBillHeaderModel } from "@admin/labs/pages/models";

import { ResourceService } from "@shared/services";
import { OB } from "../../../areas/admin/full-transcript/pages/ob-report/ob.namespace";
import { PharmacyInfusionHeader } from "../../entities/pharmacyInfusion.entity";

class FilterOptions {
    encryptedAppointmentId: string = null;
}


class Filters {
    options: FilterOptions;
    applied: boolean;

    constructor() {
        this.init();
    }

    init() {
        this.options = new FilterOptions();
        this.applied = undefined;
    }
}
class OrderPrescriptionModel {
    appoitmentId: number;
    orderPrescription: string;

}

@Component({
    templateUrl: "./ob-prescription-report.html",
    selector: "ob-prescriptionreports",
    encapsulation: ViewEncapsulation.None
})
export class OBPrescriptionReportPage implements OnInit, OnDestroy, AfterViewInit {
    encryptedPatientId: string;
    encryptedAppointmentId: string;
    @Input() printId: string;
    @Input() printadmission: boolean;
    loadingVitals: boolean;
    filters: Filters;
    routingValue: string;
    page: Page;
    modalRef: NgbModalRef;
    modalViewRef: NgbModalRef;
    loadingCategories: boolean;
    categories: Array<IResource>;
    section: string;
    pagination: Pagination;
    loading: boolean;
    documents: Array<PatientDocument>;
    patientDocumentId: number;
    document: PatientDocument;
    appointmentId: string;
    isAdmission: boolean;
    providerId: number;
    patientId: number;
    appointments: Array<Appointment>;
    oldAppointment: PreviousAppointment;
    fullTranscript: ObEncounterFullTranscript;
    loadingDocument: boolean;
    documentError: boolean;
    showPrevious: boolean;
    showNext: boolean;
    result: number;
    vitalsdata: any;
    planOfmanagementMother: boolean;
    fetalplan: boolean;
    importantadvice: boolean;
    diagnosisremarks: boolean;
    otherMedication: boolean;
    nutrionalOrdersIP: boolean;
    diagnosisIP: boolean;
    maternalPlanIP: boolean;
    fetalPlanIP: boolean;
    proceduresTemplates: boolean;
    doctorwise: boolean;
    patient: PatientProfile;
    appointment: Appointment;
    obEncounter: ObEncounter;
    submitting: boolean;
    locationId: number;
    locations: Array<IResource>;
    currentLocation: string;
    signature: string;
    infusionrecords: Array<any>;
    infusions: Array<any>;
    isAppointmentClosed: boolean;
    a_Block = true;
    b_Block = true;
    c_Block = true;
    d_Block = true;
    e_Block = true;
    f_Block = true;
    g_Block = true;
    h_Block = true;
    i_Block = true;
    m_Block = true;
    j_Block = true;
    n_Block = true;
    diagnosis_Block = true;
    maternal_Block = true;
    fetal_Block = true;
    procedure_Block = true;
    o_Block = true;
    p_Block = true
    vt_block = true
    records: Array<PatientMedicationHeader>;
    iconBasics: ImageReports;
    flag = true;
    isPrintLogo: boolean;
    labs: Array<PatientMedicationHeader>;
    bookingHeader: Array<LabBillHeaderModel>;
    husbandName: string;
    referralOrder: Array<OB.OrderReferral>;
    antinantalRisk: OB.AntinantalRisk;
    ordersPrescription: OB.OrdersPrescription;
    measure: OB.Measure;
    postNatalVisit: OB.PostnantalVisit;
    ancCard: OB.AncCards;
    ancCardGeneration: OB.AncCardGeneration;
    allergies: OB.Allergies;
    medicationComment: InternalMedicine.MedicationComment;
    scanAppointmentDetails: OB.ScanDetails;
    encounterTypes: IResource[];
    selectedEncounter: any;
    orders: Array<OrderPrescription>;
    isTeleconsulatantReport: boolean;
    date: Date;
    addendumRecords: OB.AddendumForm;
    scanAppointments: Array<ScanBooking>
    futureAppointments: Array<Appointment>;
    encounterType: string;
    emailAndMobile: any;
    loadingRepotName: boolean;
    reportName: string;
    isFooter: boolean;
    scanTests: Array<any>;
    lodingLocation: boolean;
    location: IResource;
    orderdata: Array<any>;
    generalExamination: any
    isMedicationInstruction: boolean;
    constructor(
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly httpService: HttpService,
        private readonly toggleService: AppointmentToggleService,
        public timelineService: TimelineToggleService,
        private readonly printOptionService: PrintOptionService,
        private readonly iconService: IconService,
        private readonly notifyService: NotifyService,
        private readonly encounterCommunication: EncounterCommunication,
        private readonly resourceService: ResourceService,



    ) {
        this.page = new Page();
        this.pagination = new Pagination();
        this.appointments = [];
        this.records = new Array<PatientMedicationHeader>();
        this.filters = new Filters();
        this.labs = new Array<PatientMedicationHeader>();
        this.bookingHeader = new Array<LabBillHeaderModel>();
        this.date = new Date();
        this.isPrintLogo = true;
        this.infusions = new Array<any>
        this.orderdata = new Array<any>();
    }
    public onCalculateGA(value: any, lmpStatus?: any) {
        const request = {
            value: value,
            lmpStatus: lmpStatus,
            appointmentDate: this.appointment && this.appointment.appointmentDate ? this.appointment.appointmentDate : null
        }
        this.httpService.get(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.calculateGA), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: string) => {
                this.ancCard.ga = response as any;
            });

    }
    private findDashboard() {
        if (this.routingValue == 'ob-ip-encounter') {
            this.isAdmission = true

        }
        const request = { encryptedAppointmentId: this.appointmentId, encryptedProviderId: this.page.userAccount.encryptedReferenceId, encryptedPatientId: this.encryptedPatientId };
        request["isAdmission"] = this.isAdmission;

        this.httpService.post<ObEncounterFullTranscript>(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.findPrescription), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: ObEncounterFullTranscript) => {
                this.fullTranscript = response;
                if (this.fullTranscript) {
                    this.antinantalRisk = this.fullTranscript.antinantalRisk ? JSON.parse(this.fullTranscript.antinantalRisk) : null;
                    this.ordersPrescription = this.fullTranscript.orderPrescription ? JSON.parse(this.fullTranscript.orderPrescription) : null;
                    this.measure = this.fullTranscript.measure ? JSON.parse(this.fullTranscript.measure) : null;
                    this.postNatalVisit = this.fullTranscript.postnantalVisit ? JSON.parse(this.fullTranscript.postnantalVisit) : null;
                    this.ancCard = this.fullTranscript.ancCard ? JSON.parse(this.fullTranscript.ancCard) : null;
                    if (this.ancCard) {
                        if (this.ancCard.lmpStatus == "Unknown") {
                            this.onCalculateGA(this.ancCard.ultraSoundEdd, this.ancCard.lmpStatus);
                        }
                        else {
                            this.onCalculateGA(this.ancCard.lmp, this.ancCard.lmpStatus);
                        }
                    }
                    this.medicationComment = this.fullTranscript.medicationComment ? JSON.parse(this.fullTranscript.medicationComment) : null;
                    this.allergies = this.fullTranscript.allergies ? JSON.parse(this.fullTranscript.allergies) : null;
                    this.referralOrder = this.fullTranscript.refferalOrder ? JSON.parse(this.fullTranscript.refferalOrder) : null;
                    this.addendumRecords = this.fullTranscript.addendumForm ? JSON.parse(this.fullTranscript.addendumForm) : null;
                    this.generalExamination = this.fullTranscript.generalExamination ? JSON.parse(this.fullTranscript.generalExamination) : null
                    this.scanTests = this.fullTranscript.scanTests ? JSON.parse(this.fullTranscript.scanTests) : null;
                }
                if (this.printId == null) {
                    if (!this.isTeleconsulatantReport) {
                        if (response.orderPrescription != null) {
                            this.notifyService.confirm(" you want to complete the transaction for this visit", () => {
                                this.encounterCommunication.modifyEncounterStatus(this.appointment.appointmentId, this.isAdmission);
                            }, () => { $("#printButton").click(); })
                        }
                        else {
                            this.notifyService.confirm("Order details are not available for this patient visit, still you would like to continue", () => {
                                this.notifyService.confirm("Do you want to complete the encounter for this visit", () => {
                                    this.encounterCommunication.modifyEncounterStatus(this.appointment.appointmentId, this.isAdmission);
                                }, () => { $("#printButton").click(); })
                            })
                        }
                    }
                }

            });

    }


    private findPatientDetails(id: string) {
        this.page.loading = true;
        this.httpService.post<PatientProfile>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.profile), { encryptedPatientId: id })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: PatientProfile) => {
                this.patient = response;
                var detaills = this.patient.relativeDetails[0];
                if (detaills && detaills.relation == "Husband") {
                    this.husbandName = this.patient.relativeDetails[0].fullName
                }


            });
    }
    private findAncCard(id: number) {
        const request = {
            patientId: id,
            appointmentId: this.appointment.appointmentId,
            isAdmission: this.isAdmission
        }
        this.httpService.get(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchANC), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: OB.AncCardGeneration) => {
                if (response) {
                    this.ancCardGeneration = response;
                    this.husbandName = this.ancCardGeneration.husbandName;
                }
            });
    }

    private fetchMedication() {



        this.loading = true;
        const request = {
            encryptedAppointmentId: this.appointmentId,
            isAdmission: this.isAdmission
        }

        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                this.records = response;
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });

    }

    gotoPrescription(appointmentId: string) {
        this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/prescription/${appointmentId}`, "_blank"); });
    }





    private findAppointment(appointmentId: string) {
        const request = {
            encryptedAppointmentId: appointmentId,
            isAdmission: this.isAdmission
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointment = response;
                 this.encryptedPatientId = response.encryptedPatientId;
                this.findAncCard(this.appointment.patientId);
                this.fetchMedication();
                this.findDashboard();
                this.fetchLabs();
                this.findPatientDetails(response.encryptedPatientId);
                this.fetchFutureAppointments();
                this.fetchInfusions();
                this.fetchLocation();
            });

    }
    getContact() {
        const request = {
            type: 'EncounterEmailAndMobile'
        };
        this.httpService
            .get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), request, true)
            .subscribe(
                (response: Array<Setting>) => {
                    if (response && response.length > 0) {
                        this.emailAndMobile = response[0];
                    }
                }
            );
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    if (this.printId) {
                        this.appointmentId = this.printId;
                        this.isAdmission = this.printadmission;
                        this.findAppointment(this.printId);
                    }
                    else {
                        const url = this.router.url;
                        this.currentLocation = this.page.userAccount.locationName;
                        this.isAdmission = url.split("/")[4] === "a";
                        this.appointmentId = decodeURIComponent(url.split("/")[3]);
                        this.isTeleconsulatantReport = url.split("/")[6] === "T";
                        if (url.split("/")[6] === "N" || url.split("/")[6] === "T") {
                            this.encounterType = url.split("/")[url.split("/").length - 5];
                        } else {
                            this.encounterType = url.split("/")[url.split("/").length - 4];
                        }
                        this.locationId = this.page.userAccount.locationId;
                        this.filters.options.encryptedAppointmentId = decodeURIComponent(this.appointmentId);
                        this.routingValue = url.split("/")[2];
                        this.findAppointment(this.appointmentId);

                    }
                    this.fetch();
                   
                    this.fetchEncounterType();
                    this.getSettings();
                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((params: Params) => {
                            const section = params["section"];
                            this.section = section ? section : undefined;
                        });
                } else {
                    this.page.userAccount = undefined;
                }
                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                this.iconService.getIconImage((is) => { this.iconBasics = is; });
                this.getContact()
            });
    }
    onChangePrintType(isShow: boolean) {
        this.isPrintLogo = isShow;
    }

    ngAfterViewInit() {
        this.paddingTop();
    }
    paddingTop() {
        if (document.getElementById("header") != null && document.getElementById("obprintContainer") != null) {
            var paddingTopValue = document.getElementById("header").offsetHeight;
            var content = document.getElementById("obprintContainer");
            content.setAttribute('style', `padding-top:${paddingTopValue}px`);
        }
    }
    private fetchLabs() {
        this.loading = true;
        const request = {
            appointmentId: this.appointment.appointmentId,
            isAdmission: this.isAdmission
        }
        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetchLabs), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                if (response && response.length > 0) {
                    this.bookingHeader = response[0].labs;
                }
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });
    }
    fetch() {

        this.loading = true;
        this.httpService
            .post<any>(ApiResources.getURI(ApiResources.progressReportVitals.base, ApiResources.progressReportVitals.fetchemr), { encryptedAdmissionId: this.appointmentId })
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: any) => {
                    if (response) {
                        this.vitalsdata = response.data
                    }
                },
                () => {
                    this.notifyService.defaultError();
                }
            );
    }


    private fetchEncounterType() {
        this.resourceService.encounterType()
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.encounterTypes = response;
                var name = this.routingValue === 'ob-encounter' ? "OB Encounter" :
                    this.routingValue === 'gyn-encounter' || this.routingValue === 'gyn-ip-encounter' ? "Gyne Encounter" :
                        this.routingValue === 'pediatric-encounter' ? "Pediatric Encounter" :
                            this.routingValue === 'diet-plan-encounter' ? "Diet Encounter" :
                                this.routingValue === 'internal-medicine' ? "Internal Medicine" :
                                    this.routingValue === 'genetic-speciality' ? "Genetic Specialty Encounter" : this.routingValue === 'ob-ip-encounter' ? "OB IP Encounter" : this.routingValue === 'physiotherapy-encounter' ?
                                        "Physiotherapy Encounter" : this.routingValue === 'neonatal-ip-encounter' ? "NeonatalIP Encounter" : this.routingValue === 'ivf-encounter' ? "IVF Encounter" : 0;
                var selectedEncounter = this.encounterTypes.find(s => s.name == name);
                this.selectedEncounter = selectedEncounter;
                this.onFetchMasterForOper();

            });
    }
    onFetchMasterForOper() {
        let encounterTypeId;
        let providerId;

        encounterTypeId = this.selectedEncounter?.id;
        if (this.page.userAccount.roleId == 3) {
            providerId = this.page.userAccount.referenceId;
        }
        const req = { encounterTypeId: encounterTypeId, providerId: providerId };
        this.httpService.post<Array<OrderPrescription>>(ApiResources.getURI(ApiResources.orders.base, ApiResources.orders.fetchOrders), req)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: any) => {
                this.orderdata = response;

                var data = response;
                data.forEach((x) => {
                    switch (x.orderName) {
                        case "Maternal Plan":
                            this.planOfmanagementMother = true;
                            break;
                        case "Fetal Plan":
                            this.fetalplan = true;
                            break;
                        case "Procedures Templates":
                            this.proceduresTemplates = true;
                            break;
                        case "Important Advice":
                            this.importantadvice = true;
                            break;
                        case "Diagnosis Remarks":
                            this.diagnosisremarks = true;
                            break;
                        case "Other Medication":
                            this.otherMedication = true;
                            break;
                        case "Nutrional Orders IP":
                            this.nutrionalOrdersIP = true;
                            break;
                        case "Diagnosis IP":
                            this.diagnosisIP = true;
                            break;
                        case "Maternal Plan IP":
                            this.maternalPlanIP = true;
                            break;
                        case "Fetal Plan IP":
                            this.fetalPlanIP = true;
                            break;
                        case "Doctor wise":
                            this.doctorwise = true;
                            break;
                    }
                })
            });
    }

    @HostListener('window:load')
    onLoad() {
        setTimeout(() => { this.paddingTop(); }, 1500)
    }
    @HostListener('window:hashchange')
    onhashchange() {
        setTimeout(() => { this.paddingTop(); }, 500)
    }
    @HostListener('window:resize')
    onResize() {
        this.paddingTop();
    }

    onChangeStatus = (type: string, e: MouseEvent) => {
        switch (type) {
            case "a":
                this.a_Block = !this.a_Block;

                break;
            case "b":
                this.b_Block = !this.b_Block;

                break;

            case "c":
                this.c_Block = !this.c_Block;

                break;
            case "d":
                this.d_Block = !this.d_Block;
                break;
            case "e":
                this.e_Block = !this.e_Block;
                break;
            case "f":
                this.f_Block = !this.f_Block;
                break;
            case "g":

                this.g_Block = !this.g_Block;
                break;
            case "h":
                this.h_Block = !this.h_Block;
                break;
            case "i":
                this.i_Block = !this.i_Block;
                break;
            case "other_m":
                this.m_Block = !this.m_Block;
                break;
            case "j":
                this.j_Block = !this.j_Block;
                break;
            case "n":
                this.n_Block = !this.n_Block;
                break;
            case "z":
                this.diagnosis_Block = !this.diagnosis_Block;
                break;
            case "y":
                this.maternal_Block = !this.maternal_Block;
                break;
            case "x":
                this.fetal_Block = !this.fetal_Block;
                break;
            case "v":
                this.procedure_Block = !this.procedure_Block;
                break;
            case "o":
                this.o_Block = !this.o_Block;
                break;
            case "p":
                this.p_Block = !this.p_Block;
                break;
            case "VT":
                this.vt_block = !this.vt_block;
                break;
        }
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }

    appointmentToggle() {
        this.toggleService.toggle(true);
    }

    timelineToggle() {
        this.timelineService.toggle();
    }

    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }

    private fetchFutureAppointments() {
        let request = {
            encryptedPatientId: this.encryptedPatientId,
        }
        this.httpService
            .post<[Appointment, ScanBooking]>(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.findFutureAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: [Appointment, ScanBooking]) => {
                    this.futureAppointments = response["appointments"];
                    this.scanAppointments = response["scanAppointments"];
                },
                () => {
                    this.futureAppointments = [];
                    this.scanAppointments = [];
                }
            );
    }
    fetchInfusions() {
        this.loading = true;
        const request = {
            encryptedAppointmentId: this.appointmentId,
            isAdmission: this.isAdmission
        }

        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetchInfusion), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PharmacyInfusionHeader>) => {
                this.infusionrecords = response;
                this.infusionrecords.forEach((product) => {
                    product.infusions.forEach((x) => {
                        if (x != null) {
                            this.infusions.push(x);
                        }

                    })

                })

            });

    }



    private fetchLocation() {
        this.lodingLocation = true;
        this.resourceService.locations()
            .pipe(finalize(() => { this.lodingLocation = false }))
            .subscribe((response: Array<IResource>) => {
                this.locations = response;
                this.location = this.locations.find(s => s.id == this.appointment.locationId);
            });
    }

    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }


    private getSettings() {
        this.loading = true;
        this.httpService.get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: (response: Array<Setting>) => {
                    if (response && response.length > 0) {
                        var medciationINstruction = response.find(s => s.name == "OrderPrescriptionMedicationInstruction");
                        this.isMedicationInstruction = medciationINstruction.active;
                    }
                }, error: () => { }
            });
    }
}