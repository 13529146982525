
<div class="  mt-1 scroll-obsecNotHeading text-black">
    <div>
        <button class="btn btn-info" type="button" (click)="onChangePrintType( false)">
            <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
        </button>
        <button class="btn btn-info" type="button" (click)="onChangePrintType(true)">
            <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
        </button>
        <button class="btn btn-info" type="button" (click)="onChangeFooter( false)">
            <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
        </button>
        <button class="btn btn-info" type="button" (click)="onChangeFooter(true)">
            <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
        </button>
    </div>
    <div class="container image_section" style="text-align:initial;">
        <div class="card-box mb-0">
            <div class="clearfix mb-3">
                <div class="align-items-center float-right row">
                    <h4 class="m-0 d-print-none heading2">genetic Encounter Full Transcript</h4>
                    <button [useExistingCss]="true" printSectionId="geneticprint" ngxPrint class="close d-print-none"><i class="fe-printer" placement="left" ngbTooltip="Print"></i></button>
                </div>
            </div>

            <div id="geneticprint">
                <div class="row" *ngIf="page.loading">
                    <div class="col-12">
                        <div class="d-flex align-items-center">
                            <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                            <span class="ml-2">Please wait while generating IEncounter Full Transcript ...</span>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="!page.loading && (!appointment || (!appointment.appointmentNo))">
                    <div class="col-12 mh-300">
                        <no-data title=" Full Transcript for genetic Encounter"></no-data>
                    </div>
                </div>
                <ng-container *ngIf="!page.loading && appointment">
                    <div class="col-12" *ngIf="isPrintLogo">
                        <banner-setting [reportName]='"Genetic Speciality Full Transcript Header"'></banner-setting>
                    </div><hr />
                    <div class="d-flex justify-content-between heading1 white-space-nowrap">
                        <div class="col-6 p-0">
                            <div class="text-truncate">
                                <label>Patient Name:</label>
                                <span class="pl-1 text-uppercase" [textContent]="appointment.patientName"></span>
                            </div>
                            <div *ngIf="appointment.patientMobile">
                                <label>Mobile No:</label>
                                <span class="pl-1" [textContent]="appointment.patientMobile + ' / '"></span>
                                <span class="pl-1" *ngIf="appointment.patientGender" [textContent]="(appointment.patientGender | gender) + ' / '"></span>
                                <span class="pl-1" *ngIf="appointment.patientAge" [textContent]="appointment.patientAge +' yrs'"></span>
                            </div>
                        </div>
                        <div class="d-inline-block">
                            <div>
                                <label>Appointment No:</label>
                                <span class="pl-1">{{appointment.appointmentNo}}</span>
                            </div>
                            <div>
                                <label>Appointment Date:</label>
                                <span class="pl-1" [textContent]="(appointment.appointmentDate | date:'dd/MM/yyyy') + ' '+ appointment.appointmentTimeString "></span>
                            </div>
                        </div>
                    </div><hr />
                </ng-container>
                <ng-container>
                    <div class="w-100">



                        <div class="row">
                            <div class="col-12 textOfEveryVw" *ngIf="!page.loading">

                                <div class="dashboard-box dashboard-table-box" #GeneticVisitSection *ngIf="geneticVisit && geneticVisit !== null">
                                    <h4 class="dashboard-title">Genetic Visit</h4>
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="geneticVisit && geneticVisit !== null">

                                                <tr *ngIf="geneticVisit.partnersName">
                                                    <td class="col-8">Partner's Name</td>
                                                    <td class="col-4" [textContent]="geneticVisit.partnersName"> </td>
                                                </tr>
                                                <tr *ngIf="geneticVisit.age">
                                                    <td class="col-8">Age</td>
                                                    <td class="col-4" [textContent]="geneticVisit.age"> </td>
                                                </tr>
                                                <tr *ngIf="geneticVisit.ancno">
                                                    <td class="col-8">Anc No</td>
                                                    <td class="col-4" [textContent]="geneticVisit.ancno"> </td>
                                                </tr>
                                                <tr>
                                                    <h6 class="font-18 mt-0 mb-2">Genetic Visit Details</h6>

                                                </tr>
                                                <tr *ngIf="geneticVisit.counsellingperiod">
                                                    <td class="col-8">Counselling period</td>
                                                    <td class="col-4" [textContent]="geneticVisit.counsellingperiod"> </td>
                                                </tr>
                                                <tr *ngIf="geneticVisit.maternalobstetricproblems">
                                                    <td class="col-8">Maternal Obstetric problems</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="geneticVisit.maternalobstetricproblems"> </td>
                                                </tr>
                                                <tr *ngIf="geneticVisit.maternalmedicalproblems">
                                                    <td class="col-8">Maternal Medical Problems</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="geneticVisit.maternalmedicalproblems"> </td>
                                                </tr>
                                                <tr *ngIf="geneticVisit.consanguinity">
                                                    <td class="col-8">Consanguinity</td>
                                                    <td class="col-4" [textContent]="geneticVisit.consanguinity"> </td>
                                                </tr>
                                                <tr *ngIf="geneticVisit.familyhistory">
                                                    <td class="col-8">Family History</td>
                                                    <td class="col-4" [textContent]="geneticVisit.familyhistory"> </td>
                                                </tr>
                                                <tr *ngIf="geneticVisit.previouschild">
                                                    <td class="col-8">Previous Child</td>
                                                    <td class="col-4" [textContent]="geneticVisit.previouschild"> </td>
                                                </tr>
                                                <tr *ngIf="geneticVisit.developmenthistory">
                                                    <td class="col-8">Development History</td>
                                                    <td class="col-4" [textContent]="geneticVisit.developmenthistory"> </td>
                                                </tr>
                                                <tr *ngIf="geneticVisit.speech">
                                                    <td class="col-8">Speech</td>
                                                    <td class="col-4" [textContent]="geneticVisit.speech"> </td>
                                                </tr>
                                                <tr *ngIf="geneticVisit.clinicalfeatures">
                                                    <td class="col-8">Clinical Features</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="geneticVisit.clinicalfeatures"> </td>
                                                </tr>
                                                <tr *ngIf="geneticVisit.typeofGeneticDisorder">
                                                    <td class="col-8">Type Of Genetic Disorder</td>
                                                    <td class="col-4" [textContent]="geneticVisit.typeofGeneticDisorder"> </td>
                                                </tr>
                                                <tr *ngIf="geneticVisit.plan">
                                                    <td class="col-8">Plan</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="geneticVisit.plan"> </td>
                                                </tr>
                                                <tr *ngIf="geneticVisit.parentalDiagnosisDone">
                                                    <td class="col-8">Parental Diagnosis Done</td>
                                                    <td class="col-4" [textContent]="geneticVisit.parentalDiagnosisDone"> </td>
                                                </tr>
                                                <tr *ngIf="geneticVisit.summaryGiven">
                                                    <td class="col-8">Summary Given</td>
                                                    <td class="col-4" [textContent]="geneticVisit.summaryGiven"> </td>
                                                </tr>
                                                <tr *ngIf="geneticVisit.evaluationDiagnosisDone">
                                                    <td class="col-8">Evaluation Diagnosis Done</td>
                                                    <td class="col-4" [textContent]="geneticVisit.evaluationDiagnosisDone"> </td>
                                                </tr>
                                                <tr *ngIf="geneticVisit.provisionalDiagnosis">
                                                    <td class="col-8">Provisional Diagnosis</td>
                                                    <td class="col-4" [textContent]="geneticVisit.provisionalDiagnosis"> </td>
                                                </tr>
                                                <tr *ngIf="geneticVisit.geneticSummary">
                                                    <td class="col-8">Genetic Summary</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="geneticVisit.geneticSummary"> </td>
                                                </tr>
                                                <tr *ngIf="geneticVisit.summaryTypeSearch">
                                                    <td class="col-8">Summary Type Search</td>
                                                    <td class="col-4" [textContent]="geneticVisit.summaryTypeSearch"> </td>
                                                </tr>
                                                <tr *ngIf="geneticVisit.others">
                                                    <td class="col-8">Others</td>
                                                    <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="geneticVisit.others"> </td>
                                                </tr>
                                                <tr *ngIf="geneticVisit.referralDoctor">
                                                    <td class="col-8">Referral Doctor</td>
                                                    <td class="col-4" [textContent]="geneticVisit.referralDoctor"> </td>
                                                </tr>

                                            </table>
                                            <div *ngIf="genetictemplate">

                                                <h4 class="dashboard-title">Summary Type Template</h4>
                                                <div class="table table-sm table-striped table-bordered mb-0 font-13 pad-imp">
                                                    <div>
                                                        <div class="d-flex">
                                                            <div class="col-2">S.No</div>
                                                            <div class="col-4">Template Name</div>
                                                            <div class="col-6">Description</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="d-flex" *ngFor="let item of genetictemplate; let i=index;">
                                                            <div class="col-2">{{i+1}}</div>
                                                            <div class="col-4">{{item.templateName}}</div>
                                                            <div class="col-6">
                                                                <div [innerHTML]="item.description"></div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="dashboard-box dashboard-table-box" #OutsideTestSection *ngIf="outsideTest && outsideTest !== null">
                                    <h4 class="dashboard-title">Outside Tests</h4>
                                    <div class="dashboard-body" *ngFor="let items of outsideTest">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="items">
                                                <thead>
                                                    <tr>
                                                        <th>Done Date</th>
                                                        <th>Test Name</th>
                                                        <th>Other Tests</th>
                                                        <th>Other Test Results</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div class="media">
                                                                <div class="media-body">
                                                                    <h5 class="mb-0 mt-0 font-weight-normal text-truncate-line" [textContent]=" items.doneDate | date:'dd/MM/yyyy'"></h5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="items.testname" [textContent]="items.testname"></p>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="items.otherTests" [textContent]="items.otherTests"></p>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="items.otherTestResults" [textContent]="items.otherTestResults"></p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table *ngIf="items.parameters && items.parameters.length>0" class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>SN</th>
                                                        <th>Component</th>
                                                        <th>Parameter</th>
                                                        <th>Result Value</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr *ngFor="let item of items.parameters ; let i=index">
                                                        <td>
                                                            <p *ngIf="item.parameterName" [textContent]="i+1"></p>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="item.componentName" [textContent]="item.componentName"></p>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="item.parameterName" [textContent]="item.parameterName"></p>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="item.resultValues" [textContent]="item.resultValues"></p>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>



                                <div>
                                    <div class="dashboard-box dashboard-table-box" #orderPrescriptionSection *ngIf="orderPrescription && orderPrescription !== null">
                                        <h4 class="dashboard-title">Order/Prescription</h4>
                                        <div class="dashboard-body">
                                            <div class="overflow-auto">
                                                <table class="table table-centered table-sm table-striped mb-0">
                                                    <tr *ngIf="bookingHeader && bookingHeader.length > 0">
                                                        <td>Investigations</td>
                                                        <td>
                                                            <table class="table table-centered table-nowrap table-sm table-striped transcipt mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Test Name</th>
                                                                        <th>Test Code</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                    <tr *ngFor="let item of bookingHeader;let i =index;">
                                                                        <td>
                                                                            <span class=" text-wrap" [textContent]="item.testName"></span><br />
                                                                        </td>
                                                                        <td>
                                                                            <span class=" text-wrap" [textContent]="item.testCode"></span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="records && records.length !== 0">
                                                        <td>
                                                            Prescription
                                                        </td>
                                                        <td *ngFor="let record of records;">
                                                            <table class="table table-centered table-nowrap table-sm table-striped transcipt mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Medicine Name</th>
                                                                        <th>Generic Name</th>
                                                                        <th>Dosage</th>
                                                                        <th>Category</th>
                                                                        <th>Qty</th>
                                                                        <th>Remarks</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                    <tr *ngFor="let item of record.medicines; let i = index">
                                                                        <td>
                                                                            <span class=" text-wrap" [textContent]="item.productName"></span><br />
                                                                        </td>
                                                                        <td>
                                                                            <span class=" text-wrap" *ngIf="item.genericName" [textContent]="item.genericName"></span>

                                                                        </td>
                                                                        <td>
                                                                            <span class=" text-wrap">
                                                                                <span *ngIf="item.instruction" [textContent]="item.instruction"></span>
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span class=" text-wrap">
                                                                                <span *ngIf="item.instruction" [textContent]="item.categoryName"></span>
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span class=" text-wrap">
                                                                                <span *ngIf="item.instruction" [textContent]="item.dosage"></span>
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span class=" text-wrap">
                                                                                <span *ngIf="item.instruction" [textContent]="item.remark"></span>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="referralOrder">
                                                        <td>
                                                            Referrals
                                                        </td>

                                                        <td>
                                                            <table class="table table-centered table-nowrap table-sm table-striped transcipt mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Doctors</th>
                                                                        <th>Comments</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                    <tr *ngFor="let item of referralOrder; let i = index">
                                                                        <td>
                                                                            <span class=" text-wrap" [textContent]="item.name"></span><br />
                                                                        </td>
                                                                        <td>
                                                                            <span class=" text-wrap" [textContent]="item.comments"></span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="dashboard-box dashboard-table-box" *ngIf="orderPrescription">
                                    <h5 class="dashboard-title">Other Medications</h5>
                                    <div class="col-12">
                                        <span *ngFor="let item of orderPrescription.orderData">
                                            <span [innerHTML]="item.comments"></span>
                                        </span>
                                    </div>
                                </div>
                                <div class="dashboard-box dashboard-table-box" #ReminderSection *ngIf="reminder && reminder !== null">
                                    <h4 class="dashboard-title">Reminders</h4>
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="reminder && reminder !== null">

                                                <tr *ngIf="reminder">
                                                    <td class="col-8">Reminder</td>
                                                    <td class="col-4" [textContent]="reminder"> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="dashboard-box dashboard-table-box" #DemoGraphicSection *ngIf="demoGraphicData && demoGraphicData !==null">
                                        <h4 class="dashboard-title">DemoGraphicData</h4>
                                        <div class="dashboard-body">
                                            <div class="overflow-auto">
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0" *ngIf="demoGraphicData && demoGraphicData !== null">

                                                    <tr *ngIf="demoGraphicData.formType">
                                                        <td class="col-8">Form Type</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.formType"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.ipSearch">
                                                        <td class="col-8">IP Search</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.ipSearch"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.formdate">
                                                        <td class="col-8">Form Date</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.formdate |date:'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.todate">
                                                        <td class="col-8">To Date</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.todate  |date:'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.searchbycrego">
                                                        <td class="col-8">Search By Crego</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.searchbycrego"> </td>
                                                    </tr>
                                                </table>
                                                <div>
                                                    <h4 class="dashboard-title">Patient Details</h4>
                                                </div>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">

                                                    <tr *ngIf="demoGraphicData.title">
                                                        <td class="col-8">Title</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.title"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.reqType">
                                                        <td class="col-8">Req Type</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.reqType"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.name">
                                                        <td class="col-8">Name</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.name"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.dateofbirth">
                                                        <td class="col-8">Date</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.dateofbirth | date:'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.maritalstatus">
                                                        <td class="col-8">Marital Status</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.maritalstatus"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.sex">
                                                        <td class="col-8">Sex</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.sex"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.reqsex">
                                                        <td class="col-8">Req Sex</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.reqsex"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.address">
                                                        <td class="col-8">Address</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.address"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.contactno">
                                                        <td class="col-8">Contact Number</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.contactno"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.phonenumber">
                                                        <td class="col-8">Phone Number</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.phonenumber"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.religion">
                                                        <td class="col-8">Religion</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.religion"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.area">
                                                        <td class="col-8">Area</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.area"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.distric">
                                                        <td class="col-8">District</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.distric"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.state">
                                                        <td class="col-8">State</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.state"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.pincode">
                                                        <td class="col-8">Pin Code</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.pincode"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.occupation">
                                                        <td class="col-8">Occupation</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.occupation"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.email">
                                                        <td class="col-8">Email</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.email"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.education">
                                                        <td class="col-8">Education</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.education"> </td>
                                                    </tr>
                                                </table>
                                                <div>
                                                    <h4 class="dashboard-title">Relative Details</h4>
                                                </div>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">

                                                    <tr *ngIf="demoGraphicData.reqrelation">
                                                        <td class="col-8">Req Relation</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.reqrelation"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.relationtype">
                                                        <td class="col-8">Relation Types</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.relationtype"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.relativename">
                                                        <td class="col-8">Relative Name</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.relativename"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.relativedob">
                                                        <td class="col-8">Relative DOB</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.relativedob"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.relativemobile">
                                                        <td class="col-8">Relative Mobile</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.relativemobile"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.relativequalification">
                                                        <td class="col-8">Relative Qualification</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.relativequalification"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.relativeoccupation">
                                                        <td class="col-8">Relative Occupation</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.relativeoccupation"> </td>
                                                    </tr>
                                                </table>
                                                <div>
                                                    <h4 class="dashboard-title">Other Details</h4>
                                                </div>
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">

                                                    <tr *ngIf="demoGraphicData.mlcno">
                                                        <td class="col-8">Mlc No</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.mlcno"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.mlcprob">
                                                        <td class="col-8">Mlc Prob</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.mlcprob"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.reason">
                                                        <td class="col-8">Reason</td>
                                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="demoGraphicData.reason"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.ipadmissiondate">
                                                        <td class="col-8">Date</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.ipadmissiondate"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.aadharNo">
                                                        <td class="col-8">AadharNo</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.aadharNo"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.mrno">
                                                        <td class="col-8">Mr No</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.mrno"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.birthMarks1">
                                                        <td class="col-8">BirthMarks1</td>
                                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="demoGraphicData.birthMarks1"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.birthMarks2">
                                                        <td class="col-8">BirthMarks2</td>
                                                        <td class="col-4" style=" white-space: normal; word-break: break-all;" [textContent]="demoGraphicData.birthMarks2"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.subvisitNo">
                                                        <td class="col-8">SubVisit NO</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.subvisitNo"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.missueno">
                                                        <td class="col-8">Missue No</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.missueno"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.reqno">
                                                        <td class="col-8">Req No</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.reqno"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.consultantdoctor">
                                                        <td class="col-8">Consultant Doctor</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.consultantdoctor"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.diagnosis">
                                                        <td class="col-8">Diagnosis</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.diagnosis"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.source">
                                                        <td class="col-8">Source</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.source"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.remarkes">
                                                        <td class="col-8">Remarkes</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.remarkes"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.employeenumber">
                                                        <td class="col-8">Employee Number</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.employeenumber"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.ipadmissionarea">
                                                        <td class="col-8">Area</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.ipadmissionarea"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.type">
                                                        <td class="col-8">Type</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.type"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.billno">
                                                        <td class="col-8">Bill No</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.billno"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.patientType">
                                                        <td class="col-8">PatType</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.patientType"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.reciptno">
                                                        <td class="col-8">Recipt No</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.reciptno"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.ipno">
                                                        <td class="col-8">Ip No</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.ipno"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.timeNew">
                                                        <td class="col-8">Timenew</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.timeNew"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.ipadmissionconsultantdoctor">
                                                        <td class="col-8">Consultant Doctor</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.ipadmissionconsultantdoctor"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.invDate">
                                                        <td class="col-8">Inv Date</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.invDate"> </td>
                                                    </tr>
                                                    <tr *ngIf="demoGraphicData.invno">
                                                        <td class="col-8">Inv No</td>
                                                        <td class="col-4" [textContent]="demoGraphicData.invno"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="dashboard-box dashboard-table-box" #GenecticSummarySection *ngIf="geneticSummary && geneticSummary !== null">
                                    <h4 class="dashboard-title">Genetic Summary</h4>
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="w-100 m-0 p-0 table table-striped mb-0" *ngIf="geneticSummary && geneticSummary !== null">
                                                <tr>
                                                    <td class="white-space-nowrap">Partner Name </td>
                                                    <td class="white-space-nowrap" [textContent]="geneticSummary.partnersName"> </td>
                                                </tr>
                                                <tr>
                                                    <td class="">Age</td>
                                                    <td class="" [textContent]="geneticSummary.age"> </td>
                                                </tr>
                                                <tr>
                                                    <td class="white-space-nowrap">Referral Doctor</td>
                                                    <td class="white-space-nowrap" [textContent]="geneticSummary.referralDoctor"> </td>
                                                </tr>
                                            </table>
                                            <div *ngIf="geneticSummary.geneticSummary">
                                                <h4 class="dashboard-title">GeneticSummary</h4>
                                                <span class="col-12" [innerHTML]="geneticSummary.geneticSummary"> </span>
                                            </div>
                                            <div *ngIf="geneticSummarytemplate">

                                                <h4 class="dashboard-title">Summary Type Template</h4>
                                                <div class="table table-sm table-striped table-bordered mb-0 font-13 pad-imp">
                                                    <div>
                                                        <div class="d-flex">
                                                            <div class="col-2">S.No</div>
                                                            <div class="col-4">Template Name</div>
                                                            <div class="col-6">Description</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="d-flex" *ngFor="let item of geneticSummarytemplate; let i=index;">
                                                            <div class="col-2">{{i+1}}</div>
                                                            <div class="col-4">{{item.templateName}}</div>
                                                            <div class="col-6">
                                                                <div [innerHTML]="item.description"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div class="dashboard-box dashboard-table-box" #specialFeatureSection *ngIf="specialFeature && specialFeature !== null && specialFeature.specialFeature">
                                    <h4 class="dashboard-title">Special Features</h4>
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <tr *ngIf="specialFeature.specialFeature">
                                                    <td class="col-8">Special Features</td>
                                                    <td class="col-4">
                                                        <span class="wordBreak" [textContent]="specialFeature.specialFeature"></span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>



                                <div #telephoneTriageAssessmentSection *ngIf="telephoneTriageAssessmentdata && telephoneTriageAssessmentdata.length>0">
                                    <div class="dashboard-box dashboard-table-box" *ngFor="let telephoneTriageAssessments of telephoneTriageAssessmentdata">
                                        <h4 class="dashboard-title">TelePhone Triage Assessment </h4>
                                        <div class="dashboard-body">
                                            <div class="overflow-auto" *ngIf="telephoneTriageAssessments && telephoneTriageAssessments !== null && (telephoneTriageAssessments.callDate || telephoneTriageAssessments.callTime || telephoneTriageAssessments.typeofConsultation || telephoneTriageAssessments.reasonForCall || telephoneTriageAssessments.reasonothers
                          || telephoneTriageAssessments.adviceother || telephoneTriageAssessments.adviceGiventoPatient || telephoneTriageAssessments.actionTakenIfAdvisedToVisit || telephoneTriageAssessments.actionother || telephoneTriageAssessments.doctorName || telephoneTriageAssessments.callCompletedDate || telephoneTriageAssessments.callcompletedTime)">
                                                <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                    <tr>
                                                        <td class="col-8">Call Date</td>
                                                        <td class="col-4" [textContent]="telephoneTriageAssessments.callDate | date:'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="col-8">Call Time</td>
                                                        <td class="col-4" [textContent]="telephoneTriageAssessments.callTime"> </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="col-8">Type of Consultation</td>
                                                        <td class="col-4" [textContent]="telephoneTriageAssessments.typeofConsultation"> </td>
                                                    </tr>
                                                    <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.reasonForCall?.length > 0">
                                                        <td class="col-4">Reason For Call</td>
                                                        <td class="col-8" *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.reasonForCall">
                                                            <ul class="dashboard-ul row">
                                                                <li *ngFor="let item of  telephoneTriageAssessments.reasonForCall" [textContent]="item"></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="col-8">Other</td>
                                                        <td class="col-4" [textContent]="telephoneTriageAssessments.reasonothers"> </td>
                                                    </tr>
                                                    <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.adviceGiventoPatient?.length > 0">
                                                        <td class="col-4">Advice Given To Patient</td>
                                                        <td class="col-8">
                                                            <ul class="dashboard-ul row">
                                                                <li *ngFor="let item of  telephoneTriageAssessments.adviceGiventoPatient" [textContent]="item"></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="col-8"> Others</td>
                                                        <td class="col-4" [textContent]="telephoneTriageAssessments.adviceother"> </td>
                                                    </tr>
                                                    <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.actionTakenIfAdvisedToVisit?.length > 0">
                                                        <td class="col-4">Action Taken If Advised To Visit</td>
                                                        <td class="col-8">
                                                            <ul class="dashboard-ul row">
                                                                <li *ngFor="let item of  telephoneTriageAssessments.actionTakenIfAdvisedToVisit" [textContent]="item"></li>

                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="col-8">Others</td>
                                                        <td class="col-4" [textContent]="telephoneTriageAssessments.actionother"> </td>
                                                    </tr>
                                                    <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.doctorName">
                                                        <td class="col-8">Doctor Name</td>
                                                        <td class="col-4 text-uppercase" [textContent]="telephoneTriageAssessments.doctorName"> </td>
                                                    </tr>
                                                    <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.callCompletedDate">
                                                        <td class="col-8">Call Completed Date</td>
                                                        <td class="col-4" [textContent]="telephoneTriageAssessments.callCompletedDate | date:'dd/MM/yyyy'"> </td>
                                                    </tr>
                                                    <tr *ngIf="telephoneTriageAssessments && telephoneTriageAssessments.callcompletedTime">
                                                        <td class="col-8">Call Completed Time</td>
                                                        <td class="col-4" [textContent]="telephoneTriageAssessments.callcompletedTime"> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="dashboard-box dashboard-table-box" #referralFormSection *ngIf="referralForm && referralForm.length>0 ">
                                    <h4 class="dashboard-title">Referral Form</h4>
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>Referral To Doctor</th>
                                                        <th>Reason For Referring</th>
                                                        <th>Req Date</th>
                                                        <th>Time</th>
                                                        <th>Consultant Doctor</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of referralForm">
                                                        <td><span [textContent]="item.referralDoctor"></span></td>
                                                        <td><span [textContent]="item.reason"></span></td>
                                                        <td><span [textContent]="item.requiredDate | date: 'dd/MM/yyyy'"></span></td>
                                                        <td><span [textContent]="item.time"></span></td>
                                                        <td><span [textContent]="item.consultantDoctor"></span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                                <div class="dashboard-box dashboard-table-box" *ngIf="reminders && reminders !== null">
                                    <h4 class="dashboard-title">Reminders</h4>
                                    <div class="dashboard-body">
                                        <div class="overflow-auto">
                                            <div class="row col-12" *ngIf="reminders && reminders !== null">
                                                <div class="row col-12">
                                                    <div *ngIf="reminders && reminders==null">
                                                        There is no reminders
                                                    </div>
                                                </div>
                                                <div class="col-12" *ngIf="reminders && reminders !== null">
                                                    <div class="table-responsive horizontal-scroll">
                                                        <table class="table table-sm mb-0">
                                                            <tr *ngIf="reminders">
                                                                <td class="col-8">Reminders</td>
                                                                <td class="col-4">
                                                                    <span class=" text-wrap" [textContent]="reminders"></span><br />
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="dashboard-box dashboard-table-box" *ngIf="outSideTests && outSideTests !== null" #outsideTestsSection>
                                    <h4 class="dashboard-title">Outside Tests</h4>
                                    <div class="dashboard-body" *ngFor="let items of outSideTests">
                                        <div class="overflow-auto">
                                            <table class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Done Date</th>
                                                        <th>Test Name</th>
                                                        <th>Other Tests</th>
                                                        <th>Other Test Results</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div class="media">
                                                                <div class="media-body">
                                                                    <h5 class="mb-0 mt-0 font-weight-normal text-truncate-line" [textContent]=" items.doneDate | date:'dd/MM/yyyy'"></h5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="items.testname" [textContent]="items.testname"></p>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="items.otherTests" [textContent]="items.otherTests "></p>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="items.otherTestResults" [textContent]="items.otherTestResults "></p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table *ngIf="items.parameters && items.parameters.length>0" class="table table-centered table-nowrap table-sm table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>SN</th>
                                                        <th>Component</th>
                                                        <th>Parameter</th>
                                                        <th>Result Value</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr *ngFor="let item of items.parameters; let i=index">
                                                        <td>
                                                            <p *ngIf="item.parameterName" [textContent]="i+1"></p>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="item.componentName" [textContent]="item.componentName"></p>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="item.parameterName" [textContent]="item.parameterName"></p>
                                                        </td>
                                                        <td>
                                                            <p *ngIf="item.resultValues" [textContent]="item.resultValues"></p>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="col-12" *ngIf="isFooter">
                    <banner-setting [reportName]='"Genetic Speciality Full Transcript Footer"'></banner-setting>
                </div>
            </div>

        </div>
    </div>
</div>