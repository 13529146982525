export class AdmissionInformationModel {
    admissionId: number;
    bedNumber?: number;
    patientName?: string;
    caseTypeName?: number;
    attendantName: number;
    ProviderName: number;
    mobile: string;
    umrNo: string;
    admissionPayTypeName: string;
    admissionDate: Date;
    AdmissionNo?: number;
    payType: string;
    AdmissionTime?: Date;
    Counselling?: boolean;
    locationId: number;
    insuranceCompanyName: string;
    locationName: string;
    unitName: string;
    taggedDoctors: string;
    chargeCategoryName: string;
    totalItems: number;
    total: number;
    paidAmount: number;
    pendingAmount: number;
    refundAmount: number;
    patientId: number;
    repeatTypeId: number;
    admissionTimeInveralHour: string;
    rowColor: string;
    serviceOrderTotal: number;
    labServicesTotal: number;
    doctorVisitTotal: number;
    scanServiceTotal: number;
    surgeryServiceTotal: number;
    roomVisitTotal: number;
    surgeonChargeServicesTotal: number;
    packageServiceTotal: number;
    pharmacyTotal: number;
    admissionNo: string;
    isAutoServices: boolean;
    isBilledAmountIsGreaterThanAdvance: boolean;
}