<div class="modal-body" *ngIf="loading">
    <div class="d-flex align-items-center">
        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
        <span class="ml-2">Please wait while loading bill details....</span>
    </div>
</div>
<div class="modal-body" *ngIf="!loading && bill.length === 0">
    <div class="d-flex justify-content-center">
        <div class="no-data-container">
            <img class="mt-1" width="350" src="assets/images/no-data-2.png" alt="No Data" />
            <h4 class="mt-1">No Bill Data found.Nothing to show you.</h4>
        </div>
    </div>
</div>
<div Id="invoiceId" *ngIf="!loading && bill.length > 0">
    <div class="report bg-white" *ngFor="let bill of bill;index as i;">
        <div class="overflow-auto">
            <table width="100%">
                <tr *ngIf="isPrintLogo">
                    <td colspan="4" align="center">
                        <banner-setting [reportName]='"Lab Invoice Header"'></banner-setting>
                    </td>
                </tr>
                <tr *ngIf="!isPrintLogo">
                    <td colspan="2" class="height100"></td>
                </tr>
                <tr>
                    <td colspan="4">
                        <h3 class="text-center">Final Bill of Supply - Diagnostics Bill</h3>
                        <h5 class="text-center">(Health Care Service - SAC)</h5>
                        <hr class="border-dark">
                    </td>

                </tr>

                <tr class="vertical-align-top">
                    <th>
                        <h5 *ngIf="bill.patientName">Patient Name</h5>
                        <h5 *ngIf="bill.employeeName">Employee Name</h5>
                    </th>
                    <td class="d-flex">
                        <span class="mr5px">: </span>
                        <span class="text-uppercase d-block" *ngIf="bill.patientName" [textContent]="bill.patientName"></span>
                        <span class="text-uppercase d-block" *ngIf="bill.employeeName" [textContent]="bill.employeeName"></span>
                    </td>
                    <th>
                        UMR No
                    </th>
                    <td>
                        <span class="mr5px">: </span>
                        <span [textContent]="bill.umrNo || '-----'"></span>
                    </td>
                </tr>
                <tr class="vertical-align-top">
                    <th>
                        {{relationType ? relationType : 'Relative Name'}}
                    </th>
                    <td class="d-flex">
                        <span class="mr5px">: </span>
                        <span class="d-block text-uppercase" [textContent]="bill.relativeName || '------'"></span>
                    </td>
                    <th>
                        <h5>Bill Date</h5>
                    </th>
                    <td>
                        <span class="mr5px">: </span>
                        <span [textContent]="bill.createdDate | date:'dd/MM/yyyy, h:mm a'"></span>
                    </td>
                </tr>
                <tr class="vertical-align-top">
                    <th>
                        <h5>Bill No</h5>
                    </th>
                    <td class="d-flex">
                        <span class="mr5px">: </span>
                        <span [textContent]="bill.billNumber || '----'"></span>
                    </td>
                    <th>
                        Age/Gender
                    </th>
                    <td>
                        <span class="mr5px">: </span>
                        <span [textContent]="bill.patientAge ? bill.patientAge: ( bill.age +' Years'  || '----')"></span>
                        <span *ngIf="bill.gender"> / <span [textContent]="bill.gender | gender"></span></span>
                    </td>
                </tr>
                <tr class="vertical-align-top">
                    <th>
                        Doctor Name
                    </th>
                    <td class="d-flex">
                        <span class="mr5px">: </span>
                        <span class="d-block text-uppercase" [textContent]="bill.doctorName || '------'"></span>
                    </td>
                    <th>
                        Requisition No
                    </th>
                    <td>
                        <span class="mr5px">: </span>
                        <span [textContent]="bill.requisitionNumber"></span>
                    </td>
                </tr>
                <tr class="vertical-align-top">
                    <th>
                        <h5>Mobile Number</h5>
                    </th>
                    <td>
                        <span class="mr5px">: </span>
                        <span [textContent]="bill.mobile || '----'"></span>
                    </td>
                </tr>
            </table>
        </div>
        <hr class="border-dark">
        <div class="d-flex mt-20px" >
            <div class="col-1"> <h5>S.No</h5> </div>
            <div class="col-2"> <h5>Dept Name</h5></div>
            <div class="col-5"> <h5>Investigations</h5></div>
            <div class="col-2"> <h5>Emergency</h5></div>
            <div class="col-2"> <h5>Amount (Rs)</h5></div>
        </div>
        <hr class="border-dark mt-1px">
        <div class="mt-25px">
            <div class="d-flex marginButton10px" *ngFor="let lab of bill.labs;index as j;">
                <div class="col-1" [textContent]="j+1"></div>
                <div class="col-2 break-all" [textContent]="lab.departmentName || '--'"></div>
                <div class="col-5 break-all" [textContent]="lab.testName"></div>
                <div class="col-2" [textContent]="lab.emergency ? 'Yes':'No'"></div>
                <div class="col-2 break-all" [textContent]="lab.totalAmount || 0 | currency :'INR'"></div>
            </div>
        </div>
        <hr class="border-dark mt-2px">
        <div class="mt-20px">
            <div class="d-flex ml-25px">
                <div class="col-7 d-flex">
                    <div class="col-3">
                        <h5>Rupees</h5> 
                    </div>
                    <span>: </span>
                    <div class="col-9">
                       {{paidAmountInWords}} rupees only
                    </div>
                </div>
                <div class="col-5 d-flex">
                    <div class="col-8">
                        <h5>Total Amount Rs</h5>  
                    </div>
                    <span>: </span>
                    <div class="col-4">
                        {{bill.overallTotalAmount || 0 | currency:'INR' }}
                    </div>
                </div>
            </div>
            <div class="d-flex ml-25px">
                <div class="col-7 d-flex">
                    <div class="col-3">
                        <h5>Pay Mode</h5> 
                    </div>
                    <span>: </span>
                    <div class="col-9">
                        {{bill.payTypes[0]?.payTypeName}}
                    </div>
                </div>
            </div>
            <div class="d-flex ml-25px">
                <div class="col-7 d-flex">
                    <div class="col-3">
                        <h5>Diagnosis</h5>
                    </div>
                    <span>: </span>
                    <div class="col-9">
                        LAB
                    </div>
                </div>  
                <div class="col-5 d-flex">
                    <div class="col-8">
                        <h5>Paid Amount Rs</h5>
                    </div>
                    <span>: </span>
                    <div class="col-4">
                        {{bill.paidAmount || 0 | currency:'INR' }}
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="col-2">
                    <div class="flex-grow-1 text-left">
                        <qrcode [qrdata]="QrCode" [width]="100" [errorCorrectionLevel]="'M'" [elementType]="'img'"></qrcode>
                    </div>
                </div>
                <div class="col-10">
                    <div>
                        <span>
                            Note: This is a computer-generated document. No signature is required.
                        </span>
                    </div>
                    <div class="d-flex">
                        <span>
                            <b>
                                You can check your reports(Only Lab) online at <a href="https://www.fernandezhospital.com/">www.fernandezhospital.com</a> and click the Lab reports tab.
                            </b>
                        </span>
                    </div>
                    <div class="d-flex col-10">
                        <div class="col-6">
                            User Name: <b>3552604</b>
                        </div>
                        <div class="col-6">
                            Password: <b>YER3578935</b>
                        </div>
                    </div>
                    <div>
                        <span><b>NOW OPEN! Fernandez's new Outpatient Clinic at Necklace Road, Sec’bad. Call 18004191397 for Appointments</b></span>
                    </div>
                    <div>
                        <span>
                            <b>Please scan the QR code to leave a feedback about our services.</b>
                        </span>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="col-8 d-flex">
                    <div class="text-left">
                        Printed By : {{page.userAccount?.fullName}}
                    </div>
                </div>
                <div class="col-4 d-flex">
                    <div class="text-right">
                        Printed Date : {{currentDate | date:'dd/MM/yyyy, h:mm a'}}
                    </div>
                </div>
            </div>
        </div>
        <hr class="border-dark mt-2px">
    </div>
    <div *ngIf="isFooter">
        <banner-setting [reportName]='"Lab Invoice Footer"'></banner-setting>
    </div>
</div>