export class OrderPrescription {
    orderPrescriptionMasterId: number;
    valueId: number;
    valueName: string;
    comments: string;
    active: boolean;
    createdBy: number;
    createdDate: Date;
    totalItems: number;
    fullName: string;
    modifiedDate: Date; 
    createdByName: string;
    modifiedByName: string;
    createdByRole: string;
    modifiedByRole: string;
    orderName: string;
    orderData: Array<OrderModel>;
    name: string;
    encounterName: string;
    providerName: string;
    encounterTypeId: number;
    providerId: number;
 
   



    constructor() {
        this.orderData = new Array<OrderModel>();
    }
}
export class OrderModel {
    comments: string;
    orderName: string;
    orderPrescriptionMasterId: number;
}


