
import { Component, OnDestroy, OnInit, ViewEncapsulation, Input } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ApiResources } from "@shared/helpers";
import { Appointment, PreviousAppointment, ObEncounterFullTranscript, PatientMedicationHeader, ImageReports, Setting } from "@shared/entities";
import { Page, Pagination, IResource, PatientProfile, ICategoryMenuModel, IUserAccount } from "@shared/models";
import { AppConfig, HttpService, AppointmentToggleService, TimelineToggleService, IconService, PrintOptionService, appUrls, MenuService, AppData } from "@shared/services";
import { finalize, takeUntil } from "rxjs/operators";
import { ObEncounter } from "@shared/models/ob-encounter.model";
import { InternalMedicine } from "@admin/internal-medicine/internal-medicine.namespace";

import { LabBillHeaderModel } from "@admin/labs/pages/models";
import { forkJoin } from "rxjs";

import { MenuType } from "@shared/enums";
import { OB } from "../../../areas/admin/full-transcript/pages/ob-report/ob.namespace";
import { PharmacyInfusionHeader } from "../../entities/pharmacyInfusion.entity";
class FilterOptions {
    encryptedAppointmentId: string = null;
}

class Filters {
    options: FilterOptions;
    applied: boolean;

    constructor() {
        this.init();
    }

    init() {
        this.options = new FilterOptions();
        this.applied = undefined;
    }
}

@Component({
    templateUrl: "./ob-full-report.html",
    selector: "ob-fullreport",

    encapsulation: ViewEncapsulation.None
})
export class ObFullReportPage implements OnInit, OnDestroy {
    @Input() encryptedPatientId: string;
    @Input() encryptedAppointmentId: string;


    loadingVitals: boolean;
    filters: Filters;
    routingValue: string;

    @Input() printId: string;
    @Input() printadmission: boolean;

    page: Page;
    modalRef: NgbModalRef;
    modalViewRef: NgbModalRef;
    loadingCategories: boolean;
    categories: Array<IResource>;
    section: string;
    pagination: Pagination;
    loading: boolean;
    appointmentId: string;
    isAdmission: boolean;
    infusionrecords: Array<any>;
    infusions: Array<any>;
    vitals: InternalMedicine.Vitals;
    problemList: Array<InternalMedicine.Problem>;
    ros: InternalMedicine.Ros;
    geneticScreening: OB.GeneticScreening;
    infectionHistory: OB.InfectionHistory;
    providerId: number;
    patientId: number;
    appointments: Array<Appointment>;
    oldAppointment: PreviousAppointment;
    fullTranscript: ObEncounterFullTranscript;
    isFamilyHistory = false;
    isProcedureSurgery = false;
    isPastMedicalHistory = false;
    referralForm: Array<OB.ReferralForm>;
    referralOrder: Array<OB.OrderReferral>;
    roleId: number;

    admissionSlip: OB.AdmssionSlip;
    syndromeDetails: OB.Syndromes;
    opManagement: OB.OPManagement;
    motherEpilepsy: OB.MotherWithEpilepsy;
    externalCephalic: OB.ExternalCephalic;
    dischargeSummary: OB.DischargeSummary;
    labRecords: Array<PatientMedicationHeader>;
    nurseAssessment: OB.NursingAssessment;
    bookingHeader: Array<LabBillHeaderModel>;

    loadingDocument: boolean;
    documentError: boolean;
    showPrevious: boolean;
    showNext: boolean;
    rosArray: Array<string>;
    noRosArray: Array<string>;
    constitutionalValue: Array<string>;
    noConstitutionalValue: Array<string>;
    ent: Array<string>;
    noEnt: Array<string>;
    rosSkin: Array<string>;
    noRosSkin: Array<string>;
    all: Array<string>;
    noAll: Array<string>;
    rosHeent: Array<string>;
    noRosHeent: Array<string>;
    eyes: Array<string>;
    noEyes: Array<string>;
    rosRespiratory: Array<string>;
    noRosRespiratory: Array<string>;
    cv: Array<string>;
    noCv: Array<string>;
    gi: Array<string>;
    noGi: Array<string>;
    integumentary: Array<string>;
    noIntegumentary: Array<string>;
    gu: Array<string>;
    noGu: Array<string>;
    endocrine: Array<string>;
    noEndocrine: Array<string>;
    musculoSkeletal: Array<string>;
    noMusculoSkeletal: Array<string>;
    neuro: Array<string>;
    noNeuro: Array<string>;
    psych: Array<string>;
    noPsych: Array<string>;
    heme: Array<string>;
    noHeme: Array<string>;
    gyn: Array<string>;
    noGyn: Array<string>;
    medications: Array<InternalMedicine.Medications>;
    medicationComment: InternalMedicine.MedicationComment;
    complaints: OB.Complaints;
    obstetricExamination: OB.ObstetricExamination;
    breastExamination: OB.BreastExamination;
    vaginalExamination: OB.VaginalExamination;
    ancCaredClosure: OB.AncCardClosure;
    ancCardGeneration: OB.AncCardGeneration;
    planOfManagement: OB.PlanOfManagement;
    records: Array<PatientMedicationHeader>;
    iconBasics: ImageReports;
    flag = true;
    vteRisk: OB.VTERisk;
    sgaScanning: OB.SGAScanning;
    procedureSurgery: Array<OB.ProcedureSurgery>;
    orderPrescription: OB.OrdersPrescription;
    drugHistory: OB.DrugHistory;
    menstrualHistory: OB.MenstrualHistory;
    procedureForm: OB.OPProcedure;
    antinantalRisk: OB.AntinantalRisk;
    otherDetail: OB.OtherDetail;
    birthHistory: Array<OB.ObBirthHistory>;
    measure: OB.Measure;
    generalExamination: OB.GeneralExamination;
    breech: OB.Breech;
    ancCards: OB.AncCards;
    covid: OB.Covid;
    postnantalVisit: OB.PostnantalVisit;
    vaccine: OB.Vaccine;
    anaesthesia: OB.Anaesthesia;
    prePregnancy: OB.PrePregnancyCounseling;
    @Input() isPrintLogo: boolean;
    patientData: ObEncounter;
    familyHistory: Array<OB.ObFamilyHistory>;
    allergies: OB.Allergies;
    outPatientManagement: OB.OutPatientManagement;
    specialFeature: OB.SpecialFeature;
    prePregnancyPlan: Array<OB.PrePregnancyPlan>;
    appointment: ObEncounter;
    patient: PatientProfile;
    isNurseDisplay = false;
    outSideTests: OB.OutsideTest;
    reminders: string;
    signature: string;
    @Input() useExistingCss: boolean;
    addendumForm: OB.AddendumForm;
    normoldeliveryform: OB.NormalDeliveryForm;
    lscsmodel: OB.LSCS;
    admissionDetails: OB.AdmissionDetails;
    iufdmodel: OB.IUFDEntryForm;
    doctorsReviewForm: OB.DoctorReviewForm;

    cimsAllergies: InternalMedicine.Allergies;
    diseaseName: any
    yearOfDiagnosis: any
    icd: any
    status: any
    treatmentHistory: any
    appointmentList: Appointment;
    aldreteScore: any;
    anaesthesiaRecord: any;
    isFooter: boolean;
    isMedicationInstruction: boolean;
    telephoneTriageAssessmentdata: Array<OB.TelephoneTriageAssessment>
    onlyUrl: boolean;
    constructor(
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly httpService: HttpService,
        private readonly toggleService: AppointmentToggleService,
        public timelineService: TimelineToggleService,
        private readonly printOptionService: PrintOptionService,
        private readonly iconService: IconService,
        private readonly menuService: MenuService,

    ) {
        this.page = new Page();
        this.pagination = new Pagination();
        this.appointments = [];
        this.constitutionalValue = [];
        this.noConstitutionalValue = [];
        this.ent = [];
        this.noEnt = [];
        this.rosSkin = [];
        this.noRosSkin = [];
        this.all = [];
        this.noAll = [];
        this.rosHeent = [];
        this.noRosHeent = [];
        this.eyes = [];
        this.noEyes = [];
        this.rosRespiratory = [];
        this.noRosRespiratory = [];
        this.cv = [];
        this.noCv = [];
        this.gi = [];
        this.noGi = [];
        this.integumentary = [];
        this.noIntegumentary = [];
        this.gu = [];
        this.noGu = [];
        this.endocrine = [];
        this.noEndocrine = [];
        this.musculoSkeletal = [];
        this.noMusculoSkeletal = [];
        this.neuro = [];
        this.noNeuro = [];
        this.psych = [];
        this.noPsych = [];
        this.heme = [];
        this.noHeme = [];
        this.gyn = [];
        this.noGyn = [];
        this.filters = new Filters();
        this.patientData = new ObEncounter();
        this.records = Array<PatientMedicationHeader>();
        this.infusions = new Array<any>
        this.telephoneTriageAssessmentdata = new Array<OB.TelephoneTriageAssessment>();

    }





    thresholdBMI() {
        return this.bmiModel.color;
    }

    thresholdBP() {
        return this.bpModel.color;
    }
    get bmiModel() {
        const bmi = parseFloat(this.vitals.bmi);
        if (bmi < 18.5) {
            return { color: "#5a5a5a", title: "Underweight" };
        }

        if (bmi > 18.5 && bmi <= 25) {
            return { color: "#44cf9c", title: "Normal" };
        }

        if (bmi > 25 && bmi <= 30) {
            return { color: "#fcc015", title: "Overweight" };
        }

        if (bmi > 30) {
            return { color: "#fcc015", title: "Overweight" };
        }

        return { color: "#f86262", title: "Obesity" };
    }

    get bpModel() {
        const systolicBP = parseFloat(this.vitals.systolicBP);
        const diastolicBP = parseFloat(this.vitals.diastolicBP);

        if (systolicBP <= 90 && diastolicBP <= 60) {
            return { color: "#5a5a5a", title: "Low" };
        }

        if (systolicBP <= 120 && diastolicBP <= 80) {
            return { color: "#44cf9c", title: "Normal" };
        }

        if (systolicBP <= 140 && diastolicBP <= 90) {
            return { color: "#fcc015", title: "Elevated" };
        }

        if (140 > systolicBP && diastolicBP > 90) {
            return { color: "#f86262", title: "Hypertension" };
        }

        return { color: "#f86262", title: "Hypertension" };
    }
    thresholdRespiratory = {
        '0': { color: "#fcc015" },
        '12': { color: "#44cf9c" },
        '19': { color: "#f86262" }
    };

    thresholdPulse = {
        '50': { color: "#fcc015" },
        '94': { color: "#44cf9c" },
        '98': { color: "#f86262" }
    };

    thresholdTemperature = {
        '94.0': { color: "#fcc015" },
        '97.8': { color: "#44cf9c" },
        '99.1': { color: "#f86262" }
    };

    thresholdHeart = {
        '30': { color: "#fcc015" },
        '60': { color: "#44cf9c" },
        '91': { color: "#f86262" }
    };



    filterEkg(item: any) {
        // ReSharper disable once TsResolvedFromInaccessibleModule
        for (const [key, value] of Object.entries(item)) {
            if (value === null) {
                delete item[key];
            }
        }
        return item;
    }
    private findDashboard(appointmentId: string) {
        if (this.routingValue == 'ob-ip-encounter') {
            this.isAdmission = true
        }
        const request = { encryptedAppointmentId: appointmentId };
        request["isAdmission"] = this.isAdmission;

        this.httpService.post<ObEncounterFullTranscript>(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.findFullTranscript), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: ObEncounterFullTranscript) => {
                this.fullTranscript = response;
                if (this.fullTranscript && this.fullTranscript.obEncounterId) {
                    this.vitals = this.fullTranscript.vitals ? JSON.parse(this.fullTranscript.vitals) : null;
                    let problems = this.fullTranscript.problemList ? JSON.parse(this.fullTranscript.problemList) : null;
                    if (problems && problems["problems"].length > 0) {
                        this.problemList = problems["problems"]
                    }
                    if (problems && problems["problems"].length == 0) {
                        this.isPastMedicalHistory = true;
                    }
                    this.ros = this.fullTranscript.briefRos ? JSON.parse(this.fullTranscript.briefRos) : null;
                    this.geneticScreening = this.fullTranscript.geneticScreening ? JSON.parse(this.fullTranscript.geneticScreening) : null;
                    this.infectionHistory = this.fullTranscript.infectionHistory ? JSON.parse(this.fullTranscript.infectionHistory) : null;
                    this.medications = this.fullTranscript.medications ? JSON.parse(this.fullTranscript.medications) : [];
                    this.breastExamination = this.fullTranscript.breastExamination ? JSON.parse(this.fullTranscript.breastExamination) : null;
                    this.vaginalExamination = this.fullTranscript.vaginalExamination ? JSON.parse(this.fullTranscript.vaginalExamination) : null;
                    this.ancCaredClosure = this.fullTranscript.ancCaredClosure ? JSON.parse(this.fullTranscript.ancCaredClosure) : null;
                    this.ancCardGeneration = this.fullTranscript.ancCardGeneration ? JSON.parse(this.fullTranscript.ancCardGeneration) : null;
                    this.planOfManagement = this.fullTranscript.planOfManagement ? JSON.parse(this.fullTranscript.planOfManagement) : null;
                    this.complaints = this.fullTranscript.complaints ? JSON.parse(this.fullTranscript.complaints) : null;
                    this.obstetricExamination = this.fullTranscript.obstetricExamination ? JSON.parse(this.fullTranscript.obstetricExamination) : null;
                    this.vteRisk = this.fullTranscript.vteRisk ? JSON.parse(this.fullTranscript.vteRisk) : null;
                    this.sgaScanning = this.fullTranscript.sgaScanning ? JSON.parse(this.fullTranscript.sgaScanning) : null;
                    this.procedureSurgery = this.fullTranscript.surgeries ? JSON.parse(this.fullTranscript.surgeries) : null;
                    if (this.fullTranscript.surgeries && this.procedureSurgery.length == 0) {
                        this.isProcedureSurgery = true;
                    }
                    this.orderPrescription = this.fullTranscript.orderPrescription ? JSON.parse(this.fullTranscript.orderPrescription) : null;
                    this.drugHistory = this.fullTranscript.drugHistory ? JSON.parse(this.fullTranscript.drugHistory) : null;
                    this.menstrualHistory = this.fullTranscript.menstrualHistory ? JSON.parse(this.fullTranscript.menstrualHistory) : null;
                    this.procedureForm = this.fullTranscript.procedureForm ? JSON.parse(this.fullTranscript.procedureForm) : null;
                    this.antinantalRisk = this.fullTranscript.antinantalRisk ? JSON.parse(this.fullTranscript.antinantalRisk) : null;
                    this.otherDetail = this.fullTranscript.otherDetail ? JSON.parse(this.fullTranscript.otherDetail) : null;
                    this.birthHistory = this.fullTranscript.birthHistory ? JSON.parse(this.fullTranscript.birthHistory) : null;
                    this.measure = this.fullTranscript.measure ? JSON.parse(this.fullTranscript.measure) : null;
                    this.generalExamination = this.fullTranscript.generalExamination ? JSON.parse(this.fullTranscript.generalExamination) : null;
                    this.breech = this.fullTranscript.breech ? JSON.parse(this.fullTranscript.breech) : null;
                    this.ancCards = this.fullTranscript.ancCard ? JSON.parse(this.fullTranscript.ancCard) : null;
                    this.covid = this.fullTranscript.covid ? JSON.parse(this.fullTranscript.covid) : null;
                    this.vaccine = this.fullTranscript.vaccine ? JSON.parse(this.fullTranscript.vaccine) : null;
                    this.anaesthesia = this.fullTranscript.anaesthesiaRecord ? JSON.parse(this.fullTranscript.anaesthesiaRecord) : null;
                    this.prePregnancy = this.fullTranscript.prepregnancyCounseling ? JSON.parse(this.fullTranscript.prepregnancyCounseling) : null;
                    this.allergies = this.fullTranscript.allergies ? JSON.parse(this.fullTranscript.allergies) : null;
                    this.familyHistory = this.fullTranscript.familyHistory ? JSON.parse(this.fullTranscript.familyHistory) : null;
                    if (this.fullTranscript.familyHistory && this.familyHistory.length == 0) {
                        this.isFamilyHistory = true;
                    }
                    this.outPatientManagement = this.fullTranscript.outPatientManagement ? JSON.parse(this.fullTranscript.outPatientManagement) : null;
                    this.specialFeature = this.fullTranscript.specialFeature ? JSON.parse(this.fullTranscript.specialFeature) : null;
                    this.prePregnancyPlan = this.fullTranscript.prePregnancyPlan ? JSON.parse(this.fullTranscript.prePregnancyPlan) : null;
                    this.referralOrder = this.fullTranscript.refferalOrder ? JSON.parse(this.fullTranscript.refferalOrder) : null;
                    this.admissionSlip = this.fullTranscript.admissionSlip ? JSON.parse(this.fullTranscript.admissionSlip) : null;
                    this.syndromeDetails = this.fullTranscript.syndromes ? JSON.parse(this.fullTranscript.syndromes) : null;
                    this.opManagement = this.fullTranscript.opManagement ? JSON.parse(this.fullTranscript.opManagement) : null;
                    this.motherEpilepsy = this.fullTranscript.motherWithEpilepsy ? JSON.parse(this.fullTranscript.motherWithEpilepsy) : null;
                    this.externalCephalic = this.fullTranscript.externalCephalicVersion ? JSON.parse(this.fullTranscript.externalCephalicVersion) : null;
                    this.dischargeSummary = this.fullTranscript.dischargeSummary ? JSON.parse(this.fullTranscript.dischargeSummary) : null;
                    this.nurseAssessment = this.fullTranscript.nurseAssessment ? JSON.parse(this.fullTranscript.nurseAssessment) : null;
                    this.outSideTests = this.fullTranscript.outSideTests ? JSON.parse(this.fullTranscript.outSideTests) : null;
                    this.postnantalVisit = this.fullTranscript.postnantalVisit ? JSON.parse(this.fullTranscript.postnantalVisit) : null;
                    this.reminders = this.fullTranscript.reminder ? this.fullTranscript.reminder : null;
                    this.addendumForm = this.fullTranscript.addendumForm ? JSON.parse(this.fullTranscript.addendumForm) : null;
                    this.normoldeliveryform = this.fullTranscript.normalDeliveryForm ? JSON.parse(this.fullTranscript.normalDeliveryForm) : null;
                    this.lscsmodel = this.fullTranscript.lscs ? JSON.parse(this.fullTranscript.lscs) : null;
                    this.admissionDetails = this.fullTranscript.admissionDetails ? JSON.parse(this.fullTranscript.admissionDetails) : null;
                    this.iufdmodel = this.fullTranscript.iufd ? JSON.parse(this.fullTranscript.iufd) : null;
                    this.doctorsReviewForm = this.fullTranscript.doctorsReview ? JSON.parse(this.fullTranscript.doctorsReview) : null;
                    this.cimsAllergies = this.fullTranscript.cimsAllergy ? JSON.parse(this.fullTranscript.cimsAllergy) : null;
                    this.aldreteScore = this.fullTranscript.aldreteScore ? JSON.parse(this.fullTranscript.aldreteScore) : null;
                    this.anaesthesiaRecord = this.fullTranscript.anaesthesiaRecordPostOP ? JSON.parse(this.fullTranscript.anaesthesiaRecordPostOP) : null;
                }

            });


    }

    private findPatientDetails() {
        this.page.loading = true;
        this.httpService.post<PatientProfile>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.profile), { encryptedPatientId: this.appointment.encryptedPatientId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: PatientProfile) => {
                if (response) {
                    this.patient = response;
                    this.patientId = this.patient.patient.patientId;
                }

            });
    }
    private findPatient() {
        this.page.loading = true;
        const request = { encryptedAppointmentId: this.appointmentId };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<ObEncounter>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: ObEncounter) => {
                this.appointment = response;
                this.encryptedPatientId = response.encryptedPatientId;
                this.findPatientDetails();
                setTimeout(() => {
                    this.fetchAll();
                }, 100)

            });
    }

    private fetchAll() {
        const ancRequest = { patientId: this.patientId, appointmentId: this.appointment.appointmentId, isAdmission: this.isAdmission }
        let ancCard = this.httpService.get(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchANC), ancRequest)
        const medRequest = { encryptedAppointmentId: this.appointmentId }
        let med = this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetch), medRequest)
        const labReq = { appointmentId: this.appointment.appointmentId, isAdmission: this.isAdmission }
        let lab = this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetchLabs), labReq)
        forkJoin(ancCard, med, lab)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loading = false; }))
            .subscribe((response: Array<any>) => {
                if (response[0] && response[0].ancNo != null) {
                    this.ancCardGeneration = response[0];
                }
                this.records = response[1];
                var labs = response[2].length ? response[2] : null;
                this.bookingHeader = labs ? labs[0].labs : null;
            })

    }
    gotoPrescription(appointmentId: string) {
        this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/prescription/${appointmentId}`, "_blank"); });
    }

    onCloseModal(type?: string) {
        if (type === "document") {
            try {
                this.modalViewRef.close();
                this.modalViewRef = undefined;
            } catch (e) {
                // ignored;
            }
        } else {
            try {
                this.modalRef.close();
                this.modalRef = undefined;
            } catch (e) {
                // ignored;
            }
        }
    }
    private findAppointment() {
        const request = {
            encryptedAppointmentId: this.encryptedAppointmentId,
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointmentList = response;
                this.findreferralForm(this.appointmentList.patientId, this.appointmentList.appointmentId)
                this.fetchalltelephonetriage(this.appointmentList.appointmentId, this.appointmentList.patientId)
            });

    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    this.isAdmission = url.split("/")[4] === "a";
                    this.onlyUrl = url.split("/")[4] !== "a" && url.split("/")[4] !== "b";
                    this.appointmentId = decodeURIComponent(url.split("/")[3]);
                    this.filters.options.encryptedAppointmentId = decodeURIComponent(this.appointmentId);
                    this.encryptedAppointmentId = this.appointmentId;

                    this.encryptedAppointmentId = this.printId
                    this.appointmentId = this.printId
                   this.isAdmission=this.printadmission
                    this.routingValue = url.split("/")[2];
                    this.findAppointment();
                    this.fetchInfusions();
                    this.getSettings();
                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((params: Params) => {
                            const section = params["section"];
                            this.section = section ? section : undefined;
                            const bypass = params["bypass"];
                            if (!bypass || bypass !== "t") {
                                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                                this.iconService.getIconImage((is) => { this.iconBasics = is; });
                            }
                        });
                    this.encounterMenus();
                    if (this.appointmentId) {
                        this.findPatient();
                        this.findDashboard(this.appointmentId);
                    } else {
                        this.router.navigateByUrl(appUrls.notFound);
                    }

                } else {
                    this.page.userAccount = undefined;
                }

                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                this.iconService.getIconImage((is) => { this.iconBasics = is; });

            });

    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }

    appointmentToggle() {
        this.toggleService.toggle(true);
    }

    timelineToggle() {
        this.timelineService.toggle();
    }

    encounterMenus() {
        var type = "OB Encounter";
        let allSubMenus = this.menuService.menus(MenuType.SubMenu, type);
        var onlySubMenus = allSubMenus.filter(x => x.menuTypeId === MenuType.SubMenu ||
            (x.menuTypeId === MenuType.CategoryMenu && x.category && x.category === x.displayName));
        onlySubMenus.forEach(x => {
            if (x.menuTypeId === MenuType.CategoryMenu) {
                x.categoryMenus = allSubMenus.filter(y => y.menuTypeId === MenuType.CategoryMenu && y.category === x.category && y.displayName !== x.displayName)
                    .map((y, i) => {
                        return {
                            id: i,
                            subPage: y.subPage,
                            displayName: y.displayName,
                            iconClasses: y.iconClasses,
                            url: y.url,
                            priority: y.priority,
                        } as ICategoryMenuModel
                    });
            }
        });
        if (onlySubMenus.length > 0 && onlySubMenus.length < 6) {
            this.isNurseDisplay = true;
        }
        return onlySubMenus;

    }

    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }
    onChangePrintType(isShow: boolean) {
        this.isPrintLogo = isShow;
    }
    fetchInfusions() {
        this.loading = true;
        const request = {
            encryptedAppointmentId: this.appointmentId,
            isAdmission: this.isAdmission
        }

        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetchInfusion), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PharmacyInfusionHeader>) => {
                this.infusionrecords = response;
                this.infusionrecords.forEach((product) => {
                    product.infusions.forEach((x) => {
                        if (x != null) {
                            this.infusions.push(x);
                        }

                    })

                })

            });

    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
    private findreferralForm(id: any, appointmentId: any) {
        this.loading = true;

        const request = Object.assign({ isAdmission: this.isAdmission, patientId: id, appointmentId: appointmentId });

        this.httpService.post(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchCrossConsultation), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<OB.ReferralForm>) => {
                if (response.length > 0) {
                    this.referralForm = response;
                }
            });
    }

    private getSettings() {
        this.loading = true;
        this.httpService.get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: (response: Array<Setting>) => {
                    if (response && response.length > 0) {
                        var medciationINstruction = response.find(s => s.name == "OrderPrescriptionMedicationInstruction");
                        this.isMedicationInstruction = medciationINstruction.active;
                    }
                }, error: () => { }
            });
    }
    fetchalltelephonetriage(appointmentId: any, patientId: any) {
        const request = {
            patientId: patientId,
            appointmentId: appointmentId,
            isAdmission: this.isAdmission
        }

        this.httpService.post(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchtelephonetraige), request)
            .subscribe((result: any) => {
                if (result) {
                    var response = result;
                    response.forEach((x) => {
                        var data = JSON.parse(x.json)
                        this.telephoneTriageAssessmentdata.push(data)
                    })
                }


            })

    }
}