<div class="col" *ngIf="sectionType != 5">
    <div class="row">
        <div class="col-auto">
            <label>Section Name <code>*</code></label>
        </div>
        <div class="col">
            <ng-select [items]="Sections"
                       [clearable]="true"
                       bindLabel="name"
                       (change)="onChange($event)">
                <!-- formControlName="searchSectionName"
                bindValue="sectionId"
                [searchFn]="customSearch"-->
                <!--(change)="onChange($event)"
                placeholder="Enter Section Name"
                (search)="fetchSectionName($event)"
                -->
                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                    <div class="ng-option disabled"
                         *ngIf="searchTerm && searchTerm.length >= 2">
                        No section found for '{{searchTerm}}'
                    </div>
                    <div class="ng-option disabled"
                         *ngIf="(!searchTerm || searchTerm.length < 2)">
                        Search section
                    </div>
                </ng-template>

                <ng-template ng-option-tmp let-item="item" let-i="index">
                    <div class="media">
                        <div class="avatar-xs mr-1">
                            <span class="avatar-title rounded-circle font-11 font-weight-bold bg-soft-warning text-primary">
                                <i class="fe-map-pin"></i>
                            </span>
                        </div>
                        <div class="media-body">
                            <h5 class="mb-0 mt-0 font-weight-normal"
                                [textContent]="item.name"></h5>
                            <span class="d-block font-13"
                                  [textContent]="item.name || '-----'"></span>
                        </div>
                    </div>
                </ng-template>
            </ng-select>
        </div>
    </div>
</div>

<div>
    <div class="card-body p-2"  *ngIf="false">
        <div id="contentDiv" [innerHTML]="htmlString1"></div>
    </div>
</div>

<div>
    <div #dynamicComponentContainer></div>
</div>