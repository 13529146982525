export class DrugRegister {
    
    name: string;
    createdDate: Date;
    gstNo: string;
    createdByRole: string;
    supplierProductHeaderId?: number;
    supplierName: string;
    createdByName: string;
    modifiedBy: number;
    modifiedByName: string;
    modifiedDate?: Date;
    batchNumber: string;
    billNumber: string;
    genericName: string;
    productName: string;
    expiryDate: Date;
    quantity: number;

    companyName: string;
}