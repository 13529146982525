<div class="modal-body" *ngIf="loading">
    <div class="d-flex align-items-center">
        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
        <span class="ml-2">Please wait while loading Report details....</span>
    </div>
</div>
<div >
    <div class="modal-body" *ngIf="!loading && resultdata.length === 0 ">
        <div class="d-flex justify-content-center">
            <div class="no-data-container">
                <img class="mt-1" width="350" src="assets/images/no-data-2.png" alt="No Data" />
                <h4 class="mt-1">No Report Data found.Nothing to show you.</h4>
            </div>
        </div>
    </div>

    <div id="invoiceId" *ngIf="!loading && resultdata.length > 0">

        <div class="report position-relative">
            <div class="page-footer-pdetail">
                <div class="h-100">
                    <div *ngIf="isFooter" class="border-top h-100 border-dark pb-1 px-1 left-0 right-0 background-color" [ngStyle]="{'background-color': (isPrintLogo ) ? 'white':'#dddddd'}">
                        <div class="h-100 p-1">
                            <banner-setting [reportName]='"Lab Report Footer"'></banner-setting>
                        </div>
                    </div>
                </div>
            </div>
            <table class="w-100 border-collapse table-layout-fixed">
                <thead>
                    <tr class="border-bottom border-dark">
                        <td>
                            <div class="text-center">
                                <div id="header-content" class="w-100">
                                    <div class="d-table-cell page-header-space mb-1 vertical-align-middle" *ngIf="isPrintLogo">
                                        <banner-setting [reportName]='"Lab Report Header"'></banner-setting>
                                    </div>
                                    <div *ngIf="!isPrintLogo" class="h-200px">

                                    </div>
                                    <div class="overflow-x-auto overflow-y-hidden white-space-nowrap">
                                        <table class="text-left w-100 line-height16">
                                            <tr class="vertical-align-top">
                                                <th>
                                                    <h5 class="white-space-nowrap" *ngIf="patient && patient.fullName">Patient Name</h5>
                                                </th>
                                                <td class="d-flex">
                                                    <span class="marginright5">: </span>
                                                    <span class="text-capitalize" *ngIf="patient &&  patient.fullName" [textContent]="patient.fullName"></span>
                                                </td>
                                                <th>
                                                    <h5 class="white-space-nowrap">UMR No</h5>
                                                </th>
                                                <td class="white-space-nowrap">
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="patient.umrNo || '-----'"></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    Age/Gender
                                                </th>
                                                <td>
                                                    <div class="d-flex">
                                                        <span class="marginright5">: </span>
                                                        <div>

                                                            <span *ngIf="patient &&  patient.dateOfBirth">{{patient.dateOfBirth | date:'dd/MM/yyyy'}} </span>
                                                            <span *ngIf="patient &&  patient.gender">{{patient.gender}}</span>

                                                        </div>
                                                    </div>
                                                </td>
                                                <th>
                                                    Invoice Date
                                                </th>
                                                <td class="white-space-nowrap">
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="resultdata[0].createdDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    {{relationType ? relationType : 'RelativeName' }}
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="patient?.relativeName"></span>
                                                </td>
                                                <th class="white-space-nowrap">
                                                    Requisition No
                                                </th>
                                                <td class="white-space-nowrap">
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="resultdata[0].reqno"></span>
                                                </td>
                                            </tr>

                                            <tr class="vertical-align-top">

                                                <th class="white-space-nowrap">
                                                    Collected Date
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="resultdata[0].createdDate | date:'dd/MM/yyyy'"></span>
                                                </td>
                                                <th>
                                                    Received Date
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="resultdata[0].reportdt "></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    Consultant
                                                </th>
                                                <td class="d-flex">
                                                    <span class="marginright5">: </span>
                                                    <span class="text-capitalize" [textContent]="resultdata[0].usernm || '------'"></span>
                                                </td>
                                                <th>
                                                    Reported Date
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="resultdata[0].reportdt"></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    Ref.Doctor
                                                </th>
                                                <td class="d-flex">
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="patient?.referredBy"></span>
                                                </td>
                                                <th>
                                                    Approved Date
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span *ngIf="reportSignatures" [textContent]="reportSignatures[reportSignatures.length-1].approvedDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                                </td>
                                            </tr>


                                        </table>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            *** CONTENT GOES HERE ***
                            <div class="page">
                                <div>
                                    <div id="dataContainer">
                                        <div class="col-12 p-0 border-bottom_1px">

                                            <ng-container *ngIf="resultdata.length >0">
                                                <div class="overflow-auto">
                                                    <table class="w-100">
                                                        <thead>
                                                            <tr>
                                                                <th colspan="4">
                                                                    <div class="d-flex justify-content-center">
                                                                        <h5 class="text-decoration" *ngIf="reports && reports.departmentName !='' && reports.departmentName != null">{{reports.departmentName}}</h5>
                                                                    </div>
                                                                    <div class="d-flex justify-content-center">
                                                                        <h5 class="text-decoration">{{resultdata[0].testName}}</h5>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div>
                                                                        <div>
                                                                            <div class="overflow-auto textOfEveryVw">
                                                                                <table width="100%">
                                                                                    <thead>
                                                                                        <tr class="vertical-align-top">
                                                                                            <th><b>Test Name</b></th>
                                                                                            <th><b>Result</b></th>
                                                                                            <th *ngIf="!isShowMicrobiology"><b>Biological Reference Range</b></th>
                                                                                            <th *ngIf="!isShowMicrobiology"><b>Units</b></th>
                                                                                            <th *ngIf="!isShowMicrobiology"><b>Method</b></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody *ngFor="let templateItem of resultdata">
                                                                                        <tr>
                                                                                            <td>{{templateItem.testcomponent}}</td>
                                                                                            <td>{{templateItem.resultvalue }}</td>
                                                                                            <td>{{templateItem.billlocid}}</td>
                                                                                            <td>{{templateItem.units }}</td>
                                                                                            <td>{{templateItem.method}}</td>
                                                                                        </tr>                                                                                  
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>


                                                </div>
                                            </ng-container>

                                        </div>

                                        <div class="col-12">
                                            <div class="d-flex justify-content-center">
                                                <p>--End Of Report--</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>

                        </td>
                    </tr>
                </tbody>



            </table>
            <table class="w-100" [ngClass]="isPrintTermsAndConditions?'':'d-print-none'">
                <thead>
                    <tr class="d-none d-print-table-row">
                        <td>
                            <div class="text-center">
                                <div id="header-content" class="w-100">
                                    <div class="d-table-cell page-header-space mb-1 vertical-align-middle" *ngIf="isPrintLogo">
                                        <banner-setting [reportName]='"Lab Information Report Header"'></banner-setting>
                                    </div>
                                    <div *ngIf="!isPrintLogo" class="mt100">

                                    </div>
                                    <div class="overflow-auto">
                                        <table class="text-left w-100 line-height16">
                                            <tr class="vertical-align-top">
                                                <th>
                                                    <h5 class="white-space-nowrap" *ngIf="patient && patient.fullName">Patient Name</h5>
                                                </th>
                                                <td class="d-flex">
                                                    <span class="marginright5">: </span>
                                                    <span class="text-capitalize" *ngIf="patient &&  patient.fullName" [textContent]="patient.fullName"></span>
                                                </td>
                                                <th>
                                                    <h5 class="white-space-nowrap">UMR No</h5>
                                                </th>
                                                <td class="white-space-nowrap">
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="patient.umrNo || '-----'"></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    Age/Gender
                                                </th>
                                                <td>
                                                    <div class="d-flex">
                                                        <span class="marginright5">: </span>
                                                        <div>

                                                            <span *ngIf="patient &&  patient.dateOfBirth">{{patient.dateOfBirth | date:'dd/MM/yyyy'}} </span>
                                                            <span *ngIf="patient &&  patient.gender">{{patient.gender}}</span>

                                                        </div>
                                                    </div>
                                                </td>
                                                <th>
                                                    Invoice Date
                                                </th>
                                                <td class="white-space-nowrap">
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="resultdata[0].createdDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    {{relationType ? relationType : 'RelativeName' }}
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="patient?.relativeName"></span>
                                                </td>
                                                <th class="white-space-nowrap">
                                                    Requisition No
                                                </th>
                                                <td class="white-space-nowrap">
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="resultdata[0].reqno"></span>
                                                </td>
                                            </tr>

                                            <tr class="vertical-align-top">

                                                <th class="white-space-nowrap">
                                                    Collected Date
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="resultdata[0].createdDate | date:'dd/MM/yyyy'"></span>
                                                </td>
                                                <th>
                                                    Received Date
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="resultdata[0].reportdt "></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    Consultant
                                                </th>
                                                <td class="d-flex">
                                                    <span class="marginright5">: </span>
                                                    <span class="text-capitalize" [textContent]="resultdata[0].usernm || '------'"></span>
                                                </td>
                                                <th>
                                                    Reported Date
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="resultdata[0].reportdt"></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    Ref.Doctor
                                                </th>
                                                <td class="d-flex">
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="patient?.referredBy"></span>
                                                </td>
                                                <th>
                                                    Approved Date
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span *ngIf="reportSignatures" [textContent]="reportSignatures[reportSignatures.length-1].approvedDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                                </td>
                                            </tr>


                                        </table>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class="mt-3">
                                <h4 class="border-bottom border-dark border-top topHeading font-weight-bold mb-2 p-1 text-center text-dark">
                                    TERMS AND CONDITIONS FOR LABORATORY TESTING AND REPORTING
                                </h4>
                                <div class="d-flex flex-lg-nowrap flex-wrap headingText1 pr-3 text-justify gap15px">
                                    <div>
                                        <ul>
                                            <li>
                                                The reported results are for information and interpretation of the
                                                referring clinician or other medical professional, who understands
                                                reporting units, reference ranges and limitation of technologies.
                                            </li>
                                            <li>
                                                A qualified medical professional should interpret the laboratory
                                                results and adjuvant information using objective clinical judgment
                                                and in conjunction with patient presentation, history, and other
                                                diagnostic evidence.
                                            </li>
                                            <li>
                                                The laboratory services at {{resultdata[0].practiceName || '---'}}
                                                Hospital (A unit of {{resultdata[0].practiceName || '---'}})
                                                may outsource certain tests to qualified labs.
                                            </li>
                                            <li>
                                                Test results depend upon quality of sample and testing method, and
                                                they may vary from lab-to-lab and/or from time-to-time for the same
                                                parameters for the same patient. To help maintain sample quality, the
                                                laboratory specifies criteria for specimen storage in accordance with
                                                accreditation guidelines and testing methods. For more information,
                                                please contact the Laboratory Services team.
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                Specified biological reference ranges encompass 95% confidence
                                                limits of a given population, which implies test results for healthy
                                                individuals may fall in the abnormal range. In case of unexpectedly
                                                abnormal lab results, the lab services team may perform repeat
                                                analysis, based on Clinician/Client’s request and after due
                                                investigation.
                                            </li>
                                            <li>
                                                We assure that we maintain confidentiality of this information.
                                            </li>
                                            <li>
                                                {{resultdata[0].practiceName || '---'}}, its directors, employees and/or
                                                representatives are not liable for claims of damages incurred by any
                                                person, including the patient as a result of interpretation of lab reports.
                                                Financial or monetary claims are subject to approval by the
                                                management of {{resultdata[0].practiceName || '---'}} and shall not exceed the
                                                stipulated test cost under any circumstances.
                                            </li>
                                            <li>
                                                All claims are subject to the jurisdiction of {{resultdata[0].city || '---'}} ({{resultdata[0].state || '---'}}) India.
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </td>
                    </tr>
                </tbody>
         
            </table>

        </div>

    </div>

</div>



