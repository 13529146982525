export enum PaymentModule {
    Patient = 1,
    Appointment,
    Pharmacy,
    Labs,
    ScanAppointment,
    Admission,
    MachineWiseScanAppointment,
    Services,
    ScanAppointmentUpdate,
    GeneralServices
}