import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { ApiResources, UtilHelper } from "@shared/helpers";
import { Appointment, ImageReports, PatientFamily, TemplateDocument } from "@shared/entities";
import { Page, IUserAccount, IResource } from "@shared/models";
import { AppData, HttpService, IconService, NotifyService, PrintOptionService, ResourceService, } from "@shared/services";
import { finalize, takeUntil } from "rxjs/operators";

import { HttpErrorResponse } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";
import { GeneticSpecialityEncounterList } from "../../entities/geneticSpecialityList.entity";


@Component({
    templateUrl: "./genetic-report.html",
    selector: "genetic-report",

    encapsulation: ViewEncapsulation.None
})
export class GeneticPrintReportPage implements OnInit, OnDestroy {
    page: Page;
    appointmentId: string;
    encryptedAppointmentId: string;
    isAdmission: boolean;
    patientId: number;
    appointments: Array<Appointment>;
    appointment: Appointment;
    submitting: boolean;
    date: Date;
    geneticspeciality: GeneticSpecialityEncounterList;
    geneticVisit: any;
    loadingPractice: boolean;
    practice: Array<IResource>;
    practiceName: string;
    isPrintLogo: boolean;
    iconBasics: ImageReports;
    searching: boolean;
    patient: any;
    partnerName: any;
    age: any;
    printedByName: string;
    errorMessage: any;
    teplateDocuments: TemplateDocument[];
    show = false;
    isFooter: boolean;
    @Input() printId: string;
    @Input() printadmission: boolean;
    constructor(
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly httpService: HttpService,
        private readonly resourceService: ResourceService,
        private readonly printOptionService: PrintOptionService,
        private readonly iconService: IconService,
        private readonly sanitizer: DomSanitizer,
        private readonly notifyService: NotifyService,
    ) {
        this.page = new Page();
        this.appointments = [];
        this.date = new Date();
    }
    private findAppointment(appointmentId: string) {
        const request = {
            encryptedAppointmentId: appointmentId
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointment = response;
                this.fetchPatient(this.appointment.patientId);
                this.fetchDocuments(this.appointment.appointmentId);
            });

    }
    private fetchPatient(id) {
        const request = {
            patientId: id
        }
        this.httpService
            .post<Array<PatientFamily>>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.fetchFamilyMembers), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { }))
            .subscribe(
                (response: Array<any>) => {
                    this.patient = response[0];

                });
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    if (this.printId) {
                        this.appointmentId = this.printId;
                        this.isAdmission = this.printadmission;
                        this.findAppointment(this.printId);
                        this.findOrderPrescription();
                        this.fetchPractice();
                        this.printedByName = this.page.userAccount.fullName;
                    }
                    else {
                        this.page.userAccount = userAccount;
                        const url = this.router.url;
                        this.isAdmission = url.split("/")[4] === "a";

                        this.appointmentId = decodeURIComponent(url.split("/")[3]);
                        this.findAppointment(this.appointmentId);
                        this.findOrderPrescription();
                        this.fetchPractice();
                        this.printedByName = this.page.userAccount.fullName;
                    }
                }
                this.printOptionService.get((is) => { this.isPrintLogo = is; });
                this.iconService.getIconImage((is) => { this.iconBasics = is; });
            });
    }


    private findOrderPrescription() {
        const request = { encryptedAppointmentId: this.appointmentId };
        this.httpService.post<GeneticSpecialityEncounterList>(ApiResources.getURI(ApiResources.geneticSpecialityEncounter.base, ApiResources.geneticSpecialityEncounter.findDashboard), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: GeneticSpecialityEncounterList) => {
                this.geneticspeciality = response;
                this.geneticVisit = this.geneticspeciality.geneticSummary ? JSON.parse(this.geneticspeciality.geneticSummary) : null;
                this.partnerName = this.patient && this.patient.fullName ? this.patient.fullName : this.geneticVisit && this.geneticVisit.partnersName ? this.geneticVisit.partnersName : null
                this.age = this.patient && this.patient.age ? this.patient.age : this.geneticVisit && this.geneticVisit.age ? this.geneticVisit.age : null
            });

    }
    ngOnDestroy() {
        this.page.unsubscribeAll();
    }

    private fetchPractice() {
        this.loadingPractice = true;
        this.resourceService.practice()
            .pipe(finalize(() => { this.loadingPractice = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.practice = response;
                this.practiceName = this.practice[0].value;
            });
    }
    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }
    fetchDocuments(appointmentId: number) {
        this.errorMessage = undefined;
        this.httpService
            .get(ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.fetchtemplatedocuments), { appointmentId: appointmentId })
            .pipe(finalize(() => { }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                (response: Array<TemplateDocument>) => {
                    this.teplateDocuments = response;
                    if (this.teplateDocuments?.length > 0) {
                        this.teplateDocuments.forEach((template) => {
                            this.prepareDocument(template);
                        })
                    }

                },
                (error: HttpErrorResponse) => {
                    this.errorMessage = UtilHelper.handleError(error);
                    this.notifyService.warning(this.errorMessage);
                }
            );
    }
    private prepareDocument(document: TemplateDocument) {
        document.formedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${document.imageUrl}`);
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
}