import { Component, ViewEncapsulation, OnInit, OnDestroy, Input } from "@angular/core";
import { GenericResponse, GenericStatus, IResource, IUserAccount, Page } from "../../models";
import { AppData, HttpService, PrintOptionService, ResourceService } from "../../services";
import { IAdmissionModel } from "../../../areas/admin/services/models/admission.model";
import { finalize, takeUntil } from "rxjs";
import { AdmissionFetchHelper } from "../../../areas/admin/progress-report/shared/helper";
import { ApiResources, DateHelper } from "../../helpers";
import { FinalBillBasicModel } from "../../../areas/admin/services/models/final-bill-basic.model";
import * as numberToWords from 'number-to-words';
import { IChargeModel } from "../../../areas/admin/services/models/charge.model";
import { IReceiptModel } from "../../../areas/admin/services/models/receipt.model";
import { ServiceBillDetailModel, ServiceBillHeaderModel } from "../../../areas/admin/op-services/pages/models";
import { PayTypes } from "../../../areas/admin/labs/pages/models";
import moment from "moment";

@Component({
    templateUrl: "./service-invoive-widget.html",
    styleUrls: ['./service-invoive-widget.css'],
    selector: "service-invoice-print",
    encapsulation: ViewEncapsulation.None
})

export class ServicesInvoicePage implements OnInit, OnDestroy {    
    @Input() admissionId: string;
    @Input() isAdmission: boolean;
    @Input() isPrintLogo: boolean;
    @Input() isFooter: boolean;
    @Input() serviceBookingHeaderId: number;
    @Input() showFinanceandPrintByDetails: boolean;
    page: Page;
    record: IAdmissionModel;
    isChargesLoading: boolean;
    finalBillBasics: FinalBillBasicModel;
    billNumber = "";
    loading: boolean;
    activeGeneralRecords: IChargeModel[];
    invoiceTotal: number;
    amountPaid: number;
    amountInWord: string;
    practiceLocations: Array<IResource>;
    receipts: Array<IReceiptModel>;
    currentDate: Date;
    bill: Array<ServiceBillHeaderModel>;
    billServices: Array<ServiceBillDetailModel>;
    payModes: Array<PayTypes>;
    yearsfordisplay: number;
    monthsfordisplay: number;
    daysfordisplay: number;
    patientAge: string;

    constructor(
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
        private readonly appData: AppData,
        private readonly admissionFetchHelper: AdmissionFetchHelper,
        private readonly resourceService: ResourceService
    ) {
        this.currentDate = new Date();
        this.page = new Page();
        this.practiceLocations = new Array<IResource>();
    }

    fetchAdmissionInfo = () => {
        this.admissionFetchHelper.admissionFetch(+this.admissionId, this.isAdmission, (data: IAdmissionModel) => {
            this.record = data;
            const dateOfBirth = DateHelper.toNgbDateStruct(String(this.record.patientDateOfBirth));
            if (dateOfBirth != null) {
                let mdob = moment(String(this.record.patientDateOfBirth), "YYYY-MM-DD");
                let age = moment().diff(mdob, "months");
                let yrs = (age / 12).toFixed(1);
                this.yearsfordisplay = parseInt(yrs, 10);
                let months = age % 12;
                var days = moment().diff(mdob.add(yrs, 'years'), 'days', false);
                this.yearsfordisplay = days;
                this.yearsfordisplay = parseInt(yrs);
                this.monthsfordisplay = months;
                var date1 = moment(String(this.record.patientDateOfBirth), "YYYY-MM-DD");
                var monthsa = moment().diff(date1, 'months');
                date1.add(monthsa, 'months');
                var daysa = moment().diff(date1, 'days');
                monthsa + ' ' + 'Month(s)' + ' ' + daysa + " Day(s)"
                this.daysfordisplay = daysa;
                this.patientAge = this.record.patientDateOfBirth ? this.yearsfordisplay + 'Y ' + this.monthsfordisplay + 'M ' + this.daysfordisplay + 'D' : null;
            }
            
        });
    }
    private getFinalBillBasics( ) {
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.finalBill.base, ApiResources.finalBill.getBasics), { id: this.admissionId, isAdmission: this.isAdmission })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.isChargesLoading = false))
            .subscribe(
                (response: GenericResponse) => {
                    this.finalBillBasics = response.data ? response.data as FinalBillBasicModel : new FinalBillBasicModel();
                    if (this.finalBillBasics.finalBillId != null) {
                        this.billNumber = String(this.finalBillBasics.finalBillId);
                    }
                    
                },
                () => {
                    this.finalBillBasics = new FinalBillBasicModel();
                    
                }
            );
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchPracticeLocation();
                    if (this.serviceBookingHeaderId > 0) {
                        this.fetchInvoice();
                    }
                    else if (this.admissionId) {
                        this.fetchAdmissionInfo();
                        this.fetch();                        
                        this.fetchReceipts();
                        this.getFinalBillBasics();
                        this.printOptionService.get((is) => { this.isPrintLogo = is; });
                        //this.bannerService.getBannerImage((is) => { this.bannerBasics = is; });
                        //this.currentDate = new Date();
                    }
                } else {
                    this.page.userAccount = undefined;
                }
            });

    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }


    fetch() {
        this.loading = true;
        const request = {
            id: this.admissionId,
            isAdmission: this.isAdmission
        }
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.serviceOrder.base, ApiResources.serviceOrder.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        if (response && response.data) {
                            this.activeGeneralRecords = response.data["generalRecords"];
                            this.activeGeneralRecords = this.activeGeneralRecords.map(record => {
                                return {
                                    ...record, // Keep existing properties
                                    totalCost: (record.unit || 0) * (record.cost || 0) - (record.discount)// Calculate totalCost
                                };
                            });
                            this.invoiceTotal = this.activeGeneralRecords.map((x) => {

                                return x.totalCost;
                            }).reduce((a, b) => a + b, 0);
                            let amountInWords = numberToWords.toWords(this.invoiceTotal);
                            this.amountInWord = amountInWords + ' Rupees Only.';
                        }
                    }
                }
            )
    }

    private fetchPracticeLocation() {
        this.resourceService.fetchPracticeLocations()
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<IResource>) => {
                this.practiceLocations = response;
            });
    }
    private fetchReceipts (){
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.receipt.base, ApiResources.receipt.fetch), { id: this.admissionId, isAdmission: this.isAdmission, activeOnly: true })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: GenericResponse) => {
                    const records = response.data.records as Array<IReceiptModel>;
                    if (records.length > 0) {
                        records.forEach((item) => {
                            item.receiptNo = String(item.receiptId).padStart(6, '0');
                        });
                    }
                    this.receipts = records;
                },
                () => {
                    this.receipts = new Array<IReceiptModel>();
                }
            );

    }

    private fetchInvoice() {
        const request = {
            serviceBookingHeaderId: this.serviceBookingHeaderId
        };
        if (this.showFinanceandPrintByDetails == null && this.showFinanceandPrintByDetails == undefined) {
            this.showFinanceandPrintByDetails == false;
        }
        this.loading = true;
        this.httpService.post(ApiResources.getURI(ApiResources.serviceTransaction.base, ApiResources.serviceTransaction.fetchSelectedInvoiceData), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<ServiceBillHeaderModel>) => {
                    this.bill = response;
                    this.bill.forEach((item) => {
                        if (item.dateOfBirth != null) {
                            let mdob = moment(String(item.dateOfBirth), "YYYY-MM-DD");
                            let age = moment().diff(mdob, "months");
                            let yrs = (age / 12).toFixed(1);
                            this.yearsfordisplay = parseInt(yrs, 10);
                            let months = age % 12;
                            let days = moment().diff(mdob.add(yrs, 'years'), 'days', false);
                            this.yearsfordisplay = days;
                            this.yearsfordisplay = parseInt(yrs);
                            this.monthsfordisplay = months;
                            let date1 = moment(String(item.dateOfBirth), "YYYY-MM-DD");
                            let monthsa = moment().diff(date1, 'months');
                            date1.add(monthsa, 'months');
                            let daysa = moment().diff(date1, 'days');
                            monthsa + ' ' + 'Month(s)' + ' ' + daysa + " Day(s)"
                            this.daysfordisplay = daysa;
                            item.patientAge = item.dateOfBirth ? this.yearsfordisplay + 'Y ' + this.monthsfordisplay + 'M ' + this.daysfordisplay + 'D' : null;
                        }
                    })
                    this.billServices = response[0].services.map(service => {
                        return {
                            ...service,
                            bookingDetailId: service.serviceBookingDetailId.toString().padStart(6, '0'),
                        };
                    });
                    this.payModes = response[0].payTypes;
                    this.invoiceTotal = this.billServices.map((x) => {
                        return x.netAmount;
                    }).reduce((a, b) => a + b, 0);
                    this.amountPaid = this.payModes.map((x) => {
                        return x.amountPaid;
                    }).reduce((a, b) => a + b, 0);

                    let amountInWords = numberToWords.toWords(this.invoiceTotal);
                    this.amountInWord = amountInWords + ' Rupees Only.';
                });
        this.currentDate = new Date();
    }
}

