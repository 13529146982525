export class Service {
    serviceId: number;
    serviceName: string;
    active: boolean;
    serviceIconId?: number;
    specializationIconId?: number;
    serviceJson: string;
    createdDate: Date;
    createdByName: string;
    modifiedByName: string;
    createdBy: number;
    modifiedDate: Date;
    modifiedBy: number;
    totalItems: number;   
    url: string;
    url2?: string;
    priority: number;
    mobileIconId?: number;
}