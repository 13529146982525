import { Component, ViewEncapsulation, OnInit, OnDestroy, Input } from "@angular/core";
import { IUserAccount, Page, IResource } from "@shared/models";
import { HttpService, PrintOptionService, BannerService, ResourceService, AppData } from "@shared/services";
import { ImageReports, Appointment, PayTypes } from "@shared/entities";
import { ApiResources } from "@shared/helpers";
import { finalize, takeUntil } from "rxjs/operators";
import * as numberToWords from 'number-to-words';
import * as converter from "number-to-words";

@Component({
    templateUrl: "./goods-invoice.html",
    styleUrls: ['./goods-invoice.widget.css'],
    selector: "goods-invoice",
    encapsulation: ViewEncapsulation.None
})
export class GoodsInvoiceWidget implements OnInit, OnDestroy {
   
    page: Page;
    loading: boolean;
    bannerBasics: ImageReports;
    myImgUrl = "assets/images/careaxesLogo.png";
    
    relationType: string;
    practiceLocations: Array<IResource>
    formattedText: string;
    practices: Array<string>
    constructor(
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
        private readonly bannerService: BannerService,
        private readonly appData: AppData,
        private readonly resourceService: ResourceService,
    ) {
        this.page = new Page();
        
    }
    private fetchPracticeLocation() {
        this.loading = true;
        this.resourceService.fetchPracticeLocations()
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<IResource>) => {
                this.practiceLocations = response;
                this.practices = this.practiceLocations[0].optionalText2.split(/\n/g);
               
            });
    }
    
    

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchPracticeLocation();
                } else {
                    this.page.userAccount = undefined;
                }
            });

    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
}
