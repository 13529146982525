import { Time } from "@angular/common";

export class ObEncounter {
    obEncounterId?: number;
    encryptedPatientId: string;
    appointmentId: number;
    encounterDate: Date;
    vitals: string;
    problemList: string;
    briefRos: string;
    active: boolean;
    createdBy: number;
    createdDate: Date
    modifiedBy: number;
    modifiedDate: Date;
    geneticScreening: string;
    infectionHistory: string;
    medicationComment: string = null;
    breastExamination: string;
    vaginalExamination: string;
    ancCaredClosure: string;
    ancCardGeneration: string;
    complaints: string;
    obstetricExamination: string;
    birthHistory: string;
    orderPrescription: string;
    drugHistory: string;
    vteRisk: string;
    planOfManagement: string;
    menstrualHistory: string;
    sgaScanning: string;
    surgeries: string;
    procedureForm: string;
    antinantalRisk: string;
    otherDetail: string;
    jsonString: string;
    vaccine: string;
    postnantalVisit: string;
    measure: string;
    generalExamination: string;
    breech: string;
    ancCard: string;
    covid: string;
    anaesthesiaRecord: string;
    prepregnancyCounseling: string;
    allergies: string;
    familyHistory: string;
    appointmentDate: Date;
    appointmentTimeString: string;
    providerName: string;
    appointmentTime: Time;
    isAppointmentClosed: boolean;
    outPatientManagement: string;
    specialFeature: string;
    prePregnancyPlan: string;
    referralForm: string;
    refferalOrder: string;
    admissionSlip: string;
    syndromes: string;
    opManagement: string;
    motherWithEpilepsy: string;
    externalCephalicVersion: string;
    dischargeSummary: string;
    nurseAssessment: string;
    outSideTests: string;
    encryptedAppointmentId: string;
    ProviderName: string;
    measureCommonData: string;
    ancCardCloseCommonData: string;
    commonSpecialFeature: string;
    neonatalAssessmentForm: string;
    inductionForm: string;
    normalDeliveryForm: string;
    lscs: string;
    admissionDetails: string;
    iufd: string;
    doctorsReview: string;
    reminder: string;
    riskCategoryAtAdm: string;
    addendumForm: string;
    cimsAllergy: string;
    gpla: string;
    emergencyTriage: string;
    nursingERForm: string;
    confidential: string;
    scanAppointmentDetails: string;
    futureAppointments: string;
    iolList: string;
    fullName: string;
    appointmentNo: string;
    encounterName: string;
    totalItems: number;
    isAdmitted: boolean;
    telePhoneTriageAssessment: string;
    scanTests: string;
    providerEmail: string;
    aldreteScore: string;
    anaesthesiaRecordPostOP: string
    patientName: string;
    patientId: number;
    encounterType:string
    radiology:string
}
