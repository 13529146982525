import { PayTypes } from "./paytype.entity";

export class ScanBooking {
    providerLocationId: number;
    providerName: string;
    providerId: number;
    availableDays: string;
    day: string;
    duration: string;
    fromTime: string;
    toTime: string;
    fromDate: Date;
    toDate: Date;
    patientId: number;
    encryptedPatientId: string;
    patientName: string;
    checkedIn: string;
    checkedOut: string;
    departmentId: number;
    departmentName: string;
    slotTat: string;
    slotDuration: string;
    availability: string;
    scanMachineMasterId: string;
    scanMachineMasterIds: string;
    machineName: string;
    bookScanAppointmentId: number;
    appointmentStartDateTime: string;
    appointmentEndDateTime: string;
    status: string;
    umrno: string;
    gender: string;
    doctorName: string;
    mobile: string;
    fullAddress: string;
    paymentNumber: string;
    fatherOrHusband: string;
    age: string;
    ageInYMD: string;
    appointmentDate: string;
    payTypeName: string;
    paymentType: string;
    appointmentTime: string;
    scanTestName: string;
    createdDate: string;
    createdByName: string;
    displayName: string;
    appointmentTimeString: string;
    scanTestMasterId: number;
    scanMachineTestMapId: number;
    scanTestCode: string;
    chargeCategoryId: number;
    chargeCategoryName: string;
    amount: number;
    discountAmount: number;
    amt: number;
    paymentStatus: boolean;
    pndtReport: boolean;
    isDispatched: boolean;
    paidPendingStatus: string;
    patientPaymentStatus: boolean;
    payTypeId: number;
    locationId: number;
    locationName: string;
    scanAvailabilityStatusName: string;
    scanAvailabilityReasonName: string;
    appointmentEndTime: string;
    scanAvailabilityReasonColor: string;
    isSalucroAppointment: boolean;
    requisitionNumber: string;
    isNewPatient: boolean;
    totalItems: number;
    ancNo: string;
    appointmentNo: string;
    indication: string;
    typeOfPayment: string;
    scanAppointmentType: string;
    actualAmount: number;
    gravida: string;
    periodOfGestation: string;
    edd: Date;
    hwcPatientId: number;
    backgroundColor: string;
    isSelected: boolean;
    tokenNumber?: number;
    isPatRegPaid: boolean;
    regCharges: number;
    scanAmount: number;
    totalDiscount: number;
    netAmount: number;
    paidAmount: number;
    paidAmt: number;
    paidScanAmount: number;
    refundAmount: number;
    scanDoctors: string;
    packageModuleDetailId: number;
    isIncludedInPackage: boolean;
    packageName: string;
    createdByRole: string;
    payTypes: Array<PayTypes>;
    isAccepted: boolean;
    scanServiceId: number;
    dueAmount: number;
    practiceName?: string;
    practiceMobile?: string;
    scanDoctorName?: string;
    aadharNumber?: string;
    appointmentId?: number;
    relitive: string;
    fulladdress: string;
    streetAddress: string;
    city: string;
    state: string;
    zipcode: string;
    birthHistory: string;
    umrNo: string;
    bookScanRemarks: string;
    isConsentFormRequired: boolean;
    scanDocumentId: number;
    formType: string;
    consentScanDocumentId: number;
    consentFormType: string;
    billNumber: number;
    relativeName: string;
    relation: string;
    referredByIdName: string;

    constructor() {
        this.payTypes = new Array<PayTypes>();
    }
}

export class PatientFutureAppointment {
    patientId: number;
    appointmentDate: string;
    appointmentTime: string;
    scanTestName: string;
    locationName: string;
    machineName: string;
    providerName: string;
    type: string;
}