<div>
    <div class="report position-relative">
        <table class="w-100">
            <thead>
                <tr>
                    <td class="p-0">
                        <div>
                            <div *ngIf="!isPrintLogo" class="col-12 p-0">
                                <div>

                                </div>
                            </div>
                            <div *ngIf="isPrintLogo">
                                <div class="border-dark">
                                    <banner-setting [reportName]='"PO-ORDER_REPORT Header"'></banner-setting>
                                </div>
                            </div>
                        </div>

                    </td>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>
                        <div>
                            <div class="min-height-80 mb-3">
                                <div class=" text-center mb-3">
                                    <h4 class="d-inline-block border-bottom border-dark border-darker m-0 ">
                                        Goods Receipt Note (G.R.N)
                                    </h4>
                                </div>
                                <div>
                                    <div class="overflow-auto">
                                        <table class="w-100 border-dark border">
                                            <tr class="vertical-align-top">

                                                <td class="">
                                                    <h5 class="d-inline-block w-150px pl-1">Supplier Name</h5>
                                                    <span class="d-inline-block text-uppercase">
                                                        <span [textContent]="': '"></span>
                                                    </span>
                                                    <span class="d-inline-block text-uppercase pl-3">
                                                        <span [textContent]="responses[0]?.supplierName"></span>
                                                    </span>
                                                </td>
                                                <td class="">
                                                    <h5 class="d-inline-block w-150px ">GRN No </h5>
                                                    <span class="d-inline-block text-uppercase">
                                                        <span [textContent]="': '"></span>
                                                    </span>
                                                    <span class="d-inline-block text-uppercase pl-3">
                                                        <span [textContent]="+responses[0]?.grnNumber|| '----'"></span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="">
                                                    <h5 class="d-inline-block w-150px pl-1">Address</h5>
                                                    <span class="d-inline-block text-uppercase">
                                                        <span [textContent]="': '"></span>
                                                    </span>
                                                    <span class="d-inline-block text-uppercase pl-3">
                                                        <span [textContent]="responses[0]?.address || '----'"></span>
                                                    </span>

                                                </td>
                                                <td class="">
                                                    <h5 class="d-inline-block w-150px ">GRN Date </h5>
                                                    <span class="d-inline-block text-uppercase">
                                                        <span [textContent]="': '"></span>
                                                    </span>
                                                    <span class="d-inline-block text-uppercase pl-3">
                                                        <span [textContent]="(responses[0]?.approvedDate ? (responses[0].approvedDate | date:'dd/MM/yyyy') : '---')"></span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="">
                                                    <h5 class="d-inline-block w-150px pl-1">City</h5>
                                                    <span class="d-inline-block text-uppercase">
                                                        <span [textContent]="': '"></span>
                                                    </span>
                                                    <span class="d-inline-block text-uppercase pl-3">
                                                        <span [textContent]="(responses[0]?.city ?? '----')"></span>
                                                    </span>

                                                </td>
                                                <td class=" pl-1">
                                                    <h5 class="d-inline-block w-150px">Invoice No</h5>
                                                    <span class="d-inline-block text-uppercase">
                                                        <span [textContent]="': '"></span>
                                                    </span>
                                                    <span class="d-inline-block text-uppercase pl-3">
                                                        <span [textContent]="(responses[0]?.billNumber ?? '----')"></span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="">
                                                    <h5 class="d-inline-block w-150px pl-1">Phone</h5>
                                                    <span class="d-inline-block text-uppercase">
                                                        <span [textContent]="': '"></span>
                                                    </span>
                                                    <span class="d-inline-block text-uppercase pl-3">
                                                        <span [textContent]="(responses[0]?.mobile ?? '----')"></span>
                                                    </span>
                                                </td>
                                                <td class="">
                                                    <h5 class="d-inline-block w-150px ">Invoice Date </h5>
                                                    <span class="d-inline-block text-uppercase">
                                                        <span [textContent]="': '"></span>
                                                    </span>
                                                    <span class="d-inline-block text-uppercase pl-3">
                                                        <span [textContent]="(responses[0]?.billDate ? (responses[0].billDate | date:'dd/MM/yyyy') : '---')"></span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="pl-1">
                                                    <h5 class="d-inline-block w-150px pl-1">TIN No</h5>
                                                    <span class="d-inline-block text-uppercase">
                                                        <span [textContent]="': '"></span>
                                                    </span>
                                                </td>
                                                <td class="pl-1">
                                                    <h5 class="d-inline-block w-150px">D.L.No</h5>
                                                    <span class="d-inline-block text-uppercase">
                                                        <span [textContent]="': '"></span>
                                                    </span>
                                                    <span class="d-inline-block text-uppercase pl-3">
                                                        <span [textContent]="(responses[0]?.drugLicense ?? '----')"></span>
                                                    </span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>

                                <div class="mt-1">
                                    <div class="overflow-auto">
                                        <table class="border-dark  w-100">
                                            <tr class="white-space-nowrap vertical-align-top">
                                                <th class="border-top border-bottom border-left text-center border-dark"><h5>S.No.</h5></th>
                                                <th class="border-top  border-bottom border-dark w-250px"><h5>Item Description</h5></th>
                                                <th class="border-top text-center border-bottom border-dark"><h5>Qty</h5> </th>
                                                <th class="border-top text-center border-bottom border-dark"><h5>Free</h5></th>
                                                <th class="border-top text-center border-bottom border-dark"><h5>Batch no</h5></th>
                                                <th class="border-top text-center border-bottom border-dark"><h5>ExpDt</h5></th>
                                                <th class="border-top text-center border-bottom border-dark"><h5>Rate</h5></th>
                                                <th class="border-top text-center border-bottom border-dark"><h5>Disc</h5></th>
                                                <th class="border-top text-center border-bottom border-dark"><h5>Tax</h5></th>
                                                <th class="border-top text-center border-bottom  border-dark"><h5>MRP</h5></th>
                                                <th class="border-top text-center border-bottom border-right border-dark"><h5>Amount</h5></th>
                                            </tr>
                                            <tr *ngFor="let prod of responses;index as j;" class="vertical-align-top">
                                                <td class="border-bottom border-left text-center  border-dark">
                                                    <span [textContent]="j+1"></span>
                                                </td>
                                                <td class="border-bottom  border-dark pl-1">
                                                    <div class="text-uppercase" [textContent]="prod?.productName || '----'"></div>
                                                </td>
                                                <td class="border-bottom border-dark text-center">
                                                    <span [textContent]="prod?.approvedQuantity || '----'"></span>
                                                </td>
                                                <td class="border-bottom border-dark text-center">
                                                    <span [textContent]="prod?.free || '----'"></span>
                                                </td>
                                                <td class="border-bottom border-dark text-center">
                                                    <span [textContent]="prod?.batchNumber || '----'"></span>
                                                </td>
                                                <td class="border-bottom border-dark text-center">
                                                    <span [textContent]="prod?.expiryDate | date:'dd/MM/yyyy' || '---'"></span>
                                                </td>

                                                <td class="border-bottom border-dark text-center">
                                                    <span [textContent]="prod?.purchaseRate || '----'"></span>
                                                </td>
                                                <td class="border-bottom border-dark text-center">
                                                    <span [textContent]="(prod?.discountPercentage || 0) +'%'"></span>
                                                </td>
                                                <td class="border-bottom border-dark text-center">
                                                    <span [textContent]="(prod?.taxAmount || 0) | currency:'INR'"></span>
                                                </td>
                                                <td class="border-bottom  border-dark text-center">
                                                    <span [textContent]="(prod.mrp || 0) | currency:'INR'"></span>
                                                </td>
                                                <td class="border-bottom border-right border-dark text-center">
                                                    <span [textContent]="(prod?.mrp || 0) | currency:'INR'"></span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="border-bottom border-left border-dark text-left" colspan="3">
                                                    <div class="d-flex">
                                                        <h5>Total Amt: </h5>
                                                        <h5>&nbsp;{{totalamt}}</h5>
                                                    </div>
                                                </td>
                                                <td class="border-bottom border-dark text-left" colspan="3">
                                                    <div class="d-flex">
                                                        <h5>Less Discount: </h5>
                                                        <span>&nbsp;--</span>
                                                    </div>
                                                </td>
                                                <td class="border-bottom border-dark text-left" colspan="3">
                                                    <div class="d-flex">
                                                        <h5>Add Tax: </h5>
                                                        <span>&nbsp;--</span>
                                                    </div>
                                                </td>
                                                <td class="border-bottom border-dark text-left border-right" colspan="3">
                                                    <div class="d-flex">
                                                        <h5>Other Tax: </h5>
                                                        <span>&nbsp;--</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="12" class="">
                                                    <div class="d-flex">
                                                        <h5>Net Amt &nbsp;: </h5>
                                                        <h5 class="pl-1"> &nbsp;{{totaltax + totalamt}} </h5>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="12" class="">
                                                    <div class="d-flex">
                                                        <h5>Amount in Words&nbsp;: </h5>
                                                        <span class="text-capitalize pl-2"> &nbsp; {{totalAmtInWords}} Only</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <table class="table table-borderless mt-4">
                                        <tbody>
                                            <tr>
                                                <td class="border-bottom border-dark text-center">
                                                    <span>{{responses[0]?.fullName || '-----'}}</span>
                                                </td>
                                                <td class="w-150px"></td>
                                                <td class="white-space-nowrap border-bottom border-dark text-center">
                                                    <span>{{responses[0]?.fullName || '-----'}}</span>
                                                </td>
                                                <td class="w-150px"></td>
                                                <td class="white-space-nowrap border-bottom border-dark text-center">
                                                    <span>{{responses[0]?.fullName || '-----'}}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class=" text-center"><h5>GRN By</h5></td>

                                                <td class="w-150px"></td>
                                                <td class="text-center"><h5>Received By</h5></td>
                                                <td class="w-150px"></td>
                                                <td class="text-center"><h5>Store Incharge</h5></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </td>
                </tr>
            </tbody>

            <tfoot>
                <tr>
                    <td>
                        <div class="page-footer-space"></div>
                    </td>
                </tr>
            </tfoot>

        </table>
    </div>
</div>