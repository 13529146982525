
import { OnInit, OnDestroy, Component, Input, ElementRef, ViewChild } from "@angular/core";
import { takeUntil, finalize } from "rxjs/operators";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Appointment, PreviousAppointment, pediatricEncounterFullTranscript, PatientMedicationHeader, ImageReports } from "@shared/entities";
import { HttpService, AppData, IconService, PrintOptionService, MenuService, appUrls } from "@shared/services";
import { Time } from "@angular/common";
import { ImmunizationHistoryModel } from "@admin/vaccines/models";
import { debug } from "util";
import { PEncounter } from "../../models/pediatric-encounter.model";
import { Page } from "../../models/page.model";
import { OB } from "../../../areas/admin/full-transcript/pages/ob-report/ob.namespace";
import { ApiResources } from "../../helpers/api.helper";
import { MenuType } from "../../enums/menu-type.enum";
import { UtilHelper } from "../../helpers/util.helper";

class FilterOptions {
    encryptedAppointmentId: string = null;
}

class Filters {
    options: FilterOptions;
    applied: boolean;

    constructor() {
        this.init();
    }

    init() {
        this.options = new FilterOptions();
        this.applied = undefined;
    }
}
class OrderPrescriptionModel {
    appoitmentId: number;
    orderPrescription: string;
    appointmentDate: Date;
    appointmentId: string;
    appointmentTime: Time;
    providerName: string;

}
@Component({
    templateUrl: "./pediatric-full-report.html",
    selector: "pediatric-fullreport",
})

export class PediatricfullReportPage implements OnInit, OnDestroy {

    @Input() printId: string;
    @Input() printadmission: boolean;

    fullTranscript: pediatricEncounterFullTranscript;
    appointments: Array<Appointment>;
    oldAppointment: PreviousAppointment;
    page: Page;
    isAdmission: boolean;
    appointmentId: string;
    routingValue: string;
    section: string;
    filters: Filters;
    measurements: any;
    neonatalRisk: any;
    pediatricRisk: any;
    syndrome: any;
    pediatricGuide: any;
    diagnosisRecords: Array<OB.DiagnosisOrder>;
    visitOrders: Array<OB.VisitForm>;
    pediatricGuideLines: Array<any>;
    flag = true;
    appointment: PEncounter;
    loading: boolean;
    records: Array<PatientMedicationHeader>;
    clinicalExamination: any;
    downSyndromeFollowUp: any;
    highRiskIntial: any;
    orderPrescription: OB.OrdersPrescription;
    allergies: OB.Allergies;
    vaccineDetail: string;
    reminders: string;
    loadingImm: boolean;
    familyHistory: Array<OB.ObFamilyHistory>;
    birthHistory: Array<OB.ObBirthHistory>;
    specialFeature: OB.SpecialFeature;
    addendumForm: OB.AddendumForm;
    mChat: any;
    others: any;
    refferalOrder: Array<OB.OrderReferral>;
    isFamilyHistory: boolean;
    isBirthHistory: boolean;
    orders: Array<OrderPrescriptionModel>;
    immunization: Array<ImmunizationHistoryModel>;
    iconBasics: ImageReports;
    @Input() encryptedPatientId: string;
    @Input() encryptedAppointmentId: string;
    @Input() isPrintLogo: boolean;
    @Input() useExistingCss: boolean;
    problemList: Array<any>;
    procedureSurgery: Array<OB.ProcedureSurgery>;
    isPastMedicalHistory: boolean;
    isProcedureSurgery: boolean;
    roleId: number;
    cimsAllergies: any;
    diseaseName: any
    yearOfDiagnosis: any
    icd: any
    status: any
    treatmentHistory: any
    data: any;
    isFooter: boolean;
    aldreteScore: OB.AldreteScore;
    telephoneTriageAssessmentdata: Array<OB.TelephoneTriageAssessment>;
    anaesthesiaRecord: OB.AnaesthesiaRecordPostOP;
    anaesthesia: OB.Anaesthesia;
    constructor(
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
        private readonly iconService: IconService,
        private readonly menuService: MenuService

    ) {
        this.page = new Page();
        this.appointments = [];
        this.immunization = new Array<ImmunizationHistoryModel>();
        this.telephoneTriageAssessmentdata = Array<OB.TelephoneTriageAssessment>();
    }
    private findDashboard(appointmentId: string) {

        const request = { encryptedAppointmentId: appointmentId, encryptedPatientId: this.encryptedPatientId };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<pediatricEncounterFullTranscript>(ApiResources.getURI(ApiResources.pediatricEncounter.base, ApiResources.pediatricEncounter.findFullTranscript), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: pediatricEncounterFullTranscript) => {
                this.fullTranscript = response;

                if (this.fullTranscript && this.fullTranscript.pediatricEncounterId) {

                    this.measurements = this.fullTranscript.measurements ? JSON.parse(this.fullTranscript.measurements) : null;
                    this.neonatalRisk = this.fullTranscript.neonatalRisk ? JSON.parse(this.fullTranscript.neonatalRisk) : null;
                    this.pediatricRisk = this.fullTranscript.pediatricRisk ? JSON.parse(this.fullTranscript.pediatricRisk) : null;
                    this.syndrome = this.fullTranscript.syndrome ? JSON.parse(this.fullTranscript.syndrome) : null;
                    this.pediatricGuide = this.fullTranscript.pediatricGuide ? JSON.parse(this.fullTranscript.pediatricGuide) : null;
                    this.clinicalExamination = this.fullTranscript.clinicalExamination ? JSON.parse(this.fullTranscript.clinicalExamination) : null;
                    this.downSyndromeFollowUp = this.fullTranscript.downSyndromeFollowUp ? JSON.parse(this.fullTranscript.downSyndromeFollowUp) : null;
                    this.highRiskIntial = this.fullTranscript.highRiskIntial ? JSON.parse(this.fullTranscript.highRiskIntial) : null;
                    this.orderPrescription = this.fullTranscript.orderPrescription ? JSON.parse(this.fullTranscript.orderPrescription) : null;
                    this.allergies = this.fullTranscript.allergies ? JSON.parse(this.fullTranscript.allergies) : null;
                    this.refferalOrder = this.fullTranscript.refferalOrder ? JSON.parse(this.fullTranscript.refferalOrder) : null;
                    this.vaccineDetail = this.fullTranscript.vaccineDetail;
                    this.diagnosisRecords = this.fullTranscript.diagnosisOrder ? JSON.parse(this.fullTranscript.diagnosisOrder) : null;
                    this.visitOrders = this.fullTranscript.nextVisitOrder ? JSON.parse(this.fullTranscript.nextVisitOrder) : null;
                    this.specialFeature = this.fullTranscript.specialFeature ? JSON.parse(this.fullTranscript.specialFeature) : null;
                    this.familyHistory = this.fullTranscript.familyHistory ? JSON.parse(this.fullTranscript.familyHistory) : null;
                    this.reminders = this.fullTranscript.reminder ? this.fullTranscript.reminder : null;
                    this.cimsAllergies = this.fullTranscript.cimsAllergy ? JSON.parse(this.fullTranscript.cimsAllergy) : null;
                    this.aldreteScore = this.fullTranscript.aldreteScore ? JSON.parse(this.fullTranscript.aldreteScore) : null;
                    this.anaesthesiaRecord = this.fullTranscript.anaesthesiaRecordPostOP ? JSON.parse(this.fullTranscript.anaesthesiaRecordPostOP) : null;
                    this.anaesthesia = this.fullTranscript.anaesthesiaRecord ? JSON.parse(this.fullTranscript.anaesthesiaRecord) : null;

                    if (this.fullTranscript.familyHistory && this.familyHistory.length == 0) {
                        this.isFamilyHistory = true;
                    }
                    this.birthHistory = this.fullTranscript.birthHistory ? JSON.parse(this.fullTranscript.birthHistory) : null;

                    this.mChat = this.fullTranscript.mchat ? JSON.parse(this.fullTranscript.mchat) : null;
                    this.pediatricGuideLines = this.fullTranscript.pediatricGuide ? JSON.parse(this.fullTranscript.pediatricGuide) : null;
                    this.others = this.fullTranscript.others ? JSON.parse(this.fullTranscript.others) : null;
                    this.addendumForm = this.fullTranscript.addendumForm ? JSON.parse(this.fullTranscript.addendumForm) : null;
                }
                this.procedureSurgery = this.fullTranscript.surgeries ? JSON.parse(this.fullTranscript.surgeries) : null;
                if (this.fullTranscript.surgeries && this.procedureSurgery.length == 0) {
                    this.isProcedureSurgery = true;
                }
                this.problemList = this.fullTranscript.problemList ? JSON.parse(this.fullTranscript.problemList) : null;

                if (this.problemList) {
                    this.data = this.problemList["problems"]
                    if (this.data.length == 0) {
                        this.isPastMedicalHistory = true
                    }
                }
                if (UtilHelper.isEmpty(this.vaccineDetail)) {
                    this.fetchImmunizaion();
                }


            });

    }
    private fetchImmunizaion() {
        this.loadingImm = true;
        const request = {
            patientId: this.appointment.patientId
        };

        this.httpService.get(ApiResources.getURI(ApiResources.vaccine.base, ApiResources.vaccine.fetchPatientImmunizationHistory), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loadingImm = false;
            }))
            .subscribe((response: Array<ImmunizationHistoryModel>) => {
                this.immunization = response;
            }, () => { this.immunization = new Array<ImmunizationHistoryModel>(); });
    }
    private fetchMedication() {
        this.loading = true;
        const request = {
            encryptedAppointmentId: this.appointmentId
        }

        this.httpService.post(ApiResources.getURI(ApiResources.patientEncounter.base, ApiResources.patientEncounter.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: Array<PatientMedicationHeader>) => {
                this.records = response;
            }, () => {
                this.records = Array<PatientMedicationHeader>();
            });

    }
    private findPatient() {
        this.page.loading = true;
        const request = { encryptedAppointmentId: this.appointmentId };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<PEncounter>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: PEncounter) => {
                this.appointment = response;
                this.encryptedPatientId = response.encryptedPatientId;
                this.findDashboard(this.appointmentId);
                this.fetchalltelephonetriage(this.appointment.appointmentId, this.appointment.patientId)
                //this.fetchDocuments();
            });
    }
  
    ngOnInit() {

        const url = this.router.url;
        this.appointmentId = url.split("/")[url.split("/").length - 3];
        this.routingValue = url.split("/")[url.split("/").length - 4];

        this.appointmentId = this.printId
        this.isAdmission = this.printadmission
        this.route.params
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((params: Params) => {
                if (this.appointmentId) {
                    this.findPatient();
                    this.findDashboard(this.appointmentId);
                    this.fetchMedication();
                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((params: Params) => {
                            const section = params["section"];
                            this.section = section ? section : undefined;
                        });
                } else {
                    this.router.navigateByUrl(appUrls.notFound);
                }

                const bypass = params["bypass"];
                if (!bypass || bypass !== "t") {
                    this.printOptionService.get((is) => { this.isPrintLogo = is; });
                    this.iconService.getIconImage((is) => { this.iconBasics = is; });
                }
            });

    }
    ngOnDestroy() {

        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }
    fetchalltelephonetriage(appointmentId: any, patientId: any) {
        const request = {
            patientId: patientId,
            appointmentId: appointmentId,
            isAdmission: this.isAdmission
        }

        this.httpService.post(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchtelephonetraige), request)
            .subscribe((result: any) => {
                if (result) {
                    var response = result;
                    response.forEach((x) => {
                        var data = JSON.parse(x.json)
                        this.telephoneTriageAssessmentdata.push(data)
                    })
                }


            })

    }
}

