import { OnInit, OnDestroy, Component, TemplateRef, ViewChild } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Page, IUserAccount, IResource, Pagination } from "../../../../../shared/models";
import { takeUntil, finalize } from "rxjs/operators";
import { AppData } from "../../../../../app.data";
import { UtilHelper, ApiResources } from "../../../../../shared/helpers";
import { ResourceService, HttpService, NotifyService } from "../../../../../shared/services";
import { DoctorUnits, BedManagement } from "../../../../../shared/entities";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AdmissionTransferRequest } from "../../../../../shared/entities/admission-transfer-request.entity";
import { HttpErrorResponse } from "@angular/common/http";



class FilterOptions {
    fromDate: string ;
    toDate: string;
    fullName: string;
    changeRequestType: string;
    status ='Pending';
}


class Filters {
    options: FilterOptions;
    applied: boolean;

    constructor() {
        this.init();
    }

    init() {
        this.options = new FilterOptions();
        this.applied = undefined;
    }
}

@Component({
    templateUrl: "./transfer-request.html",
})
export class TransferRequestPage implements OnInit, OnDestroy {
    @ViewChild("reasonForCancel", { static: true }) reasonForCancel: TemplateRef<any>;
    page: Page;
    modalRef: NgbModalRef;
    isBed = false;
    isDoctorRequest = false;
    locationId: number;
    searching: boolean;
    patients: Array<IResource>;
    chargeCategories: Array<IResource>;
    DoctorUnits: Array<DoctorUnits>;
    loadingDoctorUnits: boolean;
    transferRequestForm: FormGroup;
    submitting: boolean;
    submitted: boolean;
    loading: boolean;
    records: Array<AdmissionTransferRequest>;
    @ViewChild("chargeCategoryChangeTemplate", { static: true }) chargeCategoryChangeTemplate: TemplateRef<any>;
    chargeCategory: Array<IResource>;
    filterChargeCategory: Array<IResource>;
    chargeCategoryId: number;
    loadingBeds: boolean;
    showBeds: boolean;
    beds: Array<BedManagement>;
    selectedBed: BedManagement;
    selectedBedManagement: BedManagement;
    selectedRequest: AdmissionTransferRequest;
    pagination: Pagination;
    changeBillingCategory: boolean;
    filters: Filters;
    maxxDate: string;
    minsDate: string;
    admissionId: number;
    admissionStoredId: number;
    admissionTransferId: number;
    comments: string;
    type: string;
    constructor(
        private readonly modalService: NgbModal,
        private readonly appData: AppData,
        private readonly resourceService: ResourceService,
        private readonly httpService: HttpService,
        private readonly formBuilder: FormBuilder,
        private readonly notifyService: NotifyService,
    ) {
        this.page = new Page();
        this.filterChargeCategory = new Array<IResource>();
        this.initPagination();
        this.changeBillingCategory = false;
        this.filters = new Filters();
    }
    private initPagination() {
        this.pagination = new Pagination();
        this.pagination.pageIndex = 1;
        this.pagination.pageSize = 10;

    }
    onNextPage() {
        $("body,html").animate({ scrollTop: 0 });
        this.fetchrequests();
    }

     buildForm() {
        this.transferRequestForm = this.formBuilder.group({
            admissionTransferRequestId: [null],
            admissionId: [null, Validators.required],
            chargeCategoryId: [null],
            doctorUnitMasterId: [null],
        })
    }

    fetchPatient(value: any) {
        if (UtilHelper.isEmpty(value.term)) {
            const request = value.term;
            if (request.length > 2) {
                this.searching = true;
                this.resourceService.admissionPatient(request.trim())
                    .pipe(finalize(() => { this.searching = false }))
                    .pipe(takeUntil(this.page.unSubscribe))
                    .subscribe((response: Array<IResource>) => {
                        this.patients = response;
                    });
            }
        }
        else {
            this.patients = [];
        }

    }
    //private fetchChargeCategories() {
    //    this.resourceService.chargeCategory()
    //        .pipe(takeUntil(this.page.unSubscribe))
    //        .subscribe((response: Array<IResource>) => {
    //            this.chargeCategories = response;
    //        });
    //}

    //private fetchDoctorUnits(locationId: number) {
    //    this.loadingDoctorUnits = true;
    //    const request = {
    //        locationId: locationId
    //    };
    //    this.httpService.post(ApiResources.getURI(ApiResources.doctorUnit.base, ApiResources.doctorUnit.fetch), request)
    //        .pipe(takeUntil(this.page.unSubscribe))
    //        .pipe(finalize(() => this.loadingDoctorUnits = false))
    //        .subscribe((response: Array<DoctorUnits>) => {
    //            this.DoctorUnits = response;
    //        });

    //}

    onChangeBed() {
        this.isBed = !this.isBed;
    }

    onChangeDoctorRequest() {
        this.isDoctorRequest = !this.isDoctorRequest;
    }

    ngOnDestroy(): void {
        if (this.modalRef){ this.onCloseModal(); }
        this.page.unsubscribeAll()
    }
    ngOnInit(): void {
        sessionStorage.setItem("istimeline", "false") 
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.locationId = this.page.userAccount.locationId;
                    this.fetchrequests();
                    this.fetchAllPatients();
                   
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    private fetchChargeCategory(records?: AdmissionTransferRequest) {
        this.resourceService.chargeCategory()
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.chargeCategory = response;
                this.filterChargeCategory = [];
                records.charges = records.chargeCategories.split(',');
                const count = records.charges.length;
                for (let i = 0; i < count; i++) {
                    var id = parseInt(records.charges[i]);
                    const filter = this.chargeCategory.find(s => s.id == id);
                    const filterWithAdmissionId = { ...filter, admissionId: records.admissionId };
                    this.filterChargeCategory.push(filterWithAdmissionId);
                }           
            });
    }

    onOpenModel(content: TemplateRef<any>, records?: AdmissionTransferRequest) {
        this.fetchChargeCategory(records);
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: "xl",
            windowClass: "custom-modal effect-scale"
        });
    }

    onCloseModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
        } catch (e) {
            // ignored;
            console.error(e);
        }
    }

    onSubmit() {
        if (this.selectedBedManagement != null && this.selectedBedManagement != undefined) {
            this.notifyService.confirm(`Are you sure you want to accept this request`, () => {
                this.submitting = true;
                const request = {
                    bedId: this.selectedBedManagement.bedId,
                    admissionId: this.selectedRequest.admissionId,
                    approvedBy: this.page.userAccount.accountId,
                    admissionTransferRequestId: this.selectedRequest.admissionTransferRequestId,
                    doctorUnitMasterId:0,
                    locationId: this.page.userAccount.locationId,
                    chargeCategoryId: this.selectedBedManagement.chargeCategoryId,
                    changeBillingCategory: this.changeBillingCategory
                };
                request["patientName"] = this.selectedRequest.fullName;
                request["roleId"] = this.page.userAccount.roleId;
                request["createdBy"] = this.page.userAccount.accountId;
                request["createdByName"] = this.page.userAccount.fullName;
                request["admissionChangeRequestTypeId"] = this.selectedRequest.admissionChangeRequestTypeId;
                request["locationId"] = this.page.userAccount.locationId;
                this.httpService
                    .post(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.acceptAdmissionTransferRequest), request)
                    .pipe(takeUntil(this.page.unSubscribe))
                    .pipe(finalize(() => { this.submitting = false }))
                    .subscribe({
                        next: (response: number) => {
                            if (response > 0) {
                                this.submitting = false;
                                this.notifyService.success("Request Accepted  Successfully.");
                                this.fetchrequests();
                                this.onCloseModal();
                            }

                        },
                        error: (error: any) => {
                        const errorMessage = UtilHelper.handleError(error);
                        if(errorMessage) {
                            this.notifyService.warning(errorMessage);
                        } else {
                            this.notifyService.defaultError();
                        }
                    }
                    });
            })
        } else {
            this.notifyService.warningToast("Please Select Bed ");
        }
    }
    get form() { return this.transferRequestForm.controls; }
    private fetchrequests() {
        this.loading = true;
        const request = {
            ...this.filters.options,
            ...this.pagination,
            locationId: this.page.userAccount.locationId
        };
        request["active"] = (this.filters.options.status == "Accepted" || this.filters.options.status == "Rejected") ? false : this.filters.options.status == "Pending" ? true: null;
        this.httpService.post(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchTransferRequest), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<AdmissionTransferRequest>) => {
                    this.records = response;
                  UtilHelper.applyPagination(this.records, this.pagination);
                },
            );
    }

    onAcceptRequest(item: AdmissionTransferRequest) {
        this.showBeds = false;
        this.selectedRequest = item
        if (item.admissionChangeRequestTypeId == 1) {
            this.onOpenModel(this.chargeCategoryChangeTemplate, item);
        }
        else {
            this.notifyService.confirm(`Are you sure you want to accept this request`, () => {
                const request = {
                    admissionId: item.admissionId,
                    doctorUnitMasterId: item.doctorUnitMasterId,
                    approvedBy: this.page.userAccount.accountId,
                    admissionTransferRequestId: item.admissionTransferRequestId,
                    specializationId:item.specializationId
                };
                request["patientName"] = this.selectedRequest.fullName;
                request["roleId"] = this.page.userAccount.roleId;
                request["createdBy"] = this.page.userAccount.accountId;
                request["createdByName"] = this.page.userAccount.fullName;
                request["admissionChangeRequestTypeId"] = this.selectedRequest.admissionChangeRequestTypeId;
                request["locationId"] = this.page.userAccount.locationId;
                this.httpService
                    .post(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.acceptAdmissionTransferRequest), request)
                    .pipe(takeUntil(this.page.unSubscribe))
                    .subscribe({
                        next: (response: number) => {
                            if (response > 0) {
                                this.notifyService.success("Request Accepted  Successfully.");
                                this.fetchrequests();
                            }

                        },
                        error: (error: HttpErrorResponse) => {
                        const errorMessage = UtilHelper.handleError(error);
                        if(errorMessage) {
                            this.notifyService.warning(errorMessage);
                        } else {
                            this.notifyService.defaultError();
                        }
                    }
                    });
            })
        }
    }

    onReject(item: AdmissionTransferRequest) {
        this.admissionStoredId = item.admissionId
        this.admissionTransferId = item.admissionTransferRequestId
        this.type = item.changeRequestType
        this.notifyService.confirm(`Are you sure you want to Reject  this request`, () => {
            this.onOpenModel(this.reasonForCancel);
        })
    }
    cancelHelper = () => {

        if (this.comments == null && this.comments == undefined) {
            this.notifyService.warningToast("Please enter the comments.");
            return;
        }
        this.comments = this.comments.trim();
        this.type = this.type.trim();
        const request = {    
            admissionId: this.admissionStoredId,
            admissionTransferRequestId: this.admissionTransferId,
            rejectedBy: this.page.userAccount.accountId,
            rejectComments: this.comments,
            changeRequestType: this.type
        };
        this.httpService
            .post(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.rejectAdmissionTransferRequest), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { }))
            .subscribe(
                () => {
                    this.notifyService.successToast("Rejected  Successfully.");
                    this.fetchrequests();
                    this.comments = "";
                    this.onCloseModal();
                }, (error: HttpErrorResponse) => {
                    const errorMessage = UtilHelper.handleError(error);
                    if (errorMessage) {
                        this.notifyService.warning(errorMessage);
                        
                    } else {
                        this.notifyService.defaultError();
                    }
                }
            );
    }

    onSelectChargeCategory(record: IResource) {

        this.chargeCategoryId = record.id;
        this.admissionId=record.admissionId
        if (this.chargeCategoryId != 0 || this.chargeCategoryId != undefined || this.chargeCategoryId == null) {
            this.fetchBeds();
        }
    }
    fetchBeds(callback?: Function) {
        this.showBeds = true;
        this.loadingBeds = true;
        const request = {
            chargeCategoryId: this.chargeCategoryId,
            bedStatusId: 1,
            admissionId: this.admissionId,
        };
        this.httpService
            .post<Array<BedManagement>>(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchBeds), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingBeds = false))
            .subscribe(
                (response: Array<BedManagement>) => {
                    this.beds = response;
                    const availableBed = this.beds.find(x => x.bedStatusName === 'Available');
                    if (availableBed) {
                        this.selectBed(availableBed);
                    }
                    callback && callback();
                },
                () => {
                    this.beds = [];
                }
            );
    }

    selectBed(bed: BedManagement) {

        this.selectedBed = bed;
        this.selectedBedManagement = bed;
        this.selectedBedManagement.wardId = bed.wardId;
        this.selectedBedManagement.wardName = bed.wardName;
        this.selectedBedManagement.roomId = bed.roomId;
        this.selectedBedManagement.roomName = bed.roomName;
        this.selectedBedManagement.roomRent = bed.roomRent;
        //this.bookAdmissionForm.patchValue({
        //    bedId: bed.bedId
        //});
    }
    changeMaxDate(value: string) {
        this.maxxDate = value;
        this.filters.options.fromDate = value;
    }
    changeMinDate(value: string) {
        this.minsDate = value;
        this.filters.options.toDate = value;
    }
    onApplyFilters() {
        this.filters.applied = UtilHelper.isFiltersApplied(this.filters.options);
        this.initPagination();
        this.fetchrequests();
    }
    onResetFilters() {
        this.filters.init();
        this.initPagination();
        this.fetchrequests();
    }
    fetchAllPatients() {
        const request = {
            locationId: this.page.userAccount.locationId
        };
        this.httpService
            .get<Array<IResource>>(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchalltransferrequestpatients), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingBeds = false))
            .subscribe(
                (response: Array<IResource>) => {
                    this.patients = response;
                },
                () => {
                    this.patients = [];
                }
            );
    }
}