
<div id="dischargeReportContainer" class="scroll-obsecNotHeading row">
    <div class="col-12">
        <div class="mt-1">
            <div class="col-12 p-0">
                <div class="col border border-bottom-0 d-flex pl-1 pr-1 pt-1 headerStyle">
                    <div class="pl-0 col-11 d-flex">
                        <div class="right5">
                            <button class="btn btn-info" type="button" (click)="onChangePrintType(false)">
                                <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                            </button>
                            <button class="btn btn-info" type="button" (click)="onChangePrintType(true)">
                                <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                            </button>
                            <button class="btn btn-info" type="button" (click)="onChangeFooter(false)">
                                <i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer
                            </button>
                            <button class="btn btn-info" type="button" (click)="onChangeFooter(true)">
                                <i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer
                            </button>
                        </div>
                    </div>
                    <div class="col-1">
                        <button [useExistingCss]="true" id="printButton" printSectionId="dischargeReport" ngxPrint class="close d-print-none"> <i class="fe-printer" placement="left" ngbTooltip="Print"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="border-left border-right bg-white mb-0 pt-1">
            <div class="clearfix mb-3">
                <div id="dischargeReport">
                    <div class="report pl-4 pr-4 position-relative">
                        <ng-container *ngIf="isPrintLogo">
                            <div class="col-12 p-0">
                                <banner-setting [reportName]='"Ob Encounter Discharge Summary Header"'></banner-setting>
                            </div>
                        </ng-container>
                        <!--*** CONTENT GOES HERE ***-->
                        <div class="page">
                            <div class="col-12 p-0">
                                <div class="text-dark" *ngIf="!page.loading && fullTranscript">
                                    <div class="border-dark border-top2px d-flex justify-content-between pb-1 pt-1 border-bottom-dotted">
                                        <h4>Discharge Summary</h4>
                                        <h4 class="white-space-nowrap">Department of Obstetrics</h4>
                                    </div>
                                    <ng-container>
                                        <div class="col-12 p-0 pb-1 pt-1 white-space-nowrap">
                                            <table class="w-100 white-space-nowrap">
                                                <tr class="col-12">
                                                    <td class="w-130 border-bottom-dotted">
                                                        <span>Name</span>
                                                    </td>
                                                    <td class="border-bottom-dotted">
                                                        <h5>:</h5>
                                                    </td>
                                                    <td class="border-bottom-dotted text-uppercase">
                                                        <h5 class="d-block  pl-1 text-truncate max-width_25vw" *ngIf="appointment.patientName" [textContent]="appointment.patientName"></h5>
                                                    </td>
                                                    <td class="w-130 border-bottom-dotted">
                                                        <span> W/o</span>
                                                    </td>
                                                    <td class="border-bottom-dotted">
                                                        <h5>:</h5>
                                                    </td>
                                                    <td class="w-130 text-uppercase border-bottom-dotted">
                                                        <h5 class="pl-1 " *ngIf="husbandName" [textContent]="husbandName"></h5>
                                                    </td>
                                                </tr>
                                                <tr class="col-12">
                                                    <td>
                                                        <span>Age</span>
                                                    </td>
                                                    <td>
                                                        <h5>:</h5>
                                                    </td>

                                                    <td *ngIf="appointment.patientAge">
                                                        <h5 class="pl-1">{{appointment.patientAge}}&nbsp; years</h5>
                                                    </td>
                                                    <td>
                                                        <span>Admission Date/Time</span>
                                                    </td>
                                                    <td>
                                                        <h5>:</h5>
                                                    </td>
                                                    <td><h5 class=" pl-1 text-uppercase" [textContent]="appointment.appointmentDate | date: 'dd/MM/yyyy hh:mm a'"></h5> </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span>MR.No</span>
                                                    </td>
                                                    <td>
                                                        <h5>:</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="pl-1 text-uppercase " [textContent]="appointment.umrNo"></h5>
                                                    </td>
                                                    <td>
                                                        <span>Birth Date</span>
                                                    </td>
                                                    <td>
                                                        <h5>:</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="pl-1 text-uppercase" [textContent]="appointment.patientDateOfBirth| date: 'dd/MM/yyyy'"></h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span>IP.No</span>
                                                    </td>
                                                    <td>
                                                        <h5>:</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="pl-1 text-uppercase " [textContent]="patient.patient.admissionId"></h5>
                                                    </td>
                                                    <td>
                                                        <span>Discharge Date</span>
                                                    </td>
                                                    <td>
                                                        <h5>:</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="pl-1 text-uppercase">{{dischargeSummary.dischargeDate| date: 'dd/MM/yyyy'}}</h5>
                                                    </td>
                                                </tr>

                                            </table>
                                        </div>
                                        <div class="border-top-dotted border-dark">
                                            <table>
                                                <tr>
                                                    <td>
                                                        <span>Consultant </span>
                                                    </td>
                                                    <td>
                                                        <h5>:</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="pl-1 text-uppercase" [textContent]="appointment.providerName"></h5>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span>Email Id</span>
                                                    </td>
                                                    <td>
                                                        <h5>:</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="pl-1 text-uppercase" [textContent]="appointment.providerEmail"></h5>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="border-top-dotted">
                                            <div class="d-flex justify-content-between mt-3 ">
                                                <h5 class="text-uppercase">
                                                    {{dignosis}}
                                                </h5>

                                            </div>
                                        </div>
                                        <div *ngIf="obHistory" class="mt-2">
                                            <div class="dashboard-body">
                                                <div *ngIf="obHistory.length >0">
                                                    <div><h5 class="font-weight-bold text-black">OBSTETRIC HISTORY</h5></div>
                                                    <div class="overflow-auto mt-1 mb-1">
                                                        <table class="w-100 border-collapse">
                                                            <thead>
                                                                <tr>
                                                                    <th class="w-10px"><h5>Preg No</h5></th>
                                                                    <th><h5>Year</h5></th>
                                                                    <th><h5>Birth Details</h5></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let item of obHistory;">
                                                                    <td class="min-width80px">
                                                                        <div>
                                                                            <div class="text-wrap text-uppercase" [textContent]="item.pregnancyNo"></div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            <span class="text-capitalize" [textContent]="item.yearOfBirth"></span>&nbsp;&nbsp;
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            <span>FT {{item.modeOfDelivery}} at {{item.deliveryAt}}hospital {{item.gaWeeks}}weeks of gestation.{{item.sexOfBaby}},{{item.statusOfBaby}}& healty,birth Weight:{{item.birthWeight}}Kg at {{item.deliveryAt}} hospital</span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <table>
                                                <tr>
                                                    <td>
                                                        <h5 class="m-0"> LMP&nbsp;&nbsp;:</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class="pl-1" [textContent]="ancCard?.lmp |date :'dd/MM/yyyy'"></h5>
                                                    </td>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <td>
                                                        <h5 class="m-0"> EDD&nbsp;&nbsp;:</h5>
                                                    </td>
                                                    <td>
                                                        <h5 class=" pl-1" [textContent]="ancCard?.edd"></h5>
                                                    </td>
                                                </tr>

                                            </table>
                                        </div>
                                        <div *ngIf="problemList" class="mt-2">
                                            <div><h5 class="text-uppercase">Medical History</h5> </div>
                                            <div *ngIf="isPastMedicalHistory">
                                                None
                                            </div>
                                            <table style="width:100%">
                                                <tbody>
                                                    <tr *ngFor="let item of problemList; let i = index">
                                                        <td class="vertical-align-top w-50px">
                                                            <span [textContent]="i +1"></span>
                                                        </td>
                                                        <td>
                                                            <div class="d-flex">
                                                                <span>
                                                                    <span *ngIf="item.usable">Disease:{{item.usable}},</span>
                                                                    <span *ngIf="item.code">ICD Code:{{item.code}},</span>
                                                                    <span *ngIf="item.date">Year of diagnosis:{{item.date}},</span>
                                                                    <span *ngIf="item.comments">Treatment History:{{item.comments}},</span>
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div *ngIf="procedureSurgery" class="mt-2">
                                            <div><h5 class="text-uppercase">Surgical History</h5> </div>
                                            <div *ngIf="isProcedureSurgery">
                                                There is no Surgical History
                                            </div>
                                            <table style="width:100%" *ngIf="procedureSurgery.length>0">
                                                <tbody>
                                                    <tr *ngFor="let item of procedureSurgery; let i = index">

                                                        <div class="d-flex">
                                                            <span>
                                                                <span *ngIf="item.date"> Year Of Surgery:{{item.date}},</span>
                                                                <span *ngIf="item.surgeries"> Surgeries:{{item.surgeries}},</span>
                                                                <span *ngIf="item.comments"> Comments:{{item.comments}},</span>
                                                            </span>
                                                        </div>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div *ngIf="familyHistory" class="mt-2">
                                            <div><h5 class="text-uppercase">Family History</h5> </div>
                                            <div *ngIf="isFamilyHistory">
                                                There is no Family History
                                            </div>
                                            <table style="width:100%" *ngIf="familyHistory.length >0">
                                                <tbody>
                                                    <tr *ngFor="let item of familyHistory; let i = index">
                                                        <td>
                                                            <div class="d-flex">
                                                                <span>
                                                                    <span *ngIf="item.relation"> Relation:{{item.relation}},</span>
                                                                    <span *ngIf="item.problem"> Problem:{{item.problem}},</span>
                                                                    <span *ngIf="item.comments"> Comments:{{item.comments}},</span>
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-12 p-0 mt-2" *ngIf="allergies && allergies !== null">
                                            <div><h5 class="text-uppercase">Allergy History</h5> </div>
                                            <div *ngIf="allergies">
                                                <div class="d-flex" *ngIf="allergies && allergies.noHistory && allergies.noHistory!=null">
                                                    <div>
                                                        <div>
                                                            Allergies  <span class="pl-1" *ngIf="allergies && allergies.noHistory && allergies.noHistory!=null "> &nbsp; : &nbsp; There is no allergy</span>
                                                        </div>
                                                    </div>

                                                </div>    <table class="w-100 vertical-align-top" *ngIf="allergies && !allergies.noHistory">
                                                    <tr class="col-12" *ngIf="allergies.drugNames||allergies.drugReactions">
                                                        <td class="w-150px white-space-nowrap">
                                                            <span> Drug Allergies</span>
                                                        </td>
                                                        <td>
                                                            <h5 class="">:</h5>
                                                        </td>
                                                        <td>
                                                            <span class="d-block pl-1" [textContent]="allergies.drugNames"></span>
                                                        </td>
                                                        <td class="w-150px white-space-nowrap">
                                                            <span *ngIf="allergies.drugReactions"> Adverse Drug Reaction</span>
                                                        </td>
                                                        <td>
                                                            <span *ngIf="allergies.drugReactions" class="">:</span>
                                                        </td>
                                                        <td>
                                                            <span class="pl-1" [textContent]="allergies.drugReactions"></span>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12" *ngIf="allergies.foodNames||allergies.foodReactions">
                                                        <td class="w-150px white-space-nowrap">
                                                            <span> Food Allergy</span>
                                                        </td>
                                                        <td>
                                                            <h5 class="">:</h5>
                                                        </td>
                                                        <td>
                                                            <span class="d-block pl-1" [textContent]="allergies.foodNames"></span>
                                                        </td>
                                                        <td class="w-150px white-space-nowrap">
                                                            <span *ngIf="allergies.foodReactions"> Adverse Drug Reaction</span>
                                                        </td>
                                                        <td>
                                                            <span *ngIf="allergies.foodReactions" class="">:</span>
                                                        </td>
                                                        <td>
                                                            <span class="pl-1" [textContent]="allergies.foodReactions"></span>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12" *ngIf="allergies.dustNames||allergies.dustReactions">
                                                        <td class="w-150px white-space-nowrap">
                                                            <span> Dust Allergy</span>
                                                        </td>
                                                        <td>
                                                            <h5 class="">:</h5>
                                                        </td>
                                                        <td>
                                                            <span class="d-block pl-1" [textContent]="allergies.dustNames"></span>
                                                        </td>
                                                        <td class="w-150px white-space-nowrap">
                                                            <span *ngIf="allergies.dustReactions"> Adverse Drug Reaction</span>
                                                        </td>
                                                        <td>
                                                            <span *ngIf="allergies.dustReactions" class="">:</span>
                                                        </td>
                                                        <td>
                                                            <span class="pl-1" [textContent]="allergies.dustReactions"></span>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12" *ngIf="allergies.otherNames||allergies.otherReactions">
                                                        <td class="w-150px white-space-nowrap">
                                                            <span> Others</span>
                                                        </td>
                                                        <td>
                                                            <h5 class="">:</h5>
                                                        </td>
                                                        <td>
                                                            <span class="d-block pl-1" [textContent]="allergies.otherNames"></span>
                                                        </td>
                                                        <td class="w-150px white-space-nowrap">
                                                            <span *ngIf="allergies.otherReactions"> Adverse Drug Reaction</span>
                                                        </td>
                                                        <td>
                                                            <span *ngIf="allergies.otherReactions" class="">:</span>
                                                        </td>
                                                        <td>
                                                            <span class="pl-1" [textContent]="allergies.otherReactions"></span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>




                                        <div class="mt-2">
                                            <div>
                                                <div><h5> PRESENT PREGNANCY DETAILS </h5> </div>

                                                <span [innerHTML]="dischargeSummary.presentPregnancyDetail"></span>


                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <div>
                                                <h5>ADMISSION DETAILS :</h5>
                                            </div>
                                            <div>
                                                <h5>  <span [innerHTML]="dischargeSummary.admssionDetails"></span></h5>
                                            </div>
                                        </div>

                                        <div *ngIf="lscs && dischargeSummary ">
                                            <div class="col-12 p-0 pb-1 pt-1">
                                                <table class="w-75 mt-2">
                                                    <tr class="col-12">
                                                        <td class="w-130 white-space-nowrap">
                                                            <h5><u>PROCEDURE - LSCS</u></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Primary Indication</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.primaryIndication"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Contributing Factors</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.contributingFactors"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Type</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.type"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Anaesthesia</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.anaesthesia"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Abdominal Incision</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.abdominalIncision"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>LUS Condition</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.segmentCondition"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Previous Scar Integrity</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.scarIntegrity"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Uterine Incision</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.uterineIncision"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Plac - Site</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.placentaSite"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Vertical Uterine Tear</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.verticalUterineTear"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Uterine Vessel Involved</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.uterineVesselInvolved"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Rt Ovary</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.rTOvary"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Rt.Tube</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.rTTube"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Lt. Ovary</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.lTOvary"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Lt. Tube</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.lTTube"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Uterine Anomaly</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.uterineAnomaly"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Placenta Weight (gm)</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.placentaWeight"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Tubal Ligation</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.oFtubalLigation"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Fibroids</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.fibroids"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Adhesions</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.adhesions"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="w-130 white-space-nowrap">
                                                            <h5><u>BABY DETAILS</u></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Baby Mrno</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="dischargeSummary" [textContent]="dischargeSummary.baby1Mrno"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Date of Birth</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.babyBirthDate1 | date: 'dd/MM/yyyy'"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Time of Birth</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.babyBirthTime1"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Sex</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.sexOfBaby1"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Weight</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.babyBirthWt1"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Status</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.statusOfBaby1"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Apgar</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs.apgar1"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Cord ABG</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs?.cordABG1"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Presentation</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs?.babyPresentation1"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>P P Position</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs?.presentingPartPosition1"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Amniotic Fluid</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs?.amnioticFluidColour1"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>PH-Arterial</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs?.pHArterial1"></h5>
                                                        </td>
                                                    </tr>
                                                    <tr class="col-12">
                                                        <td class="white-space-nowrap">
                                                            <span>Venous</span>
                                                        </td>
                                                        <td>
                                                            <h5>:</h5>
                                                        </td>
                                                        <td>
                                                            <h5 class="pl-1" *ngIf="lscs" [textContent]="lscs?.pCO2Venous1"></h5>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <h5>COURSE  IN  THE  HOSPITAL</h5>

                                            <h5>
                                                <span [innerHTML]="dischargeSummary.courseHospital"></span>
                                            </h5>
                                        </div>

                                        <div class="mt-2">
                                            <div>
                                                <h5>CONDITION AT DISCHARGE</h5>
                                            </div>
                                            <h5>
                                                <span [innerHTML]="dischargeSummary.consitionDischarge"></span>
                                            </h5>
                                        </div>
                                        <div class="mt-2">
                                            <div>
                                                <h5 class="font-weight-bold text-black">Other Advice</h5>
                                            </div>
                                            <h5>
                                                <span [innerHTML]="dischargeSummary.otheradvice"></span>
                                            </h5>
                                        </div>
                                        <div class="mt-2">
                                            <div>
                                                <h5 class="font-weight-bold text-black">Remarks</h5>
                                            </div>
                                            <h5>
                                                <span [innerHTML]="dischargeSummary.remarks"></span>
                                            </h5>
                                        </div>
                                        <div class="mt-2">
                                            <div class="border-dark border-top p-1" *ngIf="records && records.length>0">
                                                <div class="pl-0 ">
                                                    <div>
                                                        <h5>DISCHARGE ADVICE</h5>
                                                        <div class="overflow-auto" *ngFor="let record of records">
                                                            <table class="w-100" style="border-collapse: collapse;">
                                                                <thead>
                                                                    <tr>
                                                                        <th><h5>Medications Adviced</h5> </th>
                                                                        <th><h5>Generic Name</h5></th>
                                                                        <th><h5>Dosage</h5></th>
                                                                        <th><h5>Category</h5></th>
                                                                        <th><h5>Qty</h5></th>
                                                                        <th><h5>Remarks</h5></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                    <tr *ngFor="let item of record.medicines; let i = index">
                                                                        <td class="min-width200px">
                                                                            <h5 class=" text-wrap text-uppercase" [textContent]="item.productName"></h5>
                                                                        </td>
                                                                        <td class="min-width200px">
                                                                            <h5 class=" text-wrap text-lowercase" *ngIf="item.genericName" [textContent]="item.genericName"></h5>
                                                                        </td>
                                                                        <td>
                                                                            <h5 class=" text-wrap">
                                                                                <span class="text-lowercase" *ngIf="item.instruction" [textContent]="item.instruction"></span>
                                                                            </h5>
                                                                        </td>
                                                                        <td>
                                                                            <h5 class=" text-wrap">
                                                                                <span *ngIf="item.instruction" [textContent]="item.categoryName"></span>
                                                                            </h5>
                                                                        </td>
                                                                        <td>
                                                                            <h5 class=" text-wrap">
                                                                                <span *ngIf="item.instruction" [textContent]="item.dosage"></span>
                                                                            </h5>
                                                                        </td>
                                                                        <td>
                                                                            <h5 class=" text-wrap">
                                                                                <span *ngIf="item.instruction" [textContent]="item.remark"></span>
                                                                            </h5>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mt-2">
                                            <div>
                                                <h5>
                                                    <u>FOLLOW UP / REVIEW DATE</u>
                                                </h5>
                                                <div>
                                                    {{dischargeSummary?.followUp}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <div *ngIf="problemList">
                                                <div><h5>FINAL DIAGNOSIS</h5> </div>
                                                <div *ngIf="isPastMedicalHistory">
                                                    None
                                                </div>
                                                <table class="tableBorder tableWidth " *ngIf="problemList && problemList.length>0">
                                                    <thead>
                                                        <tr class="tableBorder">
                                                            <th class="tableBorder"><h5>ICD Code</h5></th>
                                                            <th class="tableBorder"><h5> DESCRIPTION</h5></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of problemList">
                                                            <td class="tableBorder" [textContent]="item.code"></td>
                                                            <td class="tableBorder" [textContent]="item.usable"></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <div *ngIf="recordsResults && recordsResults.length >0">
                                                <div><h5 class="font-weight-bold text-black">Investigation Done</h5> </div>

                                                <table class="tableBorder tableWidth">
                                                    <thead>
                                                        <tr class="tableBorder">
                                                            <th class="tableBorder"><h5>Date</h5></th>
                                                            <th class="tableBorder"><h5>Investigation</h5> </th>
                                                            <th class="tableBorder"><h5>Result</h5></th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of recordsResults;let i =index;">
                                                            <td class="tableBorder" [textContent]="item.createdDate |date:'dd/MM/yyyy'"></td>
                                                            <td class="tableBorder" [textContent]="item.testName"></td>
                                                            <td class="tableBorder">{{item.result}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <div class="border-dark border-top d-flex justify-content-between mt-3">
                                                <h5>Report to the hospital in case of fever,foul smelling discharge per vaginum,excessive bleeding per vaginum or abdominal pain.</h5>
                                            </div>
                                        </div>
                                        <div class=" mt-1 align-items-end col-12 d-flex flex-wrap justify-content-between p-0  column-gap15px">
                                            <div class="d-flex   flex-grow-1">
                                                <div><span>IN CASE OF EMERGENCY CONTACT :&nbsp;</span></div>
                                                <div class="text-truncate"><h5 class="text-lowercase">{{location.optionalText}}</h5></div>
                                            </div>
                                            <div class="d-flex flex-wrap column-gap15px  flex-grow-1">
                                                <div class="d-flex align-items-end">
                                                    <div><span>Doctor Name :&nbsp;</span></div>
                                                    <div *ngIf="appointment.providerName"><h5 class="text-uppercase" [textContent]="appointment.providerName"></h5></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" mt-1 align-items-end col-12 d-flex flex-wrap justify-content-between p-0  column-gap15px">
                                            <div class="d-flex   flex-grow-1">
                                                <div><span>LACTATION HELPLINE NUMBER :&nbsp;</span></div>
                                                <div class="text-truncate"><h5 class="text-lowercase">8374005353</h5></div>
                                            </div>
                                            <div class="d-flex flex-wrap column-gap15px  flex-grow-1">
                                                <div class="d-flex align-items-end">
                                                    <div><span>Signature :&nbsp;</span></div>
                                                    <div><h5 class="border-bottom border-dark" *ngIf="appointment.signature"><img *ngIf="appointment && appointment.signature!=null" [src]="safe(appointment.signature)" cssClass="img-fluid rounded-circle" style="height:80px" /></h5></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-2 border-top-dotted border-dark">
                                            <h5>The discharge advice and instructions have been explained to me in a language which I understand.</h5>
                                            <br />
                                            <h5 *ngIf="regionalLanguage==='TAMIL'">உத்சர்க சலுகைகள் மற்றும் வழிகாட்டுதல்கள் எனக்கு புரிந்த மொழியில் விளக்கமாக உள்ளன.</h5>
                                            <h5 *ngIf="regionalLanguage==='TELUGU'">ఉత్సర్గ సలహాలు మరియు సూచనలు నాకు అర్థమయ్యే భాషలో వివరించబడ్డాయి.</h5>
                                        </div>
                                        <div class=" mt-1 align-items-end col-12 d-flex flex-wrap justify-content-between p-0  column-gap15px">
                                            <div class="d-flex   flex-grow-1">
                                                <div><span>Name of the patient / Attendant :&nbsp;</span></div>
                                                <div class="text-truncate" *ngIf="appointment"><h5 class="text-uppercase" [textContent]="appointment.patientName"></h5></div>
                                            </div>
                                            <div class="d-flex flex-wrap column-gap15px  flex-grow-1">
                                                <div class="d-flex align-items-end">
                                                    <div><span>Signature :&nbsp;</span></div>
                                                    <div><h5 class="border-bottom border-dark"></h5></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between border-dark border-top mt-2 font-weight-semibold">
                                            <h5><span>Saved User:</span> <span class="text-uppercase" [textContent]="appointment.providerName"></span></h5>
                                            <h5><span>Print Date/Time :</span> <span [textContent]="date | date :'d/MM/yyyy'"></span><span [textContent]="date | date :' h:mm a'"></span></h5>
                                            <h5><span>Printed by :</span> <span class="text-uppercase" [textContent]="printName"></span></h5>
                                        </div>
                                        <div>
                                            <div class="mt-2">
                                                <h5 class="logoStyle">DISCHARGE INSTRUCTIONS TO POST LSCS PATIENTS</h5>
                                                <div>
                                                    <h5>
                                                        The following instructions have been designed to help prevent you getting an infection at the site of your surgical incision. PLEASE
                                                        FOLLOW THEM METICULOUSLY:
                                                    </h5>
                                                </div>
                                                <div>
                                                    <div>
                                                        <h5>
                                                            1. If you have been given antibiotic to be taken as per your discharge, please complete the doses prescribed by your doctor, do not
                                                            skip doses or stop the medication before the completion of the course of antibiotic.
                                                        </h5>

                                                        <h5>2. Care of the incision site:</h5>

                                                        <h5>
                                                            a) Bathe at least once a day. Pat your incision wound dry.
                                                        </h5>

                                                        <h5>
                                                            b) Do not wear tight clothing or apply body lotions over the wound.
                                                        </h5>

                                                        <h5>
                                                            c) Ask for advice on how to hold and feed the baby to avoid uncomfortable pressure on the wound.
                                                        </h5>
                                                        <h5>
                                                            d) Support the incision site with your hands or a pillow when coughing and when you get up from a sitting or lying position.
                                                        </h5>

                                                        <h5>
                                                            3. Eat wholesome balanced meals with plenty of proteins.
                                                        </h5>

                                                        <h5>
                                                            4. Do not confine yourself to bed; early ambulation will help the wound heal better.
                                                        </h5>

                                                        <h5>
                                                            5. Seek medical care if there is:
                                                        </h5>

                                                        <h5>
                                                            a) Discharge / leakage of fluid from any part of wound.
                                                        </h5>

                                                        <h5>
                                                            b) Pain, redness, warmth or smelling around wound area.
                                                        </h5>

                                                        <h5>
                                                            c) Separation of the edges of the wound.
                                                        </h5>

                                                        <h5>
                                                            d) Visit to any hospital /used antibiotic / got a pus swab taken from wound, inform us on this number:
                                                        </h5>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="border-dark border-top mt-2">
                                            <banner-setting [reportName]='"Ob Encounter Discharge Summary Footer"'></banner-setting>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>





