<div class="content" style="background-color:white">
    <div class="container-fluid">
        <div class="d-flex align-content-between">
            <div class="col-6">
                <h1 style="color: #fbde06; margin-left: 30px">
                    Post Operative Ward
                </h1>
            </div>
            <div class="col-2" style="margin-top:20px">
                Date : 2024-11-05
            </div>
            <div class="col-4">
                <banner-setting [reportName]='"Lab Invoice Header"'></banner-setting>
            </div>
        </div>
        <!--<div class="row">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading...</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mh-300">
                <div class="no-data">
                    <img src="assets/images/no-data.png" alt="No data" />
                    <h4 class="title">Oops! No Data found.</h4>
                    <p class="sub-title">There is no data to show you right now.</p>
                </div>
            </div>
        </div>-->
        <div>
            <div class="d-flex justify-content-md-between">
                <div class="table-responsive queue-parent mx-2">
                    <table class="table table-bordered table-striped mb-0">
                        <thead>
                            <tr style="background-color: #fbde06 ">
                                <th class="text-center"></th>
                                <th class="text-center">MR Number</th>
                                <th class="text-center">Bed No.</th>
                                <th class="text-center">Time</th>
                                <th class="text-center">Procedure</th>
                                <th class="text-center">Cunsultant</th>
                                <th class="text-center">Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Test1</td>
                                <td>Test2</td>
                                <td>Test3</td>
                                <td>Test4</td>
                                <td>Test5</td>
                                <td>Test6</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Test11</td>
                                <td>Test12</td>
                                <td>Test13</td>
                                <td>Test14</td>
                                <td>Test15</td>
                                <td>Test16</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
