import { DiscountType } from "./discount-type.model";

export const DiscountTypeStrings = {
  GeneralServices: "general_services",
  LabServices: "lab_services",
  DoctorVisits: "doctor_visits",
  BedServices: "bed_services",
  ScanServices: "scan_services",
  OtServices: "ot_services",
  SurgeonChargeServices: "surgeonCharge_services",
  PharmacyServices: "pharmacy_services"
}

export class OverallDiscount {
  serviceCode: string;
  discountType: DiscountType;
  discountAmount: number;
  discountPercentage: number;
  discountReason: string;
}