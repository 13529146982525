export class PracticeLocation {
    practiceLocationId: number;
    encryptedPracticeLocationId?: string;
    encryptedPracticeId?: string;
    practiceId: number;
    practiceName: string;
    practiceTIN: string;
    practiceType: string;
    fullName: string;
    email: string;
    phone: string;
    extension: string;
    streetAddress: string;
    addressLine2: string;
    city: number;
    state: number;
    zipcode: string;
    countryId?: number;
    countryCode: string;
    active?: true;
    createdBy?: number;
    createdDate?: Date;
    modifiedBy?: number;
    modifiedDate?: Date;
    totalItems: number;
    locationId: number;
    name?: string;

    location: string;
    nameLoc: string;
    locationCode: string;
    drugLicenseNo: string;
    squeareFeetNo: number;
    dHCPsequence: string;
    toAddress: string;
    drescription: string;
    employeeNo: string;
    providentFundCode: string;
    helpMessage: string;
    premiumCharges: string;
    onlineAppointmentsApplicable: string;
    nonEmerStart: string;
    nonEmerEnd: string;
    numberOfSlots: string;
    locationWiseMoneyReceiptBillNo: string;
    emergencyPhoneNo: string;
    gPSAddress: string;
    gstinNo: string;
    cinNo: string;
    panNo: string;
    advertisement:string
}